<template>
  <CommonLayout title="INDEX">
    <template v-slot>
      <div
        style="
          font-size: 140px;
          text-align: left;
          margin-top: 100px;
          margin-left: 100px;
          color:black;
        "
      >
        目次
      </div>
      <div style="padding-left: 350px;">
        <div :style="[mokujiStyle, underline]">
          01
        </div>
        <div :style="[mokujiStyle, underline]">
          02
        </div>
        <div :style="[mokujiStyle, underline]">
          03
        </div>
        <div :style="[mokujiStyle, underline]">
          04
        </div>
        <div :style="iStyle">
          サマリー
        </div>
        <div :style="iStyle" style="padding-left: 100px;">
          投資指標
        </div>
        <div :style="iStyle" style="padding-left: 50px;">
          長期収支
        </div>
        <div :style="iStyle" style="padding-left: 50px;">
          投資計画
        </div>
        <div :style="[mokujiStyle, underline]">
          05
        </div>
        <div :style="[mokujiStyle, underline]">
          06
        </div>
        <div :style="[mokujiStyle, underline]">
          07
        </div>
        <div :style="[mokujiStyle, underline]">
          08
        </div>
        <div :style="iStyle" style="padding-right: -100px;">
          単年度収支
        </div>
        <div :style="iStyle" style="padding-right: 50px;">
          売却時
        </div>
        <div :style="iStyle" style="padding-left: 50px;">
          CF計画書
        </div>
        <div :style="iStyle" style="padding-left: 0px;">
          PL計画書
        </div>
      </div>
      <div
        style="
                    font-size: 90px;
                    padding: 225px 100px;
                    color:black;
                "
      >
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        本レポートは一般的な事例を基にした試算であり、個別具体的な情報をお示しするものではございません。
        <br />
        あくまでも参考情報としてお取り扱いいただき、個別具体的な計算については必ず税理士等の専門家に相談されてください。
        <br /><br />
        <div style="font-size:90px; margin-top:0px;">
          ・本レポートの試算結果については確実に保証されるものではありません。<br />
          ・本レポートのシミュレーションにより得られた数値を起因として、ご利用者または第三者が損害を被った場合であっても、
          <br />&nbsp;&thinsp;当社は一切の責任を負いかねます。
        </div>
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';

  export default {
    props: {
      commonInput: {
        type: String,
        required: true,
      },
    },
    components: {
      CommonLayout,
    },
    data() {
      return {
        mokujiStyle: {
          'font-size': '250px',
          'font-weight': 'bold',
          float: 'left',
          'margin-top': '0%',
          'margin-left': '5%',
          'margin-right': '5%',
          color: '#1d1d63',
        },
        underline: {
          'border-bottom': '15px solid',
          width: '650px',
          'border-bottom-color': '#00BFFF',
        },
        iStyle: {
          'font-size': '150px',
          float: 'left',
          'margin-left': '5%',
          'margin-right': '5%',
          color: '#1d1d63',
          'padding-top': '1%',
        },
      };
    },
    computed: {},
  };
</script>
