<!--
作成者：本間
作成日：2021.02.03
画面名：投資分析/出力
機能：単年度収支
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <b-tab id="SingleYearBalance" title="単年度収支">
    <b-card class="card-result" title="単年度収支計画(満室想定)">
      <b-container class="report" v-if="result">
        <b-row>
          <b-col class="text-right">
            単位：円
          </b-col>
        </b-row>
        <b-row>
          <b-col class="title emboss">項目1</b-col>
          <b-col class="title emboss">項目2</b-col>
          <b-col class="title emboss">項目3</b-col>
          <b-col class="title emboss" cols="1">数量</b-col>
          <b-col class="title emboss" cols="1">単位</b-col>
          <b-col class="title emboss">金額</b-col>
          <b-col class="title emboss">摘要</b-col>
        </b-row>
        <b-row
          class="title"
          style="height:2px;border-top:1px solid black;border-left:1px solid black;border-right:1px solid black;
          background-color:"
        />
        <!-- 収入の部 -->
        <span v-for="(item1, index1) in result.income.items" v-bind:key="`income_${index1}`">
          <span v-if="item1.items == undefined">
            <b-row>
              <b-col class="subtitle" v-bind:class="{ rowspan: index1 > 0 }">
                {{ index1 == 0 ? result.income.name : '' }}
              </b-col>
              <b-col class="subtitle">{{ item1.name }}</b-col>
              <b-col class="subtitle colspan" />
              <b-col class="value" cols="1" v-if="item1.amount == 0"></b-col>
              <b-col class="value" cols="1" v-else>{{ item1.quantity | currency }}</b-col>
              <b-col class="unit" cols="1">{{ item1.amount == 0 ? '' : item1.unit }}</b-col>
              <b-col class="value" v-if="item1.amount == 0"></b-col>
              <b-col class="value" v-else>{{ item1.amount | currency }}</b-col>
              <b-col>{{ item1.amount == 0 ? '' : item1.summary }}</b-col>
            </b-row>
          </span>
          <span v-else>
            <b-row
              v-for="(item2, index2) in item1.items"
              v-bind:key="`income_${index1}__${index2}`"
            >
              <b-col class="subtitle" v-bind:class="{ rowspan: index1 > 0 || index2 > 0 }">
                {{ index1 == 0 && index2 == 0 ? result.income.name : '' }}
              </b-col>
              <b-col class="subtitle" v-bind:class="{ rowspan: index2 > 0 }">
                {{ index2 == 0 ? item1.name : '' }}
              </b-col>
              <b-col class="subtitle">{{ item2.name }}</b-col>
              <b-col class="value" cols="1" v-if="item2.amount == 0"></b-col>
              <b-col class="value" cols="1" v-else>{{ item2.quantity | currency }}</b-col>
              <b-col class="unit" cols="1">{{ item2.amount == 0 ? '' : item2.unit }}</b-col>
              <b-col class="value" v-if="item2.amount == 0"></b-col>
              <b-col class="value" v-else>{{ item2.amount | currency }}</b-col>
              <b-col>{{ item2.amount == 0 ? '' : item2.summary }}</b-col>
            </b-row>
          </span>
        </span>
        <b-row>
          <b-col class="title text-right emboss" cols="8">収入の部&nbsp;合計</b-col>
          <b-col class="title text-right">{{ result.income.total | currency }}</b-col>
          <b-col class="title text-left">
            (@月&nbsp;{{ result.income.totalPerMonth | currency }})
          </b-col>
        </b-row>
        <b-row
          class="title"
          style="height:2px;border-top:1px solid black;border-left:1px solid black;border-right:1px solid black;
          background-color:"
        />
        <!-- 支出の部 -->
        <span v-for="(item1, index1) in result.spending.items" v-bind:key="`spending_${index1}`">
          <span v-if="item1.items == undefined">
            <b-row>
              <b-col class="subtitle" v-bind:class="{ rowspan: index1 > 0 }">
                {{ index1 == 0 ? result.spending.name : null }}
              </b-col>
              <b-col class="subtitle">{{ item1.name }}</b-col>
              <b-col class="subtitle colspan" />
              <b-col class="value" cols="1" v-if="item1.amount == 0"></b-col>
              <b-col class="value" cols="1" v-else>{{ item1.quantity | currency }}</b-col>
              <b-col class="unit" cols="1">{{ item1.amount == 0 ? '' : item1.unit }}</b-col>
              <b-col class="value" v-if="item1.amount == 0"></b-col>
              <b-col class="value" v-else>{{ item1.amount | currency }}</b-col>
              <b-col>{{ item1.amount == 0 ? '' : item1.summary }}</b-col>
            </b-row>
          </span>
          <span v-else>
            <b-row
              v-for="(item2, index2) in item1.items"
              v-bind:key="`spending_${index1}__${index2}`"
            >
              <b-col class="subtitle" v-bind:class="{ rowspan: index1 > 0 || index2 > 0 }">
                {{ index1 == 0 && index2 == 0 ? result.spending.name : null }}
              </b-col>
              <b-col class="subtitle" v-bind:class="{ rowspan: index2 > 0 }">
                {{ index2 == 0 ? item1.name : '' }}
              </b-col>
              <b-col class="subtitle">{{ item2.name }}</b-col>
              <b-col class="value" cols="1" v-if="item2.amount == 0"></b-col>
              <b-col class="value" cols="1" v-else>{{ item2.quantity | currency }}</b-col>
              <b-col class="unit" cols="1">{{ item2.amount == 0 ? '' : item2.unit }}</b-col>
              <b-col class="value" v-if="item2.amount == 0"></b-col>
              <b-col class="value" v-else>{{ item2.amount | currency }}</b-col>
              <b-col>{{ item2.amount == 0 ? '' : item2.summary }}</b-col>
            </b-row>
          </span>
        </span>
        <b-row>
          <b-col class="title text-right emboss" cols="8">支出の部&nbsp;合計</b-col>
          <b-col class="title text-right">{{ result.spending.total | currency }}</b-col>
          <b-col class="title text-left">
            (@月&nbsp;{{ result.spending.totalPerMonth | currency }})
          </b-col>
        </b-row>
        <b-row
          class="title"
          style="height:2px;border-top:1px solid black;border-left:1px solid black;border-right:1px solid black;
          background-color:"
        />
        <!-- 単年度収支 -->
        <b-row>
          <b-col class="title text-right emboss" cols="8">単年度収支</b-col>
          <b-col class="title text-right">{{ result.total | currency }}</b-col>
          <b-col class="title text-left">(@月 {{ result.totalPerMonth | currency }})</b-col>
        </b-row>
        <b-row style="height:0.5em;border-top:1px solid black" />
        <!-- 投資分析指標-->
        <b-row
          v-for="(item, index) in result.investmentAnalysisIndex.items"
          v-bind:key="`investmentAnalysisIndex_${index}`"
          style="height:27px;"
        >
          <b-col
            class="title emboss "
            cols="2"
            v-bind:class="{
              rowspan: index > 0,
            }"
            >{{ index == 0 ? '投資分析指標' : '' }}</b-col
          >
          <b-col class="subtitle" cols="2">{{ item.name[0] }}</b-col>
          <b-col class="subtitle colspan" cols="2">{{ item.name[1] }}</b-col>
          <b-col class="value text-right" cols="2" v-if="item.unit == '％'">
            {{ isNaN(item.amount) ? '0' : item.amount | ratio }}
          </b-col>
          <b-col class="value text-right" cols="2" v-if="item.unit != '％'">
            {{ item.amount | currency }}{{ item.unit }}
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </b-tab>
</template>
<script>
  import Calculation from '../Input/Calculation';
  import Loan from './LoanCalculation';

  export default {
    props: ['model'],
    mixins: [Calculation, Loan],
    data: function() {
      return {
        result: {
          propertyPrice: null, // 物件価格
        },
      };
    },
    created: function() {
      this.result = this.initialize();
    },
    mounted: function() {},
    methods: {
      // 初期化
      initialize: function() {
        // 各項目計算
        let items = {
          // 収入の部
          income: {
            name: '収入の部',
            items: null,
          },
          // 支出の部
          spending: {
            name: '支出の部',
            items: null,
          },
          // 単年度収支
          total: null,
          totalPerMonth: null,
          // 投資分析指標
          investmentAnalysisIndex: {
            items: null,
          },
        };
        // [収入の部]計算メイン
        items.income.items = this.calcIncome();
        // [収入の部]合計および月額算出
        items.income.total = items.income.items.reduce((a, v) => {
          return a + (isNaN(v.amount) ? v.total : v.amount);
        }, 0);
        items.income.totalPerMonth = items.income.total / 12;
        // [支出の部]計算メイン
        items.spending.items = this.calcSpending(items.income);
        // [支出の部]合計および月額算出
        items.spending.total = items.spending.items.reduce((a, v) => {
          return a + (isNaN(v.amount) ? v.total : v.amount);
        }, 0);
        items.spending.totalPerMonth = items.spending.total / 12;
        // 単年度収支
        items.total = items.income.total - items.spending.total;
        items.totalPerMonth = items.total / 12;
        // 投資分析指標算出
        items.investmentAnalysisIndex.items = this.calcInvestmentAnalysisIndex(
          items.income.total,
          items.spending.total -
            (items.spending.items.filter(item => item.name == '借入返済金')[0]?.amount ?? 0) // 借入返済金の支出は純利益に含めない
        );
        // [debug]計算結果
        //console.debug('[単年度収支]');
        //console.debug(JSON.stringify(items));
        // グローバル変数に保持(投資分析で参照)
        this.investmentAnalyticsTemp.singleYearBalanceResult = items;
        return items;
      },
      // 計算(収入の部)
      calcIncome: function() {
        let result = [];
        // 賃料
        if (this.rentTotal > 0) {
          result.push({
            name: '賃料',
            quantity: 1,
            unit: '年',
            amount: this.rentTotal * 12,
            summary: null,
          });
        }
        // 共益費
        if (this.commonServiceFeesTotal > 0) {
          result.push({
            name: '共益費',
            quantity: 1,
            unit: '年',
            amount: this.commonServiceFeesTotal * 12,
            summary: null,
          });
        }
        // 駐車料
        if (this.parkingFeesTotal > 0) {
          result.push({
            name: '駐車料',
            quantity: 1,
            unit: '年',
            amount: this.parkingFeesTotal * 12,
            summary: null,
          });
        }
        // 土地賃貸料
        if (this.landRentalFeesTotal > 0) {
          result.push({
            name: '土地賃貸料',
            quantity: 1,
            unit: '年',
            amount: this.landRentalFeesTotal * 12,
            summary: null,
          });
        }
        // 一時金
        const cp = this.investmentAnalytics.initialInvestment.capitalPlan; // 資金計画
        let total =
          Number(cp.deposit) +
          Number(cp.constructionCooperationFund) +
          Number(cp.securityDeposit) +
          Number(cp.keyMoney); // 一時金合計
        if (total > 0) {
          let items = [];
          // 保証金
          if (Number(cp.deposit) > 0) {
            items.push({
              name: '保証金',
              quantity: 1,
              unit: '年',
              amount: Number(cp.deposit),
              summary: null,
            });
          }
          // 建設協力金
          if (Number(cp.constructionCooperationFund) > 0) {
            items.push({
              name: '建設協力金',
              quantity: 1,
              unit: '年',
              amount: Number(cp.constructionCooperationFund),
              summary: null,
            });
          }
          // 敷金
          if (Number(cp.securityDeposit) > 0) {
            items.push({
              name: '敷金',
              quantity: 1,
              unit: '年',
              amount: Number(cp.securityDeposit),
              summary: null,
            });
          }
          // 礼金
          if (Number(cp.keyMoney) > 0) {
            items.push({
              name: '礼金',
              quantity: 1,
              unit: '年',
              amount: Number(cp.keyMoney),
              summary: null,
            });
          }
          // 一時金
          result.push({
            name: '一時金',
            items: items,
            total: total,
          });
        }
        // その他
        if (this.othersTotal > 0) {
          result.push({
            name: 'その他',
            quantity: 1,
            unit: '年',
            amount: this.othersTotal * 12,
            summary: null,
          });
        }
        // 消費税
        // 課税分合計
        let taxation =
          this.rentTotalTaxation + // 賃料(課税分)
          this.commonServiceFeesTotalTaxation + // 共益費(課税分)
          this.parkingFeesTotalTaxation + // 駐車料(課税分)
          this.landRentalFeesTotalTaxation + // 土地賃貸料(課税分)
          this.othersTotalTaxation; // その他(課税分)
        // 課税分のみの消費税（一時金は消費税なし）
        result.push({
          name: '消費税',
          quantity: this.getTax('消費税率'),
          unit: '％',
          amount: this.tax(taxation * 12),
          summary: null,
        });
        return result;
      },

      // 計算(支出の部)
      calcSpending: function(income) {
        const cp = this.investmentAnalytics.initialInvestment.capitalPlan; // 資金計画
        const sp = this.investmentAnalytics.singleYearPlan.spendingPlan; // 支出
        let result = [];
        // 固定資産税
        if (this.propertyTaxTotal > 0) {
          result.push({
            name: '固定資産税',
            items: [
              {
                name: '土地',
                quantity: 1,
                unit: '式',
                amount: Number(this.investmentAnalytics.initialInvestment.propertyTax[0]),
                summary: null,
              },
              {
                name: '建物',
                quantity: 1,
                unit: '式',
                amount: this.propertyTaxBuildingTotalAll,
                summary: null,
              },
              {
                name: '付属建物',
                quantity: 1,
                unit: '式',
                amount: Number(this.investmentAnalytics.initialInvestment.propertyTax[21]),
                summary: null,
              },
              {
                name: '償却資産',
                quantity: 1,
                unit: '式',
                amount: Number(this.investmentAnalytics.initialInvestment.propertyTax[22]),
                summary: null,
              },
            ],
            total: this.propertyTaxTotalAll,
          });
        }
        // 都市計画税
        if (this.cityPlanningTaxTotal > 0) {
          result.push({
            name: '都市計画税',
            items: [
              {
                name: '土地',
                quantity: 1,
                unit: '式',
                amount: Number(this.investmentAnalytics.initialInvestment.cityPlanningTax[0]),
                summary: null,
              },
              {
                name: '建物',
                quantity: 1,
                unit: '式',
                amount: this.cityPlanningTaxBuildingTotalAll,
                summary: null,
              },
              {
                name: '付属建物',
                quantity: 1,
                unit: '式',
                amount: Number(this.investmentAnalytics.initialInvestment.cityPlanningTax[21]),
                summary: null,
              },
            ],
            total: this.cityPlanningTaxTotalAll,
          });
        }
        // 借入返済金
        if (cp.loans > 0) {
          // ローン計算
          let loan = this.calcLoan({
            amount: cp.loans, // 借入金額
            period: Number(this.investmentAnalytics.initialInvestment.capitalPlan.repaymentPeriod), // 返済期間
            rates: this.investmentAnalytics.initialInvestment.capitalPlan.borrowingRate, // 金利
            applicationPeriod: Number(
              this.investmentAnalytics.initialInvestment.capitalPlan.borrowingRateApplicationYears
            ), // 適用期間
            method: this.investmentAnalytics.initialInvestment.capitalPlan.repaymentMethod, // 返済方法
          });

          //nullの時、０をセットする
          if (loan == null) loan = [{ amount: 0 }];
          result.push({
            name: '借入返済金',
            quantity: 1,
            unit: '式',
            amount: loan[0].amount,
            summary:
              cp.borrowingRateApplicationYears == null
                ? ''
                : '当初' + cp.borrowingRateApplicationYears + '年',
          });
          // グローバル変数に保持(収支計画書で参照)
          this.investmentAnalyticsTemp.loanCalculationResults = loan;
        } else {
          // グローバル変数に保持(収支計画書で参照)
          this.investmentAnalyticsTemp.loanCalculationResults = null;
        }
        // 償還金
        if (Number(cp.redemptionPeriod) > 0) {
          let items = [];
          // 保証金
          if (cp.deposit > 0) {
            items.push({
              name: '保証金',
              quantity: 1,
              unit: '式',
              amount: Math.floor(Number(cp.deposit) / Number(cp.redemptionPeriod)),
              summary: null,
            });
          }
          if (items.length > 0) {
            result.push({
              name: '償還金',
              items: items,
              total: items.reduce((a, v) => {
                return a + Number(v.amount);
              }, 0),
            });
          }
        }
        // 修繕費
        if (this.minorRepairsTotal > 0) {
          result.push({
            name: '修繕費',
            items: [
              {
                name: '軽微な修繕',
                quantity: 1,
                unit: '式',
                amount: this.minorRepairsTotal,
                summary: null,
              },
            ],
            total: this.minorRepairsTotal,
          });
        }
        // 消耗品費
        if (this.consumablesTotal > 0) {
          result.push({
            name: '消耗品費',
            quantity: 1,
            unit: '式',
            amount: this.consumablesTotal,
            summary: null,
          });
        }
        // ビルメンテナンス/概算入力時
        if (this.investmentAnalytics.singleYearPlan.spendingPlan.isDetail[2] == 0) {
          const value = Math.floor(
            Number(this.investmentAnalytics.singleYearPlan.spendingPlan.approximateValue[2])
          );
          if (value > 0) {
            result.push({
              name: 'ビルメンテナンス',
              quantity: 1,
              unit: '式',
              amount: value,
              summary: null,
            });
          }
        }
        // ビルメンテナンス/詳細入力時
        else {
          const names = [
            '定期清掃',
            'ガラス清掃',
            '貯水槽点検・清掃',
            '水質検査11項目',
            '雑排水槽点検・清掃',
            'EV保守点検',
            'EV法定検査代行',
            '自動ドア保守点検',
            '消防設備保守点検',
            '防火対象物点検',
            '建築設備定期検査',
            '防火設備定期検査',
            '特殊建築物定期調査',
            '除草',
            '除雪',
            '警備',
            'キュービクル保守点検',
            //  '宅配ロッカー保守管理費',
            'その他',
          ];
          let items = [];
          this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance.forEach(
            (v, i) => {
              if (v.amount > 0 && names[i] !== undefined) {
                let unit = '';
                if (v.amount) {
                  if (names[i] == '警備') {
                    unit = 'ヶ月';
                  } else if (names[i] == 'その他') {
                    unit = '式';
                  } else {
                    unit = '回/年';
                  }
                }
                items.push({
                  name: names[i],
                  quantity: v.quantity,
                  unit: unit,
                  amount: v.amount ? v.amount : null,
                  summary: null,
                });
              }
            }
          );
          // ビルメンテナンス
          if (items.length > 0) {
            result.push({
              name: 'ビルメンテナンス',
              items: items,
              total: items.reduce((a, v) => {
                return a + Number(v.amount);
              }, 0),
            });
          }
        }
        // 管理料
        if (sp.managementFeeType > 0 && sp.managementFeeValue > 0) {
          result.push({
            name: '管理料',
            quantity: sp.managementFeeType == 3 ? sp.managementFeeValue : 1,
            unit: sp.managementFeeType == 3 ? '％' : '式',
            amount: this.managementFee(income.total),
            summary: null,
          });
        }
        // 原状回復費
        if (sp.expenditureItems[6].quantity > 0 && sp.expenditureItems[6].unitPrice > 0) {
          result.push({
            name: '原状回復費',
            quantity: 1,
            unit: '式',
            amount:
              Math.floor(Number(sp.expenditureItems[6].quantity)) *
              Math.floor(Number(sp.expenditureItems[6].unitPrice)) *
              12,
            summary: null,
          });
        }
        // 保険料
        let items = [];
        if (this.investmentAnalytics.initialInvestment.incidentalExpenses[10] == 1) {
          // 火災保険
          let a =
            Number(this.investmentAnalytics.initialInvestment.insuranceFees[0]) +
            Number(this.investmentAnalytics.initialInvestment.insuranceFees[1]);
          if (a > 0) {
            items.push({
              name: '火災保険',
              quantity: 1,
              unit: '年分',
              amount: a / 5,
              summary: null,
            });
          }

          let b = Number(this.investmentAnalytics.initialInvestment.insuranceFees[2]);
          if (b > 0) {
            items.push({
              name: '施設賠償責任保険',
              quantity: 1,
              unit: '式',
              amount: b,
              summary: null,
            });
          }
          result.push({
            name: '保険料',
            items: items,
            // aは5年分の値なので1年分の値にするため5で割る
            total: a / 5 + b,
          });
        }
        // 賃借料
        {
          let items = [];
          if (
            this.investmentAnalytics.initialInvestment.incidentalExpenses[9] == 1 &&
            this.investmentAnalytics.initialInvestment.preCareerCommitment.isDetail == 1
          ) {
            // 賃料
            // [支払]賃借料/賃料
            const rent =
              Number(this.investmentAnalytics.initialInvestment.preCareerCommitment.monthlyRent) *
              12;
            if (rent > 0) {
              // 単年度収支計画/支出の部/賃借料
              items.push({
                name: '賃料',
                quantity: 1,
                unit: '式',
                amount: rent,
                summary: null,
              });
            }
            // 占用使用料
            const usageFee =
              Number(
                this.investmentAnalytics.initialInvestment.preCareerCommitment.monthlyOccupancyFee
              ) * 12;
            if (usageFee >= 0) {
              // 単年度収支計画/支出の部/占用使用料
              items.push({
                name: '占用使用料',
                quantity: 1,
                unit: '式',
                amount: usageFee,
                summary: null,
              });
            }
            // 賃借料
            if (items.length > 0) {
              result.push({
                name: '賃借料',
                items: items,
                total: items.reduce((a, v) => {
                  return a + Number(v.amount);
                }, 0),
              });
            }
          } else if (this.investmentAnalytics.initialInvestment.incidentalExpenses[9] == 0) {
            const paidRent =
              Number(this.investmentAnalytics.singleYearPlan.spendingPlan.monthlyRent) * 12;
            if (paidRent > 0) {
              // 単年度収支計画/支出の部/賃料(事業開始前負担がOFFの場合の支払地代の手入力値)
              items.push({
                name: '賃料',
                quantity: 1,
                unit: '式',
                amount: paidRent,
                summary: null,
              });
            }
            // 賃借料
            if (items.length > 0) {
              result.push({
                name: '賃借料',
                items: items,
                total: items.reduce((a, v) => {
                  return a + Number(v.amount);
                }, 0),
              });
            }
          }
        }
        // 利用料
        {
          let total =
            Math.floor(Number(sp.expenditureItems[7].quantity)) *
            Math.floor(Number(sp.expenditureItems[7].unitPrice)) *
            12;
          if (total > 0) {
            result.push({
              name: '利用料',
              items: [
                {
                  name: 'Wi-Fi利用料',
                  quantity: 1,
                  unit: '式',
                  amount: total,
                  summary: null,
                },
              ],
              total: total,
            });
          }
        }
        // 広告費
        {
          let total =
            Math.floor(Number(sp.expenditureItems[9].quantity)) *
            Math.floor(Number(sp.expenditureItems[9].unitPrice));
          if (total > 0) {
            result.push({
              name: '広告費',
              quantity: 1,
              unit: '式',
              amount: total,
              summary: null,
            });
          }
        }
        // その他
        {
          let items = [];
          const params = [
            // { name: '水道光熱費', index: 12 },
            { name: '諸雑費', index: 10, count: 1 },
            { name: 'その他', index: 8, count: 12 },
          ];
          for (let param of params) {
            let tmp = Math.floor(Number(sp.expenditureItems[param.index].unitPrice)) * param.count;
            if (tmp >= 0) {
              items.push({
                name: param.name,
                quantity: 1,
                unit: '式',
                amount: tmp,
                summary: null,
              });
            }
          }
          // 予備費
          {
            // ここまでの支出合計
            let spendingTotal =
              result.reduce((a, v) => {
                return a + (isNaN(v.amount) ? v.total : v.amount);
              }, 0) +
              items.reduce((a, v) => {
                return a + (isNaN(v.amount) ? v.total : v.amount);
              }, 0);

            // 予備費率
            let rate =
              Math.floor(
                Number(this.investmentAnalytics.singleYearPlan.spendingPlan.reserveRate) * 10
              ) / 10;
            if (spendingTotal > 0 && rate > 0) {
              items.push({
                name: '予備費',
                quantity: 1,
                unit: '式',
                amount: Math.floor((spendingTotal * rate) / 100),
                summary: null,
              });
            }
          }

          // その他
          if (items.length > 0) {
            result.push({
              name: 'その他',
              items: items,
              total: items.reduce((a, v) => {
                return a + Number(v.amount);
              }, 0),
            });
          }
        }
        // 消費税
        {
          let items = [];
          // 維持修繕費分
          // ここまでの支出合計 ※消費税が未計算なのは以下の項目のみ
          const applicableItems = ['修繕費', '消耗品費', 'ビルメンテナンス'];
          let spendingTotal = result.reduce((a, v) => {
            if (applicableItems.some(item => item == v.name)) {
              if (isNaN(v.amount)) {
                return a + Number(v.total);
              } else {
                return a + Number(v.amount);
              }
            } else {
              return a;
            }
          }, 0);
          if (spendingTotal > 0) {
            items.push({
              name: '維持修繕費分',
              quantity: this.getTax('消費税率'),
              unit: '％',
              amount: this.tax(spendingTotal),
              summary: null,
            });
          }
          // 預かり消費税
          let tax = income.items.find(item => item.name == '消費税');
          if (tax != null && tax != undefined) {
            items.push({
              name: '預かり消費税',
              quantity: 1,
              unit: '式',
              amount: tax.amount,
              summary: null,
            });
          }
          // 消費税
          if (items.length > 0) {
            result.push({
              name: '消費税',
              items: items,
              total: items.reduce((a, v) => {
                return a + Number(v.amount);
              }, 0),
            });
          }
        }
        return result;
      },
      // 計算(投資分析指標)
      // 引数：収入合計、支出合計
      calcInvestmentAnalysisIndex: function(incomeTotal, spendingTotal) {
        // 返済金額を除く
        // if (this.investmentAnalyticsTemp.loanCalculationResults != null) {
        //   spendingTotal -= this.investmentAnalyticsTemp.loanCalculationResults[0].amount;
        // }
        // 物件価格
        this.result.propertyPrice = Math.floor(
          Number(this.investmentAnalytics.initialInvestment.tradingPrice[0])
        ); // 土地本体価格
        this.result.propertyPrice += this.sumTax(this.tradingPriceBuilding); // 売買代金 建物合計
        this.result.propertyPrice += this.sumTax(this.newConstructionCostsTotal); // 新築工事費 合計
        return [
          // 融資比率(LTV)＝借入金÷総事業費×100（単位：％）
          {
            name: ['融資比率', '(LTV)'],
            amount:
              (this.investmentAnalytics.initialInvestment.capitalPlan.loans * 100) /
              this.totalProjectCost,
            unit: '％',
          },
          // 表面利回り(グロス利回り)＝収入合計÷物件価格×100
          {
            name: ['表面利回り', '(グロス利回り)'],
            amount: (incomeTotal * 100) / this.result.propertyPrice,
            unit: '％',
          },
          // 純収益(NOI)＝収入合計－支出合計
          { name: ['純収益', '(NOI)'], amount: incomeTotal - spendingTotal, unit: '' },
          // 純利回り(FCR)＝収入合計－支出合計÷総事業費×100
          {
            name: ['純利回り', '(FCR)'],
            amount: ((incomeTotal - spendingTotal) * 100) / this.totalProjectCost,
            unit: '％',
          },
        ];
      },
    },
  };
</script>
