<template>
  <div class="item-list-root" :style="{ height: height + 'px' }">
    <div
      class="item-list-record"
      v-for="item in items"
      :key="item[keyProperty]"
      @click="clickItem(item)"
    >
      <div :class="[item[keyProperty] === selectedItem[keyProperty] ? 'isSelected' : null]">
        {{ item.name }}
      </div>
    </div>
    <div
      class="item-list-record"
      v-for="(item, i) in items.length === 0 ? new Array(20) : []"
      :key="i"
    >
      <b-skeleton></b-skeleton>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from '@vue/composition-api';

  export default defineComponent({
    props: {
      items: Array,
      selectedItem: Object,
      height: Number,
      keyProperty: {
        // リスト内でユニークなプロパティ
        type: String,
        default: 'id',
      },
    },
    setup(props, context) {
      const clickItem = val => {
        context.emit('clickItem', val);
      };
      return { clickItem };
    },
  });
</script>
<style scoped>
  .item-list-root {
    overflow-y: scroll;
  }
  .item-list-record {
    border-top-style: solid;
    border-top-color: #eee;
    text-align: center;
    font-size: 13px;
    user-select: none;
  }
  .item-list-record:hover {
    background-color: #aaa;
    cursor: pointer;
  }
  .item-list-record > .isSelected {
    background-color: #555;
    color: white;
  }
</style>
