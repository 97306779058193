<!--
作成者：本間
作成日：2021.02.15
画面名：投資分析/入力
機能：初期投資/工事計画/新築工事
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="NewBuildingConstruction">
    <div v-for="id in investmentAnalytics.newBuildingCount" v-bind:key="`newBuilding-${id}`">
      <!-- 概算入力 -->
      <b-row>
        <b-col class="title bold">
          {{ id == 1 ? '新築工事' : '' }}
        </b-col>
        <b-col class="title">新築建物{{ id }}</b-col>
        <b-col class="component">
          <b-row>
            <b-col class="component">
              <allyText
                type="number"
                class="number"
                :separation="true"
                halfWidth
                v-model="
                  investmentAnalytics.initialInvestment.constructionPlan.newConstructionCost[id - 1]
                "
                roundType="down"
              />
            </b-col>
            <b-col cols="2" class="unit">円</b-col>
          </b-row>
        </b-col>
        <b-col cols="1" class="title">
          <b-button variant="ally" v-b-toggle="`tabInitialInvestment51_${id}`">
            <i class="when-open ti-angle-double-up" />
            <i class="when-closed ti-angle-double-down" />
          </b-button>
        </b-col>
      </b-row>
      <!-- 詳細入力 -->
      <b-row>
        <b-col>
          <b-collapse :id="`tabInitialInvestment51_${id}`" role="tabpanel">
            <b-card-body>
              <b-container>
                <b-row>
                  <b-col cols="2" class="title bold">
                    構造
                  </b-col>
                  <b-col cols="3">
                    <b-form-input
                      disabled
                      :value="
                        investmentAnalytics.initialInvestment.propertyDescription[10 + id].structure
                      "
                    />
                  </b-col>
                  <b-col cols="1" class="unit" />
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold">
                    種類
                  </b-col>
                  <b-col cols="3" class=" ally-select">
                    <b-form-input
                      v-if="
                        investmentAnalytics.initialInvestment.propertyDescription[10 + id].kind ==
                          '共同住宅'
                      "
                      disabled
                      value="住宅（共同住宅）"
                    />
                    <b-form-input
                      v-else
                      disabled
                      :value="
                        investmentAnalytics.initialInvestment.propertyDescription[10 + id].kind
                      "
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold">
                    延床面積
                  </b-col>
                  <b-col cols="3">
                    <allyText
                      class="number"
                      type="number"
                      mode="View"
                      :separation="true"
                      :value="
                        investmentAnalytics.initialInvestment.propertyDescription[10 + id].area
                      "
                      :floatLength="2"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="1" class="unit">㎡</b-col>
                  <b-col cols="3">
                    <b-form-input
                      disabled
                      class="currency"
                      :value="
                        tsubo(
                          investmentAnalytics.initialInvestment.propertyDescription[10 + id].area
                        )
                      "
                    />
                  </b-col>
                  <b-col cols="1" class="unit">坪</b-col>
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold">
                    標準 工事単価
                  </b-col>
                  <b-col cols="3" class="component">
                    <b-form-input
                      disabled
                      class="currency"
                      :value="
                        getUnitPrice(
                          '新築工事',
                          investmentAnalytics.initialInvestment.propertyDescription[10 + id]
                            .structure +
                            '/' +
                            investmentAnalytics.initialInvestment.propertyDescription[10 + id].kind
                        ) | currency
                      "
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                  <b-col cols="3" class="title-guideline">
                    ※目安
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold">
                    工事費
                  </b-col>
                  <b-col cols="3" class="component">
                    <allyText
                      type="number"
                      class="number"
                      :separation="true"
                      halfWidth
                      v-model="
                        investmentAnalytics.initialInvestment.constructionPlan.newConstructionCost[
                          id - 1
                        ]
                      "
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                  <b-col cols="3" class="component">
                    <b-form-input
                      disabled
                      class="currency"
                      :value="
                        (investmentAnalytics.initialInvestment.propertyDescription[10 + id].area *
                          getUnitPrice(
                            '新築工事',
                            investmentAnalytics.initialInvestment.propertyDescription[10 + id]
                              .structure +
                              '/' +
                              investmentAnalytics.initialInvestment.propertyDescription[10 + id]
                                .kind
                          ))
                          | currency
                      "
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                  <button
                    class="btn btn-ally mr-1"
                    style="width: 60px; white-space: nowrap; padding-left: 13px; "
                    @click="
                      changeGuideline(
                        id - 1,
                        investmentAnalytics.initialInvestment.propertyDescription[10 + id].area *
                          getUnitPrice(
                            '新築工事',
                            investmentAnalytics.initialInvestment.propertyDescription[10 + id]
                              .structure +
                              '/' +
                              investmentAnalytics.initialInvestment.propertyDescription[10 + id]
                                .kind
                          )
                      )
                    "
                  >
                    反映
                  </button>
                </b-row>
              </b-container>
            </b-card-body>
          </b-collapse>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  export default {
    methods: {
      //固定資産税・都市計画税の反映を行う
      changeGuideline: function(id, sum) {
        Vue.set(
          this.investmentAnalytics.initialInvestment.constructionPlan.newConstructionCost,
          id,
          sum
        );
      },
    },
  };
</script>
