var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"PreBusinessBurden"}},[_c('b-card',{staticClass:"category",attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabPreBusinessBurden",modifiers:{"tabPreBusinessBurden":true}}],attrs:{"id":"btnPreBusinessBurden","variant":"link"}},[_vm._v(" 事業開始前負担 ")])],1),_c('b-collapse',{attrs:{"id":"tabPreBusinessBurden","role":"tabpanel"}},[_c('b-card-body',[_c('b-tabs',{attrs:{"fill":"","pills":""},model:{value:(_vm.investmentAnalytics.initialInvestment.preCareerCommitment.isDetail),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.preCareerCommitment, "isDetail", $$v)},expression:"investmentAnalytics.initialInvestment.preCareerCommitment.isDetail"}},[_c('b-tab',{attrs:{"title":"概算入力"}},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v("総額")]),_c('b-col',{staticClass:"title",attrs:{"cols":"2"}},[_vm._v(" (税抜) ")]),_c('b-col',[_c('b-row',[_c('b-col',[_c('allyText',{staticClass:"number",attrs:{"name":"approximateValue","type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                          _vm.investmentAnalytics.initialInvestment.preCareerCommitment
                            .approximateValue
                        ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.preCareerCommitment
                            , "approximateValue", $$v)},expression:"\n                          investmentAnalytics.initialInvestment.preCareerCommitment\n                            .approximateValue\n                        "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}}),_c('b-col',{staticClass:"title",attrs:{"cols":"2"}},[_vm._v(" (税込) ")]),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.sumTax(
                            Math.floor(
                              _vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                .approximateValue
                            )
                          ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col')],1)],1)],1),_c('b-tab',{attrs:{"title":"詳細入力"}},[_c('b-card-group',{attrs:{"columns":""}},[_c('b-card',{staticClass:"category",attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabPreBusinessBurden0",modifiers:{"tabPreBusinessBurden0":true}}],attrs:{"variant":"link"}},[_vm._v(" 事業開始前期間 ")])],1),_c('b-collapse',{attrs:{"id":"tabPreBusinessBurden0","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold "},[_vm._v("工事期間")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"name":"duration0","type":"number","separation":true,"roundType":"down"},model:{value:(
                                  _vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .duration[0]
                                ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .duration, 0, $$v)},expression:"\n                                  investmentAnalytics.initialInvestment.preCareerCommitment\n                                    .duration[0]\n                                "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("ヶ月")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title bold "},[_vm._v("募集/FR期間")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"name":"duration1","type":"number","separation":true,"roundType":"down"},model:{value:(
                                  _vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .duration[1]
                                ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .duration, 1, $$v)},expression:"\n                                  investmentAnalytics.initialInvestment.preCareerCommitment\n                                    .duration[1]\n                                "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("ヶ月")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title bold "},[_vm._v("立退き期間")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"name":"duration2","type":"number","separation":true,"roundType":"down"},model:{value:(
                                  _vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .duration[2]
                                ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .duration, 2, $$v)},expression:"\n                                  investmentAnalytics.initialInvestment.preCareerCommitment\n                                    .duration[2]\n                                "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("ヶ月")])],1)],1),_c('b-col')],1)],1)],1)],1)],1),_c('b-card',{staticClass:"category",attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabPreBusinessBurden1",modifiers:{"tabPreBusinessBurden1":true}}],attrs:{"variant":"link"}},[_vm._v(" 賃借料 ")])],1),_c('b-collapse',{attrs:{"id":"tabPreBusinessBurden1","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("月額賃借料")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"name":"monthlyRent","type":"number","separation":true,"roundType":"down"},model:{value:(
                                  _vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .monthlyRent
                                ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    , "monthlyRent", $$v)},expression:"\n                                  investmentAnalytics.initialInvestment.preCareerCommitment\n                                    .monthlyRent\n                                "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col')],1),_c('b-row',{staticStyle:{}},[_c('b-col',{staticClass:"title  bold"},[_vm._v("負担率")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"name":"affordability","type":"number","separation":true,"floatLength":2,"roundType":"down"},model:{value:(
                                  _vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .affordability
                                ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    , "affordability", $$v)},expression:"\n                                  investmentAnalytics.initialInvestment.preCareerCommitment\n                                    .affordability\n                                "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("％")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title  bold"},[_vm._v("事業開始前賃借料")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.preBusinessRent)}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title bold "},[_vm._v("工事期間")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .duration[0])}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("ヶ月")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title bold "},[_vm._v("募集/FR期間")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .duration[1])}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("ヶ月")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title bold "},[_vm._v("立退き期間")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .duration[2])}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("ヶ月")])],1)],1),_c('b-col')],1)],1)],1)],1)],1),_c('b-card',{staticClass:"category",attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabPreBusinessBurden2",modifiers:{"tabPreBusinessBurden2":true}}],attrs:{"variant":"link","id":"btnPreBusinessBurden2"}},[_vm._v(" 占用使用料 ")])],1),_c('b-collapse',{attrs:{"id":"tabPreBusinessBurden2","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title  bold"},[_vm._v("月額占用料")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"name":"monthlyOccupancyFee","type":"number","separation":true,"roundType":"down"},model:{value:(
                                  _vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .monthlyOccupancyFee
                                ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    , "monthlyOccupancyFee", $$v)},expression:"\n                                  investmentAnalytics.initialInvestment.preCareerCommitment\n                                    .monthlyOccupancyFee\n                                "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"2"}},[_vm._v("円")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title  bold"},[_vm._v("事業開始前期間")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"name":"duration3","type":"number","mode":"View","separation":true,"value":_vm.preBusinessPeriod}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"2"}},[_vm._v("ヶ月")])],1)],1),_c('b-col')],1)],1)],1)],1)],1),_c('b-card',{staticClass:"category",attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabPreBusinessBurden3",modifiers:{"tabPreBusinessBurden3":true}}],attrs:{"variant":"link","id":"btnPreBusinessBurden3"}},[_vm._v(" 支払利息 ")])],1),_c('b-collapse',{attrs:{"id":"tabPreBusinessBurden3","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title  bold",staticStyle:{"margin-top":"45px"}},[_vm._v("借入額")]),_c('b-col',[_c('b-row',[_c('b-col',{staticStyle:{"height":"45px"},attrs:{"cols":"12"}}),_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"name":"borrowedAmount","type":"number","separation":true,"roundType":"down"},model:{value:(
                                  _vm.investmentAnalytics.initialInvestment.capitalPlan.borrowedAmount
                                ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.capitalPlan, "borrowedAmount", $$v)},expression:"\n                                  investmentAnalytics.initialInvestment.capitalPlan.borrowedAmount\n                                "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',[_c('b-row',{staticStyle:{"white-space":"nowrap"}},[_c('b-col',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topleft",value:(
                                '登録免許税までの事業費合計に' +
                                  this.magnification +
                                  '倍した金額を表示しています。「初期投資＞資金計画＞借入金」の借入金とは計算方法が異なります。'
                              ),expression:"\n                                '登録免許税までの事業費合計に' +\n                                  this.magnification +\n                                  '倍した金額を表示しています。「初期投資＞資金計画＞借入金」の借入金とは計算方法が異なります。'\n                              ",modifiers:{"hover":true,"topleft":true}}],staticClass:"guideline pl-0",staticStyle:{"cursor":"help"}},[_vm._v(" ※目安 "),_c('u',[_vm._v("(?)")])]),_c('button',{staticClass:"btn btn-ally btn-reflection mr-1 mt-auto md-0",on:{"click":function($event){_vm.changeGuideline(
                                  'borrowedAmount',
                                  Math.floor(_vm.totalProjectCostExclude * _vm.magnification)
                                )}}},[_vm._v(" 反映 ")])],1),_c('b-row',[_c('b-col',{staticClass:"component pr-0 pl-auto",attrs:{"cols":"10"}},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(Math.floor(_vm.totalProjectCostExclude * _vm.magnification))}})],1),_c('b-col',{staticClass:"unit pl-1",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title  bold"},[_vm._v("利率")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"name":"borrowingRate","type":"number","separation":true,"floatLength":2,"roundType":"down"},model:{value:(
                                  _vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .borrowingRate
                                ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    , "borrowingRate", $$v)},expression:"\n                                  investmentAnalytics.initialInvestment.preCareerCommitment\n                                    .borrowingRate\n                                "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("％")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("事業開始前期間")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"name":"duration4","type":"number","mode":"View","separation":true,"value":_vm.preBusinessPeriod}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("ヶ月")])],1)],1),_c('b-col',{staticClass:"title-center",staticStyle:{"align-items":"end"}})],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("事業開始前利息")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[(
                                  !_vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .isChanged
                                )?_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.interestExpenseTotal2)}}):_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true},model:{value:(
                                  _vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    .interestBeforeStartingBusiness
                                ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.preCareerCommitment
                                    , "interestBeforeStartingBusiness", $$v)},expression:"\n                                  investmentAnalytics.initialInvestment.preCareerCommitment\n                                    .interestBeforeStartingBusiness\n                                "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }