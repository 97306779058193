<template>
  <div class="root">
    <div class="chart-canvas-container">
      <div :style="{ width: `${width}px` }" class="chart-canvas">
        <canvas ref="chart"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'chart',
    props: {
      chartValue: {
        type: Array,
        required: true,
      },
      width: Number,
      height: Number,
    },
    methods: {
      loadChart: function() {
        this.$refs.chart.width = this.width;
        this.$refs.chart.height = this.height;

        //set canvas info
        const width = this.$refs.chart.clientWidth;
        const height = this.$refs.chart.clientHeight;
        const ctx = this.$refs.chart.getContext('2d');
        const innerPaddingX = width * 0.15;
        const innerPaddingY = height * 0.15;
        const innerWidth = width - innerPaddingX;
        const innerHeight = height - innerPaddingY;
        const SCALE_X = innerWidth / 200;
        const SCALE_Y = innerHeight / 400;
        const OFFSET_X = innerPaddingX / 2;
        const OFFSET_Y = height / 2;

        //outside rect
        ctx.fillStyle = '#ddd';
        ctx.fillRect(0, 0, width, height);
        ctx.save();

        //inside rect
        ctx.fillStyle = 'white';
        ctx.fillRect(innerPaddingX / 2, innerPaddingY / 2, innerWidth, innerHeight);
        ctx.save();

        //vertical line
        ctx.strokeStyle = '#444';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(width / 2, (height - innerHeight) / 2);
        ctx.lineTo(width / 2, height - (height - innerHeight) / 2);
        ctx.stroke();
        ctx.save();

        //horizonal line
        ctx.beginPath();
        ctx.moveTo((width - innerWidth) / 2, height / 2);
        ctx.lineTo(width - (width - innerWidth) / 2, height / 2);
        ctx.stroke();
        ctx.save();

        //収益性メモリ
        for(let i = height - OFFSET_X, j = 0; j <= 20 ; j++, i -= ((height - OFFSET_X * 2) / 20)){
          if(j == 10){
            continue;
          }
          if(j % 5 == 0){
            ctx.beginPath();
            ctx.moveTo((width - innerWidth) / 0.32, i);
            ctx.lineTo(width - (width - innerWidth) / 0.32, i);
            ctx.stroke();
            ctx.save();
          }else{
            ctx.beginPath();
            ctx.moveTo((width - innerWidth) / 0.31, i);
            ctx.lineTo(width - (width - innerWidth) / 0.31, i);
            ctx.stroke();
            ctx.save();
          }
        }

        //set area(A B C D) font
        let fontSize = (height / 7).toFixed(2);
        ctx.font = 'bold ' + fontSize + 'px roboto';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#ccc';

        const textY = innerHeight / 4;
        const textX = innerWidth / 4;

        //write A B C D
        ctx.fillText('A', textX * 2.8 + innerPaddingX / 2, textY + innerPaddingY / 2);

        ctx.fillText('B', textX * 2.8 + innerPaddingX / 2, textY * 3 + innerPaddingY / 2);

        ctx.fillText('C', textX * 1.15, textY + innerPaddingY / 2);

        ctx.fillText('D', textX * 1.15, textY * 3 + innerPaddingY / 2);

        ctx.save();

        //set inside text format
        ctx.fillStyle = 'black';
        fontSize = (height / 35).toFixed(2);
        ctx.font = fontSize + 'px roboto';

        //write inside text
        ctx.fillText(
          '+20%',
          width / 1.85,
          OFFSET_X / 1.0
        );
        ctx.fillText(
          '-20%',
          width / 1.85,
          (height - OFFSET_X) / 1.0
        );
        ctx.save();
        /*//割引率として入力した数字をグラフ内表示するようにした場合（表示位置は右下）
        if (Number.isFinite(this.chartValue[this.chartValue.length - 1].discountRate)) {
          ctx.font = 'bold ' + fontSize + 'px roboto';
          ctx.fillText(
            this.chartValue[this.chartValue.length - 1].discountRate,
            width / 1.15,
            (height - OFFSET_X) / 0.97
          );
          ctx.save();
        }
        fontSize = (height / 47).toFixed(2);
        ctx.font = fontSize + 'px roboto';
        
        ctx.fillText(
          '割引率',
          width / 1.08,
          (height - OFFSET_X) / 0.97
        );
        ctx.save();
        */
        //set outside text format
        ctx.fillStyle = 'black';

        fontSize = (height / 30).toFixed(2);
        ctx.font = 'bold ' + fontSize + 'px roboto';

        //write outside text
        ctx.fillText(
          '収益性(高)',
          Math.round((width - ctx.measureText('収益性(高)').width) / 2),
          height * 0.04
        );

        ctx.fillText(
          '収益性(低)',
          Math.round((width - ctx.measureText('収益性(低)').width) / 2),
          height * 0.97
        );

        const yindex = textY * 2 - fontSize;

        ctx.fillText('  資', 2, yindex + fontSize * 1);
        ctx.fillText('  産', 2, yindex + fontSize * 2);
        ctx.fillText('  効', 2, yindex + fontSize * 3);
        ctx.fillText('  率', 2, yindex + fontSize * 4);
        ctx.save();
        ctx.font = 'bold ' + (fontSize - 2.5) + 'px roboto';
        ctx.fillText(' (低)', 3, yindex + fontSize * 5, 220);

        const tempX = width - ctx.measureText('(高)').width + 3;
        ctx.font = 'bold ' + fontSize + 'px roboto';
        ctx.fillText(' 資', tempX-11, yindex + fontSize * 1);
        ctx.fillText(' 産', tempX-11, yindex + fontSize * 2);
        ctx.fillText(' 効', tempX-11, yindex + fontSize * 3);
        ctx.fillText(' 率', tempX-11, yindex + fontSize * 4);
        ctx.save();
        ctx.font = 'bold ' + (fontSize - 2.5) + 'px roboto';
        ctx.fillText('(高)', tempX-10.2, yindex + fontSize * 5, 195);

        ctx.save();
        ctx.fillStyle = 'black';

        //set chartdata text format
        fontSize = (height / 20).toFixed(2);
        ctx.font = 'bold ' + fontSize + 'px roboto';

        const POINT_WIDTH = this.width * 0.04;
      
        //write point
        this.chartValue.forEach((val, index) => {
          // プロパティチェック
          if (!('x' in val && 'y' in val)) {
            return;
          }

          // 不正な値のチェック
          if (!Number.isFinite(val.x) || !Number.isFinite(val.y)) {
            return;
          }

          const x = val.x * SCALE_X + OFFSET_X;
          const y = val.y * SCALE_Y * -1 + OFFSET_Y;
          ctx.fillStyle = this.getRandomRgb(index);

          ctx.fillRect(x - POINT_WIDTH / 2, y - POINT_WIDTH / 2, POINT_WIDTH, POINT_WIDTH);

          ctx.fillStyle = 'black';

          ctx.fillText(index + 1, x - POINT_WIDTH / 2.4, y - POINT_WIDTH);

          ctx.save();
        });
      },
      getRandomRgb: function(index) {
        //var num = Math.round(0xffffff * Math.random());
        var num = Math.round(0xffffff * ((index + 1) * 0.01));
        var r = num >> 16;
        var g = (num >> 8) & 255;
        var b = num & 255;
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
      },
    },
    mounted() {
      this.loadChart();
    },
  };
</script>
<style scoped>
  .root {
    padding-top: 4px;
  }
  .chart-canvas {
    margin: 0 auto;
    border: solid 1px #ddd;
    box-shadow: 6px 6px 6px #ddd;
  }
</style>
