// @ts-check

import { Vec } from '../../../util/math/vector';
import { PathData } from '../../../util/path';
import { doorBase1 } from './parts';
import { doubleSlidingSash2, doubleSlidingSash3, doubleSlidingSash4 } from './window';

/**
 * @param {number} width
 * @param {number} height
 */

export function entrancePullDoor1(width, height) {
  const w1 = width * 0.47;
  const w2 = width * 0.53;
  const w3 = width * 0.95;
  const h1 = height / 2;

  const base = new PathData({
    d: `M${0},${0} L${0},${h1} L${w1},${h1} L${w1},${0} Z M${w2},${0} L${w3},0 L${w3},${h1} L${width},${h1} L${width},${-h1} L${w2},${-h1} Z`,
    stroke: 'black',
    fill: 'white',
    strokeWidth: 4,
  });

  const h2 = height / 4;

  const body = new PathData({
    d: `M${w1},${h2} L${w3},${h2}`,
    stroke: 'black',
    fill: 'white',
    strokeDasharray: '12 11',
    strokeWidth: 3,
  });

  return [base, body];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function entrancePullDoor2(width, height) {
  return doubleSlidingSash2(width, height);
}

/**
 * @param {number} width
 * @param {number} height
 */
export function entrancePullDoor3(width, height) {
  return doubleSlidingSash3(width, height);
}

/**
 * @param {number} width
 * @param {number} height
 */
export function entrancePullDoor4(width, height) {
  return doubleSlidingSash4(width, height);
}

/**
 * @param {number} width
 * @param {number} height
 */
export function doubleSlidingDoor1(width, height) {
  const base = doorBase1(width, height);
  base.stroke = 'transparent';

  const w1 = width / 2;
  const h1 = height / 2;

  const body = new PathData({
    d: `M${0},${h1} L${0},${-h1} L${w1},${-h1} M${w1},${0} L${w1},${h1} L${width},${h1} L${width},${0} Z`,

    fill: 'transparent',
    strokeWidth: 4,
  });

  const deco = new PathData({
    d: `M${w1},${-h1} L${width},${-h1}`,
    strokeDasharray: '12 11',
    strokeWidth: 4,
  });

  return [base, body, deco];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function doubleSlidingDoor2(width, height) {
  const base = doorBase1(width, height);
  const w1 = (width * 6) / 11;
  const w2 = (width * 5) / 11;
  const h1 = height / 3;

  const body = new PathData({
    d: `M0 ${-h1 / 2} L${w1} ${-h1 / 2} M${w2} ${h1 / 2} L${width} ${h1 / 2}`,
  });
  const h2 = height / 2;
  const deco = new PathData({
    d: `M${0},${h2} L${0},${-h2}  M${width},${h2} L${width},${-h2}`,
    strokeWidth: 3,
  });

  const center = Math.round(width / 2);

  const deco2 = new PathData({
    d: PathData.polygon([new Vec(center, -height / 2), new Vec(center, height / 2)]),
    strokeWidth: 5,
  });
  return [base, body, deco, deco2];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function doubleSlidingDoor3(width, height) {
  const base = doorBase1(width, height);
  const w1 = width * 0.36;
  const w2 = width * 0.3;
  const w3 = width * 0.69;
  const w4 = width * 0.63;
  const h1 = height / 2.5 / 2;
  const body = new PathData({
    d: `M0 ${-h1} L${w1} ${-h1} M${w2} ${0} L${w3} ${0} M${w4} ${h1} L${width} ${h1}`,
  });
  const h2 = height / 2;
  const deco = new PathData({
    d: `M${0},${h2} L${0},${-h2}  M${width},${h2} L${width},${-h2}`,
    strokeWidth: 3,
  });
  const w5 = width / 3;
  const w6 = (width * 2) / 3;
  const h3 = height / 2;

  const deco2 = new PathData({
    d: `M${w5},${-h3} L${w5},${h3} M${w6},${-h3} L${w6},${h3}`,
    strokeWidth: 5,
  });

  return [base, body, deco, deco2];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function doubleSlidingDoor4(width, height) {
  const base = doorBase1(width, height);
  base.stroke = 'transparent';

  const w1 = width * 0.28;
  const w2 = width * 0.22;
  const w3 = width * 0.78;
  const w4 = width * 0.72;
  const h1 = height / 2.5 / 2;
  const body = new PathData({
    d: `M0 ${-h1} L${w1} ${-h1} M${w2} ${h1} L${w3} ${h1} M${w4} ${-h1} L${width} ${-h1}`,
    strokeWidth: 3,
  });

  const h2 = height / 2;

  const deco = new PathData({
    d: `M${0},${h2} L${0},${-h2}  M${width},${h2} L${width},${-h2}`,
    strokeWidth: 3,
  });

  const w5 = width / 4;
  const w6 = width / 2;
  const w7 = (width * 3) / 4;
  const h3 = height / 2;
  const deco2 = new PathData({
    d: `M${w5},${-h3} L${w5},${h3} M${w6},${-h3} L${w6},${h3} M${w7},${-h3} L${w7},${h3}`,
    strokeWidth: 5,
  });

  return [base, body, deco, deco2];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function doubleSlidingDoorSingle2(width, height) {
  const base = doorBase1(width, height);
  base.stroke = 'transparent';

  const w1 = width / 2;
  const w2 = width * 1.5;
  const h1 = height / 2;
  const h2 = height / 4;

  const body = new PathData({
    d: `M${0},${h1} L${0},${-h1} L${w1},${-h1} M${w1},${-h2} L${width},${-h2} M${width},${0} L${width},${h1} L${w2},${h1} L${w2},${0} Z`,
    fill: 'transparent',
    strokeWidth: 4,
  });

  const deco1 = new PathData({
    d: `M${w1},${-h1} L${w2},${-h1}  M${width},${-h2} L${w2},${-h2}`,
    stroke: 'gray',
    strokeWidth: 4,
    strokeDasharray: '12 11',
  });

  const deco2 = new PathData({
    d: `M${width},${-h1} L${width},${0} L${w2},${0} L${w2},${-h1} Z`,
    stroke: 'transparent',
  });

  return [base, body, deco1, deco2];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function doubleSlidingDoorSingle3(width, height) {
  const base = doorBase1(width, height);
  base.stroke = 'transparent';

  const w1 = width / 3;
  const w2 = (width * 2) / 3;
  const w3 = (width * 4) / 3;
  const h1 = height / 2;
  const h2 = height / 3;
  const h3 = height / 6;

  const p1 = new PathData({
    d: `M${0},${h1} L${0},${-h1} L${w1},${-h1} M${w1},${-h2} L${w2},${-h2} M${w2},${-h3} L${width},${-h3} M${width},${0} L${width},${h1} L${w3},${h1} L${w3},${0} Z`,
    fill: 'transparent',
    strokeWidth: 4,
  });

  const p2 = new PathData({
    d: `M${w1},${-h1} L${w3},${-h1} M${w2},${-h2} L${w3},${-h2} M${width},${-h3} L${w3},${-h3}`,
    strokeDasharray: '12 11',
    strokeWidth: 2,
  });

  const p3 = new PathData({
    d: `M${width},${-h1} L${width},${0} L${w3},${0} L${w3},${-h1} Z`,
    stroke: 'transparent',
  });

  return [base, p1, p2, p3];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function doubleSlidingDoorRetract(width, height) {
  const base = doorBase1(width, height);
  base.stroke = 'transparent';

  const w1 = width / 2;
  const w2 = width * 2;
  const h1 = height / 2;
  const h2 = height / 6;

  const p1 = new PathData({
    d: `M0,${h1} L0,${-h1}  M${w1},${h1} L${w1},${-h1}  M${width},${h2} L${w2},${h2}  M0,0 L${width},0`,
    fill: 'transparent',
    strokeWidth: 4,
  });

  const p2 = new PathData({
    d: `M${w2},0 L${width},0`,
    stroke: 'white',
    strokeDasharray: '12 11',
    strokeWidth: 4,
  });

  const p3 = new PathData({
    d: `M${width},${-h2} L${width},${h2} L${w2},${h2} L${w2},${-h2} Z`,
    stroke: 'transparent',
    fill: 'black',
  });

  return [base, p1, p2, p3];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function doubleSlidingDoorDouble(width, height) {
  const base = doorBase1(width, height);
  base.stroke = 'transparent';

  const w1 = -width / 2;
  const w2 = width / 2;
  const w3 = width * 1.5;
  const h1 = height / 2;
  const h2 = height / 6;

  const p1 = new PathData({
    d: `M${w1},${h2} L0,${h2} L0,${h1} L${w1},${h1}  M0,${-h1} L${width},${-h1}  M${w3},${h2} L${width},${h2} L${width},${h1} L${w3},${h1}  M${w2},${-h1 -
      h2} L${w2},${-h1 + h2}`,
    strokeWidth: 4,
  });

  const p2 = new PathData({
    d: `M${w1},${-h1} L${0},${-h1}  M${width},${-h1} L${w3},${-h1}`,
    stroke: 'white',
    strokeDasharray: '12 11',
    strokeWidth: 4,
  });

  const p3 = new PathData({
    d: `M${w1},${-h1 - h2} L${0},${-h1 - h2} L${0},${-h1 + h2} L${w1},${-h1 + h2} Z   M${w3},${-h1 -
      h2} L${width},${-h1 - h2} L${width},${-h1 + h2} L${w3},${-h1 + h2} Z`,
    stroke: 'transparent',
    fill: 'black',
  });

  return [base, p1, p2, p3];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function shutterDoor(width, height) {
  const base = doorBase1(width, height);
  base.stroke = 'black';

  const h1 = height / 2;

  const p1 = new PathData({
    d: `M0,${-h1 - 3} L${width},${-h1 - 3}`,
    strokeDasharray: '6 6',
    stroke: 'black',
    strokeWidth: 6,
  });

  return [base, p1];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function opening(width, height) {
  const base = doorBase1(width, height);
  base.stroke = 'transparent';

  const h1 = height / 2;

  const p1 = new PathData({
    d: `M0,${-h1} L${width},${-h1} M0,${h1} L${width},${h1}`,
    strokeWidth: 5,
  });

  return [base, p1];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function openingDownWall(width, height) {
  const base = doorBase1(width, height);
  base.stroke = 'transparent';

  const h1 = height / 2;

  const p1 = new PathData({
    d: `M0,${-h1} L${width},${-h1} M0,${h1} L${width},${h1}`,
    strokeDasharray: '6 6',
    strokeWidth: 5,
  });

  return [base, p1];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function openingWaistWall(width, height) {
  const base = doorBase1(width, height);
  base.fill = 'gray';

  return [base];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function openingDig(width, height) {
  const base = doorBase1(width, height);
  base.stroke = 'transparent';

  const h1 = height / 2;
  const h2 = height / 6;

  const p1 = new PathData({
    d: `M0,${-h1} L${width},${-h1} M0,${h1} L${width},${h1}`,
    strokeDasharray: '6 6',
    strokeWidth: 5,
  });

  const p2 = new PathData({
    d: `M0,${-h1} L0,${h1}  M${width},${-h1} L${width},${h1}  M0,${-h2} L0,${h2} L${width},${h2} L${width},${-h2} Z`,
    fill: 'gray',
    strokeDasharray: '6 6',
    strokeWidth: 5,
  });

  return [base, p1, p2];
}

/**
 *
 * @param {number} width
 * @param {number} height
 * @returns
 */
export function entranceSingleDoor(width, height) {
  return singleDoor(width, height);
}

/**
 * @param {number} width
 * @param {number} height
 */
export function entranceParentChildDoor(width, height) {
  const base = doorBase1(width, height);

  const h1 = height / 2;
  const r1 = width / 3;
  const r2 = (width * 2) / 3;

  const p1 = new PathData({
    d: `M${0},${-h1} L0,${-h1 -
      r1} A${r1} ${r1} ${90} ${0} ${1} ${r1} ${-h1} Z  M${width},${-h1} L${width},${-h1 -
      r2} A${r2} ${r2} ${90} ${0} ${0} ${width - r2} ${-h1} Z`,
    strokeWidth: 3,
  });

  return [base, p1];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function entranceDoubleDoor(width, height) {
  const base = doorBase1(width, height);

  const h1 = height / 2;
  const r = width / 2;

  const p1 = new PathData({
    d: `M${0},${-h1} L0,${-h1 -
      r} A${r} ${r} ${90} ${0} ${1} ${r} ${-h1} Z  M${width},${-h1} L${width},${-h1 -
      r} A${r} ${r} ${90} ${0} ${0} ${width - r} ${-h1} Z`,
    strokeWidth: 3,
  });

  return [base, p1];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function entranceSingleFixDoor(width, height) {
  const base = doorBase1(width, height);

  const w1 = width / 6;
  const w2 = (width * 5) / 6;
  const h1 = height / 2;
  const r = (width * 4) / 6;

  const p1 = new PathData({
    d: `M${w2},${-h1} L${w2},${-h1 -
      r} A${r} ${r} ${90} ${0} ${0} ${w1} ${-h1} Z  M${w1},${h1} L${w1},${-h1}  M${w2},${h1} L${w2},${-h1}`,
    strokeWidth: 3,
  });

  return [base, p1];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function entranceParentChildSleeveDoor(width, height) {
  const base = doorBase1(width, height);

  const w1 = (width * 2) / 6;
  const w2 = (width * 5) / 6;
  const h1 = height / 2;
  const r1 = w1;
  const r2 = (width * 3) / 6;

  const p1 = new PathData({
    d: `M${0},${-h1} L0,${-h1 -
      r1} A${r1} ${r1} ${90} ${0} ${1} ${r1} ${-h1} Z  M${w2},${-h1} L${w2},${-h1 -
      r2} A${r2} ${r2} ${90} ${0} ${0} ${w1} ${-h1} Z  M${w2},${-h1} L${w2},${h1}`,
    strokeWidth: 3,
  });

  return [base, p1];
}

/**
 * @param {number} height
 * @param {number} height
 */
export function singleDoor(width, height) {
  const base = doorBase1(width, height);
  base.stroke = 'black';

  const h1 = height / 2;
  const r = width;

  const body = new PathData({
    d: `M${width},${-h1} L${width},${-h1 - r} A${r} ${r} ${90} ${0} ${0} ${0} ${-h1} Z`,
    stroke: 'black',
    fill: 'white',
    strokeWidth: 3,
  });

  return {
    body,
    base,
  };
}

/**
 * @param {number} width
 * @param {number} height
 */
export function doubleParentChildDoor(width, height) {
  return entranceParentChildDoor(width, height);
}

/**
 * @param {number} width
 * @param {number} height
 */
export function doubleDoor(width, height) {
  return entranceDoubleDoor(width, height);
}

/**
 * @param {number} width
 * @param {number} height
 */
export function foldingDoor1(width, height) {
  const base = doorBase1(width, height);

  const w1 = width / 2;
  const w2 = (width * 3) / 4;
  const h1 = height / 2;
  const h2 = width / 2;

  const p1 = new PathData({
    d: `M${w1},${h1} L${w2},${-h2 + h1 * 2} L${width},${h1} Z`,
    strokeWidth: 3,
  });

  const p2 = new PathData({
    d: `M${w1},${-h1} L${width},${-h1}`,
    strokeWidth: 3,
  });

  return [base, p1, p2];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function foldingDoor2(width, height) {
  const base = doorBase1(width, height);

  const w1 = width / 3;
  const w2 = (width * 2) / 3;
  const h1 = height / 2;
  const h2 = (w1 * Math.sqrt(3)) / 2;

  const p1 = new PathData({
    d: `M${0},${h1} L${w1 / 2},${-h2 + h1 * 2} L${w1},${h1} Z  M${w2},${h1} L${w2 + w1 / 2},${-h2 +
      h1 * 2} L${width},${h1} Z`,
    strokeWidth: 3,
  });

  const p2 = new PathData({
    d: `M${0},${-h1} L${width},${-h1}`,
    strokeWidth: 3,
  });

  return [base, p1, p2];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function foldingDoor3(width, height) {
  const base = doorBase1(width, height);

  const w1 = width / 5;
  const w2 = (width * 3) / 5;
  const w3 = (width * 4) / 5;
  const h1 = height / 2;
  const h2 = (w1 * Math.sqrt(3)) / 2;

  const p1 = new PathData({
    d: `M${0},${h1} L${w1 / 2},${-h2 + h1 * 2} L${w1},${h1} Z  M${w2},${h1} L${w2 + w1 / 2},${-h2 +
      h1 * 2} L${w3},${h1} Z  M${w3},${h1} L${w3 + w1 / 2},${-h2 + h1 * 2} L${width},${h1} Z`,
    strokeWidth: 3,
  });

  const p2 = new PathData({
    d: `M${0},${-h1} L${width},${-h1}`,
    strokeWidth: 3,
  });

  return [base, p1, p2];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function foldingDoor4(width, height) {
  const base = doorBase1(width, height);

  const w1 = width / 5;
  const w2 = (width * 2) / 5;
  const w3 = (width * 3) / 5;
  const w4 = (width * 4) / 5;
  const h1 = height / 2;
  const h2 = (w1 * Math.sqrt(3)) / 2;

  const p1 = new PathData({
    d: `M${0},${h1} L${w1 / 2},${-h2 + h1 * 2} L${w1},${h1} Z  M${w1},${h1} L${w1 + w1 / 2},${-h2 +
      h1 * 2} L${w2},${h1} Z  M${w3},${h1} L${w3 + w1 / 2},${-h2 +
      h1 * 2} L${w4},${h1} Z  M${w4},${h1} L${w4 + w1 / 2},${-h2 + h1 * 2} L${width},${h1} Z`,
    strokeWidth: 3,
  });

  const p2 = new PathData({
    d: `M${0},${-h1} L${width},${-h1}`,
    strokeWidth: 3,
  });

  return [base, p1, p2];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function foldingOpenDoor(width, height) {
  const base = doorBase1(width, height);

  const w1 = width / 3;
  const w2 = (width * 7) / 8;
  const w3 = (width * 7.75) / 8;
  const h1 = height / 2;
  const r = w1;

  const p1 = new PathData({
    d: `M${0},${-h1} L0,${-h1 -
      r} A${r} ${r} ${90} ${0} ${1} ${w1} ${-h1} Z  M${w2},${-h1} L${w3},${-r -
      h1} L${width},${-h1}`,
    strokeWidth: 3,
  });

  const p2 = new PathData({
    d: `M${0},${-h1} L${width},${-h1}`,
    strokeWidth: 3,
  });

  return [base, p1, p2];
}
