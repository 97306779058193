// @ts-check

import { API, graphqlOperation } from 'aws-amplify';
import { getS3Url, getSystemMaster } from '../../../../graphql/queries';

export class MaterialFetcher {
  /**
   * @param {string} sort
   */
  async listMaterials(sort) {
    //マスタ情報をロード
    const masResult = await API.graphql(
      graphqlOperation(getSystemMaster, {
        classification: 'materialImage',
        classificationDetail: '1',
      })
    );
    //マスタから素材情報を取得
    // @ts-ignore
    const materialData = masResult.data.getSystemMaster;
    if (materialData == null) {
      console.error('素材のロードに失敗しました');
      return;
    }
    const links = [];

    //ファイルのディレクトリ情報を取得
    materialData.value.forEach((/** @type {string[]} */ material, /** @type {number} */ key) => {
      if (key != 0) {
        links.push({
          name: material[0], //素材名
          info: material[1], //素材の種類（壁紙、外壁、フローリングなど）
          class: material[3], //第二ソート
          dir: 'Simulate/' + material[2], //ファイルディレクトリ名
        });
      }
    });

    // S3からイメージURL取得
    const s3Result = await API.graphql(
      graphqlOperation(getS3Url, {
        level: 'Master',
        file: links.map(x => {
          return {
            fileName: x.dir,
            fileAction: 'View',
          };
        }),
      })
    );

    // @ts-ignore
    const materialInfo = s3Result.data.getS3Url
      .map((
        /** @type {{ fileName: string; fileUrl: string; }} */ image,
        /** @type {string | number} */ key
      ) => {
        return {
          name: image.fileName.split('_')[1]?.replace('.jpg', '') || links[key].name,
          sort: links[key].info,
          class: links[key].class,
          url: image.fileUrl,
        };
      })
      .filter((/** @type {{ sort: string; }} */ v) => v.sort === sort);

    const result = await Promise.all(
      materialInfo.map(async m => {
        const data = await fetch(m.url);
        const blob = await data.blob();
        const mm = {
          ...m,
          url: m.url.replace(/\?.*/, ''),
          blob,
        };
        return mm;
      })
    );

    return result;
  }
}
