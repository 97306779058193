<template>
  <span>
    <div class="outer-frame" v-for="(colorData, index) of colorDatas" :key="index">
      <div
        v-if="colorData[2] == seccondSort"
        class="inner-frame"
        :class="{ active: activeColor == colorData[1] }"
        @click="getClickColor(colorData)"
      >
        <div class="color-box" :style="'background:' + colorData[1] + ';'"></div>
        <div style="text-align:center;">{{ colorData[0] }}</div>
      </div>
    </div>
    <div style="float: left; padding: 100px 20px;"></div>
  </span>
</template>
<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { getSystemMaster } from '../../../../graphql/queries';

  export default {
    props: ['activeColor', 'seccondSort'],
    data: function() {
      return {
        colorDatas: [],
      };
    },
    async created() {
      //カラーコードを取得
      let result = await API.graphql(
        graphqlOperation(getSystemMaster, {
          classification: 'materialImage',
          classificationDetail: '3',
        })
      );
      let data = result.data.getSystemMaster.value;
      data.shift(); //一番上の分類文字を破棄
      this.colorDatas = data;
    },
    mounted: async function() {},
    methods: {
      /**
       * クリックした際、処理を開始しカラーコードを返却
       */
      getClickColor(getColor) {
        this.$emit('getClickColor', getColor);
      },
      /**
       * 呼び出された際、取得したカラーコードと一致したものがあるか確認し返却
       */
      checkActiveColor(checkColor) {
        let colorData = this.colorDatas.find(val => val[1] == checkColor);
        return colorData;
      },
    },
  };
</script>

<style scoped>
  .outer-frame {
    display: block;
    color: white;
  }
  .inner-frame {
    float: left;
    width: 90px;
    border: 1px solid;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  .inner-frame:hover {
    background: darkgrey;
  }
  .inner-frame.active {
    background: rgb(121, 109, 0);
  }
  .color-box {
    border: 1px solid black;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: 6px;
    margin-right: 6px;
  }
</style>
