<!--
作成者：Kim Seungmin
作成日：2020.7.22
画面名：マニュアル照会
機能：各機能ごと使い方を説明
親子関係：View-userCreate.vue: ユーザー登録の場合
更新者：
①
更新内容：
①
-->
<template>
  <div id="manual" class="col-12 m-0 p-0">
    <div class="row grid-margin col-12">
      <h4 class="font-weight-bold">
        マニュアル・規約
      </h4>
    </div>
    <div class="row grid-margin mt-2 col-12" style="margin-right:-5px;">
      <div class="row">
        <div class="col-12">
          <router-link to="/DashBoard" tag="button" class="btn btn-ally float-right">
            メインページへもどる
          </router-link>
        </div>
      </div>
    </div>
    <b-card-group columns>
      <b-card v-for="(item, i) in items" :key="i" :header="item.category">
        <b-list-group>
          <b-list-group-item v-for="(link, j) in item.links" :key="j" :disabled="link.disabled">
            <a :href="s3url + link.file"> {{ link.name }}</a>
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <b-card header="その他">
        <b-list-group>
          <b-list-group-item v-for="(submenu, i) in submenus" :key="i">
            <b-link @click="showModal(submenu)">{{ submenu.name }}</b-link>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-card-group>
    <!-- モーダル -->
    <b-modal
      id="modal-submenu"
      :title="selected ? selected.name : ''"
      size="lg"
      header-class="ally-modal"
      body-class="confirm-modal"
      footer-class="ally-modal"
      centered
      ok-only
      ok-variant="light"
    >
      <span v-if="selected">
        <TermsOfUse id="term-of-use" v-if="selected.id == 'term-of-use'" />
        <PrivacyPolicy id="privacy-policy" v-if="selected.id == 'privacy-policy'" />
        <SpecifiedCommercialTransaction
          id="specified-commercial-transaction"
          v-if="selected.id == 'specified-commercial-transaction'"
        />
        <PriceList id="price-list" v-if="selected.id == 'price-list'" />
        <FreeTrial id="free-trial" v-if="selected.id == 'free-trial'" />
        <PaymentMethod id="payment-method" v-if="selected.id == 'payment-method'" />
      </span>
    </b-modal>
  </div>
</template>
<script>
  import TermsOfUse from '@/views/Main/Login/SignUp/TermsOfUse';
  import PrivacyPolicy from '@/views/Main/Login/SignUp/PrivacyPolicy.vue';
  import SpecifiedCommercialTransaction from '@/views/Main/Login/SignUp/SpecifiedCommercialTransaction.vue';
  import PriceList from '@/views/Main/Login/SignUp/PriceList.vue';
  import FreeTrial from '@/views/Main/Login/SignUp/FreeTrial.vue';
  import PaymentMethod from '@/views/Main/Login/SignUp/PaymentMethod.vue';

  export default {
    components: {
      TermsOfUse,
      PrivacyPolicy,
      SpecifiedCommercialTransaction,
      PriceList,
      FreeTrial,
      PaymentMethod,
    },
    data: function() {
      return {
        s3url: 'https://allys3163848-dev.s3.ap-northeast-1.amazonaws.com/Master/manual/',
        items: [
          {
            category: 'シミュレーションアプリ',
            links: [
              {
                file: 'ALLY-Photo.pdf',
                name: 'ALLY フォト（画像シミュレーター）',
              },
              {
                file: 'ALLY-AR-Simulator.pdf',
                name: 'ALLY - ARシミュレーター',
              },
              {
                file: 'ALLY-AutoSketch.pdf',
                name: 'ALLY オートスケッチ（部屋計測・間取図自動作成）',
              },
            ],
          },
          {
            category: '不動産分析',
            links: [
              {
                file: 'ALLY-InvestmentAnalytics.pdf',
                name: 'ALLY 不動産投資分析',
              },
              {
                file: 'ALLY-RealEstateSuitability.pdf',
                name: 'ALLY 不動産活用診断',
              },
              {
                file: 'ALLY-ROA.pdf',
                name: 'ALLY 不動産ポートフォリオ分析',
              },
              {
                file: 'ALLY-InheritanceTax.pdf',
                name: 'ALLY 相続シミュレーション',
              },
            ],
          },
          {
            category: '管理機能',
            links: [
              {
                file: 'CustomerManagement.pdf',
                name: '顧客管理',
              },
              {
                file: 'PropertyManagement.pdf',
                name: '物件管理',
              },
              {
                file: 'AccountManagement.pdf',
                name: 'アカウント管理',
              },
              {
                file: 'LicenseManagement.pdf',
                name: 'ライセンス管理',
              },
              {
                file: 'CompanyInformationManagement.pdf',
                name: '自社情報管理',
              },
            ],
          },
        ],
        submenus: [
          { id: 'term-of-use', name: '利用規約' },
          { id: 'privacy-policy', name: 'プライバシーポリシー' },
          { id: 'specified-commercial-transaction', name: '特定商取引法に基づく表記' },
          { id: 'price-list', name: 'ALLY-PRO® 料金表' },
          { id: 'payment-method', name: '利用料のお支払いについて' },
          { id: 'free-trial', name: '無料トライアルについて' },
        ],
        selected: null,
      };
    },
    methods: {
      showModal: function(item) {
        this.selected = item;
        this.$bvModal.show('modal-submenu');
      },
    },
  };
</script>

<style scoped>
  .card {
    border-top-color: gainsboro !important;
    border-radius: calc(0.25rem - 1px);
    margin-bottom: 1rem;
  }
  .card-header {
    transition: 0.2s;
    background: rgba(var(--colorThemeRGB));
    color: white;
    font-weight: bold;
  }
  #manual >>> .card .card-body {
    padding: 10px;
    margin: 0;
  }
  .disabled a {
    color: lightgray;
  }
  .card-columns {
    column-count: 1;
  }
  @media only screen and (min-width: 768px) {
    .card-columns {
      column-count: 2;
    }
  }
  @media only screen and (min-width: 1200px) {
    .card-columns {
      column-count: 2;
    }
  }
</style>
<style>
  #modal-submenu .info-title,
  #modal-submenu .check-info,
  #modal-submenu .margin-bottom > br {
    display: none;
  }
  #modal-submenu .margin-bottom {
    margin-bottom: 0;
  }
  #modal-submenu .info-content {
    width: auto;
    height: 30rem;
  }
  #modal-submenu #price-list .info-content {
    padding: 0;
    border: none;
    background-color: white;
  }
  #modal-submenu #price-list table {
    table-layout: fixed;
  }
  #modal-submenu #price-list td {
    white-space: normal;
  }
  #modal-submenu #price-list th {
    white-space: normal;
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
  }
</style>
