// @ts-check
import { Transform } from '../../util/math/transform';
import { Vec } from '../../util/math/vector';
import { nextCycleIndex } from '../../util/util';
import { HandleFlg } from '../tools/handleFlg';
import { PvCircleHandle } from './circleHandle';
import { PvGroup } from './groupNode';
import { createNode } from './pvNode';
import { PvObject } from './pvObject';

export class PvHandle extends PvObject {
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'handle';

  constructor() {
    super();
  }

  /**
   * オブジェクトからハンドルを生成
   * @param {PvObject} obj
   * @param {HandleFlg} handleFlg
   * @returns {PvGroup}
   */
  static createHandles(obj, handleFlg) {
    const handle = createNode(PvHandle);
    handle.subType = 'handle';

    if (obj.handleFlg.edge && handleFlg.edge) {
      handle.appendChildren(PvHandle.createEdgeHandles(obj));
    }

    if (obj.handleFlg.vertex && handleFlg.vertex) {
      handle.appendChildren(PvHandle.createVertexHandles(obj));
    }

    if (obj.handleFlg.curve && handleFlg.curve) {
      handle.appendChildren(PvHandle.createCurveHandles(obj));
    }

    if (obj.handleFlg.rotate && handleFlg.rotate) {
      handle.appendChild(PvHandle.createRotateHandles(obj));
    }

    handle.transform = obj.absoluteTransform();

    return handle;
  }

  /**
   * @private
   * @param {PvObject} target
   */
  static createVertexHandles(target) {
    return target.vertexes.map((v, index) => {
      const handle = createNode(PvCircleHandle);
      handle.moveTo(v);
      handle.index = index;
      handle.targetId = target.id;
      handle.style.stroke = 'blue';
      handle.subType = 'vertex';
      handle.vertexes = [new Vec()];
      return handle;
    });
  }

  /**
   * @private
   * @param {PvObject} target
   */
  static createEdgeHandles(target) {
    return target.vertexes.map((v, index) => {
      const next = target.vertexes[nextCycleIndex(index, target.vertexes.length)];
      const arc = target.arcPoints[index] ?? 0;
      const handle = createNode(PvCircleHandle);
      const unit = next.eq(v)
        ? new Vec(1, 0)
        : next
            .sub(v)
            .normal()
            .unit();
      handle.transform = new Transform({
        translate: new Vec((v.x + next.x) / 2, (v.y + next.y) / 2).add(unit.scale(arc)),
      });
      handle.index = index;
      handle.targetId = target.id;
      handle.style.stroke = 'green';
      handle.subType = 'edge';
      handle.vertexes = [new Vec()];
      return handle;
    });
  }

  /**
   * @private
   * @param {PvObject} target
   */
  static createCurveHandles(target) {
    return target.vertexes.map((v, index) => {
      const next = target.vertexes[nextCycleIndex(index, target.vertexes.length)];
      const arc = target.arcPoints[index];
      const handle = createNode(PvCircleHandle);
      const unit = next
        .sub(v)
        .normal()
        .unit();
      handle.transform = new Transform({
        translate: new Vec((v.x + next.x) / 2, (v.y + next.y) / 2).add(unit.scale(arc)),
      });
      handle.index = index;
      handle.targetId = target.id;
      handle.style.stroke = 'red';
      handle.subType = 'curve';
      handle.vertexes = [new Vec()];
      return handle;
    });
  }

  /**
   * @private
   * @param {PvObject} target
   */
  static createRotateHandles(target) {
    const v = target.vertexes[0];
    const next = target.vertexes[1];
    const arc = target.arcPoints[0] ?? 0;
    const handle = createNode(PvCircleHandle);
    handle.transform = new Transform({
      translate: new Vec((next.x - v.x) / 2, -150 - arc),
    });
    handle.targetId = target.id;
    handle.style.stroke = 'orange';
    handle.subType = 'rotate';
    handle.vertexes = [new Vec()];
    return handle;
  }
}
