<!--
作成者：Lee HyeonSeok
作成日：2020.6.12
画面名：会社情報登録・修正・照会
機能：会社情報登録・修正・照会
親子関係：なし
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <div id="CompanyView">
      <b-overlay :show="loading" opacity="0.25">
        <h4 class="font-weight-bold">
          {{ title }}
        </h4>

        <b-container style="padding:0;">
          <!-- 上部ボタン -->
          <b-row>
            <b-col>
              <b-navbar>
                <b-navbar-nav class="ml-auto">
                  <router-link
                    to="/Company/List?page=true"
                    tag="button"
                    class="btn btn-ally"
                    v-if="authType == 'systemAdmin'"
                  >
                    会社情報一覧に戻る
                  </router-link>
                  <router-link to="/DashBoard" tag="button" class="btn btn-ally float-right">
                    メインページへもどる
                  </router-link>
                </b-navbar-nav>
              </b-navbar>
            </b-col>
          </b-row>
          <!-- 会社情報編集画面 -->
          <b-row>
            <b-col>
              <b-card no-body class="ally-input-background">
                <ValidationObserver slim ref="companyInfo">
                  <b-container>
                    <b-row class="company-items">
                      <!--左側-->
                      <b-col style="border-right:1px solid rgb(154,154,154);">
                        <b-row>
                          <b-col>
                            <allyText
                              title="会社名"
                              name="companyName"
                              v-model="companyInfo.companyName"
                              :mode="mode"
                              :placeholder="'〇〇株式会社'"
                              rules="zen|required|max:45"
                              :fullWidth="true"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <allyAddress
                              v-model="companyInfo.address"
                              :mode="mode"
                              rules="required"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <allyText
                              title="電話番号"
                              name="tel"
                              v-model="companyInfo.tel"
                              :mode="mode"
                              :placeholder="'0196000000'"
                              rules="tel|required"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <allyText
                              title="E-mail"
                              name="mail"
                              v-model="companyInfo.mail"
                              :mode="mode"
                              :placeholder="'xxxxxxxxx@xxx.co.jp'"
                              rules="email|required"
                            />
                          </b-col>
                        </b-row>
                      </b-col>
                      <!--右側-->
                      <b-col>
                        <b-row>
                          <b-col cols="7">
                            <b-row>
                              <b-col>
                                <allyText
                                  title="代表者名"
                                  name="bossLastName"
                                  v-model="companyInfo.bossLastName"
                                  :mode="mode"
                                  :placeholder="'新井'"
                                  rules="required|max:10"
                                />
                              </b-col>
                              <b-col>
                                <allyText
                                  title="-"
                                  style="color:transparent;"
                                  name="bossFirstName"
                                  v-model="companyInfo.bossFirstName"
                                  :mode="mode"
                                  :placeholder="'太郎'"
                                  rules="required|max:10"
                                />
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <allyText
                                  title="法人番号"
                                  name="registrationNum"
                                  v-model="companyInfo.registrationNum"
                                  :mode="mode"
                                  :placeholder="'0000000000000'"
                                  :min="'12'"
                                  :max="'13'"
                                />
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col>
                            <b-row>
                              <b-col>
                                <allyImages
                                  v-model="companyInfo.companyLogo"
                                  :mode="mode"
                                  title="会社ロゴ"
                                  :companyId="id"
                                  :loading="loading"
                                  ref="logo"
                                />
                              </b-col> </b-row
                          ></b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <allyText
                              title="URL"
                              name="qualification"
                              v-model="companyInfo.qualification"
                              :mode="mode"
                              :placeholder="'http://example.com'"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <allyTextArea
                              title="メモ"
                              name="memo"
                              v-model="companyInfo.memo"
                              :mode="mode"
                              :rows="mode == 'Add' ? '14' : '8.5'"
                            />
                          </b-col>
                        </b-row>
                        <b-row v-if="mode != 'Add'">
                          <b-col cols="5" v-if="authType == 'systemAdmin'">
                            <allyText
                              title="会社ID"
                              name="id"
                              v-model="companyInfo.id"
                              mode="View"
                            />
                          </b-col>
                          <b-col>
                            <allyText
                              title="登録日"
                              name="createdDate"
                              v-model="createdDate"
                              mode="View"
                            />
                          </b-col>
                          <b-col>
                            <allyText
                              title="更新日"
                              name="updatedDate"
                              v-model="updatedDate"
                              mode="View"
                            />
                          </b-col>
                          <b-col>
                            <allyText
                              title="顧客番号"
                              name="customerNumber"
                              v-model="companyInfo.customerNumber"
                              mode="View"
                            />
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-container>
                </ValidationObserver>
              </b-card>
            </b-col>
          </b-row>
          <!-- 管理者情報入力画面  -->
          <b-row v-if="!loading && mode == 'Add'">
            <b-col>
              <b-card no-body class="ally-input-background">
                <ValidationObserver slim ref="accountInfo">
                  <b-container>
                    <b-row class="company-items">
                      <!--左側-->
                      <b-col style="border-right:1px solid rgb(154,154,154);">
                        <b-row>
                          <b-col>
                            <allyText
                              title="管理者E-mail"
                              description="登録したアドレスがIDに設定されます"
                              name="admin-mail"
                              v-model="accountInfo.email"
                              rules="required|email"
                              :placeholder="'xxxxxxxxx@xxx.co.jp'"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <allyText
                              title="管理者氏名"
                              name="lastName"
                              v-model="accountInfo.familyname"
                              rules="required"
                              :placeholder="'新井'"
                            />
                          </b-col>
                          <b-col>
                            <allyText
                              title="管理者氏名"
                              style="color:transparent;"
                              name="firstName"
                              v-model="accountInfo.givenname"
                              rules="required"
                              :placeholder="'太郎'"
                              hideBadge
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <allyText
                              title="電話番号"
                              name="admin_phonenumber"
                              v-model="accountInfo.phonenumber"
                              rules="tel|aorbrequired:@携帯電話番号"
                              :placeholder="'0196000000'"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <allyText
                              title="携帯電話番号"
                              name="mobilePhoneNumber"
                              v-model="accountInfo.mobilePhoneNumber"
                              rules="tel|aorbrequired:@電話番号"
                              :placeholder="'0196000000'"
                            />
                            <span class="appendix">
                              ※IDを忘れた場合に、SMSメールにて照会できます。
                            </span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col style="top:-1rem;">
                            <allyRadio
                              class="col-12"
                              title="性別"
                              name="gender"
                              :items="[
                                { text: '男', value: 'male' },
                                { text: '女', value: 'female' },
                              ]"
                              v-model="accountInfo.gender"
                            />
                          </b-col>
                        </b-row>
                      </b-col>
                      <!--右側-->
                      <b-col>
                        <b-row>
                          <b-col id="birthdate">
                            <allyDate
                              title="生年月日"
                              name="birthdate"
                              v-model="accountInfo.birthdate"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <allyText
                              title="部署"
                              name="address"
                              v-model="accountInfo.address"
                              :placeholder="'〇〇支店'"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <allyText
                              title="パスワード"
                              name="admin_password"
                              v-model="accountInfo.password"
                              placeholder="8文字以上・大小文字・数字含む"
                              rules="required|password"
                              type="password"
                              autocomplete="new-password"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <allyText
                              title="パスワードの確認"
                              name="newPasswordCheck"
                              v-model="newPasswordCheck"
                              rules="required|NewAndNewCheck:@パスワード"
                              placeholder="8文字以上・大小文字・数字含む"
                              type="password"
                              autocomplete="new-password"
                            />
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-container>
                </ValidationObserver>
              </b-card>
            </b-col>
          </b-row>
          <!-- ライセンス情報 -->
          <b-row v-if="mode == 'View' && authType == 'systemAdmin'">
            <b-col>
              <b-card no-body class="ally-input-background">
                <License ref="license" :companyData="companyInfo" :mode="mode" :key="displayKey" />
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="mode == 'View' && authType == 'systemAdmin'">
            <b-col>
              <b-card>
                <payment :show="loading" :companyInfo="companyInfo" />
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="mode == 'View' && authType == 'systemAdmin'">
            <b-col>
              <b-card v-if="!loading">
                <FreeLicenseCheck
                  :companyInfo="companyInfo"
                />
              </b-card>
            </b-col>
          </b-row>
          <span v-if="nonSettlementMode == true">
            <b-row v-if="mode == 'View' && authType == 'systemAdmin'">
              <b-col>
                <b-card>
                  <NonSettlement
                    @loading="val => (loading = val)"
                    :companyInfo="companyInfo"
                    @updateDisplayKey="updateDisplayKey"
                  />
                </b-card>
              </b-col>
            </b-row>
          </span>
          <!-- 下部ボタン -->
          <b-row>
            <b-col>
              <b-navbar>
                <b-navbar-nav class="ml-auto">
                  <router-link
                    :to="{
                      path: '/Company/View/' + id,
                    }"
                    tag="button"
                    class="btn btn-ally float-right"
                    v-if="mode == 'Edit' && authType == 'systemAdmin'"
                  >
                    詳細に戻る
                  </router-link>
                  <router-link
                    :to="{
                      path: '/MyCompany/View/',
                    }"
                    tag="button"
                    class="btn btn-ally float-right"
                    v-if="mode == 'Edit' && authType == 'admin'"
                  >
                    詳細に戻る
                  </router-link>
                  <button
                    class="btn btn-ally float-right"
                    v-on:click="create"
                    v-if="mode == 'Add'"
                    type="submit"
                    :disabled="addRoad"
                  >
                    登録
                  </button>
                  <router-link
                    :to="{
                      path: '/Company/Edit/' + id,
                    }"
                    tag="button"
                    class="btn btn-ally float-right"
                    v-if="mode == 'View' && authType == 'systemAdmin'"
                  >
                    編集
                  </router-link>
                  <router-link
                    :to="{
                      path: '/MyCompany/Edit/' + id,
                    }"
                    tag="button"
                    class="btn btn-ally float-right"
                    v-if="mode == 'View' && authType == 'admin'"
                  >
                    編集
                  </router-link>
                  <button
                    class="btn btn-ally float-right"
                    v-if="mode == 'Edit'"
                    v-on:click="update"
                  >
                    保存
                  </button>
                </b-navbar-nav>
              </b-navbar>
            </b-col>
          </b-row>
        </b-container>
        <!-- ロード中 -->
        <template #overlay>
          <Load />
        </template>
      </b-overlay>
    </div>

    <div class="row">
      <div class="col-12"></div>
    </div>
    <allyAlert code="S100" @okAction="pageLink()"></allyAlert>
    <allyAlert code="E0001"></allyAlert>
    <!-- モーダル -->
    <b-modal
      id="messageModal"
      size="lg"
      header-class="ally-modal text-center"
      footer-class="ally-modal text-center"
      body-class="ally-input-background"
      ok-only
      ok-variant="light"
      @ok="closeModal()"
      :title="title"
      centered
    >
      <div class="row">
        <div class="col-md-8">
          <div class="d-block text-center">
            <h3 class="text-left">
              {{ message }}
            </h3>
            <p class="text-left" v-if="subtext">
              <br />
              {{ subtext }}
              <br />
            </p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import {
    createCompany,
    updateCompany,
    createAccount,
    deleteCompany,
  } from '../../graphql/mutations';
  import { getCompany, listCompanys } from '../../graphql/queries';
  import store from '../../store';
  import License from './License';
  import payment from './payment';
  import Load from '../../components/Common/Load';
  import NonSettlement from './NonSettlement';
  import FreeLicenseCheck from './FreeLicenseCheck';

  API.configure(awsconfig);

  export default {
    data: function() {
      return {
        displayKey: new Date().toISOString(),
        id: null,
        loading: true,
        companyInfo: {
          companyName: null,
          bossLastName: null,
          bossFirstName: null,
          tel: null,
          mail: null,
          address: {
            postalCode: null,
            address1: null,
            address2: null,
            address3: null,
          },
          qualification: null,
          registrationNum: null,
          companyLogo: null,
          license: null,
          payment: null,
        },
        accountInfo: {
          email: null,
          familyname: null,
          givenname: null,
          phonenumber: null,
          mobilePhoneNumber: '',
          gender: null,
          birthdate: null,
          address: null,
          isAdmin: true,
          password: null,
          companyId: null,
        },
        authType: null,
        addRoad: false,
        newPasswordCheck: null,
        status: null,
        subtext: null,
        nonSettlementMode: true,
      };
    },
    mounted: async function() {
      //非決済モードは開発中しか写らないようにする
      if (process.env.VUE_APP_DEBUG_MODE == 'TRUE') this.nonSettlementMode = true;
      //接続経路（アドミン：/Mycompany、システムアドミン: /Company）によって検索用ID or 自社名を設定
      //システムアドミンの場合は対象会社名を設定
      if (this.$router.currentRoute.path.startsWith('/Company')) {
        this.id = this.$route.params.id;
        this.authType = 'systemAdmin';
      } else {
        //アドミンの場合は自社名を選択
        var groups = store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        this.id = groups[groupIndex].replace('Company-', '');
        this.authType = 'admin';
      }

      this.loading = false;
    },
    methods: {
      /**
       * METHOD: 会社登録
       */
      create: async function() {
        // 会社情報バリデーションチェック
        const companyValid = await this.$refs.companyInfo.validate();
        if (!companyValid) {
          return;
        }
        this.addRoad = true;
        // アカウントバリデーションチェック
        const accountValid = await this.$refs.accountInfo.validate();
        if (!accountValid) {
          return;
        }

        // 会社情報を会社登録情報に格納
        var companyRegInfo = this.companyInfo;
        try {
          if (this.companyInfo.registrationNum) {
            // 登録番号重複チェック
            var registrationNumCheck = await API.graphql(
              graphqlOperation(listCompanys, {
                filter: {
                  registrationNum: { eq: this.companyInfo.registrationNum },
                },
              })
            );
            // 登録番号が重複されている場合、エラーを出力、処理を終了する
            if (registrationNumCheck.data.listCompanys.items.length > 0) {
              this.status = 'ErrorRegistrationNumber';
              this.subtext = null;
              this.$bvModal.show('messageModal');
              this.addRoad = false;
              return;
            }
          }
        } catch (e) {
          console.error({ e });
          this.status = 'ErrorRegistrationNumber';
          this.$bvModal.show('messageModal');
          this.subtext = null;
          this.addRoad = false;
          return;
        }

        this.loading = true;

        try {
          // 会社登録処理
          var registeredCompanyData = await API.graphql(
            graphqlOperation(createCompany, {
              input: companyRegInfo,
            })
          );
          var uploadData = {
            id: null,
            companyId: null,
          };
          uploadData.id = this.id = registeredCompanyData.data.createCompany.id;
          uploadData.companyId = 'Company-' + registeredCompanyData.data.createCompany.id;

          // ロゴファイルアップロード
          this.fileUpload();
          uploadData.companyLogo = this.companyInfo.companyLogo;

          // 会社認証用グループ登録
          await API.graphql({
            query: updateCompany,
            variables: { input: uploadData },
          });

          try {
            // 登録成功時に初期アカウント登録を行う
            // アカウント情報
            this.accountInfo.companyId = registeredCompanyData.data.createCompany.id;
            await API.graphql(
              graphqlOperation(createAccount, {
                input: {
                  ...this.accountInfo,
                  serialNumber: '1',
                },
              })
            );
            this.status = 'OK';
            this.subtext = '会社名：' + this.companyInfo.companyName;
            this.$bvModal.show('messageModal');
          } catch (e) {
            console.error({ e });
            await API.graphql(
              graphqlOperation(deleteCompany, {
                input: {
                  id: this.accountInfo.companyId,
                },
              })
            );
            this.status = 'ErrorAccount';
            this.$bvModal.show('messageModal');
            this.addRoad = false;
          }
        } catch (e) {
          await API.graphql(
            graphqlOperation(deleteCompany, {
              input: {
                id: this.accountInfo.companyId,
              },
            })
          );
          console.error({ e });
          this.status = 'ErrorCompany';
          this.$bvModal.show('messageModal');
          this.addRoad = false;
        } finally {
          this.loading = false;
        }
      },
      /**
       * METHOD: 会社情報更新
       */
      update: async function() {
        this.$refs.companyInfo.validate().then(async isValid => {
          if (isValid) {
            this.fileUpload();

            var updloadData = null;

            if (this.authType == 'systemAmin') {
              updloadData = {
                companyName: this.companyInfo.companyName, // 会社名
                bossLastName: this.companyInfo.bossLastName, //代表者名（姓）
                bossFirstName: this.companyInfo.bossFirstName, // 代表者名（名）
                tel: this.companyInfo.tel, // 電話番号
                mail: this.companyInfo.mail, // E-mail
                address: this.companyInfo.address, // 住所
                qualification: this.companyInfo.qualification, // URL
                registrationNum: this.companyInfo.registrationNum, // 法人番号
                companyLogo: this.companyInfo.companyLogo, // ロゴ
                license: this.companyInfo.license, // ライセンス
                memo: this.companyInfo.memo, // メモ
              };
            } else {
              updloadData = this.companyInfo;
            }

            updloadData.id = this.id;

            try {
              await API.graphql({
                query: updateCompany,
                variables: { input: updloadData },
              });
              //モーダルを表示する。
              this.$bvModal.show('S100');
            } catch {
              //エラー時モーダルを表示する。
              this.$bvModal.show('E0001');
            }
          }
        });
      },
      /**
       * METHOD: ページリンク
       */
      pageLink: function() {
        if (this.authType == 'admin') {
          //保存フラグをtrueに設定（ページ移動の警告メッセージを防ぐ為）
          this.$store.commit('setSaveFlg', true);
          this.$router.push('/MyCompany/View');
        } else {
          //保存フラグをtrueに設定（ページ移動の警告メッセージを防ぐ為）
          this.$store.commit('setSaveFlg', true);
          this.$router.push('/Company/View/' + this.id);
        }
      },
      /**
       * METHOD: ファイルアップロード
       */
      fileUpload: function() {
        this.$refs.logo.companyId = this.id;
        this.$refs.logo.fileUpload();
      },
      /**
       * METHOD: メッセージモーダルクローズ時
       */
      closeModal: async function() {
        if (this.status == 'OK') {
          //保存フラグをtrueに設定（ページ移動の警告メッセージを防ぐ為）
          this.$store.commit('setSaveFlg', true);
          this.$router.push('/Company/List');
        }
      },
      /**
       * METHOD: 会社情報取得
       */
      async getCompanyInfo() {
        //会社情報を取得
        const result = await API.graphql(graphqlOperation(getCompany, { id: this.id }));
        //会社情報を格納
        this.companyInfo = result.data.getCompany;
      },
      /**
       * METHOD: 画面Key更新
       */
      updateDisplayKey() {
        this.displayKey = new Date().toISOString();
      },
    },
    computed: {
      /**
       * COMPUTED: モード
       */
      mode() {
        //経路によってモードを設定
        if (
          this.$router.currentRoute.path.startsWith('/MyCompany/Edit/') ||
          this.$router.currentRoute.path.startsWith('/Company/Edit/')
        ) {
          return 'Edit';
        } else if (this.$router.currentRoute.path.endsWith('/Add')) {
          return 'Add';
        } else {
          return 'View';
        }
      },
      /**
       * COMPUTED: 登録日
       */
      createdDate() {
        let result;
        try {
          const date = new Date(this.companyInfo.createdAt);
          result = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
        } catch {
          result = null;
        }
        delete this.companyInfo.createdAt;
        return result;
      },
      /**
       * COMPUTED: 更新日
       */
      updatedDate() {
        let result;
        try {
          const date = new Date(this.companyInfo.updatedAt);

          result = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
        } catch {
          result = null;
        }
        delete this.companyInfo.updatedAt;
        return result;
      },
      /**
       * COMPUTED: タイトル
       */
      title: function() {
        return '会社情報' + (this.mode == 'Add' ? '登録' : this.mode == 'View' ? '詳細' : '編集');
      },
      /**
       * COMPUTED: メッセージ
       */
      message: function() {
        switch (this.status) {
          case 'OK':
            return '登録完了しました。';
          case 'ErrorRegistrationNumber':
            return 'すでに登録されている法人番号です。';
          case 'ErrorAccount':
            return 'アカウント登録エラー';
          default:
            return null;
        }
      },
    },
    watch: {
      /**
       * WATCH: 会社IDを監視し、会社情報を取得する
       */
      async id() {
        //編集、詳細画面の場合は会社情報をロードする
        if (this.mode == 'View' || this.mode == 'Edit') {
          //会社情報を取得
          this.getCompanyInfo();
        }
      },
      /**
       * WATCH: 画面Keyを監視し、会社情報を取得する
       */
      displayKey() {
        // 会社情報をロードする
        this.getCompanyInfo();
      },
    },
    components: {
      License,
      Load,
      payment,
      NonSettlement,
      FreeLicenseCheck,
    },
  };
</script>
<style scoped>
  #CompanyView >>> .container {
    max-width: 100%;
  }
  .ally-input-background .container {
    padding: 1rem;
  }
  #CompanyView >>> nav {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }
  #CompanyView >>> nav > ul > button {
    margin-left: 0.5rem;
  }
  #CompanyView >>> .card {
    border-top-color: gainsboro !important;
    margin-bottom: 1rem;
  }
  .company-items > .col {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  #app .main-panel .card {
    border-top-color: gainsboro !important;
  }
  #LicenseMain {
    margin: 1rem;
  }
  .input-group #createdDate,
  .input-group #updatedDate,
  .input-group #customerNumber,
  #createdDate {
    text-align: center;
    padding: 0;
  }
  .appendix {
    font-size: small;
    position: relative;
    top: -1rem;
  }
</style>
<style>
  #createdDate,
  #updatedDate,
  #customerNumber {
    text-align: center;
    padding: 0;
  }
</style>
