<template>
  <b-card-group deck>
    <b-card header="立地条件">
      <b-container>
        <b-row>
          <b-col>
            <allyText
              Area
              title="物件名"
              name="locationRequirement-name"
              v-model="inputValue.locationRequirement.name"
              rows="10"
              rules="required"
              :loading="loading"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <allyText
              Area
              title="メモ"
              name="locationRequirement-memo"
              v-model="inputValue.locationRequirement.memo"
              rows="10"
              rules="max:10"
              :loading="loading"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <allyText
              Area
              title="交通"
              name="locationRequirement-traffic"
              v-model="inputValue.locationRequirement.traffic"
              rows="10"
              :loading="loading"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <allyText
              Area
              title="周辺の土地利用状況"
              name="locationRequirement-landUseInTheVicinity"
              v-model="inputValue.locationRequirement.landUseInTheVicinity"
              rows="10"
              :loading="loading"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <allyImages
              title="案内図"
              class="images"
              mode="Edit"
              v-model="guideMap"
              :loading="loading"
              name="guideMap"
              ref="guideMap"
              :max="1"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-card header="具体的活用法画像">
      <b-container>
        <b-row>
          <b-col>
            <allyImages
              title="更地・貸地"
              class="images"
              mode="Edit"
              v-model="vacantLandAndLeasedLand"
              :loading="loading"
              name="vacantLandAndLeasedLand"
              ref="vacantLandAndLeasedLand"
              :max="1"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <allyImages
              title="月極駐車場"
              class="images"
              mode="Edit"
              v-model="monthlyParkingLot"
              :loading="loading"
              name="monthlyParkingLot"
              ref="monthlyParkingLot"
              :max="1"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <allyImages
              title="集合型賃貸(独身)"
              class="images"
              mode="Edit"
              v-model="groupRental"
              :loading="loading"
              name="groupRental"
              ref="groupRental"
              :max="1"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <allyImages
              title="集合型賃貸(家族)"
              class="images"
              mode="Edit"
              v-model="groupRentalFamily"
              :loading="loading"
              name="groupRentalFamily"
              ref="groupRentalFamily"
              :max="1"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <allyImages
              title="戸建賃貸"
              class="images"
              mode="Edit"
              v-model="detachedHousesForRent"
              :loading="loading"
              name="detachedHousesForRent"
              ref="detachedHousesForRent"
              :max="1"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <allyImages
              title="売却して現金化"
              class="images"
              mode="Edit"
              v-model="sellAndCashOut"
              :loading="loading"
              name="sellAndCashOut"
              ref="sellAndCashOut"
              :max="1"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </b-card-group>
</template>
<script>
  export default {
    props: {
      inputValue: {
        type: Object,
        required: true,
      },
      calcFlg: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        loading: false,
      };
    },
    computed: {
      guideMap: {
        get() {
          if (this.inputValue.locationRequirement.guideMap == null) {
            return [];
          }
          return [this.inputValue.locationRequirement.guideMap];
        },
        set(input) {
          if (input.length != 1) {
            this.inputValue.locationRequirement.guideMap = null;
            return;
          }
          this.$refs.guideMap.fileUpload();
          this.inputValue.locationRequirement.guideMap = input[0];
        },
      },
      vacantLandAndLeasedLand: {
        get() {
          if (
            this.inputValue.locationRequirement.concreteApplicationImages.vacantLandAndLeasedLand ==
            null
          ) {
            return [];
          }
          return [
            this.inputValue.locationRequirement.concreteApplicationImages.vacantLandAndLeasedLand,
          ];
        },
        set(input) {
          if (input.length != 1) {
            this.inputValue.locationRequirement.concreteApplicationImages.vacantLandAndLeasedLand = null;
            return;
          }
          this.$refs.vacantLandAndLeasedLand.fileUpload();
          this.inputValue.locationRequirement.concreteApplicationImages.vacantLandAndLeasedLand =
            input[0];
        },
      },
      monthlyParkingLot: {
        get() {
          if (
            this.inputValue.locationRequirement.concreteApplicationImages.monthlyParkingLot == null
          ) {
            return [];
          }
          return [this.inputValue.locationRequirement.concreteApplicationImages.monthlyParkingLot];
        },
        set(input) {
          if (input.length != 1) {
            this.inputValue.locationRequirement.concreteApplicationImages.monthlyParkingLot = null;
            return;
          }
          this.$refs.monthlyParkingLot.fileUpload();
          this.inputValue.locationRequirement.concreteApplicationImages.monthlyParkingLot =
            input[0];
        },
      },
      groupRental: {
        get() {
          if (this.inputValue.locationRequirement.concreteApplicationImages.groupRental == null) {
            return [];
          }
          return [this.inputValue.locationRequirement.concreteApplicationImages.groupRental];
        },
        set(input) {
          if (input.length != 1) {
            this.inputValue.locationRequirement.concreteApplicationImages.groupRental = null;
            return;
          }
          this.$refs.groupRental.fileUpload();
          this.inputValue.locationRequirement.concreteApplicationImages.groupRental = input[0];
        },
      },
      groupRentalFamily: {
        get() {
          if (
            this.inputValue.locationRequirement.concreteApplicationImages.groupRentalFamily == null
          ) {
            return [];
          }
          return [this.inputValue.locationRequirement.concreteApplicationImages.groupRentalFamily];
        },
        set(input) {
          if (input.length != 1) {
            this.inputValue.locationRequirement.concreteApplicationImages.groupRentalFamily = null;
            return;
          }
          this.$refs.groupRentalFamily.fileUpload();
          this.inputValue.locationRequirement.concreteApplicationImages.groupRentalFamily =
            input[0];
        },
      },
      detachedHousesForRent: {
        get() {
          if (
            this.inputValue.locationRequirement.concreteApplicationImages.detachedHousesForRent ==
            null
          ) {
            return [];
          }
          return [
            this.inputValue.locationRequirement.concreteApplicationImages.detachedHousesForRent,
          ];
        },
        set(input) {
          if (input.length != 1) {
            this.inputValue.locationRequirement.concreteApplicationImages.detachedHousesForRent = null;
            return;
          }
          this.$refs.detachedHousesForRent.fileUpload();
          this.inputValue.locationRequirement.concreteApplicationImages.detachedHousesForRent =
            input[0];
        },
      },
      sellAndCashOut: {
        get() {
          if (
            this.inputValue.locationRequirement.concreteApplicationImages.sellAndCashOut == null
          ) {
            return [];
          }
          return [this.inputValue.locationRequirement.concreteApplicationImages.sellAndCashOut];
        },
        set(input) {
          if (input.length != 1) {
            this.inputValue.locationRequirement.concreteApplicationImages.sellAndCashOut = null;
            return;
          }
          this.$refs.sellAndCashOut.fileUpload();
          this.inputValue.locationRequirement.concreteApplicationImages.sellAndCashOut = input[0];
        },
      },
    },
  };
</script>
<style scoped>
  #realEstateSuitability .nav-link {
    font-size: medium;
  }
  #realEstateSuitability table tr {
    height: 1.5em;
  }
  #realEstateSuitability .component {
    padding: 0;
  }
  #realEstateSuitability table tbody td,
  #realEstateSuitability table thead th {
    padding-top: 0.1em;
    padding-bottom: 0.1em;
  }
  #realEstateSuitability .title {
    padding-right: 0.5em;
    display: grid;
    text-align: right;
    font-weight: bold;
    align-items: center;
  }
  #realEstateSuitability .value {
    padding: 0;
    margin: 0;
    display: table-cell;
    align-items: center;
  }
  #realEstateSuitability .unit {
    padding-left: 0.5em;
    padding-right: 0;
    display: flex;
    text-align: left;
    align-items: center;
  }
  #realEstateSuitability .currency {
    text-align: right;
  }
  #realEstateSuitability .ally-select select {
    height: 2.875rem;
  }
  #realEstateSuitability .form-group {
    margin-top: 0;
    margin-bottom: 0;
  }
  #realEstateSuitability .form-control {
    padding: 0.5em;
  }
  #realEstateSuitability .form-group label,
  #realEstateSuitability .form-check-label {
    font-weight: bold;
  }
  #realEstateSuitability .checkbox,
  #realEstateSuitability .checkbox label {
    font-weight: bold;
  }
  #realEstateSuitability #actualInheritancePercentage tr {
    height: 1.5em;
  }
  #realEstateSuitability input[type='number'] {
    text-align: right;
  }
  #realEstateSuitability legend {
    padding-bottom: 0.5em;
    font-weight: bold;
  }
  #realEstateSuitability input[type='radio'] {
    margin-left: unset;
  }
  #realEstateSuitability input[name='rentLandRadios'] + label,
  #realEstateSuitability #spouse label {
    font-size: initial;
  }
  #realEstateSuitability .card {
    margin-top: 0.5em;
    border-top-color: gainsboro !important;
  }
  #realEstateSuitability #ownerInformation .card,
  #realEstateSuitability #ownerInformation .card-body,
  #realEstateSuitability #siteInformation .card,
  #realEstateSuitability #siteInformation .card-body {
    border: 0 !important;
    padding-top: 0.5em;
    padding-bottom: 0;
    padding-left: 0.25em;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  #realEstateSuitability .card-header {
    font-size: medium;
    font-weight: bold;
  }
  #defaultValue #rentalProperty,
  #masterPlan #rentalProperty {
    max-width: unset;
  }
  #defaultValue #parking .row,
  #defaultValue #sale .row,
  #siteConditions .row,
  #locationConditions .row {
    margin-bottom: 1em;
  }
  #defaultValue #parking .unit,
  #defaultValue #sale .unit,
  #defaultValue #vacantLand .unit,
  #siteConditions .unit {
    padding-bottom: 1em;
    align-items: flex-end;
  }
</style>
