<!--
作成者：本間
作成日：2021.02.15
画面名：投資分析/入力
機能：初期投資/工事計画
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="ConstructionPlan">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button variant="link" v-b-toggle.tabConstructionPlan>
          工事計画
        </b-button>
      </b-card-header>
      <b-collapse id="tabConstructionPlan" role="tabpanel" visible>
        <b-card-body style="padding-top:0;">
          <b-container>
            <table>
              <tbody>
                <tr>
                  <td>
                    <b-form-group
                      style="margin:1em;font-size:large;"
                      label="入力する項目を選択してください:"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-checkbox-group
                        id="constructionPlanSwiches"
                        v-model="selectedConstructions"
                        :options="optionsConstructions"
                        :aria-describedby="ariaDescribedby"
                        switches
                        size="lg"
                        style="font-weight:normal;"
                        @input="changeConstructions"
                      >
                      </b-form-checkbox-group>
                    </b-form-group>
                  </td>
                  <td width="10%">
                    <b-button
                      id="constructionPlanSwiches-select-all"
                      variant="ally"
                      style="width:100%;"
                      size="sm"
                      @click="checkAll"
                      >すべて選択</b-button
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </b-container>
          <b-container class="detail" style="max-width:800px;margin-left:1rem;">
            <b-row>
              <b-col class="title bold">項目</b-col>
              <b-col class="title bold">細目</b-col>
              <b-col>
                <b-row>
                  <b-col class="title-center bold" style="height:2rem;">工事費</b-col>
                  <b-col cols="2" />
                </b-row>
              </b-col>
              <b-col cols="1" style="padding:0 4px 0 0;" class="title-right">詳細</b-col>
            </b-row>
            <!-- 新築工事 -->
            <NewBuildingConstruction
              v-if="investmentAnalytics.initialInvestment.constructionPlan.selected[0] == 1"
            />
            <!-- 修繕工事 -->
            <RepairWork
              v-if="investmentAnalytics.initialInvestment.constructionPlan.selected[1] == 1"
            />
            <!-- 設備更新工事 -->
            <EquipmentRenewal
              v-if="investmentAnalytics.initialInvestment.constructionPlan.selected[2] == 1"
            />
            <!-- 造成工事 -->
            <SitePreparation
              v-if="investmentAnalytics.initialInvestment.constructionPlan.selected[3] == 1"
            />
            <!-- 外構工事 -->
            <ExteriorConstruction
              v-if="investmentAnalytics.initialInvestment.constructionPlan.selected[4] == 1"
            />
            <!-- 建築付帯工事 -->
            <ConstructionIncidentalWork
              v-if="investmentAnalytics.initialInvestment.constructionPlan.selected[5] == 1"
            />
            <!-- 道路切り下げ工事 -->
            <RoadLawArticle24
              v-if="investmentAnalytics.initialInvestment.constructionPlan.selected[6] == 1"
            />
            <!-- 解体工事 -->
            <Demolition
              v-if="investmentAnalytics.initialInvestment.constructionPlan.selected[7] == 1"
            />
            <!-- 土壌改良工事 -->
            <SoilImprovementWork
              v-if="investmentAnalytics.initialInvestment.constructionPlan.selected[8] == 1"
            />
            <!-- 地盤改良工事 -->
            <GroundImprovementWork
              v-if="investmentAnalytics.initialInvestment.constructionPlan.selected[9] == 1"
            />
            <!-- その他 -->
            <OtherConstruction
              v-if="investmentAnalytics.initialInvestment.constructionPlan.selected[10] == 1"
            />
            <b-row>
              <b-col class="borderline" />
              <b-col cols="1" />
            </b-row>
            <b-row>
              <b-col class="title bold">工事総額</b-col>
              <b-col class="title">(税抜)</b-col>
              <b-col>
                <b-row>
                  <b-col c class="component">
                    <b-form-input
                      disabled
                      class="currency"
                      :value="constructionPlanTotal | currency"
                    />
                  </b-col>
                  <b-col cols="2" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col cols="1" />
            </b-row>
            <b-row>
              <b-col class="title bold" />
              <b-col class="title">(税込)</b-col>
              <b-col>
                <b-row>
                  <b-col class="component">
                    <b-form-input
                      disabled
                      class="currency"
                      :value="sumTax(Number(constructionPlanTotal)) | currency"
                    />
                  </b-col>
                  <b-col cols="2" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col cols="1" />
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Calculation from './../Calculation';
  import NewBuildingConstruction from './NewBuildingConstruction';
  import EquipmentRenewal from './EquipmentRenewal';
  import RepairWork from './RepairWork';
  import SitePreparation from './SitePreparation';
  import ExteriorConstruction from './ExteriorConstruction';
  import ConstructionIncidentalWork from './ConstructionIncidentalWork';
  import RoadLawArticle24 from './RoadLawArticle24';
  import Demolition from './Demolition';
  import SoilImprovementWork from './SoilImprovementWork';
  import GroundImprovementWork from './GroundImprovementWork';
  import OtherConstruction from './OtherConstruction';
  export default {
    mixins: [Calculation],
    components: {
      NewBuildingConstruction,
      RepairWork,
      EquipmentRenewal,
      SitePreparation,
      ExteriorConstruction,
      ConstructionIncidentalWork,
      RoadLawArticle24,
      Demolition,
      SoilImprovementWork,
      GroundImprovementWork,
      OtherConstruction,
    },
    data: function() {
      return {
        // 工事計画
        // ※チェックされた項目のvalueのみ格納される。
        selectedConstructions: [],
        // 工事計画
        optionsConstructions: [
          { text: '新築工事', value: 0 },
          { text: '修繕工事', value: 1 },
          { text: '設備更新工事', value: 2 },
          { text: '造成工事', value: 3 },
          { text: '外構工事', value: 4 },
          { text: '建築付帯工事', value: 5 },
          { text: '道路切り下げ工事', value: 6 },
          { text: '解体工事', value: 7 },
          { text: '土壌改良工事', value: 8 },
          { text: '地盤改良工事', value: 9 },
          { text: 'その他', value: 10 },
        ],
      };
    },
    mounted: function() {
      // 工事計画チェック初期化
      this.initCheckBoxes();
    },
    methods: {
      // 工事計画チェック初期化
      initCheckBoxes: function() {
        this.selectedConstructions = [];
        for (let id in this.investmentAnalytics.initialInvestment.constructionPlan.selected) {
          if (this.investmentAnalytics.initialInvestment.constructionPlan.selected[id] > 0)
            this.selectedConstructions.push(Number(id));
        }
      },
      // 工事計画チェック変更時
      changeConstructions: function() {
        for (let id in this.investmentAnalytics.initialInvestment.constructionPlan.selected) {
          // 状態配列を更新
          Vue.set(
            this.investmentAnalytics.initialInvestment.constructionPlan.selected,
            id,
            this.selectedConstructions.includes(Number(id)) ? 1 : 0
          );
        }
      },
      // すべてチェック
      checkAll: function() {
        this.selectedConstructions = [];
        for (let id in this.investmentAnalytics.initialInvestment.constructionPlan.selected) {
          // 状態配列を更新
          Vue.set(this.investmentAnalytics.initialInvestment.constructionPlan.selected, id, 1);
          this.selectedConstructions.push(Number(id));
        }
      },
    },
  };
</script>
