/**
 * 計算の定数
 */
//計算の精密さ(試行回数が少ないとエラーになることがある)
var epsMax = 1e-16;

//試行回数(回数が少ないとerrorになることがある。)
var iterMax = 100;

//返却するときに四捨五入して返却する桁数
var maxDigits = 15;

/**
 * 二分法でIRRの計算を行う
 * @param {object} values 配列で数値を入力する
 * @param {number} guess
 * @returns -1~1の数値 or "error"
 */
export default {
  methods: {
    calcIRR: function(values, guess) {
      try {
        var dates = [];
        var positive = false; // values内の正値の存在性フラグ
        var negative = false; // values内の負値の存在性フラグ
        for (var i = 0; i < values.length; i++) {
          dates[i] = i === 0 ? 0 : dates[i - 1] + 365;
          if (values[i] > 0) positive = true;
          if (values[i] < 0) negative = true;
        }

        if (!positive || !negative) return '#NUM!';

        var getGuess = typeof guess === 'undefined' ? 0.1 : guess;
        var resultRate = getGuess;

        var newRate, epsRate, resultValue;
        var iteration = 0;
        var contLoop = true;
        let isInfinity = false;
        do {
          resultValue = IRRFormula(values, dates, resultRate);
          newRate = resultRate - resultValue / IRRFirstDerivation(values, dates, resultRate);
          epsRate = Math.abs(newRate - resultRate);
          resultRate = newRate;
          contLoop = epsRate > epsMax && Math.abs(resultValue) > epsMax;
          if (resultRate === Infinity) {
            isInfinity = true;
          }
        } while (contLoop && ++iteration < iterMax);

        // ループ判定フラグがtrueのままであれば、収束しなかったということでエラー
        if (contLoop) return '#NUM!';

        if (isInfinity) {
          resultRate = halfIntervalMethod(values, dates);
        }

        return (Math.round(resultRate * 10 ** maxDigits) / 10 ** maxDigits) * 100;
      } catch (e) {
        //失敗した場合はエラーを変換する
        console.error(e);
        return '#NUM!';
      }
    },
  },
};

//================================================================================
// 下記はIRRを求める途中に必要な関数
//================================================================================

function IRRFormula(values, dates, rate) {
  var r = rate + 1;
  var result = values[0];
  for (var i = 1; i < values.length; i++) {
    result += values[i] / Math.pow(r, (dates[i] - dates[0]) / 365);
  }
  return result;
}

function IRRFirstDerivation(values, dates, rate) {
  var r = rate + 1;
  var result = 0;
  for (var i = 1; i < values.length; i++) {
    var frac = (dates[i] - dates[0]) / 365;
    result -= (frac * values[i]) / Math.pow(r, frac + 1);
  }
  return result;
}

function halfIntervalMethod(values, dates) {
  var a = 1;
  var b = -0.999; //-1だどエラーになる

  var iteration = 0;
  let countLoop = true;
  var result;
  let aResult = IRRFormula(values, dates, a);
  let bResult = IRRFormula(values, dates, b);

  let IRRroot;

  if (aResult - bResult <= 0) {
    a = -0.99;
    b = 1;
  }

  do {
    let interMediate = (a + b) / 2;
    result = IRRFormula(values, dates, interMediate);
    if (result < 0) {
      b = interMediate;
    } else if (result > 0) {
      a = interMediate;
    } else {
      IRRroot = interMediate;
    }
    countLoop = Math.abs(a - b) > epsMax;
    if (!countLoop) {
      IRRroot = interMediate;
    }
  } while (countLoop && ++iteration < iterMax);

  return IRRroot;
}
