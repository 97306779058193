// @ts-check

import { DrawStairsActionBuilder } from '../../action/builder/drawStairsActionBuilder';
import { EventPresetBuilder } from '../eventPresetBuilder';
import { EventPreset } from '../eventPreset';

export class StairsPreset extends EventPresetBuilder {
  build() {
    const preset = new EventPreset();
    let isMouseDown = false; // DATA: mousedownフラグ
    const { getObjectPreset } = this.context;
    let targetChildrenLength = 0; // DATA: 部屋の子オブジェクト数
    let clickTarget = null; // DATA: mousedownした時のオブジェクト

    preset.mousedown = dataset => {
      const objCode = getObjectPreset().code; // DATA: 建具コード
      if (dataset.target === 'outside' || (objCode !== 4301 && objCode !== 4302)) {
        return;
      }
      // クリックしたオブジェクト情報を取得できていたら「床」のみ取得
      const target =
        dataset.targetObject != null ? dataset.targetObject.findUpByType('floor') : null;

      if (dataset.button === 'left') {
        // 現在のクリックしたオブジェクト情報を保持
        clickTarget = target;
        // 現在の部屋の子オブジェクト数を取得
        targetChildrenLength = target?.children.length ?? 0;
        this.actionManager.setAction(new DrawStairsActionBuilder(this.context, dataset));
      }
      isMouseDown = true;
    };

    preset.mousemove = dataset => {
      if (
        dataset.target === 'outside' ||
        (getObjectPreset().code !== 4301 && getObjectPreset().code !== 4302) ||
        !isMouseDown
      ) {
        return;
      }

      if (dataset.button === 'left') {
        // <DrawStairsActionBuilder>型のインスタンス作成
        const drawObject = new DrawStairsActionBuilder(this.context, dataset);
        // 現在の部屋の子オブジェクト数を渡す
        drawObject.targetChildrenLength = targetChildrenLength;
        this.actionManager.setAction(drawObject);
      }
    };

    preset.mouseup = dataset => {
      const objCode = getObjectPreset().code; // DATA: 建具コード
      isMouseDown = false;
      if (dataset.target === 'outside' || (objCode !== 4301 && objCode !== 4302)) {
        return;
      }

      if (dataset.button === 'left') {
        this.actionManager.setAction(new DrawStairsActionBuilder(this.context, dataset));
        // クリックしたオブジェクト情報を取得できていたら「床」のみ取得
        const target =
          dataset.targetObject != null ? dataset.targetObject.findUpByType('floor') : null;
        if (target?.type !== 'floor' && clickTarget !== null) {
          // mousedownで生成したPvStairsを削除
          clickTarget.lastChild.removeSelf();
        } else {
          // 螺旋階段をヒストリーに登録
          this.actionManager.onAfterComplete();
          this.actionManager.onAfterCompleteOrAbort();
        }
      }
    };

    preset.click = dataset => {
      const objectPreset = this.context.getObjectPreset();
      if (
        dataset.target === 'outside' ||
        objectPreset.code === 4301 ||
        objectPreset.code === 4302
      ) {
        return;
      }

      if (dataset.button === 'left') {
        this.actionManager.setAction(new DrawStairsActionBuilder(this.context, dataset));
      }
    };

    return preset;
  }
}
