<template>
  <g style="pointer-events: none" :transform="`scale(${gridScale})`">
    <defs>
      <pattern id="water-mark" width="1200" height="800" patternUnits="userSpaceOnUse">
        <image ref="image" href="/planeView/water-mark.png" width="1200" height="800" />
      </pattern>
    </defs>
    <rect
      v-if="showWaterMark"
      :width="width"
      :height="height"
      :fill="`url(#water-mark)`"
      style="opacity: 0.8"
    />
    <path
      v-if="showGrid"
      :style="{
        'pointer-events': 'none',
      }"
      stroke="#bbb"
      fill="none"
      :d="sLinePath"
    />
    <path
      v-if="showGrid"
      :style="{
        'pointer-events': 'none',
      }"
      stroke="#bbb"
      fill="none"
      stroke-width="4"
      :d="lLinePath"
    />
  </g>
</template>
<script>
  //@ts-check
  import { defineComponent, ref, toRefs, watch } from '@vue/composition-api';

  export default defineComponent({
    props: {
      width: Number,
      height: Number,
      zoom: Number,
      largeGridPx: Number,
      smallGridPx: Number,
      gridScale: Number,
      showWaterMark: Boolean,
      showGrid: Boolean,
    },
    setup(props) {
      const sLinePath = ref('');
      const lLinePath = ref('');

      const { width, height, largeGridPx, smallGridPx } = toRefs(props);

      watch(
        [height, width, largeGridPx, smallGridPx],
        () => {
          let x = 0;
          while (x <= width.value) {
            if (x % largeGridPx.value === 0) {
              lLinePath.value += `M${x} 0 L${x} ${height.value}`;
            } else {
              sLinePath.value += `M${x} 0 L${x} ${height.value}`;
            }
            x += smallGridPx.value;
          }
          let y = 0;
          while (y <= height.value) {
            if (y % largeGridPx.value === 0) {
              lLinePath.value += `M0 ${y} L${width.value} ${y}`;
            } else {
              sLinePath.value += `M0 ${y} L${width.value} ${y}`;
            }
            y += smallGridPx.value;
          }
        },
        {
          immediate: true,
        }
      );
      return {
        sLinePath,
        lLinePath,
      };
    },
  });
</script>
