var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-page",style:([
    _vm.style,
    {
      margin: '0 auto',
      position: 'relative',
      'background-image':
        'url(https://allys3163848-dev.s3-ap-northeast-1.amazonaws.com/Master/report/reportCommonImage3.png)',
      'background-repeat': 'no-repeat',
      'background-size': '100% 100%',
    } ])},[_c('div',[_c('div',{staticClass:"report-header-design",style:({
        'font-size': 200 * _vm.scale + 'px',
        'padding-left': 200 * _vm.scale + 'px',
        'padding-top': 120 * _vm.scale + 'px',
        'margin-bottom': -125 + 'px',
        height: '100%',
        'text-align': 'left',
      })},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{style:(_vm.bodyStyle)},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }