<!--
作成者：本間
作成日：2021.02.15
画面名：投資分析/入力
機能：初期投資/工事計画/土壌改良工事
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="SoilImprovementWork">
    <!-- 概算入力 -->
    <b-row>
      <b-col class="title bold">土壌改良工事</b-col>
      <b-col />
      <b-col class="component">
        <b-row>
          <b-col class="component">
            <allyText
              type="number"
              class="number"
              :separation="true"
              halfWidth
              v-model="
                investmentAnalytics.initialInvestment.constructionPlan.constructionCost[17].amount
              "
              roundType="down"
            />
          </b-col>
          <b-col cols="2" class="unit">円</b-col>
        </b-row>
      </b-col>
      <b-col cols="1" class="title">
        <b-button variant="ally" v-b-toggle.tabInitialInvestment59>
          <i class="when-open ti-angle-double-up" />
          <i class="when-closed ti-angle-double-down" />
        </b-button>
      </b-col>
      <!-- 土壌改良工事 -->
    </b-row>
    <!-- 詳細入力 -->
    <b-row>
      <b-col>
        <b-collapse id="tabInitialInvestment59" role="tabpanel">
          <b-card-body>
            <b-container>
              <b-row>
                <b-col cols="2" class="title bold">工事面積</b-col>
                <b-col cols="3" class="component">
                  <allyText
                    type="number"
                    class="number"
                    :separation="true"
                    v-model="
                      investmentAnalytics.initialInvestment.constructionPlan.constructionCost[17]
                        .quantity
                    "
                    :floatLength="2"
                    roundType="down"
                  />
                </b-col>
                <b-col cols="1" class="unit">㎡</b-col>
                <b-col cols="1" class="title-right" style="white-space: nowrap;">※地積</b-col>
                <b-col cols="3" class="component">
                  <allyText
                    type="number"
                    class="number"
                    mode="View"
                    :separation="true"
                    v-model="investmentAnalytics.initialInvestment.propertyDescription[0].area"
                    :floatLength="2"
                    roundType="down"
                  />
                </b-col>
                <b-col cols="1" class="unit">㎡</b-col>
              </b-row>
              <b-row>
                <b-col cols="2" class="title bold">単価</b-col>
                <b-col cols="3" class="component">
                  <b-form-input
                    disabled
                    class="currency"
                    :value="getUnitPrice('土壌改良工事', '有') | currency"
                  />
                </b-col>
                <b-col cols="1" class="unit">円/㎡</b-col>
              </b-row>
              <b-row>
                <b-col cols="2" class="title bold">工事費</b-col>
                <b-col cols="3" class="component">
                  <allyText
                    type="number"
                    class="number"
                    :separation="true"
                    halfWidth
                    v-model="
                      investmentAnalytics.initialInvestment.constructionPlan.constructionCost[17]
                        .amount
                    "
                    roundType="down"
                  />
                </b-col>
                <b-col cols="1" class="unit">円</b-col>
                <b-col cols="1" class="title-right" style="white-space: nowrap;">※目安</b-col>
                <b-col cols="3" class="component">
                  <b-form-input
                    disabled
                    class="currency"
                    :value="
                      ((Math.floor(
                        investmentAnalytics.initialInvestment.constructionPlan.constructionCost[17]
                          .quantity * 100
                      ) /
                        100) *
                        getUnitPrice('土壌改良工事', '有'))
                        | currency
                    "
                  />
                </b-col>
                <b-col cols="1" class="unit">円</b-col>
                <button
                  class="btn btn-ally mr-1 cols='1'"
                  style="width: 50px; white-space: nowrap; padding-left: 10px; "
                  @click="
                    changeGuideline(
                      investmentAnalytics.initialInvestment.constructionPlan.constructionCost[17]
                        .quantity * getUnitPrice('土壌改良工事', '有')
                    )
                  "
                >
                  反映
                </button>
              </b-row>
            </b-container>
          </b-card-body>
        </b-collapse>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import Vue from 'vue';
  export default {
    data: function() {
      return {
        // 選択項目
        soilImprovementItems: this.getUnitPricesItems('土壌改良工事'),
      };
    },
    methods: {
      changeGuideline: function(sum) {
        Vue.set(
          this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[17],
          'amount',
          sum
        );
      },
    },
    // マスタ更新時
    '$store.state.master.unitPrice': {
      handler: function() {
        // 選択項目更新
        this.soilImprovementItems = this.getUnitPricesItems('土壌改良工事');
      },
      deep: false,
    },
  };
</script>
