<template>
  <g
    :data-type="obj.type"
    :class="[
      'pvnode-root',
      obj.handleFlg.outline && selectedIdSet.has(obj.id) ? 'selected' : undefined,
    ]"
    :transform="transform"
  >
    <!-- real -->
    <g v-if="obj.type === 'root'"></g>
    <g v-if="obj.type === 'handle'"></g>
    <Floor v-else-if="obj.type === 'floor'" :obj="obj" class="pv-node" />
    <Icon v-else-if="obj.type === 'icon'" :obj="obj" />
    <Wall v-else-if="obj.type === 'wall'" :obj="obj" />
    <Door v-else-if="obj.type === 'door'" :obj="obj" />
    <Stairs v-else-if="obj.type === 'stairs'" :obj="obj" />
    <PvText v-else-if="obj.type === 'text'" :obj="obj" />
    <Group v-else-if="obj.type === 'group'" :obj="obj" />
    <!-- measure -->
    <Measure v-else-if="obj.type === 'measure'" :obj="obj" />
    <MeasurePart v-else-if="obj.type === 'measurePart'" :obj="obj" />
    <!-- guide -->
    <GuideMarker v-else-if="obj.type === 'guideMarker'" :obj="obj" />
    <!-- handle -->
    <CircleHandle v-else-if="obj.type === 'circleHandle'" :obj="obj" />
    <PrintArea v-else-if="obj.type === 'printArea'" :obj="obj" />

    <text v-else> 未定義のタイプ {{ err(obj.type) }} </text>
  </g>
</template>
<script>
  // @ts-check
  import { defineComponent, toRefs, computed } from '@vue/composition-api';
  import Floor from './Floor.vue';
  import Icon from './Icon.vue';
  import Wall from './Wall.vue';
  import Door from './Door.vue';
  import Stairs from './Stairs.vue';
  import Group from './Group.vue';
  import PvText from './PvText.vue';
  import Measure from './Measure.vue';
  import MeasurePart from './MeasurePart.vue';
  import CircleHandle from './CircleHandle.vue';
  import GuideMarker from './GuideMarker.vue';
  import PrintArea from './PrintArea.vue';
  import { PvObject } from '../../lib/node/node/pvObject';
  import { radToDeg } from '../../../Property/PlaneView/SvgEditor/lib/math/common';
  import { Rect } from '../../lib/util/math/rect';

  export default defineComponent({
    props: {
      obj: {
        type: Object,
        required: true,
      }, // PvObject
      selectedIdSet: {
        type: Set,
        default: () => new Set(),
      },
    },
    components: {
      Floor,
      Icon,
      Wall,
      Door,
      Stairs,
      PvText,
      Group,
      GuideMarker,
      Measure,
      MeasurePart,
      CircleHandle,
      PrintArea,
    },
    setup(props) {
      /**
       * @type {{
       * obj: import('@vue/composition-api').Ref<PvObject>
       * }} */
      // @ts-ignore
      const { obj } = toRefs(props);

      const bbox = computed(() => {
        const o = obj.value;
        const vertexes = o.vertexes;
        const box = Rect.fromVecs(vertexes);
        return box;
      });

      const transform = computed(() => {
        const o = obj.value;
        const tr = o.absoluteTransform();
        return `translate(${tr.translate.x}, ${tr.translate.y}) scale(${tr.scale.x},${
          tr.scale.y
        }) rotate(${radToDeg(tr.rotate)})`;
      });

      const err = (/** @type {string} */ type) => {
        throw new Error(
          '未定義のタイプ: ' +
            type +
            '. PvNode.vueにタイプに対応するコンポーネントが存在するか確認してください.'
        );
      };

      return {
        bbox,
        transform,
        err,
      };
    },
  });
</script>
<style scoped>
  .selected.pvnode-root {
    outline: skyblue 5px solid;
  }
</style>
