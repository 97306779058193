import awsconfig from '../../../aws-exports';
import { API, graphqlOperation } from 'aws-amplify';
import { listCompanys, getAozoraPayment } from '../../../graphql/queries';
import { updateCompany } from '@/graphql/mutations';

export default {
  methods: {
    /**
     *  METHOD: 会社情報一覧取得
     * @returns {company[]}
     */
    getCompanys: async function() {
      var companyList = [];

      try {
        let queryVar = {
          limit: 60,
          nextToken: null,
        };
        do {
          // 会社情報取得
          var result = await API.graphql(graphqlOperation(listCompanys, queryVar));
          queryVar.nextToken = result.data.listCompanys.nextToken;
          companyList = [...result.data.listCompanys.items, ...companyList];
        } while (queryVar.nextToken != null);

        //会社情報返却
        return companyList;
      } catch (e) {
        console.error(e);
        return;
      }
    },

    /**
     *  METHOD: 会社情報更新
     * @param company
     */
    async updateCompany(company) {
      try {
        // パラメータ生成
        let param = [];
        param = {
          id: company.id,
          payment: company.payment,
        };
        await API.graphql(
          graphqlOperation(updateCompany, {
            input: param,
          })
        );
      } catch (error) {
        console.error('ERROR:' + error + ', Company:' + company);
        alert(
          '登録時に問題が発生しました。時間をおいて再度お試しいただくか、管理者までお問い合わせください。'
        );
      }
    },

    /**
     *  METHOD: あおぞらの支払方法を取得
     * @param {*} companys
     * @returns
     */
    getAozoraPaymentData: async function(companys) {
      let aozoraPayment = [];
      for (let company of companys) {
        if (company?.bankTransfer != null) {
          try {
            let result = await API.graphql(
              graphqlOperation(getAozoraPayment, {
                accessID: company.bankTransfer.accessID,
                accessPass: company.bankTransfer.accessPass,
                orderID: company.bankTransfer.orderId,
              })
            );
            if (result.data.getAozoraPayment.statusCode == 200) {
              let oldCompany = company?.payment?.filter(val => parseInt(val.id) < this.getTodayId());
              let payData = JSON.parse(result.data.getAozoraPayment.result);

              let createOldPay = [];
              if (oldCompany != null) {
                for (let pay of oldCompany) {
                  if (pay.paymentResult == true) {
                    let formattedDateStr = pay.billingDate.replace(/-/g, '');
                    let amount = pay.amount + pay.tax;
                    createOldPay.push([
                      this.changeDay(formattedDateStr, 26),
                      'ｶｺｹｯｻｲ',
                      'ｶｺｹｯｻｲ',
                      'ｶｺｹｯｻｲ',
                      amount.toString(),
                    ]);
                  }
                }
              }

              if (createOldPay.length != 0) {
                for (let i = createOldPay.length - 1; i >= 0; i--) {
                  payData.push(createOldPay[i]);
                }
              }

              aozoraPayment.push({
                id: company.id,
                pay: payData,
              });
            }
          } catch (e) {
            console.error(e);
          }
        }
      }
      return aozoraPayment;
    },
    getTodayId: function() {
      // 日付文字列
      let dateStr = this.today;

      if (dateStr == null) return null;

      // 日付文字列をDateオブジェクトに変換
      let date = new Date(dateStr);

      // 10ヶ月前の日付を計算
      date.setMonth(date.getMonth() - 13);

      // 結果の月と日を取得（0埋めで2桁にする）
      let YY = String(date.getFullYear()).slice(-2); // 年の後ろ2桁を取得
      let MM = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() は0から11までの値を返すので +1 が必要

      // MMDD形式の文字列を作成
      let id = YY + MM;
      return parseInt(id);
    },
    changeDay(dateStr, newDay) {
      // Check if dateStr is in the correct format
      if (!/^\d{8}$/.test(dateStr)) {
        throw new Error('Invalid date format. Expected YYYYMMDD.');
      }

      // Extract the year, month, and day parts
      let year = dateStr.substring(0, 4);
      let month = dateStr.substring(4, 6);
      let day = dateStr.substring(6, 8);

      // Ensure newDay is a valid two-digit number
      newDay = newDay.toString().padStart(2, '0');

      // Construct the new date string
      let newDateStr = year + month + newDay;

      return newDateStr;
    },
  },
};
