var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"NewBuildingConstruction"}},_vm._l((_vm.investmentAnalytics.newBuildingCount),function(id){return _c('div',{key:("newBuilding-" + id)},[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v(" "+_vm._s(id == 1 ? '新築工事' : '')+" ")]),_c('b-col',{staticClass:"title"},[_vm._v("新築建物"+_vm._s(id))]),_c('b-col',{staticClass:"component"},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                _vm.investmentAnalytics.initialInvestment.constructionPlan.newConstructionCost[id - 1]
              ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.constructionPlan.newConstructionCost, id - 1, $$v)},expression:"\n                investmentAnalytics.initialInvestment.constructionPlan.newConstructionCost[id - 1]\n              "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"2"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title",attrs:{"cols":"1"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("tabInitialInvestment51_" + id)),expression:"`tabInitialInvestment51_${id}`"}],attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":("tabInitialInvestment51_" + id),"role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v(" 構造 ")]),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-input',{attrs:{"disabled":"","value":_vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id].structure}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}})],1),_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v(" 種類 ")]),_c('b-col',{staticClass:" ally-select",attrs:{"cols":"3"}},[(
                      _vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id].kind ==
                        '共同住宅'
                    )?_c('b-form-input',{attrs:{"disabled":"","value":"住宅（共同住宅）"}}):_c('b-form-input',{attrs:{"disabled":"","value":_vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id].kind}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v(" 延床面積 ")]),_c('b-col',{attrs:{"cols":"3"}},[_c('allyText',{staticClass:"number",attrs:{"type":"number","mode":"View","separation":true,"value":_vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id].area,"floatLength":2,"roundType":"down"}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("㎡")]),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm.tsubo(
                        _vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id].area
                      )}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("坪")])],1),_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v(" 標準 工事単価 ")]),_c('b-col',{staticClass:"component",attrs:{"cols":"3"}},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice(
                        '新築工事',
                        _vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id]
                          .structure +
                          '/' +
                          _vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id].kind
                      ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")]),_c('b-col',{staticClass:"title-guideline",attrs:{"cols":"3"}},[_vm._v(" ※目安 ")])],1),_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v(" 工事費 ")]),_c('b-col',{staticClass:"component",attrs:{"cols":"3"}},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                      _vm.investmentAnalytics.initialInvestment.constructionPlan.newConstructionCost[
                        id - 1
                      ]
                    ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.constructionPlan.newConstructionCost, 
                        id - 1
                      , $$v)},expression:"\n                      investmentAnalytics.initialInvestment.constructionPlan.newConstructionCost[\n                        id - 1\n                      ]\n                    "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")]),_c('b-col',{staticClass:"component",attrs:{"cols":"3"}},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")((_vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id].area *
                        _vm.getUnitPrice(
                          '新築工事',
                          _vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id]
                            .structure +
                            '/' +
                            _vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id]
                              .kind
                        )))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")]),_c('button',{staticClass:"btn btn-ally mr-1",staticStyle:{"width":"60px","white-space":"nowrap","padding-left":"13px"},on:{"click":function($event){_vm.changeGuideline(
                      id - 1,
                      _vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id].area *
                        _vm.getUnitPrice(
                          '新築工事',
                          _vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id]
                            .structure +
                            '/' +
                            _vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id]
                              .kind
                        )
                    )}}},[_vm._v(" 反映 ")])],1)],1)],1)],1)],1)],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }