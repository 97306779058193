// @ts-check
import { Geo } from './geometry';

/**
 * ベクトル
 */
export class Vec {
  constructor(x = 0, y = 0) {
    this.x = x;
    this.y = y;
  }

  /**
   * @param {Vec} ivec
   */
  static from(ivec) {
    return new Vec(ivec.x, ivec.y);
  }

  /**
   * FUNC: 二点を結んだ長さを返す
   * @param {Vec} position
   * @returns {number}
   */
  getSideLength(position) {
    const d = new Vec(Math.abs(position.x - this.x), Math.abs(position.y - this.y));
    return Math.sqrt(d.x * d.x + d.y * d.y);
  }
  /**
   * FUNC: 座標の回転
   * @param {number} degree
   * @param {Vec} origin 回転の中心
   * @returns {Vec}
   */
  rotate(degree, origin = new Vec()) {
    const rad = (degree / 180) * Math.PI;
    const x =
      Math.cos(rad) * this.x -
      Math.sin(rad) * this.y +
      origin.x -
      origin.x * Math.cos(rad) +
      origin.y * Math.sin(rad);
    const y =
      Math.sin(rad) * this.x +
      Math.cos(rad) * this.y +
      origin.y -
      origin.x * Math.sin(rad) -
      origin.y * Math.cos(rad);
    return new Vec(x, y);
  }

  /**
   * 使わないでください
   * なす角（0 < x < 180 ラジアン）を求める
   * @param {Vec} vec
   * @returns {number}
   */
  subtendedAngle(vec) {
    const cos = this.dot(vec) / (this.absVal() * vec.absVal());
    const kaku = Math.acos(cos);
    return kaku;
  }

  /**
   * FUNC: なす角（ラジアン 0 < x < 2π）を求める
   * @param {Vec} vec
   * @returns {number|null}
   */
  angle(vec) {
    const angle = this.subtendedAngle(vec);
    if (isNaN(angle)) return null;
    const gaiseki = this.cross(vec);
    if (gaiseki > 0) {
      return angle;
    } else {
      return angle * -1;
    }
  }

  /**
   * FUNC: 内積
   * @param {Vec} vec
   * @returns {number}
   */
  dot(vec) {
    return this.x * vec.x + this.y * vec.y;
  }

  /**
   * FUNC: 外積(z成分) ※HTMLのy座標が下向きなため、外積の符号も変わる
   * @param {Vec} vec
   * @returns {number}
   */
  cross(vec) {
    return this.x * vec.y - this.y * vec.x;
  }

  /**
   * FUNC: 点がベクトルの左にあるか
   * ベクトルABに対し、点Pを通るベクトルAPの向きを判定する
   * @param {Vec} vec
   * @returns {boolean}
   */
  isLeft(vec) {
    const cross = this.cross(vec);
    return cross < 0;
  }

  /**
   * FUNC: ２つのベクトルで張られる面の法線の符号
   * @param {Vec} vec
   * @returns {number}
   */
  surfaceDirection(vec) {
    const cross = this.cross(vec);
    return this.cross(vec) / Math.abs(cross);
  }

  /**
   * FUNC: vecに対するthisの射影
   * @param {Vec} vec
   */
  projection(vec) {
    return vec.scale(this.dot(vec) / vec.absSquare());
  }

  /**
   * FUNC: ベクトルの絶対値
   * @returns {number}
   */
  absVal() {
    return Math.sqrt(this.x ** 2 + this.y ** 2);
  }

  /**
   * FUNC: ベクトルの絶対値の2乗
   * @returns
   */
  absSquare() {
    return this.x ** 2 + this.y ** 2;
  }

  /**
   * FUNC: 長さがaより大きい
   * @param {Vec} a
   * @returns {boolean}
   */
  gtLen(a) {
    return this.absSquare() > a.absSquare();
  }

  /**
   * FUNC: 長さがa以上
   * @param {Vec} a
   * @returns {boolean}
   */
  gteqLen(a) {
    return this.absSquare() >= a.absSquare();
  }

  /**
   * FUNC: 大きさ1のベクトル
   * @returns {Vec}
   */
  unit() {
    const abs = this.absVal();
    if (abs === 0) throw new Error('ベクトルの長さが0の場合単位ベクトルは定義できません。' + abs);
    return this.scale(1 / abs);
  }

  /**
   * FUNC: 2つのベクトルの中点
   * @param {Vec} target
   * @returns {Vec}
   */
  centerPoint(target) {
    const sum = this.add(target);
    return new Vec(sum.x / 2, sum.y / 2);
  }

  /**
   * FUNC: スカラー倍
   * @param {number} rate 倍率
   * @param {Vec|null} origin 拡大原点
   * @returns {Vec}
   */
  scale(rate, origin = null) {
    if (origin == null) {
      origin = new Vec();
    }
    return new Vec((this.x - origin.x) * rate + origin.x, (this.y - origin.y) * rate + origin.y);
  }

  /**
   * FUNC: ベクトルの和
   * @param {Vec} vec
   * @returns {Vec}
   */
  add(vec) {
    return new Vec(this.x + vec.x, this.y + vec.y);
  }

  /**
   * FUNC: ベクトルの差
   * @param {Vec} vec
   * @returns {Vec}
   */
  sub(vec) {
    return this.add(vec.reverse());
  }

  /**
   * FUNC: ベクトルの、digit桁以下の四捨五入
   * @param {Number} digit // 桁数
   */
  round(digit) {
    const digitCorrection = Math.pow(10, digit);
    return new Vec(
      Math.round(this.x * digitCorrection) / digitCorrection,
      Math.round(this.y * digitCorrection) / digitCorrection
    );
  }

  /**
   * FUNC: 逆ベクトル
   * @returns {Vec}
   */
  reverse() {
    return new Vec(-this.x, -this.y);
  }

  /**
   * FUNC: 外向き法線方向
   * @return {Vec}
   */
  normal() {
    return this.rotate(-90);
  }

  nUnit() {
    return this.rotate(-90).unit();
  }

  /**
   * FUNC: クローン
   * @returns {Vec}
   */
  clone() {
    return new Vec(this.x, this.y);
  }

  /**
   * FUNC: 比較
   * @param {Vec} vec
   */
  cmp(vec, nullable = false) {
    if (this.x === vec.x) {
      if (this.y === vec.y) {
        return nullable ? null : true;
      } else if (this.y > vec.y) {
        return true;
      } else {
        return false;
      }
    } else if (this.x > vec.x) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * FUNC: 同じ座標
   * @param {Vec} vec
   */
  same(vec) {
    return this.x === vec.x && this.y === vec.y;
  }

  /**
   * @param {Vec} vec
   */
  eq(vec) {
    return vec.x === this.x && vec.y === this.y;
  }

  /** static */

  /**
   * FUNC: v1, v2を通る直線からの距離
   * @param {Vec} v1
   * @param {Vec} v2
   */
  distFromLine(v1, v2) {
    if (v1.x === v2.x) {
      return Math.abs(this.x - v1.x);
    }
    // FUNC: 傾きpとy切片qを求める
    // @ts-ignore
    const [p, q] = Geo.getLineDef(v1, v2);
    const result = Math.abs(p * this.x - this.y + q) / Math.sqrt(p * p + 1);
    return result;
  }

  /**
   * FUNC: 2直線の交点
   * @param {number} p1
   * @param {number} q1
   * @param {number} p2
   * @param {number} q2
   * @returns {Vec}
   */
  static getCrossPoint(p1, q1, p2, q2) {
    const x = (q1 - q2) / (p2 - p1);
    const y = p1 * x + q1;
    return new Vec(x, y);
  }

  /**
   * FUNC: 2つの線分の交点
   * @param {Vec} start1
   * @param {Vec} start2
   * @param {Vec} end1
   * @param {Vec} end2
   * @returns {Vec|null}
   */
  static getCrossPointOnLine(start1, end1, start2, end2) {
    if (start1.eq(start2) || start1.eq(end2)) {
      return start1;
    } else if (start2.eq(end1) || start2.eq(start1)) {
      return start2;
    }

    if (start1.onTheLine(start2, end2)) {
      return start1;
    }
    if (end1.onTheLine(start2, end2)) {
      return end1;
    }
    const s1 =
      ((end2.x - start2.x) * (start1.y - start2.y) - (end2.y - start2.y) * (start1.x - start2.x)) /
      2;
    const s2 =
      ((end2.x - start2.x) * (start2.y - end1.y) - (end2.y - start2.y) * (start2.x - end1.x)) / 2;
    if (s1 === 0 || s2 === 0) return null;
    return new Vec(
      start1.x + ((end1.x - start1.x) * s1) / (s1 + s2),
      start1.y + ((end1.y - start1.y) * s1) / (s1 + s2)
    );
  }

  /**
   * FUNC: 重心
   * @param {Vec[]} vectors
   * @returns {Vec}
   */
  static getCenter(vectors) {
    let vecSum = new Vec();
    vectors.forEach(v => {
      vecSum = vecSum.add(v);
    });

    const result = vecSum.scale(1 / vectors.length);

    return result;
  }

  /**
   * FUNC: ３点が同一直線上にあるか
   * @param {Vec[]} vecList
   */
  static on1Line(vecList) {
    const mid = vecList[0];
    const start = vecList[1];
    const end = vecList[2];
    const gen = start.sub(end);
    const g = gen.absVal() / 2;
    const h = mid.distFromLine(start, end);
    const r = (g * g + h * h) / (2 * h);
    // 無効な値、もしくは極端に大きい場合true
    return isNaN(r) || !isFinite(r) || r > 1000000000;
  }

  /**
   * FUNC: 任意の2点を結ぶ直線上にあるか
   * @param {Vec} start
   * @param {Vec} end
   */
  onTheLine(start, end) {
    const on1line = Vec.on1Line([this, start, end]);
    if (!on1line) return false;
    const l1 = this.sub(start);
    const l2 = this.sub(end);
    return l1.dot(l2) < 0;
  }

  /**
   * @param {Vec} start 直線上の点1
   * @param {Vec} end 直線上の点2
   * @param {Vec} anotherPoint
   * @returns {number[] | null}
   */
  static getVerticalLineDef(start, end, anotherPoint) {
    const [p1] = Geo.getLineDef(start, end);
    if (p1 === 0) {
      return null;
    } else if (!isFinite(p1)) {
      return [0, anotherPoint.y];
    } else {
      const p2 = -1 / p1;
      const q2 = anotherPoint.y - anotherPoint.x * p2;
      return [p2, q2];
    }
  }

  /**
   * FUNC: min
   * @param {Vec[]} vecs
   */
  static min(vecs) {
    const min = vecs.reduce((prev, current) => {
      return !prev.cmp(current) ? prev : current;
    }, new Vec(Number.MAX_VALUE, Number.MAX_VALUE));
    return min;
  }

  /**
   * FUNC: max
   * @param {Vec[]} vecs
   */
  static max(vecs) {
    const max = vecs.reduce((prev, current) => {
      return prev.cmp(current) ? prev : current;
    }, new Vec(Number.MIN_VALUE, Number.MIN_VALUE));
    return max;
  }

  /**
   * FUNC: 左上
   * @param {Vec[]} vecs
   * @returns {Vec}
   */
  static leftTop(vecs) {
    let res = vecs[0];
    vecs.forEach(v => {
      if (res.absSquare() > v.absSquare()) {
        res = v;
      }
    });
    return res;
  }

  /**
   * FUNC: ベクトルを長さと回転成分に分離する
   * @param {Vec} vec
   */
  static vecToVecRotation(vec) {
    const x = vec.x;
    const y = vec.y;
    const r = Math.atan2(y, x);
    return {
      len: vec.absVal(),
      rotate: r,
    };
  }
}
