// @ts-check

import { Vec } from './vector';

/**
 * 弧
 */
export class Arc {
  constructor() {
    this.r = 0;
    this.isLarger = false;
    this.isRightRotation = false;
    this.position = new Vec();
  }

  /**
   * 三点を通る円の半径と中心座標を求める
   * @param {Vec} start 弧の始点
   * @param {Vec} mid 中間の点
   * @param {Vec} end 終点
   * @return {Array} [半径, 中心座標] 定義できない場合null配列
   */
  static getCircle(start, mid, end) {
    // 弧の弦の半分
    const gen = start.sub(end);
    const g = gen.absVal() / 2;
    const h = mid.distFromLine(start, end);
    const r = (g * g + h * h) / (2 * h);
    // 無効な値、もしくは極端に大きい場合
    if (isNaN(r) || !isFinite(r) || r > 1000000000) {
      return [null, null];
    }

    const p1 = start.x - mid.x;
    const p2 = start.y - mid.y;
    const p3 = mid.x - end.x;
    const p4 = mid.y - end.y;

    const k = 1 / (p1 * p4 - p2 * p3) / 2;
    const startAbs = start.absVal();
    const midAbs = mid.absVal();
    const endAbs = end.absVal();
    const x1 = Math.pow(startAbs, 2) - Math.pow(midAbs, 2);
    const x2 = Math.pow(midAbs, 2) - Math.pow(endAbs, 2);
    const a = k * (p4 * x1 - p2 * x2);
    const b = k * (-p3 * x1 + p1 * x2);

    const center = new Vec(a, b);
    return [r, center];
  }

  /**
   * 弧の定義を取得 (SVGPathElement の Aコマンドの形式に合わせた定義)
   * @param {Vec} start 始点
   * @param {Vec} mid 弧の中間の点
   * @param {Vec} end 終点
   * @returns {Arc}
   */
  static getArc(start, mid, end) {
    const arc = new Arc();
    const center = end.centerPoint(start);

    const [r] = Arc.getCircle(start, mid, end);
    arc.r = r;

    const gen = end.sub(start); // 弧の弦
    const rr = gen.absVal() / 2; // 弦を直径とした円の半径
    const distCtrlMidToGenMid = mid.sub(center).absVal(); // 弦の中点と制御点の中点の距離
    // 大きい方の弧を描画(弧は円周上の2点に対して大小二種類存在する)
    arc.isLarger = distCtrlMidToGenMid > rr;

    // 外積の正負で弦に対するmidの位置により弧の回転方向を設定
    const ms = mid.sub(start);
    arc.isRightRotation = gen.isLeft(ms);

    arc.position = end.clone();

    return arc;
  }

  /**
   * 弧の面積
   * @param {Vec} start 始点
   * @param {Vec} mid 弧の中間の点
   * @param {Vec} end 終点
   * @returns {number}
   */
  static getArcAreaSize(start, mid, end) {
    const [r, center] = Arc.getCircle(start, mid, end);
    // 円が定義できない場合0
    if (r == null) {
      return 0;
    }
    const v1 = start.sub(center);
    const v2 = end.sub(center);
    const gen = end.sub(start);
    const genMid = end.centerPoint(start);
    const centerToGen = genMid.sub(center);

    const angle = gen.cross(mid.sub(start)) > 0 ? 2 * Math.PI - v1.angle(v2) : v1.angle(v2);

    // 角の大きさによって三角形の面積を足すか引くかが変わる
    const sign = angle < Math.PI ? -1 : 1;

    // 扇形
    let area = (r * r * angle) / 2;
    area += (sign * centerToGen.absVal() * gen.absVal()) / 2;

    return area;
  }
}
