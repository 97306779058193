<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：初期投資/付帯費用
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="IncidentalExpenses">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button variant="link" v-b-toggle.tabIncidentalExpenses>
          付帯費用
        </b-button>
      </b-card-header>
      <b-collapse id="tabIncidentalExpenses" role="tabpanel" visible>
        <b-card-body style="padding-top:0;">
          <b-container>
            <table>
              <tbody>
                <tr>
                  <td>
                    <b-form-group
                      style="margin:1em;font-size:large;"
                      label="入力する項目を選択してください:"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-checkbox-group
                        id="incidentalExpensesSwiches"
                        v-model="selectedIncidentalExpenses"
                        :options="optionsIncidentalExpenses"
                        :aria-describedby="ariaDescribedby"
                        switches
                        size="lg"
                        style="font-weight:normal;"
                        @input="changeIncidentalExpenses"
                      >
                      </b-form-checkbox-group>
                    </b-form-group>
                  </td>
                  <td width="10%">
                    <b-button
                      id="incidentalExpenses-select-all"
                      variant="ally"
                      style="width:100%;"
                      size="sm"
                      @click="checkAll"
                      >すべて選択</b-button
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </b-container>
          <b-container>
            <b-row>
              <b-col>
                <b-card-group columns id="groupIncidentalExpenses">
                  <b-card no-body style="margin:0;padding:0;border:none;box-shadow:none;">
                    <b-card-group class="columns" columns style="column-count:1;">
                      <!-- コンサルタント料 -->
                      <ConsultantFees v-if="displayColumn[0] == 0" />
                      <!-- 設計監理費 -->
                      <DesignCosts v-if="displayColumn[1] == 0" />
                      <!-- 調査測量費 -->
                      <SurveyCosts v-if="displayColumn[2] == 0" />
                      <!-- 許認可費 -->
                      <LicensingFees v-if="displayColumn[3] == 0" />
                      <!-- 各種負担金・預託金 -->
                      <Contributions v-if="displayColumn[4] == 0" />
                      <!-- 印紙税 -->
                      <StampDuty v-if="displayColumn[5] == 0" />
                      <!-- 不動産取得税 -->
                      <RealEstateAcquisitionTax v-if="displayColumn[6] == 0" />
                      <!-- 登録免許税 -->
                      <RegistrationTax v-if="displayColumn[7] == 0" />
                      <!-- 支払手数料 -->
                      <PaymentFees v-if="displayColumn[8] == 0" />
                      <!-- 事業開始前負担 -->
                      <PreBusinessBurden v-if="displayColumn[9] == 0" />
                      <!-- 保険料負担 -->
                      <InsuranceFees v-if="displayColumn[10] == 0" />
                      <!-- その他経費 -->
                      <OtherExpenses v-if="displayColumn[11] == 0" />
                    </b-card-group>
                  </b-card>
                  <b-card no-body style="margin:0;padding:0;border:none;box-shadow:none;">
                    <b-card-group class="columns" columns style="column-count:1;">
                      <!-- コンサルタント料 -->
                      <ConsultantFees v-if="displayColumn[0] == 1" />
                      <!-- 設計監理費 -->
                      <DesignCosts v-if="displayColumn[1] == 1" />
                      <!-- 調査測量費 -->
                      <SurveyCosts v-if="displayColumn[2] == 1" />
                      <!-- 許認可費 -->
                      <LicensingFees v-if="displayColumn[3] == 1" />
                      <!-- 各種負担金・預託金 -->
                      <Contributions v-if="displayColumn[4] == 1" />
                      <!-- 印紙税 -->
                      <StampDuty v-if="displayColumn[5] == 1" />
                      <!-- 不動産取得税 -->
                      <RealEstateAcquisitionTax v-if="displayColumn[6] == 1" />
                      <!-- 登録免許税 -->
                      <RegistrationTax v-if="displayColumn[7] == 1" />
                      <!-- 支払手数料 -->
                      <PaymentFees v-if="displayColumn[8] == 1" />
                      <!-- 事業開始前負担 -->
                      <PreBusinessBurden v-if="displayColumn[9] == 1" />
                      <!-- 保険料負担 -->
                      <InsuranceFees v-if="displayColumn[10] == 1" />
                      <!-- その他経費 -->
                      <OtherExpenses v-if="displayColumn[11] == 1" />
                    </b-card-group>
                  </b-card>
                </b-card-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Calculation from './../Calculation';
  import ConsultantFees from './ConsultantFees';
  import DesignCosts from './DesignCosts';
  import SurveyCosts from './SurveyCosts';
  import LicensingFees from './LicensingFees';
  import Contributions from './Contributions';
  import StampDuty from './StampDuty';
  import RealEstateAcquisitionTax from './RealEstateAcquisitionTax';
  import RegistrationTax from './RegistrationTax';
  import PaymentFees from './PaymentFees';
  import PreBusinessBurden from './PreBusinessBurden';
  import InsuranceFees from './InsuranceFees';
  import OtherExpenses from './OtherExpenses';
  export default {
    mixins: [Calculation],
    components: {
      ConsultantFees,
      DesignCosts,
      SurveyCosts,
      LicensingFees,
      Contributions,
      StampDuty,
      RealEstateAcquisitionTax,
      RegistrationTax,
      PaymentFees,
      PreBusinessBurden,
      InsuranceFees,
      OtherExpenses,
    },
    data: function() {
      return {
        // 付帯費用
        // ※チェックされた項目のvalueのみ格納される。
        selectedIncidentalExpenses: [],
        // 付帯費用
        optionsIncidentalExpenses: [
          { text: 'コンサルタント料', value: 0 },
          { text: '設計監理費', value: 1 },
          { text: '調査測量費', value: 2 },
          { text: '許認可費', value: 3 },
          {
            text: '各種負担金・預託金',
            value: 4,
          },
          { text: '印紙税', value: 5 },
          { text: '不動産取得税', value: 6 },
          { text: '登録免許税', value: 7 },
          { text: '支払手数料', value: 8 },
          { text: '事業開始前負担', value: 9 },
          { text: '保険料負担', value: 10 },
          { text: 'その他経費', value: 11 },
        ],
      };
    },
    mounted: function() {
      // 付帯費用チェック初期化
      this.initCheckBoxes();
    },
    computed: {
      // 表示カラム計算
      displayColumn: function() {
        let count = 0;
        let result = [];
        this.investmentAnalytics.initialInvestment.incidentalExpenses.forEach(v => {
          result.push(v > 0 ? Math.round(count++ / this.selectedIncidentalExpenses.length) : null);
        });
        return result;
      },
    },
    methods: {
      // 付帯費用チェック初期化
      initCheckBoxes: function() {
        this.selectedIncidentalExpenses = [];
        for (let id in this.investmentAnalytics.initialInvestment.incidentalExpenses) {
          if (this.investmentAnalytics.initialInvestment.incidentalExpenses[id] > 0)
            this.selectedIncidentalExpenses.push(Number(id));
        }
      },
      // 付帯費用チェック変更時
      changeIncidentalExpenses: function() {
        for (let id in this.investmentAnalytics.initialInvestment.incidentalExpenses) {
          // 状態配列を更新
          Vue.set(
            this.investmentAnalytics.initialInvestment.incidentalExpenses,
            id,
            this.selectedIncidentalExpenses.includes(Number(id)) ? 1 : 0
          );
        }
      },
      // すべてチェック
      checkAll: function() {
        this.selectedIncidentalExpenses = [];
        for (let id in this.investmentAnalytics.initialInvestment.incidentalExpenses) {
          // 状態配列を更新
          Vue.set(this.investmentAnalytics.initialInvestment.incidentalExpenses, id, 1);
          this.selectedIncidentalExpenses.push(Number(id));
        }
      },
    },
  };
</script>
