<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：初期投資
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="InitialInvestment">
    <!-- 共通情報 -->
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button variant="link" v-b-toggle.tabReportInfo>
          共通情報
        </b-button>
      </b-card-header>
      <b-collapse id="tabReportInfo" role="tabpanel" visible>
        <b-card-body>
          <b-container>
            <b-row class="mt-3">
              <b-col lg="6">
                <allyText
                  title="レポート名"
                  name="reportTitle"
                  rules="required|max: 15"
                  v-model="investmentAnalytics.reportName"
                />
              </b-col>
              <b-col lg="6" style="position: relative;">
                <allyText
                  title="顧客名"
                  name="customer-name"
                  rules="required|max: 15"
                  v-model="investmentAnalytics.ownerName"
                />
                <div
                  style="position: absolute; padding: 0; top: -5%; right: 3%; white-space: nowrap"
                >
                  <button
                    class="btn btn-ally mr-1"
                    style="height:10px;line-height:2px;"
                    @click="$bvModal.show('searchCustomer')"
                  >
                    検索
                  </button>
                  <button
                    class="btn btn-ally"
                    style="margin-left:0.5rem;height:10px;line-height:2px;"
                    @click="$bvModal.show('createCustomer')"
                  >
                    新規
                  </button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col lg="6">
                <allyDate
                  title="作成日"
                  name="reportDate"
                  v-model="investmentAnalytics.inputDate"
                />
              </b-col>
              <b-col lg="" style="position: relative;">
                <allyText
                  title="担当者名"
                  name="staff-name"
                  rules="required|max: 15"
                  v-model="investmentAnalytics.staffName"
                />
                <div
                  style="position: absolute; padding: 0; top: -5%; right: 3%; white-space: nowrap"
                >
                  <button
                    class="btn btn-ally"
                    style="height:10px;line-height:2px;"
                    @click="$bvModal.show('searchAccount')"
                  >
                    検索
                  </button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col lg="12">
                <!--
                <PropertySettingV2
                  :property="investmentAnalytics.propertyData"
                  @selectProperty="selectProperty"
                />
                -->
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
    <br />
    <!-- 初期投資 -->
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button
          variant="link"
          v-b-toggle.tabInitialInvestment
          id="btnInitialInvestment"
          @click="viewFlag = true"
        >
          初期投資
        </b-button>
      </b-card-header>
      <b-collapse id="tabInitialInvestment" role="tabpanel" v-if="viewFlag">
        <b-card-body>
          <b-card-group columns style="column-count:1;">
            <!-- 物件概要 -->
            <b-card no-body class="category">
              <b-card-header role="tab">
                <b-button variant="link" v-b-toggle.tabObjectSummary>
                  物件概要
                </b-button>
              </b-card-header>
              <b-collapse id="tabObjectSummary" role="tabpanel" visible v-if="lazLoading[0]">
                <b-card-body>
                  <b-card-group columns id="groupObjectSummary">
                    <b-card no-body style="margin:0;padding:0;border:none;box-shadow:none;">
                      <b-card-group columns style="column-count:1;">
                        <!-- 土地 -->
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              variant="link"
                              v-b-toggle.tabInitialInvestmentLand
                              id="objectSummary-land"
                            >
                              土地
                            </b-button>
                          </b-card-header>
                          <b-collapse
                            id="tabInitialInvestmentLand"
                            role="tabpanel"
                            v-if="lazLoading[5]"
                          >
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="3" class="title bold">
                                    所在地
                                  </b-col>
                                  <b-col class="component">
                                    <allyAddress
                                      v-model="
                                        investmentAnalytics.initialInvestment.propertyDescription[0]
                                          .address
                                      "
                                    />
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title bold">
                                    筆数
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col>
                                        <allyText
                                          name="quantity"
                                          v-model="
                                            investmentAnalytics.initialInvestment
                                              .propertyDescription[0].quantity
                                          "
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">
                                        筆
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title bold">
                                    現況地目
                                  </b-col>
                                  <b-col class="ally-select">
                                    <allySelect
                                      name="structure"
                                      :items="currentLandUseItems"
                                      v-model="
                                        investmentAnalytics.initialInvestment.propertyDescription[0]
                                          .structure
                                      "
                                    />
                                  </b-col>
                                  <b-col />
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title bold">
                                    計画地面積
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col>
                                        <allyText
                                          name="area"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          v-model="
                                            investmentAnalytics.initialInvestment
                                              .propertyDescription[0].area
                                          "
                                          :floatLength="2"
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">
                                        ㎡
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col>
                                        <allyText
                                          mode="View"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          :floatLength="2"
                                          :value="
                                            tsubo(
                                              investmentAnalytics.initialInvestment
                                                .propertyDescription[0].area
                                            )
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">
                                        坪
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title bold">
                                    固定資産税路線価
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col>
                                        <allyText
                                          name="price"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment
                                              .propertyDescription[0].price
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">
                                        円/㎡
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                                <b-row>
                                  <b-col cols="3" />
                                  <b-col>
                                    ※路線価・地区区分の検索が必要な場合は下記をご覧ください。
                                    <a
                                      href="https://www.chikamap.jp/chikamap/"
                                      target="_blank"
                                      style="margin-right : 5px;"
                                    >
                                      <img src="../../../images/ChikaMap_M.gif" />
                                    </a>
                                    <a
                                      href="https://www.rosenka.nta.go.jp/index.htm"
                                      target="_blank"
                                    >
                                      <img src="../../../images/bannerForLink.jpg" />
                                    </a>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <!-- 既存建物 -->
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              id="btnInitialInvestmentExistingBuilding"
                              variant="link"
                              v-b-toggle.tabInitialInvestmentExistingBuilding
                            >
                              既存建物（継続使用予定の建物）
                            </b-button>
                          </b-card-header>
                          <b-collapse
                            id="tabInitialInvestmentExistingBuilding"
                            role="tabpanel"
                            v-if="lazLoading[6]"
                          >
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="2" class="title bold">
                                    棟数
                                  </b-col>
                                  <b-col cols="2">
                                    <allySelect
                                      name="existingBuildingCount"
                                      :items="buildingCountItems"
                                      style="direction: rtl;"
                                      v-model="investmentAnalytics.existingBuildingCount"
                                    />
                                  </b-col>
                                  <b-col cols="1" class="unit">棟</b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <b-tabs fill pills class="card" v-model="selectedTab[0]">
                                      <b-tab
                                        v-for="id in investmentAnalytics.existingBuildingCount"
                                        v-bind:key="`existingBuilding-${id}`"
                                        :title="`既存建物${id}`"
                                      >
                                        <b-card-body style="padding-top:0;">
                                          <b-container>
                                            <b-row>
                                              <b-col cols="3" class="title bold">
                                                所在地
                                              </b-col>
                                              <b-col class="component">
                                                <allyAddress
                                                  v-model="
                                                    investmentAnalytics.initialInvestment
                                                      .propertyDescription[id].address
                                                  "
                                                />
                                              </b-col>
                                            </b-row>
                                            <b-row>
                                              <b-col cols="3" class="title bold">
                                                構造
                                              </b-col>
                                              <b-col class="ally-select">
                                                <allySelect
                                                  :name="'structure1-' + id"
                                                  :items="structureItems"
                                                  v-model="
                                                    investmentAnalytics.initialInvestment
                                                      .propertyDescription[id].structure
                                                  "
                                                />
                                              </b-col>
                                              <b-col />
                                            </b-row>
                                            <b-row>
                                              <b-col cols="3" class="title bold">
                                                種類
                                              </b-col>
                                              <b-col class=" ally-select">
                                                <allySelect
                                                  :name="'kind1-' + id"
                                                  :items="kindItems"
                                                  v-model="
                                                    investmentAnalytics.initialInvestment
                                                      .propertyDescription[id].kind
                                                  "
                                                />
                                              </b-col>
                                              <b-col />
                                            </b-row>
                                            <b-row>
                                              <b-col cols="3" class="title bold">
                                                用途
                                              </b-col>
                                              <b-col class="component">
                                                <allyText
                                                  :name="'purpose1-' + id"
                                                  v-model="
                                                    investmentAnalytics.initialInvestment
                                                      .propertyDescription[id].purpose
                                                  "
                                                />
                                              </b-col>
                                              <b-col />
                                            </b-row>
                                            <b-row>
                                              <b-col cols="3" class="title bold">築年月日</b-col>
                                              <b-col>
                                                <allyDate
                                                  :name="'dateOfConstruction1-' + id"
                                                  v-model="
                                                    investmentAnalytics.initialInvestment
                                                      .propertyDescription[id].dateOfConstruction
                                                  "
                                                />
                                              </b-col>
                                              <b-col style="margin-top:30px;">
                                                <b-form-input
                                                  disabled
                                                  :value="ageOfBuilding(id) | filterAgeOfBuilding"
                                                />
                                              </b-col>
                                            </b-row>
                                            <b-row>
                                              <b-col cols="3" class="title bold">戸数</b-col>
                                              <b-col>
                                                <b-row>
                                                  <b-col>
                                                    <allyText
                                                      :name="'quantity1-' + id"
                                                      type="number"
                                                      class="number"
                                                      :separation="true"
                                                      halfWidth
                                                      v-model="
                                                        investmentAnalytics.initialInvestment
                                                          .propertyDescription[id].quantity
                                                      "
                                                      roundType="down"
                                                    />
                                                  </b-col>
                                                  <b-col cols="2" class="unit">戸</b-col>
                                                </b-row>
                                              </b-col>
                                              <b-col />
                                            </b-row>
                                            <b-row>
                                              <b-col cols="3" class="title bold">
                                                延床面積
                                              </b-col>
                                              <b-col>
                                                <b-row>
                                                  <b-col>
                                                    <allyText
                                                      :name="'area1-' + id"
                                                      type="number"
                                                      class="number"
                                                      :separation="true"
                                                      v-model="
                                                        investmentAnalytics.initialInvestment
                                                          .propertyDescription[id].area
                                                      "
                                                      :floatLength="2"
                                                      roundType="down"
                                                    />
                                                  </b-col>
                                                  <b-col cols="2" class="unit">㎡</b-col>
                                                </b-row>
                                              </b-col>
                                              <b-col>
                                                <b-row>
                                                  <b-col>
                                                    <allyText
                                                      mode="View"
                                                      type="number"
                                                      class="number"
                                                      :separation="true"
                                                      :floatLength="2"
                                                      :value="
                                                        tsubo(
                                                          investmentAnalytics.initialInvestment
                                                            .propertyDescription[id].area
                                                        )
                                                      "
                                                    />
                                                  </b-col>
                                                  <b-col cols="2" class="unit">坪</b-col>
                                                </b-row>
                                              </b-col>
                                            </b-row>
                                          </b-container>
                                        </b-card-body>
                                      </b-tab>
                                    </b-tabs>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <!-- 新築建物 -->
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              variant="link"
                              v-b-toggle.tabInitialInvestmentNewBuilding
                              id="btnInitialInvestmentNewBuilding"
                            >
                              新築建物
                            </b-button>
                          </b-card-header>
                          <b-collapse
                            id="tabInitialInvestmentNewBuilding"
                            role="tabpanel"
                            v-if="lazLoading[7]"
                          >
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="2" class="title bold">
                                    棟数
                                  </b-col>
                                  <b-col cols="2">
                                    <allySelect
                                      name="newBuildingCount"
                                      :items="buildingCountItems"
                                      style="direction: rtl;"
                                      v-model="investmentAnalytics.newBuildingCount"
                                    />
                                  </b-col>
                                  <b-col cols="1" class="unit">棟</b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <b-tabs fill pills class="card" v-model="selectedTab[1]">
                                      <b-tab
                                        v-for="id in investmentAnalytics.newBuildingCount"
                                        v-bind:key="`newBuilding-${id}`"
                                        :title="`新築建物${id}`"
                                      >
                                        <b-card-body style="padding-top:0;">
                                          <b-container>
                                            <b-row>
                                              <b-col cols="3" class="title bold">
                                                所在地
                                              </b-col>
                                              <b-col class="component">
                                                <allyAddress
                                                  v-model="
                                                    investmentAnalytics.initialInvestment
                                                      .propertyDescription[10 + id].address
                                                  "
                                                />
                                              </b-col>
                                            </b-row>
                                            <b-row>
                                              <b-col cols="3" class="title bold">
                                                構造
                                              </b-col>
                                              <b-col class=" ally-select">
                                                <allySelect
                                                  :name="'structure2-' + id"
                                                  :items="structureItems"
                                                  v-model="
                                                    investmentAnalytics.initialInvestment
                                                      .propertyDescription[10 + id].structure
                                                  "
                                                />
                                              </b-col>
                                              <b-col />
                                            </b-row>
                                            <b-row>
                                              <b-col cols="3" class="title bold">
                                                種類
                                              </b-col>
                                              <b-col class=" ally-select">
                                                <allySelect
                                                  :name="'kind2-' + id"
                                                  :items="kindItems"
                                                  v-model="
                                                    investmentAnalytics.initialInvestment
                                                      .propertyDescription[10 + id].kind
                                                  "
                                                />
                                              </b-col>
                                              <b-col />
                                            </b-row>
                                            <b-row>
                                              <b-col cols="3" class="title bold">
                                                用途
                                              </b-col>
                                              <b-col class="component">
                                                <allyText
                                                  :name="'purpose2-' + id"
                                                  v-model="
                                                    investmentAnalytics.initialInvestment
                                                      .propertyDescription[10 + id].purpose
                                                  "
                                                />
                                              </b-col>
                                              <b-col />
                                            </b-row>
                                            <b-row>
                                              <b-col cols="3" class="title bold">戸数</b-col>
                                              <b-col>
                                                <b-row>
                                                  <b-col>
                                                    <allyText
                                                      :name="'quantity2-' + id"
                                                      type="number"
                                                      class="number"
                                                      :separation="true"
                                                      halfWidth
                                                      v-model="
                                                        investmentAnalytics.initialInvestment
                                                          .propertyDescription[10 + id].quantity
                                                      "
                                                      roundType="down"
                                                    />
                                                  </b-col>
                                                  <b-col cols="2" class="unit">戸</b-col>
                                                </b-row>
                                              </b-col>
                                              <b-col />
                                            </b-row>
                                            <b-row>
                                              <b-col cols="3" class="title bold">
                                                延床面積
                                              </b-col>
                                              <b-col>
                                                <b-row>
                                                  <b-col>
                                                    <allyText
                                                      :name="'area2-' + id"
                                                      type="number"
                                                      class="number"
                                                      :separation="true"
                                                      v-model="
                                                        investmentAnalytics.initialInvestment
                                                          .propertyDescription[10 + id].area
                                                      "
                                                      :floatLength="2"
                                                      roundType="down"
                                                    />
                                                  </b-col>
                                                  <b-col cols="2" class="unit">㎡</b-col>
                                                </b-row>
                                              </b-col>
                                              <b-col>
                                                <b-row>
                                                  <b-col>
                                                    <allyText
                                                      mode="View"
                                                      type="number"
                                                      class="number"
                                                      :separation="true"
                                                      :floatLength="2"
                                                      :value="
                                                        tsubo(
                                                          investmentAnalytics.initialInvestment
                                                            .propertyDescription[10 + id].area
                                                        )
                                                      "
                                                    />
                                                  </b-col>
                                                  <b-col cols="2" class="unit">坪</b-col>
                                                </b-row>
                                              </b-col>
                                            </b-row>
                                          </b-container>
                                        </b-card-body>
                                      </b-tab>
                                    </b-tabs>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </b-card-group>
                    </b-card>
                    <b-card no-body style="margin:0;padding:0;border:none;box-shadow:none;">
                      <b-card-group columns style="column-count:1;">
                        <!-- 償却資産 -->
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              id="btnInitialInvestmentDepreciation"
                              variant="link"
                              v-b-toggle.tabInitialInvestmentDepreciation
                            >
                              付属建物
                            </b-button>
                          </b-card-header>
                          <b-collapse
                            id="tabInitialInvestmentDepreciation"
                            role="tabpanel"
                            v-if="lazLoading[8]"
                          >
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="3" class="title bold">
                                    構造
                                  </b-col>
                                  <b-col class="ally-select">
                                    <allySelect
                                      name="structure3"
                                      :items="structureItems"
                                      v-model="
                                        investmentAnalytics.initialInvestment
                                          .propertyDescription[21].structure
                                      "
                                    />
                                  </b-col>
                                  <b-col />
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title bold">
                                    種類
                                  </b-col>
                                  <b-col class="ally-select">
                                    <allySelect
                                      name="kind3"
                                      :items="attachedBuildingItems"
                                      v-model="
                                        investmentAnalytics.initialInvestment
                                          .propertyDescription[21].kind
                                      "
                                    />
                                  </b-col>
                                  <b-col />
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title bold">築年月日</b-col>
                                  <b-col>
                                    <allyDate
                                      name="dateOfConstruction3"
                                      v-model="
                                        investmentAnalytics.initialInvestment
                                          .propertyDescription[21].dateOfConstruction
                                      "
                                    />
                                  </b-col>
                                  <b-col style="margin-top:30px;">
                                    <b-form-input
                                      disabled
                                      :value="ageOfBuilding(21) | filterAgeOfBuilding"
                                    />
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title bold">戸数</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col>
                                        <allyText
                                          name="quantity3"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment
                                              .propertyDescription[21].quantity
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">戸</b-col>
                                    </b-row>
                                    <b-col />
                                  </b-col>
                                  <b-col />
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title bold">
                                    延床面積
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col>
                                        <allyText
                                          name="area3"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          v-model="
                                            investmentAnalytics.initialInvestment
                                              .propertyDescription[21].area
                                          "
                                          :floatLength="2"
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">㎡</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col>
                                        <allyText
                                          mode="View"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          :floatLength="2"
                                          :value="
                                            tsubo(
                                              investmentAnalytics.initialInvestment
                                                .propertyDescription[21].area
                                            )
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">坪</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <!-- 固定資産評価 -->
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              id="btnInitialInvestment13"
                              variant="link"
                              v-b-toggle.tabInitialInvestment13
                            >
                              固定資産評価
                            </b-button>
                          </b-card-header>
                          <b-collapse
                            id="tabInitialInvestment13"
                            role="tabpanel"
                            v-if="lazLoading[9]"
                          >
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col class="title bold">種別</b-col>
                                  <b-col cols="3">
                                    <b-row>
                                      <b-col class="ally-select" style="padding:0;">
                                        <allySelect
                                          name="memo0"
                                          :items="memoItems"
                                          v-model="investmentAnalytics.initialInvestment.memo[0]"
                                        />
                                      </b-col>
                                      <b-col cols="4" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="guideline">
                                        ※目安
                                      </b-col>
                                      <button
                                        class="btn btn-ally btn-reflection mr-1 mt-auto mb-0"
                                        @click="
                                          changeGuideline(0, propertyTaxValuationGuideline(0))
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title">
                                    土地
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="land0"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment
                                              .propertyTaxValuation[0]
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="propertyTaxValuationGuideline(0) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit" style="padding-top:2px;"
                                        >円</b-col
                                      >
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="9" style="height:1em;" />
                                </b-row>
                                <b-row>
                                  <b-col class="title" style="color:gray;">
                                    ※既存建物利用の場合
                                  </b-col>
                                  <b-col cols="3">
                                    <b-row>
                                      <b-col class="ally-select" style="padding:0;">
                                        <allySelect
                                          name="memo1"
                                          :items="memoItems"
                                          v-model="investmentAnalytics.initialInvestment.memo[1]"
                                        />
                                      </b-col>
                                      <b-col cols="4" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="guideline">
                                        ※目安
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row
                                  v-for="id of investmentAnalytics.existingBuildingCount"
                                  v-bind:key="`for04-${id}`"
                                  class="row-cols-1 mt-1"
                                >
                                  <b-row class="mx-0">
                                    <button
                                      class="btn btn-ally btn-reflection mr-1 ml-auto"
                                      @click="
                                        changeGuideline(id, propertyTaxValuationGuideline(id))
                                      "
                                    >
                                      反映
                                    </button>
                                  </b-row>
                                  <b-row cols="12" class="mx-0 pt-1">
                                    <b-col cols="3" class="title">既存建物{{ id }}</b-col>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component">
                                          <allyText
                                            :name="'existingBuilding0-' + id"
                                            type="number"
                                            class="number"
                                            :separation="true"
                                            halfWidth
                                            v-model="
                                              investmentAnalytics.initialInvestment
                                                .propertyTaxValuation[id]
                                            "
                                            roundType="down"
                                          />
                                        </b-col>
                                        <b-col cols="2" class="unit">円</b-col>
                                      </b-row>
                                    </b-col>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component">
                                          <b-form-input
                                            disabled
                                            class="currency"
                                            :value="propertyTaxValuationGuideline(id) | currency"
                                          />
                                        </b-col>
                                        <b-col cols="2" class="unit">円</b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-row>
                                <b-row
                                  v-for="id of investmentAnalytics.newBuildingCount"
                                  v-bind:key="`for05-${id}`"
                                  class="row-cols-1 mt-1"
                                >
                                  <b-row class="mx-0">
                                    <button
                                      class="btn btn-ally btn-reflection mr-1 ml-auto"
                                      @click="
                                        changeGuideline(
                                          10 + id,
                                          propertyTaxValuationGuideline(10 + id)
                                        )
                                      "
                                    >
                                      反映
                                    </button>
                                  </b-row>
                                  <b-row cols="12" class="mx-0 pt-1">
                                    <b-col cols="3" class="title">新築建物{{ id }}</b-col>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component">
                                          <allyText
                                            :name="'existingBuilding0-' + id"
                                            type="number"
                                            class="number"
                                            :separation="true"
                                            halfWidth
                                            v-model="
                                              investmentAnalytics.initialInvestment
                                                .propertyTaxValuation[10 + id]
                                            "
                                            roundType="down"
                                          />
                                        </b-col>
                                        <b-col cols="2" class="unit">円</b-col>
                                      </b-row>
                                    </b-col>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component">
                                          <b-form-input
                                            disabled
                                            class="currency"
                                            :value="
                                              propertyTaxValuationGuideline(10 + id) | currency
                                            "
                                          />
                                        </b-col>
                                        <b-col cols="2" class="unit">円</b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-row>
                                <b-row class="mt-1">
                                  <b-col />
                                  <button
                                    class="btn btn-ally btn-reflection mr-1"
                                    @click="changeGuideline(21, propertyTaxValuationGuideline(21))"
                                  >
                                    反映
                                  </button>
                                </b-row>
                                <b-row class="pt-1">
                                  <b-col cols="3" class="title">
                                    付属建物
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'depreciableAssets0'"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment
                                              .propertyTaxValuation[21]
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="propertyTaxValuationGuideline(21) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row class="pt-1">
                                  <b-col cols="3" class="title">
                                    償却資産
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'depreciableAssets0'"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment
                                              .propertyTaxValuation[22]
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <!-- 固定資産税・都市計画税 -->
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              id="btnInitialInvestment14"
                              variant="link"
                              v-b-toggle.tabInitialInvestment14
                            >
                              固定資産税・都市計画税
                            </b-button>
                          </b-card-header>
                          <b-collapse
                            id="tabInitialInvestment14"
                            role="tabpanel"
                            v-if="lazLoading[10]"
                          >
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col class="title">固定資産税率</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="propertyTaxRate"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          :floatLength="2"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment.propertyTaxRate
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">％</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                                <b-row>
                                  <b-col class="title ">都市計画税率</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="cityPlanningTaxRate"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          :floatLength="2"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment
                                              .cityPlanningTaxRate
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">％</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                                <b-row>
                                  <b-col class="borderline" />
                                </b-row>
                                <b-row>
                                  <b-col cols="2" class="title bold">
                                    種別
                                  </b-col>
                                  <b-col cols="2" />
                                  <b-col>
                                    <b-row>
                                      <b-col class="ally-select pl-1">
                                        <allySelect
                                          name="memo2"
                                          :items="memoItems"
                                          v-model="investmentAnalytics.initialInvestment.memo[2]"
                                        />
                                      </b-col>
                                      <b-col cols="3" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="guideline pl-0">
                                        ※目安
                                      </b-col>
                                      <button
                                        class="btn btn-ally btn-reflection mr-1 mt-auto md-0"
                                        @click="
                                          changeGuidelineCity(
                                            'propertyTax',
                                            0,
                                            propertyTaxGuideline(0)
                                          )
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row class="pt-1">
                                  <b-col cols="2" class="title">
                                    土地
                                  </b-col>
                                  <b-col cols="2" class="title text-right">固定資産税</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component pl-1">
                                        <allyText
                                          name="land-propertyTax"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment.propertyTax[0]
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                        >円/年</b-col
                                      >
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component pl-1">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="propertyTaxGuideline(0) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                        >円/年</b-col
                                      >
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row class="row-cols-1 mt-2" style="padding-top:2px;">
                                  <b-row class="mx-0">
                                    <button
                                      class="btn btn-ally btn-reflection mr-1 ml-auto"
                                      @click="
                                        changeGuidelineCity(
                                          'cityPlanningTax',
                                          0,
                                          cityPlanningTaxGuideline(0)
                                        )
                                      "
                                    >
                                      反映
                                    </button>
                                  </b-row>
                                  <b-row class="mx-0 pt-1">
                                    <b-col cols="2" class="title"> </b-col>
                                    <b-col cols="2" class="title text-right">都市計画税</b-col>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component pl-1">
                                          <allyText
                                            name="land-cityPlanningTax"
                                            type="number"
                                            class="number"
                                            :separation="true"
                                            halfWidth
                                            v-model="
                                              investmentAnalytics.initialInvestment
                                                .cityPlanningTax[0]
                                            "
                                            roundType="down"
                                          />
                                        </b-col>
                                        <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                          >円/年</b-col
                                        >
                                      </b-row>
                                    </b-col>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component pl-1">
                                          <b-form-input
                                            disabled
                                            class="currency"
                                            :value="cityPlanningTaxGuideline(0) | currency"
                                          />
                                        </b-col>
                                        <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                          >円/年</b-col
                                        >
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-row>
                                <span
                                  v-for="id of investmentAnalytics.existingBuildingCount"
                                  v-bind:key="`for05-${id}`"
                                >
                                  <b-row />
                                  <b-row class="row-cols-1 mt-2">
                                    <b-row class="mx-0">
                                      <button
                                        class="btn btn-ally btn-reflection mr-1 ml-auto"
                                        @click="
                                          changeGuidelineCity(
                                            'propertyTax',
                                            id,
                                            propertyTaxGuideline(id)
                                          )
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                    <b-row class="mx-0 pt-1">
                                      <b-col class="title" cols="2"> 既存建物{{ id }} </b-col>
                                      <b-col class="title text-right" cols="2">
                                        固定資産税
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component pl-1">
                                            <allyText
                                              :name="'propertyTax11-' + id"
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.initialInvestment.propertyTax[
                                                  id
                                                ]
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                            >円/年</b-col
                                          >
                                        </b-row>
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component pl-1">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="propertyTaxGuideline(id) | currency"
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                            >円/年</b-col
                                          >
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                  </b-row>
                                  <b-row class="row-cols-1 mt-2" style="padding-top:2px;">
                                    <b-row class="mx-0">
                                      <button
                                        class="btn btn-ally btn-reflection mr-1 ml-auto"
                                        @click="
                                          changeGuidelineCity(
                                            'cityPlanningTax',
                                            id,
                                            cityPlanningTaxGuideline(id)
                                          )
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                    <b-row class="mx-0 pt-1">
                                      <b-col class="title " cols="2"> </b-col>
                                      <b-col class="title text-right" cols="2">都市計画税 </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component pl-1">
                                            <allyText
                                              :name="'cityPlanningTax11-' + id"
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.initialInvestment
                                                  .cityPlanningTax[id]
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                            >円/年</b-col
                                          >
                                        </b-row>
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component pl-1">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="cityPlanningTaxGuideline(id) | currency"
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                            >円/年</b-col
                                          >
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                  </b-row>
                                </span>
                                <span
                                  v-for="id of investmentAnalytics.newBuildingCount"
                                  v-bind:key="`for06-${id}`"
                                >
                                  <b-row />
                                  <b-row class="row-cols-1 mt-2">
                                    <b-row class="mx-0">
                                      <button
                                        class="btn btn-ally btn-reflection mr-1 ml-auto"
                                        @click="
                                          changeGuidelineCity(
                                            'propertyTax',
                                            10 + id,
                                            propertyTaxGuideline(10 + id)
                                          )
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                    <b-row class="mx-0 pt-1">
                                      <b-col class="title" cols="2"> 新築建物{{ id }} </b-col>
                                      <b-col class="title text-right" cols="2">
                                        固定資産税
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component pl-1">
                                            <allyText
                                              :name="'propertyTax11-' + id"
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.initialInvestment.propertyTax[
                                                  10 + id
                                                ]
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                            >円/年</b-col
                                          >
                                        </b-row>
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component pl-1">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="propertyTaxGuideline(10 + id) | currency"
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                            >円/年</b-col
                                          >
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                  </b-row>
                                  <b-row class="row-cols-1 mt-2" style="padding-top:2px;">
                                    <b-row class="mx-0">
                                      <button
                                        class="btn btn-ally btn-reflection mr-1 ml-auto"
                                        @click="
                                          changeGuidelineCity(
                                            'cityPlanningTax',
                                            10 + id,
                                            cityPlanningTaxGuideline(10 + id)
                                          )
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                    <b-row class="mx-0 pt-1">
                                      <b-col class="title " cols="2"> </b-col>
                                      <b-col class="title text-right" cols="2">都市計画税 </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component pl-1">
                                            <allyText
                                              :name="'cityPlanningTax11-' + (10 + id)"
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.initialInvestment
                                                  .cityPlanningTax[10 + id]
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                            >円/年</b-col
                                          >
                                        </b-row>
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component pl-1">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="cityPlanningTaxGuideline(10 + id) | currency"
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                            >円/年</b-col
                                          >
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                  </b-row>
                                </span>
                                <b-row class="row-cols-1 mt-2">
                                  <b-row class="mx-0">
                                    <button
                                      class="btn btn-ally btn-reflection mr-1 ml-auto"
                                      @click="
                                        changeGuidelineCity(
                                          'propertyTax',
                                          21,
                                          propertyTaxGuideline(21)
                                        )
                                      "
                                    >
                                      反映
                                    </button>
                                  </b-row>
                                  <b-row class="mx-0 pt-1">
                                    <b-col class="title" cols="2">
                                      付属建物
                                    </b-col>
                                    <b-col class="title text-right" cols="2">
                                      <b-row> <b-col>固定資産税</b-col> </b-row>
                                    </b-col>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component pl-1">
                                          <allyText
                                            :name="'depreciableAssets3'"
                                            type="number"
                                            class="number"
                                            :separation="true"
                                            halfWidth
                                            v-model="
                                              investmentAnalytics.initialInvestment.propertyTax[21]
                                            "
                                            roundType="down"
                                          />
                                        </b-col>
                                        <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                          >円/年</b-col
                                        >
                                      </b-row>
                                    </b-col>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component pl-1">
                                          <b-form-input
                                            disabled
                                            class="currency"
                                            :value="propertyTaxGuideline(21) | currency"
                                          />
                                        </b-col>
                                        <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                          >円/年</b-col
                                        >
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row class="mx-0">
                                    <button
                                      class="btn btn-ally btn-reflection mr-1 ml-auto"
                                      @click="
                                        changeGuidelineCity(
                                          'cityPlanningTax',
                                          21,
                                          cityPlanningTaxGuideline(21)
                                        )
                                      "
                                    >
                                      反映
                                    </button>
                                  </b-row>
                                  <b-row class="mx-0 pt-1">
                                    <b-col class="title " cols="2"> </b-col>
                                    <b-col class="title text-right" cols="2">都市計画税 </b-col>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component pl-1">
                                          <allyText
                                            :name="'cityPlanningTax11-' + 21"
                                            type="number"
                                            class="number"
                                            :separation="true"
                                            halfWidth
                                            v-model="
                                              investmentAnalytics.initialInvestment
                                                .cityPlanningTax[21]
                                            "
                                            roundType="down"
                                          />
                                        </b-col>
                                        <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                          >円/年</b-col
                                        >
                                      </b-row>
                                    </b-col>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component pl-1">
                                          <b-form-input
                                            disabled
                                            class="currency"
                                            :value="cityPlanningTaxGuideline(21) | currency"
                                          />
                                        </b-col>
                                        <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                          >円/年</b-col
                                        >
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row class="mx-0 pt-1">
                                    <b-col class="title" cols="2">
                                      償却資産
                                    </b-col>
                                    <b-col class="title text-right" cols="2">
                                      <b-row> <b-col>固定資産税</b-col> </b-row>
                                    </b-col>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component pl-1">
                                          <allyText
                                            :name="'depreciableAssets3'"
                                            type="number"
                                            class="number"
                                            :separation="true"
                                            halfWidth
                                            v-model="
                                              investmentAnalytics.initialInvestment.propertyTax[22]
                                            "
                                            roundType="down"
                                          />
                                        </b-col>
                                        <b-col cols="3" class="unit" :style="noLineBreakStyle"
                                          >円/年</b-col
                                        >
                                      </b-row>
                                    </b-col>
                                    <b-col />
                                  </b-row>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </b-card-group>
                    </b-card>
                  </b-card-group>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!-- 売買代金 -->
            <b-card no-body class="category">
              <b-card-header role="tab">
                <b-button id="btnTradingValue" variant="link" v-b-toggle.tabTradingValue>
                  売買代金
                </b-button>
              </b-card-header>
              <b-collapse id="tabTradingValue" role="tabpanel" v-if="lazLoading[1]">
                <b-card-body style="max-width:800px;">
                  <b-container>
                    <b-row>
                      <b-col class="title">
                        ※代金総額（税込）
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col class="component">
                            <allyText
                              name="totalTradingPrice"
                              type="number"
                              class="number"
                              :separation="true"
                              halfWidth
                              v-model="investmentAnalytics.initialInvestment.totalTradingPrice"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                        </b-row>
                      </b-col>
                      <b-col class="title">
                        <b style="color:red;" v-if="totalPriceCheck">
                          総額が合致しません
                        </b>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="borderline" />
                    </b-row>
                    <b-row>
                      <b-col class="title bold">
                        種別
                      </b-col>
                      <b-col class="title-center bold">
                        <b-row>
                          <b-col>
                            売買代金
                          </b-col>
                          <b-col cols="2" />
                        </b-row>
                      </b-col>
                      <b-col class="title-center">
                        <b-row>
                          <b-col>
                            ※目安
                          </b-col>
                          <b-col cols="2" />
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        土地
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col class="component">
                            <allyText
                              name="tradingValue-land"
                              type="number"
                              class="number"
                              :separation="true"
                              halfWidth
                              v-model="investmentAnalytics.initialInvestment.tradingPrice[0]"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                        </b-row>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col class="component">
                            <b-form-input
                              disabled
                              class="currency"
                              :value="tradingPriceGuideline(0, true) | currency"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                          <button
                            class="btn btn-ally mr-1"
                            style="width: 60px; white-space: nowrap; padding-left: 13px; "
                            @click="
                              changeGuidelineCity(
                                'tradingPrice',
                                0,
                                Math.floor(tradingPriceGuideline(0, true))
                              )
                            "
                          >
                            反映
                          </button>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row
                      v-for="id of investmentAnalytics.existingBuildingCount"
                      v-bind:key="`for03-${id}`"
                    >
                      <b-col class="title">既存建物{{ id }}</b-col>
                      <b-col>
                        <b-row>
                          <b-col class="component">
                            <allyText
                              :name="'tradingValue-existing-building-' + id"
                              type="number"
                              class="number"
                              :separation="true"
                              halfWidth
                              v-model="investmentAnalytics.initialInvestment.tradingPrice[id]"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                        </b-row>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col class="component">
                            <b-form-input
                              disabled
                              class="currency"
                              :value="tradingPriceGuideline(id) | currency"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                          <button
                            class="btn btn-ally mr-1"
                            style="width: 60px; white-space: nowrap; padding-left: 13px; "
                            @click="
                              changeGuidelineCity('tradingPrice', id, tradingPriceGuideline(id))
                            "
                          >
                            反映
                          </button>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        付属建物
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col class="component">
                            <allyText
                              name="tradingValue-land"
                              type="number"
                              class="number"
                              :separation="true"
                              halfWidth
                              v-model="investmentAnalytics.initialInvestment.tradingPrice[21]"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                        </b-row>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col class="component">
                            <b-form-input
                              disabled
                              class="currency"
                              :value="tradingPriceGuideline(21) | currency"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                          <button
                            class="btn btn-ally mr-1"
                            style="width: 60px; white-space: nowrap; padding-left: 13px; "
                            @click="
                              changeGuidelineCity('tradingPrice', 21, tradingPriceGuideline(21))
                            "
                          >
                            反映
                          </button>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="borderline" />
                    </b-row>
                    <b-row>
                      <b-col cols="4" class="title">
                        合計(税抜)
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col cols="5" class="component">
                            <b-form-input
                              disabled
                              class="currency"
                              :value="
                                (Math.floor(investmentAnalytics.initialInvestment.tradingPrice[0]) +
                                  tradingPriceBuilding)
                                  | currency
                              "
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4" class="title">
                        消費税
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col cols="5" class="component">
                            <b-form-input
                              disabled
                              class="currency"
                              :value="tradingPriceBuildingTax | currency"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="4" class="title">
                        合計(税込)
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col cols="5" class="component">
                            <b-form-input
                              disabled
                              class="currency"
                              :value="
                                (Math.floor(investmentAnalytics.initialInvestment.tradingPrice[0]) +
                                  tradingPriceBuilding +
                                  tradingPriceBuildingTax)
                                  | currency
                              "
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!-- 工事計画 -->
            <ConstructionPlan ref="ConstructionPlan" v-if="lazLoading[2]" />
            <!-- 付帯費用 -->
            <IncidentalExpenses ref="IncidentalExpenses" v-if="lazLoading[3]" />
            <!-- 資金計画 -->
            <b-card no-body class="category">
              <b-card-header role="tab">
                <b-button id="btnFundingPlan" variant="link" v-b-toggle.tabFundingPlan>
                  資金計画
                </b-button>
              </b-card-header>
              <b-collapse id="tabFundingPlan" role="tabpanel" v-if="lazLoading[4]">
                <b-card-body style="padding:0;max-width:1050px;">
                  <b-card-group columns style="column-count:2;">
                    <b-card style="border:none;box-shadow:none;">
                      <b-container>
                        <b-row>
                          <b-col cols="12" style="height: 45px;"></b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="4" class="title bold">
                            総事業費
                          </b-col>
                          <b-col class="component">
                            <b-form-input
                              disabled
                              class="currency"
                              :value="totalProjectCost | currency"
                            />
                          </b-col>
                          <b-col cols="1" class="unit">
                            円
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="4">
                            <b-row style="padding:0;">
                              <b-col class="title bold">保証金</b-col>
                            </b-row>
                            <b-row style="padding:0;">
                              <b-col class="title" style="padding-right:0;">（償還あり）</b-col>
                            </b-row>
                          </b-col>
                          <b-col class="component">
                            <allyText
                              name="deposit"
                              type="number"
                              class="number"
                              :separation="true"
                              v-model="investmentAnalytics.initialInvestment.capitalPlan.deposit"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="1" class="unit">
                            円
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="4">
                            <b-row style="padding:0;">
                              <b-col class="title bold">建設協力金</b-col>
                            </b-row>
                            <b-row style="padding:0;">
                              <b-col class="title" style="padding-right:0;">（償還なし）</b-col>
                            </b-row>
                          </b-col>
                          <b-col class="component">
                            <allyText
                              name="constructionCooperationFund"
                              type="number"
                              class="number"
                              :separation="true"
                              v-model="
                                investmentAnalytics.initialInvestment.capitalPlan
                                  .constructionCooperationFund
                              "
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="1" class="unit">
                            円
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="4" class="title bold">
                            敷金
                          </b-col>
                          <b-col class="component">
                            <allyText
                              name="securityDeposit"
                              type="number"
                              class="number"
                              :separation="true"
                              v-model="
                                investmentAnalytics.initialInvestment.capitalPlan.securityDeposit
                              "
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="1" class="unit">
                            円
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="4" class="title bold">
                            礼金
                          </b-col>
                          <b-col class="component">
                            <allyText
                              name="keyMoney"
                              type="number"
                              class="number"
                              :separation="true"
                              v-model="investmentAnalytics.initialInvestment.capitalPlan.keyMoney"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="1" class="unit">
                            円
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="4" class="title bold">
                            自己資金
                          </b-col>
                          <b-col class="component">
                            <allyText
                              name="ownResources"
                              type="number"
                              class="number"
                              :separation="true"
                              v-model="
                                investmentAnalytics.initialInvestment.capitalPlan.ownResources
                              "
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="1" class="unit">
                            円
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-card>
                    <b-card style="border:none;box-shadow:none;">
                      <b-container>
                        <b-row>
                          <b-col />
                          <b
                            class="ml-5 mr-0 mt-auto mb-6"
                            style="font-size: 0.8rem; color:red;"
                            v-if="loansPriceCheck"
                          >
                            ※総事業費との差異あり
                          </b>
                          <b-col v-else />
                          <b-col class="guideline">
                            ※目安
                          </b-col>
                          <button
                            class="btn btn-ally btn-reflection mr-1 mt-auto mb-0"
                            @click="changeGuidelineLoans('loans', loansGuideline)"
                          >
                            反映
                          </button>
                        </b-row>
                        <b-row>
                          <b-col cols="4">
                            <b-row style="padding:0;">
                              <b-col class="title bold">借入金</b-col>
                            </b-row>
                            <b-row style="padding:0;">
                              <b-col class="title" style="padding-right:0;">（債務残高）</b-col>
                            </b-row>
                          </b-col>
                          <b-col>
                            <b-row class=".mr-3">
                              <b-col cols="5" class="component">
                                <allyText
                                  name="loans"
                                  type="number"
                                  class="number"
                                  :separation="true"
                                  v-model="investmentAnalytics.initialInvestment.capitalPlan.loans"
                                  roundType="down"
                                />
                              </b-col>
                              <b-col cols="1" class="unit">
                                円
                              </b-col>
                              <b-col class="component">
                                <b-form-input
                                  disabled
                                  class="currency"
                                  :value="loansGuideline | currency"
                                />
                              </b-col>
                              <b-col cols="1" class="unit">
                                円
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col class="title bold">
                            借入金利
                          </b-col>
                        </b-row>
                        <b-row style="padding-top:2px;">
                          <b-col cols="4" class="ally-select" style="padding:0;">
                            <b-form-select
                              id="borrowingRateApplicationYears"
                              v-model="
                                investmentAnalytics.initialInvestment.capitalPlan
                                  .borrowingRateApplicationYears
                              "
                              :options="yearItems"
                              size="sm"
                            >
                            </b-form-select>
                          </b-col>
                          <b-col>
                            <allyText
                              name="borrowingRate0"
                              type="number"
                              class="number"
                              :floatLength="2"
                              v-model="
                                investmentAnalytics.initialInvestment.capitalPlan.borrowingRate[0]
                              "
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="1" class="unit">
                            ％
                          </b-col>
                        </b-row>
                        <b-row style="padding-top:2px;">
                          <b-col
                            cols="4"
                            class="title"
                            style="font-size:14px;color:#495057;padding-left:22px;"
                          >
                            {{
                              investmentAnalytics.initialInvestment.capitalPlan
                                .borrowingRateApplicationYears > 0
                                ? investmentAnalytics.initialInvestment.capitalPlan
                                    .borrowingRateApplicationYears +
                                  1 +
                                  '年目以降'
                                : ''
                            }}
                          </b-col>
                          <b-col>
                            <allyText
                              name="borrowingRate1"
                              type="number"
                              class="number"
                              :floatLength="2"
                              v-model="
                                investmentAnalytics.initialInvestment.capitalPlan.borrowingRate[1]
                              "
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="1" class="unit">
                            ％
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="4">
                            <b-row style="padding:0;">
                              <b-col class="title bold">返済期間</b-col>
                            </b-row>
                            <b-row style="padding:0;">
                              <b-col class="title" style="padding-right:0;">（残期間）</b-col>
                            </b-row>
                          </b-col>
                          <b-col>
                            <allyText
                              name="repaymentPeriod"
                              type="number"
                              class="number"
                              :separation="true"
                              v-model="
                                investmentAnalytics.initialInvestment.capitalPlan.repaymentPeriod
                              "
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="1" class="unit">
                            年
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="4" class="title bold">
                            返済方法
                          </b-col>
                          <b-col>
                            <b-row>
                              <b-col class="ally-select">
                                <allySelect
                                  name="repaymentMethod"
                                  :items="repaymentMethodItems"
                                  v-model="
                                    investmentAnalytics.initialInvestment.capitalPlan
                                      .repaymentMethod
                                  "
                                />
                              </b-col>
                              <b-col cols="1" />
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="4" class="title bold">
                            保証金償還期間
                          </b-col>
                          <b-col>
                            <allyText
                              name="redemptionPeriod"
                              type="number"
                              class="number"
                              :separation="true"
                              v-model="
                                investmentAnalytics.initialInvestment.capitalPlan.redemptionPeriod
                              "
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="1" class="unit">
                            年
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-card>
                  </b-card-group>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-card-group>
        </b-card-body>
      </b-collapse>
    </b-card>
    <!-- 顧客検索モーダル -->
    <b-modal
      title="顧客検索"
      size="xl"
      id="searchCustomer"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      ok-variant="light"
      ok-only
      no-close-on-backdrop
    >
      <template v-slot:modal-ok>戻る</template>
      <CustomerList mode="Search" @selectCustomer="row => selectCustomer(row)" />
    </b-modal>
    <!-- 顧客新規モーダル -->
    <b-modal
      title="新規登録"
      size="xl"
      id="createCustomer"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      no-close-on-backdrop
    >
      <template #modal-footer="{ cancel }">
        <button
          style="background-color: white; color: black;"
          class="btn btn-ally"
          size="sm"
          variant="outline-secondary"
          @click="modalOkPreventDefault"
        >
          登録
        </button>
        <button
          style="background-color: white; color: black;"
          class="btn btn-ally"
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          戻る
        </button>
      </template>
      <div class="px-5">
        <CustomerViewer
          viewMode="Add"
          comp="true"
          @modalClause="modalClause()"
          ref="customerViewer"
        />
      </div>
    </b-modal>
    <!-- 担当者検索モーダル -->
    <b-modal
      title="アカウント検索"
      size="xl"
      id="searchAccount"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      ok-variant="light"
      ok-only
      no-close-on-backdrop
    >
      <template v-slot:modal-ok>戻る</template>
      <AccountList :mode="'Search'" @selectAccount="selectAccount" />
    </b-modal>
  </div>
</template>
<script>
  import CustomerList from '../../Customer/List';
  import Vue from 'vue';
  import CustomerViewer from '@/views/Customer/View';
  import AccountList from '@/views/Account/List';
  import Calculation from './Calculation';
  import ConstructionPlan from './ConstructionPlan/ConstructionPlan';
  import IncidentalExpenses from './IncidentalExpenses/IncidentalExpenses';
  //import PropertySettingV2 from '@/components/Property/PropertySettingV2';
  import newBuildingFunction from './newBuildingFunction.js';

  export default {
    props: ['model'],
    mixins: [Calculation, newBuildingFunction],
    components: {
      CustomerList,
      CustomerViewer,
      AccountList,
      ConstructionPlan,
      IncidentalExpenses,
      //PropertySettingV2,
    },
    data: function() {
      return {
        viewFlag: true,
        selectedTab: [0, 0],
        // 借入金利の年数
        yearItems: Array(45)
          .fill(null)
          .map((v, i) => {
            return { text: i + 1 + '年目まで', value: i + 1 };
          }),
        // 建物棟数
        buildingCountItems: [
          { text: '0', value: 0 },
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
          { text: '5', value: 5 },
          { text: '6', value: 6 },
          { text: '7', value: 7 },
          { text: '8', value: 8 },
          { text: '9', value: 9 },
          { text: '10', value: 10 },
        ],
        // 現況地目
        currentLandUseItems: [
          { text: '', value: null },
          { text: '宅地', value: '宅地' },
          { text: '雑種地', value: '雑種地' },
          { text: '原野', value: '原野' },
          { text: '田', value: '田' },
          { text: '畑', value: '畑' },
          { text: '山林', value: '山林' },
          { text: 'その他', value: 'その他' },
        ],
        // 構造
        structureItems: [
          { text: '', value: null },
          { text: '木造', value: '木造' },
          { text: '鉄骨鉄筋コンクリート造', value: '鉄骨鉄筋コンクリート造' },
          { text: '鉄筋コンクリート造', value: '鉄筋コンクリート造' },
          { text: '軽量鉄骨造', value: '軽量鉄骨造' },
          { text: '重量鉄骨造', value: '重量鉄骨造' },
        ],
        // 種類
        kindItems: [
          { text: '', value: null },
          { text: '事務所', value: '事務所' },
          { text: '店舗', value: '店舗' },
          { text: '居宅', value: '居宅' },
          { text: '共同住宅', value: '共同住宅' },
          { text: '旅館/ホテル', value: '旅館/ホテル' },
          { text: '病院/診療所', value: '病院/診療所' },
          { text: '工場/倉庫', value: '工場/倉庫' },
        ],
        // 付属建物 種類
        attachedBuildingItems: [
          { text: '', value: null },
          { text: '物置', value: '物置' },
          { text: '車庫', value: '車庫' },
        ],
        // 返済方法
        repaymentMethodItems: [
          { text: '', value: null },
          { text: '元利均等方式', value: '元利均等方式' },
          { text: '元金均等方式', value: '元金均等方式' },
        ],
        // メモ
        memoItems: [
          { text: '', value: null },
          { text: '(推定)', value: '(推定)' },
          { text: '(実額)', value: '(実額)' },
        ],
        lazLoading: [],
        lazNum: 11,
        noLineBreakStyle: {
          'white-space': 'nowrap',
        },
      };
    },
    created: async function() {
      for (let i = 0; i < this.lazNum; i++) {
        this.lazLoading.push(false);
      }
      let count = 0;
      //ページが重たくなるので、段階敵に表示する
      let inter = setInterval(() => {
        Vue.set(this.lazLoading, count, true);
        count++;
        if (count >= this.lazNum) {
          clearInterval(inter);
        }
      }, 100);

      // 初期値設定
      this.investmentAnalytics.initialInvestment.propertyTaxRate = this.getTax('固定資産税率');
      this.investmentAnalytics.initialInvestment.cityPlanningTaxRate = this.getTax('都市計画税率');

      await this.newBuildingInit();
    },
    methods: {
      searchCustomer: function() {
        this.$bvModal.show('searchCustomer');
      },
      selectCustomer(row) {
        this.$bvModal.hide('searchCustomer');
        this.investmentAnalytics.ownerName = row.customerName;
        this.investmentAnalytics.ownerId = row.id;
      },
      selectAccount(row) {
        this.$bvModal.hide('searchAccount');
        this.$set(this.investmentAnalytics, 'staffName', row.familyname + ' ' + row.givenname);
      },
      //物件情報を取得する
      selectProperty: function(result) {
        this.investmentAnalytics.propertyData = result;
      },
      //目安値を反映する
      changeGuideline: function(id, sum) {
        Vue.set(this.investmentAnalytics.initialInvestment.propertyTaxValuation, id, sum);
      },
      //固定資産税・都市計画税の反映を行う
      changeGuidelineCity: function(variable, id, sum) {
        Vue.set(this.investmentAnalytics.initialInvestment[variable], id, sum);
      },
      //借入金の目安値を反映する
      changeGuidelineLoans: function(id, sum) {
        Vue.set(this.investmentAnalytics.initialInvestment.capitalPlan, id, sum);
      },
      //情報が変更されているかチェックする
      getChangeCheck: function(newVal, oldVal) {
        if (oldVal == null) return false;
        var keys = Object.keys(newVal);
        for (let j = 0; j < keys.length; j++) {
          if (JSON.stringify(newVal[keys[j]]) != JSON.stringify(oldVal[keys[j]])) {
            return true;
          }
        }
        return false;
      },
      //顧客情報保存を確認したとき処理する
      modalClause: function() {
        this.$bvModal.hide('createCustomer');
      },
      // CustomerViewerのモーダル、登録ボタン用メソッド呼び出し
      customerCreate: function() {
        const customerViewer = this.$refs.customerViewer;
        return customerViewer.create();
      },
      //  モーダルの登録ボタンclick後の誤動作（モーダルclose）抑制メソッド
      modalOkPreventDefault: async function() {
        //顧客情報の登録を実行
        let result = await this.$refs.customerViewer.create();
        //正常に登録が完了した時モーダルを閉じる
        if (result.flag == true) {
          this.selectCustomer(result.result);
          this.$bvModal.hide('createCustomer');
        }
      },
    },
    computed: {
      // 土地 所在地
      locationLand: function() {
        return this.investmentAnalytics.initialInvestment.propertyDescription[0].location;
      },
      // 新築建物 延床面積
      newBuildingTotalFloorArea: function() {
        let areas = [];
        for (let i = 1; i <= this.investmentAnalytics.newBuildingCount; i++) {
          areas.push(
            Math.floor(
              Number(this.investmentAnalytics.initialInvestment.propertyDescription[10 + i].area) *
                100
            ) / 100
          );
        }
        return areas;
      },
      //代金総額のエラー
      totalPriceCheck: function() {
        let flag = false;

        // 代金総額 != 合計(税抜) + 消費税
        let sumTax =
          Math.floor(this.investmentAnalytics.initialInvestment.tradingPrice[0]) +
          Math.floor(this.tradingPriceBuilding) +
          this.tradingPriceBuildingTax;
        flag = this.investmentAnalytics.initialInvestment.totalTradingPrice != sumTax;

        //代金総額が空欄の時、エラーを表示しない
        if (this.investmentAnalytics.initialInvestment.totalTradingPrice == null) flag = false;
        //土地と既存物件が空欄の時エラーを表示しない
        for (let i = 0; i < this.investmentAnalytics.existingBuildingCount + 1; i++) {
          if (this.investmentAnalytics.initialInvestment.tradingPrice[i] == null) {
            flag = false;
          }
        }
        return flag;
      },
      //借入金のエラー
      loansPriceCheck: function() {
        let flag = false;

        // 自己資金（保証金＋建設協力金＋敷金＋礼金＋自己資金）＋借入金
        let sumTax =
          // 自己資金
          this.investmentAnalytics.initialInvestment.capitalPlan.deposit +
          this.investmentAnalytics.initialInvestment.capitalPlan.constructionCooperationFund +
          this.investmentAnalytics.initialInvestment.capitalPlan.securityDeposit +
          this.investmentAnalytics.initialInvestment.capitalPlan.keyMoney +
          this.investmentAnalytics.initialInvestment.capitalPlan.ownResources +
          // 借入金
          this.investmentAnalytics.initialInvestment.capitalPlan.loans;
        // 総事業費 !=  自己資金＋借入金
        flag = this.totalProjectCost != sumTax;

        return flag;
      },
    },
    watch: {
      // 新築建物棟数変更時
      'investmentAnalytics.newBuildingCount': {
        handler: function() {
          // 下記項目に自動反映
          // 初期投資/工事計画/地盤改良工事/詳細入力/建築棟数
          this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[18].quantity = this.investmentAnalytics.newBuildingCount;
          // 初期投資/付帯費用/許認可費/詳細入力/建築確認申請/詳細入力/申請件数
          this.investmentAnalytics.initialInvestment.licensingCost[0].quantity = this.investmentAnalytics.newBuildingCount;
          // 初期投資/付帯費用/許認可費/詳細入力/建築完了検査/詳細入力/申請件数
          this.investmentAnalytics.initialInvestment.licensingCost[1].quantity = this.investmentAnalytics.newBuildingCount;
          // 初期投資/付帯費用/許認可費/詳細入力/構造計算適合判定/詳細入力/申請件数
          this.investmentAnalytics.initialInvestment.licensingCost[2].quantity = this.investmentAnalytics.newBuildingCount;
          // 初期投資/付帯費用/支払手数料/詳細入力/建物保存登記/件数
          this.$set(
            this.investmentAnalytics.initialInvestment.paymentFees.numberOfCases,
            2,
            this.investmentAnalytics.newBuildingCount
          );
        },
        deep: false,
      },
      // 新築建物 延床面積 変更時
      newBuildingTotalFloorArea: function(newVal, oldVal) {
        for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
          if (newVal[i] != oldVal[i]) {
            const value = newVal[i];
            const target = this.investmentAnalytics.initialInvestment.paymentFees
              .buildingRepresentationRegistration[i];
            // 初期投資/付帯費用/支払手数料/建物表示登記/面積 を更新
            if (value == null || value == '') {
              target.content = null;
            }
            //
            else {
              const items = this.getUnitPricesItems('建物表示登記');
              for (let j = 0; j < items.length; j++) {
                const item = items[j].value;
                const pos = [
                  item.lastIndexOf('～'),
                  item.lastIndexOf('㎡'),
                  item.lastIndexOf('超'),
                ];
                // ～㎡
                if (pos[0] >= 0 && pos[1] > 0) {
                  const text = item.substr(pos[0] + 1, pos[1] - 1);
                  const limit = Number(text.replace(',', ''));
                  if (value <= limit) {
                    target.content = item;
                    break;
                  }
                }
                // ㎡超
                else if (pos[1] > 0 && pos[2] > 0) {
                  const text = item.substring(0, pos[1]);
                  const limit = Number(text.replace(',', ''));
                  if (value > limit) {
                    target.content = item;
                    break;
                  }
                }
              }
            }
          }
        }
      },
      // 土地 所在地 変更時
      locationLand: function(v) {
        // 既存建物 所在地 更新
        for (let i = 1; i <= 10; i++) {
          this.investmentAnalytics.initialInvestment.propertyDescription[i].location = v;
        }
      },
      //固定資産税率
      // 'investmentAnalytics.initialInvestment.propertyTaxRate': function(newVal, oldVal) {
      //   if (oldVal != null) {
      //     for (let id = 11; id < 21; id++) {
      //       let propertyDescription = this.investmentAnalytics.initialInvestment
      //         .propertyDescription[id];
      //       //既存物件と償却資産は処理を行わない。
      //       let unitPrice = this.getNewBuilding(propertyDescription);
      //       if (unitPrice && unitPrice?.length > 0) {
      //         if (unitPrice[0][5] == '-') {
      //           //基準価格なし
      //           this.investmentAnalytics.initialInvestment.propertyTax[id] = 0;
      //         } else {
      //           //税価格=評価額(=新築建物課税標準価格x延床面積)x税率
      //           this.investmentAnalytics.initialInvestment.propertyTax[id] =
      //             (unitPrice[0][5] *
      //               Number(propertyDescription.area) *
      //               Number(this.investmentAnalytics.initialInvestment.propertyTaxRate)) /
      //             100;
      //           if (propertyDescription.kind == '住宅') {
      //             // 【新築住宅に係る税額の減額措置】
      //             // https://www.mlit.go.jp/jutakukentiku/house/jutakukentiku_house_tk2_000021.html
      //             this.investmentAnalytics.initialInvestment.propertyTax[id] /= 2;
      //           }
      //         }
      //       }
      //     }
      //   }
      //   return newVal;
      // },
      // //都市計画税率
      // 'investmentAnalytics.initialInvestment.cityPlanningTaxRate': function(newVal, oldVal) {
      //   if (oldVal != null) {
      //     for (let id = 11; id < 21; id++) {
      //       let propertyDescription = this.investmentAnalytics.initialInvestment
      //         .propertyDescription[id];
      //       //既存物件と償却資産は処理を行わない。
      //       let unitPrice = this.getNewBuilding(propertyDescription);
      //       if (unitPrice && unitPrice?.length > 0) {
      //         if (unitPrice[0][5] == '-') {
      //           //基準価格なし
      //           this.investmentAnalytics.initialInvestment.cityPlanningTax[id] = 0;
      //         } else {
      //           //税価格=評価額(=新築建物課税標準価格x延床面積)x税率
      //           this.investmentAnalytics.initialInvestment.cityPlanningTax[id] =
      //             (unitPrice[0][5] *
      //               Number(propertyDescription.area) *
      //               Number(this.investmentAnalytics.initialInvestment.cityPlanningTaxRate)) /
      //             100;
      //         }
      //       }
      //     }
      //   }
      //   return newVal;
      // },
    },
  };
</script>
