<template>
  <CommonLayout title="Ⅲ．実行可能な具体的活用法">
    <template v-slot>
      <ReportSubTitle subTitle="比較検討の前提条件" />
      <table
        style="width: 97%;margin-top: 150px; background-color: white; table-layout: fixed; font-size: 60px; margin-left:75px;"
        border="1"
      >
        <thead>
          <tr>
            <td v-for="(head, i) in header" :key="i" :style="headerStyle">
              {{ head.label }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(label, i) in columnLabels" :key="i" style="font-size: 55px">
            <td v-html="label.label" :style="{ 'text-align': 'center' }"></td>
            <td
              v-for="(pro, j) in rentalPropertyMerged.slice(0, 7)"
              :key="'pro' + j"
              :style="{
                'text-align': typeof pro[label.name] === 'number' ? 'right' : 'center',
                'font-size': label.name === 'structure' || label.name === 'kind' ? '55px' : '55px',
                padding: '0 30px',
                'background-color': background[j] ? '' : '#CCCCCC',
              }"
            >
            <!--工事費は一戸当たり工事費×戸数を表示する-->
              {{ pro[label.name] 
              ? label.name === 'constructionCostPerUnit' 
              ? pro['numberOfHouses'] * pro[label.name] + ' ' + label.unit 
              : pro[label.name] + ' ' + label.unit
              : '' }}
            </td>
            <td style="padding-left: 30px; font-size: 50px">
              {{ detalList[i] }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import ReportSubTitle from '@/components/ExportReport/ReportSubTitle';
  import { headerStyle, bodyStyle, subHeaderStyle } from '@/components/ExportReport/style';

  export default {
    props: {
      property: {
        type: Object,
        required: true,
      },
    },
    components: {
      CommonLayout,
      ReportSubTitle,
    },
    data() {
      return {
        headerStyle,
        bodyStyle,
        subHeaderStyle,
        background: [],
        columnLabels: [
          { name: 'floorPlanAndName', label: '間取り', unit: '' },
          { name: 'constructionCostPerUnit', label: '工事費', unit: '万円' },
          { name: 'structure', label: '構造', unit: '' },
          { name: 'kind', label: '用途', unit: '' },
          {
            name: 'legalServiceLife',
            label: '法定耐用年数',
            unit: '年',
          },
          // { name: 'numberOfLevels', label: '階数', unit: '階' },
          { name: 'numberOfHouses', label: '総戸数', unit: '戸' },
          { name: 'monthlyRent', label: '月家賃', unit: '万円/月' },
          { name: 'vacancyRate', label: '空室率', unit: '％' },
          {
            name: 'repairCostsPerUnit',
            label: '一戸当たりの修繕費',
            unit: '万円/戸',
          },
          {
            name: 'managementCostRate',
            label: '管理費率',
            unit: '％',
          },
          {
            name: 'firstYearExpenseRatio',
            label: '初年度経費率',
            unit: '％',
          },
          {
            name: 'propertyTaxAssessmentRate',
            label: '建物固定資産<br/>税評価率',
            unit: '％',
          },
          { name: 'personalFunds', label: '自己資金', unit: '万円' },
          { name: 'borrowingRate', label: '借入金利', unit: '％' },
          { name: 'borrowingPeriod', label: '借入期間', unit: '年' },
        ],
        detalList: [
          '1DK,3LDK等',
          '設備・外構込み（税込）',
          '木造・軽鉄・重鉄・RC',
          '構造の法定耐用年数',
          // '地上階数',
          '賃貸に出す総戸数',
          '一戸当たりの月家賃',
          '賃貸期間中を通して　　　見込む空室の割合',
          "年間の修繕費　　　　　（通常は工事費の　　　0.5～1%）",
          '賃貸管理業者に支払うお金（対家賃）',
          '初年度にかかる税金等（通常は工事費の　　　　5～8%）',
          '実際の建築費に対する　建物固定資産税評価額　の割合',
          '頭金（残りは借入金）',
          '固定金利として計算',
          '通常,木造20～25年,　　軽量鉄骨25～30年,　　　鉄骨RC30～35年',
        ],
      };
    },
    computed: {
      header() {
        return [
          { name: 0, label: '', width: 2 },
          { name: 1, label: '集合型賃貸①' },
          { name: 2, label: '集合型賃貸②' },
          { name: 3, label: '集合型賃貸③' },
          { name: 4, label: '集合型賃貸④' },
          { name: 5, label: '戸建賃貸①' },
          { name: 6, label: '戸建賃貸②' },
          { name: 6, label: '戸建賃貸③' },
          { name: 7, label: '詳細', width: 2 },
        ];
      },
      items() {
        return this.columnLabels.map((col, i) => {
          return [col.label]
            .concat(
              this.rentalPropertyMerged.map(val => {
                return val[col.name];
              })
            )
            .concat([this.detalList[i]]);
        });
      },
      rentalPropertyMerged() {
        let items = [];
        items = this.property.overallPlan.rentalProperty.map((val, i) => {
          return {
            ...this.$store.state.master.defaultValue.rentalProperty[i],
            ...val,
          };
        });
        Array.from(items).forEach(value => {
          if (!value.checked) {
            this.background.push(value.checked);
            Object.keys(value).forEach(tag => {
              value[tag] = null;
            });
          } else {
            this.background.push(value.checked);
          }
        });
        return items;
      },
    },
  };
</script>
