var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    width: this.width,
    height: this.height,
    'background-color': 'white',
    border: 'solid 1px',
    padding: '2%',
    'border-radius': '10px',
  })},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }