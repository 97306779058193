var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"ConsultantFees"}},[_c('b-card',{staticClass:"category",attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabConsultantFees",modifiers:{"tabConsultantFees":true}}],attrs:{"id":"BtnConsultantFees","variant":"link"}},[_vm._v(" コンサルタント料 ")])],1),_c('b-collapse',{attrs:{"id":"tabConsultantFees","role":"tabpanel"}},[_c('b-card-body',[_c('b-tabs',{attrs:{"fill":"","pills":""},model:{value:(_vm.investmentAnalytics.initialInvestment.isDetailConsultingCost),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment, "isDetailConsultingCost", $$v)},expression:"investmentAnalytics.initialInvestment.isDetailConsultingCost"}},[_c('b-tab',{attrs:{"title":"概算入力"}},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v("総額")]),_c('b-col',{staticClass:"title ",attrs:{"cols":"2"}},[_vm._v(" (税抜) ")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"name":"ConsultantFeesTotal","type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(_vm.investmentAnalytics.initialInvestment.estimatedConsultingCost),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment, "estimatedConsultingCost", $$v)},expression:"investmentAnalytics.initialInvestment.estimatedConsultingCost"}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}}),_c('b-col',{staticClass:"title",attrs:{"cols":"2"}},[_vm._v(" (税込) ")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.sumTax(
                            Math.floor(
                              _vm.investmentAnalytics.initialInvestment.estimatedConsultingCost
                            )
                          ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col')],1)],1)],1),_c('b-tab',{attrs:{"title":"詳細入力"}},[_c('b-container',{staticClass:"detail"},[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("項目")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v("コンサルタント料")]),_c('b-col',{attrs:{"cols":"3"}})],1)],1),_c('b-col',{staticClass:"title-center "},[_c('b-row',[_c('b-col',[_vm._v("詳細")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v("開発許可")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(Math.floor(
                            _vm.investmentAnalytics.initialInvestment.consultingCost[0].amount
                          ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"},[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabConsultantFees1",modifiers:{"tabConsultantFees1":true}}],staticClass:"btn-detail",attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabConsultantFees1","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("開発許可")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"ally-select"},[_c('allySelect',{attrs:{"name":"developmentPermits","items":_vm.developmentPermitsItems},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.consultingCost[0]
                                      .content
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.consultingCost[0]
                                      , "content", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.consultingCost[0]\n                                      .content\n                                  "}})],1)],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"guideline"},[_vm._v(" ※地積 × 1.2 ")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("開発区域面積")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"floatLength":2,"roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.consultingCost[0]
                                      .quantity
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.consultingCost[0]
                                      , "quantity", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.consultingCost[0]\n                                      .quantity\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("㎡")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","mode":"View","separation":true,"value":(Math.floor(
                                      _vm.investmentAnalytics.initialInvestment.propertyDescription[0]
                                        .area * 100
                                    ) /
                                      100) *
                                      1.2,"floatLength":2,"roundType":"down"}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("㎡")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("単価")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice(
                                      '開発許可',
                                      _vm.investmentAnalytics.initialInvestment.consultingCost[0]
                                        .content
                                    ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("円/㎡")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"guideline"},[_vm._v("※目安 "),_c('button',{staticClass:"btn-ally",on:{"click":function($event){_vm.changeGuideline(
                                      0,
                                      Math.floor(
                                        (Math.floor(
                                          _vm.investmentAnalytics.initialInvestment.consultingCost[0]
                                            .quantity * 100
                                        ) /
                                          100) *
                                          _vm.getUnitPrice(
                                            '開発許可',
                                            _vm.investmentAnalytics.initialInvestment
                                              .consultingCost[0].content
                                          )
                                      )
                                    )}}},[_vm._v(" 反映 ")])])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("コンサルタント料")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.consultingCost[0].amount
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.consultingCost[0], "amount", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.consultingCost[0].amount\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(Math.floor(
                                      (Math.floor(
                                        _vm.investmentAnalytics.initialInvestment.consultingCost[0]
                                          .quantity * 100
                                      ) /
                                        100) *
                                        _vm.getUnitPrice(
                                          '開発許可',
                                          _vm.investmentAnalytics.initialInvestment.consultingCost[0]
                                            .content
                                        )
                                    ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1)],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v("税務コンサル")]),_c('b-col',{staticClass:"component",attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(Math.floor(
                            _vm.investmentAnalytics.initialInvestment.consultingCost[1].amount
                          ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"},[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabConsultantFees2",modifiers:{"tabConsultantFees2":true}}],staticClass:"btn-detail",attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabConsultantFees2","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("件数")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.consultingCost[1]
                                      .quantity
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.consultingCost[1]
                                      , "quantity", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.consultingCost[1]\n                                      .quantity\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("件")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("単価")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice('税務コンサル', '有'))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("円/件")])],1)],1),_c('b-col',{staticClass:"guideline"},[_vm._v("※目安 "),_c('button',{staticClass:"btn-ally",on:{"click":function($event){_vm.changeGuideline(
                                  1,
                                  Math.floor(
                                    _vm.investmentAnalytics.initialInvestment.consultingCost[1]
                                      .quantity
                                  ) * _vm.getUnitPrice('税務コンサル', '有')
                                )}}},[_vm._v(" 反映 ")])])],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("コンサルタント料")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.consultingCost[1].amount
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.consultingCost[1], "amount", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.consultingCost[1].amount\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")((Math.floor(
                                      _vm.investmentAnalytics.initialInvestment.consultingCost[1]
                                        .quantity
                                    ) *
                                      _vm.getUnitPrice('税務コンサル', '有')))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1)],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v("法務コンサル")]),_c('b-col',{staticClass:"component",attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(Math.floor(
                            _vm.investmentAnalytics.initialInvestment.consultingCost[2].amount
                          ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"},[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabConsultantFees3",modifiers:{"tabConsultantFees3":true}}],staticClass:"btn-detail",attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabConsultantFees3","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"4"}},[_vm._v("内容")]),_c('b-col',{staticClass:"ally-select",attrs:{"cols":"6"}},[_c('allySelect',{attrs:{"name":"legalConsultancy","items":_vm.legalConsultancyItems},model:{value:(
                                _vm.investmentAnalytics.initialInvestment.consultingCost[2].content
                              ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.consultingCost[2], "content", $$v)},expression:"\n                                investmentAnalytics.initialInvestment.consultingCost[2].content\n                              "}})],1),_c('b-col',{attrs:{"cols":"1"}})],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("件数")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.consultingCost[2]
                                      .quantity
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.consultingCost[2]
                                      , "quantity", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.consultingCost[2]\n                                      .quantity\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("件")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("単価")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice(
                                      '法務コンサル',
                                      _vm.investmentAnalytics.initialInvestment.consultingCost[2]
                                        .content
                                    ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("円/件")])],1)],1),_c('b-col',{staticClass:"guideline"},[_vm._v("※目安 "),_c('button',{staticClass:"btn-ally",on:{"click":function($event){_vm.changeGuideline(
                                  2,
                                  Math.floor(
                                    _vm.investmentAnalytics.initialInvestment.consultingCost[2]
                                      .quantity
                                  ) *
                                    _vm.getUnitPrice(
                                      '法務コンサル',
                                      _vm.investmentAnalytics.initialInvestment.consultingCost[2]
                                        .content
                                    )
                                )}}},[_vm._v(" 反映 ")])])],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("コンサルタント料")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.consultingCost[2].amount
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.consultingCost[2], "amount", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.consultingCost[2].amount\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")((Math.floor(
                                      _vm.investmentAnalytics.initialInvestment.consultingCost[2]
                                        .quantity
                                    ) *
                                      _vm.getUnitPrice(
                                        '法務コンサル',
                                        _vm.investmentAnalytics.initialInvestment.consultingCost[2]
                                          .content
                                      )))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1)],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v("不動産コンサル")]),_c('b-col',{staticClass:"component",attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(Math.floor(
                            _vm.investmentAnalytics.initialInvestment.consultingCost[3].amount
                          ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"},[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabConsultantFees4",modifiers:{"tabConsultantFees4":true}}],staticClass:"btn-detail",attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabConsultantFees4","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("内容")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"ally-select"},[_c('allySelect',{attrs:{"name":"realEstateConsultancy","items":_vm.realEstateConsultancyItems},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.consultingCost[3]
                                      .content
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.consultingCost[3]
                                      , "content", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.consultingCost[3]\n                                      .content\n                                  "}})],1)],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("件数")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.consultingCost[3]
                                      .quantity
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.consultingCost[3]
                                      , "quantity", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.consultingCost[3]\n                                      .quantity\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("件")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"4"}},[_vm._v("単価")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice(
                                      '不動産コンサル',
                                      _vm.investmentAnalytics.initialInvestment.consultingCost[3]
                                        .content
                                    ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("円/件")])],1)],1),_c('b-col',{attrs:{"cols":"3"}})],1),_c('b-row',[_c('b-col'),_c('b-col'),_c('b-col',{staticClass:"guideline"},[_vm._v("※目安 "),_c('button',{staticClass:"btn-ally",on:{"click":function($event){_vm.changeGuideline(
                                  3,
                                  Math.floor(
                                    _vm.investmentAnalytics.initialInvestment.consultingCost[3]
                                      .quantity
                                  ) *
                                    _vm.getUnitPrice(
                                      '不動産コンサル',
                                      _vm.investmentAnalytics.initialInvestment.consultingCost[3]
                                        .content
                                    )
                                )}}},[_vm._v(" 反映 ")])])],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("コンサルタント料")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.consultingCost[3].amount
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.consultingCost[3], "amount", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.consultingCost[3].amount\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")((Math.floor(
                                      _vm.investmentAnalytics.initialInvestment.consultingCost[3]
                                        .quantity
                                    ) *
                                      _vm.getUnitPrice(
                                        '不動産コンサル',
                                        _vm.investmentAnalytics.initialInvestment.consultingCost[3]
                                          .content
                                      )))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1)],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v("その他コンサル")]),_c('b-col',{staticClass:"component",attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.investmentAnalytics.initialInvestment.otherConsultingCost)}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"},[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabConsultantFees5",modifiers:{"tabConsultantFees5":true}}],staticClass:"btn-detail",attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabConsultantFees5","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("権利者調整")])],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("人数")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"ally-select"},[_c('allySelect',{attrs:{"name":"realEstateConsultancy","items":_vm.adjustmentOfRightsHoldersItems},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.otherCostDetails[0]
                                      .content
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherCostDetails[0]
                                      , "content", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.otherCostDetails[0]\n                                      .content\n                                  "}})],1)],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"guideline"},[_vm._v(" ※目安 "),_c('button',{staticClass:"btn-ally",on:{"click":function($event){_vm.changeGuidelinOtherCostDetails(
                                      0,
                                      _vm.getUnitPrice(
                                        '権利者調整',
                                        _vm.investmentAnalytics.initialInvestment.otherCostDetails[0]
                                          .content
                                      )
                                    )}}},[_vm._v(" 反映 ")])]),_c('b-col',{attrs:{"cols":"2"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("金額")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.otherCostDetails[0]
                                      .amount
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherCostDetails[0]
                                      , "amount", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.otherCostDetails[0]\n                                      .amount\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice(
                                      '権利者調整',
                                      _vm.investmentAnalytics.initialInvestment.otherCostDetails[0]
                                        .content
                                    ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1)],1),_c('b-row',[_c('b-col'),_c('b-col'),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"guideline"},[_vm._v(" ※目安 "),_c('button',{staticClass:"btn-ally",on:{"click":function($event){_vm.changeGuidelinOtherCostDetails(
                                      1,
                                      _vm.getUnitPrice('その他コンサル', '民事信託コンサル')
                                    )}}},[_vm._v(" 反映 ")])]),_c('b-col',{attrs:{"cols":"2"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("民事信託コンサル")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.otherCostDetails[1]
                                      .amount
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherCostDetails[1]
                                      , "amount", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.otherCostDetails[1]\n                                      .amount\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice('その他コンサル', '民事信託コンサル'))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1)],1),_c('b-row',[_c('b-col'),_c('b-col'),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"guideline"},[_vm._v(" ※目安 "),_c('button',{staticClass:"btn-ally",on:{"click":function($event){_vm.changeGuidelinOtherCostDetails(
                                      2,
                                      _vm.getUnitPrice('その他コンサル', '任意後見コンサル')
                                    )}}},[_vm._v(" 反映 ")])]),_c('b-col',{attrs:{"cols":"2"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("任意後見コンサル")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.otherCostDetails[2]
                                      .amount
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherCostDetails[2]
                                      , "amount", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.otherCostDetails[2]\n                                      .amount\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice('その他コンサル', '任意後見コンサル'))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1)],1),_c('b-row',[_c('b-col'),_c('b-col'),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"guideline"},[_vm._v(" ※目安 "),_c('button',{staticClass:"btn-ally",on:{"click":function($event){_vm.changeGuidelinOtherCostDetails(
                                      3,
                                      _vm.getUnitPrice('その他コンサル', 'その他')
                                    )}}},[_vm._v(" 反映 ")])]),_c('b-col',{attrs:{"cols":"2"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("その他")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.otherCostDetails[3]
                                      .amount
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherCostDetails[3]
                                      , "amount", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.otherCostDetails[3]\n                                      .amount\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice('その他コンサル', 'その他'))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"borderline",attrs:{"cols":"12"}})],1),_c('b-row',[_c('b-col',{staticClass:"title bold text-right",attrs:{"cols":"4"}},[_vm._v("合計")]),_c('b-col',{staticClass:"component",attrs:{"cols":"4"}},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.detailsTotal())}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }