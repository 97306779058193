<template>
  <div id="app" :style="[colortheme, userStyle]">
    <router-view v-if="$route.meta.plainLayout" />
    <div class="container-fluid page-body-wrapper" v-if="!$route.meta.plainLayout">
      <div><SideBar @changeColortheme="changeColor"  @changeFlag="changeFlag" ref="sideBar"/></div>
      <div class="main-panel">
        <div class="content-wrapper">
          <router-view :key="$route.fullPath"></router-view>
        </div>
        <footer class="footer" style="background-color: white; background-color: #f7f7f7">
          <div class="d-sm-flex justify-content-sm-between float-left">
            <span
              class="text-muted text-center text-sm-left d-block d-sm-inline-block text-padding"
            >
              Copyright © {{ year }}
              <a href="https://dynastage.co.jp/" target="_blank">ダイナステージ株式会社</a>. All
              rights reserved.
            </span>
          </div>
          <div class="float-right sideBar-img"></div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
  import SideBar from './components/Common/SideBar';
  import $ from 'jquery';

  export default {
    data: function() {
      return {
        //デフォルトの色を設定
        colortheme: null,
      };
    },
    created() {
      var link = document.location.href;
      if (link.endsWith('/DashBoard') || link.endsWith('/Login') || link.endsWith('/List')) {
        window.onbeforeunload = function() {
          return;
        };
      }
    },
    watch: {
      $route(to, from) {
        if (to.name === 'DashBoard') {
          this.changeFlag();
        }
      }
    },
    methods: {
      //変更色をセットアップする
      changeColor: function(color) {
        if (color == null) {
          return;
        }
        this.colortheme = color;
        this.setBodyColor(color);
      },
      setBodyColor: function(color) {
        //ログインもしくは、リフレッシュボタンを押した場合、ボディーの色を設定
        $('body')
          .get(0)
          .style.setProperty('--colorTheme', color);

        //RGB値を保存
        var result = this.hexToRgb(color);
        var rgbColor = result[0].toString() + ',' + result[1] + ',' + result[2];

        $('body')
          .get(0)
          .style.setProperty('--colorThemeRGB', rgbColor);
      },
      hexToRgb: function(hex) {
        //hexをRGBに変換
        var hexData = hex.substr(1, 6);
        let int = parseInt(hexData, 16);
        let r = (int >> 16) & 255;
        let g = (int >> 8) & 255;
        let b = int & 255;
        return [r, g, b];
      },
      changeFlag: function() {
        if (this.$refs.sideBar?.loadFlag) {this.$refs.sideBar.loadFlag = false;}
      },
    },
    computed: {
      //色の変更を監視、反映する
      userStyle() {
        return {
          '--colorTheme': this.colortheme,
        };
      },
      year() {
        let date = new Date();
        return date.getFullYear();
      },
    },
    components: {
      SideBar,
    },
  };
</script>
