<!--
作成者：本間
作成日：2020.12.14
画面名：投資分析項目出力コンポーネント
機能：投資分析結果を出力
親子関係：なし
更新者：
更新内容：
-->
<template>
  <b-card id="InvestmentAnalysisResults" no-body>
    <b-tabs pills fill>
      <InvestmentPlan ref="InvestmentPlan" />
      <FinancingPlan ref="FinancingPlan" />
      <SingleYearBalance ref="SingleYearBalance" />
      <GainOnSale ref="GainOnSale" />
      <InvestmentAnalytics ref="InvestmentAnalytics" :resultMode="true" />
      <Yield ref="Yield" />
      <IncomeAndExpenditureProjectionsCash ref="IncomeAndExpenditureProjectionsCash" />
      <IncomeAndExpenditureProjectionsForTaxPurposes
        ref="IncomeAndExpenditureProjectionsForTaxPurposes"
      />
      <LongTermBalanceGraph ref="LongTermBalanceGraph" :resultMode="true" />
    </b-tabs>
  </b-card>
</template>
<script>
  import InvestmentPlan from './InvestmentPlan';
  import FinancingPlan from './FinancingPlan';
  import SingleYearBalance from './SingleYearBalance';
  import GainOnSale from './GainOnSale';
  import InvestmentAnalytics from './InvestmentAnalytics';
  import Yield from './Yield';
  import IncomeAndExpenditureProjectionsCash from './IncomeAndExpenditureProjectionsCash';
  import IncomeAndExpenditureProjectionsForTaxPurposes from './IncomeAndExpenditureProjectionsForTaxPurposes';
  import LongTermBalanceGraph from './LongTermBalanceGraph';
  export default {
    components: {
      InvestmentPlan,
      FinancingPlan,
      SingleYearBalance,
      GainOnSale,
      Yield,
      IncomeAndExpenditureProjectionsCash,
      IncomeAndExpenditureProjectionsForTaxPurposes,
      LongTermBalanceGraph,
      InvestmentAnalytics,
    },
    data: function() {
      return {};
    },
  };
</script>
<style>
  #InvestmentAnalysisResults {
    padding: 0.5em;
    border-top-color: gainsboro !important;
  }
  #InvestmentAnalysisResults .card {
    border-top-color: gainsboro;
  }
  #InvestmentAnalysisResults .card-result .card-title {
    color: black;
    font-weight: bold;
    font-size: x-large;
    text-align: center;
    text-shadow: 1px 1px 1px gray;
  }
  #InvestmentAnalysisResults .nav-pills .nav-link.active {
    background-color: var(--colorTheme);
    margin-left: 1px;
    margin-right: 1px;
    font-size: large;
    font-weight: bold;
    text-shadow: 1px 1px 1px #000000, -1px 1px 1px #000000, 1px -1px 1px #000000,
      -1px -1px 1px #000000, 1px 0px 1px #000000, 0px 1px 1px #000000, -1px 0px 1px #000000,
      0px -1px 1px #000000;
  }
  #InvestmentAnalysisResults .nav-tabs .nav-link.active {
    color: black;
    font-weight: bold;
    text-align: center;
    text-shadow: 1px 1px 1px gray;
  }
  #InvestmentAnalysisResults .nav-pills .nav-link {
    background-color: gainsboro;
    margin-left: 1px;
    margin-right: 1px;
    font-size: large;
  }
  #InvestmentAnalysisResults .report .colspan {
    border-left-color: transparent !important;
  }
  #InvestmentAnalysisResults .report .rowspan {
    border-top-color: transparent !important;
  }
  #InvestmentAnalysisResults .report > .row {
    padding: 0;
  }
  #InvestmentAnalysisResults .text-right {
    text-align: right !important;
  }
  #InvestmentAnalysisResults .text-left {
    text-align: left !important;
  }
  #InvestmentAnalysisResults .subtotal > div {
    background-color: rgba(var(--colorThemeRGB), 0.5);
    text-align: right !important;
  }
  #InvestmentAnalysisResults .subtotal > div:first-child {
    border-right: 0 !important;
  }
  #InvestmentAnalysisResults .report > .row:nth-child(n + 2) div,
  #InvestmentAnalysisResults .report span .row div {
    border-top: 1px solid black;
    border-left: 1px solid black;
  }
  #InvestmentAnalysisResults .report > .row:nth-child(n + 2) div:last-child,
  #InvestmentAnalysisResults .report span .row div:last-child {
    border-right: 1px solid black;
  }
  #InvestmentAnalysisResults .report > .row:last-child > div {
    border-bottom: 1px solid black;
  }
  #InvestmentAnalysisResults .title {
    color: white;
    background-color: rgba(var(--colorThemeRGB), 1);
    display: grid;
    text-align: center;
    align-items: center;
  }
  #InvestmentAnalysisResults .subtitle {
    color: black;
    background-color: rgba(var(--colorThemeRGB), 0.5);

    display: grid;
    text-align: left;
    align-items: center;
  }
  #InvestmentAnalysisResults .unit {
    display: grid;
    text-align: center;
    align-items: center;
    padding: 0;
  }
  #InvestmentAnalysisResults .value {
    display: grid;
    text-align: right;
    align-items: center;
  }
  #InvestmentAnalysisResults .card-columns {
    column-count: 2;
  }
  #InvestmentAnalysisResults div:not(id='Yield') .card-columns > .card {
    border: 0;
    box-shadow: none;
  }
  /* http://var.blog.jp/archives/74242827.html */
  * {
    text-decoration-skip-ink: none !important;
  }
  #InvestmentAnalysisResults .emboss {
    text-shadow: 1px 1px 1px #000000, -1px 1px 1px #000000, 1px -1px 1px #000000,
      -1px -1px 1px #000000, 1px 0px 1px #000000, 0px 1px 1px #000000, -1px 0px 1px #000000,
      0px -1px 1px #000000;
  }
  #InvestmentAnalysisResults .nav-item {
    margin-bottom: 2px;
  }
</style>
