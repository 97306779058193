// @ts-check

import { EventDataset } from './eventDataset';

/**
 * モード毎のハンドラー群
 * @typedef {(dataset: EventDataset) => void} PvEventHandler
 */

export class EventPreset {
  /** @type {PvEventHandler} */
  click = () => {};
  /** @type {PvEventHandler} */
  drag = () => {};
  /** @type {PvEventHandler} */
  dragstart = () => {};
  /** @type {PvEventHandler} */
  dragend = () => {};
  /** @type {PvEventHandler} */
  mousedown = () => {};
  /** @type {PvEventHandler} */
  mouseup = () => {};
  /** @type {PvEventHandler} */
  mousemove = () => {};
  /** @type {PvEventHandler} */
  mouseleave = () => {};
  /** @type {PvEventHandler} */
  touchstart = () => {};
  /** @type {PvEventHandler} */
  touchmove = () => {};
  /** @type {PvEventHandler} */
  rightclick = () => {};
}
