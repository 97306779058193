<template>
  <div>
    <div>{{ title }}</div>
    <div v-show="mode === 'common'">
      <div class="row grid-margin" style="margin-left : -30px">
        <div class="row mx-2">
          <div class="col-12">
            <router-link to="/DashBoard" tag="button" class="btn btn-ally mx-2 float-right">
              メインページへもどる
            </router-link>
            <button class="btn btn-ally mx-2 float-right" v-b-modal="'masterModal'">
              共通データ確認
            </button>
            <button
              id="input-menu-save"
              class="btn btn-ally mx-2 float-right"
              @click="saveInputModal"
            >
              入力項目を保存
            </button>
            <button class="btn btn-ally mx-2 float-right" @click="openLoadModal">
              入力データロード
            </button>
            <button
              class="btn btn-ally mx-2 float-right"
              v-b-modal="'defaultValueModal'"
              v-if="route === 'RealEstateSuitability' && group == 'admin'"
            >
              単価設定
            </button>
          </div>
        </div>
      </div>
      <div>
        <!-- 共通項目 -->
        <ValidationObserver ref="commonInputCheck">
          <CommonInput v-model="commonInput" :inputType="type" />
        </ValidationObserver>

        <!-- 相続不動産一覧 -->
        <b-card no-body class="mt-4">
          <b-card-header class="card-header">
            <b>相続不動産一覧</b>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col>
                <b-table hover :items="propertyList" :fields="fields" style="line-height:200px;">
                  <template #cell(index)="row">
                    {{ row.index + 1 }}
                  </template>
                  <template #cell(name)="row">
                    {{ row.item.name }}
                  </template>
                  <template #cell(isCalculated)="row">
                    <span v-if="row.item.isCalculated" style="color: green; font-size: 24px"
                      >◎</span
                    >
                    <span v-if="!row.item.isCalculated" style="color: red; font-size: 36px">×</span>
                  </template>
                  <template #cell(edit)="row">
                    <b-button
                      size="sm"
                      @click="editInput(row)"
                      class="ally btn-ally mr-1 action-btn"
                    >
                      編集
                    </b-button>
                  </template>
                  <template #cell(delete)="row">
                    <b-button
                      size="sm"
                      @click="deleteProperty(row)"
                      class="ally btn-ally mr-1 action-btn"
                    >
                      削除
                    </b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row v-if="propertyList.length < 20">
              <b-col>
                <div style="height: 30px; margin-top: 10px;">
                  <!--
                  <button
                    :disabled="propertyList.length === 20"
                    class="btn btn-ally"
                    style="width: 100%; height: 30px; padding: 0;"
                    @click="createInput"
                  >
                    <i class="ti-plus property-icon" style="font-color: white; height: 10px;"></i>
                  </button>
                  -->
                  <b-button
                    size="sm"
                    style="height: 35px;"
                    @click="createInput()"
                    class="btn btn-ally"
                  >
                    追加
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>
    </div>
    <div class="pt-4">
      <b-card v-if="mode === 'result'">
        <slot name="result" :propertyList="propertyList" :commonInput="commonInput"></slot>
      </b-card>
    </div>
    <div class="right">
      <button v-if="mode === 'common'" class="btn btn-ally" @click="openResult">
        結果表示
      </button>
      <button v-if="mode === 'result'" class="btn btn-ally" @click="() => (mode = 'common')">
        キャンセル
      </button>
    </div>

    <!-- 編集画面 -->
    <!-- 入力（カテゴリー別） -->
    <div v-if="mode === 'add' || mode === 'edit'">
      <ValidationObserver ref="inputCheck">
        <div class="right mb-2">
          <button
            class="btn btn-ally float-left"
            v-b-modal="'defaultValueModal'"
            v-if="route === 'RealEstateSuitability' && group == 'admin'"
          >
            単価設定
          </button>
          <button class="btn btn-ally mr-2" @click="() => (mode = 'common')">
            キャンセル</button
          ><button v-if="mode === 'edit'" class="btn btn-ally" @click="validateInput">適用</button
          ><button
            id="add-input-button-top"
            v-if="mode === 'add'"
            class="btn btn-ally"
            @click="validateInput"
          >
            追加
          </button>
        </div>
        <slot name="input" :input="input" :value="value" :calcFlg="calcFlg"></slot>
        <div class="right">
          <button class="btn btn-ally mr-2" @click="() => (mode = 'common')">
            キャンセル</button
          ><button v-if="mode === 'edit'" class="btn btn-ally" @click="validateInput">適用</button
          ><button
            id="add-input-button-bottom"
            v-if="mode === 'add'"
            class="btn btn-ally"
            @click="validateInput"
          >
            追加
          </button>
        </div>
      </ValidationObserver>
    </div>

    <!-- 結果 -->

    <!--下記に共通メッセージモーダルを作成-->
    <allyAlert :code="'E108'"></allyAlert>
    <allyAlert :code="'E109'"></allyAlert>
    <allyAlert code="E0001"></allyAlert>
    <b-modal
      id="saveModal"
      title="保存データ選択"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="保存"
      @ok.prevent="saveInputInDynamo"
      no-close-on-backdrop
    >
      <ValidationObserver tag="div" slim ref="saveInputCheck" class="row">
        <b-form-group style="margin-left:5px">
          <allyText title="タイトル" name="inputTitle" v-model="inputTitle" rules="required" />
        </b-form-group>
      </ValidationObserver>
    </b-modal>
    <b-modal
      size="lg"
      ref="loadModal"
      title="入力データ"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      ok-variant="light"
      ok-title="キャンセル"
      ok-only
      no-close-on-backdrop
    >
      <b-card>
        <b-table hover :items="items" :fields="inputFields">
          <template #cell(edit)="row">
            <b-button
              size="sm"
              @click="loadInputFromDynamo(row)"
              class="btn btn-ally mr-1 action-btn"
            >
              ロード
            </b-button>
          </template>
          <template #cell(delete)="row">
            <b-button
              size="sm"
              @click="deleteInputFromDynamo(row)"
              class="btn btn-ally mr-1 action-btn"
            >
              削除
            </b-button>
          </template>
        </b-table>
      </b-card>
    </b-modal>
    <b-modal
      id="masterModal"
      size="xl"
      title="共通データ確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-only
      ok-variant="light"
      ok-title="確認"
      no-close-on-backdrop
    >
      <Master :type="this.type" mode="View" />
    </b-modal>
    <b-modal
      ref="validationConfirm"
      size="md"
      title="確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-variant="light"
      ok-title="確認"
      @ok="addProperty"
      no-close-on-backdrop
    >
      入力に誤りがあります。このまま続行しますか？
    </b-modal>
    <b-modal
      ref="gotoResultErrorModal"
      size="md"
      title="エラー"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-only
      ok-variant="light"
      ok-title="OK"
      no-close-on-backdrop
    >
      入力項目に不備があります。<br />
      すべての物件の入力を完了してください。
    </b-modal>
    <b-modal
      ref="familyErrorModal"
      size="md"
      title="エラー"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-only
      ok-variant="light"
      ok-title="OK"
      no-close-on-backdrop
    >
      二次相続計算に必要な家族情報の入力が足りません。
    </b-modal>
    <b-modal
      ref="notExistMasterModal"
      id="masterErrorModal"
      size="md"
      title="注意"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-only
      ok-variant="light"
      ok-title="OK"
      @ok="() => this.$refs.specifiedModal.show()"
      no-close-on-backdrop
    >
      規定値が設定されていません。<br />
      すべての規定値を入力してください。
    </b-modal>
    <b-modal
      ref="validationDefaultValueConfirm"
      id="validationMasterModal"
      size="md"
      title="注意"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      ok-variant="light"
      ok-title="OK"
      @ok="setMaster"
      no-close-on-backdrop
    >
      入力されていない単価があります。<br />
      このまま続行しますか？
    </b-modal>
    <b-modal
      ref="specifiedModal"
      id="defaultValueModal"
      size="xl"
      title="単価設定"
      header-class="ally-modal"
      hide-footer
      no-close-on-backdrop
    >
      <ValidationObserver slim ref="defaultValue">
        <InputDefaultValue
          ref="inputDefaultValue"
          :modeRent="'1'"
          :master="defaultMaster"
        ></InputDefaultValue>
      </ValidationObserver>
      <button class="btn btn-ally my-3 float-right" @click="validateDefaultValue">保存</button>
    </b-modal>
    <b-modal
      ref="saveErrorModal"
      size="md"
      title="保存エラー"
      header-class="ally-modal"
      hide-footer
      no-close-on-backdrop
    >
      <div v-html="saveErrorMessage" class="save-error-message"></div>
    </b-modal>
    <allyAlert code="E0001"></allyAlert>
    <allyAlert code="S100"></allyAlert>
  </div>
</template>
<script>
  import awsconfig from '@/aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { createAnalyticsInput, deleteAnalyticsInput } from '@/graphql/mutations';
  import CommonInput from '@/components/ProperAnalytics/CommonInput';
  import Master from '@/views/Master/View';
  import { listAnalyticsInputs } from '../../graphql/queries';
  import CommonInputClass from './class';
  import { createCompanyMaster, updateCompanyMaster } from '../../graphql/mutations';
  import { mapState } from 'vuex';
  import InputDefaultValue from '../../views/RealEstateSuitability/InputDefaultValue';

  API.configure(awsconfig);

  class Property {
    constructor(input) {
      if (input == null) {
        input = {};
      }
      this.id = input.id;
      this.name = input.name;
      this.isCalculated = input.isCalculated;
    }
  }

  export default {
    props: {
      inputClass: {
        type: Function,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
    },
    components: {
      CommonInput,
      Master,
      InputDefaultValue,
    },
    data: function() {
      return {
        id: 0,
        table: null,
        items: [],
        input: {},
        value: {},
        propertyList: [],
        inputTitle: '',
        commonInput: new CommonInputClass(),
        mode: 'common', // common | edit | add | result
        defaultMaster: this.$store.state.master,
        calcFlg: false,
        editIndex: null,
        route: null,
        isExistMaster: true,
        group: this.getAuthority(),
        headers: [
          {
            key: 'download',
            label: 'ロード',
            button: { label: 'ロード', event: 'loadInputFromDynamo' },
          },
        ],
        fields: [
          { key: 'index', label: 'No.' },
          { key: 'name', label: '物件名' },
          {
            key: 'isCalculated',
            label: '入力チェック',
          },
          { key: 'edit', label: '' },
          { key: 'delete', label: '' },
        ],
        inputFields: [
          {
            key: 'commonInput.reportDate',
            label: '作成日',
          },
          { key: 'inputTitle', label: 'タイトル' },
          { key: 'commonInput.reportTitle', label: 'レポート名' },
          { key: 'commonInput.customerName', label: '顧客名' },
          { key: 'edit', label: '' },
          { key: 'delete', label: '' },
        ],
        saveErrorMessage: '',
      };
    },
    mounted: async function() {
      //不動産適正のページかどうか判定
      if (this.$router.currentRoute.path.indexOf('RealEstateSuitability') != -1) {
        this.route = 'RealEstateSuitability';
        //単価設定の可否
        this.isExistMaster = this.master.defaultValue.isFilledMaster;
        if (this.isExistMaster == null || this.isExistMaster == 'false') {
          //単価設定に入力漏れ、もしくは単価がなかった場合、単価入力モーダルを開く
          this.$refs.notExistMasterModal.show();
        }
      }
    },
    computed: {
      ...mapState(['master']),
    },
    methods: {
      async createInput() {
        //単価設定の可否
        if (this.isExistMaster == null || this.isExistMaster == 'false') {
          this.$refs.notExistMasterModal.show();
          return;
        }
        this.input = new this.inputClass();
        this.mode = 'add';
      },
      async editInput(e) {
        //単価設定の可否
        if (this.isExistMaster == null || this.isExistMaster == 'false') {
          this.$refs.notExistMasterModal.show();
          return;
        }
        const result = await this.$db.propertyListInputs.get(this.propertyList[e.index].id);
        this.input = new this.inputClass(result.data);
        this.editIndex = e.index;
        this.mode = 'edit';
      },
      async validateInput() {
        const isValid = await this.$refs.inputCheck.validate();
        if (!isValid) {
          this.$refs.validationConfirm.show();
          return;
        }

        this.addProperty(null, true);
      },
      async addProperty(_, isValid) {
        if (isValid === true) {
          // 表示を変える前に計算させる
          this.calcFlg = true;
          await this.$nextTick();
          this.calcFlg = false;
        }
        this.input.isCalculated = isValid ? true : false;

        const beforeMode = this.mode;
        this.mode = 'common';
        let id = null;

        if (this.route === 'RealEstateSuitability') {
          // 不動産活用診断 入力値の補正
          // 相続条件画面
          this.input.siteConditions.ownerInformation.estimatedEstateValue = Math.floor(
            Number(this.input.siteConditions.ownerInformation.estimatedEstateValue)
          );
          this.input.siteConditions.ownerInformation.numberOfChildren = Math.floor(
            Number(this.input.siteConditions.ownerInformation.numberOfChildren)
          );
          this.input.siteConditions.ownerInformation.numberOfParentsAlive = Math.floor(
            Number(this.input.siteConditions.ownerInformation.numberOfParentsAlive)
          );
          this.input.siteConditions.ownerInformation.siblingsOfTheDeceased = Math.floor(
            Number(this.input.siteConditions.ownerInformation.siblingsOfTheDeceased)
          );
          let spouse = this.input.siteConditions.ownerInformation.spouse;
          if (spouse == 0 || spouse == null) {
            this.input.siteConditions.ownerInformation.spouseRealRatio = null;
          } else {
            this.input.siteConditions.ownerInformation.spouseRealRatio =
              Math.floor(this.input.siteConditions.ownerInformation.spouseRealRatio * 100) / 100;
          }
          this.input.siteConditions.ownerInformation.childRealRatio = this.moldRealRatio(
            'child',
            this.input.siteConditions.ownerInformation.numberOfChildren
          );
          this.input.siteConditions.ownerInformation.parentRealRatio = this.moldRealRatio(
            'parent',
            this.input.siteConditions.ownerInformation.numberOfParentsAlive
          );
          this.input.siteConditions.ownerInformation.siblingRealRatio = this.moldRealRatio(
            'sibling',
            this.input.siteConditions.ownerInformation.siblingsOfTheDeceased
          );

          // 敷地条件画面
          this.input.siteConditions.siteInformation.surfaceArea =
            Math.floor(Number(this.input.siteConditions.siteInformation.surfaceArea) * 100) / 100;
          this.input.siteConditions.siteInformation.roadsideLandPrice = Math.floor(
            Number(this.input.siteConditions.siteInformation.roadsideLandPrice)
          );
          if (this.input.siteConditions.siteInformation.buildingToLandRatio) {
            this.input.siteConditions.siteInformation.buildingToLandRatio =
              Math.floor(
                Number(this.input.siteConditions.siteInformation.buildingToLandRatio) * 10
              ) / 10;
          }
          if (this.input.siteConditions.siteInformation.floorAreaRatio) {
            this.input.siteConditions.siteInformation.floorAreaRatio =
              Math.floor(Number(this.input.siteConditions.siteInformation.floorAreaRatio) * 10) /
              10;
          }
          this.input.siteConditions.siteInformation.percentageOfLeaseholdInterest =
            Math.floor(
              Number(this.input.siteConditions.siteInformation.percentageOfLeaseholdInterest) * 10
            ) / 10;
          this.input.siteConditions.siteInformation.propertyTaxAssessment = Math.floor(
            Number(this.input.siteConditions.siteInformation.propertyTaxAssessment)
          );
          this.input.siteConditions.siteInformation.availableForSale = Math.floor(
            Number(this.input.siteConditions.siteInformation.availableForSale)
          );
          if (this.input.siteConditions.siteInformation.currentIncome) {
            this.input.siteConditions.siteInformation.currentIncome = Math.floor(
              Number(this.input.siteConditions.siteInformation.currentIncome)
            );
          }

          // 全体計画
          this.input.overallPlan.storeOffice.totalFlooraSpace =
            Math.floor(Number(this.input.overallPlan.storeOffice.totalFlooraSpace) * 100) / 100;
          this.input.overallPlan.sale.periodOfOwnership = Math.floor(
            Number(this.input.overallPlan.sale.periodOfOwnership)
          );
        }

        if (beforeMode === 'add') {
          id = await this.$db.propertyListInputs.add({ data: this.input });
          const property = new Property({
            id: id,
            name: this.input.getName(),
            isCalculated: isValid ? true : false,
          });
          this.propertyList.push(property);
        } else if (beforeMode === 'edit') {
          id = this.propertyList[this.editIndex].id;
          await this.$db.propertyListInputs.update(id, {
            id: id,
            data: this.input,
          });
          const property = new Property({
            id: id,
            name: this.input.getName(),
            isCalculated: isValid ? true : false,
          });
          this.$set(this.propertyList, `${this.editIndex}`, property);
        } else {
          this.showError('E0001');
          return;
        }
      },
      validateDefaultValue: async function() {
        let isValid = await this.$refs.defaultValue.validate();
        if (!isValid) {
          this.$refs.validationDefaultValueConfirm.show();
          return;
        }

        await this.setMaster();
      },
      setMaster: async function() {
        //単価がすべて入力されているかどうかチェック
        let isFilledMaster = await this.$refs.defaultValue.validate();
        //入力した項目をstoreにコミット
        await this.$refs.inputDefaultValue.commitMaster(isFilledMaster);
        //必要な情報を格納
        let data = {
          companyId: this.getGroup(),
          classification: 'realEstateSuitability',
          classificationDetail: '10',
          masterName: '不動産適正規定値',
          year: new Date().getFullYear().toString(),
          value: [],
        };
        let value = [];
        //コミット済みのmasterのdefaultValueのキーでループ処理
        Object.keys(this.master.defaultValue).forEach(key => {
          //キーが 'isFilledMaster' の時、配列の先頭に格納
          if (key === 'isFilledMaster') {
            value.unshift(['isFilledMaster', isFilledMaster.toString()]);
          } else if (key === 'rentalProperty') {
            Object.keys(this.master.defaultValue[key]).forEach(sub => {
              //rentalPropertyの配列でループし、それぞれの配列内のキーでまたループ
              Object.keys(this.master.defaultValue[key][sub]).forEach(detail => {
                //対象のデータがnullじゃなければ文字列に変換して格納。それ以外は''で格納
                if (this.$store.state.master.defaultValue[key][sub][detail] != null) {
                  value.push([
                    key + '-' + sub + '-' + detail,
                    this.$store.state.master.defaultValue[key][sub][detail].toString(),
                  ]);
                } else {
                  value.push([key + '-' + sub + '-' + detail, '']);
                }
              });
            });
          } else {
            //上記2つ以外の場合、キーでまたループ
            Object.keys(this.master.defaultValue[key]).forEach(sub => {
              if (this.$store.state.master.defaultValue[key][sub] != null) {
                value.push([
                  key + '-' + sub,
                  this.$store.state.master.defaultValue[key][sub].toString(),
                ]);
              } else {
                value.push([key + '-' + sub, '']);
              }
            });
          }
        });
        data.value = value;
        //↓↓’add'(不動産追加)、’edit'(不動産編集)中に単価を変更したいので'common'(初期状態)に戻す必要がない
        // this.mode = 'common';
        //↑↑
        //単価入力の可否を設定。すべて入力されていればtrue、それ以外はfalse
        this.isExistMaster = isFilledMaster.toString();
        //会社マスタがあった場合、update
        try {
          await API.graphql(graphqlOperation(updateCompanyMaster, { input: data }));
          this.$bvModal.show('S100');
        } catch (e) {
          //会社マスタがなかった場合、create
          try {
            await API.graphql(graphqlOperation(createCompanyMaster, { input: data }));
            this.$bvModal.show('S100');
          } catch (e) {
            console.log(e);
          }
        }
        this.defaultMaster = this.$store.state.master;
        this.$refs.specifiedModal.hide();
      },
      deleteProperty: function(e) {
        this.propertyList.splice(e.index, 1);
      },
      //ユーザ入力項目の保存処理
      async saveInputInDynamo() {
        const isValid = await this.$refs.saveInputCheck.validate();

        if (!isValid) {
          return;
        }

        const resultPromiseList = this.propertyList.map(async val => {
          const resultDB = await this.$db.propertyListInputs.get(val.id);
          return resultDB.data;
        });

        const resultList = await Promise.all(resultPromiseList);

        const input = {
          inputTitle: this.inputTitle,
          commonInput: this.commonInput,
          inputType: this.type,
          author: this.$store.state.user.attributes.sub,
          [`${this.type}Input`]: resultList,
        };

        try {
          await API.graphql(
            graphqlOperation(createAnalyticsInput, {
              input,
            })
          );
        } catch (error) {
          console.error(error);
          this.showError('E0001', error);
          return;
        }

        this.$bvModal.hide('saveModal');
      },
      async loadInputFromDynamo(input) {
        this.commonInput = input.item.commonInput;
        this.propertyList = [];
        for (const ip of input.item[`${this.type}Input`]) {
          const data = new this.inputClass(ip);
          const result = await this.$db.propertyListInputs.add({ data: data });
          const property = new Property({
            id: result,
            name: data.getName(),
            isCalculated: ip.isCalculated,
          });
          this.propertyList.push(property);
        }
        this.$refs.loadModal.hide();
      },
      async deleteInputFromDynamo(e) {
        try {
          await API.graphql(
            graphqlOperation(deleteAnalyticsInput, {
              input: {
                id: e.item.id,
              },
            })
          );
        } catch (error) {
          this.showError('E1000', error);
          return;
        }

        // 表示リフレッシュ
        this.listItems();
      },
      async saveInputModal() {
        const commonInputValidation = await this.$refs.commonInputCheck.validate();
        const propertyLength = this.propertyList.length <= 0;
        if (propertyLength || !commonInputValidation) {
          this.saveErrorMessage = '※以下をご確認ください。<br><br>';
          this.saveErrorMessage += propertyLength
            ? '・物件情報を少なくとも1つ入力してください。<br>'
            : '';
          this.saveErrorMessage += !commonInputValidation
            ? '・共通情報の必須項目を入力してください。'
            : '';
          this.$refs.saveErrorModal.show();
          return;
        }
        this.$bvModal.show('saveModal');
      },
      // アイテム取得
      getItems: async function(nextToken) {
        const queryVar = {
          limit: 50,
        };

        queryVar.nextToken = nextToken;

        try {
          const queryResult = await API.graphql(graphqlOperation(listAnalyticsInputs, queryVar));
          const result = queryResult.data['listAnalyticsInputs'];

          if (result == null) {
            return;
          }
          this.items = [...this.items, ...result.items].filter(val => {
            return val.inputType === this.type;
          });
          return result.nextToken;
        } catch (error) {
          console.error(error);
          this.showError('E0001', error);
          return;
        }
      },
      // 初期化処理
      listItems: async function() {
        this.items = [];
        this.currentPage = 0;
        let nextToken = null;

        // 初回の読み込みが終わった時点で
        nextToken = await this.getItems(nextToken);
        this.loading = false;

        // すべてのデータを読み込むまでループ
        while (nextToken != null) {
          nextToken = await this.getItems(nextToken);
        }
      },
      async openLoadModal() {
        await this.listItems();
        this.$refs.loadModal.show('loadModal');
      },
      // 結果表示
      async openResult() {
        // 共通項目バリデーション
        const commonInputIsValid = await this.$refs.commonInputCheck.validate();

        // すべての物件の入力が済んでいるかチェック
        let propertyListIsValid = true;
        for (const property of this.propertyList) {
          if (property.isCalculated === false) {
            propertyListIsValid = false;
            break;
          }
        }

        //src\views\InheritanceTax\Report.vueでチェックしているが、他でも使っているので残しておく
        if (this.commonInput.continuityInheritanceFlg == 1) {
          //相次相続控除の入力チェック
          if (this.commonInput.secondaryInheritancePattern == 1) {
            //家族情報に配偶者と子がいなければエラー
            let child = this.commonInput.family.filter(value => {
              return value.familyType === '子' || value.familyType === '養子';
            });
            let spouse = this.commonInput.family.filter(value => {
              return value.familyType === '妻' || value.familyType === '夫';
            });
            if (child.length == 0 || spouse.length != 1) {
              this.$refs.familyErrorModal.show();
              return;
            }
          } else if (this.commonInput.secondaryInheritancePattern == 2) {
            //家族情報に子と孫がいなければエラー
            let child = this.commonInput.family.filter(value => {
              return value.familyType === '子' || value.familyType === '養子';
            });
            let grandchild = this.commonInput.family.filter(value => value.familyType === '孫');
            if (child.length == 0 || grandchild == 0) {
              this.$refs.familyErrorModal.show();
              return;
            }
          } else if (this.commonInput.secondaryInheritancePattern == 3) {
            //家族情報に子が複数いなければエラー
            let child = this.commonInput.family.filter(value => {
              return value.familyType === '子' || value.familyType === '養子';
            });
            // let others = this.commonInput.family.filter(value => value.familyType != '子');
            if (child.length <= 1) {
              this.$refs.familyErrorModal.show();
              return;
            }
          } else if (this.commonInput.secondaryInheritancePattern == 4) {
            //家族情報に兄弟と甥姪がいなければエラー
            let brothers = this.commonInput.family.filter(value => {
              return (
                value.familyType == '兄' ||
                value.familyType == '弟' ||
                value.familyType == '姉' ||
                value.familyType == '妹'
              );
            });
            let brothersChild = this.commonInput.family.filter(
              value => value.familyType === '兄弟姉妹の子'
            );
            if (brothers.length == 0 || brothersChild == 0) {
              this.$refs.familyErrorModal.show();
              return;
            }
          }
        }

        if (
          this.propertyList.length === 0 ||
          commonInputIsValid === false ||
          propertyListIsValid === false
        ) {
          this.$refs.gotoResultErrorModal.show();
          return;
        }
        this.mode = 'result';
      },
      getGroup: function() {
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];

        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },
      getAuthority() {
        var userGroup = new Array();
        userGroup = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        if (userGroup.indexOf('systemAdmin') != -1) {
          return 'systemAdmin';
        } else if (userGroup.indexOf('admin') != -1) {
          return 'admin';
        }
      },
      moldRealRatio: function(textHeiress, heiress) {
        if (
          textHeiress === 'spouse' ||
          !this.input.siteConditions.ownerInformation[textHeiress + 'RealRatio']
        ) {
          return null;
        }
        if (heiress == null) {
          heiress = 0;
        }
        let array = this.input.siteConditions.ownerInformation[textHeiress + 'RealRatio'].filter(
          (value, index) => {
            return index < heiress;
          }
        );
        // 小痛点以下３位で切り捨てる補正
        for (let i = 0; i < array.length; i++) {
          array[i] = Math.floor(array[i] * 100) / 100;
        }
        return array;
      },
    },
    watch: {
      mode() {
        // 画面が変わる度上にスクロール
        scrollTo(0, 0);
      },
    },
  };
</script>
<style scoped>
  .action-btn {
    height: 100%;
  }
  .card-header {
    transition: 0.2s;
    background: rgba(var(--colorThemeRGB));
    color: white;
  }
  .right {
    text-align: right;
    margin-top: 20px;
  }
  .save-error-message {
    text-align: center;
  }
</style>
