<!--
作成者：Suzuki Sota
作成日：
画面名：平面図作成初期画面
機能：平面図一覧確認
親子関係：なし
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <div class="row grid-margin col-12">
      <div class="row my-3">
        <div class="col-12">
          <b-button
            v-if="completeFirstLoadFlg"
            :disabled="!isEnableCreateNew"
            class="btn btn-ally float-right mr-2"
            @click="goPlaneViewEditor()"
          >
            新規作成
          </b-button>
          <b-button class="btn btn-ally float-right mr-2" @click="historyClick()">
            履歴管理
          </b-button>
        </div>
      </div>
      <div class="col-12" v-if="!isEnableCreateNew">
        <span class="float-right text-red"
          >登録数が上限に達しました。(最大10件まで登録できます)</span
        >
      </div>
    </div>
    <PlanePagination
      class="list"
      ref="pagination"
      hideDetail
      :headers="headers"
      targetName="PlaneView"
      :filterFix="planeFilterFix"
      :companyId="companyId"
      :maxCount="10"
      hideDelete
      @checkboxClicked="e => checkPlane(e)"
      @editClicked="e => editPlane(e)"
      @itemsChanged="e => itemsChanged(e)"
      @completeFirstLoad="() => (completeFirstLoadFlg = true)"
      @printClicked="e => printProperty(e)"
      @openChangeRightModal="openChangeRightModal"
    >
    </PlanePagination>
    <Load v-if="downloading" class="load" />
    <div class="row">
      <div class="col-12">
        <b-button
          class="btn btn-ally float-right mx-1 my-2"
          :disabled="!activeDeleteBtn"
          @click="$bvModal.show('deleteModal')"
        >
          削除
        </b-button>
        <b-button
          class="btn btn-ally float-right mx-1 my-2"
          :disabled="!activeDownloadBtn"
          @click="$bvModal.show('downloadModal')"
        >
          ダウンロード
        </b-button>
      </div>
    </div>
    <b-modal
      id="deleteModal"
      title="削除確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="いいえ"
      ok-variant="light"
      ok-title="はい"
      @ok="deletePlane()"
    >
      選択した平面図を削除しますか？
    </b-modal>
    <b-modal
      id="downloadModal"
      title="出力確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="いいえ"
      ok-variant="light"
      ok-title="はい"
      @ok="confirmDownload()"
    >
      選択した平面図をダウンロードしますか？<br />
    </b-modal>
    <b-modal
      id="ProcessingPayment"
      title="お支払い手続き中"
      header-class="ally-modal"
      footer-class="ally-modal"
      centered
      ok-only
      ok-variant="light"
      ok-title="OK"
      @ok="checkPayment"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      お支払い手続き画面を表示しました。<br />
      お支払い完了後にOKボタンを押すと、ダウンロードを開始します。
    </b-modal>
    <b-modal
      ref="changeRight"
      title="編集者追加"
      header-class="ally-modal"
      footer-class="ally-modal"
      centered
      ok-only
      ok-variant="light"
      ok-title="OK"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      @ok="reloadPlanePagination"
    >
      <div style="height: 40px;">※ 管理者と作成者は常に編集可能です。</div>
      <paginationV2
        v-if="changeRightTarget !== null"
        ref="accountPagination"
        :headers="accountHeaders"
        targetName="Account"
        hideDetail
        hideDelete
        @addRight="changeRight"
        :filterFix="{
          status: {
            contains: 'true',
          },
          isAdmin: {
            eq: false,
          },
          sub: {
            ne: changeRightTarget !== null ? changeRightTarget.ownerId : undefined,
          },
        }"
      ></paginationV2>
    </b-modal>
    <b-modal
      ref="warningChangeSelfRight"
      title="確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      centered
    >
      このアカウントの権限を変更することはできません。
    </b-modal>
    <b-modal
      ref="noRight"
      title="編集権限がありません"
      header-class="ally-modal"
      footer-class="ally-modal"
      centered
      ok-only
    >
      編集権限の所有者のみ、編集権限の付与が行えます。
    </b-modal>
  </div>
</template>
<script>
  import awsconfig from '../../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import {
    enterPayment,
    getCompany,
    downloadPlaneViews,
    getAccount,
  } from '../../../graphql/queries.js';
  import { updateCompany } from '../../../graphql/mutations';
  import LicenseCommon from '../../License/js/Common';
  import Load from '../../../components/Common/Load';
  import PlanePagination from './PlanePagination';
  import { PlaneViewFetcher } from '../../PlaneView/lib/fetch/planeView';

  API.configure(awsconfig);

  export default {
    mixins: [LicenseCommon],
    data: function() {
      return {
        companyId: null, // 会社ID
        propertyId: null, // 物件ID
        maxImage: 10, // 保存MAX件数
        itemsLengthMaxFlg: false,
        completeFirstLoadFlg: false,
        activeDownloadBtn: false, // ダウンロードボタン活性フラグ
        activeDeleteBtn: false, // 削除ボタン活性フラグ
        isEnableCreateNew: false, // 新規作成可能フラグ
        orderId: null, // オーダーID
        downloading: false, // ダウンロード中
        changeRightTarget: null,
        guestIdList: null,
        roomId: null,
        planeFilterFix: [],
      };
    },
    async created() {
      // CREATED: 会社ID
      this.companyId = this.getCompanyId();
      // CREATED: 部屋ID
      this.roomId = this.$route.params.roomId;
      // CREATED: 物件ID
      this.propertyId = this.$route.params.id;
      // CREATED: 物件フィルターFixセット
      this.setPlaneFilterFix();
    },
    methods: {
      /**
       * METHOD: 平面図ページネーション再描画
       */
      reloadPlanePagination() {
        this.$refs.pagination.listItems();
      },
      /**
       * METHOD: openChangeRightModal
       */
      openChangeRightModal(e) {
        this.changeRightTarget = e;
        const right = this.checkRight(
          this.$store.state.user.attributes.sub,
          e.guestIdList,
          e.ownerId
        );
        if (!right) {
          this.$refs.noRight.show();
          return;
        }
        this.$refs.changeRight.show();
      },
      /**
       * METHOD: changeRight
       */
      async changeRight(e) {
        const accountId = e.sub;
        const result = await API.graphql(graphqlOperation(getAccount, { sub: accountId }));
        const isAdmin = result.data.getAccount.isAdmin;
        let { guestIdList, id, ownerId } = this.changeRightTarget;
        const isOwner = ownerId === accountId;
        // オーナーか管理者は自分自身の権限は変更できない
        if (accountId === this.$store.state.user.attributes.sub || isAdmin || isOwner) {
          this.$refs.warningChangeSelfRight.show();
          return;
        }
        const name =
          this.$store.state.user.attributes.family_name +
          this.$store.state.user.attributes.given_name;
        if (guestIdList == null) guestIdList = [];
        try {
          const fetcher = new PlaneViewFetcher();
          let result;
          // idが無ければ追加
          if (guestIdList.findIndex(g => g === accountId) === -1) {
            result = await fetcher.updatePlaneView({
              id: id,
              guestIdList: [...guestIdList, accountId],
              updatePerson: name,
            });
            // idがあれば削除
          } else {
            guestIdList = guestIdList.filter(g => g !== accountId);
            result = await fetcher.updatePlaneView({
              id: id,
              guestIdList: [...guestIdList],
              updatePerson: name,
            });
          }

          this.changeRightTarget = result.data.updatePlaneView;
        } catch (error) {
          console.error(error);
          return;
        }

        this.$refs.accountPagination.listItems();
      },
      /**
       * METHOD: ロード完了
       */
      onCompleteLoad() {
        this.completeFirstLoadFlg = true;
      },
      /**
       * METHOD: 会社ID取得
       */
      getCompanyId: function() {
        //アカウントから会社ID取得
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },
      /**
       * METHOD: 平面図 ビューエディター遷移
       */
      goPlaneViewEditor(planeId = null, viewOnly = false) {
        const query = {};
        if (this.propertyId) query.propertyId = this.propertyId;
        if (this.roomId) query.roomId = this.roomId;
        this.$router.push({
          path: `/PlaneView/${viewOnly ? 'View' : 'Edit'}/` + (planeId ?? ''),
          query,
        });
      },
      /**
       * METHOD: 平面図 作成
       */
      createPlane() {
        this.goPlaneViewEditor();
      },
      /**
       * METHOD: 平面図 編集
       */
      editPlane: function(e) {
        const right = this.checkRight(
          this.$store.state.user.attributes.sub,
          e.guestIdList,
          e.ownerId
        );

        this.goPlaneViewEditor(e.id, !right);
      },
      /**
       * METHOD: 平面図 削除
       */
      deletePlane: async function() {
        const checked = this.$refs.pagination.getChecked().filter(ch => ch.right);
        try {
          const fetcher = new PlaneViewFetcher();
          const results = checked.map(c => {
            return fetcher.deletePlaneView(
              c.propertyId,
              c.id,
              this.$store.state.user.attributes.family_name +
                this.$store.state.user.attributes.given_name
            );
          });
          await Promise.all(results);

          this.$refs.pagination.listItems();
        } catch (error) {
          console.error(error);
          this.showError('E0001');
        }
      },
      /**
       * METHOD: チェックボックス入力時
       */
      checkPlane: function() {
        this.$nextTick(() => {
          this.activeDownloadBtn = false;
          this.activeDeleteBtn = false;
          let list = this.$refs.pagination?.getChecked();

          if (list == null || list.length <= 0) {
            return;
          }

          this.activeDownloadBtn = true;
          if (!(list.findIndex(l => !l.right) !== -1)) {
            this.activeDeleteBtn = true;
          }
        });
      },
      /**
       * METHOD: ダウンロード確認
       */
      confirmDownload: async function() {
        // ライセンス確認
        const licensed = this.$store.state.functions.some(v => {
          return v == 9; // ALLY オートスケッチ（部屋計測・間取図自動作成）
        });

        // ライセンス割当有：即時ダウンロード（ウォーターマーク無）
        if (licensed) {
          await this.doDownload(false);
          return;
        }

        // ユーザ権限レベル確認
        const level = this.getUserAuthLevel();
        if (level == 'member') {
          // ライセンス割当無のメンバー：出力（ウォーターマーク有）
          await this.doDownload(true);
          return;
        }

        // ライセンス割当無の管理者：決済画面表示
        await this.showPayment();
      },

      /**
       * METHOD: ユーザ権限レベル取得
       * 'systemAdmin' or 'admin' or 'member'
       */
      getUserAuthLevel: function() {
        var userGroup = new Array();
        userGroup = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        if (userGroup.indexOf('systemAdmin') != -1) {
          return 'systemAdmin';
        } else if (userGroup.indexOf('admin') != -1) {
          return 'admin';
        }
        return 'member';
      },

      /**
       * METHOD: 決済画面表示
       */
      showPayment: async function() {
        // ライセンスマスタ取得
        const masters = await this.loadMaster();

        // 平面図情報取得
        const master = masters.find(v => {
          return v.id == 9; // ALLY オートスケッチ（部屋計測・間取図自動作成）
        });

        // 平面図価格取得
        const price = master.prices.find(v => {
          return v.period.unit == 'diagram';
        });

        // 出力枚数取得
        const count = this.$refs.pagination.getChecked().length;

        // 決済URL取得
        const result = await API.graphql(
          graphqlOperation(enterPayment, {
            companyId: this.companyId.slice(8),
            amount: price.unitPrice * count,
            tax: price.tax * count,
            overview: 'ALLY-PRO® 平面図出力',
          })
        );
        if (result.data.enterPayment.statusCode != '200') {
          return;
        }
        const json = JSON.parse(result.data.enterPayment.body);
        const url = json.url; // 決済URL
        this.orderId = json.orderId; // オーダーID

        // 決済情報追加
        const newPayment = {
          orderId: this.orderId, // オーダーID
          billingDate: this.getToday(), // 課金日
          amount: price.unitPrice * count, // 金額
          tax: price.tax * count, // 税額
        };
        this.addPayment(newPayment);

        // 決済URL表示
        window.open(url, 'enterPayment');

        // 支払中モーダル表示
        this.$bvModal.show('ProcessingPayment');
      },

      /**
       * METHOD: 決済情報追加
       */
      addPayment: async function(newPayment) {
        // 会社情報を取得
        let result = await API.graphql(
          graphqlOperation(getCompany, { id: this.companyId.slice(8) })
        );
        const company = result.data.getCompany;

        // 決済情報追加
        if (company.payment == null) {
          company.payment = [];
        }
        newPayment.id = company.payment.length + 1; // 決済ID
        company.payment.push(newPayment);

        // 会社情報更新
        result = await API.graphql(
          graphqlOperation(updateCompany, {
            input: {
              id: this.companyId.slice(8),
              payment: company.payment,
            },
          })
        );
      },

      /**
       * METHOD: 決済結果確認
       * 決済結果によりウォーターマークの有無を制御して平面図ダウンロード実行
       */
      checkPayment: async function() {
        // 会社情報を取得
        const result = await API.graphql(
          graphqlOperation(getCompany, { id: this.companyId.slice(8) })
        );
        const company = result.data.getCompany;

        // オーダーIDに紐付く決済情報を検索
        const payment = company.payment.find(v => {
          return v.orderId == this.orderId;
        });

        // 決済結果チェック
        let watermark = true;
        if (payment.paymentResult) {
          watermark = false;
        }

        // ダウンロード実行
        await this.doDownload(watermark);
      },

      /**
       * METHOD: ダウンロード実行
       */
      doDownload: async function(watermark) {
        this.downloading = true;

        // ダウンロード対象
        let propertyId = null;
        let files = [];
        for (let item of this.$refs.pagination.getChecked()) {
          files.push(item.imageName);
          propertyId = item.propertyId;
        }

        // URL取得(Lambda呼出)
        const result = await API.graphql(
          graphqlOperation(downloadPlaneViews, {
            companyId: this.companyId.slice(8),
            propertyId: propertyId,
            files: files,
            watermark: watermark,
          })
        );

        // ダウンロード実行
        const link = document.createElement('a');
        link.href = result.data.downloadPlaneViews;
        link.click();
        this.downloading = false;
      },

      /**
       * METHOD: リスト変更時
       */
      itemsChanged: function(e) {
        this.isEnableCreateNew = e.length < this.maxImage;
      },
      checkRight(myUserId, guestIdList, ownerId, userLevel = null) {
        if (userLevel == null) {
          userLevel = this.getUserAuthLevel();
        }
        const right =
          (guestIdList != null && guestIdList?.findIndex(v => myUserId === v) >= 0) ||
          ownerId === myUserId ||
          (ownerId == null && guestIdList == null) || // 両方未設定の場合
          userLevel === 'admin';
        return right;
      },
      /**
       * METHOD: フィルター設定を行う
       */
      setPlaneFilterFix() {
        const planeFilterFix = {
          propertyId: { eq: this.propertyId ?? undefined }, // 物件ID
          deleted: { ne: true },
        };
        // 部屋IDが存在する場合は条件としてセットし、無い場合は部屋IDが存在しないデータを条件とする
        planeFilterFix.roomId = this.roomId
          ? {
              eq: this.roomId,
            }
          : { attributeExists: false };

        this.planeFilterFix = planeFilterFix;
      },
      /**
       * METHOD: 履歴管理（history）ページの遷移設定を行う
       */
      historyClick: function() {
        let page = `/Property/${this.propertyId}/PlaneView/History`;
        if (this.roomId != null) page += '?roomId=' + this.roomId;
        if (this.$route.query.propertyList == 'true') {
          page += '&propertyList=true';
        }
        this.$router.push(page);
      },
    },
    computed: {
      // COMPUTED: ALLYオートスケッチリスト・ヘッダー
      headers() {
        return [
          {
            key: 'planeName',
            label: '平面図名',
          },
          {
            key: 'right',
            label: '編集権限',
            callback: (h, row) => {
              const id = this.$store.state.user.attributes.sub;
              const right = this.checkRight(id, row.item.guestIdList, row.item.ownerId);
              return right ? '◎' : '✕';
            },
          },
          { key: 'ownerName', label: '作成者' },
          {
            key: 'addEditor',
            label: '編集者追加',
            button: { label: '追加', event: 'openChangeRightModal' },
            searchable: false,
          },
          {
            key: 'edit',
            label: '平面図',
            callback: (h, row) => {
              const id = this.$store.state.user.attributes.sub;
              const right = this.checkRight(id, row.item.guestIdList, row.item.ownerId);
              return right ? '編集' : '閲覧';
            },
            button: { event: 'editClicked' },
            searchable: false,
          },
          {
            key: 'check',
            label: '選択',
            checkbox: { event: 'checkboxClicked' },
            searchable: false,
          },
        ];
      },
      // COMPUTED: アカウント・ヘッダー
      accountHeaders() {
        return [
          {
            key: 'name',
            label: '姓名',
            callback: (header, row) => {
              const familyname = row.item.familyname ? row.item.familyname + ' ' : '';
              const givenname = row.item.givenname ? row.item.givenname : '';
              return familyname + givenname;
            },
          },
          { key: 'email', label: 'E-mail' },
          {
            key: 'right',
            label: '編集権限',
            callback: (h, row) => {
              const id = row.item.sub;
              let { guestIdList, ownerId } = this.changeRightTarget;
              if (guestIdList == null) guestIdList = [];
              const right = this.checkRight(
                id,
                guestIdList,
                ownerId,
                row.item.isAdmin ? 'admin' : 'member'
              );
              return right ? '◎' : '✕';
            },
          },
          { key: 'select', label: '権限変更', button: { event: 'addRight', label: '変更' } },
        ];
      },
    },
    components: {
      PlanePagination,
      Load,
    },
  };
</script>
<style scoped>
  .add-plane {
    background-color: orange;
  }
  .list >>> .custom-control-input:checked ~ .custom-control-label::before {
    background: var(--colorTheme);
    border-color: var(--colorTheme);
  }
  .load {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 130px;
  }
</style>
