<template>
  <div>
    <div style="display: flex; font-size: 0.9rem">
      <TileBtn
        :block="false"
        width="50%"
        height="30px"
        :selected="!objectPreset.style.flipHorizontal"
        @click="$emit('changeFlipHorizontal', false)"
      >
        <i class="ti-shift-left"></i>
      </TileBtn>
      <TileBtn
        :block="false"
        width="50%"
        height="30px"
        :selected="objectPreset.style.flipHorizontal"
        @click="$emit('changeFlipHorizontal', true)"
      >
        <i class="ti-shift-right"></i>
      </TileBtn>
    </div>
    <div style="padding: 10px">
      <div class="pv-config-label">種類</div>
      <div>
        <select v-model="objectPreset.code" style="width: 100%">
          <option v-for="stairsItem in stairList" :value="stairsItem.code" :key="stairsItem.code">{{
            stairsItem.name
          }}</option></select
        >
      </div>
      <div>
        <div class="pv-config-label">幅(px)</div>
        <div>
          <input
            type="number"
            min="1"
            max="1000"
            style="width: 100%; text-align: center"
            :value="objectPreset.width"
            @change="e => $emit('changeWidth', parseFloat(e.srcElement.value))"
          />
        </div>
        <div class="pv-config-label">一段あたりの高さ(m)</div>
        <div>
          <input
            type="number"
            min="0.01"
            max="10"
            step="0.01"
            style="width: 100%"
            v-model="objectPreset.additionalInfo.stepHeight"
          />
        </div>
        <div class="pv-config-label">全体の高さ(m)</div>
        <div>
          <input
            type="number"
            min="0.1"
            max="10"
            step="0.1"
            style="width: 100%"
            :value="objectPreset.height3d.toFixed(2)"
            @input="e => $emit('changeHeight3d', parseFloat(e.srcElement.value))"
          />
        </div>
        <div v-if="objectPreset.code === 4301 || objectPreset.code === 4302">
          <div class="pv-config-label">一段あたりの角度</div>
          <div>
            <input
              type="number"
              min="15"
              max="90"
              step="1"
              style="width: 100%"
              v-model="objectPreset.additionalInfo.stepAngle"
            />
          </div>
        </div>
        <div v-show="isVRSimulatorLicense">
          <div class="pv-config-label">手すり(VR用)</div>
          <allyRadio
            class="col-12 p-0"
            name="stringers-type"
            :items="[
              {
                text: ADDITIONAL_INFO_STRINGERS_TYPE.NONE.LOGICAL_NAME,
                value: ADDITIONAL_INFO_STRINGERS_TYPE.NONE.ID,
              },
              {
                text: ADDITIONAL_INFO_STRINGERS_TYPE.LEFT.LOGICAL_NAME,
                value: ADDITIONAL_INFO_STRINGERS_TYPE.LEFT.ID,
              },
              {
                text: ADDITIONAL_INFO_STRINGERS_TYPE.RIGHT.LOGICAL_NAME,
                value: ADDITIONAL_INFO_STRINGERS_TYPE.RIGHT.ID,
              },
            ]"
            v-model="objectPreset.additionalInfo.stringers"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // @ts-check
  import { defineComponent, ref } from '@vue/composition-api';
  import {
    DEFAULT_WALL_TYPE,
    WALL_TYPES,
    ADDITIONAL_INFO_STRINGERS_TYPE,
  } from '../../../lib/util/const';
  import TileBtn from '../PvBtn.vue';
  import { PvObject } from '../../../lib/node/node/pvObject';

  export default defineComponent({
    components: {
      TileBtn,
    },
    props: {
      objectPreset: PvObject,
      isVRSimulatorLicense: Boolean,
      stairList: Array, // PROP: 階段リスト
    },
    setup(_, context) {
      const wallTypeList = ref(WALL_TYPES);

      return { DEFAULT_WALL_TYPE, wallTypeList, ADDITIONAL_INFO_STRINGERS_TYPE };
    },
  });
</script>
