<!--
作成者：Lee HyeonSeok
作成日：2020.7.13
画面名：レポート一覧
機能：レポート一覧
親子関係：なし
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <h4 class="font-weight-bold">レポート一覧</h4>
    <div class="row grid-margin mt-2" style="margin-right:-5px;">
      <div class="row">
        <div class="col-12">
          <router-link to="/DashBoard" tag="button" class="btn btn-ally float-right ml-3">
            メインページへもどる
          </router-link>
        </div>
      </div>
    </div>
    <div style="overflow-x: auto">
      <paginationV2
        ref="pagination"
        class="grid-margin"
        :headers="headers"
        targetName="ReportList"
        :labelEnums="labelEnums"
        :filterFix="filterFix ? filterFix : null"
        hideDetail
        @statusChange="e => statusChange(e)"
        @deleteClicked="e => deleteReport(e)"
        @downloadReport="e => downloadReport(e)"
      ></paginationV2>
      <b-modal
        ref="statusModal"
        title="ステータス変更"
        header-class="ally-modal"
        footer-class="ally-modal"
        body-class="ally-input-background"
        cancel-variant="light"
        cancel-title="キャンセル"
        ok-variant="light"
        ok-title="保存"
        @ok="updateStatus"
      >
        <div class="row">
          <b-form-group label="ステータス" class="col-12">
            <b-form-radio-group
              class="col-12"
              v-model="status"
              :options="[
                { value: '1', text: '決裁待ち' },
                { value: '2', text: '決裁完了' },
                { value: '3', text: '返戻' },
                { value: '4', text: '提出完了' },
              ]"
              buttons
              stacked
              button-variant="outline-ally"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </b-modal>
      <b-modal
        ref="download"
        title="ダウンロード中"
        header-class="ally-modal"
        footer-class="ally-modal"
        body-class="ally-input-background"
        ok-variant="light"
        ok-title="キャンセル"
        ok-only
        @ok="cancelDownload"
        @cancel="cancelDownload"
      >
        <div style="height: 300px;">
          <Load height="150px" width="150px" />
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getS3Url } from '../../graphql/queries';
  import { updateReportList, deleteReportList } from '../../graphql/mutations';
  import Load from '../../components/Common/Load';
  import axios from 'axios';

  API.configure(awsconfig);

  export default {
    components: {
      Load,
    },
    data: function() {
      return {
        headers: [
          { key: 'customerName', label: '顧客名' },
          { key: 'fileTitle', label: 'レポート名' },
          { key: 'mail', label: '登録者' },
          { key: 'updatedAt', label: '修正日', dataType: 'Date' },
          {
            key: 'status',
            label: 'ステータス',
            button: { event: 'statusChange' },
            enumType: 'status',
          },
          {
            key: 'download',
            label: 'ダウンロード',
            button: { label: 'ダウンロード', event: 'downloadReport' },
          },
        ],
        labelEnums: {
          status: {
            '1': '決裁待ち',
            '2': '決裁完了',
            '3': '返戻',
            '4': '提出完了',
          },
        },
        status: null,
        selectedRow: null,
        filterFix: null,
        cancelFlg: false,
      };
    },
    mounted: function() {
      if (this.$route.query.customer) {
        this.filterFix = {
          customerId: {
            eq: this.$route.query.customer,
          }
        };
        console.log("this.filterFix",this.filterFix)
      }
    },
    methods: {
      statusChange: function(e) {
        this.status = e.status;
        this.selectedRow = e;
        this.$refs.statusModal.show();
      },
      updateStatus: async function() {
        this.$refs.pagination.loading = true;

        await API.graphql(
          graphqlOperation(updateReportList, {
            input: { id: this.selectedRow.id, status: this.status },
          })
        );

        this.$refs.pagination.listItems();
      },
      deleteReport: async function(row) {
        //S3のファイルを削除する
        await API.graphql(
          graphqlOperation(getS3Url, {
            level: 'public',
            file: {
              fileName: row.fileName,
              fileAction: 'Delete',
              report: {
                reportId: row.fileName,
              },
            },
          })
        )
          .then(
            //成功した場合、メタデータを削除する
            async () =>
              await API.graphql(graphqlOperation(deleteReportList, { input: { id: row.id } }))
          )
          .catch(() => this.$bvModal.show('E101'));

        this.$refs.pagination.listItems();
      },
      cancelDownload() {
        this.cancelFlg = true;
      },
      downloadReport: async function(row) {
        this.$refs.download.show();
        var result = await API.graphql(
          graphqlOperation(getS3Url, {
            level: 'public',
            file: {
              fileName: row.fileName,
              fileAction: 'View',
              report: {
                reportId: row.fileName,
              },
            },
          })
        );

        await axios
          .get(result.data.getS3Url[0].fileUrl, { responseType: 'blob' })
          .then(response => {
            const blob = new Blob([response.data], {
              type: 'application/pdf',
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = row.fileName;
            if (this.cancelFlg === true) {
              this.cancelFlg = false;
              return;
            }
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch(console.error)
          .finally(() => {
            this.$refs.download.hide();
          });
      },
    },
  };
</script>
<style scoped>
  .download-modal {
    height: 1000px;
  }
</style>
