<template>
  <div>
    <div style="position: absolute; padding: 0; top: -2%; right: 3%; white-space: nowrap">
      <button class="btn btn-ally mr-1" style="height:10px;line-height:2px;" @click="listModalOpen">
        検索
      </button>
      <button
        class="btn btn-ally"
        style="margin-left:0.5rem;height:10px;line-height:2px;"
        @click="createMode"
      >
        新規
      </button>
    </div>

    <ValidationObserver ref="validation" v-slot="{ errors }">
      <div>
        <allyText
          :mode="'View'"
          v-model="propertyData.commonValue.propertyName"
          placeholder="物件名"
          title="物件名"
          rules="required|max:15"
        />
        <span class="required">※必須</span>
      </div>
      <div v-if="errors != null" style="position: relative; top: -10px">
        <span v-if="errors['物件名'] != null" style="color: red; font-size:13px;">
          {{ errors['物件名'][0] }}
        </span>
      </div>
      <allySelect
        title="部屋番号"
        :items="items"
        :mode="items.length <= 1 ? 'View' : 'Add'"
        v-model="roomId"
      />
    </ValidationObserver>
    <b-modal
      id="PropertyList"
      title="既存の物件情報を選択"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-title="閉じる"
      ok-only
      size="xl"
    >
      <PropertyList @selectClicked="selectClicked" @selectRoomClicked="selectRoomClicked" />
    </b-modal>
    <b-modal
      id="createModal"
      title="新規の物件情報を作成"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      size="sm"
    >
      <ValidationObserver ref="modalValidation">
        <div>
          <allyText
            v-model="createSet.propertyName"
            placeholder="物件名"
            title="物件名"
            rules="required|max:15"
          />
        </div>
        <div>
          <allyText
            v-model="createSet.roomName"
            placeholder="部屋番号"
            title="部屋番号"
            rules="max:15"
          />
        </div>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <button
          style="background-color: white; color: black;"
          class="btn btn-ally"
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          キャンセル
        </button>
        <button
          style="background-color: cornflowerblue;"
          class="btn btn-ally"
          size="sm"
          variant="outline-secondary"
          @click="createSetData()"
        >
          保存
        </button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
    <b-modal
      id="maxAlertModal"
      title="保存上限です。"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-only
      ok-title="閉じる"
      size="sm"
    >
      {{ maxSimlotion }}枚を超える保存はできません。
    </b-modal>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getProperty, listPhotoSimulators } from '../../graphql/queries';
  import { v4 as uuidv4 } from 'uuid';

  import PropertyList from '@/views/Property/List';

  API.configure(awsconfig);

  export default {
    data: function() {
      return {
        propertyData: {
          commonValue: {
            propertyName: null,
          },
        },
        roomId: null,
        roomFlag: false,
        propertyMode: 'create',
        items: [],
        createSet: {
          propertyName: null,
          roomName: null,
        },
        maxSimlotion: 20,
        propertyLoading: false,
      };
    },
    components: {
      PropertyList,
    },
    methods: {
      /**
       * 物件管理情報のリストを開く
       */
      listModalOpen: function() {
        this.$bvModal.show('PropertyList');
      },
      /**
       * 選択した物件管理情報を取得する
       */
      selectClicked: async function(row) {
        if (this.propertyLoading == true) return;
        this.propertyLoading = true;
        let photoList = await this.getPhotoList(row.id, null);
        if (photoList.length < this.maxSimlotion) {
          this.propertyData = row;
          this.roomId = null;
          this.propertyMode = 'select';
          this.roomFlag = false;
          this.$bvModal.hide('PropertyList');
          await this.roomSelectList(this.propertyData.roomInfo);
        } else {
          this.$bvModal.show('maxAlertModal');
        }
        this.propertyLoading = false;
      },
      /**
       * 選択した物件管理情報と部屋番号を取得する
       */
      selectRoomClicked: async function(item, propertyId) {
        if (this.propertyLoading == true) return;
        this.propertyLoading = true;
        let result = await API.graphql({
          query: getProperty,
          variables: {
            id: propertyId,
          },
        });

        let photoList = await this.getPhotoList(result.data.getProperty.id, item.id);
        if (photoList.length < this.maxSimlotion) {
          this.propertyData = result.data.getProperty;
          this.propertyMode = 'select';
          this.roomFlag = true;
          await this.roomSelectList(this.propertyData.roomInfo);
          this.roomId = item.id;
          this.$bvModal.hide('PropertyList');
        } else {
          this.$bvModal.show('maxAlertModal');
        }
        this.propertyLoading = false;
      },
      /**
       * 新しい物件を作るためのモーダルを表示する
       */
      createMode: function() {
        this.$bvModal.show('createModal');
      },
      /**
       * 新規の物件名がバリデーションに沿っているとき、設定し閉じる
       */
      createSetData: async function() {
        await this.$refs.modalValidation.validate().then(async propertyValid => {
          if (propertyValid) {
            this.propertyData = {
              commonValue: {
                propertyName: '',
              },
              roomInfo: null,
            };
            this.propertyData.commonValue.propertyName = this.createSet.propertyName;

            this.roomId = null;
            this.roomFlag = false;
            this.propertyMode = 'create';

            let roomId = null;
            if (this.createSet.roomName != null) {
              roomId = await uuidv4();
              this.propertyData.roomInfo = [
                {
                  id: roomId,
                  roomName: this.createSet.roomName,
                },
              ];
            }
            await this.roomSelectList(this.propertyData.roomInfo);
            this.roomId = roomId;
            this.$bvModal.hide('createModal');
          }
        });
      },
      /**
       * 部屋番号をプルダウンで選択できるようにする
       */
      roomSelectList: async function(roomInfo) {
        let mainSim = await this.getPhotoList(this.propertyData.id, null);
        if (this.propertyMode == 'create') mainSim = [];
        let roomName = '';
        if (mainSim.length >= this.maxSimlotion) roomName += ' ※これ以上保存できません';
        this.items = [
          {
            value: null,
            text: roomName,
            disabled: mainSim.length >= this.maxSimlotion,
          },
        ];
        if (roomInfo == null) return;

        //部屋番号の項目を設定する
        for (let room of roomInfo) {
          let simData = await this.getPhotoList(this.propertyData.id, room.id);
          if (this.propertyMode == 'create') simData = [];

          let roomName = room.roomName;
          if (simData.length >= this.maxSimlotion) roomName += ' ※これ以上保存できません';
          this.items.push({
            value: room.id,
            text: roomName,
            disabled: simData.length >= this.maxSimlotion,
          });
        }
      },
      /**
       * listPhotoSimulatorsを取得する
       */
      getPhotoList: async function(propertyId, roomId) {
        //フォトシミュの取得条件
        const queryVar = {
          limit: 60,
          company: await this.getCompanyId(),
          filter: {
            propertyId: {
              eq: propertyId,
            },
            roomId: {
              eq: roomId,
            },
          },
        };
        if (roomId == null) queryVar.filter.roomId = { attributeExists: false };
        var query = [];
        queryVar.nextToken = null;
        //平面図の枚数を数える。
        try {
          do {
            let items = await API.graphql(graphqlOperation(listPhotoSimulators, queryVar));
            query = [...query, ...items.data.listPhotoSimulators.items];
            queryVar.nextToken = items.data.listPhotoSimulators.nextToken;
          } while (queryVar.nextToken != null);
        } catch {
          return [];
        }
        return query;
      },
      /**
       * 選択した物件情報を返す
       */
      getPropertyData: function() {
        return this.propertyData;
      },
      /**
       * 部屋を設定するとき、部屋Noを表示する
       * 未設定時:null
       */
      getSelectRoomId: function() {
        return this.roomId;
      },
      /**
       * 既存の物件情報を使うか、新規で作成するかの情報を返す
       * "select": 既存の物件情報利用
       * "create": 新規の物件情報作成
       */
      getPropertyMode: function() {
        return this.propertyMode;
      },
      /**
       * バリデーションチェックの結果を返す
       */
      getValidationCheck: async function() {
        try {
          return await this.$refs.validation.validate().then(propertyValid => {
            return propertyValid;
          });
        } catch {
          return false;
        }
      },
      /*===============================================
       * 会社IDを取得する。
       */
      getCompanyId: async function() {
        //アカウントから会社ID取得
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },
      deployWidth: function() {},
    },
  };
</script>
<style>
  option:disabled {
    color: lightgray;
  }
</style>

<style scoped>
  .required {
    position: absolute;
    padding: 0;
    top: 2px;
    left: 70px;
    font-size: 13px;
    color: red;
    font-weight: bold;
  }
</style>
<style>
  .modal-dialog.modal-xl {
    max-width: 1340px;
  }
</style>
