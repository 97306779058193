import { API, graphqlOperation } from 'aws-amplify';
import { getToday } from '@/graphql/queries';

// https://note.com/rubymade/n/n59f5b885d3d7
export default {
  created() {},
  methods: {
    /**
     * エラーメッセージ表示
     *
     * エラーメッセージを表示する。
     *
     * @param {string} code errorIDを入力するメッセージID
     * @param {object} e 例外オブジェクト(省略可)
     * @note 呼出元に code が一致する <allyAlert> が存在すること。
     */
    showError(code, e) {
      if (!(e === null || e === undefined || e === '')) {
        // message がある場合
        if (e.message !== undefined) {
          console.error('EXCEPTION: ' + e.message);
        }
        // errors がある場合
        if (e.errors && e.errors.length > 0) {
          e.errors.forEach((v, i) => {
            console.debug('EXCEPTION[' + (i + 1) + '/' + e.errors.length + ']: ' + v.message);
          });
        }
      }
      this.$bvModal.show(code);
    },
    // システム日付更新
    updateSysDate: async function() {
      let today;
      try {
        // 環境変数(.env_local) ※要サーバー再起動
        today = process.env.VUE_APP_TODAY;
        console.debug({ 'today.env': today });
        // システムマスタ(dynamo)
        if (!today) {
          const result = await API.graphql(graphqlOperation(getToday));
          today = result.data.getToday;
          console.debug({ 'today.master': today });
        }
        // ローカルのシステム日付
        if (!today) {
          today = new Date().toISOString().slice(0, 10);
          console.debug({ 'today.local': today });
        }
      } catch {
        // 例外発生時：ローカルのシステム日付
        today = new Date().toISOString().slice(0, 10);
      } finally {
        // セッションに保持
        sessionStorage.setItem('today', today);
      }
    },
  },
  computed: {
    // 今日
    today: {
      get: function() {
        return sessionStorage.getItem('today');
      },
    },
  },
};
