<template>
  <CommonLayout title="利回り" :companyLogo="companyLogo" :companyName="companyName">
    <div
      style="display: grid; grid-template-columns: 1fr; grid-gap: 25mm; padding: 0 40mm ; font-size: 60px"
    >
      <div>
        <div :style="headerStyle">[表面利回り]</div>
        <div :style="{ ...bodyStyle, 'font-size': '60px' }">
          <div style="padding-top: 40px;">
            年間収入／物件価格 =
            {{ yieldResult ? yieldResult.yearlyRentTotal : 0 | tenThousandYen }} 万円 /
            {{ yieldResult ? yieldResult.propertyPrice : 0 | tenThousandYen }}万円 =
            {{ yieldResult ? yieldResult.yields[0] : 0 | ratio }}
          </div>
          <div :style="annotationStyle" style="padding-bottom: 40px;">
            ※年間収入は、満室時における家賃・駐車場の1年間の合計額です。
          </div>
        </div>
      </div>
      <div>
        <div :style="headerStyle">
          [ローン返済期間中の現金収支、及び実質利回り]
        </div>
        <div :style="{ ...bodyStyle, 'font-size': '60px' }">
          <div style="padding-top: 40px;">
            ローン期間中における1ヶ月当り現金収支 =
            {{ yieldResult ? yieldResult.cashFlowPerMonthEndOfLoan : 0 | tenThousandYen }}
            万円 ・・・①
          </div>
          <div :style="annotationStyle">
            ※年間収入は、満室時における家賃・駐車場の1年間の合計額です。
          </div>
          <div :style="subCaptionStyle" style="padding-top: 40px;">
            [建築価格に対する利回り]
          </div>
          <div>
            年間現金収支（①×12ヶ月） / 初期投資額 =
            {{ (yieldResult ? yieldResult.cashFlowPerMonthEndOfLoan : 0) | tenThousandYen }}万円 /
            {{ yieldResult ? yieldResult.initialCost : 0 | tenThousandYen }}万円 =
            {{ yieldResult ? yieldResult.yields[1] : 0 | ratio }}
          </div>
          <div :style="subCaptionStyle" style="padding-top: 60px;">
            [自己資金に対する利回り]
          </div>
          <div style="padding-bottom: 50px;">
            年間現金収支（①×12ヶ月） / 自己資金 =
            {{ yieldResult ? yieldResult.cashFlowPerMonthAfterEndOfLoan : 0 | tenThousandYen }}万円
            / {{ yieldResult ? yieldResult.ownResources : 0 | tenThousandYen }}万円 =
            {{ yieldResult ? yieldResult.yields[2] : 0 | ratio }}
          </div>
        </div>
      </div>
      <div>
        <div :style="headerStyle">
          [ローン返済終了直後の現金収支、及び実質利回り]
        </div>
        <div :style="{ ...bodyStyle, 'font-size': '60px' }">
          <div style="padding-top: 50px;">
            ローン返済終了直後の1ヶ月当り現金収支 =
            {{ yieldResult ? yieldResult.cashFlowPerMonthAfterEndOfLoan : 0 | tenThousandYen }}万円
            ・・・①
          </div>
          <div :style="annotationStyle">
            ※キャッシュフロー計画書の、ローン終了翌年の数値です。
          </div>
          <div :style="subCaptionStyle" style="padding-top: 50px;">
            [建築価格に対する利回り]
          </div>
          <div>
            年間現金収支（②×12ヶ月） / 初期投資額 =
            {{ yieldResult ? yieldResult.cashFlowPerMonthAfterEndOfLoan : 0 | tenThousandYen }}万円
            / {{ yieldResult ? yieldResult.initialCost : 0 | tenThousandYen }}万円 =
            {{ yieldResult ? yieldResult.yields[3] : 0 | ratio }}
          </div>
          <div :style="subCaptionStyle" style="padding-top: 50px;">
            [自己資金に対する利回り]
          </div>
          <div style="padding-bottom: 50px;">
            年間現金収支（②×12ヶ月） / 自己資金 =
            {{ yieldResult ? yieldResult.cashFlowPerMonthAfterEndOfLoan : 0 | tenThousandYen }}万円
            / {{ yieldResult ? yieldResult.ownResources : 0 | tenThousandYen }}万円 =
            {{ yieldResult ? yieldResult.yields[4] : 0 | ratio }}
          </div>
        </div>
      </div>
      <div :style="{ ...annotationStyle, 'font-size': '4rem' }">
        ※年間現金収支のなかにはすでに初年度経費も含まれるので、[建築価格に対する利回り]の分母は（建物価格+初年度経費）とせず（建物価格）のみとしています
      </div>
    </div>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import {
    headerStyle,
    subHeaderStyle,
    bodyStyle,
  } from '../../../../components/ExportReport/style';
  import Logo from '@/assets/report/reportImages/logo.png';

  export default {
    props: {
      companyLogo: String,
      companyName: String,
      yieldResult: Object,
    },
    components: {
      CommonLayout,
    },
    data() {
      return {
        logo: Logo,
        headerStyle,
        subHeaderStyle,
        bodyStyle,
        annotationStyle: {
          'font-size': '60px',
          color: 'red',
          'text-align': 'center',
        },
        subCaptionStyle: {
          'font-size': '60px',
          color: 'green',
          'text-align': 'center',
        },
      };
    },
    computed: {
      // 表面利回り
      hyoumenRimawari() {
        return this.yieldResult.yearlyRentTotal / this.yieldResult.propertyPrice;
      },
    },
  };
</script>
