export const REPORT_SCALE = 5; // レポートの拡大率（convertReport側でpage.pdfの引数のscaleにこれの逆数を渡す）
export const REPORT_SIZE = {
  A4: {
    width: 297,
    height: 210,
    scale: 1,
  },
  A3: {
    width: 420,
    height: 297,
    scale: 1.41,
  },
};
