<template>
  <div>
    <div>
      <img :src="imageUrl" :width="size || width" :height="size || height" :style="imageStyle" />
    </div>
    <div style="visibility: hidden;" :class="myClass" ref="canvas">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      imageStyle: Object,
      size: String,
      width: String,
      height: String,
    },
    data() {
      return {
        imageUrl: '',
        // classを自動で割り当てる（querySelectorでキャンバスを指定するため）
        myClass:
          'report-canvas' +
          Date.now() +
          Math.random()
            .toString()
            .replace('.', ''),
      };
    },
    mounted() {
      setTimeout(this.updateChart, 500); // 描画を待つ
    },
    methods: {
      updateChart() {
        // canvas検索
        const canvas = document.querySelector(`.${this.myClass} canvas`);
        if (canvas) {
          this.imageUrl = canvas.toDataURL('image/png');
          this.$refs.canvas.remove();
        }
      },
    },
  };
</script>
