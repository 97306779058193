// @ts-check

import { API, graphqlOperation } from 'aws-amplify';
import { getSystemMaster } from '../../../../graphql/queries';
import { getS3Url } from '../../../../graphql/queries';
import { checkDBCache } from '../../../../js/cache';
import { isNumber } from '../util/util';
import { iconList } from '../util/iconList';

export class IconDataFetcher {
  async listIcons() {
    const iconCategoryList = await this._IconCategoryList();
    const iconList = await this._IconList();
    /** @type {import('../util/iconList').IconData[]} */
    const result = await checkDBCache('iconImageCache', () => this._listIcons(iconList), {
      forceRefresh: false,
    });

    return result;
  }
  async IconCategoryList() {
    return await this._IconCategoryList();
  }

  /**
   *  FUNC: アイコンデータ取得
   * @param {import('../util/iconList').IconData[]} iconList
   */
  async _listIcons(iconList) {
    const s3Result = await API.graphql(
      graphqlOperation(getS3Url, {
        level: 'Master',
        file: iconList.map(x => {
          return {
            fileName: 'planeView/icons/' + x.imageFilename,
            fileAction: 'View',
          };
        }),
      })
    );
    // @ts-ignore
    const data = s3Result.data.getS3Url;
    // @ts-ignore
    const result = await Promise.all(
      iconList.map(async (u, i) => {
        const resp = await fetch(data[i].fileUrl);
        const blob = await resp.blob();
        return { ...u, blob, url: data[i].fileUrl.replace(/\?.*/, '') };
      })
    );
    return result;
  }

  /**
   *  FUNC: アイコンマスタ取得
   */
  async _IconList() {
    const result = await API.graphql(
      graphqlOperation(getSystemMaster, {
        classification: 'planeViewMaster',
        classificationDetail: 3,
      })
    );
    // 属性名を取得
    // @ts-ignore
    const attributes = result.data.getSystemMaster?.value[1];
    // 建具情報を整形し取得
    // @ts-ignore
    const iconList = result.data.getSystemMaster?.value.slice(2).map(item => {
      const data = [];
      // 属性名をキーに値をセットする
      item.map((val, index) => {
        const checkedVal = isNumber(val) ? parseInt(val) : val !== 'null' ? val : null;
        data[attributes[index]] = checkedVal;
      });
      return data;
    });
    return iconList.filter(icon => icon.isEnabled != 0);
  }

  /**
   *  FUNC: アイコン分類マスタ取得
   */
  async _IconCategoryList() {
    const result = await API.graphql(
      graphqlOperation(getSystemMaster, {
        classification: 'planeViewMaster',
        classificationDetail: 2,
      })
    );
    // 属性名を取得
    // @ts-ignore
    const attributes = result.data.getSystemMaster?.value[1];
    // 建具情報を整形し取得
    // @ts-ignore
    const iconCategoryList = result.data.getSystemMaster?.value.slice(2).map(item => {
      const data = [];
      // 属性名をキーに値をセットする
      item.map((val, index) => {
        const checkedVal = isNumber(val) ? parseInt(val) : val !== 'null' ? val : null;
        data[attributes[index]] = checkedVal;
      });
      return data;
    });
    return iconCategoryList.filter(icon => icon.isEnabled != 0);
  }
}
