// @ts-check

import { onMounted, ref } from '@vue/composition-api';
import { API, graphqlOperation } from 'aws-amplify';
import { getSystemMaster } from '../../../../graphql/queries';
import { InteriorMasterDataForAR } from '../../../../js/ar/interiorMasterDataForAR';
import { PvAdditionalInfo } from '../node/tools/additionalInfo';
import { doorList } from '../util/doorList';

export class InteriorMaster {
  /** @type {number} */
  code;
  /** @type {string} */
  name;
  /** @type {number} */
  type;
  /** @type {string} */
  subType;
  /** @type {number} */
  open;
  /** @type {number} */
  knob;
  /** @type {number} */
  sliding;
  /** @type {number} */
  bay;
  /** @type {boolean} */
  isAREnabled;
  /** @type {boolean} */
  isPVEnabled;
  constructor(params = {}) {
    this.code = parseInt(params.code) ?? 0;
    this.name = params.name ?? '';
    this.type = parseInt(params.type) ?? 0;
    this.subType = params.subType ?? '';
    this.open = parseInt(params.open) ?? 0;
    this.knob = parseInt(params.knob) ?? 0;
    this.sliding = parseInt(params.sliding) ?? 0;
    this.bay = parseInt(params.bay) ?? 0;
    this.isAREnabled = Boolean(parseInt(params.isAREnabled)) ?? false;
    this.isPVEnabled = Boolean(parseInt(params.isPVEnabled)) ?? false;
  }
}
export class InteriorMasterDataForPlaneView {
  /** @type {number} */
  code;
  /** @type {string} */
  name;
  /** @type {number} */
  type;
  /** @type {string} */
  subType;
  /** @type {PvAdditionalInfo} */
  additionalInfo;
  constructor(params = {}) {
    this.code = params.code ?? 0;
    this.name = params.name ?? '';
    this.type = params.type ?? 0;
    this.subType = params.subType ?? '';
    this.additionalInfo = new PvAdditionalInfo({
      open: params.open ?? 0,
      knob: params.knob ?? 0,
      sliding: params.sliding ?? 0,
    });
  }
}

export const useInteriorMaster = () => {
  /** @type {import('@vue/composition-api').Ref<InteriorMaster[]>} */
  const interiorMasterData = ref([]);
  /** @type {import('@vue/composition-api').Ref<InteriorMasterDataForAR[]>} */
  const interiorMasterDataForAR = ref([]);
  /** @type {import('@vue/composition-api').Ref<InteriorMasterDataForPlaneView[]>} */
  const interiorMasterDataForPlaneView = ref([]);

  onMounted(async () => {
    const result = await API.graphql(
      graphqlOperation(getSystemMaster, {
        classification: 'planeViewMaster',
        classificationDetail: 1,
      })
    );

    // 属性名を取得
    // @ts-ignore
    const attributes = result.data.getSystemMaster?.value[1];
    // 建具情報を整形し取得
    // @ts-ignore
    interiorMasterData.value = result.data.getSystemMaster?.value.slice(2).map(item => {
      const data = [];
      // 属性名をキーに値をセットする
      item.map((val, index) => {
        data[attributes[index]] = val;
      });
      // // @ts-ignore
      // data.dispTransform =
      //   doorList.find(door => {
      //     // @ts-ignore
      //     door.subType == data.subType && door.dispTransform;
      //   })?.dispTransform ?? null;
      return new InteriorMaster(data);
    });
    // console.log('interiorMasterData:', interiorMasterData.value);

    // @ts-ignore
    interiorMasterDataForAR.value = interiorMasterData.value
      .filter(
        // @ts-ignore
        data => data.isAREnabled === true
      )
      .map(data => new InteriorMasterDataForAR(data));
    // @ts-ignore
    interiorMasterDataForPlaneView.value = interiorMasterData.value
      .filter(data => {
        return data.isPVEnabled === true;
      })
      .map(data => {
        return new InteriorMasterDataForPlaneView(data);
      });
    // console.log('interiorMasterDataForAR:', interiorMasterDataForAR.value);
    // console.log('interiorMasterDataForPlaneView:', interiorMasterDataForPlaneView.value);
  });

  return {
    interiorMasterDataForAR,
    interiorMasterDataForPlaneView,
  };
};
