<template>
  <div
    class="editor-root"
    style=" font-size:12px;"
    @click="isContextMenuShown = false"
    @mousedown="mousedownGlobal"
    @mouseup="mouseupGlobal"
    @mousemove="mousemoveGlobal"
  >
    <PvHeader
      :height="headerHeight"
      :mode="mode"
      :isImageGenerating="false"
      :isLoadingMaterials="isLoadingWallMaterials || isLoadingFloorMaterials"
      :isARSimulatorAppLicense="isARSimulatorAppLicense"
      :isVRSimulatorLicense="isVRSimulatorLicense"
      @back="back"
      @mode="changeMode"
      @zoom="addZoom"
      @redo="redo"
      @undo="undo"
      @downloadImage="openDownloadImageModal"
      @length-menu="openLengthMenu"
      @grid-menu="openGridMenu"
      @startMeasurement="startMeasurement"
      @startFurnitureSimulator="startFurnitureSimulator"
      @openPlaneViewListModal="openPlaneViewListModal"
      @save="openSaveModal"
      @vrView="openVrView"
      :isEditor="isEditor"
      :layerLength="planeViewPatterns[patternIndex].layersRoot.length"
      :isDisplayLower="isDisplayLower"
      @changeDisplayLower="val => (isDisplayLower = val)"
    />
    <div class="middle-area">
      <EditMenu
        v-if="activeObject !== null"
        :resizeHandleFlg="resizeHandleFlg"
        @select-all="selectAll"
        @delete="deleteSelectedObjects"
        @move-front="moveFront"
        @move-back="moveBack"
        @forefront="moveFrontmost"
        @backmost="moveBackmost"
        @rotate-left="rotate(-90)"
        @rotate-right="rotate(90)"
        @copy="copy"
        @handle="resizeHandleFlg = !resizeHandleFlg"
        @merge="merge"
        :isEditor="isEditor"
      />
      <Azimuth
        @change-azimuth="val => (azimuth = val)"
        :azimuthValue="azimuth"
        :isEditor="isEditor"
      />
      <div
        :style="{
          width: leftMenuWidth + 'px',
          minWidth: leftMenuWidth + 'px',
          'background-color': 'gray',
        }"
      >
        <LeftMenu :height="editorHeight" :mode="mode" :activeObject="activeObject">
          <PanMenu v-show="mode === 'pan'" />
          <SelectMenu
            v-show="mode === 'select'"
            :mode="mode"
            :activeObject="activeObject"
            :selectMenuMode="selectMenuMode"
            :objectTree="objectTree"
            @changeSelectedMenuMode="val => (selectMenuMode = val)"
            :materials="floorMaterials"
            :height="editorHeight"
            :isEditor="isEditor"
            :isVRSimulatorLicense="isVRSimulatorLicense"
            :stairList="stairList"
            :doorWindowList="doorWindowList"
          />
          <FloorMenu
            v-show="mode === 'floor'"
            :isLoading="isLoadingFloorMaterials"
            :material="floorMaterials"
            @changeFloorType="val => (floorType = val)"
            @changeMaterial="changeMaterial"
            @changeSpaceName="val => setStyleProperty('spaceName', val)"
            @changeStrokeWidth="val => setStyleProperty('strokeWidth', val)"
            @changePatternWidth="val => setStyleProperty('patternWidth', val)"
            @changeStrokeColor="val => setStyleProperty('stroke', val)"
            @changeHeight3d="val => setProperty({ key: 'height3d', value: val })"
            :floorType="floorType"
            :objectPreset="currentPreset"
            :height="editorHeight"
          />
          <IconMenu
            v-show="mode === 'icon'"
            :iconImages="iconImages"
            :iconCategoryList="iconCategoryList"
            :isLoading="isLoadingIconImages"
            :height="editorHeight"
          />
          <DoorMenu
            v-show="mode === 'door'"
            @changeStrokeWidth="val => setStyleProperty('strokeWidth', val)"
            @changeHeight3d="val => setProperty({ key: 'height3d', value: val })"
            @changeMountingHeight="val => setProperty({ key: 'mountingHeight', value: val })"
            :doorType="doorType"
            :objectPreset="currentPreset"
            :height="editorHeight"
            @changeType="changeDoorType"
            :doorWindowList="doorWindowList"
          />
          <WallMenu
            v-show="mode === 'wall'"
            @changeWallType="changeWallType"
            @changeStrokeWidth="val => setStyleProperty('strokeWidth', val)"
            @changeHeight3d="val => setProperty({ key: 'height3d', value: val })"
            :objectPreset="currentPreset"
          />
          <StairsMenu
            v-show="mode === 'stairs'"
            @changeWallType="changeWallType"
            @changeFlipHorizontal="val => setStyleProperty('flipHorizontal', val)"
            @changeWidth="
              val => {
                setProperty({ key: 'width', value: val });
                setProperty({ key: 'height', value: val });
              }
            "
            @changeHeight3d="val => setProperty({ key: 'height3d', value: val })"
            :objectPreset="currentPreset"
            :isVRSimulatorLicense="isVRSimulatorLicense"
            :stairList="stairList"
          />
        </LeftMenu>
      </div>
      <div
        :style="{
          width: '100%',
        }"
      >
        <svg
          :style="{
            'background-color': 'white',
          }"
          :width="editorWidth"
          :height="editorHeight"
          :viewBox="`${editorX} ${editorY} ${editorWidth / zoom} ${editorHeight / zoom}`"
          @click="click"
          @mousedown="mousedown"
          @mouseup="mouseup"
          @mousemove="mousemove"
          @mouseleave="mouseleave"
          @contextmenu.prevent="contextmenu"
          ref="targetElement"
        >
          <g>
            <rect
              :width="layerWidth"
              :height="layerHeight"
              fill="white"
              data-area="layer"
              :style="{
                cursor: mode === 'pan' ? 'move' : 'auto',
              }"
            />
            <g id="pv-lower-tree" v-if="lowerObjectTree && isDisplayLower">
              <!-- 下階オブジェクト -->
              <NodeView
                v-for="obj in lowerObjectTree"
                :key="obj.id"
                :obj="obj"
                :selectedIdSet="selectedIdSet"
              />
            </g>
            <GridLayer
              :showGrid="showGrid"
              :width="layerWidth"
              :height="layerHeight"
              :zoom="zoom"
              :largeGridPx="largeGridPx"
              :smallGridPx="smallGridPx"
              :gridScale="gridScale"
              :showWaterMark="showWaterMark"
              :printAreaX.sync="printAreaX"
              :printAreaY.sync="printAreaY"
              :printZoom="printZoom"
              :printPaperRatio="printPaperRatio"
              :printWidth="printWidth"
              :printHeight="printHeight"
            />
            <g id="pv-main-tree">
              <!-- 実オブジェクト -->
              <NodeView
                v-for="obj in objectTree"
                :key="obj.id"
                :obj="obj"
                :selectedIdSet="selectedIdSet"
              />
            </g>
            <g id="pv-guide-tree">
              <!-- ガイドオブジェクト -->
              <NodeView
                v-for="obj in guideObjectTree"
                :key="obj.id + 'guide'"
                :obj="obj"
                :style="{
                  opacity: GUIDE_OPACITY,
                  'pointer-events': 'none',
                }"
              />
            </g>
            <g id="pv-handle-tree" v-if="!generatingImage">
              <!-- ハンドルオブジェクト -->
              <NodeView v-for="obj in handleTree" :key="obj.id" :obj="obj" />
            </g>
            <g id="pv-measure-tree">
              <!-- 長さ -->
              <NodeView v-for="obj in measureTree" :key="obj.id" :obj="obj" />
            </g>
            <g id="pv-measure-tree" v-if="!generatingImage">
              <NodeView :obj="printArea" />
            </g>
            <MousePointer
              v-if="mode !== 'pan' && !generatingImage"
              :x="currentPos.x"
              :y="currentPos.y"
            />
          </g>
        </svg>
        <!--
        <div hidden :spaceParam="spaceParam" />
        -->
      </div>
      <!-- VIEW: パターンチェンジ -->
      <div v-if="planeViewPatterns.length > 1" :style="patternLayerCSS" class="pattern-change-btn">
        <select
          class="theme-color-select px-2 py-1"
          name="pattern"
          v-model="patternIndex"
          @change="
            if (layerIndex >= planeViewPatterns[patternIndex].layersRoot.length) {
              layerIndex = planeViewPatterns[patternIndex].layersRoot.length - 1;
            }
          "
        >
          <option
            class="theme-color-option bg-white text-dark"
            v-for="(pattern, index) in planeViewPatterns"
            :value="index"
            :key="displayKey + index"
            >{{ pattern.name }}</option
          ></select
        >
      </div>
      <!-- VIEW: レイヤーチェンジ -->
      <div :style="patternLayerCSS" class="layer-change-btn">
        <PvBtn
          v-for="index in planeViewPatterns[patternIndex].layersRoot.length"
          :key="displayKey + index"
          :margin="'0 1em 0 0'"
          :borderRadius="patternLayerBtnCSS.borderRadius"
          :width="patternLayerBtnCSS.width"
          :height="patternLayerBtnCSS.height"
          :backgroundColor="patternLayerBtnCSS.backgroundColor"
          :hoverBackground="patternLayerBtnCSS.hoverBackground"
          :color="patternLayerBtnCSS.color"
          :border="patternLayerBtnCSS.border"
          :opacity="patternLayerBtnCSS.opacity"
          @click="layerIndex = index - 1"
          :selected="layerIndex === index - 1"
        >
          <span
            :style="{
              'font-weight': patternLayerBtnCSS.fontWeight,
            }"
            ><span
              :style="{
                'font-size': patternLayerBtnCSS.fontSize,
              }"
              >{{ index }}</span
            >F</span
          >
        </PvBtn>
      </div>
      <!-- VIEW: パターン・レイヤー設定 -->
      <div :style="patternLayerCSS" class="pattern-layer-setting-btn">
        <PvBtn
          :borderRadius="patternLayerBtnCSS.borderRadius"
          :width="patternLayerBtnCSS.width"
          :height="patternLayerBtnCSS.height"
          :backgroundColor="patternLayerBtnCSS.backgroundColor"
          :hoverBackground="patternLayerBtnCSS.hoverBackground"
          :color="patternLayerBtnCSS.color"
          :border="patternLayerBtnCSS.border"
          :opacity="patternLayerBtnCSS.opacity"
          @click="openPatternLayerSettingModal"
        >
          <span
            :style="{
              'font-size': patternLayerBtnCSS.fontSize,
              'font-weight': patternLayerBtnCSS.fontWeight,
            }"
            >︙</span
          >
        </PvBtn>
      </div>
    </div>
    <!-- VIEW: スクロールバー -->
    <ScrollBar
      :style="{
        position: 'fixed',
        right: 0,
        top: headerHeight + 'px',
      }"
      :value="editorRatioY"
      @change="val => (editorY = val * layerHeight)"
      :length="editorHeight"
    />
    <ScrollBar
      :style="{
        position: 'fixed',
        left: leftMenuWidth + 'px',
        bottom: 0,
      }"
      :horizontal="true"
      :value="editorRatioX"
      @change="val => (editorX = val * layerWidth)"
      :length="editorWidth"
    />
    <div
      style="background-color: white; width: 15px; height: 15px; position: fixed; right: 0; bottom: 0;"
    />
    <!-- VIEW: 部屋の単位の設定モーダル -->
    <b-modal
      ref="lengthMenu"
      header-class="ally-modal text-center"
      footer-class="ally-modal text-center"
      body-class="ally-input-background"
      title="部屋の単位の設定"
      centered
      size="md"
      ok-title="閉じる"
      ok-only
      hide-header-close
    >
      <MeasureSetting
        :unitOfMeasure="unitOfMeasure"
        @unitOfMeasure="val => (unitOfMeasure = val)"
        :showMeasure="showMeasure"
        @showMeasure="val => (showMeasure = val)"
        :unitOfSpace="unitOfSpace"
        @unitOfSpace="val => (unitOfSpace = val)"
        :showSpace="showSpace"
        @showSpace="val => (showSpace = val)"
      />
    </b-modal>
    <!-- VIEW: 画像ダウンロードモーダル -->
    <b-modal
      ref="downloadImageModal"
      header-class="ally-modal text-center"
      footer-class="ally-modal text-center"
      body-class="ally-input-background"
      title="画像プレビュー"
      centered
      size="lg"
      @ok="downloadImage"
      ok-title="画像ダウンロード"
    >
      <PrintSetting
        :currentPrintSize.sync="currentPrintSize"
        :currentPrintScale.sync="currentPrintScale"
        :imageBuf="imageBuf"
        :generatingImage="generatingImage"
      />
    </b-modal>
    <!-- VIEW: グリッド設定モーダル -->
    <b-modal
      ref="gridMenu"
      header-class="ally-modal text-center"
      footer-class="ally-modal text-center"
      body-class="ally-input-background"
      title="グリッド設定"
      centered
      size="md"
      ok-title="閉じる"
      @ok="() => {}"
      ok-only
      hide-header-close
    >
      <GridSetting
        :showGrid="showGrid"
        @showGrid="val => (showGrid = val)"
        :gridSize="gridSize"
        @gridSize="val => (gridSize = val)"
      />
    </b-modal>
    <!-- VIEW: ARご案内モーダル -->
    <b-modal
      ref="arAnnounceModal"
      header-class="ally-modal text-center"
      footer-class="ally-modal text-center"
      body-class="ally-input-background"
      title="ご案内"
      centered
      ok-only
      size="md"
    >
      <ArAnnounce />
    </b-modal>
    <!-- VIEW: オートスケッチ一覧モーダル -->
    <b-modal
      ref="planeViewListModal"
      header-class="ally-modal text-center"
      footer-class="ally-modal
     text-center"
      body-class="ally-input-background"
      title="オートスケッチの読み込み"
      size="xl"
      ok-title="閉じる"
      @ok="isPlaneViewListModal = false"
      ok-only
      hide-header-close
      no-close-on-backdrop
    >
      <List :isPlaneViewListModal="isPlaneViewListModal" targetName="Property" />
    </b-modal>
    <!-- VIEW: 保存モーダル -->propertyId
    <b-modal
      ref="saveModal"
      header-class="ally-modal text-center"
      footer-class="ally-modal text-center"
      body-class="ally-input-background"
      title="平面図のタイトルを入力"
      centered
      ok-title="保存"
      @ok="save"
      :ok-disabled="isMax || !isValidate"
      cancel-title="キャンセル"
      size="md"
      hide-header-close
    >
      <SaveModal
        :isNewPlaneView="isNewPlaneView"
        :planeName="planeName"
        @updatePlaneName="val => (planeName = val)"
        :propertyId="propertyId"
        @updatePropertyId="val => (propertyId = val)"
        :roomId="roomId"
        @updateRoomId="val => (roomId = val)"
        @updateIsMax="val => (isMax = val)"
        @updateIsValidate="val => (isValidate = val)"
      />
    </b-modal>
    <!-- VIEW: パターン・階層設定モーダル -->
    <b-modal
      ref="patternLayerSettingModal"
      header-class="ally-modal text-center"
      footer-class="ally-modal text-center"
      body-class="ally-input-background"
      title="階層・パターン設定"
      centered
      ok-only
      ok-title="閉じる"
      hide-header-close
      size="md"
    >
      <PatternLayerSettingModal
        :patternIndex="patternIndex"
        :planeViewPatterns="planeViewPatterns"
        :planeViewLayers="planeViewPatterns[patternIndex].layersRoot"
        @addPattern="
          val => {
            addPattern(val);
            historyController.saveHistory(val.index);
          }
        "
        @renamePattern="val => renamePattern(val)"
        @splicePatterns="
          val => {
            splicePatterns(val);
            historyController.deletePattern(val.index);
          }
        "
        @updateLayers="
          val => {
            if (planeViewPatterns[patternIndex].layersRoot.length > val.layerNum) {
              for (
                let i = val.layerNum;
                i < planeViewPatterns[patternIndex].layersRoot.length;
                i++
              ) {
                historyController.deleteLayer(patternIndex, i);
              }
            }
            updateLayers(val);
          }
        "
      />
    </b-modal>
    <!-- VIEW: ローディング -->
    <div v-if="showLoadingOverlay" class="loading-overlay">
      <b-spinner variant="primary" style="width: 100px; height: 100px" />
    </div>
    <SplashScreen :isLoading="isLoading" />
    <div>
      <img
        v-if="draggedIcon"
        :style="{
          position: 'absolute',
          left: draggedIconPosition.x + 'px',
          top: draggedIconPosition.y + 'px',
          'pointer-events': 'none',
        }"
        :src="draggedIcon.url"
        :width="dragedIconWidth"
        :height="dragedIconHeight"
      />
    </div>
    <!-- VIEW: 右クリックメニュー -->
    <div
      v-if="isContextMenuShown"
      class="pv-context-menu"
      :style="{
        left: contextMenuPos.x + 'px',
        top: contextMenuPos.y + 'px',
      }"
    >
      <PvBtn @click="deleteSelectedObjects">
        削除
      </PvBtn>
      <PvBtn @click="selectAll">
        全選択
      </PvBtn>
    </div>
  </div>
</template>
<script>
  // IMPORT:
  // @ts-check
  import {
    computed,
    defineComponent,
    getCurrentInstance,
    nextTick,
    onMounted,
    onUnmounted,
    ref,
    toRefs,
    watch,
  } from '@vue/composition-api';
  import PvHeader from './components/UI/PvHeader.vue';
  import GridLayer from './components/UI/GridLayer.vue';
  import NodeView from './components/Node/NodeView.vue';
  import LeftMenu from './components/UI/LeftMenu/LeftMenu.vue';
  import PanMenu from './components/UI/LeftMenu/PanMenu.vue';
  import SelectMenu from './components/UI/LeftMenu/SelectMenu/SelectMenu.vue';
  import FloorMenu from './components/UI/LeftMenu/FloorMenu.vue';
  import WallMenu from './components/UI/LeftMenu/WallMenu.vue';
  import IconMenu from './components/UI/LeftMenu/IconMenu.vue';
  import DoorMenu from './components/UI/LeftMenu/DoorMenu.vue';
  import StairsMenu from './components/UI/LeftMenu/StairsMenu.vue';
  import EditMenu from './components/UI/EditBtnGroup.vue';
  import PvBtn from './components/UI/PvBtn.vue';
  import MousePointer from './components/UI/MousePointer.vue';
  import MeasureSetting from './components/UI/MeasureSetting.vue';
  import GridSetting from './components/UI/GridSetting.vue';
  import List from './List.vue';
  import SaveModal from './components/UI/SaveModal.vue';
  import PatternLayerSettingModal from './components/UI/patternLayerSetting.vue';
  import PlaneViewList from '../Property/PlaneView/List.vue';
  import ArAnnounce from './components/UI/ArAnnounce.vue';
  import WaterMarkLayer from './components/UI/WaterMarkLayer.vue';
  import PrintSetting from './components/UI/PrintSetting.vue';
  import ScrollBar from './components/UI/ScrollBar.vue';
  import Azimuth from './components/UI/Azimuth.vue';
  import {
    GUIDE_OPACITY,
    ALLY_LICENSE,
    MESSAGE_TEXT,
    INTERIOR_MASTER_CATEGORY_TYPE,
  } from './lib/util/const';
  import { WALL_STYLE_PRESETS } from './lib/node/tools/wallStylePresets';
  import { useTree } from './lib/state/tree';
  import { useGuideTree } from './lib/state/guideTree';
  import { useEditor } from './lib/state/editor';
  import { useHistoryController } from './lib/state/historyController';
  import { useMeasure } from './lib/state/measure';
  import { useGlobalSettings } from './lib/state/globalSettings';
  import { useWindow } from './lib/state/window';
  import { useFloorMaterials } from './lib/state/floorMaterials';
  import { useWallMaterials } from './lib/state/wallMaterials';
  import { useHandle } from './lib/state/handle';
  import { useSelectedObjectsOperation } from './lib/state/selectedObjectsOperation';
  import { ActionManager } from './lib/event/action/actionManager';
  import { useEventConverter } from './lib/state/eventConverter';
  import { useAr } from './lib/state/ar';
  import { convertLayersForVR } from './lib/state/vr';
  import { useObjectSelector } from './lib/state/objectSelector';
  import { useObjectPresets } from './lib/state/objectPresets';
  import { useIconImages } from './lib/state/iconImages';
  import { useInteriorMaster } from './lib/state/interiorMaster';
  import { useSave } from './lib/state/save';
  import { useAppendIcon } from './lib/state/appendIcon';
  import { usePrint } from './lib/state/print';
  import {
    testData1,
    testData2,
    testData3,
    testData4,
    testData5,
    testData6,
    testData7,
    testData8,
    testData9,
    testData10,
    testData11,
    testData12,
    testData13,
    testData14,
    testData15,
    testData16,
    testData17,
    testData18,
    testDataForVR,
  } from './lib/util/testData';
  import { callUnity, CALL_COMMAND } from '../../js/ar/unity';
  import { EventContext } from './lib/event/preset/eventContext';
  import SplashScreen from '../../components/Common/SplashScreen.vue';
  import { Vec } from './lib/util/math/vector';
  import { cleanNodes } from './lib/node/node/pvNode';
  import { makeToast } from './lib/util/util';
  import router from '../../router';
  import { PvFloor } from './lib/node/node/floorNode';

  export default defineComponent({
    components: {
      PvHeader,
      SelectMenu,
      GridLayer,
      NodeView,
      MousePointer,
      LeftMenu,
      PanMenu,
      FloorMenu,
      DoorMenu,
      WallMenu,
      IconMenu,
      StairsMenu,
      EditMenu,
      PvBtn,
      MeasureSetting,
      GridSetting,
      PrintSetting,
      ArAnnounce,
      List,
      SaveModal,
      PatternLayerSettingModal,
      WaterMarkLayer,
      SplashScreen,
      PlaneViewList,
      ScrollBar,
      Azimuth,
    },
    beforeRouteLeave(to, form, next) {
      // このコンポーネントから離れる場合、確認ダイアログ表示
      if (confirm('保存されていない内容が失われる可能性があります。')) {
        next();
      } else {
        next(false);
      }
    },
    setup() {
      /**
       * METHOD: クリーン・ノード
       */
      cleanNodes();

      const layerBtnSize = ref(50); // DATA: 階層ボタンサイズ
      const patternLayerBtnCSS = {
        borderRadius: '100%',
        width: layerBtnSize.value + 'px',
        height: layerBtnSize.value + 'px',
        backgroundColor: 'transparent',
        hoverBackground: 'var(--colorTheme)',
        color: 'var(--colorTheme)',
        fontSize: '1.75em',
        fontWeight: 'bold',
        border: 'solid 2px var(--colorTheme)',
        opacity: '0.9',
      };
      /**
       * COMPUTED: 階層ボタンCSS
       */
      const patternLayerCSS = computed(() => {
        return {
          '--pulldown-right': layerBtnSize.value / 2 + 'px',
          '--pulldown-bottom': layerBtnSize.value * 1.75 + 'px',
          '--btn-bottom': layerBtnSize.value / 2 + 'px',
          '--layer-btn-right': layerBtnSize.value * 1.5 + 'px',
          '--setting-btn-right': layerBtnSize.value / 2 + 'px',
        };
      });

      const isDisplayLower = ref(true); // DATA: 下階表示フラグ
      const instance = getCurrentInstance(); // DATA: カレント・インスタンス
      const isEditor = ref(false); // DATA: 編集権限
      /** @type {import('@vue/composition-api').Ref<SVGSVGElement|null>} */
      const targetElement = ref(null); // DATA: ref名：エディタ編集領域
      const showLoadingOverlay = ref(false);

      const { windowWidth, windowHeight } = useWindow();

      const globalSettings = useGlobalSettings();
      const { mode } = toRefs(globalSettings);

      const selectMenuMode = ref('detail'); // DATA: 左メニューモード（ detail | tree ）

      const isPlaneViewListModal = ref(false); // DATA: オートスケッチ一覧モーダルフラグ

      const interiorMaster = useInteriorMaster(); // DATA: 全建具データ取得
      const interiorList = ref(interiorMaster.interiorMasterDataForPlaneView); // DATA: 平面図用建具リストを取得

      const editor = useEditor({
        targetElement,
        windowWidth,
        windowHeight,
      });

      /**
       * COMPUTED: ライセンスチェック
       */
      const licensed = computed(() => {
        return getCurrentInstance()?.proxy.$store.state.functions?.some((
          /** @type {number} */ v
        ) => {
          return v == ALLY_LICENSE.AUTO_SKETCH.ID; // ALLY オートスケッチ（部屋計測・間取図自動作成）
        });
      });

      /**
       * COMPUTED: ARシミュレーターアプリライセンスチェック
       */
      const isARSimulatorAppLicense = computed(() => {
        return getCurrentInstance()?.proxy.$store.state.functions?.some((
          /** @type {number} */ v
        ) => {
          return v == ALLY_LICENSE.AR_SIMULATOR_APP.ID; // ALLY ARシミュレーター
        });
      });

      /**
       * COMPUTED: VRシミュレーターライセンスチェック
       */
      const isVRSimulatorLicense = computed(() => {
        return getCurrentInstance()?.proxy.$store.state.functions?.some((
          /** @type {number} */ v
        ) => {
          return v == ALLY_LICENSE.VR_SIMULATOR.ID; // ALLY VRシミュレーター
        });
      });

      /**
       * COMPUTED: 階段リスト
       */
      const stairList = computed(() => {
        return interiorList.value.filter(
          interior => interior.type === INTERIOR_MASTER_CATEGORY_TYPE.STAIRS
        );
      });

      /**
       * COMPUTED: ドア・窓リスト
       */
      const doorWindowList = computed(() => {
        return interiorList.value.filter(
          interior =>
            interior.type === INTERIOR_MASTER_CATEGORY_TYPE.DOORS ||
            interior.type === INTERIOR_MASTER_CATEGORY_TYPE.WINDOWS
        );
      });

      const showWaterMark = ref(!licensed.value); // DATA: ウォーターマーク表示フラグ

      const { currentPos, mousePos, layerHeight, layerWidth } = editor;

      let objectPresets = useObjectPresets(mode);
      const { presets } = objectPresets;

      const tree = useTree();
      const {
        objectTree,
        lowerObjectTree,
        root,
        lowerRoot,
        patternIndex,
        layerIndex,
        updateLayers,
        addPattern,
        renamePattern,
        splicePatterns,
        layerPatterns,
      } = tree;

      const objectSelector = useObjectSelector({
        objectTree,
        root,
      });

      const guideTree = useGuideTree();
      const { guideRoot } = guideTree;

      const print = usePrint({
        editorX: editor.editorY,
        editorY: editor.editorY,
        zoom: editor.zoom,
        targetElement,
      });

      const historyController = useHistoryController(layerPatterns, patternIndex, layerIndex);

      const selectedObjectsOperation = useSelectedObjectsOperation({
        objectSelector,
        root,
        historyController,
      });

      /**
       * COMPUTED: 平面図パターンリスト
       */
      const planeViewPatterns = computed(() => {
        return tree.layerPatterns;
      });

      /**
       * COMPUTED: 表示中の平面図データ
       */
      const displayLayers = computed(() => {
        return [
          planeViewPatterns.value[patternIndex.value].layersRoot[layerIndex.value]
            .flat()
            .map(n => n.toJsonObject()),
        ];
      });

      /**
       * WATCH: モード
       */
      watch([mode], () => {
        // 全選択解除
        root.value.unselectAll();
        switch (mode.value) {
          case 'select': {
            makeToast(MESSAGE_TEXT.SELECT_HELP_MULTIPLE_KEY, 'primary');
          }
        }
        // プリセット変更
        // objectPresets = useObjectPresets(mode);
      });

      const unitOfSpace = ref('帖');
      const showSpace = ref(true);

      /**
       * WATCH:部屋の広さ関連
       */
      watch([unitOfSpace, showSpace], () => {
        PvFloor.setSpaceParam(objectTree.value, unitOfSpace.value, showSpace.value);
      });

      const { floorMaterials, isLoadingFloorMaterials } = useFloorMaterials();
      const { wallMaterials, isLoadingWallMaterials } = useWallMaterials();

      const iconImages = useIconImages();
      const { iconCategoryList } = iconImages;
      const { isLoadingIconImages } = iconImages;

      const { handleTree, resizeHandleFlg } = useHandle({
        selectedObjects: objectSelector.selectedObjects,
      });

      const measure = useMeasure({
        objectTree,
      });
      const { measureTree } = measure;

      const actionManager = new ActionManager({
        onAfterComplete: () => {
          historyController.saveHistory();
        },
        onAfterCompleteOrAbort: () => {
          guideRoot.value.removeChildrenAll();
        },
      });

      const screenPos = ref(new Vec()); // DATA: 画面位置
      const contextMenuPos = ref(new Vec(0, 0)); // DATA: 右クリックメニュー位置
      const isContextMenuShown = ref(false); // DATA: 右クリックメニューフラグ

      const eventContext = new EventContext({
        getRoot: () => root.value,
        getGuideRoot: () => guideRoot.value,
        getGlobalSettings: () => globalSettings,
        getCurrentPos: () => currentPos.value.clone(),
        getObjectPreset: () => objectPresets.currentPreset.value,
        getSelectedObjects: () => objectSelector.selectedObjects.value,
        moveEditor: editor.moveBy,
        showContextMenu: () => {
          contextMenuPos.value = screenPos.value;
          isContextMenuShown.value = true;
        },
      });

      const eventConverter = useEventConverter({
        mode,
        eventContext,
        actionManager,
        isEditor,
      });

      const save = useSave({
        layersRoot: tree.layersRoot,
        // @ts-ignore
        layerPatterns: planeViewPatterns.value,
        generateImage: print.generateImage,
      });

      const ar = useAr({
        layerWidth,
        layerHeight,
        objectTree,
        root,
        saveHistory: historyController.saveHistory,
        presets,
        save,
      });

      /** @type {import('@vue/composition-api').Ref<Element|null>} */
      const downloadImageModal = ref(null); // DATA: ref名：画像ダウンロードモーダル
      /**
       * METHOD: 画像ダウンロードモーダルオープン
       */
      const openDownloadImageModal = async () => {
        // @ts-ignore
        downloadImageModal.value.show();
        print.generateImage().then(data => {
          print.imageBuf.value = data;
        });
      };
      /**
       * METHOD: 画像ダウンロード
       */
      const downloadImage = async () => {
        root.value.unselectAll();
        await nextTick();
        showLoadingOverlay.value = true;
        await print.generateAndDownloadImage();
        showLoadingOverlay.value = false;
      };

      /**
       * METHOD: モード変更
       */
      const changeMode = (/** @type {string} */ val) => {
        mode.value = val;
      };

      /**
       * METHOD: マテリアル変更
       */
      const changeMaterial = (/** @type {{ name: string; url: any; color: any; }} */ val) => {
        objectPresets.setStyleProperty('imageName', val.name);
        objectPresets.setStyleProperty('imageUrl', val.url);
        objectPresets.setStyleProperty('fill', val.color);
      };

      /**
       * METHOD: 壁タイプ変更
       */
      const changeWallType = (/** @type {keyof WALL_STYLE_PRESETS} */ val) => {
        objectPresets.setStyle(WALL_STYLE_PRESETS[val].clone());
        objectPresets.setProperty({ key: 'subType', value: val });
      };

      /**
       * METHOD: ドアタイプ変更
       */
      const changeDoorType = (/** @type {import('./lib/node/node/doorNode').PvDoor} */ door) => {
        objectPresets.setProperty([
          { key: 'subType', value: door.subType },
          { key: 'code', value: door.code },
          { key: 'additionalInfo', value: door.additionalInfo },
        ]);
      };

      /**
       * METHOD: リスナ用：キーボードイベント
       */
      const keyboardEvent = async (
        /** @type {{ ctrlKey: any; key: string; preventDefault: () => void; }} */ e
      ) => {
        if (!isEditor.value) {
          return;
        } //編集権限が無しの場合
        //ctrl+カーソルキーが押された場合
        if (e.ctrlKey && e.key === 'ArrowRight') {
          selectedObjectsOperation.moveRight();
        } else if (e.ctrlKey && e.key === 'ArrowDown') {
          selectedObjectsOperation.moveDown();
        } else if (e.ctrlKey && e.key === 'ArrowLeft') {
          selectedObjectsOperation.moveLeft();
        } else if (e.ctrlKey && e.key === 'ArrowUp') {
          selectedObjectsOperation.moveUp();
        }
        // コントロールキーが押されていない場合（deleteは例外）
        if (!e.ctrlKey && e.key !== 'Delete') {
          return;
        }
        e.preventDefault();
        switch (e.key) {
          case 'z':
            historyController.undo();
            break;
          case 'Z':
            historyController.redo();
            break;
          case 'a':
            selectedObjectsOperation.selectAll();
            break;
          case 'c':
            selectedObjectsOperation.copy();
            break;
          case 'x':
            selectedObjectsOperation.deleteSelectedObjects();
            break;
          case 's':
            if (!(await save.save())) {
              openSaveModal();
            }
            break;
          // default:
        }
      };

      /**
       * METHOD: リスナ用：ARデバッグ用
       */
      const debugArMeasureEvent = async (/** @type {{ key: string; shiftKey: any; }} */ e) => {
        // if (e.key === '!' && e.shiftKey) {
        //   console.log('debugArMeasureEvent');
        //   // @ts-ignore
        //   await ar.loadAr(testData13);
        // } else if (e.key === '"' && e.shiftKey) {
        //   // @ts-ignore
        //   await ar.loadAr(testData11);
        // } else if (e.key === '#' && e.shiftKey) {
        //   // @ts-ignore
        //   await ar.loadAr(testData12);
        // } else if (e.key === '$' && e.shiftKey) {
        //   // @ts-ignore
        //   await ar.loadAr(testData14);
        // } else if (e.key === '%' && e.shiftKey) {
        //   // @ts-ignore
        //   await ar.loadAr(testData15);
        // } else if (e.key === '^' && e.shiftKey) {
        //   // @ts-ignore
        //   await ar.loadAr(testData16);
        // } else if (e.key === '&' && e.shiftKey) {
        //   // @ts-ignore
        //   await ar.loadAr(testData18);
        // }
      };

      // AR計測終了後、WebView再表示時処理
      /**
       * @param {any} jsonData
       */
      function GetJsonData(jsonData) {
        // @ts-ignore
        postMessage({ type: 'unity', value: jsonData });
      }
      // @ts-ignore
      window.GetJsonData = GetJsonData;

      /**
       * METHOD: リスナ用：toast
       */
      const toast = (/** @type {Event} */ e) => {
        // @ts-ignore
        instance.proxy.$bvToast.toast(e.text, {
          'header-tag': null,
          // @ts-ignore
          title: 'Tips',
          autoHideDelay: 2000,
          // @ts-ignore
          variant: e.variant,
          solid: true,
        });
      };

      /**
       * METHOD: リスナ用：VRからの受信メッセージ分岐処理
       * @param {{
       * origin : string
       * data:{
       *  action: string
       *  message: string
       * }
       * }} event
       */
      const switchPostMessageFromVr = event => {
        if (event.origin === window.location.origin && event.data.action) {
          switch (event.data.action) {
            case 'SyncMessage':
              // 動作確認テスト用
              postMessageForWindow(vrWindow.value, 'SyncMessage', 'TEST MESSAGE!!!');
              break;
            case 'StartInit': {
              // VR用マテリアル情報生成
              const materialInfo = createMaterialInfoForVr([
                floorMaterials.value, // 床マテリアル
                wallMaterials.value, // 壁マテリアル
              ]);
              // マテリアル情報をVRへ送信する
              postMessageForWindow(vrWindow.value, 'MaterialInfo', JSON.stringify(materialInfo));
              break;
            }
            case 'EndInit': {
              // VR画面ローディングフラグ更新
              isLoadingVr.value = false;
              // VRページから初期化完了のメッセージが来たら、読み込ませたいファイルデータを送る
              if (displayLayers.value) {
                const layersForVR = convertLayersForVR(displayLayers.value, save.azimuth.value);
                // console.log('layersForVR ', JSON.stringify(layersForVR));
                postMessageForWindow(vrWindow.value, 'FileData', JSON.stringify(layersForVR));
              }
              break;
            }
            case 'ButtonOn':
              // $("#"+event.data.message).show();
              break;
            case 'ButtonOff':
              // $("#"+event.data.message).hide();
              break;
            case 'FileData':
              break;
            case 'beforeunload':
              // VR画面ローディングフラグ更新
              isLoadingVr.value = true;
              break;
            default:
              break;
          }
        }
      };

      /**
       * METHOD: VR用マテリアル情報作成
       * 各素材からマスタデータ以外を除外し、必要なプロパティのみにする。
       * @param {any[][]} materialsArray 各materialsの配列
       */
      const createMaterialInfoForVr = materialsArray => {
        const result = { materials: [] };
        materialsArray.forEach(materials => {
          materials
            .filter(material => material.sort)
            .map(material => {
              result.materials.push({
                // @ts-ignore
                name: material.name,
                // @ts-ignore
                sort: material.sort,
                // @ts-ignore
                url: material.url,
              });
            });
        });
        return result;
      };

      /**
       * METHOD: VRへメッセージ送信
       * @param {Window} window
       * @param {string} action
       * @param {string} message
       */
      const postMessageForWindow = (window, action, message) => {
        window.postMessage(
          {
            action: action,
            message: message,
          },
          window.location.origin
        );
      };

      /**
       * METHOD: 編集権限の取得
       * 'true' or 'false'
       * @param {any} myUserId
       * @param {any} guestIdList
       * @param {any} ownerId
       * @param {any} userLevel
       */
      const checkRight = (myUserId, guestIdList, ownerId, userLevel = null) => {
        if (userLevel == null) {
          userLevel = getUserAuthLevel();
        }
        const right =
          // @ts-ignore
          (guestIdList != null && guestIdList?.findIndex(v => myUserId === v) >= 0) ||
          ownerId === myUserId ||
          (ownerId == null && guestIdList == null) || // 両方未設定の場合
          userLevel === 'admin';
        return right;
      };

      /**
       * METHOD: ユーザ権限レベル取得
       * 'systemAdmin' or 'admin' or 'member'
       */
      const getUserAuthLevel = () => {
        var userGroup = new Array();
        userGroup = getCurrentInstance()?.proxy.$store.state.user.signInUserSession.accessToken
          .payload['cognito:groups'];
        if (userGroup?.indexOf('systemAdmin') != -1) {
          return 'systemAdmin';
        } else if (userGroup?.indexOf('admin') != -1) {
          return 'admin';
        }
        return 'member';
      };

      onMounted(async () => {
        // MOUNTED: toastリスナ登録
        addEventListener('toast', toast);
        // MOUNTED: keydown keyboardEventリスナ登録
        addEventListener('keydown', keyboardEvent);
        // MOUNTED: keydown debugArMeasureEventリスナ登録
        addEventListener('keydown', debugArMeasureEvent);
        // MOUNTED: VRからのメッセージ受信リスナ
        addEventListener('message', switchPostMessageFromVr, {
          once: false,
          passive: true,
          capture: false,
        });
        // MOUNTED: 平面図データの取得とツリー作成
        const planeView = await save.load();

        // 平面図データを取得できた場合
        if (planeView != null) {
          // ツリー作成
          tree.loadTree(planeView.patterns);
          planeView.patterns.forEach((o, i) => historyController.saveHistory(i));
        }
        layerIndex.value = -1;
        layerIndex.value = 0;

        // MOUNTED: 編集権限があるか判断
        isEditor.value = await checkRight(
          instance?.proxy.$store.state.user.attributes.sub,
          planeView?.guestIdList,
          planeView?.ownerId
        );
        // MOUNTED: 編集権限がない場合、デフォルト設定を「選択モード」に変更
        if (!isEditor.value) {
          mode.value = 'select';
        }
      });

      onUnmounted(() => {
        // UNMOUNTED: toastリスナ解除
        removeEventListener('toast', toast);
        // UNMOUNTED: keydown keyboardEventリスナ解除
        removeEventListener('keydown', keyboardEvent);
        // UNMOUNTED: keydown debugArMeasureEventリスナ解除
        removeEventListener('keydown', debugArMeasureEvent);
        // UNMOUNTED: VRからのメッセージ受信リスナ解除
        removeEventListener('message', switchPostMessageFromVr, false);
      });

      const lengthMenu = ref(null); // DATA: ref名：部屋の単位の設定モーダル
      /**
       * METHOD: 部屋の単位の設定モーダルオープン
       */
      const openLengthMenu = () => {
        // @ts-ignore
        lengthMenu.value.show();
      };

      const gridMenu = ref(null); // DATA: ref名：グリッド設定モーダル
      /**
       * METHOD: グリッド設定モーダルオープン
       */
      const openGridMenu = () => {
        // @ts-ignore
        gridMenu.value.show();
      };

      /** @type {import('@vue/composition-api').Ref<Element|null>} */
      const saveModal = ref(null); // DATA: ref名：保存モーダルィ
      /**
       * METHOD: 保存モーダルオープン
       */
      const openSaveModal = () => {
        // @ts-ignore
        saveModal.value.show();
      };

      const arAnnounceModal = ref(null); // DATA: ref名：ARご案内モーダル

      const vrWindow = ref(); // DATA: VR画面オブジェクト
      const isLoadingVr = ref(false); // DATA: VR画面ローディングフラグ
      /**
       * METHOD: VRオープン
       */
      // @ts-ignore
      const openVrView = async page => {
        if (vrWindow.value && !vrWindow.value.closed && isLoadingVr.value) {
          // ロード中は処理しない
          return;
        }
        // console.log('displayLayers:', displayLayers.value);

        // VR画面確認
        if (!vrWindow.value || vrWindow.value.closed) {
          // 存在しない場合、別タブで開く
          let resolvedRoute = await router.resolve({ name: page, params: { id: 'someData' } });
          vrWindow.value = window.open(resolvedRoute.href);
          // VR画面ローディングフラグ更新
          isLoadingVr.value = true;
        } else {
          // 存在する場合、平面図データを送信する
          const layersForVR = convertLayersForVR(displayLayers.value, save.azimuth.value);
          postMessageForWindow(vrWindow.value, 'FileData', JSON.stringify(layersForVR));
          // console.log('JSON.stringify(layersForVR):', JSON.stringify(layersForVR));
          return;
        }
      };

      /**
       * METHOD: AR計測アプリ開始
       */
      const startMeasurement = async () => {
        const result = callUnity(
          CALL_COMMAND.SEND_INTERIOR_MASTER,
          interiorMaster.interiorMasterDataForAR.value
        );
        if (!result) {
          // @ts-ignore
          await arAnnounceModal.value.show();
        }
      };

      /** @type {import('@vue/composition-api').Ref<Element|null>} */
      const planeViewListModal = ref(null); // DATA: ref名：オートスケッチ一覧モーダル
      /**
       *
       * METHOD: オートスケッチ一覧モーダル
       */
      const openPlaneViewListModal = async () => {
        isPlaneViewListModal.value = true;
        // @ts-ignore
        await planeViewListModal.value.show();
      };

      /** @type {import('@vue/composition-api').Ref<Element|null>} */
      const patternLayerSettingModal = ref(null); // DATA: ref名：パターン・階層設定モーダル
      /**
       * METHOD: パターン・階層設定オープン
       */
      const openPatternLayerSettingModal = () => {
        // @ts-ignore
        patternLayerSettingModal.value.show();
      };

      /**
       * COMPUTED: ローディングフラグ
       */
      const isLoading = computed(() => {
        return isLoadingFloorMaterials.value || isLoadingIconImages.value;
      });

      /**
       * METHOD: 戻る
       */
      const back = () => {
        history.back();
      };

      const appendIcon = useAppendIcon({
        zoom: editor.zoom,
        editorX: editor.editorX,
        editorY: editor.editorY,
        root,
        iconImages: iconImages.iconImages,
        iconCategoryList: iconCategoryList,
        saveHistory: historyController.saveHistory,
      });

      /**
       * METHOD: マウスダウン・グローバル
       */
      const mousedownGlobal = (/** @type {MouseEvent} */ e) => {
        appendIcon.mousedown(e);
      };
      /**
       * METHOD: マウスアップ・グローバル
       */
      const mouseupGlobal = (/** @type {MouseEvent} */ e) => {
        appendIcon.mouseup(e);
      };
      /**
       * METHOD: マウスムーブ・グローバル
       */
      const mousemoveGlobal = (/** @type {MouseEvent} */ e) => {
        appendIcon.mousemove(e);
        screenPos.value = new Vec(e.clientX, e.clientY);
      };

      return {
        // インポート？
        ...historyController,
        ...editor,
        ...objectPresets,
        ...tree,
        ...toRefs(globalSettings),
        ...guideTree,
        ...selectedObjectsOperation,
        ...eventConverter,
        ...objectSelector,
        ...iconImages,
        ...iconCategoryList,
        ...measure,
        ...ar,
        ...save,
        ...print,
        appendIcon,

        // フラグ
        showLoadingOverlay,
        isLoadingFloorMaterials,
        isLoadingWallMaterials,
        isContextMenuShown, // 右クリックメニューフラグ
        resizeHandleFlg,
        isPlaneViewListModal, // オートスケッチ一覧モーダルフラグ
        showWaterMark, // ウォーターマーク表示フラグ
        isARSimulatorAppLicense, // ARシミュレーターアプリライセンスフラグ
        isVRSimulatorLicense, // VRシミュレーターライセンスフラグ
        isLoading,
        isEditor, //  平面図編集権限
        showSpace, // 部屋の広さ表示フラグ
        isDisplayLower, // 下階表示フラグ

        // ref名
        targetElement, // ref名：エディタ編集領域
        lengthMenu, // ref名：部屋の単位の設定モーダル
        gridMenu, // ref名：グリッド設定モーダル
        arAnnounceModal, // ref名：ARご案内モーダル
        planeViewListModal, // ref名：オートスケッチ一覧モーダル
        saveModal, // ref名：保存モーダル
        downloadImageModal, // ref名：画像ダウンロードモーダル
        patternLayerSettingModal, // ref名：パターン・階層設定モーダル

        // モーダルオープン
        openLengthMenu, // 部屋の単位の設定モーダルオープン
        openGridMenu, // グリッド設定モーダルオープン
        startMeasurement, // AR計測アプリ開始
        openPlaneViewListModal, // オートスケッチ一覧モーダルオープン
        openSaveModal, // 保存モーダルオープン
        openDownloadImageModal, // 画像ダウンロードモーダルオープン
        openVrView, // VRオープン
        openPatternLayerSettingModal, // パターン・階層設定モーダルオープン

        // マウスイベント
        mousedownGlobal, // マウスダウン・グローバル
        mouseupGlobal, // マウスアップ・グローバル
        mousemoveGlobal, // マウスムーブ・グローバル
        draggedIcon: appendIcon.draggedIcon,
        draggedIconPosition: appendIcon.draggedIconPosition,
        dragedIconWidth: appendIcon.dragedIconWidth,
        dragedIconHeight: appendIcon.dragedIconHeight,

        // リスト
        handleTree,
        measureTree,
        floorMaterials,
        wallMaterials,
        doorWindowList, // ドア・窓リスト
        stairList, // 階段リスト
        planeViewPatterns, // 平面図パターンリスト

        // その他変数
        GUIDE_OPACITY,
        selectMenuMode,
        windowHeight,
        contextMenuPos, // 右クリックメニュー位置
        unitOfSpace, // 部屋の広さの単位
        layerBtnSize, // 階層ボタンサイズ
        patternLayerBtnCSS,

        // その他メソッド
        changeDoorType, // ドアタイプ変更
        changeWallType, // 壁タイプ変更
        changeMode, // モード変更
        changeMaterial,
        downloadImage, // 画像ダウンロード
        patternLayerCSS,
        back, // 戻る
        updateLayers,
        addPattern,
        renamePattern,
        splicePatterns,
        historyController,
      };
    },
  });
</script>
<style scoped>
  #pv-lower-tree {
    opacity: 0.3;
    pointer-events: none;
  }
  .editor-root {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  .middle-area {
    display: flex;
    position: relative;
    user-select: none;
  }
  .loading-overlay {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    text-align: center;
    line-height: 100vh;
  }
  .pv-context-menu {
    width: 150px;
    font-size: 12px;
    z-index: 100;
    border-radius: 5px;
    padding: 5px 0px;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
    background-color: white;
    position: fixed;
  }
  .pattern-change-btn {
    position: absolute;
    right: var(--pulldown-right);
    bottom: var(--pulldown-bottom);
    display: flex;
  }
  .layer-change-btn {
    position: absolute;
    right: var(--layer-btn-right);
    bottom: var(--btn-bottom);
    display: flex;
  }
  .pattern-layer-setting-btn {
    position: absolute;
    right: var(--setting-btn-right);
    bottom: var(--btn-bottom);
    display: flex;
  }
</style>
<style>
  .pv-config-label {
    font-size: 12px;
    font-weight: bold;
    user-select: none;
  }
  .material-setting .pv-config-label {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .editor-btn {
    margin-left: 5px;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }
  .pattern-change-btn select.theme-color-select {
    background-color: rgba(var(--colorThemeRGB), 1);
    border: solid 1px rgba(var(--colorThemeRGB), 1);
    color: white;
    font-weight: bold;
    border-radius: 0.5rem;
  }
</style>
