<!--
作成者：Lee HyeonSeok
作成日：2020.6.12
画面名：日付入力フォーム
機能：日付を入力する
親子関係：
更新者：NakatsukaDaisuke
①
更新内容：西暦和暦の切り替え追加
①
-->
<template>
  <validation-provider :name="title" :rules="rules" v-slot="{ valid, errors }">
    <b-form-group :label-for="name">
      <label style="display: flex;">
        {{ title }}
        <b-form-checkbox class="ml-4" switch @change="yearsChange" style="width: 90px;">西暦/和暦</b-form-checkbox>
        <allyBadge class="mb-1" :mode="hideBadge ? 'View' : mode" :rules="rules" />
      </label>
      <div v-if="yearChange == 'seireki'">
        <b-form-input
          ref="seirekiInput"
          :id="name"
          :readonly="mode == 'View'"
          v-model="result"
          :state="state(valid, errors)"
          :placeholder="placeholder"
          type="date"
          min="1800-01-01"
          max="2999-12-31"
        />
      </div>
      <div v-if="yearChange == 'wareki'" class="col wareki-form">
        <div class=" form-group ">
          <div class="row">
            <div class="container ">
              <div class="col">
                <div class="row">
                  <allySelect
                    style="padding-left: 1px; padding-right: 1px;"
                    :readonly="mode == 'View'"
                    :mode="mode"
                    :items="warekiYear"
                    v-model="selectWarekiData"
                    :hideBadge="true"
                  />
                  <validation-provider :name="title" :rules="rules" v-slot="{ valid, errors }">
                    <div class="col">
                      <div class="row">
                        <div>
                          <b-input-group size="sm">
                            <b-form-input
                              list="datalistOptions"
                              :readonly="mode == 'View'"
                              :id="selectYearData"
                              placeholder="0"
                              v-model="selectYearData"
                              :state="yearList.length >= selectYearData && state(valid, errors)"
                              autocomplete="off"
                              type="number"
                              min="1"
                              :max="yearList.length"
                            />
                            <datalist id="datalistOptions">
                              <option :value="y.text" v-for="y in yearList" :key="y.text" />
                            </datalist>
                            <span class="d-flex align-items-end">年</span>
                          </b-input-group>
                        </div>
                      </div>
                    </div>
                  </validation-provider>
                  <validation-provider :name="title" :rules="rules" v-slot="{ valid, errors }">
                    <div class="col">
                      <div class="row">
                        <div>
                          <b-input-group size="sm">
                            <b-form-input
                              list="monthListOptions"
                              :readonly="mode == 'View'"
                              :id="selectMonthData"
                              placeholder="0"
                              v-model="selectMonthData"
                              :state="monthList.length >= selectMonthData && state(valid, errors)"
                              type="number"
                              min="1"
                              :max="monthList.length"
                            />
                            <datalist id="monthListOptions">
                              <option :value="m.text" v-for="m in monthList" :key="m.text" />
                            </datalist>
                            <span class="d-flex align-items-end">月</span>
                          </b-input-group>
                        </div>
                      </div>
                    </div>
                  </validation-provider>
                  <validation-provider :name="title" :rules="rules" v-slot="{ valid, errors }">
                    <div class="col">
                      <div class="row">
                        <div>
                          <b-input-group size="sm">
                            <b-form-input
                              list="dayListOptions"
                              :readonly="mode == 'View'"
                              :id="selectDayData"
                              placeholder="0"
                              v-model="selectDayData"
                              :state="dayList.length >= selectDayData && state(valid, errors)"
                              type="number"
                              min="1"
                              :max="dayList.length"
                            />
                            <datalist id="dayListOptions">
                              <option :value="d.text" v-for="d in dayList" :key="d.text" />
                            </datalist>
                            <span class="d-flex align-items-end">日</span>
                          </b-input-group>
                        </div>
                      </div>
                    </div>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-form-invalid-feedback :state="state(valid, errors)"
        >{{ dateError }}<br v-if="dateError" />{{ errors[0] }}</b-form-invalid-feedback
      >
    </b-form-group>
  </validation-provider>
</template>

<script>
  export default {
    //name: 物理名
    //mode: View/Edit/Add
    //value: 値
    //title: タイトル
    //loading: ロード中プラグ
    //placeholder: 説明
    //hideBadge: バッジ非表示
    props: ['name', 'mode', 'value', 'title', 'loading', 'rules', 'placeholder', 'hideBadge'],
    data: function() {
      //初期化
      return {
        result: this.value,
        selectWarekiData: null,
        selectYearData: null,
        selectMonthData: null,
        selectDayData: null,
        wareki: null,
        yearList: [],
        monthList: [],
        dayList: [],
        yearChange: 'seireki',
        //無効な日付を判断するためのフラグ
        frag: false,
        dateError: '',
      };
    },
    methods: {
      state: function(valid, errors) {
        // valid status
        if (this.yearChange === 'seireki') {
          if (this.frag && this.$refs.seirekiInput.validity.badInput) {
            this.dateError = '有効な日付を入力してください';
            return false;
          } else {
            this.dateError = null;
            return this.mode == 'View' ||
              (!(this.rules && this.rules.includes('required')) && !(this.rules && this.result))
              ? null
              : errors[0]
              ? false
              : valid
              ? true
              : null;
          }
        } else {
          return this.mode == 'View' ||
            (!(this.rules && this.rules.includes('required')) && !(this.rules && this.result))
            ? null
            : errors[0]
            ? false
            : valid
            ? true
            : null;
        }
      },
      setWarekiYear: function() {
        //現在の年までプルダウンで表示
        var y = new Date();
        var reiwaYear = y.getFullYear() - 2019 + 1;

        this.warekiYear = [
          { text: '明治', value: 'M', len: 45 },
          { text: '大正', value: 'T', len: 15 },
          { text: '昭和', value: 'S', len: 64 },
          { text: '平成', value: 'H', len: 31 },
          { text: '令和', value: 'R', len: reiwaYear },
        ];
      },
      setYear: function(data) {
        //選択した年号の年数を取得する。
        var len = 0;
        this.warekiYear.forEach(element => {
          if (element.value == data) {
            len = element.len;
          }
        });

        //年号がセットされたとき、年月日を設定する。
        this.yearList = [];
        for (let i = 1; i <= len; i++) {
          let date = { text: i, value: i };
          this.yearList.push(date);
        }
        //月セットを呼び出す
        this.setMonth();
      },
      setMonth: function() {
        //月をセットする。
        this.monthList = [];
        this.monthList.push({ text: '1', value: 1, len: 31 });
        this.monthList.push({ text: '2', value: 2, len: 29 });
        this.monthList.push({ text: '3', value: 3, len: 31 });
        this.monthList.push({ text: '4', value: 4, len: 30 });
        this.monthList.push({ text: '5', value: 5, len: 31 });
        this.monthList.push({ text: '6', value: 6, len: 30 });
        this.monthList.push({ text: '7', value: 7, len: 31 });
        this.monthList.push({ text: '8', value: 8, len: 31 });
        this.monthList.push({ text: '9', value: 9, len: 30 });
        this.monthList.push({ text: '10', value: 10, len: 31 });
        this.monthList.push({ text: '11', value: 11, len: 30 });
        this.monthList.push({ text: '12', value: 12, len: 31 });
      },
      setDay: function(data) {
        //選択した月の日数を取得する。
        var len = 0;
        this.monthList.forEach(element => {
          if (element.value == data) {
            len = element.len;
          }
        });

        //日数をセットする。
        this.dayList = [];
        for (let i = 1; i <= len; i++) {
          let date = { text: i, value: i };
          this.dayList.push(date);
        }
      },
      warekiChange: function(dateInput) {
        var _dateInput = new Date(dateInput), // 入力値
          _eras = [
            {
              name: '令和',
              kana: 'れいわ',
              initial: 'R',
              begin: '2019-05-01',
              end: '9999-12-31',
            },
            {
              name: '平成',
              kana: 'へいせい',
              initial: 'H',
              begin: '1989-01-08',
              end: '2019-04-30',
            },
            {
              name: '昭和',
              kana: 'しょうわ',
              initial: 'S',
              begin: '1926-12-25',
              end: '1989-01-07',
            },
            {
              name: '大正',
              kana: 'たいしょう',
              initial: 'T',
              begin: '1912-07-30',
              end: '1926-12-24',
            },
            {
              name: '明治',
              kana: 'めいじ',
              initial: 'M',
              begin: '1868-10-23',
              end: '1912-07-29',
            },
          ];
        // filterで絞り込み
        return _eras.filter(function(item) {
          var _dateBegin = new Date(item.begin + ' 00:00:00 +0900'),
            _dateEnd = new Date(item.end + ' 23:59:59 +0900'),
            _year = 0;

          // Date.getTimeを使って開始後かつ終了前を判定
          if (
            _dateInput.getTime() >= _dateBegin.getTime() &&
            _dateInput.getTime() <= _dateEnd.getTime()
          ) {
            // 年数を計算
            _year = _dateInput.getFullYear() - _dateBegin.getFullYear() + 1;

            // 1年なら元年にしてプロパティに追加
            item.year = _year;

            return item;
          }
        })[0];
      },
      yearsChange: function() {
        //切り替え
        if (this.yearChange == 'seireki') {
          this.yearChange = 'wareki';
          this.frag = false;
        } else {
          this.yearChange = 'seireki';
        }

        //切り替え時、西暦データから和暦データ作成
        if (this.result) {
          this.setSeirekiToWareki(this.result);
        }
      },
      setSeirekiToWareki: function(seirekiDate) {
        if (seirekiDate != null) {
          //西暦を和暦データに変換
          var wareki = this.warekiChange(seirekiDate);
          var date = new Date(seirekiDate);

          if (wareki != null) {
            //変換した和暦データをセット
            this.selectWarekiData = wareki.initial;
            this.selectYearData = String(wareki.year);
            this.selectMonthData = String(date.getMonth() + 1);
            this.selectDayData = String(date.getDate());
          }
        }
      },
      setWarekiToSeireki: function(wareki, year, month, day) {
        //和暦を西暦に変換する。
        var str = null;
        var yersNum = Number(year);
        if (wareki != null && yersNum != null) {
          if (wareki == 'M' && yersNum > 0 && yersNum <= 45) {
            //明治
            str = yersNum + 1868 - 1;
          } else if (wareki == 'T' && yersNum > 0 && yersNum <= 15) {
            //大正
            str = yersNum + 1912 - 1;
          } else if (wareki == 'S' && yersNum > 0 && yersNum <= 64) {
            //昭和
            str = yersNum + 1926 - 1;
          } else if (wareki == 'H' && yersNum > 0 && yersNum <= 31) {
            //平成
            str = yersNum + 1989 - 1;
          } else if (wareki == 'R' && yersNum > 0 && yersNum <= 99) {
            //令和
            str = yersNum + 2019 - 1;
          }
        }
        //形式を合わせて西暦データにセット
        //全て入力されているとき、処理を行う
        if (wareki != null && year != null && month != null && day != null) {
          var result = str + '-' + ('00' + month).slice(-2) + '-' + ('00' + day).slice(-2);
          this.result = result;
        }
      },
      //和暦を変更したとき、変更後の和暦が対応していないとき、その和暦の最大値にする。
      yearOverSet: function() {
        if (this.yearList.length < this.selectYearData) {
          this.selectYearData = this.yearList.length.toString();
        }
      },
    },
    mounted: function() {
      //フォーマットを調整 YYYY-MM-DDに調整
      var date = new Date(this.result);
      if (this.result != null)
        this.result =
          date.getFullYear() +
          '-' +
          ('0' + (date.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + date.getDate()).slice(-2);
      if (this.result == 'NaN-aN-aN') this.result = null;
      //和暦のプルダウンセット
      this.setWarekiYear();
    },
    watch: {
      value: function() {
        this.result = this.value;
      },
      result: function() {
        if (this.result === '') {
          this.result = null;
        }
        this.frag = true;

        // 値を変更すると値を返上
        this.$emit('input', this.result);

        //西暦表示の時のみ処理をする。
        if (this.yearChange == 'seireki') {
          this.setSeirekiToWareki(this.result);
        }
      },
      loading: function() {
        // ロードが終わったら値を初期化
        if (this.loading === false) this.result = this.value;
      },
      selectWarekiData: function() {
        //和暦が変更されたときに処理
        this.setYear(this.selectWarekiData);
        if (this.yearChange == 'wareki') {
          this.setWarekiToSeireki(
            this.selectWarekiData,
            this.selectYearData,
            this.selectMonthData,
            this.selectDayData
          );
        }
        this.yearOverSet();
      },
      selectYearData: function() {
        //年が変更されたときに処理
        if (this.yearChange == 'wareki') {
          this.setWarekiToSeireki(
            this.selectWarekiData,
            this.selectYearData,
            this.selectMonthData,
            this.selectDayData
          );
        }
      },
      selectMonthData: function() {
        //月が変更された時の処理
        this.setDay(this.selectMonthData);
        if (this.yearChange == 'wareki') {
          this.setWarekiToSeireki(
            this.selectWarekiData,
            this.selectYearData,
            this.selectMonthData,
            this.selectDayData
          );
        }
      },
      selectDayData: function() {
        //日が変更されたときの処理
        if (this.yearChange == 'wareki') {
          this.setWarekiToSeireki(
            this.selectWarekiData,
            this.selectYearData,
            this.selectMonthData,
            this.selectDayData
          );
        }
      },
    },
  };
</script>
<style scoped>
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .wareki-form input::-webkit-calendar-picker-indicator {
    display: none;
  }
  .wareki-form .input-group {
    background-color: rgba(0, 0, 0, 0);
    height: calc(1.5em + 0.75rem + 2px);
  }
  .wareki-form .input-group > span {
    padding-bottom: 0.3rem;
  }
  .wareki-form .input-group > .form-control {
    height: 100%;
  }
</style>
