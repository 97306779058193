// @ts-check

import { Vec } from '../../util/math/vector';
import { PvObject } from './pvObject';

export class PvPrintArea extends PvObject {
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'printArea';
  paperType = 'A4';
  constructor() {
    super();
  }

  /**
   * @returns {number}
   */
  get paperRatio() {
    if (this.paperType === 'A4') {
      return 297 / 210;
    } else {
      return 1;
    }
  }

  /**
   *
   * @param {number} index
   * @param {Vec} vec
   */
  moveVertexBy(index, vec) {
    const vertexes = this.vertexes;
    let w, h;

    if (index === 0) {
      w = vertexes[1].x - vec.x;
      h = w / this.paperRatio;
      this.moveBy(new Vec(vec.x, vertexes[2].y - h));
    } else if (index === 1) {
      w = vertexes[1].x + vec.x;
      h = w / this.paperRatio;
      this.moveBy(new Vec(0, vertexes[2].y - h));
    } else if (index === 2) {
      w = vertexes[1].x + vec.x;
      h = w / this.paperRatio;
    } else if (index === 3) {
      w = vertexes[1].x - vec.x;
      h = w / this.paperRatio;
      this.moveBy(new Vec(vec.x, 0));
    }
    this.vertexes = [new Vec(), new Vec(w, 0), new Vec(w, h), new Vec(0, h)];
  }
}
