// @ts-check

import { getCurrentInstance } from '@vue/composition-api';

export const useRouter = () => {
  const instance = getCurrentInstance();
  if (instance == null) {
    throw new Error('');
  }
  const router = instance.proxy.$router;
  const { planeId } = instance.proxy.$route.params;
  const { propertyId, roomId } = instance.proxy.$route.query;
  return {
    router,
    planeId,
    propertyId,
    roomId,
  };
};
