<template>
  <span :style="styles">
    <div class="text-box" @click="getMaterialSearch(searchMessage)">
      <b-form-input v-model="searchMessage" />
    </div>
    <div class="outer-frame" v-if="searchFlag">
      <div
        v-for="colorData of colorSearchResult"
        :key="colorData[0]"
        class="inner-frame"
        :class="{ active: activeData == colorData[1] }"
        @click="getClickColor(colorData)"
      >
        <div class="color-box" :style="'background:' + colorData[1] + ';'"></div>
        <div style="text-align:center;">{{ colorData[0] }}</div>
      </div>
      <div
        v-for="materialData of materialSearchResult"
        :key="materialData.fileName"
        class="inner-frame"
        :class="{ select: activeMaterial == materialData[0] }"
        @click="materialClick(materialData[1], materialData[0])"
      >
        <div class="image-box">
          <img class="image" :src="materialData[1]" ref="images" crossorigin="anonymous" />
        </div>
        <div style="text-align:center;">{{ materialData[0] }}</div>
      </div>
    </div>
  </span>
</template>
<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { getSystemMaster, getS3Url } from '@/graphql/queries';

  export default {
    //outerWidth : 検索結果の表示窓のサイズ
    //outerLeft : 検索結果の窓の位置を左右にずらす
    //boxSize : 結果の１要素の横幅を指定
    //activeData : 現在選択中のカラーコードを指定
    //activeMaterial : 現在選択中のマテリアル名を指定
    props: ['outerWidth', 'outerLeft', 'boxSize', 'activeData', 'activeMaterial'],
    data: function() {
      return {
        colorData: [],
        materialData: [],
        searchMessage: '',
        colorSearchResult: [],
        materialSearchResult: [],
        searchFlag: false,
      };
    },
    async created() {},
    mounted: async function() {
      //日塗工コードを取得する
      let result = await API.graphql(
        graphqlOperation(getSystemMaster, {
          classification: 'materialImage',
          classificationDetail: '3',
        })
      );
      let data = result.data.getSystemMaster.value;
      data.shift();
      this.colorData = data;

      //素材一覧を取得する
      let materialResult = await API.graphql(
        graphqlOperation(getSystemMaster, {
          classification: 'materialImage',
          classificationDetail: '1',
        })
      );
      let data2 = materialResult.data.getSystemMaster.value;
      data2.shift();
      this.materialData = await this.getImageData(data2);

      document.addEventListener('click', e => {
        if (e.target.closest('.text-box')) {
          this.getMaterialSearch(this.searchMessage);
        } else {
          this.searchFlag = false;
        }
      });
    },
    computed: {
      //outer-frameの位置を合わせる
      styles() {
        //初期値
        let width = '200';
        let left = '0';
        let size = '75';

        //propsがある場合
        if (this.outerWidth != null) width = this.outerWidth;
        if (this.outerLeft != null) left = this.outerLeft;
        if (this.boxSize != null) size = this.boxSize;

        return {
          '--width': width + 'px',
          '--left': left + 'px',
          '--size': size + 'px',
          '--sizeh': Number(size) + 20 + 'px',
        };
      },
    },
    watch: {
      searchMessage: {
        handler: function() {
          this.getMaterialSearch(this.searchMessage);
        },
      },
    },
    methods: {
      /**
       * 検索文字列を入力する。ヒットしたデータを返却する
       * @param {String} searchMessage 検索文言
       * @returns 検索結果を出力
       * [
       *  [ 日塗工コード, カラーコード],[ 日塗工コード, カラーコード],
       * ]
       */
      getMaterialSearch(searchMessage) {
        this.searchFlag = false;
        this.colorSearchResult = [];
        this.materialSearchResult = [];
        if (searchMessage == '' || searchMessage == null) {
          this.colorSearchResult = this.colorData.concat();
          this.materialSearchResult = this.materialData.concat();
          this.searchFlag = true;
        }

        //前方一致
        //let regexp = new RegExp('^' + searchMessage + '(.*?)', 'g');
        //部分一致
        let regexp = new RegExp(searchMessage + '(.*?)', 'g');

        this.colorSearchResult = this.colorData.filter(val => val[0].match(regexp));
        this.materialSearchResult = this.materialData.filter(val => val[0].match(regexp));
        this.searchFlag = true;
      },
      getNttoColor() {
        let result = this.colorData.concat();
        this.colorSearchResult = result;
        return result;
      },
      /**
       * カラーをクリックするとクリックデータを返却する
       * @param {} //カラー情報
       */
      getClickColor(getColor) {
        this.$emit('getClickColor', getColor);
      },
      /**
       * 素材情報をクリックした時の処理
       */
      materialClick: function(url, name) {
        this.$emit('materialClick', url, name);
      },
      getImageData: async function(datas) {
        let links = [];

        for (let data of datas) {
          links.push({ dir: 'Simulate/' + data[2] });
        }

        //getS3にアクセス
        //ファイル名だけの時
        // S3からイメージURLを受けてくる
        var s3Result = await API.graphql(
          graphqlOperation(getS3Url, {
            level: 'Master',
            file: links.map(x => {
              return {
                fileName: x.dir,
                fileAction: 'View',
              };
            }),
          })
        );
        let newData = [];
        s3Result.data.getS3Url.forEach((image, key) => {
          newData.push([datas[key][0], image.fileUrl.replace(/\?.*/, '')]);
        });
        return newData;
      },
    },
  };
</script>

<style scoped>
  .outer-frame {
    display: block;
    background: black;
    color: white;
    border: 2px solid white;
    /*
    background: white;
    color: black;
    border: 2px solid black;
    */
    position: absolute;
    left: var(--left);
    z-index: 3;
    width: var(--width);
    padding: 10px 5px;
    overflow-y: scroll;
    height: 85%;
  }
  .inner-frame {
    float: left;
    width: var(--size);
    height: var(--sizeh);
    border: 1px solid;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
  }
  .inner-frame:hover {
    background: darkgrey;
  }
  .inner-frame.active {
    background: rgb(121, 109, 0);
  }
  .inner-frame.select {
    background: rgb(121, 109, 0);
  }

  .color-box {
    border: 1px solid black;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .image-box {
    overflow: hidden;
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
  }
</style>
