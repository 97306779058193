<template>
  <div>
    <ValidationObserver ref="ROAinput">
      <b-form-group>
        <div class="container">
          <b-row>
            <h4>基本情報</h4>
          </b-row>
          <div class="row">
            <allyText
              class="col-md-4"
              title="名称"
              name="item-inheritanceTaxBase-assetsRealEstate-salseObjectPoint-name"
              v-model="item.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.name"
              rules="required"
            />
            <allyTextArea
              class="col-md-4"
              title="所在"
              name="item-inheritanceTaxBase-assetsRealEstate-salseObjectPoint-address"
              v-model="item.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.address"
              max_rows="3"
              rules="required"
            />
            <allyText
              class="col-md-4"
              title="地番"
              name="item-inheritanceTaxBase-assetsRealEstate-salseObjectPoint-lotNum"
              v-model="item.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.lotNum"
              rules="required"
            />
            <allyText
              class="col-md-4"
              title="利用状況"
              name="item-inheritanceTaxBase-assetsRealEstate-salseObjectPoint-usageSituation"
              v-model="item.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.usageSituation"
              rules="required"
            />
            <allyText
              class="col-md-4"
              title="土地面積"
              append="㎡"
              name="item-inheritanceTaxBase-assetsRealEstate-salseObjectPoint-landSize"
              v-model="item.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.landSize"
              type="number"
              separation
              rules="required"
              :floatLength="2"
              roundType="down"
            />
            <allyText
              class="col-md-4"
              title="建物面積"
              append="㎡"
              name="item-inheritanceTaxBase-assetsRealEstate-salseObjectPoint-buildingSize"
              v-model="item.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.buildingSize"
              type="number"
              separation
              rules="required|decimals"
              :floatLength="2"
              roundType="down"
            />
          </div>
          <hr class="ally-divide" />
          <div class="row">
            <div class="col-md-5">
              <b-row>
                <h4>貸借対照表（B/S)</h4>
              </b-row>
              <b-row>
                <label class="font-weight-bold">
                  <br />&emsp;価格（相続税評価ベース または 取得価格ベース）</label
                >
              </b-row>
            </div>
            <div class="col-md-7" style="text-align: right;">
              <b-row>
                <label style="font-weight:normal;font-size:0.875rem;">
                  ※路線価・地区区分の検索が必要な場合は下記をご覧ください。
                </label>
              </b-row>
              <b-row style="margin: 0px">
                <b-col>
                  <a
                    href="https://www.chikamap.jp/chikamap/"
                    target="_blank"
                    style="margin-right : 5px;"
                  >
                    <img src="../../images/ChikaMap_M.gif" />
                  </a>
                  <a href="https://www.rosenka.nta.go.jp/index.htm" target="_blank">
                    <img src="../../images/bannerForLink.jpg" />
                  </a>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="row">
            <allyText
              class="col-md-6"
              title="土地"
              name="item-inheritanceTaxBase-assetsRealEstate-bs-inheritanceBasePrice-land"
              v-model="item.inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice.land"
              append="円"
              separation
              type="number"
              rules="required|min_value:0"
              :tips="
                '(相続税評価ベースの場合)<br />&emsp;相続税評価額。<br />&emsp;相続税路線価を確認したい場合は、右上のバナーから<br /><br />(取得価格ベースの場合)<br />&emsp;実際に取得した際の本体価格を入力してください。'
              "
              roundType="down"
            />
            <allyText
              class="col-md-6"
              title="建物"
              name="item-inheritanceTaxBase-assetsRealEstate-bs-inheritanceBasePrice-building"
              v-model="item.inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice.building"
              append="円"
              separation
              type="number"
              rules="required|min_value:0"
              :tips="
                '(相続税評価ベースの場合)<br />&emsp;相続税評価額。<br />&emsp;建物は固定資産税評価額を基に計算します。<br />&emsp;①居住用利用のもの＝固定資産税評価額×1.0<br />&emsp;②第三者に貸していた場合＝固定資産税評価額×（1-借家権割合）<br />&emsp;③賃貸アパートの場合＝固定資産税評価額×（1-借家権割合×賃貸割合）<br /><br />(取得価格ベースの場合)<br />&emsp;実際に取得した際の本体価格を入力してください。'
              "
              roundType="down"
            />
          </div>
          <div class="row">
            <label class="col-12 font-weight-bold"> 価格（実勢価格ベース） </label>
          </div>
          <div class="row">
            <allyText
              class="col-md-6"
              title="土地"
              name="item-prevailingPriceBase-prevailingPrice-land"
              v-model="item.prevailingPriceBase.prevailingPrice.land"
              append="円"
              separation
              rules="required|min_value:0"
              type="number"
              tips="売却する場合の現在価格"
              roundType="down"
            />
            <allyText
              class="col-md-6"
              title="建物"
              name="item-prevailingPriceBase-prevailingPrice-building"
              v-model="item.prevailingPriceBase.prevailingPrice.building"
              append="円"
              separation
              rules="required|min_value:0"
              type="number"
              tips="売却する場合の現在価格"
              roundType="down"
            />
          </div>
          <hr class="ally-divide" />
          <b-row>
            <h4>年間収支</h4>
          </b-row>
          <div class="row">
            <allyText
              class="col-md-6"
              title="収入"
              name="item-inheritanceTaxBase-assetsRealEstate-pl-income"
              v-model="item.inheritanceTaxBase.assetsRealEstate.pl.income"
              append="円"
              separation
              rules="required|min_value:0"
              type="number"
              roundType="down"
            />
          </div>
          <div class="row">
            <label class="col-12 font-weight-bold"> 支出 </label>
          </div>
          <div class="row">
            <allyText
              class="col-md-6"
              title="土地 固定資産税"
              name="item-inheritanceTaxBase-assetsRealEstate-pl-spending-landPropertyTax"
              v-model="item.inheritanceTaxBase.assetsRealEstate.pl.spending.landPropertyTax"
              append="円"
              separation
              rules="required|min_value:0"
              type="number"
              roundType="down"
            />
            <allyText
              class="col-md-6"
              title="土地 都市計画税"
              name="item-inheritanceTaxBase-assetsRealEstate-pl-spending-landCityPlanningTax"
              v-model="item.inheritanceTaxBase.assetsRealEstate.pl.spending.landCityPlanningTax"
              append="円"
              separation
              rules="required|min_value:0"
              type="number"
              tips="都市計画税が賦課されない場合は、0を入力してください。"
              roundType="down"
            />
          </div>
          <div class="row">
            <allyText
              class="col-md-6"
              title="建物 固定資産税"
              name="item-inheritanceTaxBase-assetsRealEstate-pl-spending-buildingPropertyTax"
              v-model="item.inheritanceTaxBase.assetsRealEstate.pl.spending.buildingPropertyTax"
              append="円"
              separation
              rules="required|min_value:0"
              type="number"
              roundType="down"
            />
            <allyText
              class="col-md-6"
              title="建物 都市計画税"
              name="item-inheritanceTaxBase-assetsRealEstate-pl-spending-buildingCityPlanningTax"
              v-model="item.inheritanceTaxBase.assetsRealEstate.pl.spending.buildingCityPlanningTax"
              append="円"
              separation
              rules="required|min_value:0"
              type="number"
              tips="都市計画税が賦課されない場合は、0を入力してください。"
              roundType="down"
            />
          </div>
          <div class="row">
            <allyText
              class="col-md-6"
              title="管理費"
              name="item-inheritanceTaxBase-assetsRealEstate-pl-spending-maintenanceFee"
              v-model="item.inheritanceTaxBase.assetsRealEstate.pl.spending.maintenanceFee"
              append="円"
              separation
              rules="required|min_value:0"
              type="number"
              tips="管理業者等に支払う維持管理費用"
              roundType="down"
            />
          </div>
          <div class="row">
            <label class="col-12 font-weight-bold"> キャッシュフロー計算 </label>
          </div>
          <div class="row">
            <div class="col-md-6">
              <allyText
                title="借入金(総額)"
                name="item-inheritanceTaxBase-assetsRealEstate-bs-ourstandingDebt"
                v-model="item.inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt"
                append="円"
                separation
                type="number"
                rules="required|min_value:0"
                roundType="down"
              />
            </div>
            <div class="col-md-6">
              <allyText
                title="借入金返済額(年間)"
                name="item-inheritanceTaxBase-assetsRealEstate-cashflowCalc-outstandingDebtTotal"
                :value="calcDebtTotal()"
                append="円"
                separation
                type="number"
                mode="View"
                roundType="down"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <allySelect
                title="返済方法"
                name="item-inheritanceTaxBase-assetsRealEstate-cashflowCalc-repaymentType"
                :items="[
                  { value: 1, text: '元利均等方式' },
                  { value: 2, text: '元金均等方式' },
                ]"
                v-model="item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.repaymentType"
                rules="required"
              ></allySelect>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <allyText
                title="返済期間"
                name="item-inheritanceTaxBase-assetsRealEstate-cashflowCalc-repaymentPeriod"
                v-model="item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.repaymentPeriod"
                append="年"
                type="number"
                rules="required|numeric|min_value:1"
              />
            </div>
            <div class="col-md-6">
              <allyText
                title="借入金利"
                name="item-inheritanceTaxBase-assetsRealEstate-cashflowCalc-borrowRate"
                v-model="item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.borrowRate"
                append="％"
                step="0.1"
                separation
                rules="required|min_value:0.1|max_value:100"
                type="number"
                :floatLength="1"
                roundType="down"
              />
            </div>
          </div>
          <div class="row">
            <allyText class="col-12" title="備考" name="item-comment" v-model="item.comment" />
          </div>
        </div>
      </b-form-group>
    </ValidationObserver>
  </div>
</template>
<script>
  export default {
    props: ['roaInput'],
    data: function() {
      return {
        loading: false,
      };
    },
    computed: {
      item: {
        get() {
          return this.roaInput;
        },
        set() {
          this.$emit('update:roaInput', this.roaInput);
        },
      },
    },
    methods: {
      async validate() {
        return await this.$refs.ROAinput.validate();
      },
      //借入金返済額を計算(年間返済額)
      calcDebtTotal: function() {
        let result = null;
        let year1st = null;
        if (
          this.item.inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt != null &&
          this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.repaymentType != null &&
          this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.repaymentPeriod != null &&
          this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.borrowRate != null
        ) {
          if (this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.repaymentType == 1) {
            //元利均等方式
            result =
              Math.floor(
                (this.item.inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt *
                  Math.floor(
                    this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.borrowRate * 10
                  )) /
                  10 /
                  100 /
                  12 /
                  (1 -
                    Math.pow(
                      1 +
                        Math.floor(
                          this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.borrowRate * 10
                        ) /
                          10 /
                          100 /
                          12,
                      -this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.repaymentPeriod *
                        12
                    ))
              ) *
              this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.repaymentPeriod *
              12;
            year1st = Math.floor(
              result / this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.repaymentPeriod
            );
          } else if (
            this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.repaymentType == 2
          ) {
            //元金均等方式
            for (
              let i = 1;
              i <= this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.repaymentPeriod * 12;
              i++
            ) {
              let sum = Math.floor(
                this.item.inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt /
                  12 /
                  this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.repaymentPeriod +
                  ((this.item.inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt -
                    (this.item.inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt /
                      12 /
                      this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.repaymentPeriod) *
                      (i - 1)) *
                    Math.floor(
                      this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.borrowRate * 10
                    )) /
                    10 /
                    100 /
                    12
              );
              result += sum;
              if (i <= 12) {
                year1st += sum;
              }
            }
          }
        }
        year1st = Math.max(0, year1st);
        this.item.inheritanceTaxBase.assetsRealEstate.cashflowCalc.outstandingDebtTotal = year1st;
        return year1st;
      },
    },
    watch: {
      'item.inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice': {
        handler() {
          this.item.prevailingPriceBase.prevailingPrice.building = Math.floor(
            Math.floor(
              this.item.inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice.building
            ) * 1.25
          );
          this.item.prevailingPriceBase.prevailingPrice.land = Math.floor(
            Math.floor(this.item.inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice.land) *
              1.25
          );
        },
        deep: true,
      },
    },
  };
</script>

<style scoped>
  .ally-divide {
    border: solid 1px black;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
</style>
