<template>
  <div id="InvestmentAnalysisReport">
    <ReportPreview
      :fileTitle="investmentAnalytics ? investmentAnalytics.reportName : ''"
      :customerId="investmentAnalytics ? investmentAnalytics.ownerId : ''"
      :saveS3="false"
      :customerName="investmentAnalytics ? investmentAnalytics.ownerName : ''"
    >
      <template v-slot:default="{ companyInfo }">
        <slide
          ><ReportCover
            :title="investmentAnalytics.reportName"
            :commonInput="{
              reportDate: investmentAnalytics.inputDate,
              customerName: investmentAnalytics.ownerName,
              staffName: investmentAnalytics.staffName,
            }"
            :companyLogo="companyLogoFileUrl"
            :companyInfo="companyInfo"
          />
        </slide>
        <slide>
          <reportInfo commonInput="commonInput"></reportInfo>
        </slide>
        <slide v-if="investmentPlanTotal !== 0"
          ><InvestmentPlanTotal
            :companyLogo="companyLogoFileUrl"
            :companyName="companyName"
            :borrowingConditionsList="borrowingConditionsList"
            :repaymentMethod="repaymentMethod"
            :depositRedemptionTermsList="depositRedemptionTermsList"
            :depositRedemptionTermsTotal="depositRedemptionTermsTotal"
            :totalProjectCostList="totalProjectCostList"
            :totalProjectCostTotal="totalProjectCostTotal"
            :investmentPlanResult="investmentPlanResult"
          />
        </slide>
        <slide
          ><InvestmentAnalyticsGraph
            :companyLogo="companyLogoFileUrl"
            :companyName="companyName"
            :result="investmentAnalyticsResult"
            :yieldResult="yieldResult"
          ></InvestmentAnalyticsGraph>
        </slide>
        <slide>
          <LongTermPayment
            :companyLogo="companyLogoFileUrl"
            :companyName="companyName"
            :result="longTermBalanceGraphResult"
          />
        </slide>
        <slide v-if="investmentPlanResult"
          ><InvestmentPlan1
            :companyLogo="companyLogoFileUrl"
            :companyName="companyName"
            :investmentPlanResult="investmentPlanResult"
            :InvestmentPlanSpendingsList="InvestmentPlanSpendingsList"
          />
        </slide>
        <slide v-if="InvestmentPlanSpendingsList[1]"
          ><InvestmentPlan2
            :companyLogo="companyLogoFileUrl"
            :companyName="companyName"
            :investmentPlanResult="investmentPlanResult"
            :total="investmentPlanTotal"
            :InvestmentPlanSpendingsList="InvestmentPlanSpendingsList"
          />
        </slide>
        <slide v-if="singleYearBalanceIncomeTotal !== 0 && singleYearBalanceSpendingTotal !== 0"
          ><SingleYearPlanTotal
            :companyLogo="companyLogoFileUrl"
            :companyName="companyName"
            :singleYearBalanceResult="singleYearBalanceResult"
          />
        </slide>
        <slide
          ><GainOnSaleTotal
            :companyLogo="companyLogoFileUrl"
            :companyName="companyName"
            :result="gainOnSaleResult"
          />
        </slide>
        <slide
          ><GainOnSaleDetail
            :companyLogo="companyLogoFileUrl"
            :companyName="companyName"
            :result="gainOnSaleResult"
          />
        </slide>
        <template v-for="(y, i) in saleYears || []">
          <slide :key="'b' + i">
            <IncomeAndExpenditure
              :result="incomeAndExpenditureProjectionsCashResult"
              :initialCost="incomeAndExpenditureProjectionsCashInitialCost"
              :start="y.start"
              :end="y.end"
              type="cash:income"
            />
          </slide>
        </template>
        <template v-for="(y, i) in saleYears || []">
          <slide :key="'c' + i">
            <IncomeAndExpenditure
              :result="incomeAndExpenditureProjectionsForTaxPurposesResult"
              :initialCost="incomeAndExpenditureProjectionsCashInitialCost"
              :start="y.start"
              :end="y.end"
              type="tax:income"
            />
          </slide>
        </template>
      </template>
    </ReportPreview>
    <InvestmentPlan hidden ref="investmentPlan" />
    <FinancingPlan hidden ref="financingPlan" />
    <SingleYearBalance hidden ref="singleYearBalance" />
    <Yield hidden ref="yield" />
    <GainOnSale hidden ref="gainOnSale" />
    <InvestmentAnalytics hidden ref="investmentAnalytics" :resultMode="false" />
    <LongTermBalanceGraph hidden ref="longTermBalanceGraph" :resultMode="false" />
    <IncomeAndExpenditureProjectionsCash hidden ref="incomeAndExpenditureProjectionsCash" />
    <IncomeAndExpenditureProjectionsForTaxPurposes
      hidden
      ref="incomeAndExpenditureProjectionsForTaxPurposes"
    />
  </div>
</template>

<script>
  import ReportPreview from '@/components/ExportReport/ReportPreview';
  import ReportCover from '@/components/ExportReport/ReportCover';
  import InvestmentPlanTotal from './Pages/InvestmentPlanTotal';
  import InvestmentPlan1 from './Pages/InvestmentPlan1';
  import InvestmentPlan2 from './Pages/InvestmentPlan2';
  import FinancingPlan1 from './Pages/FinancingPlan1';
  import SingleYearPlanTotal from './Pages/SingleYearPlanTotal';
  import Yield1 from './Pages/Yield1';
  import reportInfo from './Pages/Intro';
  import GainOnSaleTotal from './Pages/GainOnSaleTotal';
  import GainOnSaleDetail from './Pages/GainOnSaleDetail';
  import IncomeAndExpenditure from './Pages/IncomeAndExpenditure';
  import LongTermPayment from './Pages/LongTermPayment';
  import InvestmentAnalyticsGraph from './Pages/InvestmentAnalyticsGraph';
  import InvestmentPlan from '../Result/InvestmentPlan';
  import FinancingPlan from '../Result/FinancingPlan';
  import SingleYearBalance from '../Result/SingleYearBalance';
  import Yield from '../Result/Yield';
  import GainOnSale from '../Result/GainOnSale';
  import InvestmentAnalytics from '../Result/InvestmentAnalytics';
  import LongTermBalanceGraph from '../Result/LongTermBalanceGraph';
  import IncomeAndExpenditureProjectionsCash from '../Result/IncomeAndExpenditureProjectionsCash';
  import IncomeAndExpenditureProjectionsForTaxPurposes from '../Result/IncomeAndExpenditureProjectionsForTaxPurposes';

  export default {
    props: {
      commonInput: Object,
    },
    components: {
      reportInfo,
      // Result
      InvestmentPlan,
      FinancingPlan,
      SingleYearBalance,
      Yield,
      GainOnSale,
      InvestmentAnalytics,
      LongTermBalanceGraph,
      IncomeAndExpenditureProjectionsCash,
      IncomeAndExpenditureProjectionsForTaxPurposes,
      // Report
      ReportPreview,
      ReportCover,
      InvestmentPlanTotal,
      InvestmentPlan1,
      InvestmentPlan2,
      SingleYearPlanTotal,
      GainOnSaleTotal,
      GainOnSaleDetail,
      IncomeAndExpenditure,
      // PaymentPlan,
      LongTermPayment,
      InvestmentAnalyticsGraph,
    },
    data() {
      return {
        investmentAnalyticsInput: null,
        investmentPlanResult: null,
        financingPlanResult: null,
        singleYearBalanceResult: null,
        yieldResult: null,
        gainOnSaleResult: null,
        investmentAnalyticsResult: null,
        longTermBalanceGraphResult: null,
        incomeAndExpenditureProjectionsCashResult: null,
        incomeAndExpenditureProjectionsCashInitialCost: null,
        incomeAndExpenditureProjectionsForTaxPurposesResult: null,
        id: '',
        companyLogoFileUrl: '',
        companyName: '',
        timeOfsale: null,
      };
    },
    computed: {
      InvestmentPlanSpendingsList() {
        const maxLineNum = 25; // １ページの最大行数
        if (this.investmentPlanResult == null) {
          return [];
        }
        const items = this.investmentPlanResult.incidentalExpenses.details;

        let lineCount = 2;
        const resultList = [];
        let tempList = [];
        items.forEach((val, i) => {
          let tmp = val;
          if (val.details == null) {
            let v = {
              name: val.name,
              details: [],
              total: val.total,
            };
            v.details.push(val);
            tmp = v;
          }

          lineCount += tmp.details.length;
          lineCount += 2; // タイトルと小計分

          if (lineCount > maxLineNum) {
            resultList.push(tempList);
            tempList = [tmp];
            lineCount = 2;
          } else {
            tempList.push(tmp);
          }

          // 末尾対応
          if (i == items.length - 1) {
            resultList.push(tempList);
          }
        });

        return resultList;
      },
      saleYears() {
        const length = Math.ceil(this.timeOfsale / 15);
        const amari = this.timeOfsale % 15;
        const result = new Array(length).fill(null).map((_, i) => {
          if (i === length - 1 && amari > 0) {
            return { start: i * 15 + 1, end: i * 15 + amari };
          }
          return { start: i * 15 + 1, end: i * 15 + 15 };
        });
        return result;
      },
      repaymentMethod() {
        if (this.financingPlanResult == null) {
          return null;
        }
        return this.financingPlanResult.borrowingConditions.items[1].unit;
      },
      /**
       * サマリー
       */
      investmentPlanTotal() {
        if (this.investmentPlanResult == null) {
          return 0;
        }
        return this.investmentPlanResult.totalProjectCost;
      },
      borrowingConditionsList() {
        if (this.financingPlanResult == null) {
          return [];
        }
        return this.financingPlanResult.borrowingConditions.items;
      },
      depositRedemptionTermsList() {
        if (this.financingPlanResult == null) {
          return [];
        }
        return this.financingPlanResult.depositRedemptionTerms.items;
      },
      depositRedemptionTermsTotal() {
        if (this.financingPlanResult == null) {
          return 0;
        }
        return this.financingPlanResult.depositRedemptionTerms.items.reduce(
          (pre, cur) => pre.amount + parseFloat(cur.amount)
        );
      },
      totalProjectCostList() {
        if (this.financingPlanResult == null) {
          return [];
        }
        return this.financingPlanResult.totalProjectCost.items;
      },
      totalProjectCostTotal() {
        if (this.financingPlanResult == null) {
          return 0;
        }
        return this.financingPlanResult.totalProjectCost.amount;
      },
      /**
       * 単年度収支（満室想定）
       */
      // 収入の部
      singleYearBalanceIncomeTotal() {
        if (this.singleYearBalanceResult == null) {
          return 0;
        }
        return this.singleYearBalanceResult.income.total;
      },
      singleYearBalanceSpendingTotal() {
        if (this.singleYearBalanceResult == null) {
          return 0;
        }

        return this.singleYearBalanceResult.spending.total;
      },
      /**
       * 売却益
       */
      // 収支
      gainOnSaleTotal() {
        if (this.gainOnSaleResult == null) {
          return 0;
        }

        return this.gainOnSaleResult.total;
      },
      incomeLineNum() {
        if (this.result == null) {
          return 0;
        }
        return this.result.income.items.length;
      },
      spendingList() {
        const maxLineNum = 34; // １ページの最大行数

        if (this.result == null) {
          return [];
        }
        const items = this.result.spending.items;

        let lineCount = this.incomeLineNum;
        const resultList = [];
        let tempList = [];
        items.forEach((val, i) => {
          if (val.items == null) {
            return;
          }
          lineCount += val.items.length;

          if (lineCount > maxLineNum) {
            resultList.push(tempList);
            tempList = [val];
            lineCount = 0;
          } else {
            tempList.push(val);
          }

          // 表示されていなかった末尾対応
          if (i == items.length - 1) {
            resultList.push(tempList);
          }
        });

        return resultList;
      },
    },
    mounted() {
      this.investmentPlanResult = this.$refs.investmentPlan.result;
      this.financingPlanResult = this.$refs.financingPlan.result;
      this.singleYearBalanceResult = this.$refs.singleYearBalance.result;
      this.yieldResult = this.$refs.yield.result;
      this.gainOnSaleResult = this.$refs.gainOnSale.result;
      this.investmentAnalyticsResult = {
        indicators: [
          this.$refs.investmentAnalytics.profitPriceDcf,
          this.$refs.investmentAnalytics.netPresentValue,
          this.$refs.investmentAnalytics.internalRateOfReturn,
          this.$refs.investmentAnalytics.finalCashOnCashReturn,
        ],
        chartStyles: this.$refs.investmentAnalytics.chartStyles,
        chartOptions: this.$refs.investmentAnalytics.chartOptions,
        chartData: this.$refs.investmentAnalytics.chartData,
      };

      this.longTermBalanceGraphResult = {
        chartStyles: this.$refs.longTermBalanceGraph.chartStyles,
        chartOptions: this.$refs.longTermBalanceGraph.chartOptions,
        chartData: this.$refs.longTermBalanceGraph.chartData,
      };
      this.incomeAndExpenditureProjectionsCashResult = this.$refs.incomeAndExpenditureProjectionsCash.result;
      this.incomeAndExpenditureProjectionsCashInitialCost = this.$refs.incomeAndExpenditureProjectionsCash.initialCost;
      this.incomeAndExpenditureProjectionsForTaxPurposesResult = this.$refs.incomeAndExpenditureProjectionsForTaxPurposes.result;
      this.timeOfsale = Math.floor(
        this.$refs.investmentPlan.investmentAnalytics.salePlan.timeOfSale
      );
    },
  };
</script>
<style>
  #InvestmentAnalysisReport {
    padding: 0.5em;
    border-top-color: gainsboro !important;
  }
  #InvestmentAnalysisReport .card {
    border-top-color: gainsboro;
  }
  #InvestmentAnalysisReport .card-result .card-title {
    color: black;
    font-weight: bold;
    font-size: x-large;
    text-align: center;
    text-shadow: 1px 1px 1px gray;
  }
  #InvestmentAnalysisReport .nav-pills .nav-link.active {
    background-color: var(--colorTheme);
    margin-left: 1px;
    margin-right: 1px;
    font-size: large;
    font-weight: bold;
    text-shadow: 1px 1px 1px #000000, -1px 1px 1px #000000, 1px -1px 1px #000000,
      -1px -1px 1px #000000, 1px 0px 1px #000000, 0px 1px 1px #000000, -1px 0px 1px #000000,
      0px -1px 1px #000000;
  }
  #InvestmentAnalysisReport .nav-tabs .nav-link.active {
    color: black;
    font-weight: bold;
    text-align: center;
    text-shadow: 1px 1px 1px gray;
  }
  #InvestmentAnalysisReport .nav-pills .nav-link {
    background-color: gainsboro;
    margin-left: 1px;
    margin-right: 1px;
    font-size: large;
  }
  #InvestmentAnalysisReport > .tabs > .card-header > ul > li {
    margin-bottom: 0;
  }
  #InvestmentAnalysisReport .report .colspan {
    border-left-color: transparent !important;
  }
  #InvestmentAnalysisReport .report .rowspan {
    border-top-color: transparent !important;
  }
  #InvestmentAnalysisReport .report > .row {
    padding: 0;
  }
  #InvestmentAnalysisReport .text-right {
    text-align: right !important;
  }
  #InvestmentAnalysisReport .text-left {
    text-align: left !important;
  }
  #InvestmentAnalysisReport .subtotal > div {
    background-color: rgba(var(--colorThemeRGB), 0.5);
    text-align: right !important;
  }
  #InvestmentAnalysisReport .subtotal > div:first-child {
    border-right: 0 !important;
  }
  #InvestmentAnalysisReport .report > .row:nth-child(n + 2) div,
  #InvestmentAnalysisReport .report span .row div {
    border-top: 1px solid black;
    border-left: 1px solid black;
  }
  #InvestmentAnalysisReport .report > .row:nth-child(n + 2) div:last-child,
  #InvestmentAnalysisReport .report span .row div:last-child {
    border-right: 1px solid black;
  }
  #InvestmentAnalysisReport .report > .row:last-child > div {
    border-bottom: 1px solid black;
  }

  #InvestmentAnalysisReport .title {
    color: white;
    background-color: rgba(var(--colorThemeRGB), 1);

    display: grid;
    text-align: center;
    align-items: center;
  }
  #InvestmentAnalysisReport .subtitle {
    color: black;
    background-color: rgba(var(--colorThemeRGB), 0.5);

    display: grid;
    text-align: left;
    align-items: center;
  }
  #InvestmentAnalysisReport .unit {
    display: grid;
    text-align: center;
    align-items: center;
    padding: 0;
  }
  #InvestmentAnalysisReport .value {
    display: grid;
    text-align: right;
    align-items: center;
  }
  #InvestmentAnalysisReport .card-columns {
    column-count: 2;
  }
  #InvestmentAnalysisReport div:not(id='Yield') .card-columns > .card {
    border: 0;
    box-shadow: none;
  }
  /* http://var.blog.jp/archives/74242827.html */
  * {
    text-decoration-skip-ink: none !important;
  }
  #InvestmentAnalysisReport .emboss {
    text-shadow: 1px 1px 1px #000000, -1px 1px 1px #000000, 1px -1px 1px #000000,
      -1px -1px 1px #000000, 1px 0px 1px #000000, 0px 1px 1px #000000, -1px 0px 1px #000000,
      0px -1px 1px #000000;
  }
  #InvestmentAnalysisReport .nav-item {
    margin-bottom: 2px;
  }
  #InvestmentAnalysisReport #buttons .btn {
    display: grid;
    text-align: center;
    align-items: center;
    border-radius: 0.3rem;
    border: 0;
    margin: 1px;
    font-size: large;
    background-color: gainsboro;
    color: #007bff;
  }
  #InvestmentAnalysisReport #buttons .btn.active {
    font-weight: bold;
    background-color: rgba(var(--colorThemeRGB), 1);
    color: white;
  }
  #InvestmentAnalysisReport #buttons .btn.active > span {
    text-shadow: 1px 1px 1px #000000, -1px 1px 1px #000000, 1px -1px 1px #000000,
      -1px -1px 1px #000000, 1px 0px 1px #000000, 0px 1px 1px #000000, -1px 0px 1px #000000,
      0px -1px 1px #000000;
  }
  #tabs__BV_tab_controls_ {
    display: none;
  }
</style>
