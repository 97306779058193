<template>
  <div>
    <h4 class="font-weight-bold">会社削除リスト</h4>
    <paginationV2
      class="mt-5"
      ref="pagination"
      :headers="headers"
      targetName="DeleteQue"
      :hideDelete="true"
      :hideDetail="true"
      @restoreClicked="e => confirmRestore(e)"
      @detailClicked="detailCompany"
    ></paginationV2>
    <b-modal
      ref="restoreCheck"
      title="確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="復旧"
      @ok="restoreCompany"
    >
      会社情報を復旧しますか？
    </b-modal>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { deleteDeleteQue, updateCompany } from '../../graphql/mutations';

  API.configure(awsconfig);

  export default {
    data: function() {
      return {
        deleteTarget: null,
        companys: {
          body: [],
          title: [],
        },
        isMounted: false,
        headers: [
          { key: 'companyName', label: '会社名' },
          { key: 'tel', label: '電話番号' },
          { key: 'mail', label: 'E-mail' },
          { key: 'deleteFlg', label: '削除フラグ' },
          {
            key: 'detaile',
            label: '詳細',
            button: { event: 'detailClicked', label: '詳細' },
          },
          {
            key: 'status',
            label: '会社情報復旧',
            button: { event: 'restoreClicked', label: '復旧' },
          },
        ],
      };
    },
    methods: {
      confirmRestore(e) {
        this.deleteTarget = e;
        this.$refs.restoreCheck.show();
      },
      restoreCompany: async function() {
        try {
          await API.graphql(
            graphqlOperation(deleteDeleteQue, { input: { id: this.deleteTarget.id } })
          );
          await API.graphql(
            graphqlOperation(updateCompany, {
              input: {
                ...this.deleteTarget,
                deleteDate: null,
                deleteFlg: undefined,
                createdAt: undefined,
                updatedAt: undefined,
              },
            })
          );
        } catch (e) {
          console.error(e);
          alert('会社削除に失敗しました。');
        }

        this.$refs.pagination.listItems();
      },
      detailCompany: function(body) {
        this.$router.push('/DeleteCompany/View/' + body.id);
      },
    },
  };
</script>
