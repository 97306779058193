<template>
  <CommonLayout title="相続税計算">
    <ReportSubTitle subTitle="権利の評価明細計算" />
    <div style="padding: 80px 100px;">
      <div
        v-for="(detailsData, index) in rightEvaluationDetailsList"
        :key="'detailsData' + index"
        style="margin-bottom:60px;"
      >
        <h3 style="margin-bottom:10px; font-size:70px; font-weight:bold;">
          {{ detailsData.getName() }}の評価明細計算
        </h3>
        <div class="row">
          <!-- VIEW: 計算概要 -->
          <div class="col-12">
            <table style="width: 100%; table-layout: fixed;" id="previewCalcOverview">
              <thead>
                <tr style="font-size: 50px">
                  <th width="34%" :style="headerStyle">相続税評価対象のタイプ</th>
                  <th width="20%" :style="headerStyle">
                    {{ detailsData.calcMode == 2 ? '固定資産税評価額(円)' : '1㎡当たりの価額' }}
                  </th>
                  <th width="16%" :style="headerStyle">
                    {{ detailsData.calcMode == 2 ? '倍率' : '地積' }}
                  </th>
                  <th width="30%" :style="headerStyle">総額</th>
                </tr>
              </thead>
              <tbody style="background-color: white">
                <tr style="font-size: 50px;">
                  <td :style="bodyStyle">
                    {{
                      detailsData.calcMode == 2
                        ? '路線価地区区分倍率地域の場合'
                        : '路線価地域の場合'
                    }}
                  </td>
                  <td :style="bodyStylePrice">
                    {{
                      detailsData.resultValue.resultPrice > 0
                        ? detailsData.resultValue.resultPrice.toLocaleString()
                        : 0
                    }}
                    {{ '円' }}
                  </td>
                  <td class="landTaxDetail" :style="bodyStylePrice">
                    {{
                      detailsData.calcMode == 2
                        ? detailsData.landMagnification
                        : detailsData.landSize
                    }}
                    {{ detailsData.calcMode == 2 ? '倍' : '㎡' }}
                  </td>
                  <td :style="bodyStylePrice">
                    {{ detailsData.totalPrice > 0 ? detailsData.totalPrice.toLocaleString() : 0 }}
                    円
                  </td>
                </tr>
                <tr
                  v-if="detailsData.houseFixedAssetTaxFlg === 'true'"
                  style="font-size: 50px;"
                >
                  <td :style="bodyStyle">
                    {{ '家屋（固定資産税評価額）' }}
                  </td>
                  <td :style="bodyStyle"></td>
                  <td :style="bodyStyle"></td>
                  <td :style="bodyStylePrice">
                    {{
                      detailsData.houseFixedAssetTax
                        ? detailsData.houseFixedAssetTax.toLocaleString()
                        : ''
                    }}
                    円
                  </td>
                </tr>
                <tr style="font-size: 50px;">
                  <td :style="bodyStyle">{{ '合計' }}</td>
                  <td :style="bodyStyle"></td>
                  <td :style="bodyStyle"></td>
                  <td :style="bodyStylePrice">
                    {{
                      (
                        parseInt(detailsData.totalPrice) + 
                        parseInt((detailsData.houseFixedAssetTax ? detailsData.houseFixedAssetTax : 0))
                      ).toLocaleString()
                    }}
                    円
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- VIEW: 計算内容 -->
          <div class="col-12">
            <table
              v-if="detailsData.calcMode == 1"
              style="width: 100%; table-layout: fixed;"
              id="previewCalcContent"
            >
              <thead>
                <tr style="font-size: 50px">
                  <th width="70%" :style="headerStyle">土地の評価明細</th>
                  <th width="30%" :style="headerStyle">金額</th>
                  <!--<th>計算記号</th>-->
                </tr>
              </thead>
              <tbody style="background-color: white">
                <tr
                  v-for="row in detailsData.resultValue.calcArray"
                  :key="row.Id"
                  style="font-size: 50px;"
                >
                  <td :style="bodyStyle">
                    {{ row.text }}
                  </td>
                  <td :style="bodyStylePrice">{{ row.resultPrice.toLocaleString() }} 円</td>
                  <!--<td>{{ row.resultPriceType }}</td>-->
                </tr>
                <tr
                  v-if="detailsData.resultValue.calcArray.length <= 0"
                  style="font-size: 50px;"
                >
                  <td colspan="2" :style="bodyStyle">
                    詳細項目がありません
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import ReportSubTitle from '@/components/ExportReport/ReportSubTitle';
  import { headerStyle, bodyStyle, bodyStylePrice } from './style';
  export default {
    props: {
      rightEvaluationDetailsList: {
        type: Array,
        required: true,
      },
    },
    data: function() {
      return {
        listPage: [],
        headerStyle,
        bodyStyle,
        bodyStylePrice,
      };
    },
    components: {
      CommonLayout,
      ReportSubTitle,
    },
    mounted() {},
    watch: {},
  };
</script>
