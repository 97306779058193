// @ts-check
import { makeToast, nextCycleIndex } from '../../../util/util';
import { MESSAGE_TEXT } from '../../../util/const';
import { Vec } from '../../../util/math/vector';
import { Action } from '../action';
import { ActionBuilder } from '../../action/builder/actionBuilder';
import { PvGuideMarker } from '../../../node/node/guideMarkerNode';
import { createNode } from '../../../node/node/pvNode';
import { PvFloor } from '../../../node/node/floorNode';
import { cloneNode } from '../../../node/tools/clone';
import { PvDoor } from '../../../node/node/doorNode';
import { Transform } from '../../../util/math/transform';
import { degToRad, radToDeg } from '../../../util/math/utils';
import { UseJudgeThePointInArea } from '../../../util/judgeThePointInArea';
export class DrawDoorActionBuilder extends ActionBuilder {
  build() {
    const action = new Action();
    action.actionType = Action.ACTION_TYPE.CLICK2;

    const { getGuideRoot, getObjectPreset, getCurrentPos } = this.context;
    const { part, targetObject, index } = this.dataset;

    const guideMarker = createNode(PvGuideMarker);
    const targetFloor = /** @type {PvFloor} */ (targetObject);

    if (targetFloor?.type !== 'floor' || part !== 'edge' || index == null) {
      makeToast(MESSAGE_TEXT.DRAW_HELP_WALL_SELECT);
      return;
    }

    const absVertexes = targetFloor.absoluteVertexes();
    const v1 = absVertexes[index];
    const v2 = absVertexes[nextCycleIndex(index, absVertexes.length)];
    const wallVec = v2.sub(v1);

    // 基準点計算
    const d = getCurrentPos().sub(v1);
    const pj = d.projection(wallVec);
    const pivot = v1.add(pj);

    getGuideRoot().appendChild(guideMarker);

    const guide = cloneNode(/** @type {PvDoor} */ (getObjectPreset()));
    guide.vertexes = [new Vec(), new Vec()];
    guide.transform = new Transform({
      translate: pivot,
      rotate: new Vec(1, 0).angle(wallVec) ?? undefined,
    });
    getGuideRoot().appendChild(guide);

    action.onUpdate = ({ dist }) => {
      const projection = dist.projection(wallVec);
      const direction = projection.isLeft(wallVec.normal()) ? 0 : 180;
      guide.rotateTo(radToDeg(new Vec(1, 0).angle(wallVec) ?? 0) + direction);

      guide.doorWidth = projection.absVal();
    };

    /**
     * FUNC: 建具追加（2クリック）時の処理
     * @param {any} dist // 2クリック目の座標
     */
    action.onComplete = ({ dist }) => {
      const projection = dist.projection(wallVec);

      let door = cloneNode(/** @type {PvDoor} */ (getObjectPreset()));
      // door.vertexes = [new Vec(), new Vec()];
      door.vertexes.splice(0, 2, new Vec(), new Vec(projection.x, projection.y));
      // door.vertexes = [new Vec(), new Vec(projection.x, projection.y)];
      const direction = projection.isLeft(wallVec.normal()) ? 0 : 180;
      door.transform = Transform.fromMatrix(
        targetFloor.getLocalTransform(
          new Transform({
            translate: pivot,
            rotate: (new Vec(1, 0).angle(wallVec) ?? 0) + degToRad(direction),
          })
        )
      );

      // 内外判定の対象座標を求める
      const judgeThePointInArea = new UseJudgeThePointInArea();
      const targetPoint = judgeThePointInArea.createTargetPoint(targetFloor, door);
      // 部屋に対する対称座標の内外判定(true:内開き / false:外開き)
      let isPointInArea = judgeThePointInArea.isPointInArea(targetFloor.vertexes, targetPoint);
      // 判定結果をもとに建具追加情報の取得
      door.additionalInfo = judgeThePointInArea.getAdditionalInfo(
        door.additionalInfo,
        isPointInArea
      );

      door.doorWidth = projection.absVal();
      targetFloor.appendChild(door);
    };

    return action;
  }
}
