<template>
  <CommonLayout title="相続税計算">
    <ReportSubTitle subTitle="一次相続時の相続割合に応じた二次相続時の税額" />
    <div style="padding: 75px 100px;">
      <div style="text-align:right; font-size: 50px;">(単位：万円)</div>
      <table style="width: 100%;" id="calcDetail" v-if="commonInput.family.length > 0">
        <thead>
          <tr>
            <th
              rowspan="2"
              style="vertical-align: middle;"
              :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]"
            >
              割合
            </th>
            <th colspan="3" :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">
              一次相続
            </th>
            <th colspan="3" :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">
              二次相続
            </th>
            <th
              rowspan="2"
              style="vertical-align: middle;"
              :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]"
            >
              総合計
            </th>
          </tr>
          <tr>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">
              {{ pattern.decedent }}
            </th>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">
              {{ pattern.heir }}
            </th>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">合計</th>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">
              {{ pattern.heir }}
            </th>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">控除額※１</th>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">
              合計
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in inheritance"
            :key="row.Id"
            style="font-size: 50px;"
            :style="background(index)"
          >
            <td :style="[originStyle, percentColor(index)]">{{ index * 10 }}％</td>
            <td :style="[originStyle, { 'text-align': 'right', padding: '0 15px' }]">
              {{
                row.decedentInheritanceTax.toLocaleString(undefined, { minimumFractionDigits: 1 })
              }}
            </td>
            <td :style="[originStyle, { 'text-align': 'right', padding: '0 15px' }]">
              {{
                row.heirPrimaryInheritanceTax.toLocaleString(undefined, {
                  minimumFractionDigits: 1,
                })
              }}
            </td>
            <td :style="[originStyle, { 'text-align': 'right', padding: '0 15px' }]">
              {{
                row.totlaPrimaryInheritanceTax.toLocaleString(undefined, {
                  minimumFractionDigits: 1,
                })
              }}
            </td>
            <td :style="[originStyle, { 'text-align': 'right', padding: '0 15px' }]">
              {{
                row.heirSecondaryInheritanceTax.toLocaleString(undefined, {
                  minimumFractionDigits: 1,
                })
              }}
            </td>
            <td :style="[originStyle, { 'text-align': 'right', padding: '0 15px' }]">
              {{
                row.inheritanceDeducition.toLocaleString(undefined, { minimumFractionDigits: 1 })
              }}
            </td>
            <td :style="[originStyle, { 'text-align': 'right', padding: '0 15px' }]">
              {{
                row.totalSecondaryInheritanceTax.toLocaleString(undefined, {
                  minimumFractionDigits: 1,
                })
              }}
            </td>
            <td :style="[originStyle, { 'text-align': 'right', padding: '0 15px' }]">
              {{ row.totalInheritanceTax.toLocaleString(undefined, { minimumFractionDigits: 1 }) }}
            </td>
          </tr>
        </tbody>
      </table>
      <div style="text-align:right; font-size: 50px;">※1…相次相続控除</div>
    </div>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import ReportSubTitle from '@/components/ExportReport/ReportSubTitle';
  import { headerStyle, subHeaderStyle, bodyStyle } from './style';
  export default {
    props: {
      commonInput: {
        type: Object,
        required: true,
      },
      familyInheritanceInfo: {
        type: Array,
        required: true,
      },
      pattern: {
        type: Object,
        required: true,
      },
      inheritance: {
        type: Array,
        required: true,
      },
      minRow: null,
    },
    data: function() {
      return {
        listPage: [],
        headerStyle,
        subHeaderStyle,
        bodyStyle,
        originStyle: {
          border: 'solid black 1px',
          'padding-right': '3px',
          'text-align': 'center',
          padding: '0 4px',
        },
      };
    },
    computed: {
      background() {
        return function(index) {
          if (this.minRow == index) {
            return {
              'background-color': 'lightcoral',
            };
          } else {
            return null;
          }
        };
      },
      percentColor() {
        return function(index) {
          if (this.minRow == index) {
            return {
              'background-color': 'lightcoral',
            };
          } else {
            return {
              'background-color': '#CCCCCC',
            };
          }
        };
      },
    },
    components: {
      CommonLayout,
      ReportSubTitle,
    },
  };
</script>
