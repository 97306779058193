// @ts-check
import { Action } from '../action';
import { createNode } from '../../../node/node/pvNode';
import { PvWall } from '../../../node/node/wallNode';
import { PvGuideMarker } from '../../../node/node/guideMarkerNode';
import { ActionBuilder } from '../../action/builder/actionBuilder';
import { Transform } from '../../../util/math/transform';
import { makeToast } from '../../../util/util';
import { MESSAGE_TEXT } from '../../../util/const';

export class DrawWallActionBuilder extends ActionBuilder {
  build() {
    const action = new Action();
    action.actionType = Action.ACTION_TYPE.CLICK2;

    const { getRoot, getGuideRoot, getObjectPreset, getCurrentPos } = this.context;
    const clickTarget = this.dataset.targetObject;
    const target = clickTarget != null ? clickTarget.findUpByType('floor') : null;

    if (target?.type !== 'floor') {
      makeToast(MESSAGE_TEXT.DRAW_HELP_ROOM_INSIDE);
      return;
    }

    const guideMarker = createNode(PvGuideMarker);
    getGuideRoot().appendChild(guideMarker);

    const guide = createNode(PvWall);
    guide.moveTo(getCurrentPos());
    guide.style = getObjectPreset().style.clone();
    getGuideRoot().appendChild(guide);

    action.onUpdate = ({ first, dist }) => {
      guideMarker.transform = new Transform({
        translate: first,
      });
      guide.deformateFromStartEnd(dist);
    };
    action.onComplete = ({ first, last }) => {
      const wall = PvWall.fromStartEnd(first, last);
      wall.style = getObjectPreset().style.clone();
      wall.height3d = getObjectPreset().height3d;
      if (target) {
        wall.transform = Transform.fromMatrix(target.getLocalTransform(wall.transform));
        target.appendChild(wall);
      } else {
        getRoot().appendChild(wall);
      }
    };

    return action;
  }
}
