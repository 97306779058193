// @ts-check

import { computed, nextTick, onMounted, ref, watchEffect } from '@vue/composition-api';
import { ImageFetcher } from '../fetch/image';
import { PlaneViewFetcher } from '../fetch/planeView';
import { PvPrintArea } from '../node/node/printAreaNode';
import { createNode } from '../node/node/pvNode';
import { PvRoot } from '../node/node/rootNode';
import {
  LARGE_GRID_METER_DEFAULT,
  LARGE_GRID_PX_DEFAULT,
  PAPER_SIZE,
  PAPER_SCALE,
  PAPER_SCALE_OPTIONS,
  PAPER_SIZE_OPTIONS,
  LEFT_MENU_WIDTH,
} from '../util/const';
import { Vec } from '../util/math/vector';
import { useRouter } from './router';
import { useUser } from './user';

export const usePrint = ({ editorX, editorY, zoom, targetElement }) => {
  const printAreaX = ref(0);
  const printAreaY = ref(0);
  const printPapaerType = ref('A4');
  const printPaperRatio = ref(210 / 297);
  const printZoom = ref(1);
  const printWidth = ref(5000);
  const printHeight = computed(() => {
    return printWidth.value * printPaperRatio.value;
  });
  const printTree = ref([createNode(PvRoot)]);
  const printArea = ref(createNode(PvPrintArea));
  /** @type {import('@vue/composition-api').Ref<null|Buffer>} */
  const imageBuf = ref(null);

  // 解像度
  const printDpi = ref(300);

  onMounted(() => {
    const node = createNode(PvPrintArea);
    node.transform = node.transform.cloneWith({
      translate: new Vec(editorX.value + LEFT_MENU_WIDTH + 100, editorY.value + 100),
    });

    node.vertexes = [
      new Vec(),
      new Vec(printWidth.value, 0),
      new Vec(printWidth.value, printHeight.value),
      new Vec(0, printHeight.value),
    ];
    printArea.value = node;
    printTree.value.push(printArea.value);
  });

  const planeViewFetcher = new PlaneViewFetcher();
  const imageFetcher = new ImageFetcher();

  const { planeId, propertyId } = useRouter();
  const { user } = useUser();

  const generatingImage = ref(false);

  const generateImage = async () => {
    generatingImage.value = true;
    await nextTick();
    const buf = await imageFetcher.generateImage(targetElement.value, {
      offsetX: -printArea.value.transform.translate.x,
      offsetY: -printArea.value.transform.translate.y,
      scaleWidth: printArea.value.vertexes[1].x,
      scaleHeight: printArea.value.vertexes[2].y,
    });
    generatingImage.value = false;

    return buf;
  };

  const generateAndDownloadImage = async () => {
    const buf = await generateImage();
    const planeName = 'image' + (planeId == null ? '' : planeId);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(new Blob([buf]));
    link.download = (planeName || '平面図') + '.' + 'png';
    link.click();

    if (planeId) {
      await planeViewFetcher.updateOutputHistoryPlaneView(planeId, user.attributes.sub);
    }

    return buf;
  };

  const currentPrintSize = ref(PAPER_SIZE_OPTIONS[0].value);

  const currentPrintScale = ref(PAPER_SCALE_OPTIONS[1].value);

  watchEffect(() => {
    const scale = PAPER_SCALE.find(s => s === currentPrintScale.value) ?? 0;
    // @ts-ignore
    const paperWidth = PAPER_SIZE[currentPrintSize.value].width;
    const tileNum = (scale * paperWidth) / LARGE_GRID_METER_DEFAULT;
    const width = tileNum * LARGE_GRID_PX_DEFAULT;
    const height = width * printPaperRatio.value;
    printArea.value.vertexes = [
      new Vec(),
      new Vec(width, 0),
      new Vec(width, height),
      new Vec(0, height),
    ];
    printWidth.value = width;
  });

  watchEffect(async () => {
    printWidth.value;
    imageBuf.value = await generateImage();
  });

  return {
    printAreaX,
    printAreaY,
    printPapaerType,
    printZoom,
    printPaperRatio,
    printWidth,
    printHeight,
    printTree,
    printArea,
    generateImage,
    generateAndDownloadImage,
    generatingImage,
    currentPrintSize,
    currentPrintScale,
    imageBuf,
  };
};

/** @typedef {ReturnType<typeof usePrint>} PrintInfo */
