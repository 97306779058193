<!--
作成者：Nakatsuka Daisuke
作成日：2020.10.21
画面名：物件情報登録・修正・照会
機能：物件情報登録・修正・照会
親子関係：なし
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <div class="row grid-margin">
      <label class="title">部屋情報</label>
      <b-navbar-nav class="ml-auto">
        <router-link :to="viewJamp()" tag="button" class="btn btn-ally">
          {{ propertyList == 'true' ? '物件一覧に戻る' : '物件管理に戻る' }}
        </router-link>
      </b-navbar-nav>
    </div>
    <div class="ally-input-background card col-12" v-if="!loading">
      <div class="card-body">
        <label class="title">部屋情報</label>

        <div>
          <allyText
            title="物件名"
            name="PropertyName"
            :mode="'View'"
            v-model="propertyData.commonValue.propertyName"
            :placeholder="'○○物件'"
            rules="max:20"
            tips="物件の所有者名を入力する"
          />
        </div>
        <div>
          <allyText
            title="部屋番号"
            name="roomNumber"
            :mode="'View'"
            v-model="roomData.roomName"
            :placeholder="'101号室'"
            rules="max:20"
            tips="物件の所有者名を入力する"
          />
        </div>
        <div id="PropertyFunction">
          <div class="card">
            <div style="">
              <b-tabs fill pills v-model="selectFunction">
                <b-tab title="ALLYフォト （画像シミュレーター）">
                  <b-container style="margin: 0px; max-width:100%;">
                    <SimList />
                  </b-container>
                </b-tab>
                <b-tab title="ALLYオートスケッチ（部屋計測・間取図自動作成）">
                  <b-container style="margin: 0px; max-width:100%;">
                    <PlaneList />
                  </b-container>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getProperty } from '../../graphql/queries';

  import SimList from './WallpaperSimulator/Main';
  import PlaneList from './PlaneView/List';

  API.configure(awsconfig);

  export default {
    components: {
      SimList,
      PlaneList,
    },
    data: function() {
      return {
        id: null, // ID
        roomId: null, // 部屋ID
        roomData: null, // 部屋情報
        propertyData: null, // 物件情報
        loading: true, // ローディングステータス
        selectFunction: 0,
        propertyList: null, // 物件リスト
      };
    },
    created: function() {
      // CREATED: selectFunctionをセットする
      if (this.$route.query.fun != null) this.selectFunction = Number(this.$route.query.fun);
      // CREATED: 物件リストをセットする
      this.propertyList = this.$route.query.propertyList;
    },
    mounted: async function() {
      let result = null;
      this.loading = true;

      // MOUNTED: idをセットする
      this.id = this.$route.params.id;
      // MOUNTED: 部屋IDをセットする
      this.roomId = this.$route.params.roomId;
      try {
        //物件情報を取得
        result = await API.graphql(
          graphqlOperation(getProperty, { company: this.getCompanyId(), id: this.id })
        );
      } catch (e) {
        console.error(e);
        return;
      }
      // MOUNTED: 部屋情報をセットする
      this.roomData = result.data.getProperty.roomInfo.find(val => val.id == this.roomId);
      // MOUNTED: 物件情報をセットする
      let propertyData = result.data.getProperty;
      this.propertyData = propertyData;

      this.loading = false;
    },
    methods: {
      /**
       * METHOD: 会社情報を取得する。
       */
      getCompanyId: function() {
        //アカウントから会社ID取得
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },
      /**
       * METHOD: フォトシミュと平面図を切り替え
       */
      clickFuncChange(num) {
        this.selectFunction = num;
      },
      /**
       * METHOD: viewJamp
       */
      viewJamp() {
        if (this.propertyList == null) {
          let jamp = '/Property/View/' + this.id;
          if (this.selectFunction != 0) jamp += '?fun=' + this.selectFunction;
          return jamp;
        } else {
          let jamp = '/Property/List';
          return jamp;
        }
      },
    },
  };
</script>

<style scoped>
  .title {
    font-weight: bold;
  }

  .itemfunc {
    position: relative;
    text-align: center;
    border: 1px solid;
    min-height: 90px;
    height: auto;
    font-size: 18px;
    display: table;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
    cursor: hand;
  }

  .itemfunc.select {
    color: white;
    background-color: var(--colorTheme);
  }
  .ally-input-background .card {
    border-top-color: gainsboro !important;
  }
  .card {
    border-top-color: gainsboro !important;
  }
</style>
<style>
  #PropertyFunction .nav-pills .nav-link.active {
    background-color: var(--colorTheme);
    margin-left: 1px;
    margin-right: 1px;
  }
  #PropertyFunction .nav-pills .nav-link {
    background-color: gainsboro;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
  }
</style>
