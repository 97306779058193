<template>
  <div>
    <b-card no-body class="category margin-bottom">
      <b-card-header class="card-header">
        <b>権利の評価明細計算</b>
      </b-card-header>

      <!--相続評価リスト-->
      <b-card-body>
        <!--計算結果の概要-->
        <table class="table text-center ally-table route-price" id="calcDetail">
          <thead>
            <tr>
              <th>土地・建物名</th>
              <th>相続価格ベース価値評価金額（円）</th>
              <!-- <th>実価格ベース価値評価額（円）</th> -->
            </tr>
          </thead>
          <tbody style="background-color: white">
            <tr v-for="row in inheritanceInfoList" :key="row.Id">
              <td>
                {{ row.realEstateName }}
              </td>
              <td style="text-align: right;">
                {{
                  (
                    row.totalPrice + (row.houseFixedAssetTax ? row.houseFixedAssetTax : 0)
                  ).toLocaleString()
                }}
                円
              </td>
              <!-- <td>{{ (row.totalPrice * 1.25).toLocaleString() }} 円</td> -->
            </tr>
            <tr v-if="inheritanceInfoList.length <= 0">
              <td colspan="3">
                詳細項目がありません
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <div class="col-md-12">
          <div class="row">
            <allyText
              class="col-6"
              title="相続税評価額参考価格（円）"
              name="totalInheritanceTaxProperValue"
              v-model="totalInheritanceTaxProperValue"
              rules="required"
              type="number"
              :separation="true"
              mode="View"
            />
          </div>
        </div>

        <b-collapse id="calcDetail">
          <table class="table text-center ally-table route-price" id="calcDetail">
            <thead>
              <tr>
                <th>明細</th>
                <th>金額</th>
                <th>計算記号</th>
              </tr>
            </thead>
            <tbody style="background-color: white"></tbody>
          </table>
        </b-collapse>
      </b-card-body>
    </b-card>
    <br />
    <!--相続税計算-->
    <b-card v-if="!errorFlg">
      <b-card-header class="card-header">
        <b>相続税計算</b>
      </b-card-header>

      <!--計算結果欄-->
      <b-card-body>
        <!--計算結果の概要-->
        <table
          class="table text-center ally-table route-price"
          id="calcDetail"
          v-if="commonInput.family.length > 0"
        >
          <thead>
            <tr>
              <th>土地・建物 相続税評価額（円）</th>
              <th>現金(円)</th>
              <th>生命保険(円)</th>
              <th>その他金融資産(円)</th>
              <th>その他(円)</th>
            </tr>
          </thead>
          <tbody style="background-color: white">
            <tr>
              <td>{{ Math.round(totalInheritanceTaxProperValue).toLocaleString() }} 円</td>
              <td>{{ commonInput.cash > 0 ? commonInput.cash.toLocaleString() : 0 }} 円</td>
              <td>{{ commonInput.stocks > 0 ? commonInput.stocks.toLocaleString() : 0 }} 円</td>
              <td>
                {{
                  commonInput.otherFinancialAsset > 0
                    ? commonInput.otherFinancialAsset.toLocaleString()
                    : 0
                }}
                円
              </td>
              <td>
                {{ commonInput.otherAsset > 0 ? commonInput.otherAsset.toLocaleString() : 0 }} 円
              </td>
            </tr>

            <tr>
              <td class="text-center colorPoint">
                課税価格の合計額
              </td>
              <td colspan="4">
                {{
                  totalInheritanceTaxAssetValue > 0
                    ? totalInheritanceTaxAssetValue.toLocaleString()
                    : 0
                }}
                円
              </td>
            </tr>

            <tr>
              <td class="text-center colorPoint">
                課税遺産総額
              </td>
              <td colspan="4">
                {{
                  totalInheritanceTaxAssetValue > 0
                    ? totalInheritanceTaxAssetValue.toLocaleString()
                    : 0
                }}
                円
              </td>
            </tr>

            <tr>
              <td class="text-center colorPoint">
                相続税の総額
              </td>
              <td colspan="4">
                {{ totalInheritanceTax > 0 ? totalInheritanceTax.toLocaleString() : 0 }} 円
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          相続対象情報が入力されておりません。
        </div>

        <b-collapse id="calcDetail">
          <table class="table text-center ally-table route-price" id="calcDetail">
            <thead>
              <tr>
                <th>明細</th>
                <th>金額</th>
                <th>計算記号</th>
              </tr>
            </thead>
            <tbody style="background-color: white"></tbody>
          </table>
        </b-collapse>
      </b-card-body>
    </b-card>
    <br />
    <!--各相続人の相続税額-->
    <b-card v-if="!errorFlg">
      <b-card-header class="card-header">
        <b>各相続人の詳細</b>
      </b-card-header>
      <b-card-body>
        <!--各相続人の相続税額-->
        <table
          class="table text-center ally-table route-price"
          id="calcDetail"
          v-if="commonInput.family.length > 0"
        >
          <thead>
            <tr>
              <th>氏名</th>
              <th>顧客との関係</th>
              <th>納付税額(円)</th>
              <th>実質相続金額(円)</th>
            </tr>
          </thead>
          <tbody style="background-color: white">
            <!--1.課税価格の合計額を算出-->
            <tr v-for="row in familyInheritanceInfo" :key="row.Id">
              <td>{{ row.name }}</td>
              <td>{{ row.familyType }}</td>
              <td>
                {{
                  row.payInheritanceTaxPrice > 0 ? row.payInheritanceTaxPrice.toLocaleString() : 0
                }}
                円
              </td>
              <td>
                {{
                  row.inheritanceGetPrice - row.payInheritanceTaxPrice > 0
                    ? (row.inheritanceGetPrice - row.payInheritanceTaxPrice).toLocaleString()
                    : 0
                }}
                円
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          相続対象情報が入力されておりません。
        </div>

        <b-collapse id="calcDetail">
          <table class="table text-center ally-table route-price" id="calcDetail">
            <thead>
              <tr>
                <th>明細</th>
                <th>金額</th>
                <th>計算記号</th>
              </tr>
            </thead>
            <tbody style="background-color: white"></tbody>
          </table>
        </b-collapse>
      </b-card-body>
    </b-card>
    <br />
    <b-card>
      <b-card-header class="card-header">
        <b>二次相続</b>
      </b-card-header>
      <b-card-body>
        <div style="text-align:right;">(単位：万円)</div>
        <table
          class="table ally-table route-price"
          id="calcDetail"
          border="1"
          rules="all"
          style="table-layout: fixed;"
          v-if="commonInput.family.length > 0"
        >
          <thead class="text-center">
            <tr>
              <th rowspan="2" style="vertical-align: middle;">割合</th>
              <th colspan="3">一次相続</th>
              <th colspan="3">二次相続</th>
              <th rowspan="2" style="vertical-align: middle;">総合計</th>
            </tr>
            <tr>
              <th>{{ secondPattern.decedent }}</th>
              <th>{{ secondPattern.heir }}</th>
              <th>合計</th>
              <th>{{ secondPattern.heir }}</th>
              <th>控除額※１</th>
              <th>合計</th>
            </tr>
          </thead>
          <tbody style="background-color: white" class="text-right">
            <tr
              v-for="(row, index) in tableDataOfInheritance"
              :key="row.Id"
              :class="background(index)"
            >
              <td style="background-color: #CCCCCC" :class="background(index)">
                {{ index * 10 }}％
              </td>
              <td>
                {{
                  row.decedentInheritanceTax.toLocaleString(undefined, { minimumFractionDigits: 0 })
                }}
              </td>
              <td>
                {{
                  row.heirPrimaryInheritanceTax.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                  })
                }}
              </td>
              <td>
                {{
                  row.totlaPrimaryInheritanceTax.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                  })
                }}
              </td>
              <td>
                {{
                  row.heirSecondaryInheritanceTax.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                  })
                }}
              </td>
              <td>
                {{
                  row.inheritanceDeducition.toLocaleString(undefined, { minimumFractionDigits: 0 })
                }}
              </td>
              <td>
                {{
                  row.totalSecondaryInheritanceTax.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                  })
                }}
              </td>
              <td>
                {{
                  row.totalInheritanceTax.toLocaleString(undefined, { minimumFractionDigits: 0 })
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <div style="text-align: right;">※１…相次相続控除</div>
        <div>
          税額の最大と最小の差は・・・
          <span style="color:red; font-size:1.5rem;">{{ maxDifference.toLocaleString() }}</span>
          万円です。
        </div>
      </b-card-body>
    </b-card>
    <br />

    <div v-if="!errorFlg" style="width:90%;margin-left:5%;">
      <ReportPreview
        :fileTitle="commonInput.reportTitle"
        :customerId="commonInput.customerId"
        :customerName="commonInput.customerName"
        :saveS3="false"
        class="report-preview"
        v-if="showReport && !errorFlg"
      >
        <template #default="{companyInfo,companyLogo}">
          <slide
            ><ReportCover
              :title="commonInput.reportTitle"
              :commonInput="commonInput"
              :companyInfo="companyInfo"
              :companyLogo="companyLogo"
          /></slide>
          <slide>
            <ReportIntro :commonInput="commonInput" :companyInfo="companyInfo" />
          </slide>
          <slide>
            <RightEvaluation
              :inheritanceInfoList="inheritanceInfoListFirst"
              :totalInheritanceTaxProperValue="totalInheritanceTaxProperValue"
              :realTotalInheritanceTaxProperValue="realTotalInheritanceTaxProperValue"
            />
          </slide>
          <slide v-if="inheritanceInfoList.length > 10">
            <RightEvaluation
              :inheritanceInfoList="inheritanceInfoListNext"
              :totalInheritanceTaxProperValue="totalInheritanceTaxProperValue"
              :realTotalInheritanceTaxProperValue="realTotalInheritanceTaxProperValue"
            />
          </slide>
          <slide
            v-for="(rightEvaluationDetailsList, index) in rightEvaluationDetailsData"
            :key="'rightEvaluationDetailsList-' + index"
          >
            <RightEvaluationDetails :rightEvaluationDetailsList="rightEvaluationDetailsList" />
          </slide>
          <slide v-if="commonInput.family.length > 0">
            <TaxCalculation
              :commonInput="commonInput"
              :totalInheritanceTaxAssetValue="totalInheritanceTaxAssetValue"
              :totalInheritanceTaxProperValue="totalInheritanceTaxProperValue"
              :totalInheritanceTax="totalInheritanceTax"
            />
          </slide>
          <slide v-if="commonInput.family.length > 0">
            <SecondaryInherytance
              :commonInput="commonInput"
              :familyInheritanceInfo="familyInheritanceInfo"
              :pattern="secondPattern"
              :inheritance="tableDataOfInheritance"
              :minRow="minRow"
            />
          </slide>
          <slide v-if="commonInput.family.length > 0">
            <Heirs :commonInput="commonInput" :familyInheritanceInfo="familyInheritanceInfo" />
          </slide>
          <!-- <slide>
            <ReportEndMessage :commonInput="commonInput" :companyInfo="companyInfo" />
          </slide> -->
        </template>
      </ReportPreview>
    </div>
    <allyAlert code="E115"></allyAlert>
    <allyAlert code="E116"></allyAlert>
    <allyAlert code="E117"></allyAlert>
    <allyAlert code="E119"></allyAlert>
    <allyAlert code="E120"></allyAlert>
    <allyAlert code="E0001"></allyAlert>
  </div>
</template>
<script>
  import ReportPreview from '@/components/ExportReport/ReportPreview.vue';
  import ReportCover from '@/components/ExportReport/ReportCover';
  import ReportIntro from './Report/Intro';
  // import ReportEndMessage from '@/components/ExportReport/EndMessage';
  import RightEvaluation from './Report/RightEvaluation';
  import RightEvaluationDetails from './Report/RightEvaluationDetails';
  import TaxCalculation from './Report/TaxCalculation';
  import SecondaryInherytance from './Report/SecondaryInheritance';
  import Heirs from './Report/Heirs';
  import InheritanceInput from './inputClass';
  // import { PDFDocument } from 'pdf-lib';

  export default {
    props: {
      propertyList: Array,
      commonInput: Object,
    },
    components: {
      ReportPreview,
      ReportCover,
      ReportIntro,
      // ReportEndMessage,
      RightEvaluation,
      RightEvaluationDetails,
      TaxCalculation,
      SecondaryInherytance,
      Heirs,
    },
    data() {
      return {
        rightEvaluationDetailsData: [],
        inheritanceInfoList: [],
        inheritanceInfoListFirst: [],
        inheritanceInfoListNext: [],
        propertyDataList: [],
        input: new InheritanceInput(),
        selectNum: 0,
        showReport: true,
        totalInheritanceTaxProperValue: 0,
        realTotalInheritanceTaxProperValue: 0,
        totalInheritanceTaxAssetValue: 0,
        totalInheritancePrice: 0,
        totalInheritancePriceBasePrice1: 30000000,
        totalInheritancePriceBasePrice2: 6000000,
        insuranceDeduction: 5000000,
        calcStopFlg: false,
        familyInheritanceInfo: [],
        secondPattern: {
          decedent: '',
          heir: '',
        },
        secondaryHeir: 1,
        inheritance: {
          decedentInheritanceTax: [], //二次相続における被相続人の一次相続税……(1)
          heirPrimaryInheritanceTax: [], //二次相続における被相続人の一次相続税……(2)
          heirSecondaryInheritanceTax: [], //二次相続における被相続人の二次相続税(控除なし)
          primaryIndividualInheritance: [], //一次相続における個別相続額
          primaryIndividualInheritanceTax: [], //一次相続における個別相続税
          inheritanceDeducition: [], //二次相続における相次相続控除
          totlaPrimaryInheritanceTax: [], //一次相続税の合計((1) + (2))
          totalSecondaryInheritanceTax: [], //二次相続税(控除アリ)
          totalInheritanceTax: [],
        },
        tableDataOfInheritance: [],
        maxDifference: 0, //二次相続総合計の差の最大値
        minRow: 0,
        totalInheritanceTax: 0,
        inheritancePrice: 0, //実際の相続割合（％）
        donation: 0, //暦年課税制度で3年以内に受けた贈与額（円）
        inheritancePriceDeduction: 0, //相続時精算課税制度の適用を受けた資産額（円）
        errorFlg: false,
        generalDonationTaxRate: [
          //一般贈与財産の税率表 [基礎控除後の課税価格, 税率, 控除額]
          [2000000, 10, 0],
          [3000000, 15, 100000],
          [4000000, 20, 250000],
          [6000000, 30, 650000],
          [10000000, 40, 1250000],
          [15000000, 45, 1750000],
          [30000000, 50, 2500000],
        ],
        exceptionDonationTaxRate: [
          //特例贈与財産用の税率表 [基礎控除後の課税価格, 税率, 控除額]
          [2000000, 10, 0],
          [4000000, 15, 100000],
          [6000000, 20, 300000],
          [10000000, 30, 900000],
          [15000000, 40, 1900000],
          [30000000, 45, 2650000],
          [45000000, 50, 4150000],
        ],
      };
    },
    computed: {
      background() {
        return function(index) {
          return index == this.minRow
            ? {
                minTotalInheritance: true,
              }
            : { minTotalInheritance: false };
        };
      },
      rightEvaluationList() {
        return this.inheritanceInfoList;
      },
      slideDataReduced() {
        if (this.slideData.length === 0) {
          return [];
        }
        return this.slideData.reduce((pre, current) => [...pre, ...current], []);
      },
      slideSet() {
        return this.slideData[this.selectNum];
      },
      selectItems() {
        return this.propertyList.map((_, i) => ({ text: `不動産${i + 1}`, value: i }));
      },
      slideData() {
        if (this.chartDataList == null) {
          return null;
        }

        let result = [];

        for (const chartData of this.chartDataList) {
          result = [
            ...result,
            [
              {
                title: '不動産適正',
                subTitle: '月間収支（毎日の手取り収入）の比較',
                caption: '毎月の手取り収入',
                comments: [
                  '○⼟地の固定資産税・都市計画税: 除外。',
                  '○更地: 収⼊ゼロとし草刈り等の維持費⽤分マイナス。',
                  '○貸地: 地代収⼊。',
                  '○売却: 売却後の⼿取り額に対しての運⽤益とする。',
                  '○不動産所得に対する所得税・住⺠税:　各⼈ごと<br/>の総所得や家族構成等により異なるため考慮しない。',
                ],
                chartData: chartData,
              },
            ],
          ];
        }

        return result;
      },
    },
    methods: {
      async downloadAll() {
        const result = await Promise.all(
          this.chartDataList.map(async (_, i) => {
            this.selectNum = i;
            await this.$nextTick();
            return await this.$refs.reportPreview.convertPdf();
          })
        );

        const buffer = await this.mergePdfs(result);

        var blob = new Blob([buffer], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'report.pdf';
        link.click();

        this.selectNum = 0;
      },

      // async mergePdfs(pdfsToMerges) {
      //   const mergedPdf = await PDFDocument.create();
      //   const actions = pdfsToMerges.map(async pdfBuffer => {
      //     const pdf = await PDFDocument.load(pdfBuffer);
      //     const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      //     copiedPages.forEach(page => {
      //       // console.log('page', page.getWidth(), page.getHeight());
      //       // page.setWidth(210);
      //       mergedPdf.addPage(page);
      //     });
      //   });
      //   await Promise.all(actions);
      //   const buffer = await mergedPdf.save();
      //   return buffer;
      // },

      async CalculateInheritanceTax() {
        try {
          this.errorFlg = false;
          //相続人情報がない場合は処理を終了
          if (this.commonInput.family.length > 0) {
            //生前贈与の持ち戻し
            let donationPrice = 0;
            this.commonInput.family.forEach(value => {
              if (value.inheritanceFlg) {
                //相続放棄していても贈与がある場合があるが仕様上、除外している
                if (value.inheritanceSystemFlg == 1) {
                  donationPrice += value.donation;
                } else if (
                  value.inheritanceSystemFlg == 2 &&
                  (value.familyType == '子' ||
                    value.familyType == '養子' ||
                    value.familyType == '孫')
                ) {
                  donationPrice += value.inheritancePriceDeduction;
                }
              }
            });

            //相続資産
            this.totalInheritancePrice =
              Math.round(this.totalInheritanceTaxProperValue) + //相続価格ベース価値評価額
              this.commonInput.cash + //現金
              this.commonInput.stocks + //生命保険
              this.commonInput.otherFinancialAsset + //その他金融資産
              donationPrice; //贈与額合計

            //相続対象の確認を行い、法定相続額を決める
            let wife = 0;
            let child = 0; //実子
            let adoptedChild = 0; //養子(補正数)
            let grandChild = 0;
            let parents = 0;
            let grandParents = 0;
            let brother = 0;
            let other = 0;
            let percentWife = 0;
            let percentChild = 0;
            let percentGrandChild = 0;
            let percentParents = 0;
            let percentGrandParents = 0;
            let percentBrother = 0;
            let percentOther = 0;
            //配偶者控除比較金額
            let wifeDedution = 0;
            let wifeDedutionMaster = 160000000;
            //相続比率チェック（100％を超えた場合、終了）
            let checkPercentage = 0;
            //法定相続税総額
            let totalLegalInheritanceTax = 0;
            //算出税額初期化
            this.totalInheritanceTax = 0;

            this.commonInput.family.forEach(value => {
              //各登録人数をカウント
              if (value.familyType == '子') {
                child += 1;
              } else if (value.familyType == '父' || value.familyType == '母') {
                parents += 1;
              } else if (
                value.familyType == '兄' ||
                value.familyType == '弟' ||
                value.familyType == '姉' ||
                value.familyType == '妹'
              ) {
                brother += 1;
              } else if (value.familyType == '養子') {
                adoptedChild += 1;
              } else if (value.familyType == '夫' || value.familyType == '妻') {
                wife += 1;
              } else if (value.familyType == '孫') {
                grandChild += 1;
              } else if (value.familyType == '祖父' || value.familyType == '祖母') {
                grandParents += 1;
              } else if (value.familyType == '兄弟姉妹の子') {
                other += 1;
              }
            });

            //エラーチェック
            if (wife > 1) {
              this.errorFlg = true;
              this.showError('E115');
              return;
            } else if (parents > 2) {
              this.errorFlg = true;
              this.showError('E116');
              return;
            } else if (grandParents > 2) {
              this.errorFlg = true;
              this.showError('E119');
              return;
            }

            //法定相続割合を判定
            let legalHeir = 0;
            if (wife == 0) {
              //配偶者がない場合
              if (child > 0 || adoptedChild > 0) {
                //実子が居る場合、養子は１人までしか数えない
                //実子が居ない場合、養子は２人までしか数えない
                legalHeir = child + Math.min(adoptedChild, child > 0 ? 1 : 2);
                percentChild = (1 / legalHeir).toFixed(3);
              } else if (grandChild > 0) {
                percentGrandChild = (1 / grandChild).toFixed(3);
                legalHeir = grandChild;
              } else if (parents > 0) {
                percentParents = (1 / parents).toFixed(3);
                legalHeir = parents;
              } else if (grandParents > 0) {
                percentGrandParents = (1 / grandParents).toFixed(3);
                legalHeir = grandParents;
              } else if (brother > 0) {
                percentBrother = (1 / brother).toFixed(3);
                legalHeir = brother;
              } else if (other > 0) {
                percentOther = (1 / other).toFixed(3);
                legalHeir = other;
              } else {
                //相続対象が居ないのでエラー
                this.errorFlg = true;
                this.showError('E108');
                return;
              }
            } else {
              //配偶者がある場合
              if (child > 0 || adoptedChild > 0) {
                percentWife = 0.5;
                //実子が居る場合、養子は１人までしか数えない
                //実子が居ない場合、養子は２人までしか数えない
                percentChild = (0.5 / (child + Math.min(adoptedChild, child > 0 ? 1 : 2))).toFixed(
                  3
                );
                legalHeir = 1 + child + Math.min(adoptedChild, child > 0 ? 1 : 2);
              } else if (grandChild > 0) {
                //孫の代襲相続
                percentWife = 0.5;
                percentGrandChild = (0.5 / grandChild).toFixed(3);
                legalHeir = 1 + grandChild;
              } else if (parents > 0) {
                percentWife = 0.667;
                percentParents = (0.333 / parents).toFixed(3);
                legalHeir = 1 + parents;
              } else if (grandParents > 0) {
                percentWife = 0.667;
                percentGrandParents = (0.333 / grandParents).toFixed(3);
                legalHeir = 1 + grandParents;
              } else if (brother > 0) {
                percentWife = 0.75;
                percentBrother = (0.25 / brother).toFixed(3);
                legalHeir = 1 + brother;
              } else if (other > 0) {
                //甥姪の代襲相続
                percentWife = 0.75;
                percentOther = (0.25 / other).toFixed(3);
                legalHeir = 1 + other;
              } else {
                percentWife = 1;
                legalHeir = 1;
              }
            }

            //課税資産
            //法定相続人数をカウントしてから計算
            this.totalInheritanceTaxAssetValue =
              this.totalInheritancePrice - //相続資産
              this.commonInput.liability - //債務・葬式分担費（円）
              (this.totalInheritancePriceBasePrice1 + //基礎控除額
                this.totalInheritancePriceBasePrice2 * legalHeir) - //法定相続人数
              Math.min(this.commonInput.stocks, this.insuranceDeduction * legalHeir); //生命保険控除
            this.totalInheritanceTaxAssetValue = Math.max(this.totalInheritanceTaxAssetValue, 0);

            this.familyInheritanceInfo = [];
            //相続税マスタロード
            var taxRate = this.$store.state.master.interitanceMaster10;
            this.commonInput.family.forEach(value => {
              //支払済みの贈与税を算出
              let donationTax = 0;
              if (value.inheritanceSystemFlg == 1) {
                if (value.donations[0] > 110 * 10000) {
                  let baseDonation = value.donations[0] - 110 * 10000; //110万円の基礎控除
                  baseDonation = Math.floor(baseDonation / 1000) * 1000; //千円未満を切り捨て
                  if (
                    value.familyType == '子' ||
                    value.familyType == '養子' ||
                    value.familyType == '孫'
                  ) {
                    //子(養子)、孫は特例贈与
                    let donationRate = this.exceptionDonationTaxRate.find(
                      value => baseDonation <= value[0]
                    );
                    if (donationRate == undefined) {
                      donationRate = ['', 55, 6400000];
                    }
                    donationTax +=
                      Math.floor(baseDonation * (donationRate[1] / 100)) - donationRate[2];
                  } else {
                    //それ以外は一般贈与
                    let donationRate = this.generalDonationTaxRate.find(
                      value => baseDonation <= value[0]
                    );
                    if (donationRate == undefined) {
                      donationRate = ['', 55, 4000000];
                    }
                    donationTax +=
                      Math.floor(baseDonation * (donationRate[1] / 100)) - donationRate[2];
                  }
                }
                if (value.donations[1] > 110 * 10000) {
                  let baseDonation = value.donations[1] - 110 * 10000; //110万円の基礎控除
                  baseDonation = Math.floor(baseDonation / 1000) * 1000; //千円未満を切り捨て
                  if (
                    value.familyType == '子' ||
                    value.familyType == '養子' ||
                    value.familyType == '孫'
                  ) {
                    //子(養子)、孫は特例贈与
                    let donationRate = this.exceptionDonationTaxRate.find(
                      value => baseDonation <= value[0]
                    );
                    if (donationRate == undefined) {
                      donationRate = ['', 55, 6400000];
                    }
                    donationTax +=
                      Math.floor(baseDonation * (donationRate[1] / 100)) - donationRate[2];
                  } else {
                    //それ以外は一般贈与
                    let donationRate = this.generalDonationTaxRate.find(
                      value => baseDonation <= value[0]
                    );
                    if (donationRate == undefined) {
                      donationRate = ['', 55, 4000000];
                    }
                    donationTax +=
                      Math.floor(baseDonation * (donationRate[1] / 100)) - donationRate[2];
                  }
                }
                if (value.donations[2] > 110 * 10000) {
                  let baseDonation = value.donations[2] - 110 * 10000; //110万円の基礎控除
                  baseDonation = Math.floor(baseDonation / 1000) * 1000; //千円未満を切り捨て
                  if (
                    value.familyType == '子' ||
                    value.familyType == '養子' ||
                    value.familyType == '孫'
                  ) {
                    //子(養子)、孫は特例贈与
                    let donationRate = this.exceptionDonationTaxRate.find(
                      value => baseDonation <= value[0]
                    );
                    if (donationRate == undefined) {
                      donationRate = ['', 55, 6400000];
                    }
                    donationTax +=
                      Math.floor(baseDonation * (donationRate[1] / 100)) - donationRate[2];
                  } else {
                    //それ以外は一般贈与
                    let donationRate = this.generalDonationTaxRate.find(
                      value => baseDonation <= value[0]
                    );
                    if (donationRate == undefined) {
                      donationRate = ['', 55, 4000000];
                    }
                    donationTax +=
                      Math.floor(baseDonation * (donationRate[1] / 100)) - donationRate[2];
                  }
                }
              } else if (
                value.inheritanceSystemFlg == 2 &&
                (value.familyType == '子' || value.familyType == '養子' || value.familyType == '孫')
              ) {
                //相続時精算課税制度は子(養子)、孫のみ
                if (value.inheritancePriceDeduction > 2500 * 10000) {
                  //2,500万円の控除後の20%が贈与税
                  donationTax = (value.inheritancePriceDeduction - 2500 * 10000) * 0.2;
                }
              }
              donationTax = Math.max(donationTax, 0);

              //実相続資産 = 相続資産 × 相続割合
              let getPrice =
                Math.floor((this.totalInheritancePrice * (value.inheritancePrice / 100)) / 1000) *
                1000;
              //法定相続税を算出
              let legalRate = 0;
              switch (value.familyType) {
                case '夫':
                case '妻':
                  legalRate = percentWife;
                  break;
                case '子':
                case '養子':
                  legalRate = percentChild;
                  break;
                case '孫':
                  legalRate = percentGrandChild;
                  break;
                case '父':
                case '母':
                  legalRate = percentParents;
                  break;
                case '祖父':
                case '祖母':
                  legalRate = percentGrandParents;
                  break;
                case '兄':
                case '弟':
                case '姉':
                case '妹':
                  legalRate = percentBrother;
                  break;
                default:
                  legalRate = percentOther;
                  break;
              }
              //法定相続額 = 課税遺産総額 × 法定相続割合
              let legalPrice =
                Math.floor((this.totalInheritanceTaxAssetValue * legalRate) / 1000) * 1000;
              for (var t = 1; t < taxRate.length - 1; t++) {
                //0番目には項目名(文字列)が入っている。末尾は無限大なので判定範囲から外れ場合に適用される
                if (taxRate[t][0] * 10000 >= legalPrice) {
                  break;
                }
              }
              //法定相続税
              let legalInheritanceTax =
                Math.floor((legalPrice * taxRate[t][1]) / 100) - taxRate[t][2] * 10000;

              //法定相続税合計
              totalLegalInheritanceTax += legalInheritanceTax;

              let result = {
                name: value.lastName + ' ' + value.firstName,
                familyType: value.familyType,
                inheritanceGetPrice: getPrice, //実相続金額
                donationTax: donationTax, //贈与税
              };
              checkPercentage += value.inheritancePrice; //相続割合の合計チェック

              this.familyInheritanceInfo.push(result);
            });
            if (checkPercentage > 100) {
              this.errorFlg = true;
              this.showError('E117');
              return;
            }

            //各人ごとの納付税額の計算
            this.familyInheritanceInfo.forEach(value => {
              //本システムでは遺贈を考慮しないため、相続人=法定相続人(20%増額判定はなし)

              //納付税額(基本額)=法定相続税総額x(相続額÷相続資産)
              value.payInheritanceTaxPrice = Math.floor(
                (totalLegalInheritanceTax * value.inheritanceGetPrice) / this.totalInheritancePrice
              );

              //支払済みの個別贈与税を控除（共通）
              value.payInheritanceTaxPrice -= value.donationTax;

              //個別補正(配偶者控除と遺贈割り増し)
              switch (value.familyType) {
                case '夫':
                case '妻':
                  //配偶者控除
                  //(1億６千万 or 法定相続額のいずれか大きい方) or 課税価格（相続金額）の小さい方が基準価格
                  wifeDedution = Math.min(
                    Math.max(wifeDedutionMaster, this.totalInheritancePrice * percentWife),
                    value.inheritanceGetPrice
                  );

                  //配偶者控除 = 相続税の総額 x 基準価格 ÷ 課税価格の総額
                  wifeDedution = Math.floor(
                    (totalLegalInheritanceTax * wifeDedution) / this.totalInheritancePrice
                  );

                  //1億６千万を超えない為の補正
                  wifeDedution = Math.min(wifeDedution, wifeDedutionMaster);

                  //実質取得した分から差し引く
                  value.payInheritanceTaxPrice -= wifeDedution;
                  break;
                case '子':
                case '養子':
                  //特になし
                  break;
                case '孫':
                  if (percentGrandChild == 0) {
                    //遺贈
                    //代襲相続も考えられるが、本システムでは未対応(子が居ない場合のみ代襲相続と判定される)
                    value.payInheritanceTaxPrice *= 1.2;
                  }
                  break;
                case '父':
                case '母':
                  if (percentParents == 0) {
                    //遺贈
                    value.payInheritanceTaxPrice *= 1.2;
                  }
                  break;
                case '祖父':
                case '祖母':
                  if (percentGrandParents == 0) {
                    //遺贈
                    value.payInheritanceTaxPrice *= 1.2;
                  }
                  break;
                case '兄':
                case '弟':
                case '姉':
                case '妹':
                  if (percentBrother == 0) {
                    //遺贈
                    value.payInheritanceTaxPrice *= 1.2;
                  }
                  break;
                default:
                  if (percentOther == 0) {
                    //遺贈
                    value.payInheritanceTaxPrice *= 1.2;
                  }
                  break;
              }

              //控除補正
              value.payInheritanceTaxPrice = Math.max(value.payInheritanceTaxPrice, 0);

              //相続税の総額に加算
              this.totalInheritanceTax += value.payInheritanceTaxPrice;
            });

            if (this.commonInput.continuityInheritanceFlg == 1) {
              //二次相続あり

              //エラーチェック
              if (
                this.commonInput.secondaryInheritancePattern == 1 &&
                (wife == 0 || child + adoptedChild == 0)
              ) {
                //配偶者、または子も養子が居ないのでエラー
                this.errorFlg = true;
                this.showError('E120');
                return;
              }
              if (
                this.commonInput.secondaryInheritancePattern == 2 &&
                (percentChild == 0 || grandChild == 0)
              ) {
                //子が相続を受けていない、または孫が居ないのでエラー
                this.errorFlg = true;
                this.showError('E120');
                return;
              }
              if (
                this.commonInput.secondaryInheritancePattern == 3 &&
                (percentChild == 0 || child + adoptedChild == 1)
              ) {
                //子が相続を受けていない、または他に子または養子が居ないのでエラー
                this.errorFlg = true;
                this.showError('E120');
                return;
              }
              if (
                this.commonInput.secondaryInheritancePattern == 4 &&
                (percentBrother == 0 || other == 0)
              ) {
                //兄弟が相続を受けていない、または甥姪が居ないのでエラー
                this.errorFlg = true;
                this.showError('E120');
                return;
              }

              //相次相続の計算
              //データの初期化
              Object.entries(this.inheritance).forEach(value => {
                value[1].splice(0);
              });
              this.tableDataOfInheritance.splice(0);

              //０％から１００％を１０％刻みに相続税を割り出し表示する。計算式は下記のようにする
              //個別相続額=（課税対象金額×（0％～100％））
              //個別相続税＝個別相続額 x 個別相続額をもとにした相続税率
              let secondaryHeir = 1;
              if (this.commonInput.secondaryInheritancePattern == 1) {
                //子+養子の数
                secondaryHeir = child + adoptedChild;
              } else if (this.commonInput.secondaryInheritancePattern == 3) {
                //入力による人数
                secondaryHeir = child + adoptedChild - 1;
              } else {
                //入力による人数
                secondaryHeir = this.commonInput.secondaryHeir;

                if (
                  this.commonInput.secondaryInheritancePattern == 2 &&
                  secondaryHeir > grandChild
                ) {
                  this.errorFlg = true;
                  this.showError('E120');
                  return;
                } else if (
                  this.commonInput.secondaryInheritancePattern == 4 &&
                  secondaryHeir > other
                ) {
                  this.errorFlg = true;
                  this.showError('E120');
                  return;
                }
              }
              if (secondaryHeir <= 0) {
                this.errorFlg = true;
                this.showError('E120');
                return;
              }
              //一次相続の計算
              for (let i = 0; i < 11; i++) {
                if (this.commonInput.secondaryInheritancePattern == 1) {
                  //配偶者 → 子の場合
                  //まず、一次相続の個別相続額を求める
                  let individualInheritance = this.totalInheritancePrice * (i / 10);
                  this.inheritance.primaryIndividualInheritance.push(individualInheritance);
                  let wifeTax = (totalLegalInheritanceTax * i) / 10;
                  //配偶者控除の計算
                  //(1億６千万 or 法定相続額のいずれか大きい方) or 実相続額の小さい方が基準
                  let deduction =
                    (totalLegalInheritanceTax *
                      Math.min(
                        Math.max(wifeDedutionMaster, this.totalInheritancePrice * percentWife),
                        (this.totalInheritancePrice * i) / 10
                      )) /
                    this.totalInheritancePrice;
                  //相続額が控除額以下ならば、相続税は0になる
                  wifeTax = Math.max(0, Math.floor(wifeTax - deduction));
                  this.inheritance.decedentInheritanceTax.push(wifeTax);
                  let childTax = Math.floor((totalLegalInheritanceTax * (10 - i)) / 10);
                  this.inheritance.heirPrimaryInheritanceTax.push(childTax);
                } else if (
                  this.commonInput.secondaryInheritancePattern == 2 ||
                  this.commonInput.secondaryInheritancePattern == 3
                ) {
                  //子 → 孫
                  //子 → 子の兄弟姉妹
                  //まず、一次相続の個別相続額を求める
                  let individualInheritance = this.totalInheritancePrice * percentChild;
                  this.inheritance.primaryIndividualInheritance.push(individualInheritance);
                  //法定相続税を計算
                  let childTax = Math.floor(totalLegalInheritanceTax * percentChild);
                  //法定相続税総額
                  this.inheritance.decedentInheritanceTax.push(childTax);
                  this.inheritance.heirPrimaryInheritanceTax.push(0);
                } else if (this.commonInput.secondaryInheritancePattern == 4) {
                  //兄弟姉妹 → 兄弟姉妹の子
                  let individualInheritance = this.totalInheritancePrice * percentBrother;
                  this.inheritance.primaryIndividualInheritance.push(individualInheritance);
                  //法定相続税を計算
                  let brotherTax = Math.floor(totalLegalInheritanceTax * percentBrother);
                  //法定相続税総額
                  this.inheritance.decedentInheritanceTax.push(brotherTax);
                  this.inheritance.heirPrimaryInheritanceTax.push(0);
                }
              }
              // //一次相続計算後、二次相続人の一次相続税を逆順に並べ替える(100%~0%へ降順)
              // this.inheritance.heirPrimaryInheritanceTax.reverse();
              //一次相続税の合計額を配列に格納
              for (let i = 0; i < this.inheritance.heirPrimaryInheritanceTax.length; i++) {
                this.inheritance.totlaPrimaryInheritanceTax.push(
                  this.inheritance.decedentInheritanceTax[i] + //二次被相続者の一次相続税
                    this.inheritance.heirPrimaryInheritanceTax[i] //二次相続者の一次相続税
                );
              }
              //二次相続の計算
              this.inheritance.primaryIndividualInheritance.forEach((value, index) => {
                //一次相続における二次被相続人の個別相続額を相続資産とし、計算(primaryIndividualInheritanceが相続資産に該当する)
                //課税対象金額を計算する。計算式は以下の通り
                //二次相続資産 = 一次相続資産 + 二次被相続人の固有資産 - 一次相続税(※ダイナ様確認済み)
                let totalInheritancePrice =
                  value +
                  this.commonInput.uniqueAsset -
                  this.inheritance.decedentInheritanceTax[index];
                //二次課税資産 = 二次相続資産 - 基礎控除
                let totalInheritanceTaxAssetValue =
                  totalInheritancePrice -
                  (this.totalInheritancePriceBasePrice1 + //基礎控除額
                    this.totalInheritancePriceBasePrice2 * secondaryHeir); //二次相続人数
                totalInheritanceTaxAssetValue = Math.max(0, totalInheritanceTaxAssetValue);
                //個別相続額 = 二次相続資産 / 二次相続人の人数
                let individualInheritance = totalInheritancePrice / secondaryHeir;
                //法定個別相続額 = 二次課税資金 / 二次相続人の人数
                let legalIndividualInheritance = totalInheritanceTaxAssetValue / secondaryHeir;
                for (var t = 0; t < taxRate.length - 1; t++) {
                  if (taxRate[t][0] * 10000 >= legalIndividualInheritance) {
                    break;
                  }
                }
                //個別相続税 ＝ 法定個別相続額 x 個別相続額をもとにした相続税率
                let inheritanceTax =
                  Math.floor((legalIndividualInheritance * taxRate[t][1]) / 100) -
                  taxRate[t][2] * 10000;
                inheritanceTax = Math.max(inheritanceTax, 0);
                //一次相続税
                let decedentInheritanceTax = this.inheritance.decedentInheritanceTax[index];
                //相次相続控除の計算。計算式は以下の通り
                //相次相続税控除
                // = 一次相続税 × 二次相続資産 ÷ (一次相続資産 - 一次相続税)
                // × (個別二次相続資産 ÷ 二次相続資産) × ((１０年 - 二次相続が発生する経過年) / １０年)
                let deduction = 0;
                if (
                  totalInheritancePrice != 0 &&
                  value != decedentInheritanceTax &&
                  this.commonInput.continuityInheritanceTerm < 10
                ) {
                  //例外対策
                  //(一次相続資産 - 一次相続税)よりも二次相続資産が大きい場合、(一次相続資産 - 一次相続税)を二次相続資産として計算
                  let diff = Math.max(totalInheritancePrice, value - decedentInheritanceTax);
                  deduction = Math.floor(
                    ((decedentInheritanceTax * //一次相続税
                      totalInheritancePrice) / //二次相続資産
                      diff) * //一次相続で故人が受け取った額－一次相続税
                    (individualInheritance / totalInheritancePrice) * //個別二次相続資産 ÷ 二次相続資産
                      ((10 - this.commonInput.continuityInheritanceTerm) / 10)
                  ); //経過年数比率
                }
                //二次相続税総額(控除額を差し引かない)を配列に格納。(テーブルに表示するため)
                this.inheritance.heirSecondaryInheritanceTax.push(inheritanceTax * secondaryHeir);
                //相次相続控除総額を配列に格納
                this.inheritance.inheritanceDeducition.push(deduction * secondaryHeir);
                //二次相続税総額(相次相続控除を差し引いた値)を配列に格納
                this.inheritance.totalSecondaryInheritanceTax.push(
                  Math.max((inheritanceTax - deduction) * secondaryHeir, 0)
                );
                //総合計（一次相続税の合計と二次相続税の合計の和）を配列に格納
                this.inheritance.totalInheritanceTax.push(
                  this.inheritance.totlaPrimaryInheritanceTax[index] +
                    this.inheritance.totalSecondaryInheritanceTax[index]
                );
                //テーブル表示用のプロパティに格納。単位は万円、小数は切り捨て
                let set = {
                  decedentInheritanceTax: Math.floor(
                    this.inheritance.decedentInheritanceTax[index] / 10000
                  ), //配偶者の一次相続税
                  heirPrimaryInheritanceTax: Math.floor(
                    this.inheritance.heirPrimaryInheritanceTax[index] / 10000
                  ), //子の一次相続税
                  totlaPrimaryInheritanceTax: Math.floor(
                    this.inheritance.totlaPrimaryInheritanceTax[index] / 10000
                  ), //一次相続税の合計(配偶者+子)
                  heirSecondaryInheritanceTax: Math.floor(
                    this.inheritance.heirSecondaryInheritanceTax[index] / 10000
                  ), //子の二次相続税(控除なし)
                  inheritanceDeducition: Math.floor(
                    this.inheritance.inheritanceDeducition[index] / 10000
                  ), //二次相続における相次相続控除
                  totalSecondaryInheritanceTax: Math.floor(
                    this.inheritance.totalSecondaryInheritanceTax[index] / 10000
                  ), //二次相続税(控除アリ)
                  totalInheritanceTax: Math.floor(
                    this.inheritance.totalInheritanceTax[index] / 10000
                  ), //総合計
                };
                this.tableDataOfInheritance.push(set);
              });
              //総合計の最小値と最大値の差を求める
              let min = Math.min(...this.inheritance.totalInheritanceTax);
              let max = Math.max(...this.inheritance.totalInheritanceTax);
              this.maxDifference = Math.round((max - min) / 10000);
              //総合計が最小値の行を探索する
              this.inheritance.totalInheritanceTax.forEach((value, index) => {
                if (min == value) {
                  this.minRow = index;
                }
              });
            } else {
              //二次相続なし
              this.inheritance.primaryIndividualInheritance.forEach((value, index) => {});
              this.maxDifference = 0;
            }
          } else {
            return;
          }
        } catch (e) {
          this.errorFlg = true;
          this.showError('E0001');
          console.error(e);
        }
      },
    },
    async mounted() {
      await this.$nextTick();
      // MOUNTED: 二次相続のパターンより、表に表示する名前を決める
      if (this.commonInput.continuityInheritanceFlg == 1) {
        switch (this.commonInput.secondaryInheritancePattern) {
          //配偶者 → 子
          case 1:
            this.secondPattern.decedent = '配偶者';
            this.secondPattern.heir = '子';
            break;
          //子 → 孫
          case 2:
            this.secondPattern.decedent = '子';
            this.secondPattern.heir = '孫';
            break;
          //子 → 子の兄弟姉妹
          case 3:
            this.secondPattern.decedent = '子';
            this.secondPattern.heir = '子の兄弟姉妹';
            break;
          //兄弟姉妹 → 兄弟姉妹の子
          case 4:
            this.secondPattern.decedent = '兄弟姉妹';
            this.secondPattern.heir = '兄弟姉妹の子';
            break;
        }
      }

      // MOUNTED: 価値評価リストを呼び出す
      const promiseList = this.propertyList.map(async val => {
        const result = await this.$db.propertyListInputs.get(val.id);
        return result.data;
      });
      this.inheritanceInfoList = await Promise.all(promiseList);

      // MOUNTED: リストのトータルプライスを
      this.inheritanceInfoList.forEach(item => {
        this.totalInheritanceTaxProperValue += item.totalPrice + item.houseFixedAssetTax;
        this.realTotalInheritanceTaxProperValue += item.totalPrice * 1.25;
      });

      const p = this.propertyList.map(async val => {
        const r = await this.$db.propertyListInputs.get(val.id);
        return r;
      });

      const result = await Promise.all(p);

      this.propertyDataList = result.map(val => val.data);

      // MOUNTED: 物件情報が10件を超えた場合、2つのリストに分ける
      this.inheritanceInfoList.forEach((element, index) => {
        if (index < 10) {
          this.inheritanceInfoListFirst.push(element);
        } else {
          this.inheritanceInfoListNext.push(element);
        }
      });

      // MOUNTED: 権利の評価明細計算のデータ生成
      const maximumNumRows = 25; // 最大行数
      const fixedNumRows = 4; // 1データあたりの固定表示分の行数（土地名(2行分)、テーブル項目欄、１データ表示後のマージン）
      const fixedNumRowsLeftTable = 2; // 計算概要（左テーブル）の固定表示行数
      let totalNumRows = 0; // 合計行数
      const pageData = []; // ページデータ
      this.rightEvaluationDetailsData = []; // 権利の評価明細計算のデータ（初期化）
      await this.propertyList.forEach(async (property, index, array) => {
        const inputData = (await this.$db.propertyListInputs.get(property.id)).data;
        // 現在のデータの行数を取得
        // ※1データあたりの固定表示分の行数 +（計算モードが１なら計算内容項目数、それ以外なら計算概要項目数(家屋の項目があれば+1)）
        const sumNumRows =
          fixedNumRows +
          (inputData.calcMode == 1
            ? inputData.resultValue.calcArray.length
            : fixedNumRowsLeftTable + (inputData.houseFixedAssetTaxFlg === 'true' ? 1 : 0));
        // 合計行数+現在のデータの行数が最大行数以下の場合
        if (totalNumRows + sumNumRows <= maximumNumRows) {
          // 現在のデータの行数を合計行数に加算
          totalNumRows += sumNumRows;
        }
        // 最大行数を超える場合
        else {
          // 権利の評価明細計算のデータにページデータを追加
          this.rightEvaluationDetailsData.push(pageData.concat());
          // 合計行数を現在のデータの行数にする
          totalNumRows = sumNumRows;
          // ページデータクリア
          pageData.splice(0);
        }
        // 現在のデータをページデータに追加
        pageData.push(new InheritanceInput(inputData));
        // 最後のデータの場合
        if (index === array.length - 1) {
          // 権利の評価明細計算のデータにページデータを追加
          this.rightEvaluationDetailsData.push(pageData.concat());
        }
      });
    },
    watch: {
      async selectNum() {
        this.showReport = false;
        await this.$nextTick();
        this.showReport = true;
      },
      realTotalInheritanceTaxProperValue: {
        handler: async function() {
          this.CalculateInheritanceTax();
        },
      },
    },
  };
</script>
<style scoped>
  .loop {
    display: inline;
  }
  .download-all {
    text-align: right;
  }
  .colorPoint {
    transition: 0.2s;
    background: rgba(var(--colorThemeRGB), 0.7);
    color: honeydew;
    font-weight: bold;
  }
  .report {
    margin: 0 auto;
  }
  .minTotalInheritance {
    background-color: lightcoral !important;
  }
</style>
