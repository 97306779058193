<template>
  <div>
    <div class="pv-config-label">反転</div>
    <div style="display: flex">
      <div style="flex: 50%">
        <span>左右</span>
        <input
          type="checkbox"
          v-model="activeObject.style.flipHorizontal"
          @change="e => flipX(e.target.checked)"
          :disabled="!isEditor"
        />
      </div>
      <div style="flex: 50%">
        <span>上下</span>
        <input
          type="checkbox"
          v-model="activeObject.style.flipVertical"
          @change="e => flipY(e.target.checked)"
          :disabled="!isEditor"
        />
      </div>
    </div>
    <div class="menu-btn-group">
      <TileBtn
        :block="false"
        height="30px"
        :selected="doorWindow === INTERIOR_MASTER_CATEGORY_TYPE.DOORS"
        @click="doorWindow = INTERIOR_MASTER_CATEGORY_TYPE.DOORS"
      >
        ドア
      </TileBtn>
      <TileBtn
        :block="false"
        height="30px"
        :selected="doorWindow === INTERIOR_MASTER_CATEGORY_TYPE.WINDOWS"
        @click="doorWindow = INTERIOR_MASTER_CATEGORY_TYPE.WINDOWS"
      >
        窓
      </TileBtn>
    </div>
    <div class="pv-config-label">厚み(px)</div>
    <div>
      <input
        type="number"
        style="width: 100%"
        :value="activeObject.style.strokeWidth"
        step="5"
        @input="e => (activeObject.style.strokeWidth = parseInt(e.srcElement.value))"
        :disabled="!isEditor"
      />
    </div>
    <div class="pv-config-label">高さ(m)</div>
    <div>
      <input
        type="number"
        min="0"
        max="10"
        step="0.1"
        style="width: 100%"
        :value="activeObject.height3d.toFixed(2)"
        @input="e => (activeObject.height3d = parseFloat(e.srcElement.value))"
        :disabled="!isEditor"
      />
    </div>
    <div class="pv-config-label">取付け高さ(m)</div>
    <div>
      <input
        type="number"
        min="0"
        max="10"
        step="0.1"
        style="width: 100%"
        :value="activeObject.mountingHeight.toFixed(2)"
        @input="e => (activeObject.mountingHeight = parseFloat(e.srcElement.value))"
        :disabled="!isEditor"
      />
    </div>
    <GridView
      class="mt-2"
      :items="doorListFiltered"
      :height="listHeight"
      :currentItem="currentDoor"
      @clickItem="door => changeType(door)"
      :isEditor="isEditor"
    >
      <template #item="{ item, index }">
        <div style="height: 110px">
          <svg width="70" height="70" viewBox="-20,-350,400,400">
            <path
              v-for="(path, j) in pathList[index]"
              :key="j"
              :d="path.d"
              :stroke="path.stroke"
              :stroke-width="path.strokeWidth"
              :stroke-dasharray="path.strokeDasharray"
              :fill="path.fill"
            />
          </svg>
          <div style="font-size: 10px">
            {{ item.name }}
          </div>
        </div>
      </template>
    </GridView>
  </div>
</template>
<script>
  // @ts-check
  import { computed, defineComponent, watch, ref } from '@vue/composition-api';
  import TileBtn from '../../PvBtn.vue';
  import ItemList from '../../ItemList.vue';
  import { doorGenerator } from '../../../../lib/node/tools/doorGenerator';
  import {
    INTERIOR_MASTER_CATEGORY_TYPE,
    ADDITIONAL_INFO_OPEN_TYPE,
    ADDITIONAL_INFO_KNOB_TYPE,
    ADDITIONAL_INFO_SLIDING_TYPE,
  } from '../../../../lib/util/const';
  import GridView from '../GridView.vue';

  export default defineComponent({
    components: {
      TileBtn,
      ItemList,
      GridView,
    },
    props: {
      activeObject: Object,
      height: Number,
      isEditor: {
        // PROP: 編集権限の有無
        type: Boolean,
        default: true,
      },
      doorWindowList: Array, // PROP: 建具リスト
    },
    setup(props, context) {
      const doorWindow = ref(INTERIOR_MASTER_CATEGORY_TYPE.DOORS); // DATA: 建具カテゴリ

      /**
       * METHOD: 建具アイコン左右反転
       */
      const flipX = (/** @type {string} */ val) => {
        props.activeObject.style.flipHorizontal = val;
        // ノブ方向反転
        switch (props.activeObject.additionalInfo.knob) {
          case ADDITIONAL_INFO_KNOB_TYPE.LEFT:
            props.activeObject.additionalInfo.knob = ADDITIONAL_INFO_KNOB_TYPE.RIGHT;
            break;
          case ADDITIONAL_INFO_KNOB_TYPE.RIGHT:
            props.activeObject.additionalInfo.knob = ADDITIONAL_INFO_KNOB_TYPE.LEFT;
            break;
        }
        // 引き方向反転
        switch (props.activeObject.additionalInfo.sliding) {
          case ADDITIONAL_INFO_SLIDING_TYPE.LEFT:
            props.activeObject.additionalInfo.sliding = ADDITIONAL_INFO_SLIDING_TYPE.RIGHT;
            break;
          case ADDITIONAL_INFO_SLIDING_TYPE.RIGHT:
            props.activeObject.additionalInfo.sliding = ADDITIONAL_INFO_SLIDING_TYPE.LEFT;
            break;
        }
      };

      /**
       * METHOD: 建具アイコン上下反転
       */
      const flipY = (/** @type {string} */ val) => {
        props.activeObject.style.flipVertical = val;
        // 開き方向反転
        switch (props.activeObject.additionalInfo.open) {
          case ADDITIONAL_INFO_OPEN_TYPE.INWARD:
            props.activeObject.additionalInfo.open = ADDITIONAL_INFO_OPEN_TYPE.OUTWARD;
            break;
          case ADDITIONAL_INFO_OPEN_TYPE.OUTWARD:
            props.activeObject.additionalInfo.open = ADDITIONAL_INFO_OPEN_TYPE.INWARD;
            break;
        }
      };

      /**
       * COMPUTED: リストの縦幅
       */
      const listHeight = computed(() => {
        return props.height - 320;
      });

      /**
       * COMPUTED: 建具カテゴリが変更されたら建具アイコンリストを更新する
       */
      const doorListFiltered = computed(() => {
        // @ts-ignore
        return props.doorWindowList.filter(door => door.type === doorWindow.value);
      });

      /**
       * COMPUTED: 選択された建具情報を取得する
       */
      const currentDoor = computed(() => {
        // @ts-ignore
        return props.doorWindowList.find(door => door.subType === props.activeObject.subType);
      });

      /**
       * WATCH: 選択中の建具が変更されたら建具カテゴリを更新する
       */
      watch([currentDoor], () => {
        doorWindow.value =
          // @ts-ignore
          props.doorWindowList.find(door => door.subType === props.activeObject.subType)?.type ??
          INTERIOR_MASTER_CATEGORY_TYPE.DOORS;
      });

      const pathList = computed(() => {
        return doorListFiltered.value.map(d => {
          // @ts-ignore
          return doorGenerator(d.subType, 300, 20);
        });
      });

      /**
       * METHOD: 建具が選択されたら建具情報を更新する
       */
      const changeType = (
        /** @type {import('../../../../lib/node/node/doorNode').PvDoor} */ door
      ) => {
        props.activeObject.subType = door.subType;
        props.activeObject.code = door.code;
        props.activeObject.additionalInfo = door.additionalInfo;
      };

      return {
        INTERIOR_MASTER_CATEGORY_TYPE,
        flipX,
        flipY,
        doorListFiltered,
        doorWindow,
        currentDoor,
        listHeight,
        pathList,
        changeType,
      };
    },
  });
</script>
<style scoped>
  .menu-btn-group {
    display: flex;
    font-size: 0.8rem;
  }
</style>
