<!--
作成者：本間
作成日：2021.02.01
画面名：投資分析/出力
機能：投資計画
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <b-tab id="InvestmentPlan" title="投資計画" active>
    <b-card class="card-result" title="投資計画(初期投資・想定詳細)">
      <b-container class="report">
        <b-row>
          <b-col class="text-right">
            単位：円
          </b-col>
        </b-row>
        <b-row>
          <b-col class="title emboss">項目1</b-col>
          <b-col class="title emboss">項目2</b-col>
          <b-col class="title emboss">項目3</b-col>
          <b-col class="title emboss" cols="1">数量</b-col>
          <b-col class="title emboss" cols="1">単位</b-col>
          <b-col class="title emboss">金額</b-col>
          <b-col class="title emboss">摘要</b-col>
        </b-row>
        <!-- 本体価格/明細 -->
        <b-row
          class="title"
          style="height:2px;border-top:1px solid black;border-left:1px solid black;border-right:1px solid black;
          background-color:"
        />
        <b-row
          v-for="(detail, index) in result.basePrice.details"
          v-bind:key="`basePrice_${detail.name}`"
        >
          <b-col class="subtitle" v-bind:style="index > 0 ? 'border-top-color:transparent;' : ''">
            {{ index == 0 ? '本体価格' : '' }}
          </b-col>
          <b-col class="subtitle">{{ detail.name }}</b-col>
          <b-col class="subtitle colspan" />
          <b-col class="value" cols="1" v-if="detail.amount == 0"></b-col>
          <b-col class="value" cols="1" v-else>{{ detail.quantity | currency }}</b-col>
          <b-col class="unit" cols="1">{{ detail.amount == 0 ? '' : detail.unit }}</b-col>
          <b-col class="value" v-if="detail.amount == 0"></b-col>
          <b-col class="value" v-else>{{ detail.amount | currency }}</b-col>
          <b-col>{{ detail.amount == 0 ? '' : detail.summary }}</b-col>
        </b-row>
        <!-- 本体価格/合計 -->
        <b-row>
          <b-col class="title" />
          <b-col class="title colspan" />
          <b-col class="title colspan" />
          <b-col class="title colspan emboss text-right">
            本体価格&nbsp;合計
          </b-col>
          <b-col class="title text-right">{{ result.basePrice.total | currency }}</b-col>
          <b-col class="title text-left">{{ result.basePrice.compositionRatio | ratio }}</b-col>
        </b-row>
        <!-- 工事費/明細 -->
        <b-row
          class="title"
          style="height:2px;border-top:1px solid black;border-left:1px solid black;border-right:1px solid black;
          background-color:"
        />
        <b-row
          v-for="(detail, index) in result.constructionCosts.details"
          v-bind:key="`constructionCosts_${detail.name}`"
        >
          <b-col class="subtitle" v-bind:style="index > 0 ? 'border-top-color:transparent;' : ''">
            {{ index == 0 ? '工事費' : '' }}
          </b-col>
          <b-col class="subtitle" cols="4"> {{ detail.name }}</b-col>
          <b-col class="value" cols="1" v-if="detail.amount == 0"></b-col>
          <b-col class="value" cols="1" v-else>{{ detail.quantity | currency }}</b-col>
          <b-col class="unit" cols="1">{{ detail.amount == 0 ? '' : detail.unit }}</b-col>
          <b-col class="value" v-if="detail.amount == 0"></b-col>
          <b-col class="value" v-else>{{ detail.amount | currency }}</b-col>
          <b-col>{{ detail.amount == 0 ? '' : detail.summary }}</b-col>
        </b-row>
        <!-- 工事費/合計 -->
        <b-row>
          <b-col class="title" />
          <b-col class="title colspan" />
          <b-col class="title colspan" />
          <b-col class="title colspan emboss text-right">
            工事費&nbsp;合計
          </b-col>
          <b-col class="title text-right">{{ result.constructionCosts.total | currency }}</b-col>
          <b-col class="title text-left">
            {{ result.constructionCosts.compositionRatio | ratio }}
          </b-col>
        </b-row>

        <!-- 付帯費用/明細 -->
        <b-row
          class="title"
          style="height:2px;border-top:1px solid black;border-left:1px solid black;border-right:1px solid black;
          background-color:"
        /><span
          v-for="(detail, index0) in result.incidentalExpenses.details"
          v-bind:key="`incidentalExpenses_${index0}`"
        >
          <span v-if="detail.details == undefined">
            <!-- 概算入力時 -->
            <b-row>
              <b-col class="subtitle"> {{ detail.name }}</b-col>
              <b-col class="subtitle colspan" />
              <b-col class="subtitle colspan" />
              <b-col class="value" cols="1" v-if="detail.amount == 0"></b-col>
              <b-col class="value" cols="1" v-else>{{ detail.quantity | currency }}</b-col>
              <b-col class="unit" cols="1">{{ detail.amount == 0 ? '' : detail.unit }}</b-col>
              <b-col class="value" v-if="detail.amount == 0"></b-col>
              <b-col class="value" v-else>{{ detail.amount | currency }}</b-col>
              <b-col>{{ detail.amount == 0 ? '' : detail.summary }}</b-col>
            </b-row>
          </span>
          <span v-else>
            <!-- 詳細入力時 -->
            <span
              v-for="(sub1, index1) in detail.details"
              v-bind:key="`incidentalExpenses_${index0}_${index1}`"
            >
              <span v-if="sub1.details == undefined">
                <b-row>
                  <b-col
                    class="subtitle"
                    v-bind:style="index1 == 0 ? '' : 'border-top-color:transparent;'"
                  >
                    {{ index1 == 0 ? detail.name : '' }}
                  </b-col>
                  <b-col class="subtitle" cols="4">
                    {{ sub1.name }}
                  </b-col>
                  <b-col class="value" cols="1" v-if="sub1.amount == 0"></b-col>
                  <b-col class="value" cols="1" v-else>{{ sub1.quantity | currency }}</b-col>
                  <b-col class="unit" cols="1">{{ sub1.amount == 0 ? '' : sub1.unit }}</b-col>
                  <b-col class="value" v-if="sub1.amount == 0"></b-col>
                  <b-col class="value" v-else>{{ sub1.amount | currency }}</b-col>
                  <b-col>{{ sub1.amount == 0 ? '' : sub1.summary }}</b-col>
                </b-row>
              </span>
              <span
                v-for="(sub2, index2) in sub1.details"
                v-bind:key="`incidentalExpenses_${index0}_${index1}_${index2}`"
              >
                <b-row>
                  <b-col
                    class="subtitle"
                    v-bind:style="index1 == 0 && index2 == 0 ? '' : 'border-top-color:transparent;'"
                  >
                    {{ index1 == 0 && index2 == 0 ? detail.name : '' }}
                  </b-col>
                  <b-col
                    class="subtitle"
                    v-bind:style="index2 == 0 ? '' : 'border-top-color:transparent;'"
                  >
                    {{ index2 == 0 ? sub1.name : '' }}
                  </b-col>
                  <b-col class="subtitle"> {{ sub2.name }}</b-col>
                  <b-col class="value" cols="1" v-if="sub2.amount == 0"></b-col>
                  <b-col class="value" cols="1" v-else>{{ sub2.quantity | currency }}</b-col>
                  <b-col class="unit" cols="1">{{ sub2.amount == 0 ? '' : sub2.unit }}</b-col>
                  <b-col class="value" v-if="sub2.amount == 0"></b-col>
                  <b-col class="value" v-else>{{ sub2.amount | currency }}</b-col>
                  <b-col>{{ sub2.amount == 0 ? '' : sub2.summary }}</b-col>
                </b-row>
              </span>
            </span>
            <!-- 小計 -->
            <b-row>
              <b-col class="subtitle" style="border-top-color:transparent;"> </b-col>
              <b-col class="subtitle" style="border-left-color:transparent;"> </b-col>
              <b-col class="subtitle" style="border-left-color:transparent;"> </b-col>
              <b-col class="subtitle" style="border-left-color:transparent;" cols="1" />
              <b-col class="subtitle text-right" style="border-left-color:transparent;" cols="1">
                小計
              </b-col>
              <b-col class="subtitle text-right">{{ detail.total | currency }}</b-col>
              <b-col class="subtitle">{{ detail.summary }}</b-col>
            </b-row>
          </span>
        </span>
        <!-- 付帯費用/合計 -->
        <b-row>
          <b-col class="title" />
          <b-col class="title colspan" />
          <b-col class="title colspan" />
          <b-col class="title colspan text-right emboss">
            付帯費用&nbsp;合計
          </b-col>
          <b-col class="title text-right">{{ result.incidentalExpenses.total | currency }}</b-col>
          <b-col class="title text-left">
            {{ result.incidentalExpenses.compositionRatio | ratio }}
          </b-col>
        </b-row>
        <!-- 総事業費 -->
        <b-row
          class="title"
          style="height:2px;border-top:1px solid black;border-left:1px solid black;border-right:1px solid black;
          background-color:"
        />
        <b-row>
          <b-col class="title" />
          <b-col class="title colspan" />
          <b-col class="title colspan" />
          <b-col class="title colspan text-right emboss">
            総事業費
          </b-col>
          <b-col class="title text-right "> {{ result.totalProjectCost | currency }}</b-col>
          <b-col class="title text-left ">{{ 100 | ratio }}</b-col>
        </b-row>
      </b-container>
    </b-card>
  </b-tab>
</template>
<script>
  import Calculation from '../Input/Calculation';
  export default {
    props: ['model'],
    mixins: [Calculation],
    data: function() {
      return { result: null };
    },
    created: function() {
      this.result = this.initialize();
    },
    mounted: function() {},
    filters: {
      // 構成比編集
      ratio(value) {
        return value == null || value == '' || value == undefined || Number.isNaN(value)
          ? null
          : '構成比 ' +
              Number(value)
                .toFixed(2)
                .padStart(6, ' ') +
              '%';
      },
    },
    methods: {
      // 入力チェック
      isEmpty: function(value) {
        return value == null || value == '' || value == undefined || Number.isNaN(value)
          ? true
          : false;
      },
      // 初期化
      initialize: function() {
        // 各項目計算
        let items = {
          basePrice: this.calcBasePrice(), // 本体価格
          constructionCosts: this.calcConstructionCosts(), // 工事費
          incidentalExpenses: this.calcIncidentalExpenses(), // 付帯費用
        };
        // 総事業費
        items.totalProjectCost =
          items.basePrice.total + items.constructionCosts.total + items.incidentalExpenses.total;
        // 構成比計算
        items.basePrice.compositionRatio = (items.basePrice.total / items.totalProjectCost) * 100;
        items.constructionCosts.compositionRatio =
          (items.constructionCosts.total / items.totalProjectCost) * 100;
        items.incidentalExpenses.compositionRatio =
          (items.incidentalExpenses.total / items.totalProjectCost) * 100;
        // [debug]計算結果
        //console.debug('[投資計画]');
        //console.debug(JSON.stringify(items));
        return items;
      },
      // 計算(本体価格)
      calcBasePrice: function() {
        let result = {
          name: '本体価格',
          details: [], // 詳細
          total: 0, // 合計
        };
        // 土地
        result.details.push({
          name: '土地',
          quantity: 1,
          unit: '式',
          amount: Number(this.investmentAnalytics.initialInvestment.tradingPrice[0]),
          summary: null,
        });
        if (this.investmentAnalytics.existingBuildingCount > 0) {
          // 既存建物
          result.details.push({
            name: '建物',
            quantity: 1,
            unit: '式',
            amount:
              // 付属建物が含まれているので引く
              this.tradingPriceBuilding -
              Number(this.investmentAnalytics.initialInvestment.tradingPrice[21]),
            summary: null,
          });
          // 付属建物
          result.details.push({
            name: '付属建物',
            quantity: 1,
            unit: '式',
            amount: Number(this.investmentAnalytics.initialInvestment.tradingPrice[21]),
            summary: null,
          });
          // 消費税
          result.details.push({
            name: '消費税',
            quantity: this.getTax('消費税率'),
            unit: '％',
            amount: this.tradingPriceBuildingTax,
            summary: null,
          });
        }
        // 合計
        result.total = result.details.reduce((a, v) => {
          return a + v.amount;
        }, 0);
        return result;
      },
      // 計算(工事費)
      calcConstructionCosts: function() {
        let result = {
          name: '工事費',
          details: [], // 詳細
          total: 0, // 合計
        };
        // 建築工事費
        if (
          this.investmentAnalytics.initialInvestment.constructionPlan.selected[0] == 1 &&
          this.investmentAnalytics.newBuildingCount > 0
        ) {
          let total = 0;
          for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
            total += Number(
              this.investmentAnalytics.initialInvestment.constructionPlan.newConstructionCost[i]
            );
          }
          result.details.push({
            name: '新築工事費',
            quantity: 1,
            unit: '式',
            amount: total,
            summary: null,
          });
        }
        // 修繕工事費
        if (
          this.investmentAnalytics.initialInvestment.constructionPlan.selected[1] == 1 &&
          !this.isEmpty(this.investmentAnalytics.initialInvestment.constructionPlan.repairWorkCost)
        ) {
          result.details.push({
            name: '修繕工事費',
            quantity: 1,
            unit: '式',
            amount: Number(
              this.investmentAnalytics.initialInvestment.constructionPlan.repairWorkCost
            ),
            summary: null,
          });
        }
        // 設備更新工事費
        if (
          this.investmentAnalytics.initialInvestment.constructionPlan.selected[2] == 1 &&
          !this.isEmpty(
            this.investmentAnalytics.initialInvestment.constructionPlan.equipmentUpdateCost
          )
        ) {
          result.details.push({
            name: '設備更新工事費',
            quantity: 1,
            unit: '式',
            amount: Number(
              this.investmentAnalytics.initialInvestment.constructionPlan.equipmentUpdateCost
            ),
            summary: null,
          });
        }
        // 造成工事費
        if (
          this.investmentAnalytics.initialInvestment.constructionPlan.selected[3] == 1 &&
          !this.isEmpty(
            this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0].amount
          )
        ) {
          result.details.push({
            name: '造成工事費',
            quantity: 1,
            unit: '式',
            amount: Number(
              this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0].amount
            ),
            summary: null,
          });
        }
        // 外構工事費
        if (
          this.investmentAnalytics.initialInvestment.constructionPlan.selected[4] == 1 &&
          !this.isEmpty(
            this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[1].amount
          )
        ) {
          result.details.push({
            name: '外構工事費',
            quantity: 1,
            unit: '式',
            amount: Number(
              this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[1].amount
            ),
            summary: null,
          });
        }
        // 建築付帯工事費
        let isEmpty = true; // 全部空ならtrue
        let total = 0; // 合計
        if (this.investmentAnalytics.initialInvestment.constructionPlan.selected[5] == 1) {
          isEmpty =
            isEmpty &&
            this.isEmpty(
              this.investmentAnalytics.initialInvestment.constructionPlan.constructionInciDentalWork
            );
          total += Number(
            this.investmentAnalytics.initialInvestment.constructionPlan.constructionInciDentalWork
          );
          if (!isEmpty) {
            result.details.push({
              name: '建築付帯工事費',
              quantity: 1,
              unit: '式',
              amount: total,
              summary: null,
            });
          }
        }
        // 道路切り下げ工事費
        if (
          this.investmentAnalytics.initialInvestment.constructionPlan.selected[6] == 1 &&
          !this.isEmpty(
            this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[6].amount
          )
        ) {
          result.details.push({
            name: '道路切り下げ工事費',
            quantity: 1,
            unit: '式',
            amount: Number(
              this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[6].amount
            ),
            summary: null,
          });
        }
        // 解体工事費
        isEmpty = true; // 全部空ならtrue
        total = 0; // 合計
        if (this.investmentAnalytics.initialInvestment.constructionPlan.selected[7] == 1) {
          for (
            let id = 0;
            id < this.investmentAnalytics.initialInvestment.constructionPlan.demolitionCount;
            id++
          ) {
            isEmpty =
              isEmpty &&
              this.isEmpty(
                this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[7 + id]
                  .amount
              );
            total += Number(
              this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[7 + id]
                .amount
            );
          }
          if (!isEmpty) {
            result.details.push({
              name: '解体工事費',
              quantity: 1,
              unit: '式',
              amount: total,
              summary: null,
            });
          }
        }
        // 土壌改良工事費
        if (
          this.investmentAnalytics.initialInvestment.constructionPlan.selected[8] == 1 &&
          !this.isEmpty(
            this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[17].amount
          )
        ) {
          result.details.push({
            name: '土壌改良工事費',
            quantity: 1,
            unit: '式',
            amount: Number(
              this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[17]
                .amount
            ),
            summary: null,
          });
        }
        // 地盤改良工事費
        if (
          this.investmentAnalytics.initialInvestment.constructionPlan.selected[9] == 1 &&
          !this.isEmpty(
            this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[18].amount
          )
        ) {
          result.details.push({
            name: '地盤改良工事費',
            quantity: 1,
            unit: '式',
            amount: Number(
              this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[18]
                .amount
            ),
            summary: null,
          });
        }
        // その他工事
        if (
          this.investmentAnalytics.initialInvestment.constructionPlan.selected[10] == 1 &&
          !this.isEmpty(
            this.investmentAnalytics.initialInvestment.constructionPlan.otherConstructionFee
          )
        ) {
          result.details.push({
            name: 'その他工事',
            quantity: 1,
            unit: '式',
            amount: Number(
              this.investmentAnalytics.initialInvestment.constructionPlan.otherConstructionFee
            ),
            summary: null,
          });
        }
        // 工事予備費
        if (
          this.investmentAnalytics.initialInvestment.constructionPlan.selected[10] == 1 &&
          !this.isEmpty(
            this.investmentAnalytics.initialInvestment.constructionPlan.constructionReserves
          )
        ) {
          result.details.push({
            name: '工事予備費',
            quantity: 1,
            unit: '式',
            amount: Number(
              this.investmentAnalytics.initialInvestment.constructionPlan.constructionReserves
            ),
            summary: null,
          });
        }
        // 消費税
        total = result.details.reduce((a, v) => {
          return a + v.amount;
        }, 0); // ここまでの合計
        result.details.push({
          name: '消費税',
          quantity: this.getTax('消費税率'),
          unit: '％',
          amount: this.tax(total),
          summary: null,
        });
        // 合計
        result.total = this.sumTax(total);
        return result;
      },
    },
  };
</script>
