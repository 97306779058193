// 軸設定
const axes = isReport => {
  return {
    // 金額(万円)
    money: {
      display: true,
      scaleLabel: {
        display: true,
        labelString: '万円',
        fontSize: isReport ? 70 : undefined,
      },
      ticks: {
        beginAtZero: true,
        fontSize: isReport ? 60 : undefined,
        maxRotation: 0,
        minRotation: 0,
        autoSkip: true,
        padding: 10 ,

        callback: function(value) {
          return Number(value).toLocaleString();
        },
      },
      gridLines: isReport
        ? {
            lineWidth: 10,
            zeroLineWidth:15,
            zeroLineColor: '#666',
            color:'#ccc',
          }
        : {},
    },
    // 年
    year: {
      display: true,
      scaleLabel: {
        display: true,
        labelString: '年',
        fontSize: isReport ? 70 : undefined,
      },
      ticks: {
        padding: 20 ,
        fontSize: isReport ? 50 : undefined,
      },
      gridLines: isReport
        ? {
            lineWidth: 10,
            color:'#ccc',
          }
        : {},
    },
  };
};

// ツールチップ設定
const tooltips = {
  // 年(タイトル)
  year: function(tooltipItem) {
    return tooltipItem[0].xLabel + '年目';
  },
  // 金額(万円⇒円)
  money: {
    // 折れ線グラフ
    line: function(tooltipItem, data) {
      if (isNaN(tooltipItem.value)) {
        return null;
      }
      var label = data.datasets[tooltipItem.datasetIndex].label || '';
      if (label) {
        label += ': ';
      }
      label += Math.round(Number(tooltipItem.value) * 10000).toLocaleString();
      return label;
    },
    // 棒グラフ
    bar: function(tooltipItem, data) {
      var label = data.datasets[tooltipItem.datasetIndex].label || '';
      if (label) {
        label += ': ';
      }
      // [xxxx,yyyy] => y,yyy
      var value = Number(tooltipItem.value.replace(new RegExp('^.*, *(.+)].*$'), '$1')) * 10000;
      if (isNaN(value)) {
        return null;
      }
      label += Math.floor(value).toLocaleString();
      return label;
    },
  },
};

const chartOptions = isReport => {
  return [
    // 住宅ローン残高
    {
      id: 0,
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
        text: 'ローン残高',
      },
      legend: {
        position: 'bottom',
        labels: isReport
          ? {
              fontSize: 70,
            }
          : {},
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          title: tooltips.year,
          label: tooltips.money.line,
        },
      },
      scales: {
        xAxes: [axes(isReport)['year']],
        yAxes: [axes(isReport).money],
      },
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    },
    // 月間収支の比較
    {
      id: 1,
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
        text: '月間収支の比較',
      },
      legend: {
        position: 'bottom',
        labels: isReport
          ? {
              fontSize: 70,
              padding: 70,
            }
          : {},
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          label: tooltips.money.bar,
        },
      },
      scales: {
        xAxes: isReport
          ? [
              {
                display: true,
                ticks: {
                  fontSize: 45,
                  padding:25,
                },
                gridLines: {
                  lineWidth: 10,
                  color:'#ccc',
                },
              },
            ]
          : [],
        yAxes: [axes(isReport).money],
      },
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    },
    // 土地の固定資産税比較(年間)
    {
      id: 2,
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: '土地の固定資産税比較(年間)',
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          label: function(tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
              label += ': ';
            }
            // [xxxx,yyyy] => y,yyy
            var value =
              Number(tooltipItem.yLabel.replace(new RegExp('^.*, *(.+)].*$'), '$1')) * 10000;
            if (isNaN(value)) {
              return null;
            }
            label += Math.round(value).toLocaleString();
            return label;
          },
        },
      },
      scales: {
        xAxes: isReport
          ? [
              {
                display: true,
                ticks: {
                  fontSize: 45,
                  padding: 25,
                },
                gridLines: {
                  lineWidth: 10,
                },
              },
            ]
          : [],
        yAxes: [axes(isReport).money],
      },
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    },
    // 現金収支累計
    {
      id: 3,
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
        text: '現金収支累計',
      },
      legend: {
        position: 'bottom',
        labels: isReport
          ? {
              fontSize: 70,
            }
          : {},
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          title: tooltips.year,
          label: tooltips.money.line,
        },
      },
      scales: {
        xAxes: [axes(isReport)['year']],
        yAxes: [axes(isReport).money],
      },
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    },
    // 賃貸経営安定度
    {
      id: 4,
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
        text: '賃貸経営安定度',
      },
      legend: {
        position: 'bottom',
        labels: isReport
          ? {
              fontSize: 70,
              padding:70,
            }
          : {},
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          label: function(tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
              label += ': ';
            }
            var value;
            switch (data.datasets[tooltipItem.datasetIndex].order) {
              case 1:
                // 損益分岐点空室率
                value = tooltipItem.yLabel;
                break;
              case 2:
                // 借入金額
                value = Math.round(tooltipItem.yLabel * 10000);
                break;
              default:
                return null;
            }
            label += Number(value).toLocaleString();
            return label;
          },
        },
      },
      scales: {
        xAxes: isReport
          ? [
              {
                ticks: {
                  padding:25,
                  fontSize: 42,
                },
                gridLines: {
                lineWidth: 10,
                color:'#ccc',
                },
              },
            ]
          : [],
        yAxes: [
          {
            id: 'LEFT',
            position: 'left',
            display: true,
            scaleLabel: {
              display: true,
              labelString: '万円',
              fontSize: isReport ? 70 : 0,
            },
            ticks: {
              beginAtZero: true,
              fontSize: isReport ? 70 : null,
              callback: function(value) {
                return Number(value).toLocaleString();
              },
            },
            gridLines: {
              lineWidth: 10,
              zeroLineWidth:15,
              zeroLineColor: '#666',
              color:'#ccc',
            },
          },
          {
            id: 'RIGHT',
            position: 'right',
            display: true,
            scaleLabel: {
              display: true,
              labelString: '％',
              fontSize: isReport ? 70 : 0,
            },
            ticks: {
              beginAtZero: true,
              fontSize: isReport ? 70 : null,
            },
          },
        ],
      },
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    },
    // 活用法別相続財産評価額（活用直後）
    {
      id: 5,
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
        text: '活用法別相続財産評価額（活用直後）',
      },
      legend: {
        display: false,
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          label: tooltips.money.line,
        },
      },
      scales: {
        xAxes: isReport
          ? [
              {
                display: true,
                ticks: {
                  fontSize: 45,
                  padding: 25,
                },
                gridLines: {
                  lineWidth: 10,
                },
              },
            ]
          : [],
        yAxes: [axes(isReport).money],
      },
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    },
    // 将来の活用法別相続財産評価額の推移
    {
      id: 6,
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
        text: '将来の活用法別相続財産評価額の推移',
      },
      legend: {
        position: 'bottom',
        labels: isReport
          ? {
              fontSize: 70,
            }
          : {},
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          title: tooltips.year,
          label: tooltips.money.line,
        },
      },
      scales: {
        xAxes: [axes(isReport)['year']],
        yAxes: [axes(isReport).money],
      },
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    },
    // 5年後の相続税額と収支累計
    {
      id: 7,
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
        text: '5年後の相続税額と収支累計',
      },
      legend: {
        position: 'bottom',
        labels: isReport
          ? {
              fontSize: 70,
              padding:70,
            }
          : {},
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          label: tooltips.money.bar,
        },
      },
      scales: {
        xAxes: [axes(isReport).money],
        yAxes: [
          {
            scaleLabel: {
              display: true,
            },
            ticks: {
              fontSize: isReport ? 70 : null,
            },
            gridLines: {
              lineWidth: 10,
              color:'#ccc',
              zeroLineWidth:10,
              zeroLineColor: '#ccc',
            },
          },
        ],
      },
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    },
    // 10年後の相続税額と収支累計
    {
      id: 8,
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
        text: '10年後の相続税額と収支累計',
      },
      legend: {
        position: 'bottom',
        labels: isReport
          ? {
              fontSize: 70,
              padding:70,
            }
          : {},
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          label: tooltips.money.bar,
        },
      },
      scales: {
        xAxes: [axes(isReport).money],
        yAxes: [
          {
            scaleLabel: {
              display: true,
            },
            ticks: {
              fontSize: isReport ? 70 : null,
            },
            gridLines: {
            lineWidth: 10,
            color:'#ccc',
            zeroLineWidth:10,
            zeroLineColor: '#ccc',
            }
          },
        ],
      },
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    },
    // 相続税支払後の残資産
    {
      id: 9,
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
        text: '相続税支払後の残資産',
      },
      legend: {
        position: 'bottom',
        labels: isReport
          ? {
              fontSize: 70,
              padding:70,
            }
          : {},
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          label: tooltips.money.bar,
        },
      },
      scales: {
        xAxes: isReport
          ? [
              {
                display: true,
                ticks: {
                  fontSize: 45,
                  padding:25,
                },
                gridLines: {
                  lineWidth: 10,
                  color:'#ccc',
                },
              },
            ]
          : [],

        yAxes: [axes(isReport).money],
      },
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    },
  ];
};

export default chartOptions;
