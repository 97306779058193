<template>
  <g>
    <path :d="d" fill="none" :stroke="color" stroke-width="10" />
    <path
      class="click-target"
      v-for="(target, i) in clickTargetDList"
      :key="i"
      :data-index="i"
      :d="clickTargetDList[i]"
      fill="none"
      stroke="transparent"
      stroke-width="20"
    />
  </g>
</template>
<script>
  // @ts-check
  import { computed, defineComponent, ref, toRefs } from '@vue/composition-api';
  import { Vec } from '../../lib/util/math/vector';
  import { nextCycleIndex } from '../../lib/util/util';
  import SVGSelect from './Parts/SVGSelect.vue';

  const BORDER_COLOR = '#22759f';

  export default defineComponent({
    components: {
      SVGSelect,
    },
    props: {
      obj: Object, // PvObject
    },
    setup(props) {
      const { obj } = toRefs(props);
      const color = ref(BORDER_COLOR);
      const r = ref(14);
      const d = computed(() => {
        const vertexes = obj.value.vertexes;
        if (vertexes.length < 4) {
          return '';
        }
        return `M0,0 h${vertexes[1].x} v${vertexes[2].y} h-${vertexes[2].x} v-${vertexes[3].x} Z`;
      });

      const clickTargetDList = computed(() => {
        const vertexes = obj.value.vertexes;
        if (vertexes.length < 4) {
          return '';
        }
        return [
          `M0,0 h${vertexes[1].x}`,
          `M${vertexes[1].x},0 v${vertexes[2].y}`,
          `M${vertexes[2].x},${vertexes[2].y} h-${vertexes[2].x}`,
          `M0,${vertexes[3].y} v-${vertexes[3].y}`,
        ];
      });

      const vertexCenter = computed(() => {
        /** @type {Vec[]} */
        const vertexes = obj.value.vertexes;
        return vertexes.map((v, i) => {
          const nextIndex = nextCycleIndex(i, vertexes.length);
          const next = vertexes[nextIndex];
          return next.sub(v);
        });
      });

      const topPosition = computed(() => {
        return vertexCenter.value[0]?.scale(1 / 2) ?? new Vec();
      });

      const buttonWidth = ref(120);
      const buttonHeight = ref(60);

      return {
        r,
        d,
        clickTargetDList,
        vertexCenter,
        topPosition,
        buttonWidth,
        buttonHeight,
        color,
      };
    },
  });
</script>
<style scoped>
  .click-target {
    /* cursor: move; */
    pointer-events: stroke;
  }
  .handle {
    cursor: pointer;
  }
  .handle:hover {
    stroke: skyblue;
  }
</style>
