import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { callUnity, CALL_COMMAND } from '@/js/ar/unity';
// import loadMaster from '@/js/loadMaster';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    saveFlg: false,
    master: {
      legalServiceLife: null,
      depreciationRate: null,
      inheritanceTax: null,
      taxRate: null,
      climingRate: null,
    },
    functions: null,
    showFurnitureSimulator: false,
    privilegedFlag: false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
        ? { signInUserSession: user.signInUserSession, attributes: user.attributes }
        : null;
    },
    setSaveFlg(state, saveFlg) {
      state.saveFlg = saveFlg;
    },
    setMaster(state, master) {
      state.master = master;
    },
    setFunctions(state, functions) {
      state.functions = functions;
    },
    setShowFurnitureSimulator(state, val) {
      state.showFurnitureSimulator = val;
    },
    // 非決済モードフラグをセット
    setPrivilegedFlag(state, privilegedFlag) {
      state.privilegedFlag = privilegedFlag;
    },
  },
  actions: {
    // console.log('this.state', this.state);

    /**
     *  FUNC: 家具シミュレーター起動
     */
    async startFurnitureSimulator() {
      // iOSアプリ上から呼ばれた場合、家具シミュレーターを起動する
      const result = callUnity(CALL_COMMAND.START_FURNITURE_SIMULATOR);
      // 起動できなかった場合、インストールを促すモーダルを表示
      if (!result) {
        // モーダル表示
        this.commit('setShowFurnitureSimulator', true);
        // 自動的にフラグを戻す
        setTimeout(() => {
          this.commit('setShowFurnitureSimulator', false);
        }, 1000);
      }
    },
  },
  getters: {
    /**
     *  FUNC: 管理者権限判定
     * @param {*} state
     * @returns
     */
    isAdmin(state) {
      const groups = state.user?.signInUserSession?.accessToken?.payload['cognito:groups'];
      if (groups && groups.includes('admin')) {
        return true;
      } else {
        return false;
      }
    },
  },
  // pagerの番号です。デフォルトを設定
  paginationNUmber: 1,
  plugins: [
    createPersistedState({
      key: 'vuex',
      paths: ['user', 'functions'],
      storage: window.sessionStorage,
    }),
  ],
});

export default store;
