var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CommonLayout',{attrs:{"title":"長期収支","companyLogo":_vm.companyLogo,"companyName":_vm.companyName},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticStyle:{"padding-left":"25%","padding-right":"10%"}},[_c('div',{staticStyle:{"width":"100%","height":"2800px","border-style":"none"}},[_c('ReportCanvas',{ref:"reportCanvas",staticStyle:{"padding-top":"100px","position":"absolute"},attrs:{"width":"2750px","height":"2750px"}},[_c('allyLineChart',{staticStyle:{"width":"3600px","height":"auto"},attrs:{"data":_vm.result ? _vm.result.chartData : null,"options":{
              legend: {
                reverse: true,
                position: 'bottom',
                labels: {
                  boxWidth: 50,
                  fontSize: 70,
                  padding: 50,
                },
              },
              scales: {
                yAxes: [
                  {
                    display: true,
                    scaleLabel: {
                      display: true,
                      labelString: '万円',
                      fontSize: 70,
                    },
                    ticks: {
                      beginAtZero: true,
                      callback: function(value) {
                        return Number(value / 10000).toLocaleString();
                      },
                      fontSize: 70,
                    },
                    gridLines: {
                      lineWidth: 10,
                      zeroLineWidth:15,
                      zeroLineColor: '#666',
                      color: '#ccc',
                    },
                  } ],
                xAxes: [
                  {
                    display: true,
                    scaleLabel: {
                      display: true,
                      labelString: '年',
                      fontSize: 70,
                    },
                    ticks: {
                      fontSize: 70,
                      maxRotation: 0,
                      minRotation: 0,
                      autoSkip: true,
                      autoSkipPadding: 10,
                      maxTicksLimit: 29,
                    },
                    gridLines: {
                      display: false,
                    },
                  } ],
              },
              animation: {
                duration: 0,
              },
              responsiveAnimationDuration: 0,
            },"styles":_vm.result ? _vm.result.chartStyles : null}})],1)],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }