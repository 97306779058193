// @ts-check

import { Rect } from '../../util/math/rect';
import { Vec } from '../../util/math/vector';
import { HandleFlg } from '../tools/handleFlg';
import { PvObject } from './pvObject';

export class PvIcon extends PvObject {
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'icon';
  handleFlg = new HandleFlg({
    vertex: false,
    edge: true,
    curve: false,
    rotate: true,
    outline: true,
  });
  /** @type {number}  PROPERTY: 平面上の横幅（オリジナル） */
  originalWidth = 1;
  /** @type {number}  PROPERTY: 平面上の縦幅（オリジナル） */
  originalHeight = 1;

  /** @type {string | null}  PROPERTY: 3Dモデルパス */
  modelPath;

  constructor() {
    super();
  }

  get ratio() {
    return this.originalHeight / this.originalWidth;
  }

  get iconWidth() {
    return this.width;
  }

  set iconWidth(val) {
    this.width = val;
    this.vertexes[1].x = val;
    this.vertexes[2].x = val;
  }

  get iconHeight() {
    return this.height;
  }

  set iconHeight(val) {
    this.height = val;
    this.vertexes[2].y = val;
    this.vertexes[3].y = val;
  }

  resetSize() {
    this.width = this.originalWidth;
    this.height = this.originalHeight;
    this.vertexes = new Rect(0, 0, this.width, this.height).toVecs();
  }

  /**
   * @param {number} index
   * @param {Vec} vec
   */
  moveVertexBy(index, vec) {
    if (index === 0) {
      const h = -vec.y + this.height;
      if (h <= 0) {
        return;
      }
      this.moveBy(new Vec(0, vec.y));
      this.height = h;
    } else if (index === 1) {
      const w = vec.x + this.width;
      if (w <= 0) {
        return;
      }
      this.width = w;
    } else if (index === 2) {
      const h = vec.y + this.height;
      if (h <= 0) {
        return;
      }
      this.height = h;
    } else if (index === 3) {
      const w = -vec.x + this.width;
      if (w <= 0) {
        return;
      }
      this.moveBy(new Vec(vec.x, 0));
      this.width = w;
    }
    this.vertexes = new Rect(0, 0, this.width, this.height).toVecs();
  }

  /**
   * @param {number} index
   * @param {Vec} vec
   */
  moveEdgeBy(index, vec) {
    if (index === 0) {
      const h = -vec.y + this.height;
      if (h <= 0) {
        return;
      }
      this.moveBy(new Vec(0, vec.y));
      this.height = h;
    } else if (index === 1) {
      const w = vec.x + this.width;
      if (w <= 0) {
        return;
      }
      this.width = w;
    } else if (index === 2) {
      const h = vec.y + this.height;
      if (h <= 0) {
        return;
      }
      this.height = h;
    } else if (index === 3) {
      const w = -vec.x + this.width;
      if (w <= 0) {
        return;
      }
      this.moveBy(new Vec(vec.x, 0));
      this.width = w;
    }
    this.vertexes = new Rect(0, 0, this.width, this.height).toVecs();
  }
}
