<template>
  <CommonLayout title="最後に">
    <div style="position: relative; height: 100%;">
      <div style="font-size: 90px; width: 100%; padding: 150px 100px 0px 170px;">
        <div v-if="comment" v-html="commentHTML"></div>
        <div v-if="!comment">
          ポジショニング分析は、収益性の高低を縦軸に、資産効率の高低を横軸にして、保有されている不動産の状況を可視化することで、潜在する課題の有無を明確に把握することを目的としています。
          なお、資産効率とは、不動産の相続評価に対して実勢価格（不動産の現在価値）が高いか、低いかという捉え方になります。相続評価より実勢価格が高い（資産効率が高い）のが<br />通常ですが、これが逆転している場合には、注意が必要になります。<br /><br />
          ＜カテゴリーA に分類されたもの＞<br />
          収益もしっかりと得られており、資産効率も高い優良不動産になります。
          現状の収益性及び価値を確保しつづけられるよう<br />適正な維持・管理が求められます。<br /><br />
          ＜カテゴリーB に分類されたもの＞<br />
          資産効率は高いものの、収益が充分に得られていない状況です。収益性を高めるための方策を考える必要があります。<br /><br />
          ＜カテゴリーC に分類されたもの＞<br />
          収益はしっかりと得られているものの、不動産の現在価値が低くなっています。価値を高めるか、関係者（賃借人等）に対して<br />売却することで市場価値よりも高値で換価するなどの方策を講じる必要性があります。<br /><br />
          ＜カテゴリーD に分類されたもの＞<br />
          充分な収益も得られておらず、資産価値も乏しい状況です。現状のままですと将来的にマイナスに働く可能性が高いため、<br />収益性と資産効率の改善または売却・譲渡（贈与等）の措置を早期に講じる必要があります。
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
  import Logo from '@/assets/report/reportImages/logo.png';
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import sanitizeHTML from 'sanitize-html';

  export default {
    props: {
      commonInput: Object,
      companyInfo: Array,
      comment: String,
    },
    data() {
      return {
        logo: Logo,
      };
    },
    computed: {
      companyName() {
        if (0 in this.companyInfo) {
          return this.companyInfo[0][1];
        }
        return '';
      },
      commentHTML() {
        if (this.comment == null) {
          return '';
        }
        const result = this.comment.replaceAll('\n', '<br/>');
        return sanitizeHTML(result);
      },
    },
    components: {
      CommonLayout,
    },
  };
</script>
