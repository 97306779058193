export const headerStyle = {
  border: 'solid black 1px',
  'background-color': '#1d1d63',
  color: 'white',
  'text-align': 'center',
  padding: '0 20px',
};
export const initialCostHeaderStyle = {
  border: 'solid black 1px',
  'background-color': '#1d1d63',
  color: 'white',
  'text-align': 'left',
  padding: '0 20px 0px 20px',
};
export const headerStyle2 = {
  border: 'solid black 1px',
  'background-color': '#1E90FF',
  color: 'white',
  'text-align': 'center',
  padding: '0 20px',
};
export const subHeaderStyle = {
  border: 'solid black 1px',
  'background-color': '#ccc',
  'text-align': 'center',
  padding: '0 20px',
};
export const bodyStyle = {
  border: 'solid black 1px',
  'padding-right': '3px',
  'background-color': 'white',
  'text-align': 'center',
  padding: '0 20px',
};
export const tableNumberStyle = {
  border: 'solid black 1px',
  'padding-right': '3px',
  'background-color': 'white',
  'text-align': 'right',
  padding: '0 4px',
};
export const tableInitialCostStyle = {
  border: 'solid black 1px',
  'background-color': 'white',
  'text-align': 'center',
  padding: '0 4px',
};
export const partHeaderStyle = {
  border: 'solid black 1px',
  'padding-right': '3px',
  'background-color': '#7a7a7a',
  color: 'white',
  'text-align': 'center',
  padding: '0 4px',
};
export const titleStyle = {// "総事業費"など、テーブルのタイトルのstyleを調節する
  'font-size': '4rem',
  float: 'left',
  'text-align': 'left',
  'margin-top': '20px',
};
export const unitPrice = { // "単位：円"のstyleを調節する
  'font-size': '3rem',
  float: 'right',
  'padding-top': '60px',
};
export const tableFont = { // テーブルの中のフォントstyleを調節する
  'font-size': '4rem',
  'font-weight': 'bold',
};
export const tableStyle = {  // テーブル全体のstyleを調節する
  width: '100%',
  'margin-bottom': '10px',
};
export const topHeaderStyle = { //  テーブル外のトップにあるヘッダーstyleを調節する
  'font-size': '4rem',
  border: 'solid black 1px',
  'background-color': '#1d1d63',
  color: 'white',
  'text-align': 'start',
  padding: '0 4px',
};
export const topHeaderStyle2 = { //  テーブル外のトップにあるヘッダーstyleを調節する
  'font-size': '4rem',
  border: 'solid black 1px',
  'background-color': '#1E90FF',
  color: 'white',
  'text-align': 'start',
  padding: '0 4px',
};
export const incomeAndExpenditureFont = { //  事業収支予測計算書用の共通サイズフォント
  'font-size': '1.8rem',
};
export const incomeAndExpenditureSmallFont = {  //  事業収支予測計算書（収支用）の小さいフォント
  'font-size': '1.8rem',
};
export const incomeAndExpenditureBigNumFont = {  //  事業収支予測計算書（収支用）の数値データ8桁以上用フォント
  'font-size': '1.8rem',
};
export const incomeAndExpenditureTotalFontUnitPrice = {   //  事業収支予測計算書（type=total かつ、単位文字用）の共通サイズフォント
  'font-size': '2.9rem',
  'text-align': 'end',
};
export const incomeAndExpenditureFontUnitPrice = {  //  事業収支予測計算書（収支用かつ、単位文字用）の小さいフォント
  'font-size': '2.9rem',
  'text-align': 'end',
};
export const investmentAnalyticsGraphPadding = { //  投資分析（満室想定）の各グラフの統一余白
  padding: '15px',
};
export const investmentAnalyticsGraphStyle = { //  投資分析（満室想定）の各グラフBox(項目名込み)のstyle
  height: '1200px',
  position: 'relative',
};
export const textRightStyle = {
  'text-align': 'right !important',
};
