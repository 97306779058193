<template>
  <div style="display: grid; font-size: 40px">
    <div v-if="Mode === 'income'">
      <div :style="titleStyle">
        収入の部
      </div>
      <div :style="unitPrice">
        単位：円
      </div>
      <table style="width: 100%;">
        <thead>
          <tr>
            <th :style="[headerStyle2, col1Style]" class="col1">項目1</th>
            <th :style="[headerStyle2, col2Style]" class="col2">項目2</th>
            <th :style="[headerStyle2, col3Style]" class="col3">数量</th>
            <th :style="[headerStyle2, col4Style]" class="col4">単位</th>
            <th :style="[headerStyle2]" class="col5">金額</th>
            <th :style="[headerStyle2, col6Style]" class="col6">摘要</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in singleYearBalanceIncomeList" :key="i">
            <td :style="[subHeaderStyle, col1Style]" class="col1">{{ !item || item.name }}</td>
            <td :style="[subHeaderStyle, col2Style]" class="col2"></td>
            <td :style="{ ...bodyStyle, ...col3Style, 'text-align': 'right' }" class="col3">
              {{ !item || item.quantity }}
            </td>
            <td :style="[bodyStyle, col4Style]" class="col4">
              {{ !item || item.unit }}
            </td>
            <td :style="{ ...bodyStyle, 'text-align': 'right' }">
              {{
                formatNum(item.total || item.amount, {
                  floatLength: 0,
                  separation: true,
                  rountType: 'down',
                })
              }}
            </td>
            <td :style="[bodyStyle, col6Style]" class="col6">
              {{ !item || item.summary }}
            </td>
          </tr>
        </tbody>
      </table>
      <table style="width: 100%">
        <tbody>
          <tr>
            <td :style="headerStyle2" style="width: 50%">合計</td>
            <td :style="{ ...headerStyle2, 'text-align': 'right' }" style="widht: 50%">
              {{
                formatNum(singleYearBalanceIncomeTotal, {
                  floatLength: 0,
                  separation: true,
                  rountType: 'down',
                })
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="Mode === 'spending'">
      <div :style="titleStyle">
        支出の部
      </div>
      <div :style="unitPrice">
        単位：円
      </div>
      <table style="width: 100%;">
        <thead>
          <tr>
            <th :style="[headerStyle2, col1Style]" class="col1">項目1</th>
            <th :style="[headerStyle2, col2Style]" class="col2">項目2</th>
            <th :style="[headerStyle2, col3Style]" style="'text-align': 'right'" class="col3">
              数量
            </th>
            <th :style="[headerStyle2, col4Style]" class="col4">単位</th>
            <th :style="[headerStyle2]" class="col5">金額</th>
            <th :style="[headerStyle2, col6Style]" class="col6">摘要</th>
          </tr>
        </thead>
        <tbody v-for="(item, i) in singleYearBalancePropertyTaxList" :key="i">
          <tr v-for="(detail, j) in item.items" :key="j">
            <td v-if="j === 0" :style="subHeaderStyle" :rowspan="item.items.length">
              {{ item.name }}
            </td>
            <td :style="subHeaderStyle">
              {{ detail.name }}
            </td>
            <td :style="{ ...bodyStyle, 'text-align': 'right' }">{{ detail.quantity }}</td>
            <td :style="bodyStyle">{{ detail.unit }}</td>
            <td :style="{ ...bodyStyle, 'text-align': 'right' }">
              {{
                formatNum(detail.amount, {
                  floatLength: 0,
                  separation: true,
                  rountType: 'down',
                })
              }}
            </td>
            <td :style="bodyStyle">{{ detail.summary }}</td>
          </tr>
        </tbody>
      </table>
      <table style="width: 100%">
        <tbody>
          <tr>
            <td :style="headerStyle2" style="width: 50%">合計</td>
            <td :style="{ ...headerStyle2, 'text-align': 'right' }" style="widht: 50%">
              {{
                formatNum(singleYearBalanceResult.spending.total, {
                  floatLength: 0,
                  separation: true,
                  rountType: 'down',
                })
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import {
    headerStyle,
    headerStyle2,
    partHeaderStyle,
    subHeaderStyle,
    bodyStyle,
    titleStyle,
    unitPrice,
    tableFont,
    tableStyle,
  } from '../../../../components/ExportReport/style';

  export default {
    props: {
      companyLogo: String,
      companyName: String,
      singleYearBalanceResult: Object,
      Mode: String,
    },

    components: {},
    data() {
      return {
        headerStyle,
        headerStyle2,
        partHeaderStyle,
        subHeaderStyle,
        bodyStyle,
        titleStyle,
        unitPrice,
        tableFont,
        tableStyle,
        col1Style: {
          width: '17%',
        },
        col2Style: {
          width: '25%',
        },
        col3Style: {
          width: '8%',
        },
        col4Style: {
          width: '8%',
        },
        col5Style: {
          'text-align': 'right',
          width: '17%',
        },
        col6Style: {
          width: '25%',
        },
      };
    },
    computed: {
      singleYearBalanceIncomeList() {
        if (this.singleYearBalanceResult == null) {
          return [];
        }
        return this.singleYearBalanceResult.income.items;
      },
      singleYearBalanceIncomeTotal() {
        if (this.singleYearBalanceResult == null) {
          return 0;
        }
        return this.singleYearBalanceResult.income.total;
      },
      singleYearBalancePropertyTaxList() {
        if (this.singleYearBalanceResult == null) {
          return [];
        }
        return this.singleYearBalanceResult.spending.items;
      },
      singleYearBalancePropertyTaxListItems() {
        //  v-ifとv-for併用エラー対策関数
        return this.items.filter(function(item) {
          return item.name !== undefined;
        });
      },
    },
  };
</script>
