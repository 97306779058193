export class InteriorMasterDataForAR {
  /** @type {number} */
  code;
  /** @type {string} */
  name;
  /** @type {number} */
  type;
  /** @type {number} */
  open;
  /** @type {number} */
  knob;
  /** @type {number} */
  sliding;
  /** @type {number} */
  bay;
  constructor(params = {}) {
    this.code = params.code ?? 0;
    this.name = params.name ?? '';
    this.type = params.type ?? 0;
    this.open = params.open ?? 0;
    this.knob = params.knob ?? 0;
    this.sliding = params.sliding ?? 0;
    this.bay = params.bay ?? 0;
  }
}
