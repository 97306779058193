import { render, staticRenderFns } from "./ReportCover.vue?vue&type=template&id=5af22699&scoped=true&"
import script from "./ReportCover.vue?vue&type=script&lang=js&"
export * from "./ReportCover.vue?vue&type=script&lang=js&"
import style0 from "./ReportCover.vue?vue&type=style&index=0&id=5af22699&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5af22699",
  null
  
)

export default component.exports