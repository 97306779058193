<template>
  <div>
    <b>メインページ</b>
    <div class="pt-4"></div>
    <div>
      <div>
        <div style="text-align: right;">
          <div v-show="editFlg">
            <b-btn class="btn btn-ally" @click="saveFavorite">保存</b-btn>
            <b-btn
              class="btn btn-ally ml-2"
              @click="
                () => {
                  editFlg = false;
                  favoriteItems = [...favoriteItemsTemp];
                }
              "
              >キャンセル</b-btn
            >
          </div>
          <b-btn
            v-show="!editFlg"
            class="btn btn-ally"
            style="width:30%;"
            @click="
              () => {
                editFlg = true;
                favoriteItemsTemp = [...favoriteItems];
              }
            "
            >編集</b-btn
          >
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body row" style="padding: 5px 5%">
          <div class="site-map-section">お気に入り</div>
          <Draggable
            v-model="favoriteItemsDisp"
            :group="{ name: 'siteItems', pull: 'clone', put: true }"
            :disabled="!editFlg"
            class="draggable-area mt-2"
            style="margin: 1rem 0 !important;"
          >
            <div
              v-if="favoriteItemsDisp.length === 0"
              :class="['empty-message-container', editFlg ? null : 'editable']"
            >
              <div v-if="editFlg === false" class="empty-message">
                編集ボタンを押してください
              </div>
              <div v-else class="empty-message">
                ここへドラッグアンドドロップ
              </div>
            </div>
            <template v-else>
              <div v-for="(item, i) in favoriteItemsDisp" :key="i" class="site-item-container">
                <div v-if="favoriteUpLoading">
                  <Load v-if="item.loadFlag == true" width='70px' height='70px' margin='50px 0px 100px'/>
                </div>
                <IconLink
                  :index="i"
                  :icon="item.icon"
                  :text="item.text"
                  :deletable="editFlg"
                  :disabled="editFlg || item.disabled"
                  @click="clickLink(item, null)"
                  @close="e => favoriteItems.splice(e, 1)"
                />
              </div>
            </template>
          </Draggable>
        </div>
      </div>
      <h4 class="mt-4"></h4>
      <div class="card" style="padding: 1rem 0 !important;">
        <div
          class="card-body row"
          style="padding: 0 5%"
          v-for="(listItems, index) of allItems"
          :key="index"
        >
          <hr style="margin-top:0;border:1px dashed lightgray" width="100%;" v-if="index == 3" />
          <template v-if="listItems.length !== 0">
            <Draggable
              class="draggable-area mt-2"
              v-model="listItems.lineItem"
              :group="{ name: 'siteItems', pull: 'clone', put: false }"
              sort
            >
              <div v-for="(item, i) in listItems.lineItem" :key="i" class="site-item-container">
                <div v-if="upLoading">
                  <Load v-if="item.loadFlag" width='70px' height='70px' margin='50px 0px 100px'/>
                </div>
                <IconLink
                  :icon="item.icon"
                  :text="item.text"
                  :disabled="editFlg || item.disabled"
                  :addible="editFlg && addible(item.text)"
                  @click="clickLink(item, upLodingID)"
                  @add="add"
                />
              </div>
            </Draggable>
          </template>
        </div>
      </div>
    </div>
    <allyAlert code="E0001"></allyAlert>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { Auth } from 'aws-amplify';
  import Draggable from 'vuedraggable';
  import IconLink from './IconLink';
  import Load from '../../components/Common/Load';
  import {
    commonItems,
    realEstateItems,
    propertyItems,
    companyInfoItems,
    helpItems,
    getMenuItems,
    checkLinkItem,
  } from '@/components/Common/siteMapItems.js';

  Auth.configure(awsconfig);

  export default {
    components: {
      Draggable,
      IconLink,
      Load,
    },
    data: function() {
      return {
        favoriteItems: [],
        favoriteItemsTemp: [],
        editFlg: false,
        functions: [],
        upLodingID: "upLoading",
        upLoading: false,
        favoriteUpLoading: false,
      };
    },
    computed: {
      allItems() {
        let items = [];
        items = items.concat(getMenuItems(commonItems, this.group, this.functions));
        items = items.concat(getMenuItems(realEstateItems, this.group, this.functions));
        items = items.concat(getMenuItems(propertyItems, this.group, this.functions));
        items = items.concat(getMenuItems(companyInfoItems, this.group, this.functions));
        items = items.concat(getMenuItems(helpItems, this.group, this.functions));

        for(let item of items){
          item.loadFlag = false;  //  「選択したアイテムか判別するフラグ」を初期化
        }
        
        return this.sortItems(items);
      },
      commonItems() {
        return getMenuItems(commonItems, this.group, this.functions);
      },
      realEstateItems() {
        return getMenuItems(realEstateItems, this.group, this.functions);
      },
      propertyItems() {
        return getMenuItems(propertyItems, this.group, this.functions);
      },
      companyInfoItems() {
        return getMenuItems(companyInfoItems, this.group, this.functions);
      },
      helpItems() {
        return getMenuItems(helpItems, this.group, this.functions);
      },
      // お気に入り
      favoriteItemsDisp: {
        set(input) {
          // 重複したオブジェクトを削除
          this.favoriteItems = Array.from(new Set(input));
        },
        get() {
          return this.favoriteItems;
        },
      },
      siteMap() {
        return [
          ...this.commonItems,
          ...this.realEstateItems,
          ...this.propertyItems,
          ...this.companyInfoItems,
          ...this.helpItems,
        ];
      },
      group() {
        const user = this.$store.state.user;
        if (user == null) {
          return null;
        }
        const userGroup = user.signInUserSession.accessToken.payload['cognito:groups'];
        if (userGroup.indexOf('systemAdmin') != -1) {
          return 'systemAdmin';
        } else if (userGroup.indexOf('admin') != -1) {
          return 'admin';
        } else {
          return 'member';
        }
      },
      user() {
        return this.$store.state.user;
      },
    },
    methods: {
      async saveFavorite() {
        this.editFlg = false;
        try {
          const user = await Auth.currentAuthenticatedUser();
          const favoriteKeys = this.favoriteItems.map(val => {
            return val.text;
          });
          await Auth.updateUserAttributes(user, {
            'custom:favorite': JSON.stringify(favoriteKeys),
          });
          this.$store.commit('setUser', {
            ...user,
            attributes: { ...user.attributes, 'custom:favorite': JSON.stringify(favoriteKeys) },
          });
        } catch (error) {
          console.error(error);
        }
      },
      async clickLink(item, id) {
        if(this.editFlg == true) return null;
        if (checkLinkItem(item)) {
          if(id == "upLoading"){ 
            this.upLoading = true; 
          }
          else{
            this.favoriteUpLoading = true;
          }
          item.loadFlag = true;
          this.$router.push(item.to);
        }
      },
      addible(text) {
        const item = this.favoriteItems.find(val => {
          return val.text === text;
        });
        return item == null;
      },
      add(target) {
        // 登録済みならリターン
        if (this.favoriteItems.find(val => val.text === target) != null) {
          return;
        }

        const item = this.siteMap.find(val => {
          return val.text === target;
        });

        this.favoriteItems.push(item);
      },

      /*==============================================
       *  ソートして配列化して返す。
       */
      sortItems(items) {
        let lineMax = -1; //lineの最大値を調べる。
        for (let item of items) {
          if (item.line != null) {
            if (lineMax < item.line) lineMax = item.line;
          }
        }
        let linesItems = []; //line毎に配列を切り分ける
        for (let i = 0; i < lineMax + 1; i++) {
          linesItems.push({
            lineItem: items.filter(item => item.line == i),
          });
        }

        //lineがNullのものを最後に表示する。
        let nullItems = items.filter(item => item.line == null);
        if (nullItems.length > 0) linesItems.push({ lineItem: nullItems });

        //配列をcolumn順にソート
        for (let i = 0; i < lineMax + 1; i++) {
          linesItems[i].lineItem.sort(function(a, b) {
            if (a.column == null) a.column = 99;
            if (b.column == null) b.column = 99;
            if (a.column < b.column) return -1;
            if (a.column > b.column) return 1;
            return 0;
          });
        }
        return linesItems;
      },
    },
    watch: {
      user: {
        handler() {
          try {
            const favoriteString = this.user.attributes['custom:favorite'];
            if (favoriteString == null) {
              this.favoriteItems = [];
            } else {
              const favoriteKeys = JSON.parse(favoriteString);
              const favoriteItems = favoriteKeys.map(val => {
                const result = this.siteMap.find(v => v.text === val);
                return result;
              });
              this.favoriteItems = favoriteItems.filter(val => val != null);
            }
          } catch (error) {
            console.error(error);
          }
        },
        deep: true,
        immediate: true,
      },
      'this.$store.state.functions': {
        handler: function() {
          this.$set(this, 'functions', this.$store.state.functions);
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
<style scoped>
  .draggable-area {
    min-height: 120px;
    min-width: 100%;
    padding-left: 5px;
    position: relative;
    margin-top: 0 !important;
  }

  .site-item-container {
    display: inline-table;
    padding-right: 15px;
    padding-bottom: 15px;
  }

  .site-map-section {
    font-size: 1.2rem;
    margin-left: 5px;
    margin-top: 5px;
    border-bottom: solid 1px black;
  }
  .save-btn {
    font-size: 17px;
  }

  .empty-message-container {
    position: absolute;
    left: 5px;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .empty-message {
    text-align: center;
    font-size: 20px;
    line-height: 120px;
    height: 100%;
    background-color: var(--colorTheme);
    color: white;
    animation: fadeIn 1s;
  }

  .empty-message:hover {
    text-align: center;
    font-size: 20px;
    line-height: 120px;
    height: 100%;
    background-color: var(--colorTheme);
    color: white;
    animation: fadeIn 1s;
  }

  .editable > .empty-message {
    background-color: #999;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1024px) {
    .site-map-section {
      font-size: 0.8rem;
      margin-left: 10px;
      border-bottom: solid 1px black;
    }

    .draggable-area {
      min-height: 120px;
      min-width: 100%;
      padding-left: 10px;
      position: relative;
      margin-top: 0 !important;
    }

    .site-item-container {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
</style>
