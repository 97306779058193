// @ts-check
import { EventDataset } from '../preset/eventDataset';
import { Vec } from '../../util/math/vector';
import { ActionBuilder } from './builder/actionBuilder';
import { Action, ActionContext } from './action';
import { NoneActionBuilder } from './builder/noneActionBuilder';

export class ActionManager {
  /** @type {Action} */
  current = new NoneActionBuilder().build();
  /** @type {Vec[]} */
  routes = [];
  /** @type {EventDataset[]} */
  eventDatasets = [];
  /** @type {() => void} */
  onAfterCompleteOrAbort;
  /** @type {() => void} */
  onAfterComplete;
  constructor({ onAfterCompleteOrAbort, onAfterComplete }) {
    this.onAfterComplete = onAfterComplete;
    this.onAfterCompleteOrAbort = onAfterCompleteOrAbort;
  }

  isDisabled() {
    return this.current.actionType === Action.ACTION_TYPE.NONE;
  }

  actionType() {
    return this.current.actionType;
  }

  /**
   * @param {ActionBuilder} builder
   */
  setAction(builder) {
    if (!this.isDisabled()) return;
    const val = builder.build();
    if (val == null) {
      return;
    }
    this.current = val;
    this.routes = [];
    this.eventDatasets = [];
  }

  checkStatus(dataset) {
    const actionContext = ActionContext.from(this.routes, this.eventDatasets);
    const status1 = Action.DONE[this.current.actionType](actionContext);
    const status2 = this.current.onAfterCheckStatus(actionContext, dataset, status1);

    return status2;
  }

  disable() {
    this.routes = [];
    this.eventDatasets = [];
    this.current = new NoneActionBuilder().build();
  }

  forceComplete() {
    this.routes.pop();
    this.current.onComplete(ActionContext.from(this.routes, this.eventDatasets));
    this.onAfterComplete();
    this.onAfterCompleteOrAbort();
    this.disable();
  }

  forceCancel() {
    this.current?.onAbort?.(ActionContext.from(this.routes, this.eventDatasets));
    this.onAfterCompleteOrAbort();
    this.disable();
  }

  /**
   * @param {Vec} currentPos
   * @param {EventDataset} dataset
   */
  update(currentPos, dataset) {
    return this.current.onUpdate(
      ActionContext.from([...this.routes, currentPos], [...this.eventDatasets, dataset])
    );
  }

  /**
   *
   * @param {Vec} position
   * @param {EventDataset} dataset
   * @returns {boolean}
   */
  advance(position, dataset) {
    const curerntAction = this.current;
    if (curerntAction.actionType === Action.ACTION_TYPE.CLICK2) {
      if (dataset.eventType !== 'click') {
        return false;
      }
    } else if (curerntAction.actionType === Action.ACTION_TYPE.DRAG_AND_DROP) {
      if (
        !(this.routes.length === 0 && dataset.eventType === 'dragstart') &&
        !(this.routes.length === 1 && dataset.eventType === 'dragend')
      ) {
        return false;
      }
    }

    if (this.isDisabled()) throw new Error('このアクションはdisabledです');
    const currentPos = position;
    this.routes.push(currentPos);
    this.eventDatasets.push(dataset);

    if (curerntAction.onAdvanceOrRollBack) {
      try {
        const result = curerntAction.onAdvanceOrRollBack(
          ActionContext.from([...this.routes, currentPos], [...this.eventDatasets, dataset]),
          dataset
        );
        if (result === false) {
          this.forceComplete();
        }
      } catch (error) {
        this.forceCancel();
        return true;
      }
    }

    switch (this.checkStatus(dataset)) {
      case Action.STATUS.COMPLETE: {
        try {
          curerntAction.onComplete(
            ActionContext.from([...this.routes, currentPos], [...this.eventDatasets, dataset])
          );
          this.disable();

          this.onAfterComplete();
          this.onAfterCompleteOrAbort();
        } catch (error) {
          this.forceCancel();
        }
        return true;
      }
      case Action.STATUS.INCOMPLETE: {
        return false;
      }
      case Action.STATUS.ROLLBACK: {
        this.routes.pop();
        return false;
      }
      case Action.STATUS.ABORT: {
        try {
          curerntAction.onAbort(
            ActionContext.from([...this.routes, currentPos], [...this.eventDatasets, dataset])
          );

          this.disable();
          this.onAfterCompleteOrAbort();
        } catch (error) {
          this.forceCancel();
        }
        return false;
      }
    }

    return true;
  }

  /**
   *
   * @param {Vec} currentPos
   * @param {EventDataset} dataset
   * @returns
   */
  rollback(currentPos, dataset) {
    const currentAction = this.current;
    if (this.routes.length <= 1) {
      this.forceCancel();
      return;
    }
    this.routes.pop();
    if (this.routes.length === 0) {
      this.disable();
      currentAction.onAbort(ActionContext.from(this.routes, this.eventDatasets));
      return;
    }
    currentAction.onUpdate(
      ActionContext.from([...this.routes, currentPos], [...this.eventDatasets, dataset])
    );
    if (currentAction.onAdvanceOrRollBack) {
      const result = currentAction.onAdvanceOrRollBack(
        ActionContext.from(this.routes, this.eventDatasets),
        null
      );
      if (result === false) {
        this.forceComplete();
      }
    }
  }
}
