// @ts-check

import { Transform } from '../../util/math/transform';
import { Vec } from '../../util/math/vector';
import { HandleFlg } from '../tools/handleFlg';
import { createNode } from './pvNode';
import { PvObject } from './pvObject';
import { HEIGHT3D_DEFAULT } from '../../util/const';

export class PvWall extends PvObject {
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'wall';
  code = 1002;
  handleFlg = new HandleFlg({
    vertex: true,
    edge: false,
    curve: false,
    rotate: false,
  });
  height3d = HEIGHT3D_DEFAULT.WALL;
  constructor() {
    super();
  }

  /**
   *
   * @param {Vec} start
   * @param {Vec} end
   * @returns {PvWall}
   */
  static fromStartEnd(start, end) {
    const wall = createNode(PvWall);
    const dist = end.sub(start);
    const { len, rotate } = Vec.vecToVecRotation(dist);
    wall.vertexes = [new Vec(), new Vec(len, 0)];
    wall.transform = new Transform({
      translate: start,
      rotate,
    });

    // @ts-ignore
    return wall;
  }

  /**
   *
   * @param {number} index
   * @param {Vec} vec
   */
  moveVertexBy(index, vec) {
    let first, last;
    if (index === 0) {
      first = this.vertexes[0].add(vec);
      last = this.vertexes[1].rotate(this.transform.rotateDeg);
      this.moveBy(vec);
    } else if (index === 1) {
      first = this.vertexes[0].clone();
      last = this.vertexes[1].rotate(this.transform.rotateDeg).add(vec);
    } else {
      throw new Error('インデックスが不正です。');
    }

    this.deformateFromStartEnd(last.sub(first));
  }

  /**
   * @param {Vec} vec
   */
  deformateFromStartEnd(vec) {
    const { len, rotate } = Vec.vecToVecRotation(vec);
    this.vertexes = [new Vec(), new Vec(len, 0)];
    this.transform = this.transform.cloneWith({
      rotate,
    });
  }
}
