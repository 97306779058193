// @ts-check

import { onMounted, onUnmounted, ref } from '@vue/composition-api';

export const useWindow = () => {
  const windowWidth = ref(0);
  const windowHeight = ref(0);

  const resize = () => {
    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;
  };

  onMounted(() => {
    resize();
    addEventListener('resize', resize);
  });

  onUnmounted(() => {
    removeEventListener('resize', resize);
  });

  return {
    windowWidth,
    windowHeight,
  };
};
