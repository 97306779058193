<template>
  <div
    class="export-report-root"
    :style="{
      position: 'relative',
      height: 252 * scale * REPORT_SCALE + 'mm',
      left: `calc(50% - 740px * ${scale * REPORT_SCALE})`,
    }"
    ref="previewReportContainer"
  >
    <div class="preview-report-container" :style="previewReportContainerStyle" ref="reportWindow">
      <div v-show="!loading">
        <carousel :per-page="1" ref="carousel">
          <slot name="default" :companyInfo="companyInfo"></slot
        ></carousel>
      </div>
      <div v-show="loading" style="margin: 25% 40%;">
        <load width="1000px" height="1000px"></load>
      </div>
      <button class="page-control-btn prev-btn" @click="prevPage" style="top: calc(50% - 500px)">
        <i class="ti-angle-double-left"></i>
      </button>
      <button class="page-control-btn next-btn" @click="nextPage">
        <i class="ti-angle-double-right"></i>
      </button>
      <button class="page-control-btn fullscreen-btn" @click="fullScreen">
        <i v-if="!isFullScreen" class="ti-fullscreen"></i>
        <i v-else class="ti-back-left"></i>
      </button>
      <button
        class="page-control-btn download-btn"
        @click="
          () => {
            $bvModal.show('confirmSaveReport1');
          }
        "
      >
        <i v-if="!isFullScreen && !loading" class="ti-download"></i>
      </button>
    </div>
    <!-- <div class="d-flex mt-3 pdf-btn-container">
      <button class="btn btn-ally" @click="convertPdf" :disabled="loading">
        {{ loading ? '処理しています' : 'PDFとして保存' }}
      </button>
    </div> -->
    <b-modal
      :ref="'confirmSaveReport1'"
      :id="'confirmSaveReport1'"
      header-class="ally-modal text-center"
      footer-class="ally-modal text-center"
      body-class="ally-input-background"
      title="レポート保存確認"
      @ok="clickDownloadPdf(true)"
      @cancel="clickDownloadPdf(false)"
      centered
      ok-title="保存してPDFダウンロード"
      cancel-title="保存せずPDFダウンロード"
      size="lg"
    >
      <div class="row">
        <div class="col-12" style="font-size:8mm">レポートデータを保存しますか？</div>
        <div class="col-12">
          <div>レポートデータを保存すると、同じレポートを「レポート管理」から</div>
          <div>ダウンロードすることが可能になります。</div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { createReportList } from '@/graphql/mutations';
  import { getS3Url } from '@/graphql/queries';
  import { convertReport } from '@/graphql/mutations';
  import awsconfig from '@/aws-exports';
  import load from '@/components/Common/Load';
  import axios from 'axios';
  //import html2pdf from 'html2pdf.js';
  import { getCompany } from '@/graphql/queries';
  import lzString from 'lz-string';
  import { REPORT_SCALE, REPORT_SIZE } from './const';

  API.configure(awsconfig);
  export default {
    props: {
      fileTitle: {
        type: String,
        required: true,
      },
      customerId: {
        type: String,
      },
      customerName: {
        type: String,
        required: true,
      },
      reportSize: {
        type: String,
        default: 'A4',
      },
    },
    components: {
      load,
    },
    data() {
      return {
        companyId: '',
        companyInfo: [],
        companyLogo: '',
        loading: false,
        isSaved: false,
        scale: 0.5,
        blob: null,
        downloadElement: null,
        isFullScreen: false,
        REPORT_SCALE,
        REPORT_SIZE,
      };
    },
    computed: {
      previewReportContainerStyle() {
        const { width, height, scale } = REPORT_SIZE[this.reportSize];
        let mg_x = 0;
        let mg_y = 0;
        if (this.$refs.reportWindow != null && this.isFullScreen) {
          mg_x = (innerWidth - this.$refs.reportWindow.clientWidth * (this.scale / scale)) / 2;
          mg_y = (innerHeight - this.$refs.reportWindow.clientHeight * (this.scale / scale)) / 2;
        }

        return {
          transform: `scale(${this.scale / scale})`,
          width: `calc(${width}mm * ${REPORT_SCALE * 1.3})`,
          height: `calc(${height}mm * ${REPORT_SCALE * 1.2})`,
          'background-color': '#444444',
          'transform-origin': 'left top',
          'padding-top': 80 * scale + 'mm',
          left: ' 50%',
          'margin-left': mg_x + 'px',
          'margin-top': mg_y + 'px',
        };
      },
    },
    methods: {
      prevPage() {
        this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage());
      },
      nextPage() {
        this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage());
      },
      fullScreen() {
        if (!this.isFullScreen) {
          this.$refs.previewReportContainer.requestFullscreen();
        } else {
          document.exitFullscreen();
        }
        this.isFullScreen = !this.isFullScreen;
      },
      reload() {
        location.reload();
      },
      // pdfをダウンロード
      async downloadPdf() {
        if (this.downloadElement == null) {
          this.showError('E0001');
          return;
        }

        this.downloadElement.click();
      },
      async clickDownloadPdf(reportSaveFlag) {
        const getPdfData = await this.convertPdf();
        if (reportSaveFlag == true) {
          this.saveReport(getPdfData);
        }

        const link = document.createElement('a');
        link.href = getPdfData.fileUrl;
        link.download = 'report.pdf';
        link.click();
      },
      /**
       * PDF変換
       */
      async convertPdf() {
        this.loading = true;

        const el = document.querySelectorAll('.report-page');

        let str = [];
        for (const t of el) {
          str.push(t.outerHTML);
        }

        try {
          var convertReportInput = {
            htmlStringList: str,
            styleString: '',
            companyId: await this.companyId.replace("Company-",""),
            reportSize: this.reportSize || 'A4',
          };

          console.log("convertReportInput:", convertReportInput);
          console.log("バイト数:" + encodeURIComponent(convertReportInput).replace(/%../g, "x").length);

          //lambdaでPDF変換を行う
          const result = await API.graphql(
            graphqlOperation(convertReport, {
              input: convertReportInput,
            })
          );

          let fileName ="Report/"+result.data.convertReport

          const resultAddPdfUrl = await API.graphql(
            graphqlOperation(getS3Url, {
              level: 'public',
              file: [
                {
                  fileName: fileName,
                  fileAction: 'View',
                },
              ],
            })
          );
          let getPdfData = resultAddPdfUrl.data.getS3Url[0];
        
          this.loading = false;
          return getPdfData;
        } catch (error) {
          console.error(error);
          this.showError('E0001');
          this.loading = false;
          return null;
        }
      },
      /**
       * レポート保存
       */
      async saveReport(getPdfData) {
        if (getPdfData?.fileName == null) {
          this.showError('E0001');
          return;
        }

        try {

          const input = {
            company: this.companyId,
            fileTitle: this.fileTitle,
            fileName: getPdfData?.fileName.replace("Report/",""),
            status: '1',
            mail: this.$store.state.user.attributes.email,
            customerId: this.customerId,
            customerName: this.customerName,
            owner: this.$store.state.user.signInUserSession.idToken.payload.sub,
          };

          await API.graphql(graphqlOperation(createReportList, { input }));

          return;
        } catch (error) {
          console.error(error);
          this.showError('E0001', error);
          return;
        }
      },
      addPageNum() {
        const pageNumberElements = document.getElementsByClassName('report-page-number');

        for (let i = 0; i < pageNumberElements.length; i++) {
          pageNumberElements.item(i).innerText = `${i + 1}/${pageNumberElements.length}`;
        }
      },
      generateUuid() {
        let chars = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.split('');
        for (let i = 0, len = chars.length; i < len; i++) {
          switch (chars[i]) {
            case 'x':
              chars[i] = Math.floor(Math.random() * 16).toString(16);
              break;
            case 'y':
              chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
              break;
          }
        }
        return chars.join('');
      },
      getScale(clientWidth) {
        if ((clientWidth * 1.7) / 3600 >= REPORT_SCALE) {
          return 0.85 / REPORT_SCALE;
        }
        return (clientWidth * 1.8) / (3000 * REPORT_SCALE);
      },
      async getCompany() {
        this.companyId = this.$store.state.user.signInUserSession.accessToken.payload[
          'cognito:groups'
        ].find(val => val.match(/^Company-*./));

        let result;
        try {
          result = await API.graphql(
            graphqlOperation(getCompany, {
              id: this.companyId.replace('Company-', ''),
            })
          );
        } catch (e) {
          this.showError('E0001', e);
          return;
        }

        const companyInfo = result.data.getCompany;

        this.companyInfo = [
          ['会 社 名', companyInfo ? companyInfo.companyName : ''],
          ['住　　所', companyInfo ? companyInfo.address.address1 + companyInfo.address.address2 : ''],
          ['担当者名', '担当者'],
          ['電話番号', companyInfo ? companyInfo.tel : ''],
        ];

        var link = await API.graphql(
          graphqlOperation(getS3Url, {
            level: 'public',
            file: [{ fileName: this.fileName, fileAction: 'View' }],
          })
        );
        this.companyLogo = link.data.getS3Url[0].fileUrl;
      },
    },
    mounted() {
      this.scale = this.getScale(this.$el.clientWidth);
      addEventListener('resize', () => {
        this.scale = this.getScale(this.$el.clientWidth);
      });
      this.getCompany();
      this.$nextTick(() => {
        // TODO:
        setTimeout(this.addPageNum, 500);
      });
    },
    destroyed() {
      removeEventListener('resize', () => {});
    },
  };
</script>
<style scoped>
  .pdf-btn-container {
    position: absolute;
    top: 44vw;
  }

  .page-control-btn {
    background-color: transparent;
    border: 0;
    color: #777777;
    position: absolute;
    text-shadow: 1px 0 8px gray;
    transition: 600ms ease all;
  }

  .page-control-btn:hover {
    transition: 600ms ease all;
    background-color: transparent;
    border: 0;
    color: #777777;
    position: absolute;
    text-shadow: 2px 0 35px #dddddd;
  }

  .prev-btn {
    font-size: 700px;
    top: calc(50% - 100px);
    left: 1%;
    transform: scale(0.8, 1);
  }

  .next-btn {
    font-size: 700px;
    top: 43%;
    left: 89%;
    transform: scale(0.8, 1);
  }

  .fullscreen-btn {
    font-size: 350px;
    left: 92%;
    top: 88%;
  }

  .download-btn {
    font-size: 350px;
    left: 2%;
    top: 88%;
  }
</style>
<style>
  .VueCarousel-pagination {
    transform: scale(5);
  }
  .preview-report-container {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    font-family: "BIZ UDPGothic";
  }
</style>
