import { render, staticRenderFns } from "./DoorMenu.vue?vue&type=template&id=50856308&scoped=true&"
import script from "./DoorMenu.vue?vue&type=script&lang=js&"
export * from "./DoorMenu.vue?vue&type=script&lang=js&"
import style0 from "./DoorMenu.vue?vue&type=style&index=0&id=50856308&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50856308",
  null
  
)

export default component.exports