<template>
  <CommonLayout title="ご挨拶">
    <template v-slot>
      <span style="font-weight: bold; font-size: 100px">{{
        (commonInput.customerName || '********') + ' 様'
      }}</span>
      <div
        style="
                    font-size: 90px;
                    padding: 0px 100px 0px 120px;
                    margin-top: -60px;
                "
      >
        <br />
        <br />
        この度はポートフォリオ分析のご用命を賜り、誠に有難うございます。
        <br />
        公簿調査、現地調査、聞取り調査、市場調査、評価、査定等をもとにポートフォリオ分析が完了いたしましたので<br />ご報告申し上げます。
        <br />
        {{
          `ポートフォリオ分析は、不動産の相続評価と市場価値、収益性に着眼し、現状保有されている不動産の改善点を明らかにすることで、不動産の運用・活用、相続対策等をされる際の参考にしていただくことを目的としております。ポジショニング分析で特にB・C・Dに分類された不動産については、何らかの対策を講じられることが${commonInput.customerName}様にとって有益に働くものと考えますので、引き続きサポートさせていただければ幸いです。`
        }}<br /><br />
        なお、本書には相続評価を記載している箇所がございますが、あくまでも一般的な事例をもとにした参考値であり、個別具体的に評価したものではございません。また、実勢価格についても不動産鑑定評価によるものではなく、近傍類似不動産の取引情報を<br />参考としながら現時点で仮に売却される場合の参考値となります。個別具体的な評価・査定を必要とされる際には、<br />必ず税理士や不動産鑑定士にご相談ください。
        <br /><br />
        <div style="font-size: 90px; ">
          ※ご注意ください<br />
          ・本レポートでは、小規模宅地その他の特例は考慮していません。<br />
          ・本レポートに記載された税金に関する情報や税額計算のシミュレーションにより得られた税額を起因として、ご利用者または<br />&nbsp;&thinsp;第三者が損害を被った場合であっても、当社は一切の責任を負いかねます。
        </div>
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';

  export default {
    props: {
      commonInput: {
        type: Object,
        required: true,
      },
      companyInfo: {
        type: Array,
        required: true,
      },
    },
    components: {
      CommonLayout,
    },
    data() {
      return {};
    },
    computed: {
      companyName() {
        if (0 in this.companyInfo) {
          return this.companyInfo[0][1];
        }

        return '';
      },
    },
  };
</script>
