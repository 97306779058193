<template>
  <div>
    <div class="row" v-if="hideSearchBox === undefined">
      <div class="col-6 offset-6">
        <b-input-group>
          <b-button
            class="offset-left-auto serch-object"
            variant="ally"
            @click="resetSearch"
            :disabled="loading"
          >
            <i class="ti-reload" />
          </b-button>
          <b-input-group-prepend>
            <b-dropdown :text="filter.targetLabel" variant="ally" :disabled="loading">
              <b-dropdown-item-button
                v-for="header in headers.filter(x =>
                  x.searchable !== undefined ? x.searchable : true
                )"
                :key="header.key"
                :value="header.key"
                @click="
                  filter.targetKey = header.key;
                  filter.targetLabel = header.label;
                  filter.textValue = null;
                "
                >{{ header.label }}</b-dropdown-item-button
              >
            </b-dropdown>
          </b-input-group-prepend>
          <b-form-input
            :disabled="loading"
            v-model="filter.textValue"
            :state="filter.textValue ? (filterError ? false : null) : null"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="ally" @click="() => {}" :disabled="loading">
              <i class="ti-search"></i>
            </b-button>
          </b-input-group-append>
          <b-form-invalid-feedback>{{ filterError }}</b-form-invalid-feedback>
        </b-input-group>
      </div>
    </div>
    <div class="row serch-data-table-top">
      <div class="col-12">
        <b-table
          thead-tr-class="serch-data-table-header"
          tbody-tr-class="serch-data-table-body"
          :busy="loading"
          hover
          striped
          :items="currentPageItems"
          :fields="fields"
          show-empty
          responsive="sm"
        >
          <template v-slot:table-busy>
            <Load />
          </template>
          <template v-slot:empty>
            <div class="row">
              <div class="col-12 text-center">
                <svg
                  class="bi bi-exclamation-diamond-fill"
                  width="8em"
                  height="8em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <div class="alert">データがありません</div>
              </div>
            </div>
          </template>
          <template v-slot:cell()="row">
            <div v-for="header in [headerInfo(row.field.key)]" :key="header ? header.key : ''">
              <b-button
                :variant="header.button.variant ? header.button.variant : 'ally'"
                @click="customEvent(header.button.event, row)"
                v-if="header.button"
                :disabled="header.button.disabled || (header.key != 'edit' && !row.item.right)"
              >
                {{ header.button.label ? header.button.label : getTdText(header, row) }}
              </b-button>
              <div v-if="!header.button">
                {{ getTdText(header, row) }}
              </div>
              <b-form-checkbox
                v-if="header.checkbox"
                size="lg"
                v-model="checked[row.index]"
                @change="customEvent(header.checkbox.event, row)"
              >
              </b-form-checkbox>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      :ref="targetName + 'deleteModal'"
      title="削除確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="削除"
      @ok="deleteEvent()"
    >
      削除しますか？
    </b-modal>
  </div>
</template>

<script>
  import * as query from '../../../graphql/queries';
  import { API, graphqlOperation } from 'aws-amplify';
  import Load from '../../../components/Common/Load';

  export default {
    // headers: テーブルのヘーダ
    // targetName: テーブル名
    // labelEnums: 値のenum
    // hidePrint: 出力ボタン表示プラグ
    // hideDelete: 削除ボタン表示プラグ
    // hideSearchBox: 検索ボックス表示フラグ
    // maxCount: 最大件数
    // dateDispState:  グリニッジ時間を日本時間に変更 null→日付のみ "time"→日付と時間 "wareki"→和暦で表示 "warekiTime"→和暦で表示（時間表示あり）
    props: [
      'headers',
      'targetName',
      'labelEnums',
      'hidePrint',
      'hideDelete',
      'filterFix',
      'hideSearchBox',
      'maxCount',
      'dateDispState',
    ],
    data: function() {
      return {
        // 最大同時取得レコード数
        RECORDS_LIMIT: 60,
        // テーブルデータ（内部）
        items: [],
        // ロード中プラグ
        loading: true,
        // フィルターデータ
        filter: {
          targetKey: null,
          targetLabel: '選択',
          textValue: null,
          searchValue: null,
        },
        deleteTarget: null,
        // 現在表示中のページ
        currentPage: 0,
        // チェック状態
        checked: [],
        completeFirstLoad: false,
      };
    },
    mounted: async function() {
      // MOUNTED: 非同期で取得
      this.listItems();
    },
    computed: {
      // COMPUTED: b-tableヘッダデータ
      fields() {
        const result = this.headers.map(x => {
          return {
            key: x['key'],
            label: x['label'],
          };
        });
        // 詳細、削除ボタン追加処理
        if (this.hideDelete === undefined) result.push({ key: 'delete', label: '削除' });

        return result;
      },
      // COMPUTED: クエリ名
      listQueryName() {
        return 'list' + this.targetName + 's';
      },
      // COMPUTED: クエリ本文
      listQuery() {
        return query[this.listQueryName];
      },
      // COMPUTED: フィルターエラー
      filterError() {
        if (!this.filter.targetKey) return '条件を選択して下さい';
        else return null;
      },
      // COMPUTED: 表示用テーブルデータ
      dispItems() {
        let newDispItems = this.items;
        var fiterArray = null;
        if (this.filter.targetKey != null && this.filter.textValue != null) {
          //検索ヘッダーがネストされている場合、ネストの最終項目を検索する
          if (this.filter.targetKey.includes('.')) {
            //ネスト項目は検索ができない為、ネストの最終オブジェクトを取り出す
            fiterArray = this.filter.targetKey.split('.');
            newDispItems = newDispItems.filter(item => {
              //最終ネストまでループする
              for (var i = 0; i < fiterArray.length - 1; i++) {
                item = item[fiterArray[i]];
              }
              return (
                //最終ネストで検索項目（ヘッダー）をセット、一致する文字列を探す
                item[fiterArray[fiterArray.length - 1]]?.includes(this.filter.textValue || '') ||
                false
              );
            });
          } else {
            if (this.filter.textValue != '') {
              //ネストされてない場合、現状の項目で検索を行う
              newDispItems = newDispItems.filter(item => {
                return item[this.filter.targetKey]?.includes(this.filter.textValue || '') || false;
              });
            }
          }
        }

        // 日付データを変更
        newDispItems.forEach(element => {
          var keys = Object.keys(element);
          keys.forEach(key => {
            //表示の一律変更の指定
            var dispState = null;
            if (key == 'createdAt' || key == 'updatedAt' || key.indexOf('Date') != -1) {
              if (this.dateDispState == null || this.dateDispState == 'date') {
                dispState = 'date';
              } else if (this.dateDispState == 'time') {
                dispState = 'time';
              } else if (this.dateDispState == 'wareki') {
                dispState = 'wareki';
              } else if (this.dateDispState == 'warekiTime') {
                dispState = 'warekiTime';
              }
            }
            //個別に時間表示形式が指定されているとき
            this.headers.forEach(headKey => {
              if (headKey.key == key && headKey.dispState != null) {
                dispState = headKey.dispState;
              }
            });

            //条件が満たしているカラムの表示を設定する。
            if (dispState != null) {
              var date = new Date(element[key]);
              if (dispState == 'date') {
                element[key] =
                  date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate() + '';
              } else if (dispState == 'time') {
                element[key] =
                  date.getFullYear() +
                  '/' +
                  (date.getMonth() + 1) +
                  '/' +
                  date.getDate() +
                  '' +
                  ' ' +
                  ('0' + date.getHours()).slice(-2) +
                  ':' +
                  ('0' + date.getMinutes()).slice(-2);
              } else if (dispState == 'wareki') {
                element[key] = this.setSeirekiToWareki(
                  date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
                );
              } else if (dispState == 'warekiTime') {
                element[key] =
                  this.setSeirekiToWareki(
                    date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
                  ) +
                  ' ' +
                  ('0' + date.getHours()).slice(-2) +
                  '時 ' +
                  ('0' + date.getMinutes()).slice(-2) +
                  '分';
              }
            }
          });
        });

        return newDispItems;
      },
      // COMPUTED: 現在のページアイテム
      currentPageItems() {
        // ページのインデックス
        const from = this.currentPage * this.RECORDS_LIMIT;
        const to = this.currentPage * this.RECORDS_LIMIT + this.RECORDS_LIMIT;

        return this.dispItems.slice(from, to);
      },
    },
    methods: {
      /**
       * METHOD: アイテム取得
       */
      getItems: async function(nextToken) {
        const queryVar = {
          limit: this.RECORDS_LIMIT, // 最大同時取得レコード数
        };

        if (this.filterFix) {
          // 検索条件が存在する場合、検索条件をセット
          queryVar.filter = this.filterFix;
        }
        // ネクストトークンをセット
        queryVar.nextToken = nextToken;
        // データ取得
        let queryResult = null;
        if (this.listQueryName === 'listReportLists' && this.getGroup() === 'member') {
          // ターゲット名が”ReportList”でグループがmemberの場合
          queryVar.filter = {
            owner: { contains: this.$store.state.user.signInUserSession.idToken.payload.sub },
          };
          queryResult = await API.graphql(graphqlOperation(this.listQuery, queryVar));
        } else {
          // それ以外
          queryResult = await API.graphql(graphqlOperation(this.listQuery, queryVar));
        }
        const result = queryResult.data[this.listQueryName.replace('ArMeasures', 'ARMeasures')];
        this.items = [...this.items, ...result.items];
        return result.nextToken;
      },
      /**
       * METHOD: 初期化処理
       */
      listItems: async function() {
        this.loading = true;
        this.items = [];
        this.currentPage = 0;
        let nextToken = null;

        // 初回の読み込みが終わった時点で
        nextToken = await this.getItems(nextToken);
        this.loading = false;

        // すべてのデータを読み込むまでループ
        while (nextToken != null) {
          nextToken = await this.getItems(nextToken);
        }

        this.loading = false;
        this.completeFirstLoad = true;

        const id = this.$store.state.user.attributes.sub;
        const userLevel = this.getUserAuthLevel();
        this.items = this.items.map(it => {
          it.right =
            it.guestIdList?.findIndex(v => id === v) >= 0 ||
            it.ownerId === id ||
            (it.ownerId == null && it.guestIdList == null) ||
            userLevel === 'admin';
          return it;
        });

        //得られたリストをcreatedAtでソート
        this.sortList();
      },
      /**
       * METHOD: getUserAuthLevel
       */
      getUserAuthLevel: function() {
        var userGroup = new Array();
        userGroup = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        if (userGroup.indexOf('systemAdmin') != -1) {
          return 'systemAdmin';
        } else if (userGroup.indexOf('admin') != -1) {
          return 'admin';
        }
        return 'member';
      },
      /**
       * METHOD: カスタムイベント発生
       */
      customEvent: function(event, row) {
        this.$emit(event, row.item);
      },

      /**
       * METHOD: 削除イベント発生
       */
      deleteEvent: function() {
        this.$emit('deleteClicked', this.deleteTarget.item);
      },
      /**
       * METHOD: setdeleteTarget
       */
      setdeleteTarget: function(row) {
        this.deleteTarget = row;
      },
      /**
       * METHOD: 検索
       */
      search: async function() {
        if (this.filterError) return;
        this.dispItems = this.item;
      },
      /**
       * METHOD: セルのヘッダー情報を返します
       */
      headerInfo: function(key) {
        const header = this.headers.find(x => x.key == key);
        if (header) return header;
        return null;
      },
      /**
       * METHOD: enumからテキストを呼び出す
       */
      getTdText: function(header, row) {
        const enumType = header.enumType;
        const dataType = header.dataType;
        const callback = header.callback;
        const value = row.value;

        if (callback) return callback(header, row);
        if (dataType == 'Date') return value.toString().split('T')[0];
        return enumType ? this.labelEnums[enumType][value] : value;
      },
      /**
       * METHOD: 検索リストをリロード
       */
      resetSearch: async function() {
        // 検索条件初期化
        this.filter.searchValue = null;
        // 検索入力欄の初期化
        this.filter.textValue = null;
        // 検索種別ラベル初期化
        this.filter.targetLabel = '選択';
        // 検索種別キー初期化
        this.filter.targetKey = null;
      },
      /**
       * METHOD: リストのソート
       */
      sortList: function() {
        this.items = this.items.sort(function(a, b) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
      },
      /**
       * METHOD: 和暦データ
       */
      warekiChange: function(dateInput) {
        var _dateInput = new Date(dateInput), // 入力値
          _eras = [
            {
              name: '令和',
              kana: 'れいわ',
              initial: 'R',
              begin: '2019-05-01',
              end: '9999-12-31',
            },
            {
              name: '平成',
              kana: 'へいせい',
              initial: 'H',
              begin: '1989-01-08',
              end: '2019-04-30',
            },
            {
              name: '昭和',
              kana: 'しょうわ',
              initial: 'S',
              begin: '1926-12-25',
              end: '1989-01-07',
            },
            {
              name: '大正',
              kana: 'たいしょう',
              initial: 'T',
              begin: '1912-07-30',
              end: '1926-12-24',
            },
            {
              name: '明治',
              kana: 'めいじ',
              initial: 'M',
              begin: '1868-10-23',
              end: '1912-07-29',
            },
          ];
        // filterで絞り込み
        return _eras.filter(function(item) {
          var _dateBegin = new Date(item.begin + ' 00:00:00 +0900'),
            _dateEnd = new Date(item.end + ' 23:59:59 +0900'),
            _year = 0;

          // Date.getTimeを使って開始後かつ終了前を判定
          if (
            _dateInput.getTime() >= _dateBegin.getTime() &&
            _dateInput.getTime() <= _dateEnd.getTime()
          ) {
            // 年数を計算
            _year = _dateInput.getFullYear() - _dateBegin.getFullYear() + 1;

            // 1年なら元年にしてプロパティに追加
            item.year = _year;

            return item;
          }
        })[0];
      },
      /**
       * METHOD: setSeirekiToWareki
       */
      setSeirekiToWareki: function(seirekiDate) {
        if (seirekiDate != null) {
          //西暦を和暦データに変換
          var wareki = this.warekiChange(seirekiDate);
          var date = new Date(seirekiDate);

          if (wareki != null) {
            //変換した和暦データをセット
            var result = {
              wareki: wareki.name,
              year: String(wareki.year),
              month: String(date.getMonth() + 1),
              date: String(date.getDate()),
            };
            return result.wareki + result.year + '年 ' + result.month + '月' + result.date + '日';
          }
        }
      },
      /**
       * METHOD: チェックされたものを取得
       */
      getChecked: function() {
        let ret = [];
        for (let i = 0; i < this.checked.length; i++) {
          if (this.checked[i]) {
            ret.push(this.items[i]);
          }
        }
        return ret;
      },
    },
    watch: {
      // WATCH: items
      items: {
        handler() {
          this.$emit('itemsChanged', this.items);
          // チェックボックス初期化
          this.checked.length = this.items.length;
          for (let i = 0; i < this.checked.length; i++) {
            this.checked[i] = false;
          }
        },
      },
      // WATCH: 初回ロード完了時
      completeFirstLoad() {
        // 初回ロード完了時点で発火
        if (this.completeFirstLoad === true) {
          this.$emit('completeFirstLoad');
        }
      },
    },
    components: {
      Load,
    },
  };
</script>
