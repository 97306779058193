<template>
  <div>
    <h4 class="font-weight-bold">ALLY 不動産ポートフォリオ分析</h4>
    <div v-show="!isReportPreview">
      <div class="row grid-margin mt-2" style="margin-right:-5px; margin-left:-5px;">
        <div class="row">
          <div class="col-12">
            <router-link to="/DashBoard" tag="button" class="btn btn-ally mx-2 float-right">
              メインページへもどる
            </router-link>
            <!--
            <button class="btn btn-ally mx-2 float-right" v-b-modal="'masterModal'">
              共通データ確認
            </button>
            -->
            <button
              id="input-menu-save-overwrite"
              class="btn btn-ally mx-2 float-right"
              @click="saveOverwrite"
              :disabled="inputDataId == null"
            >
              上書き保存
            </button>
            <button
              id="input-menu-save"
              class="btn btn-ally mx-2 float-right"
              @click="saveInputModal"
            >
              新規保存
            </button>
            <button class="btn btn-ally mx-2 float-right" @click="openLoadModal">
              入力データロード
            </button>
          </div>
        </div>
      </div>
      <div>
        <b-alert
          :show="dismissCountDown"
          dismissible
          fade
          variant="success"
          @dismiss-count-down="countDownChanged"
        >
          {{ alertMessage }}
        </b-alert>
      </div>
      <div class="pt-1">
        <ValidationObserver tag="div" ref="inputCheck">
          <CommonInput v-model="commonInput" />
          <b-card class="mt-5">
            <b-container class="">
              <b-row>
                <b-col>
                  <div style="text-ailgn: center">
                    <ROAChart
                      class="mb-4"
                      v-if="showChart"
                      ref="chart"
                      :chartValue="chartValueList"
                      :width="500"
                      :height="500"
                    />
                  </div>
                </b-col>
                <b-col>
                  <b-container>
                    <b-row style="padding-top: 4px;">
                      <b-col cols="12">
                        <div class="hint">
                          <div>A: 保持・継続利用</div>
                          <div>B: 戦略的保有等</div>
                          <div>C: 関係者に売却等</div>
                          <div>D: 売却検討</div>
                        </div>
                      </b-col>
                      <b-col cols="12" class="mt-4">
                        <allyText
                          title="割引率"
                          name="centerValue"
                          append="％"
                          v-model="centerValue"
                          rules="required|decimals"
                          type="number"
                          :loading="loading"
                          min="0"
                          separation
                          roundType="down"
                        />
                      </b-col>
                      <!-- <b-col cols="12">
                        <allyTextArea
                          rules="max:300"
                          title="備考"
                          rows="7"
                          name="comment"
                          v-model="comment"
                          :loading="loading"
                        />
                      </b-col> -->
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
          <b-card class="mt-5">
            <div class="d-flex mt-2" style="margin-right:15px;">
              <b-btn
                class="ally btn-ally ml-auto"
                @click="editNewInput"
                :disabled="inputList.length === 20"
                >追加</b-btn
              >
            </div>
            <Input
              :centerValue="centerValue"
              :inputList.sync="inputList"
              :valueList.sync="valueList"
              :warekiFlg.sync="warekiFlg"
              class="mt-1"
              :isReport="false"
              @click-edit="
                e => {
                  this.editIndex = e;
                  input = new RoaInput(JSON.parse(JSON.stringify(inputList[e])));
                  $refs.addInput.show();
                }
              "
              @click-delete="e => removeInput(e)"
            />
          </b-card>
        </ValidationObserver>
      </div>
    </div>
    <div v-if="isReportPreview">
      <b-card>
        <b-container cols="12">
          <allyTextArea
            rules="max:300"
            title="コメント ※最後のページに挿入されます"
            rows="7"
            name="comment"
            v-model="comment"
            :loading="loading"
          />
        </b-container>
        <ReportPreview
          :fileTitle="commonInput.reportTitle"
          :customerId="commonInput.customerId"
          :customerName="commonInput.customerName"
          :saveS3="false"
          class="report-preview"
          ref="reportPreview"
        >
          <template #default="{companyInfo}">
            <slide
              ><ReportCover
                :title="commonInput.reportTitle"
                :commonInput="commonInput"
                :companyInfo="companyInfo"
            /></slide>
            <slide>
              <ReportIntro :commonInput="commonInput" :companyInfo="companyInfo" />
            </slide>
            <slide>
              <ReportROAInput
                :inputList="inputList"
                :valueList="valueList"
                :warekiFlg="warekiFlg"
                type="inheritanceTaxBase"
              ></ReportROAInput>
            </slide>
            <slide>
              <ReportROAInput
                :inputList="inputList"
                :valueList="valueList"
                :warekiFlg="warekiFlg"
                type="prevailingPriceBase"
              ></ReportROAInput>
            </slide>
            <slide>
              <ReportROAChart
                :chartValues="chartValueList"
                :comment="comment"
                ref="chart2"
              ></ReportROAChart>
            </slide>
            <slide>
              <ReportEndMessage
                :commonInput="commonInput"
                :companyInfo="companyInfo"
                :comment="comment"
              />
            </slide>
          </template>
        </ReportPreview>
      </b-card>
    </div>
    <div class="d-flex mt-4">
      <b-btn v-if="!isReportPreview" class="ml-auto btn btn-ally" @click="showPreview"
        >プレビュー</b-btn
      >
      <b-btn
        v-if="isReportPreview"
        class="ml-auto btn btn-ally"
        @click="isReportPreview = !isReportPreview"
        >入力画面へ</b-btn
      >
    </div>
    <allyAlert :code="'E108'"></allyAlert>
    <allyAlert :code="'E109'"></allyAlert>
    <allyAlert code="E0001"></allyAlert>
    <b-modal
      id="saveModal"
      title="保存データ選択"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="保存"
      @ok.prevent="saveInputInDynamo"
      no-close-on-backdrop
    >
      <ValidationObserver tag="div" slim ref="saveInputCheck" class="row">
        <b-form-group style="margin-left:5px">
          <allyText title="タイトル" name="inputTitle" v-model="inputTitle" rules="required" />
        </b-form-group>
      </ValidationObserver>
    </b-modal>
    <b-modal
      id="masterModal"
      size="xl"
      title="共通データ確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-only
      ok-variant="light"
      ok-title="確認"
      no-close-on-backdrop
    >
      <Master />
    </b-modal>
    <b-modal
      size="lg"
      ref="loadModal"
      title="入力データ"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      ok-variant="light"
      ok-title="キャンセル"
      ok-only
      no-close-on-backdrop
    >
      <b-card>
        <b-table hover :items="items" :fields="inputFields">
          <template #cell(edit)="row">
            <b-button
              size="sm"
              @click="loadInputFromDynamo(row)"
              class="btn btn-ally mr-1 action-btn"
            >
              ロード
            </b-button>
          </template>
          <template #cell(delete)="row">
            <b-button
              size="sm"
              @click="deleteInputFromDynamo(row)"
              class="btn btn-ally mr-1 action-btn"
            >
              削除
            </b-button>
          </template>
        </b-table>
      </b-card>
    </b-modal>
    <b-modal
      size="lg"
      ref="addInput"
      title="物件情報登録"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      @ok="addInput"
      ok-title="追加"
      cancel-title="キャンセル"
      no-close-on-backdrop
    >
      <template #default="{visible}">
        <b-card>
          <ValidationObserver ref="roaInput">
            <ROATable v-if="visible" :roaInput.sync="input" />
          </ValidationObserver>
        </b-card>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import ReportPreview from '@/components/ExportReport/ReportPreview.vue';
  import ReportROAChart from './Report/ROAChart';
  import ReportROAInput from './Report/ROAInput';
  import ReportCover from '@/components/ExportReport/ReportCover';
  import ReportIntro from './Report/Intro';
  import ReportEndMessage from './Report/EndMessage';
  import CommonInput from '@/components/ProperAnalytics/CommonInput';
  import Input from './Input';
  import ROAChart from './ROAChart';
  import RoaInput from './inputClass';
  import ROATable from './ROATable';
  import { API, graphqlOperation } from 'aws-amplify';
  import {
    createAnalyticsInput,
    deleteAnalyticsInput,
    updateAnalyticsInput,
  } from '@/graphql/mutations';
  import { listAnalyticsInputs } from '../../graphql/queries';

  class CommonInputClass {
    constructor() {
      this.reportTitle = '';
      this.reportDate = '';
      this.customerName = '';
      this.customerId = '';
      this.staffName = '';
    }
  }

  export default {
    components: {
      CommonInput,
      Input,
      ROAChart,
      ReportPreview,
      ReportROAInput,
      ReportROAChart,
      ReportCover,
      ReportIntro,
      ReportEndMessage,
      ROATable,
    },
    data() {
      return {
        warekiFlg: false,
        comment: '',
        centerValue: 0,
        showChart: true,
        companyId: '',
        items: [],
        loading: false,
        input: new RoaInput(),
        editIndex: null,
        RoaInput: RoaInput,
        inputFields: [
          {
            key: 'commonInput.reportDate',
            label: '作成日',
          },
          { key: 'inputTitle', label: 'タイトル' },
          { key: 'commonInput.reportTitle', label: 'レポート名' },
          { key: 'commonInput.customerName', label: '顧客名' },
          { key: 'edit', label: '' },
          { key: 'delete', label: '' },
        ],
        inputDataId: null, // 入力データID
        inputTitle: null,
        commonInput: new CommonInputClass(),
        isReportPreview: false,
        inputList: [],
        valueList: [
          {
            chartValue: {
              x: null,
              y: null,
            },
            inheritanceTaxBase: {
              No8: null,
              No10: null,
              No15: null,
              No16: null,
              No18: null,
              No19: null,
              No20: null,
            },
            prevailingPriceBase: {
              No8: null,
              No10: null,
              No19: null,
              No20: null,
            },
            baseSelected:''
          },
        ],
        nonActiveButtonStyle: {
          'background-color': 'white',
          color: 'black',
        },
        listPage: this.valueList,
        // 読込/保存時のメッセージ
        dismissSecs: 5,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        alertMessage: null,
      };
    },
    computed: {
      chartValueList() {
        if (this.valueList.length === 0) {
          return [];
        }
        return this.valueList.map(value => {
          return value.chartValue;
        });
      },
    },
    methods: {
      async updateChart() {
        this.showChart = false;
        await this.$nextTick();
        this.showChart = true;
      },
      async saveInputModal() {
        if (!(await this.$refs.inputCheck.validate())) {
          this.showError('E108');
          return;
        }
        this.$bvModal.show('saveModal');
      },
      // 上書き保存
      saveOverwrite: async function() {
        const input = {
          id: this.inputDataId,
          inputTitle: this.inputTitle,
          commonInput: this.commonInput,
          inputType: 'roa',
          author: this.$store.state.user.attributes.sub,
          roaInput: this.inputList.map((input, i) => {
            return {
              ...input,
              value: this.valueList[i],
            };
          }),
          comment: this.comment,
        };
        console.debug({ input: input });
        // 保存実行
        await API.graphql(
          graphqlOperation(updateAnalyticsInput, {
            input: input,
          })
        )
          .catch(e => {
            // 例外発生時
            console.debug('overwrite exception.');
            console.debug({ e: e });
            this.showError('E0001', e); // システムエラー
          })
          .then(() => {
            // 正常時
            this.showAlert('上書き保存しました。（タイトル：' + this.inputTitle + '）');
          });
      },
      async openLoadModal() {
        await this.listItems();
        this.$refs.loadModal.show('loadModal');
      }, //ユーザ入力項目の保存処理
      async saveInputInDynamo() {
        const input = {
          inputTitle: this.inputTitle,
          commonInput: this.commonInput,
          inputType: 'roa',
          author: this.$store.state.user.attributes.sub,
          roaInput: this.inputList.map((input, i) => {
            return {
              ...input,
              value: this.valueList[i],
            };
          }),
          comment: this.comment,
        };

        try {
          const result = await API.graphql(
            graphqlOperation(createAnalyticsInput, {
              input,
            })
          );

          // 入力データID保持
          this.inputDataId = result.data.createAnalyticsInput.id;
          this.showAlert('保存しました。（タイトル：' + this.inputTitle + '）');
        } catch (error) {
          console.error(error);
          this.showError('E0001', error);
          return;
        }

        this.$bvModal.hide('saveModal');
      },
      async loadInputFromDynamo(input) {
        this.commonInput = input.item.commonInput;
        this.inputList = input.item.roaInput.map(val => new this.RoaInput(val));
        this.comment = input.item.comment;
        this.centerValue = input.item?.roaInput.length > 0 ? input.item.roaInput[0].centerValue : 0;

        // 入力データID＆タイトル保持
        this.inputDataId = input.item.id;
        this.inputTitle = input.item.inputTitle;

        this.$refs.loadModal.hide();
      },
      async deleteInputFromDynamo(e) {
        try {
          await API.graphql(
            graphqlOperation(deleteAnalyticsInput, {
              input: {
                id: e.item.id,
              },
            })
          );
        } catch (error) {
          this.showError('E1000', error);
          return;
        }

        // 表示リフレッシュ
        this.listItems();
      }, // アイテム取得
      getItems: async function(nextToken) {
        const queryVar = {
          limit: 50,
        };

        queryVar.nextToken = nextToken;

        try {
          const queryResult = await API.graphql(graphqlOperation(listAnalyticsInputs, queryVar));
          const result = queryResult.data['listAnalyticsInputs'];

          if (result == null) {
            return;
          }
          this.items = [...this.items, ...result.items].filter(val => {
            return val.inputType === 'roa';
          });
          return result.nextToken;
        } catch (error) {
          console.error(error);
          this.showError('E0001', error);
          return;
        }
      },
      // 初期化処理
      listItems: async function() {
        this.items = [];
        this.currentPage = 0;
        let nextToken = null;

        // 初回の読み込みが終わった時点で
        nextToken = await this.getItems(nextToken);
        this.loading = false;

        // すべてのデータを読み込むまでループ
        while (nextToken != null) {
          nextToken = await this.getItems(nextToken);
        }
      },
      async showPreview() {
        const isValid = await this.$refs.inputCheck.validate();
        if (!isValid) {
          this.showError('E108');
          return;
        }
        this.isReportPreview = !this.isReportPreview;
      },
      editNewInput() {
        this.editIndex = null;
        this.input = new this.RoaInput();
        this.$refs.addInput.show();
      },
      async addInput() {
        // バリデーション無し
        // const isValid = await this.$refs.roaInput.validate();
        // if (isValid === false) {
        //   return;
        // }
        // 小数点以下の補正
        this.input.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.landSize =
          Math.floor(
            Number(this.input.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.landSize) * 100
          ) / 100;
        this.input.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.buildingSize =
          Math.floor(
            Number(this.input.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.buildingSize) *
              100
          ) / 100;
        this.input.inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice.land = Math.floor(
          Number(this.input.inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice.land)
        );
        this.input.inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice.building = Math.floor(
          Number(this.input.inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice.building)
        );
        this.input.prevailingPriceBase.prevailingPrice.land = Math.floor(
          Number(this.input.prevailingPriceBase.prevailingPrice.land)
        );
        this.input.prevailingPriceBase.prevailingPrice.building = Math.floor(
          Number(this.input.prevailingPriceBase.prevailingPrice.building)
        );
        this.input.inheritanceTaxBase.assetsRealEstate.pl.income = Math.floor(
          Number(this.input.inheritanceTaxBase.assetsRealEstate.pl.income)
        );
        this.input.inheritanceTaxBase.assetsRealEstate.pl.spending.landPropertyTax = Math.floor(
          Number(this.input.inheritanceTaxBase.assetsRealEstate.pl.spending.landPropertyTax)
        );
        this.input.inheritanceTaxBase.assetsRealEstate.pl.spending.buildingPropertyTax = Math.floor(
          Number(this.input.inheritanceTaxBase.assetsRealEstate.pl.spending.buildingPropertyTax)
        );
        this.input.inheritanceTaxBase.assetsRealEstate.pl.spending.landCityPlanningTax = Math.floor(
          Number(this.input.inheritanceTaxBase.assetsRealEstate.pl.spending.landCityPlanningTax)
        );
        this.input.inheritanceTaxBase.assetsRealEstate.pl.spending.buildingCityPlanningTax = Math.floor(
          Number(this.input.inheritanceTaxBase.assetsRealEstate.pl.spending.buildingCityPlanningTax)
        );
        this.input.inheritanceTaxBase.assetsRealEstate.pl.spending.maintenanceFee = Math.floor(
          Number(this.input.inheritanceTaxBase.assetsRealEstate.pl.spending.maintenanceFee)
        );
        this.input.inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt = Math.floor(
          Number(this.input.inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt)
        );
        this.input.inheritanceTaxBase.assetsRealEstate.cashflowCalc.borrowRate =
          Math.floor(
            Number(this.input.inheritanceTaxBase.assetsRealEstate.cashflowCalc.borrowRate) * 10
          ) / 10;

        if (this.editIndex == null) {
          this.inputList.push(this.input);
        } else {
          // オブジェクトのプロパティを変更したいため$set使用
          this.$set(this.inputList, this.editIndex, this.input);
        }

        this.$refs.addInput.hide();
        this.input = new this.RoaInput();
      },
      removeInput(index) {
        this.inputList.splice(index, 1);
      },
      countDownChanged: function(dismissCountDown) {
        this.dismissCountDown = dismissCountDown;
      },
      showAlert: function(message) {
        this.alertMessage = message;
        this.dismissCountDown = this.dismissSecs;
      },
    },
    watch: {
      centerValue(after) {
        for (const input of this.inputList) {
          input.centerValue = after;
          this.updateChart();
        }
      },
      inputList: {
        handler() {
          this.updateChart();
        },
        deep: true,
      },
    },
  };
</script>
<style scoped>
  .hint {
    font-size: 1.5rem;
    color: gray;
    box-shadow: 6px 6px 6px #ddd;
    border: 1px solid #ddd;
    border-radius: 2%;
    padding: 8px;
  }

  @media screen and (max-width: 1140px) {
    .hint {
      margin-top: 20px;
      font-size: 1.5rem;
    }
  }

  .alert-dismissible {
    font-size: medium;
  }
</style>
