<template>
  <div>
    <CommonLayout
      title="投資計画(初期投資・想定詳細)"
      :companyLogo="companyLogo"
      :companyName="companyName"
    >
      <template v-slot>
        <div
          style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5mm; padding: 100; font-size: 50px"
        >
          <div style="width: 100%; padding: 0px 100px;">
            <div style="text-align: end;">単位：円</div>
            <table style="width: 100%;">
              <thead>
                <tr>
                  <th :style="[headerStyle, col1Style]" class="col1">項目1</th>
                  <th :style="[headerStyle, col2Style]" class="col2">項目2</th>
                  <th :style="[headerStyle, col3Style]" class="col3">数量</th>
                  <th :style="[headerStyle, col4Style]" class="col4">単位</th>
                  <th :style="[headerStyle]" class="col5">金額</th>
                  <th :style="[headerStyle, col6Style]" class="col6">摘要</th>
                </tr>
              </thead>
            </table>
            <div :style="partHeaderStyle">購入価格</div>
            <table style="width: 100%">
              <tbody>
                <tr v-for="(item, i) in investmentPlanResult.basePrice.details" :key="i">
                  <td :style="[subHeaderStyle, col1Style]" class="col1">{{ !item || item.name }}</td>
                  <td :style="[subHeaderStyle, col2Style]" class="col2"></td>
                  <td :style="[bodyStyle, col3Style, right]" class="col3">
                    {{ !item || item.quantity }}
                  </td>
                  <td :style="[bodyStyle, col4Style]" class="col4">
                    {{ !item || item.unit }}
                  </td>
                  <td :style="[bodyStyle, col5Style]" class="col5">
                    {{
                      formatNum(item.total || item.amount, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                  <td :style="[bodyStyle, col6Style]" class="col6">
                    {{ !item || item.summary }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table style="width: 100%">
              <tbody>
                <tr>
                  <th :style="headerStyle" style="width: 50%; text-align: center">
                    購入価格 合計
                  </th>
                  <th :style="headerStyle" style="width: 50%; text-align: right">
                    {{
                      formatNum(investmentPlanResult.basePrice.total, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </th>
                </tr>
              </tbody>
            </table>
            <div :style="partHeaderStyle" style="width: 100%">
              工事費
            </div>
            <table style="width: 100%">
              <tbody>
                <tr v-for="(item, i) in investmentPlanResult.constructionCosts.details" :key="i">
                  <td :style="[subHeaderStyle, col1Style]">{{ !item || item.name }}</td>
                  <td :style="[subHeaderStyle, col2Style]"></td>
                  <td :style="[bodyStyle, col3Style, right]">
                    {{ !item || item.quantity }}
                  </td>
                  <td :style="[bodyStyle, col4Style]">
                    {{ !item || item.unit }}
                  </td>
                  <td :style="[bodyStyle, col5Style]">
                    {{
                      formatNum(item.total || item.amount, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                  <td :style="[bodyStyle, col6Style]">
                    {{ !item || item.summary }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table style="width: 100%">
              <tbody>
                <tr>
                  <th :style="headerStyle" style="width: 50%; text-align: center">
                    工事費 合計
                  </th>
                  <th :style="headerStyle" style="width: 50%; text-align: right">
                    {{
                      formatNum(investmentPlanResult.constructionCosts.total, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="InvestmentPlanSpendingsList[0]" style="width: 100%; padding: 0px 100px;">
            <div style="text-align: end;">単位：円</div>
            <table style="width: 100%;">
              <thead>
                <tr>
                  <th :style="[headerStyle, col1Style]" class="col1">項目1</th>
                  <th :style="[headerStyle, col2Style]" class="col2">項目2</th>
                  <th :style="[headerStyle, col3Style]" class="col3">数量</th>
                  <th :style="[headerStyle, col4Style]" class="col4">単位</th>
                  <th :style="[headerStyle]" class="col5">金額</th>
                  <th :style="[headerStyle, col6Style]" class="col6">摘要</th>
                </tr>
              </thead>
            </table>
            <table style="width: 100%">
              <tbody v-for="(item, i) in InvestmentPlanSpendingsList[0]" :key="i">
                <tr>
                  <td :style="partHeaderStyle" colspan="6">
                    {{ item.name }}
                  </td>
                </tr>
                <tr v-for="(detail, j) in item.details || []" :key="j">
                  <td :style="[subHeaderStyle, col1Style]" class="col1">{{ !detail || detail.name }}</td>
                  <td :style="[subHeaderStyle, col2Style]" class="col2"></td>
                  <td :style="[bodyStyle, col3Style, right]" class="col3">
                    {{ !detail || detail.quantity }}
                  </td>
                  <td :style="[bodyStyle, col4Style]" class="col4">
                    {{ !detail || detail.unit }}
                  </td>
                  <td :style="[bodyStyle, col5Style]" class="col5">
                    {{
                      formatNum(detail.total || detail.amount, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                  <td :style="[bodyStyle, col6Style]" class="col6">
                    {{ !detail || detail.summary }}
                  </td>
                </tr>
                <tr>
                  <th :style="subHeaderStyle" style="width: 50%; text-align: center" colspan="4">
                    {{ item.name }} 小計
                  </th>
                  <th :style="subHeaderStyle" style="width: 50%; text-align: right" colspan="2">
                    {{
                      formatNum(item.total, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </th>
                </tr>
              </tbody>
              <tbody v-if="InvestmentPlanSpendingsList[1] == null">
                <tr>
                  <th :style="headerStyle" style="width: 50%; text-align: center" colspan="4">
                    付帯費用 合計
                  </th>
                  <th :style="headerStyle" style="width: 50%; text-align: right" colspan="2">
                    {{
                      formatNum(investmentPlanResult.incidentalExpenses.total, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </CommonLayout>
  </div>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import {
    headerStyle,
    partHeaderStyle,
    subHeaderStyle,
    bodyStyle,
  } from '../../../../components/ExportReport/style';

  export default {
    props: {
      companyLogo: String,
      companyName: String,
      investmentPlanResult: {
        type: Object,
        default: () => {},
      },
      InvestmentPlanSpendingsList: Array,
    },
    components: {
      CommonLayout,
    },
    data() {
      return {
        headerStyle,
        partHeaderStyle,
        subHeaderStyle,
        bodyStyle,
        col1Style: {
          width: '24%',
        },
        col2Style: {
          width: '10%',
        },
        col3Style: {
          width: '8%',
        },
        col4Style: {
          width: '8%',
        },
        col5Style: {
          'text-align': 'right',
          width: '25%',
        },
        col6Style: {
          width: '25%',
        },
        right: {
          'text-align': 'right',
        },
      };
    },
  };
</script>
