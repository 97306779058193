<!--
作成者：Nagata Yoshitaka
作成日：2020.12.23
画面名：画像情報編集 レイヤータブコンポーネント
機能：
親子関係：
更新者：Nakatsuka Daisuke
①
更新内容：
①
-->
<template>
  <div class="container-fluid">
    <div
      :style="fontSize"
      class="row layer-tab edit property-layer"
      :class="{ active: layer.isActive }"
      :layerTabName="layer.name"
      @mousedown="layerClick($event, isLicensedArSimulator)"
    >
      <div class="d-flex eyes-column" style="padding: 0px;margin-bottom:3px;">
        <div class="" :for="layer.name" @mousedown.stop="" @click="clickEnabledEvent()">
          <span>
            <img class="eyes-icon" v-if="layer.enabled == true" :src="eyesOpen" />
            <img class="eyes-icon" v-if="layer.enabled == false" :src="eyesClosed" />
          </span>
        </div>
        <div v-b-popover.hover.boundary.viewport.bottom="'範囲設定カラーを変更できます。'">
          <v-swatches
            v-if="layer.isActive"
            class=""
            show-fallback
            v-model="layer.layerColor"
            popover-x="right"
            row-length="4"
            swatch-size="20"
            :disabled="isLicensedArSimulator == false"
            fallback-input-type="color"
            fallback-ok-text="閉じる"
          >
            <i class="ti-paint-bucket" />
            <div
              slot="trigger"
              class="color-bucket"
              :style="'color:' + layer.layerColor + '; ' + 'background: ' + layer.layerColor + ';'"
            >
              ■
            </div>
          </v-swatches>
          <div
            v-if="layer.isActive == false"
            slot="trigger"
            class="color-bucket"
            :style="'color:' + layer.layerColor + '; ' + 'background: ' + layer.layerColor + ';'"
          >
            ■
          </div>
        </div>
        <div
          style="margin-left:2px;"
          v-b-popover.hover.boundary.viewport.bottom.html="
            'レイヤーの名称を<br/>入力・変更ができます。'
          "
        >
          <input
            type="text"
            class="name"
            v-model="layer.name"
            maxlength="15"
            :disabled="isLicensedArSimulator == false"
          />
        </div>
        <span @click.stop="">
          <span
            class="delete text-center"
            @click="layerMenu"
            :class="{ hidden: isLicensedArSimulator == false }"
            v-b-popover.hover.boundary.viewport.right="'レイヤーの前面・背面への移動ができます。'"
          >
            <span class="ti-menu-alt" style="font-size:25px"></span>
          </span>
        </span>
      </div>
      <div>
        <div class="d-flex">
          <div class="row layer-delete-icon-wrapper align-items-center justify-content-center">
            <div class="columns">
              透明
              <input
                type="number"
                class="num"
                v-model="layer.transparent"
                min="0"
                max="100"
                step="1"
                @change="mouseApp()"
                :disabled="isLicensedArSimulator == false"
              />
            </div>
            <div class=" columns">
              <b-form-input
                type="range"
                v-model="layer.transparent"
                min="0"
                max="100"
                step="1"
                style=""
                @mouseup="mouseApp()"
                :disabled="isLicensedArSimulator == false"
              />
            </div>
          </div>
        </div>
        <div class="d-flex ">
          <div
            v-if="flipFlag == true"
            class="row layer-delete-icon-wrapper d-flex align-items-center justify-content-center "
          >
            <div class=" columns">
              明度
              <input
                type="number"
                class="num"
                v-model="layer.brightness"
                min="0"
                max="200"
                step="1"
                @change="mouseApp()"
                :disabled="isLicensedArSimulator == false"
              />
            </div>
            <div class=" columns">
              <b-form-input
                type="range"
                v-model="layer.brightness"
                min="0"
                max="200"
                step="1"
                @mouseup="mouseApp()"
                :disabled="isLicensedArSimulator == false"
              />
            </div>
          </div>
        </div>
        <div class="d-flex ">
          <div
            v-if="flipFlag == true"
            class="row layer-delete-icon-wrapper d-flex align-items-center justify-content-center "
          >
            <div class=" columns">
              彩度
              <input
                type="number"
                class="num"
                v-model="layer.saturation"
                min="0"
                max="200"
                step="1"
                @change="mouseApp()"
                :disabled="isLicensedArSimulator == false"
              />
            </div>
            <div class=" columns">
              <b-form-input
                type="range"
                v-model="layer.saturation"
                min="0"
                max="200"
                step="1"
                @mouseup="mouseApp()"
                :disabled="isLicensedArSimulator == false"
              />
            </div>
          </div>
        </div>
        <div
          v-if="flipFlag == false"
          class="layer-delete-icon-wrapper d-flex align-items-center justify-content-center "
        ></div>
      </div>
    </div>
    <b-modal
      :id="'deleteModal' + num"
      :ref="'deleteModal' + num"
      title="レイヤーを削除しますか？"
      @ok="deleteLayer"
      ok-title="削除"
      cancel-title="キャンセル"
      size="lg"
      header-class="ally-modal text-center"
      footer-class="ally-modal text-center"
      body-class="ally-input-background"
    >
      <div>「{{ layer.name }}」レイヤーを削除しますか？</div>
      <div style="color:red">※保存が完了するまでは完全に削除されません</div>
    </b-modal>
    <b-modal
      id="deleteLimitModal"
      ref="deleteLimitModal"
      title="レイヤーを削除できません"
      ok-only
      ok-title="閉じる"
      header-class="ally-modal text-center"
      footer-class="ally-modal text-center"
      body-class="ally-input-background"
    >
      レイヤーを０件にすることはできません
    </b-modal>
  </div>
</template>
<script>
  import VSwatches from 'vue-swatches';

  export default {
    /*
    layer: 個別のレイヤー情報
    num; レイヤーの上から何番目か取得
    flipFlag: シミュレート箇所(false)と、シミュレート画面(true)の切り替え情報
    isLicensedArSimulator: ログインアカウントの権限情報
    */
    props: ['layer', 'num', 'flipFlag', 'layersMenuChange', 'isLicensedArSimulator'],
    data: function() {
      return {
        id: 0,
        eyesOpen: require('../../../../../src/images/simulationIcon/eyes-open.png'),
        eyesClosed: require('../../../../../src/images/simulationIcon/eyes-closed.png'),
      };
    },
    mounted: function() {},
    methods: {
      deleteCheck: function() {
        if (document.getElementsByClassName('layer-tab').length > 1) {
          this.$bvModal.show('deleteModal' + this.num);
        } else {
          this.$bvModal.show('deleteLimitModal');
        }
      },
      deleteLayer: function() {
        this.$emit('deleteLayer', this.layer.id);
      },
      /**
       * レイヤーをクリックした時の処理
       */
      layerClick: function(e, isLicensedArSimulator) {
        //アクティブレイヤーの時は処理を行わない
        if(this.layer.isActive == true) return;
        //右クリック検知
        if (e.button == 2 && isLicensedArSimulator == true) {
          this.layerMenu(e); //メニュー表示
          return;
        }
        this.clauseRightMenu(); //メニューを閉じる
        this.$emit('changeActiveLayer', this.layer.id); //クリックしたレイヤーをactiveにする
      },
      mouseApp() {
        if (this.flipFlag == true) {
          this.$emit('simUndo');
        }
      },
      clickEnabledEvent() {
        this.layer.enabled == false ? (this.layer.enabled = true) : (this.layer.enabled = false);
        this.$emit('clickEnabledEvent');
      },
      /**
       * レイヤーのメニューを開く処理
       * 右クリックか、レイヤーのメニューをクリックする。
       */
      layerMenu(e) {
        this.layersMenuChange(this.num); //メニューの状態を設定する
        let contextmenu = document.getElementById('contextmenu');
        //現在右クリック中のIDをセットする。
        contextmenu.dataset.layerId = this.layer.id;
        //マウスの位置をstyleへ設定（左上の開始位置を指定）
        contextmenu.style.left = e.pageX + 'px';
        contextmenu.style.top = e.pageY + 'px';
        //メニューをblockで表示させる
        contextmenu.style.display = 'block';
      },
      /**
       * レイヤーメニューを閉じる
       */
      clauseRightMenu() {
        let contextmenu = document.getElementById('contextmenu');
        contextmenu.style.display = 'none';
      },
    },
    watch: {},
    computed: {
      fontSize() {
        return {
          '--font-size': this.layer.layerColor,
        };
      },
    },
    components: {
      VSwatches,
    },
  };
</script>

<style scoped>
  .decoSim .layer-tab {
    cursor: pointer;
    border: 6px solid rgb(170, 170, 170);
    border-bottom: 1px solid rgb(0, 0, 0);
  }
  .decoSim .layer-tab.active {
    background: var(--font-size);
    border: 6px solid rgb(255, 26, 26);
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.6));
  }
  .decoSim .layer-delete-icon {
    width: 25px;
  }

  .decoSim .move-box {
    border: 1px solid;
    width: 20px;
    height: 50%;
    user-select: none;
  }
  .decoSim .move-box:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  input.name {
    width: 90px;
    border: 2px solid #000;
    height: 25px;
    font-size: 15px;
    padding: 0px;
  }

  input.num {
    width: 50px;
    border: 1px solid #000;
    font-size: 15px;
  }

  .eyes-column {
    height: 25px;
  }

  .eyes-icon {
    width: 24px;
  }

  .color-bucket {
    border: 2px solid #000;
    width: 30px;
    height: 25px;
  }

  .decoSim .columns {
    width: 170px;
  }
</style>
<style>
  /* カラーパレット「fallback-input-type」表示調整 */
  .decoSim .layer-tab .vue-swatches__fallback__input {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  /* カラーパレット「fallback-ok-text」のボタンサイズ調整 */
  .decoSim .layer-tab .vue-swatches__fallback__button {
    white-space: nowrap;
    font-size: 6px;
    padding: 3px 5px;
  }

  .hidden {
    display: none;
  }
</style>
