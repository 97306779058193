<template>
  <div>
    <div class="row grid-margin">
      <h4 class="font-weight-bold">ALLY フォト（画像シミュレーター）</h4>
    </div>
    <div class="row">
      <div class="col-12">
        <router-link to="/DashBoard" tag="button" class="btn btn-ally float-right">
          メインページへもどる
        </router-link>
      </div>
    </div>
    <div v-show="uploading">
      <Load />
    </div>
    <div class="pt-3"></div>
    <ValidationObserver slim ref="propertyInfo">
      <SimulatorImageAdd
        v-show="!uploading"
        v-if="!loading"
        :propertyInfo="propertyInfo"
        :propSimInfoData="simInfoData"
        :loading="loading"
        :mode="mode"
        @mode="changeMode"
        ref="simAdd"
        @newButton="saveButton()"
      />
    </ValidationObserver>

    <SimLog />

    <allyAlert code="S100"></allyAlert>
    <allyAlert code="E0001"></allyAlert>
  </div>
</template>

<script>
  import awsconfig from '../../../aws-exports';
  //import { API } from 'aws-amplify';
  import { API, graphqlOperation } from 'aws-amplify';
  import { createProperty, createPhotoSimulator, updateProperty } from '../../../graphql/mutations';

  import SimulatorImageAdd from './Add';
  import Load from '../../../components/Common/Load';
  import SimLog from './NewSimLog';

  API.configure(awsconfig);

  export default {
    data: function() {
      return {
        mode: 'New',
        showMode: 'edit',
        propertyId: null,
        loading: true,
        propertyInfo: {
          company: null,
          commonValue: {
            propertyName: null,
            address: {
              postalCode: null,
              address1: null,
              address2: null,
              address3: null,
            },
          },
          simInfo: [],
        },
        simInfoData: {
          baseImageFileName: null,
          baseImageDisplayName: null,
          savePeten: [
            {
              order: '0',
              saveName: 'パターン1',
              layersInfo: [],
            },
          ],
        },
        selectItem: {
          commonValue: {
            propertyName: null,
            address: {
              postalCode: null,
              address1: null,
              address2: null,
              address3: null,
            },
          },
        },
        uploading: false,
      };
    },
    watch: {},
    mounted: async function() {
      this.loading = false;
    },
    methods: {
      //dynamoDBにデータを保存する。
      saveButton: async function() {
        //切り替え設定
        let propertySetting = this.$refs.simAdd.$refs.PropertySetting;
        this.$refs.propertyInfo.validate().then(async propertyValid => {
          if (propertyValid) {
            if (propertySetting.getPropertyMode() == 'create') {
              if (this.simInfoData.baseImageFileName.length == 0) {
                alert('画像が登録されていません。');
                return;
              }
              this.uploading = true;

              let name =
                this.$store.state.user.attributes.family_name +
                ' ' +
                this.$store.state.user.attributes.given_name;

              //新規 物件情報を取得する。
              try {
                let propertyInput = propertySetting.getPropertyData();
                propertyInput.commonValue.address = {
                  postalCode: null,
                  address1: null,
                  address2: null,
                  address3: null,
                };
                propertyInput.commonValue.quickSimLogTime = Date().toString();
                if (propertySetting.getSelectRoomId() == null) {
                  propertyInput.simulationLog = [
                    {
                      updateDate: new Date(),
                      fileUrlName: this.simInfoData.baseImageFileName[0],
                      fileName: this.simInfoData.baseImageDisplayName,
                      action: 'add',
                      userName: name,
                      userMail: this.$store.state.user.attributes.email,
                    },
                  ];
                } else {
                  propertyInput.roomInfo.find(
                    val => val.id == propertySetting.getSelectRoomId()
                  ).simulationLog = [
                    {
                      updateDate: new Date(),
                      fileUrlName: this.simInfoData.baseImageFileName[0],
                      fileName: this.simInfoData.baseImageDisplayName,
                      action: 'add',
                      userName: name,
                      userMail: this.$store.state.user.attributes.email,
                    },
                  ];
                }
                propertyInput.company = this.getCompanyId();

                //物件情報 登録処理
                var result = await API.graphql(
                  graphqlOperation(createProperty, {
                    input: propertyInput,
                  })
                );
                this.selectItem.id = result.data.createProperty.id;
                this.propertyId = this.selectItem.id;
              } catch (e) {
                //モーダルを表示する。
                this.$bvModal.show('E0001');
                console.error(e);
                return;
              }

              //フォトシミュレーションを作成する
              let createPhotoData = {
                company: await this.getCompanyId(),
                propertyId: this.propertyId,
                roomId: propertySetting.getSelectRoomId(),
                quickSimulationFlag: 'true',
                simInfo: {
                  baseImageFileName: this.simInfoData.baseImageFileName[0],
                  baseImageDisplayName: this.simInfoData.baseImageDisplayName,
                  simCreateDate: new Date(),
                  simUpdateDate: new Date(),
                },
              };

              //propertyIDをセットする。
              await this.$refs.simAdd.setPropertyId(this.propertyId);

              //ファイルアップロード
              await this.$refs.simAdd.fileUpload();
              try {
                //データを追加する（更新処理）
                await API.graphql({
                  query: createPhotoSimulator,
                  variables: { input: createPhotoData },
                });

                //ページを遷移する。
                this.pageLink();
              } catch (e) {
                this.$bvModal.show('E0001');
                console.error(e);
                return;
              }
            } else if (propertySetting.getPropertyMode() == 'select') {
              let propertyData = propertySetting.getPropertyData();
              this.propertyId = propertyData.id;
              this.selectItem = propertyData;

              let input = {
                company: await this.getCompanyId(),
                id: this.propertyId,
                simulationLog: propertyData.simulationLog,
                roomInfo: propertyData.roomInfo,
              };
              if (input.simulationLog == null) input.simulationLog = [];

              let name =
                this.$store.state.user.attributes.family_name +
                ' ' +
                this.$store.state.user.attributes.given_name;

              let log = {
                updateDate: new Date(),
                fileUrlName: this.simInfoData.baseImageFileName[0],
                fileName: this.simInfoData.baseImageDisplayName,
                action: 'add',
                userName: name,
                userMail: this.$store.state.user.attributes.email,
              };

              if (propertySetting.getSelectRoomId() == null) {
                input.simulationLog.push(log);
              } else if (propertySetting.getSelectRoomId() != null) {
                let roomSimulationLog = input.roomInfo.find(
                  val => val.id == propertySetting.getSelectRoomId()
                ).simulationLog;
                if (roomSimulationLog == null) {
                  let simulationLog = [];
                  simulationLog.push(log);
                  input.roomInfo.find(
                    val => val.id == propertySetting.getSelectRoomId()
                  ).simulationLog = simulationLog;
                } else {
                  roomSimulationLog.push(log);
                }
              }

              await API.graphql({
                query: updateProperty,
                variables: { input: input },
              });

              //フォトシミュレーションを作成する
              let createPhotoData = {
                company: await this.getCompanyId(),
                propertyId: this.propertyId,
                roomId: propertySetting.getSelectRoomId(),
                quickSimulationFlag: 'true',
                simInfo: {
                  baseImageFileName: this.simInfoData.baseImageFileName[0],
                  baseImageDisplayName: this.simInfoData.baseImageDisplayName,
                  simCreateDate: new Date(),
                  simUpdateDate: new Date(),
                },
              };

              //propertyIDをセットする。
              await this.$refs.simAdd.setPropertyId(this.propertyId);

              //ファイルアップロード
              await this.$refs.simAdd.fileUpload();
              try {
                //データを追加する（更新処理）
                await API.graphql({
                  query: createPhotoSimulator,
                  variables: { input: createPhotoData },
                });

                //ページを遷移する。
                this.pageLink();
              } catch (e) {
                this.$bvModal.show('E0001');
                console.error(e);
                return;
              }
            }
          }
        });
      },
      pageLink: async function() {
        this.$store.commit('setSaveFlg', true);

        let propertySetting = this.$refs.simAdd.$refs.PropertySetting;
        if (propertySetting.getSelectRoomId() == null) {
          this.$router.push(
            '/Property/' +
              this.selectItem.id +
              '/WallPaperSimulator/Edit/' +
              this.simInfoData.baseImageFileName[0] +
              '?side=true'
          );
        } else if (propertySetting.getSelectRoomId() != null) {
          this.$router.push(
            '/Property/' +
              this.selectItem.id +
              '/WallPaperSimulator/Edit/' +
              this.simInfoData.baseImageFileName[0] +
              '?side=true&room=' +
              propertySetting.getSelectRoomId()
          );
        }
      },
      changeMode: function(mode) {
        this.mode = mode;
      },
      //会社情報を取得する。
      getCompanyId: function() {
        //アカウントから会社ID取得
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },
    },
    components: {
      SimulatorImageAdd,
      Load,
      SimLog,
    },
  };
</script>
<style scoped>
  .text-red {
    color: red;
  }

  .property-link {
    font-size: 1.2rem;
    width: 2rem;
    height: 2rem;
    color: white;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px 20px 20px 20px;
    transition: 0.2s;
  }

  .property-link:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .property-link:active {
    background-color: rgba(255, 255, 255, 0.2);
    transition: 0.15s;
  }

  /* レイヤーcolor */
  .property-layer {
    background-color: rgba(255, 255, 255, 0);
    transition: 0.2s;
  }
  .property-layer:hover {
    background-color: rgba(218, 157, 97, 0.3);
  }

  .property-delete-layer:hover {
    background-color: rgba(255, 0, 0, 0.4);
  }

  /* アイコンの大きさ設定 */

  .property-icon {
    font-size: 5rem;
    color: var(--colorTheme);
    transition: 0.2s;
  }

  .property-back-color {
    border-radius: 20px 20px 20px 20px;
    transition: 0.2s;
  }

  .property-back-color:hover {
    background-color: #ffffff;
    border-radius: 20px 20px 20px 20px;
  }
  .property-function-icon {
    max-width: 200px;
  }

  .line {
    display: inline-block;
  }
</style>
