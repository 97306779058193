// @ts-check
import { createNode } from './pvNode';
import { Arc } from '../../util/math/arc';
import { Rect } from '../../util/math/rect';
import { Vec } from '../../util/math/vector';
import { Geo } from '../../util/math/geometry';
import { PvText } from '../../node/node/textNode';
import { PvObject } from '../../node/node/pvObject';
import { round, nextCycleIndex, getBarycentricCoordinate } from '../../util/util';
import { LARGE_GRID_PX, LARGE_GRID_METER_DEFAULT, HEIGHT3D_DEFAULT } from '../../util/const';
import * as turfTools from '../../util/math/turfTools';
import * as turf from '@turf/turf';
import { cloneNode } from '../tools/clone';
import { Transform } from '../../util/math/transform';
import { PvStyle } from '../tools/style';

const FLOOR_SIZE_TEXT_ALIAS = 'floorSizeText';

export class PvFloor extends PvObject {
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'floor';
  code = 0;
  height3d = HEIGHT3D_DEFAULT.WALL;
  constructor() {
    super();
  }

  /**
   * @returns {PvText}
   */
  getFloorSizeText() {
    // @ts-ignore
    return this.children.find(c => c.alias === FLOOR_SIZE_TEXT_ALIAS);
  }

  /**
   * @param {number} index
   * @param {Vec} vec
   */
  moveVertexBy(index, vec) {
    super.moveVertexBy(index, vec);
    this.updateAreaSize();
  }

  /**
   * @param {number} index
   * @param {Vec} vec
   */
  moveEdgeBy(index, vec) {
    super.moveEdgeBy(index, vec);
    this.updateAreaSize();
  }

  /**
   *
   * @param {number} index
   * @param {Vec} dist
   */
  curve(index, dist) {
    const vertex1 = this.vertexes[index];
    const vertex2 = this.vertexes[nextCycleIndex(index, this.vertexes.length)];
    const edgeVec = vertex2.sub(vertex1);
    const oldArc = this.arcPoints[index];

    /** @type {Vec} */
    const unitVec = edgeVec.normal().unit();

    const rotate = this.transform.rotateDeg;

    /** @type {Vec} */
    const dr = dist.rotate(-rotate);
    const rad = unitVec.subtendedAngle(dr);
    if (isNaN(rad)) {
      return;
    }
    const ddr = unitVec.scale(dr.absVal() * Math.cos(rad));

    this.arcPoints.splice(index, 1, oldArc + ddr.absVal() * (Math.cos(rad) > 0 ? 1 : -1));
    this.updateAreaSize();
  }

  /**
   * 始点終点により変形
   * @param {Vec} start
   * @param {Vec} end
   */
  deformateFromStartEnd(start, end) {
    super.deformateFromStartEnd(start, end);
    this.updateAreaSize();
  }

  /**
   * 多点により変形
   * @param {Vec[]} vecs
   */
  deformateFromVecList(vecs) {
    this.vertexes.splice(0, this.vertexes.length, ...vecs.map(v => v.clone()));
    this.updateAreaSize();
  }

  /**
   * @param {Vec[]} vertexes
   * @returns {boolean}
   */
  static isClockWise(vertexes) {
    const direction = vertexes[1].sub(vertexes[0]).cross(vertexes[2].sub(vertexes[1])) > 0;
    return direction;
  }

  getAreaSize() {
    let allArea = 0;
    // 多角形の面積
    allArea = Geo.getAreaSize(this.vertexes);
    // 弧の面積の和
    const len = this.vertexes.length;
    this.vertexes.forEach((start, i) => {
      const end = this.vertexes[(i + 1) % len];
      const dist = end.sub(start);
      if (dist.absSquare() === 0) {
        return;
      }
      const arc = start.centerPoint(end).add(
        dist
          .normal()
          .unit()
          .scale(this.arcPoints[i % len])
      );
      const sign = dist.cross(arc.sub(start)) < 0 ? 1 : -1;
      const arcArea = Arc.getArcAreaSize(start, arc, end);
      allArea += sign * arcArea;
    });
    // ㎡に換算
    return round(
      Math.abs(
        (allArea / LARGE_GRID_PX / LARGE_GRID_PX) *
          LARGE_GRID_METER_DEFAULT *
          LARGE_GRID_METER_DEFAULT
      ),
      2
    );
  }

  /**
   * 設定に応じて㎡を帖に変換する
   * @param {number} size
   * @returns {string}
   */
  calcAreaSize(size) {
    return this.areaSizeUnit == '帖' ? (size / 1.62).toFixed(1) : size.toFixed(2);
  }

  updateAreaSize() {
    const text = this.getFloorSizeText();
    if (text == null) {
      return;
    }
    text.style.spaceName = this.style.spaceName;
    if (this.showAreaSize) {
      const areaSize = this.calcAreaSize(this.getAreaSize());
      text.text =
        (this.style.spaceName ? this.style.spaceName + '/' : '') + areaSize + this.areaSizeUnit;
    } else {
      // 空文字を設定しないと空白表示に変化しない
      text.text = '';
    }
  }

  getTextNode() {
    const text = createNode(PvText);
    text.parent = this;
    text.alias = FLOOR_SIZE_TEXT_ALIAS;
    text.style.textAnchor = 'middle';
    text.style.fontSize = 40;
    text.moveTo(this.transform.origin);
    // 部屋の重心座標を生成
    const textVec = getBarycentricCoordinate(this.vertexes);
    // 部屋の重心座標をセット
    text.transform.translate = textVec;
    text.vertexes = [textVec];

    return text;
  }

  /**
   * @param {Vec[]} vertexes
   * @param {PvStyle} style
   * @param {number} height3d
   * @returns {PvFloor}
   */
  static createRect(vertexes, style, height3d) {
    const start = vertexes[0];
    const end = vertexes[vertexes.length - 1];
    const floor = createNode(PvFloor);

    floor.style = style.clone();
    floor.height3d = height3d;

    const rect = Rect.fromStartEnd(start, end);

    floor.vertexes = [
      new Vec(),
      new Vec(rect.width),
      new Vec(rect.width, rect.height),
      new Vec(0, rect.height),
    ];
    const tr = new Transform({
      translate: new Vec(rect.x, rect.y),
      origin: new Vec(rect.width / 2, rect.height / 2),
    });

    floor.transform = tr;

    floor.arcPoints = floor.vertexes.map(() => 0);

    // 時計回りになるよう反転
    if (!PvFloor.isClockWise(floor.vertexes)) {
      floor.vertexes = floor.vertexes.reverse();
    }

    const text = floor.getTextNode();
    floor.appendChild(text);
    floor.updateAreaSize();

    return floor;
  }

  /**
   *
   * @param {Vec[]} vertexes
   * @param {PvStyle} style
   * @param {number} height3d
   * @returns {PvFloor}
   */
  static createPolygon(vertexes, style, height3d) {
    const floor = createNode(PvFloor);

    floor.style = style.clone();
    floor.height3d = height3d;
    floor.vertexes = vertexes.map(route => {
      const p = route;
      return new Vec(p.x, p.y);
    });

    floor.arcPoints = floor.vertexes.map(() => 0);

    // 時計回りになるよう反転
    if (!PvFloor.isClockWise(floor.vertexes)) {
      floor.vertexes = floor.vertexes.reverse();
    }

    const box = Rect.fromVecs(floor.vertexes);
    const translate = new Vec(box.x, box.y);
    floor.vertexes = floor.vertexes.map(v => {
      return v.sub(translate);
    });
    const origin = Vec.getCenter(floor.vertexes);

    floor.transform = new Transform({
      translate,
      origin,
    });

    const text = floor.getTextNode();
    floor.appendChild(text);
    floor.updateAreaSize();

    return floor;
  }

  /**
   *
   * @param {PvObject[]} floors
   * @returns {PvFloor}
   */
  static mergeFloors(floors) {
    const vertexesList = floors.map(o => {
      return o.absoluteVertexes();
    });

    // マージ
    let mergedVertexes = vertexesList.shift() ?? [];
    let len = vertexesList.length;
    let mergedCount = 0;
    for (let i = 0; i < len; i++) {
      const targetVertexes = vertexesList[i];
      const polygon1 = turfTools.createTurfPolygon(mergedVertexes);
      const polygon2 = turfTools.createTurfPolygon(targetVertexes);

      if (turfTools.intersect(mergedVertexes, targetVertexes).length === 0) {
        continue;
      }

      const union = turf.union(polygon1, polygon2);
      if (union == null) {
        throw new Error('unionがnullです');
      }
      mergedVertexes = union.geometry.coordinates[0].map(v => new Vec(v[0], v[1]));
      mergedVertexes.pop();
      mergedCount++;
      vertexesList.splice(i, 1);
      len = vertexesList.length;
      i = -1;
    }

    if (mergedCount !== floors.length - 1) {
      throw new Error('隣接した部屋を選択してください');
    }

    // 結合した座標郡の一番左上をoffsetとして取得する
    const offset = Vec.leftTop(mergedVertexes);

    // 各Floorの子に対しtranslateをoffsetに合わせて補正する。Floor[0]以外の子はFloor[0]に集約する
    floors.forEach((f, fIndex) => {
      // Floorのtranslateとoffsetの差分を求める
      const diff = f.transform.translate.sub(offset);
      f.children.forEach((c, cIndex) => {
        // Floor[0]または、Floor[0]以外かつtypeがtext以外の場合
        if (fIndex == 0 || (fIndex != 0 && c.type !== 'text')) {
          // 子のtranslateに差分を加算する
          c.transform.translate = c.transform.translate.add(diff);
          // Floorのインデックスが[0]以外の場合
          if (fIndex != 0) {
            // Floor[0]の子に加える
            floors[0].appendChild(cloneNode(c));
          }
        }
      });
    });
    // Floor[0]のクローンを生成する
    const floor = cloneNode(/** @type {PvFloor} */ (floors[0]));

    floor.transform = floor.transform.cloneWith({
      translate: offset,
    });
    floor.vertexes = mergedVertexes.map(s => s.sub(offset));

    // arcPointsの処理(暫定でarcPointsを初期化した上でハンドル数分を積む)
    let length = floor.vertexes.length;
    floor.arcPoints = [];
    for (let i = 0; i < length; i++) {
      floor.arcPoints.push(0);
    }

    floor.updateAreaSize();

    return floor;
  }

  /**
   * @param {PvObject[]} objs
   * @param {String} unitOfSpace
   * @param {Boolean} showSpace
   */
  static setSpaceParam(objs, unitOfSpace, showSpace) {
    const targets = objs.filter(o => o.type === 'floor');
    for (const target of targets) {
      const floor = /** @type {PvFloor} */ (target);
      target.areaSizeUnit = unitOfSpace;
      target.showAreaSize = showSpace;
      floor.updateAreaSize();
    }
  }

  /**
   * @param {PvFloor} floor
   * @param {String} name
   */
  static setSpaceName(floor, name) {
    floor.style.spaceName = name;
    floor.updateAreaSize();
  }
}
