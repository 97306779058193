<template>
  <div>
    <div style="text-align: center">
      こちらの機能は iPhone または iPad でご利用いただけます。
    </div>

    <table class="ar-measure">
      <tbody>
        <tr>
          <td colspan="3">
            <a
              href="https://apps.apple.com/jp/app/ally-ar%E3%82%B7%E3%83%9F%E3%83%A5%E3%83%AC%E3%83%BC%E3%82%BF%E3%83%BC/id1572609232?itscg=30200&amp;itsct=apps_box_appicon"
              style="width: 128px; height: 128px; border-radius: 22%;"
              target="_blank"
            >
              <img
                src="../../../../images/ar/ally-ar.png"
                alt="ALLY - ARシミュレーター"
                style="width: 128px; height: 128px; border-radius: 22%;"
              />
            </a>
            <img src="../../../../images/ar/title.png" />
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <a
              href="https://apps.apple.com/jp/app/ally-ar%E3%82%B7%E3%83%9F%E3%83%A5%E3%83%AC%E3%83%BC%E3%82%BF%E3%83%BC/id1572609232?itsct=apps_box_badge&amp;itscg=30200"
              style="border-radius: 13px; width: 225px; height: 83px;"
              target="_blank"
            >
              <img
                src="../../../../images/ar/black.svg"
                alt="Download on the App Store"
                style="border-radius: 13px; width: 225px; height: 83px;"
              />
            </a>
          </td>
          <td rowspan="4">
            <img src="../../../../images/ar/qr-code.jpg" style="width: 128px; height: 128px;" />
          </td>
        </tr>
        <tr>
          <td rowspan="3">推奨デバイス：</td>
          <td>
            iPhone 12 以降の Pro 及び Pro Max<br />
            iPad Pro 11インチ(第2世代)以降<br />
            iPad Pro 12.9インチ(第4世代)以降<br />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import { defineComponent } from '@vue/composition-api';

  export default defineComponent({
    setup() {},
  });
</script>
