// @ts-check
import { DrawDoorActionBuilder } from '../../action/builder/drawDoorActionBuilder';
import { EventPresetBuilder } from '../eventPresetBuilder';
import { EventPreset } from '../eventPreset';

export class DoorPreset extends EventPresetBuilder {
  build() {
    const preset = new EventPreset();

    preset.click = dataset => {
      if (this.actionManager.isDisabled()) {
        this.actionManager.setAction(new DrawDoorActionBuilder(this.context, dataset));
      }
    };

    return preset;
  }
}
