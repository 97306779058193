// @ts-check

import { getNode } from '../../node/node/pvNode';
import { PvObject } from '../../node/node/pvObject';

/** マウスのボタン */
const MOUSE = {
  0: 'left',
  1: 'middle',
  2: 'right',
};

/**
 * クリックイベントから必要な情報を抽出したもの
 * クリック対象の要素のdatasetプロパティから情報を取得する
 * https://developer.mozilla.org/ja/docs/Web/API/HTMLElement/dataset
 */
export class EventDataset {
  /** @type {string} */
  id;
  /** @type {number|null} */
  index;
  /** @type {string|null} */
  target;
  /** @type {"edge" | "vertex" | "body" | "rotate" | "curve" | null} */
  part;
  /** @type {string} */
  eventType = '';
  /** @type {"left" | "middle" | "right"} */
  button;
  /** @type {boolean} */
  ctrlKey;
  /** @type {PvObject|null} */
  targetObject;
  /**
   * @private
   * @param {EventDataset} params
   */
  constructor(params) {
    Object.assign(this, params);
  }

  /**
   * @param {Event} e
   * @param {{
   * eventType?: string
   * }} param
   * @returns {EventDataset}
   */
  static fromEvent(e, param = {}) {
    const event = /** @type {PointerEvent} */ (e);
    const target = /** @type {any} */ (e.target);
    const dataset = target?.dataset ?? {};
    return new EventDataset({
      id: dataset.id ?? '',
      index: dataset.index ? parseInt(dataset.index) : null,
      target: dataset.area,
      part: dataset.part,
      eventType: param.eventType ?? e.type,
      button: MOUSE[event.button],
      ctrlKey: event.ctrlKey,
      // @ts-ignore
      targetObject: dataset.id ? getNode(dataset.id) : null,
    });
  }

  /**
   * @param {import('pixi.js').InteractivePointerEvent} e
   * @param {{
   * eventType?: string
   * }} param
   * @returns {EventDataset}

   */
  static fromPixiEvent(e, param = {}) {
    // @ts-ignore
    const event = e.data.originalEvent;
    // @ts-ignore
    return EventDataset.fromEvent(
      {
        target: {
          // @ts-ignore
          dataset: e.target?.dataset ?? {},
        },
        button: event.button,
        ctrlKey: event.ctrlKey,
      },
      param
    );
  }
}
