<!--
作成者：nakatsuka daisuke
作成日：2020.12.09
画面名：simulationの素材を設定する。
機能：
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div class="property-view back" @click="clauseRightMenu">
    <ValidationObserver slim ref="simInfoData">
      <div v-if="upLoading">
        <Load />
      </div>
      <div class="row" v-if="propertyId != 'new'"></div>
      <div class="row grid-margin padding-bottom: 5px">
        <div class="ally-input-background col-12">
          <div class="row col-12" style="margin-top:15px;margin-bottom:0px;">
            <h4 class="font-weight-bold">
              ALLY フォト（画像シミュレーター）
            </h4>
          </div>
          <div class="">
            <div class="row" ref="funcButton">
              <span class="col-3" style=" text-align: left;">
                <button
                  type="button"
                  id="save"
                  class="btn btn-ally ml-2"
                  @click="isLicensedArSimulator ? $bvModal.show('backCheck') : backListPage()"
                >
                  {{
                    $route.query.side == 'true'
                      ? '更新履歴'
                      : this.$route.query.room != null
                      ? '部屋情報'
                      : '物件管理'
                  }}へ戻る
                </button>
                <button
                  type="button"
                  id="save"
                  class="btn btn-ally ml-2"
                  :class="{ hidden: isLicensedArSimulator == false }"
                  @click="saveLayers"
                >
                  保存
                </button>
              </span>
              <div class="col-9 " style="text-align: right; margin-bottom: 15px">
                <span>画像名</span>
                <input
                  type="text"
                  class="text-box"
                  v-model="simInfoData.baseImageDisplayName"
                  name="baseImageDisplayName"
                  :disabled="isLicensedArSimulator == false"
                  maxlength="15"
                  v-b-popover.hover.top="'画像名の変更ができます。'"
                />

                <b-form-select
                  class="col-3 ml-3"
                  v-model="activeSelectPetten"
                  :options="layerSelectItem"
                  :disabled="mode == 'View'"
                  :taggable="true"
                  @change="patternModal"
                  v-b-popover.hover.top="'パターンを選択して切り替えます。'"
                ></b-form-select>
                <span class="inline-block" :class="{ hidden: isLicensedArSimulator == false }">
                  <button
                    type="button"
                    id="save"
                    class="btn btn-ally ml-2"
                    @click="namechangeModal(activeSelectPetten)"
                  >
                    パターン名変更
                  </button>

                  <button
                    type="button"
                    id="save"
                    class="btn btn-ally ml-3 "
                    @click="addPatternModal()"
                  >
                    パターン追加
                  </button>

                  <button
                    type="button"
                    id="save"
                    class="btn btn-ally ml-3 mr-2"
                    @click="deletePatten()"
                  >
                    パターン削除
                  </button>
                </span>
              </div>
            </div>

            <div class="decoSim" ref="decoSim">
              <div class="row no-gutters property-bar">
                <div class="col-12">
                  <div class="d-flex bd-highlight" v-if="upLoading == false">
                    <div class="ml-2 edit-mode-area">
                      <label
                        class="edit-mode-change"
                        :class="{ active: flipFlag == false }"
                        @click="flipFlag = false"
                      >
                        <div
                          class=""
                          v-b-popover.hover.top.html="'素材を反映する範囲を<br/>設定する画面へ。'"
                        >
                          <span>
                            <div style="margin-top:10px;">範囲設定</div>
                            <div>画面</div>
                          </span>
                        </div>
                      </label>
                    </div>
                    <label class="ml-2" style="margin-top:12px">
                      <span style="font-size: 25px">◁▷</span>
                    </label>
                    <div class="ml-2 edit-mode-area">
                      <label
                        class="edit-mode-change"
                        :class="{ active: flipFlag == true }"
                        @click="
                          flipFlag = true;
                          editProperty.editMode = 'move';
                        "
                      >
                        <div
                          class=""
                          v-b-popover.hover.top.html="
                            '素材を反映してシミュレーション<br/>を行う画面へ移動します。'
                          "
                        >
                          <span>
                            <div style="margin-top:10px;">
                              素材反映
                            </div>
                            <div>画面</div>
                          </span>
                        </div>
                      </label>
                    </div>
                    <!-- シミュレート画面 -->
                    <div class="d-flex" v-if="isLicensedArSimulator == true">
                      <div class="d-item ml-3" v-if="flipFlag == true">
                        <label class="edit-mode">
                          <div
                            @click="undoMaterial"
                            v-b-popover.hover.top="'直前の作業を取り消して戻します。'"
                            class="d-flex flex-column h-100 justify-content-center text-center"
                          >
                            <i class="ti-arrow-left"></i>
                            <span>戻る</span>
                          </div>
                        </label>
                        <label class="edit-mode">
                          <div
                            @click="undoCancelMaterial"
                            v-b-popover.hover.top="'戻した作業をやり直します。'"
                            class="d-flex flex-column h-100 justify-content-center text-center"
                          >
                            <i class="ti-arrow-right"></i>
                            <span>進む</span>
                          </div>
                        </label>
                      </div>
                      <div class="d-item ml-3 edit-mode-area" v-if="flipFlag == true">
                        <div v-b-popover.hover.top="'反映させた素材の編集を行います。'">
                          <label :class="{ active: simEditMode }" class="edit-mode">
                            <input
                              type="checkbox"
                              name="simEditMode"
                              v-model="simEditMode"
                              @change="simChange('simEditMode')"
                            />
                            <div class="h-100 justify-content-center text-center">
                              <div><i class="ti-pencil"></i></div>
                              <div><span>素材編集</span></div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="d-item edit-mode-area" v-if="flipFlag == true">
                        <div
                          v-b-popover.hover.top.html="
                            '画像上でクリックした場所の色を<br/>選択範囲に反映させます。'
                          "
                          @click="
                            dropper == false ? (dropper = true) : (dropper = false);
                            simChange('dropper');
                          "
                        >
                          <label :class="{ active: dropper }" class="edit-mode">
                            <div class="h-100 justify-content-center text-center">
                              <div class="mt-3"></div>
                              <div><i class="ti-filter"></i></div>
                              <div><span>スポイト</span></div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- 範囲指定画面 -->
                    <div class="d-flex" v-if="isLicensedArSimulator == true">
                      <div class="d-item ml-3" v-if="flipFlag == false">
                        <label class="edit-mode">
                          <div
                            @click="undoMask"
                            v-b-popover.hover.top="'直前の作業を取り消して戻します。'"
                            class="d-flex flex-column h-100 justify-content-center text-center"
                          >
                            <i class="ti-arrow-left"></i>
                            <span>戻る</span>
                          </div>
                        </label>
                        <label class="edit-mode">
                          <div
                            @click="undoCancelMask"
                            v-b-popover.hover.top="'戻した作業をやり直します。'"
                            class="d-flex flex-column h-100 justify-content-center text-center"
                          >
                            <i class="ti-arrow-right"></i>
                            <span>進む</span>
                          </div>
                        </label>
                      </div>
                      <div class="d-item ml-3" v-if="flipFlag == false">
                        <div>
                          <label
                            v-for="editMode in editModeProperty"
                            :key="editMode.name"
                            :class="{
                              active: editProperty.editMode === editMode.name,
                            }"
                            class="edit-mode"
                            v-b-popover.hover.top.html="editMode.tips"
                          >
                            <input
                              type="radio"
                              name="editMode"
                              v-model="editProperty.editMode"
                              :value="editMode.name"
                            />
                            <div
                              class="d-flex flex-column h-100 justify-content-center text-center"
                            >
                              <i :class="editMode.icon"></i>
                              <span style="height: 15px" v-html="editMode.text"></span>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="d-item ml-3" v-if="flipFlag == false">
                        <div>
                          <label
                            v-for="editMode in editModeProperty2"
                            :key="editMode.name"
                            :class="{
                              active: editProperty.editMode === editMode.name,
                            }"
                            class="edit-mode"
                            v-b-popover.hover.bottom.html="editMode.tips"
                          >
                            <input
                              type="radio"
                              v-if="editMode.name != ''"
                              name="editMode"
                              v-model="editProperty.editMode"
                              :value="editMode.name"
                            />
                            <div
                              class="d-flex flex-column h-100 justify-content-center text-center"
                            >
                              <i :class="editMode.icon"></i>
                              <span style="height: 15px" v-html="editMode.text"></span>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="d-item ml-4" v-if="flipFlag == false">
                        <div>
                          <label
                            v-for="editMode in editModeProperty3"
                            :key="editMode.name"
                            :class="{
                              active: editProperty.editMode === editMode.name,
                            }"
                            class="edit-mode eraser-mode"
                            v-b-popover.hover.bottom.html="editMode.tips"
                          >
                            <input
                              type="radio"
                              v-if="editMode.name != ''"
                              name="editMode"
                              v-model="editProperty.editMode"
                              :value="editMode.name"
                            />
                            <div
                              class="d-flex flex-column h-100 justify-content-center text-center"
                            >
                              <i :class="editMode.icon"></i>
                              <span style="height: 15px" v-html="editMode.text"></span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="d-item ml-3" v-if="flipFlag == false">
                      <div>
                        <label
                          v-for="editMode in editModeProperty4"
                          :key="editMode.name"
                          :class="{
                            active: editProperty.editMode === editMode.name,
                          }"
                          class="edit-mode move-mode"
                          v-b-popover.hover.bottom="editMode.tips"
                        >
                          <input
                            type="radio"
                            v-if="editMode.name != ''"
                            name="editMode"
                            v-model="editProperty.editMode"
                            :value="editMode.name"
                          />
                          <div class="d-flex flex-column h-100 justify-content-center text-center">
                            <i :class="editMode.icon"></i>
                            <span style="height: 15px" v-html="editMode.text"></span>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="ml-4"></div>
                    <div
                      class="ml-2 border"
                      style="width:100px;height:60px;"
                      v-if="flipFlag == false && editProperty.editMode == 'floodFill'"
                      v-b-popover.hover.top="'自動選択の許容範囲を指定します。'"
                    >
                      <div style="margin-top:5px; margin-left:5px">
                        <div style="margin-top:5px;"><i class="ti-wand" />許容値</div>
                        <div style="font-size: 1em; width: 100px;line-height:1.6em;">
                          <b-form-input
                            style="width: 70%; float:left;"
                            name="threshold"
                            v-model="editProperty.threshold"
                            type="number"
                            step="1"
                            halfWidth
                            @change="val => (editProperty.threshold = minMaxLimit(val))"
                            max="100"
                            min="1"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="ml-2 border"
                      style="width:100px;height:60px;"
                      v-if="flipFlag == false && editProperty.editMode != 'floodFill'"
                      v-b-popover.hover.top="'線の太さを変更できます。'"
                    >
                      <div style="margin-top:5px; margin-left:5px">
                        <div style="white-space: nowrap;"><i class="ti-pencil" />ライン幅</div>
                        <div style="font-size: 1em; width: 100px;line-height:1.6em;">
                          <b-form-input
                            style="width: 70%; float:left;"
                            name="threshold"
                            v-model="editProperty.lineWidth"
                            type="number"
                            step="1"
                            halfWidth
                            @change="val => (editProperty.lineWidth = minMaxLimit(val))"
                            max="100"
                            min="1"
                            :disabled="this.layers.length == 0 || isLicensedArSimulator == false"
                          />px
                        </div>
                      </div>
                    </div>
                    <div
                      class="ml-2 border"
                      style="width:100px;height:60px;"
                      v-b-popover.hover.top.html="
                        '画像（写真）を拡大、縮小します。<br/>Ctrlキー＋マウススクロールでも<br/>操作できます。'
                      "
                    >
                      <div style="margin-top:5px; margin-left:5px">
                        <div style="white-space: nowrap;"><i class="ti-search" />拡大/縮小</div>
                        <div style="font-size: 1em; width: 100px;line-height:1.6em;">
                          <b-form-input
                            style="width: 70%; float:left;"
                            name="threshold"
                            v-model="editProperty.zoom"
                            type="number"
                            step="1"
                            halfWidth
                            max="300"
                            min="1"
                            @change="zommText"
                            :disabled="isLicensedArSimulator == false"
                          />%
                        </div>
                      </div>
                    </div>
                    <div
                      class="ml-2 border"
                      style="width:150px;height:60px;"
                      v-b-popover.hover.top.html="
                        '反映した素材のパターンのサイズを<br/>拡大・縮小することができます。'
                      "
                      v-if="flipFlag == true"
                    >
                      <div style="margin-top:5px; margin-left:5px">
                        <div style="white-space: nowrap;">
                          <i class="ti-arrows-corner" />パターンサイズ
                        </div>
                        <div style="font-size: 1em; width: 100px; line-height:1.6em;">
                          <b-form-input
                            style="width: 70%; float:left;"
                            name="threshold"
                            type="number"
                            halfWidth
                            :value="materialSize"
                            step="1"
                            max="100"
                            min="1"
                            @change="val => materialScaleChange(val)"
                            :disabled="this.layers.length == 0 || isLicensedArSimulator == false"
                          />%
                        </div>
                      </div>
                    </div>
                    <div
                      class="ml-auto bd-highlight text-right mr-3"
                      v-if="flipFlag == true"
                      v-b-popover.hover.top="'画像ファイル（.png）で出力します。'"
                    >
                      <button
                        type="button"
                        id="output"
                        class="btn"
                        style="height:60px"
                        @click="exportImage"
                      >
                        完成画像出力
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row no-gutters" v-if="mode != 'Add'" style=" ">
              <div class="decoSim w-100">
                <div class="row no-gutters">
                  <div class="col-12 d-flex ">
                    <div class="layer-tab-list border" ref="layerTabsList">
                      <div class="row">
                        <div
                          class="col-2"
                          @mousedown.stop=""
                          @click="allLayerEnabledChange"
                          v-b-popover.hover.right.html="
                            'すべてのレイヤーの表示・非表示を<br/>まとめて切り替えます。'
                          "
                        >
                          <span class="eraserImages" style="padding: 10px 5px;">
                            <img class="eyes-icon" v-if="allEyesFlag == true" :src="eyesOpen" />
                            <img class="eyes-icon" v-if="allEyesFlag == false" :src="eyesClosed" />
                            <span
                              style="
                                position: absolute;
                                top: 26px;
                                left: 18px;"
                              >ALL</span
                            >
                          </span>
                        </div>
                        <div
                          class="col-8 d-item bd-highlight layer-button"
                          v-b-popover.hover.right="'範囲選択レイヤーを追加します。'"
                        >
                          <button
                            type="button"
                            id="add"
                            class="btn btn-info w-100"
                            @click="newNameAddLayer()"
                            :disabled="isLicensedArSimulator == false"
                          >
                            選択箇所追加
                          </button>
                        </div>
                        <div
                          class="col-2 layer-sort"
                          @click="layerTabSort = !layerTabSort"
                          v-b-popover.hover.right.html="
                            'レイヤーの並び順（昇順⇔降順）<br/>を切り替えます。'
                          "
                        >
                          <span v-if="layerTabSort">▲</span>
                          <span v-if="!layerTabSort">▼</span>
                        </div>
                      </div>
                      <div v-show="upLoading == false" class="layer-tab-area">
                        <LayerTab
                          v-for="(layer, index) in reverseLayers()"
                          :key="index"
                          :layer="layer"
                          :num="index"
                          ref="layerTabs"
                          :flipFlag="flipFlag"
                          :layersMenuChange="layersMenuChange"
                          :isLicensedArSimulator="isLicensedArSimulator"
                          @changeActiveLayer="e => changeActiveLayer(e)"
                          @deleteLayer="e => deleteLayer(e)"
                          @simUndo="e => simUndo(e)"
                          @clickEnabledEvent="clickEnabledEvent"
                        />
                        <div class="mb-5"></div>
                      </div>
                    </div>
                    <div
                      id="edit-area"
                      ref="editArea"
                      @wheel.ctrl="e => zoomArea(e)"
                      @mousedown="e => moveStart(e)"
                      @touchstart="e => moveStart(e)"
                      @mousemove="e => move(e)"
                      @touchmove="e => move(e)"
                      @mouseout="moveEnd"
                      @mouseup="moveEnd"
                      @touchend="moveEnd"
                      :class="{
                        'mouse-cursor':
                          editProperty.editMode == 'move' &&
                          (dropper == false || ctrlCkick == true),
                        moving: editProperty.isMove,
                      }"
                    >
                      <div
                        class="wrapper"
                        ref="wrapper"
                        :style="canvasPosition"
                        @mousemove="cornerMouseMove($event)"
                        @touchmove="cornerMouseMove($event)"
                      >
                        <div class="inner mt-3" ref="editAreaInner" v-show="upLoading == false">
                          <div>
                            <canvas
                              id="base-image"
                              class="base-image"
                              ref="baseImage"
                              v-show="!imageDownloading"
                            ></canvas>
                          </div>
                          <div v-show="flipFlag == false">
                            <Layer
                              v-for="(layer, index) in layers"
                              :key="index"
                              :layerName="layer.name"
                              :layer="layer"
                              :baseImageInfo="baseImageInfo"
                              :editProperty="editProperty"
                              :layerReplacement="layerReplacement"
                              :allEnabledLayer="allEnabledLayer"
                              ref="layers"
                              @completeComponent="completeComponent"
                            />
                          </div>
                          <div v-show="flipFlag == true">
                            <LayerSim
                              v-for="layer in layers"
                              :key="layer.id"
                              :layerName="layer.name"
                              :layer="layer"
                              :baseImageInfo="baseImageInfo"
                              :editMode="simEditMode"
                              :zoom="editProperty.zoom"
                              :allEnabledLayer="allEnabledLayer"
                              :setChangeMaterial="setChangeMaterial"
                              ref="layersSim"
                              @completeComponent="completeComponent"
                              @mainSimulate="simulate"
                              @setMaterialEditLog="setMaterialEditLog"
                              @reDrawing="materialModeDrawing()"
                            />
                            <div
                              style="
                                position: absolute;
                                top: 0;
                                left: 0;
                              "
                            >
                              <canvas
                                class="clickCanvas"
                                ref="clickCanvas"
                                @mousedown="event => baseImageClickEvent(event, dropper)"
                                @touchstart="event => baseImageClickEvent(event, dropper)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-show="flipFlag == true && upLoading == false" style="background: #000">
                      <div class="row no-gutters material-list my-1">
                        <div class="col-12 offsimulateset-2 mx-auto">
                          <Material
                            @simulate="(val, name, color) => simulate(val, name, color)"
                            ref="material"
                            :class="{ hidden: isLicensedArSimulator == false }"
                            :activeLayerMaterial="activeLayerMaterial"
                            :activeLayer="activeLayer"
                            :windowIconSize="windowIconSize"
                            @completeComponent="completeComponent"
                            @materialModeDrawing="materialModeDrawing"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <allyAlert code="E0002" :params="{ number: '10' }"></allyAlert>
    <allyAlert code="E109"></allyAlert>
    <div id="fadeLayer" ref="fadeLayer"></div>

    <b-modal
      id="pattern"
      title="切り替え機能"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="切り替え"
      @ok="selectPetten()"
      @cancel="cancelModal()"
      @hide="cancelModal()"
    >
      切り替えると、現在の状態が失われます。<br />
      切り替えします？
    </b-modal>
    <b-modal
      id="addPattern"
      title="パターン作成"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="追加"
      @ok="addPetten()"
      @cancel="cancelModal()"
      @hide="cancelModal()"
    >
      パターン新規作成・・・新規でパターンを追加<br />
      パターン複製・・・現在のパターンを維持した状態で追加<br />

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">
          パターン新規作成
        </b-button>
        <b-button
          size="sm"
          style="color: white; background-color: Blue;"
          variant="outline-secondary"
          @click="copyPetten()"
        >
          パターン複製
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          キャンセル
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
    <b-modal
      id="pattenMax"
      title="パターンの上限"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-variant="light"
      ok-only
      ok-title="閉じる"
    >
      上限です。パターンは最大５つまでの追加可能です。<br />
      これ以上追加することはできません
    </b-modal>

    <b-modal
      id="deletePatten"
      title="パターンの削除"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-variant="light"
      ok-title="削除"
      cancel-title="キャンセル"
      @ok="deletePattenFunc()"
    >
      このパターンを削除しますか？
    </b-modal>

    <b-modal
      id="namechange"
      title="パターン名の変更"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-variant="light"
      ok-title="変更"
      cancel-title="キャンセル"
      @ok="nameChangeFix"
    >
      パターン名
      <allyText v-model="selectNameChange" />
    </b-modal>

    <b-modal
      id="newProperty"
      title="物件情報追加"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-variant="light"
      ok-only
      ok-title="閉じる"
    >
      物件が登録されました。<br />
      ※次回より『物件管理ページ』から開くことができます。<br />
    </b-modal>

    <b-modal
      id="updateProperty"
      title="物件情報確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      @hide="pegeChangeFlag = false"
    >
      シミュレーターの保存が完了しました。<br />
      担当者情報が登録されていません。<br />
      検索が利用しやすくなるためぜひご登録お願い致します。<br />
      <span v-if="pegeChangeFlag == true">完了後、一覧ページへ戻ります。</span>
      <div class="mt-3"></div>
      <ValidationObserver ref="changeModal">
        <allyText
          title="物件名"
          v-model="propertyInfo.commonValue.propertyName"
          rules="required|max:15"
        />
        <allyText title="担当者" v-model="propertyInfo.commonValue.staffName" rules="max:15" />
      </ValidationObserver>
      <template #modal-footer="{ ok }">
        <b-button
          size="sm"
          variant="success"
          @click="pegeChangeFlag == false ? ok() : backListPage()"
        >
          {{ pegeChangeFlag == false ? '閉じる' : '担当者情報は保存せず離れる' }}
        </b-button>
        <b-button size="sm" style="color: white; background-color: Blue;" @click="saveProperty()">
          {{ pegeChangeFlag == false ? '保存' : '担当者情報を保存して離れる' }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="backCheck"
      title="保存の確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      ok-variant="light"
      ok-title="保存する"
      cancel-title="保存しない"
      @ok="backSaveListPage"
      @cancel="backListPage"
    >
      現在の状態を保存しますか？
    </b-modal>

    <div id="contextmenu" ref="contextmenu">
      <div class="mt-1"></div>
      <div>
        <div
          v-for="(menu, index) of setLayerMenu()"
          :key="index"
          :class="{ active: getMenuActive(menu.active) }"
          class="right-menu"
          @click="layerMenus(menu.action)"
          v-b-popover.hover.right.html="menu.tips"
        >
          <span :class="menu.icon"></span>
          {{ menu.str }}
        </div>
        <div class="border-bottom" style="margin: 3px;"></div>
        <div
          ref="menu-delete"
          class="right-menu"
          :class="{ active: layersDeleteDisp }"
          @click="layerMenus('delete')"
          v-b-popover.hover.right="'レイヤーを削除します。'"
        >
          <img style="max-width: 20px;" :src="deleteIcon" class="layer-delete-icon" />
          削除
        </div>
      </div>
      <div class="mt-1"></div>
    </div>
  </div>
</template>
<script>
  import awsconfig from '../../../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import axios from 'axios';
  import Load from '../../../../components/Common/Load';
  //編集用コンポーネント
  import Layer from './Layer';
  import LayerSim from './SimulatorLayer';
  import Material from './Material';
  import LayerTab from './LayerTab';

  //graphQL
  import { updatePhotoSimulator, updateProperty } from '../../../../graphql/mutations';
  import { getS3Url, getProperty } from '../../../../graphql/queries';

  API.configure(awsconfig);

  //保存するレイヤー画像の拡張子
  const FILETYPE = '.png';

  export default {
    props: ['propertyInfo', 'photoData', 'loading'],
    data: function() {
      return {
        isLicensedArSimulator: false, //ライセンス
        simInfoData: null,
        upLoading: false,
        propertyId: null,
        level: this.private === undefined ? 'public' : 'private',
        imageName: null,
        baseImageInfo: {
          canvas: null,
          context: null,
          image: null,
        },
        mode: null,
        layerImages: [],
        imageDownloading: true,
        editProperty: {
          threshold: 15,
          lineWidth: 5,
          editMode: 'rect',
          // isEraser: false,
          isEdit: false,
          isMove: false,
          lastPosition: {
            x: 0,
            y: 0,
          },
          movePosition: {
            x: 0,
            y: 0,
          },
          zoom: 100,
        },
        //よく使う機能
        editModeProperty: [
          {
            name: 'floodFill',
            text: '自動選択',
            icon: 'ti-wand',
            tips: '選択したポイントと色が近い範囲内を<br/>自動選択します。',
          },
          {
            name: 'rect',
            text: '範囲選択',
            icon: 'ti-layout-sidebar-none',
            tips: '選択したい範囲をクリックで囲み<br/>設定します。',
          },
          {
            name: 'freeDraw',
            text: 'フリー<br />ハンド',
            icon: 'ti-pencil',
            tips: 'ドラッグで自由になぞった部分を<br/>設定できます。',
          },
        ],
        //範囲指定系機能
        editModeProperty2: [
          {
            name: 'line',
            text: '直線',
            icon: 'ti-layout-line-solid',
            tips:
              'ドラッグで引いた直線を設定します。<br/><span style="white-space: nowrap">線の太さは<i class="ti-pencil" />ライン幅で変更可能です。</span>',
          },
          {
            name: 'shapeRect',
            text: '四角形',
            icon: 'ti-control-stop',
            tips: 'ドラッグして描いた四角形の範囲を<br/>設定します。',
          },
          {
            name: 'shapeEllipse',
            text: '楕円形',
            icon: 'ti-control-record',
            tips: 'ドラッグして描いた円形の範囲を<br/>設定します。',
          },
          {
            name: 'curve',
            text: '曲線',
            icon: 'ti-vector',
            tips: 'クリックで描いた曲線（右クリックで確定）を設定します。',
          },
        ],
        //範囲消し（範囲解除）系
        editModeProperty3: [
          {
            name: 'eraserRect',
            text: '範囲消し',
            icon: 'icon-erase-range',
            tips: 'クリックで囲んだ範囲を<br/>消すことができます。',
          },
          {
            name: 'eraser',
            text: 'フリー消し',
            icon: 'icon-erase-free',
            tips: 'ドラッグでなぞった部分を<br/>消すことができます。',
          },
          {
            name: 'eraserLine',
            text: '直線消し',
            icon: 'icon-erase-line',
            tips: 'ドラッグで引く直線のラインで<br/>消すことができます。',
          },
          {
            name: 'eraserShapeRect',
            text: '四角消し',
            icon: 'icon-erase-rectangle',
            tips: 'ドラッグで作成した四角形の範囲内を<br/>消すことができます。',
          },
          {
            name: 'eraserShapeEllipse',
            text: '円形消し',
            icon: 'icon-erase-circle',
            tips: 'ドラッグで作成した円形の範囲内を<br/>消すことができます。',
          },
        ],
        //移動
        editModeProperty4: [
          {
            name: 'move',
            text: '移動',
            icon: 'ti-hand-open',
            tips: 'ドラッグで画像を掴み、動かすことができます。',
          },
        ],

        //layer{name: sring, mask, oldMask, undoData, isActive:bool}
        layers: [],
        idCounter: 1, //新規レイヤーの値を設定＆保存
        position: '10px',
        flipFlag: false, //シミュレーター箇所選択、シミュレーター画面の切り替え
        simEditMode: false,
        componentLoadFlag: {
          layer: 0,
          simLayer: 0,
          material: false,
          flag: false,
        },
        layerColor: [
          '#a0c238',
          '#c93a40',
          '#fff001',
          '#d06d8c',
          '#65ace4',
          '#56a764',
          '#d16b16',
          '#9460a0',
          '#f2cf01',
          '#0074bf',
        ],
        ctrlCkick: false,
        stackMode: null,

        layerSelectItem: [],
        activeSelectPetten: '0',
        oldChangeSelect: '0',
        selectNameChange: null,
        mobileDistance: 0,
        zooms: 100,
        focusPosition: 200, //トランスフォームカメラの位置
        reloadFuncFlag: false,
        reloadFuncFlip: false,
        swapFuncFlag: null, //レイヤーを切り替えるとき、activeレイヤーの調整を行う
        simulateSize: {
          //シミュレートで描画する画像サイズ（大きい程メモリに負担が掛かります）
          width: 1920,
          height: 1080,
        },
        undoMaterialLog: [],
        undoCanselMaterialLog: [],
        oldMode: null,
        eyesOpen: require('../../../../../src/images/simulationIcon/eyes-open.png'),
        eyesClosed: require('../../../../../src/images/simulationIcon/eyes-closed.png'),
        allEyesFlag: true, //全表示非表示機能
        dropper: false, //スポイト機能
        pegeChangeFlag: false, //ページを離れる状態を判別するflag
        deleteIcon: require('../../../../../src/images/simulationIcon/delete-icon2.png'), //削除アイコン
        layersMenu: {
          up: true,
          down: true,
        },
        propertyList: null,
        layerTabSort: false,
        layerMenuData: [
          {
            index: 1,
            active: 'down',
            action: 'mostDown',
            tips: '現在のレイヤーを最前面に<br/>移動します。',
            icon: 'icon-09',
            str: '最前面へ移動',
          },
          {
            index: 2,
            active: 'down',
            action: 'down',
            tips: '現在のレイヤーを１つ前面に<br/>移動します。',
            icon: 'icon-08',
            str: '前面へ移動',
          },
          {
            index: 3,
            active: 'up',
            action: 'up',
            tips: '現在のレイヤーを１つ背面に<br/>移動します。',
            icon: 'icon-10',
            str: '背面へ移動',
          },
          {
            index: 4,
            active: 'up',
            action: 'mostUp',
            tips: '現在のレイヤーを最背面に<br/>移動します。',
            icon: 'icon-11',
            str: '最背面へ移動',
          },
        ],
      };
    },
    computed: {
      layersLength() {
        return this.layers.length;
      },
      canvasPosition() {
        return 'transform: translate( 0px, 0px);';
      },
      activeLayerMaterial() {
        let activeLayers = this.layers.find(layer => layer.isActive);
        if (activeLayers == undefined) return null;
        return activeLayers.material;
      },
      activeLayer() {
        let activeLayers = this.layers.find(layer => layer.isActive);
        if (activeLayers == undefined) return null;
        return activeLayers;
      },
      materialSize() {
        let activeLayers = this.layers.find(layer => layer.isActive);
        if (activeLayers == undefined) return null;
        if (activeLayers.materialScale == undefined) return 100;
        return Number(activeLayers.materialScale);
      },
      /**
       * レイヤー数が１件の時、falseを設定する
       */
      layersDeleteDisp() {
        if (this.layers.length <= 1) return false;
        return true;
      },
    },
    watch: {
      'editProperty.editMode': function() {
        let flag = false;
        //Click中に切り替えた時、状態をクリアする
        if (this.editProperty.editMode == 'rect' && this.oldMode == 'eraserRect') {
          if (this.editProperty.isMove == true) {
            flag = true;
          }
        }

        //変形系の処理のときは、モードが移動しても変更を加えない
        if (
          this.editProperty.editMode != 'rect' &&
          this.editProperty.editMode != 'eraserRect' &&
          this.editProperty.editMode != 'curve'
        ) {
          if (this.editProperty.editMode != 'move') {
            flag = true;
          } else if (this.editProperty.editMode == 'move') {
            // １つ前のモードが変形モードじゃないとき、描画状態を解除する
            if (this.oldMode != 'rect' && this.oldMode != 'eraserRect' && this.oldMode != 'curve') {
              flag = true;
            }
          }
        }
        if (
          this.editProperty.editMode == 'rect' ||
          this.editProperty.editMode == 'eraserRect' ||
          this.editProperty.editMode == 'curve'
        ) {
          if (this.oldMode == 'rect' || this.oldMode == 'eraserRect' || this.oldMode == 'curve') {
            flag = true;
          }
        }

        //trueの時、描画状態をリセットする。
        if (flag == true) {
          this.editProperty.isEdit = false;
          for (let layer of this.$refs.layers) {
            layer.modeChange();
          }
        }
        this.oldMode = this.editProperty.editMode;
      },
      flipFlag: async function() {
        //シミュレート画面
        if (this.flipFlag == true) {
          this.upLoading = true;
          this.$refs.fadeLayer.style.visibility = 'visible';
          for (let i = 0; i < this.layers.length; i++) {
            let dom = await this.$refs.layers[i].$refs.main.toDataURL('image/png');
            await this.$refs.layersSim[i].dataUpdateCanvas(dom);
          }
          if (this.reloadFuncFlip == false) {
            this.upLoading = false;
            this.$refs.fadeLayer.style.visibility = 'hidden';
          }
        } else if (this.flipFlag == false) {
          //範囲指定画面
          var trueLayer = this.layers.filter(layer => layer.enabled == true);
          let dataCanvas = [];
          for (let layer of trueLayer) {
            if (this.$refs.layers != null) {
              let ctx = this.$refs.layers.find(lay => lay.layer.id == layer.id);
              if (ctx != null) {
                let data = {
                  canvas: await ctx.getMainCanvas(),
                  brightness: layer.brightness,
                  saturation: layer.saturation,
                  transparent: layer.transparent,
                  layerColor: layer.layerColor,
                  materialScale: layer.materialScale,
                  isActive: layer.isActive,
                };
                dataCanvas.push(data);
              }
            }
          }
          if (this.$refs.layers != null) {
            let ctx = await this.$refs.layers.find(lay => lay.layer.isActive);
            if (ctx != null) await ctx.fusionImage(dataCanvas);
          }
        }
        this.setLayerTabSize();
        this.$refs.material.setMaterialTabSize();
        this.dropper = false;
        this.simEditMode = false;
      },
    },
    mounted: async function() {
      this.upLoading = true;
      this.$refs.fadeLayer.style.visibility = 'visible';
      this.propertyId = this.$route.params.id;
      this.baseImageInfo.canvas = this.$refs.baseImage;
      this.baseImageInfo.context = this.baseImageInfo.canvas.getContext('2d');

      // ライセンスチェック（フォトシミュレーター）
      this.isLicensedArSimulator = this.$store.state.functions?.some(v => {
        return v == 8; // ALLY - ARシミュレーター
      });

      //プルダウン作成
      if (this.simInfoData.savePeten == null) {
        this.simInfoData.savePeten = [];
        this.simInfoData.savePeten.push({
          id: this.getUniqueStr(),
          order: '0',
          saveName: 'パターン1',
          layersInfo: [],
        });
      } else if (this.simInfoData.savePeten.length == 0) {
        this.simInfoData.savePeten.push({
          id: this.getUniqueStr(),
          order: '0',
          saveName: 'パターン1',
          layersInfo: [],
        });
      }

      let cnt = 0;
      let selectpatternItem = 0;
      for (let pattern of this.simInfoData.savePeten) {
        //patternIDが存在しないとき、生成する
        if (pattern.id == null) pattern.id = this.getUniqueStr();
        this.layerSelectItem.push({
          text: pattern.saveName,
          value: pattern.order,
        });
        //最終選択パターンが存在するとき、そのパターンを最初に選択する
        if (pattern.id == this.simInfoData.selectPattern) {
          selectpatternItem = cnt;
        }
        cnt++;
      }

      //初期表示パターン設定
      this.activeSelectPetten = this.layerSelectItem[selectpatternItem].value;
      this.oldChangeSelect = this.activeSelectPetten;

      //レイヤー情報
      await this.layerLoad(this.activeSelectPetten);

      //サイドバーのフォトシミュレーターから遷移したときに表示する。
      if (this.$route.query.new == 'true') this.$bvModal.show('newProperty');

      //レイヤータブの大きさ調整
      this.setLayerTabSize();
      let imageSizeX = this.$refs.editAreaInner.getElementsByClassName('dummyBaseImage')[0].width;
      let canvasSizeX = this.$refs.wrapper.clientWidth - 100;
      let imgSizeX = imageSizeX;
      let count = 0;
      for (let i = 1; imgSizeX > canvasSizeX; i++) {
        imgSizeX = imageSizeX * ((100 - i) * 0.01);
        count = i;
        if (i >= 90) break;
      }

      this.editProperty.zoom = 100 - count;
      this.zooms = this.editProperty.zoom; // 比較するため現在のズーム位置を保存して置く
      //拡大率を設定
      this.zoomElement(
        this.$refs.editAreaInner,
        this.editProperty.movePosition.x,
        this.editProperty.movePosition.y,
        this.editProperty.zoom
      );

      this.oldMode = this.editProperty.editMode;

      window.addEventListener('resize', () => {
        //ウィンドウサイズ変更したとき自動で演算し直す
        this.setLayerTabSize();
      });

      //右クリック禁止
      document.oncontextmenu = function() {
        return false;
      };
      //スペースキーを押したとき、移動モードに変更する。
      window.addEventListener('keydown', e => {
        let keycd = e.keyCode;
        if (keycd == 32 && this.ctrlCkick == false) {
          this.stackMode = this.editProperty.editMode;
          this.editProperty.editMode = 'move';
          this.ctrlCkick = true;
        }
      });
      window.addEventListener('keyup', e => {
        let keycd = e.keyCode;
        if (keycd == 32 && this.ctrlCkick == true) {
          this.editProperty.editMode = this.stackMode;
          this.ctrlCkick = false;
        }
        if (e.ctrlKey && e.code === 'KeyZ') {
          this.undoMask();
        }
      });
    },
    methods: {
      materialScaleChange: function(num) {
        let activeLayers = this.layers.find(layer => layer.isActive);
        if (activeLayers == undefined) return null;
        activeLayers.materialScale = num;
      },
      layerLoad: async function(selectNum) {
        //部屋画像Image取得
        await this.getImages([this.simInfoData.baseImageFileName])
          .then(async images => {
            this.baseImageInfo.image = await images[0];
          })
          .then(async () => {
            //部屋画像url設定後描画
            await this.drawbaseImage();
            if (this.$refs.wrapper.clientWidth < 600) {
              if (this.$refs.editAreaInner.clientWidth < 600) this.editProperty.zoom = -200;
            }
            this.editProperty.movePosition.x = this.position;
            this.zoomElement(
              this.$refs.editAreaInner,
              this.editProperty.movePosition.x,
              this.editProperty.movePosition.y,
              this.editProperty.zoom
            );

            if (
              this.simInfoData.savePeten.find(val => val.order == selectNum).layersInfo.length == 0
            ) {
              this.imageDownloading = false;
              return;
            }

            for (let layerInfo of this.simInfoData.savePeten.find(val => val.order == selectNum)
              .layersInfo) {
              let images = await this.getImages([layerInfo.layerImageFileName]);
              let layerData = {
                id: layerInfo.id,
                transparent: layerInfo.transparent,
                brightness: layerInfo.brightness,
                saturation: layerInfo.saturation,
                material: layerInfo.material,
                materialScale: layerInfo.materialScale,
                color: layerInfo.color,
                layerColor: layerInfo.layerColor,
                PerspectiveData: layerInfo.PerspectiveData,
              };
              await this.addLayer(layerInfo.layerDisplayName, images[0].fileUrl, layerData);
              await this.$nextTick();
              this.idCounter = Number(layerInfo.layerDisplayName.replace(/[^0-9]/g, '')) + 1;
              this.imageDownloading = false;
            }
          })
          .then(async () => {
            if (
              this.simInfoData.savePeten.find(val => val.order == selectNum).layersInfo.length == 0
            ) {
              await this.addLayer('選択' + this.idCounter);
              await this.$nextTick();
            }
          });
        await this.$nextTick();
      },

      /**
       * コンポーネント完了を検知する。
       */
      completeComponent: async function(compName = null) {
        //子コンポーネントの読み込みを確認する。
        if (compName != null) {
          if (compName == 'material') {
            this.componentLoadFlag.material = true;
          }
          if (compName == 'layer') {
            this.componentLoadFlag.layer += 1;
          }
          if (compName == 'simLayer') {
            this.componentLoadFlag.simLayer += 1;
          }
        }
        if (
          this.simInfoData.savePeten.find(val => val.order == this.activeSelectPetten).layersInfo
            .length <= this.componentLoadFlag.layer &&
          this.simInfoData.savePeten.find(val => val.order == this.activeSelectPetten).layersInfo
            .length <= this.componentLoadFlag.simLayer &&
          this.componentLoadFlag.material == true
        ) {
          if (this.componentLoadFlag.layer == this.componentLoadFlag.simLayer) {
            if (this.componentLoadFlag.flag == false && this.reloadFuncFlag == false) {
              await this.drawingLayes();
              await this.allEnabledLayer();
              if (this.layers[0] != null) await this.changeActiveLayer(this.layers[0].id);
              this.componentLoadFlag.flag = true;
              this.upLoading = false;
              this.swapFuncFlag = null;
              this.$refs.fadeLayer.style.visibility = 'hidden';
            }
          }
        }

        //reload処理中用 レイヤーの全てマウント処理が終わるまで以下の処理を待つ
        if (this.reloadFuncFlag == true) {
          if (
            this.layers.length == this.componentLoadFlag.layer &&
            this.layers.length == this.componentLoadFlag.simLayer
          ) {
            await this.reloadComponent();
          }
        }
      },
      reloadComponent: async function() {
        await this.drawingLayes();
        await this.allEnabledLayer();
        if (this.layers.length > 0) {
          await this.changeActiveLayer(this.layers[0].id);
        }
        if (this.swapFuncFlag != null) {
          //移動後に、移動したレイヤーをアクティブにする
          await this.changeActiveLayer(this.swapFuncFlag);
        }
        this.reloadFuncFlag = false;
        if (this.reloadFuncFlip == true) {
          //モードを切り替えていた時、元のモードに戻す
          this.flipFlag = true;
          this.reloadFuncFlip = false;
        }
        this.swapFuncFlag = null;
        this.upLoading = false;
        this.$refs.fadeLayer.style.visibility = 'hidden';
      },
      drawingLayes: async function() {
        //全ての子コンポーネントのロードを検知
        let materials = await this.$refs.material.$refs.material; //、Material一覧を取得

        //素材を描画する。
        for (let layer of this.layers) {
          try {
            if (layer.material != '') {
              if (layer.material != null) {
                let iamgeDom = await materials
                  ?.find(val => val.innerText == layer.material)
                  ?.querySelector('#material-img');
                let simDom = this.$refs.layersSim.find(val => val.layer.id == layer.id);
                await simDom.simulate(iamgeDom?.src);

                //画像を適用する。
                var trueLayer = this.layers.filter(layer => layer.enabled == true);
                let dataUrl = [];
                for (let layer of trueLayer) {
                  if (this.$refs.layers != null) {
                    let ctx = this.$refs.layers.find(lay => lay.layer.id == layer.id);
                    if (ctx != null) {
                      let data = {
                        canvas: await ctx.getMainCanvas(),
                        brightness: layer.brightness,
                        saturation: layer.saturation,
                        transparent: layer.transparent,
                      };
                      dataUrl.push(data);
                    }
                  }
                }
                for (let ctx of this.$refs.layers) {
                  await ctx.fusionImage();
                }
              }
            }
          } catch (e) {
            console.error(e);
          }
        }
        this.allEnabledLayer();
      },
      //
      //選択されているレイヤー以外の表示中のレイヤーを取得する。
      //
      allEnabledLayer: async function() {
        let trueLayer = this.layers.filter(layer => layer.enabled == true);
        let dataCanvas = [];
        for (let layer of trueLayer) {
          if (this.$refs.layers != null) {
            let ctx = this.$refs.layers.find(lay => lay.layer.id == layer.id);
            if (ctx != null) {
              let data = {
                canvas: await ctx.getMainCanvas(),
                brightness: layer.brightness,
                saturation: layer.saturation,
                transparent: layer.transparent,
                layerColor: layer.layerColor,
                isActive: layer.isActive,
              };
              dataCanvas.push(data);
            }
          }
        }
        if (this.$refs.layers != null) {
          let ctx = await this.$refs.layers.find(lay => lay.layer.isActive);
          if (ctx == null) return;
          if (dataCanvas.length == 0) {
            await ctx.fusionImage(null);
            return;
          }
          await ctx.fusionImage(dataCanvas);
        }
      },
      /**
       * レイヤーcanvasとタブdomを追加します。
       * 生成されたレイヤーはアクティブ状態になります。
       * @param string id レイヤー採番 暫定のレイヤー名とする
       */
      addLayer: async function(name, fileUrl = null, layerData = null) {
        if (this.layers.length > 9) {
          this.$bvModal.show('E0002');
          return;
        }
        //現在アクティブのレイヤーを非アクティブに変更
        let layers = await this.layers.map(layer => {
          return {
            id: layer.id == null ? this.getUniqueStr() : layer.id,
            name: layer.name,
            fileUrl: layer.fileUrl,
            isActive: false,
            enabled: layer.enabled,
            transparent: layer.transparent,
            brightness: layer.brightness,
            saturation: layer.saturation,
            material: layer.material,
            materialScale: layer.materialScale,
            color: layer.color,
            layerColor: layer.layerColor,
            PerspectiveData: layer.PerspectiveData,
          };
        });

        //レイヤーのcolorを設定する。
        let setLayerClolor = null;
        if (layerData != null && layerData.layerColor != null) {
          //既存のレイヤーがあるとき
          setLayerClolor = layerData.layerColor;
        } else {
          //既存のレイヤーがないとき
          let colorPallet = this.layerColor.concat();
          for (let i = this.layers.length - 1; i >= 0; i--) {
            if (colorPallet.find(val => val == this.layers[i].layerColor)) {
              //レイヤーに同じ色があった場合、パレットから色を削除する。
              colorPallet.splice(colorPallet.indexOf(this.layers[i].layerColor), 1);
            }
          }
          setLayerClolor = colorPallet[0];
        }

        let layer = {
          id: this.getUniqueStr(),
          name: name,
          fileUrl: fileUrl,
          isActive: true,
          enabled: true,
          color:
            layerData != null
              ? layerData.color != null
                ? layerData.color
                : setLayerClolor
              : setLayerClolor,
          layerColor: setLayerClolor,
          transparent:
            layerData != null ? (layerData.transparent != null ? layerData.transparent : 100) : 100, //透過
          brightness:
            layerData != null ? (layerData.brightness != null ? layerData.brightness : 100) : 100, //明度
          saturation:
            layerData != null ? (layerData.saturation != null ? layerData.saturation : 100) : 100, //彩度
          material:
            layerData != null ? (layerData.material != null ? layerData.material : null) : null, //Material
          materialScale:
            layerData != null
              ? layerData.materialScale != null
                ? layerData.materialScale
                : 100
              : 100, //Material
          PerspectiveData:
            layerData != null
              ? layerData.PerspectiveData != null
                ? layerData.PerspectiveData
                : null
              : null,
        };
        await layers.push(layer);
        this.layers = layers;
        this.idCounter++;
      },
      getUniqueStr(myStrong = null) {
        var strong = 1000;
        if (myStrong) strong = myStrong;
        return new Date().getTime().toString(16) + Math.floor(strong * Math.random()).toString(16);
      },
      newNameAddLayer: function() {
        let bottunPress = document.getElementById('add');
        //  スペースキーの誤動作防止
        bottunPress.addEventListener('keydown', function(event) {
          if (event.code === 'Space') {
            event.preventDefault();
          }
        });
        let lastNo = 0;
        for (let layer of this.layers) {
          if (layer.name.match(/選択/)) {
            let no = layer.name.replace(/選択/g, ''); // 文字列取り出し
            if (Number(lastNo) < Number(no)) lastNo = Number(no);
          }
        }
        this.addLayer('選択' + (lastNo + 1));
        this.undoMaterialLog = [];
        this.undoCanselMaterialLog = [];
      },
      //アクティブレイヤー変更
      changeActiveLayer: function(id) {
        //同じレイヤーをクリックしたとき、undo防止
        let flag = false;
        if (this.layers.find(val => val.id == id) != null) {
          if (this.layers.find(val => val.id == id).isActive == true) flag = true;
        }
        //現在アクティブのレイヤーを非アクティブに変更
        this.layers = this.layers.map(layer => {
          return {
            id: layer.id,
            name: layer.name,
            fileUrl: layer.fileUrl,
            isActive: layer.id === id ? true : false,
            enabled: layer.id === id ? true : layer.enabled,
            color: layer.color,
            layerColor: layer.layerColor,
            transparent: layer.transparent,
            brightness: layer.brightness,
            saturation: layer.saturation,
            material: layer.material,
            materialScale: layer.materialScale,
            PerspectiveData: layer.PerspectiveData,
          };
        });
        //素材画面の場合処理をしない
        if (this.flipFlag == false) {
          for (let i = 0; i < this.layers.length; i++) {
            if (this.$refs.layers != null) {
              let dom = this.$refs.layers[i];
              if (dom != null) {
                dom.fusionImage();
              }
            }
          }
          this.allEnabledLayer();
        }
        //素材反映画面で再描画する
        this.materialModeDrawing();
        if (flag == false) {
          //シミュレート画面側undoセット
          let activeLayer = this.layers.find(val => val.isActive == true);
          let materialData = this.$refs.material.getMaterial(activeLayer.material);
          this.undoMaterialLog = [];
          this.undoCanselMaterialLog = [];
          this.undoMaterialLog.push({
            mode: 'material',
            material: materialData == null ? null : materialData.url,
            color: activeLayer.color,
            name: activeLayer.material,
            singleColor: activeLayer.material == null ? true : false,
            transparent: activeLayer.transparent,
            brightness: activeLayer.brightness,
            saturation: activeLayer.saturation,
          });
          let simLayerDom = this.$refs.layersSim.find(layer => layer.layer.id == activeLayer.id);
          simLayerDom.setMaterialEditCorner(null, true);
        }
      },
      /**
       * //素材反映画面で再描画する
       */
      materialModeDrawing() {
        if (this.flipFlag == true) {
          for (let i = 0; i < this.layers.length; i++) {
            if (i === this.layers.length - 1) {
            let dom = this.$refs.layers[i].$refs.main.toDataURL('image/png');
            this.$refs.layersSim[i].dataUpdateCanvas(dom);
              this.$refs.layersSim[i].redrawFlag = true;
            }
          }
        }
      },
      //レイヤー削除
      deleteLayer: async function(id) {
        //レイヤーのマウント処理完了後切り替えを行う
        this.reloadFuncFlip = this.flipFlag;
        this.flipFlag = false;

        for (let layer of this.layers) {
          layer.fileUrl = this.$refs.layers
            .find(val => val.layer.id == layer.id)
            .$refs.main.toDataURL('image/png');
        }

        this.upLoading = true;
        this.$refs.fadeLayer.style.visibility = 'visible';
        let layers = await this.layers;
        this.layers = await [];

        //レイヤーのマウント完了状態をゼロに戻す。
        this.componentLoadFlag = {
          layer: 0,
          simLayer: 0,
          material: true,
          flag: false,
        };
        this.reloadFuncFlag = true;
        layers = await layers.filter(layer => layer.id != id);
        this.layers = await layers;
      },
      //ログイン情報から会社IDを取得
      getCompanyId: function() {
        //アカウントから会社ID取得
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },
      //S3からイメージを取得する
      getImages: async function(imageNames) {
        try {
          //ディレクトリ情報
          var propertyId = this.$route.params.id;
          var simulationFolder = 'simulation/baseImage';
          //layer検知した場合ディレクトリ変更
          if (imageNames[0].indexOf('layer') >= 0) {
            simulationFolder = 'simulation/layerImage';
          }

          //パラメータ用に再格納
          let files = await imageNames.map(name => {
            return {
              fileName: name,
              fileAction: 'View',
              propertyDirectory: {
                propertyId: propertyId,
                fileDirectory: simulationFolder,
              },
            };
          });
          let results = await API.graphql(
            graphqlOperation(getS3Url, {
              level: 'public',
              file: files,
            })
          );

          // Download
          return results.data.getS3Url;
        } catch (e) {
          console.error(e);
          this.$bvModal.show('E0001');
        }
      },
      //部屋画像描画
      drawbaseImage: async function() {
        return new Promise((resolve, reject) => {
          let ctx = this.baseImageInfo.context;
          let image = new Image();
          image.src = this.baseImageInfo.image.fileUrl;
          image.crossOrigin = 'anonymous';
          image.onload = () => {
            //画像サイズが大きすぎる場合、サイズ調整を行う
            let resizeW = image.width;
            let resizeH = image.height;

            let count = 1000;
            //指定のサイズ以下になるまで処理を繰り返す
            while (resizeW > this.simulateSize.width || resizeH > this.simulateSize.height) {
              resizeW = image.width * (count * 0.001);
              resizeH = image.height * (count * 0.001);
              count--;
            }
            this.$refs.baseImage.width = resizeW;
            this.$refs.baseImage.height = resizeH;
            this.$refs.clickCanvas.width = resizeW;
            this.$refs.clickCanvas.height = resizeH;
            (async () => {
              await ctx.drawImage(image, 0, 0, resizeW, resizeH);
              resolve(reject);

              //キャンバス初期位置調整
              var domPoint = this.$refs.editArea.getBoundingClientRect();
              var basePoint = resizeW;
              var median = (domPoint.width - basePoint) / 2;

              if (median < 10) median = 10;
              this.position = Math.floor(median);
            })();
          };
        });
      },

      /**
       * マスク状態を一つ前に戻します。
       */
      undoMask: function() {
        //アクティブレイヤーがない場合は処理終了
        let activeLayer = this.layers.find(layer => layer.isActive);
        if (activeLayer == undefined) return;
        let dom = this.$refs.layers.find(layer => layer.layer.id == activeLayer.id);
        dom.undoMask();
      },
      /**
       * マスク状態を一つ前に戻します。
       */
      undoCancelMask: function() {
        //アクティブレイヤーがない場合は処理終了
        let activeLayer = this.layers.find(layer => layer.isActive);
        if (activeLayer == undefined) return;
        let dom = this.$refs.layers.find(layer => layer.layer.id == activeLayer.id);
        dom.undoCancelMask();
      },

      /**
       * 編集したレイヤーをgraphQLに保存します。
       */
      saveLayers: async function(modalFlag = false) {
        if (this.upLoading == true) return;
        this.upLoading = true;
        this.$refs.fadeLayer.style.visibility = 'visible';

        if (this.layers.length >= 0) {
          //保存データ用オブジェクト作成
          this.createLayerImageObjects();

          //サムネイル発火
          await this.thumbnailSave();
          //画像を保存する。
          await this.fileUpload();

          //更新データの準備
          let updloadData = {
            company: null,
            id: null,
            propertyId: null,
            simInfo: null,
          };
          //PK,SKをセットする。
          updloadData.company = this.propertyInfo.company;
          updloadData.propertyId = this.propertyInfo.id;
          updloadData.id = this.photoData.id;

          //変更内容を保存する。
          updloadData.simInfo = this.photoData.simInfo;

          //現在選択中のパターンを保存する。
          updloadData.simInfo.selectPattern = updloadData.simInfo.savePeten.find(
            val => val.order == this.activeSelectPetten
          ).id;

          var getLayer = this.setLayersInfo();

          //名称変更を適用
          this.simInfoData.savePeten.find(
            val => val.order == this.activeSelectPetten
          ).saveName = this.layerSelectItem.find(val => val.value == this.activeSelectPetten).text;

          this.simInfoData.savePeten.find(
            val => val.order == this.activeSelectPetten
          ).layersInfo = getLayer.map(layer => {
            return {
              id: layer.id,
              layerImageFileName: layer.layerImageFileName,
              layerDisplayName: layer.layerDisplayName,
              material: layer.material,
              materialScale: layer.materialScale.toString(),
              color: layer.color,
              layerColor: layer.layerColor,
              transparent: layer.transparent.toString(),
              brightness: layer.brightness.toString(),
              saturation: layer.saturation.toString(),
              PerspectiveData: layer.PerspectiveData,
            };
          });

          this.simInfoData.simUpdateDate = new Date(); //最新の日時をセット

          try {
            await API.graphql({
              query: updatePhotoSimulator,
              variables: { input: updloadData },
            });

            await this.setSimulationLog(updloadData, 'edit');

            //モーダルを呼び出す。
            if (modalFlag == false) {
              this.$bvModal.show('S100');
            }
          } catch (e) {
            console.error(e);
            //エラー時モーダルを表示する。
            this.$bvModal.show('E0001');
          }
        } else {
          this.$bvModal.show('E109');
        }
        this.upLoading = false;
        this.$refs.fadeLayer.style.visibility = 'hidden';
        if (this.propertyInfo.commonValue.staffName == null) {
          this.$bvModal.show('updateProperty');
        }
      },
      /*
       *担当者名が保存されていないときに、簡易的に保存をできるようにする。
       */
      saveProperty: function() {
        this.$refs.changeModal.validate().then(async isValid => {
          if (isValid == false) return;
          this.$bvModal.hide('updateProperty');
          let updloadData = {
            company: this.propertyInfo.company,
            id: this.propertyInfo.id,
            commonValue: this.propertyInfo.commonValue,
          };
          this.upLoading = true;
          this.$refs.fadeLayer.style.visibility = 'visible';
          await API.graphql({
            query: updateProperty,
            variables: { input: updloadData },
          });
          this.upLoading = false;
          if (this.$refs.fadeLayer != null) {
            this.$refs.fadeLayer.style.visibility = 'hidden';
            this.$bvModal.show('S100');
          }
        });
        //flagがONの時、処理を止める
        if (this.pegeChangeFlag == true) this.backListPage();
      },
      createLayerImageObjects: function() {
        this.layerImages = [];
        this.layerImages = this.$refs.layers.map(layer => {
          //canvasから画像データ取得
          let dataURL = layer.convertCanvasToImage();
          //blobデータに変換
          let blob = this.dataURLtoBlob(dataURL);
          //画像ファイル名生成
          let fileName = this.generateFileName(layer.layer.id);
          //ロード時の情報を使用して上書き判定を行う
          let isExists =
            this.layerImages == null
              ? false
              : this.layerImages.find(image => image.fileName == fileName);
          //再度追加したらisDeletedフラグfalse
          if (isExists) {
            isExists.isDeleted = false;
            return;
          }
          //画像を選択
          return {
            id: layer.layer.id,
            fileBlob: blob, // blob
            fileLayer: layer.layer.name, //レイヤー名
            fileName: fileName, // ファイル名
            fileDataURL: dataURL, // イメージDataUrl
            fileExt: FILETYPE, // ファイルタイプ
            isAdded: true, // 追加フラグ
            isDeleted: false, // 削除フラグ
            transparent: layer.layer.transparent, //透過
            brightness: layer.layer.brightness, //明度
            saturation: layer.layer.saturation, //彩度
            material: layer.layer.material, //素材
            materialScale: layer.layer.materialScale,
            PerspectiveData: layer.layer.PerspectiveData, //変形情報
            color: layer.layer.color,
            layerColor: layer.layer.layerColor,
          };
        });
      },
      generateFileName: function(id) {
        //ディレクトリの削除
        var fileName = this.baseImageInfo.image.fileName.replace(/[a-zA-Z0-9-?]+\//g, '');
        return (
          fileName.substr(0, 32) + '_pattern' + this.activeSelectPetten + '_layer' + id + '.png'
        );
      },
      dataURLtoBlob: function(dataurl) {
        // DataURL => Blob
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      },
      fileUpload: async function() {
        var propertyId = this.$route.params.id;
        //var simulationFolder = 'Property-' + propertyId + '/simulation/layerImage/';

        var links = await API.graphql(
          graphqlOperation(getS3Url, {
            level: this.level,
            file: this.layerImages
              .filter(x => x.isAdded || x.isDeleted)
              .map(x => {
                return {
                  fileName: x.fileName,
                  fileAction: x.isAdded ? 'Add' : 'Delete',
                  propertyDirectory: {
                    propertyId: propertyId,
                    fileDirectory: 'simulation/layerImage',
                  },
                };
              }),
          })
        );

        for (var i = 0; i < links.data.getS3Url.length; i++) {
          var link = links.data.getS3Url[i];
          var file = this.layerImages.filter(x => x.fileName == link.fileName)[0];
          if (!file) continue;
          axios.put(link.fileUrl, new File([file.fileBlob], link.fileName), {
            'Content-Type': 'image/' + file.fileExt,
          });
        }
      },
      setLayersInfo: function() {
        return this.layerImages.map(image => {
          return {
            id: image.id,
            layerDisplayName: image.fileLayer,
            layerImageFileName: image.fileName,
            dataUrl: image.fileDataURL,
            material: image.material,
            materialScale: image.materialScale,
            transparent: image.transparent,
            brightness: image.brightness,
            saturation: image.saturation,
            PerspectiveData: image.PerspectiveData,
            color: image.color,
            layerColor: image.layerColor,
          };
        });
      },
      //一覧ページへ戻る
      backListPage: function() {
        //
        if (this.$route.query.side == 'true') {
          this.$router.push('/Property/new/WallPaperSimulator/Edit/new');
        } else if (this.$route.query.room == null) {
          this.$router.push('/Property/View/' + this.propertyId);
        } else {
          let link = '/Property/View/' + this.propertyId + '/RoomView/' + this.$route.query.room;
          if (this.propertyList == 'true') link += '?propertyList=true';
          this.$router.push(link);
        }
      },
      //担当確認があるとき止まる
      backSaveListPage: async function() {
        await this.saveLayers(true);
        if (this.propertyInfo.commonValue.staffName != null) {
          this.backListPage();
        }
        this.pegeChangeFlag = true; //ページ離脱フラグをONにする。
      },
      zoomArea: function(e) {
        if (e.targetTouches == null) e.preventDefault();

        //スクロールの+-が反比例するので
        if (e.deltaY < 0) {
          if (this.editProperty.zoom < 300) {
            this.editProperty.zoom = Number(this.editProperty.zoom) + 5;
            if (this.editProperty.zoom > 300) this.editProperty.zoom = 300;
          }
        } else {
          if (this.editProperty.zoom > 1) {
            this.editProperty.zoom = Number(this.editProperty.zoom) - 5;
            if (this.editProperty.zoom < 1) this.editProperty.zoom = 1;
          }
        }

        //マウスカーソルの設定位置計算
        let clientRect = this.$refs.editArea.getBoundingClientRect();
        let pointerX = e.clientX - clientRect.left;
        let pointerY = e.clientY - clientRect.top;

        //マウスカーソルの位置からの拡大縮小による、画像位置の計算
        let zoomX = pointerX / ((pointerX * (this.editProperty.zoom * 0.01)) / pointerX);
        let oldZoomX = pointerX / ((pointerX * (this.zooms * 0.01)) / pointerX);
        let zoomY = pointerY / ((pointerY * (this.editProperty.zoom * 0.01)) / pointerY);
        let oldZoomY = pointerY / ((pointerY * (this.zooms * 0.01)) / pointerY);

        //位置設定
        this.editProperty.movePosition.x += zoomX - oldZoomX;
        this.editProperty.movePosition.y += zoomY - oldZoomY;

        //前回の拡大率を調整する
        this.zooms = this.editProperty.zoom;

        //拡大率を設定
        this.zoomElement(
          this.$refs.editAreaInner,
          this.editProperty.movePosition.x,
          this.editProperty.movePosition.y,
          this.editProperty.zoom
        );
      },
      zommText: function() {
        if (this.editProperty.zoom > 300) {
          this.editProperty.zoom = 300;
        } else if (this.editProperty.zoom < 1) {
          this.editProperty.zoom = 1;
        }

        //ズームを計算
        let zoom =
          this.$refs.wrapper.clientWidth /
          ((this.$refs.wrapper.clientWidth * (this.editProperty.zoom * 0.01)) /
            this.$refs.wrapper.clientWidth);
        let oldZoom =
          this.$refs.wrapper.clientWidth /
          ((this.$refs.wrapper.clientWidth * (this.zooms * 0.01)) / this.$refs.wrapper.clientWidth);

        this.editProperty.movePosition.x += (zoom - oldZoom) / 2;

        this.zooms = this.editProperty.zoom;
        this.zoomElement(
          this.$refs.editAreaInner,
          this.editProperty.movePosition.x,
          this.editProperty.movePosition.y,
          this.editProperty.zoom
        );
      },
      /**
       * 指定した要素を、マウス位置を中心に拡大縮小する
       * @param HTMLElment elm
       * @param number x
       * @param number y
       * @param number z
       */
      zoomElement: function(elm, x = 0, y = 0, z = 0) {
        let focus = this.focusPosition; //集点距離
        let zoom = 0; // Z位置

        let imageSizeX = null;
        let imageSizeY = null;
        //遠近法の計算を行う 「Z位置 = 画像の元サイズ * 集点距離 / 撮影サイズ - 集点距離」
        if (this.$refs.editAreaInner.getElementsByClassName('base-image')[0] != null) {
          //画像が範囲外にできないように設定する
          imageSizeX = this.$refs.editAreaInner.getElementsByClassName('base-image')[0].width;
          imageSizeY = this.$refs.editAreaInner.getElementsByClassName('base-image')[0].height;

          let viewSizeX =
            this.$refs.wrapper.clientWidth /
            ((this.$refs.wrapper.clientWidth * (this.editProperty.zoom * 0.01)) /
              this.$refs.wrapper.clientWidth);
          let viewSizeY =
            this.$refs.wrapper.clientHeight /
            ((this.$refs.wrapper.clientHeight * (this.editProperty.zoom * 0.01)) /
              this.$refs.wrapper.clientHeight);

          //画像が範囲外に出たとき範囲内に戻す処理
          if (this.editProperty.movePosition.x <= imageSizeX / 8 - imageSizeX) {
            this.editProperty.movePosition.x = imageSizeX / 8 - imageSizeX;
            x = this.editProperty.movePosition.x;
          }
          if (this.editProperty.movePosition.y <= imageSizeY / 8 - imageSizeY) {
            this.editProperty.movePosition.y = imageSizeY / 8 - imageSizeY;
            y = this.editProperty.movePosition.y;
          }
          if (this.editProperty.movePosition.x >= viewSizeX - imageSizeX / 8) {
            this.editProperty.movePosition.x = viewSizeX - imageSizeX / 8;
            x = this.editProperty.movePosition.x;
          }
          if (this.editProperty.movePosition.y >= viewSizeY - viewSizeY / 8) {
            this.editProperty.movePosition.y = viewSizeY - viewSizeY / 8;
            y = this.editProperty.movePosition.y;
          }

          let objectWidthSize = null; //画像の元サイズ
          objectWidthSize = imageSizeX;
          let viewSize = objectWidthSize * (z * 0.01); //撮影サイズを求める
          zoom = -((objectWidthSize * focus) / viewSize - focus); //Z位置
        }
        //位置をずらす
        elm.style.transform = `perspective(${focus}px) translate3d(${x}px, ${y}px, ${zoom}px)`;
      },
      /**
       * 指定した要素を移動する
       * @param HTMLElment elm
       * @param number x
       * @param number y
       */
      moveElement: function(elm, x = 0, y = 0, z = 0) {
        this.zoomElement(elm, x, y, z);
      },
      moveStart: function(e) {
        this.editProperty.isMove = true;

        let moveX = e.offsetX;
        let moveY = e.offsetY;
        if (e.changedTouches != null) {
          moveX = e.changedTouches[0].clientX;
          moveY = e.changedTouches[0].clientY;
        }
        this.editProperty.lastPosition.x = moveX;
        this.editProperty.lastPosition.y = moveY;
      },
      move: function(e) {
        if (!this.editProperty.isMove) return;
        if (e.targetTouches != null) {
          if (e.targetTouches.length >= 2) {
            this.mobileZoomFunction(e);
            return;
          }
        }
        if (
          this.editProperty.editMode != 'move' ||
          (this.dropper == true && this.ctrlCkick == false)
        )
          return;
        if (this.layers.length != 0) {
          if (this.$refs.layersSim.find(val => val.layer.isActive == true).cornerDraggable == true)
            return;
        }
        //  取得した要素が画像外の領域か判断
        let activeClass = e.target.className === 'wrapper' ? true : false;

        let moveX = e.offsetX;
        let moveY = e.offsetY;
        if (e.changedTouches != null) {
          moveX = e.changedTouches[0].clientX;
          moveY = e.changedTouches[0].clientY;
        }

        //スクロール禁止
        document.addEventListener('touchmove', e.preventDefault(), { passive: false });

        const moveThreshold = this.editProperty.zoom; //  マウスの移動許容範囲（単位：px）
        //const moveDistance = 7;
        const diffX = this.editProperty.lastPosition.x - moveX;
        const diffY = this.editProperty.lastPosition.y - moveY;

        //  拡大/縮小に合わせたマウス移動量を調節するための値
        let adjustValue = 3;
        //  拡大/縮小値に合わせた移動量基準値
        let moveReferenceValue = 100 / moveThreshold;

        if (moveThreshold < Math.abs(diffX)) {
          if (activeClass) {
            this.editProperty.lastPosition.x = moveX;
            this.editProperty.movePosition.x -= diffX * moveReferenceValue;
          } else {
            this.editProperty.movePosition.x -= diffX; 
          }
        }
        if (moveThreshold < Math.abs(diffY)) {
          if (activeClass) {
            this.editProperty.lastPosition.y = moveY;
            this.editProperty.movePosition.y -= diffY * moveReferenceValue;
          } else {
            this.editProperty.movePosition.y -= diffY;
          }
        }
        this.moveElement(
          this.$refs.editAreaInner,
          this.editProperty.movePosition.x,
          this.editProperty.movePosition.y,
          this.editProperty.zoom
        );
      },
      moveEnd: function() {
        this.editProperty.isMove = false;
      },
      //素材切り替え時の処理
      simulate: async function(material, name, singleColor = null) {
        //前回と変更がない場合、処理を行わない。
        if (this.undoMaterialLog.length != 0) {
          let checkUndo = this.undoMaterialLog[this.undoMaterialLog.length - 1];
          if (checkUndo.material == null && material == null) {
            if (checkUndo.color == this.layers.find(val => val.isActive == true).color) return;
          } else if (material == checkUndo.material) {
            return;
          }
        }
        //undo設定し、描画
        let activeLayer = this.layers.find(val => val.isActive == true);
        this.undoMaterialLog.push({
          mode: 'material',
          material: material,
          color: activeLayer.color,
          name: name,
          singleColor: singleColor,
          transparent: activeLayer.transparent,
          brightness: activeLayer.brightness,
          saturation: activeLayer.saturation,
        });
        this.undoCanselMaterialLog = [];
        this.setSimulate(
          material,
          name,
          singleColor,
          this.layers.find(val => val.isActive == true).color
        );
      },
      //素材のundo
      undoMaterial() {
        if (this.undoMaterialLog[this.undoMaterialLog.length - 1].mode != 'initMaterialRange' && this.undoMaterialLog.length > 1) {
          let sim = this.undoMaterialLog[this.undoMaterialLog.length - 2];
          if (sim.mode == 'material') {
            this.setSimulate(sim.material, sim.name, sim.singleColor, sim.color);
          } else if (sim.mode == 'materialRange' || sim.mode == 'initMaterialRange') {
            let activeLayer = this.layers.find(layer => layer.isActive);
            let simLayerDom = this.$refs.layersSim.find(layer => layer.layer.id == activeLayer.id);
            simLayerDom.setMaterialEditCorner(sim.perspectiveData);
          }
          let layer = this.layers.find(val => val.isActive == true);
          layer.transparent = sim.transparent;
          layer.brightness = sim.brightness;
          layer.saturation = sim.saturation;

          let simSet = this.undoMaterialLog[this.undoMaterialLog.length - 1];
          this.undoCanselMaterialLog.push(simSet);
          this.undoMaterialLog.pop();
        }
      },
      //素材のundo
      undoCancelMaterial() {
        if (this.undoCanselMaterialLog.length > 0) {
          let sim = this.undoCanselMaterialLog[this.undoCanselMaterialLog.length - 1];
          if (sim.mode == 'material') {
            if (sim.PerspectiveData) {
              let activeLayer = this.layers.find(val => val.isActive == true);
              let simLayerDom = this.$refs.layersSim.find(layer => layer.layer.id == activeLayer.id);
              simLayerDom.setMaterialEditCorner(sim.PerspectiveData);
            }
            this.setSimulate(sim.material, sim.name, sim.singleColor, sim.color);
          } else if (sim.mode == 'materialRange') {
            let activeLayer = this.layers.find(layer => layer.isActive);
            let simLayerDom = this.$refs.layersSim.find(layer => layer.layer.id == activeLayer.id);
            simLayerDom.setMaterialEditCorner(sim.perspectiveData);
          }
          let layer = this.layers.find(val => val.isActive == true);
          layer.transparent = sim.transparent;
          layer.brightness = sim.brightness;
          layer.saturation = sim.saturation;

          this.undoMaterialLog.push(sim);
          this.undoCanselMaterialLog.pop();
        }
      },
      //設定された素材を画面に設定する。
      setSimulate: async function(material, name, singleColor, color) {
        //シミュレーター
        let activeLayer = this.layers.find(layer => layer.isActive);
        if (activeLayer == undefined) return;
        activeLayer.color = color;

        let simDom = this.$refs.layersSim.find(layer => layer.layer.id == activeLayer.id);
        await simDom.simulate(material, singleColor);
        this.layers.find(layer => layer.isActive).material = name;
      },
      //シミュレーターレイヤーで差し替え発生時、差し替え用の画像を取得する。
      layerReplacement: async function(dataUrl) {
        let activeLayer = this.layers.find(layer => layer.isActive);
        if (activeLayer == undefined) return;
        let dom = this.$refs.layersSim.find(layer => layer.layer.id == activeLayer.id);
        return await dom.dataUpdateCanvas(dataUrl);
      },
      /**
       * 画像を出力する。
       */
      exportImage: async function() {
        try {
          //適応するレイヤーのみ取得
          let enableLayers = this.layers.filter(layer => layer.enabled);
          if (enableLayers < 1) return;

          //統合したcontextを取得
          let canvas = await this.fusionCanvas(this.baseImageInfo, enableLayers);
          //contextをImageに変換
          let image = await this.createImage(canvas, 'image/png');
          //別タブ（もしくは保存）処理
          let dlLink = document.createElement('a');
          dlLink.href = image.src;
          dlLink.download = this.simInfoData.baseImageDisplayName + '.png';
          dlLink.click();
        } catch (e) {
          this.$bvModal.show('E0001');
        }
      },
      /**
       * サムネイル用画像を作成する
       */
      thumbnailSave: async function() {
        //適応するレイヤーのみ取得
        let enableLayers = this.layers.filter(layer => layer.enabled);
        if (enableLayers < 1) return;

        //シミュレート画面（素材反映画面）の情報を更新する。
        for (let i = 0; i < this.layers.length; i++) {
          let dom = await this.$refs.layers[i].$refs.main.toDataURL('image/png');
          await this.$refs.layersSim[i].dataUpdateCanvas(dom);
          await this.$refs.layersSim[i].simulate();
        }

        //統合したcontextを取得
        let canvas = await this.fusionCanvas(this.baseImageInfo, enableLayers);
        let blob = this.dataURLtoBlob(canvas.toDataURL());

        //保存処理
        let propertyId = this.$route.params.id;
        let baseImageName = this.simInfoData.baseImageFileName;
        let patternId = this.simInfoData.savePeten[this.activeSelectPetten].id;
        baseImageName = baseImageName.replace(/\.[^/.]+$/, '');

        let upFileData = [
          {
            fileName: baseImageName + '_' + patternId + '.png',
            fileAction: 'Add',
            propertyDirectory: {
              propertyId: propertyId,
              fileDirectory: 'simulation/thumbnailImage',
            },
          },
        ];

        let links = await API.graphql(
          graphqlOperation(getS3Url, {
            level: this.level,
            file: upFileData,
          })
        );

        //画像保存
        for (var i = 0; i < links.data.getS3Url.length; i++) {
          var link = links.data.getS3Url[i];
          if (!blob) continue;
          axios.put(link.fileUrl, new File([blob], link.fileName), {
            'Content-Type': 'image/png',
          });
        }
      },
      /**
       * Canvas合成
       *
       * @param {object} baseImageInfo 部屋画像情報
       * @param {array} activeLayers アクティブなレイヤープロパティ
       * @return {canvas} 合成後のcanvasオブジェクト
       */
      fusionCanvas: async function(baseImageInfo, enableLayers) {
        //統合キャンパス生成
        let canvas = document.createElement('canvas');
        canvas.width = baseImageInfo.canvas.width;
        canvas.height = baseImageInfo.canvas.height;
        let ctx = canvas.getContext('2d');

        //部屋画像を統合キャンパスに出力
        let mainImage = await this.createImage(baseImageInfo.canvas, 'image/png');
        ctx.drawImage(mainImage, 0, 0);

        enableLayers.forEach(async enableLayer => {
          let layerDom = this.$refs.layersSim.find(layer => layer.layer.id == enableLayer.id).$refs
            .main;
          let layerImage = await this.createImage(layerDom, 'image/png');
          if (enableLayer.transparent != null) ctx.globalAlpha = enableLayer.transparent / 100; //透明化のセット
          ctx.filter =
            'brightness(' +
            enableLayer.brightness +
            '% ) saturate(' +
            enableLayer.saturation +
            '%)'; //明度 彩度のセット
          ctx.drawImage(layerImage, 0, 0);
          ctx.globalAlpha = 1.0;
          ctx.filter = 'none';
        });
        return canvas;
      },
      //キャンパスのデータを画像にする。
      createImage: async function(canvas, mime_type) {
        let image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = canvas.toDataURL(mime_type);
        return image;
      },
      /**
       * マウスを動かしたデータを取得する。
       */
      cornerMouseMove: function(ev) {
        //アクティブレイヤーがない場合は処理終了
        let activeLayer = this.layers.find(layer => layer.isActive);
        if (activeLayer == undefined) return;
        let simLayerDom = this.$refs.layersSim.find(layer => layer.layer.id == activeLayer.id);
        simLayerDom.cornerMouseMove(ev);
      },
      minMaxLimit: function(val) {
        let num = val;
        if (num > 100) {
          num = 100;
        } else if (num < 0) {
          num = 0;
        }
        return num;
      },
      //パターン切り替え時、の処理
      selectPetten: async function() {
        this.$refs.fadeLayer.style.visibility = 'visible';
        this.upLoading = true;

        this.oldChangeSelect = this.activeSelectPetten;
        this.layers = await [];

        //レイヤーのマウント完了数を０に戻す
        this.componentLoadFlag = {
          layer: 0,
          simLayer: 0,
          material: true,
          flag: false,
        };

        this.idCounter = 1;
        await this.layerLoad(this.activeSelectPetten);

        //レイヤーのマウント完了後処理を行う
        this.reloadFuncFlag = true;
        this.reloadFuncFlip = this.flipFlag;
        if (this.reloadFuncFlip == true) {
          this.flipFlag = false;
        }

        this.zoomElement(
          this.$refs.editAreaInner,
          this.editProperty.movePosition.x,
          this.editProperty.movePosition.y,
          this.editProperty.zoom
        );
        await this.drawingLayes();
      },
      //パターンを新規追加する。
      addPetten: async function() {
        await this.saveLayers(true);
        //画面を暗転して処理中にする。
        this.$refs.fadeLayer.style.visibility = 'visible';
        this.upLoading = true;

        //新たなパターンの順番を呼び出す。
        let max = this.simInfoData.savePeten.reduce((a, b) => (a.order > b.order ? a : b)).order;
        this.oldChangeSelect = (Number(max) + 1).toString();

        let saveName = null;
        let dup = null;
        let count = 0;
        do {
          saveName = 'パターン' + (Number(max) + count + 2).toString();
          dup = this.layerSelectItem.find(val => val.text == saveName);
          count++;
        } while (dup != null);

        this.simInfoData.savePeten.push({
          id: this.getUniqueStr(),
          order: (Number(max) + 1).toString(),
          saveName: saveName,
          layersInfo: [],
        });

        this.layerSelectItem.push({
          text: this.simInfoData.savePeten.find(val => val.order == (Number(max) + 1).toString())
            .saveName,
          value: this.simInfoData.savePeten.find(val => val.order == (Number(max) + 1).toString())
            .order,
        });

        this.activeSelectPetten = (Number(max) + 1).toString();
        this.layers = [];
        this.idCounter = 1;
        await this.layerLoad(this.activeSelectPetten);

        this.upLoading = false;
        this.$refs.fadeLayer.style.visibility = 'hidden';
        await this.saveLayers(true);
      },

      //パターン複製の処理
      copyPetten: async function() {
        this.$bvModal.hide('addPattern');
        await this.saveLayers(true);

        //画面を暗転して処理中にする。
        this.$refs.fadeLayer.style.visibility = 'visible';
        this.upLoading = true;

        //レイヤーのマウントが完了したとき描画処理を行うflag
        this.reloadFuncFlip = this.flipFlag;
        this.flipFlag = false;

        let savePaten = this.simInfoData.savePeten.find(val => val.order == this.oldChangeSelect);

        //新たなパターンの順番を呼び出す。
        let max = this.simInfoData.savePeten.reduce((a, b) => (a.order > b.order ? a : b)).order;
        this.oldChangeSelect = (Number(max) + 1).toString();

        let pattenLayer = [];
        for (let layer of savePaten.layersInfo) {
          let layerData = layer;
          if (layer.id != null) layerData.layerImageFileName = this.generateFileName(layer.id);
          if (layer.id == null)
            layerData.layerImageFileName = this.generateFileName(layer.layerDisplayName);
          pattenLayer.push(layerData);
        }

        let saveName;

        let dup = null;
        let count = 0;
        do {
          saveName = 'パターン' + (Number(max) + count + 2).toString();
          dup = this.layerSelectItem.find(val => val.text == saveName);
          count++;
        } while (dup != null);

        this.simInfoData.savePeten.push({
          id: this.getUniqueStr(),
          order: (Number(max) + 1).toString(),
          saveName: saveName,
          layersInfo: pattenLayer,
        });

        this.layerSelectItem.push({
          text: this.simInfoData.savePeten.find(val => val.order == (Number(max) + 1).toString())
            .saveName,
          value: this.simInfoData.savePeten.find(val => val.order == (Number(max) + 1).toString())
            .order,
        });

        this.activeSelectPetten = (Number(max) + 1).toString();

        this.upLoading = false;
        this.$refs.fadeLayer.style.visibility = 'hidden';
        await this.saveLayers(true);

        this.layers = [];
        this.componentLoadFlag = {
          layer: 0,
          simLayer: 0,
          material: true,
          flag: false,
        };

        this.idCounter = 1;
        await this.layerLoad(this.activeSelectPetten);

        this.reloadFuncFlag = true;

        this.zoomElement(
          this.$refs.editAreaInner,
          this.editProperty.movePosition.x,
          this.editProperty.movePosition.y,
          this.editProperty.zoom
        );
      },
      patternModal: function() {
        this.$bvModal.show('pattern');
      },
      addPatternModal: function() {
        //パターンが一定以上の場合、追加で作成を防止
        if (this.simInfoData.savePeten.length >= 5) {
          this.$bvModal.show('pattenMax');
          return;
        }
        this.$bvModal.show('addPattern');
      },
      cancelModal: function() {
        this.activeSelectPetten = this.oldChangeSelect;
      },
      namechangeModal: function(num) {
        this.selectNameChange = this.layerSelectItem.find(val => val.value == num).text;
        this.$bvModal.show('namechange');
      },
      deletePatten() {
        if (this.simInfoData.savePeten.length <= 1) {
          return;
        }
        this.$bvModal.show('deletePatten');
      },
      deletePattenFunc: async function() {
        this.layerSelectItem = this.layerSelectItem.filter(
          item => (item.value == this.activeSelectPetten) == false
        );
        this.simInfoData.savePeten = this.simInfoData.savePeten.filter(
          item => (item.order == this.activeSelectPetten) == false
        );
        this.activeSelectPetten = this.layerSelectItem[0].value;
        this.oldChangeSelect = this.activeSelectPetten;
        this.layers = [];
        this.idCounter = 1;
        await this.selectPetten();
      },
      nameChangeFix: async function() {
        this.layerSelectItem.find(
          val => val.value == this.activeSelectPetten
        ).text = this.selectNameChange;
      },
      mobileZoomFunction: async function(e) {
        e.preventDefault();
        var touches = e.changedTouches;

        if (touches.length > 1) {
          var x1 = touches[0].pageX;
          var y1 = touches[0].pageY;

          var x2 = touches[1].pageX;
          var y2 = touches[1].pageY;

          var distance = await Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
          this.zoomArea({
            clientX: Math.floor(x1 + (x2 - x1) / 2),
            clientY: Math.floor(y1 + (y2 - y1) / 2),
            deltaY: Math.floor(this.mobileDistance - distance),
            targetTouches: e.targetTouches,
          });
          this.mobileDistance = distance;
        }
      },
      /*
       * レイヤータブのウィンドウsizeを設定
       */
      setLayerTabSize() {
        if (this.$refs.layerTabsList != null) {
          this.$refs.layerTabsList.style.height = this.windowIconSize() + 'px';
        }
      },
      windowIconSize() {
        let wind = window.innerHeight;
        let iconber = this.$refs.decoSim.clientHeight;
        let funcButton = this.$refs.funcButton.clientHeight;
        return wind - iconber - funcButton - 25;
      },
      simUndo() {
        //layerTabの操作で
        let layer = this.layers.find(val => val.isActive == true);
        this.undoMaterialLog.push({
          mode: 'graph',
          transparent: layer.transparent,
          brightness: layer.brightness,
          saturation: layer.saturation,
        });
      },
      /**
       * 全てのレイヤーを表示非表示で切り替える
       */
      allLayerEnabledChange() {
        if (this.allEyesFlag == false) {
          this.allEyesFlag = true;
          for (let layer of this.layers) {
            layer.enabled = true;
          }
        } else if (this.allEyesFlag == true) {
          this.allEyesFlag = false;
          for (let layer of this.layers) {
            layer.enabled = false;
          }
        }
      },
      /**
       * 全てのレイヤーの表示非表示に合わせて全体の表示非表示設定を切り替える
       */
      clickEnabledEvent() {
        let changeFlag = true;
        for (let layer of this.layers) {
          if (layer.enabled == this.allEyesFlag) {
            changeFlag = false;
            return;
          }
        }
        if (changeFlag == true) {
          this.allEyesFlag = !this.allEyesFlag;
        }
      },
      /*
       * clickを検知、その後、simulateにデータを送信する。
       */
      baseImageClickEvent: async function(e, dropper) {
        if (this.ctrlCkick == false) {
          let sim = this.$refs.layersSim.find(val => val.layer.isActive == true);

          //適応するレイヤーのみ取得
          let enableLayers = this.layers.filter(layer => layer.enabled);
          if (enableLayers < 1) return;

          //統合したcontextを取得
          let canvas = await this.fusionCanvas(this.baseImageInfo, enableLayers);
          sim.clickCheck(e, dropper, canvas);
        }
      },
      simChange(str) {
        if (str == 'simEditMode') this.dropper = false;
        if (str == 'dropper') this.simEditMode = false;
      },
      /**
       * materialNameから、material情報を取得する
       * @param {String} materialName
       */
      setChangeMaterial(materialName) {
        return this.$refs.material.getMaterial(materialName);
      },
      confirmSave(event) {
        event.returnValue = '編集中のものは保存されませんが、よろしいですか？';
        return event.returnValue;
      },
      /**
       * 右クリックし、選択した後の処理
       *
       */
      layerMenus(select = null) {
        let contextmenu = document.getElementById('contextmenu');
        //レイヤーを上下に移動する。
        if (select == 'up' || select == 'down' || select == 'mostUp' || select == 'mostDown') {
          this.layerSwapEvent(select, contextmenu.dataset.layerId);
        }

        //選択したレイヤーを削除する
        else if (select == 'delete') {
          this.$refs.layerTabs
            .find(val => val.layer.id == contextmenu.dataset.layerId)
            .deleteCheck();
        }
      },
      /**
       * レイヤーの入れ替え処理を行う
       *
       */
      async layerSwapEvent(e, id) {
        //入れ替えを行う
        let index = 0;
        for (let layer of this.layers) {
          if (layer.id == id) break;
          index++;
        }
        if (e == 'up' && index <= 0) return;
        if (e == 'down' && index >= this.layers.length - 1) return;
        if (e == 'mostUp' && index <= 0) return;
        if (e == 'mostDown' && index >= this.layers.length - 1) return;

        //レイヤーのマウント処理完了後切り替えを行う
        this.reloadFuncFlip = this.flipFlag;
        this.flipFlag = false;

        for (let layer of this.layers) {
          layer.fileUrl = this.$refs.layers
            .find(val => val.layer.id == layer.id)
            .$refs.main.toDataURL('image/png');
        }

        this.upLoading = true;
        this.$refs.fadeLayer.style.visibility = 'visible';
        let layers = await this.layers;
        this.layers = await [];

        //レイヤーのマウント完了状態をゼロに戻す。
        this.componentLoadFlag = {
          layer: 0,
          simLayer: 0,
          material: true,
          flag: true,
        };
        this.reloadFuncFlag = true;

        //レイヤー背面に移動
        if (e == 'up' && index > 0) {
          let temp = layers[index];
          layers[index] = layers[index - 1];
          layers[index - 1] = temp;
          this.swapFuncFlag = temp.id;
        }
        //レイヤー前面に移動
        if (e == 'down' && index < layers.length - 1) {
          let temp = layers[index];
          layers[index] = layers[index + 1];
          layers[index + 1] = temp;
          this.swapFuncFlag = temp.id;
        }
        //レイヤー最背面に移動
        if (e == 'mostUp' && index > 0) {
          //レイヤーの入れ替え
          let temp = layers.splice(index, 1);
          layers.unshift(temp[0]);
          this.swapFuncFlag = temp[0].id;
        }
        //レイヤー最前面に移動
        if (e == 'mostDown' && index < layers.length - 1) {
          let temp = layers.splice(index, 1);
          layers.push(temp[0]);
          this.swapFuncFlag = temp[0].id;
        }
        this.layers = await layers;
      },
      /**
       * 他の箇所をクリックしたらレイヤーメニューを非表示にする。
       */
      clauseRightMenu() {
        let contextmenu = document.getElementById('contextmenu');
        contextmenu.style.display = 'none';
      },
      /**
       * レイヤーメニューの表示状態を切り替える。
       */
      layersMenuChange(num) {
        this.layersMenu = {
          up: true,
          down: true,
        };
        if (!this.layerTabSort) {
          if (num == 0) this.layersMenu.down = false;
          if (num == this.layers.length - 1) this.layersMenu.up = false;
        } else {
          if (num == 0) this.layersMenu.up = false;
          if (num == this.layers.length - 1) this.layersMenu.down = false;
        }
      },
      /**
       * ログの履歴を設定する
       */
      setSimulationLog: async function(body, status) {
        let updloadData = {
          company: body.company,
          id: body.propertyId,
        };

        //データを削除する（更新処理）
        let getResult = await API.graphql({
          query: getProperty,
          variables: { id: body.propertyId },
        });

        let name =
          this.$store.state.user.attributes.family_name +
          ' ' +
          this.$store.state.user.attributes.given_name;
        if (this.$route.query.room == null) {
          updloadData.simulationLog = getResult.data.getProperty.simulationLog;

          //削除ログを設定する
          if (updloadData.simulationLog == null) updloadData.simulationLog = [];
          updloadData.simulationLog.push({
            updateDate: new Date(),
            simId: body.id,
            fileUrlName: body.simInfo.baseImageFileName,
            fileName: body.simInfo.baseImageDisplayName,
            action: status,
            userName: name,
            userMail: this.$store.state.user.attributes.email,
          });
          if (updloadData.simulationLog.length > 100) {
            updloadData.simulationLog.shift();
          }
        } else {
          //RoomIDがあるときの処理
          updloadData.roomInfo = getResult.data.getProperty.roomInfo;
          let roomInfo = updloadData.roomInfo.find(val => val.id == this.$route.query.room);
          if (roomInfo.simulationLog == null) roomInfo.simulationLog = [];
          roomInfo.simulationLog.push({
            updateDate: new Date(),
            simId: body.id,
            fileUrlName: body.simInfo.baseImageFileName,
            fileName: body.simInfo.baseImageDisplayName,
            action: status,
            userName: name,
            userMail: this.$store.state.user.attributes.email,
          });
          if (roomInfo.simulationLog.length > 100) {
            roomInfo.simulationLog.shift();
          }
        }

        //データを削除する（更新処理）
        await API.graphql({
          query: updateProperty,
          variables: { input: updloadData },
        });
      },
      /**
       * レイヤーの並び順を切り替える
       */
      reverseLayers() {
        if (this.layerTabSort) {
          return this.layers.slice();
        } else if (!this.layerTabSort) {
          return this.layers.slice().reverse();
        }
      },
      /**
       * アクティブ状態にするレイヤーメニューを設定する
       */
      getMenuActive(status) {
        return this.layersMenu[status];
      },
      /**
       * レイヤーメニューの順番を入れ替える
       */
      setLayerMenu() {
        if (!this.layerTabSort) {
          return this.layerMenuData;
        } else if (this.layerTabSort) {
          return this.layerMenuData.slice().reverse();
        }
      },
      /**
       * 素材編集の変更ログを確保する
       */
      setMaterialEditLog(perspectiveData, initFlag = false) {
        let layer = this.layers.find(val => val.isActive == true);
        if(initFlag) {
          this.undoMaterialLog.push({
          mode: 'initMaterialRange',
          perspectiveData: perspectiveData,
          transparent: layer.transparent,
          brightness: layer.brightness,
          saturation: layer.saturation,
        });
        } else {
        this.undoMaterialLog.push({
          mode: 'materialRange',
          perspectiveData: perspectiveData,
          transparent: layer.transparent,
          brightness: layer.brightness,
          saturation: layer.saturation,
        });
        }
      },
    },
    components: {
      Layer,
      LayerTab,
      Load,
      LayerSim,
      Material,
    },
    //ブラウザ更新・閉じるが押されたときの処理
    created() {
      this.simInfoData = this.photoData.simInfo;
      window.addEventListener('beforeunload', this.confirmSave);
      this.propertyList = this.$route.query.propertyList;
    },
    destroyed() {
      window.removeEventListener('beforeunload', this.confirmSave);
    },
  };
</script>

<style scoped>
  .back {
    position: fixed;
    left: 0;
    top: 0;
    width: 99vw;
    height: 100vh;
  }

  button#undo {
    padding: 6px;
    font-size: 0.8em;
    color: #fff;
  }

  button#undo i {
    font-size: 0.5em;
  }

  button#add {
    font-size: 0.8em;
    color: #000;
    background: white;
    border: 0.5px solid rgba(0, 0, 0, 0.6);
  }
  button#add:hover {
    background: khaki;
  }

  button#output {
    font-size: 0.8em;
    color: #000;
    background: white;
    height: 52px;
    border: 0.5px solid rgba(0, 0, 0, 0.6);
  }

  button#save {
    color: #fff;
    height: 50px;
    margin-bottom: 5px;
  }

  .decoSim {
    background: rgb(0, 0, 0);
  }

  .decoSim ul {
    list-style: none;
  }

  .decoSim li {
    border: 0.5px solid #ddd;
  }

  .decoSim .console .view {
    width: 100%;
    height: 500px;
    overflow: scroll;
  }
  .decoSim .edit-mode-area {
    height: 60px;
  }
  .decoSim .edit-mode-change {
    width: 100px;
    height: 60px;
    font-size: 0.8em;
    border: 2px solid rgba(0, 0, 0, 0.6);
    text-align: center;
    border-radius: 5px 5px 5px 5px;
    transition: 0.2s;
    color: black;
    background-color: white;
  }

  .decoSim .edit-mode-change:active {
    color: black;
    background-color: white;
  }

  .decoSim .edit-mode-change i {
    font-size: 4.2em;
    transition: 0.2s;
  }
  .decoSim .edit-mode-change:hover {
    cursor: pointer;
    color: #000;
    transition: 0.2s;
    background: khaki;
  }
  .decoSim .edit-mode {
    width: 52px;
    height: 60px;
    margin-bottom: 0;
    font-size: 0.5em;
    border: 0.5px solid rgba(0, 0, 0, 0.6);
    transition: 0.2s;
    color: black;
    background: white;
  }

  .decoSim .eraser-mode {
    background: #ccffff;
  }

  .decoSim .move-mode {
    background: #ccffcc;
  }

  .decoSim .edit-mode i {
    font-size: 2.2em;
  }

  .decoSim .edit-mode:hover {
    cursor: pointer;
    background-color: khaki;
  }
  .decoSim .edit-mode.active {
    background-color: gold;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.4));
  }

  .decoSim .edit-mode input[type='radio'] {
    display: none;
  }

  .decoSim button {
    background-color: black;
    border: 0.5px solid rgba(255, 255, 255, 0.6);
  }
  .decoSim button:hover {
    background-color: khaki;
  }

  .edit-mode-change.active {
    background-color: gold;
  }

  .decoSim input[type='number'] {
    width: 65%;
    padding: 0%;
    height: 1.5rem;
    font-size: 1.1rem;
    margin-left: auto;
    margin-right: auto;
  }

  .decoSim #edit-area {
    position: relative;
    width: 90%;
    min-width: 370px;
    background: #333;
  }
  .decoSim .mouse-cursor {
    cursor: grab;
  }
  .decoSim .mouse-cursor.moving {
    cursor: grabbing;
  }
  .decoSim #edit-area .wrapper {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow: hidden;
  }

  .decoSim #edit-area .inner {
    display: block;
    position: relative;
    transform-origin: top left;
    width: 60%;
    height: 100%;
  }
  .decoSim .layer-tab-list {
    position: relative;
    max-width: 210px;
    background: #aaa;
    /* max-height: 800px; */
    /*overflow-y: scroll; */
  }
  .decoSim .layer-tab-area {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 90%;
  }

  .decoSim #edit-area canvas.layer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .decoSim #edit-area canvas.setting {
    display: none;
  }

  .decoSim #edit-area canvas.layer.active {
    display: block;
  }

  .decoSim .material:hover {
    cursor: pointer;
  }
  .decoSim .deco-space {
    margin-right: 20px;
  }

  .decoSim .material-url {
    height: 50px;
    object-fit: cover;
  }

  .decoSim .material-name {
    display: inline-block;
  }

  .text-box {
    /*
    background-color: var(--colorTheme);
    color: #fff;
    */
    border: 1px solid black;
    width: 150px;
    height: 38px;
    font-size: 1.2em;
  }
  .decoSim .threshold,
  .decoSim .lineWidth {
    width: 8%;
  }

  #fadeLayer {
    position: absolute;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;

    background-color: #000000;
    opacity: 0.5;
    visibility: hidden;
    z-index: 1;
  }

  .hidden {
    display: none;
  }

  .sort-buttn {
    width: 100px;
    height: 30px;
    font-size: 15px;
    border: thick double #32a1ce;
    text-align: center;

    transition: 0.2s;
    color: white;
  }

  .sort-buttn.active {
    background: var(--colorTheme);
  }

  .sort-buttn:hover {
    background: var(--colorTheme);
    transition: 0.2s;
    cursor: pointer;
  }

  .layer-button {
    padding: 5px 10px;
    border-bottom: 1px solid rgb(255, 255, 255);
  }

  .color-bucket {
    width: 60px;
    height: 20px;
    border-radius: 5px;
    margin-top: 4px;
    margin-left: 5px;
  }

  .eraserImages {
    display: block;
    margin-left: auto;
    margin-right: auto;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  #contextmenu {
    display: none;
    position: fixed;
    left: 0px;
    top: 0px;
    width: auto;
    height: auto;
    border: 1px solid #000;
    background-color: #fff;
  }
  #contextmenu li {
    cursor: pointer;
  }
  .right-menu:hover {
    background-color: rgb(197, 197, 197);
  }

  .right-menu {
    font-size: 20px;
    padding: 2px 10px;
    color: rgb(185, 185, 185);
  }
  .right-menu img {
    filter: opacity(50%);
  }
  .right-menu.active {
    color: #000;
  }
  .right-menu.active img {
    filter: opacity(100%);
  }
  .layer-sort {
    padding-top: 15px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
</style>
