export default class RoaInput {
  constructor(data) {
    if (data == null) {
      data = {};
    }

    this.centerValue = data.centerValue;
    this.inheritanceTaxBase = data.inheritanceTaxBase || {
      assetsRealEstate: {
        salseObjectPoint: {
          address: '',
          lotNum: '',
          name: '',
          usageSituation: '',
          landSize: null,
          buildingSize: null,
          acquisitionYear: '',
        },
        bs: {
          inheritanceBasePrice: {
            land: null,
            building: null,
          },
          ourstandingDebt: null,
        },
        pl: {
          income: null,
          spending: {
            landPropertyTax: null,
            landCityPlanningTax: null,
            buildingPropertyTax: null,
            buildingCityPlanningTax: null,
            maintenanceFee: null,
          },
        },
        cashflowCalc: {
          outstandingDebtTotal: null,
          repaymentType: null,
          repaymentPeriod: null,
          borrowRate: null,
        },
      },
      assetsNonRealEstate: {
        ourstandingDebt: null,
        income: null,
        outstandingDebtTotal: null,
      },
    };

    this.prevailingPriceBase = data.prevailingPriceBase || {
      prevailingPrice: {
        land: null,
        building: null,
      },
    };

    this.comment = data.comment;

    this.value = data.value;
  }
}
