<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：初期投資/付帯費用/各種負担金・預託金
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="Contributions">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button id="BtnContributions" variant="link" v-b-toggle.tabContributions>
          各種負担金・預託金
        </b-button>
      </b-card-header>
      <b-collapse id="tabContributions" role="tabpanel">
        <b-card-body>
          <b-tabs
            fill
            pills
            v-model="investmentAnalytics.initialInvestment.isDetailContributionsAndDeposits"
          >
            <b-tab title="概算入力">
              <b-container>
                <b-row>
                  <b-col class="title bold">負担金総額</b-col>
                  <b-col>
                    <b-row>
                      <b-col class="component">
                        <allyText
                          name="ContributionsTotal"
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="investmentAnalytics.initialInvestment.estimatedContributions"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
                <b-row>
                  <b-col class="title bold">
                    預託金総額
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="component">
                        <allyText
                          name="estimatedDeposits"
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="investmentAnalytics.initialInvestment.estimatedDeposits"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="詳細入力">
              <b-container class="detail">
                <b-row>
                  <b-col cols="2" class="title bold">項目</b-col>
                  <b-col class="title bold">細目</b-col>
                  <b-col>
                    <b-row>
                      <b-col class="title-center bold">負担金・預託金</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="2" class="title-center pr-1">詳細</b-col>
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold">負担金</b-col>
                  <b-col class="title">上水道加入金</b-col>
                  <b-col class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(
                              investmentAnalytics.initialInvestment.contributionsAndDeposits[0]
                                .amount
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="2" class="title">
                    <b-button variant="ally" v-b-toggle.tabContributions1>
                      <i class="when-open ti-angle-double-up" />
                      <i class="when-closed ti-angle-double-down" />
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabContributions1" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">地域/口径</b-col>
                            <b-col class="ally-select">
                              <allySelect
                                name="sewerAccessionPayment"
                                :items="sewerAccessionPaymentItems"
                                v-model="
                                  investmentAnalytics.initialInvestment.contributionsAndDeposits[0]
                                    .content
                                "
                              />
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">メーター個数</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment
                                        .contributionsAndDeposits[0].quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">個</b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="unitPrice(0) | currency"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円/個</b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline" style="padding-top:0;">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        0,
                                        Math.floor(
                                          investmentAnalytics.initialInvestment
                                            .contributionsAndDeposits[0].quantity
                                        ) * unitPrice(0)
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">金額</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment
                                        .contributionsAndDeposits[0].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>

                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      Math.floor(
                                        investmentAnalytics.initialInvestment
                                          .contributionsAndDeposits[0].quantity * unitPrice(0)
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold" />
                  <b-col class="title">下水道受益者負担金</b-col>
                  <b-col class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(
                              investmentAnalytics.initialInvestment.contributionsAndDeposits[1]
                                .amount
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="2" class="title">
                    <b-button variant="ally" v-b-toggle.tabContributions2>
                      <i class="when-open ti-angle-double-up" />
                      <i class="when-closed ti-angle-double-down" />
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabContributions2" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">地域</b-col>
                            <b-col class="ally-select">
                              <allySelect
                                name="sewerBeneficiaryContribution"
                                :items="sewerBeneficiaryContributionItems"
                                v-model="
                                  investmentAnalytics.initialInvestment.contributionsAndDeposits[1]
                                    .content
                                "
                              />
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">※地積</b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">地積</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment
                                        .contributionsAndDeposits[1].quantity
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    mode="View"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.propertyDescription[0]
                                        .area
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="unitPrice(1) | currency"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円/㎡</b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline" style="padding-top:0;">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        1,
                                        Math.floor(
                                          (Math.floor(
                                            investmentAnalytics.initialInvestment
                                              .contributionsAndDeposits[1].quantity * 100
                                          ) /
                                            100) *
                                            unitPrice(1)
                                        )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">金額</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment
                                        .contributionsAndDeposits[1].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      Math.floor(
                                        (Math.floor(
                                          investmentAnalytics.initialInvestment
                                            .contributionsAndDeposits[1].quantity * 100
                                        ) /
                                          100) *
                                          unitPrice(1)
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold" />
                  <b-col class="title">団地負担金</b-col>
                  <b-col class="component">
                    <b-row>
                      <b-col class="component">
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.contributionsAndDeposits[3].amount
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="2" class="title" />
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold" />
                  <b-col class="title">開発協力金</b-col>
                  <b-col class="component">
                    <b-row>
                      <b-col class="component">
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.contributionsAndDeposits[4].amount
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="2" class="title" />
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold" />
                  <b-col class="title">改良区決済金</b-col>
                  <b-col class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(
                              investmentAnalytics.initialInvestment.contributionsAndDeposits[2]
                                .amount
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="2" class="title">
                    <b-button variant="ally" v-b-toggle.tabContributions3>
                      <i class="when-open ti-angle-double-up" />
                      <i class="when-closed ti-angle-double-down" />
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabContributions3" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline" style="padding-top:0;">※地積</b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">地積</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment
                                        .contributionsAndDeposits[2].quantity
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    mode="View"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.propertyDescription[0]
                                        .area
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment
                                        .contributionsAndDeposits[2].unitPrice
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円/㎡</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        2,
                                        Math.floor(
                                          (Math.floor(
                                            investmentAnalytics.initialInvestment
                                              .contributionsAndDeposits[2].quantity * 100
                                          ) /
                                            100) *
                                            Math.floor(
                                              investmentAnalytics.initialInvestment
                                                .contributionsAndDeposits[2].unitPrice
                                            )
                                        )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">金額</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment
                                        .contributionsAndDeposits[2].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      Math.floor(
                                        (Math.floor(
                                          investmentAnalytics.initialInvestment
                                            .contributionsAndDeposits[2].quantity * 100
                                        ) /
                                          100) *
                                          Math.floor(
                                            investmentAnalytics.initialInvestment
                                              .contributionsAndDeposits[2].unitPrice
                                          )
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold">預託金</b-col>
                  <b-col class="title">敷金</b-col>
                  <b-col class="component">
                    <b-row>
                      <b-col class="component">
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.contributionsAndDeposits[5].amount
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="2" class="title" />
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold" />
                  <b-col class="title">建設協力金</b-col>
                  <b-col class="component">
                    <b-row>
                      <b-col class="component">
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.contributionsAndDeposits[6].amount
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="2" class="title" />
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold" />
                  <b-col class="title">保証金</b-col>
                  <b-col class="component">
                    <b-row>
                      <b-col class="component">
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.contributionsAndDeposits[7].amount
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="2" class="title" />
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Calculation from './../Calculation';
  import Vue from 'vue';
  export default {
    mixins: [Calculation],
    data: function() {
      return {
        // 上水道加入金
        sewerAccessionPaymentItems: this.getUnitPricesItems('上水道メーター加入金'),
        // 下水受益者負担金
        sewerBeneficiaryContributionItems: this.getUnitPricesItems('上下水道受益者負担金'),
        // 単価情報
        unitPrices: [
          { name: '上水道メーター加入金', amount: null, isChanged: 0 },
          { name: '上下水道受益者負担金', amount: null, isChanged: 0 },
        ],
      };
    },
    watch: {
      // マスタ更新時
      '$store.state.master.unitPrice': {
        handler: function() {
          // 選択項目更新
          this.sewerAccessionPaymentItems = this.getUnitPricesItems('上水道メーター加入金');
          this.sewerBeneficiaryContributionItems = this.getUnitPricesItems('上下水道受益者負担金');
        },
        deep: false,
      },
    },
    methods: {
      // チェックボックス変更時
      changeCheckBox: function(id) {
        // 初期値設定
        if (!this.unitPrices[id].isChecked) {
          this.unitPrices[id].amount = this.getUnitPrice(
            this.unitPrices[id].name,
            this.investmentAnalytics.initialInvestment.contributionsAndDeposits[id].content
          );
        }
      },
      changeGuideline: function(id, sum) {
        Vue.set(
          this.investmentAnalytics.initialInvestment.contributionsAndDeposits[id],
          'amount',
          sum
        );
      },
    },
    computed: {
      // 単価
      unitPrice: function() {
        return function(id) {
          let amount;
          // 未変更時
          if (!this.unitPrices[id].isChecked) {
            // マスタデータ
            amount = this.getUnitPrice(
              this.unitPrices[id].name,
              this.investmentAnalytics.initialInvestment.contributionsAndDeposits[id].content
            );
          }
          // 変更時
          else {
            // 入力値
            amount = this.unitPrices[id].amount;
          }
          return amount;
        };
      },
    },
  };
</script>
<style scoped>
  #Contributions .detail .card-body {
    padding-top: 0;
    padding-bottom: 8px;
  }
  #Contributions .guideline {
    padding-top: 22px;
    font-size: medium;
    text-align: center;
  }
  #Contributions .checkbox {
    display: grid;
    text-align: left;
    align-items: center;
  }
</style>
