<!--
作成者：Lee HyeonSeok
作成日：2020.6.3
画面名：住所フォーム
機能：住所を入力する
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div v-if="result">
    <div class="row">
      <validation-provider
        name="郵便番号"
        :rules="rules ? rules + '|length:7' : 'length:7'"
        v-slot="{ valid, errors }"
        ref="validationObserver"
        tag="div"
        class="col-8"
      >
        <b-form-group inline>
          <label label-for="result.postalCode" for="inline-form-input-name" class="d-flex">
            郵便番号（ハイフン不要）
            <allyBadge v-if="hideBadge" :mode="hideBadge ? 'View' : mode" :rules="rules" />
            <allyBadge v-if="optionalOutput" :mode="mode" :rules="rules" />
            <span class="font-weight-normal text-right ml-auto" style="font-size:x-small">
              検索は
              <a href="https://www.post.japanpost.jp/zipcode/index.html" target="_blank">こちら</a>
            </span>
          </label>
          <b-input-group style="background-color: transparent">
            <b-form-input
              id="result.postalCode"
              :readonly="mode == 'View'"
              v-model="result.postalCode"
              :state="state(valid, errors)"
              :placeholder="'0200000'"
              @change="converter()"
            />
            <b-input-group-append>
              <b-button variant="ally" @click="searchPostalCode" :disabled="mode == 'View'">
                <i class="ti-search"></i>
              </b-button>
            </b-input-group-append>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </validation-provider>
      <allyText
        class="col"
        title="都道府県"
        name="result.address1"
        v-model="result.address1"
        :mode="mode"
        :rules="rules ? rules + '|prefecture' : 'prefecture'"
        :loading="isLoading"
        :placeholder="'〇〇県'"
        :hideBadge="hideBadge"
        :optionalOutput="optionalOutput"
      />
    </div>
    <div class="row">
      <allyText
        class="col-12"
        title="市区町村"
        name="result.address2"
        v-model="result.address2"
        :mode="mode"
        :rules="rules"
        :loading="isLoading"
        :placeholder="'〇〇市'"
        :hideBadge="hideBadge"
        :optionalOutput="optionalOutput"
      />
    </div>
    <div class="row">
      <allyText
        class="col-12"
        title="それ以降の住所"
        name="result.address3"
        v-model="result.address3"
        :mode="mode"
        :rules="rules"
        :loading="isLoading"
        :placeholder="'〇〇〇丁目〇ー〇 '"
        :hideBadge="hideBadge"
        :optionalOutput="optionalOutput"
      />
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import moji from 'moji';

  export default {
    // mode: View/Edit/Add
    // value: 値
    // loading: ロード中確認
    // hideBadge: バッジ非表示
    // optionalOutput: 任意で「必須」文字を出力する
    props: ['mode', 'value', 'loading', 'rules', 'hideBadge', 'optionalOutput'],
    data: function() {
      //初期化
      return { result: null, addressLoading: false };
    },
    mounted() {
      this.result = this.value;
      if(this.result == null){
        this.result = {
          address1: null,
          address2: null,
          address3: null,
        }
      }

    },
    methods: {
      searchPostalCode: function() {
        let zipCode = this.converter(this.result.postalCode);
        this.addressLoading = true;

        //api.zipaddress.netから住所情報をもらう
        axios
          .get(`https://apis.postcode-jp.com/api/v4/postcodes/${zipCode}`)
          .then(response => {
            //成功したらaddress1, address2にy値を
            this.result.address1 = response.data[0].pref;
            this.result.address2 = response.data[0].city + response.data[0].town;
            this.addressLoading = false;
          })
          .catch(() => {
            //失敗したらaddress1, address2初期化
            this.result.address1 = '';
            this.result.address2 = ''; /*  */
            this.addressLoading = false;
          });
        this.result.postalCode = zipCode;
      },
      state: function(valid, errors) {
        if (
          (errors[0] ||
            (this.rules && this.result) ||
            (this.rules && this.rules.includes('required') && this.result)) &&
          this.mode != 'View'
        ) {
          return errors[0] ? false : valid ? true : null;
        }
        return null;
        // valid status
      },
      converter: function(zipCode) {
        if (zipCode) {
          var convertedZipcode = moji(zipCode)
            .convert('ZS', 'HS')
            .convert('ZE', 'HE')
            .convert('HG', 'KK')
            .convert('ZK', 'HK')
            .toString();

          return convertedZipcode;
        }
      },
    },
    computed: {
      isLoading: function() {
        return this.addressLoading || this.loading ? true : false;
      },
    },
    watch: {
      value: function() {
        this.result = this.value;
      },
      result: function() {
        this.converter();
        // 値を変更すると値を返上
        this.$emit('input', this.result);
      },
      loading: function() {
        // ロードが終わったら値を初期化
        if (this.loading === false) this.result = this.value;
      },
    },
  };
</script>
