<template>
  <div id="LicenseMain">
    <b-overlay :show="loading" opacity="0.25">
      ライセンス管理
      <b-container class="col-12 m-0 p-0">
        <!-- 上部ボタン -->
        <b-row>
          <b-col>
            <b-navbar>
              <b-navbar-nav class="ml-auto">
                <router-link
                  to="/License/Purchase"
                  tag="button"
                  class="btn btn-ally"
                  v-if="paymentCheckFlag"
                >
                  ライセンス購入
                </router-link>
                <router-link to="/License/Cancel" tag="button" class="btn btn-ally">
                  ライセンス解約
                </router-link>
                <router-link
                  to="/License/Assign"
                  tag="button"
                  class="btn btn-ally"
                  v-if="dispLicenseItems.length > 0"
                >
                  ライセンス割当
                </router-link>
                <router-link to="/PaymentMethod/View" tag="button" class="btn btn-ally">
                  支払方法
                </router-link>
                <router-link to="/DashBoard" tag="button" class="btn btn-ally">
                  メインページへもどる
                </router-link>
              </b-navbar-nav>
            </b-navbar>
          </b-col>
        </b-row>
        <!-- ライセンス一覧 -->
        <b-row>
          <b-col>
            <b-table
              thead-tr-class="serch-data-table-header"
              tbody-tr-class="serch-data-table-body"
              striped
              bordered
              :items="dispLicenseItems"
              :fields="fields"
              show-empty
            >
              <!-- 空の場合 -->
              <template v-slot:empty>
                <b-container v-if="!loading">
                  <b-row class="text-center">
                    <b-col>
                      <svg
                        class="bi bi-exclamation-diamond-fill"
                        width="8em"
                        height="8em"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                        />
                      </svg>
                      <b-alert id="alert" show>
                        ライセンスがありません
                      </b-alert>
                    </b-col>
                  </b-row>
                </b-container>
                <b-container v-else />
              </template>
              <!-- 数量表示 -->
              <template v-slot:cell(quantity)="row">
                {{ row.value | currency }}
              </template>
            </b-table>
          </b-col>
        </b-row>
        <!-- 請求内容一覧 -->
        <b-row class="col-12 pt-4 info-title">
          請求内容一覧
        </b-row>
        <b-row class="pt-1">
          <b-col>
            <b-table
              thead-tr-class="serch-data-table-header"
              tbody-tr-class="serch-data-table-body"
              striped
              bordered
              :items="billingItems"
              :fields="billingHeaders"
              show-empty
            >
              <!-- 空の場合 -->
              <template v-slot:empty>
                <b-container>
                  <b-row class="text-center">
                    <b-col>
                      請求はありません
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <!-- 空の場合 -->
              <template #cell(licenses)="row">
                <b-container>
                  <b-row class="text-center">
                    <b-col cols="12" class="p-1" v-for="item in row.item.licenses" :key="item.id">
                      {{ item }}
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <!-- 購入金額合計 -->
              <template #cell(amount)="row">
                {{ Number(row.item.amount).toLocaleString('ja-JP', { style: 'decimal' }) }}円
              </template>
              <!-- 請求書 -->
              <template #cell(invoice)="row">
                <b-button
                  v-if="row.item.invoice"
                  class="download"
                  variant="ally"
                  size="sm"
                  @click="downloadInvoice(row.item)"
                >
                  ダウンロード
                </b-button>
              </template>
              <template #cell(paymentResult)="row">
                <span v-if="row.item.paymentResult == null"> </span>
                <span v-else-if="row.item.paymentResult"> 済 </span>
                <span v-else style="color: red; font-weight: bold;">
                  未納
                </span>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <!--
        <div class="d-flex py-4">
          <b-button
            v-if="unpaidItems.length > 0"
            class="ml-auto"
            variant="ally"
            @click="payUnpaiedItems"
          >
            未納分を支払う
          </b-button>
        </div>
        -->
      </b-container>
      <!-- ロード中 -->
      <template #overlay>
        <Load />
      </template>
    </b-overlay>
    <!-- メッセージ -->
    <b-modal
      id="showPaymentPage"
      title="確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      centered
      ok-only
      @ok="reloadCompany"
    >
      お支払い画面を表示しました。
    </b-modal>
    <allyAlert code="E0001" />
    <allyAlert title="自動更新を停止しました。" code="S100" />
  </div>
</template>

<!-- スクリプト -->
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { enterPayment } from '../../graphql/queries';
  import { updateCompany } from '../../graphql/mutations';
  import { getS3Url } from '@/graphql/queries';
  import moment from 'moment';
  import Load from '../../components/Common/Load';
  import Common from './js/Common';
  import axios from 'axios';

  API.configure(awsconfig);

  // ライセンス一覧のヘッダ
  const headers = [
    {
      key: 'name',
      label: 'ライセンス名称',
    },
    {
      key: 'quantity',
      label: '数量',
      class: 'text-center',
    },
    {
      key: 'startDate',
      label: '利用開始日',
      formatter: v => {
        return v.replaceAll('-', '/');
      },
    },
  ];

  export default {
    components: {
      Load,
    },
    mixins: [Common],

    data() {
      return {
        fields: headers, // ライセンス一覧のヘッダ
        master: null, // マスタ
        company: null, // 会社情報
        loading: true, // ロード中
        paymentCheckFlag: false, //未納状態Check
      };
    },
    async created() {
      this.loading = true;

      // ログインユーザーの会社IDを取得
      const companyId = this.getCompanyId();

      // マスタ,会社情報を取得
      this.loadMaster(companyId).then(result => {
        this.master = result;
      });

      this.company = await this.getCompany(this.getCompanyId());
      await this.paymentCheck();
      this.loading = false;
    },
    methods: {
      async reloadCompany() {
        this.loading = true;
        try {
          this.company = await this.getCompany(this.getCompanyId());
          this.paymentCheck();
        } catch (error) {
          console.error(error);
          this.showError('E0001');
        } finally {
          this.loading = false;
        }
      },
      // 請求書ダウンロード
      async downloadInvoice(payment) {
        //請求書データ
        const billingPayment = this.targetPayments.find(p => p.id === payment.id);
        const paymentYear = parseInt(billingPayment.id.toString().slice(0, 2)) + 2000;
        const paymentMonth = parseInt(billingPayment.id.toString().slice(2, 4));
        const fileName =
          'invoices/' + this.company.customerNumber + '-' + billingPayment.id + '.pdf';
        // S3からURLを取得
        const result = await API.graphql(
          graphqlOperation(getS3Url, {
            level: 'public',
            group: 'Company-' + this.company.customerNumber,
            file: {
              fileName: fileName,
              fileAction: 'View',
            },
          })
        );
        const invoiceFileUrl = result.data.getS3Url[0].fileUrl;
        await axios
          .get(invoiceFileUrl, { responseType: 'blob' })
          .then(response => {
            const blob = new Blob([response.data], {
              type: 'application/pdf',
            });

            //ダウンロードリンク作成
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.crossOrigin = 'anonymous';
            link.download = '請求書(' + paymentYear + '年' + paymentMonth + '月ご利用分).pdf';
            link.target = '_blank';
            link.click();
          })
          .catch(error => {
            console.error(error);
          });
      },
      async payUnpaiedItems() {
        // 決済URL取得
        this.loading = true;
        try {
          const unpaid = this.unpaidItems ?? [];
          const unpaidSet = new Set(unpaid.map(u => u.id));
          const payment = this.company?.payment ?? [];

          let amount = 0;
          let tax = 0;
          unpaid.forEach(u => {
            amount += u.amount;
            tax += u.tax;
          });

          const resp = await API.graphql(
            graphqlOperation(enterPayment, {
              companyId: this.company.id,
              amount,
              tax,
              overview: 'ALLY-PRO® ライセンス',
            })
          );

          const statusCode = resp.data.enterPayment.statusCode;
          const { orderId, url } = JSON.parse(resp.data.enterPayment.body);
          if (statusCode != '200') {
            throw new Error(statusCode);
          }

          payment.forEach(pay => {
            if (unpaidSet.has(pay.id)) {
              pay.orderId = orderId;
            }
          });

          // 会社情報更新
          await API.graphql(
            graphqlOperation(updateCompany, {
              input: {
                id: this.company.id,
                payment,
              },
            })
          );

          window.open(url, 'enterPayment');

          await this.$bvModal.show('showPaymentPage');
        } catch (error) {
          console.error(error);
          await this.showError('E0001');
        } finally {
          this.loading = false;
        }
      },
      paymentIdToPurchaseDate(paymentId) {
        if (paymentId == null) {
          return '';
        }
        const str = paymentId.toString();
        return `20${str.slice(0, 2)}年${str.slice(2, 4)}月`;
      },
      // 支払い対象のライセンスを取得
      periodLicenses(paymentId) {
        const licenses = this.company?.license ?? [];
        return licenses.filter(li => {
          return li.paymentId == paymentId;
        });
      },
      //未納状態のライセンスがあるか確認
      async paymentCheck() {
        if (this.company.payment != null) {
          if (this.company.payment.length > 0) {
            let paymentValids = this.company.payment.filter(val => {
              let timeFlag = moment(this.today).format('YYMM');
              return val.paymentResult == false && parseInt(val.id) < parseInt(timeFlag);
            });
            this.paymentCheckFlag = paymentValids.length == 0;
            return;
          }
        }
        this.paymentCheckFlag = true;
      },
    },
    computed: {
      // id毎のライセンス
      licenseMapById() {
        const licenseMap = new Map();
        for (const li of this.validLicenses) {
          if (!licenseMap.has(li.id)) {
            licenseMap.set(li.id, []);
          }

          licenseMap.get(li.id).push(li);
        }
        return licenseMap;
      },
      // id毎のライセンスマスタ
      licenseMasterMapById() {
        const map = new Map();
        for (const m of this.master ?? []) {
          map.set(m.id, m);
        }

        return map;
      },
      // 未購入
      unpaidItems() {
        const pay = this.company?.payment ?? [];
        return pay.filter(p => {
          return p.paymentResult === false;
        });
      },
      // 表示対象の請求情報
      targetPayments() {
        const payments = this.company?.payment ?? [];
        const lastMonthId = moment(this.today)
          .subtract(1, 'months')
          .format('YYMM');
        return payments.filter(pay => {
          return lastMonthId === pay.id.toString() || pay.paymentResult === false;
        });
      },
      // 請求情報
      billingItems() {
        return this.targetPayments.map(pay => {
          return {
            id: pay.id,
            useDate: this.paymentIdToPurchaseDate(pay.id), // 購入日
            licenseName: this.licenseMasterMapById.get(pay.licenseId)?.name ?? '', // ライセンス名
            licenses: this.paymentDetailsById.get(pay.id), // 購入内容
            amount: pay.amount + pay.tax, // 購入金額合計
            billingDate: pay.billingDate.replaceAll('-', '/'), // 支払日/振込期限
            invoice: pay.invoice, // 請求書URL
            paymentResult: pay.paymentResult, // 支払結果
          };
        });
      },
      // 現在有効なライセンス
      validLicenses() {
        const licenses = this.company?.license ?? [];
        const today = moment(this.today);
        return licenses.filter(li => {
          return (
            moment(li.startDate).isSameOrBefore(today) &&
            today.isSameOrBefore(li.endDate) &&
            li.isValid
          );
        });
      },
      // paymentId毎の購入ライセンスの内訳
      paymentDetailsById() {
        return this.targetPayments.reduce((detailMap, pay) => {
          const licenses = this.validLicenses.filter(li => li.paymentId === pay.id);
          if (licenses == null) {
            return '';
          }

          //請求月のライセンスを取得する
          const licenseMap = new Map();
          for (const li of this.periodLicenses(pay.id)) {
            if (!licenseMap.has(li.id)) {
              licenseMap.set(li.id, []);
            }
            licenseMap.get(li.id).push(li);
          }

          //請求月のライセンスを表示する
          const result = [];
          licenseMap.forEach((licenseList, id) => {
            const m = this.licenseMasterMapById.get(id);
            if (m == null) {
              return;
            }
            const totalQuantity = licenseList.reduce((count, cur) => count + cur.quantity, 0);
            result.push(m.name + ' × ' + totalQuantity);
          });

          detailMap.set(pay.id, result);
          return detailMap;
        }, new Map());
      },
      // 表示ライセンス情報
      dispLicenseItems() {
        const result = [];
        this.licenseMapById.forEach((licenses, id) => {
          const master = this.licenseMasterMapById.get(id);
          if (master == null) return;

          let count = 0;
          let startDate = null;
          licenses.forEach(li => {
            count += li.quantity;
            const st = moment(li.initialPurchaseDate);

            if (startDate == null || startDate.isAfter(st)) {
              startDate = st;
            }
          });

          result.push({
            name: master.name,
            quantity: count,
            startDate: startDate?.format?.('YYYY-MM-DD') ?? '',
          });
        });

        return result;
      },
      // 請求内容一覧のヘッダ
      billingHeaders() {
        let text;
        if (this.company) {
          switch (this.company.payType) {
            case '0':
              text = '支払日';
              break;
            case '36':
              text = '振込期限';
              break;
          }
        }
        return [
          { key: 'useDate', label: '利用年月' },
          { key: 'licenses', label: '品名・数量' },
          { key: 'amount', label: '請求金額' },
          { key: 'billingDate', label: text },
          { key: 'invoice', label: '請求書' },
          { key: 'paymentResult', label: '決済結果' },
        ];
      },
    },
  };
</script>

<!-- スタイル -->
<style scoped>
  #LicenseMain >>> nav {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }
  #LicenseMain >>> nav > ul > button {
    margin-left: 0.5rem;
  }
  #alert {
    background-color: transparent;
    border: none;
  }
  #LicenseMain >>> .checkbox-column {
    padding: 0;
  }
  #LicenseMain >>> .quantity-input {
    padding-top: 0;
    padding-bottom: 0;
  }
  #LicenseMain >>> .custom-control-input:checked ~ .custom-control-label::before {
    background: var(--colorTheme);
    border-color: var(--colorTheme);
  }
  #LicenseMain >>> .number .form-group {
    margin: 0;
  }
  #LicenseMain >>> .number .form-group input[type='number'] {
    height: 1.5rem;
    padding-left: 0;
    padding-right: 0.5rem;
    text-align: right;
  }
  #LicenseMain >>> .row-center {
    display: grid;
    text-align: center;
    align-items: center;
    padding: 0;
  }
  #LicenseMain >>> .renew {
    display: table-cell;
    align-items: center;
  }
  #LicenseMain >>> .container {
    max-width: 100%;
  }
  #LicenseMain >>> .info-content {
    width: 100%;
    height: 10rem;
    font-size: 14px;
  }
  .info-content table td:first-child {
    display: flex;
  }
  #LicenseMain >>> .info-content table td {
    white-space: pre-wrap;
  }
  #LicenseMain >>> .download {
    font-size: x-small;
  }
</style>
