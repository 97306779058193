// @ts-check

import { DrawWallActionBuilder } from '../../action/builder/drawWallActionBuilder';
import { EventPresetBuilder } from '../eventPresetBuilder';
import { EventPreset } from '../eventPreset';

export class WallPreset extends EventPresetBuilder {
  /**
   *
   * @returns {EventPreset}
   */
  build() {
    const preset = new EventPreset();

    preset.click = dataset => {
      if (dataset.target === 'layer' || dataset.target === 'object') {
        this.actionManager.setAction(new DrawWallActionBuilder(this.context, dataset));
      }
    };

    return preset;
  }
}
