<!--
作成者：Nagata Yoshitaka
作成日：2021.01.07
画面名：画像情報編集 レイヤーコンポーネント
機能：
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div class="layer-wrapper" :class="{ enable: layer.enabled }" ref="wrapper">
    <canvas
      class="layer"
      :layerName="layer.name"
      ref="main"
      :style="
        'opacity:' +
          layer.transparent +
          '%; ' +
          'filter: brightness(' +
          layer.brightness +
          '% ) saturate(' +
          layer.saturation +
          '%);'
      "
    ></canvas>
    <canvas
      class="material-canvas"
      ref="materialCanvas"
      :class="{ enable: editMode && layer.isActive }"
    ></canvas>
    <div
      v-for="corner in perspectiveData"
      :key="corner.name"
      :id="corner.name"
      :style="{ top: corner.y + 'px', left: corner.x + 'px' }"
      class="corner ui-draggable"
      :class="{ enable: editMode && layer.isActive }"
      @mousedown="cornerMouseDown(corner)"
      @mouseup="cornerMouseUp()"
    ></div>
  </div>
</template>
<script>
  import * as fx from 'glfx';

  const OFFSET_REPEAT_SIZE = 100;
  export default {
    props: ['layer', 'baseImageInfo', 'editMode', 'zoom', 'allEnabledLayer', 'setChangeMaterial'],
    data: function() {
      return {
        id: 0,
        url: '',
        defaultContext: null,
        defaultCorner: [
          { name: 'topLeft', x: 0, y: 0 },
          { name: 'topRight', x: 0, y: 0 },
          { name: 'bottomLeft', x: 0, y: 0 },
          { name: 'bottomRight', x: 0, y: 0 },
        ],
        perspectiveData: [
          { name: 'topLeft', x: 0, y: 0 },
          { name: 'topRight', x: 0, y: 0 },
          { name: 'bottomLeft', x: 0, y: 0 },
          { name: 'bottomRight', x: 0, y: 0 },
        ],
        draggingCorner: null,
        cornerDraggable: false,
        baseMaterialCanvas: null,
        fxCanvas: null,
        selectMaterial: null,
        redrawFlag: false,
      };
    },
    watch: {
      //素材編集モード切り替え時
      editMode() {
        if (this.editMode) {
          this.fxCanvas.classList.add('enable');
        } else {
          this.fxCanvas.classList.remove('enable');
          if (this.selectMaterial != null) {
            this.simulate();
          }
          this.$emit('reDrawing');
        }
        this.allEnabledLayer();
      },
      'layer.materialScale': {
        deep: true,
        handler: async function() {
          this.simulate();
        },
      },
    },
    mounted: async function() {
      //サイズを部屋画像にあわせる
      this.$refs.main.width = this.baseImageInfo.canvas.width;
      this.$refs.main.height = this.baseImageInfo.canvas.height;
      //素材編集用データ初期化
      this.initMaterialCanvas();
      if (this.layer.fileUrl) {
        let ctx = this.$refs.main.getContext('2d');
        let image = new Image();
        image.src = await this.layer.fileUrl;
        image.crossOrigin = 'anonymous';
        ctx.drawImage(image, 0, 0, this.$refs.main.width, this.$refs.main.height);
        this.defaultContext = this.$refs.main
          .getContext('2d')
          .getImageData(0, 0, this.$refs.main.width, this.$refs.main.height);
      }
      //マウント処理が完了をmainに通知
      this.$emit('completeComponent', 'simLayer');
    },
    methods: {
      //シミュレーターモードに切り替えたとき、キャンバスのデータを更新する。
      dataUpdateCanvas: async function(fileUrl) {
        if (fileUrl) {
          let ctx = this.$refs.main.getContext('2d');

          //イメージファイル設定
          let image = await new Promise(resolve => {
            let image = new Image();
            image.src = fileUrl;
            image.crossOrigin = 'anonymous';
            image.onload = function() {
              resolve(image);
            };
          });
          //画像の色を用意
          let canvasWall = document.createElement('canvas');
          canvasWall.width = this.$refs.main.width;
          canvasWall.height = this.$refs.main.height;
          let wall = canvasWall.getContext('2d');
          wall.fillStyle = this.layer.color;
          wall.fillRect(0, 0, this.$refs.main.width, this.$refs.main.height);

          //wall.globalCompositeOperation = 'source-in';
          //wall.drawImage(image, 0, 0);
          //wall.globalCompositeOperation = 'source-over';

          //一度描画をリセットする。
          ctx.clearRect(0, 0, this.$refs.main.width, this.$refs.main.height);

          //編集モードの画像を描画する。
          ctx.drawImage(image, 0, 0, this.$refs.main.width, this.$refs.main.height);

          ctx.globalCompositeOperation = 'source-in';
          ctx.drawImage(canvasWall, 0, 0);
          ctx.globalCompositeOperation = 'source-over';

          this.defaultContext = await this.$refs.main
            .getContext('2d')
            .getImageData(0, 0, this.$refs.main.width, this.$refs.main.height);

          let getMaterial = this.setChangeMaterial(this.layer.material);
          //material設定値がる場合、再設定を行う
          if (getMaterial == null || this.layer.material == null) {
            let canvas = document.createElement('canvas');
            if (canvas.getContext) {
              var context = canvas.getContext('2d');
              //ここに具体的な描画内容を指定する
              //塗りスタイルに青色を指定する
              context.fillStyle = this.layer.color;
              context.fillRect(0, 0, this.$refs.main.width, this.$refs.main.height);

              this.simulate(canvas.toDataURL());
              return await this.$refs.main.toDataURL('image/png');
            }
          } else if (getMaterial != null) {
            this.simulate(getMaterial.url);
            return await this.$refs.main.toDataURL('image/png');
          }
          return null;
        }
      },
      //初期処理
      initMaterialCanvas: function() {
        this.$refs.materialCanvas.width = this.baseImageInfo.canvas.width;
        this.$refs.materialCanvas.height = this.baseImageInfo.canvas.height;

        this.initPointer();

        this.baseMaterialCanvas = document.createElement('canvas');
        this.baseMaterialCanvas.width = this.baseImageInfo.canvas.width;
        this.baseMaterialCanvas.height = this.baseImageInfo.canvas.height;
        this.fxCanvas = fx.canvas();
      },
      initPointer: function(flag) {
        if (this.layer.PerspectiveData == null) {
          this.perspectiveData = [
            { name: 'topLeft', x: 0, y: 0 },
            { name: 'topRight', x: this.$refs.materialCanvas.width, y: 0 },
            { name: 'bottomLeft', x: 0, y: this.$refs.materialCanvas.height },
            {
              name: 'bottomRight',
              x: this.$refs.materialCanvas.width,
              y: this.$refs.materialCanvas.height,
            },
          ];
          this.$emit('setMaterialEditLog', JSON.parse(JSON.stringify(this.perspectiveData)));
        } else {
          let point = this.layer.PerspectiveData;
          this.perspectiveData = [
            {
              name: 'topLeft',
              x: point.find(p => p.name == 'topLeft').x,
              y: point.find(p => p.name == 'topLeft').y,
            },
            {
              name: 'topRight',
              x: point.find(p => p.name == 'topRight').x,
              y: point.find(p => p.name == 'topRight').y,
            },
            {
              name: 'bottomLeft',
              x: point.find(p => p.name == 'bottomLeft').x,
              y: point.find(p => p.name == 'bottomLeft').y,
            },
            {
              name: 'bottomRight',
              x: point.find(p => p.name == 'bottomRight').x,
              y: point.find(p => p.name == 'bottomRight').y,
            },
          ];
          if (flag) {
          this.$emit('setMaterialEditLog', JSON.parse(JSON.stringify(this.perspectiveData)), true);
          }
        }
        this.defaultCorner = [
          { name: 'topLeft', x: 0, y: 0 },
          { name: 'topRight', x: this.$refs.materialCanvas.width, y: 0 },
          { name: 'bottomLeft', x: 0, y: this.$refs.materialCanvas.height },
          {
            name: 'bottomRight',
            x: this.$refs.materialCanvas.width,
            y: this.$refs.materialCanvas.height,
          },
        ];
      },
      /**
       * baseMaterialCanvasに素材反映
       * fxCanvasにコピー
       * fxCanvas変形
       * MaterialCanvasにコピー
       * MainCanvasにコピー
       * @param {elemnt} material 素材img/canvasエレメント
       */
      simulate: async function(material, singleColor = null) {
        let canvasImage = null;
        if (material != null) {
          canvasImage = await new Promise(resolve => {
            let image = new Image();
            image.src = material;
            image.crossOrigin = 'anonymous';
            image.onload = function() {
              resolve(image);
            };
          });
        } else {
          if (singleColor == null) {
            canvasImage = this.selectMaterial;
          }
        }

        this.selectMaterial = canvasImage; //material情報を保存
        this.setBaseMaterialCanvas(canvasImage);
        this.perspective();
        this.setMaterialCanvas();
        this.setMainCanvas();
      },
      /**
       * baseMaterialCanvasに素材反映
       * @param {elemnt} material 素材img/canvasエレメント
       */
      setBaseMaterialCanvas: function(material) {
        //baseMaterialCanvasに一旦描画
        let tmpContext = this.baseMaterialCanvas.getContext('2d'),
          w = this.$refs.main.width,
          h = this.$refs.main.height;
        //マスク描画
        if (this.defaultContext != null) {
          tmpContext.putImageData(this.defaultContext, 0, 0);
        }
        //素材画像を縮小
        let matCanvas = document.createElement('canvas');

        if (material != null) {
          if (material.width != 0) {
            let scale = 100 * 0.01;
            if (this.layer.materialScale != null) scale = Number(this.layer.materialScale) * 0.01;
            matCanvas.width = material.width * scale;
            matCanvas.height = material.height * scale;
          } else {
            matCanvas.width = OFFSET_REPEAT_SIZE;
            matCanvas.height = OFFSET_REPEAT_SIZE;
          }

          matCanvas.getContext('2d').drawImage(material, 0, 0, matCanvas.width, matCanvas.height);
          let pattern = tmpContext.createPattern(matCanvas, 'repeat');

          // 塗りつぶしスタイルの設定
          tmpContext.fillStyle = pattern;
          // パスに矩形のサブパスを追加する
          tmpContext.rect(0, 0, w, h);
          // 現在の塗りつぶしスタイルでサブパスを塗りつぶす
          tmpContext.fill();
          //ブレンドモード設定
          tmpContext.save();
          tmpContext.restore();
        }
        if (material == null) {
          if (this.layer.color != null) {
            // 塗りつぶしスタイルの設定
            tmpContext.fillStyle = this.layer.color;
            // パスに矩形のサブパスを追加する
            tmpContext.rect(0, 0, w, h);
            // 現在の塗りつぶしスタイルでサブパスを塗りつぶす
            tmpContext.fill();
            //ブレンドモード設定
            tmpContext.save();
            tmpContext.restore();
          }
        }
      },
      /**
       * 素材画像レイヤーの変形
       * glfx.jsを使用。
       * 引数として、変形前の座標、変形後の座標を渡す。
       */
      perspective: function() {
        let before = [];
        let after = [];
        this.defaultCorner.forEach(data => {
          before.push(data.x);
          before.push(data.y);
        });
        this.perspectiveData.forEach(data => {
          after.push(data.x);
          after.push(data.y);
        });

        let matCanvas = this.baseMaterialCanvas;
        let texture = this.fxCanvas.texture(matCanvas);
        this.fxCanvas
          .draw(texture)
          .perspective(before, after)
          .update();
      },
      setMaterialCanvas: function() {
        let matContext = this.$refs.materialCanvas.getContext('2d');
        matContext.beginPath();
        matContext.clearRect(
          0,
          0,
          this.$refs.materialCanvas.width,
          this.$refs.materialCanvas.height
        );
        matContext.drawImage(this.fxCanvas, 0, 0);
      },
      setMainCanvas: function() {
        let context = this.$refs.main.getContext('2d');
        //マスク描画
        if (this.defaultContext != null && this.redrawFlag) {
          context.putImageData(this.defaultContext, 0, 0);
          this.redrawFlag = false;
        }
        //Materialcanvasを合成
        context.globalCompositeOperation = 'source-in';
        context.drawImage(this.$refs.materialCanvas, 0, 0);
        context.globalCompositeOperation = 'source-over';
      },
      cornerMouseDown: function(corner) {
        this.cornerDraggable = true;
        this.draggingCorner = corner;
      },
      cornerMouseMove: function(e) {
        if (this.cornerDraggable) {
          let corner = this.draggingCorner;
          //EditArea左上のページで見た座標を取得
          let clientRect = this.$refs.main.getBoundingClientRect();
          //ページでみたクリック位置との相対座標を取得
          let magn = this.$refs.main.width / (this.$refs.main.width * (this.zoom * 0.01));
          let modeX = (e.clientX - clientRect.left) * magn;
          let modeY = (e.clientY - clientRect.top) * magn;
          corner.x = modeX;
          corner.y = modeY;
          this.perspective();
          this.setMaterialCanvas();
        }
      },
      cornerMouseUp: function() {
        this.cornerDraggable = false;
        this.draggingCorner = null;
        this.layer.PerspectiveData = this.perspectiveData;
        this.$emit('setMaterialEditLog', JSON.parse(JSON.stringify(this.perspectiveData)));
      },
      /*
       * 単色カラーを変更する。
       */
      clickCheck(e, dropper, baseImageCanvas) {
        //スポイト機能がONの時
        if (e.button == 0 && dropper == true) {
          let pos = {
            x: e.offsetX,
            y: e.offsetY,
          };
          if (e.changedTouches != null) {
            //mobile対応
            pos = this.mobileTouch(e);
          }

          let req = this.getCollar(baseImageCanvas, pos);
          this.layer.color = this.rgbChanger(req);
          this.$emit('mainSimulate', null, null, true);
        }
      },
      /*
       * 画面をクリックした場合、その位置の色を取得する。
       */
      getCollar(canvas, pos) {
        //マウス位置を取得
        let x = pos.x;
        let y = pos.y;

        let context = canvas.getContext('2d');

        // 指定座標から幅1,高さ1のImageDataオブジェクトの取得。
        var imagedata = context.getImageData(x, y, 1, 1);

        // RGBAの取得。
        var r = imagedata.data[0];
        var g = imagedata.data[1];
        var b = imagedata.data[2];

        return [r, g, b];
      },
      /*
       * カラーコード10進数⇒16進数カラーコード
       */
      rgbChanger: function(rgb = [0, 0, 0]) {
        return (
          '#' +
          rgb
            .map(function(value) {
              return ('0' + value.toString(16)).slice(-2);
            })
            .join('')
        );
      },
      //mobileモードのTouchを検知
      mobileTouch: function(e) {
        let pos = {
          x: 0,
          y: 0,
        };
        if (e.changedTouches != null) {
          const rect = e.target.getBoundingClientRect();
          pos.x = Math.floor(e.touches[0].clientX - rect.left);
          pos.y = Math.floor(e.touches[0].clientY - rect.top);
        }
        return pos;
      },
      //  素材編集の角を設定
      setMaterialEditCorner: function(newCorners, flag) {
        if (newCorners) {
          this.layer.PerspectiveData = newCorners;
        }
        this.initPointer(flag);
        this.perspective();
        this.setMaterialCanvas();
      },
    },
  };
</script>
<style scoped>
  .layer-wrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
  }

  .layer-wrapper.enable {
    display: block;
  }

  /*   .layer {
    opacity: 0.5;
  } */

  .material-canvas {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .material-canvas.enable {
    display: block;
    opacity: 0.6;
  }
  .corner {
    display: none;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: -5px;
    background: #ff0000;
    border: 2px solid white;
    border-radius: 50%;
    cursor: move;
    z-index: 9999;
  }
  .corner.enable {
    display: block;
  }
</style>
