<template>
  <g
    class="svgselect-root"
    :transform="`translate(${-width / 2})`"
    @mouseover="mouseover"
    @mouseleave="mouseleave"
  >
    <rect
      :width="width"
      :height="height"
      fill="white"
      :x="-width / 2"
      :y="-height / 2"
      :stroke="outlineColor"
      stroke-width="4"
      :rx="10"
      :ry="10"
    />
    <text
      stroke="black"
      fill="black"
      :style="{
        'font-size': fontSize + 'px',
      }"
      text-anchor="middle"
      :dy="fontSize / 2"
      >{{ value.label }}</text
    >
    <g
      :class="{
        option: true,
        hidden: hiddenOptions,
      }"
    >
      <rect
        :width="width"
        :height="height * options.length"
        fill="white"
        :x="-width / 2"
        :y="-height / 2 + height"
        :stroke="outlineColor"
        stroke-width="4"
        :rx="10"
        :ry="10"
      />
      <g
        v-for="(o, i) in options"
        :key="i"
        :transform="`translate(0, ${fontSize / 2 + height * (i + 1) - 4})`"
        @click="clickOption(o)"
      >
        <rect
          :width="width - 8"
          :height="height - 8"
          fill="white"
          :x="-width / 2 + 4"
          :y="(-height * 3) / 4 + 4"
          class="option-tile"
        />
        <text
          stroke="black"
          fill="black"
          :style="{
            'font-size': fontSize + 'px',
            'pointer-events': 'none',
          }"
          text-anchor="middle"
          >{{ o.label }}</text
        >
      </g>
    </g>
  </g>
</template>
<script>
  // @ts-check
  import { defineComponent, ref } from '@vue/composition-api';

  export default defineComponent({
    props: {
      value: {
        type: Object,
        required: true,
      },
      fontSize: {
        type: Number,
        default: 50,
      },
      width: Number,
      height: Number,
      options: Array,
      outlineColor: {
        type: String,
        default: 'gray',
      },
    },
    setup(_, context) {
      const hiddenOptions = ref(true);
      const mouseover = () => {
        hiddenOptions.value = false;
      };
      const mouseleave = () => {
        hiddenOptions.value = true;
      };
      const clickOption = (/** @type {any} */ option) => {
        hiddenOptions.value = true;
        context.emit('select', option);
      };
      return {
        mouseover,
        mouseleave,
        hiddenOptions,
        clickOption,
      };
    },
  });
</script>
<style scoped>
  @keyframes fe {
    0% {
      opacity: 1;
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(0.1);
    }
  }
  @keyframes be {
    0% {
      opacity: 0;
      transform: scale(1);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  .svgselect-root {
    color: black;
    pointer-events: all;
    cursor: pointer;
    box-shadow: 2px 2px 2px 2px gray;
  }
  .option {
    opacity: 1;
    animation: be 0.2s forwards;
  }

  .option.hidden {
    opacity: 0;
    pointer-events: none;
    animation: fe 0.3s forwards;
  }
  .option-tile:hover {
    fill: #999;
  }
</style>
