<template>
  <b-container class="pt-2">
    <b-row>
      <b-col class="" sm="12">
        <b-form-checkbox
          :checked="showMeasure"
          @change="val => $emit('showMeasure', val)"
          name="showLength"
        >
          部屋の長さを表示する。
        </b-form-checkbox>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col class="title" :disabled="!showMeasure">
        単位：
      </b-col>
      <b-col>
        <b-form-select
          :value="unitOfMeasure"
          @change="e => $emit('unitOfMeasure', e)"
          :options="measureSelectList"
          :disabled="!showMeasure"
          style="width:auto;"
        />
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col class="" sm="12">
        <b-form-checkbox
          :checked="showSpace"
          @change="val => $emit('showSpace', val)"
          name="showSpace"
        >
          部屋の広さを表示する。
        </b-form-checkbox>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col class="title" :disabled="!showSpace">
        単位：
      </b-col>
      <b-col>
        <b-form-select
          :value="unitOfSpace"
          @change="e => $emit('unitOfSpace', e)"
          :options="spaceSelectList"
          :disabled="!showSpace"
          style="width:auto;"
        />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import { computed, ref, watchEffect } from '@vue/composition-api';
  import { MEASURE_SELECT_LIST, SPACE_SELECT_LIST } from '../../lib/util/const';
  export default {
    props: {
      unitOfMeasure: {
        type: String,
        required: 'm',
      },
      showMeasure: {
        type: Boolean,
        required: true,
      },
      unitOfSpace: {
        type: String,
        required: '帖',
      },
      showSpace: {
        type: Boolean,
        required: true,
      },
    },
    setup() {
      const measureSelectList = ref(MEASURE_SELECT_LIST);
      const spaceSelectList = ref(SPACE_SELECT_LIST);

      return {
        measureSelectList,
        spaceSelectList,
      };
    },
  };
</script>
<style scoped>
  .title {
    display: grid;
    align-items: center;
    padding-left: 2.5rem;
    max-width: 7rem;
    text-align: right;
  }
</style>
