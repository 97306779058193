<template>
  <b-container>
    <div style="display: flex; justify-content: end; padding: 4px;">
      <b-form-select
        :value="currentPrintSize"
        @change="e => $emit('update:currentPrintSize', e)"
        :options="paperSizeOptions"
        style="width:auto;"
      />
      <b-form-select
        :value="currentPrintScale"
        @change="e => $emit('update:currentPrintScale', e)"
        :options="paperScaleOptions"
        style="width:auto; margin-left: 2px;"
      />
    </div>
    <div style="display: flex; justify-content: center">
      <img v-show="!loadingImage" :src="imageUrl" width="700" alt="img" @load="onLoadImage" />
      <div
        v-show="loadingImage"
        :style="{
          width: '700px',
          height: 700 * PAPER_RATIO + 'px',
          display: 'flex',
          'justify-content': 'center',
          'align-items': 'center',
        }"
      >
        <b-spinner style="width: 100px; height: 100px" />
      </div>
    </div>
    <button
      style="position: absolute; height: 60px; width: 10px; top: 0; right: 0; opacity: 0"
      @click="() => (counter += 1)"
    ></button>
  </b-container>
</template>
<script>
  // @ts-check
  import { computed, ref, watchEffect } from '@vue/composition-api';
  import { PAPER_RATIO, PAPER_SIZE_OPTIONS, PAPER_SCALE_OPTIONS } from '../../lib/util/const';
  export default {
    props: {
      currentPrintSize: {
        type: String,
        required: true,
      },
      currentPrintScale: {
        type: Number,
        required: true,
      },
      imageBuf: {
        type: Buffer,
      },
      generatingImage: {
        type: Boolean,
      },
    },
    setup(props) {
      const paperSizeOptions = ref(PAPER_SIZE_OPTIONS);

      const paperScaleOptions = ref(PAPER_SCALE_OPTIONS);

      const imageUrl = computed(() => {
        // @ts-ignore
        return URL.createObjectURL(new Blob([props.imageBuf]));
      });

      const loading = ref(false);

      const onLoadImage = () => {
        loading.value = false;
      };

      watchEffect(() => {
        if (props.generatingImage) {
          loading.value = true;
        }
      });

      const loadingImage = computed(() => {
        return loading.value || props.generatingImage;
      });

      return {
        paperSizeOptions,
        paperScaleOptions,
        imageUrl,
        onLoadImage,
        loadingImage,
        PAPER_RATIO,
      };
    },
  };
</script>
<style scoped>
  .title {
    display: grid;
    align-items: center;
    padding-left: 2.5rem;
    text-align: right;
  }
</style>
