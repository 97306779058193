<template>
  <b-row>
    <b-col>
      <!-- VIEW: メッセージ -->
      <div class="guidance-message">
        <!-- 物件未選択時 -->
        <p v-if="isNewPlaneView && !propertyData.commonValue.propertyName">
          {{ MESSAGE_TEXT.SAVE_TEXT_PLEASE_SELECT_PROPERTY }}
        </p>
        <!-- 枚数最大時 -->
        <p v-if="isNewPlaneView && isMax">
          {{ MESSAGE_TEXT.SAVE_WARNING_PLANE_VIEW_MAX_PROPERTY }}
        </p>
      </div>
      <!-- VIEW: 新規作成時の検索・新規ボタン -->
      <div
        class="search-or-create"
        v-if="isNewPlaneView"
        style="position: absolute; padding: 0; top: -2%; right: 3%; white-space: nowrap"
      >
        <button class="btn btn-ally mr-1" @click="openPropertyListModal">
          検索
        </button>
        <button class="btn btn-ally" style="margin-left:0.5rem;" @click="openCreatePropertyModal">
          新規
        </button>
      </div>
      <!-- VIEW: 平面図のタイトル入力フォーム -->
      <ValidationObserver ref="savePlaneNameForm">
        <allyText
          :mode="'View'"
          title="物件名"
          v-model="propertyData.commonValue.propertyName"
          rules="required"
        />
        <allySelect
          :mode="roomNameItems.length <= 1 || !isNewPlaneView ? 'View' : 'Add'"
          title="部屋番号"
          v-model="vModelRoomId"
          :items="roomNameItems"
          @input="val => $emit('updateRoomId', val)"
        />
        <allyText
          title="タイトル"
          v-model="vModelPlaneName"
          @input="val => $emit('updatePlaneName', val ? val : '')"
          :rules="'required|max:' + VALIDATE_NUM.TITLE_LENGTH_MAX"
        />
      </ValidationObserver>
    </b-col>

    <!-- VIEW: 検索モーダル -->
    <b-modal
      id="propertyListModal"
      title="既存の物件情報を選択"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-title="閉じる"
      ok-only
      size="xl"
    >
      <PropertyList
        :modalFlag="true"
        @selectClicked="selectClicked"
        @selectRoomClicked="selectRoomClicked"
      />
    </b-modal>

    <!-- VIEW: 新規作成モーダル -->
    <ValidationObserver ref="createPropertyDataForm" v-slot="ObserverProps">
      <b-modal
        id="createPropertyModal"
        title="新規の物件情報を作成"
        header-class="ally-modal"
        footer-class="ally-modal"
        cancel-variant="light"
        cancel-title="キャンセル"
        ok-title="保存"
        :ok-disabled="ObserverProps.invalid"
        @ok="createPropertyData()"
        size="xl"
      >
        <PropertyViewer ref="propertyViewerComponent" :modalFlag="true" />
      </b-modal>
    </ValidationObserver>
  </b-row>
</template>
<script>
  // IMPORT:
  import {
    computed,
    defineComponent,
    getCurrentInstance,
    nextTick,
    onMounted,
    onUnmounted,
    ref,
    toRefs,
    watch,
  } from '@vue/composition-api';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getProperty, listPlaneViews } from '../../../../graphql/queries';
  import PropertyList from '@/views/Property/List';
  import { useRouter } from '../../lib/state/router';
  import PropertyViewer from '../../../Property/View';
  import { MESSAGE_TEXT, VALIDATE_NUM } from '../../lib/util/const';

  export default defineComponent({
    components: {
      PropertyList,
      PropertyViewer,
    },
    props: {
      isNewPlaneView: {
        // PROP: 新規平面図フラグ
        type: Boolean,
        required: true,
      },
      planeName: {
        // PROP: 平面図名
        type: String,
        required: true,
        default: null,
      },
      propertyId: {
        // PROP: 物件ID
        type: String,
        required: false,
        default: null,
      },
      roomId: {
        // PROP: 部屋ID
        type: String,
        required: false,
        default: null,
      },
    },
    setup(props, context) {
      // 定数
      const CURRENT_INSTANCE = getCurrentInstance()?.proxy; // CONST: thisの代わり
      const ROUTER = useRouter(); // CONST: ユーザー・ルーター
      const MAX_PLANE_VIEW = 10; // CONST: 平面図最大枚数
      const ROOM_NAME_ITEMS_INITIAL = JSON.stringify([{ value: null, text: '-', disabled: false }]); // CONST: 部屋番号プルダウン初期値（JSON.parseして使う）
      // Data
      const savePlaneNameForm = ref(null); // DATA: 平面図タイトル入力フォームref名
      const createPropertyDataForm = ref(null); // DATA: 物件情報新規作成フォームref名
      const propertyViewerComponent = ref(null); // DATA: 物件情報登録コンポーネントref名
      const isMax = ref(false); // DATA: 物件情報ローディング フラグ
      const isPropertyLoading = ref(false); // DATA: 物件情報ローディング フラグ
      const propertyMode = ref('select'); // DATA: 物件モード（create）
      const propertyData = ref({
        // DATA: 物件データ（物件名）
        commonValue: {
          propertyName: null, // 物件名
        },
      });
      const vModelRoomId = ref(props.roomId ? props.roomId : null); // DATA: Vモデル：部屋ID
      const vModelPlaneName = ref(props.planeName ? props.planeName : null); // DATA: Vモデル：平面図名
      const roomNameItems = ref(JSON.parse(ROOM_NAME_ITEMS_INITIAL)); // DATA: 部屋番号プルダウンアイテム

      onMounted(async () => {
        // 物件IDがある場合
        if (props.propertyId && !propertyData.value.commonValue.propertyName) {
          // MOUNTED: 物件IDがある場合：物件情報を取得（物件名表示）
          propertyData.value = await getPropertyData(props.propertyId);
          // MOUNTED: 物件IDがある場合：部屋番号プルダウンアイテムを更新
          await updateRoomItems();
          // MOUNTED: 物件IDがある場合：最大枚数フラグチェック
          setIsMax(vModelRoomId);
          // MOUNTED: バリデート
          await validateSavePlaneNameForm();
        }
      });

      /**
       * WATCH: 物件情報更新時：物件IDをセット
       */
      watch([propertyData], () => {
        CURRENT_INSTANCE.$emit('updatePropertyId', propertyData.value.id);
      });

      /**
       * WATCH: 部屋番号更新時：最大数フラグ変更
       */
      watch([vModelRoomId], async () => {
        setIsMax(vModelRoomId);
      });

      /**
       * WATCH: 平面図タイトル入力モーダル入力時：バリデート
       */
      watch([propertyData, vModelRoomId, vModelPlaneName], async () => {
        validateSavePlaneNameForm();
      });

      /**
       * METHOD: バリデート
       */
      const validateSavePlaneNameForm = async () => {
        CURRENT_INSTANCE.$emit('updateIsValidate', await savePlaneNameForm.value.validate());
      };

      /**
       * METHOD: 選択中の部屋番号の最大枚数フラグチェック
       */
      const setIsMax = async vModelRoomId => {
        const roomItem = await roomNameItems.value.find(item => item.value == vModelRoomId.value);
        // 平面図編集の場合は常にfalse
        isMax.value = props.isNewPlaneView ? roomItem.disabled : false;
        CURRENT_INSTANCE.$emit('updateIsMax', isMax.value);
      };

      /**
       * METHOD: モーダル表示：物件情報 検索
       */
      const openPropertyListModal = () => {
        CURRENT_INSTANCE.$bvModal.show('propertyListModal');
      };

      /**
       * METHOD: モーダル表示：物件情報 新規作成
       */
      const openCreatePropertyModal = () => {
        CURRENT_INSTANCE.$bvModal.show('createPropertyModal');
      };

      /**
       * METHOD: 検索画面で選択された物件情報を取得
       */
      const selectClicked = async row => {
        if (isPropertyLoading.value == true) {
          // 物件情報ロード中は処理しない
          return;
        }
        // 物件情報ロード中とする
        isPropertyLoading.value = true;
        // 平面図リスト取得
        let planeViewList = await getPlaneViewList(row.id, null);

        // 保存上限チェック
        if (planeViewList.length < MAX_PLANE_VIEW) {
          // 保存上限未満の場合
          propertyData.value = row; // 物件情報
          propertyMode.value = 'select'; // 物件モード
          vModelRoomId.value = null; // Vモデル：部屋ID
          // 部屋番号プルダウンアイテム更新
          await updateRoomItems();
          // 検索モーダル非表示
          CURRENT_INSTANCE.$bvModal.hide('propertyListModal');
        } else {
          // 保存上限以上の場合、保存上限アラート表示
          alert(MAX_PLANE_VIEW + MESSAGE_TEXT.SAVE_WARNING_LIMIT_ALERT);

          CURRENT_INSTANCE.$bvModal.hide('propertyListModal');
          CURRENT_INSTANCE.$bvModal.show('propertyListModal');
        }
        isPropertyLoading.value = false;
      };

      /**
       * METHOD: 検索画面で選択された物件情報と部屋番号を取得
       */
      const selectRoomClicked = async (item, propertyId) => {
        if (isPropertyLoading.value == true) {
          // 物件情報ロード中は処理しない
          return;
        }
        // 物件情報ロード中とする
        isPropertyLoading.value = true;

        // 平面図リスト取得
        let planeViewList = await getPlaneViewList(propertyId, item.id);

        // 保存上限チェック
        if (planeViewList.length < MAX_PLANE_VIEW) {
          // 保存上限未満の場合
          propertyData.value = await getPropertyData(propertyId); // 物件情報
          propertyMode.value = 'select'; // 物件モード
          vModelRoomId.value = item.id; // Vモデル：部屋ID
          // 部屋番号プルダウンアイテム更新
          await updateRoomItems();
          // 検索モーダル非表示
          CURRENT_INSTANCE.$bvModal.hide('propertyListModal');
        } else {
          // 保存上限以上の場合、保存上限アラート表示
          alert(MAX_PLANE_VIEW + MESSAGE_TEXT.SAVE_WARNING_LIMIT_ALERT);
        }
        isPropertyLoading.value = false;
      };

      /**
       * METHOD: 物件情報を取得
       */
      const getPropertyData = async propertyId => {
        // 物件情報取得
        try {
          const result = await API.graphql({
            query: getProperty,
            variables: {
              id: propertyId,
            },
          });
          if (!result.data) {
            console.error('物件情報を取得できませんでした propertyId:' + propertyId);
          }
          return result.data.getProperty;
        } catch (error) {
          console.error(error, ', propertyId:' + propertyId);
        }
      };

      /**
       * METHOD: 部屋番号プルダウン更新
       */
      const updateRoomItems = async () => {
        const roomInfo = propertyData.value.roomInfo;
        try {
          const maxMessage = MESSAGE_TEXT.SAVE_WARNING_PLANE_VIEW_MAX_ROOM; // 最大枚数時メッセージ
          // 部屋名プルダウンアイテムを初期化する
          roomNameItems.value = JSON.parse(ROOM_NAME_ITEMS_INITIAL);

          // 物件モードがcreate以外の場合
          if (propertyMode.value != 'create') {
            // 物件情報に紐づく平面図リスト取得
            const planeViewList = await getPlaneViewList(propertyData.value.id, null);
            // 平面図リストが最大枚数以上の場合
            if (planeViewList.length >= MAX_PLANE_VIEW) {
              roomNameItems.value[0].text += maxMessage; // 最大枚数時メッセージを追記
              roomNameItems.value[0].disabled = true; // 非活性化
            }
          }
          // 部屋情報が存在しない、または部屋情報が0件の場合、終了する
          if (!roomInfo || roomInfo.length == 0) {
            return;
          }

          // 部屋情報が存在する場合、部屋番号の項目を設定する
          for (const room of roomInfo) {
            let planeViewList = [];
            // 物件モードがcreate以外の場合
            if (propertyMode.value != 'create') {
              // 部屋情報に紐づく平面図リストを取得
              planeViewList = await getPlaneViewList(propertyData.value.id, room.id);
            }
            // 部屋名をセット
            let roomName = room.roomName;
            // 平面図リストが最大枚数以上なら、部屋名にメッセージを追記する。
            if (planeViewList.length >= MAX_PLANE_VIEW) {
              roomName += maxMessage;
            }
            // 部屋名プルダウンアイテムにプッシュする
            roomNameItems.value.push({
              value: room.id,
              text: roomName,
              disabled: planeViewList.length >= MAX_PLANE_VIEW,
            });
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsMax(vModelRoomId);
        }
      };

      /**
       * METHOD: 平面図リストを取得
       */
      const getPlaneViewList = async (propertyId, roomId) => {
        // 平面図取得条件
        const queryVar = {
          limit: 60,
          companyId: await getCompanyId(),
          filter: {
            propertyId: {
              eq: propertyId,
            },
            roomId: {
              eq: roomId,
            },
            deleted: { eq: false },
          },
        };
        if (roomId == null) queryVar.filter.roomId = { attributeExists: false };

        var query = [];
        queryVar.nextToken = null;
        // 平面図の枚数を数える。
        try {
          do {
            // ネクストトークンが存在する限り続ける
            const result = await API.graphql(graphqlOperation(listPlaneViews, queryVar));
            query = [...query, ...result.data.listPlaneViews.items];
            queryVar.nextToken = result.data.listPlaneViews.nextToken;
          } while (queryVar.nextToken != null);
        } catch {
          return [];
        }
        return query;
      };

      /*
       * METHOD: 会社IDを取得
       */
      const getCompanyId = async () => {
        //アカウントから会社ID取得
        var groups =
          CURRENT_INSTANCE.$store.state.user.signInUserSession.accessToken.payload[
            'cognito:groups'
          ];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      };

      /**
       * METHOD: 物件情報 新規作成
       */
      const createPropertyData = async () => {
        // バリデートチェック
        if (!(await createPropertyDataForm.value.validate())) {
          // バリデートエラーがあれば終了する
          console.error('バリデートエラー。物件登録できません');
          return;
        }
        try {
          // コンポーネントの保存処理を呼ぶ
          const resultPropertyData = await propertyViewerComponent.value.create();

          // 物件情報更新
          propertyData.value = resultPropertyData;
          // Vモデル：部屋ID更新
          vModelRoomId.value =
            resultPropertyData.roomInfo.length > 0 ? resultPropertyData.roomInfo[0].id : null;
          // 物件モード更新
          propertyMode.value = 'create';
          // 部屋番号プルダウンアイテム更新
          await updateRoomItems();
        } catch (error) {
          console.error('Error:', error);
        }
      };

      return {
        isMax, // 最大枚数フラグ
        MAX_PLANE_VIEW, // 平面図最大枚数
        savePlaneNameForm, // 平面図タイトル入力フォームref名
        createPropertyDataForm, // 物件情報新規作成フォームref名
        propertyViewerComponent, // 物件情報登録コンポーネントref名
        roomNameItems, // 部屋番号プルダウンアイテム
        vModelRoomId, // Vモデル：部屋ID
        vModelPlaneName, // Vモデル：平面図名
        propertyMode, // 物件モード
        openPropertyListModal, // 物件検索モーダル表示
        selectClicked, // 検索モーダル：物件選択処理
        selectRoomClicked, // 検索モーダル：部屋選択処理
        propertyData, // 物件情報
        isPropertyLoading, // 物件情報ローディングフラグ
        openCreatePropertyModal, // 新規作成モーダル表示
        createPropertyData, // 新規作成処理
        MESSAGE_TEXT, // メッセージ文
        VALIDATE_NUM, // バリデート値
      };
    },
  });
</script>
<style scoped>
  /* メッセージ */
  .guidance-message p {
    font-size: small;
    font-weight: bold;
    color: red;
  }
  /* 検索・新規ボタン */
  .search-or-create button {
    height: 10px;
    line-height: 2px;
  }
</style>
