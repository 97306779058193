<template>
  <div class="margin-bottom">
    <div class="info-box">
      <b-container class="main">
        <b-row class="info-title">
          無料トライアルについて
        </b-row>
        <b-row class="info-content form-control overflow-auto " readonly>
          <b-col style="padding:0;">
            １．毎月1日に利用申込み（ライセンス購入）を行った場合・・・
            <span class="red">1ヶ月間</span>無料<br />
            <br />
            ２．月の途中で利用申込み（ライセンス購入）を行った場合・・・
            <span class="red">翌月末まで</span>無料<br />
            <br />
            <img style="max-width: 100%;" :src="imageUrl" />
            <br />
            <br />
            <span style="text-decoration:underline;">
              ※有料期間への継続を希望されない場合は、無料期間中にお客様ご自身で解約手続きを行ってください。
            </span>
            <br />
            解約手続きがなされなかった場合は、無料期間終了後翌月1日より1ヶ月分の料金が発生します。<br />
            <br />
            ※同じ商品のライセンスを追加または解約後に再購入いただく場合、無料トライアル期間の適用対象外となります。<br />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <br />
  </div>
</template>
<script>
  export default {
    name: 'info',
    props: ['infoCheck'],
    data: function() {
      return {
        infoChecker: this.infoCheck,
        space: '　',
        imageUrl:
          'https://allys3163848-dev.s3.ap-northeast-1.amazonaws.com/Master/manual/FreeTrialImage.png',
      };
    },
    watch: {
      infoChecker: function() {
        this.$emit('update:infoCheck', this.infoChecker);
      },
    },
  };
</script>
<style scoped>
  .margin-bottom {
    margin-bottom: 15px;
  }
  .info-box {
    background-color: rgba(43, 160, 132, 0.2);
  }
  .info-title {
    font-weight: bold;
    padding-top: 5px;
    margin-left: -5px;
  }
  .info-content {
    width: calc(30px + 100%);
    height: calc(35rem - 43px);
    font-size: 14px;
  }
  .info-content table td:first-child {
    display: flex;
  }
  .check-info {
    margin-bottom: 10px;
    text-align: center;
  }
  .check-info >>> .custom-control-input:checked ~ .custom-control-label::before {
    background: var(--colorTheme);
    border-color: var(--colorTheme);
  }
  .red {
    color: #ff0000;
  }
</style>
