<template>
  <CommonLayout title="長期収支" :companyLogo="companyLogo" :companyName="companyName">
    <template v-slot>
      <div style="padding-left: 25%; padding-right: 10%;">
        <div style="width: 100%; height: 2800px;  border-style: none;">
          <ReportCanvas
            ref="reportCanvas"
            width="2750px"
            height="2750px"
            style="padding-top: 100px; position: absolute;"
          >
            <allyLineChart
              style="width: 3600px; height: auto;"
              :data="result ? result.chartData : null"
              :options="{
                legend: {
                  reverse: true,
                  position: 'bottom',
                  labels: {
                    boxWidth: 50,
                    fontSize: 70,
                    padding: 50,
                  },
                },
                scales: {
                  yAxes: [
                    {
                      display: true,
                      scaleLabel: {
                        display: true,
                        labelString: '万円',
                        fontSize: 70,
                      },
                      ticks: {
                        beginAtZero: true,
                        callback: function(value) {
                          return Number(value / 10000).toLocaleString();
                        },
                        fontSize: 70,
                      },
                      gridLines: {
                        lineWidth: 10,
                        zeroLineWidth:15,
                        zeroLineColor: '#666',
                        color: '#ccc',
                      },
                    },
                  ],
                  xAxes: [
                    {
                      display: true,
                      scaleLabel: {
                        display: true,
                        labelString: '年',
                        fontSize: 70,
                      },
                      ticks: {
                        fontSize: 70,
                        maxRotation: 0,
                        minRotation: 0,
                        autoSkip: true,
                        autoSkipPadding: 10,
                        maxTicksLimit: 29,
                      },
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                },
                animation: {
                  duration: 0,
                },
                responsiveAnimationDuration: 0,
              }"
              :styles="result ? result.chartStyles : null"
            />
          </ReportCanvas>
        </div>
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import {
    headerStyle,
    subHeaderStyle,
    bodyStyle,
  } from '../../../../components/ExportReport/style';
  import ReportCanvas from '@/components/ExportReport/ReportCanvas';
  import Logo from '@/assets/report/reportImages/logo.png';

  export default {
    props: {
      companyLogo: String,
      companyName: String,
      result: Object,
    },
    components: {
      CommonLayout,
      ReportCanvas,
    },
    data() {
      return {
        logo: Logo,
        headerStyle,
        subHeaderStyle,
        bodyStyle,
      };
    },
    methods: {
      year(){
        console.log(Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)));
        return Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale));
      },
      genarateRandomData(num, start, end) {
        const result = [];
        for (let i = 0; i < num; i++) {
          result.push(Math.floor(Math.random() * (end - start) + start));
        }

        return result;
      },
    },
    computed: {
      options() {
        return {
          ...this.result.chartOptions,
          legend: {
            position: 'bottom',
            labels: {
              fontSize: 70,
            },
          },
          scales: {
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: '年',
                  fontSize: 70,
                },
                ticks: {
                  fontSize: 70,
                },
                gridLines: {
                  lineWidth: 10,
                },
              },
            ],
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: '年',
                  fontSize: 70,
                },
                ticks: {
                  fontSize: 70,
                },
                gridLines: {
                  lineWidth: 10,
                },
              },
            ],
          },
        };
      },
    },
  };
</script>
