<!--
作成者：Nakatsuka Daisuke
作成日：2020.12.21
画面名：物件情報一覧
機能：物件情報一覧
親子関係：なし
更新者：2021.08.03
①
更新内容：
①
-->
<template>
  <div>
    <b>物件情報一覧</b>
    <div
      class="row grid-margin"
      v-if="$route.path != '/Property/new/WallPaperSimulator/Edit/new' && !modalFlag"
    >
      <div class="col-12">
        <router-link to="/DashBoard" tag="button" class="btn btn-ally float-right">
          メインページへもどる
        </router-link>
        <router-link to="Add" tag="button" class="btn btn-ally mx-2 float-right">追加</router-link>
      </div>
    </div>
    <paginationV2
      ref="pagination"
      :headers="headers"
      targetName="Property"
      @detailClicked="e => detailProperty(e)"
      @deleteClicked="e => deleteProperty(e)"
      :hideDetail="hideDetailFlag"
      :hideDelete="hideDeleteFlag"
      :imageViewSize="{ width: '150px', height: '73px' }"
      @selectClicked="e => selectClicked(e)"
      @createRoomClicked="e => createRoomClicked(e)"
    ></paginationV2>
    <b-modal
      id="roomDelete"
      ref="roomDelete"
      title="部屋情報削除確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="削除"
      @ok="deleteRoomEvent()"
    >
      「{{ roomDeleteData.item.roomName }}」の部屋情報を削除しますか？
    </b-modal>
    <b-modal
      id="room-create"
      ref="roomCreate"
      title="部屋情報追加"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
    >
      <ValidationObserver ref="addRoomValidation">
        <allyText title="部屋番号" v-model="addRoomName" rules="required|max:15" />
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button class="btn-light" @click="cancel()">
          キャンセル
        </b-button>
        <b-button class="btn-light" @click="createRoomEvent()">
          追加
        </b-button>
      </template>
    </b-modal>
    <allyAlert code="E0002" :params="{ number: getMaxRoom }"></allyAlert>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { deleteProperty, updateProperty } from '../../graphql/mutations';
  import {
    getS3Url,
    listPlaneViews,
    getProperty,
    listPhotoSimulators,
  } from '../../graphql/queries';
  import roomFunction from './roomFunction';

  API.configure(awsconfig);

  export default {
    // PROP:
    props: ['modalFlag'],
    // DATA:
    data: function() {
      return {
        companys: {
          body: [],
          title: [],
        },
        isMounted: false,
        headers: [
          { key: 'roomInfo', label: ' ', deployment: this.deployment },
          {
            key: 'simInfo',
            label: '画像',
            publicImage: this.imageViewing,
            searchable: false,
          },
          { key: 'commonValue.propertyName', label: '物件名', sortable: true },
          { key: 'roomNumber', label: '部屋番号', searchable: false },
          { key: 'commonValue.owner', label: '所有者', sortable: true },
          { key: 'commonValue.staffName', label: '担当者', sortable: true },
          {
            key: 'simInfo.id',
            label: 'シミュレーター枚数',
            publicText: this.photoSimCheck,
            searchable: false,
            sortable: true,
          },
          {
            key: 'planeView.id',
            label: '平面図枚数',
            publicText: this.planeViewCheck,
            searchable: false,
            sortable: true,
          },
        ],
        noDataImage: require('../../images/no-image.png'),
        roomDeleteData: {
          item: { roomName: null },
        },
        hideDetailFlag: true,
        hideDeleteFlag: true,
        getPropertyData: null,
        addRoomName: null,
      };
    },
    mounted: async function() {
      // MOUNTED: リストヘッダー設定
      if (
        this.$route.path == '/Property/new/WallPaperSimulator/Edit/new' ||
        this.modalFlag == true
      ) {
        this.headers.push({
          key: 'select',
          label: '選択',
          searchable: false,
          button: { label: '選択', event: 'selectClicked' },
        });
        this.headers.push({
          key: 'createRoom',
          label: '部屋情報追加',
          searchable: false,
          button: { label: '追加', event: 'createRoomClicked' },
          details: false,
        });
      } else {
        this.headers.push({
          key: 'detail',
          label: '詳細',
          thClass: 'header-item',
          searchable: false,
          button: { label: '選択' },
        });
        this.headers.push({
          key: 'createRoom',
          label: '部屋情報追加',
          searchable: false,
          button: { label: '追加', event: 'createRoomClicked' },
          details: false, //部屋情報には、ボタンを表示しない
        });
        this.headers.push({
          key: 'delete',
          label: '削除',
          thClass: 'header-item',
          searchable: false,
          button: { label: '選択' },
        });
      }
    },
    mixins: [roomFunction],
    methods: {
      /*
       * METHOD: 物件情報を削除
       */
      deleteProperty: async function(body) {
        try {
          // 物件情報を削除
          await API.graphql(
            graphqlOperation(deleteProperty, {
              input: { id: body.id },
            })
          );
        } catch (e) {
          alert('物件情報の削除に失敗しました。');
        }

        this.$refs.pagination.listItems();
      },
      /*
       * METHOD: 物件情報詳細
       */
      detailProperty: function(body) {
        this.$router.push({
          path: '/Property/View/' + body.id,
        });
      },

      /*===============================================
       * METHOD: フォトシミュレーターのシミュレート枚数を設定する。
       */
      photoSimCheck: async function(e) {
        let getPhotoList = await this.getPhotoList(e);

        if (e.simInfo == null) {
          return getPhotoList.length + '枚';
        }
        return e.simInfo.length + '枚';
      },
      /*
       * METHOD: フォトシミュレート情報を取得する
       */
      getPhotoList: async function(e) {
        //フォトシミュの取得条件
        const queryVar = {
          limit: 60,
          company: await this.getCompanyId(),
          filter: {
            propertyId: {
              eq: e.id,
            },
            roomId: {
              eq: e.roomId,
            },
          },
        };
        if (e.roomId == null) {
          delete queryVar.filter.roomId;
        }
        var query = [];
        queryVar.nextToken = null;
        //フォトの枚数を数える。
        do {
          let items = await API.graphql(graphqlOperation(listPhotoSimulators, queryVar));
          query = [...query, ...items.data.listPhotoSimulators.items];
          queryVar.nextToken = items.data.listPhotoSimulators.nextToken;
        } while (queryVar.nextToken != null);
        if (e.roomId == null) query = query.filter(val => val.roomId == null);
        return query;
      },
      /**
       * METHOD: 平面図の保存数を取得する
       */
      getGroundFloorList: async function(e) {
        //平面図の取得条件
        const queryVar = {
          limit: 60,
          companyId: await this.getCompanyId(),
          filter: {
            companyId: {
              eq: await this.getCompanyId(),
            },
            roomId: { eq: e.roomId },
            propertyId: {
              eq: e.id,
            },
            deleted: { eq: false },
          },
        };
        if (e.roomId == null) {
          queryVar.filter.roomId = { attributeExists: false };
        }
        queryVar.nextToken = null;

        var query = [];
        //平面図の枚数を数える。
        do {
          let items = await API.graphql(graphqlOperation(listPlaneViews, queryVar));
          query = [...query, ...items.data.listPlaneViews.items];
          queryVar.nextToken = items.data.listPlaneViews.nextToken;
        } while (queryVar.nextToken != null);
        return query;
      },
      /*
       * METHOD: 平面図の作成枚数を表示する。
       */
      planeViewCheck: async function(e) {
        let getplaneView = await this.getGroundFloorList(e);
        return getplaneView.length + '枚';
      },
      /*
       * METHOD: imageViewing
       */
      imageViewing: async function(e) {
        return await this.getImageViewing(e.id, null);
      },
      /*===============================================
       * METHOD: 物件情報のイメージURLを取得する。
       */
      getImageViewing: async function(propertyId, roomId) {
        let params = {
          fileName: '',
          updateDate: new Date(0),
          directory: '',
        };

        // 平面図情報を取得する
        let groundFloorData = await this.getGroundFloorList({ id: propertyId, roomId: roomId });
        for (let groundFloor of groundFloorData) {
          let currentUpdateDate = new Date(groundFloor.updateDate);
          if (currentUpdateDate.getTime() > params.updateDate.getTime()) {
            params.fileName = groundFloor.imageName;
            params.updateDate = currentUpdateDate;
            params.directory = 'planeView/images';
          }
        }

        //フォトシミュ情報を取得する
        let photoData = [];
        try {
          photoData = await this.getPhotoList({ id: propertyId, roomId: roomId });
        } catch (er) {
          console.error(er);
        }
        if (photoData != null) {
          //最新の日付のシミュor平面図の画像を取得。
          photoData.forEach(simData => {
            let val = simData.simInfo;
            let currentUpdateDate = new Date(simData.updatedAt);
            if (currentUpdateDate.getTime() > params.updateDate.getTime()) {
              //パターン情報がないときはベース画僧を表示する
              if (val.selectPattern == null) {
                params.fileName = val.baseImageFileName;
                params.updateDate = currentUpdateDate;
                params.directory = 'simulation/baseImage';
              } else if (val.savePeten.find(value => value.id == val.selectPattern) == null) {
                params.fileName = val.baseImageFileName;
                params.updateDate = currentUpdateDate;
                params.directory = 'simulation/baseImage';
              } else {
                let patternId = val.savePeten.find(value => value.id == val.selectPattern).id;
                if (patternId == null) {
                  params.fileName = val.baseImageFileName;
                  params.updateDate = currentUpdateDate;
                  params.directory = 'simulation/baseImage';
                } else {
                  //最新の素材適用画像を取得する
                  params.fileName =
                    val.baseImageFileName.replace(/\.[^/.]+$/, '') + '_' + patternId + '.png';
                  params.updateDate = currentUpdateDate;
                  params.directory = 'simulation/thumbnailImage';
                }
              }
            }
          });
        }

        if (params.directory === '') {
          return this.noDataImage;
        }
        //画像をS3から呼び出す。
        let links = await API.graphql(
          graphqlOperation(getS3Url, {
            level: 'public',
            file: {
              fileName: params.fileName,
              fileAction: 'View',
              propertyDirectory: {
                propertyId: propertyId,
                fileDirectory: params.directory,
              },
            },
          })
        );

        return links.data.getS3Url[0].fileUrl;
      },

      /*===============================================
       * METHOD: 会社IDを取得する。
       */
      getCompanyId: async function() {
        //アカウントから会社ID取得
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },
      /**
       * METHOD: 一覧に表示された子要素に表示or処理する
       */
      deployment: async function(item, itemIndex, propertyId) {
        let header = this.headers[itemIndex];
        if (this.hideDeleteFlag == undefined) {
          if (itemIndex == this.headers.length) header = { key: 'detail' };
          if (itemIndex == this.headers.length + 1) header = { key: 'delete' };
        }
        let str = '';
        if (header.key == 'simInfo') {
          let imageUrl = await this.getImageViewing(propertyId, item.id);
          str = '<img class="roomImage" src="' + imageUrl + '" >';
        }

        //物件名に部屋番号を表示する
        if (header.key == 'roomNumber') str = item.roomName;

        //シミュレーター枚数
        if (header.key == 'simInfo.id') {
          let getPhotoList = [];
          try {
            getPhotoList = await this.getPhotoList({ id: propertyId, roomId: item.id });
            str = getPhotoList.length + '枚';
          } catch (e) {
            console.error(e);
          }
        }

        //平面図枚数
        if (header.key == 'planeView.id') {
          let getGroundFloorList = await this.getGroundFloorList({
            id: propertyId,
            roomId: item.id,
          });
          str = getGroundFloorList.length + '枚';
        }

        //部屋番号を選択する
        if (header.key == 'select') {
          this.selectRoomClicked(item, propertyId);
        }

        //詳細
        if (header.key == 'detail') {
          this.$router.push({
            path: '/Property/View/' + propertyId + '/RoomView/' + item.id + '?propertyList=true',
          });
        }

        //削除
        if (header.key == 'delete') {
          this.deleteRoom(item, propertyId);
        }

        return str;
      },
      /**
       * METHOD: 部屋削除モーダル表示
       */
      deleteRoom: async function(item, propertyId) {
        this.roomDeleteData = {
          item: item,
          propertyId: propertyId,
        };
        this.$bvModal.show('roomDelete');
      },
      /**
       * METHOD: 部屋情報を削除する。
       */
      deleteRoomEvent: async function() {
        try {
          let result = await API.graphql(
            graphqlOperation(getProperty, {
              company: await this.getCompanyId(),
              id: this.roomDeleteData.propertyId,
            })
          );
          let property = result.data.getProperty;
          property.roomInfo = property.roomInfo.filter(
            val => val.id != this.roomDeleteData.item.id
          );

          delete property.createdAt;
          delete property.updatedAt;

          await API.graphql({
            query: updateProperty,
            variables: { input: property },
          });

          this.$refs.pagination.listItems();
        } catch (e) {
          console.error(e);
        }
      },
      /**
       * METHOD: 物件情報選択時の処理
       */
      selectClicked(e) {
        this.$emit('selectClicked', e);
      },
      /**
       * METHOD: 部屋情報選択の処理
       */
      selectRoomClicked(item, propertyId) {
        this.$emit('selectRoomClicked', item, propertyId);
      },
      /**
       * METHOD: 新たな部屋を追加するためのモーダルを起動する
       * @param {*} e //追加ボタンを押した物件情報
       */
      createRoomClicked(e) {
        //部屋情報の登録件数を超えたときエラー出力する
        if (e.roomInfo.length <= this.getMaxRoom()) {
          this.getPropertyData = e;
          this.addRoomName = null;
          this.$bvModal.show('room-create');
        } else {
          this.showError('E0002');
        }
      },
      /**
       * METHOD: 新たな部屋情報を追加する
       */
      createRoomEvent: async function() {
        this.$refs.addRoomValidation.validate().then(async propertyValid => {
          if (propertyValid) {
            let roomInfo = await this.addRoom(this.getPropertyData.roomInfo, this.addRoomName);

            //部屋情報の登録上限を超えていない、その他エラーがない場合
            if (roomInfo.status == 'success') {
              this.$bvModal.hide('room-create');
              let updataPropertyData = {
                id: this.getPropertyData.id,
                roomInfo: roomInfo.data,
              };

              //物件情報更新（部屋情報追加）
              try {
                await API.graphql(
                  graphqlOperation(updateProperty, {
                    input: updataPropertyData,
                  })
                );
              } catch (e) {
                console.error(e);
                return;
              }

              //追加したルーム情報を表示する。
              let addRoomView = {
                roomId: roomInfo.addRoomId,
                addRoomData: [
                  {
                    key: 'simInfo',
                    str: '<img class="roomImage" src="' + this.noDataImage + '" >',
                  },
                  {
                    key: 'roomNumber',
                    str: this.addRoomName,
                  },
                  {
                    key: 'simInfo.id',
                    str: '0枚',
                  },
                  {
                    key: 'planeView.id',
                    str: '0枚',
                  },
                ],
              };
              //一覧に追加された部屋情報のカラムに文言をセットする
              this.$refs.pagination.setTextRoomLoading(addRoomView);
            }
          }
        });
      },
    },
    // ページング処理コンポーネント呼び出し
    components: {},
  };
</script>

<style>
  html {
    overflow-y: scroll;
  }

  .table td img.roomImage {
    max-width: 150px;
    max-height: 73px;
    width: auto;
    height: auto;
    border-radius: 10%;
  }
</style>
