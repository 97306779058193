<template>
  <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
        <div class="row flex-grow">
          <div class="col-lg-6 d-flex align-items-center justify-content-center">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5 col-8">
              <div class="brand-logo">
                <logo :visible="true" color="black" />
              </div>
              <authenticator></authenticator>
            </div>
          </div>
          <div class="col-lg-13 allyLoginImage d-flex flex-row">
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import logo from '../../components/Common/Logo';
  import authenticator from './Login/Authenticator';

  export default {
    components: {
      logo,
      authenticator,
    },
  };
</script>

<style scoped>
  .ally-logo {
    fill: black;
    width: 100%;
    padding: 5%;
  }

  * {
    margin: 0;
    padding: 0;
  }

  ul,
  li {
    list-style: none;
  }

  .allyLoginImage {
    height: 100%;
    overflow: hidden;
  }

  .allyLoginImage ul {
    height: 100%;
  }

  .allyLoginImage li {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    animation: fade 16s infinite;
  }

  .allyLoginImage li:nth-child(1) {
    background-size: cover;
    background-image: url('../../images/login/login-bg-1.jpg');
    animation-delay: 0s;
  }
  .allyLoginImage li:nth-child(2) {
    background-size: cover;
    background-image: url('../../images/login/login-bg-2.jpg');
    animation-delay: 4s;
  }
  .allyLoginImage li:nth-child(3) {
    background-size: cover;
    background-image: url('../../images/login/login-bg-3.jpg');
    animation-delay: 8s;
  }
  .allyLoginImage li:nth-child(4) {
    background-size: cover;
    background-image: url('../../images/login/login-bg-4.jpg');
    animation-delay: 12s;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    30% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
</style>
