<!--
作成者：Lee HyeonSeok
作成日：2020.6.3
画面名：法人顧客用フォーム
機能：法人顧客用フォーム
親子関係：View.vue
更新者：
①
更新内容：
①
-->
<template>
  <div class="row">
    <div class="col-md-5">
      <div class="row">
        <allyText
          class="col-12"
          title="顧客名"
          name="customerName"
          v-model="customerName"
          :mode="mode"
          rules="required"
          placeholder="顧客名"
          optionalOutput
        />
      </div>
      <div class="row">
        <allyText
          class="col-12"
          title="部署名"
          name="department"
          v-model="department"
          :mode="mode"
          placeholder="部署名"
        />
      </div>
      <div class="row">
        <allyText
          class="col-12"
          title="電話番号"
          name="tel"
          v-model="tel"
          :mode="mode"
          rules="aorbrequired:@携帯番号|tel"
          placeholder="0196000000"
          optionalOutput
        />
      </div>
      <div class="row">
        <allyText
          class="col-12"
          title="携帯番号"
          name="phone"
          v-model="phone"
          :mode="mode"
          rules="aorbrequired:@電話番号|tel"
          placeholder="09000000000"
          optionalOutput
        />
      </div>
      <div class="row">
        <allyText
          class="col-12"
          title="FAX番号"
          name="fax"
          v-model="fax"
          :mode="mode"
          rules="tel"
          placeholder="0196000000"
        />
      </div>
      <div class="row">
        <allyText
          class="col-12"
          title="E-mail"
          name="mail"
          v-model="mail"
          :mode="mode"
          rules="email"
          placeholder="xxx@xxx.xx.jp"
        />
      </div>
      <allyAddress v-model="address" :mode="mode" rules="required" optionalOutput />
    </div>
    <div class="col-md-2">
      <div class="col-6" style="border-right: 1px solid rgb(154, 154, 154); height: 100%"></div>
    </div>
    <div class="col-md-5">
      <div class="row">
        <label class="col-12 font-weight-bold">
          担当者
          <hr class="ally-divide" />
        </label>
      </div>
      <div class="row">
        <allyText
          class="col-6"
          title="姓"
          name="lastName"
          v-model="lastName"
          :mode="mode"
          rules="required"
          placeholder="新井"
          optionalOutput
        />
        <allyText
          class="col-6"
          title="名"
          name="firstName"
          v-model="firstName"
          :mode="mode"
          rules="required"
          placeholder="太郎"
          optionalOutput
        />
      </div>
      <div class="row">
        <allyText
          class="col-6"
          title="姓（カナ）"
          name="lastNameKana"
          v-model="lastNameKana"
          :mode="mode"
          rules="required|onlykatakana"
          placeholder="アライ"
          optionalOutput
        />
        <allyText
          class="col-6"
          title="名（カナ）"
          name="firstNameKana"
          v-model="firstNameKana"
          :mode="mode"
          rules="required|onlykatakana"
          placeholder="タロウ"
          optionalOutput
        />
      </div>
      <div class="row">
        <allyRadio
          class="col-12"
          title="性別"
          name="gender"
          :items="[
            { text: '男', value: 1 },
            { text: '女', value: 2 },
          ]"
          v-model="gender"
          :mode="mode"
          rules="required"
        />
      </div>
      <businessCard
        :originBusinessCard="businessCard"
        :originCompanyInfo="companyInfo"
        :mode="mode"
        @input="updatebusinessCardInfo => setBusinessInfo(updatebusinessCardInfo)"
        ref="businessCard"
      />
    </div>
  </div>
</template>

<script>
  import businessCard from '../../components/Customer/businessCard';

  export default {
    props: ['mode', 'value', 'inputType'],
    data: function() {
      this.companyInfoModalClear();
      return this.value;
    },
    methods: {
      setBusinessInfo: function(result) {
        this.businessCard = result.updateBusinessCardMap;
        this.companyInfo = result.updateBusinessCard;
      },
      companyInfoModalClear: function() {
        this.companyInfoModal = {
          companyName: null,
          position: null,
          tel: null,
          mail: null,
          fax: null,
        };
      },
      fileUpload: async function() {
        await this.$refs.businessCard.fileUpload();
      },
    },
    components: {
      businessCard,
    },
  };
</script>

<style scoped>
  .ally-divide {
    border: solid 1px black;
  }
</style>
