<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：初期投資/付帯費用/印紙税
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="StampDuty">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button id="BtnStampDuty" variant="link" v-b-toggle.tabStampDuty>
          印紙税
        </b-button>
      </b-card-header>
      <b-collapse id="tabStampDuty" role="tabpanel">
        <b-card-body>
          <b-tabs
            fill
            pills
            v-model="investmentAnalytics.initialInvestment.isDetailStampDuty"
            @input="changeTab"
          >
            <b-tab title="概算入力">
              <b-container>
                <b-row>
                  <b-col class="title bold">総額</b-col>
                  <b-col>
                    <b-row>
                      <b-col class="component">
                        <allyText
                          name="StampDutyTotal"
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="investmentAnalytics.initialInvestment.estimatedStampDuty"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="詳細入力">
              <b-container class="detail">
                <b-row>
                  <b-col class="title bold">項目</b-col>
                  <b-col class="title-center bold">
                    <b-row style="padding:0">
                      <b-col>
                        契約金額
                      </b-col>
                      <b-col cols="2" />
                    </b-row>
                  </b-col>
                  <b-col class="title-center bold">
                    <b-row style="padding:0">
                      <b-col>
                        印紙税額
                      </b-col>
                      <b-col cols="2" />
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="title-center" style="padding:0;">
                    変更
                  </b-col>
                </b-row>
                <b-row v-for="id in stampDuty.length" v-bind:key="`stampDuty-${id}`">
                  <b-col class="title" v-if="id != 5">
                    {{ stampDuty[id - 1].title }}
                  </b-col>
                  <b-col v-else>
                    <span>
                      {{ stampDuty[4].title }}
                    </span>
                    <span
                      style="cursor: help;"
                      v-b-popover.hover.bottomleft="
                        '印紙税までの事業費合計に' + leverage + '倍した金額を表示しています。'
                      "
                    >
                      <u>(?)</u>
                    </span>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="contractAmount(id - 1) | currency"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="getStampDuty(contractAmount(id - 1), id - 1) | currency"
                          v-if="!investmentAnalytics.initialInvestment.isChangedStampDuty[id - 1]"
                        />
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="investmentAnalytics.initialInvestment.stampDuty[id - 1]"
                          roundType="down"
                          v-else
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="checkbox">
                    <b-form-checkbox
                      :id="`stampDutyCheck_${id}`"
                      v-model="investmentAnalytics.initialInvestment.isChangedStampDuty[id - 1]"
                      :name="`stampDutyCheck_${id}`"
                      @change="changeCheckBox(id - 1)"
                      size="lg"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Calculation from './../Calculation';
  export default {
    mixins: [Calculation],
    data: function() {
      return {
        // 印紙税
        stampDuty: [
          { title: '売買契約' },
          { title: '工事契約' },
          { title: 'コンサル契約' },
          { title: '設計監理契約' },
          { title: '金銭消費貸借契約' },
          { title: '賃貸借契約' },
        ],
      };
    },
    methods: {
      // タブ変更時
      changeTab: function(index) {
        // 詳細入力時
        if (index == 1) {
          // 各項目
          this.investmentAnalytics.initialInvestment.stampDuty.forEach((v, i) => {
            // 未変更時
            if (!this.investmentAnalytics.initialInvestment.isChangedStampDuty[i]) {
              // 計算値設定
              this.$set(
                this.investmentAnalytics.initialInvestment.stampDuty,
                i,
                this.getStampDuty(this.contractAmount(i), i)
              );
            }
          });
        }
      },
      // チェックボックス変更時
      changeCheckBox: function(id) {
        // 初期値設定
        if (!this.investmentAnalytics.initialInvestment.isChangedStampDuty[id]) {
          this.$set(
            this.investmentAnalytics.initialInvestment.stampDuty,
            id,
            this.getStampDuty(this.contractAmount(id), id)
          );
        }
      },
    },
  };
</script>
<style scoped>
  #StampDuty .detail .card-body {
    padding-top: 0;
    padding-bottom: 8px;
  }
  #StampDuty .checkbox {
    display: grid;
    text-align: center;
    align-items: center;
    padding-left: 20px;
  }
</style>
