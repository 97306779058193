import { PvObject } from './pvObject';

/**
 *  CLASS: エディタ上に表示されるオブジェクトのデータ
 */
export class PvPattern {
  /** @type {string}  PROPERTY: パターン名 */
  name = 'パターンA';
  /** @type {PvObject[][]}  PROPERTY: 階層ごとのオブジェクト */
  layers = [[]];

  /**
   * @param {{
   * name: string,
   * layers: Partial<PvObject>[][],
   * }} param
   */
  constructor(param) {
    this.name = param.name;
    this.layers = param.layers;
  }
}
