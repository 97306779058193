/**
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：計算式(出力用)
親子関係：
更新者：
①
更新内容：
①
**/
import Calculation from '../Input/Calculation';
export default {
  mixins: [Calculation],
  computed: {
    // 年間賃料合計 ※売却年まで
    annualRentTotal: function() {
      return this.calcChange({
        value: this.rentTotal * 12, // 単年度収支計画/収益の部/賃料/合計×12
        years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)), // 売却年
        term: Number(this.getRateOfIncrease('据え置き期間')),
        period: Number(this.getRateOfIncrease('賃料-変動周期')),
        factor: Number(this.getRateOfIncrease('賃料-変動係数')),
      });
    },
    // 年間共益費合計 ※売却年まで
    annualCommonServiceFeesTotal: function() {
      return this.calcChange({
        value: this.commonServiceFeesTotal * 12, // 単年度収支計画/収益の部/共益費/合計×12
        years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)), // 売却年
        term: this.getRateOfIncrease('据え置き期間'),
        period: this.getRateOfIncrease('賃料-変動周期'),
        factor: this.getRateOfIncrease('賃料-変動係数'),
      });
    },
    // 年間駐車料合計 ※売却年まで
    annualParkingFeesTotal: function() {
      return this.calcChange({
        value: this.parkingFeesTotal * 12, // 単年度収支計画/収益の部/駐車料/合計×12
        years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)), // 売却年
        term: this.getRateOfIncrease('据え置き期間'),
        period: this.getRateOfIncrease('賃料-変動周期'),
        factor: this.getRateOfIncrease('賃料-変動係数'),
      });
    },
    // 年間土地賃貸料合計 ※売却年まで
    annualLandRentalFeesTotal: function() {
      return this.calcChange({
        value: this.landRentalFeesTotal * 12, // 単年度収支計画/収益の部/土地賃貸料/合計×12
        years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)), // 売却年
        term: this.getRateOfIncrease('据え置き期間'),
        period: this.getRateOfIncrease('賃料-変動周期'),
        factor: this.getRateOfIncrease('賃料-変動係数'),
      });
    },
    // 年間その他合計 ※売却年まで
    annualOthersTotal: function() {
      return this.calcChange({
        value: this.othersTotal * 12, // 単年度収支計画/収益の部/その他/合計×12
        years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)), // 売却年
        term: this.getRateOfIncrease('据え置き期間'),
        period: this.getRateOfIncrease('賃料-変動周期'),
        factor: this.getRateOfIncrease('賃料-変動係数'),
      });
    },
    // 空室損失 ※売却年まで
    vacancyLosses: function() {
      const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
      let ratio = this.getRateOfIncrease('空室率-当初'); // 空室率-当初
      const ratioYearOnwards = this.getRateOfIncrease('空室率-変更年数以降'); // 空室率-変更年数以降
      const DesignatedYears =  Math.floor(this.getRateOfIncrease('空室率-変更年数')); // 空室率-変更年数
      const rent = this.annualRentTotal; // 賃料
      const comm = this.annualCommonServiceFeesTotal; // 共益費
      const park = this.annualParkingFeesTotal; // 駐車料
      const land = this.annualLandRentalFeesTotal; // 土地賃借料
      const othr = this.annualOthersTotal; // その他
      let result = [];
      for (let i = 0; i < years; i++) {
        // 変更年数以降の空室率を置き換え
        if(i >= (DesignatedYears - 1)){
          ratio = ratioYearOnwards;
        }
        // －(賃料＋共益費＋駐車料＋土地賃借料＋その他)×空室率
        let tmp = rent[i] + comm[i] + park[i] + land[i] + othr[i];
        result.push((-1 * (tmp * ratio)) / 100);
      }
      return result;
    },
    // 固定資産税（土地） ※売却年まで
    propertyTaxLand: function() {
      return this.calcChange({
        value: this.investmentAnalytics.initialInvestment.propertyTax[0], // 単年度収支計画/費用の部/固定資産税/土地
        years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)), // 売却年
        term: this.getRateOfIncrease('据え置き期間'),
        period: this.getRateOfIncrease('固都税-土地-変動周期'),
        factor: this.getRateOfIncrease('固都税-土地-変動係数'),
      });
    },
    // 固定資産税（既存建物） ※売却年まで
    propertyTaxBuildings: function() {
      const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
      // 既存建物分
      let result = new Array(years).fill(0);
      for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
        const structure = this.investmentAnalytics.initialInvestment.propertyDescription[i + 1]
          .structure;
        let calc = this.calcChange({
          value: this.investmentAnalytics.initialInvestment.propertyTax[i + 1], // 単年度収支計画/支出の部/固定資産税・都市計画税/既存建物
          years: years,
          term: this.getRateOfIncrease('据え置き期間'),
          period: this.getRateOfIncrease('固都税-建物-' + structure + '-変動周期'),
          factor: this.getRateOfIncrease('固都税-建物-' + structure + '-変動係数'),
        });
        for (let j = 0; j < years; j++) {
          result[j] += calc[j];
        }
      }
      return result;
    },
    // 固定資産税（新築建物） ※売却年まで
    propertyTaxNewBuildings: function() {
      const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
      // 新築建物分
      let result = new Array(years).fill(0);
      for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
        const structure = this.investmentAnalytics.initialInvestment.propertyDescription[i + 11]
          .structure;
        let calc = this.calcChange({
          value: this.investmentAnalytics.initialInvestment.propertyTax[i + 11], // 単年度収支計画/支出の部/固定資産税・都市計画税/新築建物
          years: years,
          term: this.getRateOfIncrease('据え置き期間'),
          period: this.getRateOfIncrease('固都税-建物-' + structure + '-変動周期'),
          factor: this.getRateOfIncrease('固都税-建物-' + structure + '-変動係数'),
        });
        for (let j = 0; j < years; j++) {
          result[j] += calc[j];
        }
      }
      return result;
    },
    // 固定資産税（付属建物） ※売却年まで
    propertyTaxAttachedBuilding: function() {
      const structure = this.investmentAnalytics.initialInvestment.propertyDescription[21]
        .structure;
      return this.calcChange({
        value: this.investmentAnalytics.initialInvestment.propertyTax[21], // 初期投資/物件概要/固定資産税/付属建物
        years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)), // 売却年
        term: this.getRateOfIncrease('据え置き期間'),
        period: this.getRateOfIncrease('固都税-建物-' + structure + '-変動周期'),
        factor: this.getRateOfIncrease('固都税-建物-' + structure + '-変動係数'),
      });
    },
    // 固定資産税（償却資産） ※売却年まで
    propertyTaxDepreciableAssets: function() {
      const structure = this.investmentAnalytics.initialInvestment.propertyDescription[22]
        .structure;
      return this.calcChange({
        value: this.investmentAnalytics.initialInvestment.propertyTax[22], // 初期投資/物件概要/固定資産税/償却資産
        years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)), // 売却年
        term: this.getRateOfIncrease('据え置き期間'),
        period: this.getRateOfIncrease('固都税-建物-' + structure + '-変動周期'),
        factor: this.getRateOfIncrease('固都税-建物-' + structure + '-変動係数'),
      });
    },
    // 都市計画税（土地） ※売却年まで
    cityPlanningTaxLand: function() {
      return this.calcChange({
        value: this.investmentAnalytics.initialInvestment.cityPlanningTax[0], // 単年度収支計画/費用の部/都市計画税/土地
        years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)), // 売却年
        term: this.getRateOfIncrease('据え置き期間'),
        period: this.getRateOfIncrease('固都税-土地-変動周期'),
        factor: this.getRateOfIncrease('固都税-土地-変動係数'),
      });
    },
    // 都市計画税（既存建物） ※売却年まで
    cityPlanningTaxBuildings: function() {
      const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
      // 既存建物分
      let result = new Array(years).fill(0);
      for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
        const structure = this.investmentAnalytics.initialInvestment.propertyDescription[i + 1]
          .structure;
        let calc = this.calcChange({
          value: this.investmentAnalytics.initialInvestment.cityPlanningTax[i + 1], // 単年度収支計画/支出の部/固定資産税・都市計画税/既存建物
          years: years,
          term: this.getRateOfIncrease('据え置き期間'),
          period: this.getRateOfIncrease('固都税-建物-' + structure + '-変動周期'),
          factor: this.getRateOfIncrease('固都税-建物-' + structure + '-変動係数'),
        });
        for (let j = 0; j < years; j++) {
          result[j] += calc[j];
        }
      }
      return result;
    }, // 都市計画税（新築建物） ※売却年まで
    cityPlanningTaxNewBuildings: function() {
      const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
      // 新築建物分
      let result = new Array(years).fill(0);
      for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
        const structure = this.investmentAnalytics.initialInvestment.propertyDescription[i + 11]
          .structure;
        let calc = this.calcChange({
          value: this.investmentAnalytics.initialInvestment.cityPlanningTax[i + 11], // 単年度収支計画/支出の部/固定資産税・都市計画税/新築建物
          years: years,
          term: this.getRateOfIncrease('据え置き期間'),
          period: this.getRateOfIncrease('固都税-建物-' + structure + '-変動周期'),
          factor: this.getRateOfIncrease('固都税-建物-' + structure + '-変動係数'),
        });
        for (let j = 0; j < years; j++) {
          result[j] += calc[j];
        }
      }
      return result;
    },
    // 都市計画税（付属建物） ※売却年まで
    cityPlanningTaxAttachedBuilding: function() {
      return this.calcChange({
        value: this.investmentAnalytics.initialInvestment.cityPlanningTax[21], // 単年度収支計画/費用の部/都市計画税/付属建物
        years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)), // 売却年
        term: this.getRateOfIncrease('据え置き期間'),
        period: this.getRateOfIncrease('固都税-土地-変動周期'),
        factor: this.getRateOfIncrease('固都税-土地-変動係数'),
      });
    },
    // 建物借入金利息 ※返済期間まで
    interestOnLoans: function() {
      let result = [];
      if (this.investmentAnalyticsTemp.loanCalculationResults != null) {
        this.investmentAnalyticsTemp.loanCalculationResults.forEach((v, i) => {
          // 返済金額－ローン残高の差分
          result.push(
            v.amount -
              ((i == 0
                ? this.investmentAnalytics.initialInvestment.capitalPlan.loans // 初年度は借入金額
                : this.investmentAnalyticsTemp.loanCalculationResults[i - 1].balance) - // 以降は前年のローン残高
                v.balance) // 当年のローン残高
          );
        });
      }
      return result;
    },
    // 維持費 ※売却年まで
    // 引数：維持費
    maintenanceCosts: function() {
      return function(amount) {
        return this.calcChange({
          value: amount,
          years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)), // 売却年
          term: this.getRateOfIncrease('据え置き期間'),
          period: this.getRateOfIncrease('維持費-変動周期'),
          factor: this.getRateOfIncrease('維持費-変動係数'),
        });
      };
    },
    // 大規模改修 ※売却年まで
    longTermRepairPlanTotals: function() {
      // 年度毎の合計を取得
      let total = this.investmentAnalyticsTemp.longTermRepairPlanTotal;
      return total;
    },
    // 償還金 ※償還期間まで
    // 引数：金額
    redemptions: function() {
      return function(amount) {
        let result = [];
        // 償還期間
        let period = Number(
          this.investmentAnalytics.initialInvestment.capitalPlan.redemptionPeriod
        );
        // 償還期間で割る
        for (let i = 0; i < period; i++) {
          result.push(amount / period);
        }
        return result;
      };
    },
    // 償還金 ※償還期間満期時に一括返済の場合
    // 引数：金額
    redemptionsDeposit: function() {
      return function(amount) {
        let result = [];
        // 償還期間
        let period = Number(
          this.investmentAnalytics.initialInvestment.capitalPlan.redemptionPeriod
        );
        // 償還期間満期時に全額
        for (let i = 0; i < period; i++) {
          if(period == (i + 1)){
            result.push(amount);
          }else{
            result.push(0);
          }
        }
        return result;
      };
    },
    // 消費税額合計 ※売却年まで
    consumptionTaxTotals: function() {
      // 月収入
      const amount = this.monthlyIncome; // 空室率-当初
      const amountAfter = this.monthlyIncomeAfter; // 空室率-変更年数以降
      const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
      const DesignatedYears =  Math.floor(Number(this.getRateOfIncrease('空室率-変更年数')));

      // 変動計算
      // 空室率変更前
      let result = this.calcChange({
        value: amount * 12, // 月額合計×12
        years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)), // 売却年
        term: this.getRateOfIncrease('据え置き期間'),
        period: this.getRateOfIncrease('賃料-変動周期'),
        factor: this.getRateOfIncrease('賃料-変動係数'),
      });
      // 空室率変更後
      let resultAfter = this.calcChange({
        value: amountAfter * 12, // 月額合計×12
        years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)), // 売却年
        term: this.getRateOfIncrease('据え置き期間'),
        period: this.getRateOfIncrease('賃料-変動周期'),
        factor: this.getRateOfIncrease('賃料-変動係数'),
      });

      // 変更年数以降の分を置き換え
      for (let i = 0; i < years; i++) {
        if(i >= (DesignatedYears - 1)){
          result[i] = resultAfter[i];
          }
        }

      // 消費税額に置換
      if (result != null) {
        result.forEach((v, i) => {
          result[i] = this.tax(v);
        });
      }
      return result;
    },
    // 売却益 ※売却年まで
    gainOnSales: function() {
      const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
      let result = Array(years).fill(null);
      // 売却益/売却益(税引後) ※売却年度のみ
      result[years - 1] = Number(this.investmentAnalyticsTemp.gainOnSaleAfterTax);
      return result;
    },
    // 減価償却費 ※耐用年数まで
    // 引数：建物種別(0:既存、1:新規、2:付属建物)、建物index(0～9)
    depreciationExpenses: function() {
      return function(type, index = 0) {
        const year = this.applicableServiceLife(type, index); // 適用耐用年数
        const rate = this.getDepreciationRate(year, 1); // 定額法-償却率
        const amount = this.acquisitionPrice(type, index); // 取得価格
        // 取得価格×償却率を年数分設定
        // 厳密には耐用最終年は-1円？ https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/2106.htm
        return Array(year).fill(amount * rate); // 厳密には耐用最終年は-1円
      };
    },
    // 管理料 ※売却年まで
    // 引数：収入 ※売却年まで
    managementFees: function() {
      return function(income) {
        let result = [];
        const value = Number(
          this.investmentAnalytics.singleYearPlan.spendingPlan.managementFeeValue
        );
        const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
        const gainOnSales = this.gainOnSales; // 売却益(税引後)
        switch (Number(this.investmentAnalytics.singleYearPlan.spendingPlan.managementFeeType)) {
          case 1: // 全体で、[NNN,NNN]円
            result = this.maintenanceCosts(Math.floor(value) * 12);
            break;
          case 2: // １室あたり、[NNN,NNN]円×室数
            result = this.maintenanceCosts(
              Math.floor(value) *
                Math.floor(
                  Number(this.investmentAnalytics.singleYearPlan.spendingPlan.numberOfRooms)
                ) *
                12
            );
            break;
          // 収入の、[NNN.NNN]％
          case 3:
            income.forEach((v, i) => {
              // 売却年の管理料計算は収入合計に売却益を含めない
              if (gainOnSales != undefined && i == (years - 1)) {
                // 収入合計 - 売却益(税引後)
                v -= gainOnSales[years -1];
              }
              result.push((v * Math.floor(value * 10)) / 10 / 100);
            });
            break;
        }
        return result;
      };
    },
    // 借入返済金 ※返済期間まで
    repayments: function() {
      let result = [];
      if (this.investmentAnalyticsTemp.loanCalculationResults != null) {
        this.investmentAnalyticsTemp.loanCalculationResults.forEach(v => {
          result.push(v.amount);
        });
      }
      return result;
    },
    // 各年の純収益（NOI） ※売却年まで
    noiPerYear: function() {
      const it = this.investmentAnalyticsTemp.incomeTotal; // 収入合計
      const ot = this.investmentAnalyticsTemp.opexTotal; // 運営費(OPEX)合計
      const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
      let result = [];
      if (it != undefined && ot != undefined) {
        for (let i = 0; i < years; i++) {
          // 収入合計－運営費(OPEX)合計
          result.push(it[i] - ot[i]);
        }
      }
      return result;
    },
    // 単年度現金収支（税引前現金収支） ※売却年まで
    cashFlowsBeforeTax: function() {
      const it = this.investmentAnalyticsTemp.incomeTotal; // 収入合計
      const et = this.investmentAnalyticsTemp.expenditureTotal; // 支出合計
      const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
      let result = [];
      if (it != undefined && et != undefined) {
        for (let i = 0; i < years; i++) {
          // 収入合計－支出合計 ※支出合計の配列の[0]は、初期投資のためインデックスを+1
          result.push(it[i] - et[i + 1]);
        }
      }
      return result;
    },
    // 累計
    // 引数：累計対象配列
    cumulativeTotals: function() {
      return function(values) {
        let result = [];
        if (values !== undefined) {
          values.forEach((v, i) => {
            result.push((i == 0 ? 0 : result[i - 1]) + v);
          });
        }
        return result;
      };
    },
    // 損益分岐入居率（BER） ※売却年まで
    breakEvenOccupancyRates: function() {
      const it = this.investmentAnalyticsTemp.incomeTotal; // 収入合計
      const et = [...this.investmentAnalyticsTemp.expenditureTotal]; // 支出合計
      const vl = this.vacancyLosses; // 空室損失
      let result = [];
      if (it != undefined && et != undefined && vl != undefined) {
        const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
        for (let i = 0; i < years; i++) {
          // 支出合計÷（収入合計－空室損失）×100
          if (it[i] - vl[i] == 0) {
            result.push(0);
          } else {
            result.push((et[i + 1] / (it[i] - vl[i])) * 100);
          }
        }
      }
      return result;
    },
    // 借入金残高 ※返済期間（または売却年）まで
    loansOutstanding: function() {
      const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
      const loanResults = this.investmentAnalyticsTemp.loanCalculationResults; // 借入返済金
      let result = [];
      if (loanResults != null) {
        for (let i = 0; i < years; i++) {
          if (i < loanResults.length) {
            result.push(loanResults[i].balance);
          }
        }
      }
      return result;
    },
    // 税引後現金収支 ※売却年まで
    cashFlowsAfterTax: function() {
      const income = this.cashFlowsBeforeTax; // 単年度現金収支（税引前現金収支）
      const tax = this.investmentAnalyticsTemp.incomeTaxAndInhabitantTax; // 賃貸事業に対する所得税・住民税
      let result = [];
      if (income != undefined && tax != undefined) {
        const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
        for (let i = 0; i < years; i++) {
          // 単年度現金収支－賃貸事業に対する所得税・住民税
          result.push(Number(income[i]) - Number(tax[i]));
        }
      }
      return result;
    },
    // 純利回り(FCR) ※売却年まで
    freeAndClearReturn: function() {
      let result = [];
      const cat = this.noiPerYear; // NOI
      if (cat != undefined) {
        const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
        for (let i = 0; i < years; i++) {
          // NOI ÷ 投資計画/総事業費 × 100
          // 投資計画/総事業費が0円の場合-∞％を表示しないようにする。※総事業費0円、借入金のみ入力の場合を想定
          if(this.totalProjectCost > 0){
            result.push((cat[i] / this.totalProjectCost) * 100);
          }
        }
      }
      return result;
    },
    // 自己資金配当率(CCR) ※売却年まで
    cashOnCashReturn: function() {
      let result = [];
      // 単年度現金収支（税引前現金収支）
      const cbt = this.cashFlowsBeforeTax;
      // 借入返済金
      const loanResults = this.investmentAnalyticsTemp.loanCalculationResults;
      // 自己資金
      const ownResources = Number(
        this.investmentAnalytics.initialInvestment.capitalPlan.ownResources
      );
      if (cbt != undefined && loanResults != null && ownResources > 0) {
        // 売却年
        const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale));
        for (let i = 0; i < years; i++) {
            // (単年度現金収支 ÷ 初期投資>資金計画>自己資金) * 100(%表示用)
            result.push(cbt[i] / ownResources * 100);
        }
      }

      return result;
    },
    // 債務回収率(DSCR) ※返済期間（または売却年）まで
    debtServiceCoverageRatio: function() {
      let result = [];
      const noi = this.noiPerYear; // NOI(純収益)
      const loanResults = this.investmentAnalyticsTemp.loanCalculationResults; // 借入返済金
      if (noi != undefined && loanResults != null) {
        const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
        for (let i = 0; i < years; i++) {
          if (i < loanResults.length) {
            // NOI ÷ 返済金額 * 100(%表示用)
            result.push(noi[i] / loanResults[i].amount * 100);
          }
        }
      }
      return result;
    },
  },
};
