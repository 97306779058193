<!--
作成者：Park Chongmyung
作成日：2020.6.12
画面名：不動産適正マスタ画面
機能：不動産適正のマスタ登録・修正・照会
親子関係：なし
更新者：Nakatsuka Daisuke
①
更新内容：一覧表示機能
①
-->

<template>
  <div>
    <div class="row grid-margin" v-if="mode != 'Edit'">
      <h4 class="font-weight-bold">不動産適正{{ mode == 'Edit' ? '変更' : '詳細' }}</h4>
    </div>
    <div>
      <div class="row">
        <allySelect
          v-if="!loading"
          class="col-8"
          title="分類"
          name="master"
          :items="selectItems"
          v-model="classificationDetail"
        />
      </div>
      <div class="row" v-if="mode == 'Edit'">
        <allySelect
          v-if="!loading"
          class="col-6"
          title="項目名"
          name="master"
          :items="categories"
          v-model="category"
        />
        <div class="col-4" v-if="category === '固定資産評価単価'">
          <div class="row">
            ※新築建物課税標準価格認定基準表が必要な場合は下記をご覧ください。
          </div>
          <div class="row">
            <a href="http://bell-com.biz/shiho-ninteikijun/" target="_blank"
              >新築建物課税標準価格認定基準表 一覧</a
            >
          </div>
        </div>
        <div class="col-4" v-if="category != '固定資産評価単価'"></div>
        <div class="col-2">
          <b-button
            variant="ally"
            class="my-2 mx-2 initMaster"
            v-if="mode == 'Edit'"
            @click="showInitMasterModal()"
            >初期化</b-button
          >
        </div>
      </div>
      <hr />
    </div>
    <div v-if="loading">
      <load />
      <div style="height: 150px;" />
    </div>
    <ValidationObserver slim ref="realEstateMaster" v-if="mode == 'Edit' ? category != null : true">
      <div class="master-table-header">
        <h4>{{ masterName }}</h4>
        <br />
        <div v-if="!isSystemMaster && mode == 'Edit'">
          御社のカスタムデータを使用しています。<br />
        </div>
      </div>
      <b-table
        ref="master"
        thead-tr-class="serch-data-table-header"
        tbody-tr-class="serch-data-table-body"
        hover
        striped
        :items="mode == 'Edit' ? dispItems : items"
        :fields="fields"
        :fixed="true"
        caption-top
        :sticky-header="mode == 'Edit' ? tableSize : false"
      >
        <!-- カテゴリ名(先頭要素)は編集不可 -->
        <template v-slot:cell(value0)="data"> {{ data.item.value0 }} </template>

        <template v-slot:cell()="data">
          <div v-show="false">
            {{
              /** 扱いやすいように変数保存 */
              (getEditData =
                getEditability == null
                  ? null
                  : getEditability.find(val => val.key == data.field.key))
            }}
            {{ (typeData = getEditData == null ? null : getEditData.type) }}
          </div>

          <!-- エディットモードではないのであれば、全て編集不可-->
          <span v-if="mode != 'Edit'"> {{ data.item[data.field.key] }} </span>

          <!-- エディットモードであれば編集を可能にする -->
          <span v-else>
            <span v-if="typeData == null">
              <allyText :title="getLabel(data.field.key)" :nonAppear="true" rules="required" v-model="data.item[data.field.key]" />
            </span>
            <span v-else>
              <allyText
                v-model="data.item[data.field.key]"
                type="number"
                class="number"
                :separation="true"
                halfWidth
              />
            </span>
          </span>

          <!-- editability -->
        </template>

        <!-- 削除ボタン('tail'要素) -->
        <template v-slot:cell(tail)="data">
          <b-button @click="deleteItem(data.item.value0, data.item.value1)">
            削除
          </b-button>
        </template>
      </b-table>
      <div class="row float-right">
        <b-button variant="ally" class="my-2 mx-2" v-if="mode == 'Edit'" @click="addItem()"
          >項目追加</b-button
        >
        <b-button variant="ally" class="my-2" v-if="mode == 'Edit'" @click="setSaveTarget()"
          >保存</b-button
        >
      </div>
    </ValidationObserver>
    <allyAlert code="E0001"></allyAlert>

    <b-modal
      id="saveMasterData"
      title="新規保存"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="変更"
      @ok="save"
    >
      マスタデータを変更します。<br />
      <div class="text-danger">変更すると他の計算結果に影響を与える可能性があります</div>
      よろしいですか？
    </b-modal>
    <b-modal
      id="initMasterData"
      title="マスタデータ初期化"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="初期化"
      @ok="initMaster"
    >
      マスタデータを初期化します。<br />
      <div class="text-danger">初期化すると他の計算結果に影響を与える可能性があります</div>
      よろしいですか？
    </b-modal>
    <b-modal
      id="finishInitMasterData"
      title="マスタデータ初期化完了"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-only
      ok-variant="light"
      ok-title="ok"
    >
      マスタデータを初期化しました。<br />
      反映したい場合はページの再読み込みをしてください。
    </b-modal>
    <allyAlert :code="'S100'" :title="'マスタデータ保存成功'"></allyAlert>
  </div>
</template>
<script>
  //aws
  import awsconfig from '../../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getSystemMaster, getCompanyMaster } from '../../../graphql/queries';
  import {
    createCompanyMaster,
    updateCompanyMaster,
    deleteCompanyMaster,
  } from '../../../graphql/mutations';

  //component
  import load from '../../../components/Common/Load';

  API.configure(awsconfig);

  export default {
    props: ['masterType', 'companyId', 'mode', 'propClassificationDetail'],
    data: function() {
      return {
        loading: false,
        masterName: null,
        id: null,
        classificationDetail: null,
        isSystemMaster: false,
        createMasterData: [],
        categories: [
          {
            text: null,
            value: null,
          },
        ],
        category: null,
        items: [],
        dispItems: [],
        itemsLength: null,
        countAdd: 0,
        fields: [],
        companyMasterData: {
          classification: null,
          classificationDetail: null,
          companyId: null,
          masterName: null,
          value: [],
          year: null,
        },
        keyList: [
          'legalServiceLife', // 法定耐用年数表
          'depreciationRate', // 減価償却資産の償却率表
          'inheritanceTax', // 相続税の速算表
          'stampDuty', // 印紙税
          'constructionCost', // 建物の標準的な建築価額表
          'taxRate', // 税率
          'climingRate', // 上昇率
          'unitPrice', // 単価表
          'incomeTax', // 所得税の速算表
        ],
        storeMaster: {
          legalServiceLife: [],
          depreciationRate: [],
          inheritanceTax: [],
          stampDuty: [],
          constructionCost: [],
          taxRate: [],
          climingRate: [],
          unitPrice: [],
          incomeTax: [],
        },
        tableSize: '320px',
        editability: [
          {
            ClassificationDetail: 7,
            value: [
              { key: 'value1', flag: true, type: 'number' },
              { key: 'value2', flag: true, type: null },
            ],
          },
          {
            ClassificationDetail: 8,
            value: [{ key: 'value2', flag: true, type: 'number' }],
          },
        ],
        selectItems: [
          { text: '法定耐用年数表', value: '1' },
          { text: '減価償却資産の償却率表', value: '2' },
          { text: '相続税の速算表', value: '3' },
          { text: '印紙税', value: '4' },
          { text: '建物の標準的な建築価額表', value: '5' },
          { text: '税率', value: '6' },
          { text: '上昇率', value: '7' },
          { text: '単価表', value: '8' },
          { text: '新築建物課税標準価格認定基準表', value: '11' },
          { text: '北海道市町村別法務局', value: '12' },
          { text: '経年減価補正率表', value: '13' },
          { text: '保険料長期係数', value: '14' },
        ],
      };
    },
    computed: {
      getEditability() {
        let result = this.editability.find(
          val => val.ClassificationDetail == this.classificationDetail
        );
        return result?.value;
      },
    },
    mounted: async function() {
      const windowSize = window.innerHeight;
      this.tableSize = parseInt((windowSize * 320) / 722) + 'px';

      //初期化
      this.masteClear();
      if (this.mode == 'Edit') {
        this.classificationDetail = this.propClassificationDetail;
        this.selectItems = [
          { text: '各種係数', value: '7' },
          { text: '単価表', value: '8' },
        ];
      }
    },
    methods: {
      getGroup: function() {
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];

        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },
      //分類のセレクトボックスを選択した時、データを表示する。
      loadMasterData: async function() {
        this.loading = true;

        //初期化
        this.masteClear();

        this.loading == true;
        this.masteClear();

        var masterData = null;
        this.categories = [
          {
            text: null,
            value: null,
          },
        ];
        this.category = null;

        //マスタ情報をロード
        try {
          //会社マスタの取得

          var result = await API.graphql(
            graphqlOperation(getCompanyMaster, {
              companyId: this.getGroup(),
              classification: this.masterType,
              classificationDetail: this.classificationDetail.toString(),
            })
          );

          //システムマスタの取得
          if (!result.data.getCompanyMaster) {
            this.isSystemMaster = true;
            result = await API.graphql(
              graphqlOperation(getSystemMaster, {
                classification: this.masterType,
                classificationDetail: this.classificationDetail.toString(),
              })
            );
            masterData = result.data.getSystemMaster;
          } else {
            //会社マスタを取得した場合は会社マスタを格納
            masterData = result.data.getCompanyMaster;
          }
        } catch (e) {
          //エラー出力
          console.log('error', e);
          this.$bvModal.show('E0001');
        }
        //マスタデータの名前指定
        this.masterName = masterData.masterName;

        //マスタデータ内各データの格納
        this.companyMasterData.classification = masterData.classification;
        this.companyMasterData.classificationDetail = masterData.classificationDetail;
        this.companyMasterData.masterName = masterData.masterName;
        this.companyMasterData.year = masterData.year;
        this.companyMasterData.companyId = this.getGroup();

        for (var e = 1; e < masterData.value.length; e++) {
          //配列宣言
          var itmesInput = {};
          for (var i = 0; i < masterData.value[0].length; i++) {
            //最初のループの場合ヘッダ名を設定
            if (e == 1) {
              var fieldInput = {
                key: 'value' + i,
                label: masterData.value[0][i],
              };
              this.fields.push(fieldInput);
            }
            //b-tableのアイテムを設定
            var keyName = 'value' + i;

            //categoriesにない項目名ならば追加
            const isFind = this.categories.find(
              category => category.text === masterData.value[e][i]
            );
            if (keyName == 'value0' && isFind == undefined) {
              if (this.classificationDetail != 7) {
                this.categories.push({
                  text: masterData.value[e][i],
                  value: masterData.value[e][i],
                });
              } else if (this.classificationDetail == 7) {
                //特別な処理、上昇率は一部のみ変更可能にする
                if (
                  masterData.value[e][i] == '空室率' ||
                  masterData.value[e][i] == '割引率' ||
                  masterData.value[e][i] == '賃料-変動周期' ||
                  masterData.value[e][i] == '賃料-変動係数'
                ) {
                  this.categories.push({
                    text: masterData.value[e][i],
                    value: masterData.value[e][i],
                  });
                }
              }
            }

            //単価表編集時、金額を数値として変換する
            if (this.mode == 'Edit') {
              if (isNaN(masterData.value[e][i])) {
                itmesInput[keyName] = masterData.value[e][i];
              } else {
                itmesInput[keyName] = parseInt(masterData.value[e][i]);
              }
            } else {
              //以下のマスタを出力する場合
              if (masterData.classificationDetail == '2') {
                //減価償却資産の償却率表
                if (keyName == 'value0' || isNaN(masterData.value[e][i])) {
                  itmesInput[keyName] = masterData.value[e][i];
                } else if (keyName == 'value4') {
                  if (!isNaN(masterData.value[e][i])) {
                    itmesInput[keyName] = parseFloat(masterData.value[e][i]).toFixed(5);
                  }
                } else {
                  if (!isNaN(masterData.value[e][i])) {
                    itmesInput[keyName] = parseFloat(masterData.value[e][i]).toFixed(3);
                  }
                }
              } else if (masterData.classificationDetail == '3') {
                //相続税の速算表
                if (keyName == 'value1' || !isFinite(masterData.value[e][i])) {
                  itmesInput[keyName] = masterData.value[e][i];
                } else {
                  itmesInput[keyName] = parseFloat(masterData.value[e][i]).toLocaleString();
                }
              } else if (masterData.classificationDetail == '4') {
                //印紙税
                if (!isFinite(masterData.value[e][i])) {
                  itmesInput[keyName] = masterData.value[e][i];
                } else {
                  itmesInput[keyName] = parseFloat(masterData.value[e][i]).toLocaleString();
                }
              } else if (masterData.classificationDetail == '5') {
                //建物の標準的な建築価格表
                if (keyName == 'value0' || isNaN(masterData.value[e][i])) {
                  itmesInput[keyName] = masterData.value[e][i];
                } else {
                  itmesInput[keyName] = parseFloat(masterData.value[e][i]).toLocaleString();
                }
              } else if (masterData.classificationDetail == '8') {
                //単価表
                if (keyName == 'value2') {
                  if (!isNaN(masterData.value[e][i])) {
                    itmesInput[keyName] = parseFloat(masterData.value[e][i]).toLocaleString();
                  }
                } else {
                  itmesInput[keyName] = masterData.value[e][i];
                }
              } else {
                //上記以外のマスタ
                itmesInput[keyName] = masterData.value[e][i];
              }
            }
          }
          
          // エディットモードであれば削除ボタン用の処理をする
          if (this.mode == 'Edit') {
            // 最初のループの場合ヘッダ名をヘッダ要素を追加する
            if (e == 1) {
              let fieldInput = {
                key: 'tail',
                label: '',
              };
              this.fields.push(fieldInput);
            }
            // ボタン表示用の要素を追加する
            itmesInput['tail'] = '';
          }

          this.items.push(itmesInput);
        }
        this.categories.splice(0, 1);
        this.loading = false;
      },
      //規定値変更時、項目名で絞り込む
      filterItems: function() {
        this.dispItems.splice(0, this.dispItems.length);
        this.items.forEach(element => {
          if (this.category === element['value0']) {
            this.dispItems.push(element);
          }
        });
        //項目追加時、編集判定のために、絞り込んだ後のitemの長さを保存
        this.itemsLength = this.dispItems.length;
      },
      masteClear: function() {
        this.items = [];
        this.fields = [];
        this.masterName = null;
      },
      setSaveTarget: async function() {
        //編集したマスタデータを格納
        //テーブルの値を取得
        this.items.forEach((element, index) => {
          this.companyMasterData.value[index + 1] = [];
          //最初のループはヘッダー情報の格納
          if (index == 0) {
            this.companyMasterData.value[index] = [];
            this.fields.forEach(element => {
              if (element.key != 'tail') {  // 削除ボタン用の要素は回避
                this.companyMasterData.value[index].push(element.label);
              }
            });
          }
          //それ以降はテーブルの行ごとにループし、2次元配列になるように格納していく
          for (let value in element) {
            //もしマスタデータの単位がundefinedだった場合、修正する
            if (
              (this.classificationDetail == 8 && element[value] === undefined) ||
              element[value] === null && value != 'tail'
            ) {
              if (value == 'value2') {
                element[value] = null;
              }
              if (value == 'value3') {
                element[value] = '円';
              }
              this.companyMasterData.value[index + 1].push(element[value]);
            } else {
              if (value != 'tail') {  // 削除ボタン用の要素は回避
                this.companyMasterData.value[index + 1].push(element[value]);
              }
            }
          }
        });

        //会社マスタがなかった場合
        if (this.isSystemMaster) {
          //編集していないマスタデータを格納。変更時にまとめてcreateする
          for (let i = 1; i <= 9; i++) {
            if (i != this.companyMasterData.classificationDetail) {
              var masterData = await API.graphql(
                graphqlOperation(getSystemMaster, {
                  classification: this.masterType,
                  classificationDetail: i.toString(),
                })
              );
              this.createMasterData[i] = {
                classification: null,
                classificationDetail: null,
                masterName: null,
                year: null,
                companyId: null,
                value: [],
              };
              this.createMasterData[i].classification =
                masterData.data.getSystemMaster.classification;
              this.createMasterData[i].classificationDetail =
                masterData.data.getSystemMaster.classificationDetail;
              this.createMasterData[i].masterName = masterData.data.getSystemMaster.masterName;
              this.createMasterData[i].year = masterData.data.getSystemMaster.year;
              this.createMasterData[i].companyId = this.companyMasterData.companyId;
              this.createMasterData[i].value = masterData.data.getSystemMaster.value;
            }
          }
        }
        //store更新用データの格納
        this.keyList.forEach((_, index) => {
          this.storeMaster[this.keyList[index]] = this.$store.state.master[this.keyList[index]];
        });
        //store更新用データに編集したマスタデータを格納
        this.storeMaster[
          this.keyList[this.classificationDetail - 1]
        ] = this.companyMasterData.value.slice(1);

        //マスタデータ更新確認モーダルの表示
        this.$bvModal.show('saveMasterData');
      },
      save: async function() {
        const isValid = await this.$refs.realEstateMaster.validate();
        if (isValid) {
          //システムデータから取得している場合
          if (this.isSystemMaster) {
            try {
              this.loading = true;
              var companyId = this.companyId;
              //編集していない他のマスタデータをcreate
              this.createMasterData.forEach(async value => {
                await API.graphql(
                  graphqlOperation(createCompanyMaster, { input: value, companyId })
                );
              });
              //編集したマスタデータのcreate
              await API.graphql(
                graphqlOperation(createCompanyMaster, { input: this.companyMasterData })
              ).then(() => {
                //登録完了モーダルを表示する。
                this.$bvModal.show('S100');

                //storeの更新を行う
                this.$store.commit('setMaster', this.storeMaster);
              });
            } catch (e) {
              // システムエラー
              this.showError('E0001', e);
            } finally {
              this.loading = false;
            }
          } else {
            //会社のマスタデータから取得している場合
            try {
              this.loading = true;
              API.graphql(
                graphqlOperation(updateCompanyMaster, { input: this.companyMasterData })
              ).then(() => {
                //登録完了モーダルを表示する。
                this.$bvModal.show('S100');

                //storeの更新を行う
                this.$store.commit('setMaster', this.storeMaster);
              });
            } catch (e) {
              // システムエラー
              this.showError('E0001', e);
            } finally {
              this.loading = false;
            }
          }
        } else {
          // 未入力項目あり
          this.showError('E108');
        }
        //追加項目保存後、表示しているリストの長さを更新する。
        this.itemsLength = this.dispItems.length;
      },
      showInitMasterModal: function() {
        this.$bvModal.show('initMasterData');
      },
      initMaster: async function() {
        if (!this.isSystemMaster) {
          for (let i = 1; i < 10; i++) {
            try {
              await API.graphql(
                graphqlOperation(deleteCompanyMaster, {
                  input: {
                    companyId: this.getGroup(),
                    classification: 'realEstateSuitability',
                    classificationDetail: i.toString(),
                  },
                })
              );
            } catch (e) {
              console.log(e);
            }
          }
        }
        this.$bvModal.show('finishInitMasterData');
      },
      //条件、単位の編集判定
      isEditable: function(index) {
        if (this.mode == 'Edit') {
          //テーブルの行数で新しく追加されたitemかどうかで判定
          if (this.itemsLength < index + 1) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
      //項目追加ボタンのメソッド
      addItem: async function() {
        this.countAdd++;
        //大元のデータに新しいデータを追加する。(項目名は絞り込み中の項目名)
        // 同じカテゴリの先頭行から単位文字列を参照して初期値とする
        if (this.dispItems.length > 0) {
          // 表によって項目数が違うので、単位のコピー処理を分ける
          if (Object.values(this.dispItems[0]).length == 5) {
            //項目を追加
            this.items.push({ value0: this.category, value1: '', value2: '', value3: this.dispItems[0].value3 });
          } else {
            //項目を追加
            this.items.push({ value0: this.category, value1: '', value2: this.dispItems[0].value2 });
          }
        } else {
          // 万が一、空の表だった場合(追加行だけvalue3があっても問題はない)
          this.items.push({ value0: this.category, value1: '', value2: '', value3: '' });
        }
        //もう一度検索をかける
        await this.filterItems();
        this.itemsLength = this.itemsLength - this.countAdd;
      },
      // 項目削除ボタンのメソッド
      deleteItem: async function(val0, val1) {
        // 空の表になる事を回避
        if (this.dispItems.length == 1) {
          return;
        }

        this.countAdd--;
        // 該当行以外を残す
        this.items = this.items.filter(function(item) {
          return !(item.value0 === val0 && item.value1 === val1);
        });
        // もう一度検索をかける(this.dispItemsの再作成)
        await this.filterItems();
        this.itemsLength = this.itemsLength - this.countAdd;
      },
      // 要素名からラベル名を探して返す
      getLabel: function(name) {
        let tmp = this.fields.filter(function(item) {
          return item.key == name;
        });
        if (tmp) {
          return tmp[0].label;
        }
        return null;
      },
    },
    watch: {
      //セレクトボックスの中身を指定したとき
      classificationDetail: async function() {
        await this.loadMasterData();

        this.getEditability;
      },
      //規定値変更時
      category: async function() {
        this.countAdd = 0;
        await this.filterItems();
      },
    },
    components: {
      load,
    },
  };
</script>
<style scoped>
  .master-table-header {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
  }
  .initMaster {
    background-color: orangered;
  }
</style>
