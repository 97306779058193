<!--
作成者：Kaneko Takefumi
作成日：2022.10.26
画面名：マスタ画面
機能：マスタデータ登録・修正・照会
親子関係：なし
-->
<template>
  <div>
    <div class="row grid-margin">
      <div class="ally-input-background card col-12">
        <div class="card-body">
          <div class="row">
            <!-- 分類選択 -->
            <allySelect
              class="col-6"
              title="分類"
              name="master"
              :items="[
                { text: '相続税', value: 'inheritanceTax' },
                { text: '不動産活用・投資分析', value: 'realEstateSuitability' },
              ]"
              v-model="masterType"
            />
          </div>
          <hr />

          <!-- エディタ本体 -->
          <MasterEditor
            :masterType="masterType"
            :companyId="companyId"
            :stickyMode="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { API } from 'aws-amplify';
  import MasterEditor from './MasterComponent/MasterEditor';

  API.configure(awsconfig);

  export default {
    props: ['type'],
    data: function() {
      return {
        masterType: null,
        companyId: this.getGroup(),
      };
    },
    mounted: async function() {
      if (this.type) {
        if (this.type.indexOf('realEstate') != -1) {
          this.masterType = 'realEstateSuitability';
        } else {
          this.masterType = 'inheritanceTax';
        }
      }
    },
    methods: {
      getGroup: function() {
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        if (groups[groupIndex] == null) {
          return null;
        }
        return groups[groupIndex].replace('Company-', '');
      },
    },
    watch: {},
    components: {
      MasterEditor,
    },
  };
</script>

<style scoped>
  .ally-radio {
    width: calc(100% / var(--radioCount));
    border: solid 1px #ced4da;
    text-align: center;
    height: 2.875rem;
    padding: 0.875rem 1.375rem;
    background-color: white;
  }

  .ally-radio input {
    visibility: hidden;
  }

  .ally-radio-selected {
    color: white;
    background-color: var(--colorTheme);
  }

  .ally-tab label {
    margin-bottom: 0;
  }
</style>
<style>
  #inputMasterModal input[type='number'],
  #inputMasterModal .number input[type='text'] {
    text-align: right;
  }

  #inputMasterModal fieldset.form-group {
    margin-bottom: 0rem;
  }

  #inputMasterModal > div > div > div {
    padding: 2rem;
  }

  #inputMasterModal td {
    padding: 0.25rem;
  }
</style>
