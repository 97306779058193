import { API, Auth, graphqlOperation } from 'aws-amplify';
import { 
  registCompany
}from '@/graphql/queries';

import {
  updateCompany,
  deleteCompanyTemporaryStorage,
} from '@/graphql/mutations';

export default {
  data: function() {
    return {
    };
  },
  methods: {
    companyRegister: async function(companyData, email, userSub) {
      //会社情報の登録を行う
      const result = await API.graphql(
        graphqlOperation(registCompany, {
          input: {
            companyInfo: {
              companyName: companyData.companyName,
              bossLastName: companyData.bossLastName,
              bossFirstName: companyData.bossFirstName,
              companyTel: companyData.tel,
              companyAddress: companyData.address,
              registrationNum: companyData.registrationNum,
              mail: companyData.mail,
              qualification: companyData.qualification,
              memo: companyData.memo,
            },
            accountInfo: {
              userName: email,
            },
          },
        })
      );
      //一時保存した内容を削除する
      await API.graphql(
        graphqlOperation(deleteCompanyTemporaryStorage, {
          input: {
            username: userSub,
          },
        })
      );
      console.log("result.data.registCompany",result.data.registCompany)
      if (result.data.registCompany == '200') {
        // 登録された会社IDを取得
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
        const group = groups.find(g => {
          return g.startsWith('Company-');
        }, 'Company-');
        const id = group.substring('Company-'.length);

        // 会社ロゴをアップロード
        await this.uploadLogo(id);
      } else if (result.data.registCompany == '419') {
        // 登録番号重複
        this.error = 1;
        return;
      } else {
        // システムエラー
        this.error = 1;
        return;
      }
    },
    // 会社ロゴ画像アップロード
    uploadLogo: async function(id) {
      if (
        !this.$refs ||
        !this.$refs.logo ||
        !this.$refs.logo.imageList ||
        !this.$refs.logo.imageList[0]
      ) {
        return false;
      }
      try {
        // 会社ロゴ アップロード
        await this.$refs.logo.fileUpload();

        // 会社情報更新
        await API.graphql(
          graphqlOperation(updateCompany, {
            input: {
              id: id,
              companyLogo: this.companyInfo.companyLogo,
            },
          })
        );
        return true;
      } catch (e) {
        console.error({ uploadLogo: e });
        return false;
      }
    },
  },
}