// @ts-check
import { PvFloor } from '../../../node/node/floorNode';
import { PvGuideMarker } from '../../../node/node/guideMarkerNode';
import { createNode } from '../../../node/node/pvNode';
import { cloneNodeSingle } from '../../../node/tools/clone';
import { makeToast } from '../../../util/util';
import { Action } from '../action';
import { ActionBuilder } from '../../action/builder/actionBuilder';
import { MESSAGE_TEXT } from '../../../util/const';

export class DrawPolygonActionBuilder extends ActionBuilder {
  build() {
    const action = new Action();
    action.actionType = Action.ACTION_TYPE.MULTI_CLICK;

    const { getGuideRoot, getCurrentPos, getRoot, getObjectPreset } = this.context;

    const guide = cloneNodeSingle(/** @type {PvFloor} */ (getObjectPreset()));
    getGuideRoot().appendChild(guide);

    const guideMarker = createNode(PvGuideMarker);
    guideMarker.pointerEvents = 'all';
    guideMarker.transform = guideMarker.transform.cloneWith({
      translate: getCurrentPos(),
    });
    getGuideRoot().appendChild(guideMarker);

    makeToast(MESSAGE_TEXT.DRAW_HELP_POLYGON_FINISH, 'primary');

    action.onUpdate = ({ routes }) => {
      guide.deformateFromVecList(routes);
    };

    // 多角形床作成時
    action.onComplete = ({ routes }) => {
      // 床作成時の設定値を受け取る
      const floor = PvFloor.createPolygon(
        routes,
        getObjectPreset().style.clone(),
        getObjectPreset().height3d
      );
      getRoot().appendChild(floor);
    };

    action.onAdvanceOrRollBack = ({ len }, dataset) => {
      if (dataset?.id === guideMarker.id) {
        return false;
      }
    };

    return action;
  }
}
