<template>
  <div id="AccountList">
    <h4 class="font-weight-bold">アカウント一覧</h4>
    <b-container style="padding:0;">
      <!-- 上部ボタン -->
      <b-row>
        <b-col>
          <b-navbar v-if="mode !== 'Search'">
            <b-navbar-nav class="ml-auto">
              <button class="btn btn-ally" @click="addAccount">
                アカウント追加
              </button>
              <router-link to="/Account/Restore" tag="button" class="btn btn-ally">
                削除済アカウント確認
              </router-link>
              <router-link to="/DashBoard" tag="button" class="btn btn-ally">
                メインページへもどる
              </router-link>
            </b-navbar-nav>
          </b-navbar>
        </b-col>
      </b-row>
      <!-- アカウント一覧 -->
      <b-row>
        <b-col>
          <paginationV2
            ref="pagination"
            :headers="headers"
            targetName="Account"
            :labelEnums="labelEnums"
            :hideDetail="mode === 'Search' || undefined"
            :hideDelete="mode === 'Search' || undefined"
            @detailClicked="e => detailAccount(e)"
            @deleteClicked="e => deleteAccount(e)"
            @selectClicked="e => selectAccount(e)"
            :filterFix="{
              status: {
                contains: 'true',
              },
            }"
          >
          </paginationV2>
        </b-col>
      </b-row>
    </b-container>
    <!-- メッセージ -->
    <allyAlert code="E0001"></allyAlert>
    <allyAlert code="E114"></allyAlert>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { deleteAccount } from '../../graphql/mutations';

  API.configure(awsconfig);

  export default {
    props: {
      mode: {
        type: String,
        default: 'View',
      },
    },
    data: function() {
      return {
        loading: true,
        mountFlag: false,
        headers: [
          {
            key: 'serialNumber',
            label: 'No.',
            sortable: true,
          },
          {
            key: 'isAdmin',
            label: '管理者',
            callback: (header, row) => {
              return row.item.isAdmin ? '○' : '';
            },
            sortable: true,
            searchable: false,
          },
          {
            key: 'name',
            label: '姓名',
            callback: (header, row) => {
              var familyname = row.item.familyname ? row.item.familyname + ' ' : '';
              var givenname = row.item.givenname ? row.item.givenname : '';
              return familyname + givenname;
            },
            sortable: true,
          },
          { key: 'address', label: '部署', sortable: true },
          { key: 'phonenumber', label: '電話番号', sortable: true },
          { key: 'mobilePhoneNumber', label: '携帯電話番号', sortable: true },
          { key: 'email', label: 'ID', sortable: true },
        ],
        labelEnums: {
          isAdmin: {
            true: '管理者',
            false: 'メンバー',
          },
        },
      };
    },
    methods: {
      addAccount: function() {
        const newNo = this.$refs.pagination.items.length + 1;
        this.$router.push({
          path: '/Account/Add/' + newNo,
        });
      },
      deleteAccount: async function(row) {
        this.$refs.pagination.loading = true;

        if (row.sub == this.$store.state.user.signInUserSession.idToken.payload.sub) {
          this.$bvModal.show('E114');
        } else {
          try {
            await API.graphql(graphqlOperation(deleteAccount, { sub: row.sub }));
          } catch (e) {
            // システムエラー
            this.showError('E0001', e);
          }
        }
        this.$refs.pagination.listItems();
      },
      detailAccount: function(row) {
        this.$router.push({
          path: '/Account/View/' + row.sub,
        });
      },
      selectAccount: function(row) {
        this.$emit('selectAccount', row);
      },
      /**
       * グループ名取得
       */
      getGroupName: function() {
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex].substring('Company-'.length);
      },
    },
    created() {
      // 検索モードのとき
      if (this.mode === 'Search')
        this.headers.push({
          key: 'select',
          label: '選択',
          button: { label: '選択', event: 'selectClicked' },
        });
    },
  };
</script>
<!-- スタイル -->
<style scoped>
  #AccountList >>> .container {
    max-width: 100%;
  }
  #AccountList >>> nav {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }
  #AccountList >>> nav > ul > button {
    margin-left: 0.5rem;
  }
</style>
