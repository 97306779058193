<template>
  <CommonLayout title="ポートフォリオ分析">
    <ReportSubTitle
      :subTitle="
        '物件情報' +
          (type === 'inheritanceTaxBase'
            ? '(' + valueList[0].baseSelected + ')'
            : '(実勢価格ベース)')
      "
    />
    <div div style="padding: 150px 100px;">
      <div>
        <div class="table-top">
          <div style="text-align: right; font-size:40px;">
            <span>単位: 万円</span>
          </div>
          <table style="width: 100%; border : solid 2px #525851; table-layout: fixed">
            <thead style="background: #1d1d63; border : solid 2px #525851;">
              <tr>
                <th colspan="5" :style="thStyle">物件概要</th>
                <th colspan="5" :style="thStyle">貸借対照表(B/S)</th>
                <th colspan="5" :style="thStyle">損益計算(P/L)</th>
                <th colspan="2" :style="thStyle">キャッシュフロー</th>
                <th colspan="3" :style="thStyle">健全性</th>
              </tr>
              <tr>
                <th :style="thInherStyle">所在地</th>
                <th :style="thInherStyle">名称</th>
                <th :style="thInherStyle">利用状況</th>
                <th :style="thInherStyle">土地面積(㎡)</th>
                <th :style="thInherStyle">建物面積(㎡)</th>
                <th :style="thStyle">
                  <div>土地価格</div>
                </th>
                <th :style="thStyle">
                  <div>建物価格</div>
                </th>
                <th :style="thStyle">
                  <div>合計</div>
                </th>
                <th :style="thStyle">
                  <div>借入金</div>
                </th>
                <th :style="thStyle">
                  <div>純資産</div>
                </th>
                <th :style="thStyle">収入</th>
                <th :style="thStyle">公租公課</th>
                <th :style="thStyle">管理費</th>
                <th :style="thStyle">合計</th>
                <th :style="thStyle">年間収支</th>
                <th :style="thStyle">
                  <div>借入金</div>
                  <div>返済額</div>
                </th>
                <th :style="thStyle">
                  <div>税前</div>
                  <div>キャッシュ<br />フロー</div>
                </th>
                <th :style="thStyle">
                  <div>利回り</div>
                  <div>(%)</div>
                </th>
                <th :style="thStyle">
                  <div>借入金比率</div>
                  <div>(%)</div>
                </th>
                <th :style="thStyle">
                  <div>資産効率</div>
                  <div>(%)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(input, key) in inputList" :key="'pro-' + key">
                <td
                  :style="[tdStyleValue, { 'text-align': 'left' }]"
                  v-html="locationHTMLList[key]"
                ></td>
                <td :style="[tdStyleValue, { 'text-align': 'left' }]">
                  {{ inputList[key].inheritanceTaxBase.assetsRealEstate.salseObjectPoint.name }}
                </td>
                <td :style="[tdStyleValue, { 'text-align': 'left' }]">
                  {{
                    inputList[key].inheritanceTaxBase.assetsRealEstate.salseObjectPoint
                      .usageSituation
                  }}
                </td>
                <td :style="[tdStyleValue, { 'text-align': 'right' }]">
                  {{
                    inputList[
                      key
                    ].inheritanceTaxBase.assetsRealEstate.salseObjectPoint.landSize.toFixed(2)
                  }}
                </td>
                <td :style="[tdStyleValue, { 'text-align': 'right' }]">
                  {{
                    inputList[
                      key
                    ].inheritanceTaxBase.assetsRealEstate.salseObjectPoint.buildingSize.toFixed(2)
                  }}
                </td>
                <td v-if="type === 'inheritanceTaxBase'" :style="tdStyleValue">
                  {{
                    formatNum(
                      inputList[key].inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice
                        .land / 10000,
                      {
                        floatLength: 0,
                        roundType: 'off',
                        separation: true,
                      }
                    )
                  }}
                </td>
                <td v-else :style="tdStyleValue">
                  {{
                    formatNum(inputList[key].prevailingPriceBase.prevailingPrice.land / 10000, {
                      floatLength: 0,
                      roundType: 'off',
                      separation: true,
                    })
                  }}
                </td>
                <td v-if="type === 'inheritanceTaxBase'" :style="tdStyleValue">
                  {{
                    formatNum(
                      inputList[key].inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice
                        .building / 10000,
                      {
                        floatLength: 0,
                        roundType: 'off',
                        separation: true,
                      }
                    )
                  }}
                </td>
                <td v-else :style="tdStyleValue">
                  {{
                    formatNum(inputList[key].prevailingPriceBase.prevailingPrice.building / 10000, {
                      floatLength: 0,
                      roundType: 'off',
                      separation: true,
                    })
                  }}
                </td>
                <td v-if="type === 'inheritanceTaxBase'" :style="tdStyleValue">
                  {{
                    formatNum(valueList[key].inheritanceTaxBase.No8 / 10000, {
                      floatLength: 0,
                      roundType: 'off',
                      separation: true,
                    })
                  }}
                </td>
                <td v-else :style="tdStyleValue">
                  {{
                    formatNum(valueList[key].prevailingPriceBase.No8 / 10000, {
                      floatLength: 0,
                      roundType: 'off',
                      separation: true,
                    })
                  }}
                </td>
                <td :style="tdStyleValue">
                  {{
                    formatNum(
                      inputList[key].inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt / 10000,
                      {
                        floatLength: 0,
                        roundType: 'off',
                        separation: true,
                      }
                    )
                  }}
                </td>
                <td v-if="type === 'inheritanceTaxBase'" :style="tdStyleValue">
                  {{
                    formatNum(valueList[key].inheritanceTaxBase.No10 / 10000, {
                      floatLength: 0,
                      roundType: 'off',
                      separation: true,
                    })
                  }}
                </td>
                <td v-else :style="tdStyleValue">
                  {{
                    formatNum(valueList[key].prevailingPriceBase.No10 / 10000, {
                      floatLength: 0,
                      roundType: 'off',
                      separation: true,
                    })
                  }}
                </td>
                <td :style="tdStyleValue">
                  {{
                    formatNum(
                      inputList[key].inheritanceTaxBase.assetsRealEstate.pl.income / 10000,
                      {
                        floatLength: 0,
                        roundType: 'off',
                        separation: true,
                      }
                    )
                  }}
                </td>
                <td :style="tdStyleValue">
                  {{
                    formatNum(
                      (inputList[key].inheritanceTaxBase.assetsRealEstate.pl.spending
                        .landPropertyTax +
                        inputList[key].inheritanceTaxBase.assetsRealEstate.pl.spending
                          .landCityPlanningTax +
                        inputList[key].inheritanceTaxBase.assetsRealEstate.pl.spending
                          .buildingPropertyTax +
                        inputList[key].inheritanceTaxBase.assetsRealEstate.pl.spending
                          .buildingCityPlanningTax) /
                        10000,
                      {
                        floatLength: 0,
                        roundType: 'off',
                        separation: true,
                      }
                    )
                  }}
                </td>
                <td :style="tdStyleValue">
                  {{
                    formatNum(
                      inputList[key].inheritanceTaxBase.assetsRealEstate.pl.spending
                        .maintenanceFee / 10000,
                      {
                        floatLength: 0,
                        roundType: 'off',
                        separation: true,
                      }
                    )
                  }}
                </td>
                <td :style="tdStyleValue">
                  {{
                    formatNum(valueList[key].inheritanceTaxBase.No15 / 10000, {
                      floatLength: 0,
                      roundType: 'off',
                      separation: true,
                    })
                  }}
                </td>
                <td :style="tdStyleValue">
                  {{
                    formatNum(valueList[key].inheritanceTaxBase.No16 / 10000, {
                      floatLength: 0,
                      roundType: 'off',
                      separation: true,
                    })
                  }}
                </td>
                <td :style="tdStyleValue">
                  {{
                    formatNum(
                      inputList[key].inheritanceTaxBase.assetsRealEstate.cashflowCalc
                        .outstandingDebtTotal / 10000,
                      {
                        floatLength: 0,
                        roundType: 'off',
                        separation: true,
                      }
                    )
                  }}
                </td>
                <td :style="tdStyleValue">
                  {{
                    formatNum(valueList[key].inheritanceTaxBase.No18 / 10000, {
                      floatLength: 0,
                      roundType: 'off',
                      separation: true,
                    })
                  }}
                </td>
                <td v-if="type === 'inheritanceTaxBase'" :style="tdStyleValue">
                  {{
                    formatNum(valueList[key].inheritanceTaxBase.No19, {
                      floatLength: 1,
                      roundType: 'off',
                      separation: true,
                    })
                  }}
                </td>
                <td v-else :style="tdStyleValue">
                  {{
                    formatNum(valueList[key].prevailingPriceBase.No19, {
                      floatLength: 1,
                      roundType: 'off',
                      separation: true,
                    })
                  }}
                </td>
                <td v-if="type === 'inheritanceTaxBase'" :style="tdStyleValue">
                  {{
                    formatNum(valueList[key].inheritanceTaxBase.No20, {
                      floatLength: 1,
                      roundType: 'off',
                      separation: true,
                    })
                  }}
                </td>
                <td v-else :style="tdStyleValue">
                  {{
                    formatNum(valueList[key].prevailingPriceBase.No20, {
                      floatLength: 1,
                      roundType: 'off',
                      separation: true,
                    })
                  }}
                </td>
                <td :style="tdStyleValue">
                  {{
                    formatNum(
                      valueList[key].inheritanceTaxBase.No8 !== 0
                        ? (valueList[key].prevailingPriceBase.No8 /
                            valueList[key].inheritanceTaxBase.No8) *
                            100
                        : '',
                      {
                        floatLength: 1,
                        roundType: 'off',
                        separation: true,
                      }
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import ReportSubTitle from '@/components/ExportReport/ReportSubTitle';
  import sanitizeHTML from 'sanitize-html';

  export default {
    props: {
      inputList: {
        type: Array,
        required: true,
      },
      valueList: {
        type: Array,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
      warekiFlg: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        thStyle: {
          border: 'solid 2px #333',
          color: 'white',
          'font-size': '50px',
          'text-align': 'center',
        },
        thInherStyle: {
          border: 'solid 2px #333',
          color: 'white',
          'font-size': '50px',
          height: '10mm',
          'text-align': 'center',
        },
        tdStyle: {
          'font-size': '50px',
          border: 'solid 2px #333',
          background: 'white',
          'text-align': 'right',
          padding: '0px 15px',
        },
        tdStyleValue: {
          'font-size': '35px',
          border: 'solid 2px #333',
          background: 'white',
          'text-align': 'right',
          padding: '0px 15px',
        },
      };
    },
    components: {
      CommonLayout,
      ReportSubTitle,
    },
    methods: {
      wareki(inputYear) {
        if (this.warekiFlg === true) {
          const warekiObject = this.warekiChange(inputYear);
          if (warekiObject == null) {
            return '';
          }
          return warekiObject.name + warekiObject.year + '年';
        } else {
          return inputYear;
        }
      },
    },
    computed: {
      // 所在地
      locationHTMLList() {
        if (this.inputList == null) {
          return [];
        }
        return this.inputList.map(input => {
          const html = input.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.address.replaceAll(
            '\n',
            '<br />'
          );
          // htmlサニタイズ
          return sanitizeHTML(
            html + '<br/>' + input.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.lotNum
          );
        });
      },
    },
  };
</script>
