<!--
作成者：Lee HyeonSeok
作成日：2020.08.04
画面名：最初ログインパスワード初期化
機能：最初ログインパスワード初期化
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <load v-if="loading"></load>
    <div v-if="!loading">
      <div class="row">
        <allyText
          class="col-12"
          title="新規パスワード"
          name="password"
          v-model="password"
          rules="required"
          type="password"
          :placeholder="'8文字以上・大小文字・数字含む'"
        />
      </div>
      <div class="row grid-margin">
        <div class="col-12">
          <b-button @click="change" :disabled="!password" variant="ally-login">変更 </b-button>
          <b-button @click="signIn" variant="ally-login" class="passwordReset">戻る </b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="error">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { AmplifyEventBus } from 'aws-amplify-vue';
  import load from '../../../components/Common/Load';

  export default {
    name: 'RequireNewPassword',
    props: ['user'],
    data: function() {
      return {
        error: '',
        password: '',
        loading: false,
      };
    },
    methods: {
      change: function() {
        this.loading = true;
        this.$Amplify.Auth.completeNewPassword(this.user, this.password, null)
          .then(() => this.checkContact(this.user))
          .catch(err => this.setError(err))
          .finally(() => (this.loading = false));
      },
      checkContact: function(user) {
        this.$Amplify.Auth.verifiedContact(user)
          .then(() => {
            AmplifyEventBus.$emit('localUser', this.user);
            AmplifyEventBus.$emit('authState', 'signedIn');
          })
          .catch(e => this.setError(e));
      },
      signIn: function() {
        AmplifyEventBus.$emit('authState', 'signIn');
      },
      setError: function(e) {
        this.error = this.$parent.getErrorString(e.code);
      },
    },
    components: {
      load,
    },
  };
</script>
