export default class RealEstateInput {
  constructor(input) {
    if (input == null) {
      input = {};
    }

    this.siteConditions = input.siteConditions || {
      ownerInformation: {
        ownerType: 2,
        estimatedEstateValue: null,
        spouse: null,
        numberOfChildren: null,
        numberOfParentsAlive: null,
        siblingsOfTheDeceased: null,
        spouseRealRatio: null,
        childRealRatio: [null],
        parentRealRatio: [null],
        siblingRealRatio: [null],
      },
      siteInformation: {
        surfaceArea: null,
        buildingToLandRatio: null,
        floorAreaRatio: null,
        percentageOfLeaseholdInterest: null,
        roadsideLandPrice: null,
        propertyTaxAssessment: null,
        availableForSale: null,
        currentUse: null,
        currentIncome: null,
        zoningDistrict: null,
        otherAreas: null,
        landPicture: null,
        surfaceAreaUnit: 2,
        roadsideLandPriceUnit: 2,
        presumption: 2,
      },
    };

    this.locationRequirement = input.locationRequirement || {
      traffic: null,
      name: null,
      memo: null,
      landUseInTheVicinity: null,
      guideMap: null,
      concreteApplicationImages: {
        vacantLandAndLeasedLand: null,
        monthlyParkingLot: null,
        storeOffice: null,
        groupRental: null,
        groupRentalFamily: null,
        detachedHousesForRent: null,
        sellAndCashOut: null,
      },
    };

    this.overallPlan = input.overallPlan || {
      rentalProperty: new Array(7).fill(null).map(() => ({
        checked: false,
        monthlyRent: null,
        numberOfFloors: null,
        numberOfHouses: null,
        personalFunds: null,
        borrowingRate: null,
        borrowingPeriod: null,
      })),
      monthlyParkingLot: {
        monthlyRent: null,
        numberOfUnits: null,
        personalFunds: null,
        borrowingRate: null,
        borrowingPeriod: null,
        equityRatio: null,
      },
      storeOffice: {
        monthlyRent: null,
        personalFunds: null,
        borrowingRate: null,
        borrowingPeriod: null,
        equityRatio: null,
      },
      sale: {
        salePrice: null,
        priceAtTheTimeOfPurchase: null,
        periodOfOwnership: null,
        purchasePrice: null,
        agencyFees: null,
        otherTransferCosts: null,
        termOfTransferIncomeTax: null,
        transferIncomeTax: null,
        netProceeds: null,
        acquisitionCost: null,
      },
    };

    this.chartData = input ? input.chartData : [];

    this.isCalculated = input ? input.isCalculated : false;
  }

  getName() {
    return this.locationRequirement.name;
  }

  setChartData(chartDataList) {
    this.chartData = JSON.stringify(chartDataList);
  }

  getChartData() {
    return JSON.parse(this.chartData);
  }
}
