<!--
作成者：Suzuki Sota
作成日：
画面名：平面図編集画面(管理名入力画面)
機能：平面図編集(管理名入力)
親子関係：なし
更新者：
①
更新内容：
①
-->
<template>
  <div class="property-view">
    <div class="row grid-margin">
      <h4 class="font-weight-bold">
        {{
          mode == 'PlaneAdd'
            ? '平面図管理名入力'
            : mode == 'PlaneEdit'
            ? '平面図編集'
            : mode == 'RoomAdd'
            ? '部屋タイプ選択'
            : '部屋作成'
        }}
      </h4>
    </div>
    <div class="row my-3">
      <div class="col-12">
        <b-button
          class="btn btn-ally float-right mx-1"
          v-if="mode == 'PlaneEdit'"
          @click="permissionsEvent()"
        >
          権限
        </b-button>
      </div>
    </div>

    <ValidationObserver slim v-if="!loading">
      <!-- 物件情報 -->
      <div class="row grid-margin">
        <div class="ally-input-background card col-12">
          <div class="card-body">
            <h4 class="font-weight-bold">
              {{
                mode == 'PlaneAdd'
                  ? '平面図管理名入力'
                  : mode == 'PlaneEdit'
                  ? '平面図編集'
                  : mode == 'RoomAdd'
                  ? '部屋タイプ選択'
                  : '部屋作成'
              }}
            </h4>
            <div class="row">
              <div class="col-md-4">
                <allyText
                  class="col-5 form-group"
                  :title="mode == 'PlaneAdd' ? '管理名' : 'フロア入力'"
                  name="printDisplayName"
                  :mode="mode"
                  :loading="loading"
                  rules="required"
                  v-if="mode == 'PlaneAdd' || mode == 'RoomAdd'"
                />
                <div class="row">
                  <allySelect
                    class="col-6"
                    title="部屋タイプ種別"
                    :items="Object.keys(roomType)"
                    v-if="mode == 'RoomAdd'"
                    rules="required"
                    @input="e => result(e)"
                  />
                  <allySelect
                    class="col-6"
                    title="部屋タイプ選択"
                    :items="roomTypeDetail"
                    v-if="mode == 'RoomAdd'"
                    rules="required"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
    <b-modal
      :id="planeID + 'printModal'"
      title="出力確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="出力"
      @ok="printEvent()"
    >
      出力しますか？
    </b-modal>
    <b-modal
      :id="planeID + 'permissionsModal'"
      title="出力確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="設定"
    >
      <h4>権限</h4>
      <div>
        <paginationV2
          ref="pagination"
          :headers="headers"
          targetName="Account"
          :hideDetail="''"
          :hideDelete="''"
          :hideSearchBox="''"
          :filterFix="{
            status: {
              contains: 'true',
            },
          }"
        ></paginationV2>
      </div>
    </b-modal>

    <allyAlert code="S100"></allyAlert>
    <allyAlert code="E0001"></allyAlert>
  </div>
</template>
<script>
  import awsconfig from '../../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getProperty } from '../../../graphql/queries';
  import { createARMeasure } from '../../../graphql/mutations';

  API.configure(awsconfig);

  export default {
    data: function() {
      return {
        loading: false,
        isOpenEditor: false,
        propertyInfo: {
          company: null,
          simInfo: [],
        },
        simInfoData: {
          baseImageFileName: null,
          baseImageDisplayName: null,
          layersInfo: [],
        },
        jsonText: null,
        messageCount: 0,
        companyId: '0000',
        operationMode: null,
        mode: null,
        innerWidth: null,
        id: this.$route.params.id,
        lastAuth: null,
        lastUpdate: null,
        roomMaxFlag: null,
        iconMaxFlag: null,
        planeID: null,
        roomTypeDetail: {},
        roomType: {
          住居1: ['洋室', '和室', 'LDK', 'DK', 'LD', 'リビング'],
          住居2: ['ダイニング', 'キッチン', '居間', '食事室', '台所', '洗面所'],
          住居3: ['脱衣室', '浴室', 'UB', 'トイレ', 'ホール', '廊下'],
          商業1: [
            'オフィス',
            '個人用オフィス',
            '共有オフィス',
            '受付',
            'サービスルーム',
            'オープンスペース',
          ],
          商業2: [
            'フリースペース',
            'ミーティングルーム',
            'セミナールーム',
            'トレーニングルーム',
            '会議室',
            '応接室',
          ],
          商業3: ['事務室', '役員室', '社長室', '金庫室', '作業室', '書庫'],
        },
        headers: [
          {
            key: 'name',
            label: '姓名',
            callback: (header, row) => {
              var familyname = row.item.familyname ? row.item.familyname + ' ' : '';
              var givenname = row.item.givenname ? row.item.givenname : '';
              return familyname + givenname;
            },
          },
          //役職テーブルの作成(データの設計が終わったら)
          {
            key: 'managerial',
            label: '権限',
          },
          {
            key: 'addManagerial',
            label: '権限付与',
            button: { label: '権限付与', event: 'addManagerialClicked' },
          },
        ],
      };
    },
    mounted: async function() {
      addEventListener('message', e => {
        //メッセージカウントが０の場合は処理終了（unityから戻る際に何故か二度メッセージを検知して処理が連続実行されるため）
        if (this.messageCount < 1) {
          return;
        }
        this.messageCount = 0;

        var result = '';
        //Unityから受け取ったjsonDataを取得
        var json = e.data.value;
        //jsonを分解して取得
        var obj = JSON.parse(json);

        //データベース登録用型変換処理
        let interiors = [];
        for (let i = 0; i < obj.interiors.length; i++) {
          let measureTypes = [];
          for (let j = 0; j < obj.interiors[i].measureTypes.length; j++) {
            let measurements = [];
            for (let k = 0; k < obj.interiors[i].measureTypes[j].measurements.length; k++) {
              let position = {
                x: parseFloat(obj.interiors[i].measureTypes[j].measurements[k].position.x),
                y: parseFloat(obj.interiors[i].measureTypes[j].measurements[k].position.y),
                z: parseFloat(obj.interiors[i].measureTypes[j].measurements[k].position.z),
              };
              measurements.push({
                position: position,
                length: parseFloat(obj.interiors[i].measureTypes[j].measurements[k].length),
                angle: parseFloat(obj.interiors[i].measureTypes[j].measurements[k].angle),
              });
            }
            measureTypes.push({
              type: parseInt(obj.interiors[i].measureTypes[j].type) + 4,
              height: parseFloat(obj.interiors[i].measureTypes[j].height) + 3,
              svg: obj.interiors[i].measureTypes[j].SVG,
              measurements: measurements,
            });
          }
          interiors.push({
            code: parseInt(obj.interiors[i].code) + 2,
            setNumber: parseInt(obj.interiors[i].setNumber) + 1,
            measureTypes: measureTypes,
          });
        }
        // this.$router.currentRoute.path.startsWith('/Company/Edit/')

        //会社情報を取得
        this.getCompany();

        //必要な情報を格納
        let data = {
          companyId: this.companyId, //会社ID
          propertyId: this.$route.params.id, //物件ID
          floorPlanId: '12345', //平面図ID（※現時点では仕様が固まっていないため仮の値を使用）
          interiors: interiors, //建具計測データ
        };

        //DynamoDBに保存
        try {
          API.graphql(graphqlOperation(createARMeasure, { input: data }));
        } catch (error) {
          console.error(error);
          this.showError('E0001', error);
          return;
        }

        //日本語化処理
        result += '建具計測データ{\r\n';
        result += '\r\n';
        for (let i = 0; i < obj.interiors.length; i++) {
          result += '　建具コード　　：' + obj.interiors[i].code + '\r\n';
          result += '　建具セット番号：' + obj.interiors[i].setNumber + '\r\n';
          result += '\r\n';

          for (let j = 0; j < obj.interiors[i].measureTypes.length; j++) {
            result += '　計測データ' + j + 1 + '{\r\n';
            result += '　　計測タイプ　：' + obj.interiors[i].measureTypes[j].type + '\r\n';
            result += '　　計測高さ　　：' + obj.interiors[i].measureTypes[j].height + '（ｍ）\r\n';
            result += '　　ＳＶＧデータ：' + obj.interiors[i].measureTypes[j].SVG + '\r\n';
            result += '\r\n';

            for (let k = 0; k < obj.interiors[i].measureTypes[j].measurements.length; k++) {
              result += '　　座標データ' + (k + 1) + '：{\r\n';
              result +=
                '　　　計測点　Ｘ：' +
                obj.interiors[i].measureTypes[j].measurements[k].position.x +
                '\r\n';
              result +=
                '　　　　　　　Ｙ：' +
                obj.interiors[i].measureTypes[j].measurements[k].position.y +
                '\r\n';
              result +=
                '　　　　　　　Ｚ：' +
                obj.interiors[i].measureTypes[j].measurements[k].position.z +
                '\r\n';
              result +=
                '　　　距離　：' +
                obj.interiors[i].measureTypes[j].measurements[k].length +
                '（ｍ）\r\n';
              result +=
                '　　　角度　：' + obj.interiors[i].measureTypes[j].measurements[k].angle + '°\r\n';
              result += '　　}\r\n';
            }
            result += '\r\n　}\r\n';
          }
        }
        result += '\r\n}\r\n';

        //画面表示
        this.jsonText = result;
      });
      //経路によってモードを設定
      if (this.$router.currentRoute.path.endsWith('/PlaneEdit')) {
        this.mode = 'PlaneEdit';
      } else if (this.$router.currentRoute.path.endsWith('/PlaneAdd')) {
        this.mode = 'PlaneAdd';
      } else if (this.$router.currentRoute.path.endsWith('/RoomAdd')) {
        this.mode = 'RoomAdd';
      } else {
        this.mode = 'RoomEdit';
      }

      //計測終了後、WebView再表示時処理
      function GetJsonData(jsonData) {
        postMessage({ type: 'unity', value: jsonData });
      }
      window.GetJsonData = GetJsonData;

      //部屋数のカウント(最大部屋数不明。確認の必要アリ)
      this.roomMaxFlag = await this.getRoomCount();
      //アイコン数のカウント
      this.iconMaxFlag = await this.getIconCount();

      //平面図IDの取得(現在、物件IDで動かしているため今後修正)
      this.planeID = this.$route.params.id;

      this.propertyId = this.$route.params.id;
      this.imageName = this.$route.params.image;
      this.authType = 'admin';
      //経路によってモードを設定
      if (this.$router.currentRoute.path.match('.*/Add.*')) {
        this.operationMode = 'Add';
      } else if (this.$router.currentRoute.path.match('.*/Edit.*')) {
        this.operationMode = 'Edit';
      } else if (this.$router.currentRoute.path.match('.*/Simulate.*')) {
        this.operationMode = 'Simulate';
      } else {
        this.operationMode = 'List';
      }

      try {
        //物件情報を取得
        var getQuery = await API.graphql(
          graphqlOperation(getProperty, { company: this.getCompany(), id: this.propertyId })
        );
      } catch (e) {
        //モーダルを表示する。
        this.$bvModal.show('E0001');
        return;
      }
      //会社情報を格納
      this.propertyInfo = getQuery.data.getProperty;

      delete this.propertyInfo.createdAt;
      delete this.propertyInfo.updatedAt;

      //リスト以外は会社情報を取得する
      if (this.operationMode != 'List') {
        var result = null;
        if (this.operationMode != 'Add') {
          result = getQuery.data.getProperty.simInfo.find(
            x => x.baseImageFileName == this.imageName
          );
        }
        //シミュレーション画像情報
        this.simInfoData = result;
      }

      this.loading = false;
    },
    methods: {
      async getCompany() {
        this.companyId = this.$store.state.user.signInUserSession.accessToken.payload[
          'cognito:groups'
        ].find(val => val.match(/^Company-*./));
      },
      //出力する(PDF化する)データのセット
      setPrintEvent: function() {
        this.$bvModal.show(this.planeID + 'printModal');
      },

      //出力処理
      printEvent() {
        this.$router.push({ path: 'PlaneView/List' });
      },

      permissionsEvent() {
        this.$bvModal.show(this.planeID + 'permissionsModal');
      },

      //保存イベント
      saveEvent: function() {
        //更新者と更新日を取得。
        this.lastAuth = this.$store.state.user.signInUserSession.idToken.payload['name'];
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth();
        var day = date.getDate();
        this.lastUpdate = year + '/' + (month + 1) + '/' + day;
        //更新者、更新日をDBへ

        //編集した平面図の保存
      },
      //部屋タイプselectbox分割用
      result: function(value) {
        this.roomTypeDetail = this.roomType[value];
      },

      //部屋数取得(GQL決定次第実装)
      getRoomCount: async function() {},

      //アイコン数取得(GQL決定次第実装)
      getIconCount: async function() {},
    },
  };
</script>
<style scoped>
  body {
    color: #333333;
    line-height: 140%;
  }
  .modal-content {
    width: 200%;
  }
  textarea {
    width: 600pt;
  }
  .svg-editor-component {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }
</style>
