// @ts-check

// eslint-disable-next-line no-unused-vars
import { Vec } from '../../../../../../PlaneView/lib/util/math/vector';

/**
 * SVGのスタイル等の設定
 */
export class Config {
  stroke = '';
  strokeWidth = 5;
  strokeLinecap = 'butt';
  fill = '';
  imageUrl = '';
  patternWidth = 200;
  patternOffsetX = 0;
  patternOffsetY = 0;
  /** @type {number|null} */
  ratio = null;
  flipHorizontal = false; // 左右反転
  flipVertical = false; // 上下反転

  // Floorのみ
  /** @type {Vec} */
  textTranslate = null;
  areaSizeUnit = '帖';
  customText = '';

  constructor(config = null) {
    if (config != null) {
      Object.keys(config).forEach(key => {
        if (config[key]) this[key] = config[key] ?? this[key];
      });
    }
  }

  /**
   * クローン
   * @returns {Config}
   */
  clone() {
    const c = new Config();
    Object.keys(this).forEach(key => {
      const val = this[key];
      if (typeof val !== 'object' || val === null) {
        c[key] = this[key];
      } else if (val.clone != null) {
        c[key] = val.clone();
      } else {
        c[key] = this[key];
      }
    });
    return c;
  }
}
