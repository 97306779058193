<template>
  <div>
    <h4 class="font-weight-bold">削除済アカウント一覧</h4>
    <!-- ページング処理を呼び出す -->
    <div class="row grid-margin">
      <div class="col-12">
        <router-link to="/Account/List" tag="button" class="btn btn-ally float-right"
          >アカウント一覧に戻る</router-link
        >
      </div>
    </div>
    <paginationV2
      ref="pagination"
      :headers="headers"
      targetName="Account"
      :labelEnums="labelEnums"
      :filterFix="{
        status: {
          contains: 'false',
        },
      }"
      hideDetail
      hideDelete
      @callRestoreModal="e => callRestoreModal(e)"
      @callDeleteModal="e => callDeleteModal(e)"
    ></paginationV2>
    <b-modal
      ref="restoreModal"
      title="アカウント復旧確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="復旧"
      @ok="restoreAccount"
      centered
    >
      対象アカウントを復旧しますか？
    </b-modal>
    <b-modal
      ref="deleteModal"
      title="完全削除確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="いいえ"
      ok-variant="light"
      ok-title="はい"
      @ok="deleteAccount"
      centered
    >
      完全削除しますか？<br />
      ※このアカウントは完全に削除され、復旧できません。
    </b-modal>
    <allyAlert code="E0001"></allyAlert>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { permanentDeleteAccount, restoreAccount } from '../../graphql/mutations';

  API.configure(awsconfig);

  export default {
    data: function() {
      return {
        headers: [
          {
            key: 'serialNumber',
            label: 'No.',
            sortable: true,
          },
          {
            key: 'isAdmin',
            label: '管理者',
            callback: (header, row) => {
              return row.item.isAdmin ? '○' : '';
            },
            sortable: true,
          },
          {
            key: 'name',
            label: '姓名',
            callback: (header, row) => {
              var familyname = row.item.familyname ? row.item.familyname + ' ' : '';
              var givenname = row.item.givenname ? row.item.givenname : '';
              return familyname + givenname;
            },
            sortable: true,
          },
          { key: 'address', label: '部署', sortable: true },
          { key: 'phonenumber', label: '電話番号', sortable: true },
          { key: 'email', label: 'ID', sortable: true },
          {
            key: 'restore',
            label: '復旧',
            button: { label: '復旧', event: 'callRestoreModal' },
            searchable: false,
          },
          {
            key: 'deleteCompletely.',
            label: '完全削除',
            button: { label: '完全削除', event: 'callDeleteModal' },
            searchable: false,
          },
        ],
        labelEnums: {
          isAdmin: {
            true: '管理者',
            false: 'メンバー',
          },
        },
        restoreAccountId: null,
        deleteAccountId: null,
      };
    },
    mounted: async function() {},
    methods: {
      // 完全削除モーダルの呼出
      callDeleteModal: async function(row) {
        this.deleteAccountId = null;
        this.$refs.pagination.loading = false;
        this.$refs['deleteModal'].show();
        this.deleteAccountId = row.sub;
      },
      //アカウント完全削除
      deleteAccount: async function() {
        this.$refs.pagination.loading = true;
        await API.graphql(graphqlOperation(permanentDeleteAccount, { sub: this.deleteAccountId }));
        this.$refs.pagination.listItems();
      },
      //復旧モーダルの呼び出し
      callRestoreModal: async function(row) {
        this.restoreAccountId = null;
        this.$refs.pagination.loading = false;
        this.$refs['restoreModal'].show();
        this.restoreAccountId = row.sub;
      },
      //アカウント復旧処理
      restoreAccount: async function() {
        this.$refs.pagination.loading = true;
        try {
          await API.graphql(graphqlOperation(restoreAccount, { sub: this.restoreAccountId }));
          this.$refs.pagination.listItems();
        } catch (e) {
          e.errors.forEach(error => {
            console.error('EXCEPTION: ' + error.message);
          });
          this.$bvModal.show('E0001');
          this.$refs.pagination.loading = false;
        }
      },
    },
  };
</script>
