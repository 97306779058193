<!--
作成者：Kaneko Takefumi
作成日：2022.10.26
画面名：マスタ画面
機能：マスタデータ登録・修正・照会
親子関係：なし
-->

<template>
  <div>
    <div>
      <div class="row">
        <!-- マスタ選択 -->
        <allySelect
          v-if="!loading"
          class="col-6"
          title="マスタ名"
          name="master"
          :items="masterType == 'inheritanceTax' ? inheritanceTaxList : realEstateList"
          v-model="classificationDetail"
        />

        <!-- バージョン情報 -->
        <div class="col-4 mt-4" v-if="!loading && companyMasterData.version"> {{ "version " + companyMasterData.version }} </div>
        <div class="col-4" v-else></div>
        
        <!-- 初期化ボタン -->
        <div class="col-2" v-if="!loading" >
          <b-button
            variant="ally"
            class="my-2 mx-2 initMaster"
            @click="showInitAllMasterModal()"
            >全て初期化</b-button
          >
          <b-button
            variant="ally"
            v-if="classificationDetail"
            class="my-2 mx-2 initMaster"
            @click="showInitMasterModal()"
            >初期化</b-button
          >
        </div>
      </div>
      <hr v-if="!loading" />
      
      <!-- 細目選択 -->
      <div v-if="subdivision()">
        <div class="row">
          <allySelect
            v-if="!loading"
            class="col-6"
            title="細目"
            name="master"
            :items="categories"
            v-model="category"
          />
        </div>
        <hr />
      </div>
    </div>

    <!-- ロード中の処理 -->
    <div v-if="loading">
      <load />
      <div style="height: 150px;" />
    </div>

    <!-- テーブル -->
    <ValidationObserver slim ref="masterEditor">
      <div class="master-table-header">
        <div v-if="!loading && !isSystemMaster && classificationDetail">
          御社のカスタムデータを使用しています。<br />
        </div>
      </div>
      <b-table
        ref="master"
        thead-tr-class="serch-data-table-header"
        tbody-tr-class="serch-data-table-body"
        hover
        striped
        :items="subdivision() ? dispItems : items"
        :fields="subdivision() ? dispFields : fields"
        :fixed="true"
        caption-top
        :sticky-header="stickyMode ? tableSize : false"
      >
        <template v-slot:cell()="data">
          <div v-show="false">
            {{
              /** 扱いやすいように変数保存 */
              (getEditData = getEditability(data.item.value0, data.field.key))
            }}
            {{ (typeData = getEditData == null ? null : getEditData.type) }}
            {{ (digitLength = getEditData == null ? 0 : getEditData.digits ? getEditData.digits : data.item.value2 == '%' ? 3 : 0) }}
          </div>

          <!-- 編集リストに該当が無ければ編集不可-->
          <span v-if="getEditData == null && !isEditable(data.field.key, data.index)"> {{ data.item[data.field.key] }} </span>

          <!-- 該当があれば編集を可能にする -->
          <span v-else>
            <span v-if="typeData == 'number'">
              <!-- 数値 -->
              <allyText
                v-model="data.item[data.field.key]"
                type="number"
                class="number"
                :floatLength="Number(digitLength)"
                :separation="true"
              />
            </span>
            <span v-else>
              <!-- 文字列 -->
              <allyText :title="getLabel(data.field.key)" :nonAppear="true" rules="required" v-model="data.item[data.field.key]" />
            </span>
          </span>

          <!-- editability -->
        </template>

        <!-- 削除ボタン('tail'要素) -->
        <template v-slot:cell(tail)="data">
          <b-button @click="deleteItem(data.index)">
            削除
          </b-button>
        </template>
      </b-table>

      <!-- 下部ボタン -->
      <div class="row float-right">
        <b-button variant="ally" class="my-2 mx-2" v-if="checkNeedButton('A')" @click="addItem()"
          >項目追加</b-button
        >
        <b-button variant="ally" class="my-2" @click="setSaveTarget()"
          >保存</b-button
        >
      </div>
    </ValidationObserver>
    <allyAlert code="E0001"></allyAlert>

    <b-modal
      id="saveMasterData"
      title="マスタデータ保存"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="変更"
      @ok="save"
    >
      編集中のマスタデータを変更します。<br />
      <div class="text-danger">変更すると他の計算結果に影響を与える可能性があります</div>
      よろしいですか？
    </b-modal>
    <b-modal
      id="initAllMasterData"
      title="マスタデータ初期化"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="初期化"
      @ok="initAllMaster"
    >
      全てのマスタデータを初期化します。<br />
      <div class="text-danger">初期化すると他の計算結果に影響を与える可能性があります</div>
      よろしいですか？
    </b-modal>
    <b-modal
      id="initMasterData"
      title="マスタデータ初期化"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="初期化"
      @ok="initMaster"
    >
      編集中のマスタデータを初期化します。<br />
      <div class="text-danger">初期化すると他の計算結果に影響を与える可能性があります</div>
      よろしいですか？
    </b-modal>
    <b-modal
      id="finishInitMasterData"
      title="マスタデータ初期化完了"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-only
      ok-variant="light"
      ok-title="ok"
    >
      マスタデータを初期化しました。
    </b-modal>
    <allyAlert :code="'S100'" :title="'マスタデータ保存成功'"></allyAlert>
  </div>
</template>
<script>
  //aws
  import awsconfig from '../../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getSystemMaster, getCompanyMaster } from '../../../graphql/queries';
  import {
    createCompanyMaster,
    updateCompanyMaster,
    deleteCompanyMaster,
  } from '../../../graphql/mutations';

  //component
  import load from '../../../components/Common/Load';

  API.configure(awsconfig);

  export default {
    props: ['masterType', 'companyId', 'stickyMode'],
    data: function() {
      return {
        loading: false,
        masterName: null,
        classificationDetail: null,
        isSystemMaster: false,
        categories: [
          {
            text: null,
            value: null,
          },
        ],
        category: null,
        items: [],
        dispItems: [],
        itemsLength: null,
        countAdd: 0,
        fields: [],
        dispFields: [],
        companyMasterData: {
          classification: null,
          classificationDetail: null,
          companyId: null,
          masterName: null,
          value: [],
          year: null,
          version: null,
          editability: null,
        },
        keyList: [
          'legalServiceLife', // 法定耐用年数表
          'depreciationRate', // 減価償却資産の償却率表
          'inheritanceTax', // 相続税の速算表
          'stampDuty', // 印紙税
          'constructionCost', // 建物の標準的な建築価額表
          'taxRate', // 税率
          'climingRate', // 上昇率
          'unitPrice', // 単価表
          'incomeTax', // 所得税の速算表
          'defaultValue', // 不動産活用診断の単価設定(※保存時にstoreMasterから消滅を回避する為)
        ],
        storeMaster: {
          legalServiceLife: [],
          depreciationRate: [],
          inheritanceTax: [],
          stampDuty: [],
          constructionCost: [],
          taxRate: [],
          climingRate: [],
          unitPrice: [],
          incomeTax: [],
          defaultValue: [],
        },
        tableSize: '320px',
        inheritanceTaxList: [
            { text: '奥行価格補正率表', value: '1' },
            { text: '側方路線影響加算率表', value: '2' },
            { text: '二方路線影響加算率表', value: '3' },
            { text: '不整形地補正率を算定する際の地積区分表', value: '4' },
            { text: '不整形地補正率表', value: '5' },
            { text: '間口狭小補正率表', value: '6' },
            { text: '奥行長大補正率表', value: '7' },
            { text: 'がけ地補正率表', value: '8' },
            { text: '規模格差補正率を算定する際の表イ三大都市圏に所在する宅地(三大都市)', value: '9.1' },
            { text: '規模格差補正率を算定する際の表イ三大都市圏に所在する宅地(その他)', value: '9.2' },
            { text: '相続税計算税マスタ', value: '10' },
            { text: '宅地造成費', value: '11' },
            { text: '傾斜地造成費', value: '12' },
          ],
       realEstateList: [
            { text: '法定耐用年数表', value: '1' },
            { text: '減価償却資産の償却率表', value: '2' },
            { text: '相続税の速算表', value: '3' },
            { text: '印紙税', value: '4' },
            { text: '税率', value: '6' },
            { text: '上昇率', value: '7' },
            { text: '単価表', value: '8' },
            { text: '所得税の速算表', value: '9' },
            { text: '新築建物課税標準価格認定基準表', value: '11' },
            { text: '北海道市町村別法務局', value: '12' },
            { text: '経年減価補正率表', value: '13' },
            { text: '保険料長期係数', value: '14' },
          ],
      };
    },
    mounted: async function() {
      const windowSize = window.innerHeight;
      this.tableSize = parseInt((windowSize * 320) / 722) + 'px';

      //初期化
      this.masterClear();
    },
    methods: {
      getGroup: function() {
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];

        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },
      //分類のセレクトボックスを選択した時、データを表示する。
      loadMasterData: async function() {
        this.loading = true;

        //初期化
        this.masterClear();

        this.loading == true;
        this.masterClear();

        var masterData = null;

        //マスタ情報をロード
        try {
          //会社マスタの取得
          var result = await API.graphql(
            graphqlOperation(getCompanyMaster, {
              companyId: this.getGroup(),
              classification: this.masterType,
              classificationDetail: this.classificationDetail,
            })
          );

          //システムマスタの取得
          if (!result.data.getCompanyMaster) {
            this.isSystemMaster = true;
            result = await API.graphql(
              graphqlOperation(getSystemMaster, {
                classification: this.masterType,
                classificationDetail: this.classificationDetail,
              })
            );
            masterData = result.data.getSystemMaster;
          } else {
            //会社マスタを取得した場合は会社マスタを格納
            masterData = result.data.getCompanyMaster;
          }
        } catch (e) {
          //エラー出力
          console.log('error', e);
          this.$bvModal.show('E0001');
        }
        //マスタデータの名前指定
        this.masterName = masterData.masterName;

        //マスタデータ内各データの格納
        this.companyMasterData.classification = masterData.classification;
        this.companyMasterData.classificationDetail = masterData.classificationDetail;
        this.companyMasterData.masterName = masterData.masterName;
        this.companyMasterData.year = masterData.year;
        this.companyMasterData.companyId = this.getGroup();
        this.companyMasterData.version = masterData.version;
        this.companyMasterData.editability = masterData.editability;

        for (var e = 1; e < masterData.value.length; e++) {
          //配列宣言
          var itmesInput = {};
          for (var i = 0; i < masterData.value[0].length; i++) {
            //b-tableのアイテムを設定
            var keyName = 'value' + i;
            
            //最初のループの場合ヘッダ名を設定
            if (e == 1) {
              var fieldInput = {
                key: keyName,
                label: masterData.value[0][i],
              };
              this.fields.push(fieldInput);
            }

            // 細目情報の収集
            if (this.companyMasterData?.editability[0].subdivision == "true") {
              //categoriesにない項目名ならば追加
              const isFind = this.categories.find(
                category => category.text === masterData.value[e][i]
              );
              if (keyName == 'value0' && isFind == undefined) {
                this.categories.push({
                  text: masterData.value[e][i],
                  value: masterData.value[e][i],
                });
              }
            }

            // 表示データを格納
            itmesInput[keyName] = masterData.value[e][i];
          }
          
          // 削除ボタンの追加処理
          if (e == 1 && this.checkNeedButton('D')) {
            // 最初のループの場合ヘッダ名をヘッダ要素を追加する
            let fieldInput = {
              key: 'tail',
              label: '',
            };
            this.fields.push(fieldInput);
          }

          this.items.push(itmesInput);
        }
        // 初期化用の空要素を削除
        this.categories.splice(0, 1);
        this.loading = false;
      },
      //規定値変更時、項目名で絞り込む
      filterItems: function() {
        this.dispFields.splice(0, this.dispFields.length);
        this.dispItems.splice(0, this.dispItems.length);
        if (this.category) {
          let tailFlag = this.checkNeedButton('D');
          this.dispFields = this.fields.concat();
          // 削除ボタンの追加処理
          if (tailFlag) {
            let fieldInput = {
              key: 'tail',
              label: '',
            };
            this.dispFields.push(fieldInput);
          }
          this.items.forEach(element => {
            if (this.category === element['value0']) {
              this.dispItems.push(element);
            }
          });
          //項目追加時、編集判定のために、絞り込んだ後のitemの長さを保存
          this.itemsLength = this.dispItems.length;
        } else {
          this.itemsLength = this.items.length;
        }
      },
      masterClear: function() {
        this.masterName = null;
        this.isSystemMaster = false;
        this.categories = [
          {
            text: null,
            value: null,
          },
        ];
        this.category = null;
        this.items = [];
        this.dispItems = [];
        this.itemsLength = 0;
        this.countAdd = 0;
        this.fields = [];
        this.dispFields = [];
        this.companyMasterData.version = null;
        this.companyMasterData.editability = null;
      },
      setSaveTarget: async function() {
        //編集したマスタデータを格納
        //テーブルの値を取得
        this.items.forEach((element, index) => {
          this.companyMasterData.value[index + 1] = [];
          //最初のループはヘッダ情報の格納
          if (index == 0) {
            this.companyMasterData.value[index] = [];
            this.fields.forEach(element => {
              if (element.key != 'tail') {  // 削除ボタン用の要素以外を格納
                this.companyMasterData.value[index].push(element.label);
              }
            });
          }
          //それ以降はテーブルの行ごとにループし、2次元配列になるように格納していく
          for (let value in element) {
            //マスタデータがundefinedだった場合、修正する
            if (element[value] === undefined) {
              element[value] = null;
            }
            // ヘッダにある要素のみ格納する（追加行対策）
            this.fields.forEach(checker => {
              if (checker.key == value) {
                this.companyMasterData.value[index + 1].push(element[value]);
              }
            });
          }
        });

        //store更新用データの格納
        this.keyList.forEach((_, index) => {
          this.storeMaster[this.keyList[index]] = this.$store.state.master[this.keyList[index]];
        });
        // 不動産適正・投資分析の時、シート1～9の場合だけ編集したマスタのstoreMasterを更新する
        if (this.masterType == 'realEstateSuitability' && this.classificationDetail <= this.keyList.length) {
          //store更新用データに編集したマスタデータを格納
          this.storeMaster[
            this.keyList[this.classificationDetail - 1]
          ] = this.companyMasterData.value.slice(1);
        }

        //マスタデータ更新確認モーダルの表示
        this.$bvModal.show('saveMasterData');
      },
      save: async function() {
        const isValid = await this.$refs.masterEditor.validate();
        if (isValid) {
          //システムデータから取得している場合
          if (this.isSystemMaster) {
            try {
              this.loading = true;
              //編集したマスタデータのcreate
              await API.graphql(
                graphqlOperation(createCompanyMaster, { input: this.companyMasterData })
              ).then(() => {
                //登録完了モーダルを表示する。
                this.$bvModal.show('S100');

                //storeの更新を行う
                this.$store.commit('setMaster', this.storeMaster);
              });

              // 保存に成功したのでフラグを変更
              this.isSystemMaster = false;
            } catch (e) {
              // システムエラー
              this.showError('E0001', e);
            } finally {
              this.loading = false;
            }
          } else {
            //会社のマスタデータから取得している場合
            try {
              this.loading = true;
              API.graphql(
                graphqlOperation(updateCompanyMaster, { input: this.companyMasterData })
              ).then(() => {
                //登録完了モーダルを表示する。
                this.$bvModal.show('S100');

                //storeの更新を行う
                this.$store.commit('setMaster', this.storeMaster);
              });
            } catch (e) {
              // システムエラー
              this.showError('E0001', e);
            } finally {
              this.loading = false;
            }
          }
        } else {
          // 未入力項目あり
          this.showError('E108');
        }
        //追加項目保存後、表示しているリストの長さを更新する。
        this.itemsLength = this.dispItems.length;
      },
      showInitAllMasterModal: function() {
        this.$bvModal.show('initAllMasterData');
      },
      showInitMasterModal: function() {
        this.$bvModal.show('initMasterData');
      },
      // 全てのマスタデータを初期化する
      initAllMaster: async function() {
        // 相続税
        for (let i = 0; i < this.inheritanceTaxList.length; i++) {
          try {
            await API.graphql(
              graphqlOperation(deleteCompanyMaster, {
                input: {
                  companyId: this.getGroup(),
                  classification: 'inheritanceTax',
                  classificationDetail: this.inheritanceTaxList[i].value,
                },
              })
            );
          } catch (e) {
            console.log(e);
          }
        }
        // 不動産適正・投資分析
        for (let i = 0; i < this.realEstateList.length; i++) {
          try {
            await API.graphql(
              graphqlOperation(deleteCompanyMaster, {
                input: {
                  companyId: this.getGroup(),
                  classification: 'realEstateSuitability',
                  classificationDetail: this.realEstateList[i].value,
                },
              })
            );
          } catch (e) {
            console.log(e);
          }
        }
        this.classificationDetail = null; // this.masterClear()が呼ばれる
        this.$bvModal.show('finishInitMasterData');
      },
      // 編集中のマスタデータを初期化する
      initMaster: async function() {
        if (!this.isSystemMaster && this.classificationDetail) {
          try {
            await API.graphql(
              graphqlOperation(deleteCompanyMaster, {
                input: {
                  companyId: this.getGroup(),
                  classification: this.masterType,
                  classificationDetail: this.classificationDetail,
                },
              })
            );
          } catch (e) {
            console.log(e);
          }
        }
        this.classificationDetail = null; // this.masterClear()が呼ばれる
        this.$bvModal.show('finishInitMasterData');
      },
      // 編集判定
      isEditable: function(key, index) {
        // // 同期が取れていない事がある為、行数の再計算が必要
        this.itemsLength = this.category ? this.dispItems.length : this.items.length;
        // テーブルの行数で新しく追加されたitem、なおかつ細目でなければ編集可（項目別に処理するのでadded要素で判定できない）
        if (index < this.itemsLength - this.countAdd || (this.category && key == 'value0')) {
          return false;
        } else {
          return true;
        }
      },
      // ボタン表示の判定(id = 'A' or 'D')
      checkNeedButton: function(id) {
        let result = false;
        // 行追加設定の有無
        if (this.companyMasterData?.editability && this.companyMasterData.editability[0].editLine?.permission) {
          // 行操作情報にidが含まれ、尚且つ条件がある場合は細目(value0)と一致している場合に編集時用の要素を返す
          let matchId = this.companyMasterData?.editability[0].editLine.permission.find(val => val == id) != null;
          let matchTerms = (this.companyMasterData?.editability[0].editLine.terms == null);
          if (this.category && this.companyMasterData.editability[0].editLine.terms != null) {
            matchTerms = this.companyMasterData?.editability[0].editLine.terms.find(val0 => val0 == this.category) ? true : false;
          }
          result = matchId && matchTerms;
        }
        return result;
      },
      //項目追加ボタンのメソッド
      addItem: async function() {
        this.countAdd++;
        //大元のデータに新しいデータを追加する
        this.items.push({ value0: this.category, value1: '', value2: '', value3: '', value4: '', value5: '', value6: '', value7: '', value8: '', value9: '', added: true });
        //もう一度検索をかける
        await this.filterItems();
        this.itemsLength = this.itemsLength - this.countAdd;
      },
      // 項目削除ボタンのメソッド
      deleteItem: async function(targetIndex) {
        // 空の表になる事を回避
        if (this.items.length == 1 || this.dispItems.length == 1) {
          return;
        }

        let deletedObject = [];

        // 該当行以外を残す
        if (this.category) {
          // 細目がある場合
          let count = 0;
          let findedIndex = -1;
          this.items.every((value, index) => {
            if (value.value0 == this.category) {
              if (count == targetIndex) {
                // indexを保存してループを中断
                findedIndex = index;
                return false;
              }
              count++;
            }
            return true;
          });
          if (findedIndex != -1) {
            // １行削除（everyの中で処理しても良いが…）
            deletedObject = this.items.splice(findedIndex, 1);
          }
        } else {
          // １行削除
          deletedObject = this.items.splice(targetIndex, 1);
        }

        // 追加行を削除したか判定
        if (deletedObject[0]?.added) {
          this.countAdd--;
        }

        // もう一度検索をかける
        await this.filterItems();

        this.itemsLength = this.itemsLength - this.countAdd;
      },
      // 要素名からラベル名を探して返す
      getLabel: function(name) {
        let tmp = this.fields.filter(function(item) {
          return item.key == name;
        });
        if (tmp) {
          return tmp[0].label;
        }
        return null;
      },
      //
      subdivision: function() {
        return this.companyMasterData?.editability && this.companyMasterData?.editability[0].subdivision == "true";
      },
      // 項目編集可能か判定
      getEditability: function(value0, key) {
        let result = null;
        // 項目編集設定の有無
        if (this.companyMasterData?.editability[0].editItem) {
          // keyが編集許可された項目名と同一、尚且つ編集条件がある場合はvalue0と一致している場合に編集時用の要素を返す
          result = this.companyMasterData?.editability[0].editItem.find(val => val.key == key
                 && (val.terms?.length > 0 ? (val.terms.find(val0 => val0 == value0)) : true));
        }
        return result;
      },
    },
    watch: {
      //セレクトボックスの中身を指定したとき
      classificationDetail: async function() {
        if (this.classificationDetail == null) {
          // 未選択であればマスタを初期化
          this.masterClear();
          return;
        }

        await this.loadMasterData();
      },
      //規定値変更時
      category: async function() {
        this.countAdd = 0;
        await this.filterItems();
      },
      // 分類の変更時
      masterType: function() {
        // マスタ名を初期化
        this.classificationDetail = null;
      },
    },
    components: {
      load,
    },
  };
</script>
<style scoped>
  .master-table-header {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
  }
  .initMaster {
    background-color: orangered;
  }
</style>
<style>
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default {
      color: white!important;
      background-color: var(--colorTheme)!important;
    }
  }
</style>