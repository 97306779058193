// @ts-check

import { computed, ref } from '@vue/composition-api';
import { PvGroup } from '../node/node/groupNode';
import { PvHandle } from '../node/node/handleNode';
import { createNode } from '../node/node/pvNode';
import { PvObject } from '../node/node/pvObject';
import { HandleFlg } from '../node/tools/handleFlg';

/**
 *
 * @param {{
 * selectedObjects: import('@vue/composition-api').ComputedRef<PvObject[]>;
 * }} param0
 * @returns
 */
export const useHandle = ({ selectedObjects }) => {
  const resizeHandleFlg = ref(true);

  const handleFlg = computed(() => {
    if (resizeHandleFlg.value) {
      return new HandleFlg({
        vertex: true,
        edge: true,
        curve: false,
        rotate: false,
      });
    } else {
      return new HandleFlg({
        vertex: false,
        edge: false,
        curve: true,
        rotate: true,
      });
    }
  });

  const handles = computed(() => {
    const h = selectedObjects.value.map(obj => PvHandle.createHandles(obj, handleFlg.value));
    return h;
  });

  const handleTree = computed(() => {
    const root = createNode(PvGroup);
    root.appendChildren(handles.value);
    const flatten = root.flatWithoutRoot();
    return flatten;
  });

  return {
    handleTree,
    resizeHandleFlg,
  };
};
