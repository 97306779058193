<!--
作成者：Lee HyeonSeok
作成日：2020.6.3
画面名：顧客情報登録・修正・照会
機能：顧客情報登録・修正・照会
親子関係：View-corp.vue: 法人の場合、View-personal.vue: 個人の場合
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <div class="row grid-margin">
      <h4 class="font-weight-bold">
        顧客情報{{ mode == 'Add' ? '登録' : mode == 'View' ? '詳細' : '編集' }}
      </h4>
    </div>
    <div class="row grid-margin">
      <div class="col-12">
        <div class="row ally-tab" id="customerType">
          <label
            class="col-2"
            :class="{
              'ally-radio-selected': value.customerType == '法人',
            }"
            v-if="value.customerType == '法人' || mode == 'Add'"
            style="text-align:center;"
          >
            <input
              type="radio"
              class="form-check-input"
              name="customerType"
              v-model="value.customerType"
              value="法人"
            />
            <h3>法人</h3>
          </label>
          <label
            class="col-2"
            :class="{
              'ally-radio-selected': value.customerType == '個人',
            }"
            v-if="value.customerType == '個人' || mode == 'Add'"
            style="text-align:center;"
          >
            <input
              type="radio"
              class="form-check-input"
              name="customerType"
              v-model="value.customerType"
              value="個人"
            />
            <h3>個人</h3>
          </label>
        </div>
      </div>
      <div class="ally-input-background card col-12">
        <div class="card-body">
          <ValidationObserver slim ref="customer">
            <ViewCorp
              v-if="value.customerType == '法人'"
              v-model="value"
              :mode="mode"
              ref="ViewCorp"
            />
            <ViewPersonal
              v-else-if="value.customerType == '個人'"
              v-model="value"
              :mode="mode"
              ref="ViewPersonal"
            />
          </ValidationObserver>
        </div>
      </div>
    </div>
    <div v-if="value.customerType == '個人'">
      <div class="ally-input-background card">
        <div class="card-body">
          <ViewFamily
            :mode="mode"
            :value="value"
            @familyModalOpen="(e, index) => modalOpen(e, index)"
          ></ViewFamily>
        </div>
      </div>
    </div>
    <div class="row my-3" v-if="comp == 'false'">
      <div class="col-12">
        <button
          class="btn btn-ally float-right"
          v-on:click="create"
          v-if="mode == 'Add'"
          type="submit"
        >
          登録
        </button>
        <b-button-group class="float-right">
          <b-button
            @click="
              $router.push({
                path: '/Report/List',
                query: { customer: id },
              })
            "
            variant="ally"
            v-if="mode == 'View'"
            style="margin-right : 10px"
            >レポート管理</b-button
          >
          <b-button
            @click="$router.push('/Customer/Edit/' + id)"
            variant="ally"
            v-if="mode == 'View'"
          >
            編集
          </b-button>
        </b-button-group>
        <button class="btn btn-ally float-right" v-if="mode == 'Edit'" v-on:click="update">
          保存
        </button>
        <router-link
          v-if="this.viewMode == null"
          to="/Customer/List?page=true"
          tag="button"
          class="btn btn-ally float-right"
          style="
            margin-right:10px;
            margin-left:10px
          "
          >一覧に戻る</router-link
        >
      </div>
    </div>
    <allyAlert code="S100" @okAction="pageLink()"></allyAlert>
    <allyAlert code="E0001"></allyAlert>
  </div>
</template>
<script>
  import $ from 'jquery';

  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { createCustomer, updateCustomer } from '../../graphql/mutations';
  import { getCustomer } from '../../graphql/queries';
  import ViewCorp from './ViewCorp';
  import ViewPersonal from './ViewPersonal';
  import ViewFamily from '../../components/Customer/ViewFamily';

  API.configure(awsconfig);

  export default {
    props: {
      viewMode: {
        type: String,
        default: null,
      },
      comp: {
        type: String,
        default: "false",
      },
    },
    data: function() {
      return {
        id: this.$route.params.id,
        mode: this.viewMode,
        value: {
          customerType: null,
          customerName: null,
          department: null,
          lastName: null,
          firstName: null,
          lastNameKana: null,
          firstNameKana: null,
          tel: null,
          phone: null,
          fax: null,
          mail: null,
          gender: null,
          birthday: null,
          comment: null,
          address: {
            postalCode: null,
            address1: null,
            address2: null,
            address3: null,
          },
          family: null,
          businessCard: null,
          companyInfo: null,
        },
      };
    },
    mounted: async function() {
      if (this.mode == null) {
        if (this.$router.currentRoute.path == '/Customer/Add') this.mode = 'Add';
        else if (this.$router.currentRoute.path.startsWith('/Customer/Edit')) this.mode = 'Edit';
        else this.mode = 'View';
      }

      if (this.mode == 'Add') {
        $('#customerType input:first').prop('checked', 'checked');
        this.value.customerType = '法人';
      }

      if (this.mode == 'View' || this.mode == 'Edit') {
        var result = await API.graphql(graphqlOperation(getCustomer, { id: this.id }));

        this.value = result.data.getCustomer;
        delete this.value.createdAt;
        delete this.value.updatedAt;
      }
    },
    methods: {
      modalOpen: function(item, index) {
        this.$refs.ViewPersonal.modalOpen(item, index);
      },
      // 追加する
      create: async function() {
        const isValid = await this.$refs.customer.validate();
        if (isValid) {
          var uploadData = this.value;

          if (this.value.businessCard.length > 0) {
            if (this.$refs.ViewCorp) await this.$refs.ViewCorp.fileUpload();
            if (this.$refs.ViewPersonal) await this.$refs.ViewPersonal.fileUpload();
          }
          uploadData.company = this.getGroup();
          delete uploadData.familyModalValues;
          delete uploadData.modalLoading;

          try {
            // DBに保存
            let createData = await API.graphql(graphqlOperation(createCustomer, { input: uploadData }));
            //モーダルを表示する。
            if(this.comp == "false"){
              //component利用時は2重表示になっているので表示しない
              this.$bvModal.show('S100');
            }
            return {
              flag: true,
              result: createData.data.createCustomer,
            };
          } catch {
            //エラー時モーダルを表示する。
            this.$bvModal.show('E0001');
          }
        }
        return {
          flag: false,
          result: null,
        };
      },
      // 変更する
      update: async function() {
        const isValid = await this.$refs.customer.validate();
        if (isValid) {
          if (this.value.businessCard.length > 0) {
            if (this.$refs.ViewCorp) await this.$refs.ViewCorp.fileUpload();
            if (this.$refs.ViewPersonal) await this.$refs.ViewPersonal.fileUpload();
          }

          var uploadData = this.value;

          //IDセットアップ
          uploadData.id = this.id;

          delete uploadData.familyModalValues;
          delete uploadData.modalLoading;

          try {
            // DBに保存
            await API.graphql({
              query: updateCustomer,
              variables: { input: uploadData },
            });
            //モーダルを表示する。
            this.$bvModal.show('S100');
          } catch {
            //エラー時モーダルを表示する。
            this.$bvModal.show('E0001');
          }
        }
      },
      pageLink: function() {
        if (this.viewMode != null) {
          this.$emit('modalClause');
          return;
        }
        //保存フラグをtrueに設定（ページ移動の警告メッセージを防ぐ為）
        this.$store.commit('setSaveFlg', true);
        this.$router.push('/Customer/List');
      },
      getGroup: function() {
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];

        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');

        return groups[groupIndex];
      },
    },
    components: {
      ViewCorp,
      ViewPersonal,
      ViewFamily,
    },
    watch: {
      'value.customerType': function() {
        if (this.mode == 'Add') {
          // 初期化する
          let customerTypeTemp = this.value.customerType;
          this.value = {
            customerType: customerTypeTemp,
            customerName: null,
            department: null,
            lastName: null,
            firstName: null,
            lastNameKana: null,
            firstNameKana: null,
            tel: null,
            phone: null,
            fax: null,
            mail: null,
            gender: null,
            birthday: null,
            comment: null,
            address: {
              postalCode: null,
              address1: null,
              address2: null,
              address3: null,
            },
            family: null,
            businessCard: null,
            companyInfo: null,
          };
        }
      },
    },
  };
</script>

<style scoped>
  .ally-radio {
    width: calc(100% / var(--radioCount));
    border: solid 1px #ced4da;
    text-align: center;
    height: 2.875rem;
    padding: 0.875rem 1.375rem;
    background-color: white;
  }

  .ally-radio input {
    visibility: hidden;
  }

  .ally-radio-selected {
    color: white;
    background-color: var(--colorTheme);
  }

  .ally-tab label {
    margin-bottom: 0;
  }
</style>
