<!--
作成者：Lee HyeonSeok
作成日：2020.08.04
画面名：ログイン画面認証ビュー
機能：ログイン画面認証ビュー
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <signIn
      v-if="displayMap.showSignIn"
      @forgotPassword="
        data => {
          user = data;
        }
      "
      @confirmSignUp="
        data => {
          user = data;
        }
      "
      @confirmsignupcustomflow="
        data => {
          user = data;
        }
      "
    ></signIn>
    <forgotPassword v-if="displayMap.showForgotPassword"></forgotPassword>
    <requireNewPassword
      v-if="displayMap.requireNewPassword"
      :user="user"
      rules="password"
    ></requireNewPassword>
    <confirmSignUp v-if="displayMap.confirmSignUp" :user="user"></confirmSignUp>
  </div>
</template>

<script>
  import { AmplifyEventBus } from 'aws-amplify-vue';
  import signIn from './SignIn';
  import forgotPassword from './ForgotPassword';
  import requireNewPassword from './RequireNewPassword';
  import store from '../../../store';
  import confirmSignUp from './confirmSignUp';
  import { API, Auth, graphqlOperation } from 'aws-amplify';
  import { 
    getSystemMaster, 
    getCognitoAttributes, 
    getCompanyTemporaryStorage,
  }from '../../../graphql/queries';
  import gv from '@/mixins/globalValiables';
  import Vue from 'vue';
  Vue.mixin(gv);
  import cp from "./SignUp/companyRegister";

  export default {
    name: 'Authenticator',
    data: function() {
      return {
        user: {
          username: null,
          password: null,
        },
        displayMap: {},
      };
    },
    mixins:[cp],
    mounted: async function() {
      AmplifyEventBus.$once('localUser', user => {
        // 2021/10/26 H.Honma
        // 本番環境だと何故か拡張属性を取得できない。
        // →Lambda で取得し user に設定する。
        API.graphql(
          graphqlOperation(getCognitoAttributes, {
            id: user.username,
          })
        ).then(result => {
          const attributes = JSON.parse(result.data.getCognitoAttributes);
          if (user.attributes == undefined) {
            user.attributes = attributes;
          } else {
            for (let [key, value] of Object.entries(attributes)) {
              if (key.startsWith('custom:')) {
                user.attributes[key] = value;
              }
            }
          }

          store.commit('setUser', user);
          // ライセンス保有機能更新
          this._updateLicensedFunctions().then(() => {
            //会社が登録されてない場合、会社登録ページを出力
            if (user.signInUserSession.accessToken.payload['cognito:groups'] === undefined) {
              AmplifyEventBus.$emit('authState', 'confirmsignupcustomflow');
            } else {
              this.$router.push({
                path:
                  user && user.signInUserSession.accessToken.payload['cognito:groups'] !== undefined
                    ? '/DashBoard'
                    : '/Login',
              });
            }
          });
        });
      });
      AmplifyEventBus.$once('authState', data => {
        this.displayMap = this.updateDisplayMap(data);
      });
      this.displayMap = this.updateDisplayMap('signedOut');
    },
    methods: {
      changeDiplayMap: function(state){
        this.displayMap = this.updateDisplayMap(state)
      },
      updateDisplayMap: function(state) {
        return {
          showSignIn: state === 'signedOut' || state === 'signIn',
          showForgotPassword: state === 'forgotPassword',
          requireNewPassword: state === 'requireNewPassword',
          confirmSignUp: state === 'confirmSignUp',
          confirmsignupcustomflow: state === 'confirmsignupcustomflow',
        };
      },
      getErrorString: function(code, isSignin) {
        var message = null;

        switch (code) {
          case 'UserNotFoundException':
          case 'NotAuthorizedException':
          case 'InvalidPasswordException':
            message = isSignin
              ? 'E-mailまたはパスワードを確認してください'
              : 'E-mailを確認してください。';
            break;
          case 'TooManyRequestsException':
          case 'TooManyFailedAttemptsException':
            message = 'しばらくしてからもう一度実行してください';
            break;
          case 'UserNotConfirmedException':
            message = 'Eメール認証コードを入力してください';
            break;
          case 'LimitExceededException':
            message =
              '認証コードの送信制限回数を超えました。しばらくしてからもう一度実行してください';
            break;
          case 'ExpiredCodeException':
            message =
              '認証コードの有効期限が切れました。「認証コード再送信」ボタンをクリックしてください';
            break;
          case 'CodeMismatchException':
            message = '認証コードが一致しません';
            break;
          default:
            message = 'システムエラーが発生しました';
        }

        return message;
      },
      // ライセンスマスタ読込
      _loadLicenseMaster: async function() {
        // ロード
        const result = await API.graphql(
          graphqlOperation(getSystemMaster, {
            classification: 'license',
            classificationDetail: '1',
          })
        );

        // 文字列⇒JSON変換
        let masters = [];
        result.data.getSystemMaster.value.forEach(element => {
          masters.push(JSON.parse(element));
        });

        return masters;
      },
      // ライセンス保有機能更新
      _updateLicensedFunctions: async function() {
        await this.updateSysDate(); // システム日付更新
        if (this.$store.state.user.attributes == null) {
          return;
        }
        let functions = [];
        const masters = await this._loadLicenseMaster();
        const attribute = this.$store.state.user.attributes['custom:licenses'];
        const licenses = attribute == null ? [] : attribute.split(',');
        for (let license of licenses) {
          const master = masters.find(v => {
            return String(v.id) == String(license);
          });
          if (master != null) {
            if (master.functions == null) {
              // 単機能購入時
              functions.push(master.id);
            } else {
              // パック購入時
              for (let f of master.functions) {
                const m = masters.find(v => {
                  return String(v.id) == String(f);
                });
                functions.push(m.id);
              }
            }
          }
        }
        functions = [...new Set(functions)]; // 重複排除
        this.$store.commit('setFunctions', functions);
      },
      /**
       * 認証コード入力画面でリロードしてしまった場合、
       * 再認証で会社情報を登録する
       */
      reloadAfterCompnay: async function(email, userSub){
        try{
          //一時保存した会社情報を呼び出す
          const result = await API.graphql(
            graphqlOperation(getCompanyTemporaryStorage, {
              username: userSub,
            })
          );
          let storageJson = JSON.parse(result.data.getCompanyTemporaryStorage.storageJson);

          //会社情報の登録を行う
          await this.companyRegister(storageJson, email, userSub);

          //ログイン状態の認証を行う
          let user = await Auth.currentAuthenticatedUser({ bypassCache: true });
          store.commit('setUser', user);

          //ログイン後ページ遷移処理
          this.$router.push('/DashBoard');

        }catch(e){
          console.error("再登録エラー",e);
        }
      },
    },
    components: {
      signIn,
      forgotPassword,
      requireNewPassword,
      confirmSignUp,
    },
  };
</script>
