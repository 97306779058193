<template>
  <div class="margin-bottom">
    <div class="info-box">
      <b-overlay :show="loading" opacity="0.25">
        <b-container class="main">
          <b-row class="info-title">
            ALLY-PRO® 料金表
          </b-row>
          <b-row class="info-content form-control overflow-auto " readonly>
            <b-col style="padding:0;">
              <b-table
                thead-tr-class="serch-data-table-header"
                tbody-tr-class="serch-data-table-body"
                striped
                bordered
                :items="licenseItems"
                :fields="fields"
              >
                <!-- カラム設定 -->
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.width }"
                  />
                </template>
                <!-- ライセンス名称 -->
                <template v-slot:cell(name)="row">
                  {{ row.item.name }}
                </template>
                <!-- 単価表示 -->
                <template v-slot:cell(price)="row">
                  {{ row.item.price | currency }}円／１ライセンス
                </template>
              </b-table>
              <span v-if="!loading">
                <br />
                <span class="appendix" v-for="(text, i) in appendixes" :key="i">
                  {{ text }}<br />
                </span>
                <br />
                <b>【支払日】</b><br />
                ・利用月の翌月２７日<br />
                <br />
                <b>【支払方法】</b><br />
                ・クレジットカード（VISA、Master、JCB、AMEX、ダイナース）<br />
                ・銀行振込<br />
                ・口座振替<br />
                <br />
              </span>
            </b-col>
          </b-row>
        </b-container>
        <!-- ロード中 -->
        <template #overlay>
          <Load />
        </template>
      </b-overlay>
    </div>
  </div>
</template>
<script>
  import awsconfig from '@/aws-exports';
  import { API, Auth } from 'aws-amplify';
  import Load from '@/components/Common/Load';
  import Common from '@/views/License/js/Common';

  API.configure(awsconfig);

  // ライセンス一覧のヘッダ
  const headers = [
    {
      key: 'name',
      label: 'ライセンス名',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'price',
      label: '月額利用料（税込）',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
  ];
  export default {
    components: {
      Load,
    },
    mixins: [Common],
    data: function() {
      return {
        fields: headers, // ライセンス一覧のヘッダ
        licenseItems: [], // ライセンス
        loading: true, // ロード中
        appendixes: [
          '※１　利用料は、当月１日から当月末までの１ヶ月分を翌月２７日にお支払いいただきます。',
          '※２　月の途中にライセンスの利用申込みを行った場合や、利用申込みを行った月にライセンスを解約した場合でも、',
          '　　　１ヶ月分の利用料が発生します。',
          '※３　月の途中でライセンスを解約した場合、日割り計算による返金は行っておりません。',
          '※４　解約の取り消しはできません。解約後に再度利用申込みを行った場合は料金が二重で発生することがございます。',
          '※５　使用料は予告なく変更することがございます。',
        ],
      };
    },
    mounted: async function() {
      let dummy = false;
      try {
        // 未サインイン時、ダミーユーザーでサインイン
        try {
          await Auth.currentAuthenticatedUser();
        } catch (e) {
          await Auth.signIn(
            process.env.VUE_APP_UNAUTHENTICATED_ID,
            process.env.VUE_APP_UNAUTHENTICATED_PWD
          );
          dummy = true;
        }
        // マスタ取得
        this.master = await this.loadMaster();
        let items = [];
        this.master.forEach(m => {
          if (m.id > 0) {
            // 期間追加
            const options = [];
            m.prices.forEach(p => {
              if (p.period.unit == 'month') {
                options.push({
                  value: 0,
                  text: this.showPeriod(p.period),
                  price: p.unitPrice,
                  tax: p.tax,
                  period: p.period,
                });
              }
            });

            // ライセンス追加
            items.push({
              id: m == undefined ? '' : m.id,
              name: m == undefined ? '' : m.name,
              price: options[0].price + options[0].tax,
              period: { selected: 0, options: options },
              quantity: 0,
              amount: 0,
            });
          }

          // ライセンス追加
          this.licenseItems = items;
        });
      } finally {
        this.loading = false;
        if (dummy) {
          // サインアウト
          await Auth.signOut();
        }
      }
    },
  };
</script>
<style scoped>
  .margin-bottom {
    margin-bottom: 15px;
  }
  .info-box {
    background-color: rgba(43, 160, 132, 0.2);
  }
  .info-title {
    font-weight: bold;
    padding-top: 5px;
    margin-left: -5px;
  }
  .info-content {
    width: calc(30px + 100%);
    height: 35rem;
    font-size: 14px;
  }
  .info-content table td:first-child {
    display: flex;
  }
  .appendix {
    font-size: small;
    width: 100%;
  }
  .appendix .row .col:first-child {
    max-width: 3rem;
    padding-right: 0;
  }
</style>
