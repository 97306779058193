var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"RealEstateAcquisitionTax"}},[_c('b-card',{staticClass:"category",attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabRealEstateAcquisitionTax",modifiers:{"tabRealEstateAcquisitionTax":true}}],attrs:{"id":"BtnRealEstateAcquisitionTax","variant":"link"}},[_vm._v(" 不動産取得税 ")])],1),_c('b-collapse',{attrs:{"id":"tabRealEstateAcquisitionTax","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v(" 種別 ")]),_c('b-col',{staticClass:"title"},[_vm._v(" ※種類 ")]),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-row',{staticStyle:{"padding":"0"}},[_c('b-col',{staticClass:"title-center bold"},[_vm._v(" 不動産取得税 ")]),_c('b-col',{attrs:{"cols":"2"}})],1)],1)],1)],1),_c('b-col',{staticClass:"title-center",staticStyle:{"padding":"0"},attrs:{"cols":"2"}},[_vm._v(" 変更 ")])],1),_c('b-row',[_c('b-col',{staticClass:"title",attrs:{"cols":"2"}},[_vm._v("土地")]),_c('b-col'),_c('b-col',[_c('b-row',{staticStyle:{"padding":"0"}},[_c('b-col',{staticClass:"control"},[(
                      !_vm.investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[0]
                    )?_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.calculated(0))}}):_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"roundType":"down"},model:{value:(_vm.investmentAnalytics.initialInvestment.realEstateAcquisitionTax[0]),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.realEstateAcquisitionTax, 0, $$v)},expression:"investmentAnalytics.initialInvestment.realEstateAcquisitionTax[0]"}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"checkbox",staticStyle:{"padding-top":"0.5em"},attrs:{"cols":"2"}},[_c('b-form-checkbox',{staticStyle:{"padding-left":"2em"},attrs:{"id":"realEstateAcquisitionTax_0","name":"realEstateAcquisitionTax_0","size":"lg"},on:{"change":function($event){return _vm.changeCheckBox(0)}},model:{value:(
                  _vm.investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[0]
                ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax, 0, $$v)},expression:"\n                  investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[0]\n                "}})],1)],1),_vm._l((_vm.investmentAnalytics.existingBuildingCount),function(id){return _c('b-row',{key:("for01-" + id)},[_c('b-col',{staticClass:"title",attrs:{"cols":"2"}},[_vm._v("既存建物"+_vm._s(id))]),_c('b-col',[(
                  _vm.investmentAnalytics.initialInvestment.propertyDescription[id].kind == '共同住宅'
                )?_c('b-form-input',{attrs:{"disabled":"","value":"住宅（共同住宅）"}}):_c('b-form-input',{attrs:{"disabled":"","value":_vm.investmentAnalytics.initialInvestment.propertyDescription[id].kind}})],1),_c('b-col',[_c('b-row',{staticStyle:{"padding":"0"}},[_c('b-col',[(
                      !_vm.investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[id]
                    )?_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.calculated(id))}}):_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"roundType":"down"},model:{value:(_vm.investmentAnalytics.initialInvestment.realEstateAcquisitionTax[id]),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.realEstateAcquisitionTax, id, $$v)},expression:"investmentAnalytics.initialInvestment.realEstateAcquisitionTax[id]"}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center",attrs:{"cols":"2"}},[_c('b-form-checkbox',{staticStyle:{"padding-left":"2em"},attrs:{"id":("realEstateAcquisitionTax_1_" + id),"name":("realEstateAcquisitionTax_1_" + id),"size":"lg"},on:{"change":function($event){return _vm.changeCheckBox(id)}},model:{value:(
                  _vm.investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[id]
                ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax, id, $$v)},expression:"\n                  investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[id]\n                "}})],1)],1)}),_vm._l((_vm.investmentAnalytics.newBuildingCount),function(id){return _c('b-row',{key:("for02-" + id)},[_c('b-col',{staticClass:"title",attrs:{"cols":"2"}},[_vm._v("新築建物"+_vm._s(id))]),_c('b-col',[(
                  _vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id].kind ==
                    '共同住宅'
                )?_c('b-form-input',{attrs:{"disabled":"","value":"住宅（共同住宅）"}}):_c('b-form-input',{attrs:{"disabled":"","value":_vm.investmentAnalytics.initialInvestment.propertyDescription[10 + id].kind}})],1),_c('b-col',[_c('b-row',{staticStyle:{"padding":"0"}},[_c('b-col',[(
                      !_vm.investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[
                        10 + id
                      ]
                    )?_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.calculated(10 + id))}}):_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"roundType":"down"},model:{value:(
                      _vm.investmentAnalytics.initialInvestment.realEstateAcquisitionTax[10 + id]
                    ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.realEstateAcquisitionTax, 10 + id, $$v)},expression:"\n                      investmentAnalytics.initialInvestment.realEstateAcquisitionTax[10 + id]\n                    "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center",attrs:{"cols":"2"}},[_c('b-form-checkbox',{staticStyle:{"padding-left":"2em"},attrs:{"id":("realEstateAcquisitionTax_2_" + id),"name":("realEstateAcquisitionTax2_" + id),"size":"lg"},on:{"change":function($event){return _vm.changeCheckBox(10 + id)}},model:{value:(
                  _vm.investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[10 + id]
                ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax, 10 + id, $$v)},expression:"\n                  investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[10 + id]\n                "}})],1)],1)}),_c('b-row',[_c('b-col',{staticClass:"title",attrs:{"cols":"2"}},[_vm._v("付属建物")]),_c('b-col'),_c('b-col',[_c('b-row',{staticStyle:{"padding":"0"}},[_c('b-col',{staticClass:"control"},[(
                      !_vm.investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[21]
                    )?_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.calculated(21))}}):_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"roundType":"down"},model:{value:(_vm.investmentAnalytics.initialInvestment.realEstateAcquisitionTax[21]),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.realEstateAcquisitionTax, 21, $$v)},expression:"investmentAnalytics.initialInvestment.realEstateAcquisitionTax[21]"}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"checkbox",staticStyle:{"padding-top":"0.5em"},attrs:{"cols":"2"}},[_c('b-form-checkbox',{staticStyle:{"padding-left":"2em"},attrs:{"id":"realEstateAcquisitionTax_21","name":"realEstateAcquisitionTax_21","size":"lg"},on:{"change":function($event){return _vm.changeCheckBox(21)}},model:{value:(
                  _vm.investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[21]
                ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax, 21, $$v)},expression:"\n                  investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[21]\n                "}})],1)],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }