<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：長期修繕計画
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="RepairPlan">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button
          id="btnRepairPlan"
          variant="link"
          v-b-toggle.tabRepairPlan
          @click="viewFlag = true"
        >
          長期修繕計画
        </b-button>
      </b-card-header>
      <b-collapse id="tabRepairPlan" role="tabpanel" v-if="viewFlag">
        <b-card-body>
          <b-tabs
            fill
            pills
            class="card"
            v-model="investmentAnalytics.longTermRepairPlan.isApproximation"
          >
            <b-tab id="approximation" title="概算入力">
              <b-card-body style="padding-top:0;">
                <b-container style="padding:0;margin-left:1rem;max-width:640px;">
                  <b-row style="height:3em;">
                    <b-col class="title-center bold">項目</b-col>
                    <b-col cols="3" class="title-center bold">
                      <b-row>
                        <b-col>
                          周期
                        </b-col>
                        <b-col cols="3" class="unit" />
                      </b-row>
                    </b-col>
                    <b-col class="title-center bold">
                      <b-row>
                        <b-col>
                          費用
                        </b-col>
                        <b-col cols="3" class="unit" />
                      </b-row>
                    </b-col>
                    <b-col cols="1" />
                  </b-row>
                  <b-row
                    v-for="(item, id) in investmentAnalytics.longTermRepairPlan.approximations"
                    v-bind:key="`approximateItems-${id}`"
                  >
                    <b-col class="title-left">
                      <allyText :name="'name-' + id" v-model="item.name" />
                    </b-col>
                    <b-col cols="3">
                      <b-row>
                        <b-col>
                          <allyText
                            :name="'frequency-' + id"
                            type="number"
                            class="number"
                            :separation="true"
                            halfWidth
                            min="1"
                            max="45"
                            v-model="item.frequency"
                            roundType="down"
                          />
                        </b-col>
                        <b-col cols="3" class="unit">年毎</b-col>
                      </b-row>
                    </b-col>
                    <b-col>
                      <b-row>
                        <b-col>
                          <allyText
                            :name="'cost-' + id"
                            type="number"
                            class="number"
                            :separation="true"
                            halfWidth
                            v-model="item.cost"
                            roundType="down"
                          />
                        </b-col>
                        <b-col cols="3" class="unit">円/年</b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="1">
                      <b-button variant="ally" @click="deleteItem(id)" v-if="id > 0">
                        <i class="ti-minus" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col />
                    <b-col cols="1">
                      <b-button id="addItem" variant="ally" @click="addItem">
                        <i class="ti-plus" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card-body>
            </b-tab>
            <b-tab id="detail" title="詳細入力">
              <b-card-group columns style="padding:1em;column-count:1;">
                <b-card no-body class="category">
                  <b-card-header role="tab">
                    <b-button variant="link" v-b-toggle.tabRepairPlan1>
                      大規模修繕
                    </b-button>
                  </b-card-header>
                  <b-collapse id="tabRepairPlan1" role="tabpanel" v-if="lazLoading[0]">
                    <b-card-body>
                      <b-container>
                        <b-row style="height: 2em">
                          <b-col>
                            <b-row>
                              <b-col class="title bold">細目</b-col>
                              <b-col cols="2" class="title-center bold">標準周期</b-col>
                              <b-col cols="2" class="title-center bold" style="padding:0"
                                >回数</b-col
                              >
                              <b-col class="title">
                                <b-row>
                                  <b-col class="text-center bold">実施時期</b-col>
                                  <b-col cols="3" />
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col>
                            <b-row>
                              <b-col class="title">
                                <b-row>
                                  <b-col class="text-center bold">数量</b-col>
                                  <b-col cols="3" />
                                </b-row>
                              </b-col>
                              <b-col class="title">
                                <b-row>
                                  <b-col class="text-center bold">単価</b-col>
                                  <b-col cols="2" />
                                </b-row>
                              </b-col>
                              <b-col class="title">
                                <b-row>
                                  <b-col class="text-center bold">費用</b-col>
                                  <b-col cols="2" />
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col cols="1" class="title-center" style="padding:0;">
                            変更
                          </b-col>
                        </b-row>
                        <b-row
                          v-for="id in majorRenovation.length"
                          v-bind:key="`majorRenovation-${id}`"
                        >
                          <b-col>
                            <b-row>
                              <b-col class="title">
                                {{ majorRenovation[id - 1].title }}
                              </b-col>
                              <b-col cols="2" class="title-center">
                                {{ majorRenovation[id - 1].cycle }}
                                {{ majorRenovation[id - 1].cycle !== undefined ? '年' : '' }}
                              </b-col>
                              <b-col cols="2" class="title-center">
                                {{ majorRenovation[id - 1].subtitle }}
                              </b-col>
                              <b-col>
                                <b-row>
                                  <b-col class="component">
                                    <allyText
                                      :name="'implementationTime' + id"
                                      type="number"
                                      class="number"
                                      :separation="true"
                                      halfWidth
                                      v-model="
                                        investmentAnalytics.longTermRepairPlan.majorRenovation[
                                          id - 1
                                        ].implementationTime
                                      "
                                      roundType="down"
                                    />
                                  </b-col>
                                  <b-col cols="3" class="unit">年後</b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col>
                            <b-row>
                              <b-col>
                                <b-row>
                                  <b-col class="component">
                                    <allyText
                                      :name="'majorRenovation-quantity' + id"
                                      type="number"
                                      class="number"
                                      :separation="true"
                                      halfWidth
                                      v-model="
                                        investmentAnalytics.longTermRepairPlan.majorRenovation[
                                          id - 1
                                        ].quantity
                                      "
                                      roundType="down"
                                    />
                                  </b-col>
                                  <b-col cols="3" class="unit">
                                    {{ majorRenovation[id - 1].unit }}
                                  </b-col>
                                </b-row>
                              </b-col>
                              <b-col>
                                <b-row>
                                  <b-col class="component">
                                    <b-form-input
                                      disabled
                                      class="currency"
                                      :value="majorRenovation[id - 1].unitPrice | currency"
                                    />
                                  </b-col>
                                  <b-col cols="1" class="unit">円</b-col>
                                </b-row>
                              </b-col>
                              <b-col>
                                <b-row>
                                  <b-col class="component">
                                    <!-- 計算値 -->
                                    <b-form-input
                                      disabled
                                      class="currency"
                                      :value="
                                        (Math.floor(
                                          investmentAnalytics.longTermRepairPlan.majorRenovation[
                                            id - 1
                                          ].quantity
                                        ) *
                                          majorRenovation[id - 1].unitPrice)
                                          | currency
                                      "
                                      v-if="
                                        investmentAnalytics.longTermRepairPlan.majorRenovation[
                                          id - 1
                                        ].isChanged == false
                                      "
                                    />
                                    <!--入力値-->
                                    <allyText
                                      :name="'majorRenovation-amount' + id"
                                      type="number"
                                      class="number"
                                      :separation="true"
                                      halfWidth
                                      v-model="
                                        investmentAnalytics.longTermRepairPlan.majorRenovation[
                                          id - 1
                                        ].amount
                                      "
                                      roundType="down"
                                      v-else
                                    />
                                  </b-col>
                                  <b-col cols="1" class="unit">円</b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col cols="1" class="title-center checkbox">
                            <b-form-checkbox
                              v-model="
                                investmentAnalytics.longTermRepairPlan.majorRenovation[id - 1]
                                  .isChanged
                              "
                              size="lg"
                              style="padding-left:2em"
                              @change="changeCheckBox('majorRenovation', id - 1)"
                            >
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="category">
                  <b-card-header role="tab">
                    <b-button id="btnRepairPlan2" variant="link" v-b-toggle.tabRepairPlan2>
                      設備更新
                    </b-button>
                  </b-card-header>
                  <b-collapse id="tabRepairPlan2" role="tabpanel" v-if="lazLoading[1]">
                    <b-card-body>
                      <b-container>
                        <b-row style="height: 2em">
                          <b-col>
                            <b-row>
                              <b-col class="title bold">細目</b-col>
                              <b-col cols="2" class="title-center bold">標準周期</b-col>
                              <b-col cols="2" class="title-center bold" style="padding:0">
                                回数
                              </b-col>
                              <b-col>
                                <b-row>
                                  <b-col class="title-center bold">実施時期</b-col>
                                  <b-col cols="4" />
                                </b-row> </b-col
                            ></b-row>
                          </b-col>
                          <b-col>
                            <b-row>
                              <b-col>
                                <b-row>
                                  <b-col class="title-center bold">数量</b-col>
                                  <b-col cols="4" />
                                </b-row>
                              </b-col>
                              <b-col>
                                <b-row>
                                  <b-col class="title-center bold">単価</b-col>
                                  <b-col cols="2" />
                                </b-row>
                              </b-col>
                              <b-col class="title-center bold">
                                <b-row>
                                  <b-col class="title-center bold">費用</b-col>
                                  <b-col cols="2" />
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>

                          <b-col cols="1" class="title-center" style="padding:0;">
                            変更
                          </b-col>
                        </b-row>
                        <b-row
                          v-for="id in equipmentRenewal.length"
                          v-bind:key="`equipmentRenewal-${id}`"
                        >
                          <b-col>
                            <b-row>
                              <b-col class="title"> {{ equipmentRenewal[id - 1].title }}</b-col>
                              <b-col cols="2" class="title-center">
                                {{ equipmentRenewal[id - 1].cycle }}
                                {{ equipmentRenewal[id - 1].cycle !== undefined ? '年' : '' }}
                              </b-col>
                              <b-col cols="2" class="title-center">
                                {{ equipmentRenewal[id - 1].subtitle }}</b-col
                              >
                              <b-col>
                                <b-row>
                                  <b-col class="component">
                                    <allyText
                                      type="number"
                                      class="number"
                                      :separation="true"
                                      halfWidth
                                      v-model="
                                        investmentAnalytics.longTermRepairPlan.equipmentRenewal[
                                          id - 1
                                        ].implementationTime
                                      "
                                      roundType="down"
                                    />
                                  </b-col>
                                  <b-col cols="3" class="unit">年後</b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col>
                            <b-row>
                              <b-col>
                                <b-row>
                                  <b-col class="component">
                                    <allyText
                                      type="number"
                                      class="number"
                                      :separation="true"
                                      halfWidth
                                      v-model="
                                        investmentAnalytics.longTermRepairPlan.equipmentRenewal[
                                          id - 1
                                        ].quantity
                                      "
                                      roundType="down"
                                    />
                                  </b-col>
                                  <b-col cols="3" class="unit">
                                    {{ equipmentRenewal[id - 1].unit }}</b-col
                                  >
                                </b-row>
                              </b-col>
                              <b-col>
                                <b-row>
                                  <b-col class="component">
                                    <b-form-input
                                      disabled
                                      class="currency"
                                      :value="equipmentRenewal[id - 1].unitPrice | currency"
                                    />
                                  </b-col>
                                  <b-col cols="1" class="unit">円</b-col>
                                </b-row>
                              </b-col>
                              <b-col>
                                <b-row>
                                  <!--計算値-->
                                  <b-col class="component">
                                    <b-form-input
                                      disabled
                                      class="currency"
                                      :value="
                                        (Math.floor(
                                          investmentAnalytics.longTermRepairPlan.equipmentRenewal[
                                            id - 1
                                          ].quantity
                                        ) *
                                          equipmentRenewal[id - 1].unitPrice)
                                          | currency
                                      "
                                      v-if="
                                        investmentAnalytics.longTermRepairPlan.equipmentRenewal[
                                          id - 1
                                        ].isChanged == false
                                      "
                                    />
                                    <!--入力値-->
                                    <allyText
                                      :name="'equipmentRenewal-amount' + id"
                                      type="number"
                                      class="number"
                                      :separation="true"
                                      halfWidth
                                      v-model="
                                        investmentAnalytics.longTermRepairPlan.equipmentRenewal[
                                          id - 1
                                        ].amount
                                      "
                                      roundType="down"
                                      v-else
                                    />
                                  </b-col>
                                  <b-col cols="1" class="unit">円</b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col cols="1" class="title-center checkbox">
                            <b-form-checkbox
                              v-model="
                                investmentAnalytics.longTermRepairPlan.equipmentRenewal[id - 1]
                                  .isChanged
                              "
                              size="lg"
                              style="padding-left:2em"
                              @change="changeCheckBox('equipmentRenewal', id - 1)"
                            >
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="category">
                  <b-card-header role="tab">
                    <b-button variant="link" v-b-toggle.tabRepairPlan3>
                      外構
                    </b-button>
                  </b-card-header>
                  <b-collapse id="tabRepairPlan3" role="tabpanel" v-if="lazLoading[2]">
                    <b-card-body>
                      <b-container>
                        <b-row style="height: 2em">
                          <b-col>
                            <b-row>
                              <b-col class="title bold">細目</b-col>
                              <b-col cols="2" class="title-center bold">標準周期</b-col>
                              <b-col cols="2" class="title-center bold" style="padding:0"
                                >回数</b-col
                              >
                              <b-col>
                                <b-row>
                                  <b-col class="title-center bold">実施時期</b-col>
                                  <b-col cols="4" />
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col>
                            <b-row>
                              <b-col>
                                <b-row>
                                  <b-col class="title-center bold">数量</b-col>
                                  <b-col cols="4" />
                                </b-row>
                              </b-col>
                              <b-col>
                                <b-row>
                                  <b-col class="title-center bold">単価</b-col>
                                  <b-col cols="2" />
                                </b-row>
                              </b-col>
                              <b-col class="title-center bold">
                                <b-row>
                                  <b-col class="title-center bold">費用</b-col>
                                  <b-col cols="2" />
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>

                          <b-col cols="1" class="title-center" style="padding:0;">
                            変更
                          </b-col>
                        </b-row>
                        <b-row
                          v-for="id in externalStructure.length"
                          v-bind:key="`externalStructure-${id}`"
                        >
                          <b-col>
                            <b-row>
                              <b-col class="title"> {{ externalStructure[id - 1].title }}</b-col>
                              <b-col cols="2" class="title-center">
                                {{ externalStructure[id - 1].cycle }}
                                {{ externalStructure[id - 1].cycle !== undefined ? '年' : '' }}
                              </b-col>
                              <b-col cols="2" class="title-center">
                                {{ externalStructure[id - 1].subtitle }}</b-col
                              >
                              <b-col>
                                <b-row>
                                  <b-col class="component">
                                    <allyText
                                      type="number"
                                      class="number"
                                      :separation="true"
                                      halfWidth
                                      v-model="
                                        investmentAnalytics.longTermRepairPlan.externalStructure[
                                          id - 1
                                        ].implementationTime
                                      "
                                      roundType="down"
                                    />
                                  </b-col>
                                  <b-col cols="3" class="unit">年後</b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col>
                            <b-row>
                              <b-col>
                                <b-row>
                                  <b-col class="component">
                                    <allyText
                                      type="number"
                                      class="number"
                                      :separation="true"
                                      halfWidth
                                      v-model="
                                        investmentAnalytics.longTermRepairPlan.externalStructure[
                                          id - 1
                                        ].quantity
                                      "
                                      roundType="down"
                                    />
                                  </b-col>
                                  <b-col cols="3" class="unit">
                                    {{ externalStructure[id - 1].unit }}
                                  </b-col>
                                </b-row>
                              </b-col>
                              <b-col>
                                <b-row>
                                  <b-col class="component">
                                    <b-form-input
                                      disabled
                                      class="currency"
                                      :value="externalStructure[id - 1].unitPrice | currency"
                                    />
                                  </b-col>
                                  <b-col cols="1" class="unit">円</b-col>
                                </b-row>
                              </b-col>
                              <b-col>
                                <b-row>
                                  <!--計算値-->
                                  <b-col class="component">
                                    <b-form-input
                                      disabled
                                      class="currency"
                                      :value="
                                        (Math.floor(
                                          investmentAnalytics.longTermRepairPlan.externalStructure[
                                            id - 1
                                          ].quantity
                                        ) *
                                          externalStructure[id - 1].unitPrice)
                                          | currency
                                      "
                                      v-if="
                                        investmentAnalytics.longTermRepairPlan.externalStructure[
                                          id - 1
                                        ].isChanged == false
                                      "
                                    />
                                    <!--入力値-->
                                    <allyText
                                      :name="'externalStructure-amount' + id"
                                      type="number"
                                      class="number"
                                      :separation="true"
                                      halfWidth
                                      v-model="
                                        investmentAnalytics.longTermRepairPlan.externalStructure[
                                          id - 1
                                        ].amount
                                      "
                                      roundType="down"
                                      v-else
                                    />
                                  </b-col>
                                  <b-col cols="1" class="unit">円</b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col cols="1" class="title-center checkbox">
                            <b-form-checkbox
                              v-model="
                                investmentAnalytics.longTermRepairPlan.externalStructure[id - 1]
                                  .isChanged
                              "
                              size="lg"
                              style="padding-left:2em"
                              @change="changeCheckBox('externalStructure', id - 1)"
                            >
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="category">
                  <b-card-header role="tab">
                    <b-button variant="link" v-b-toggle.tabRepairPlan4>
                      その他
                    </b-button>
                  </b-card-header>
                  <b-collapse id="tabRepairPlan4" role="tabpanel" v-if="lazLoading[3]">
                    <b-card-body>
                      <b-container>
                        <b-row>
                          <b-col
                            ><b-row>
                              <b-col cols="8" class="title bold">細目</b-col>
                              <b-col>
                                <b-row>
                                  <b-col class="title-center bold">実施時期</b-col>
                                  <b-col cols="4" />
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col
                            ><b-row>
                              <b-col>
                                <b-row>
                                  <b-col class="title-center bold">数量</b-col>
                                  <b-col cols="2" />
                                </b-row>
                              </b-col>
                              <b-col>
                                <b-row>
                                  <b-col class="title-center bold">単価</b-col>
                                  <b-col cols="2" />
                                </b-row>
                              </b-col>
                              <b-col class="title-center bold">
                                <b-row>
                                  <b-col class="title-center bold">費用</b-col>
                                  <b-col cols="2" />
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col cols="1" class="title-center" style="padding:0;">
                            変更
                          </b-col>
                        </b-row>
                        <b-row v-for="id of 6" :key="`others-${id}`">
                          <b-col
                            ><b-row>
                              <b-col cols="8" class="component">
                                <allyText
                                  v-model="
                                    investmentAnalytics.longTermRepairPlan.others[id - 1].itemName
                                  "
                                />
                              </b-col>
                              <b-col>
                                <b-row>
                                  <b-col class="component">
                                    <allyText
                                      type="number"
                                      class="number"
                                      :separation="true"
                                      halfWidth
                                      v-model="
                                        investmentAnalytics.longTermRepairPlan.others[id - 1]
                                          .implementationTime
                                      "
                                      roundType="down"
                                    />
                                  </b-col>
                                  <b-col cols="3" class="unit">年後</b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col
                            ><b-row>
                              <b-col>
                                <b-row>
                                  <b-col class="component">
                                    <allyText
                                      type="number"
                                      class="number"
                                      :separation="true"
                                      halfWidth
                                      v-model="
                                        investmentAnalytics.longTermRepairPlan.others[id - 1]
                                          .quantity
                                      "
                                      roundType="down"
                                    />
                                  </b-col>
                                  <b-col cols="1" class="unit" />
                                </b-row>
                              </b-col>
                              <b-col>
                                <b-row>
                                  <b-col class="component">
                                    <allyText
                                      type="number"
                                      class="number"
                                      :separation="true"
                                      halfWidth
                                      v-model="
                                        investmentAnalytics.longTermRepairPlan.others[id - 1]
                                          .unitPrice
                                      "
                                      roundType="down"
                                    />
                                  </b-col>
                                  <b-col cols="1" class="unit">円</b-col>
                                </b-row>
                              </b-col>
                              <b-col>
                                <b-row>
                                  <b-col class="component">
                                    <!--計算値-->
                                    <b-form-input
                                      disabled
                                      class="currency"
                                      :value="
                                        (Math.floor(
                                          investmentAnalytics.longTermRepairPlan.others[id - 1]
                                            .quantity
                                        ) *
                                          Math.floor(
                                            investmentAnalytics.longTermRepairPlan.others[id - 1]
                                              .unitPrice
                                          ))
                                          | currency
                                      "
                                      v-if="
                                        investmentAnalytics.longTermRepairPlan.others[id - 1]
                                          .isChanged == false
                                      "
                                    />
                                    <!--入力値-->
                                    <allyText
                                      :name="'others-amount' + id"
                                      type="number"
                                      class="number"
                                      :separation="true"
                                      halfWidth
                                      v-model="
                                        investmentAnalytics.longTermRepairPlan.others[id - 1].amount
                                      "
                                      roundType="down"
                                      v-else
                                    />
                                  </b-col>
                                  <b-col cols="1" class="unit">円</b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col cols="1" class="title-center checkbox">
                            <b-form-checkbox
                              v-model="
                                investmentAnalytics.longTermRepairPlan.others[id - 1].isChanged
                              "
                              size="lg"
                              style="padding-left:2em"
                              @change="changeCheckBox('others', id - 1)"
                            >
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <div v-show="false">
                  <b-form-input readonly :value="total().toString()" />
                </div>
              </b-card-group>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Calculation from './Calculation';
  import Vue from 'vue';
  export default {
    mixins: [Calculation],
    components: {},
    data: function() {
      return {
        viewFlag: true,
        // 概要項目
        approximateItems: [
          {
            name: '大規模修繕',
            items: [
              { name: '屋根', index: 0 },
              { name: '外壁', index: 1 },
              { name: 'バルコニー床防水', index: 2 },
              { name: '開放廊下床防水', index: 3 },
              { name: '鉄部塗装', index: 4 },
              { name: '給水管更新', index: 5 },
              { name: '受水槽', index: 6 },
              { name: '高置水槽', index: 7 },
              { name: '揚水ポンプ', index: 8 },
              { name: '排水管', index: 9 },
              { name: '排水ポンプ', index: 10 },
              { name: 'ガス管', index: 11 },
              { name: '仮設工事', index: 12 },
            ],
          },
          {
            name: '設備更新',
            items: [
              { name: '空調設備', index: 13 },
              { name: '配電盤・幹線設備', index: 14 },
              { name: '電話設備', index: 15 },
              { name: 'テレビ共聴設備', index: 16 },
              { name: '消防設備', index: 17 },
              { name: 'EVカゴ内装', index: 18 },
              { name: 'EV更新', index: 19 },
              { name: '機械式P/部品交換', index: 20 },
              { name: '機械式P/更新', index: 21 },
            ],
          },
          {
            name: '外構',
            items: [
              { name: '舗装', index: 22 },
              { name: 'フェンス', index: 23 },
            ],
          },
          {
            name: 'その他',
            items: [
              { name: null, index: 24 },
              { name: null, index: 25 },
              { name: null, index: 26 },
              { name: null, index: 27 },
              { name: null, index: 28 },
              { name: null, index: 29 },
            ],
          },
        ],
        // 表示情報/大規模修繕費用
        majorRenovation: [
          {
            title: '屋根',
            subtitle: '1回目',
            unit: '㎡',
            keys: ['大規模修繕費用', '屋根'],
            cycle: 12,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '㎡',
            keys: ['大規模修繕費用', '屋根'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '㎡',
            keys: ['大規模修繕費用', '屋根'],
          },
          {
            title: '外壁',
            subtitle: '1回目',
            unit: '㎡',
            keys: ['大規模修繕費用', '外壁'],
            cycle: 12,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '㎡',
            keys: ['大規模修繕費用', '外壁'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '㎡',
            keys: ['大規模修繕費用', '外壁'],
          },
          {
            title: 'バルコニー床防水',
            subtitle: '1回目',
            unit: '㎡',
            keys: ['大規模修繕費用', 'バルコニー床防水'],
            cycle: 12,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '㎡',
            keys: ['大規模修繕費用', 'バルコニー床防水'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '㎡',
            keys: ['大規模修繕費用', 'バルコニー床防水'],
          },
          {
            title: '開放廊下床防水',
            subtitle: '1回目',
            unit: '㎡',
            keys: ['大規模修繕費用', '開放廊下床防水'],
            cycle: 12,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '㎡',
            keys: ['大規模修繕費用', '開放廊下床防水'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '㎡',
            keys: ['大規模修繕費用', '開放廊下床防水'],
          },
          {
            title: '鉄部塗装',
            subtitle: '1回目',
            unit: '箇所',
            keys: ['大規模修繕費用', '鉄部塗装'],
            cycle: 6,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '箇所',
            keys: ['大規模修繕費用', '鉄部塗装'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '箇所',
            keys: ['大規模修繕費用', '鉄部塗装'],
          },
          {
            title: '',
            subtitle: '4回目',
            unit: '箇所',
            keys: ['大規模修繕費用', '鉄部塗装'],
          },
          {
            title: '',
            subtitle: '5回目',
            unit: '箇所',
            keys: ['大規模修繕費用', '鉄部塗装'],
          },
          {
            title: '',
            subtitle: '6回目',
            unit: '箇所',
            keys: ['大規模修繕費用', '鉄部塗装'],
          },
          {
            title: '',
            subtitle: '7回目',
            unit: '箇所',
            keys: ['大規模修繕費用', '鉄部塗装'],
          },
          {
            title: '給水管更新',
            subtitle: '1回目',
            unit: '戸',
            keys: ['大規模修繕費用', '給水管'],
            cycle: 30,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '戸',
            keys: ['大規模修繕費用', '給水管'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '戸',
            keys: ['大規模修繕費用', '給水管'],
          },
          {
            title: '受水槽',
            subtitle: '1回目',
            unit: '個',
            keys: ['大規模修繕費用', '受水槽'],
            cycle: 25,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '個',
            keys: ['大規模修繕費用', '受水槽'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '個',
            keys: ['大規模修繕費用', '受水槽'],
          },
          {
            title: '高置水槽',
            subtitle: '1回目',
            unit: '個',
            keys: ['大規模修繕費用', '高置水槽'],
            cycle: 25,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '個',
            keys: ['大規模修繕費用', '高置水槽'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '個',
            keys: ['大規模修繕費用', '高置水槽'],
          },
          {
            title: '雑排水槽',
            subtitle: '1回目',
            unit: '個',
            keys: ['大規模修繕費用', '雑排水槽'],
            cycle: 25,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '個',
            keys: ['大規模修繕費用', '雑排水槽'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '個',
            keys: ['大規模修繕費用', '雑排水槽'],
          },
          {
            title: '揚水ポンプ',
            subtitle: '1回目',
            unit: '個',
            keys: ['大規模修繕費用', '揚水ポンプ'],
            cycle: 16,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '個',
            keys: ['大規模修繕費用', '揚水ポンプ'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '個',
            keys: ['大規模修繕費用', '揚水ポンプ'],
          },
          {
            title: '排水管',
            subtitle: '1回目',
            unit: '戸',
            keys: ['大規模修繕費用', '排水管'],
            cycle: 30,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '戸',
            keys: ['大規模修繕費用', '排水管'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '戸',
            keys: ['大規模修繕費用', '排水管'],
          },
          {
            title: '排水ポンプ',
            subtitle: '1回目',
            unit: '個',
            keys: ['大規模修繕費用', '排水ポンプ'],
            cycle: 16,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '個',
            keys: ['大規模修繕費用', '排水ポンプ'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '個',
            keys: ['大規模修繕費用', '排水ポンプ'],
          },
          {
            title: 'ガス管',
            subtitle: '1回目',
            unit: '戸',
            keys: ['大規模修繕費用', 'ガス管'],
            cycle: 30,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '戸',
            keys: ['大規模修繕費用', 'ガス管'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '戸',
            keys: ['大規模修繕費用', 'ガス管'],
          },
          {
            title: '仮設工事',
            subtitle: '1回目',
            unit: '戸',
            keys: ['大規模修繕費用', '仮設工事'],
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '戸',
            keys: ['大規模修繕費用', '仮設工事'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '戸',
            keys: ['大規模修繕費用', '仮設工事'],
          },
        ],
        // 表示情報/設備更新費用
        equipmentRenewal: [
          {
            title: '空調設備',
            subtitle: '1回目',
            unit: '個',
            keys: ['設備更新費用', '空調設備'],
            cycle: 15,
          },
          {
            subtitle: '2回目',
            unit: '個',
            keys: ['設備更新費用', '空調設備'],
          },
          {
            subtitle: '3回目',
            unit: '個',
            keys: ['設備更新費用', '空調設備'],
          },
          {
            title: '配電盤・幹線設備',
            subtitle: '1回目',
            unit: '戸',
            keys: ['設備更新費用', '配電盤・幹線設備'],
            cycle: 30,
          },
          {
            subtitle: '2回目',
            unit: '戸',
            keys: ['設備更新費用', '配電盤・幹線設備'],
          },
          {
            subtitle: '3回目',
            unit: '戸',
            keys: ['設備更新費用', '配電盤・幹線設備'],
          },
          {
            title: '電話設備',
            subtitle: '1回目',
            unit: '戸',
            keys: ['設備更新費用', '電話設備'],
            cycle: 30,
          },
          {
            subtitle: '2回目',
            unit: '戸',
            keys: ['設備更新費用', '電話設備'],
          },
          {
            subtitle: '3回目',
            unit: '戸',
            keys: ['設備更新費用', '電話設備'],
          },
          {
            title: 'テレビ共聴設備',
            subtitle: '1回目',
            unit: '個',
            keys: ['設備更新費用', 'テレビ共聴設備'],
            cycle: 15,
          },
          {
            subtitle: '2回目',
            unit: '個',
            keys: ['設備更新費用', 'テレビ共聴設備'],
          },
          {
            subtitle: '3回目',
            unit: '個',
            keys: ['設備更新費用', 'テレビ共聴設備'],
          },
          {
            title: '消防設備',
            subtitle: '1回目',
            unit: '個',
            keys: ['設備更新費用', '消防設備'],
            cycle: 20,
          },
          {
            subtitle: '2回目',
            unit: '個',
            keys: ['設備更新費用', '消防設備'],
          },
          {
            subtitle: '3回目',
            unit: '個',
            keys: ['設備更新費用', '消防設備'],
          },
          {
            title: 'EVカゴ内装',
            subtitle: '1回目',
            unit: '基',
            keys: ['設備更新費用', 'EVカゴ内装'],
            cycle: 15,
          },
          {
            subtitle: '2回目',
            unit: '基',
            keys: ['設備更新費用', 'EVカゴ内装'],
          },
          {
            subtitle: '3回目',
            unit: '基',
            keys: ['設備更新費用', 'EVカゴ内装'],
          },
          {
            title: 'EV更新',
            subtitle: '1回目',
            unit: '基',
            keys: ['設備更新費用', 'EV更新'],
            cycle: 30,
          },
          {
            subtitle: '2回目',
            unit: '基',
            keys: ['設備更新費用', 'EV更新'],
          },
          {
            subtitle: '3回目',
            unit: '基',
            keys: ['設備更新費用', 'EV更新'],
          },
          {
            title: '機械式P/部品交換',
            subtitle: '1回目',
            unit: '基',
            keys: ['設備更新費用', '機械式P/部品交換'],
            cycle: 5,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '基',
            keys: ['設備更新費用', '機械式P/部品交換'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '基',
            keys: ['設備更新費用', '機械式P/部品交換'],
          },
          {
            title: '',
            subtitle: '4回目',
            unit: '基',
            keys: ['設備更新費用', '機械式P/部品交換'],
          },
          {
            title: '',
            subtitle: '5回目',
            unit: '基',
            keys: ['設備更新費用', '機械式P/部品交換'],
          },
          {
            title: '',
            subtitle: '6回目',
            unit: '基',
            keys: ['設備更新費用', '機械式P/部品交換'],
          },
          {
            title: '',
            subtitle: '7回目',
            unit: '基',
            keys: ['設備更新費用', '機械式P/部品交換'],
          },
          {
            title: '',
            subtitle: '8回目',
            unit: '基',
            keys: ['設備更新費用', '機械式P/部品交換'],
          },
          {
            title: '',
            subtitle: '9回目',
            unit: '基',
            keys: ['設備更新費用', '機械式P/部品交換'],
          },
          {
            title: '機械式P/更新',
            subtitle: '1回目',
            unit: '基',
            keys: ['設備更新費用', '機械式P/更新'],
            cycle: 20,
          },
          {
            subtitle: '2回目',
            unit: '基',
            keys: ['設備更新費用', '機械式P/更新'],
          },
          {
            subtitle: '3回目',
            unit: '基',
            keys: ['設備更新費用', '機械式P/更新'],
          },
          {
            title: 'キュービクル',
            subtitle: '1回目',
            unit: '機',
            keys: ['設備更新費用', 'キュービクル'],
            cycle: 30,
          },
          {
            subtitle: '2回目',
            unit: '機',
            keys: ['設備更新費用', 'キュービクル'],
          },
          {
            subtitle: '3回目',
            unit: '機',
            keys: ['設備更新費用', 'キュービクル'],
          },
        ],
        // 表示情報/外構
        externalStructure: [
          {
            title: '舗装',
            subtitle: '1回目',
            unit: '㎡',
            keys: ['設備更新費用', '外構/舗装'],
            cycle: 10,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '㎡',
            keys: ['設備更新費用', '外構/舗装'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '㎡',
            keys: ['設備更新費用', '外構/舗装'],
          },
          {
            title: '',
            subtitle: '4回目',
            unit: '㎡',
            keys: ['設備更新費用', '外構/舗装'],
          },
          {
            title: 'フェンス',
            subtitle: '1回目',
            unit: '㎡',
            keys: ['設備更新費用', '外構/フェンス'],
            cycle: 20,
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '㎡',
            keys: ['設備更新費用', '外構/フェンス'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '㎡',
            keys: ['設備更新費用', '外構/フェンス'],
          },
          {
            title: 'その他',
            subtitle: '1回目',
            unit: '㎡',
            keys: ['設備更新費用', '外構/その他'],
          },
          {
            title: '',
            subtitle: '2回目',
            unit: '㎡',
            keys: ['設備更新費用', '外構/その他'],
          },
          {
            title: '',
            subtitle: '3回目',
            unit: '㎡',
            keys: ['設備更新費用', '外構/その他'],
          },
        ],
        lazLoading: [],
        lazNum: 8,
      };
    },
    created() {
      for (let i = 0; i < this.lazNum; i++) {
        this.lazLoading.push(false);
      }
      let count = 0;
      //ページが重たくなるので、段階敵に表示する
      let inter = setInterval(() => {
        Vue.set(this.lazLoading, count, true);

        count++;
        if (count >= this.lazNum) {
          clearInterval(inter);
        }
      }, 100);
    },
    mounted: function() {
      // 単価更新
      this.updateUnitPrice();
    },
    //    computed: {
    //      // 配電盤・幹線設備 数量
    //      countSwitchboard: function() {
    //        return [
    //          this.investmentAnalytics.longTermRepairPlan.equipmentRenewal[3].quantity,
    //          this.investmentAnalytics.longTermRepairPlan.equipmentRenewal[4].quantity,
    //          this.investmentAnalytics.longTermRepairPlan.equipmentRenewal[5].quantity,
    //        ];
    //      },
    //    },
    watch: {
      // マスタ更新時
      '$store.state.master.unitPrice': {
        handler: function() {
          // 単価更新
          this.updateUnitPrice();
        },
        deep: false,
      },
      // 配電盤・幹線設備 数量 変更時
      //      countSwitchboard: {
      //        handler: function(newVal, oldVal) {
      //          newVal.forEach((v, i) => {
      //            if (v != oldVal[i]) {
      //              // 電話設備 数量 を更新
      //              this.investmentAnalytics.longTermRepairPlan.equipmentRenewal[6 + i].quantity = v;
      //            }
      //          });
      //        },
      //      },
    },
    methods: {
      // 単価更新
      updateUnitPrice: function() {
        this.majorRenovation.forEach(v => {
          v.unitPrice = this.getUnitPrice(v.keys[0], v.keys[1]);
        });
        this.equipmentRenewal.forEach(v => {
          v.unitPrice = this.getUnitPrice(v.keys[0], v.keys[1]);
        });
        this.externalStructure.forEach(v => {
          v.unitPrice = this.getUnitPrice(v.keys[0], v.keys[1]);
        });
      },
      // 項目追加
      addItem: function() {
        this.investmentAnalytics.longTermRepairPlan.approximations.push({
          name: null,
          frequency: null,
          cost: null,
        });
      },
      // 項目削除
      deleteItem: function(id) {
        this.investmentAnalytics.longTermRepairPlan.approximations.splice(id, 1);
      },
      // チェックボックス変更時
      changeCheckBox: function(name, id) {
        // 計算値設定
        if (!this.investmentAnalytics.longTermRepairPlan[name][id].isChanged) {
          this.investmentAnalytics.longTermRepairPlan[name][id].amount =
            this.investmentAnalytics.longTermRepairPlan[name][id].quantity *
            (name == 'others'
              ? this.investmentAnalytics.longTermRepairPlan[name][id].unitPrice
              : this[name][id].unitPrice);
        }
        // 入力値クリア
        else {
          this.investmentAnalytics.longTermRepairPlan[name][id].amount = null;
        }
      },
      // 合計
      total: function() {
        let result = [];
        // 概算入力時
        if (this.investmentAnalytics.longTermRepairPlan.isApproximation == 0) {
          // 売却時期まで
          const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale));
          result = Array(years).fill(null);
          // 概要項目
          for (const item of this.investmentAnalytics.longTermRepairPlan.approximations) {
            if (item.frequency > 0 && item.cost > 0) {
              // 周期毎に費用加算
              for (let i = item.frequency - 1; i < years; i += item.frequency) {
                result[i] += item.cost;
              }
            }
          }
        }
        // 詳細入力時
        else {
          // 売却時期まで
          for (let year = 1; year <= Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); year++) {
            let subtotal = 0;
            // 大規模修繕
            this.majorRenovation.forEach((o, i) => {
              const mr = this.investmentAnalytics.longTermRepairPlan.majorRenovation[i];
              if (year == Math.floor(Number(mr.implementationTime))) {
                subtotal += mr.isChanged ? Math.floor(mr.amount) : Math.floor(mr.quantity) * Math.floor(o.unitPrice);
              }
            });
            // 設備更新
            this.equipmentRenewal.forEach((o, i) => {
              const er = this.investmentAnalytics.longTermRepairPlan.equipmentRenewal[i];
              if (year == Math.floor(Number(er.implementationTime))) {
                subtotal += er.isChanged ? Math.floor(er.amount) : Math.floor(er.quantity) * Math.floor(o.unitPrice);
              }
            });
            // 外構
            this.externalStructure.forEach((o, i) => {
              const er = this.investmentAnalytics.longTermRepairPlan.externalStructure[i];
              if (year == Math.floor(Number(er.implementationTime))) {
                subtotal += er.isChanged ? Math.floor(er.amount) : Math.floor(er.quantity) * Math.floor(o.unitPrice);
              }
            });
            // その他
            this.investmentAnalytics.longTermRepairPlan.others.forEach(v => {
              if (year == Math.floor(Number(v.implementationTime))) {
                subtotal += v.isChanged ? Math.floor(v.amount) : Math.floor(v.quantity) * Math.floor(v.unitPrice);
              }
            });

            result.push(subtotal == 0 ? null : subtotal);
          }
        }
        // グローバル変数に保持(収支計画書で参照)
        this.investmentAnalyticsTemp.longTermRepairPlanTotal = result;
        return result;
      },
    },
  };
</script>
<style scoped>
  #RepairPlan .row .row {
    padding-top: 0;
  }
</style>
