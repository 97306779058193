
import { API, graphqlOperation } from 'aws-amplify';
import { getSystemMaster } from '@/graphql/queries';

const keyList = [
  'interitanceMaster1', // 奥行価格補正率表
  'interitanceMaster2', // 側方路線影響加算率表
  'interitanceMaster3', // 二方路線影響加算率表
  'interitanceMaster4', // 不整形地補正率を算定する際の地積区分表
  'interitanceMaster5', // 不整形地補正率表
  'interitanceMaster6', // 間口狭小補正率表
  'interitanceMaster7', // 奥行長大補正率表
  'interitanceMaster8', // がけ地補正率表
  'interitanceMaster91', // 規模格差補正率を算定する際の表イ三大都市圏に所在する宅地(三大都市圏に所在する宅地)	
  'interitanceMaster92', // 規模格差補正率を算定する際の表ロ三大都市圏以外の地域に所在する宅地
  'interitanceMaster10', // 相続税計算税マスタ
  'interitanceMaster11', // 宅地造成費マスタ
  'interitanceMaster12', // 傾斜地造成費
  'interitanceMaster13', // 都市計画道路予定地の区域内にある宅地の評価
];
//9.2などのキーが必要なため、KeyNumを別途指定
const keyNum = [
    '1', // 奥行価格補正率表
    '2', // 側方路線影響加算率表
    '3', // 二方路線影響加算率表
    '4', // 不整形地補正率を算定する際の地積区分表
    '5', // 不整形地補正率表
    '6', // 間口狭小補正率表
    '7', // 奥行長大補正率表
    '8', // がけ地補正率表
    '9.1', // 規模格差補正率を算定する際の表イ三大都市圏に所在する宅地(三大都市圏に所在する宅地)	
    '9.2', // 規模格差補正率を算定する際の表ロ三大都市圏以外の地域に所在する宅地
    '10', // 相続税計算税マスタ
    '11', // 宅地造成費マスタ
    '12', // 傾斜地造成費
    '13', //都市計画道路予定地の区域内にある宅地の評価
  ];

export default async function loadMaster() {

  const systemMaster = await loadSystemMaster();
  if (systemMaster == null) {
    return "error"
  }

  return systemMaster;
}

async function loadSystemMaster() {
  const results = [];

  keyNum.forEach((_, index) => {
    results[index] = API.graphql(
      graphqlOperation(getSystemMaster, {
        classification: 'inheritanceTax',
        classificationDetail: keyNum[index],
      })
    );
  });

  const master = {};

  try {
    const valueList = await Promise.all(results);
    valueList.forEach((value, index) => {
      if (value.data.getSystemMaster == null) {
        throw new Error();
      }
      master[keyList[index]] = value.data.getSystemMaster.value;
    });
  } catch (error) {
    return null;
  }

  return master;
}
