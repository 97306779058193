<template>
  <div>
    <div class="row form-group my-3">
      <div class="col-md-12">
        <label for="family">家族情報</label>
      </div>
      <div class="col-12">
        <table
          class="table text-center ally-table"
          id="family"
          :class="mode == 'View' ? 'ally-table-readonly' : ''"
        >
          <thead>
            <tr>
              <th>姓名</th>
              <th>続柄</th>
              <th>電話番号</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in family" :key="row.Id">
              <td>{{ row.lastName }} {{ row.firstName }}</td>
              <td>{{ row.familyType }}</td>
              <td>{{ row.tel }}</td>
            <td>
              <b-button
                size="sm"
                @click="modalOpen(row, i)"
                class="btn btn-ally mr-1 action-btn"
              >
                編集
              </b-button>
            </td>
            <td>
              <b-button
                v-if="mode != 'View'"
                size="sm"
                @click="deleteItem(i)"
                class="btn btn-ally mr-1 action-btn"
              >
                削除
              </b-button>
            </td>
            </tr>
            <tr v-if="mode != 'View' && familyCount()">
              <td colspan="6" class="text-left" style="outline: none;">
                <button class="btn btn-ally" @click="modalOpen()">
                  追加
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  //mode ... モード(View, Add, Edit)
  //value .. 顧客リストで選択した顧客情報(getCustomer)
  props:['mode','value'],
  data: function(){
      var result = this.value;

      result.modalLoading = false;

      return result;
  },
  watch:{
    family: function(){
      this.familyCount();
    }
  },
  methods: {
    //家族情報入力モーダルを開くメソッド
    modalOpen: function(item, index){
      this.$emit('familyModalOpen',item, index);
    },
    //家族情報を消すメソッド
    deleteItem: function(index){
      this.family.splice(index, 1)
    },
    //家族登録人数の制限(20人まで)
    familyCount() {
      if(this.family != null){
        if(this.family.length >= 20){
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  },

}
</script>