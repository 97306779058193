// @ts-check

import { nextTick } from '@vue/composition-api';
import { PvFloor } from '../node/node/floorNode';
import { PvRoot } from '../node/node/rootNode';
import { cloneNodeStandalone } from '../node/tools/clone';
import { Vec } from '../util/math/vector';
import { makeToast } from '../util/util';
import { MESSAGE_TEXT } from '../util/const';
import { UseJudgeThePointInArea } from '../util/judgeThePointInArea';

/**
 *
 * @param {{
 * objectSelector: import('./objectSelector').ObjectSelector,
 * root: import('@vue/composition-api').Ref<PvRoot>,
 * historyController: import('./historyController').HistoryController,
 * }} param0
 * @returns
 */
export const useSelectedObjectsOperation = ({ objectSelector, root, historyController }) => {
  const selectAll = () => {
    root.value.selectAllChildren();
  };

  const deleteSelectedObjects = () => {
    objectSelector.selectedObjects.value.forEach(obj => {
      obj.removeSelf();
    });
    historyController.saveHistory();
  };

  const moveFront = () => {
    if (objectSelector.selectedObjects.value.length !== 1) {
      makeToast(MESSAGE_TEXT.SELECT_WARNING_MOVE_LIMIT);
      return;
    }
    const selected = objectSelector.selectedObjects.value[0];
    selected.shiftRight();
    historyController.saveHistory();
  };

  const moveBack = () => {
    if (objectSelector.selectedObjects.value.length !== 1) {
      makeToast(MESSAGE_TEXT.SELECT_WARNING_MOVE_LIMIT);
      return;
    }
    const selected = objectSelector.selectedObjects.value[0];
    selected.shiftLeft();
    historyController.saveHistory();
  };

  const moveFrontmost = () => {
    if (objectSelector.selectedObjects.value.length !== 1) {
      makeToast(MESSAGE_TEXT.SELECT_WARNING_MOVE_LIMIT);
      return;
    }
    const selected = objectSelector.selectedObjects.value[0];
    selected.shiftRightmost();
    historyController.saveHistory();
  };

  const moveBackmost = () => {
    if (objectSelector.selectedObjects.value.length !== 1) {
      makeToast(MESSAGE_TEXT.SELECT_WARNING_MOVE_LIMIT);
      return;
    }
    const selected = objectSelector.selectedObjects.value[0];
    selected.shiftLeftmost();
    historyController.saveHistory();
  };

  const rotate = r => {
    objectSelector.selectedObjects.value.forEach(obj => {
      obj.rotateBy(r);

      // 建具の場合
      if (obj.type === 'door') {
        // 回転後の内外判定を行う
        const judgeThePointInArea = new UseJudgeThePointInArea();
        // 内外判定から建具追加情報を更新
        // @ts-ignore
        obj.additionalInfo = judgeThePointInArea.getAdditionalInfoForMove(obj);
      }
    });
    historyController.saveHistory();
  };

  const copy = () => {
    objectSelector.selectedObjects.value.forEach(obj => {
      const clone = cloneNodeStandalone(obj);
      clone.moveBy(new Vec(100, 100));
      root.value.appendChild(clone);
    });
    historyController.saveHistory();
  };

  const merge = async () => {
    const objects = objectSelector.selectedObjects.value;
    if (objects.length !== 2 || !objects.every(o => o.type === 'floor')) {
      return;
    }

    let mergedFloor;
    try {
      mergedFloor = PvFloor.mergeFloors(objects);
    } catch (error) {
      return;
    }

    root.value.unselectAll();
    await nextTick();
    root.value.appendChild(mergedFloor);

    objects.forEach(o => {
      root.value.removeChild(o.id);
    });

    historyController.saveHistory();
  };

  const moveRight = () => {
    if (objectSelector.selectedObjects.value.length !== 1) {
      makeToast(MESSAGE_TEXT.SELECT_WARNING_MOVE_LIMIT);
      return;
    }
    //選択中のオブジェクトを右に移動する
    objectSelector.selectedObjects.value[0].transform.translate.x++;
  };

  const moveDown = () => {
    if (objectSelector.selectedObjects.value.length !== 1) {
      makeToast(MESSAGE_TEXT.SELECT_WARNING_MOVE_LIMIT);
      return;
    }
    //選択中のオブジェクトを下に移動する
    objectSelector.selectedObjects.value[0].transform.translate.y++;
  };

  const moveLeft = () => {
    if (objectSelector.selectedObjects.value.length !== 1) {
      makeToast(MESSAGE_TEXT.SELECT_WARNING_MOVE_LIMIT);
      return;
    }
    //選択中のオブジェクトを左に移動する
    objectSelector.selectedObjects.value[0].transform.translate.x--;
  };

  const moveUp = () => {
    if (objectSelector.selectedObjects.value.length !== 1) {
      makeToast(MESSAGE_TEXT.SELECT_WARNING_MOVE_LIMIT);
      return;
    }
    //選択中のオブジェクトを上に移動する
    objectSelector.selectedObjects.value[0].transform.translate.y--;
  };

  return {
    selectAll,
    deleteSelectedObjects,
    moveFront,
    moveBack,
    rotate,
    copy,
    moveFrontmost,
    moveBackmost,
    merge,
    moveRight,
    moveDown,
    moveLeft,
    moveUp,
  };
};
