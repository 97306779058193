<template>
  <div style="padding: 3px;">
    <div class="tree-list-tile" v-for="item in objectTree" :key="item.id">
      <div v-for="i in item.depth" :key="i" style="width: 10px"></div>
      <div>
        <div>{{ item.id }}</div>
        <div>{{ item.type }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from '@vue/composition-api';

  export default defineComponent({
    props: {
      objectTree: Array, // PvObject[]
    },
    setup() {},
  });
</script>
<style scoped>
  .tree-list-tile {
    font-size: 13px;
    border-bottom: 1px solid #333;
    text-align: center;
    display: flex;
  }
  .tree-list-tile:hover {
    background-color: #333;
    color: #fff;
    cursor: pointer;
  }
</style>
