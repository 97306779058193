// @ts-check
import { computed, onMounted, onUnmounted, ref, watchEffect } from '@vue/composition-api';
import {
  HEADER_HEIGHT,
  LARGE_GRID_METER_DEFAULT,
  LARGE_GRID_PX_DEFAULT,
  LAYER_HEIGHT,
  LAYER_SCALE_DEFAULT,
  LAYER_WIDTH,
  LEFT_MENU_WIDTH,
  SMALL_GRID_PX_DEFAULT,
} from '../util/const';
import { Vec } from '../util/math/vector';

/**
 * エディター設定値
 * @param {{
 * windowWidth: import('@vue/composition-api').Ref<number>;
 * windowHeight: import('@vue/composition-api').Ref<number>;
 * targetElement: import('@vue/composition-api').Ref<SVGSVGElement|null>
 * }} param0
 * @returns
 */
export const useEditor = ({ windowWidth, windowHeight, targetElement }) => {
  const leftMenuWidth = ref(LEFT_MENU_WIDTH);
  const headerHeight = ref(HEADER_HEIGHT);

  // 編集エリアの幅
  const editorWidth = computed(() => {
    const w = windowWidth.value - LEFT_MENU_WIDTH;
    if (w < 0) return 0;
    return w;
  });
  const editorHeight = computed(() => {
    const h = windowHeight.value - HEADER_HEIGHT;
    if (h < 0) return 0;
    return h;
  });

  const editorX = ref(100);
  const editorY = ref(100);

  const _zoom = ref(100);

  const layerWidth = ref(LAYER_WIDTH);
  const layerHeight = ref(LAYER_HEIGHT);

  const editorRatioX = computed(() => {
    return editorX.value / layerWidth.value;
  });

  const editorRatioY = computed(() => {
    return editorY.value / layerHeight.value;
  });

  const zoom = computed(() => {
    return (_zoom.value / 100) * LAYER_SCALE_DEFAULT;
  });

  const zoomedWidth = computed(() => {
    return editorWidth.value * zoom.value;
  });
  const zoomedHeight = computed(() => {
    return editorHeight.value * zoom.value;
  });

  const centerX = computed(() => {
    return editorX.value + layerWidth.value / 2;
  });

  const centerY = computed(() => {
    return editorY.value - layerHeight.value / 2;
  });

  watchEffect(() => {
    if (editorRatioX.value < 0) {
      editorX.value = 0;
    } else if (editorRatioX.value > 1) {
      editorX.value = layerWidth.value;
    }

    if (editorRatioY.value < 0) {
      editorY.value = 0;
    } else if (editorRatioY.value > 1) {
      editorY.value = layerHeight.value;
    }
  });

  /**
   *
   * @param {number} x
   * @param {number} y
   */
  const moveBy = (x, y) => {
    editorX.value -= x;
    editorY.value -= y;
  };

  const gridSize = ref(910);
  const showGrid = ref(true);
  const smallGridPx = ref(SMALL_GRID_PX_DEFAULT);
  const largeGridPx = ref(LARGE_GRID_PX_DEFAULT);

  const gridScale = computed(() => {
    return gridSize.value / 910;
  });

  const largeGridMeter = ref(LARGE_GRID_METER_DEFAULT);

  /**
   * @param {number} addVal
   */
  const addZoom = addVal => {
    _zoom.value += addVal;
  };

  const _mousePos = ref(new Vec());

  const _updateMousePos = e => {
    _mousePos.value = new Vec(e.clientX - LEFT_MENU_WIDTH, e.clientY - HEADER_HEIGHT);
  };

  onMounted(() => {
    const html = document.getElementsByTagName('html').item(0);
    // @ts-ignore
    html.style.overflow = 'hidden';
    scrollTo({
      top: 0,
      left: 0,
    });
    targetElement.value?.addEventListener?.('mousemove', _updateMousePos);
  });

  onUnmounted(() => {
    const html = document.getElementsByTagName('html').item(0);
    // @ts-ignore
    html.style.overflow = 'auto';
    scrollTo({
      top: 0,
      left: 0,
    });
    targetElement.value?.removeEventListener('mousemove', _updateMousePos);
  });

  // zoom補正込
  const mousePos = computed(() => {
    return new Vec(
      _mousePos.value.x / zoom.value + editorX.value,
      _mousePos.value.y / zoom.value + editorY.value
    );
  });

  const currentPos = computed(() => {
    const grid = smallGridPx.value * (gridSize.value / (1000 * largeGridMeter.value));
    const resultX = Math.round(mousePos.value.x / grid) * grid;
    const resultY = Math.round(mousePos.value.y / grid) * grid;

    return new Vec(resultX, resultY);
  });

  return {
    editorWidth,
    editorHeight,
    editorRatioX,
    editorRatioY,
    editorX,
    editorY,
    zoom,
    addZoom,
    layerWidth,
    layerHeight,
    leftMenuWidth,
    headerHeight,
    gridSize,
    gridScale,
    smallGridPx,
    largeGridPx,
    currentPos,
    mousePos,
    showGrid,
    moveBy,
  };
};

/**
 * @typedef {ReturnType<typeof useEditor>} EditorInfo
 */
