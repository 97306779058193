<template>
  <CommonLayout title="投資指標" :companyLogo="companyLogo" :companyName="companyName">
    <template v-slot>
      <div style="display: grid; grid-template-columns: 2fr 2fr; padding: 50px 100px; font-size: 100px">
        <div
          v-if="result != null && result.indicators != null"
          style="display: grid; grid-template-columns: 1fr; grid-template-rows: 1fr 1fr 1fr 1fr 300mm; grid-gap: 50px; padding: 20px 50px;"
        >
          <table style="width: 100%;">
            <thead>
              <tr>
                <th :style="headerStyle" >項目</th>
                <th :style="headerStyle" >値</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td :style="subHeaderStyle">収益価格(DCF法)</td>
                <td :style="bodyStyle">
                  {{
                    result
                      ? formatNum(result.indicators[0], {
                          floatLength: 0,
                          separation: true,
                          rountType: 'down',
                        })
                      : null
                  }}
                </td>
              </tr>
              <tr>
                <td :style="subHeaderStyle">正味現在価値(NPV)</td>
                <td :style="bodyStyle">
                  {{
                    result
                      ? formatNum(result.indicators[1], {
                          floatLength: 0,
                          separation: true,
                          rountType: 'down',
                        })
                      : null
                  }}
                </td>
              </tr>
              <tr>
                <td :style="subHeaderStyle">内部収益率(IRR)</td>
                <td :style="bodyStyle">
                  {{
                    result.indicators[2]
                      ? formatNum(result.indicators[2], {
                          floatLength: 2,
                          separation: true,
                          rountType: 'down',
                        })
                      : null
                  }}%
                </td>
              </tr>
              <tr>
                <td :style="subHeaderStyle">最終自己資金配当率</td>
                <td :style="bodyStyle">
                  {{
                    result.indicators[3]
                      ? formatNum(result.indicators[3], {
                          floatLength: 2,
                          separation: true,
                          rountType: 'down',
                        })
                      : null
                  }}%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="position:absolute; top:40%; width: 31%; padding: 100px 50px;">
          <div :style="{ ...headerStyle, 'font-size': '70px' }">[表面利回り]</div>
          <div :style="{ ...bodyStyle, 'font-size': '60px', 'text-align': 'left'}">
            <div style="padding: 5% 100px 0px 100px;">
              年間収入／物件価格<br> =
              {{ yieldResult ? yieldResult.yearlyRentTotal : 0 | tenThousandYen }} 万円 /
              {{ yieldResult ? yieldResult.propertyPrice : 0 | tenThousandYen }}万円<br> =
              {{ yieldResult ? yieldResult.yields[0] : 0 | ratio }}
            </div>
            <div style="padding: 0px 100px 1012px 100px; color:#1E90FF;">
              ※年間収入は、満室時における<br>&emsp;家賃・駐車場の1年間の合計額です。
            </div>
          </div>
        </div>
        <div style="position:absolute; top:40%; left:1955px; width: 31%;padding: 100px 50px;">
          <div :style="{ ...headerStyle, 'font-size': '70px' }">[ローン返済期間中の現金収支、及び実質利回り]</div>
          <div :style="{ ...bodyStyle, 'font-size': '60px', 'text-align': 'left'}">
            <div style="padding: 5% 100px 0px 100px;">
              ローン期間中における1ヶ月当り現金収支<br> =
              {{ yieldResult ? yieldResult.cashFlowPerMonthEndOfLoan : 0 | tenThousandYen }}
              万円 ・・・①
            </div>
            <div style="color:#1E90FF;">
            &emsp;&nbsp;※年間収入は、満室時における家賃・駐車場<br>&emsp;&nbsp;&emsp;の1年間の合計額です。
            </div>
            <div style="padding-top:100px;">
              &emsp; [建築価格に対する利回り]
            </div>
            <div style="padding: 0px 100px;">
              &emsp;年間現金収支（①×12ヶ月） / 初期投資額<br>&emsp; =
              {{ (yieldResult ? yieldResult.cashFlowPerMonthEndOfLoan : 0) | tenThousandYen }}万円 /
              {{ yieldResult ? yieldResult.initialCost : 0 | tenThousandYen }}万円<br>&emsp; =
              {{ yieldResult ? yieldResult.yields[1] : 0 | ratio }}
            </div>
            <div style="padding-top:100px;">
              &emsp; [自己資金に対する利回り]
            </div>
            <div style="padding: 0px 100px 185px 100px;">
              &emsp;年間現金収支（①×12ヶ月） / 自己資金<br>&emsp; =
              {{ yieldResult ? yieldResult.cashFlowPerMonthAfterEndOfLoan : 0 | tenThousandYen }}万円
              / {{ yieldResult ? yieldResult.ownResources : 0 | tenThousandYen }}万円<br>&emsp; =
              {{ yieldResult ? yieldResult.yields[2] : 0 | ratio }}
            </div>
           </div>
        </div>
        <div style="position:absolute; top:40%; right:250px; width: 31%;padding: 100px 50px;">
          <div :style="{ ...headerStyle, 'font-size': '70px' }">[ローン返済終了直後の現金収支、及び実質利回り]</div>
          <div :style="{ ...bodyStyle, 'font-size': '60px', 'text-align': 'left' }">
            <div style="padding: 5% 100px 0px 100px;">
              ローン返済終了直後の1ヶ月当り現金収支<br> =
              {{ yieldResult ? yieldResult.cashFlowPerMonthAfterEndOfLoan : 0 | tenThousandYen }}万円
              ・・・②
            </div>
            <div style="color:#1E90FF;">
              &emsp; ※キャッシュフロー計画書の、ローン終了翌年<br>&emsp;&emsp; の数値です。
            </div>
            <div style="padding-top:100px;">
              &emsp; [建築価格に対する利回り]
            </div>
            <div style="padding: 0px 100px;">
              &emsp;年間現金収支（②×12ヶ月） / 初期投資額<br>&emsp; =
              {{ yieldResult ? yieldResult.cashFlowPerMonthAfterEndOfLoan : 0 | tenThousandYen }}万円
              / {{ yieldResult ? yieldResult.initialCost : 0 | tenThousandYen }}万円<br>&emsp; =
              {{ yieldResult ? yieldResult.yields[3] : 0 | ratio }}
            </div>
            <div style="padding-top:100px;">
              &emsp; [自己資金に対する利回り]
            </div>
            <div style="padding: 0px 100px 185px 100px;">
              &emsp;年間現金収支（②×12ヶ月） / 自己資金<br>&emsp; =
              {{ yieldResult ? yieldResult.cashFlowPerMonthAfterEndOfLoan : 0 | tenThousandYen }}万円
              / {{ yieldResult ? yieldResult.ownResources : 0 | tenThousandYen }}万円<br>&emsp; =
              {{ yieldResult ? yieldResult.yields[4] : 0 | ratio }}
            </div>
           </div>
        </div> 
        <div style="font-size:70px; position:absolute; bottom:450px; padding: 0px 50px; color:#1E90FF;">
          ※年間現金収支のなかにはすでに初年度経費も含まれるので、[建築価格に対する利回り]の分母は（建物価格+初年度経費）とせず（建物価格）のみとしています
        </div>     
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import {
    headerStyle,
    subHeaderStyle,
    bodyStyle,
    investmentAnalyticsGraphPadding,
    investmentAnalyticsGraphStyle,
  } from '../../../../components/ExportReport/style';
  import ReportCanvas from '@/components/ExportReport/ReportCanvas';
  import ReportImage from '@/components/ExportReport/ReportImage';
  import Logo from '@/assets/report/reportImages/logo.png';

  export default {
    props: {
      chartData: Array,
      chartOptions: Array,
      companyLogo: String,
      companyName: String,
      result: Object,
      yieldResult: Object,
    },
    components: {
      CommonLayout,
    },
    data() {
      return {
        logo: Logo,
        headerStyle,
        subHeaderStyle,
        bodyStyle,
        investmentAnalyticsGraphPadding,
        investmentAnalyticsGraphStyle,
        graphSize: '1100px',
      };
    },
    computed: {
      // 表面利回り
      hyoumenRimawari() {
        return this.yieldResult.yearlyRentTotal / this.yieldResult.propertyPrice;
      },
    },
    filters: {
      saveValue(input) {
        if (!isFinite(input) || Number.isNaN(input)) {
          return null;
        }
        return input;
      },
    },
  };
</script>
