// @ts-check

import { computed } from '@vue/composition-api';
import { PvDoor } from '../node/node/doorNode';
import { PvFloor } from '../node/node/floorNode';
import { PvGroup } from '../node/node/groupNode';
import { createNode } from '../node/node/pvNode';
import { PvObject } from '../node/node/pvObject';
import { PvStairs } from '../node/node/stairsNode';
import { PvWall } from '../node/node/wallNode';
import { PvAdditionalInfo } from '../node/tools/additionalInfo';
import { cloneNode } from '../node/tools/clone';
import { PvStyle } from '../node/tools/style';
import { WALL_STYLE_PRESETS } from '../node/tools/wallStylePresets';

import { DEFAULT_DOOR_TYPE, DEFAULT_WALL_TYPE, DEFAULT_WALL_WIDTH } from '../util/const';

/**
 * オブジェクト新規作成時の元となるオブジェクトを管理
 * @param {import("@vue/composition-api").Ref<string>} mode
 */
export const useObjectPresets = mode => {
  /**  @type {Record<string, PvObject>} */
  const presets = {
    floor: createNode(PvFloor, {
      style: new PvStyle({
        fill: '#ffffff',
        stroke: 'black',
        strokeWidth: DEFAULT_WALL_WIDTH,
      }),
    }),
    door: createNode(PvDoor, {
      code: 0, // 建具コード
      additionalInfo: new PvAdditionalInfo(), // 建具追加情報
      subType: DEFAULT_DOOR_TYPE,
      style: new PvStyle({
        strokeWidth: DEFAULT_WALL_WIDTH,
      }),
    }),
    wall: createNode(PvWall, {
      subType: DEFAULT_WALL_TYPE,
      style: WALL_STYLE_PRESETS[DEFAULT_WALL_TYPE].clone(),
    }),
    stairs: createNode(PvStairs, {
      width: 100,
      style: new PvStyle({
        flipHorizontal: true,
      }),
    }),
  };

  /** @type {import('@vue/composition-api').ComputedRef<PvObject>} */
  const currentPreset = computed(() => {
    const p = presets[mode.value] ?? createNode(PvGroup);
    return p;
  });

  /**
   *
   * @param {PvObject} preset
   */
  const set = preset => {
    presets[mode.value] = cloneNode(preset);
  };

  /**
   * FUNC: 指定されたプロパティをセット
   * @param {any} property
   */
  const setProperty = property => {
    // 配列チェック
    if (property.length) {
      // 配列の場合
      property.map(prop => {
        presets[mode.value][prop.key] = prop.value;
      });
    } else {
      // 配列ではない場合
      presets[mode.value][property.key] = property.value;
    }
  };

  /**
   *
   * @param {string} key
   * @param {any} value
   */
  const setStyleProperty = (key, value) => {
    presets[mode.value].style = new PvStyle({
      ...presets[mode.value].style,
      [key]: value,
    });
  };

  /**
   *
   * @param {PvStyle} style
   */
  const setStyle = style => {
    presets[mode.value].style = style;
  };

  return {
    set,
    setProperty,
    setStyle,
    setStyleProperty,
    currentPreset,
    presets,
  };
};
