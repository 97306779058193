// @ts-check

import { EventContext } from '../../preset/eventContext';
import { EventDataset } from '../../preset/eventDataset';
import { Action } from '../action';

/**
 * アクションを作成するクラス
 * @abstract
 */
export class ActionBuilder {
  /** @type {EventContext} */
  context;
  /** @type {EventDataset} */
  dataset;

  /**
   * @potected
   * @param {EventContext} context
   * @param {EventDataset} dataset
   */
  constructor(context, dataset) {
    this.context = context;
    this.dataset = dataset;
  }

  /**
   * @abstract
   * @returns {Action|undefined}
   */
  build() {
    throw new Error('overrideしてください');
  }
}
