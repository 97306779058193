export default class InheritanceTaxInput {
  constructor(input) {
    if (input == null) {
      input = {};
    }
    //相続税
    this.calcMode = input.calcMode || 1;
    this.houseFixedAssetTax = input.houseFixedAssetTax || null;
    this.houseFixedAssetTaxFlg = input.houseFixedAssetTaxFlg || null;
    this.fixedAssetTax = input.fixedAssetTax || null;
    this.landMagnification = input.landMagnification || null;
    this.realEstateName = input.realEstateName || null;
    this.realEstateAddress = input.realEstateAddress || {
      postalCode: null,
      address1: null,
      address2: null,
      address3: null,
    };
    this.liveFlag = input.liveFlag || null;
    this.landNum = input.landNum || null;
    this.landType = input.landType || null;
    this.commonComment = input.commonComment || null;
    this.ownerName = input.ownerName || null;
    this.ownerAddress = input.ownerAddress || {
      postalCode: null,
      address1: null,
      address2: null,
      address3: null,
    };
    this.userName = input.userName || null;
    this.userAddress = input.userAddress || {
      postalCode: null,
      address1: null,
      address2: null,
      address3: null,
    };
    //相続税計算用 Object
    this.calcMode = input.calcMode || 1;
    this.landSize = input.landSize || null;
    this.landPrice = input.landPrice || {
      front: null,
      back: null,
      side1: null,
      side2: null,
    };
    this.propertyTax = input.propertyTax || {
      tax: null,
      areaRate: null,
    };
    this.frontageDistance = input.frontageDistance || null;
    this.depthDistance = input.depthDistance || null;
    this.useType = input.useType || null;
    this.areaType = input.areaType || null;
    this.irregularLandFlg = input.irregularLandFlg || false;
    this.irregularLandFrontageDistance = input.irregularLandFrontageDistance || null;
    this.irregularLandDepthDistance = input.irregularLandDepthDistance || null;
    this.bigLandFlg = input.bigLandFlg || false;
    this.noRoadLandCheckFlg = input.noRoadLandCheckFlg || false;
    this.noRoadLandFlg = input.noRoadLandFlg || false;
    this.noRoadLandSize = input.noRoadLandSize || null;
    this.slantedLandFlg = input.slantedLandFlg || false;
    this.slantedLandDirection = input.slantedLandDirection || null;
    this.slantedLandSize = input.slantedLandSize || null;
    this.multipleFloorAreaRatioFlg = input.multipleFloorAreaRatioFlg || false;
    this.multipleFloorAreaRatioDeductionRate = input.multipleFloorAreaRatioDeductionRate || null;
    this.corner = input.corner || {
      side1Corner: 0,
      side2Corner: 0,
      backCorner: 0,
    };
    this.setBackFlg = input.setBackFlg || false;
    this.setBackSize = input.setBackSize || null;
    this.farmlandFlag = input.farmlandFlag || false;
    this.changedFlag = input.changedFlag || new Array(7).fill(false);
    this.preparationSize = input.preparationSize || null;
    this.preparationUnitPrice = input.preparationUnitPrice;
    this.loggingSize1 = input.loggingSize1 || null;
    this.loggingUnitPrice = input.loggingUnitPrice;
    // this.loggingSize2 = input.loggingSize2 || null;
    this.stabilizationSize = input.stabilizationSize || null;
    this.stabilizationUnitPrice = input.stabilizationUnitPrice;
    this.earthworkSize = input.earthworkSize || null;
    this.earthworkHight = input.earthworkHight || null;
    this.earthworkUnitPrice = input.earthworkUnitPrice;
    this.sheathingLength = input.sheathingLength || null;
    this.sheathingHight = input.sheathingHight || null;
    this.sheathingUnitPrice = input.sheathingUnitPrice;
    this.slopeAngle = input.slopeAngle || null;
    this.cityRoadFlg = input.cityRoadFlg || false;
    this.cityRoadFactor = input.cityRoadFactor || null;
    this.golfFlg = input.golfFlg || false;
    this.golfCost = input.golfCost || null;
    this.rentLandRate = input.rentLandRate || null;
    this.rentHouseRate = input.rentHouseRate || null;
    this.lendRate = input.lendRate || null;
    this.rentHouseFlg = input.rentHouseFlg || false;
    this.lendHouseRate = input.lendHouseRate || null;
    this.topographicMap = input.topographicMap || null;
    this.topographicMapDataUrl = input.topographicMapDataUrl || null;
    this.memo = input.memo || null;

    //相続税の計算結果
    this.totalPrice = input.totalPrice || 0;
    this.resultValue = input.resultValue || {
      calcArray: [],
      resultPriceType: null,
      resultPrice: null,
    };

    //相続税計算で使用する（顧客の不動産以外の財産）
    this.userAssetInput = input.userAssetInput || {
      cash: null,
      lifeInsurance: null,
      car: null,
      etc: null,
    };

    // 計算されたか？
    this.isCalculated = input.isCalculated || false;
  }
  getName() {
    return this.realEstateName;
  }
}
