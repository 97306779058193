<!--
作成者：本間
作成日：2021.02.15
画面名：投資分析/入力
機能：初期投資/工事計画/解体工事
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="Demolition">
    <!-- 棟数入力 -->
    <b-row style="padding-bottom:10px;">
      <b-col class="title bold">
        解体工事
      </b-col>
      <b-col class="title" style="color:gray;">
        ※棟数を選択してください
      </b-col>
      <b-col class="ally-select">
        <b-row>
          <b-col>
            <allySelect
              name="demolitionCount"
              :items="demolitionCountItems"
              style="direction: rtl;"
              v-model="investmentAnalytics.initialInvestment.constructionPlan.demolitionCount"
            />
          </b-col>
          <b-col cols="2" class="unit">棟</b-col>
        </b-row>
      </b-col>
      <b-col cols="1"> </b-col>
    </b-row>
    <div
      v-for="id in investmentAnalytics.initialInvestment.constructionPlan.demolitionCount"
      v-bind:key="`demolitionBuilding-${id}`"
    >
      <!-- 概算入力 -->
      <b-row>
        <b-col class="title bold"> </b-col>
        <b-col class="title">{{ id }}棟目</b-col>
        <b-col class="component">
          <b-row>
            <b-col class="component">
              <allyText
                type="number"
                class="number"
                :separation="true"
                halfWidth
                v-model="
                  investmentAnalytics.initialInvestment.constructionPlan.constructionCost[id + 6]
                    .amount
                "
                roundType="down"
              />
            </b-col>
            <b-col cols="2" class="unit">円</b-col>
          </b-row>
        </b-col>
        <b-col cols="1" class="title">
          <b-button variant="ally" v-b-toggle="`tabInitialInvestment581_${id}`">
            <i class="when-open ti-angle-double-up" />
            <i class="when-closed ti-angle-double-down" />
          </b-button>
        </b-col>
      </b-row>
      <!-- 詳細入力 -->
      <b-row>
        <b-col>
          <b-collapse :id="`tabInitialInvestment581_${id}`" role="tabpanel">
            <b-card-body>
              <b-container>
                <b-row>
                  <b-col cols="2" class="title bold">工事内容</b-col>
                  <b-col cols="4" class="ally-select">
                    <allySelect
                      :name="`exteriorConstruction-${id}`"
                      :items="demolitionItems"
                      v-model="
                        investmentAnalytics.initialInvestment.constructionPlan.constructionCost[
                          id + 6
                        ].content
                      "
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold">解体面積</b-col>
                  <b-col cols="3" class="component">
                    <allyText
                      type="number"
                      class="number"
                      :separation="true"
                      halfWidth
                      v-model="
                        investmentAnalytics.initialInvestment.constructionPlan.constructionCost[
                          id + 6
                        ].quantity
                      "
                      :floatLength="2"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="1" class="unit">㎡</b-col>
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold">標準 工事単価</b-col>
                  <b-col cols="3">
                    <b-form-input
                      disabled
                      class="currency"
                      :value="
                        getUnitPrice(
                          '解体工事',
                          investmentAnalytics.initialInvestment.constructionPlan.constructionCost[
                            id + 6
                          ].content
                        ) | currency
                      "
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円/㎡</b-col>
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold">工事費</b-col>
                  <b-col cols="3" class="component">
                    <allyText
                      type="number"
                      class="number"
                      :separation="true"
                      halfWidth
                      v-model="
                        investmentAnalytics.initialInvestment.constructionPlan.constructionCost[
                          id + 6
                        ].amount
                      "
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                  <b-col cols="1" class="title-right" style="white-space: nowrap;">※目安</b-col>
                  <b-col cols="3" class="component">
                    <b-form-input
                      disabled
                      class="currency"
                      :value="
                        (Math.floor(
                          investmentAnalytics.initialInvestment.constructionPlan.constructionCost[
                            id + 6
                          ].quantity
                        ) *
                          getUnitPrice(
                            '解体工事',
                            investmentAnalytics.initialInvestment.constructionPlan.constructionCost[
                              id + 6
                            ].content
                          ))
                          | currency
                      "
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                  <button
                    class="btn btn-ally mr-1 cols='1'"
                    style="width: 50px; white-space: nowrap; padding-left: 10px; "
                    @click="
                      changeGuideline(
                        id + 6,
                        Math.floor(
                          investmentAnalytics.initialInvestment.constructionPlan.constructionCost[
                            id + 6
                          ].quantity
                        ) *
                          getUnitPrice(
                            '解体工事',
                            investmentAnalytics.initialInvestment.constructionPlan.constructionCost[
                              id + 6
                            ].content
                          )
                      )
                    "
                  >
                    反映
                  </button>
                </b-row>
              </b-container>
            </b-card-body>
          </b-collapse>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';

  export default {
    data: function() {
      return {
        // 選択項目
        demolitionItems: this.getUnitPricesItems('解体工事'),
        // 解体棟数
        demolitionCountItems: [
          { text: '0', value: 0 },
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
          { text: '5', value: 5 },
          { text: '6', value: 6 },
          { text: '7', value: 7 },
          { text: '8', value: 8 },
          { text: '9', value: 9 },
          { text: '10', value: 10 },
        ],
      };
    },
    watch: {
      // マスタ更新時
      '$store.state.master.unitPrice': {
        handler: function() {
          // 選択項目更新
          this.demolitionItems = this.getUnitPricesItems('解体工事');
        },
        deep: false,
      },
    },
    methods: {
      //目安値を反映する
      changeGuideline: function(id, sum) {
        Vue.set(
          this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[id],
          'amount',
          sum
        );
      },
    },
  };
</script>
