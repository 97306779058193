// @ts-check

import { PvAdditionalInfo } from '../node/tools/additionalInfo';
import { Vec } from '../util/math/vector';
import { LENGTH_PER_PX } from '../util/const';
import { meterToPx, pxToMeter, getOtherSidePoint } from '../util/util';
import { createNode } from '../node/node/pvNode';
import { PvWall } from '../node/node/wallNode';
import { cloneNode, cloneNodeStandalone, cloneNodeSingle } from '../node/tools/clone';

/**
 *  CLASS: VR用フォーマット
 */
export class VR {
  /** @type {string} */
  id;
  /** @type {number}  PROPERTY: コード */
  code;
  /** @type {string}  PROPERTY: ノードタイプ */
  type;
  /** @type {string}  PROPERTY: ノード別名 */
  subType;
  /** @type {string}  PROPERTY: マテリアル名 */
  materialName;
  /** @type {Vec[]}  PROPERTY: 座標（メートル） */
  vertexes;
  /** @type {Vec}  */
  translate;
  /** @type {number}  PROPERTY: 回転 */
  rotate;
  /** @type {number[]} */
  arcPoints;
  /** @type {number}  PROPERTY: 壁の厚み（メートル） */
  thickness;
  /** @type {number}  PROPERTY: 平面上の横幅 */
  width;
  /** @type {number}  PROPERTY: 平面上の縦幅 */
  height;
  /** @type {number}  PROPERTY: オブジェクトの高さ（メートル）*/
  height3d;
  /** @type {number}  PROPERTY: 取り付け高さ（メートル）*/
  mountingHeight;
  /** @type {number} */
  index;
  /** @type {PvAdditionalInfo}  PROPERTY: 追加情報 */
  additionalInfo;
  /** @type {string}  PROPERTY: 3Dモデルパス */
  modelPath;
  /** @type {string} */
  alias;
  /** @type {string} */
  areaSizeUnit;
  /** @type {string} */
  text;
  /** @type {number} */
  azimuth;

  constructor(node = {}, floorRotate, floorTranslate, azimuth) {
    // 自クラスのプロパティリストを取得
    const propertyList = Object.getOwnPropertyNames(this);
    // 長さ補正対象プロパティリスト
    const lengthCorrectionTargetPropertyList = ['thickness', 'width'];

    // プロパティリストと共通するプロパティをパラメータから取得
    propertyList.map(property => {
      this[property] = node[property] ?? null;
      const length = this[property]?.length ? this[property]?.length : null;

      if (property === 'thickness') {
        // 壁の厚みを取得
        this.thickness = node.style.strokeWidth;
      }
      // 長さ補正対象プロパティはメートルに補正
      if (lengthCorrectionTargetPropertyList.includes(property)) {
        if (0 < length) {
          this[property] = this[property].map(value => {
            return value * LENGTH_PER_PX.m;
          });
        } else {
          this[property] = this[property] * LENGTH_PER_PX.m;
        }
      }
    });

    // 素材名を取得
    this.materialName = node.style.imageName ?? node.style.fill ?? null;
    // 角度を取得
    this.rotate = node.transform.rotateDeg;
    // 原点座標を取得
    this.translate = node.transform.translate;

    // 階段ノードチェック
    if (node.type === 'stairs') {
      this.code = node.code;
      const stringerWidth = 20;

      const newVertexes = []; // 補正後の頂点座標配列
      let isUShaped = false; // コの字階段か
      let isUShapedBefore = false; // 前回がコの字階段か
      let isClockwise = false; // 階段の回り方向
      let isClockwiseBefore = false; // 前回の階段の回り方向
      let currentPoint; // 現在の頂点
      let nextPoint; // 次の頂点
      let isCorrectionBefore = false; // 前回の補正フラグ
      let stringerDifference = new Vec();

      //  NOTE: 【階段】座標をVR用に修正
      this.vertexes.forEach((point, index, array) => {
        // 螺旋階段の場合は最初の3点を渡す
        if (this.code === 4301 || this.code === 4302) {
          if (index < 3) {
            newVertexes.push(point);
          }
          return;
        }
        // 頂点のインデックスチェック
        if (index == this.vertexes.length - 1) {
          // 最後の頂点は処理しない
          return;
        }

        // 現在の座標より後に2点以上ある場合
        if (index + 2 < array.length) {
          // コの字階段か判定
          isUShaped =
            array[index + 2].onTheLine(array[index], array[index + 1]) || // 2つ先の頂点が現在と次を結ぶ直線上
            array[index].onTheLine(array[index + 1], array[index + 2]) || // または、現在の頂点が次と2つ先を結ぶ直線上
            array[index].same(array[index + 2]); // または、現在と2つ先の頂点が同じ座標
          // 階段の回り方向を取得
          const baseLine = array[index + 1].sub(array[index]);
          const targetLine = array[index + 2].sub(array[index]);
          isClockwise = isUShaped
            ? !node.style.flipHorizontal // コの字階段の場合、反転フラグで判定
            : !baseLine.isLeft(targetLine); // コの字階段ではない場合、2つ先の頂点の方向で判定
          // console.log({ index: index, isUShaped: isUShaped, isClockwise: isClockwise });
        }

        // 補正フラグ取得
        const isCorrection =
          (array.length == 2 && node.style.flipHorizontal) || // 頂点が2つ＆反転がON
          (node.style.flipHorizontal && !isClockwise) || // または、反転がON＆反時計回り
          (!node.style.flipHorizontal && isClockwise); // または、反転がOFF＆時計回り

        // 開始点の場合
        if (index === 0) {
          currentPoint = point;
        }
        currentPoint = isCorrectionBefore || isUShaped || isUShapedBefore ? point : currentPoint;
        nextPoint = array[index + 1];

        // 今回または前回がコの字階段の場合
        if (isUShaped || isUShapedBefore) {
          // 側桁分移動した座標を求める
          const lineVec = { startPoint: point, endPoint: array[index + 1] };
          const addStringerWidthPoint = getOtherSidePoint({
            lineData: lineVec,
            targetPoint: point,
            movingDistance: stringerWidth / 2,
            isGo: true,
            isTargetRotation: false,
            isClockwiseRotation: isUShapedBefore ? !isClockwiseBefore : !isClockwise,
          });
          // 側桁分の移動量を求める
          stringerDifference = addStringerWidthPoint.sub(currentPoint);
        }

        currentPoint = currentPoint.add(stringerDifference);
        nextPoint = nextPoint.add(stringerDifference);
        // コの字からL時に変わる場合かつ、回り方向が変わる場合
        if (!isUShaped && isUShapedBefore && isClockwise != isClockwiseBefore) {
          // 側桁分の差分を削除する
          stringerDifference = new Vec();
        }

        const lineData = {
          startPoint: currentPoint,
          endPoint: nextPoint,
        };
        // 現在の頂点
        currentPoint = !isCorrection
          ? // 補正が不要
            currentPoint
          : // 補正が必要
            getCorrectionPointForStair({
              lineData: lineData,
              width: this.width,
              isCurrentPoint: true,
              isEndPoint: false,
              isClockwise: isClockwise,
            });

        const isEndAtNext = index + 1 == array.length - 1;

        // 次の頂点
        nextPoint = !isCorrection
          ? // 補正が不要
            nextPoint
          : // 補正が必要
            getCorrectionPointForStair({
              lineData: lineData,
              width: this.width,
              isCurrentPoint: false,
              isEndPoint: isEndAtNext,
              isClockwise: isClockwise,
            });

        // 階段の座標郡を作成
        const { currentLineVec, newNextPoint } = createStairVertexesForVR({
          startPoint: currentPoint,
          nextPoint: nextPoint,
          array: array,
          width: this.width,
          isClockwise: isClockwise,
          isEndAtNext: isEndAtNext,
          newVertexes: newVertexes,
        });

        // 次の階段があるかチェック
        if (index + 1 < this.vertexes.length - 1) {
          // 踊り場の１点目の頂点を追加
          newVertexes.push(newNextPoint);
          // 踊り場の２点目の頂点を追加
          newVertexes.push(nextPoint);

          // 踊り場の３点目の頂点を取得
          const afterNextPoint = getOtherSidePoint({
            lineData: currentLineVec,
            targetPoint: nextPoint,
            movingDistance: isUShaped
              ? meterToPx(this.width * 2) + stringerWidth
              : meterToPx(this.width),
            isGo: true,
            isTargetRotation: false,
            isClockwiseRotation: isClockwise,
          });
          // 踊り場の３点目の頂点を追加
          newVertexes.push(afterNextPoint);

          // 最後の頂点を現在の頂点にセット
          currentPoint = afterNextPoint;
        }

        isCorrectionBefore = isCorrection;
        isUShapedBefore = isUShaped;
        isClockwiseBefore = isClockwise;
      });
      if (this.code === 4301 || this.code === 4302) {
        // 螺旋階段の階段の開始点と終了点は全体の半分の位置と大きさ
        const vert1 = newVertexes[0];
        const vert2 = newVertexes[1];
        const vert3 = newVertexes[2];
        const first = vert1.centerPoint(vert2);
        const second = vert2.centerPoint(vert3);
        const center = new Vec(first.x, second.y);
        const adustVertexes = [];
        if (this.code === 4301) {
          adustVertexes.push(center);
          adustVertexes.push(second);
          adustVertexes.push(vert3);
        } else {
          // 左回りは逆の位置
          adustVertexes.push(center);
          adustVertexes.push(second.rotate(180, center));
          adustVertexes.push(vert1.rotate(180, center));
        }
        this.vertexes = adustVertexes;
      } else {
        this.vertexes = newVertexes;
      }
      const height3d = Math.abs(node.height3d) < 0.1 ? 0.1 : Math.abs(node.height3d);
      const stepHeight =
        Math.abs(node.additionalInfo.stepHeight) < 0.01
          ? 0.01
          : Math.abs(node.additionalInfo.stepHeight);
      const stepAngle =
        Math.abs(node.additionalInfo.stepAngle) < 15 ? 15 : Math.abs(node.additionalInfo.stepAngle);
      this.additionalInfo.steps = Math.round(height3d / stepHeight);
      this.additionalInfo.stepAngle = stepAngle;
      this.additionalInfo.stepHeight = stepHeight;
    }
    // 各補正座標取得
    this.vertexes = this.vertexes.map(point => {
      return getCorrectionVec(
        point,
        this.rotate,
        this.translate,
        node.transform.origin,
        node.type === 'floor' ? null : floorRotate,
        floorTranslate
      );
    });
    this.azimuth = azimuth;
  }
}

/**
 *  FUNC: 平面図データをVR用に変換
 * @param {any} layers // 平面図データ
 * @param {number} azimuth // 方位角
 */
export const convertLayersForVR = (layers, azimuth) => {
  const convertedLayers = { planeView: [] }; // 変換後の平面図データ群
  // type='root'毎に処理
  layers.forEach(layer => {
    const convertedLayer = []; // 変換後の平面図データ
    let vRFloorStartVec = new Vec(); // 部屋のVR用始点座標（建具・追加壁ノードで使用）※床座標から生成された壁ノードは除く
    let floorRotate = null; // 部屋の角度（建具・壁ノードで使用）※床座標から生成された壁ノードは除く
    let floorTranslate = new Vec(); // 部屋の回転の中心（建具・追加壁ノードで使用）※床座標から生成された壁ノードは除く
    // ノード毎に処理
    layer.forEach(node => {
      const cloneNode = cloneNodeSingle(node);
      // タイプチェック
      if (cloneNode.type === 'floor') {
        // 床の場合
        const floorCorrectionValue = 0; // 床の基準座標の補正値
        // 床の基準座標を補正
        cloneNode.transform.translate = cloneNode.transform.translate.sub(
          new Vec(floorCorrectionValue, floorCorrectionValue)
        );
        // 部屋の角度を取得
        floorRotate = cloneNode.transform.rotateDeg;
        // 部屋の回転の中心を取得
        floorTranslate = cloneNode.transform.translate;
        // この部屋のVR用始点座標を取得
        vRFloorStartVec = cloneNode.transform.translate.rotate(
          cloneNode.transform.rotateDeg,
          cloneNode.transform.origin
        );
        // メートルに換算
        vRFloorStartVec = new Vec(
          vRFloorStartVec.x * LENGTH_PER_PX.m,
          vRFloorStartVec.y * LENGTH_PER_PX.m
        );
      }
      // VR用ノード生成（方位角の回転はここでは行わない：理由=302行目で座標を変換しているため）
      const vRCloneNode = new VR(cloneNode, floorRotate, floorTranslate, null);
      // タイプチェック
      if (
        vRCloneNode.type !== 'root' &&
        vRCloneNode.type !== 'floor' &&
        vRCloneNode.type !== 'text'
      ) {
        // タイプが「ルート」「床」「テキスト」以外の場合、座標にVR用始点座標を加算
        vRCloneNode.vertexes = vRCloneNode.vertexes.map(vec => vec.add(vRFloorStartVec));
      }
      // VRでは南が上なので方位角を180度回転させる
      const adjustAzimuth = (180 - azimuth) % 360;
      // 方位角を代入する
      vRCloneNode.azimuth = adjustAzimuth;
      // VR用ノードをVR用ノードリストに追加
      const vRNodeList = [vRCloneNode];

      // タイプが「床」の場合
      if (cloneNode.type === 'floor') {
        // 壁の曲線用arcPoints生成用
        let floorArcPoints = [];
        // 床のデータ位置を保持
        let floorIndex = vRNodeList.length - 1;
        // 部屋の座標から壁ノードを生成
        cloneNode.vertexes.forEach((point, index, array) => {
          const next = index === array.length - 1 ? 0 : index + 1;
          const wall = createNode(PvWall);
          wall.code = 1001;
          wall.vertexes = [point, array[next]];
          wall.transform.rotate = cloneNode.transform.rotate;
          wall.transform.origin = cloneNode.transform.origin;
          wall.transform.translate = cloneNode.transform.translate;
          // console.log('cloneNode.arcPoints', cloneNode.arcPoints);
          // 曲線情報がある場合
          if (0.001 < Math.abs(cloneNode.arcPoints[index])) {
            const arcPointVec = getOtherSidePoint({
              lineData: {
                startPoint: point,
                endPoint: array[next],
              },
              targetPoint: point.centerPoint(array[next]),
              movingDistance: cloneNode.arcPoints[index],
              isGo: cloneNode.arcPoints[index] > 0 ? true : false,
              isTargetRotation: false,
              isClockwiseRotation: false,
            });
            let arcVecs = [point, arcPointVec, array[next]];
            arcVecs = arcVecs.map(point => {
              return getCorrectionVec(
                point,
                wall.transform.rotateDeg,
                wall.transform.translate,
                wall.transform.origin,
                cloneNode.type === 'floor' ? null : floorRotate,
                cloneNode.transform.translate
              );
            });
            wall.arcPoints = [
              arcVecs[0].x,
              -arcVecs[0].y,
              arcVecs[1].x,
              -arcVecs[1].y,
              arcVecs[2].x,
              -arcVecs[2].y,
            ];
            // 床のarcPoints生成（壁のarcPointsを利用）
            floorArcPoints.push(arcVecs[1].x, -arcVecs[1].y);
          } else {
            // @ts-ignore
            wall.arcPoints = [];
            // 床のarcPoints生成（曲面が無い場合）
            floorArcPoints.push(0, 0);
          }
          // 生成した壁ノードをコンバートしてVR用ノードリストに追加
          vRNodeList.push(new VR(wall, null, null, adjustAzimuth));
        });
        // 床のarcPointsを変更
        vRNodeList[floorIndex].arcPoints = floorArcPoints;
      }
      // VR用ノードリストのノードをconvertedLayerに追加する
      vRNodeList.map(vRNode => {
        vRNode.vertexes = vRNode.vertexes.map(vec => {
          return new Vec(vec.x, -vec.y);
        });
        // VR用にコンバートしたノードをconvertedLayerに追加する
        // @ts-ignore
        convertedLayer.push(vRNode);
      });
    });
    // convertedLayerをconvertedLayersに追加する
    // @ts-ignore
    convertedLayers.planeView.push(convertedLayer);
  });
  // convertedLayersを返す
  return convertedLayers;
};

/**
 *  FUNC: 補正座標取得
 * @param {Vec} originalVec // 元座標
 * @param {number} rotate // 角度
 * @param {Vec} translate // 原点座標
 * @param {Vec} origin // 回転の中心
 * @param {number} floorRotate // 部屋の角度
 * @param {Vec} floorTranslate // 部屋の回転の中心
 * @returns {Vec}
 */
function getCorrectionVec(originalVec, rotate, translate, origin, floorRotate, floorTranslate) {
  originalVec = originalVec.add(translate);
  // 角度が0以外なら元座標を補正する
  if (rotate !== 0) {
    // 元座標を角度分補正した座標を取得
    originalVec = originalVec.rotate(rotate, origin.add(translate));
  }
  if (floorRotate) {
    originalVec = originalVec.rotate(floorRotate, floorTranslate);
  }

  // 元座標に原点座標を加算し、メートルに換算
  return new Vec(originalVec.x * LENGTH_PER_PX.m, originalVec.y * LENGTH_PER_PX.m);
}

/**
 * FUNC: 階段の頂点を補正する
 * @param {{
 * lineData: {
 * startPoint: Vec,
 * endPoint: Vec,
 * },
 * width: number ,
 * isCurrentPoint: boolean, // pointが現在の頂点かどうか
 * isEndPoint: boolean, // 終点かどうか
 * isClockwise: boolean,
 * }} param
 */
function getCorrectionPointForStair(param) {
  // 補正した頂点を取得
  const correctionPoint = getOtherSidePoint({
    lineData: param.lineData,
    targetPoint: param.isCurrentPoint ? param.lineData.startPoint : param.lineData.endPoint,
    movingDistance: meterToPx(param.width),
    isGo: true,
    isTargetRotation: param.isCurrentPoint || param.isEndPoint ? false : true,
    isClockwiseRotation:
      param.isCurrentPoint || param.isEndPoint ? !param.isClockwise : param.isClockwise,
  });

  return correctionPoint;
}

/**
 * FUNC: 階段の座標郡を作成
 * @param {{
 * startPoint: Vec,
 * nextPoint: Vec,
 * array: Array,
 * width: number ,
 * isClockwise: boolean,
 * isEndAtNext: boolean,
 * newVertexes: Vec[],
 * }} param
 */
function createStairVertexesForVR(param) {
  // 現在の頂点と次の頂点を結ぶ線分を取得
  const currentLineVec = { startPoint: param.startPoint, endPoint: param.nextPoint };

  // 現在の頂点の反対側にある頂点を取得
  const otherSideCurrentPoint = getOtherSidePoint({
    lineData: currentLineVec,
    targetPoint: param.startPoint,
    movingDistance: meterToPx(param.width),
    isGo: true,
    isTargetRotation: false,
    isClockwiseRotation: param.isClockwise,
  });

  // 新たに次の頂点を取得（次が終点なら次の頂点、終点でなければ次の頂点を基準に取得）
  const newNextPoint = param.isEndAtNext
    ? param.nextPoint
    : getOtherSidePoint({
        lineData: currentLineVec,
        targetPoint: param.nextPoint,
        movingDistance: meterToPx(param.width),
        isGo: false,
        isTargetRotation: false,
        isClockwiseRotation: null,
      });

  // 新しく座標群を生成する
  // 階段の１点目の頂点を追加
  param.newVertexes.push(otherSideCurrentPoint);
  // 階段の２点目の頂点を追加
  param.newVertexes.push(param.startPoint);
  // 階段の３点目の頂点を追加
  param.newVertexes.push(newNextPoint);

  return {
    currentLineVec: currentLineVec,
    newNextPoint: newNextPoint,
  };
}
