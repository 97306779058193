<!--
作成者：
作成日：
機能：
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="AccountView">
    <b-overlay :show="loading" opacity="0.25">
      <h4 class="font-weight-bold">
        アカウント{{ mode == 'Add' ? '追加' : mode == 'View' ? '詳細' : '編集' }}
      </h4>
      <b-container style="padding:0;">
        <!-- 上部ボタン -->
        <b-row>
          <b-col>
            <b-navbar>
              <b-navbar-nav class="ml-auto">
                <router-link to="/Account/List?page=true" tag="button" class="btn btn-ally">
                  アカウント一覧に戻る
                </router-link>
              </b-navbar-nav>
            </b-navbar>
          </b-col>
        </b-row>
        <!-- アカウント編集画面 -->
        <b-row>
          <b-col>
            <b-card no-body class="ally-input-background">
              <ValidationObserver slim ref="account">
                <b-container>
                  <b-row class="account-items">
                    <!--左側-->
                    <b-col style="border-right:1px solid rgb(154,154,154);">
                      <b-row>
                        <b-col cols="3">
                          <allyText
                            title="No."
                            name="serialNumber"
                            v-model="value.serialNumber"
                            :mode="mode"
                          />
                        </b-col>
                        <b-col cols="9">
                          <allyText
                            :title="mode == 'Add' ? 'E-mail' : 'ID'"
                            :description="
                              mode == 'Add' ? '※登録したメールアドレスがIDに設定されます。' : ''
                            "
                            name="mail"
                            v-model="value.mail"
                            :mode="mode == 'Edit' ? 'View' : mode"
                            rules="required|email"
                            :placeholder="'xxxxxxxxx@xxx.co.jp'"
                          />
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <allyText
                            title="姓"
                            name="lastName"
                            v-model="value.lastName"
                            :mode="mode"
                            rules="required|max:10"
                            :placeholder="'新井'"
                          />
                        </b-col>
                        <b-col>
                          <allyText
                            title="名"
                            name="firstName"
                            v-model="value.firstName"
                            :mode="mode"
                            rules="required|max:10"
                            :placeholder="'太郎'"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <allyText
                            title="電話番号"
                            name="phone"
                            v-model="value.phone"
                            :mode="mode"
                            rules="tel|aorbrequired:@携帯電話番号"
                            :placeholder="'0196000000'"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <allyText
                            title="携帯電話番号"
                            name="mobilePhoneNumber"
                            v-model="value.mobilePhoneNumber"
                            :mode="mode"
                            rules="tel|aorbrequired:@電話番号"
                            :placeholder="'0196000000'"
                          />
                          <span class="appendix">
                            ※IDを忘れた場合に、SMSメールにて照会できます。
                          </span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <allyRadio
                            title="性別"
                            name="gender"
                            :items="[
                              { text: '男', value: 'male' },
                              { text: '女', value: 'female' },
                            ]"
                            v-model="value.gender"
                            :mode="mode"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                    <!--右側-->
                    <b-col>
                      <b-row>
                        <b-col>
                          <allyDate
                            title="生年月日"
                            name="birthday"
                            v-model="value.birthday"
                            :mode="mode"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <allyText
                            title="部署"
                            name="address"
                            v-model="value.address"
                            :mode="mode"
                            :placeholder="'〇〇支店'"
                            rules="max:30"
                          />
                        </b-col>
                      </b-row>
                      <b-row v-if="mode == 'Add'">
                        <b-col>
                          <allyText
                            title="パスワード"
                            name="xxxxxxxx"
                            v-model="value.password"
                            :mode="mode == 'Edit' ? 'View' : mode"
                            :placeholder="'8文字以上・大小文字・数字含む'"
                            rules="required|password"
                            type="password"
                            autocomplete="new-password"
                          />
                        </b-col>
                        <b-col>
                          <allyText
                            title="パスワードの確認"
                            name="passwordCheck"
                            v-model="value.passwordCheck"
                            :mode="mode == 'Edit' ? 'View' : mode"
                            :placeholder="'8文字以上・大小文字・数字含む'"
                            rules="required|PasswordCheck:@パスワード"
                            type="password"
                            autocomplete="new-password"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <allyRadio
                            title="権限"
                            name="isAdmin"
                            :items="[
                              { text: '管理者', value: true },
                              {
                                text: 'メンバー',
                                value: false,
                              },
                            ]"
                            v-model="value.isAdmin"
                            :mode="isCurrentUser ? 'View' : mode"
                            rules="required"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-container>
              </ValidationObserver>
            </b-card>
          </b-col>
        </b-row>
        <!-- 下部ボタン -->
        <b-row>
          <b-col>
            <b-navbar>
              <b-navbar-nav class="ml-auto">
                <button class="btn btn-ally" v-on:click="moveView" v-if="mode == 'Edit'">
                  詳細に戻る
                </button>
                <button
                  class="btn btn-ally"
                  v-if="mode == 'Edit' && !isCurrentUser"
                  v-b-modal.changePasswordModal
                >
                  パスワード変更
                </button>
                <button class="btn btn-ally" v-on:click="create" type="submit" v-if="mode == 'Add'">
                  登録
                </button>
                <button class="btn btn-ally" v-on:click="moveEdit" v-if="mode == 'View'">
                  編集
                </button>
                <button class="btn btn-ally" v-on:click="update" v-if="mode == 'Edit'">
                  保存
                </button>
              </b-navbar-nav>
            </b-navbar>
          </b-col>
        </b-row>
      </b-container>
      <!-- ロード中 -->
      <template #overlay>
        <Load />
      </template>
    </b-overlay>
    <!-- モーダル -->
    <b-modal
      id="changePasswordModal"
      size="lg"
      title="パスワード変更"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      dialog-class="change-password-modal"
      cancel-variant="light"
      ok-variant="light"
      cancel-title="キャンセル"
      ok-title="変更"
      @ok="adminPasswordSetup"
      @hidden="modalClear"
      centered
    >
      <ValidationObserver tag="div" slim ref="adminPasswordSetup">
        <b-container>
          <b-row>
            <b-col>
              <allyText
                title="新規パスワード"
                name="password.newPassword"
                v-model="passwordModal.newPassword"
                mode="Edit"
                rules="required"
                placeholder="8文字以上・大小文字・数字含む"
                type="password"
                autocomplete="new-password"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <allyText
                title="新規パスワードの確認"
                name="password.newPasswordCheck"
                v-model="passwordModal.newPasswordCheck"
                mode="Edit"
                rules="required|NewAndNewCheck:@新規パスワード"
                placeholder="8文字以上・大小文字・数字含む"
                type="password"
                autocomplete="new-password"
              />
            </b-col>
          </b-row>
        </b-container>
      </ValidationObserver>
    </b-modal>
    <!-- メッセージ -->
    <allyAlert :code="'E101'" :title="'パスワードを再入力してください'"></allyAlert>
    <allyAlert :code="'E103'" :title="'パスワードを再入力してください'"></allyAlert>
    <allyAlert :code="'E105'" :title="'パスワードを再入力してください'"></allyAlert>
    <allyAlert :code="'E106'" :title="'パスワードを再入力してください'"></allyAlert>
    <allyAlert :code="'E107'" :title="'パスワードを再入力してください'"></allyAlert>
    <allyAlert :code="'E112'"></allyAlert>
    <allyAlert
      :code="'E113'"
      :title="isChanged == 'create' ? 'アカウント登録失敗' : 'アカウント更新失敗'"
    >
    </allyAlert>
    <allyAlert
      :code="'S100'"
      :title="
        isChanged == 'create'
          ? 'アカウント登録成功'
          : isChanged == 'update'
          ? 'アカウント更新成功'
          : 'パスワード変更成功'
      "
      @okAction="pageLink()"
    >
    </allyAlert>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { createAccount, updateAccount, adminPasswordSetup } from '../../graphql/mutations';
  import { getAccount, changeNonSettlement } from '../../graphql/queries';

  import Load from '@/components/Common/Load';
  import store from '@/store';
  import { extend } from 'vee-validate';

  API.configure(awsconfig);

  export default {
    props: ['newSerialNumber'],

    data: function() {
      this.modalClear();
      return {
        loading: true,
        id: this.$route.params.id,
        value: {
          mail: null,
          lastName: null,
          firstName: null,
          phone: null,
          gender: null,
          birthday: null,
          address: null,
          isAdmin: null,
          password: null,
        },
        isChanged: null,
        isCurrentUser:
          this.$route.params.id === this.$store.state.user.signInUserSession.idToken.payload.sub,
        passwordCheck: null,
        date: null,
      };
    },
    mounted: async function() {
      this.date = this.today;
      if (this.mode == 'View' || this.mode == 'Edit') {
        var result = await API.graphql(graphqlOperation(getAccount, { sub: this.id }));
        var values = result.data.getAccount;

        this.value = {
          mail: values.email,
          lastName: values.familyname,
          firstName: values.givenname,
          phone: values.phonenumber,
          mobilePhoneNumber: values.mobilePhoneNumber,
          gender: values.gender,
          birthday: values.birthdate,
          address: values.address,
          isAdmin: values.isAdmin,
          password: '****************',
          serialNumber: values.serialNumber,
        };
      } else if (this.mode == 'Add') {
        this.value.serialNumber = this.$route.params.newSerialNumber;
      }

      this.loading = false;
    },
    computed: {
      mode: function() {
        if (this.$router.currentRoute.path.startsWith('/Account/Add')) {
          return 'Add';
        } else if (this.$router.currentRoute.path.startsWith('/Account/Edit'))
          if (this.loading) return 'View';
          else return 'Edit';
        else return 'View';
      },
      name: function() {
        return this.value.lastName ? this.value.lastName.concat(this.value.firstName) : null;
      },
    },
    methods: {
      create: async function() {
        const isValid = await this.$refs.account.validate();
        if (isValid) {
          try {
            this.loading = true;
            var input = {
              email: this.value.mail,
              familyname: this.value.lastName,
              givenname: this.value.firstName,
              phonenumber: this.value.phone,
              mobilePhoneNumber: this.value.mobilePhoneNumber,
              gender: this.value.gender,
              birthdate: this.value.birthday,
              address: this.value.address,
              isAdmin: this.value.isAdmin,
              password: this.value.password,
              name: this.name,
              serialNumber: this.value.serialNumber,
            };

            //アカウントを作成する
            this.isChanged = 'create';
            await API.graphql(graphqlOperation(createAccount, { input: input }));

            //非決済モード時、ライセンスの割当を行う
            if (this.$store.state.privilegedFlag == true) {

              await API.graphql(graphqlOperation(changeNonSettlement, { 
                date: this.date,
                functionSwitch: "UPDATE_LICENSE",
                companyId: this.getCompanyId(),
              }));
            }

            //登録完了モーダルを表示する。
            this.$bvModal.show('S100');
          } catch (e) {
            const code = e.errors[0].message;

            if (code === 'UsernameExistsException') {
              this.$bvModal.show('E113');
            } else {
              // システムエラー
              this.$bvModal.show('E101');
            }
          } finally {
            this.loading = false;
          }
        } else {
          alert("必須項目が入力されていないか、パスワードが一致していません。ご確認ください。")
        }
      },
      update: async function() {
        const isValid = await this.$refs.account.validate();
        if (isValid) {
          try {
            this.loading = true;
            var input = {
              email: this.value.mail,
              familyname: this.value.lastName,
              givenname: this.value.firstName,
              phonenumber: this.value.phone,
              mobilePhoneNumber: this.value.mobilePhoneNumber,
              gender: this.value.gender,
              birthdate: this.value.birthday,
              address: this.value.address,
              isAdmin: this.value.isAdmin,
              name: this.value.lastName ? this.value.lastName.concat(this.value.firstName) : null,
              serialNumber: this.value.serialNumber,
            };

            await API.graphql(graphqlOperation(updateAccount, { input: input }));

            //登録完了モーダルを表示する。
            this.isChanged = 'update';
            this.$bvModal.show('S100');
          } catch (e) {
            // システムエラー
            this.$bvModal.show('E101');
          } finally {
            this.loading = false;
          }
        }
      },
      pageLink: function() {
        if (this.isChanged != 'password') {
          //保存フラグをtrueに設定（ページ移動の警告メッセージを防ぐ為）
          store.commit('setSaveFlg', true);
          this.$router.push('/Account/List');
        }
      },
      modalClear: function() {
        this.passwordModal = {
          newPassword: null,
          newPasswordCheck: null,
        };
      },
      adminPasswordSetup: async function(event) {
        // バリデーションエラーの場合クローズしない
        event.preventDefault();

        const isValid = await this.$refs.adminPasswordSetup.validate();
        if (isValid) {
          try {
            this.loading = true;
            const input = {
              email: this.value.mail,
              password: this.passwordModal.newPassword,
            };

            const result = await API.graphql(
              graphqlOperation(adminPasswordSetup, { input: input })
            );

            // エラーコード
            const code = result.data.adminPasswordSetup;

            // 変更成功の場合
            if (code === null) {
              // 保存フラグをtrueに設定（ページ移動の警告メッセージを防ぐ為）
              store.commit('setSaveFlg', true);
              this.modalClear();
              this.isChanged = 'password';
              this.$bvModal.hide('changePasswordModal');
              this.$bvModal.show('S100');
            }
            // パスワード変更の試行の制限を超えた場合
            else if (code === 'LimitExceededException') {
              this.$bvModal.show('E105');
            }
            // 入力した既存パスワードが間違った場合
            else if (code === 'NotAuthorizedException') {
              this.$bvModal.show('E106');
            }
            // パスワード変更ルールを守らなかった場合
            else if (code === 'InvalidPasswordException') {
              this.$bvModal.show('E103');
            }
            // パスワードの桁数が１０未満の場合
            else if (code === 'InvalidParameterException') {
              this.$bvModal.show('E107');
            }
            // 上記以外システムaエラーの場合
            else {
              this.$bvModal.show('E101');
            }
          } catch (e) {
            this.$bvModal.show('E0001');
          } finally {
            this.loading = false;
          }
        }
      },
      moveEdit: function() {
        this.$router.push('/Account/Edit/' + this.$data.id);
      },
      moveView: function() {
        this.$router.push('/Account/View/' + this.$data.id);
      },
      getCompanyId: function() {
        //アカウントから会社ID取得
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex].replace('Company-', '');
      },
    },
    // ページング処理コンポーネント呼び出し
    components: {
      Load,
    },
  };
  extend('PasswordCheck', {
    params: ['target'],
    validate(value, { target }) {
      return value === target;
    },
    message: 'パスワードとパスワードの確認が一致しておりません',
  });
</script>
<!-- スタイル -->
<style scoped>
  #AccountView >>> .container {
    max-width: 100%;
  }
  .ally-input-background .container {
    padding: 1rem;
  }
  #AccountView >>> nav {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }
  #AccountView >>> nav > ul > button {
    margin-left: 0.5rem;
  }
  #AccountView >>> .card {
    border-top-color: gainsboro !important;
    margin-bottom: 1rem;
  }
  .account-items > .col {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .appendix {
    font-size: small;
    position: relative;
    top: -1rem;
  }
</style>
<style>
  .change-password-modal {
    max-width: 30rem !important;
  }
</style>
