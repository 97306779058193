<!--
作成者：Park Chongmyung
作成日：2020.6.19
画面名：マイページ照会・修正
機能：自分のアカウント情報を確認、修正
親子関係：なし
更新者：
①
更新内容：
①
-->
<template>
  <div id="MyPageView">
    <b-overlay :show="loading" opacity="0.25">
      <h4 class="font-weight-bold">マイページ{{ mode == 'View' ? '詳細' : '変更' }}</h4>
      <br />
      <b-container style="padding:0;">
        <!-- マイページ編集画面 -->
        <b-row>
          <b-col>
            <b-card no-body class="ally-input-background">
              <ValidationObserver slim ref="account">
                <b-container>
                  <b-row class="my-page-items">
                    <!--左側-->
                    <b-col style="border-right:1px solid rgb(154,154,154);">
                      <b-row>
                        <b-col cols="3">
                          <allyText
                            title="No."
                            name="serialNumber"
                            v-model="value.serialNumber"
                            :mode="mode"
                          />
                        </b-col>
                        <b-col cols="9">
                          <allyText
                            :title="mode == 'Add' ? 'E-mail' : 'ID'"
                            :description="
                              mode == 'Add' ? '※登録したメールアドレスがIDに設定されます。' : ''
                            "
                            name="mail"
                            v-model="value.mail"
                            :mode="mode == 'Edit' ? 'View' : mode"
                            rules="required|email"
                            :placeholder="'xxxxxxxxx@xxx.co.jp'"
                          />
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <allyText
                            title="姓"
                            name="lastName"
                            v-model="value.lastName"
                            :mode="mode"
                            rules="required"
                            :placeholder="'新井'"
                          />
                        </b-col>
                        <b-col>
                          <allyText
                            title="名"
                            name="firstName"
                            v-model="value.firstName"
                            :mode="mode"
                            rules="required"
                            :placeholder="'太郎'"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <allyText
                            title="電話番号"
                            name="phone"
                            v-model="value.phone"
                            :mode="mode"
                            rules="tel|aorbrequired:@携帯電話番号"
                            :placeholder="'0196000000'"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <allyText
                            title="携帯電話番号"
                            name="mobilePhoneNumber"
                            v-model="value.mobilePhoneNumber"
                            :mode="mode"
                            rules="tel|aorbrequired:@電話番号"
                            :placeholder="'0196000000'"
                          />
                          <span class="appendix">
                            ※IDを忘れた場合に、SMSメールにて照会できます。
                          </span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <allyRadio
                            title="性別"
                            name="gender"
                            :items="[
                              { text: '男', value: 'male' },
                              { text: '女', value: 'female' },
                            ]"
                            v-model="value.gender"
                            :mode="mode"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                    <!--右側-->
                    <b-col>
                      <b-row>
                        <b-col>
                          <allyDate
                            title="生年月日"
                            name="birthday"
                            v-model="value.birthday"
                            :mode="mode"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <allyText
                            title="部署"
                            name="office"
                            v-model="value.office"
                            :mode="mode"
                            :placeholder="'〇〇支店'"
                          />
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <allyRadio
                            title="権限"
                            name="isAdmin"
                            :items="[
                              { text: '管理者', value: true },
                              {
                                text: 'メンバー',
                                value: false,
                              },
                            ]"
                            v-model="value.isAdmin"
                            mode="View"
                            rules="required"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-container>
              </ValidationObserver>
            </b-card>
          </b-col>
        </b-row>
        <!-- 下部ボタン -->
        <b-row>
          <b-col>
            <b-navbar>
              <b-navbar-nav class="ml-auto">
                <button class="btn btn-ally" v-on:click="moveView" v-if="mode == 'Edit'">
                  詳細に戻る
                </button>
                <button class="btn btn-ally" v-if="mode == 'Edit'" v-b-modal.changePasswordModal>
                  パスワード変更
                </button>
                <button class="btn btn-ally" v-on:click="moveEdit" v-if="mode == 'View'">
                  変更
                </button>
                <button class="btn btn-ally" v-on:click="update" v-if="mode == 'Edit'">
                  保存
                </button>
              </b-navbar-nav>
            </b-navbar>
          </b-col>
        </b-row>
      </b-container>
      <!-- ロード中 -->
      <template #overlay>
        <Load />
      </template>
    </b-overlay>
    <!-- モーダル -->
    <b-modal
      id="changePasswordModal"
      size="lg"
      title="パスワード変更"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      dialog-class="change-password-modal"
      cancel-variant="light"
      ok-variant="light"
      cancel-title="キャンセル"
      ok-title="変更"
      @ok="changePassword"
      @hidden="modalClear"
      centered
    >
      <ValidationObserver tag="div" slim ref="changePassword">
        <b-container>
          <b-row>
            <b-col>
              <allyText
                title="既存パスワード"
                name="password.oldPassword"
                v-model="passwordModal.oldPassword"
                mode="Edit"
                rules="required"
                placeholder="現在のパスワード"
                type="password"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <allyText
                title="新規パスワード"
                name="password.newPassword"
                v-model="passwordModal.newPassword"
                mode="Edit"
                rules="required"
                placeholder="8文字以上・大小文字・数字含む"
                type="password"
                autocomplete="new-password"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <allyText
                title="新規パスワードの確認"
                name="password.newPasswordCheck"
                v-model="passwordModal.newPasswordCheck"
                mode="Edit"
                rules="required|NewAndNewCheck:@新規パスワード"
                placeholder="8文字以上・大小文字・数字含む"
                type="password"
                autocomplete="new-password"
              />
            </b-col>
          </b-row>
        </b-container>
      </ValidationObserver>
    </b-modal>
    <!-- メッセージ -->
    <allyAlert code="E101" :title="'パスワードを再入力してください'"></allyAlert>
    <allyAlert code="E103" :title="'パスワードを再入力してください'"></allyAlert>
    <allyAlert code="E105" :title="'パスワードを再入力してください'"></allyAlert>
    <allyAlert code="E106" :title="'パスワードを再入力してください'"></allyAlert>
    <allyAlert code="E107" :title="'パスワードを再入力してください'"></allyAlert>
    <allyAlert
      code="S100"
      :title="isChangePassword ? 'パスワード変更が完了しました。' : '変更が完了しました。'"
      @okAction="
        () => {
          if (!this.isChangePassword) {
            this.moveView();
          }
        }
      "
    >
    </allyAlert>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { Auth, API, graphqlOperation } from 'aws-amplify';
  import { updateAccount } from '../../graphql/mutations';

  import Load from '../../components/Common/Load';
  import { extend } from 'vee-validate';

  API.configure(awsconfig);

  export default {
    data: function() {
      this.modalClear();
      return {
        value: {
          mail: null,
          lastName: null,
          firstName: null,
          phone: null,
          gender: null,
          birthday: null,
          office: null,
          isAdmin: null,
          password: null,
          serialNumber: null,
        },

        loading: true,
        isChangePassword: null,
      };
    },
    mounted: async function() {
      const info = await Auth.currentUserInfo();

      this.value = {
        mail: info.attributes.email,
        lastName: info.attributes.family_name,
        firstName: info.attributes.given_name,
        phone: info.attributes.phone_number ? '0' + info.attributes.phone_number.substr(3) : null,
        mobilePhoneNumber: info.attributes['custom:mobilePhoneNumber'],
        gender: info.attributes.gender,
        birthday: info.attributes.birthdate,
        office: info.attributes.address,
        isAdmin: this.authority == '管理者',
        serialNumber: info.attributes['custom:number'],
      };

      this.loading = false;
    },
    methods: {
      modalClear: function() {
        this.passwordModal = {
          oldPassword: null,
          newPassword: null,
          newPasswordCheck: null,
        };
      },
      update: async function() {
        const isValid = await this.$refs.account.validate();
        if (isValid) {
          try {
            this.loading = true;
            var input = {
              email: this.value.mail,
              familyname: this.value.lastName,
              givenname: this.value.firstName,
              phonenumber: this.value.phone,
              mobilePhoneNumber: this.value.mobilePhoneNumber,
              gender: this.value.gender,
              birthdate: this.value.birthday,
              address: this.value.office,
              isAdmin: this.value.isAdmin,
              name: this.value.lastName ? this.value.lastName.concat(this.value.firstName) : null,
              serialNumber: this.value.serialNumber,
            };

            await API.graphql(graphqlOperation(updateAccount, { input: input }));

            // 登録完了モーダルを表示する。
            this.messageOk = '変更が完了しました。';
            this.isChangePassword = false;
            this.$bvModal.show('S100');
          } catch (e) {
            // システムエラー
            this.$bvModal.show('E101');
          } finally {
            this.loading = false;
          }
        }
      },
      changePassword: async function(bvModalEvt) {
        /*有効性チェック。有効じゃない場合、modalをクローズしない */
        bvModalEvt.preventDefault();

        /*htmlから宣言した「ValidationObserver」のref「changePassword」の値「true」or「false」を取得する */
        this.$refs.changePassword.validate().then(async isValid => {
          /*「ValidationObserver」のref「changePassword」の値が「true」の場合*/
          if (isValid) {
            /* パスワード変更APIを実行する */
            const cognitoUser = await Auth.currentAuthenticatedUser();

            return Auth.changePassword(
              cognitoUser,
              this.passwordModal.oldPassword,
              this.passwordModal.newPassword
            )
              .then(() => {
                this.$bvModal.hide('changePasswordModal');
                /*正常的に処理された場合、別途のモーダルを出力し、成功メッセージを表示する */
                this.isChangePassword = true;
                this.$bvModal.show('S100');
              })
              .catch(err => {
                /*エラー発生時に別途のモーダルを出力*/
                /*cognitoからもらったエラーコードを比較し、エラーメッセージを表示する*/
                // パスワード変更の試行の制限を超えた場合
                if (err.code == 'LimitExceededException') {
                  this.$bvModal.show('E105');
                }
                // 入力した既存パスワードが間違った場合
                else if (err.code == 'NotAuthorizedException') {
                  this.$bvModal.show('E106');
                }
                // パスワード変更ルールを守らなかった場合
                else if (err.code == 'InvalidPasswordException') {
                  this.$bvModal.show('E103');
                }
                // パスワードの桁数が１０未満の場合
                else if (err.code == 'InvalidParameterException') {
                  this.$bvModal.show('E107');
                }
                // 上記以外システムエラーの場合
                else {
                  this.$bvModal.show('E101');
                }
                return;
              });
          }
        });
      },
      moveEdit: function() {
        this.$router.push('/Mypage/Edit/');
      },
      moveView: function() {
        this.$router.push('/Mypage/View/');
      },
    },
    computed: {
      // モード
      mode: function() {
        if (this.$router.currentRoute.path.startsWith('/Mypage/Edit')) {
          return this.loading ? 'View' : 'Edit';
        } else {
          return 'View';
        }
      },
      // 権限
      authority: function() {
        const userGroup = this.$store.state.user.signInUserSession.accessToken.payload[
          'cognito:groups'
        ];
        if (userGroup.indexOf('systemAdmin') != -1) {
          return 'システム管理者';
        } else if (userGroup.indexOf('admin') != -1) {
          return '管理者';
        } else {
          return 'メンバー';
        }
      },
    },
    components: {
      Load,
    },
  };

  //カスタルール
  //新規パスワードと新規パスワード確認の比較
  extend('NewAndNewCheck', {
    params: ['target'],
    validate(value, { target }) {
      return value === target;
    },
    message: '新規パスワードと新規パスワードの確認が一致しておりません',
  });
</script>
<style scoped>
  #MyPageView >>> .container {
    max-width: 100%;
  }
  .ally-input-background .container {
    padding: 1rem;
  }
  #MyPageView >>> nav {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }
  #MyPageView >>> nav > ul > button {
    margin-left: 0.5rem;
  }
  #MyPageView >>> .card {
    border-top-color: gainsboro !important;
    margin-bottom: 1rem;
  }
  .appendix {
    font-size: small;
    position: relative;
    top: -1rem;
  }
</style>
<style>
  .change-password-modal {
    max-width: 30rem !important;
  }
</style>
