// @ts-check
import { Vec } from '../../../util/math/vector';
import { Action } from '../action';
import { ActionBuilder } from './actionBuilder';

export class NoneActionBuilder extends ActionBuilder {
  constructor() {
    // @ts-ignore
    super(null, null);
  }
  build() {
    const action = new Action();
    action.actionType = Action.ACTION_TYPE.NONE;

    action.routes = [new Vec()];

    return action;
  }
}
