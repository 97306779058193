<template>
  <div id="LicenseAssign">
    <b-overlay :show="loading" opacity="0.25">
      ライセンス割当
      <b-container style="max-width:none;">
        <!-- 上部ボタン -->
        <b-row>
          <b-col>
            <b-navbar>
              <b-navbar-nav class="ml-auto">
                <router-link to="/License/Main" tag="button" class="btn btn-ally">
                  戻る
                </router-link>
              </b-navbar-nav>
            </b-navbar>
          </b-col>
        </b-row>
        <!-- 割当表 -->
        <b-row v-show="!loading">
          <!-- ライセンス未購入 -->
          <b-col v-if="licenses.length == 0">
            <svg
              class="bi bi-exclamation-diamond-fill"
              width="8em"
              height="8em"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
              />
            </svg>
            <b-alert id="alert" show>
              ライセンスがありません
            </b-alert>
          </b-col>
          <!-- ライセンス購入済 -->
          <b-col v-else>
            <!-- アカウント一覧 -->
            <b-row>
              <b-col class="col-4" style="padding:0;">
                <b-table-simple
                  striped
                  bordered
                  :items="accounts"
                  :fields="fields"
                >
                  <b-thead>
                    <b-tr class="serch-data-table-header">
                      <b-th colspan="3" style="height:73px;">
                        アカウント
                      </b-th>
                    </b-tr>
                    <b-tr class="serch-data-table-header">
                      <b-th>名前</b-th>
                      <b-th>部署</b-th>
                      <b-th>ID</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="account in accounts" v-bind:key="account.email">
                      <b-td style="height:71px;"> {{ account.name }} </b-td>
                      <b-td style="height:71px;">
                        {{ account.department }}
                      </b-td>
                      <b-td style="height:71px; white-space:normal; word-break:break-all; overflow-wrap:break-all;">
                        {{ account.email }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
              <b-col class="col-8" style="padding:0;">
                <b-table-simple
                  striped
                  bordered
                  responsive
                  :items="accounts"
                  :fields="fields"
                >
                  <b-thead>
                    <b-tr class="serch-data-table-header">
                      <b-th
                        style="white-space:normal;min-width:120px;padding:0.5rem;height:73px;"
                        v-for="license in licenses"
                        v-bind:key="license.id"
                      >
                        {{ license.name }}
                      </b-th>
                    </b-tr>
                    <b-tr class="serch-data-table-header">
                      <b-th
                        v-for="license in licenses"
                        v-bind:key="license.id"
                        style="font-weight:normal;"
                      >
                        {{ checkedCount(license.id) }} / {{ license.quantity }}
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="account in accounts" v-bind:key="account.email">
                      <b-td style="height:71px;" class="checkbox-column" v-for="license in licenses" v-bind:key="license.id">
                        <b-form-checkbox
                          v-model="account.assigned[license.id]"
                          size="lg"
                          :disabled="
                            updating ||
                              (!account.assigned[license.id] &&
                                (license.quantity == checkedCount(license.id) ||
                                  duplicated(account, license)))
                          "
                        >
                        </b-form-checkbox>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- 下部ボタン -->
        <b-row>
          <b-col>
            <b-navbar>
              <b-navbar-nav class="ml-auto">
                <b-button variant="ally" @click="updateAssign" :disabled="updating">
                  更新
                </b-button>
              </b-navbar-nav>
            </b-navbar>
          </b-col>
        </b-row>
      </b-container>
      <!-- ロード中 -->
      <template #overlay>
        <Load />
      </template>
    </b-overlay>
    <!-- メッセージ -->
    <allyAlert code="E0001" />
    <allyAlert title="更新を完了しました。" code="S100" />
  </div>
</template>

<!-- スクリプト -->
<script>
  import awsconfig from '../../aws-exports';
  import { API, Auth, graphqlOperation } from 'aws-amplify';
  import Load from '../../components/Common/Load';
  import { listAccounts, getLicense } from '@/graphql/queries';
  import { assignLicense } from '@/graphql/mutations';

  import Common from './js/Common';
  API.configure(awsconfig);
  Auth.configure(awsconfig);

  var moment = require('moment-timezone');
  moment.tz.setDefault('Asia/Tokyo');

  // アカウント一覧のヘッダ
  const headers = [
    {
      key: 'name',
      label: '名称',
    },
    {
      key: 'department',
      label: '部署',
    },
    {
      key: 'email',
      label: 'ID',
    },
  ];
  export default {
    components: {
      Load,
    },
    mixins: [Common],
    data: function() {
      return {
        companyId: null, // ログインユーザーの会社ID
        master: null, // マスタ
        company: null, // 会社情報
        licenses: [], // 購入ライセンス
        accounts: [], // アカウント
        loading: true, // ロード中
        updating: false, // 更新中
        fields: null, // アカウント一覧のヘッダ
      };
    },
    mounted: async function() {
      // システム日付
      const today = this.today;

      // マスタ取得
      this.master = await this.loadMaster();

      // ログインユーザーの会社IDを取得
      this.companyId = this.getCompanyId();

      // 会社情報を取得
      this.company = await this.getCompany(this.companyId);

      // アカウント情報取得
      const queryVar = {
        limit: 50,
      };
      do {
        const queryResult = await API.graphql(graphqlOperation(listAccounts, queryVar));
        const result = queryResult.data['listAccounts'];
        for (let item of result.items) {
          // ライセンス割当取得
          const result = await API.graphql(graphqlOperation(getLicense, { id: item.sub }));
          let licenses = [];
          if (result.data.getLicense.statusCode == '200') {
            licenses = JSON.parse(result.data.getLicense.body).licenses;
            if (licenses != null) {
              licenses = String(licenses).split(',');
            }
          }

          this.accounts.push({
            name: item.familyname + ' ' + item.givenname, // 名前
            department: item.address, // 部署
            email: item.email,
            id: item.sub,
            licenses: licenses,
            assigned: [], // 割当状態
          });
        }
        queryVar.nextToken = result.nextToken;
      } while (queryVar.nextToken != null);

      // 保有ライセンス取得
      const licenses =
        this.company.license == null
          ? []
          : this.company.license.filter(v => {
              return v.startDate <= today && today <= v.endDate && v.isValid;
            });

      // ライセンス毎に集計
      this.licenses = [];
      for (let license of licenses) {
        const found = this.licenses.find(l => {
          return l.id == license.id;
        });
        if (found != null) {
          found.quantity += license.quantity;
        } else {
          let li = this.master.find(m => {
              return m.id == license.id;
          });

          //有効では無いライセンスは表示しない
          if(li != null){
            this.licenses.push({
              id: license.id,
              functions: license.functions,
              name: li.name,
              quantity: license.quantity,
            });
          }
        }
      }
      this.licenses.sort((a, b) => {
        return a.id - b.id;
      });

      // カラムヘッダ設定
      for (let license of licenses) {
        headers.push({
          key: license.id,
          label: license.name,
        });
      }
      this.fields = headers;

      // チェックボックス初期化
      for (let account of this.accounts) {
        let assigned = {};
        for (let license of this.licenses) {
          assigned[license.id] = account.licenses.some(v => {
            return v.toString() === license.id.toString();
          });
        }
        account.assigned = assigned;
      }

      this.loading = false;
    },

    computed: {
      // チェックされた数
      checkedCount: function() {
        return function(id) {
          let count = 0;
          for (let account of this.accounts) {
            if (account.assigned[id]) {
              count++;
            }
          }
          return count;
        };
      },
      // 割り当てられた機能
      assignedFunctions: function() {
        return function(account) {
          let functions = [];
          for (let license of this.master) {
            if (account.assigned[license.id]) {
              if (license.functions) {
                functions = functions.concat(license.functions);
              } else {
                functions.push(license.id);
              }
            }
          }
          return functions;
        };
      },
      // ライセンス重複チェック
      duplicated: function() {
        return function(account, license) {
          // 該当ライセンスの機能
          const master = this.master.find(m => {
            return m.id == license.id;
          });
          const functions = master.functions ? master.functions : [license.id];
          // 割当済機能
          const assigned = this.assignedFunctions(account);
          // 重複確認
          for (let f of functions) {
            for (let a of assigned) {
              if (f == a) {
                // 重複機能あり
                return true;
              }
            }
          }
          // 重複機能なし
          return false;
        };
      },
    },

    methods: {
      // 割当更新
      updateAssign: async function() {
        this.updating = true;
        try {
          const user = await Auth.currentAuthenticatedUser();
          for (let account of this.accounts) {
            let licenses = Object.entries(account.assigned)
              .filter(v => {
                return v[1];
              })
              .map(x => {
                return Number(x[0]);
              });

            await API.graphql(
              graphqlOperation(assignLicense, {
                id: account.id,
                licenses: licenses.toString(),
              })
            );

            // ログインユーザーの場合
            if (account.id == user.username) {
              // 保有ライセンス更新
              await Auth.updateUserAttributes(user, {
                'custom:licenses': licenses.toString(),
              });
              this.$store.commit('setUser', {
                ...user,
                attributes: { ...user.attributes, 'custom:licenses': licenses.toString() },
              });
              // 使用可能機能更新
              await this.updateLicensedFunctions();
            }
          }

          this.$bvModal.show('S100');
        } catch (e) {
          console.error(e);
          this.showError('E0001', e);
        }
        this.updating = false;
      },
    },
  };
</script>
<style scoped>
  #LicenseAssign >>> nav {
    box-shadow: none;
  }
  #LicenseAssign >>> nav > ul > button {
    margin-left: 0.5rem;
  }
  #alert {
    background-color: transparent;
    border: none;
  }
  #LicenseAssign >>> tr {
    height: 2rem;
  }
  #LicenseAssign >>> th {
    vertical-align: middle;
    padding: 0;
  }
  #LicenseAssign >>> .checkbox-column {
    text-align: center;
  }
  #LicenseAssign >>> .custom-checkbox {
    padding-left: 2.5rem;
  }
  #LicenseAssign >>> .custom-control-input:checked ~ .custom-control-label::before {
    background: var(--colorTheme);
    border-color: var(--colorTheme);
  }
</style>
