<template>
  <div>
    <!-- バリデーション -->
    <ValidationObserver ref="adjustmentMethodForm" v-slot="ObserverProps">
      <!-- モーダル本体 -->
      <b-modal
        id="AdjustmentMethod"
        ref="AdjustmentMethod"
        title="調整方法選択"
        header-class="ally-modal text-center"
        footer-class="ally-modal text-center"
        ok-title="登録"
        :ok-disabled="ObserverProps.invalid"
        @ok="updateAdjust()"
        cancel-title="キャンセル"
      >
        <!-- 調整方法選択 入力欄 -->
        <allySelect
          title="調整方法選択"
          :items="adjustmentMethodItems"
          v-model="selectedAdjustmentMethodStatus"
          rules="required"
        ></allySelect>
        <!-- 入金金額 入力欄 -->
        <allyText
          type="number"
          v-if="isAmountInput.result"
          :title="isAmountInput.title"
          min="0"
          v-model="enteredAmount"
          :rules="'required|numeric|' + validateRuleForAmount + '|max:10'"
        ></allyText>
        <!-- メモ 入力欄 -->
        <allyTextArea title="メモ" v-model="enteredMemo" rules="max:1000"></allyTextArea>
      </b-modal>
    </ValidationObserver>
  </div>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { changeOrUnregisterRecurring } from '../../../graphql/queries.js';
  import datasGraphQL from '../js/datasGraphQL.js';
  import wordData from '../js/wordData';
  import util from '../js/util.js';
  const moment = require('moment');
  export default {
    mixins: [datasGraphQL, wordData, util],
    data() {
      return {
        company: null, // 会社情報
        item: null, // アイテム
        targetPayment: [], // 対象月決済情報
        amountInputTitle: '', // 金額入力欄タイトル
        validateRuleForAmount: '', // 金額入力欄用バリデートルール
        adjustmentMethodItems: [], // 調整方法プルダウンアイテム
        depositRefundStatus: '', // 現在の調整方法
        selectedAdjustmentMethodStatus: '', // 選択された調整方法
        enteredAmount: '', // 入力された入金金額
        enteredMemo: '', // 入力されたメモ
      };
    },
    mounted: async function() {},
    computed: {
      // COMPUTED: 金額入力欄表示フラグ
      isAmountInput() {
        let label = '';
        let result;
        switch (this.selectedAdjustmentMethodStatus) {
          // 入金対応の場合
          case this.getDepositRefundStatus('DIRECT_PAYMENT').no:
            label = '入金金額';
            result = true;
            break;
          // 返金対応の場合
          case this.getDepositRefundStatus('DIRECT_REFUND').no:
            label = '返金金額';
            result = true;
            break;
          // それ以外
          default:
            result = false;
        }
        this.setValidateNumForAmount();
        return {
          title: label,
          result: result,
        };
      },
    },
    methods: {
      // METHOD: モーダル表示
      showModal(company, item) {
        console.log('SHOW', company, item);
        this.company = company;
        this.item = item;
        this.setInitialValue(); // 初期値セット
        this.createAdjustmentMethodItems(); // 調整方法プルダウンアイテム生成
        this.$bvModal.show('AdjustmentMethod');
      },

      // METHOD: 更新処理
      async updateAdjust() {
        // バリデーション
        if (!(await this.validateAdjustmentMethodForm())) {
          alert('入力値に問題がありました。正しい値を入力してください。');
          this.showModal(this.company, this.item);
          return;
        }
        // 入力情報を登録
        this.targetPayment.depositRefundInfo.status = this.selectedAdjustmentMethodStatus; // 調整方法
        this.targetPayment.depositRefundInfo.amount = this.isAmountInput.result // 金額（入金対応または返金対応のみ必要）
          ? this.enteredAmount // 金額入力欄が存在する場合は入力値
          : this.targetPayment.depositRefundInfo.amount // 金額入力欄が存在しない場合、登録中の値が存在するかチェック
          ? this.targetPayment.depositRefundInfo.amount // 存在する場合は登録中の値
          : null; // 存在しない場合はnull
        this.targetPayment.depositRefundInfo.memo = this.enteredMemo; // メモ
        this.targetPayment.depositRefundInfo.adjustmentDate = moment(this.today).format(
          'YYYY-MM-DD'
        );
        // 決済結果を更新
        switch (this.selectedAdjustmentMethodStatus) {
          // 未納
          case this.getDepositRefundStatus('UNPAID').no:
            this.targetPayment.paymentResult = false;
            break;
          // 入金対応
          case this.getDepositRefundStatus('DIRECT_PAYMENT').no:
            this.targetPayment.paymentResult = true;
            break;
          default:
            break;
        }

        // 会社情報を更新
        await this.updateCompany(this.company);

        if (
          this.selectedAdjustmentMethodStatus == this.getDepositRefundStatus('DIRECT_PAYMENT').no && // 入金対応の場合
          this.targetPayment.payType ==
            this.getPaymentType().find(payType => payType.key == 'ACCOUNT_TRANSFER_PAPER').no && // 口座振替の場合
          !this.company.payment.find(
            pay => pay.id == this.createPaymentId(this.targetPayment.id, 1) && pay.paymentResult // 翌月の決済結果が存在しない場合
          )
        ) {
          // 自動売上定義変更・解除を行う
          await this.changeOrUnregisterRecurring();
        }

        //売上一覧表を再読み込み
        this.$emit('initialize');
      },

      // METHOD: 調整方法プルダウンアイテム生成
      createAdjustmentMethodItems() {
        this.adjustmentMethodItems = [];
        switch (this.depositRefundStatus) {
          // 未納
          // 入金対応
          case this.getDepositRefundStatus('UNPAID').no:
          case this.getDepositRefundStatus('DIRECT_PAYMENT').no:
            this.adjustmentMethodItems.push(
              {
                text: this.getDepositRefundStatus('UNPAID').displayName,
                value: this.getDepositRefundStatus('UNPAID').no,
              },
              {
                text: this.getDepositRefundStatus('DIRECT_PAYMENT').displayName,
                value: this.getDepositRefundStatus('DIRECT_PAYMENT').no,
              }
            );
            break;
          // 返金対応
          // 翌月精算
          case this.getDepositRefundStatus('DIRECT_REFUND').no:
          case this.getDepositRefundStatus('NEXT_USE').no:
            this.adjustmentMethodItems.push(
              {
                text: this.getDepositRefundStatus('DIRECT_REFUND').displayName,
                value: this.getDepositRefundStatus('DIRECT_REFUND').no,
              },
              {
                text: this.getDepositRefundStatus('NEXT_USE').displayName,
                value: this.getDepositRefundStatus('NEXT_USE').no,
              }
            );
            break;
          case this.getDepositRefundStatus('PAY_SUCCESS').no:
            this.adjustmentMethodItems.push({
              text: this.getDepositRefundStatus('PAY_SUCCESS').displayName,
              value: this.getDepositRefundStatus('PAY_SUCCESS').no,
            });
            break;
          case this.getDepositRefundStatus('SETTLED').no:
            this.adjustmentMethodItems.push({
              text: this.getDepositRefundStatus('SETTLED').displayName,
              value: this.getDepositRefundStatus('SETTLED').no,
            });
            break;
          default:
            break;
        }
      },

      // METHOD: 初期値セット
      setInitialValue() {
        // 決済情報取得
        this.targetPayment = this.company.payment.find(pay => pay.id === this.item.paymentId);
        // 調整処理方法
        this.depositRefundStatus = this.targetPayment.depositRefundInfo.status;
        // 初期化
        this.selectedAdjustmentMethodStatus = this.depositRefundStatus; // 選択された調整方法
        this.enteredAmount = this.targetPayment.depositRefundInfo.amount; // 入力された入金金額
        this.enteredMemo = this.targetPayment.depositRefundInfo.memo; // 入力されたメモ
      },

      // METHOD: 入力内容バリデーション
      validateAdjustmentMethodForm() {
        return this.$refs.adjustmentMethodForm.validate();
      },

      // METHOD: 金額バリデーション値設定
      setValidateNumForAmount() {
        switch (this.selectedAdjustmentMethodStatus) {
          // 未納の場合
          case this.getDepositRefundStatus('UNPAID').no: {
            // バリデーション値設定
            this.validateRuleForAmount = 'min_value:' + Math.abs(this.item.depositDifference);
            break;
          }
          // 返金対応の場合
          case this.getDepositRefundStatus('DIRECT_REFUND').no:
            // バリデーション値設定
            this.validateRuleForAmount = 'max_value:' + Math.abs(this.item.depositDifference);
            break;
          default:
            break;
        }
      },

      // METHOD: 自動売上定義変更・解除
      async changeOrUnregisterRecurring() {
        console.log('changeOrUnregisterRecurring');
        API.graphql(
          await graphqlOperation(changeOrUnregisterRecurring, {
            companyId: this.company.id,
            date: this.today,
          })
        )
          .then(result => {
            if (result.data.changeOrUnregisterRecurring.statusCode == 200) {
              console.log(result.data.changeOrUnregisterRecurring.result);
            } else if(result.data.changeOrUnregisterRecurring.statusCode == 500) {
              console.error(JSON.stringify(result.data.changeOrUnregisterRecurring.result));
            } else {
              console.error(JSON.stringify(result.data.changeOrUnregisterRecurring));
            }
          })
          .catch(error => {
            console.error(error);
          });
      },
    },
  };
</script>
