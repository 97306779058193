// @ts-check

import { Vec } from './vector';

const MAX = 9999999;

export class Rect {
  x = 0;
  y = 0;
  width = 0;
  height = 0;
  constructor(x = 0, y = 0, width = 0, height = 0) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  /** @type {Vec} */
  get position() {
    return new Vec(this.x, this.y);
  }

  /** @type {Vec} */
  get size() {
    return new Vec(this.width, this.height);
  }

  set size(vec) {
    this.width = vec.x;
    this.height = vec.y;
  }

  /**
   * 始点終点から矩形を作る
   * @param {Vec} start
   * @param {Vec} end
   * @returns {Rect}
   */
  static fromStartEnd(start, end) {
    const dist = end.sub(start);
    let translateX = 0,
      translateY = 0;
    if (dist.x < 0) {
      translateX = start.x + dist.x;
    } else {
      translateX = start.x;
    }
    if (dist.y < 0) {
      translateY = start.y + dist.y;
    } else {
      translateY = start.y;
    }

    const width = Math.abs(end.x - start.x);
    const height = Math.abs(end.y - start.y);

    return new Rect(translateX, translateY, width, height);
  }

  /**
   *
   * @param {Vec[]} shape
   */
  static fromVecs(shape) {
    let maxX = 0;
    let maxY = 0;
    let minX = MAX;
    let minY = MAX;
    for (const s of shape) {
      if (minX > s.x) minX = s.x;
      if (minY > s.y) minY = s.y;
      if (maxX < s.x) maxX = s.x;
      if (maxY < s.y) maxY = s.y;
    }
    const rect = new Rect(minX, minY, Math.abs(maxX - minX), Math.abs(maxY - minY));

    if (rect.width === MAX || rect.height === MAX) {
      return new Rect();
    }

    return rect;
  }

  /**
   *
   * @returns {Vec[]}
   */
  toVecs() {
    return [
      new Vec(this.position.x, this.position.y),
      new Vec(this.position.x + this.size.x, this.position.y),
      new Vec(this.position.x + this.size.x, this.position.y + this.size.y),
      new Vec(this.position.x, this.position.y + this.size.y),
    ];
  }
}
