<template>
  <CommonLayout title="Ⅱ．物件の概要">
    <template v-slot>
      <div>
        <ReportSubTitle subTitle="敷地条件、立地条件" />
        <div>
          <div style="height: 1250px; padding: 100px;">
            <div style="font-size: 125px; padding: 50px 0 ;">
              ◎敷地条件
            </div>
            <div style="font-size: 90px; padding: 2%">
              <!-- <div>・所在地:</div>
              <div>
                {{
                  `${commonInput.address.address1 || '**'} 
                                    ${commonInput.address.address2 || '**'} 
                                    ${commonInput.address.address3 || '**'}`
                }}
              </div> -->
              <table
                border="1"
                style="width: 2500px; background-color: white;  table-layout: fixed;"
              >
                <tbody>
                  <tr>
                    <td style="background-color: #ddd; text-align: center">用途地域</td>
                    <td style="padding-left:30px">
                    {{ realEstateInput.siteConditions.siteInformation.zoningDistrict }}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #ddd; text-align: center">建蔽率</td>
                    <td
                      style="text-align: right; padding-right:30px"
                      v-if="
                        realEstateInput.siteConditions.siteInformation.buildingToLandRatio != null
                      "
                    >
                      {{
                        realEstateInput.siteConditions.siteInformation.buildingToLandRatio.toFixed(
                          1
                        ) + ' ％'
                      }}
                    </td>
                    <td style="text-align: right" v-else></td>
                  </tr>
                  <tr>
                    <td style="background-color: #ddd; text-align: center">容積率</td>
                    <td
                      style="text-align: right; padding-right:30px"
                      v-if="realEstateInput.siteConditions.siteInformation.floorAreaRatio != null"
                    >
                      {{
                        realEstateInput.siteConditions.siteInformation.floorAreaRatio.toFixed(1) +
                          ' ％'
                      }}
                    </td>
                    <td style="text-align: right" v-else></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ReportCard
              style="position: absolute; top: 1150px; left: 3300px; width: 1620px; height: 1080px; padding: 0"
            >
              <template v-slot>
                <img :src="siteConditionsImageUrl" alt="" width="1620px" height="1080px" />
              </template>
            </ReportCard>
          </div>
          <div style="height: 1500px; padding: 100px;">
            <div style="font-size: 125px; padding: 50px 0 ;">
              ◎立地条件
            </div>
            <div style="font-size: 90px; padding: 2%;">
              <table
                border="1"
                style="width: 2500px; background-color: white; table-layout: fixed; font-size: 90px; margin-top:40px;"
              >
                <tbody>
                  <tr>
                    <td style="background-color: #ddd; text-align: center">交通</td>
                    <td style="padding-left:30px">
                      {{ realEstateInput.locationRequirement.traffic }}
                    </td>
                  </tr>
                  <tr>
                    <td style="background-color: #ddd; text-align: center;">
                      周辺
                    </td>
                    <td style="padding-left:30px">
                      {{ realEstateInput.locationRequirement.landUseInTheVicinity }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ReportCard
              style="position: absolute; top: 2350px; left: 3300px; width: 1620px; height: 1080px; padding: 0"
            >
              <template v-slot>
                <img :src="locationImageUrl" alt="" width="1620px" height="1080px" />
              </template>
            </ReportCard>
          </div>
        </div>
      </div> </template
  ></CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import ReportSubTitle from '@/components/ExportReport/ReportSubTitle';
  import ReportCard from '@/components/ExportReport/ReportCard';
  // import noImage from '@/assets/report/reportImages/noimage.png';
  import siteCondition from '../ReportImages/siteCondition.png';
  import locationCondition from '../ReportImages/locationCondition.png';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getS3Url } from '../../../graphql/queries';
  import awsconfig from '../../../aws-exports';
  API.configure(awsconfig);

  export default {
    props: {
      commonInput: {
        type: Object,
        required: true,
      },
      realEstateInput: {
        type: Object,
        required: true,
      },
    },
    components: {
      CommonLayout,
      ReportSubTitle,
      ReportCard,
    },
    data() {
      return {
        detailStyle: {
          'font-size': '8mm',
          'max-width': '250mm',
          'padding-left': '15mm',
        },
        // noImage: noImage,
        siteCondition: siteCondition,
        locationCondition: locationCondition,
        locationImageUrl: null,
        siteConditionsImageUrl: null,
      };
    },
    async created() {
      try {
        const landPicture = this.realEstateInput.siteConditions.siteInformation.landPicture;
        const guideMap = this.realEstateInput.locationRequirement.guideMap;

        const result = await API.graphql(
          graphqlOperation(getS3Url, {
            level: 'public',
            file: [
              {
                fileName: landPicture,
                fileAction: 'View',
              },
              {
                fileName: guideMap,
                fileAction: 'View',
              },
            ],
          })
        );

        if (result.data == null) {
          throw Error('画像URLの取得に失敗しました');
        }

        const links = result.data.getS3Url;

        //敷地条件の画像を取得
        let imageUrl =
          landPicture == null
            ? // ? 'https://allys3163848-dev.s3-ap-northeast-1.amazonaws.com/Master/report/noimage.png'
              location.origin + this.siteCondition
            : links[0].fileUrl;
        this.siteConditionsImageUrl = imageUrl;

        //立地条件の画像を取得
        imageUrl =
          guideMap == null
            ? // ? 'https://allys3163848-dev.s3-ap-northeast-1.amazonaws.com/Master/report/noimage.png'
              await location.origin + this.locationCondition
            : links[1].fileUrl;
        this.locationImageUrl = imageUrl;
      } catch (error) {
        console.error(error);
        this.showError('E0001');
      }
    },
  };
</script>
