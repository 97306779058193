// plugins
import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { indexedDB } from './js/indexedDB';
// bootstrap
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);
import 'jquery';
// vue-carousel
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);
import VueCompositionApi from '@vue/composition-api';
Vue.use(VueCompositionApi);
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

Vue.component('VueSlider', VueSlider);
// js
import 'expose-loader?$!expose-loader?jQuery!jquery';
import 'bootstrap';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import 'vue-chartjs';
import './js/template';

// css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-themify-icons';
import './css/RoyalUIAdmin.css';
import './css/custom.css';
import 'vue-swatches/dist/vue-swatches.css';
import '../public/icons/icomoon/style.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

// コンポーネント
import Paginate from 'vuejs-paginate';

import paginationV2 from './components/Common/PaginationV2';
import allyAddress from './components/Common/Form/Address';
import allyRadio from './components/Common/Form/Radio';
import allyText from './components/Common/Form/Text';
import allyImage from './components/Common/Form/Image';
import allyImages from './components/Common/Form/Images';
import allyAlert from './components/Common/Alert';
import allyDate from './components/Common/Form/Date';
import allySelect from './components/Common/Form/Select';
import allyTextArea from './components/Common/Form/TextArea';
import allyBarChartV from './components/Common/BarChart';
import allyBarChartH from './components/Common/HorizontalBarChart';
import allyLineChart from './components/Common/LineChart';
import allyCheckbox from './components/Common/Form/Checkbox';
import allyBadge from './components/Common/Form/Badge';

// vee-validate
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja.json';

// vee-validateを日本語で
localize('ja', ja);

// ルールファイル
import {
  required,
  length,
  email,
  numeric,
  alpha_num,
  alpha,
  max_value,
  between,
  min_value,
} from 'vee-validate/dist/rules';

// 必須
extend('required', required);
// 長さチェック
extend('length', length);
// メール
extend('email', email);
// 数
extend('numeric', numeric);
// 英語+数字
extend('alpha_num', alpha_num);
// 英語のみ
extend('alpha', alpha);
//最大値
extend('max_value', max_value);
//間
extend('between', between);
//最低値
extend('min_value', min_value);

// 数(小数点許容)
extend('decimals', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false,
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };
  },
  message: '{_field_}は数字のみ使用可能です',
});
// 電話番号
extend('tel', {
  validate: function(value) {
    return !value || value.match(/^0\d{9,10}$/);
  },
  message: '正しい電話番号ではありません',
  computesRequired: true,
});
// 全角のみ
// https://oboe2uran.hatenablog.com/entry/2020/07/14/2236//
extend('zen', {
  validate: function(value) {
    return !value || value.match(/^[^ -~｡-ﾟ]+$/);
  },
  message: '{_field_}は全角文字のみ使用可能です',
  computesRequired: true,
});
//A・B中一つは必須
extend('aorbrequired', {
  params: ['target'],
  validate: function(value, { target }) {
    return {
      valid: value || target,
      required: !target,
    };
  },
  computesRequired: true,
  message: '{_field_}か{target}の何れかは必須です。',
});
//A・Bのいずれかが入力されていた場合、両方必須
extend('aandbrequired', {
  params: ['target'],
  validate: function(value, { target }) {
    return {
      valid: value ? true : !target,
      required: value,
    };
  },
  computesRequired: true,
  message: '{_field_}は必須項目です。',
});
//自身、もしくはv1～v5いずれかの入力が必須
extend('anyrequired', {
  params: ['v1', 'v2', 'v3', 'v4', 'v5'],
  validate: function(value, { v1, v2, v3, v4, v5 }) {
    return {
      valid: value || v1 || v2 || v3 || v4 || v5,
      required: value,
    };
  },
  computesRequired: true,
  message: '何れかは必須項目です。',
});
//パスワード
extend('password', {
  validate: function(value) {
    return {
      valid: value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/),
    };
  },
  message: '{_field_}は8桁以上で英字大文字、英字小文字、数字を含める必要があります。',
});
//カタカナのみ
extend('onlykatakana', {
  validate: function(value) {
    return !value || value.match(/^[\u30a0-\u30ff]+$/);
  },
  message: '全角カタカナのみ入力可能です',
  computesRequired: true,
});
// 都道府県
extend('prefecture', {
  validate: function(value) {
    const prefectures = [
      '北海道',
      '青森県',
      '岩手県',
      '宮城県',
      '秋田県',
      '山形県',
      '福島県',
      '茨城県',
      '栃木県',
      '群馬県',
      '埼玉県',
      '千葉県',
      '東京都',
      '神奈川県',
      '新潟県',
      '富山県',
      '石川県',
      '福井県',
      '山梨県',
      '長野県',
      '岐阜県',
      '静岡県',
      '愛知県',
      '三重県',
      '滋賀県',
      '京都府',
      '大阪府',
      '兵庫県',
      '奈良県',
      '和歌山県',
      '鳥取県',
      '島根県',
      '岡山県',
      '広島県',
      '山口県',
      '徳島県',
      '香川県',
      '愛媛県',
      '高知県',
      '福岡県',
      '佐賀県',
      '長崎県',
      '熊本県',
      '大分県',
      '宮崎県',
      '鹿児島県',
      '沖縄県',
    ];
    return prefectures.some(v => {
      return v == value;
    });
  },
  message: '正しい都道府県ではありません',
});

// amplify
import Amplify from '@aws-amplify/core';
import { I18n } from 'aws-amplify';
import aws_exports from './aws-exports';
import { Auth } from '@aws-amplify/auth';

Amplify.configure(aws_exports);
Auth.configure(aws_exports);
Amplify.configure({
  Auth: {
    storage: window.sessionStorage,
  },
});
// amplify ローカライズ
let languageDict = {
  ja: {
    'Enter your Username': 'ユーザ名を入力してください',
    'Enter your password': 'パスワードを入力してください',
    'Forget your password? ': ' ',
    'Reset password': 'パスワードを忘れた場合',
    'Sign In': 'ログイン',
    'Sign Out': 'ログアウト',
    'Send Code': 'コードを送信',
    Code: 'コード',
    'New Password': '新しいパスワード',
    Submit: '登録',
    'Back to Sign In': 'ログインページに戻る',
  },
};
I18n.putVocabularies(languageDict);
I18n.setLanguage('ja');
// バリデーションコンポーネント
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// 共通コンポーネント追加
Vue.component('paginate', Paginate);
Vue.component('paginationV2', paginationV2);
Vue.component('allyAddress', allyAddress);
Vue.component('allyRadio', allyRadio);
Vue.component('allyText', allyText);
Vue.component('allyImage', allyImage);
Vue.component('allyImages', allyImages);
Vue.component('allyAlert', allyAlert);
Vue.component('allyDate', allyDate);
Vue.component('allySelect', allySelect);
Vue.component('allyTextArea', allyTextArea);
Vue.component('allyBarChartV', allyBarChartV);
Vue.component('allyBarChartH', allyBarChartH);
Vue.component('allyLineChart', allyLineChart);
Vue.component('allyCheckbox', allyCheckbox);
Vue.component('allyBadge', allyBadge);

Vue.config.productionTip = false;
var app = new Vue({
  router,
  store,
  render: h => h(App),
});
window.app = app;
app.$mount('#app');
import gv from './mixins/globalValiables';
Vue.mixin(gv);
import utility from './mixins/utility';
Vue.mixin(utility);
import VueGlobalVar from 'vue-global-var';
import InvestmentAnalyticsInput from './views/InvestmentAnalytics/Input/class/input.js';
Vue.use(VueGlobalVar, {
  globals: {
    investmentAnalytics: new InvestmentAnalyticsInput(),
    // 保存しないけど共有したい計算項目
    investmentAnalyticsTemp: {
      // 売却益（税引後）
      gainOnSaleAfterTax: null,
      // ローン計算結果
      loanCalculationResults: null,
      // 長期修繕計画/合計金額
      longTermRepairPlanTotal: null,
      // 投資分析指標
      indicators: {
        fcr: [], // 純利回り(FCR)
        ccr: [], // 自己資金配当率(CCR)
        ber: [], // 収益分岐入居率(BER)
        dscr: [], // 債務回収率(DSCR)
      },
      // 所得税・住民税
      incomeTaxAndInhabitantTax: [],
      // 税引後現金収支
      cashFlowsAfterTax: null,
      // 運営費(OPEX)合計 ※各年の借入返済金を含めない支出合計
      opexTotal: [],
      // 事業予測収支計画書（現金） 各年度純収益（NOI)
      cashNoi: null,
      // 単年度収支
      singleYearBalanceResult: null,
    },
  },
});

Vue.use(indexedDB);
