// @ts-check

import { onMounted, ref } from '@vue/composition-api';
import { WallMaterialFetcher } from '../fetch/wallMaterials';

/** @type {() => any[]} */
export let getMaterials;

export const useWallMaterials = () => {
  /** @type {import('@vue/composition-api').Ref<any[]>} */
  const wallMaterials = ref([]);
  getMaterials = () => wallMaterials.value;
  const isLoadingWallMaterials = ref(false);

  onMounted(async () => {
    isLoadingWallMaterials.value = true;
    const repo = new WallMaterialFetcher();
    const result = await repo.listWallMaterials();
    wallMaterials.value = result.map(v => ({
      ...v,
      url: v.url,
    }));

    isLoadingWallMaterials.value = false;
  });

  return {
    wallMaterials,
    isLoadingWallMaterials,
  };
};
