<!--
作成者：Lee HyeonSeok
作成日：2020.6.10
画面名：エラーモダル
機能：エラーモダル
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <b-modal
    :ref="code"
    :id="code"
    size="lg"
    header-class="ally-modal text-center"
    footer-class="ally-modal text-center"
    body-class="ally-input-background"
    ok-only
    ok-variant="light"
    @hidden="okAlertAction()"
    title="システムメッセージ"
    centered
    no-close-on-backdrop
  >
    <div class="row">
      <div class="col-md-8">
        <div class="d-block text-center">
          <h3 class="text-left" v-if="!title && code == 'E0001'">システムエラー</h3>
          <h3 class="text-left" v-if="!title && code == 'S100'">登録が完了しました</h3>
          <h3 class="text-left" v-if="title">{{ title }}</h3>
          <p class="text-left" v-if="code.substr(0, 1) != 'S'">
            エラーコード：{{ code }}
            <br />
            {{ message }}
            <br />
          </p>
          <p class="text-left" v-if="code.substr(0, 1) == 'S'">
            メッセージコード：{{ code }}
            <br />
            {{ message }}
            <br />
          </p>
          <p class="small text-left" v-if="code == 'E0001'">
            繰り返される場合はカスタマーセンターにお問い合わせください。
          </p>
        </div>
      </div>
      <logo class="col-md-4" :visible="true" />
    </div>
  </b-modal>
</template>

<script>
  import messages from '../../js/messages';
  import logo from '../../components/Common/Logo';

  export default {
    // code: エラーコード
    // title: タイトル
    // params: パラメータ
    props: ['code', 'title', 'params'],
    computed: {
      message: function() {
        var msg = messages[this.code];
        if (!this.params) return msg;
        for (let [key, value] of Object.entries(this.params))
          msg = msg.replace('##' + key + '##', value);

        return msg;
      },
    },
    methods: {
      okAlertAction: function() {
        this.$emit('okAction');
        return 0;
      },
      show() {
        this.$refs[this.code].show();
      },
    },
    components: {
      logo,
    },
  };
</script>
