<template>
  <div>
    <div class="mt-3"></div>
    <div v-show="upLoading">
      <Load />
    </div>
    <div v-show="!upLoading">
      <SimulatorImageAdd
        v-if="mode == 'List' && !loading && isLicensedArSimulator == true"
        :propertyInfo="propertyInfo"
        :propSimInfoData="simInfoData"
        :loading="loading"
        :mode="mode"
        @upLoadCheck="flg => upLoadCheck(flg)"
        @mode="changeMode"
      />
      <SimulatorImageList
        v-if="mode == 'List'"
        ref="simulatorList"
        @mode="changeMode"
        @logReload="logReload"
        :commonValue="propertyInfo.commonValue"
        :isLicensedArSimulator="isLicensedArSimulator"
      />
      <SimulatorImageEditor
        v-if="mode == 'Edit' && !loading"
        :propertyInfo="propertyInfo"
        :photoData="photoData"
        :loading="loading"
        @mode="changeMode"
        ref="simEdit"
      />
      <SimulatorSimulationLog v-if="mode == 'List'" :simulationLog="simulatorLogs" />

      <allyAlert code="S100"></allyAlert>
      <allyAlert code="E0001"></allyAlert>
    </div>
  </div>
</template>

<script>
  import awsconfig from '../../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getProperty, listPhotoSimulators } from '../../../graphql/queries';

  import SimulatorImageList from './List';
  import SimulatorImageAdd from './Add';
  import SimulatorSimulationLog from './SimulationLog';
  import SimulatorImageEditor from './Editor/Main';
  import Load from '../../../components/Common/Load';

  API.configure(awsconfig);

  export default {
    data: function() {
      return {
        mode: 'List',
        propertyId: null,
        roomId: null,
        loading: true,
        propertyInfo: {
          company: null,
          simInfo: [],
        },
        simInfoData: {
          baseImageFileName: null,
          baseImageDisplayName: null,
          savePeten: [],
        },
        photoData: null,
        upLoading: false,
        simulatorLogs: [],
        isLicensedArSimulator: false, //ライセンス
      };
    },
    watch: {},
    mounted: async function() {
      this.propertyId = this.$route.params.id;
      this.imageName = this.$route.params.image;
      this.roomId = this.$route.params.roomId;

      // ライセンスチェック（フォトシミュレーター）
      this.isLicensedArSimulator = this.$store.state.functions?.some(v => {
        return v == 8; // ALLY - ARシミュレーター
      });

      this.authType = 'admin';
      //経路によってモードを設定
      if (this.$router.currentRoute.path.match('.*/Add.*')) {
        this.mode = 'Add';
      } else if (this.$router.currentRoute.path.match('.*/Edit.*')) {
        this.mode = 'Edit';
      } else if (this.$router.currentRoute.path.match('.*/Simulate.*')) {
        this.mode = 'Simulate';
      } else {
        this.mode = 'List';
      }

      try {
        //物件情報を取得
        var getQuery = await API.graphql(
          graphqlOperation(getProperty, { company: this.getCompanyId(), id: this.propertyId })
        );
        this.logSet(getQuery);
      } catch (e) {
        console.error(e);
        //モーダルを表示する。
        this.$bvModal.show('E0001');
        return;
      }
      //会社情報を格納
      this.propertyInfo = getQuery.data.getProperty;

      delete this.propertyInfo.createdAt;
      delete this.propertyInfo.updatedAt;

      //simulation情報を取得します
      let query = {
        limit: 60,
        company: await this.getCompanyId(),
        nextToken: null,
        filter: { propertyId: { eq: this.propertyId } },
      };

      let getData = [];
      do {
        let getPhoto = await API.graphql(graphqlOperation(listPhotoSimulators, query));
        getData = [...getData, ...getPhoto.data.listPhotoSimulators.items];
        query.nextToken = getPhoto.data.listPhotoSimulators.nextToken;
      } while (query.nextToken != null);

      //リスト以外は会社情報を取得する
      if (this.mode != 'List') {
        var result = null;
        if (this.mode != 'Add') {
          result = getData.find(x => x.simInfo.baseImageFileName == this.imageName);
        }
        //シミュレーション画像情報
        this.photoData = result;
        this.simInfoData = result.simInfo;
      }

      this.loading = false;
    },
    methods: {
      setLayer: function(mode) {
        this.$refs.simEdit.createLayerImageObjects();
        var layers = this.$refs.simEdit.setLayersInfo();
        for (let layer of layers) {
          this.simInfoData.layersInfo.push(layer);
        }
        this.changeMode(mode);
      },
      changeMode: function(mode) {
        this.mode = mode;
      },
      //会社情報を取得する。
      getCompanyId: function() {
        //アカウントから会社ID取得
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },
      //画像をアップロードしたとき、最新情報を表示するための処理
      upLoadCheck: async function(flg) {
        this.upLoading = flg;

        await this.$refs.simulatorList.listReload();
        await this.logReload();

        this.upLoading = false;
      },
      //ログを取得する
      logReload: async function() {
        try {
          //物件情報を取得
          var getQuery = await API.graphql(
            graphqlOperation(getProperty, { company: this.getCompanyId(), id: this.propertyId })
          );
          await this.logSet(getQuery);
        } catch (e) {
          console.error(e);
          //モーダルを表示する。
          this.$bvModal.show('E0001');
          return;
        }
      },
      //表示しているログを再セットする
      logSet: async function(getQuery) {
        let logs = [];
        if (this.roomId == null) {
          logs = getQuery.data.getProperty.simulationLog;
        } else {
          logs = getQuery.data.getProperty.roomInfo.find(val => val.id == this.roomId)
            .simulationLog;
        }
        if (logs == null) logs = [];
        this.simulatorLogs = logs;
      },
    },
    components: {
      SimulatorImageList,
      SimulatorImageAdd,
      SimulatorSimulationLog,
      SimulatorImageEditor,
      Load,
    },
  };
</script>
<style scoped>
  .text-red {
    color: red;
  }

  .property-link {
    font-size: 1.2rem;
    width: 2rem;
    height: 2rem;
    color: white;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px 20px 20px 20px;
    transition: 0.2s;
  }

  .property-link:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .property-link:active {
    background-color: rgba(255, 255, 255, 0.2);
    transition: 0.15s;
  }

  /* レイヤーcolor */
  .property-layer {
    background-color: rgba(255, 255, 255, 0);
    transition: 0.2s;
  }
  .property-layer:hover {
    background-color: rgba(218, 157, 97, 0.3);
  }

  .property-delete-layer:hover {
    background-color: rgba(255, 0, 0, 0.4);
  }

  /* アイコンの大きさ設定 */

  .property-icon {
    font-size: 5rem;
    color: var(--colorTheme);
    transition: 0.2s;
  }

  .property-back-color {
    border-radius: 20px 20px 20px 20px;
    transition: 0.2s;
  }

  .property-back-color:hover {
    background-color: #ffffff;
    border-radius: 20px 20px 20px 20px;
  }
  .property-function-icon {
    max-width: 200px;
  }
  .line {
    display: inline-block;
  }
</style>
