<template>
  <div id="LicenseMain">
    ライセンス管理
    <b-container>
      <!-- ライセンス一覧 -->
      <b-row>
        <b-col>
          <b-table
            thead-tr-class="serch-data-table-header"
            tbody-tr-class="serch-data-table-body"
            striped
            bordered
            :items="licenseItems"
            :fields="fields"
            :busy="loading"
            show-empty
          >
            <!-- カラム設定 -->
            <template #table-colgroup="scope">
              <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.width }" />
            </template>
            <!-- 空の場合 -->
            <template v-slot:empty>
              <b-row class="text-center">
                <b-col>
                  <svg
                    class="bi bi-exclamation-diamond-fill"
                    width="8em"
                    height="8em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                  <b-alert id="alert" show>
                    ライセンスがありません
                  </b-alert>
                </b-col>
              </b-row>
            </template>
            <!-- 数量表示 -->
            <template v-slot:cell(quantity)="row">
              {{ row.value | currency }}
            </template>
            <!-- 終了日表示 -->
            <template v-slot:cell(endDate)="row">
              <b-form-input
                v-if="row.item.id == 0"
                type="date"
                :value="getDateChange(row.value)"
                @change="val => setDateChange(val, row)"
                :min="row.item.startDate"
                :disabled="mode == 'View' ? true : false"
              />
              <div v-if="row.item.id != 0">{{ row.item.endDate }}</div>
            </template>
            <!-- 自動更新表示 -->
          </b-table>
        </b-col>
      </b-row>
      <b-row v-if="trialCheck == true">
        <b-col class="mt-3" style="font-size: 25px;">
          <b-form-checkbox
            v-if="mode == 'View' ? false : true"
            @change="trialCheckFunction"
            size="lg"
          >
            試用を開始する
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-container>
    <allyAlert code="E0001" />
  </div>
</template>

<!-- スクリプト -->
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { isRegisteredCard } from '@/graphql/queries';
  import Common from './Common';
  API.configure(awsconfig);

  // ライセンス一覧のヘッダ
  const headers = [
    {
      key: 'name',
      label: 'ライセンス名称',
      width: '25%',
    },
    {
      key: 'quantity',
      label: '数量',
      width: '5%',
      class: 'text-center',
    },
    {
      key: 'startDate',
      label: '開始日',
      formatter: v => {
        return v.replaceAll('-', '/');
      },
      width: '15%',
    },
    {
      key: 'endDate',
      label: '終了日',
      formatter: v => {
        return v.replaceAll('-', '/');
      },
      width: '15%',
    },
  ];
  export default {
    props: ['companyData', 'mode'],
    components: {},
    mixins: [Common],

    data: function() {
      return {
        date: null,
        fields: headers, // ライセンス一覧のヘッダ
        isRegisteredCard: false, // カード登録有無
        master: null, // マスタ
        company: null, // 会社情報
        licenseItems: [], // ライセンス
        loading: true, // ロード中
        trialCheck: false,
      };
    },
    watch: {},
    mounted: async function() {
      // システム日付
      this.date = this.today;

      // マスタ取得
      this.master = await this.loadMaster();

      // ログインユーザーの会社IDを取得
      const companyId = this.$route.params['id'];

      // カード登録確認
      try {
        const result = await API.graphql(
          graphqlOperation(isRegisteredCard, { companyId: companyId })
        );
        if (result.data.isRegisteredCard.statusCode == '200') {
          this.isRegisteredCard = JSON.parse(result.data.isRegisteredCard.body).result;
        }
      } catch (e) {
        e.errors.forEach(v => {
          console.error(v);
        });
      }

      // 会社情報を取得
      this.company = this.companyData;

      // ライセンス読込
      await this.loadLicense();

      if (this.licenseItems.length == 0) this.trialCheck = true;
      this.loading = false;
    },

    methods: {
      // ライセンス読込
      loadLicense: async function() {
        if (this.company.license == null) {
          return;
        }
        this.licenseItems = [];
        // 現在保有ライセンス取得
        const indexes = this.company.license
          .map((v, i) => {
            return v.startDate <= this.date && this.date <= v.endDate && v.isValid ? i : -1;
          })
          .filter(v => {
            return v >= 0;
          });
        for (let index of indexes) {
          const license = this.company.license[index];

          // ライセンスマスタ検索
          const m = this.master?.find(m => {
            return m.id == license.id;
          });

          this.licenseItems.push({
            name: m?.name,
            quantity: license?.quantity,
            startDate: license?.startDate,
            endDate: license?.endDate,
          });
        }
      },
      //ライセンスを設定する
      trialCheckFunction: function(val) {
        if (val == true) {
          let endDate = new Date();
          endDate.setDate(endDate.getDate() + 30);

          // ライセンスマスタ検索
          let period = this.master.find(val => val.id == 0).prices[0].period;

          let license = {
            period: {
              unit: period.unit,
              quantity: period.quantity,
            },
            amount: 0,
            quantity: 1,
            endDate: endDate.toISOString().slice(0, 10),
            paymentId: null,
            isValid: true,
            tax: 0,
            id: 0,
            startDate: this.today,
            isRenewAutomaticaly: false,
          };
          this.companyData.license = [];
          this.companyData.license.push(license);
        } else if (val == false) {
          let index = this.companyData.license.findIndex(val => val.id == 0);
          this.companyData.license.splice(index, 1);
        }
        this.loadLicense();
      },
      getDateChange: function(val) {
        let date = new Date(val);
        let dates =
          date.getFullYear() +
          '-' +
          ('0' + (date.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + date.getDate()).slice(-2);
        return dates;
      },
      setDateChange: function(val, row) {
        let next = -1;
        if (this.company.license[row.item.index].isRenewAutomaticaly) {
          let license = this.company.license[row.item.index];
          next = this.company.license.findIndex(value => {
            return (
              value.id == license.id &&
              value.period.quantity == license.period.quantity &&
              value.period.unit == license.period.unit &&
              value.startDate == this.getTomorrow(license.endDate)
            );
          });
          this.company.license[next].startDate = this.getTomorrow(val);
          this.company.license[next].endDate = this.getNewYear(val);
        }

        this.company.license[row.item.index].endDate = val;
      },
    },
  };
</script>

<!-- スタイル -->
<style scoped>
  #LicenseMain >>> nav {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }
  #LicenseMain >>> nav > ul > button {
    margin-left: 0.5rem;
  }
  #alert {
    background-color: transparent;
    border: none;
  }
  #LicenseMain >>> .checkbox-column {
    padding: 0;
  }
  #LicenseMain >>> .quantity-input {
    padding-top: 0;
    padding-bottom: 0;
  }
  #LicenseMain >>> .custom-control-input:checked ~ .custom-control-label::before {
    background: var(--colorTheme);
    border-color: var(--colorTheme);
  }
  #LicenseMain >>> .number .form-group {
    margin: 0;
  }
  #LicenseMain >>> .number .form-group input[type='number'] {
    height: 1.5rem;
    padding-left: 0;
    padding-right: 0.5rem;
    text-align: right;
  }
  #LicenseMain >>> .row-center {
    display: grid;
    text-align: center;
    align-items: center;
    padding: 0;
  }
  #LicenseMain >>> .renew {
    display: table-cell;
    align-items: center;
  }
  #LicenseMain >>> .container {
    max-width: 100%;
  }
</style>
