var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isEditor && _vm.isAzimuthInput)?_c('div',{staticClass:"azimuth-input"},[_c('div',[_vm._v(" 方位角："),_c('input',{staticStyle:{"width":"5em"},attrs:{"type":"number","max":"180","min":"-180"},domProps:{"value":_vm.azimuthInput},on:{"input":function (e) {
      var val = parseFloat(e.srcElement.value);
      if (val > 180) { val = 180; }
      if (val < -180) { val = -180; }
      _vm.$emit('change-azimuth', val);
    }}}),_vm._v(" 度 ")]),_c('div',[_c('input',{attrs:{"type":"range","max":"180","min":"-180"},domProps:{"value":_vm.azimuthInput},on:{"input":function (e) {
        var val = parseFloat(e.srcElement.value);
        _vm.$emit('change-azimuth', val);
      }}})])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }