var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CommonLayout',{attrs:{"title":"Ⅱ．各種土地活用法における特徴"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ReportSubTitle',{attrs:{"subTitle":"メリット、デメリット"}}),_c('ReportTable',{staticStyle:{"margin-top":"150px","padding-bottom":"150px"},attrs:{"header":_vm.header,"items":_vm.items},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var col = ref.col;
return [_c('div',{staticStyle:{"font-size":"70px","text-align":"center","margin-left":"-70px"},domProps:{"innerHTML":_vm._s(col.label)}})]}},{key:"table",fn:function(ref){
var col = ref.col;
var item = ref.item;
return [(
            item[col.name] === '◯' ||
              item[col.name] === '△' ||
              item[col.name] === '×' ||
              item[col.name] === '△～◯'||
              item[col.name] === '－'
          )?_c('div',{staticStyle:{"padding-right":"65px","font-size":"100px"},domProps:{"innerHTML":_vm._s(item[col.name])}}):(
              item[col.name] === '短期から可'||
              item[col.name] === '概ね20年以上'||
              item[col.name] === '10年以上'||
              item[col.name] === '不要または小'||
              item[col.name] === '大'||
              item[col.name] === '不要'||
              item[col.name] === '無し'||
              item[col.name] === '借家権'||
              item[col.name] === '定期借地権'
          )?_c('div',{staticStyle:{"padding-right":"65px","font-size":"60px"},domProps:{"innerHTML":_vm._s(item[col.name])}}):_c('div',{staticStyle:{"padding-right":"65px","font-size":"60px","text-align":"left"},domProps:{"innerHTML":_vm._s(item[col.name])}})]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }