// @ts-check
import { Vec } from '../util/math/vector';
import { loadImage, makeToast } from '../util/util';
import { MESSAGE_TEXT } from '../util/const';
import { createNode } from '../node/node/pvNode';
import { Rect } from '../util/math/rect';
import { PvIcon } from '../../lib/node/node/iconNode';
import { LAYER_SCALE_DEFAULT, LEFT_MENU_WIDTH, HEADER_HEIGHT } from '../util/const';
import { ref } from '@vue/composition-api';
import { PvObject } from '../node/node/pvObject';
import { EventDataset } from '../event/preset/eventDataset';
import { PvRoot } from '../node/node/rootNode';

const ICON_SCALE = 2 / 18;

/**
 * アイコン追加系
 * これらは編集エリア外からの操作なのでActionにできない
 * @param {{
 * zoom: import('@vue/composition-api').ComputedRef<number>
 * editorX: import('@vue/composition-api').Ref<number>
 * editorY: import('@vue/composition-api').Ref<number>
 * root: import('@vue/composition-api').Ref<PvRoot>
 * iconImages: import('@vue/composition-api').Ref<import('../util/iconList').IconData[]>
 * iconCategoryList: import('@vue/composition-api').Ref<import('../util/iconList').IconCategoryData[]>
 * saveHistory: () => void
 * }} param0
 * @returns
 */
export const useAppendIcon = ({
  zoom,
  editorX,
  editorY,
  root,
  iconImages,
  iconCategoryList,
  saveHistory,
}) => {
  const draggedIconPosition = ref(new Vec());
  const dragedIconWidth = ref(0);
  const dragedIconHeight = ref(0);
  /** @type {import('@vue/composition-api').Ref<import('../util/iconList').IconData|null>} */
  const draggedIcon = ref(null);

  /**
   * @param {import('../util/iconList').IconData|null} icon
   * @param {Vec?} position
   * @param {PvObject?} target
   */
  const appendIcon = async (icon, position = null, target = null) => {
    if (icon == null) return;

    if (position == null) {
      position = new Vec(100, 100);
    }

    const img = await loadImage(icon.url ?? '');
    const obj = createNode(PvIcon);
    obj.vertexes = new Rect(
      0,
      0,
      img.naturalWidth * ICON_SCALE,
      img.naturalHeight * ICON_SCALE
    ).toVecs();
    obj.code = icon.code ?? -1;
    obj.width = img.naturalWidth * ICON_SCALE;
    obj.originalWidth = img.naturalWidth * ICON_SCALE;
    obj.height = img.naturalHeight * ICON_SCALE;
    obj.originalHeight = img.naturalHeight * ICON_SCALE;
    obj.style.imageUrl = icon.url ?? '';
    obj.modelPath = icon.modelName
      ? iconCategoryList.value.find(iconCategory => iconCategory.id == icon.categoryId)?.name +
        '/' +
        icon.modelName
      : null;
    const scale = zoom.value / LAYER_SCALE_DEFAULT;

    obj.moveTo(
      new Vec(
        editorX.value + position.x / LAYER_SCALE_DEFAULT / scale,
        editorY.value + position.y / LAYER_SCALE_DEFAULT / scale
      )
    );
    obj.setOrigin(new Vec(obj.width / 2, obj.height / 2));

    if (target == null) {
      root.value.appendChild(obj);
    } else {
      obj.exchangeCoordinate(target);
      target.appendChild(obj);
    }

    saveHistory();
  };

  const mousedown = async (/** @type {MouseEvent} */ e) => {
    // @ts-ignore
    const id = e.target.id;
    if (id === '') return;

    const icon = iconImages.value.find(icon => icon.logicalName === id) ?? null;
    if (icon == null) return;
    draggedIcon.value = icon;
    draggedIconPosition.value = new Vec(e.clientX, e.clientY);

    const img = await loadImage(icon?.url ?? '');
    const scale = ICON_SCALE * zoom.value;

    dragedIconWidth.value = img.naturalWidth * scale;
    dragedIconHeight.value = img.naturalHeight * scale;
  };

  const live = 299;

  const mouseup = (/** @type {MouseEvent} */ e) => {
    if (draggedIcon.value == null) return;
    const dataset = EventDataset.fromEvent(e);
    const { targetObject } = dataset;
    const position = draggedIconPosition.value;
    const target = targetObject?.type === 'floor' ? targetObject : null;
    if (
      target == null
      // &&
      // draggedIcon.value.categoryId != '1' &&
      // draggedIcon.value.categoryId != '2' &&
      // draggedIcon.value.categoryId != '16'
    ) {
      makeToast(MESSAGE_TEXT.ICON_HELP_DROP_ROOM_INSIDE);
    } else {
      appendIcon(
        draggedIcon.value,
        new Vec(position.x - LEFT_MENU_WIDTH, position.y - HEADER_HEIGHT),
        target
      );
    }
    draggedIcon.value = null;
  };

  const mousemove = async (/** @type {MouseEvent} */ e) => {
    if (draggedIcon.value == null) return;
    draggedIconPosition.value = new Vec(e.clientX, e.clientY);
  };

  return {
    appendIcon,
    mousedown,
    mouseup,
    mousemove,
    draggedIconPosition,
    dragedIconWidth,
    dragedIconHeight,
    draggedIcon,
  };
};
