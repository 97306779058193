var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",staticClass:"layer-wrapper",class:{ enable: _vm.layer.enabled }},[_c('canvas',{ref:"main",staticClass:"layer",style:('opacity:' +
        _vm.layer.transparent +
        '%; ' +
        'filter: brightness(' +
        _vm.layer.brightness +
        '% ) saturate(' +
        _vm.layer.saturation +
        '%);'),attrs:{"layerName":_vm.layer.name}}),_c('canvas',{ref:"materialCanvas",staticClass:"material-canvas",class:{ enable: _vm.editMode && _vm.layer.isActive }}),_vm._l((_vm.perspectiveData),function(corner){return _c('div',{key:corner.name,staticClass:"corner ui-draggable",class:{ enable: _vm.editMode && _vm.layer.isActive },style:({ top: corner.y + 'px', left: corner.x + 'px' }),attrs:{"id":corner.name},on:{"mousedown":function($event){return _vm.cornerMouseDown(corner)},"mouseup":function($event){return _vm.cornerMouseUp()}}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }