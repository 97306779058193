<template>
  <div id="PaymentMethod">
    <b-overlay :show="loading" opacity="0.25">
      {{ title }}
      <b-container>
        <!-- 上部ボタン -->
        <b-row>
          <b-col>
            <b-navbar>
              <b-navbar-nav class="ml-auto">
                <router-link
                  v-if="mode == 'View'"
                  to="/License/Main"
                  tag="button"
                  class="btn btn-ally"
                >
                  戻る
                </router-link>
                <b-button v-if="mode == 'Edit'" @click="returnButton()" class="btn-ally">
                  戻る
                </b-button>
              </b-navbar-nav>
            </b-navbar>
          </b-col>
        </b-row>
        <!-- 支払方法一覧 -->
        <b-row style="padding-top:1rem;">
          <b-col>
            <b-form-group>
              <b-card-group columns>
                <b-card>
                  <template #header>
                    <b-form-radio v-model="selected" :disabled="mode == 'View'" :value="creditCard">
                      クレジットカード
                    </b-form-radio>
                  </template>
                  <b-container
                    v-bind:class="[
                      mode == 'View' || selected != creditCard ? 'text-secondary' : null,
                    ]"
                  >
                    <b-row class="mb-3">
                      <b-col>
                        <b-button
                          variant="ally"
                          :disabled="mode == 'View' || selected != creditCard"
                          @click="registerCard"
                        >
                          クレジットカードの登録
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        {{ brand }}
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card>
                <b-card>
                  <template #header>
                    <b-form-radio
                      v-model="selected"
                      :disabled="mode == 'View'"
                      :value="bankTransfer"
                    >
                      銀行振込
                    </b-form-radio>
                  </template>
                  <b-container class="bank-info" v-if="company.payType == bankTransfer">
                    <b-row v-for="(item, index) in bankInfo" v-bind:key="index">
                      <b-col cols="4">{{ item.name }}</b-col>
                      <b-col
                        v-bind:class="[selected != bankTransfer ? 'text-secondary' : null]"
                        style="white-space: pre-line;"
                      >
                        {{ item.value }}
                      </b-col>
                    </b-row>
                  </b-container>
                  <b-container
                    v-if="company.payType != bankTransfer"
                    v-bind:class="[
                      mode == 'View' || selected != bankTransfer ? 'text-secondary' : null,
                    ]"
                  >
                    <b-row>
                      <b-col>
                        毎月指定期日までに、ご利用料金をお振込みいただきます。
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card>
                <!-- <b-card>
                  <template #header>
                    <b-form-radio
                      v-model="selected"
                      :disabled="mode == 'View'"
                      :value="accountTransferOnline"
                    >
                      口座振替オンライン
                    </b-form-radio>
                  </template>
                  <b-container
                    v-bind:class="[
                      mode == 'View' || selected != accountTransferOnline ? 'text-secondary' : null,
                    ]"
                  >
                    <b-row class="mb-3">
                      <b-col>
                        <b-button
                          variant="ally"
                          :disabled="mode == 'View' || selected != accountTransferOnline"
                          @click="openRegisterAccountTransferOnlineModal"
                        >
                          振替口座指定
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        毎月指定日に、ご利用料金を引き落としさせていただきます。
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card> -->
                <b-card>
                  <template #header>
                    <b-form-radio
                      v-model="selected"
                      :disabled="mode == 'View'"
                      :value="accountTransferPaper"
                    >
                      口座振替ペーパー
                    </b-form-radio>
                  </template>
                  <b-container
                    v-bind:class="[
                      mode == 'View' || selected != accountTransferPaper ? 'text-secondary' : null,
                    ]"
                  >
                    <b-row class="mb-3">
                      <b-col>
                        <b-button variant="ally" @click="openAccountTransferPaperModal">
                          預金口座振替依頼書
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        毎月指定日に、ご利用料金を引き落としさせていただきます。（お申込完了に最短2ヶ月ほどかかります）<br />
                        <p
                          class="my-2 bold"
                          v-if="
                            company.paymentChangeReservation &&
                              company.paymentChangeReservation.payType == accountTransferPaper
                          "
                        >
                          {{ company.paymentChangeReservation.reservationDate + ' 予約済み' }}
                        </p>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card>
              </b-card-group>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- 下部ボタン -->
        <span v-if="limitCheck && !getIsUnpaidOfLastMonthBefore.result">
          <b-row>
            <b-col>
              <b-navbar>
                <b-tooltip
                  v-if="selected == creditCard && !isRegisteredCard"
                  target="btn-change"
                  variant="secondary"
                  custom-class="tooltip-text"
                  placement="bottom"
                  title="クレジットカード情報を登録してください"
                >
                </b-tooltip>
                <b-navbar-nav class="ml-auto">
                  <span id="btn-change">
                    <b-button
                      v-if="mode != 'View'"
                      variant="ally"
                      :disabled="!changeable"
                      @click="changePaymentMethod($event)"
                    >
                      {{ mode == 'Edit' ? '確定' : '登録' }}
                    </b-button>
                  </span>
                </b-navbar-nav>
                <router-link
                  v-if="mode == 'View'"
                  to="/PaymentMethod/Edit"
                  tag="button"
                  class="btn btn-ally"
                >
                  変更
                </router-link>
              </b-navbar>
              <load class="roads" v-if="awaitFlag" />
            </b-col>
          </b-row>
        </span>
        <span v-else>
          <div
            v-if="getIsUnpaidOfLastMonthBefore.result"
            style="font-size: 18px; text-align: right; color:red;"
          >
            <div>
              {{getIsUnpaidOfLastMonthBefore.date}}分のお支払が未納のため、支払い方法の変更を行うことができません<br />お支払を完了させてください
            </div>
          </div>
          <div v-if="!limitCheck" style="font-size: 20px; text-align: right;">
            <div>現在支払い方法の変更を行うことができません</div>
            <div>次回変更可能日 : {{ nextChangeableDate(changeLimit) }}</div>
          </div>
        </span>
      </b-container>
      <!-- ロード中 -->
      <template #overlay>
        <Load />
      </template>
    </b-overlay>

    <!-- NOTE: 支払方法：登録確認モーダル -->
    <b-modal
      id="RegisterConfirmModal"
      :title="title"
      header-class="ally-modal"
      footer-class="ally-modal"
      centered
      ok-only
      ok-variant="light"
      ok-title="OK"
      @ok="closeModal()"
    >
      {{
        selected ? paymentSelectName.find(val => val.payType == selected).name : ''
      }}登録画面を別タブで表示しました。<br />
      登録完了後、OKボタンを押してください。
    </b-modal>

    <!-- NOTE: 振替口座オンライン：登録依頼モーダル -->
    <ValidationObserver ref="registerAccountTransferOnlineForm" v-slot="ObserverProps">
      <b-modal
        id="AccountTransferOnlineModal"
        title="振替口座指定"
        header-class="ally-modal"
        footer-class="ally-modal"
        centered
        ok-variant="light"
        ok-title="確認"
        cancel-title="キャンセル"
        :ok-disabled="ObserverProps.invalid || !ObserverProps.validated || !isBankCodeInData"
        @ok="registerAccountTransferOnline()"
      >
        <allyText
          class="col"
          title="ご希望の金融機関コード"
          name="bankCode"
          v-model="bankCode"
          :mode="AccountTransferOnlineFormAllyTextMode"
          :rules="AccountTransferOnlineFormAllyTextRules + '|numeric|length:4'"
          :placeholder="'0000'"
          step="0.1"
          suggestList="bankCodeList"
        />
        <!-- リスト部分 -->
        <datalist id="bankCodeList">
          <option v-for="bankCode in bankCodeList" :key="bankCode.value" :label="bankCode.text">{{
            bankCode.value
          }}</option>
        </datalist>
        <p
          class="col py-0 my-0"
          v-if="!ObserverProps.invalid && ObserverProps.validated && !isBankCodeInData"
          style="font-size: small; color:red; top:-10px;"
        >
          ご希望の金融機関は見つかりませんでした
        </p>
        <div v-if="isBranchCode">
          <allyText
            class="col-12"
            title="支店コード"
            name="branchCode"
            v-model="branchCode"
            :mode="AccountTransferOnlineFormAllyTextMode"
            :rules="AccountTransferOnlineFormAllyTextRules + '|numeric|length:3'"
            :placeholder="'000'"
            step="10"
          />
        </div>
        <div class="col-12" v-if="isAccountType">
          <b-form-checkbox
            v-if="isAccountType && isCheckingAccount == 'isInternetBanking'"
            v-model="isInternetBanking"
            size="md"
          >
            インターネットバンキング契約中（ 当座の場合必須）
          </b-form-checkbox>
          <allySelect
            class="col-12"
            title="預金区分"
            name="accountType"
            :items="accountTypeItems"
            v-model="accountType"
            :mode="AccountTransferOnlineFormAllyTextMode"
            :rules="AccountTransferOnlineFormAllyTextRules"
            :placeholder="'預金区分'"
          />
        </div>
        <div v-if="isAccountNumber">
          <allyText
            class="col-12"
            title="口座番号"
            name="accountNumber"
            v-model="accountNumber"
            :mode="AccountTransferOnlineFormAllyTextMode"
            :rules="AccountTransferOnlineFormAllyTextRules + '|numeric|length:7'"
            :placeholder="'0000000'"
          />
        </div>
        <div v-if="isAccountName">
          <allyText
            class="col-12"
            title="口座名義カナ(全角カタカナまたは全角英数)"
            name="accountName"
            v-model="accountName"
            :mode="AccountTransferOnlineFormAllyTextMode"
            :rules="AccountTransferOnlineFormAllyTextRules"
            :placeholder="'口座名義カナ'"
            fullWidth
          />
        </div>
      </b-modal>
    </ValidationObserver>

    <!-- NOTE: 口座振替ペーパー：依頼書ダウンロードモーダル -->
    <b-modal
      id="AccountTransferPaperModal"
      title="預金口座振替依頼書"
      header-class="ally-modal"
      footer-class="ally-modal"
      centered
      ok-variant="light"
      ok-title="完了"
      cancel-title="キャンセル"
      :ok-disabled="!isFileDownload"
      @ok="closeModal()"
    >
      <div class="col py-3 my-0">
        <p class="col py-0 my-0">
          預金口座振替依頼書をダウンロード、印刷し、<br />
          必要事項をご記入の上、下記宛先までご郵送ください。<br />
          〒020-0861<br />
          岩手県盛岡市仙北二丁目7番6号<br />
          ダイナステージ株式会社<br />
          ALLY-PROカスタマーサポートセンター 宛<br />
        </p>
      </div>
      <div class="text-center">
        <b-button variant="ally" @click="getAccountTransferRequestForm($event)">
          預金口座振替依頼書 ダウンロード
        </b-button>
      </div>
    </b-modal>

    <!-- NOTE: 支払方法：変更完了モーダル -->
    <b-modal
      id="changePayment"
      :title="'支払方法 ' + resultModalTitle"
      header-class="ally-modal"
      footer-class="ally-modal"
      centered
      ok-only
      ok-variant="light"
      ok-title="OK"
      @hidden="routerView()"
    >
      支払方法の変更{{ isChangeReserve ? '予約を' : 'が完了' }}しました。<br />
      <div v-if="paymentSelectName != null && selected != null && company.payType != null">
        【変更前】<br />
        ・{{ paymentSelectName.find(val => val.payType == company.payType).name }}<br />
        <br />
        【変更後】<br />
        ・{{ paymentSelectName.find(val => val.payType == selected).name }}
      </div>
    </b-modal>
    <allyAlert code="S100" @okAction="closeAlert()" />
    <allyAlert code="E0001" />
  </div>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import awsconfig from '../../aws-exports';
  var moment = require('moment-timezone');
  moment.tz.setDefault('Asia/Tokyo');
  API.configure(awsconfig);

  import {
    isRegisteredCard,
    registerCard,
    getRegisterAccountTransferTranID,
    getSystemMaster,
    isRegisterAccountTransfer,
    changePaymentMethods,
  } from '@/graphql/queries';
  import { updateCompany, createBankAccount } from '@/graphql/mutations';
  import Load from '@/components/Common/Load';
  import Common from './js/Common';
  import { getS3Url } from '@/graphql/queries';
  import axios from 'axios';
  const zenginCode = require('zengin-code');

  export default {
    components: {
      Load,
    },
    mixins: [Common],
    data: function() {
      return {
        companyId: null, // ログインユーザーの会社ID
        company: {
          payType: null,
        },
        selected: null, // 選択内容
        loading: true, // ロード中
        isRegisteredCard: null, // カード登録有無
        brand: '使用可能ブランド　VISA／Master／JCB／AMEX／ダイナース',

        AccountTransferOnlineFormAllyTextMode: 'Edit', // 口座振替オンライン：<AllyText>のmode
        AccountTransferOnlineFormAllyTextRules: 'required', // 口座振替オンライン：<AllyText>の共通Rules
        financialInstitutionRequiredParamList: [], // 口座振替オンライン：金融機関必須パラメータ一覧
        bankCodeList: [], // 口座振替オンライン：        金融機関コード（銀行コード）のサジェストリスト
        isBankCodeInData: false, // 口座振替オンライン： 金融機関コード存在フラグ データに存在すればtrue
        bankCode: '', // 口座振替オンライン：            金融機関コード入力値
        isBranchCode: false, // 口座振替オンライン：     支店コード表示フラグ
        branchCode: '', // 口座振替オンライン：          支店コード入力値    ※金融機関コードにより必須
        isInternetBankingCheckbox: false, // 口座振替オンライン：インターネットバンキング契約チェックボックス表示フラグ（一部金融機関の当座指定に必要）
        isInternetBanking: false, // 口座振替オンライン：        インターネットバンキング契約フラグ入力値（一部金融機関の当座指定に必要）
        isAccountType: false, // 口座振替オンライン：    預金区分（預金種別）表示フラグ
        isSavingsAccount: false, // 口座振替オンライン： 預金区分 普通利用可能フラグ
        isCheckingAccount: false, // 口座振替オンライン：預金区分 当座利用可能フラグ
        accountTypeItems: [], // 口座振替オンライン：    預金区分のプルダウンアイテム
        accountType: '', // 口座振替オンライン：         預金区分入力値      ※金融機関コードにより必須
        isAccountNumber: false, // 口座振替オンライン：  口座番号表示フラグ
        accountNumber: '', // 口座振替オンライン：       口座番号入力値      ※金融機関コードにより必須
        isAccountName: false, // 口座振替オンライン：    口座名義カナ表示フラグ
        accountName: '', // 口座振替オンライン：         口座名義カナ入力値  ※金融機関コードにより必須
        accountTransferTransactionId: '', // 口座振替オンライン：口座振替トランザクションID
        isRegisterAccountTransferOnline: null, // 口座振替オンライン： 口座振替オンライン登録フラグ

        isFileDownload: false, // 口座振替ペーパー：ファイルダウンロードフラグ
        isAccountTransferPaper: false, // 口座振替ペーパー： 口座振替ペーパーフラグ

        isChangeReserve: false, // 変更予約フラグ

        awaitFlag: false, //trueの時読み込みロゴを表示する
        changeLimit: [
          {
            startDate: 1,
            endDate: 6,
          },
          {
            startDate: 27,
            endDate: 99,
          },
        ],
        paymentSelectName: [
          {
            payType: 0,
            name: 'クレジットカード',
          },
          {
            payType: 36,
            name: '銀行振込',
          },
          {
            payType: 998,
            name: '口座振替ペーパー',
          },
          {
            payType: 999,
            name: '口座振替オンライン',
          },
        ],
      };
    },
    mounted: async function() {
      // MOUNTED: ログインユーザーの会社IDを取得
      this.companyId = this.getCompanyId();
      // MOUNTED: カード登録確認
      await this.checkCard();
      // MOUNTED: 会社情報を取得
      this.company = await this.getCompany(this.companyId);
      this.selected = this.company.payType;

      // ロード完了
      this.loading = false;
    },

    computed: {
      // COMPUTED: 定数：クレジットカード
      creditCard() {
        return this.paymentSelectName.find(val => val.name == 'クレジットカード').payType;
      },
      // COMPUTED: 定数：銀行振込
      bankTransfer() {
        return this.paymentSelectName.find(val => val.name == '銀行振込').payType;
      },
      // COMPUTED: 定数：口座振替ペーパー
      accountTransferPaper() {
        return this.paymentSelectName.find(val => val.name == '口座振替ペーパー').payType;
      },
      // COMPUTED: 定数：口座振替オンライン
      accountTransferOnline() {
        return this.paymentSelectName.find(val => val.name == '口座振替オンライン').payType;
      },

      // COMPUTED: Pathによるモード設定
      mode: function() {
        const path = this.$router.currentRoute.path;
        if (path.startsWith('/PaymentMethod/Add')) {
          return 'Add'; // 登録
        } else if (path.startsWith('/PaymentMethod/Edit')) {
          if (!this.loading) {
            return 'Edit'; // 変更
          }
        }
        return 'View'; // 詳細
      },

      // COMPUTED: 画面・モダールタイトル
      title: function() {
        switch (this.mode) {
          case 'Add': // 登録
            return '支払方法登録';
          case 'Edit': // 変更
            return '支払方法変更';
          default:
            return '支払方法詳細';
        }
      },

      // COMPUTED: 支払方法切替結果モダールタイトル
      resultModalTitle() {
        return this.isChangeReserve ? '変更予約' : '変更完了';
      },

      // COMPUTED: 支払方法：変更可能日チェック
      limitCheck: function() {
        if (this.mode == 'Edit' || this.mode == 'View') {
          let date = parseInt(moment(this.today).format('DD'));
          let flag = true;
          for (let lim of this.changeLimit) {
            if (parseInt(lim.startDate) <= date && parseInt(lim.endDate) >= date) {
              flag = false;
            }
          }
          return flag;
        } else {
          return true;
        }
      },

      // COMPUTED: 支払方法：変更可能フラグ制御（確定/登録ボタン活性制御）
      changeable: function() {
        // 現在と同じ支払方法の場合は非活性
        if (
          this.company.payType === this.selected ||
          (this.company.paymentChangeReservation &&
            this.company.paymentChangeReservation.payType == this.selected)
        ) {
          return false;
        }
        // 登録または変更モード
        if (this.mode == 'Add' || this.mode == 'Edit') {
          // 変更モードかつ選択された支払方法が現在と同じ場合
          if (this.mode == 'Edit' && this.selected == this.company.payType) {
            // 確定ボタンを非活性
            return false;
          }
          // 登録または現在と異なる支払方法へ変更の場合
          switch (this.selected) {
            case null:
              // 未選択時は非活性
              return false;

            case this.creditCard:
              // クレジットカードの場合
              // カード登録されていれば活性、未登録は非活性
              return this.isRegisteredCard;

            case this.accountTransferOnline:
              // 口座振替オンラインの場合
              // 口座振替オンラインが登録されていれば活性、未登録は非活性
              return this.isRegisterAccountTransferOnline;

            case this.accountTransferPaper:
              // 口座振替ペーパーの場合
              // 預金口座振替依頼書ダウンロードモーダルで完了ボタン押下後は活性、それまでは非活性
              return this.isAccountTransferPaper;

            default:
              // その他の場合
              // 変更可能
              return true;
          }
        }
        // その他モードの場合
        else {
          // 変更不可
          return false;
        }
      },

      // COMPUTED: 銀行振込：振込口座情報
      bankInfo: function() {
        const b = this.company.bankTransfer;
        return !b
          ? []
          : [
              { name: '銀行名', value: this.getBankName(b) + ' (' + b.bankCode + ')' },
              { name: '支店名', value: this.getBranchName(b) + ' (' + b.branchCode + ')' },
              { name: '預金種別', value: '普通' },
              { name: '口座番号', value: b.accountNumber },
              { name: '口座名義', value: b.accountHolderName },
            ];
      },
      // COMPUTED: 先々月未納フラグ
      getIsUnpaidOfLastMonthBefore() {
        const unpaidMonth = moment(this.today)
          .add(-2, 'month')
          .format('M月');
        const result = this.company.payment && this.company.payment.find(
          pay =>
            pay.id ==
              moment(this.today)
                .add(-2, 'month')
                .format('YYMM') && pay.paymentResult === false
        );
        return {
          result,
          date: unpaidMonth,
        };
      },
    },
    methods: {
      // METHOD: 支払方法表示画面へ遷移
      async routerView() {
        this.$router.push('/PaymentMethod/View');
      },

      // METHOD: 支払方法：登録/変更（確定/登録ボタン押下時に呼ばれる）
      changePaymentMethod: async function(event) {
        console.log(this.selected);
        if (
          this.company.paymentChangeReservation &&
          !window.confirm(
            this.paymentSelectName.find(val => val.payType == this.selected).name +
              'へ変更すると\n' +
              this.paymentSelectName.find(
                val => val.payType == this.company.paymentChangeReservation.payType
              ).name +
              'への変更予約が取り消されます。\nよろしいですか？'
          )
        ) {
          return;
        }
        event.target.disabled = true; // 多重送信防止
        try {
          switch (this.mode) {
            // 変更モード時
            case 'Edit':
              {
                // パラメータ生成
                this.awaitFlag = true;
                let pushs = {
                  companyId: this.company.id, // 会社ID
                  oldPayType: this.company.payType, // 登録中の支払方法
                  newPayType: this.selected, // 選択された支払方法
                  date: this.today, // 本日の日付
                };
                // 口座振替オンラインの場合、パラメータにトランザクションIDを追加
                if (this.selected == this.accountTransferOnline) {
                  pushs.accountTransfer = {
                    transactionId: this.accountTransferTransactionId, // トランザクションID
                  };
                }
                // 支払方法変更処理
                const result = await API.graphql(graphqlOperation(changePaymentMethods, pushs));
                // 結果判定
                if (result.data.changePaymentMethods.statusCode == 200) {
                  this.$bvModal.show('changePayment');
                } else if (result.data.changePaymentMethods.error) {
                  alert(result.data.changePaymentMethods.error);
                  console.error(result.data.changePaymentMethods.error);
                  this.awaitFlag = false;
                } else {
                  this.showError('E0001', result);
                  this.awaitFlag = false;
                }
                // 支払方法変更予約フラグ（予約時：true / 変更時：false）
                switch (this.selected) {
                  // 口座振替ペーパー
                  case this.accountTransferPaper:
                    this.isChangeReserve = true;
                    break;
                  // それ以外
                  default:
                    this.isChangeReserve = false;
                    break;
                }
              }
              break;
            // 新規登録時
            default:
              {
                // パラメータ生成
                let param = [];
                param = {
                  id: this.companyId,
                  payType: this.selected,
                };
                if (this.selected == this.accountTransferOnline) {
                  // 口座振替オンラインの場合、パラメータにトランザクションIDを追加
                  param.accountTransfer = {
                    transactionId: this.accountTransferTransactionId, // トランザクションID
                  };
                }

                // 会社情報更新
                this.awaitFlag = true;
                await API.graphql(
                  graphqlOperation(updateCompany, {
                    input: param,
                  })
                );

                // 銀行振込の場合
                if (this.selected == this.bankTransfer) {
                  this.awaitFlag = true;
                  // 振込口座作成
                  await API.graphql(graphqlOperation(createBankAccount, { id: this.companyId }));
                }

                // 登録完了モーダルを表示する。
                this.$bvModal.show('S100');
                this.awaitFlag = false;
              }
              break;
          }
        } catch (e) {
          this.showError('E0001', e);
          this.awaitFlag = false;
        } finally {
          event.target.disabled = false; // 多重送信防止
        }
      },

      // METHOD: クレジットカード：カード登録確認
      checkCard: async function() {
        try {
          const result = await API.graphql(
            graphqlOperation(isRegisteredCard, { companyId: this.companyId })
          );
          if (result.data.isRegisteredCard.statusCode == '200') {
            this.isRegisteredCard = JSON.parse(result.data.isRegisteredCard.body).result;
          } else {
            this.isRegisteredCard = false;
          }
        } catch (e) {
          this.isRegisteredCard = false;
        }
      },

      // METHOD: クレジットカード：カード登録
      registerCard: async function() {
        // Lambda呼出
        const result = await API.graphql(
          graphqlOperation(registerCard, {
            companyId: this.companyId,
            companyName: this.company.companyName,
          })
        );
        // 結果取得
        if (result.data.registerCard.statusCode != '200') {
          return;
        }
        // カード登録URL表示
        const url = JSON.parse(result.data.registerCard.body).url;
        window.open(url, 'registerCard');
        // モーダル表示
        this.$bvModal.show('RegisterConfirmModal');
      },

      // METHOD: クレジットカード：カード判別
      async getCardChecks() {
        const companyId = this.company.id;
        let flag = null;
        try {
          const result = await API.graphql(graphqlOperation(isRegisteredCard, { companyId }));
          if (result.data.isRegisteredCard.statusCode == '200') {
            flag = JSON.parse(result.data.isRegisteredCard.body).result;
          } else {
            flag = false;
          }
        } catch (e) {
          flag = false;
        }
        return flag;
      },

      // METHOD: 銀行振込：銀行名
      getBankName: function(b) {
        try {
          return zenginCode[b.bankCode].name;
        } catch {
          return b.bankName;
        }
      },

      // METHOD: 銀行振込：支店名
      getBranchName: function(b) {
        try {
          return zenginCode[b.bankCode].branches[b.branchCode].name;
        } catch {
          return b.branchName;
        }
      },

      // METHOD: 口座振替オンライン：登録依頼モーダル表示
      openRegisterAccountTransferOnlineModal() {
        // 各入力値リセット
        this.bankCode = '';
        this.branchCode = '';
        this.isInternetBanking = false;
        this.accountType = '';
        this.accountNumber = '';
        this.accountName = '';
        // 口座振替オンライン：金融機関必須パラメータ一覧取得
        this.getFinancialInstitutionRequiredParamList();
        // 登録完了モーダルを表示する。
        this.$bvModal.show('AccountTransferOnlineModal');
      },

      // METHOD: 口座振替オンライン：入力内容バリデーション
      async validateAccountTransferOnlineForm() {
        return await this.$refs.registerAccountTransferOnlineForm.validate();
      },

      // METHOD: 口座振替オンライン：登録依頼
      async registerAccountTransferOnline() {
        this.loading = true;
        if (!(await this.validateAccountTransferOnlineForm())) {
          return;
        }
        try {
          // 登録依頼を行う
          const result = await API.graphql(
            graphqlOperation(getRegisterAccountTransferTranID, {
              companyId: this.companyId, // 会員ID
              bankCode: this.bankCode, // 金融機関コード（銀行コード）
              branchCode: this.branchCode, // 支店コード          ※金融機関コードにより必須
              accountType: this.accountType, // 預金区分（預金種別） ※金融機関コードにより必須
              accountNumber: this.accountNumber, // 口座番号            ※金融機関コードにより必須
              accountName: this.accountName, // 口座名義カナ        ※金融機関コードにより必須
            })
          );

          // 結果取得
          // エラーチェック
          if (result.data.getRegisterAccountTransferTranID.statusCode != '200') {
            // GMOエラー詳細チェック
            this.checkGmoErrInfo(result.data.getRegisterAccountTransferTranID.result.errInfo);
            console.error(result);
            return;
          }
          // 成功時
          // 金融機関遷移URL、トランザクションID、トークンを取得
          let startUrl = result.data.getRegisterAccountTransferTranID.result.startUrl;
          let tranId = result.data.getRegisterAccountTransferTranID.result.tranID;
          this.accountTransferTransactionId = tranId;
          let token = result.data.getRegisterAccountTransferTranID.result.token;

          // 取得結果の金融機関遷移URLで振替口座登録画面（別タブ）へ遷移
          // 別タブを開く
          window.open('', 'registerAccountTransferOnlineView');
          // フォームを生成
          let form = document.createElement('form');
          form.setAttribute('action', startUrl);
          form.setAttribute('target', 'registerAccountTransferOnlineView');
          form.setAttribute('method', 'post');
          document.body.appendChild(form);
          // POSTパラメータ「TranID」を生成
          let postDataTranId = document.createElement('input');
          postDataTranId.setAttribute('type', 'hidden');
          postDataTranId.setAttribute('name', 'TranID');
          postDataTranId.setAttribute('value', tranId);
          form.appendChild(postDataTranId);
          // POSTパラメータ「Token」を生成
          let postDataToken = document.createElement('input');
          postDataToken.setAttribute('type', 'hidden');
          postDataToken.setAttribute('name', 'Token');
          postDataToken.setAttribute('value', token);
          form.appendChild(postDataToken);
          // フォーム送信
          form.submit();

          // 振替口座登録画面（別タブ）表示後 結果確認モーダル表示
          this.$bvModal.show('RegisterConfirmModal');
        } catch (error) {
          console.error(error);
          this.openRegisterAccountTransferOnlineModal();
        } finally {
          this.loading = false;
        }
      },

      // METHOD: 口座振替オンライン：金融機関必須パラメータ一覧取得
      async getFinancialInstitutionRequiredParamList() {
        try {
          // システムマスタから金融機関必須パラメータ一覧取得
          const result = await API.graphql(
            graphqlOperation(getSystemMaster, {
              classification: 'financialInstitutionRequiredParamList',
              classificationDetail: 1,
            })
          );
          // 取得データ整形
          this.bankCodeList = [];
          result.data.getSystemMaster?.value.slice(1).map(val => {
            // フォーム生成用
            this.financialInstitutionRequiredParamList[val[1]] = {
              name: val[0],
              isBranchCode: Number(val[2]),
              isAccountType: Number(val[3]),
              isAccountNumber: Number(val[4]),
              isAccountName: Number(val[5]),
              isSavingsAccount: Number(val[6]),
              isCheckingAccount: val[7],
            };
            // 金融機関コードのサジェストリスト用
            this.bankCodeList.push({ text: val[0], value: val[1] });
          });
        } catch (error) {
          console.error(error);
        }
      },

      // METHOD: 口座振替オンライン：預金区分のプルダウンアイテム生成
      createAccountTypeItem() {
        this.accountTypeItems = []; // プルダウンのアイテム（預金区分の種類）
        // 預金区分の各利用可能フラグを確認し、プルダウンのアイテムを生成。
        if (Number(this.isSavingsAccount) != 0) {
          // 「普通」が利用可能な場合
          this.accountTypeItems.push({ text: '普通', value: '1' });
        }
        if (
          Number(this.isCheckingAccount) == 1 ||
          (this.isCheckingAccount == 'isInternetBanking' && this.isInternetBanking == true)
        ) {
          // 「当座」が利用可能な場合、またはインターネットバンキング契約が条件でインターネットバンキング契約中の場合
          this.accountTypeItems.push({ text: '当座', value: '2' });
        }
      },

      // METHOD: 口座振替オンライン：登録結果確認
      async checkRegisterAccountTransferOnline() {
        try {
          this.isRegisterAccountTransferOnline = false;
          // 口座振替オンライン登録確認結果を取得
          const result = await API.graphql(
            graphqlOperation(isRegisterAccountTransfer, {
              tranId: this.accountTransferTransactionId,
            })
          );

          // 結果確認
          let statusCode = result.data.isRegisterAccountTransfer.statusCode;
          if (statusCode == '200') {
            // GMOステータスチェック
            let status = result.data.isRegisterAccountTransfer.result.status;
            let message;
            switch (status) {
              case 'ENTRY':
                message = '登録済み';
                break;
              case 'START':
                message = '金融機関画面遷移';
                break;
              case 'TERM':
                message = '結果確認';
                break;
              case 'SUCCESS':
                message = '申込成功';
                break;
              case 'FAIL':
                message = '申込失敗';
                break;
              case 'UNPROCESSED':
                message = '申込失敗２';
                break;
              default:
                break;
            }
            if (status == 'ENTRY' || status == 'SUCCESS') {
              // 口座振替オンライン登録結果に問題なければTrue（支払方法登録ボタンが活性化）
              this.isRegisterAccountTransferOnline = true;
            } else {
              // 問題があればアラート表示
              console.error('口座振替オンライン登録ステータス = ' + status + '（' + message + '）');
              alert('登録が確認できませんでしたので再度お試しください。');
            }
          } else {
            console.error('statusCode = ', statusCode);
            // GMOエラー詳細チェック
            await this.checkGmoErrInfo(result.data.isRegisterAccountTransfer.result.errInfo);
          }
        } catch (e) {
          console.error(e);
        }
      },

      // METHOD: 口座振替オンライン：GMOエラー詳細チェック
      checkGmoErrInfo(errInfoJson) {
        // 存在しなければリターン
        if (!errInfoJson || errInfoJson == 'undefined') {
          return;
        }

        let errInfo = JSON.parse(errInfoJson); // エラー詳細の配列
        console.error('ErrInfo = ', errInfo);
        let alertMessage = ''; // アラートメッセージ
        let isInvalidInput = false; // 口座振替オンライン 入力値エラーフラグ
        let isOutOfHours = false; // 金融機関 時間外エラーフラグ

        // 全エラーチェック
        errInfo.slice(0).map(val => {
          switch (val) {
            case 'K01010440': // 金融機関側の受付時間外
            case 'K01020440': // 金融機関側の受付時間外
            case 'K01020317': // 金融機関取引時間外
            case 'K01020315': // サービス時間外エラー
              isOutOfHours = true;
              break;
            case 'M01061005': // 金融機関コード書式不正
            case 'M01061002': // 金融機関コード該当なし
            case 'M01062005': // 支店コード書式不正
            case 'M01062002': // 支店コード該当なし
            case 'M01070002': // 預金種別書式不正
            case 'M01071005': // 口座番号桁数不正
            case 'M01071006': // 口座番号書式不正
            case 'M01075005': // 口座名義桁数不正
            case 'M01075013': // 口座名義書式不正
              isInvalidInput = true;
              break;
            default:
              break;
          }
        });

        // アラートメッセージ
        // 入力値エラーが存在する場合のアラートメッセージ
        if (isInvalidInput) {
          alertMessage =
            alertMessage + '入力された値に問題がありましたので再度入力してください。\n';
        }
        // 時間外エラーが存在する場合のアラートメッセージ
        if (isOutOfHours) {
          alertMessage =
            alertMessage +
            'ご指定の金融機関のサービス時間外です。詳細な時間につきましては金融機関へご確認ください。\n';
        }
        // アラートメッセージが存在しない場合のアラートメッセージ
        if (alertMessage == '') {
          alertMessage = '問題が発生しました。しばらく時間をおいてから再度お試しください。';
        }

        // アラート表示
        alert(alertMessage);
      },

      // METHOD: 口座振替ペーパー：依頼書ダウンロードモーダル表示
      openAccountTransferPaperModal() {
        this.isFileDownload = false;
        // 登録完了モーダルを表示する。
        this.$bvModal.show('AccountTransferPaperModal');
      },

      // METHOD: 口座振替ペーパー：依頼書ダウンロード
      async getAccountTransferRequestForm(event) {
        event.target.disabled = true; // 多重送信防止
        try {
          // S3からURLを取得
          const result = await API.graphql(
            graphqlOperation(getS3Url, {
              level: 'Master',
              file: { fileName: 'Document/AccountTransferRequestForm.pdf', fileAction: 'View' },
            })
          );
          const fileUrl = result.data.getS3Url[0].fileUrl;

          // S3からファイルをダウンロード
          await axios.get(fileUrl, { responseType: 'blob' }).then(response => {
            const blob = new Blob([response.data], {
              type: 'application/pdf',
            });
            // ダウンロードリンク作成
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.crossOrigin = 'anonymous';
            link.download = '預金口座振替依頼書.pdf';
            link.target = '_blank';
            // ダウンロードアラート
            link.click();
          });
        } catch (e) {
          console.error(e);
          this.showError('E0001', e);
        } finally {
          event.target.disabled = false; // 多重送信防止
          this.isFileDownload = true; // 完了ボタン活性
        }
      },

      // METHOD: 口座振替ペーパー：結果確認
      async checkAccountTransferPaper() {
        this.isAccountTransferPaper = true;
      },

      // METHOD: モーダルクローズ時 登録確認
      closeModal: async function() {
        // ロード中
        this.loading = true;
        // 支払方法別 登録確認
        switch (this.selected) {
          // クレジットカード
          case this.creditCard:
            await this.checkCard();
            break;

          // 口座振替オンライン
          case this.accountTransferOnline:
            await this.checkRegisterAccountTransferOnline();
            break;

          // 口座振替ペーパー
          case this.accountTransferPaper:
            await this.checkAccountTransferPaper();
            break;

          // その他
          default:
            break;
        }
        // ロード完了
        this.loading = false;
      },

      // METHOD: アラートクローズ時 画面遷移
      closeAlert: async function() {
        // 追加時
        if (this.mode == 'Add') {
          // ライセンス購入画面へ
          this.$router.push('/License/Purchase');
        }
      },

      // METHOD: 戻るボタン
      async returnButton() {
        if (this.company.payType == this.creditCard) {
          let flag = await this.getCardChecks();
          if (flag == true) {
            this.$router.push('/License/Main');
          } else {
            alert(
              'クレジットカードの登録がないため、ライセンス管理画面に戻ることができません。\nクレジットカードを登録するか他のお支払い方法を設定してください。'
            );
          }
        } else {
          this.$router.push('/License/Main');
        }
      },

      // METHOD: 次回変更可能日を算出
      nextChangeableDate(changeLimit) {
        let dataList = [];
        let date = moment(this.today);
        for (let cl of changeLimit) {
          if (parseInt(moment(this.today).format('DD')) > parseInt(cl.endDate)) {
            dataList.push(
              moment(date)
                .add('months', 1)
                .format('YYYY-MM-' + ('0' + cl.endDate).slice(-2))
            );
          } else {
            if (cl.endDate != '99') {
              dataList.push(moment(date).format('YYYY-MM-' + ('0' + cl.endDate).slice(-2)));
            } else {
              dataList.push(
                moment(date)
                  .endOf('month')
                  .format('YYYY-MM-DD')
              );
            }
          }
        }
        //一番最終日の日付を取得する
        if (dataList.length != 0) {
          let maxData = dataList[0];
          for (let data of dataList) {
            if (moment(maxData).isSame(data)) {
              maxData = data;
            }
          }
          return moment(maxData)
            .add('d', 1)
            .format('YYYY年MM月DD日');
        }
        return null;
      },
    },
    watch: {
      // WATCH: 口座振替オンライン：金融機関コード入力値による、必須項目表示フラグ変更
      bankCode(bankCode) {
        let bank = this.financialInstitutionRequiredParamList; // マスタから取得した金融機関必須パラメータ一覧
        // 金融機関コード入力値がマスタに存在するかチェック
        if (bankCode in bank) {
          // マスタに存在する場合
          this.isBankCodeInData = true; // 金融機関コード存在フラグ
          // 各必須項目表示フラグをマスタの値に変更
          this.isBranchCode = bank[bankCode].isBranchCode; // 支店コード表示フラグ
          this.isAccountType = bank[bankCode].isAccountType; // 預金区分（預金種別） ※金融機関コードにより必須
          this.isSavingsAccount = bank[bankCode].isSavingsAccount; // 普通利用可能フラグ
          this.isCheckingAccount = bank[bankCode].isCheckingAccount; // 当座利用可能フラグ
          // 預金区分表示かつ当座利用可能フラグがisInternetBankingの場合
          if (bank[bankCode].isAccountType && this.isCheckingAccount == 'isInternetBanking') {
            // インターネットバンキング契約者のみ利用可能な為、チェックボックス表示フラグを立てる
            this.isInternetBankingCheckbox = true; // インターネットバンキング契約チェックボックス表示フラグ
          }
          this.isAccountNumber = bank[bankCode].isAccountNumber; // 口座番号表示フラグ
          this.isAccountName = bank[bankCode].isAccountName; // 口座名義カナ表示フラグ

          // 預金区分のプルダウンアイテム生成
          this.createAccountTypeItem();
        } else {
          // システムマスタに存在しない場合
          this.isBankCodeInData = false; // 金融機関コード存在フラグ
          // 各必須項目表示フラグをすべてfalse
          this.isBranchCode = false; // 支店コード表示フラグ
          this.isAccountType = false; // 預金区分（預金種別）
          (this.isInternetBankingCheckbox = false), // インターネットバンキング契約チェックボックス表示フラグ
            (this.isInternetBanking = false), // インターネットバンキング契約フラグ
            (this.isAccountNumber = false); // 口座番号表示フラグ
          this.isAccountName = false; // 口座名義カナ表示フラグ
        }
      },

      // WATCH: 口座振替オンライン：インターネットバンキング契約フラグ入力値による、預金区分のプルダウンアイテム生成
      isInternetBanking() {
        this.createAccountTypeItem();
      },
    },
  };
</script>

<style scoped>
  #PaymentMethod >>> .navbar {
    box-shadow: none;
  }
  #PaymentMethod .navbar .btn-ally {
    margin-left: 0.5rem;
  }
  #PaymentMethod >>> .card-columns {
    column-count: 3;
  }
  @media screen and (max-width: 768px) {
    #PaymentMethod >>> .card-columns {
      column-count: 1;
    }
  }
  #PaymentMethod >>> .card {
    border-top-color: gainsboro !important;
    border-radius: calc(0.25rem - 1px);
  }
  #PaymentMethod >>> .card-body {
    min-height: 10rem;
  }
  #PaymentMethod >>> .custom-radio .custom-control-label {
    margin: 0;
    padding-top: 2px;
    font-size: large;
    font-weight: bold;
  }
  #PaymentMethod >>> .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--colorTheme);
    background-color: var(--colorTheme);
  }
  #PaymentMethod >>> .nav-pills .nav-link.active {
    background-color: var(--colorTheme);
    margin-left: 1px;
    margin-right: 1px;
  }
  #PaymentMethod >>> .nav-pills .nav-link {
    background-color: gainsboro;
  }
  #PaymentMethod >>> .card-body {
    padding: 1rem;
  }
  #PaymentMethod >>> .bank-info .row {
    min-height: 2rem;
    border-top: 1px solid gainsboro;
  }
  #PaymentMethod >>> .bank-info .row:last-child {
    border-bottom: 1px solid gainsboro;
  }
  #PaymentMethod >>> .bank-info .row > div {
    display: grid;
    align-items: center;
    border-left: 1px solid gainsboro;
    word-break: break-all;
  }
  #PaymentMethod >>> .bank-info .row > div:first-child {
    color: white;
    background-color: var(--colorTheme);
  }
  #PaymentMethod >>> .bank-info .row > div:last-child {
    border-right: 1px solid gainsboro;
  }
  .roads {
    position: relative;
    top: -130px;
    left: -40px;
  }
</style>
<style>
  .tooltip-text .tooltip-inner {
    min-width: 15rem;
    font-size: small;
  }
</style>
