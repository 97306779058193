// @ts-check

export class HandleFlg {
  vertex = true;
  edge = true;
  curve = true;
  rotate = true;
  outline = true;

  /**
   * @param {Partial<HandleFlg>} params
   */
  constructor(params = {}) {
    this.vertex = params.vertex ?? true;
    this.edge = params.edge ?? true;
    this.curve = params.curve ?? true;
    this.rotate = params.rotate ?? true;
    this.outline = params.outline ?? false;
  }

  clone() {
    const clone = new HandleFlg();
    Object.assign(clone, this);
    return clone;
  }
}
