<!--
作成者：Lee HyeonSeok
作成日：2020.08.04
画面名：ログイン画面認証ビュー
機能：ログイン画面認証ビュー
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <Load v-if="loading"></Load>
    <div class="row">
      <div class="col-12 errorWord" v-if="error == 1">
        <b>登録処理でエラーが発生しました。再度お試しください。</b>
      </div>
      <div class="col-12 errorWord" v-else-if="error == 2">
        <b>利用規約及び個人情報取扱書に同意しない場合はシステムを利用することはできません。</b>
      </div>
      <div class="col-12 errorWord" v-else-if="error == 3">
        <b>既に登録されているE-mailです。</b>
      </div>
      <div class="col-12 errorWord" v-else-if="error == 4">
        <b>認証コードに誤りがあります。再度入力してください。</b>
      </div>
      <div class="col-12 errorWord" v-else-if="error == 5">
        <b>認証コードの送信制限回数を超えました。しばらくしてからもう一度実行してください。</b>
      </div>
      <div class="col-12 errorWord" v-else-if="error == 6">
        <b>特定商取引法に基づく表記に同意しない場合はシステムを利用することはできません。</b>
      </div>
    </div>
    <!-- 1.利用規約 -->
    <TermsOfUse v-show="page == CODE.TERMS" />
    <!-- 2.ALLY-PRO® 料金表 -->
    <PriceList v-show="page == CODE.PRICE" />
    <!-- 3.プライバシーポリシー -->
    <PrivacyPolicy v-show="page == CODE.PRIVACY" :infoCheck.sync="infoCheck" />
    <!-- 4.特定商取引法に基づく表記-->
    <SpecifiedCommercialTransaction
      v-show="page == CODE.SPECIFIED"
      :infoCheck.sync="specifiedChack"
    />
    <!-- 5.会社登録 -->
    <div v-show="page == CODE.COMPANY">
      新規で会社登録を行います。<br />
      既にご登録されている場合は、自社の管理者または
      <a href="mailto:ally@dynastage.co.jp">弊社</a>
      へお問い合わせください。<br /><br />
      <ValidationObserver tag="div" slim ref="companyValidation">
        <b-row>
          <!--左側-->
          <b-col style="border-right:1px solid rgb(154,154,154);">
            <b-row>
              <b-col>
                <allyText
                  title="会社名"
                  name="companyName"
                  v-model="companyInfo.companyName"
                  :mode="mode"
                  :placeholder="'〇〇株式会社'"
                  rules="zen|required|max:45"
                  :fullWidth="true"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyAddress v-model="companyInfo.address" :mode="mode" rules="required" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyText
                  title="電話番号"
                  name="tel"
                  v-model="companyInfo.tel"
                  :mode="mode"
                  :placeholder="'0196000000'"
                  rules="tel|required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyText
                  title="E-mail"
                  name="mail"
                  v-model="companyInfo.mail"
                  :mode="mode"
                  :placeholder="'xxxxxxxxx@xxx.co.jp'"
                  rules="email|required"
                />
              </b-col>
            </b-row>
          </b-col>
          <!--右側-->
          <b-col>
            <b-row>
              <b-col cols="7">
                <b-row>
                  <b-col>
                    <allyText
                      title="代表者名"
                      name="bossLastName"
                      v-model="companyInfo.bossLastName"
                      :mode="mode"
                      :placeholder="'新井'"
                      rules="required"
                    />
                  </b-col>
                  <b-col>
                    <allyText
                      title="-"
                      style="color:transparent;"
                      name="bossFirstName"
                      v-model="companyInfo.bossFirstName"
                      :mode="mode"
                      :placeholder="'太郎'"
                      rules="required"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      title="法人番号"
                      name="registrationNum"
                      v-model="companyInfo.registrationNum"
                      :mode="mode"
                      :placeholder="'0000000000000'"
                      :min="'12'"
                      :max="'13'"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <allyImages
                      v-model="companyInfo.companyLogo"
                      :mode="mode"
                      title="会社ロゴ"
                      :loading="loading"
                      ref="logo"
                    />
                  </b-col> </b-row
              ></b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyText
                  title="URL"
                  name="qualification"
                  v-model="companyInfo.qualification"
                  :mode="mode"
                  :placeholder="'http://example.com'"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyTextArea
                  title="メモ"
                  name="memo"
                  v-model="companyInfo.memo"
                  :mode="mode"
                  rows="14"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </ValidationObserver>
    </div>
    <!-- 6.管理者登録 -->
    <div v-show="page == CODE.ADMIN">
      管理者の登録を行います。<br />
      既にご登録されている場合は、自社の管理者または
      <a href="mailto:ally@dynastage.co.jp">弊社</a>
      へお問い合わせください。<br /><br />
      <ValidationObserver tag="div" slim ref="accountValidation">
        <b-row>
          <b-col style="border-right:1px solid rgb(154,154,154);">
            <b-row>
              <b-col>
                <allyText
                  title="管理者E-mail"
                  description="登録したアドレスがIDに設定されます"
                  name="admin-mail"
                  v-model="accountInfo.email"
                  rules="required|email"
                  :placeholder="'xxxxxxxxx@xxx.co.jp'"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyText
                  title="管理者氏名"
                  name="lastName"
                  v-model="accountInfo.familyname"
                  rules="required"
                  :placeholder="'新井'"
                />
              </b-col>
              <b-col>
                <allyText
                  title="管理者氏名"
                  style="color:transparent;"
                  name="firstName"
                  v-model="accountInfo.givenname"
                  rules="required"
                  :placeholder="'太郎'"
                  hideBadge
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyText
                  title="電話番号"
                  name="admin_phonenumber"
                  v-model="accountInfo.phonenumber"
                  rules="tel|aorbrequired:@携帯電話番号"
                  :placeholder="'0196000000'"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyText
                  title="携帯電話番号"
                  name="mobilePhoneNumber"
                  v-model="accountInfo.mobilePhoneNumber"
                  rules="tel|aorbrequired:@電話番号"
                  :placeholder="'0196000000'"
                />
                <span class="appendix">
                  ※IDを忘れた場合に、SMSメールにて照会できます。
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col style="top:-1rem;">
                <allyRadio
                  class="col-12"
                  title="性別"
                  name="gender"
                  :items="[
                    { text: '男', value: 'male' },
                    { text: '女', value: 'female' },
                  ]"
                  v-model="accountInfo.gender"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-col id="birthday">
                <allyDate title="生年月日" name="birthday" v-model="accountInfo.birthday" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyText
                  title="部署"
                  name="office"
                  v-model="accountInfo.office"
                  :placeholder="'〇〇支店'"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyText
                  title="パスワード"
                  name="admin_password"
                  v-model="accountInfo.password"
                  placeholder="8文字以上・大小文字・数字含む"
                  rules="required|password"
                  type="password"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyText
                  title="パスワードの確認"
                  name="newPasswordCheck"
                  v-model="newPasswordCheck"
                  rules="required|NewAndNewCheck:@パスワード"
                  placeholder="8文字以上・大小文字・数字含む"
                  type="password"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </ValidationObserver>
    </div>
    <!-- 7.登録内容確認 -->
    <div v-show="page == CODE.CONF">
      以下の内容で新規登録を行います。<br /><br />
      内容を確認して「登録」ボタンを押してください。<br />
      内容を変更する場合は「戻る」ボタンを押してください。<br /><br />
      <b-container class="confirm">
        <b-row>
          <b-col cols="6" style="border-right:1px solid black">
            <b>会社情報</b>
            <b-container class="signUpInfoCheckTable">
              <b-row v-for="(item, index) in companyItems" v-bind:key="index">
                <b-col cols="3">{{ item.name }}</b-col>
                <b-col v-if="item.name == '会社ロゴ'">
                  <img id="logo" v-if="item.value" :src="item.value" />
                </b-col>
                <b-col v-else>{{ item.value }}</b-col>
              </b-row>
            </b-container>
          </b-col>
          <b-col cols="6">
            <b>管理者情報</b>
            <b-container class="signUpInfoCheckTable">
              <b-row v-for="(item, index) in accountItems" v-bind:key="index">
                <b-col cols="3">{{ item.name }}</b-col>
                <b-col>{{ item.value }}</b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- 8.認証コード入力 -->
    <div v-show="page == CODE.CERT">
      ご登録いただいた下記メールアドレスに認証コードを送信しました。<br /><br />
      <b>
        {{ this.accountInfo.email }}
      </b>
      <br /><br />
      <b-container>
        <b-row>
          <b-col>
            <ValidationObserver tag="div" slim ref="confirmValidator">
              <allyText
                title="認証コード(数字6桁)"
                name="confirmCode"
                v-model="confirmCode"
                placeholder="111111"
                rules="required|numeric"
              />
            </ValidationObserver>
          </b-col>
          <b-col style="display:grid;align-items:center;">
            <b-link class="resend" :disabled="!resendable || loading" @click="resend"
              >メールが届かない場合</b-link
            >
          </b-col>
        </b-row>
      </b-container>
      <br />
      <!-- ※1時間以内に認証パスワードが入力されない場合は、登録された情報が削除されます。<br /> -->
    </div>
    <!-- 9.登録完了 -->
    <div class="col-12" v-show="page == CODE.END">
      <div class="row">
        会社登録が完了いたしました。<br />
        トップ画面から設定したID・パスワードを入力の上、ログインしてご利用ください。<br />
      </div>
    </div>
    <!--footer button-->
    <div class="row">
      <div class="col-12"></div>
    </div>
    <b-navbar fixed="bottom" style="box-shadow: none;" sticky print>
      <!-- 左側 -->
      <b-navbar-nav>
        <b-button
          @click="pageControl(2)"
          variant="ally-login"
          class="footer-button back"
          v-if="page != CODE.TERMS && page != CODE.CERT && page != CODE.END"
          :disabled="loading"
        >
          戻る
        </b-button>
      </b-navbar-nav>
      <!-- 右側 -->
      <b-navbar-nav class="ml-auto">
        <b-button
          @click="pageControl(1)"
          variant="ally-login"
          class="footer-button next"
          v-if="page != CODE.CONF && page != CODE.CERT && page != CODE.END"
          :disabled="loading"
        >
          次へ
        </b-button>
        <b-button
          @click="pageControl(3)"
          variant="ally-login"
          class="footer-button next"
          v-if="page == CODE.CONF"
          :disabled="loading"
        >
          登録
        </b-button>
        <b-button
          @click="pageControl(4)"
          variant="ally-login"
          class="footer-button next"
          v-if="page == CODE.CERT"
          :disabled="loading"
        >
          認証
        </b-button>
        <b-button
          @click="$emit('closeSignUpModal')"
          variant="ally-login"
          class="footer-button next"
          v-if="page == CODE.END"
          :disabled="loading"
        >
          完了
        </b-button>
      </b-navbar-nav>
    </b-navbar>
    <allyAlert :code="'S101'" :title="'認証コード再送信'"></allyAlert>
  </div>
</template>
<script>
  import awsconfig from '@/aws-exports';
  import { API, Auth, graphqlOperation } from 'aws-amplify';
  import TermsOfUse from './TermsOfUse';
  import PrivacyPolicy from './PrivacyPolicy.vue';
  import SpecifiedCommercialTransaction from './SpecifiedCommercialTransaction';
  import PriceList from './PriceList.vue';
  import { extend } from 'vee-validate';
  import {
    createCompanyTemporaryStorage,
  } from '@/graphql/mutations';
  import Load from '@/components/Common/Load';
  import cp from './companyRegister';

  API.configure(awsconfig);

  export default {
    name: 'SignUpInput',
    data: function() {
      return {
        mode: 'Add',
        authType: 'admin',
        loading: false,
        page: 1,
        error: 0,
        passwordHide: '.*',
        infoCheck: false,
        specifiedChack: false,
        newPasswordCheck: null,
        accountInfo: {
          email: null,
          familyname: null,
          givenname: null,
          phonenumber: null,
          password: null,
          gender: null,
          birthday: null,
          office: null,
        },
        companyInfo: {
          companyName: null,
          bossLastName: null,
          bossFirstName: null,
          tel: null,
          mail: null,
          address: {
            postalCode: null,
            address1: null,
            address2: null,
            address3: null,
          },
          qualification: null,
          registrationNum: null,
          companyLogo: null,
          license: null,
          memo: null,
        },
        confirmCode: null,
        resendable: true,
        CODE: {
          TERMS: 1,
          PRICE: 2,
          PRIVACY: 3,
          SPECIFIED: 4,
          COMPANY: 5,
          ADMIN: 6,
          CONF: 7,
          CERT: 8,
          END: 9,
        },
        registerUser: null,
      };
    },
    mixins:[cp],
    methods: {
      pageControl: async function(controlNum) {
        switch (controlNum) {
          // 「次へ」ボタン押下時
          case 1:
            // 利用規約に同意しない
            if (this.page == this.CODE.PRIVACY && this.infoCheck == false) {
              this.error = 2;
              return false;
            }
            //特定商取引に同意しない
            else if (this.page == this.CODE.SPECIFIED && this.specifiedChack == false) {
              this.error = 6;
              return false;
            }
            // 会社情報入力不備
            else if (this.page == this.CODE.COMPANY) {
              const isValid = await this.$refs.companyValidation.validate();
              if (!isValid) {
                return false;
              }
              this.page += 1;
              this.error = 0;
            }
            // 管理者情報入力不備
            else if (this.page == this.CODE.ADMIN) {
              const isValid = await this.$refs.accountValidation.validate();
              if (!isValid) {
                return false;
              }
              this.page += 1;
              this.error = 0;
            } else {
              this.page += 1;
              this.error = 0;
            }
            break;
          // 「戻る」ボタン押下時
          case 2:
            this.page -= 1;
            this.error = 0;
            break;
          // 「登録」ボタン押下時
          case 3:
            // 登録処理時は重説明事項に同義した場合のみ後続処理を進む
            if (this.page == this.CODE.CONF) {
              await this.signUp();
              await this.companyTemporaryStorage();
            }
            break;
          // 「認証」ボタン押下時
          case 4:
            // 認証処理
            if (this.page == this.CODE.CERT) {
              this.confirm();
            }
            break;
        }
      },
      // 管理者アカウント登録
      signUp: async function() {
        this.loading = true;
        try {
          // [Cognito]ユーザー追加
          this.registerUser = await Auth.signUp({
            username: this.accountInfo.email,
            password: this.accountInfo.password,
            attributes: {
              email: this.accountInfo.email,
              // 電話番号フォーマット＝E.164 number convention
              phone_number: this.accountInfo.phonenumber
                ? '+81' + this.accountInfo.phonenumber.substring(1)
                : null,
              // other custom attributes
              family_name: this.accountInfo.familyname,
              given_name: this.accountInfo.givenname,
              gender: this.accountInfo.gender,
              birthdate: this.accountInfo.birthday,
              address: this.accountInfo.office,
              'custom:number': '1',
              'custom:mobilePhoneNumber': this.accountInfo.mobilePhoneNumber,
            },
          });
          this.pageControl(1);
        } catch (e) {
          console.error({ signUp: e });
          const code = e.code;
          // 既に登録されているユーザ（E-mail重複）
          if (code === 'UsernameExistsException') {
            this.error = 3;
          } else {
            // システムエラー
            this.error = 1;
          }
        } finally {
          this.loading = false;
        }
      },
      /**
       * ページ更新で再ログインする用にデータを残しておく
       */
      companyTemporaryStorage: async function() {
        if (this.registerUser != null) {
          //会社情報を一時的に保存する
          try {
            //ダミーユーザーでサインイン
            await Auth.signIn(
              process.env.VUE_APP_UNAUTHENTICATED_ID,
              process.env.VUE_APP_UNAUTHENTICATED_PWD
            );
            await API.graphql(
              graphqlOperation(createCompanyTemporaryStorage, {
                input: {
                  username: this.registerUser.userSub,
                  storageJson: JSON.stringify(this.companyInfo),
                },
              })
            );
          } catch (error) {
            console.log('error', error);
          } finally {
            // サインアウト
            await Auth.signOut();
          }
        }
      },
      // 認証処理
      confirm: async function() {
        const isValid = await this.$refs.confirmValidator.validate();
        if (isValid) {
          this.loading = true;

          try {
            // ユーザ認証
            await Auth.confirmSignUp(this.accountInfo.email, this.confirmCode);
            // サインイン
            await Auth.signIn(this.accountInfo.email, this.accountInfo.password);
          } catch (e) {
            switch (e.code) {
              // 認証コード不一致
              case 'CodeMismatchException':
                this.error = 4;
                break;
              // システムエラー
              default:
                this.error = 1;
                break;
            }
            this.loading = false;
            return;
          }

          // 会社登録
          try {
            
            await this.companyRegister(this.companyInfo, this.accountInfo.email, this.registerUser.userSub.toString());
            this.pageControl(1);
          } catch (e) {
            console.error({ e });
            this.error = 1;
          } finally {
            this.loading = false;
          }
        }
      },
      // 認証コード再送信
      resend: async function() {
        this.loading = true;
        try {
          this.error = 0;
          await Auth.resendSignUp(this.accountInfo.email);
          this.$bvModal.show('S101');
        } catch (e) {
          switch (e.code) {
            // 送信制限回数越え
            case 'LimitExceededException':
              this.error = 5;
              break;
            // システムエラー
            default:
              this.error = 1;
              break;
          }
        } finally {
          // 30秒間押下不可
          this.resendable = false;
          setTimeout(() => {
            this.resendable = true;
          }, 30000);
          this.loading = false;
        }
      },
      // 会社ロゴ画像URL取得
      logo: function() {
        if (
          !this.$refs ||
          !this.$refs.logo ||
          !this.$refs.logo.imageList ||
          !this.$refs.logo.imageList[0]
        ) {
          return null;
        }
        return this.$refs.logo.imageList[0].fileDataURL;
      },
      // 性別
      gender: function(value) {
        return value == 'male' ? '男' : value == 'female' ? '女' : value;
      },
      // 年月日
      ymd: function(value) {
        if (value == null) {
          return null;
        }
        const dt = new Date(value);
        const y = dt.getFullYear();
        const m = dt.getMonth() + 1;
        const d = dt.getDate();
        return y + '年 ' + m + '月 ' + d + '日';
      },
    },
    computed: {
      // 会社情報
      companyItems: function() {
        return [
          { name: '会社名', value: this.companyInfo.companyName },
          { name: '郵便番号', value: this.companyInfo.address.postalCode },
          {
            name: '住所',
            value:
              this.companyInfo.address.address1 +
              this.companyInfo.address.address2 +
              this.companyInfo.address.address3,
          },
          { name: '電話番号', value: this.companyInfo.tel },
          { name: 'E-mail', value: this.companyInfo.mail },
          {
            name: '代表者氏名',
            value: this.companyInfo.bossLastName + ' ' + this.companyInfo.bossFirstName,
          },
          { name: '法人番号', value: this.companyInfo.registrationNum },
          { name: '会社ロゴ', value: this.logo() },
          { name: 'URL', value: this.companyInfo.qualification },
          { name: 'メモ', value: this.companyInfo.memo },
        ];
      },
      // 管理者情報
      accountItems: function() {
        return [
          { name: 'ID', value: this.accountInfo.email },
          {
            name: '氏名',
            value: this.accountInfo.familyname + ' ' + this.accountInfo.givenname,
          },
          { name: '電話番号', value: this.accountInfo.phonenumber },
          { name: '携帯電話番号', value: this.accountInfo.mobilePhoneNumber },
          { name: '性別', value: this.gender(this.accountInfo.gender) },
          { name: '生年月日', value: this.ymd(this.accountInfo.birthday) },
          { name: '部署', value: this.accountInfo.office },
        ];
      },
    },
    components: {
      TermsOfUse,
      PrivacyPolicy,
      PriceList,
      SpecifiedCommercialTransaction,
      Load,
    },
  };

  // カスタムルール
  // 新規パスワードと新規パスワード確認の比較
  extend('NewAndNewCheck', {
    params: ['target'],
    validate(value, { target }) {
      return value === target;
    },
    message: 'パスワードとパスワードの確認が一致しておりません',
  });
</script>
<style scoped>
  .footer-button {
    margin-right: 5px;
    margin-top: 10px;
  }
  .next {
    float: right;
  }
  .back {
    float: left;
  }
  .topTitle {
    margin-bottom: 10px;
  }
  .signUpInfoCheckTable .row {
    min-height: 2rem;
    border-top: 1px solid black;
  }
  .signUpInfoCheckTable .row:last-child {
    border-bottom: 1px solid black;
  }
  .signUpInfoCheckTable .row > div {
    display: grid;
    align-items: center;
    border-left: 1px solid black;
    word-break: break-all;
  }
  .signUpInfoCheckTable .row > div:first-child {
    color: white;
    background-color: var(--colorTheme);
    font-size: 0.875rem;
    padding-left: 8px;
    padding-right: 0;
  }
  .signUpInfoCheckTable .row > div:last-child {
    border-right: 1px solid black;
  }
  .confirm {
    border: 1px solid black;
    margin-bottom: 1rem;
    white-space: pre-wrap;
  }
  .confirm > .row > div {
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
  }
  .errorWord {
    color: red;
  }
  .alertMessage {
    color: orange;
  }
  #logo {
    height: 100px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .form-group {
    margin-bottom: 0;
  }
  .disabled {
    cursor: not-allowed;
    color: gray;
  }
  .appendix {
    font-size: small;
    position: relative;
    top: -1rem;
  }
  #birthday >>> label {
    margin-bottom: 2.5px;
  }
</style>
