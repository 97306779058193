// @ts-check

import { MaterialFetcher } from './materials';
import { checkDBCache } from '../../../../js/cache';
import { MATERIALS_SORT } from '../util/const';

export class WallMaterialFetcher {
  async _listWallMaterials() {
    const repo = new MaterialFetcher();
    const result = await repo.listMaterials(MATERIALS_SORT.CROSS);
    return result;
  }

  /**
   *
   * @returns {Promise<{name: string, color?: string, url?: string}[]>}
   */
  async listWallMaterials(forceRefresh = false) {
    const result = await checkDBCache('wallMaterialCache', () => this._listWallMaterials(), {
      forceRefresh,
    });

    result.sort((a, b) => a.index - b.index);
    return this._setOtherMaterial(result);
  }

  /**
   * @param {{name: string, color?: string, url?: string}[]} materials
   * @returns {{name: string, color?: string, url?: string}[]}
   */
  _setOtherMaterial(materials) {
    const colors = [
      // {
      //   name: '白',
      //   color: 'white',
      // },
    ];
    return [...colors, ...materials];
  }
}
