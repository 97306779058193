export default {
  methods: {
    /**
     * 都道府県情報
     * @returns
     */
    getPrefectures() {
      return [
        { no: 0, name: '-' },
        { no: 1, name: '北海道' },
        { no: 2, name: '青森県' },
        { no: 3, name: '岩手県' },
        { no: 4, name: '宮城県' },
        { no: 5, name: '秋田県' },
        { no: 6, name: '山形県' },
        { no: 7, name: '福島県' },
        { no: 8, name: '茨城県' },
        { no: 9, name: '栃木県' },
        { no: 10, name: '群馬県' },
        { no: 11, name: '埼玉県' },
        { no: 12, name: '千葉県' },
        { no: 13, name: '東京都' },
        { no: 14, name: '神奈川県' },
        { no: 15, name: '新潟県' },
        { no: 16, name: '富山県' },
        { no: 17, name: '石川県' },
        { no: 18, name: '福井県' },
        { no: 19, name: '山梨県' },
        { no: 20, name: '長野県' },
        { no: 21, name: '岐阜県' },
        { no: 22, name: '静岡県' },
        { no: 23, name: '愛知県' },
        { no: 24, name: '三重県' },
        { no: 25, name: '滋賀県' },
        { no: 26, name: '京都府' },
        { no: 27, name: '大阪府' },
        { no: 28, name: '兵庫県' },
        { no: 29, name: '奈良県' },
        { no: 30, name: '和歌山県' },
        { no: 31, name: '鳥取県' },
        { no: 32, name: '島根県' },
        { no: 33, name: '岡山県' },
        { no: 34, name: '広島県' },
        { no: 35, name: '山口県' },
        { no: 36, name: '徳島県' },
        { no: 37, name: '香川県' },
        { no: 38, name: '愛媛県' },
        { no: 39, name: '高知県' },
        { no: 40, name: '福岡県' },
        { no: 41, name: '佐賀県' },
        { no: 42, name: '長崎県' },
        { no: 43, name: '熊本県' },
        { no: 44, name: '大分県' },
        { no: 45, name: '宮崎県' },
        { no: 46, name: '鹿児島県' },
        { no: 47, name: '沖縄県' },
      ];
    },
    /**
     * 支払方法設定
     * @param {*} key
     * @returns
     */
    getPaymentType(key) {
      let items = [];
      items.push({ no: -1, name: '-', key: 'NON_TYPE' });
      items.push({ no: 0, name: 'クレジットカード', key: 'CREDIT_CARD' });
      items.push({ no: 36, name: 'あおぞら銀行', key: 'BANK_TRANSFER' });
      items.push({ no: 998, name: '口座振替', key: 'ACCOUNT_TRANSFER_PAPER' });
      //items.push({ no: 999, name: '口座振替(オンライン)', key: 'ACCOUNT_TRANSFER_ONLINE' });

      let item = items.find(val => val.key == key);
      if (item != null) {
        return item;
      } else if (item == null) {
        return items;
      }

      return items.find(val => val.no == -1);
    },
    /**
     * 調整方法設定
     * @param {*} key
     * @returns
     */
    getDepositRefundStatus(key) {
      const unpaid = { no: '1', status: 'UNPAID', displayName: '未納' };
      const paySuccess = { no: '2', status: 'PAY_SUCCESS', displayName: '支払済' };
      const directPayment = { no: '3', status: 'DIRECT_PAYMENT', displayName: '入金対応' };
      const directRefund = { no: '4', status: 'DIRECT_REFUND', displayName: '返金対応' };
      const nextUse = { no: '5', status: 'NEXT_USE', displayName: '翌月清算' };
      const settled = { no: '6', status: 'SETTLED', displayName: '精算済' };
      switch (key) {
        case '1':
        case 'UNPAID':
          return unpaid;
        case '2':
        case 'PAY_SUCCESS':
          return paySuccess;
        case '3':
        case 'DIRECT_PAYMENT':
          return directPayment;
        case '4':
        case 'DIRECT_REFUND':
          return directRefund;
        case '5':
        case 'NEXT_USE':
          return nextUse;
        case '6':
        case 'SETTLED':
          return settled;
        default:
          return [unpaid, paySuccess, directPayment, directRefund, nextUse, settled];
      }
    },
  },
};
