// @ts-check

import { API, graphqlOperation } from 'aws-amplify';
import { createPlaneView, updatePlaneView, updateProperty } from '../../../../graphql/mutations';
import { getPlaneView } from '../../../../graphql/queries';
import { PvPattern } from '../node/node/pvPattern';

export class PlaneViewFetcher {
  /**
   *
   * @param {string} id
   */
  async getPlaneView(id) {
    const response = await API.graphql(
      graphqlOperation(getPlaneView, {
        id,
      })
    );

    // @ts-ignore
    const data = response.data.getPlaneView;
    return data;
  }
  /**
   * レコード作成
   * @param {{
   * updatePerson: string
   * ownerId: string
   * roomId: string
   * companyId: string
   * propertyId: string
   * ownerName: string
   * planeName: string
   * patterns: Partial<PvPattern>[]
   * imageName: string
   * updateDate?: Date
   * deleted?: boolean
   * output?: boolean
   * restored?: boolean
   * azimuth: Number
   * }} input
   * @returns
   */
  async createPlaneView(input) {
    if (input.updatePerson == null) throw new Error('updatePersonは必須です');
    if (input.ownerId == null) throw new Error('ownerIdは必須です');
    if (input.ownerName == null) throw new Error('ownerNameは必須です');
    if (input.propertyId == null) throw new Error('propertyIdは必須です');
    if (input.updateDate == null) input.updateDate = new Date();
    if (input.deleted == null) input.deleted = false;
    if (input.output == null) input.output = false;
    if (input.restored == null) input.restored = false;

    return await API.graphql(
      graphqlOperation(createPlaneView, {
        input,
      })
    );
  }
  /**
   * レコード更新
   * @param {{
   * id: string
   * patterns: Partial<PvPattern>[]
   * imageName: string
   * updatePerson: String
   * updateDate?: Date
   * planeName?: string
   * azimuth: Number
   * }} input
   * @returns
   */
  async updatePlaneView(input) {
    if (input.updatePerson == null) throw new Error('updatePersonは必須です');
    if (input.updateDate == null) {
      input.updateDate = new Date();
    }

    return await API.graphql(
      graphqlOperation(updatePlaneView, {
        input,
      })
    );
  }

  /**
   * 論理削除 (削除履歴以外の削除はこれ)
   * @param {string} propertyId
   * @param {string} planeId
   * @param {string} deletePerson
   */
  async deletePlaneView(propertyId, planeId, deletePerson) {
    if (deletePerson == null) throw new Error('deletePersonは必須です');
    await API.graphql(
      graphqlOperation(updateProperty, {
        input: {
          id: propertyId,
          planeViewData: {
            newestImageName: null,
            updateDate: null,
          },
        },
      })
    );
    return await API.graphql(
      graphqlOperation(updatePlaneView, {
        input: { id: planeId, deleted: true, deleteDate: new Date(), deletePerson },
      })
    );
  }

  /**
   * 論理削除から復旧
   * @param {string} planeId
   * @param {string} restorePerson
   * @returns
   */
  async restorePlaneView(planeId, restorePerson) {
    if (restorePerson == null) throw new Error('restorePersonは必須です');
    return await API.graphql(
      graphqlOperation(updatePlaneView, {
        input: {
          id: planeId,
          deleted: false,
          restored: true,
          restoreDate: new Date(),
          restorePerson,
        },
      })
    );
  }

  /**
   * 画像出力履歴更新
   * @param {string} planeId
   * @param {string} outputPerson
   */
  async updateOutputHistoryPlaneView(planeId, outputPerson) {
    if (outputPerson == null) throw new Error('restorePersonは必須です');
    return await API.graphql(
      graphqlOperation(updatePlaneView, {
        input: {
          id: planeId,
          output: true,
          outputDate: new Date(),
          outputPerson,
        },
      })
    );
  }
}
