import { routePriceCalc } from './routePriceCalc';
import { irregularLandCalc } from './irregularLandCalc';
import { bigLandCalc } from './bigLandCalc';
import { noRoadLandCalc } from './noRoadLandCalc';
import { slantedLandCalc } from './slantedLandCalc';
import { multipleFloorAreaRatioCalc } from './multipleFloorAreaRatioCalc';

/**
 * 倍率方式での価値評価
 * 固定資産税*土地ごとの倍率
 * @param {data} input 入力データ
 * @return {Number} 評価額
 */

/**
 * 不動産ベース価値計算
 * 引数より入力情報を受け取り、
 * 資産に応じた不動産ベース価値を計算する
 * @param {object} input 入力情報
 * @return {object} 不動産ベース価値
 */
const getBaseRate = async (input, master) => {
  //リターンobject
  var InheritanceTaxCalc = null;
  //評価額計算
  let priceResult = {
    calcArray: [],
    resultText: null,
    resultPrice: 0,
    resultPriceType: 0,
    ownUserPrice: 0,
  };

  //各格納変数の初期化
  let resultIrregularLandCalc = null;
  let resultBigLandCalc = null;
  let resultNoRoadLand = null;
  let resultSlantedLand = null;
  let resultMultipleFloorAreaRatio = null;
  let personalCalc = null;
  let setBackCalc = null;
  let farmlandCalc = null;
  let cityRoadCalc = null;
  let bigFactoryCalc = null;
  let rentalLandCalc = null;
  let landForRentCalc = null;
  let landLeaseRightRentalHouseCalc = null;
  let rightsOfRenterCalc = null;
  let lendLandCalc = null;
  let subleasingCalc = null;

  //土地
  if (input.calcMode == 1 || input.calcMode == 2) {
    if (input.calcMode == 1) {
      var resultRoutePrice = await routePriceCalc(input, master);
      resultRoutePrice.calcArray.forEach(function(i) {
        priceResult.calcArray.push(i);
      });
      priceResult.resultPrice = resultRoutePrice.resultPrice;
      priceResult.resultPriceType = resultRoutePrice.resultPriceType;
    } else {
      console.log('input.landMagnification');
      priceResult.text = '倍率評価額';
      priceResult.resultPrice = input.fixedAssetTax;
      priceResult.resultPriceType = '倍率';
      var calcArrayItem = {
        text: priceResult.text,
        resultPrice: priceResult.resultPrice,
        resultPriceType: priceResult.resultPriceType,
      };
      priceResult.calcArray.push(calcArrayItem);
      //画面出力用Objectに格納
      InheritanceTaxCalc = {
        priceType: priceResult.resultPriceType,
        price: priceResult.resultPrice,
        calcArray: priceResult.calcArray,
      };
      return InheritanceTaxCalc;
    }

    if (!(input.areaType == '7' && input.landSize >= 50000)) {
      //不整形地 and 間口狭小な土地のの計算
      //間口狭小補正率、奥行長大補正率を両方の計算で使用するため、１モジュールに使用
      resultIrregularLandCalc = await irregularLandCalc(priceResult, input, master);
      if (
        resultIrregularLandCalc.resultPriceType == 'E' ||
        resultIrregularLandCalc.resultPriceType == 'F'
      ) {
        priceResult.resultPrice = resultIrregularLandCalc.resultPrice;
        priceResult.resultPriceType = resultIrregularLandCalc.resultPriceType;
        priceResult.calcArray.push(resultIrregularLandCalc);
      }

      //地積規模が大きな宅地の判定
      if (input.areaType == 4 || input.areaType == 6) {
        //三大都市圏においては500平方メートル以上の地積の宅地(input.bigLandFlg == true)、
        //三大都市圏以外の地域においては1,000平方メートル以上の地積の宅地
        if (input.bigLandFlg || input.landSize >= 1000) {
          resultBigLandCalc = await bigLandCalc(priceResult, input, master);
          priceResult.resultPrice = resultBigLandCalc.resultPrice;
          priceResult.resultPriceType = resultBigLandCalc.resultPriceType;
          priceResult.calcArray.push(resultBigLandCalc);
          console.log('bigland', priceResult);
        }
      }
      //無道路地の計算
      if (
        (priceResult.resultPriceType == 'F' || priceResult.resultPriceType == 'G') &&
        input.noRoadLandFlg
      ) {
        resultNoRoadLand = await noRoadLandCalc(priceResult, input);
        priceResult.resultPrice = resultNoRoadLand.resultPrice;
        priceResult.resultPriceType = resultNoRoadLand.resultPriceType;
        priceResult.calcArray.push(resultNoRoadLand);
      }

      //かげ地の計算
      if (input.slantedLandFlg) {
        resultSlantedLand = await slantedLandCalc(priceResult, input, master);
        priceResult.resultPrice = resultSlantedLand.resultPrice;
        priceResult.resultPriceType = resultSlantedLand.resultPriceType;
        priceResult.calcArray.push(resultSlantedLand);
      }

      //容積率の異なる2以上の地域にわたる宅地
      if (input.multipleFloorAreaRatioFlg) {
        resultMultipleFloorAreaRatio = await multipleFloorAreaRatioCalc(priceResult, input);
        priceResult.resultPrice = resultMultipleFloorAreaRatio.resultPrice;
        priceResult.resultPriceType = resultMultipleFloorAreaRatio.resultPriceType;
        priceResult.calcArray.push(resultMultipleFloorAreaRatio);
      }

      //私道の計算（計算式が短い為、module未実装）
      if (input.useType == 5) {
        personalCalc = {
          text: '私道の計算',
          resultPrice: Math.floor(priceResult.resultPrice * 0.3),
          resultPriceType: 'L',
        };
        priceResult.resultPrice = personalCalc.resultPrice;
        priceResult.resultPriceType = personalCalc.resultPriceType;
        priceResult.calcArray.push(personalCalc);
        console.log('私道の計算完了 : ', priceResult);
      }

      //-----------------------------------------------------------------
      //以下は「土地及び土地の上に存する権利の評価明細書（第２表）」である
      //基本的にはマスタデータの呼び出しや長い処理がない為、index.jsに実装する
      //-----------------------------------------------------------------

      //セットバックの計算
      if (input.setBackFlg) {
        setBackCalc = {
          resultPrice:
            priceResult.resultPrice -
            Math.floor(priceResult.resultPrice * (input.setBackSize / input.landSize) * 0.7),
          resultPriceType: 'N',
          text: 'セットバックの計算',
        };
        priceResult.resultPrice = setBackCalc.resultPrice;
        priceResult.resultPriceType = setBackCalc.resultPriceType;
        priceResult.calcArray.push(setBackCalc);
        console.log('セットバックの計算完了 : ', priceResult);
      }

      //市街地農地の計算
      if (input.farmlandFlag && input.address != null) {
        let slopeAngleMaster = master.interitanceMaster12.slice(1);
        let slopeAngle = slopeAngleMaster.filter(s => s[1] === input.address);

        // 整地費
        let preparationCost =
          (Math.floor(Number(input.preparationSize) * 100) / 100) *
          Math.floor(Number(input.preparationUnitPrice));
        // 伐採・抜根費
        let loggingCost1 =
          (Math.floor(Number(input.loggingSize1) * 100) / 100) *
          Math.floor(Number(input.loggingUnitPrice));
        // 地盤改良費
        let stabilizationCost =
          (Math.floor(Number(input.stabilizationSize) * 100) / 100) *
          Math.floor(Number(input.stabilizationUnitPrice));
        // 土盛費
        let earthworkCost =
          (((Math.floor(Number(input.earthworkSize) * 100) / 100) *
            Math.floor(Number(input.earthworkHight) * 100)) /
            100) *
          Math.floor(Number(input.earthworkUnitPrice));
        // 土止費
        let sheathingCost =
          (((Math.floor(Number(input.sheathingLength) * 100) / 100) *
            Math.floor(Number(input.sheathingHight) * 100)) /
            100) *
          Math.floor(Number(input.sheathingUnitPrice));

        // 傾斜度に係る造成費
        let angle = Math.floor(Number(input.slopeAngle));
        let slopeAngleCost = 0;
        if (slopeAngle?.length > 0 && 3 < angle && angle <= 30) {
          let index = -1;
          if (angle <= 5) {
            index = 2;
          } else if (angle <= 10) {
            index = 3;
          } else if (angle <= 15) {
            index = 4;
          } else if (angle <= 20) {
            index = 5;
          } else if (angle <= 25) {
            index = 6;
          } else if (angle <= 30) {
            index = 7;
          }
          if (index != -1) {
            slopeAngleCost = parseInt(slopeAngle[0][index]);
          }
        }

        let flatlandCost = Math.floor(
          (preparationCost + loggingCost1 + stabilizationCost + earthworkCost + sheathingCost) /
            input.landSize
        );
        let slopelandCost = Math.floor(slopeAngleCost + loggingCost1 / input.landSize);

        farmlandCalc = {
          resultPrice: Math.max(flatlandCost, slopelandCost),
          resultPriceType: 'ZZZ',
          text: '市街地農地の計算',
        };
        if (farmlandCalc.resultPrice > 0) {
          priceResult.resultPrice = farmlandCalc.resultPrice;
          priceResult.resultPriceType = farmlandCalc.resultPriceType;
          priceResult.calcArray.push(farmlandCalc);
          console.log('市街地農地の計算完了 : ', priceResult);
        }
      }

      //都市計画道路の宅地の計算
      if (input.cityRoadFlg) {
        cityRoadCalc = {
          resultPrice: Math.floor(priceResult.resultPrice * input.cityRoadFactor),
          resultPriceType: 'O',
          text: '都市計画道路の宅地の計算',
        };
        priceResult.resultPrice = cityRoadCalc.resultPrice;
        priceResult.resultPriceType = cityRoadCalc.resultPriceType;
        priceResult.calcArray.push(cityRoadCalc);
        console.log('都市計画道路対象地の計算完了 : ', priceResult);
      }

      //大規模工場用地の計算
    } else {
      bigFactoryCalc = {
        resultPrice: priceResult.resultPrice,
        resultPriceType: 'P',
        text: '大規模工場用地の計算',
      };

      //20万㎡以上の場合は0.95を掛け算
      if (input.landSize >= 200000) {
        bigFactoryCalc.resultPrice = Math.floor(bigFactoryCalc.resultPrice * 0.95);
      }

      priceResult.resultPrice = bigFactoryCalc.resultPrice;
      priceResult.resultPriceType = bigFactoryCalc.resultPriceType;
      priceResult.calcArray.push(bigFactoryCalc);
      console.log('大規模工場用宅地の計算完了 : ', priceResult);
    }
    //自用地の１㎡当たりの価格を保存
    priceResult.ownUserPrice = priceResult.resultPrice;
  }

  //総額計算
  //-----------------------------------------------------------------
  switch (input.useType) {
    //貸宅地
    case 2:
      rentalLandCalc = {
        resultPrice: Math.floor(priceResult.resultPrice * (1 - input.rentLandRate / 100)),
        resultPriceType: 'R',
        text: '貸宅地の計算',
      };
      priceResult.resultPrice = rentalLandCalc.resultPrice;
      priceResult.resultPriceType = rentalLandCalc.resultPriceType;
      priceResult.calcArray.push(rentalLandCalc);
      break;
    //貸家建付地
    case 3:
      landForRentCalc = {
        resultPrice: Math.floor(
          priceResult.resultPrice *
            (1 - (input.rentLandRate / 100) * (input.rentHouseRate / 100) * (input.lendRate / 100))
        ),
        resultPriceType: 'S',
        text: '貸家建付地',
      };
      priceResult.resultPrice = landForRentCalc.resultPrice;
      priceResult.resultPriceType = landForRentCalc.resultPriceType;
      priceResult.calcArray.push(landForRentCalc);
      break;
    //借地権
    //借家人の有する権利
    case 4:
      //借地権
      lendLandCalc = {
        resultPrice: Math.floor(priceResult.resultPrice * (input.rentLandRate / 100)),
        resultPriceType: 'U',
        text: '借地権の計算',
      };
      priceResult.resultPrice = lendLandCalc.resultPrice;
      priceResult.resultPriceType = lendLandCalc.resultPriceType;
      priceResult.calcArray.push(lendLandCalc);

      if (input.rentHouseFlg) {
        //借家人の有する権利
        rightsOfRenterCalc = {
          resultPrice: Math.floor(
            priceResult.resultPrice * (input.rentHouseRate / 100) * (input.lendRate / 100)
          ),
          resultPriceType: 'Y',
          text: '借家人の有する権利の計算',
        };
        priceResult.resultPrice = rightsOfRenterCalc.resultPrice;
        priceResult.resultPriceType = rightsOfRenterCalc.resultPriceType;
        priceResult.calcArray.push(rightsOfRenterCalc);
      }
      break;
    //貸家建付借地権
    case 6:
      landLeaseRightRentalHouseCalc = {
        resultPrice: Math.floor(
          priceResult.resultPrice * (1 - (input.rentHouseRate / 100) * (input.lendRate / 100))
        ),
        resultPriceType: 'V',
        text: '貸家建付借地権の計算',
      };
      priceResult.resultPrice = landLeaseRightRentalHouseCalc.resultPrice;
      priceResult.resultPriceType = landLeaseRightRentalHouseCalc.resultPriceType;
      priceResult.calcArray.push(landLeaseRightRentalHouseCalc);
      break;
    //転貸借地権
    case 7:
      subleasingCalc = {
        resultPrice: Math.floor(priceResult.resultPrice * (1 - input.rentLandRate / 100)),
        resultPriceType: 'W',
        text: '転貸借地権の計算',
      };
      priceResult.resultPrice = subleasingCalc.resultPrice;
      priceResult.resultPriceType = subleasingCalc.resultPriceType;
      priceResult.calcArray.push(subleasingCalc);
      break;
  }
  //リターン値格納
  InheritanceTaxCalc = {
    priceType: priceResult.resultPriceType,
    price: priceResult.resultPrice,
    calcArray: priceResult.calcArray,
  };

  console.log('ベース価値', InheritanceTaxCalc);

  return InheritanceTaxCalc;
};

// //課税価格の合計額
// const getTotalTaxPriceCalc = () =>{
//     let totalBaseRatePrice = 0;
//     let cash = 0;
//     let lifeInsurance = 0;
//     let otherFinancialAssets = 0;
//     let other = 0;
//     return totalBaseRatePrice + cash + lifeInsurance + otherFinancialAssets + other;
// }

// //課税遺産総額
// //課税価格の合計額 － 基礎控除額（3,000万円 ＋ 600万円 × 法定相続人の数）
// const getTotalInheritancePriceCalc = () =>{
//     let totalTaxPrice = 0;
//     let legalHeirs = [];
//     //基礎控除額（3,000万円 ＋ 600万円 × 法定相続人の数）
//     let baseDeduction = (3000 + 600 * legalHeirs.length) * 10000;
//     return totalTaxPrice - baseDeduction;
// }

// /**
//  * 法定相続分取得
//  * 民法第９００条及び第９０１条に規定
//  * 参考：https://www.nta.go.jp/taxes/shiraberu/taxanswer/sozoku/4132.htm
//  */
// const getShareInInheritance = (legalHeir) =>{

//     //課税遺産総額
//     let totalInheritax = 0;
//     //法定相続人を続柄別に分ける
//     let legalHeirs = []; //customer.family?
//     let spouse = legalHeirs.filter(val =>{
//         return val.familyType === "配偶者";
//     });
//     let children = legalHeirs.filter(val =>{
//         return val.familyType === "子供";
//     });
//     let parentsAlive = legalHeirs.filter(val =>{
//         return val.familyType === "親";
//     });
//     let siblings = legalHeirs.filter(val =>{
//         return val.familyType === "兄弟姉妹";
//     });

//     //配偶者の有無
//     if(input.spouse == 1){
//         //子供がいる場合
//         if(children.length > 0){
//             if(legalHeir.familyType == "配偶者"){
//                 return "1/2";
//             }else{
//                 return "1/" + (2 * children.length).toString();
//             }
//         //子供なし且つ直系尊属がいる場合
//         }else if(children.length === 0 && parentsAlive.length > 0){
//             if(legalHeir.familyType == "配偶者"){
//                 return "2/3";
//             }else{
//                 return "1/" + (3 * parentsAlive.length).toString();
//             }
//         //子供も直系尊属もいない場合
//         }else if(children.length === 0 && parentsAlive.length === 0 && siblings.length > 0){
//             if(legalHeir.familyType == "配偶者"){
//                 return "3/4";
//             }else{
//                 return "1/" + (4 * siblings.length).toString();
//             }
//         //配偶者のみの場合
//         }else{
//             return "1/1";
//         }
//     //配偶者がいない場合
//     }else{
//         //順位上の人の総取り
//         return legalHeirs.filter(val =>{
//             return val.familyType === legalHeir.familyType;
//         }).length;
//     }
// }

// //法定相続人取得金額
// //課税遺産総額 × 各法定相続人の法定相続分
// const getInheritancePriceCalc = (legalHeir) =>{
//     let totalInheritancePrice = 0;
//     let shareInInheritance = legalHeir.shareInInheritance.split("/");
//     return totalInheritancePrice * shareInInheritance[0] / shareInInheritance[1];
// }

// //法定相続人算出税額
// const getInheritanceTaxCalc =(legalHeir) => {
//     //取得金額 * 税率 - 控除額
//     let inheritancePrice = legalHeir.inheritancePrice;
//     //取得金額により分岐
//     //1000万円以下
//     if(inheritancePrice <= 10000000){
//         return inheritancePrice * 0.1;
//     //3000万円以下
//     }else if(10000000 < inheritancePrice && inheritancePrice <= 30000000){
//         return inheritancePrice * 0.15 - 500000;
//     //5000万円以下
//     }else if(30000000 < inheritancePrice && inheritancePrice <= 50000000){
//         return inheritancePrice * 0.2 - 2000000;
//     //1億円以下
//     }else if(50000000 < inheritancePrice && inheritancePrice <= 100000000){
//         return inheritancePrice * 0.3 - 7000000;
//     //2億円以下
//     }else if(100000000 < inheritancePrice && inheritancePrice <= 200000000){
//         return inheritancePrice * 0.4 - 17000000;
//     //3億円以下
//     }else if(200000000 < inheritancePrice && inheritancePrice <= 300000000){
//         return inheritancePrice * 0.45 - 27000000;
//     //6億円以下
//     }else if(300000000 < inheritancePrice && inheritancePrice <= 600000000){
//         return inheritancePrice * 0.5 - 42000000;
//     //6億円超
//     }else if(600000000 < inheritancePrice){
//         return inheritancePrice * 0.55 - 72000000;
//     }else{
//         return false;
//     }

// }

// //法定相続人納付税額
// const getPaymentTaxCalc = (legalHeir) =>{
//     //ベースの相続額 = 相続税総額 * (法定相続人.課税価格 / 課税価格総額)
//     let totalInheritanceTax = 0;
//     legalHeirs.foreach(val =>{
//         totalInheritanceTax += val.inheritanceTax;
//     });
//     //今回課税価格は全員均一とみなすので、法定相続分で割ればよい？
//     let baseInheritancePrice = totalInheritanceTax * (totalTaxPrice * legalHeir.shareInInheritance[0] / legalHeir.shareInInheritance[1]);
//     let paymentTax = baseInheritancePrice;

//     //贈与税額
//     let presentTax = input.presentTax;
//     //配偶者控除
//     if(legalHeir.familyType === "配偶者"){
//         if(baseInheritancePrice < 160000000 || baseInheritancePrice < legalHeir.inheritancePrice){
//             paymentTax = 0;
//         }
//     }

//     //±贈与等控除額、増額
//     paymentTax -= (paymentTax);
//     if(paymentTax < 0){
//         paymentTax = 0;
//     }
//     return paymentTax;
// }

// /**
//  * 相続税計算
//  * 引数より入力情報を受け取り、
//  * 相続税の各情報を計算する
//  * @param {object} input 入力情報
//  * @return {object} 相続税情報
//  */
// const getTotalInheritanceTaxCalc = async (input) =>{
//     return false;
// }

export { getBaseRate };
