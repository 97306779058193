<template>
  <div style="margin-left:15px;margin-right:15px;">
    <div class="mt-3"><h4>
    <select v-model="selectedPrice">
      <option>相続税評価ベース</option>
      <option>取得価格ベース</option>
    </select>
    </h4>
    </div>
    <div style="text-align: right">
      <span>単位: 万円</span>
    </div>
    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th class="title" colspan="1"></b-th>
          <b-th class="title" colspan="5">貸借対照表(B/S)</b-th>
          <b-th class="title" colspan="8">損益計算(P/L)</b-th>
          <b-th class="title" colspan="2">キャッシュフロー</b-th>
          <b-th class="title" colspan="2">健全性</b-th>
          <b-th class="title" colspan="1"></b-th>
        </b-tr>
        <b-tr>
          <b-th class="title"></b-th>
          <b-th class="title">①</b-th>
          <b-th class="title">②</b-th>
          <b-th class="title">③</b-th>
          <b-th class="title">④</b-th>
          <b-th class="title">⑤</b-th>
          <b-th class="title">⑥</b-th>
          <b-th class="title">⑦</b-th>
          <b-th class="title">⑧</b-th>
          <b-th class="title">⑨</b-th>
          <b-th class="title">⑩</b-th>
          <b-th class="title">⑪</b-th>
          <b-th class="title">⑫</b-th>
          <b-th class="title">⑬</b-th>
          <b-th class="title">⑭</b-th>
          <b-th class="title">⑮</b-th>
          <b-th class="title">⑯</b-th>
          <b-th class="title">⑰</b-th>
          <b-th class="title">⑱</b-th>
        </b-tr>
        <b-tr>
          <b-th class="title" style="width: 50px">資産<br />番号</b-th>
          <b-th class="title" style="width: 80px">土地価格</b-th>
          <b-th class="title" style="width: 80px">建物価格</b-th>
          <b-th class="title" style="width: 80px"
            >合計<br />
            ①+②</b-th
          >
          <b-th class="title" style="width: 80px">借入金</b-th>
          <b-th class="title" style="width: 80px">純資産<br />③-④</b-th>
          <b-th class="title" style="width: 80px">収入</b-th>
          <b-th class="title" style="width: 80px">土地<br />(固定資産税)</b-th>
          <b-th class="title" style="width: 80px">土地<br />(都市計画税)</b-th>
          <b-th class="title" style="width: 80px">建物<br />(固定資産税)</b-th>
          <b-th class="title" style="width: 80px">建物<br />(都市計画税)</b-th>
          <b-th class="title" style="width: 80px">管理費</b-th>
          <b-th class="title" style="width: 80px">合計<br />⑦~⑪</b-th>
          <b-th class="title" style="width: 80px">年間収支<br />⑥-⑫</b-th>
          <b-th class="title" style="width: 80px">借入金返済額</b-th>
          <b-th class="title" style="width: 80px">税前<br />キャッシュ<br />フロー<br />⑬-⑭</b-th>
          <b-th class="title" style="width: 80px">利回り(%)<br />⑬÷③</b-th>
          <b-th class="title" style="width: 80px">借入金比率(%)<br />④÷③</b-th>
          <b-th class="title" style="width: 80px">資産効率(%)</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(input, i) in inputList.length === valueList.length ? inputList : []" :key="i">
          <b-td class="number-cell">{{ i + 1 }}</b-td>
          <b-td class="number-cell">
            {{
              formatNum(
                input.inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice.land / 10000,
                {
                  floatLength: 0,
                  roundType: 'off',
                  separation: true,
                }
              )
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(
                input.inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice.building / 10000,
                {
                  floatLength: 0,
                  roundType: 'off',
                  separation: true,
                }
              )
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(valueList[i].inheritanceTaxBase.No8 / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <!-- 借入金 -->
          <b-td class="number-cell">
            {{
              formatNum(input.inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(valueList[i].inheritanceTaxBase.No10 / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <!-- 収入 -->
          <b-td class="number-cell">
            {{
              formatNum(input.inheritanceTaxBase.assetsRealEstate.pl.income / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <!-- 土地 固定資産税 -->
          <b-td class="number-cell">
            {{
              formatNum(
                input.inheritanceTaxBase.assetsRealEstate.pl.spending.landPropertyTax / 10000,
                {
                  floatLength: 0,
                  roundType: 'off',
                  separation: true,
                }
              )
            }}
          </b-td>
          <!-- 土地 都市計画税 -->
          <b-td class="number-cell">
            {{
              formatNum(
                input.inheritanceTaxBase.assetsRealEstate.pl.spending.landCityPlanningTax / 10000,
                {
                  floatLength: 0,
                  roundType: 'off',
                  separation: true,
                }
              )
            }}
          </b-td>
          <!-- 建物 固定資産税 -->
          <b-td class="number-cell">
            {{
              formatNum(
                input.inheritanceTaxBase.assetsRealEstate.pl.spending.buildingPropertyTax / 10000,
                {
                  floatLength: 0,
                  roundType: 'off',
                  separation: true,
                }
              )
            }}
          </b-td>
          <!-- 建物 都市計画税 -->
          <b-td class="number-cell">
            {{
              formatNum(
                input.inheritanceTaxBase.assetsRealEstate.pl.spending.buildingCityPlanningTax /
                  10000,
                {
                  floatLength: 0,
                  roundType: 'off',
                  separation: true,
                }
              )
            }}
          </b-td>
          <!-- 管理費 -->
          <b-td class="number-cell">
            {{
              formatNum(
                input.inheritanceTaxBase.assetsRealEstate.pl.spending.maintenanceFee / 10000,
                {
                  floatLength: 0,
                  roundType: 'off',
                  separation: true,
                }
              )
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(valueList[i].inheritanceTaxBase.No15 / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(valueList[i].inheritanceTaxBase.No16 / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <!-- 借入金返済額 -->
          <b-td class="number-cell">
            {{
              formatNum(
                input.inheritanceTaxBase.assetsRealEstate.cashflowCalc.outstandingDebtTotal / 10000,
                {
                  floatLength: 0,
                  roundType: 'off',
                  separation: true,
                }
              )
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(valueList[i].inheritanceTaxBase.No18 / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(valueList[i].inheritanceTaxBase.No19, {
                floatLength: 1,
                roundType: 'off',
                separation: true,
              }) + '%'
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(valueList[i].inheritanceTaxBase.No20, {
                floatLength: 1,
                roundType: 'off',
                separation: true,
              }) + '%'
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(
                valueList[i].inheritanceTaxBase.No8 !== 0
                  ? (valueList[i].prevailingPriceBase.No8 / valueList[i].inheritanceTaxBase.No8) *
                      100
                  : '',
                {
                  floatLength: 1,
                  roundType: 'off',
                  separation: true,
                }
              ) + '%'
            }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <div class="mt-4">
      <h4>実勢価格ベース</h4>
    </div>
    <div style="text-align: right">
      <span>単位: 万円</span>
    </div>
    <b-table-simple responsive>
      <b-thead head-variant="">
        <b-tr>
          <b-th class="title" colspan="1"></b-th>
          <b-th class="title" colspan="5">貸借対照表(B/S)</b-th>
          <b-th class="title" colspan="8">損益計算(P/L)</b-th>
          <b-th class="title" colspan="2">キャッシュフロー</b-th>
          <b-th class="title" colspan="2">健全性</b-th>
          <b-th class="title" colspan="1"></b-th>
        </b-tr>
        <b-tr>
          <b-th class="title"></b-th>
          <b-th class="title">①</b-th>
          <b-th class="title">②</b-th>
          <b-th class="title">③</b-th>
          <b-th class="title">④</b-th>
          <b-th class="title">⑤</b-th>
          <b-th class="title">⑥</b-th>
          <b-th class="title">⑦</b-th>
          <b-th class="title">⑧</b-th>
          <b-th class="title">⑨</b-th>
          <b-th class="title">⑩</b-th>
          <b-th class="title">⑪</b-th>
          <b-th class="title">⑫</b-th>
          <b-th class="title">⑬</b-th>
          <b-th class="title">⑭</b-th>
          <b-th class="title">⑮</b-th>
          <b-th class="title">⑯</b-th>
          <b-th class="title">⑰</b-th>
          <b-th class="title">⑱</b-th>
        </b-tr>
        <b-tr>
          <b-th class="title" style="width: 50px">資産<br />番号</b-th>
          <b-th class="title" style="width: 80px">土地価格</b-th>
          <b-th class="title" style="width: 80px">建物価格</b-th>
          <b-th class="title" style="width: 80px"
            >合計<br />
            ①+②</b-th
          >
          <b-th class="title" style="width: 80px">借入金</b-th>
          <b-th class="title" style="width: 80px">純資産<br />③-④</b-th>
          <b-th class="title" style="width: 80px">収入</b-th>
          <b-th class="title" style="width: 80px">土地<br />(固定資産税)</b-th>
          <b-th class="title" style="width: 80px">土地<br />(都市計画税)</b-th>
          <b-th class="title" style="width: 80px">建物<br />(固定資産税)</b-th>
          <b-th class="title" style="width: 80px">建物<br />(都市計画税)</b-th>
          <b-th class="title" style="width: 80px">管理費</b-th>
          <b-th class="title" style="width: 80px">合計<br />⑦~⑪</b-th>
          <b-th class="title" style="width: 80px">年間収支<br />⑥-⑫</b-th>
          <b-th class="title" style="width: 80px">借入金返済額</b-th>
          <b-th class="title" style="width: 80px">税前<br />キャッシュ<br />フロー<br />⑬-⑭</b-th>
          <b-th class="title" style="width: 80px">利回り(%)<br />⑬÷③</b-th>
          <b-th class="title" style="width: 80px">借入金比率(%)<br />④÷③</b-th>
          <b-th class="title" style="width: 80px">資産効率(%)</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(input, i) in inputList.length === valueList.length ? inputList : []" :key="i">
          <b-td class="number-cell">{{ i + 1 }}</b-td>
          <b-td class="number-cell">
            {{
              formatNum(input.prevailingPriceBase.prevailingPrice.land / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(input.prevailingPriceBase.prevailingPrice.building / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(valueList[i].prevailingPriceBase.No8 / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(input.inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(valueList[i].prevailingPriceBase.No10 / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <!-- 収入 -->
          <b-td class="number-cell">
            {{
              formatNum(input.inheritanceTaxBase.assetsRealEstate.pl.income / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <!-- 土地 固定資産税 -->
          <b-td class="number-cell">
            {{
              formatNum(
                input.inheritanceTaxBase.assetsRealEstate.pl.spending.landPropertyTax / 10000,
                {
                  floatLength: 0,
                  roundType: 'off',
                  separation: true,
                }
              )
            }}
          </b-td>
          <!-- 土地 都市計画税 -->
          <b-td class="number-cell">
            {{
              formatNum(
                input.inheritanceTaxBase.assetsRealEstate.pl.spending.landCityPlanningTax / 10000,
                {
                  floatLength: 0,
                  roundType: 'off',
                  separation: true,
                }
              )
            }}
          </b-td>
          <!-- 建物 固定資産税 -->
          <b-td class="number-cell">
            {{
              formatNum(
                input.inheritanceTaxBase.assetsRealEstate.pl.spending.buildingPropertyTax / 10000,
                {
                  floatLength: 0,
                  roundType: 'off',
                  separation: true,
                }
              )
            }}
          </b-td>
          <!-- 建物 都市計画税 -->
          <b-td class="number-cell">
            {{
              formatNum(
                input.inheritanceTaxBase.assetsRealEstate.pl.spending.buildingCityPlanningTax /
                  10000,
                {
                  floatLength: 0,
                  roundType: 'off',
                  separation: true,
                }
              )
            }}
          </b-td>
          <!-- 管理費 -->
          <b-td class="number-cell">
            {{
              formatNum(
                input.inheritanceTaxBase.assetsRealEstate.pl.spending.maintenanceFee / 10000,
                {
                  floatLength: 0,
                  roundType: 'off',
                  separation: true,
                }
              )
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(valueList[i].inheritanceTaxBase.No15 / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(valueList[i].inheritanceTaxBase.No16 / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <!-- 借入金返済額 -->
          <b-td class="number-cell">
            {{
              formatNum(
                input.inheritanceTaxBase.assetsRealEstate.cashflowCalc.outstandingDebtTotal / 10000,
                {
                  floatLength: 0,
                  roundType: 'off',
                  separation: true,
                }
              )
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(valueList[i].inheritanceTaxBase.No18 / 10000, {
                floatLength: 0,
                roundType: 'off',
                separation: true,
              })
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(valueList[i].prevailingPriceBase.No19, {
                floatLength: 1,
                roundType: 'off',
                separation: true,
              }) + '%'
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(valueList[i].prevailingPriceBase.No20, {
                floatLength: 1,
                roundType: 'off',
                separation: true,
              }) + '%'
            }}
          </b-td>
          <b-td class="number-cell">
            {{
              formatNum(
                valueList[i].inheritanceTaxBase.No8 !== 0
                  ? (valueList[i].prevailingPriceBase.No8 / valueList[i].inheritanceTaxBase.No8) *
                      100
                  : '',
                {
                  floatLength: 1,
                  roundType: 'off',
                  separation: true,
                }
              ) + '%'
            }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <div class="mt-4"><h4>物件情報</h4></div>
    <b-table-simple responsive class="mt-3">
      <b-thead>
        <b-tr>
          <b-th class="title" style="width: 50px">資産<br />番号</b-th>
          <b-th class="title" style="width: 300px">所在地</b-th>
          <b-th class="title" style="width: 200px">名称</b-th>
          <b-th class="title" style="width: 80px">利用状況</b-th>
          <b-th class="title" style="width: 80px">土地面積(㎡)</b-th>
          <b-th class="title" style="width: 80px">建物面積(㎡)</b-th>
          <b-th class="title" style="width: 300px">備考</b-th>
          <b-th class="title" style="width: 110px"></b-th>
          <b-th class="title" style="width: 110px"></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(input, i) in inputList.length === valueList.length ? inputList : []" :key="i">
          <b-td class="number-cell">{{ i + 1 }}</b-td>
          <b-td class="number-cell" style="text-align: left" v-html="locationHTMLList[i]"> </b-td>
          <b-td class="number-cell" style="text-align: left">
            {{ input.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.name }}
          </b-td>
          <b-td class="number-cell" style="text-align: left">
            {{ input.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.usageSituation }}
          </b-td>
          <b-td class="number-cell">
            {{ input.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.landSize.toFixed(2) }}
          </b-td>
          <b-td class="number-cell">
            {{ input.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.buildingSize.toFixed(2) }}
          </b-td>
          <b-td class="number-cell" style="text-align: left">
            {{ input.comment }}
          </b-td>
          <b-td class="btn-cell">
            <b-btn @click="$emit('click-edit', i)" class="ally btn-ally">編集</b-btn>
          </b-td>
          <b-td class="btn-cell">
            <b-btn @click="$emit('click-delete', i)" class="ally btn-ally">削除</b-btn>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
  import sanitizeHTML from 'sanitize-html';
  export default {
    props: {
      inputList: {
        type: Array,
        required: true,
      },
      valueList: {
        type: Array,
        required: true,
      },
      centerValue: {
        type: Number,
        required: true,
      },
      warekiFlg: {
        type: Boolean,
        required: true,
      },

    },
    data(){
      return{
        selectedPrice: '相続税評価ベース',
      }
    },
    methods: {
      // グラフの横軸
      xResult(input) {
        return this.inheritanceTaxBaseNo8(input) == 0
          ? 0
          : this.prevailingPriceBaseNo8(input) / this.inheritanceTaxBaseNo8(input) > 1
          ? (1 + this.prevailingPriceBaseNo8(input) / this.inheritanceTaxBaseNo8(input) / 5) * 100
          : (this.prevailingPriceBaseNo8(input) / this.inheritanceTaxBaseNo8(input)) * 100;
      },
      // グラフの縦軸
      yResult(input) {
        const roa = this.roa(input);
        //利回り（元のROA）(%)-割引率（元の期待利回り）を引いた値
        let discountRoa = roa - this.centerValue;
        let yResult = 0;
        //利回り（元のROA）(%)-割引率（元の期待利回り）を引いた値+20以上、-20以下の場合はグラフの最大値、最小値に固定する
        if (discountRoa > 20) yResult = 200;
        else if (discountRoa < -20) yResult = -200;
        else yResult = discountRoa * 10;
        return yResult;
      },
      roa(input) {
        return this.inheritanceTaxBaseNo8(input) == 0 // || this.centerValue == 0
          ? 0
          : (this.inheritanceTaxBaseNo16(input) /
              this.inheritanceTaxBaseNo8(input) ) * 
              100;
      },
      showChart: async function() {
        const isValid = await this.$refs.ROATable.validate();
        if (!isValid) {
          this.$bvModal.show('E108');
          return;
        }
        this.isChart = !this.isChart;
      },
      // 相続税評価ベースでの合計価格
      inheritanceTaxBaseNo8: function(input) {
        var result =
          parseFloat(input.inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice.land) +
          parseFloat(input.inheritanceTaxBase.assetsRealEstate.bs.inheritanceBasePrice.building);

        return !isNaN(result) ? result : 0;
      },
      inheritanceTaxBaseNo10: function(input) {
        var result =
          this.inheritanceTaxBaseNo8(input) -
          parseFloat(input.inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt);

        return !isNaN(result) ? result : 0;
      },

      inheritanceTaxBaseNo15: function(input) {
        var result =
          parseFloat(input.inheritanceTaxBase.assetsRealEstate.pl.spending.landPropertyTax) +
          parseFloat(input.inheritanceTaxBase.assetsRealEstate.pl.spending.landCityPlanningTax) +
          parseFloat(input.inheritanceTaxBase.assetsRealEstate.pl.spending.buildingPropertyTax) +
          parseFloat(
            input.inheritanceTaxBase.assetsRealEstate.pl.spending.buildingCityPlanningTax
          ) +
          parseFloat(input.inheritanceTaxBase.assetsRealEstate.pl.spending.maintenanceFee);

        return !isNaN(result) ? result : 0;
      },

      inheritanceTaxBaseNo16: function(input) {
        var result =
          parseFloat(input.inheritanceTaxBase.assetsRealEstate.pl.income) -
          this.inheritanceTaxBaseNo15(input);

        return !isNaN(result) ? result : 0;
      },

      inheritanceTaxBaseNo18: function(input) {
        var result =
          this.inheritanceTaxBaseNo16(input) -
          parseFloat(input.inheritanceTaxBase.assetsRealEstate.cashflowCalc.outstandingDebtTotal);

        return !isNaN(result) ? result : 0;
      },

      inheritanceTaxBaseNo19: function(input) {
        var result =
          this.inheritanceTaxBaseNo8(input) == 0
            ? 0
            : Number(
                (
                  Math.round(
                    (this.inheritanceTaxBaseNo16(input) / this.inheritanceTaxBaseNo8(input)) * 10000
                  ) / 100
                ).toFixed(2)
              );

        return !isNaN(result) ? result : 0;
        // if (isNaN(result)) return 0;
        // if (result < -200) return -200;
        // return result;
      },

      inheritanceTaxBaseNo20: function(input) {
        var result =
          Math.round(
            (parseFloat(input.inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt) /
              this.inheritanceTaxBaseNo8(input)) *
              10000
          ) / 100;

        return !isNaN(result) ? result : 0;
      },

      prevailingPriceBaseNo8: function(input) {
        var result =
          input.prevailingPriceBase.prevailingPrice.land +
          input.prevailingPriceBase.prevailingPrice.building;

        return !isNaN(result) ? result : 0;
      },

      prevailingPriceBaseNo10: function(input) {
        var result =
          this.prevailingPriceBaseNo8(input) -
          input.inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt;

        return !isNaN(result) ? result : 0;
      },

      prevailingPriceBaseNo19: function(input) {
        var result =
          this.prevailingPriceBaseNo8(input) == 0
            ? 0
            : Number(
                (
                  Math.round(
                    (this.inheritanceTaxBaseNo16(input) / this.prevailingPriceBaseNo8(input)) *
                      10000
                  ) / 100
                ).toFixed(2)
              );

        return !isNaN(result) ? result : 0;
        // if (isNaN(result)) return 0;
        // if (result < -200) return -200;
        // return result;
      },

      prevailingPriceBaseNo20: function(input) {
        var result =
          Math.round(
            (input.inheritanceTaxBase.assetsRealEstate.bs.ourstandingDebt /
              this.prevailingPriceBaseNo8(input)) *
              10000
          ) / 100;

        return !isNaN(result) && isFinite(result) ? result : 0;
      },
      wareki(inputYear) {
        if (this.warekiFlg === true) {
          const warekiObject = this.warekiChange(inputYear);
          if (warekiObject == null) {
            return '';
          }
          return warekiObject.name + warekiObject.year + '年';
        } else {
          return inputYear;
        }
      },
      //プルダウンメニューで選択した文言を取得
      selected(){
        this.valueList[0].baseSelected=this.selectedPrice;
      }
    },
    computed: {
      valueListComputed() {
        if (this.inputList.length === 0) {
          return [];
        }

        return this.inputList.map(input => {
          return {
            chartValue: {
              x: this.xResult(input),
              y: this.yResult(input),
              //discountRate: this.centerValue, 割引率をグラフに表示時用
            },
            inheritanceTaxBase: {
              No8: this.inheritanceTaxBaseNo8(input),
              No10: this.inheritanceTaxBaseNo10(input),
              No15: this.inheritanceTaxBaseNo15(input),
              No16: this.inheritanceTaxBaseNo16(input),
              No18: this.inheritanceTaxBaseNo18(input),
              No19: this.inheritanceTaxBaseNo19(input),
              No20: this.inheritanceTaxBaseNo20(input),
            },

            prevailingPriceBase: {
              No8: this.prevailingPriceBaseNo8(input),
              No10: this.prevailingPriceBaseNo10(input),
              No19: this.prevailingPriceBaseNo19(input),
              No20: this.prevailingPriceBaseNo20(input),
            },
            baseSelected:this.selectedPrice //プルダウンメニューで選択した文言
          };
        });
      },
      // 所在地
      locationHTMLList() {
        if (this.inputList == null) {
          return [];
        }
        return this.inputList.map(input => {
          const html = input.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.address.replaceAll(
            '\n',
            '<br />'
          );
          // htmlサニタイズ
          return sanitizeHTML(
            html + '<br/>' + input.inheritanceTaxBase.assetsRealEstate.salseObjectPoint.lotNum
          );
        });
      },
    },
    filters: {
      manyen(value) {
        const floor = Math.floor(value);
        const str = floor.toString();
        let result = '';

        if (Number.isSafeInteger(floor) === false) {
          return '';
        }

        for (let i = 0; i < str.length; i++) {
          if (i % 3 === 0 && i !== 0) {
            result += ',';
          }
          result += str.charAt(i);
        }

        return result;
      },
    },
    watch: {
      inputList: {
        handler() {
          this.$emit('update:inputList', this.inputList);
        },
        deep: true,
      },
      valueListComputed: {
        handler() {
          this.$emit('update:valueList', this.valueListComputed);
        },
        deep: true,
      },
      selectedPrice:{
        handler(){
            this.selected();
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
<style scoped>
  .title {
    padding: 7px 0;
    font-size: 0.6rem;
    color: white;
    background-color: rgba(var(--colorThemeRGB), 1);
    text-shadow: 1px 1px 1px #000000, -1px 1px 1px #000000, 1px -1px 1px #000000,
      -1px -1px 1px #000000, 1px 0px 1px #000000, 0px 1px 1px #000000, -1px 0px 1px #000000,
      0px -1px 1px #000000;
    border-right: solid 1px black;
    text-align: center;
    align-items: center;
  }
  .cell {
    padding: 0;
  }
  .number-cell {
    padding: 3px 3px;
    text-align: right;
    border: gray solid 1px;
  }
  .btn-cell {
    padding: 3px;
    text-align: center;
    border: gray solid 1px;
  }
</style>
