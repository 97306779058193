//がけ地の補正率の計算
//import { API, graphqlOperation } from 'aws-amplify';
//import { getSystemMaster } from '@/graphql/queries';
const slantedLandCalc = async function(routePriceResult, input, master) {
  try {
    //奥行価格補正率表マスタロード

    var slantedLandMaster = master.interitanceMaster8.slice(1);

    //格納変数
    var resultPrice = null;

    //x偏判定地の計算
    var x = 0;
    x = parseFloat((input.slantedLandSize / input.landSize).toFixed(2));

    if (x >= slantedLandMaster[0][0]) {
      //がけ地の補正率の計算
      for (var i = slantedLandMaster.length - 1; i >= 0; i--) {
        //がけ地補正方向、判定地xで補正率を算出
        if (x >= slantedLandMaster[i][0]) {
          resultPrice = Math.floor(
            slantedLandMaster[i][input.slantedLandDirection] * routePriceResult.resultPrice
          );
          break;
        }
      }
    } else {
      resultPrice = routePriceResult.resultPrice;
    }

    //リターン値の格納
    var result = {
      text: 'がけ地の計算',
      resultPrice: resultPrice,
      resultPriceType: 'I',
    };

    console.log('がけ地計算完了 : ', result);

    return result;
  } catch (e) {
    console.log('Error : ', e);
    throw new Error(e);
  }
};

export { slantedLandCalc };
