var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"SitePreparation"}},[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("造成工事")]),_c('b-col'),_c('b-col',{staticClass:"component"},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
              _vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0].amount
            ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0], "amount", $$v)},expression:"\n              investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0].amount\n            "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"2"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title",attrs:{"cols":"1"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabInitialInvestment54",modifiers:{"tabInitialInvestment54":true}}],attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabInitialInvestment54","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',{staticClass:"detail"},[_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v("工事内容")]),_c('b-col',{staticClass:"ally-select",attrs:{"cols":"3"}},[_c('allySelect',{attrs:{"name":"sitePreparation","items":_vm.sitePreparationItems},model:{value:(
                    _vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0]
                      .content
                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0]
                      , "content", $$v)},expression:"\n                    investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0]\n                      .content\n                  "}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v("造成面積")]),_c('b-col',{staticClass:"component",attrs:{"cols":"3"}},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"floatLength":2,"roundType":"down"},model:{value:(
                    _vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0]
                      .quantity
                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0]
                      , "quantity", $$v)},expression:"\n                    investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0]\n                      .quantity\n                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("㎡")]),_c('b-col',{staticClass:"title-right",staticStyle:{"white-space":"nowrap"},attrs:{"cols":"1"}},[_vm._v("※地積")]),_c('b-col',{staticClass:"component",attrs:{"cols":"3"}},[_c('allyText',{staticClass:"number",attrs:{"type":"number","mode":"View","separation":true,"floatLength":2,"roundType":"down"},model:{value:(_vm.investmentAnalytics.initialInvestment.propertyDescription[0].area),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.propertyDescription[0], "area", $$v)},expression:"investmentAnalytics.initialInvestment.propertyDescription[0].area"}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("㎡")])],1),_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v("単価")]),_c('b-col',{staticClass:"component",attrs:{"cols":"3"}},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice(
                      '造成工事',
                      _vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0]
                        .content
                    ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円/㎡")])],1),_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v("工事費")]),_c('b-col',{staticClass:"component",attrs:{"cols":"3"}},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                    _vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0]
                      .amount
                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0]
                      , "amount", $$v)},expression:"\n                    investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0]\n                      .amount\n                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")]),_c('b-col',{staticClass:"title-right",staticStyle:{"white-space":"nowrap"},attrs:{"cols":"1"}},[_vm._v("※目安")]),_c('b-col',{staticClass:"component",attrs:{"cols":"3"}},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(((Math.floor(
                      _vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0]
                        .quantity * 100
                    ) /
                      100) *
                      _vm.getUnitPrice(
                        '造成工事',
                        _vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0]
                          .content
                      )))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")]),_c('button',{staticClass:"btn btn-ally mr-1 cols='1'",staticStyle:{"width":"50px","white-space":"nowrap","padding-left":"10px"},on:{"click":function($event){_vm.changeGuideline(
                    _vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0]
                      .quantity *
                      _vm.getUnitPrice(
                        '造成工事',
                        _vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[0]
                          .content
                      )
                  )}}},[_vm._v(" 反映 ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }