<template>
  <div class="azimuth-btn" v-if="isEditor">
    <TileBtn
      class="editor-btn"
      borderRadius="100%"
      :width="btnSize"
      :height="btnSize"
      :style="{transform:'rotate('+azimuthValue+'deg)'}"
      @click="setAzimuth"
      v-b-popover.hover.bottom="'方位角'"
    >
    <div class="north-text">N</div>
    <i class="icon-azimuth" />
  </TileBtn>
  <AzimuthInput
    :azimuthInput="azimuthValue"
    :isEditor="isEditor"
    :isAzimuthInput="isAzimuthInput"
    @change-azimuth="val => {
      $emit('change-azimuth', val);
    }"
  />
  </div>
</template>
<script>
  import { ref, defineComponent } from '@vue/composition-api';
  import TileBtn from './PvBtn.vue';
  import AzimuthInput from './AzimuthInput.vue';

  export default defineComponent({
    props: {
      azimuthValue: {
        type: Number,
        required: true,
      },
      isEditor: {  //  PROP: 編集権限の有無
        type: Boolean,
        default: true,
      }
    },
    components: {
      TileBtn,
      AzimuthInput,
    },
    methods: {
      setAzimuth() {
        this.isAzimuthInput = !this.isAzimuthInput;
      },
    },
    setup() {
      const btnSize = ref('40px');
      const isAzimuthInput = ref(false);
      return {
        btnSize,
        isAzimuthInput,
      };
    },
  });
</script>
<style scoped>
  @keyframes fadeInAnime {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  .azimuth-btn {
    display: flex;
    position: absolute;
    top: 50px;
    right: 30px;
    animation-name: fadeInAnime;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }
  .editor-btn {
    margin-left: 5px;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }
  [class^='icon-'],
  [class*=' icon-'] {
    font-size: 1.2rem;
    margin-left: -1px;
  }
  .north-text {
    font-size: 0.2em;
  }
</style>
