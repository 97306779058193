// @ts-check

import { Action } from '../action';
import { ActionBuilder } from '../../action/builder/actionBuilder';

export class PanActionBuilder extends ActionBuilder {
  build() {
    const action = new Action();
    action.actionType = Action.ACTION_TYPE.DRAG_AND_DROP;

    const { getCurrentPos } = this.context;

    action.onUpdate = ({ first }) => {
      const dist = getCurrentPos().sub(first);
      this.context.moveEditor(dist.x, dist.y);
    };

    action.onComplete = ({ dist }) => {
      this.context.moveEditor(dist.x, dist.y);
    };

    action.onAbort = ({ dist }) => {
      this.context.moveEditor(dist.x, dist.y);
    };

    return action;
  }
}
