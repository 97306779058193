var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('g',{staticClass:"svgselect-root",attrs:{"transform":("translate(" + (-_vm.width / 2) + ")")},on:{"mouseover":_vm.mouseover,"mouseleave":_vm.mouseleave}},[_c('rect',{attrs:{"width":_vm.width,"height":_vm.height,"fill":"white","x":-_vm.width / 2,"y":-_vm.height / 2,"stroke":_vm.outlineColor,"stroke-width":"4","rx":10,"ry":10}}),_c('text',{style:({
      'font-size': _vm.fontSize + 'px',
    }),attrs:{"stroke":"black","fill":"black","text-anchor":"middle","dy":_vm.fontSize / 2}},[_vm._v(_vm._s(_vm.value.label))]),_c('g',{class:{
      option: true,
      hidden: _vm.hiddenOptions,
    }},[_c('rect',{attrs:{"width":_vm.width,"height":_vm.height * _vm.options.length,"fill":"white","x":-_vm.width / 2,"y":-_vm.height / 2 + _vm.height,"stroke":_vm.outlineColor,"stroke-width":"4","rx":10,"ry":10}}),_vm._l((_vm.options),function(o,i){return _c('g',{key:i,attrs:{"transform":("translate(0, " + (_vm.fontSize / 2 + _vm.height * (i + 1) - 4) + ")")},on:{"click":function($event){return _vm.clickOption(o)}}},[_c('rect',{staticClass:"option-tile",attrs:{"width":_vm.width - 8,"height":_vm.height - 8,"fill":"white","x":-_vm.width / 2 + 4,"y":(-_vm.height * 3) / 4 + 4}}),_c('text',{style:({
          'font-size': _vm.fontSize + 'px',
          'pointer-events': 'none',
        }),attrs:{"stroke":"black","fill":"black","text-anchor":"middle"}},[_vm._v(_vm._s(o.label))])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }