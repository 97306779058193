// @ts-check

import { API, graphqlOperation } from 'aws-amplify';
import lzString from 'lz-string';
import Canvg from 'canvg';
import { convertReport } from '../../../../graphql/mutations';
import { LAYER_HEIGHT, LAYER_WIDTH } from '../util/const';

const EDITOR_WIDTH = window.innerWidth;
const EDITOR_HEIGHT = window.innerHeight - 60;
const IMAGE_SCALE = 1;
const IMAGE_WIDTH = Math.round(EDITOR_WIDTH * IMAGE_SCALE);
const IMAGE_HEIGHT = Math.round(EDITOR_HEIGHT * IMAGE_SCALE);

/**
 * @typedef {{
 * offsetX: number
 * offsetY: number
 * scaleWidth: number
 * scaleHeight: number
 * }} ImageOption
 */

export class ImageFetcher {
  /**
   *
   * @param {SVGElement} targetElement
   * @param {ImageOption} option
   */
  async generateImage(targetElement, option) {
    // ローカルの方を使う;
    if (window) {
      const repo = new ImageFetcher();
      const buf = await repo.generateImageLocal(targetElement, option);
      return buf;
    }
    const base = /** @type {SVGSVGElement} */ (targetElement.cloneNode());
    base.setAttribute('width', LAYER_WIDTH + '');
    base.setAttribute('height', LAYER_HEIGHT + '');
    base.setAttribute('viewBox', `0,0,${LAYER_WIDTH},${LAYER_HEIGHT}`);
    // for (const el of targetElement.children) {
    //   base.innerHTML += el.outerHTML;
    // }
    // base.firstElementChild?.setAttribute('tranform', '');
    const result = await API.graphql(
      graphqlOperation(convertReport, {
        input: {
          htmlStringList: [targetElement.outerHTML],
          screenshot: true,
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
        },
      })
    );

    // @ts-ignore
    const base64 = lzString.decompressFromEncodedURIComponent(result.data.convertReport);
    // @ts-ignore
    const file = base64.replace(/^data:\w+\/\w+;base64,/, '');
    const decodedFile = new Buffer(file, 'base64');

    return decodedFile;
  }

  /**
   * ローカルで画像を出力する
   * @param {SVGElement} targetElement
   * @param {ImageOption} option
   */
  async generateImageLocal(targetElement, option) {
    const base = /** @type {SVGSVGElement} */ (targetElement.cloneNode(true));
    base.setAttribute('width', LAYER_WIDTH + '');
    base.setAttribute('height', LAYER_HEIGHT + '');
    base.setAttribute(
      'viewBox',
      `${-option.offsetX},${-option.offsetY},${option.scaleWidth},${option.scaleHeight}`
    );

    // for (let i = 0; i < base.childElementCount; i++) {
    //   base.children.item(i)?.removeAttribute('transform');
    // }

    const svgString = base.outerHTML.replace(/NS[0-9]+:href/g, 'xlink:href');
    const canvas = document.createElement('canvas');
    canvas.width = IMAGE_WIDTH; // canvasの幅を指定
    canvas.height = IMAGE_HEIGHT; // canvasの高さを指定
    const ctx = canvas.getContext('2d');
    // @ts-ignore
    const v = Canvg.fromString(ctx, svgString, {
      anonymousCrossOrigin: true,
    });
    await v.render(); // Promise

    const dataUrl = canvas.toDataURL('image/' + 'png');

    const base64 = dataUrl;
    // @ts-ignore
    const file = base64.replace(/^data:\w+\/\w+;base64,/, '');

    return new Buffer(file, 'base64');
  }
}
