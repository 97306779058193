<template>
  <div>
    <div>決済情報</div>
    <div>決済方法 : 
      <span v-if="companyInfo.payType == 0">クレジットカード</span>
      <span v-else-if="companyInfo.payType == 36">あおぞら銀行</span>
      <span v-else-if="companyInfo.payType == 998">口座振替</span>
      <span v-else>未登録</span>
    </div>
    <div>
      決済変更予定:
      <span v-if="companyInfo.paymentChangeReservation == null">変更予定なし</span>
      <span v-else-if="companyInfo.paymentChangeReservation.payType == 998">
         <span class="text-red">{{companyInfo.paymentChangeReservation.changeDate}}-07</span>から口座振替へ変更予定（{{companyInfo.paymentChangeReservation.reservationDate}}申請）
      </span>
      <span v-else class="text-red">※決済方法の確認が必要です。25日までにシステム管理者へお問合せください。</span>
    </div>
    <LicenseDetailModal ref="LicenseDetailModal" :isModal="false" @createDisp="createDisp" />
    <b-button class="mt-2 btn btn-ally float-right" v-if="updatePaymentList" @click="clickSettlement()">過去の決済情報を見る</b-button>
    <LicenseDetailModal2 ref="LicenseDetailModal2" :isModal="true"/>
  </div>
</template>

<script>
  var moment = require('moment-timezone');
  moment.tz.setDefault('Asia/Tokyo');
  import Common from './Common';
  import LicenseDetailModal from '../SalesList/sub/LicenseDetailModal';
  import LicenseDetailModal2 from '../SalesList/sub/LicenseDetailModal';

  export default {
    props: {
      companyInfo:{
        type:Object,
        required: true,
      },
    },
    mixins:[Common],
    components: {LicenseDetailModal,LicenseDetailModal2},
    data: function() {
      return {
        paymentList: [], // 決済リスト
        aozoraPayment: null,
        updateInfo: false,
        callShowDisp: false,
        oldPayment: [],
      };
    },
    computed:{
      updatePaymentList() {
        // 過去の決済情報ボタンの表示判定
        return this.oldPayment.length > 0;
      },
    },
    watch: {
      //compnayInfoが更新されたとき、処理を行う
      companyInfo: function(n,o) {
          this.updateInfo = true;
        if(this.callShowDisp) {
          // createDispが呼ばれていたんだけど、companyInfoがまだ更新されていなかった場合再呼び出し
          this.createDisp();
        }
      },
    },
    mounted() {
    },
    methods: {
      callAsync: async function(item) {
        let aozoraData = await this.getAozoraPaymentData(item);
        return aozoraData;
      },
      // 決済情報表示に必要なデータを設定する
      setPaymentItem: async function(item) {
        try {
          const result = await this.callAsync(item);
          this.aozoraPayment = result;
          let paymentList = [];
          let company = item;
          if (company.license != null) {
            for (let payment of company.payment) {
              //配列化する
              paymentList.push({
                adjustmentDifference: this.getAdjustmentDifference(payment, company),
                billingAmount: this.getBillingAmount(payment, company),
                depositAmount: this.getDepositAmount(payment, company),
                depositDifference:
                  parseInt(this.getDepositAmount(payment, company)) -
                  parseInt(this.getBillingAmount(payment, company)),
                paymentDay: this.getPaymentDay(payment, company),
                paymentMethod: payment.payType,
                salesAmount: parseInt(payment.amount) + parseInt(payment.tax),
                salesConfirmationDate: moment(payment.billingDate).format('YYYY-MM-01'),
                settlementDate: payment.billingDate,
                paymentId: payment.id,
              });
            }
          }

          // モーダル表示時は最新データから複数月の情報を出すため、降順ソートした結果を返す
          this.paymentList = paymentList.sort(function(i, j) {
            return (i.paymentId < j.paymentId) ? 1 : -1 
          });

        } catch(e) {
          console.error(e);
        }
      },
      // 会社詳細情報画面に出すパーツの情報をコンポーネントへ渡す
      createDisp: async function() {
        // companyInfoが更新され、且つコンポーネント側のマスタ情報がそろっているかチェック
        if(!this.updateInfo) {
          // コンポーネントの準備は整っているが、こちらのCompanyInfoが未更新
          this.callShowDisp = true;
          return;
        }
        // コンポーネントに渡す情報を構築
        await this.setPaymentItem(this.companyInfo);
        let item = [];
        // 今月決済のデータのみを使用するためデータ抽出を行う
        const currentDate = moment().startOf('month').format('YYYY-MM-01');
        item = this.paymentList.filter(li =>  li.salesConfirmationDate === currentDate);
        // 今月のデータがあるかチェック
        if(item.length > 0) {
          // 今月の情報OK
          this.$refs.LicenseDetailModal.showDisplay(item[0], this.companyInfo, false);
        } else {
          // 今月の情報がなかった
          this.$refs.LicenseDetailModal.showDisplay(null, this.companyInfo, true);
        }
        this.oldPayment = this.paymentList.filter(li => 
          new Date(li.salesConfirmationDate).getTime() < new Date(currentDate).getTime()
        );
      },
      // 過去の決済情報をみるボタンが押された
      clickSettlement: function() {
        // 過去の情報がなかった場合はボタンごと表示されないので、値のチェックは行わない
        this.setPaymentItem(this.companyInfo);
        const currentDate = new Date(moment().startOf('month').format('YYYY-MM-01'));
        let item;
        // 先月以前の情報のみを渡す
        item = this.paymentList.filter(li => 
          new Date(li.salesConfirmationDate).getTime() < currentDate.getTime()
        );
        this.$refs.LicenseDetailModal2.showModal(item, this.companyInfo, true);
      },

      /**
       *  METHOD: 入金日
       */
      getPaymentDay: function(payment, company) {
        // 決済が完了されている場合
        if (payment.paymentResult == true) {
          // あおぞら銀行の場合
          if (payment.payType == this.getPaymentType('BANK_TRANSFER').no) {
            let pay = this.aozoraPayment.find(val => val.id == company.id);
            if (pay != null) {
              let date = '2000-01-01';
              //最新の日付を取得
              for (let p of pay.pay) {
                let d = p[0].substr(0, 4) + '-' + p[0].substr(4, 2) + '-' + p[0].substr(6, 2);
                if (moment(date).isBefore(d)) {
                  if (moment(payment.billingDate).isAfter(d)) {
                    date = d;
                  } else {
                    //未納の時、翌月分を記入する
                    if (payment?.depositRefundInfo?.status == '1') {
                      date = d;
                    }
                  }
                }
              }
              if (payment.paymentResult == true) {
                if (payment?.depositRefundInfo?.status == '3') {
                  return moment(payment?.depositRefundInfo?.adjustmentDate).format('YYYY-MM-DD');
                }
              }
              if (date == '2000-01-01') return null;
              return date;
            }
            return moment(payment.billingDate).format('YYYY-MM-DD');
          }
          // それ以外の場合
          else {
            //未納ステータスの場合、
            if (payment?.depositRefundInfo?.status == '1') {
              //未納が残っているとき
              let paymentId = ('000' + payment.id).slice(-4);
              var year = paymentId.slice(0, 2);
              var month = paymentId.slice(-2);
              var oneMonthAgoPaymentId = moment('20' + year + '-' + month + '-01')
                .add(1, 'months')
                .format('YYMM');
              let oneMonthAgoPayment = company.payment.find(
                val => val.id == parseInt(oneMonthAgoPaymentId)
              );
              if (oneMonthAgoPayment?.paymentResult == true) {
                return moment(oneMonthAgoPayment.billingDate).format('YYYY-MM-DD');
              }
            } else if (payment?.depositRefundInfo?.status == '3') {
              //入金対応を行った場合 日付を対応日に変更
              return moment(payment?.depositRefundInfo?.adjustmentDate).format('YYYY-MM-DD');
            } else {
              return moment(payment.billingDate).format('YYYY-MM-DD');
            }
          }
        }
      },
      /**
       *  METHOD: 調整差額
       */
      getAdjustmentDifference: function(payment, company) {
        //console.log('あおぞら', this.aozoraPayment);

        let num = 0;
        if (payment?.depositRefundInfo?.amount == null) return 0;

        switch (payment?.depositRefundInfo?.status) {
          //返金対応、清算済みの場合はマイナス表示する
          case '4':
          case '6':
            num = -parseInt(payment?.depositRefundInfo?.amount);
            break;

          //入金対応
          case '3':
            num = parseInt(payment?.depositRefundInfo?.amount) - (payment.amount + payment.tax);
            break;

          //その他の対応
          default:
            num = parseInt(payment?.depositRefundInfo?.amount);
            break;
        }

        return num;
      },

      /**
       *  METHOD: 請求額
       */
      getBillingAmount: function(payment, company) {
        let sum = 0;

        if (payment.payType != this.getPaymentType('BANK_TRANSFER').no) {
          //先月の決済情報を取得
          const lastMonthPaymentId = this.createPaymentId(payment.id, -1);

          //先月の請求情報が未納の場合、請求額を加算する
          let pay = company.payment.find(pay => pay.id == lastMonthPaymentId);
          if (pay?.depositRefundInfo != null) {
            if (pay.depositRefundInfo.status == 1) {
              sum += parseInt(pay.amount) + parseInt(pay.tax);
            }
          }

          sum += parseInt(payment.amount) + parseInt(payment.tax);
        }
        //あおぞらの場合
        else {
          // あおぞらの前月までの振込総額
          let aozoraTotal = this.aozoraAllTotal(company, payment);

          // 当月までの請求総額
          let claimTotal = 0;
          let companyPayment = company.payment.filter(pay => {
            return pay.id <= payment.id && pay.payType == this.getPaymentType('BANK_TRANSFER').no;
          });
          for (let payment of companyPayment) {
            claimTotal += parseInt(payment.amount) + parseInt(payment.tax);
          }

          //入金返金対応分を調整する
          let adjustmentTotal = 0;
          for (let pay of companyPayment) {
            if (pay.id != payment.id) {
              //入金対応
              if (pay?.depositRefundInfo?.status == '3') {
                adjustmentTotal -= pay.depositRefundInfo.amount;
              }
              //返金対応
              else if (pay?.depositRefundInfo?.status == '4') {
                adjustmentTotal += pay.depositRefundInfo.amount;
              }
            }
          }
          //それぞれの値を計算する
          sum = claimTotal - aozoraTotal + adjustmentTotal;
        }
        return sum;
      },

      /**
       *  METHOD: 入金額処理
       */
      getDepositAmount: function(payment, company) {
        let sum = 0;

        //あおぞらの場合
        if (payment.payType == this.getPaymentType('BANK_TRANSFER').no) {
          let aozora = this.aozoraPayment.find(val => val.id == company.id);
          if (aozora != null) {
            //console.log('aozora', aozora);
            let getPayment = aozora.pay.filter(val => {
              return moment(val[0]).isBetween(
                moment(payment.billingDate)
                  .subtract(1, 'months')
                  .add(1, 'days'),
                moment(payment.billingDate),
                null,
                '[]'
              );
            });

            for (let pay of getPayment) {
              sum += parseInt(pay[4]);
            }
            if (payment?.depositRefundInfo?.status == 3) {
              sum += payment.depositRefundInfo.amount;
            }

            return sum;
          } else {
            if (payment?.depositRefundInfo?.status == 3) {
              return payment.depositRefundInfo.amount;
            }
            return 0;
          }
        }
        //クレカと、口座振替の場合
        else {
          //先月の決済情報を取得
          const lastMonthPaymentId = this.createPaymentId(payment.id, -1);

          //先月の請求情報が未納の場合かつ、入金状態の場合、入金額を加算する
          let pay = company.payment.find(val => val.id == lastMonthPaymentId);
          if (pay?.depositRefundInfo != null) {
            //未納
            if (pay.depositRefundInfo.status == 1) {
              if (pay.paymentResult == true) {
                sum += parseInt(pay.amount) + parseInt(pay.tax);
              }
            }
          }

          //trueの時入金が完了状態にする
          if (payment?.paymentResult == true) {
            if (payment?.depositRefundInfo?.status == 1) {
              //一度でも未納なら加算しない
            } else {
              sum += parseInt(payment.amount) + parseInt(payment.tax);
            }
          }
        }

        return sum;
      },
      /**
       * 前月までの、あおぞら料金を取得する
      */
      aozoraAllTotal: function(company, payment) {
        let aozoraTotal = 0;
        let aozoraPayment = this.aozoraPayment.find(pay => pay.id == company.id);
        if (aozoraPayment) {
          let aozoraBeforeLastMonthPayment = aozoraPayment.pay.filter(pay => {
            return moment(pay[0]).isBefore(moment(payment.billingDate), 'month');
          });
          aozoraBeforeLastMonthPayment.map(pay => (aozoraTotal += parseInt(pay[4])));
        }
        return aozoraTotal;
      },
    },
  };
</script>