// @ts-check
import { PvFloor } from '../../../node/node/floorNode';
import { createNode } from '../../../node/node/pvNode';
import { PvWall } from '../../../node/node/wallNode';
import { cloneNode } from '../../../node/tools/clone';
import { WALL_STYLE_PRESETS } from '../../../node/tools/wallStylePresets';
import { difference } from '../../../util/math/turfTools';
import { Vec } from '../../../util/math/vector';
import { makeToast, getBarycentricCoordinate } from '../../../util/util';
import { MESSAGE_TEXT } from '../../../util/const';
import { Action } from '../action';
import { ActionBuilder } from '../../action/builder/actionBuilder';
const N = 10000000000;

export class SplitFloorActionBuilder extends ActionBuilder {
  build() {
    const action = new Action();
    action.actionType = Action.ACTION_TYPE.CLICK2;
    const { getGuideRoot, getCurrentPos, getRoot } = this.context;
    const { part, targetObject } = this.dataset;

    if (targetObject?.type !== 'floor' || part !== 'edge') {
      makeToast(MESSAGE_TEXT.DRAW_HELP_WALL_SELECT);
      return;
    }

    const floor = /** @type {PvFloor} */ (targetObject);

    /** @type {PvWall} */
    const guide = createNode(PvWall);
    guide.style = WALL_STYLE_PRESETS.split.clone();
    getGuideRoot().appendChild(guide);
    guide.vertexes = [new Vec(), new Vec()];
    guide.moveTo(getCurrentPos());

    let prevDist = new Vec();
    action.onUpdate = ({ dist }) => {
      guide.moveVertexBy(1, prevDist.reverse());
      guide.moveVertexBy(1, dist);
      prevDist = dist;
    };
    action.onComplete = ({ first, last, dist }) => {
      const subScaleN = dist.scale(N);
      const norm = dist.normal().scale(N);
      const vertexes = floor.absoluteVertexes();
      const mask1 = [
        first.sub(subScaleN),
        last.add(subScaleN),
        last.add(subScaleN).add(norm),
        first.sub(subScaleN).add(norm),
      ];

      let po1 = difference(vertexes, mask1);
      const offset1 = Vec.leftTop(po1);
      po1 = po1.map(v => v.sub(offset1));

      const mask2 = [
        first.sub(subScaleN),
        last.add(subScaleN),
        last.add(subScaleN).sub(norm),
        first.sub(subScaleN).sub(norm),
      ];

      let po2 = difference(vertexes, mask2);
      const offset2 = Vec.leftTop(po2);
      po2 = po2.map(v => v.sub(offset2));

      // 分割前に子要素の左右判定を行う
      const cutVector = last.sub(first);
      let flags = [];
      let child = floor.firstChild;
      while (child) {
        if (child.type != "text"){  // テキストは無視
          const checker = floor.transform.translate.add(child.transform.translate.sub(first));
          flags.push(cutVector.isLeft(checker));
        }
        child = child.rightBrother;
      }

      // クローンの作成
      const floor2 = cloneNode(floor);

      const bak = floor.transform.translate.clone();
      // 変形
      floor.deformateFromVecList(po1);
      floor.moveTo(offset1);

      // floorの重心座標を生成（テキストの座標調整）
      const text1Vec = getBarycentricCoordinate(floor.vertexes);
      floor.children[0].transform.translate = text1Vec;
      floor.children[0].vertexes = [text1Vec];

      {
        // floor 子要素の処理
        const sub = offset1.sub(bak);  // 移動差分
        let child = floor.firstChild;
        let prev = null;
        let index = 0;
        while (child) {
          if (child.type !== 'text'){
            // テキスト以外
            if (!flags[index]) {
              // 右なら座標移動して残す
              child.transform.translate = child.transform.translate.sub(sub);
              prev = child;
            } else {
              // 左ならスキップ
              if (prev != null) {
                // 前に子要素があったら次に繋げる
                prev.rightBrother = child.rightBrother;
              }
            }
            index++;
          } else {
            // テキスト
            prev = child;
          }
          child = child.rightBrother;
        }
      }

      const bak2 = floor2.transform.translate.clone();
      // 変形
      floor2.deformateFromVecList(po2);
      floor2.moveTo(offset2);

      // floor2の重心座標を生成（テキストの座標調整）
      const text2Vec = getBarycentricCoordinate(floor2.vertexes);
      floor2.children[0].transform.translate = text2Vec;
      floor2.children[0].vertexes = [text2Vec];

      {
        // floor2 子要素の処理
        const sub = offset2.sub(bak2);  // 移動差分
        let child = floor2.firstChild;
        let prev = null;
        let index = 0;
        while (child) {
          if (child.type !== 'text'){
            // テキスト以外
            if (flags[index]) {
              // 左なら座標移動して残す
              child.transform.translate = child.transform.translate.sub(sub);
              prev = child;
            } else {
              // 右ならスキップ
              if (prev != null) {
                // 前に子要素があったら次に繋げる
                prev.rightBrother = child.rightBrother;
              }
            }
            index++;
          } else {
            // テキスト
            prev = child;
          }
          child = child.rightBrother;
        }
      }

      getRoot().appendChild(floor2);
    };

    return action;
  }
}
