//不整形地の計算
//import { API, graphqlOperation } from 'aws-amplify';
//import { getSystemMaster } from '@/graphql/queries';
/**
 * @param {*} routePriceResult //AからDまでのうち該当するもの
 * @param {*} input
 * @param {*} master //システムメール
 * @returns
 */
const irregularLandCalc = async function(routePriceResult, input, master) {
  try {
    //不整形地補正率を計算する際の地積区分
    var irregularLandTypeMaster = master.interitanceMaster4.slice(1);

    //不整形地補正率表マスタロード
    var irregularLandMaster = master.interitanceMaster5.slice(1);

    //間口狭小補正率マスタロード
    var frontageDistanceMaster = master.interitanceMaster6.slice(1);

    //奥行長大補正率マスタロード
    var depthMaster = master.interitanceMaster7.slice(1);

    //リターンオブジェクト
    var result = null;

    //間口狭小補正率の算出
    var frontageDistanceFactor = 0;
    var y2 = input.frontageDistance;
    //補正率の計算
    for (var t = 0; t < frontageDistanceMaster.length; t++) {
      if (y2 < frontageDistanceMaster[t][0]) {
        frontageDistanceFactor = frontageDistanceMaster[t][input.areaType];
        break;
      }
    }

    //奥行長大補正率の算出
    var depthLargeFactor = 1;
    var y3 = input.depthDistance / input.frontageDistance;
    //補正率の計算
    if (y3 >= 2) {
      for (var d = 0; d < depthMaster.length; d++) {
        if (y3 < depthMaster[d][0]) {
          depthLargeFactor = depthMaster[d][input.areaType];
          break;
        }
      }
    }

    //不整形地の判定
    if (input.irregularLandFlg) {
      //想定整形地の地積計算 = 想定整形地の間口距離 * 想定整形地の奥地距離
      let estimationLandSize =
        input.irregularLandFrontageDistance * input.irregularLandDepthDistance;
      //かげ地割合
      var shadedLandPercentage = ((estimationLandSize - input.landSize) / estimationLandSize) * 100;
    }

    //不整形地の場合
    if (input.irregularLandFlg && shadedLandPercentage >= irregularLandMaster[0][0]) {
      //不整形地の地積区分の算出
      var irregularLandType = null;
      if (irregularLandTypeMaster[input.areaType - 1][1] > input.landSize) {
        irregularLandType = 'A';
      } else if (
        irregularLandTypeMaster[input.areaType - 1][1] <= input.landSize &&
        irregularLandTypeMaster[input.areaType - 1][3] > input.landSize
      ) {
        irregularLandType = 'B';
      } else {
        irregularLandType = 'C';
      }

      //不整形地補正率の算出
      //マスタy偏の計算
      var y1 = -1;
      for (var i = irregularLandMaster.length - 1; i >= 0; i--) {
        if (irregularLandMaster[i][0] <= shadedLandPercentage) {
          y1 = i;
          break;
        }
      }

      //補正率の計算
      //普通住宅地区の場合
      //不整形地補正率（比較用）
      var irregularLandFactor = 0;
      if (input.areaType == 5) {
        switch (irregularLandType) {
          case 'A':
            irregularLandFactor = irregularLandMaster[y1][4];
            break;
          case 'B':
            irregularLandFactor = irregularLandMaster[y1][5];
            break;
          case 'C':
            irregularLandFactor = irregularLandMaster[y1][6];
            break;
        }
      } else {
        //普通住宅地区以外
        switch (irregularLandType) {
          case 'A':
            irregularLandFactor = irregularLandMaster[y1][1];
            break;
          case 'B':
            irregularLandFactor = irregularLandMaster[y1][2];
            break;
          case 'C':
            irregularLandFactor = irregularLandMaster[y1][3];
            break;
        }
      }

      //比較用の値を設定
      var comparisonValue1 = irregularLandFactor * frontageDistanceFactor;
      var comparisonValue2 = depthLargeFactor * frontageDistanceFactor;

      //不整形地の補正率
      if (comparisonValue1 > comparisonValue2) {
        irregularLandFactor = comparisonValue2;
      } else {
        irregularLandFactor = comparisonValue1;
      }
      //0.6が下限である為、補正
      if (irregularLandFactor < 0.6) {
        irregularLandFactor = 0.6;
      }

      //地価格納
      result = {
        resultPrice: Math.floor(routePriceResult.resultPrice * irregularLandFactor),
        resultPriceType: 'F',
        text: '不整形地の計算',
      };
      //      console.log('不整形地計算完了 : ', result);

      //間口が狭小な宅地など
    } else {
      // 間口狭小・奥行長大判定
      var calcFlg = null;
      switch (input.areaType) {
        case 1:
          if (input.frontageDistance < 28) {
            calcFlg = true;
          }
          break;
        case 2:
          if (input.frontageDistance < 8 || (0 < depthLargeFactor && depthLargeFactor < 1)) {
            calcFlg = true;
          }
          break;
        case 3:
          if (input.frontageDistance < 4 || (0 < depthLargeFactor && depthLargeFactor < 1)) {
            calcFlg = true;
          }
          break;
        case 4:
          if (input.frontageDistance < 6 || (0 < depthLargeFactor && depthLargeFactor < 1)) {
            calcFlg = true;
          }
          break;
        case 5:
          if (input.frontageDistance < 8 || (0 < depthLargeFactor && depthLargeFactor < 1)) {
            calcFlg = true;
          }
          break;
        case 6:
          if (input.frontageDistance < 10 || (0 < depthLargeFactor && depthLargeFactor < 1)) {
            calcFlg = true;
          }
          break;
        case 7:
          if (input.frontageDistance < 28) {
            calcFlg = true;
          }
          break;
      }

      if (calcFlg) {
        // 間口が狭小な宅地等
        result = {
          resultPrice: Math.floor(
            routePriceResult.resultPrice * frontageDistanceFactor * depthLargeFactor
          ),
          resultPriceType: 'E',
          text: '間口が狭小な宅地等',
        };
      } else {
        //地価格納
        result = {
          resultPrice: routePriceResult.resultPrice,
          resultPriceType: routePriceResult.resultPriceType,
        };
      }

      //      console.log('間口狭小な場合の計算 : ', result);
    }

    return result;
  } catch (e) {
    console.log('Error : ', e);
    throw new Error(e);
  }
};

export { irregularLandCalc };
