<template>
  <b-card no-body>
    <b-card-header class="card-header">
      <b>共通項目</b>
    </b-card-header>
    <b-card-body>
      <b-row style="margin-left:0px;margin-right:0px;">
        <b-col lg="6">
          <allyText
            title="レポート名"
            name="reportTitle"
            rules="required|max: 15"
            v-model="input.reportTitle"
          />
        </b-col>
        <b-col lg="6" style="position: relative;">
          <allyText
            title="顧客名"
            name="customerName"
            rules="required"
            v-model="input.customerName"
          />
          <div style="position: absolute; padding: 0; top: -5%; right: 3%; white-space: nowrap">
            <button
              class="btn btn-ally mr-1"
              style="height:10px;line-height:2px;"
              @click="$bvModal.show('searchCustomer')"
            >
              検索
            </button>
            <button
              class="btn btn-ally"
              style="margin-left:0.5rem;height:10px;line-height:2px;"
              @click="$bvModal.show('createCustomer')"
            >
              新規
            </button>
          </div>
        </b-col>
      </b-row>
      <b-row style="margin-left:0px;margin-right:0px;">
        <b-col lg="6">
          <allyDate title="作成日" name="reportDate" rules="required" v-model="input.reportDate" />
        </b-col>
        <b-col lg="" style="position: relative;">
          <allyText title="担当者名" name="staffName" rules="required" v-model="input.staffName" />
          <div style="position: absolute; padding: 0; top: -5%; right: 3%; white-space: nowrap">
            <button
              class="btn btn-ally"
              style="height:10px;line-height:2px;"
              @click="$bvModal.show('searchAccount')"
            >
              検索
            </button>
          </div>
        </b-col>
      </b-row>
      <!--       
      <b-row style="margin-left:0px;margin-right:0px;">
        <b-col lg="12">
          <PropertySettingV2 :property="input.propertyData" @selectProperty="selectProperty" />
        </b-col>
      </b-row> 
      -->

      <!--相続税の計算の場合、家族情報（相続人情報）を呼び出す-->
      <b-row v-if="inputType == 'inheritanceTax'" style="margin-left:0px;margin-right:0px;">
        <b-col lg="12">
          <b>相続人情報</b><br />
          ※注意①：法定相続人（配偶者、子、父母、代襲して相続人）は相続有無にかかわらず、全員を入力してください。<br />
          ※注意②：法定相続人が相続対象ではない場合は「相続有無」を「無」に設定してください。
          <table class="table text-center ally-table" id="family">
            <thead>
              <tr>
                <th>姓名</th>
                <th>続柄</th>
                <th>相続有無</th>
                <th>実際の相続割合</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in input.family" :key="row.Id">
                <td>{{ row.lastName }} {{ row.firstName }}</td>
                <!-- <td>{{ row.lastNameKana }} {{ row.firstNameKana }}</td> -->
                <td>{{ row.familyType }}</td>
                <td>{{ !row.inheritanceFlg ? '無' : '有' }}</td>
                <td>{{ !row.inheritancePrice ? 0 : row.inheritancePrice.toLocaleString() }} %</td>
                <td>
                  <b-button
                    size="sm"
                    @click="modalOpen(row, i)"
                    class="btn btn-ally mr-1 action-btn"
                  >
                    編集
                  </b-button>
                </td>
                <td>
                  <b-button size="sm" @click="deleteItem(i)" class="btn btn-ally mr-1 action-btn">
                    削除
                  </b-button>
                </td>
              </tr>
              <tr v-if="!inputMax">
                <td colspan="6" style="outline: none;">
                  <b-button
                    size="sm"
                    @click="modalOpen()"
                    class="btn btn-ally mr-1 action-btn float-left"
                  >
                    追加
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>

      <!--相続税の計算の場合、物件情報以外の金額を入力する-->
      <b-row
        v-if="inputType == 'inheritanceTax'"
        class="mt-3"
        style="margin-left:0px;margin-right:0px;"
      >
        <b-col>
          <b>相続資産（土地・建物以外）</b>
        </b-col>
      </b-row>
      <b-row
        v-if="inputType == 'inheritanceTax'"
        class=""
        style="margin-left:0px;margin-right:0px;"
      >
        <b-col lg="12">◆資産</b-col>
        <b-col lg="3">
          <allyText
            title="現金(円)"
            name="cash"
            type="number"
            rules="min_value:0|max_value:10000000000"
            :separation="true"
            v-model="input.cash"
          />
        </b-col>
        <b-col lg="3">
          <allyText
            title="生命保険(円)"
            name="stocks"
            type="number"
            rules="min_value:0|max_value:10000000000"
            :separation="true"
            v-model="input.stocks"
          />
        </b-col>
        <b-col lg="3">
          <allyText
            title="その他金融資産(円)"
            name="otherFinancialAsset"
            type="number"
            rules="min_value:0|max_value:10000000000"
            :separation="true"
            v-model="input.otherFinancialAsset"
          />
        </b-col>
        <b-col lg="3">
          <allyText
            title="その他(円)"
            name="otherAsset"
            type="number"
            rules="min_value:0|max_value:10000000000"
            :separation="true"
            v-model="input.otherAsset"
          />
        </b-col>
        <!-- <b-col lg="12">◆贈与</b-col>
        <b-col lg="3">
          <allyText
            title="暦年課税制度で3年以内に受けた贈与額(円)"
            name="donation"
            v-model="input.donation"
            rules="max_value: 10000000000"
            type="number"
            :separation="true"
          />
        </b-col> -->
        <b-col lg="12">◆控除</b-col>
        <!-- <b-col lg="3">
          <allyText
            title="相続時精算課税制度の適用を受けた財産額（円）"
            name="inheritancePriceDeduction"
            type="number"
            rules="max_value: 10000000000"
            :separation="true"
            v-model="input.inheritancePriceDeduction"
          />
        </b-col> -->
        <!-- <b-col lg="3">
          <allyText
            title="贈与税額控除（円）"
            name="donationDeduction"
            type="number"
            rules="max_value: 10000000000"
            :separation="true"
            v-model="input.donationDeduction"
          />
        </b-col> -->
        <b-col lg="3">
          <allyText
            title="債務・葬式分担費（円）"
            name="liability"
            type="number"
            rules="min_value:0|max_value:10000000000"
            :separation="true"
            v-model="input.liability"
          />
        </b-col>
      </b-row>
      <b-row v-if="inputType == 'inheritanceTax'" style="margin-left:0px;margin-right:0px;">
        <b-col lg="3">
          <allyRadio
            title="二次相続"
            name="continuityInheritanceFlg"
            :items="[
              { text: '有', value: 1 },
              { text: '無', value: 2 },
            ]"
            v-model="input.continuityInheritanceFlg"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="inputType == 'inheritanceTax' && input.continuityInheritanceFlg == 1"
        style="margin-left:0px;margin-right:0px;"
      >
        <b-col lg="3">
          <allySelect
            title="二次相続のパターン"
            name="secondaryInheritancePattern"
            :items="[
              { value: 1, text: '配偶者 → 子' },
              { value: 2, text: '子 → 孫' },
              { value: 3, text: '子 → 子の兄弟姉妹' },
              { value: 4, text: '兄弟姉妹 → 兄弟姉妹の子' },
            ]"
            v-model="input.secondaryInheritancePattern"
            mode="View"
          />
        </b-col>
        <b-col lg="3">
          <allyText
            title="二次被相続人の固有資産"
            name="uniqueAsset"
            v-model="input.uniqueAsset"
            rules="min_value:0|max_value:10000000000"
            :mode="input.continuityInheritanceFlg == 1 ? 'Edit' : 'View'"
            type="number"
            :separation="true"
          />
        </b-col>
        <b-col lg="3">
          <allyText
            title="一次相続から二次相続までの期間（年）"
            name="continuityInheritanceTerm"
            v-model="input.continuityInheritanceTerm"
            :mode="input.continuityInheritanceFlg == 1 ? 'Edit' : 'View'"
            rules="min_value:0|max_value:100"
            type="number"
            :separation="true"
          />
        </b-col>
        <b-col
          lg="3"
          v-if="input.secondaryInheritancePattern == 2 || input.secondaryInheritancePattern == 4"
        >
          <allyText
            title="二次相続人の人数"
            name="secondaryHeir"
            v-model="input.secondaryHeir"
            type="number"
            :min="'1'"
            :separation="true"
          />
        </b-col>
        <!-- <b-col lg="2">
          <allyText
            title="相次相続控除（円）"
            name="continuityInheritance"
            type="number"
            rules="max_value: 10000000000"
            :separation="true"
            v-model="input.continuityInheritance"
          />
        </b-col> -->
      </b-row>
    </b-card-body>
    <b-modal
      title="顧客情報検索"
      size="xl"
      id="searchCustomer"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      ok-variant="light"
      ok-only
      no-close-on-backdrop
    >
      <template v-slot:modal-ok>戻る</template>
      <CustomerList mode="Search" @selectCustomer="row => selectCustomer(row)" />
    </b-modal>
    <b-modal
      title="新規登録"
      size="xl"
      id="createCustomer"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      ok-variant="light"
      ok-only
      no-close-on-backdrop
    >
      <template #modal-footer="{ cancel }">
        <button
          style="background-color: white; color: black;"
          class="btn btn-ally"
          size="sm"
          variant="outline-secondary"
          @click="modalOkPreventDefault"
        >
          登録
        </button>
        <button
          style="background-color: white; color: black;"
          class="btn btn-ally"
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          戻る
        </button>
      </template>
      <div class="px-5">
        <CustomerViewer
          viewMode="Add"
          comp="true"
          ref="CustomerViewer"
          @modalClause="modalClause()"
        />
      </div>
    </b-modal>
    <b-modal
      title="アカウント検索"
      size="xl"
      id="searchAccount"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      ok-variant="light"
      ok-only
      no-close-on-backdrop
    >
      <template v-slot:modal-ok>戻る</template>
      <AccountList :mode="'Search'" @selectAccount="selectAccount" />
    </b-modal>
    <!--家族情報-->
    <b-modal
      id="familyModal"
      size="lg"
      title="家族情報追加"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="追加"
      @ok="pushFamily"
      @hidden="familyModalClear"
      no-close-on-backdrop
    >
      <ValidationObserver tag="div" class="row" slim ref="familyModal">
        <div>
          <div class="col-md-12">
            <div class="row">
              <allyText
                class="col-6"
                title="姓"
                name="iasttName"
                v-model="input.lastName"
                rules="required"
                placeholder="新井"
              />
              <allyText
                class="col-6"
                title="名"
                name="firstName"
                v-model="input.firstName"
                rules="required"
                placeholder="太郎"
              />
            </div>
            <div class="row">
              <allyText
                class="col-6"
                title="姓（カナ）"
                name="lastNameKana"
                v-model="input.lastNameKana"
                rules="required|onlykatakana"
                placeholder="アライ"
              />
              <allyText
                class="col-6"
                title="名（カナ）"
                name="firstNameKana"
                v-model="input.firstNameKana"
                rules="required|onlykatakana"
                placeholder="タロウ"
              />
            </div>
            <div class="row">
              <allySelect
                class="col-6"
                title="顧客との関係"
                name="familyType"
                :items="[
                  { value: '夫', text: '夫' },
                  { value: '妻', text: '妻' },
                  { value: '父', text: '父' },
                  { value: '母', text: '母' },
                  { value: '祖父', text: '祖父' },
                  { value: '祖母', text: '祖母' },
                  { value: '兄', text: '兄' },
                  { value: '弟', text: '弟' },
                  { value: '姉', text: '姉' },
                  { value: '妹', text: '妹' },
                  { value: '子', text: '子' },
                  { value: '養子', text: '養子' },
                  { value: '孫', text: '孫' },
                  { value: '兄弟姉妹の子', text: '兄弟姉妹の子' },
                ]"
                v-model="input.familyType"
                rules="required"
              />
            </div>
            <div class="row">
              <allyRadio
                class="col-6"
                title="相続有無"
                name="inheritanceFlg"
                :items="[
                  { text: '相続有り', value: true },
                  {
                    text: '相続なし',
                    value: false,
                  },
                ]"
                v-model="input.inheritanceFlg"
                rules="required"
              />
              <allyText
                class="col-6"
                title="実際の相続割合（％）"
                name="inheritancePrice"
                v-model="input.inheritancePrice"
                rules="max_value:100|min_value:0"
                type="number"
                :separation="true"
                roundType="down"
                v-if="input.inheritanceFlg"
              />
            </div>
            <div v-if="input.inheritanceFlg">
              <div
                class="row"
                v-if="
                  input.familyType == '子' || input.familyType == '養子' || input.familyType == '孫'
                "
              >
                <allyRadio
                  class="col-6"
                  title="入力区分"
                  name="inheritanceSystemFlg"
                  :items="[
                    { text: '暦年課税制度', value: 1 },
                    { text: '相続時精算課税制度', value: 2 },
                  ]"
                  v-model="input.inheritanceSystemFlg"
                  @input="inheritanceSelection"
                />
              </div>
              <div class="row" v-else>
                <allyRadio
                  class="col-6"
                  title="入力区分"
                  name="inheritanceSystemFlg"
                  :items="[{ text: '暦年課税制度', value: 1 }]"
                  v-model="input.inheritanceSystemFlg"
                  @input="inheritanceSelection"
                />
              </div>
              <div class="row">
                <div class="col-6">
                  <allyText
                    title="暦年課税制度で3年以内に受けた贈与額（円）"
                    name="donations1"
                    v-model="input.donations[0]"
                    :mode="input.inheritanceSystemFlg == 1 ? 'Edit' : 'View'"
                    rules="min_value:0|max_value:10000000000"
                    type="number"
                    :separation="true"
                    v-b-popover.hover.html="donationTips"
                  />
                </div>
                <div
                  class="col-6"
                  v-if="
                    input.familyType == '子' ||
                      input.familyType == '養子' ||
                      input.familyType == '孫'
                  "
                >
                  <allyText
                    title="相続時精算課税制度の適用を受けた財産額（円）"
                    name="inheritancePriceDeduction"
                    v-model="input.inheritancePriceDeduction"
                    :mode="input.inheritanceSystemFlg == 2 ? 'Edit' : 'View'"
                    rules="min_value:0|max_value:10000000000"
                    type="number"
                    :separation="true"
                    v-b-popover.hover.html="inheritancePriceDeductionTips"
                  />
                </div>
              </div>
              <div class="row">
                <allyText
                  class="col-6"
                  title="暦年課税制度で3年以内に受けた贈与額（円）"
                  name="donation2"
                  v-model="input.donations[1]"
                  :mode="input.inheritanceSystemFlg == 1 ? 'Edit' : 'View'"
                  rules="min_value:0|max_value:10000000000"
                  type="number"
                  :separation="true"
                  :nonAppear="true"
                  v-b-popover.hover.html="donationTips"
                />
              </div>
              <div class="row">
                <allyText
                  class="col-6"
                  title="暦年課税制度で3年以内に受けた贈与額（円）"
                  name="donation3"
                  v-model="input.donations[2]"
                  :mode="input.inheritanceSystemFlg == 1 ? 'Edit' : 'View'"
                  rules="min_value:0|max_value:10000000000"
                  type="number"
                  :separation="true"
                  :nonAppear="true"
                  v-b-popover.hover.html="donationTips"
                />
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </b-modal>
  </b-card>
</template>
<script>
  import CommonInputClass from './class.js';
  import CustomerList from '@/views/Customer/List';
  import CustomerViewer from '@/views/Customer/View';
  import AccountList from '@/views/Account/List';
  //import PropertySettingV2 from '../Property/PropertySettingV2';

  export default {
    components: {
      CustomerList,
      CustomerViewer,
      AccountList,
      // PropertySettingV2,
    },
    props: {
      value: {
        type: Object,
      },
      inputType: { type: String },
    },

    data() {
      return {
        input: new CommonInputClass(),
        index: null,
        inputMax: false,
        donationTips:
          '贈与税の課税方式のひとつで、1月1日から12月31日までの1年間に贈与された財産の<br />合計額に応じて課税される方式。1人当たり年間110万円の基礎控除がある。<br /><a href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/zoyo/4408.htm" target="_blank">https://www.nta.go.jp/taxes/shiraberu/taxanswer/zoyo/4408.htm</a>',
        inheritancePriceDeductionTips:
          '相続時精算課税の制度とは、原則として60歳以上の父母又は祖父母から18歳以上の<br />子又は孫に対し、財産を贈与した場合において選択できる贈与税の制度。<br />国税庁ホームページをご参照ください。<br /><a href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/sozoku/4103.htm" target="_blank">https://www.nta.go.jp/taxes/shiraberu/taxanswer/sozoku/4103.htm</a>',
      };
    },
    mounted: async function() {
      this.input.reportDate = new Date().toISOString().slice(0, 10);
    },
    watch: {
      value: {
        handler() {
          this.input = this.value;
        },
        deep: true,
      },
      input: {
        handler() {
          this.$emit('input', this.input);
          if (!this.input.inheritanceFlg) {
            this.input.inheritancePrice = 0;
          }
          if (this.input.family.length >= 20) {
            this.inputMax = true;
          }
        },
        deep: true,
      },
      'input.continuityInheritanceFlg': function(value, oldValue) {
        if (value == 1) {
          //二次相続が「有」になったら:二次相続のパターンを「配偶者→子」にする(課題489対応兼務)
          this.input.secondaryInheritancePattern = 1;
        }
      },
      'input.inheritancePrice': {
        handler() {
          //小数点以下の切捨てを行う
          this.input.inheritancePrice = Math.floor(this.input.inheritancePrice);
        },
      },
    },
    methods: {
      inheritanceSelection() {
        if (this.input.inheritanceSystemFlg == 1) {
          this.input.inheritancePriceDeduction = 0;
        } else {
          this.input.donations[0] = 0;
          this.input.donations[1] = 0;
          this.input.donations[2] = 0;
        }
      },
      selectCustomer(row) {
        //相続税の場合、家族情報（相続対象をロードする）
        if (this.inputType == 'inheritanceTax') {
          //家族情報を初期化する
          this.input.family = [];
          //家族情報がある場合、ロードする
          if (row.family)
            row.family.forEach(value => {
              var result = {
                lastName: value.lastName,
                firstName: value.firstName,
                lastNameKana: value.lastNameKana,
                firstNameKana: value.firstNameKana,
                familyType: value.familyType,
                inheritanceFlg: false,
                inheritanceSystemFlg: null,
                inheritancePrice: 0,
                inheritancePriceDeduction: 0,
                donationDeduction: 0,
                donation: 0,
                donations: [],
                liability: null,
                continuityInheritanceFlg: null,
                continuityInheritanceTerm: 0,
              };
              this.input.family.push(result);
            });
        }
        this.$bvModal.hide('searchCustomer');
        this.$set(this.input, 'customerId', row.id);
        this.$set(this.input, 'customerName', row.customerName);
      },
      selectAccount(row) {
        this.$bvModal.hide('searchAccount');
        this.$set(this.input, 'staffName', row.familyname + ' ' + row.givenname);
      },
      createCustomer() {
        this.$router.push(location.origin + '/Customer/Add/');
      },
      //相続税計算に必要な相続人の情報をリストに格納する
      pushFamily: async function(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.$refs.familyModal.validate().then(async isValid => {
          var result = {
            lastName: this.input.lastName,
            firstName: this.input.firstName,
            lastNameKana: this.input.lastNameKana,
            firstNameKana: this.input.firstNameKana,
            familyType: this.input.familyType,
            inheritanceFlg: this.input.inheritanceFlg,
            inheritanceSystemFlg: this.input.inheritanceSystemFlg,
            inheritancePrice: this.input.inheritancePrice,
            inheritancePriceDeduction: this.input.inheritancePriceDeduction,
            donationDeduction: this.input.donationDeduction,
            donation: this.input.donation,
            donations: this.input.donations,
            liability: this.input.liability,
            continuityInheritanceFlg: this.input.continuityInheritanceFlg,
            continuityInheritanceTerm: this.input.continuityInheritanceTerm,
          };

          if (isValid && this.index != null) {
            this.input.family[this.index] = result;
            this.$bvModal.hide('familyModal');
            this.familyModalClear();
          } else if (isValid) {
            if (this.input.family == null) this.input.family = [];
            this.input.family.push(result);
            this.$bvModal.hide('familyModal');
            this.familyModalClear();
          }
        });
        // this.$refs.familyModal.validate().then(isValid => {
        //   if (isValid) {
        //     this.input.family.push({
        //       lastName: this.input.lastName,
        //       firstName: this.input.firstName,
        //       lastNameKana: this.input.lastNameKana,
        //       firstNameKana: this.input.firstNameKana,
        //       familyType: this.input.familyType,
        //     });
        //     this.$bvModal.hide('familyModal');
        //   }
        // })
      },
      //相続人入力モーダルを初期化する
      familyModalClear() {
        this.input.lastName = null;
        this.input.firstName = null;
        this.input.lastNameKana = null;
        this.input.firstNameKana = null;
        this.input.familyType = null;
        this.input.uniqueAsset = 0;
        this.input.inheritanceFlg = false;
        this.input.inheritanceSystemFlg = null;
        this.input.inheritancePriceDeduction = null;
        // this.input.donationDeduction = 0;
        this.input.donation = 0;
        this.input.donations = [];
        // this.input.liability = 0;
        // this.input.continuityInheritance = 0;
        // this.input.continuityInheritanceFlg = 2;
        // this.input.continuityInheritanceTerm = 0;
      },
      //相続人入力モーダルを開く
      modalOpen: function(item, i) {
        this.index = null;
        //編集ボタンをクリックした場合、対象行の情報を出力する
        if (i !== undefined) {
          this.index = i;
          this.input.lastName = this.input.family[i].lastName;
          this.input.firstName = this.input.family[i].firstName;
          this.input.lastNameKana = this.input.family[i].lastNameKana;
          this.input.firstNameKana = this.input.family[i].firstNameKana;
          this.input.familyType = this.input.family[i].familyType;
          this.input.inheritanceFlg =
            this.input.family[i].inheritanceFlg === undefined
              ? false
              : this.input.family[i].inheritanceFlg;
          this.input.inheritanceSystemFlg =
            this.input.family[i].inheritanceSystemFlg === undefined
              ? null
              : this.input.family[i].inheritanceSystemFlg;
          this.input.inheritancePrice =
            this.input.family[i].inheritancePrice === undefined
              ? 0
              : this.input.family[i].inheritancePrice;
          this.input.donations[0] =
            this.input.family[i].donations[0] === undefined ? 0 : this.input.family[i].donations[0];
          this.input.donations[1] =
            this.input.family[i].donations[1] === undefined ? 0 : this.input.family[i].donations[1];
          this.input.donations[2] =
            this.input.family[i].donations[2] === undefined ? 0 : this.input.family[i].donations[2];
          this.input.inheritancePriceDeduction =
            this.input.family[i].inheritancePriceDeduction === undefined
              ? 0
              : this.input.family[i].inheritancePriceDeduction;
          // this.input.donationDeduction =
          //   this.input.family[i].donationDeduction === undefined
          //     ? 0
          //     : this.input.family[i].donationDeduction;
          // this.input.liability =
          //   this.input.family[i].liability === undefined ? 0 : this.input.family[i].liability;
          // this.input.continuityInheritance =
          //   this.input.family[i].continuityInheritance === undefined
          //     ? 0
          //     : this.input.family[i].continuityInheritance;
          // this.input.continuityInheritanceFlg =
          //   this.input.family[i].continuityInheritanceFlg === undefined
          //     ? false
          //     : this.input.family[i].continuityInheritanceFlg;
          // this.input.continuityInheritanceTerm =
          //   this.input.family[i].continuityInheritanceTerm === undefined
          //     ? false
          //     : this.input.family[i].continuityInheritanceTerm;
        }
        this.$bvModal.show('familyModal');
      },
      //相続人情報を消すメソッド
      deleteItem: function(index) {
        this.input.family.splice(index, 1);
      },
      //顧客情報保存を確認したとき処理する
      modalClause: function() {
        this.$bvModal.hide('createCustomer');
      },
      selectProperty: function(result) {
        this.input.propertyData = result;
      },
      /**
       *顧客情報登録ボタンを押した時
       */
      modalOkPreventDefault: async function() {
        //顧客情報の登録を実行
        let result = await this.$refs.CustomerViewer.create();
        //正常に登録が完了した時モーダルを閉じる
        if (result.flag == true) {
          this.selectCustomer(result.result);
          this.$bvModal.hide('createCustomer');
        }
      },
    },
  };
</script>
<style scoped>
  .customer-search-btn {
    position: absolute;
    top: 10%;
    left: 40%;
  }
  .customer-create-btn {
    position: absolute;
    top: 10%;
    left: 60%;
  }
  .card-header {
    transition: 0.2s;
    background: rgba(var(--colorThemeRGB));
    color: white;
  }
</style>
