<!--
作成者：朴
作成日：2020.9.09
画面名：不動産適正共通項目入力コンポネント
機能：不動産適正の共通仕様項目を入力
親子関係：なし
更新者：
更新内容：
-->
<template>
  <div>
    <div v-show="mode == 'Input'">
      <!--相続税計算-->
      <b-card no-body class="category margin-bottom">
        <b-card-header class="card-header">
          <b>計算項目</b>
        </b-card-header>
        <b-card-body>
          <ValidationObserver slim ref="inputCheck">
            <div class="col-md-12 ml-1">
              <div class="row">
                <allyText
                  class="col-6"
                  title="物件•土地名"
                  name="realEstateName"
                  v-model="value.realEstateName"
                  rules="required|max:20"
                  :optionalOutput="true"
                />
                <allyText
                  class="col-6"
                  title="コメント"
                  name="memo"
                  v-model="value.memo"
                  mode="Edit"
                />
              </div>
            </div>
            <div class="row ml-1">
              <b-col class="component col-6">
                <allyAddress v-model="value.realEstateAddress" />
              </b-col>
            </div>
            <div class="col-md-12 ml-1">
              <allyRadio
                class="col-12"
                title="相続税評価対象のタイプ"
                name="calcMode"
                :items="[
                  { text: '路線価地域の場合', value: 1 },
                  { text: '路線価地区区分倍率地域の場合', value: 2 },
                ]"
                v-model="value.calcMode"
              />
            </div>
            <!--家屋以外の場合（土地）-->
            <div class="row flex-column ml-2">
              <div class="row">
                <div class="col-md-6">
                  <div class="col-md-12" style="margin-top : 10px">
                    <b style="white-space: nowrap;">土地</b>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="col-md-12" style="margin-top : 10px">
                  <div class="row" v-if="value.calcMode == 1">
                    <allyText
                      class="col-6"
                      title="間口距離(m)"
                      name="frontageDistance"
                      v-model="value.frontageDistance"
                      type="number"
                      rules="required|min_value:0|max_value:1000"
                      :floatLength="1"
                      :separation="true"
                      :optionalOutput="true"
                      roundType="down"
                    />
                    <allyText
                      class="col-6"
                      title="奥行距離(m)"
                      name="depthDistance"
                      type="number"
                      v-model="value.depthDistance"
                      rules="required|min_value:0|max_value:1000"
                      :floatLength="1"
                      :separation="true"
                      :optionalOutput="true"
                      roundType="down"
                    />
                  </div>
                  <div class="row">
                    <allyText
                      class="col-6"
                      title="地積(㎡)"
                      name="landSize"
                      type="number"
                      v-model="value.landSize"
                      rules="required|min_value:0|max_value:1000000"
                      :floatLength="2"
                      :separation="true"
                      :optionalOutput="true"
                      roundType="down"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row" v-if="value.calcMode == 1">
                    <allySelect
                      class="col-6"
                      title="利用区分"
                      name="useType"
                      :items="[
                        { text: '自用地', value: 1 },
                        { text: '貸宅地', value: 2 },
                        { text: '貸家建付地', value: 3 },
                        { text: '借地権', value: 4 },
                        { text: '私道', value: 5 },
                        { text: '貸家建付借地権', value: 6 },
                        { text: '転貸借地権', value: 7 },
                        { text: 'その他', value: 8 },
                      ]"
                      v-model="value.useType"
                      rules="required"
                    />
                    <allySelect
                      class="col-6"
                      title="地区区分"
                      name="areaType"
                      :items="[
                        { text: 'ビル街地区', value: 1 },
                        { text: '高度商業地区', value: 2 },
                        { text: '繁華街地区', value: 3 },
                        { text: '普通商業•併用住宅地区', value: 4 },
                        { text: '普通住宅地区', value: 5 },
                        { text: '中小工場地区', value: 6 },
                        { text: '大工場地区', value: 7 },
                      ]"
                      v-model="value.areaType"
                      rules="required"
                    />
                  </div>
                </div>

                <div class="col-md-12" style="margin-bottom : 10px">
                  <b>※路線価・地区区分の検索が必要な場合は下記をご覧ください。</b>
                  <div class="row">
                    <a
                      href="https://www.chikamap.jp/chikamap/"
                      target="_blank"
                      style="margin-right : 5px;"
                    >
                      <img src="../../images/ChikaMap_M.gif" />
                    </a>
                    <a href="https://www.rosenka.nta.go.jp/index.htm" target="_blank">
                      <img src="../../images/bannerForLink.jpg" />
                    </a>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <!--路線価の入力-->
                    <table
                      v-if="value.calcMode == 1"
                      class="table text-center ally-table"
                      style="margin-bottom : 10px"
                    >
                      <thead>
                        <tr>
                          <td colspan="3">
                            <b>路線価(円/㎡)</b>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="3">
                            <allyText
                              title="正面"
                              name="landPriceFront"
                              type="number"
                              v-model="value.landPrice.front"
                              rules="required|min_value:0|max_value:100000000"
                              :separation="true"
                              :optionalOutput="true"
                              roundType="down"
                              Key="LandPriceFront"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <allyText
                              title="側方1"
                              name="landPriceSide1"
                              type="number"
                              v-model="value.landPrice.side1"
                              rules="min_value:0|max_value:100000000"
                              :separation="true"
                              roundType="down"
                              Key="LandPriceSide1"
                            />
                            <div class="row" v-if="value.landPrice.side1">
                              <div>
                                <ol style="margin-right : 10px">
                                  <li
                                    class="btn btn-outline-ally"
                                    v-bind:class="{ active: value.corner.side1Corner == 0 }"
                                    v-on:click="value.corner.side1Corner = 0"
                                  >
                                    対象外
                                  </li>
                                  <li
                                    class="btn btn-outline-ally"
                                    v-bind:class="{ active: value.corner.side1Corner == 1 }"
                                    v-on:click="value.corner.side1Corner = 1"
                                  >
                                    角地
                                  </li>
                                  <li
                                    class="btn btn-outline-ally"
                                    v-bind:class="{ active: value.corner.side1Corner == 2 }"
                                    v-on:click="value.corner.side1Corner = 2"
                                  >
                                    準角地
                                  </li>
                                </ol>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <allyText
                              title="側方2"
                              name="landPriceSide2"
                              type="number"
                              v-model="value.landPrice.side2"
                              rules="min_value:0|max_value:100000000"
                              :separation="true"
                              roundType="down"
                              Key="LandPriceSide2"
                            />
                            <div class="row" v-if="value.landPrice.side2">
                              <div>
                                <ol style="margin-right : 10px">
                                  <li
                                    class="btn btn-outline-ally"
                                    v-bind:class="{ active: value.corner.side2Corner == 0 }"
                                    v-on:click="value.corner.side2Corner = 0"
                                  >
                                    対象外
                                  </li>
                                  <li
                                    class="btn btn-outline-ally"
                                    v-bind:class="{ active: value.corner.side2Corner == 1 }"
                                    v-on:click="value.corner.side2Corner = 1"
                                  >
                                    角地
                                  </li>
                                  <li
                                    class="btn btn-outline-ally"
                                    v-bind:class="{ active: value.corner.side2Corner == 2 }"
                                    v-on:click="value.corner.side2Corner = 2"
                                  >
                                    準角地
                                  </li>
                                </ol>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <allyText
                              title="裏面"
                              name="landPriceback"
                              type="number"
                              v-model="value.landPrice.back"
                              rules="min_value:0|max_value:100000000"
                              :separation="true"
                              roundType="down"
                              Key="LandPriceback"
                            />
                            <div class="row" v-if="value.landPrice.back">
                              <div>
                                <ol style="margin-right : 10px">
                                  <li
                                    class="btn btn-outline-ally"
                                    v-bind:class="{ active: value.corner.backCorner == 0 }"
                                    v-on:click="value.corner.backCorner = 0"
                                  >
                                    対象外
                                  </li>
                                  <li
                                    class="btn btn-outline-ally"
                                    v-bind:class="{ active: value.corner.backCorner == 1 }"
                                    v-on:click="value.corner.backCorner = 1"
                                  >
                                    角地
                                  </li>
                                  <li
                                    class="btn btn-outline-ally"
                                    v-bind:class="{ active: value.corner.backCorner == 2 }"
                                    v-on:click="value.corner.backCorner = 2"
                                  >
                                    準角地
                                  </li>
                                </ol>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <!--倍率計算-->
                    <table
                      v-if="value.calcMode == 2"
                      class="table text-center ally-table route-price"
                      style="margin-bottom : 10px"
                    >
                      <thead>
                        <tr>
                          <td colspan="3">
                            <b>倍率計算（土地）</b>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="3">
                            <allyText
                              title="土地の倍率"
                              name="landMagnification"
                              v-model="value.landMagnification"
                              type="number"
                              step="0.01"
                              :separation="true"
                              rules="required|min_value:0.01|max_value:100"
                              :floatLength="2"
                              roundType="down"
                              key="LandMagnification"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <allyText
                              title="固定資産税評価額(円)"
                              name="fixedAssetTax"
                              v-model="value.fixedAssetTax"
                              type="number"
                              :separation="true"
                              rules="required|min_value:0|max_value:100000000000"
                              roundType="down"
                              key="FixedAssetTax"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <div
                  class="col-6"
                  style="border-right: 1px solid rgb(154, 154, 154); height: 100%"
                  v-if="value.calcMode == 1"
                ></div>
              </div>
              <div class="col-md-7" v-if="value.calcMode == 1">
                <div class="col-md-12">
                  <div class="row" style="margin-bottom : 10px">
                    <b>オプション</b>
                  </div>
                  <div class="row" style="margin-bottom : 10px" v-if="bigLandCheckFlg == true">
                    <div class="col-12">
                      • 三大都市圏に所在する宅地ですか？
                    </div>
                    <div class="">
                      <button
                        style="margin-left : 10px"
                        class="btn btn-outline-ally"
                        v-bind:class="{ active: value.bigLandFlg == true }"
                        v-on:click="
                          value.bigLandFlg == true
                            ? (value.bigLandFlg = false)
                            : (value.bigLandFlg = true)
                        "
                      >
                        はい
                      </button>
                    </div>
                  </div>
                  <!--不整形地の計算-->
                  <div class="row" style="margin-bottom : 10px" v-if="!bigFactoryCheckFlg">
                    <div class="col-12">
                      • 不整形地ですか？
                    </div>
                    <div class="">
                      <button
                        style="margin-left : 10px"
                        class="btn btn-outline-ally"
                        v-bind:class="{ active: value.irregularLandFlg == true }"
                        v-on:click="
                          value.irregularLandFlg == true
                            ? (value.irregularLandFlg = false)
                            : (value.irregularLandFlg = true)
                        "
                      >
                        はい
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="row" v-if="value.irregularLandFlg == true">
                      <div class="col-md-12">
                        ※想定整形地「間口距離」、「奥行距離」を入力してください。
                        <div class="row">
                          <allyText
                            class="col-6"
                            title="間口距離(m)"
                            name="frontageDistance"
                            type="number"
                            v-model="value.irregularLandFrontageDistance"
                            rules="required|min_value:0|max_value:1000"
                            :separation="true"
                            :optionalOutput="true"
                            :floatLength="1"
                            roundType="down"
                          />
                          <allyText
                            class="col-6"
                            title="奥行距離(m)"
                            name="depthDistance"
                            type="number"
                            v-model="value.irregularLandDepthDistance"
                            rules="required|min_value:0|max_value:1000"
                            :separation="true"
                            :optionalOutput="true"
                            :floatLength="1"
                            roundType="down"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--無道路地の判定-->
                  <div class="row" style="margin-bottom : 10px" v-if="value.noRoadLandCheckFlg">
                    <div class="col-12">
                      • 無道路地ですか？
                    </div>
                    <div class="">
                      <button
                        style="margin-left : 10px"
                        class="btn btn-outline-ally"
                        v-bind:class="{ active: value.noRoadLandFlg == true }"
                        v-on:click="
                          value.noRoadLandFlg == true
                            ? (value.noRoadLandFlg = false)
                            : (value.noRoadLandFlg = true)
                        "
                      >
                        はい
                      </button>
                    </div>
                  </div>
                  <div>
                    <div class="row">
                      <div
                        class="row"
                        v-if="value.noRoadLandFlg && (value.irregularLandFlg || bigLandCheckFlg)"
                      >
                        <div class="col-md-12">
                          ※通路部分の地積を入力してください。
                          <div class="row">
                            <allyText
                              class="col-12"
                              title="通路部分の地積(㎡)"
                              name="noRoadLandSize"
                              type="number"
                              v-model="value.noRoadLandSize"
                              rules="required|min_value:0|max_value:1000000"
                              :separation="true"
                              :floatLength="2"
                              roundType="down"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--がけ地の計算-->
                  <div class="row" style="margin-bottom : 10px">
                    <div class="col-12">
                      • がけ地等を有する宅地ですか？
                    </div>
                    <div class="">
                      <button
                        style="margin-left : 10px"
                        class="btn btn-outline-ally"
                        v-bind:class="{ active: value.slantedLandFlg == true }"
                        v-on:click="
                          value.slantedLandFlg == true
                            ? (value.slantedLandFlg = false)
                            : (value.slantedLandFlg = true)
                        "
                      >
                        はい
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="row" v-if="value.slantedLandFlg == true">
                      <div class="col-md-12">
                        <allyRadio
                          class="col-6"
                          title="※がけ地の情報を入力してください。"
                          name="slantedLandDirection"
                          :items="[
                            { text: '南', value: 1 },
                            { text: '東', value: 2 },
                            { text: '西', value: 3 },
                            { text: '北', value: 4 },
                          ]"
                          v-model="value.slantedLandDirection"
                          rules="required"
                        />
                        <div class="row">
                          <allyText
                            class="col-12"
                            title="がけ地地積(㎡)"
                            name="slantedLandSize"
                            type="number"
                            v-model="value.slantedLandSize"
                            rules="required|min_value:0|max_value:1000000"
                            :separation="true"
                            :floatLength="2"
                            roundType="down"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--容積率の異なる2以上の地域にわたる宅地-->
                  <div class="row" style="margin-bottom : 10px">
                    <div class="col-12">
                      • 容積率の異なる２以上の地域にある宅地ですか？
                    </div>
                    <div class="">
                      <button
                        style="margin-left : 10px"
                        class="btn btn-outline-ally"
                        v-bind:class="{ active: value.multipleFloorAreaRatioFlg == true }"
                        v-on:click="
                          value.multipleFloorAreaRatioFlg == true
                            ? (value.multipleFloorAreaRatioFlg = false)
                            : (value.multipleFloorAreaRatioFlg = true)
                        "
                      >
                        はい
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="row" v-if="value.multipleFloorAreaRatioFlg == true">
                      <div class="col-md-12">
                        ※控除割合を入力してください。
                        <div class="row">
                          <allyText
                            class="col-12"
                            title="控除割合(小数点以下3単位未満四捨五入)"
                            name="multipleFloorAreaRatioDeductionRate"
                            type="number"
                            step="0.001"
                            v-model="value.multipleFloorAreaRatioDeductionRate"
                            rules="required|min_value:0.001|max_value:1"
                            :separation="true"
                            :floatLength="3"
                            roundType="down"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="row">
                      <div class="col-md-12">
                        ※セットバックを必要とする宅地ですか？
                        <div class="" style="margin-bottom : 10px">
                          <button
                            style="margin-left : 10px"
                            class="btn btn-outline-ally"
                            v-bind:class="{ active: value.setBackFlg == true }"
                            v-on:click="
                              value.setBackFlg == true
                                ? (value.setBackFlg = false)
                                : (value.setBackFlg = true)
                            "
                          >
                            はい
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom : 10px">
                    <div class="row" v-if="value.setBackFlg == true">
                      <div class="col-md-12">
                        ※対象地積を入力してください。
                        <div class="row">
                          <allyText
                            class="col-12"
                            title="対象地積(㎡)"
                            name="setBackSize"
                            type="number"
                            v-model="value.setBackSize"
                            rules="required|min_value:0|max_value:1000000"
                            :separation="true"
                            :floatLength="2"
                            roundType="down"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="row">
                      <div class="col-md-12">
                        ※市街地農地等とする宅地ですか？
                        <div class="" style="margin-bottom : 10px">
                          <button
                            style="margin-left : 10px"
                            class="btn btn-outline-ally"
                            v-bind:class="{ active: value.farmlandFlag == true }"
                            v-on:click="
                              value.farmlandFlag == true
                                ? (value.farmlandFlag = false)
                                : (value.farmlandFlag = true)
                            "
                          >
                            はい
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom : 10px">
                    <div class="row" v-if="value.farmlandFlag == true">
                      <div class="col-md-12">
                        平坦地
                        <b-row>
                          <b-col cols="3">
                            整地に要する面積(㎡)
                          </b-col>
                          <b-col cols="3">
                            1㎡当たりの整地費(円)
                          </b-col>
                          <b-col cols="6" />
                        </b-row>
                        <b-row>
                          <b-col cols="3">
                            <allyText
                              title="整地に要する面積(㎡)"
                              :nonAppear="true"
                              name="preparationSize"
                              type="number"
                              v-model="value.preparationSize"
                              rules="anyrequired:@伐採・抜根を要する面積(㎡),@地盤改良を要する面積(㎡),@土盛りを要する面積(㎡),@擁壁面の長さ(m),@傾斜度(度)"
                              min="0"
                              max="1000000"
                              :separation="true"
                              :floatLength="2"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="3">
                            <allyText
                              title="1㎡当たりの整地費(円)"
                              :nonAppear="true"
                              name="preparationUnitPrice"
                              type="number"
                              v-model="value.preparationUnitPrice"
                              rules="required|min_value:0|max_value:1000000"
                              :separation="true"
                              roundType="down"
                              :mode="value.changedFlag[0] ? 'Edit' : 'View'"
                            />
                          </b-col>
                          <b-col cols="1" class="checkbox center mt-2">
                            <b-form-checkbox
                              :id="`Check_0`"
                              :name="`Check_0`"
                              v-model="value.changedFlag[0]"
                              @change="changeCheckBox()"
                              size="lg"
                            >
                            </b-form-checkbox>
                          </b-col>
                          <b-col cols="5" />
                        </b-row>
                        <b-row>
                          <b-col cols="3">
                            伐採・抜根を要する面積(㎡)
                          </b-col>
                          <b-col cols="3">
                            1㎡当たりの伐採・抜根費(円)
                          </b-col>
                          <b-col cols="6" />
                        </b-row>
                        <b-row>
                          <b-col cols="3">
                            <allyText
                              title="伐採・抜根を要する面積(㎡)"
                              :nonAppear="true"
                              name="loggingSize1"
                              type="number"
                              v-model="value.loggingSize1"
                              rules="anyrequired:@整地に要する面積(㎡),@地盤改良を要する面積(㎡),@土盛りを要する面積(㎡),@擁壁面の長さ(m),@傾斜度(度)"
                              min="0"
                              max="1000000"
                              :separation="true"
                              :floatLength="2"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="3">
                            <allyText
                              title="1㎡当たりの伐採・抜根費(円)"
                              :nonAppear="true"
                              name="loggingUnitPrice"
                              type="number"
                              v-model="value.loggingUnitPrice"
                              rules="required|min_value:0|max_value:1000000"
                              :separation="true"
                              roundType="down"
                              :mode="value.changedFlag[1] ? 'Edit' : 'View'"
                            />
                          </b-col>
                          <b-col cols="1" class="checkbox center mt-2">
                            <b-form-checkbox
                              :id="`Check_1`"
                              :name="`Check_1`"
                              v-model="value.changedFlag[1]"
                              @change="changeCheckBox()"
                              size="lg"
                            >
                            </b-form-checkbox>
                          </b-col>
                          <b-col cols="5" />
                        </b-row>
                        <b-row>
                          <b-col cols="3">
                            地盤改良を要する面積(㎡)
                          </b-col>
                          <b-col cols="3">
                            1㎡当たりの地盤改良費(円)
                          </b-col>
                          <b-col cols="6" />
                        </b-row>
                        <b-row>
                          <b-col cols="3">
                            <allyText
                              title="地盤改良を要する面積(㎡)"
                              :nonAppear="true"
                              name="stabilizationSize"
                              type="number"
                              v-model="value.stabilizationSize"
                              rules="anyrequired:@整地に要する面積(㎡),@伐採・抜根を要する面積(㎡),@土盛りを要する面積(㎡),@擁壁面の長さ(m),@傾斜度(度)"
                              min="0"
                              max="1000000"
                              :separation="true"
                              :floatLength="2"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="3">
                            <allyText
                              title="1㎡当たりの地盤改良費(円)"
                              :nonAppear="true"
                              name="stabilizationUnitPrice"
                              type="number"
                              v-model="value.stabilizationUnitPrice"
                              rules="required|min_value:0|max_value:1000000"
                              :separation="true"
                              roundType="down"
                              :mode="value.changedFlag[2] ? 'Edit' : 'View'"
                            />
                          </b-col>
                          <b-col cols="1" class="checkbox center mt-2">
                            <b-form-checkbox
                              :id="`Check_2`"
                              :name="`Check_2`"
                              v-model="value.changedFlag[2]"
                              @change="changeCheckBox()"
                              size="lg"
                            >
                            </b-form-checkbox>
                          </b-col>
                          <b-col cols="5" />
                        </b-row>
                        <b-row>
                          <b-col cols="3">
                            土盛りを要する面積(㎡)
                          </b-col>
                          <b-col cols="3">
                            平均の高さ(m)
                          </b-col>
                          <b-col cols="3">
                            1㎡当たりの土盛費(円)
                          </b-col>
                          <b-col cols="3" />
                        </b-row>
                        <b-row>
                          <b-col cols="3">
                            <allyText
                              title="土盛りを要する面積(㎡)"
                              :nonAppear="true"
                              name="earthworkSize"
                              type="number"
                              v-model="value.earthworkSize"
                              rules="aandbrequired:@土盛りの平均の高さ(m)|anyrequired:@整地に要する面積(㎡),@伐採・抜根を要する面積(㎡),@地盤改良を要する面積(㎡),@擁壁面の長さ(m),@傾斜度(度)"
                              min="0"
                              max="1000000"
                              :separation="true"
                              :floatLength="2"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="3">
                            <allyText
                              title="土盛りの平均の高さ(m)"
                              :nonAppear="true"
                              name="earthworkHight"
                              type="number"
                              v-model="value.earthworkHight"
                              rules="min_value:0|max_value:1000000|aandbrequired:@土盛りを要する面積(㎡)"
                              :separation="true"
                              :floatLength="2"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="3">
                            <allyText
                              title="1㎡当たりの土盛費(円)"
                              :nonAppear="true"
                              name="earthworkUnitPrice"
                              type="number"
                              v-model="value.earthworkUnitPrice"
                              rules="required|min_value:0|max_value:1000000"
                              :separation="true"
                              roundType="down"
                              :mode="value.changedFlag[3] ? 'Edit' : 'View'"
                            />
                          </b-col>
                          <b-col cols="1" class="checkbox center mt-2">
                            <b-form-checkbox
                              :id="`Check_3`"
                              :name="`Check_3`"
                              v-model="value.changedFlag[3]"
                              @change="changeCheckBox()"
                              size="lg"
                            >
                            </b-form-checkbox>
                          </b-col>
                          <b-col cols="2" />
                        </b-row>
                        <b-row>
                          <b-col cols="3">
                            擁壁面の長さ(m)
                          </b-col>
                          <b-col cols="3">
                            平均の高さ(m)
                          </b-col>
                          <b-col cols="3">
                            1㎡当たりの土止費(円)
                          </b-col>
                          <b-col cols="3" />
                        </b-row>
                        <b-row>
                          <b-col cols="3">
                            <allyText
                              title="擁壁面の長さ(m)"
                              :nonAppear="true"
                              name="sheathingLength"
                              type="number"
                              v-model="value.sheathingLength"
                              rules="aandbrequired:@擁壁面の平均の高さ(m)|anyrequired:@整地に要する面積(㎡),@伐採・抜根を要する面積(㎡),@地盤改良を要する面積(㎡),@土盛りを要する面積(㎡),@傾斜度(度)"
                              min="0"
                              max="1000000"
                              :separation="true"
                              :floatLength="2"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="3">
                            <allyText
                              title="擁壁面の平均の高さ(m)"
                              :nonAppear="true"
                              name="sheathingHight"
                              type="number"
                              v-model="value.sheathingHight"
                              rules="min_value:0|max_value:1000000|aandbrequired:@擁壁面の長さ(m)"
                              :separation="true"
                              :floatLength="2"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="3">
                            <allyText
                              title="1㎡当たりの土止費(円)"
                              :nonAppear="true"
                              name="sheathingUnitPrice"
                              type="number"
                              v-model="value.sheathingUnitPrice"
                              rules="required|min_value:0|max_value:1000000"
                              :separation="true"
                              roundType="down"
                              :mode="value.changedFlag[4] ? 'Edit' : 'View'"
                            />
                          </b-col>
                          <b-col cols="1" class="checkbox center mt-2">
                            <b-form-checkbox
                              :id="`Check_4`"
                              :name="`Check_4`"
                              v-model="value.changedFlag[4]"
                              @change="changeCheckBox()"
                              size="lg"
                            >
                            </b-form-checkbox>
                          </b-col>
                          <b-col cols="2" />
                        </b-row>
                      </div>
                      <div class="col-md-12">
                        傾斜地
                        <b-row>
                          <b-col cols="3">
                            傾斜度(度)
                          </b-col>
                          <b-col cols="9" />
                        </b-row>
                        <b-row>
                          <b-col cols="3">
                            <allyText
                              title="傾斜度(度)"
                              :nonAppear="true"
                              name="slopeAngle"
                              type="number"
                              v-model="value.slopeAngle"
                              rules="anyrequired:@整地に要する面積(㎡),@伐採・抜根を要する面積(㎡),@地盤改良を要する面積(㎡),@土盛りを要する面積(㎡),@擁壁面の長さ(m)"
                              min="0"
                              max="30"
                              :separation="true"
                              roundType="down"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="3">
                            伐採・抜根を要する面積(㎡)
                          </b-col>
                          <b-col cols="3">
                            1㎡当たりの伐採・抜根費(円)
                          </b-col>
                          <b-col cols="6" />
                        </b-row>
                        <b-row>
                          <b-col cols="3">
                            <allyText
                              name="loggingSize1"
                              type="number"
                              v-model="value.loggingSize1"
                              rules="min_value:0|max_value:1000000"
                              :separation="true"
                              :floatLength="2"
                              roundType="down"
                              mode="View"
                            />
                          </b-col>
                          <b-col cols="3">
                            <allyText
                              name="loggingUnitPrice"
                              type="number"
                              v-model="value.loggingUnitPrice"
                              rules="min_value:0|max_value:1000000"
                              :separation="true"
                              roundType="down"
                              mode="View"
                            />
                          </b-col>
                          <b-col cols="6" />
                        </b-row>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom : 10px">
                    <div class="row">
                      <div
                        class="col-md-12"
                        v-if="
                          value.useType &&
                            value.useType != 1 &&
                            value.useType != 5 &&
                            value.useType != 6 &&
                            value.useType != 8
                        "
                      >
                        ※借地権割合を入力してください。
                        <div class="row">
                          <allyText
                            class="col-12"
                            title="借地権割合(％)"
                            name="rentLandRate"
                            type="number"
                            v-model="value.rentLandRate"
                            rules="required|min_value:0|max_value:100"
                            :separation="true"
                            roundType="down"
                          />
                        </div>
                      </div>
                      <div class="col-md-12" v-if="value.useType == 3 || value.useType == 6">
                        ※「賃貸割合」を入力してください。
                        <div class="row">
                          <allyText
                            class="col-12"
                            title="賃貸割合(％)"
                            name="lendlRate"
                            v-model="value.lendRate"
                            type="number"
                            rules="required|min_value:0|max_value:100"
                            :separation="true"
                            roundType="down"
                          />
                        </div>
                      </div>
                      <div class="col-md-12" v-if="value.useType == 4" style="margin-bottom : 10px">
                        <div class="row">
                          <div class="col-md-12">
                            ※「借家人の有する権利」の評価が必要ですか。
                            <div class="row">
                              <button
                                style="margin-left : 10px"
                                class="btn btn-outline-ally"
                                v-bind:class="{ active: value.rentHouseFlg == true }"
                                v-on:click="
                                  value.rentHouseFlg == true
                                    ? (value.rentHouseFlg = false)
                                    : (value.rentHouseFlg = true)
                                "
                              >
                                はい
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-12"
                        v-if="value.useType == 3 || value.useType == 6 || value.rentHouseFlg"
                      >
                        ※借家権割合を入力してください。
                        <div class="row">
                          <allyText
                            class="col-12"
                            title="借家権割合(％)"
                            name="rentHouseRate"
                            type="number"
                            v-model="value.rentHouseRate"
                            rules="required|min_value:0|max_value:100"
                            :separation="true"
                            roundType="down"
                          />
                        </div>
                      </div>
                      <div class="col-md-12" v-if="value.rentHouseFlg">
                        ※「賃借割合」を入力してください。
                        <div class="row">
                          <allyText
                            class="col-12"
                            title="賃貸割合(％)"
                            name="lendHouseRate"
                            type="number"
                            v-model="value.lendHouseRate"
                            rules="required|min_value:0|max_value:100"
                            :separation="true"
                            roundType="down"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row ml-4 flex-column">
                <div class="row">
                  <div class="row">
                    <div class="col-md-12">
                      ※家屋がある場合のみ、入力してください。
                      <div class="" style="margin-bottom : 10px">
                        <button
                          style="margin-left : 10px"
                          class="btn btn-outline-ally"
                          v-bind:class="{ active: value.houseFixedAssetTaxFlg }"
                          v-on:click="
                            value.houseFixedAssetTaxFlg === 'true'
                              ? (value.houseFixedAssetTaxFlg = 'false')
                              : (value.houseFixedAssetTaxFlg = 'true')
                          "
                        >
                          家屋（固定資産税評価額）
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row ml-0" style="margin-bottom : 10px">
                  <div class="row" v-if="value.houseFixedAssetTaxFlg === 'true'">
                    <div class="col-md-12">
                      <div class="row">
                        <allyText
                          class="col-12"
                          title="家屋の固定資産税評価額（円）"
                          name="houseFixedAssetTax"
                          type="number"
                          v-model="value.houseFixedAssetTax"
                          rules="required|max_value:100000000000|min_value:0"
                          :separation="true"
                          roundType="down"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row" style="float : right">
                <button class="btn btn-ally" v-on:click="calculation()">
                  計算
                </button>
              </div>
            </div>
          </ValidationObserver>
        </b-card-body>
      </b-card>
      ※あくまでも概算計算の為、正式には税理士へご相談ください。
    </div>
    <allyAlert :code="'E111'"></allyAlert>

    <!--権利の評価明細計算-->
    <div v-show="mode == 'View'">
      <b-card no-body class="category margin-bottom">
        <b-card-header class="card-header">
          <b>権利の評価明細計算</b>
        </b-card-header>

        <!--計算結果欄-->
        <b-card-body>
          <!--計算結果の概要-->
          <table class="table text-center ally-table route-price" style="margin-bottom : 10px">
            <thead>
              <tr>
                <td>
                  <b>{{ '相続税評価対象のタイプ' }}</b>
                </td>
                <td v-if="value.calcMode == 2">
                  <b>地積</b>
                </td>
                <td>
                  <b>{{ value.calcMode == 2 ? '固定資産税評価額(円)' : '1㎡当たりの価額' }}</b>
                </td>
                <td>
                  <b>{{ value.calcMode == 2 ? '倍率' : '地積' }}</b>
                </td>
                <td>
                  <b>総額</b>
                </td>
              </tr>
            </thead>
            <tbody style="text-align: right;">
              <tr>
                <td>
                  {{ value.calcMode == 2 ? '路線価地区区分倍率地域の場合' : '路線価地域の場合' }}
                </td>
                <td v-if="value.calcMode == 2">
                  {{value.landSize}} ㎡
                </td>
                <td>
                  {{
                    value.resultValue.resultPrice > 0
                      ? value.resultValue.resultPrice.toLocaleString()
                      : 0
                  }}
                  {{ '円' }}
                </td>
                <td class="landTaxDetail">
                  {{ value.calcMode == 2 ? value.landMagnification : value.landSize }}
                  {{ value.calcMode == 2 ? '倍' : '㎡' }}
                </td>
                <td>{{ value.totalPrice > 0 ? parseInt(value.totalPrice).toLocaleString() : 0 }} 円</td>
              </tr>
              <tr v-if="value.houseFixedAssetTaxFlg === 'true'">
                <td>
                  {{ '家屋（固定資産税評価額）' }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {{ value.houseFixedAssetTax ? parseInt(value.houseFixedAssetTax).toLocaleString() : 0 }}
                  円
                </td>
              </tr>
              <tr>
                <td>{{ '合計' }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {{
                    (
                      parseInt(value.totalPrice) +
                      parseInt(this.value.houseFixedAssetTax ? this.value.houseFixedAssetTax : 0)
                    ).toLocaleString()
                  }}
                  円
                </td>
              </tr>
            </tbody>
          </table>

          <b-button
            v-if="value.calcMode == 1"
            v-b-toggle.calcDetail
            variant="ally"
            class="margin-bottom offset-left-auto serch-object"
          >
            計算内容を確認▼
          </b-button>
          <b-button
            variant="ally"
            class="margin-bottom offset-left-auto serch-object"
            v-on:click="mode = 'Input'"
          >
            修正する
          </b-button>
          <b-collapse id="calcDetail" v-if="value.calcMode == 1">
            <table class="table text-center ally-table route-price" id="calcDetail">
              <thead>
                <tr>
                  <th>土地の評価明細</th>
                  <th>金額</th>
                  <!--<th>計算記号</th>-->
                </tr>
              </thead>
              <tbody style="background-color: white">
                <tr v-for="row in value.resultValue.calcArray" :key="row.Id">
                  <td>
                    {{ row.text }}
                  </td>
                  <td style="text-align: right;">{{ row.resultPrice.toLocaleString() }} 円</td>
                  <!--<td>{{ row.resultPriceType }}</td>-->
                </tr>
                <tr v-if="value.resultValue.calcArray.length <= 0">
                  <td colspan="3">
                    詳細項目がありません
                  </td>
                </tr>
              </tbody>
            </table>
          </b-collapse>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
  import { getBaseRate } from './modules/inheritanceTaxCalc';

  export default {
    props: ['value', 'commonMode', 'calcFlg'],
    data: function() {
      return {
        bigFactoryCheckFlg: false,
        bigLandCheckFlg: false,
        mode: 'Input',
        ownerIsUser: false,
        messageFlg: null,
        //顧客仮登録モーダル呼び出し判定要Flag
        newCustomerFlg: true,
        newCustomerCheckName: null,
        regiditTempCustomerCheckName: null,
        customerType: '個人',
      };
    },
    watch: {
      //メインviewに入力データを更新
      value: {
        deep: true,
        handler: function() {
          this.$emit('update:inheritanceTaxInput', this.value);
          //三大都市圏のチェック
          if (this.value.landSize >= 500) {
            this.bigLandCheckFlg = true;
          } else {
            this.bigLandCheckFlg = false;
          }
          //地区区分が大工場の場合、不整形地の選択を不可にする
          if (this.value.areaType == 7) {
            this.bigFactoryCheckFlg = true;
            this.value.irregularLandFlg = false;
          } else {
            this.bigFactoryCheckFlg = false;
          }
          //無道路地の判定
          // 路線価地域かつ、不整形地(F)または地積規模の大きな宅地(G)の場合に判定させる(地区区分に関係なし)
          if (
            this.value.calcMode == '1' &&
            (this.value.irregularLandFlg ||
              this.value.landSize >= 1000 ||
              (this.value.landSize >= 500 && this.value.bigLandFlg))
          ) {
            this.value.noRoadLandCheckFlg = true;
          } else {
            this.value.noRoadLandCheckFlg = false;
          }
          if (this.value.useType != 4) {
            this.value.rentHouseFlg = false;
          }
          this.changeCheckBox();
        },
      },
      ownerIsUser: {
        handler: async function() {
          //所有者情報に不備がある場合はエラーメッセージを出力する
          const isvaild = await this.$refs.customerInputCheck.validate();
          if (isvaild) {
            this.changeUserInfo();
          } else {
            this.ownerIsUser = false;
            this.showError('E110');
          }
        },
        deep: true,
      },
      'input.ownerName': function() {
        //顧客管理から呼び出した名前が変わった場合
        if (
          this.newCustomerCheckName != this.input.ownerName &&
          this.newCustomerCheckName != null
        ) {
          //仮登録を行ったかをチェック
          if (this.regiditTempCustomerCheckName != null) {
            //仮登録した名前が変わった場合
            if (this.regiditTempCustomerCheckName != this.input.ownerName) {
              this.newCustomerFlg = true;
            }
          } else {
            this.newCustomerFlg = true;
          }
        }
      },
      calcFlg: function() {
        this.calculation();
      },
    },
    computed: {
      getBaseRate,
    },
    methods: {
      calculation: async function() {
        try {
          const isValid = await this.$refs.inputCheck.validate();
          if (!isValid) {
            alert('入力内容に不備があります。');
            return false;
          }

          // 小数点切り捨て補正処
          this.value.frontageDistance = Math.floor(Number(this.value.frontageDistance) * 10) / 10;
          this.value.depthDistance = Math.floor(Number(this.value.depthDistance) * 10) / 10;
          this.value.landSize = Math.floor(Number(this.value.landSize) * 100) / 100;
          this.value.landPrice.front = Math.floor(Number(this.value.landPrice.front));
          this.value.landPrice.back = Math.floor(Number(this.value.landPrice.back));
          this.value.landPrice.side1 = Math.floor(Number(this.value.landPrice.side1));
          this.value.landPrice.side2 = Math.floor(Number(this.value.landPrice.side2));
          this.value.landMagnification =
            Math.floor(Number(this.value.landMagnification) * 100) / 100;
          this.value.fixedAssetTax = Math.floor(Number(this.value.fixedAssetTax));
          this.value.irregularLandFrontageDistance =
            Math.floor(Number(this.value.irregularLandFrontageDistance) * 10) / 10;
          this.value.irregularLandDepthDistance =
            Math.floor(Number(this.value.irregularLandDepthDistance) * 10) / 10;
          this.value.noRoadLandSize = Math.floor(Number(this.value.noRoadLandSize) * 100) / 100;
          this.value.slantedLandSize = Math.floor(Number(this.value.slantedLandSize) * 100) / 100;
          this.value.multipleFloorAreaRatioDeductionRate =
            Math.floor(Number(this.value.multipleFloorAreaRatioDeductionRate) * 1000) / 1000;
          this.value.setBackSize = Math.floor(Number(this.value.setBackSize) * 100) / 100;
          this.value.cityRoadFactor = Math.floor(Number(this.value.cityRoadFactor) * 100) / 100; // 未使用
          this.value.preparationSize = Math.floor(Number(this.value.preparationSize) * 100) / 100;
          this.value.preparationUnitPrice = Math.floor(Number(this.value.preparationUnitPrice));
          this.value.loggingSize1 = Math.floor(Number(this.value.loggingSize1) * 100) / 100;
          this.value.loggingUnitPrice = Math.floor(Number(this.value.loggingUnitPrice));
          this.value.stabilizationSize =
            Math.floor(Number(this.value.stabilizationSize) * 100) / 100;
          this.value.stabilizationUnitPrice = Math.floor(Number(this.value.stabilizationUnitPrice));
          this.value.earthworkSize = Math.floor(Number(this.value.earthworkSize) * 100) / 100;
          this.value.earthworkHight = Math.floor(Number(this.value.earthworkHight) * 100) / 100;
          this.value.earthworkUnitPrice = Math.floor(Number(this.value.earthworkUnitPrice));
          this.value.sheathingLength = Math.floor(Number(this.value.sheathingLength) * 100) / 100;
          this.value.sheathingHight = Math.floor(Number(this.value.sheathingHight) * 100) / 100;
          this.value.sheathingUnitPrice = Math.floor(Number(this.value.sheathingUnitPrice));
          this.value.slopeAngle = Math.floor(Number(this.value.slopeAngle));
          this.value.rentLandRate = Math.floor(Number(this.value.rentLandRate));
          this.value.lendRate = Math.floor(Number(this.value.lendRate));
          this.value.rentHouseRate = Math.floor(Number(this.value.rentHouseRate));
          this.value.lendHouseRate = Math.floor(Number(this.value.lendHouseRate));

          var inheritanceTaxInput = {
            calcMode: this.value.calcMode,
            landSize: this.value.landSize,
            landPrice: {
              front: this.value.landPrice.front,
              back: this.value.landPrice.back,
              side1: this.value.landPrice.side1,
              side2: this.value.landPrice.side2,
            },
            landMagnification: this.value.landMagnification,
            houseFixedAssetTax: this.value.houseFixedAssetTax,
            houseFixedAssetTaxFlg: this.value.houseFixedAssetTaxFlg,
            fixedAssetTax: this.value.fixedAssetTax,
            frontageDistance: this.value.frontageDistance,
            depthDistance: this.value.depthDistance,
            useType: this.value.useType,
            areaType: this.value.areaType,
            irregularLandFlg: this.value.irregularLandFlg,
            irregularLandFrontageDistance: this.value.irregularLandFrontageDistance,
            irregularLandDepthDistance: this.value.irregularLandDepthDistance,
            bigLandFlg: this.value.bigLandFlg,
            noRoadLandFlg: this.value.noRoadLandFlg,
            noRoadLandSize: this.value.noRoadLandSize,
            slantedLandFlg: this.value.slantedLandFlg,
            slantedLandDirection: this.value.slantedLandDirection,
            slantedLandSize: this.value.slantedLandSize,
            multipleFloorAreaRatioFlg: this.value.multipleFloorAreaRatioFlg,
            multipleFloorAreaRatioDeductionRate: this.value.multipleFloorAreaRatioDeductionRate,
            corner: this.value.corner,
            side1Corner: this.value.side1Corner,
            side2Corner: this.value.side2Corner,
            backCorner: this.value.backCorner,
            setBackFlg: this.value.setBackFlg,
            setBackSize: this.value.setBackSize,
            cityRoadFlg: this.value.cityRoadFlg,
            cityRoadFactor: this.value.cityRoadFactor,
            rentLandRate: this.value.rentLandRate,
            rentHouseRate: this.value.rentHouseRate,
            lendRate: this.value.lendRate,
            rentHouseFlg: this.value.rentHouseFlg,
            lendHouseRate: this.value.lendHouseRate,
            address: this.value.realEstateAddress.address1,
            farmlandFlag: this.value.farmlandFlag,
            changedFlag: this.value.changedFlag,
            preparationSize: this.value.preparationSize,
            preparationUnitPrice: this.value.preparationUnitPrice,
            loggingSize1: this.value.loggingSize1,
            loggingUnitPrice: this.value.loggingUnitPrice,
            stabilizationSize: this.value.stabilizationSize,
            stabilizationUnitPrice: this.value.stabilizationUnitPrice,
            earthworkSize: this.value.earthworkSize,
            earthworkHight: this.value.earthworkHight,
            earthworkUnitPrice: this.value.earthworkUnitPrice,
            sheathingLength: this.value.sheathingLength,
            sheathingHight: this.value.sheathingHight,
            sheathingUnitPrice: this.value.sheathingUnitPrice,
            slopeAngle: this.value.slopeAngle,
            // loggingSize2: this.value.loggingSize2,
          };
          let master = this.$store.state.master;
          var result = await getBaseRate(inheritanceTaxInput, master);
          //土地＋家屋の場合は家屋に値を入れる
          this.value.resultValue.resultPriceType = result.priceType;
          this.value.resultValue.resultPrice = result.price;
          this.value.resultValue.calcArray = result.calcArray;
          if (this.value.calcMode == 2) {
            // 路線価地区区分倍率地域の場合は、固定資産税評価額(円)ｘ土地の倍率
            // 実際には地積規模の大きな宅地の評価(↑倍率地域)と比べて低い方を採用するので、正面路線価なども要るのでは？
            this.value.totalPrice = Math.floor(
              this.value.resultValue.resultPrice * this.value.landMagnification 
            );
          } else {
            // 路線価地域の場合は、評価額(円)ｘ地積(㎡)
            this.value.totalPrice = Math.floor(
              this.value.resultValue.resultPrice * this.value.landSize
            );
          }
          this.mode = 'View';
        } catch (e) {
          console.log(e);
          window.alert('相続税の計算でエラーが発生しました。');
          return false;
        }

        return true;
      },

      //顧客仮登録の会社名を取得する
      getGroup: function() {
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];

        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');

        return groups[groupIndex];
      },
      //所有者情報を初期化する
      clearOwner: function() {
        (this.value.ownerName = null),
          (this.value.ownerId = null),
          (this.value.ownerAddress = null),
          (this.value.ownerCompanyId = null),
          (this.value.ownerIsUser = null);
      },
      applyInput: async function() {
        const isValid = await this.$refs.commonCheck.validate();
        if (!isValid) {
          this.$bvModal.show('E108');
          return;
        }
      },
      // チェックボックス変更時
      changeCheckBox: function() {
        let farmlandMaster = this.$store.state.master.interitanceMaster11.slice(1);
        let farmland = farmlandMaster.filter(f => f[1] === this.value.realEstateAddress.address1);

        if (farmland?.length > 0) {
          if (!this.value.changedFlag[0]) {
            // 平坦地単価
            this.value.preparationUnitPrice = parseInt(farmland[0][2]);
          }
          if (!this.value.changedFlag[1]) {
            // 伐採・抜根単価
            this.value.loggingUnitPrice = parseInt(farmland[0][3]);
          }
          if (!this.value.changedFlag[2]) {
            // 地盤改良単価
            this.value.stabilizationUnitPrice = parseInt(farmland[0][4]);
          }
          if (!this.value.changedFlag[3]) {
            // 土盛り単価
            this.value.earthworkUnitPrice = parseInt(farmland[0][5]);
          }
          if (!this.value.changedFlag[4]) {
            // 土止め単価
            this.value.sheathingUnitPrice = parseInt(farmland[0][6]);
          }
        }
      },
    },
  };
</script>
<style scoped>
  .margin-bottom {
    margin-bottom: 15px;
  }
  .margin-top {
    margin-top: 15px;
  }
  .card-header {
    transition: 0.2s;
    background: rgba(var(--colorThemeRGB));
    color: white;
  }
  .landTaxDetail {
    background: rgba(var(--colorThemeRGB), 0.2);
    margin: 10px;
  }
  .border-table {
    border-style: ridge;
    border-color: rgba(var(--colorThemeRGB), 0.8);
  }
  .margin-bottom {
    margin-bottom: 15px;
  }
</style>
