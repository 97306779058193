// @ts-check
import { createNode, PvNode } from '../node/pvNode';
import { PvObject, tempParamsMap } from '../node/pvObject';

/**
 * ツリーのクローンを作成
 * @template {PvNode} T
 * @param {T} node
 * @param {boolean?} sameId
 * @returns {T}
 */
function _clone(node, sameId = false) {
  const clone = cloneNodeSingle(node, sameId ? node.id : null);
  clone.appendChildren(
    node.children.map(c => {
      return _clone(c, sameId);
    })
  );
  return clone;
}

/**
 * 対象のオブジェクト単体をコピー
 * @template {PvNode} T
 * @param {T} target
 * @param {string?} id
 * @returns {T}
 */

export function cloneNodeSingle(target, id = null) {
  const clone = createNode(
    /** @type {import('../../types/utilType').Constructor<T>} */ (target.constructor),
    target,
    id
  );

  clone.parent = null;
  clone.firstChild = null;
  clone.rightBrother = null;

  // @ts-ignore
  return clone;
}

/**
 * ツリーのクローンを作成
 * @template {PvNode} T
 * @param {T} root
 * @returns {T}
 */
export function cloneNode(root) {
  const clone = _clone(root, false);
  return clone;
}

/**
 * 親の座標を引き継ぐ
 * @template {PvObject} T
 * @param {T} root
 * @returns {T}
 */

export function cloneNodeStandalone(root) {
  const clone = _clone(root);
  if (root.parent != null) {
    clone.transform = root.parent
      .absoluteMatrix()
      .transform(clone.transform.matrix())
      .decompose();
  }
  return clone;
}

/**
 * 親のtransformごとコピー
 * @template {PvObject} T
 * @param {T} target
 * @returns {{
 * targetClone: T
 * rootClone: PvObject
 * }}
 */

export function cloneNodeWithTransform(target) {
  if (target.parent == null) {
    throw new Error('親要素が存在しない場合はcloneTreeを使用してください');
  }
  const clones = [];
  target.upSearch(node => {
    if (node.id === target.id) {
      return;
    }
    const tr = node.transform.clone();
    /** 循環参照が起きるのでGroupが使えない */
    const group = createNode(PvObject);
    group.type = 'group';
    group.transform = tr;

    clones.push(group);
  });

  let tempNode = clones[0];
  let child = clones[0];
  clones.slice(1).forEach(node => {
    child = node;
    tempNode.appendChild(node);
  });

  const clone = _clone(target);
  child.appendChild(clone);

  return { targetClone: clone, rootClone: tempNode };
}

/**
 * paramsのプロパティのディープコピーをobjに割り当てる
 * @template {PvNode} T
 * @param {T} obj
 * @param {Partial<T>} params
 */
export function cloneParams(obj, params) {
  Object.keys(params).forEach(key => {
    if (params[key] != null && !tempParamsMap[key]) {
      obj[key] = cloneValue(params[key]);
    }
  });
}

/**
 * オブジェクトのプロパティ単体をコピー
 * @param {unknown} val
 */
function cloneValue(val) {
  if (val == null) {
    return null;
  } else if (typeof val !== 'object') {
    return val;
  } else if (val['clone'] != null) {
    // @ts-ignore
    return val.clone();
  } else if (Array.isArray(val)) {
    return val.map(v => cloneValue(v));
  } else {
    return { ...val };
  }
}

/**
 * ルートのみをクローンに置き換える
 * @param {PvNode} obj
 * @param {string?} id
 */
export function cloneNodeShallow(obj, id = null) {
  const clone = cloneNodeSingle(obj, id);
  clone.appendChildren(obj.children);

  return clone;
}

/**
 * @template {PvNode} T
 * @param {T} obj
 * @returns {T}
 */
export function cloneRenewNodeDeep(obj) {
  const clone = _clone(obj, true);
  return clone;
}
