<!--
作成者：Park Chongmyung
作成日：2020.6.3
画面名：マスタデータ登録・修正・照会
機能：マスタデータ登録・修正・照会
親子関係：View-corp.vue: システムの場合、View-personal.vue: カスタムの場合
更新者：Nakatsuka Daisuke
①
更新内容：共通データ不動産
①
-->
<template>
  <div>
    <div class="row grid-margin">
      <h4 class="font-weight-bold">
        共通情報管理
      </h4>
    </div>
    <div class="row grid-margin">
      <div class="ally-input-background card col-12">
        <div class="card-body">
          <div class="row" v-if="mode != 'Edit'">
            <allySelect
              class="col-6"
              title="分類"
              name="master"
              :items="[
                { text: '相続税', value: 'inheritanceTax' },
                { text: '不動産活用・投資分析', value: 'realEstateSuitability' },
              ]"
              v-model="masterType"
            />
          </div>
          <hr />
          <InheritanceTax
            v-if="masterType == 'inheritanceTax'"
            :masterType="masterType"
            :companyId="companyId"
          >
          </InheritanceTax>
          <RealEstateSuitability
            v-if="masterType == 'realEstateSuitability'"
            :masterType="masterType"
            :companyId="companyId"
            :mode="mode"
          >
          </RealEstateSuitability>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { API } from 'aws-amplify';
  import InheritanceTax from './MasterComponent/InheritanceTax';
  import RealEstateSuitability from './MasterComponent/RealEstateSuitability';

  API.configure(awsconfig);

  export default {
    props: ['parentsValue', 'mode', 'type'],
    data: function() {
      return {
        value: null,
        masterType: null,
        companyId: this.getGroup(),
      };
    },
    mounted: async function() {
      if (this.parentsValue) {
        this.value = this.parentsValue;
      }
      if (this.type) {
        if (this.type == 'realEstate') {
          this.masterType = 'realEstateSuitability';
        } else {
          this.masterType = this.type;
        }
      }
    },
    methods: {
      getGroup: function() {
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        if (groups[groupIndex] == null) {
          return null;
        }
        return groups[groupIndex].replace('Company-', '');
      },
    },
    watch: {},
    components: {
      InheritanceTax,
      RealEstateSuitability,
    },
  };
</script>

<style scoped>
  .ally-radio {
    width: calc(100% / var(--radioCount));
    border: solid 1px #ced4da;
    text-align: center;
    height: 2.875rem;
    padding: 0.875rem 1.375rem;
    background-color: white;
  }

  .ally-radio input {
    visibility: hidden;
  }

  .ally-radio-selected {
    color: white;
    background-color: var(--colorTheme);
  }

  .ally-tab label {
    margin-bottom: 0;
  }
</style>
<style>
  #inputMasterModal input[type='number'],
  #inputMasterModal .number input[type='text'] {
    text-align: right;
  }

  #inputMasterModal fieldset.form-group {
    margin-bottom: 0rem;
  }

  #inputMasterModal > div > div > div {
    padding: 2rem;
  }

  #inputMasterModal td {
    padding: 0.25rem;
  }
</style>
