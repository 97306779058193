import { PvRoot } from './rootNode';

/**
 *  CLASS: エディタ上に表示されるオブジェクトのデータ
 */
export class PvRootPattern {
  /** @type {string}  PROPERTY: パターン名 */
  name = 'パターンA';
  /** @type {PvRoot[]}  PROPERTY: ルートの配列 */
  layersRoot = [];

  /**
   * @param {PvRoot[]} layersRoot
   */
  constructor(layersRoot) {
    this.layersRoot = layersRoot;
  }
}
