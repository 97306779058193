// @ts-check
import { LENGTH_PER_PX } from '../../util/const';
import { Vec } from '../../util/math/vector';
import { PvObject } from './pvObject';

export class PvMeasurePart extends PvObject {
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'measurePart';
  /** @type {number[]} 目盛り位置 */
  scales = [];
  /** @type {string} 単位 */
  unit = 'px';

  constructor() {
    super();
  }

  get isHorizontal() {
    return this.index % 2 === 0;
  }

  /** @type {number} 長さの倍率 */
  get lengthScale() {
    return LENGTH_PER_PX[this.unit];
  }

  /**
   * 目盛り
   * @returns {number[]}
   */
  getScaleIntervals() {
    if (this.scales.length < 2) {
      return [];
    }
    let prev = this.scales[0];
    if (prev == null) return [];

    const result = this.scales
      .map(v => {
        const diff = v - prev;
        prev = v;
        return Math.abs(diff);
      })
      .filter(v => v > 0);
    return result;
  }

  /**
   * 目盛り
   * @param {Vec[]} absoluteVertexes
   * @returns {number[]}
   */
  getCenterOfScales(absoluteVertexes) {
    if (absoluteVertexes.length < 2) {
      return [];
    }

    let total = 0;
    const result = new Array(this.scales.length - 1);
    for (let i = 0; i < this.scales.length - 1; i++) {
      const dist = Math.abs(this.scales[i + 1] - this.scales[i]);
      result[i] = total + dist / 2;
      total += dist;
    }

    return result;
  }
}
