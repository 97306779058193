<template>
  <div class="margin-bottom">
    <div class="info-box">
      <b-container class="main">
        <b-row class="info-title">
          利用料のお支払いについて
        </b-row>
        <b-row class="info-content form-control overflow-auto " readonly>
          <b-col style="padding:0;">
            毎月1日から月末まで１ヶ月分の利用料を翌月２７日にお支払いいただきます。
            <br />
            <br />

            <img style="max-width: 100%;" :src="imageUrl" />
            <br />
            <br />
            <b>【お支払日について】</b><br />
            〈決済日〉利用月の翌月２７日<br />
            ※お引き落とし日はお客様がご契約のクレジットカード会社により異なります。<br />
            <br />
            <b>【お支払いが確認できなかったとき】</b><br />
            翌月以降、システムは自動で利用停止となります。<br />
            お支払いが確認された後、<span class="red ">再購入</span>の上ご利用ください。<br />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <br />
  </div>
</template>
<script>
  export default {
    name: 'info',
    props: ['infoCheck'],
    data: function() {
      return {
        infoChecker: this.infoCheck,
        space: '　',
        imageUrl:
          'https://allys3163848-dev.s3.ap-northeast-1.amazonaws.com/Master/manual/aboutPaymentImage.png',
      };
    },
    watch: {
      infoChecker: function() {
        this.$emit('update:infoCheck', this.infoChecker);
      },
    },
  };
</script>
<style scoped>
  .margin-bottom {
    margin-bottom: 15px;
  }
  .info-box {
    background-color: rgba(43, 160, 132, 0.2);
  }
  .info-title {
    font-weight: bold;
    padding-top: 5px;
    margin-left: -5px;
  }
  .info-content {
    width: calc(30px + 100%);
    height: calc(35rem - 43px);
    font-size: 14px;
  }
  .info-content table td:first-child {
    display: flex;
  }
  .check-info {
    margin-bottom: 10px;
    text-align: center;
  }
  .check-info >>> .custom-control-input:checked ~ .custom-control-label::before {
    background: var(--colorTheme);
    border-color: var(--colorTheme);
  }
  .red {
    color: #ff0000;
    text-decoration: underline; /*下線を引く*/
  }
</style>
