<!--
作成者：本間
作成日：2021.02.03
画面名：投資分析/出力
機能：資金計画
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <b-tab id="FinancingPlan" title="資金計画">
    <b-card class="card-result" title="資金計画">
      <b-card-group columns>
        <!-- 総事業費 -->
        <b-card no-body>
          <b-container class="report">
            <b-row>
              <b-col class="text-right">
                単位：円
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title emboss text-left">{{ result.totalProjectCost.name }}</b-col>
              <b-col class="title value colspan">
                {{ result.totalProjectCost.amount | currency }}
              </b-col>
            </b-row>
            <b-row
              v-for="(item, index) in result.totalProjectCost.items"
              v-bind:key="`totalProjectCost_${index}`"
            >
              <b-col cols="1" class="title blank_space"> </b-col>
              <b-col cols="6" class="subtitle">{{ item.name }}</b-col>
              <b-col class="value">{{ item.amount | currency }}</b-col>
            </b-row>
          </b-container>
        </b-card>
        <!-- 借入条件 -->
        <b-card no-body>
          <b-container class="report">
            <b-row>
              <b-col class="text-right">
                単位：円
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title  emboss text-left">{{ result.borrowingConditions.name }}</b-col>
            </b-row>
            <span
              v-for="(item1, index1) in result.borrowingConditions.items"
              v-bind:key="`borrowingConditions_${index1}`"
            >
              <span v-if="item1.items == undefined">
                <b-row>
                  <b-col cols="1" class="title blank_space"> </b-col>
                  <b-col cols="6" class="subtitle">{{ item1.name }}</b-col>
                  <b-col class="value">{{ item1.amount | currency }}{{ item1.unit }}</b-col>
                </b-row>
              </span>
              <span v-else>
                <span
                  v-for="(item2, index2) in item1.items"
                  v-bind:key="`borrowingConditions_${index1}_${index2}`"
                >
                  <b-row>
                    <b-col cols="1" class="title blank_space"> </b-col>
                    <b-col
                      cols="2"
                      class="subtitle "
                      v-bind:style="index2 == 0 ? '' : 'border-top-color:transparent;'"
                    >
                      {{ index2 == 0 ? item1.name : '' }}</b-col
                    >
                    <b-col cols="4" class="subtitle" style="padding-right:0;margin-left:0;">
                      {{ item2.name }}
                    </b-col>
                    <b-col class="value">{{ item2.amount | currency }}{{ item2.unit }}</b-col>
                  </b-row>
                </span>
              </span>
            </span>
            <b-row style="border-top:1px solid black" />
          </b-container>
        </b-card>
        <!-- 預託金償還条件 -->
        <b-card no-body>
          <b-container class="report">
            <b-row>
              <b-col class="text-right">
                単位：円
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title  emboss text-left">
                {{ result.depositRedemptionTerms.name }}
              </b-col>
            </b-row>
            <b-row
              v-for="(item, index) in result.depositRedemptionTerms.items"
              v-bind:key="`depositRedemptionTerms_${index}`"
            >
              <b-col cols="1" class="title blank_space"> </b-col>
              <b-col cols="6" class="subtitle">{{ item.name }}</b-col>
              <b-col class="value">{{ item.amount | currency }}{{ item.unit }}</b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-card-group>
    </b-card>
  </b-tab>
</template>
<script>
  import Calculation from '../Input/Calculation';
  export default {
    props: [],
    components: {},
    mixins: [Calculation],
    data: function() {
      return { result: null };
    },
    created: function() {
      this.result = this.initialize();
    },
    mounted: function() {},
    watch: {},
    computed: {},
    methods: {
      // 初期化
      initialize: function() {
        const cp = this.investmentAnalytics.initialInvestment.capitalPlan; // 資金計画
        let result = {
          // 総事業費
          totalProjectCost: {
            name: '総事業費',
            amount: this.totalProjectCost, // 総事業費/金額
            items: [
              {
                name: '自己資金',
                amount: Number(cp.ownResources),
              },
              {
                name: '保証金',
                amount: Number(cp.deposit),
              },
              {
                name: '建設協力金',
                amount: Number(cp.constructionCooperationFund),
              },
              {
                name: '敷金',
                amount: Number(cp.securityDeposit),
              },
              {
                name: '礼金',
                amount: Number(cp.keyMoney),
              },
              { name: '借入金', 
                amount: Number(cp.loans), 
              },
            ],
          },
          // 借入条件
          borrowingConditions: {
            name: '借入条件',
            items: [
              { name: '借入金', 
                amount: Number(cp.loans),
              },
              {
                name: '返済方法',
                amount: null,
                unit: cp.repaymentMethod,
              },
              {
                name: '借入期間',
                amount: Number(cp.repaymentPeriod),
                unit: '年',
              },
              {
                name: '金利',
                items: [
                  {
                    name: Number(cp.borrowingRateApplicationYears) + '年目まで',
                    amount: null,
                    unit: Number(cp.borrowingRate[0]).toFixed(2) + '％',
                  },
                  {
                    name: Number(cp.borrowingRateApplicationYears + 1) + '年目以降',
                    amount: null,
                    unit: Number(cp.borrowingRate[1]).toFixed(2) + '％',
                  },
                ],
              },
            ],
          },
          // 預託金償還条件
          depositRedemptionTerms: {
            name: '預託金償還条件',
            items: [
              {
                name: '保証金',
                amount: Number(cp.deposit),
              },
              {
                name: '敷金',
                amount: Number(cp.securityDeposit),
              },
              {
                name: '預託金合計',
                amount:
                  Number(cp.deposit) +
                  Number(cp.securityDeposit),
              },
              { name: '償還期間', amount: Number(cp.redemptionPeriod), unit: '年' },
            ],
          },
        };
        // [debug]計算結果
        //console.debug('[資金計画]');
        //console.debug(JSON.stringify(result));
        return result;
      },
    },
  };
</script>
<style scoped>
  #FinancingPlan .blank_space {
    max-width: fit-content;
    border-top-color: transparent !important;
  }
  #FinancingPlan .card {
    border-color: transparent;
    box-shadow: none;
  }
  #FinancingPlan .subtitle {
    padding-right: 0;
  }
  @media screen and (min-width: 576px) {
    #FinancingPlan .card-columns {
      column-count: 1;
    }
  }
  @media screen and (min-width: 768px) {
    /* iPad     - 縦 :  768 */
    #FinancingPlan .card-columns {
      column-count: 1;
    }
  }
  @media screen and (min-width: 992px) {
    /* iPad Pro - 縦 : 1024 */
    /* iPad     - 横 : 1024 */
    #FinancingPlan .card-columns {
      column-count: 2;
    }
  }
  @media screen and (min-width: 1200px) {
    /* iPad Pro - 横 : 1366 */
    #FinancingPlan .card-columns {
      column-count: 2;
    }
  }
  @media screen and (min-width: 1600px) {
    /* PC : 1600 */
    #FinancingPlan .card-columns {
      column-count: 3;
    }
  }
</style>
