var moment = require('moment-timezone');
moment.tz.setDefault('Asia/Tokyo');

export default {
  methods: {

    /**
     *  METHOD: 日付を任意の値やフォーマットで変更
     */
    changeDateString(dateStr, addNum, format, addTarget) {
      // 決済IDかをチェック
      if (
        moment('20' + dateStr, 'YYYYMM', true).isValid() &&
        moment('20' + dateStr).isBetween('2000-01', '3000-01', 'month') // 4桁決済IDの場合
      ) {
        // 日付にフォーマット
        dateStr = '20' + dateStr;
      } else if (
        moment('200' + dateStr, 'YYYYMM', true).isValid() &&
        moment('200' + dateStr).isBetween('2000-01', '3000-01', 'month') // 3桁決済IDの場合
      ) {
        // 日付にフォーマット
        dateStr = '200' + dateStr;
      }

      // 日付データへ変換
      const date = moment(dateStr);
      // データに問題がないかチェック
      if (!date.isValid()) {
        throw `changeDateString: 渡された日付に問題があります, dateStr: ${dateStr}, num: ${addNum} `;
      }
      // 日付を指定された数だけ移動
      if (addNum != 0) {
        date.add(addNum, addTarget ? addTarget : 'month');
      }
      // フォーマット
      const newPaymentId = date.format(format ? format : 'YYYY-MM-DD');
      return newPaymentId;
    },

    /**
     *  METHOD:  決済ID生成
     */
    createPaymentId(dateStr, addNum) {
      const addNumResult = addNum ? addNum : 0;
      // 日付または決済IDを任意月の決済ID用フォーマットへ変更し、数値に変換
      return parseInt(this.changeDateString(dateStr, addNumResult, 'YYMM'));
    },
  },
};
