<template>
  <div class="margin-bottom">
    <div class="info-box">
      <b-container class="main">
        <b-row class="info-title">
          プライバシーポリシー
        </b-row>
        <b-row class="info-content form-control overflow-auto " readonly>
          <b-col style="padding:0;">
            ダイナステージ株式会社（以下「弊社」といいます。）は、本ソフトウェア（体験版を含みます。）および本サービスに係る弊社のプライバシーポリシーとして、以下の個人情報保護に対する基本的方針および個人情報に関する公表事項を定め、公開いたします。<br />
            <br />
            【個人情報保護に対する基本方針】<br />
            弊社は、全てのお客様に安心して本ソフトウェアおよび本サービスをご利用いただくために、お預かりする個人情報を安全に管理することが至上命題であると考え、プライバシー保護を当社の企業活動における社会的責任と捉えています。弊社はこの責任を果たすために、以下の個人情報保護方針を定め、個人情報の適正な取り扱い、維持、管理に誠心誠意努めます。<br />
            <br />
            １．対象<br />
            弊社は、本ソフトウェアおよび本サービスに係る個人情報の保護に関し、本保護方針を適用いたします。<br />
            <br />
            ２．定義<br />
            弊社のプライバシーポリシーにおいて、個人情報とは、生存する個人に関する情報であって、以下のいずれかに該当するものをいいます。
            <table>
              <tr>
                <td>（１）</td>
                <td>
                  当該情報に含まれる氏名、生年月日その他の記述等（文書、図面もしくは電磁的記録に記載され、もしくは記録され、または音声、動作その他の方法を用いて表された一切の事項（個人識別符号を除く）をいう）により、特定の個人を識別できるもの（他の情報と容易に照合でき、それにより特定の個人を識別できるものを含む）
                </td>
              </tr>
              <tr>
                <td>（２）</td>
                <td>個人識別符号が含まれるもの</td>
              </tr>
            </table>

            <br />
            ３．個人情報の収集・利用・提供<br />
            弊社は、皆様の個人情報に関して、ご本人の同意なく無断で収集・利用することはありません。同意いただいた場合でも、あくまで同意を得た範囲内でかつ弊社が定めた利用目的の達成に必要な範囲内でのみ使用し、当該個人情報は同意がある場合、法律上要求されている場合および利用目的以外の達席に必要となる範囲内で委託する場合を除いて、第三者に提供いたしません。<br />
            <br />
            ４．個人情報の安全保護<br />
            弊社の保有する個人情報は厳重に管理されています。個人情報への不正アクセスや個人情報の盗難、紛失、破壊、改ざん、漏洩等を防ぐため、個人情報の保存・管理・廃棄ルールの徹底、社内ルールを定めて徹底し、その予防に努めます。<br />
            <br />
            ５．法令・社内規定の遵守<br />
            弊社は、個人情報に関する諸法令、所轄官庁の定める諸規則の遵守に努めます。<br />
            <br />
            ６．苦情・相談への対応<br />
            弊社は、弊社の個人情報の取扱いに関して、ご本人又はその他の方から苦情又は相談を受けた場合には、誠実にこれに対応し、また必要に応じて、弊社における個人情報の取り扱い方法を改善いたします。<br />
            <br />
            【個人情報に関する公表事項】<br />
            １．弊社の名称<br />
            ダイナステージ株式会社<br />
            <br />
            ２．個人情報保護に関する責任者<br />
            代表取締役社長{{ space }}菊池{{ space }}聡<br />
            <br />
            ３．個人情報の利用目的の公表に関する事項<br />
            弊社におきましては、本ソフトウェアまたは本サービス、電話、FAX、メールその他各種お問い合わせ等を通じ、ご本人からお預かりした個人情報を、以下の目的に使用します。<br />
            <br />
            （１）サービスの提供<br />
            ご本人の情報を、本ソフトウェアおよび本サービスを提供するために使用します。<br />
            <br />
            （２）サービスの維持、向上<br />
            ご本人の情報を、本ソフトウェアおよび本サービスを意図したとおりに稼働させるために使用します。また、本サービスを向上させるために使用します。<br />
            <br />
            （３）新しいサービスの開発<br />
            既存のサービスで収集した情報を、新しいサービスの開発に役立てます。<br />
            <br />
            （４）パフォーマンスの測定<br />
            本ソフトウェアおよび本サービスがどのように利用されているのかを把握、分析又は測定のために使用します。<br />
            <br />
            （５）ご本人とのコミュニケーション<br />
            弊社がご本人に直接ご連絡を差し上げるために使用します。<br />
            <br />
            （６）本ソフトウェアおよび本サービスのユーザー、一般の人々の保護<br />
            本ソフトウェアおよび本サービスの安全性と信頼性を向上させる取り組みに使用します。この取り組みには、本ソフトウェアおよび本サービスのユーザー、一般の人々に害を及ぼすおそれのある不正行為、不正使用、セキュリティ、リスク又は技術的な問題の検知、防止、対応などがあります。<br />
            <br />
            ４．認識し得ない方法による個人情報の収集<br />
            本ソフトウェアおよび本サービスでは、利便性の向上のため、ご本人が利用するWebサーバーに記録されたCookieを自動的に取得して利用する場合があります。<br />
            <br />
            ５．個人情報の第三者提供<br />
            弊社は、弊社の関連会社若しくはその他信頼できる企業又は個人および法令で許容される場合の他は、ご本人の事前の同意を得ることなく、個人情報を第三者に提供することはございません。<br />
            <br />
            ６．開示等の求めに係る手続き<br />
            弊社では、保有する個人情報のご本人から個人情報の利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去および第三者への提供の停止（以下、まとめて「開示等」といいます。）があった場合には、係る個人情報がデータベース化され、長期保有することが予定されている場合であって、弊社が開示等を行う権限を有している場合には、法令上の根拠を確認の上で、これに応じさせていただきます。<br />
            <br />
            （１）開示等の求めの対象となる項目<br />
            ３に記載の個人情報に係るすべて<br />
            <br />
            （２）開示等の求めのお申し出先<br />
            ダイナステージ株式会社<br />
            〒020-0861{{ space }}岩手県盛岡市仙北二丁目7番6号<br />
            TEL{{ space }}019-656-0300<br />
            Mail{{ space }}ally@dynastage.co.jp<br />
            <br />
            （３）開示等に際して提出する書面・手数料<br />
            弊社においては開示等に際して使用する特定の書式は定めておりません。また、弊社では開示等について手数料は徴しておりません。<br />
            <br />
            （４）ご本人確認<br />
            開示等にあたってはご本人確認のための運転免許証、パスポートなどの写真付き身分証明書の写しをご送付願います。また、代理人によるご連絡の場合には、代理関係を証明する書類を共に送付願います。<br />
            <br />
            （５）回答方法<br />
            原則として書面または電磁的方法により回答いたします。<br />
            <br />
            ７．苦情の受付窓口<br />
            ６（２）に同じです。なお、弊社の所属する認定個人情報保護団体等はありません。<br />
            <br />
            ８．プライバシーポリシーの改定<br />
            弊社は、本プライバシーポリシーに関して、有効かつ適正な運用がなされるよう継続的な見直しと改善をはかります。なお、本プライバシーポリシーを改定した場合、改訂版の公表時から改定後のプライバシーポリシーが適用されます。<br />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <br />
    <div class="check-info">
      <b-form-checkbox v-model="infoChecker">
        上記の内容を確認した上、「利用規約」及び「プライバシーポリシー」に同意します
      </b-form-checkbox>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'info',
    props: ['infoCheck'],
    data: function() {
      return {
        infoChecker: this.infoCheck,
        space: '　',
      };
    },
    watch: {
      infoChecker: function() {
        this.$emit('update:infoCheck', this.infoChecker);
      },
    },
  };
</script>
<style scoped>
  .margin-bottom {
    margin-bottom: 15px;
  }
  .info-box {
    background-color: rgba(43, 160, 132, 0.2);
  }
  .info-title {
    font-weight: bold;
    padding-top: 5px;
    margin-left: -5px;
  }
  .info-content {
    width: calc(30px + 100%);
    height: calc(35rem - 43px);
    font-size: 14px;
  }
  .info-content table td:first-child {
    display: flex;
  }
  .check-info {
    margin-bottom: 10px;
    text-align: center;
  }
  .check-info >>> .custom-control-input:checked ~ .custom-control-label::before {
    background: var(--colorTheme);
    border-color: var(--colorTheme);
  }
</style>
