<template>
  <div>
    <div class="row">
      <label class="col-12 font-weight-bold">
        職場情報
        <hr class="ally-divide" />
      </label>
    </div>
    <!-- <div class="row">
      <allyRadio
        class="col-12"
        title="入力区分"
        name="companyInfoInputType"
        :items="[
          { text: 'イメージ', value: 1 },
          { text: '手入力', value: 2 },
        ]"
        v-model="companyInfoInputType"
        :mode="mode"
        v-if="mode=='Add'"
      />
    </div> -->
    <div class="row">
      <allyImages
        :name="name"
        class="col-12"
        v-model="businessCard"
        :mode="mode"
        title="名刺"
        :ref="'image'"
        :loading="loading"
      />
    </div>
    <div class="row form-group">
      <div class="col-12">
        <table
          class="table text-center ally-table"
          id="family"
          :class="mode == 'View' ? 'ally-table-readonly' : ''"
        >
          <thead>
            <tr>
              <th colspan="3">会社情報</th>
            </tr>
          </thead>
          <tbody style="background-color: white">
            <tr v-for="(row, i) in companyInfo" :key="row.Id">
              <td>
                {{ row.companyName }}
              </td>
              <td>
                <b-button
                      size="sm"
                      @click="modalOpen(row, i)"
                      class="btn btn-ally mr-1 action-btn"
                    >
                      {{mode != 'View' ? '編集' : '詳細'}}
                </b-button>
              </td>
              <td>
                <b-button
                  v-if="mode != 'View'"
                  size="sm"
                  @click="deleteItem(i)"
                  class="btn btn-ally mr-1 action-btn"
                >
                  削除
                </b-button>
              </td>
            </tr>
            <tr v-if="mode != 'View'">
              <td colspan="4" class="text-left" style="outline: none;">
                <button class="btn btn-ally" v-b-modal="modalName">
                  追加
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <label class="col-12">
        <hr class="ally-divide" />
      </label>
    </div>
    <b-modal
      :id="modalName"
      title="会社情報追加"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="追加"
      :ok-disabled="mode != 'View' ? false : true"
      @ok="pushCompanyInfo"
      @hidden="companyInfoModalClear"
    >
      <ValidationObserver tag="div" slim :ref="'businessCardObserver'">
        <div class="row">
          <allyText
            class="col-12"
            title="会社名"
            name="companyInfoModal.companyName"
            v-model="companyInfoModal.companyName"
            :mode="mode"
            rules="required"
            placeholder="会社名"
          />
        </div>
        <div class="row">
          <allyText
            class="col-12"
            title="役職"
            name="companyInfoModal.position"
            v-model="companyInfoModal.position"
            :mode="mode"
            placeholder="役職"
          />
        </div>
        <div class="row">
          <allyText
            class="col-12"
            title="電話番号"
            name="companyInfoModal.tel"
            v-model="companyInfoModal.tel"
            :mode="mode"
            rules="required|tel"
            placeholder="09000000000"
          />
        </div>
        <div class="row">
          <allyText
            class="col-12"
            title="E-mail"
            name="companyInfoModal.mail"
            v-model="companyInfoModal.mail"
            :mode="mode"
            rules="email"
            placeholder="xxx@xxx.xx.jp"
          />
        </div>
        <div class="row">
          <allyText
            class="col-12"
            title="FAX番号"
            name="companyInfoModal.fax"
            v-model="companyInfoModal.fax"
            :mode="mode"
            rules="tel"
            placeholder="01960000000"
          />
        </div>
        <div class="row">
          <label class="col-12">
            <hr class="ally-divide" />
          </label>
        </div>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
  export default {
    props: ['mode', 'name', 'originBusinessCard', 'originCompanyInfo', 'loading'],
    data: function() {
      return {
        // companyInfoInputType: this.originCompanyInfo ? 2 : 1,
        updatebusinessCardInfo: {
          updateBusinessCard: this.originCompanyInfo,
          updateBusinessCardMap:this.originBusinessCard
        },
        businessCard: this.originBusinessCard,
        companyInfo: this.originCompanyInfo,
        companyInfoModal: {
          companyName: null,
          position: null,
          tel: null,
          mail: null,
          fax: null,
          editIndex: -1,
        },
      };
    },
    computed: {
      modalName: function() {
        return this.name ? this.name + 'companyInfoModal' : 'companyInfoModal';
      },
    },
    methods: {
      pushCompanyInfo: function(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.$refs.businessCardObserver.validate().then(isValid => {
          if (isValid && this.companyInfoModal.editIndex > -1) {
            var result = this.companyInfo[this.companyInfoModal.editIndex];

            result.companyName = this.companyInfoModal.companyName;
            result.position = this.companyInfoModal.position;
            result.tel = this.companyInfoModal.tel;
            result.mail = this.companyInfoModal.mail;
            result.fax = this.companyInfoModal.fax;

            this.$bvModal.hide(this.modalName);
            this.companyInfoModalClear();
          } else if (isValid) {
            if (this.companyInfo == null) this.companyInfo = [];
            this.companyInfo.push({
              companyName: this.companyInfoModal.companyName,
              position: this.companyInfoModal.position,
              tel: this.companyInfoModal.tel,
              mail: this.companyInfoModal.mail,
              fax: this.companyInfoModal.fax,
            });

            // this.updatebusinessCardInfo = {
            //   updateBusinessCard : this.companyInfo,
            //   // companyInfoInputType : this.companyInfoInputType
            // }

            this.updatebusinessCardInfo.updateBusinessCard = this.companyInfo;
            this.$emit('input', this.updatebusinessCardInfo);
            this.$bvModal.hide(this.modalName);
            this.companyInfoModalClear();
          }
        });
      },
      companyInfoModalClear: function(item, index) {
        this.companyInfoModal.companyName = item ? item.companyName : null;
        this.companyInfoModal.position = item ? item.position : null;
        this.companyInfoModal.tel = item ? item.tel : null;
        this.companyInfoModal.mail = item ? item.mail : null;
        this.companyInfoModal.fax = item ? item.fax : null;
        this.companyInfoModal.editIndex = index;
      },
      modalOpen: function(item, index) {
        this.$bvModal.show(this.modalName);
        this.companyInfoModalClear(item, index);
      },
      deleteItem: function(index){
        this.companyInfo.splice(index, 1)
      },
      fileUpload: async function() {
        await this.$refs.image.fileUpload();
      },
    },
    watch: {
      businessCard: {
        deep: true,
        handler: function() {
          // this.updatebusinessCardInfo = {
          //   updateBusinessCardMap : this.businessCard,
          //   // companyInfoInputType : this.companyInfoInputType
          // }
          this.updatebusinessCardInfo.updateBusinessCardMap = this.businessCard;
          this.$emit('input', this.updatebusinessCardInfo);
        },
      },
      // companyInfoInputType: function() {
      //   this.businessCard = null;
      //   this.companyInfo = null;
      //   this.$emit('input', 1, null);
      //   this.$emit('input', 2, null);
      // },
    },
  };
</script>