<template>
  <g :transform="transform" style="pointer-events: none">
    <circle :r="7" fill="red" style="pointer-events: none" />
  </g>
</template>
<script>
  // @ts-check
  import { computed, defineComponent } from '@vue/composition-api';

  export default defineComponent({
    props: {
      x: Number,
      y: Number,
    },
    setup(props) {
      const transform = computed(() => {
        return `translate(${props.x}, ${props.y})`;
      });
      return {
        transform,
      };
    },
  });
</script>
