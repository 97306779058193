// @ts-check
import { IMatrix, Matrix } from './matrix';

/**
 * @param {number} deg
 * @returns {number}
 */
export function degToRad(deg) {
  return (deg * Math.PI) / 180;
}

/**
 * @param {number} rad
 * @returns {number}
 */
export function radToDeg(rad) {
  return (rad * 180) / Math.PI;
}

/**
 *
 * @param {IMatrix} mat
 * @returns {Matrix}
 */
export function convertMat(mat) {
  return new Matrix([mat.a, mat.b, mat.c, mat.d, mat.e, mat.f]);
}
