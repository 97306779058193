<template>
  <div class="icon-menu-root">
    <div style="text-align: center; padding: 10px;">
      <select style="width: 150px; user-select: none; min-height: 25px" v-model="category">
        <option
          v-for="iconCategory in iconCategoryListItem"
          :value="iconCategory.id"
          :key="iconCategory.logicalName"
          >{{ iconCategory.logicalName }}
        </option>
      </select>
    </div>
    <GridView :items="filteredIcons" :height="listHeight">
      <template #item="{ item }">
        <div style="height: 55px" :id="item.logicalName">
          <img
            loading="lazy"
            v-show="!isLoading"
            class="icon-img"
            :src="item.url"
            alt=""
            ref="iconImg"
          />
          <b-spinner v-show="isLoading" label="Spinning" variant="success" type="grow"></b-spinner>
        </div>
        <div class="mt-2 icon-name">
          {{ item.logicalName }}
        </div>
      </template>
    </GridView>
    <div class="layout-menu-under"></div>
  </div>
</template>
<script>
  import { computed, defineComponent, ref, watchEffect, PropType } from '@vue/composition-api';
  import PvBtn from '../PvBtn';
  import GridView from './GridView.vue';

  export default defineComponent({
    components: {
      PvBtn,
      GridView,
    },
    props: {
      iconImages: Array,
      iconCategoryList: Array,
      isLoading: Boolean,
      height: Number,
    },
    setup(props) {
      const selectedIcon = ref({}); //  DATA: 選択中のアイコン
      const category = ref(1); //  DATA: 選択中のカテゴリ
      const listHeight = computed(() => {
        return props.height - 100;
      });

      /**
       *  COMPUTED: アイコンリストアイテムの取得
       */
      const filteredIcons = computed(() => {
        // console.log('IconMenu filteredIcons', props.iconImages);
        // 存在チェック
        if (props.iconImages == null || props.iconImages.length === 0) {
          return [];
        }
        return props.iconImages
          .filter(icon => icon.categoryId === category.value)
          .sort((a, b) => {
            // サブカテゴリIDでソート
            if (a.subCategoryId != b.subCategoryId) {
              return a.subCategoryId - b.subCategoryId;
            }
            // サブカテゴリIDが同じならソート番号でソート
            return a.sort - b.sort;
          });
      });

      /**
       *  COMPUTED: アイコン分類リストアイテムの取得
       */
      const iconCategoryListItem = computed(() => {
        // console.log('IconMenu iconCategoryListItem', props.iconCategoryList);
        // 存在チェック
        if (props.iconCategoryList == null || props.iconCategoryList.length === 0) {
          return [];
        }
        // 親分類が存在しない（”0”）ものに絞り込み、ID順にソートして返す。
        return props.iconCategoryList
          .filter(icon => icon.parentId === 0)
          .sort((a, b) => a.sort - b.sort);
      });

      // /**
      //  *  FUNC: アイコン名リストの色
      //  */
      // const iconNameListColor = (iconName, selectedIcon) => {
      //   return iconName === selectedIcon.logicalName
      //     ? {
      //         'background-color': '#555',
      //         color: 'white',
      //       }
      //     : {
      //         'background-color': 'white',
      //         color: 'black',
      //       };
      // };

      // /**
      //  *  FUNC: アイコン変更
      //  */
      // const changeIcon = icon => {
      //   selectedIcon.value = icon;
      // };

      /**
       *  WATCH:
       */
      watchEffect(() => {
        selectedIcon.value = props.iconImages[0];
      });

      return {
        filteredIcons,
        iconCategoryListItem,
        category,
        selectedIcon,
        // changeIcon,
        // iconNameListColor,
        listHeight,
      };
    },
  });
</script>
<style scoped>
  .icon-menu-root {
    padding: 10px;
  }
  .layout-menu-back {
    color: black;
    background-color: white;
    height: 100%;
    overflow-y: hidden;
  }
  .layout-menu-title {
    text-align: center;
  }
  .icon-list:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .select-icon {
    width: 100px;
  }
  .icon-img {
    background-color: white;
    user-select: none;
    max-width: 40px;
    max-height: 40px;
    pointer-events: none;
  }
  .icon-name {
    user-select: none;
    line-height: 15px;
    text-align: center;
    font-size: 10px;
    min-height: 25px;
    padding: 2px;
    font-weight: bold;
  }
</style>
