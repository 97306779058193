export default {
  methods: {
    // 支払方法
    getPaymentType(key) {
      const nonType = { no: -1, name: '-' };
      const creditCard = { no: 0, name: 'クレジットカード' };
      const bankTransfer = { no: 36, name: 'あおぞら銀行' };
      const accountTransferPaper = { no: 998, name: '口座振替' };
      const accountTransferOnline = { no: 999, name: '口座振替' };
      switch (key) {
        case -1:
        case 'NON_TYPE':
          return nonType;
        case 0:
        case 'CREDIT_CARD':
          return creditCard;
        case 36:
        case 'BANK_TRANSFER':
          return bankTransfer;
        case 998:
        case 'ACCOUNT_TRANSFER_PAPER':
          return accountTransferPaper;
        case 999:
        case 'ACCOUNT_TRANSFER_ONLINE':
          return accountTransferOnline;
        default:
          return [nonType, creditCard, bankTransfer, accountTransferPaper, accountTransferOnline];
      }
    },
    // 調整方法
    getDepositRefundStatus(key) {
      const unpaid = { no: '1', status: 'UNPAID', displayName: '未納' };
      const paySuccess = { no: '2', status: 'PAY_SUCCESS', displayName: '入金済' };
      const directPayment = { no: '3', status: 'DIRECT_PAYMENT', displayName: '入金対応' };
      const directRefund = { no: '4', status: 'DIRECT_REFUND', displayName: '返金対応' };
      const nextUse = { no: '5', status: 'NEXT_USE', displayName: '翌月清算' };
      const settled = { no: '6', status: 'SETTLED', displayName: '精算済' };
      switch (key) {
        case '1':
        case 'UNPAID':
          return unpaid;
        case '2':
        case 'PAY_SUCCESS':
          return paySuccess;
        case '3':
        case 'DIRECT_PAYMENT':
          return directPayment;
        case '4':
        case 'DIRECT_REFUND':
          return directRefund;
        case '5':
        case 'NEXT_USE':
          return nextUse;
        case '6':
        case 'SETTLED':
          return settled;
        default:
          return [unpaid, paySuccess, directPayment, directRefund, nextUse, settled];
      }
    },
  },
};
