<template>
  <div style="position: relative; text-align: center; cursor: pointer" class="base">
    <i v-if="deletable" class="delete-icon ti-close" @click.stop="$emit('close', index)"></i>
    <i v-if="addible" class="add-icon ti-plus" @click.stop="$emit('add', text)"></i>
    <div @click="$emit('click')" class="site-map-back-color">
      <i :class="icon + ' site-map'"></i>
    </div>
    <div class="site-item-text" v-html="text" v-bind:class="{ disabled: disabled }"></div>
  </div>
</template>
<script>
  export default {
    props: {
      index: Number,
      icon: String,
      text: String,
      deletable: Boolean, // 削除可能か
      addible: Boolean, // 追加可能か
      disabled: Boolean, // リンクを無効にするか
    },
  };
</script>
<style scoped>
  .site-map {
    font-size: 3.4rem;
    color: var(--colorTheme);
    animation: zoomIn 0.3s 1;
    position: absolute;
    width: 94%;
  }
  .site-map-back-color {
    border-radius: 5px;
    transition: 0.2s;
    border: 1px solid #aaa;
    padding: 15px 5px;
    text-align: center;
    width: 200px;
    height: 100px;
    animation: zoomIn 0.3s 1;
    align-items: center;
    display: grid;
  }
  .site-map-back-color:hover {
    background-color: lightgray;
    border-radius: 5px;
  }
  .site-item-text {
    font-size: 0.8rem;
    margin-top: 5px;
    height: auto;
    white-space: nowrap;
    color: black;
  }
  .disabled {
    color: lightgray;
  }
  .delete-icon {
    position: absolute;
    height: 25px;
    width: 25px;
    top: -5px;
    left: 124px;
    color: red;
    border: 1px solid #777;
    border-radius: 2px 2px 2px 2px;
    background-color: white;
    border-radius: 5px;
    font-size: 12px;
    padding: 5px;
    animation: zoomIn 0.3s 1 forwards;
  }
  .add-icon {
    position: absolute;
    height: 25px;
    width: 25px;
    top: -5px;
    left: 124px;
    color: blue;
    border: 1px solid #777;
    border-radius: 2px 2px 2px 2px;
    background-color: white;
    border-radius: 5px;
    font-size: 12px;
    padding: 5px;
    animation: zoomIn 0.3s 1 forwards;
  }
  .delete-icon:hover {
    background-color: lightgray;
  }
  .add-icon:hover {
    background-color: lightgray;
  }

  @media screen and (max-width: 1024px) {
    .site-map {
      font-size: 3.4rem;
    }
    .site-map-back-color {
      border-radius: 15px 15px 15px 15px;
      padding: 13px 5px;
      width: 160px;
      height: 90px;
    }
    .site-item-text {
      font-size: 0.4rem;
      margin-top: 5px;
      white-space: nowrap;
    }
    .delete-icon {
      height: 20px;
      width: 20px;
      left: 115px;
      font-size: 12px;
      padding: 3px;
    }
    .add-icon {
      height: 20px;
      width: 20px;
      left: 115px;
      font-size: 12px;
      padding: 3px;
    }
  }
  @keyframes zoomIn {
    0% {
      transform: scale(0.4);
      opacity: 0;
    }
    40% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes zoomOut {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    40% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      opacity: 0;
      transform: scale(0.4);
    }
  }
</style>
