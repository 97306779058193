<template>
  <div>
    <div class="row grid-margin">
      <h4 class="font-weight-bold">
        会社情報
      </h4>
    </div>
    <ValidationObserver slim ref="companyInfo" v-if="!loading">
      <div class="row grid-margin">
        <div class="ally-input-background card col-12">
          <div class="card-body">
            <div class="row">
              <div class="col-md-5">
                <div class="row">
                  <allyText
                    class="col-12 form-group"
                    title="会社名"
                    name="companyName"
                    v-model="companyInfo.companyName"
                    mode="View"
                    :loading="loading"
                    :placeholder="'〇〇株式会社'"
                    rules="required"
                  />
                </div>
                <div class="row">
                  <allyText
                    class="col-6 form-group"
                    title="代表者姓"
                    name="bossLastName"
                    v-model="companyInfo.bossLastName"
                    mode="View"
                    :loading="loading"
                    :placeholder="'新井'"
                  />
                  <allyText
                    class="col-6 form-group"
                    title="名"
                    name="bossFirstName"
                    v-model="companyInfo.bossFirstName"
                    mode="View"
                    :loading="loading"
                    :placeholder="'太郎'"
                  />
                </div>
                <div class="row">
                  <allyText
                    class="col-12 form-group"
                    title="電話番号"
                    name="tel"
                    v-model="companyInfo.tel"
                    mode="View"
                    :loading="loading"
                    :placeholder="'0196000000'"
                    rules="tel"
                  />
                </div>
                <div class="row">
                  <allyText
                    class="col-12 form-group"
                    title="E-mail"
                    name="mail"
                    v-model="companyInfo.mail"
                    mode="View"
                    :loading="loading"
                    :placeholder="'xxxxxxxxx@xxx.co.jp'"
                    rules="email"
                  />
                </div>
                <div class="row">
                  <allyImages
                    class="col-12"
                    v-model="companyInfo.companyLogo"
                    :loading="loading"
                    mode="View"
                    title="会社ロゴ"
                    ref="logo"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div
                  class="col-6"
                  style="border-right: 1px solid rgb(154, 154, 154); height: 100%"
                ></div>
              </div>
              <div class="col-md-5">
                <allyAddress v-model="companyInfo.address" mode="View" :loading="loading" />
                <div class="row">
                  <allyText
                    class="col-12 form-group"
                    title="資格"
                    name="qualification"
                    v-model="companyInfo.qualification"
                    mode="View"
                    :loading="loading"
                    :placeholder="'宅地建物取引士'"
                  />
                </div>
                <div class="row">
                  <allyText
                    class="col-12 form-group"
                    title="アカウント数"
                    name="accountNum"
                    v-model="companyInfo.accountNum"
                    mode="View"
                    :loading="loading"
                    :placeholder="'16'"
                    rules="required|numeric"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <div style="text-align: right">
      <b-btn class="ally btn-ally" @click="$router.go(-1)">キャンセル</b-btn>
    </div>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getDeleteQue } from '../../graphql/queries';

  API.configure(awsconfig);

  export default {
    data: function() {
      return {
        id: null,
        loading: false,
        companyInfo: {
          companyName: null,
          bossLastName: null,
          bossFirstName: null,
          tel: null,
          mail: null,
          address: {
            postalCode: null,
            address1: null,
            address2: null,
            address3: null,
          },
          qualification: null,
          registrationNum: null,
          companyLogo: null,
          accountNum: null,
        },
      };
    },
    mounted: async function() {
      this.loading = true;
      this.id = this.$router.currentRoute.params.id;

      //会社情報を取得
      try {
        const result = await API.graphql(graphqlOperation(getDeleteQue, { id: this.id }));
        //会社情報を格納
        this.companyInfo = result.data.getDeleteQue;
      } catch (error) {
        console.error(error);
        this.showError('E0001', error);
      }

      this.loading = false;
    },
  };
</script>
