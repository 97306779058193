<template>
  <CommonLayout title="Ⅱ．各種土地活用法における特徴">
    <template v-slot>
      <ReportSubTitle subTitle="メリット、デメリット" />
      <ReportTable :header="header" :items="items" style="margin-top: 150px; padding-bottom: 150px;">
        <template v-slot:header="{ col }">
          <div style="font-size: 70px; text-align: center; margin-left:-70px;" v-html="col.label"></div>
        </template>
        <template v-slot:table="{ col, item }"
          ><div
            v-if="
              item[col.name] === '◯' ||
                item[col.name] === '△' ||
                item[col.name] === '×' ||
                item[col.name] === '△～◯'||
                item[col.name] === '－'
            "
            style="padding-right: 65px; font-size: 100px;"
            v-html="item[col.name]"
          ></div>
          <div
            v-else-if="
                item[col.name] === '短期から可'||
                item[col.name] === '概ね20年以上'||
                item[col.name] === '10年以上'||
                item[col.name] === '不要または小'||
                item[col.name] === '大'||
                item[col.name] === '不要'||
                item[col.name] === '無し'||
                item[col.name] === '借家権'||
                item[col.name] === '定期借地権'
            "
            style="padding-right: 65px; font-size: 60px;"
            v-html="item[col.name]"
          ></div>
          <div v-else style="padding-right: 65px; font-size: 60px; text-align: left;" v-html="item[col.name]"></div>
        </template>
      </ReportTable>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import ReportSubTitle from '@/components/ExportReport/ReportSubTitle';
  import ReportTable from '@/components/ExportReport/ReportTable';

  export default {
    props: {},
    components: {
      CommonLayout,
      ReportSubTitle,
      ReportTable,
    },
    data() {
      return {
        header: [
          { name: 'caption', label: '', width:1.2},
          { name: 'usage', label: '活用法', width: 4 },
          { name: 'empty', label: '更地', width:0.4},
          { name: 'blueSky', label: '月極P' },
          { name: 'rentalHousing', label: 'アパート等' },
          { name: 'businessLand', label: 'コンビニ等<br/>貸土地' },
          { name: 'rentalLand', label: 'トランクルーム<br/>コインP' },
          { name: 'building', label: '貸事務所<br/>貸店舗' },
        ],
        items: [
          {
            caption: '収益性',
            usage:
              '一般的にはアパート、貸ビルが最も高くなる。利便性の<br>良い土地なら事業用定借や貸地も有利。<br>',
            empty: '×',
            blueSky: '△',
            rentalHousing: '△～◯',
            businessLand: '△',
            rentalLand: '△',
            building: '△～◯',
          },
          {
            caption: '固定資産税対策',
            usage:
              'アパートの土地は固定資産税が6分の1に軽減される。<br>（※更地、月極駐車場・事業用定借には軽減措置無し）',
            empty: '×',
            blueSky: '×',
            rentalHousing: '◯',
            businessLand: '×',
            rentalLand: '×',
            building: '×',
          },
          {
            caption: '相続税対策',
            usage:
              'アパート・貸ビルは相続税の軽減効果大<br>（※事業用定借も若干の軽減効果あり。<br>更地・月極駐車場・貸地には相続税の軽減効果無し）',
            empty: '×',
            blueSky: '×',
            rentalHousing: '◯',
            businessLand: '△',
            rentalLand: '×',
            building: '◯',
          },
          {
            caption: '事業期間',
            usage:
              '事業用定借は最低10年以上、アパート・貸ビルは概ね20年以上の<br>事業期間が必要。<br>月極駐車場・貸地は短期での利用可。',
            empty: '－',
            blueSky: '短期から可',
            rentalHousing: '概ね20年以上',
            businessLand: '10年以上',
            rentalLand: '短期から可',
            building: '概ね20年以上',
          },
          {
            caption: '投資額',
            usage:
              'アパート・貸ビルは投資額大<br>（※月極駐車場は舗装が必要になる場合あり。<br>事業用定借・更地は投資額は不要）',
            empty: '－',
            blueSky: '不要または小',
            rentalHousing: '大',
            businessLand: '不要',
            rentalLand: '不要',
            building: '大',
          },
          {
            caption: '借り手側に発生　する権利',
            usage:
              '事業用定借の借り手には定期借地権、アパート・貸しビルの入居者には借家権が発生<br>（※貸地、月極駐車場には借地権は発生しない）',
            empty: '－',
            blueSky: '無し',
            rentalHousing: '借家権',
            businessLand: '定期借地権',
            rentalLand: '無し',
            building: '借家権',
          },
        ],
      };
    },
  };
</script>
