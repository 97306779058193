// @ts-check

import { ref } from '@vue/composition-api';
import { API, graphqlOperation } from 'aws-amplify';
import axios from 'axios';
import { getS3Url } from '../../../../graphql/queries';
import { PlaneViewFetcher } from '../fetch/planeView';
import { useRouter } from './router';
import { useUser } from './user';
import { PvRoot } from '../node/node/rootNode';
import { makeToast } from '../util/util';
import { MESSAGE_TEXT, VALIDATE_NUM } from '../util/const';
import { ImageFetcher } from '../fetch/image';
import { PvPattern } from '../node/node/pvPattern';
import { PvRootPattern } from '../node/node/pvRootPattern';

/**
 * @param {{
 * layersRoot: PvRoot[]
 * layerPatterns: PvRootPattern[]
 * generateImage: () => Promise<Buffer>
 * }} params
 */
export const useSave = ({ layerPatterns, generateImage }) => {
  const router = useRouter();
  const imageFetcher = new ImageFetcher();

  const planeViewFetcher = new PlaneViewFetcher();
  const isNewPlaneView = ref(router.planeId == null); // 新規平面図フラグ
  const isNewProperty = router.propertyId == null; // 新規物件フラグ
  const isSaved = ref(!isNewPlaneView.value); /// 保存済フラグ
  const isSaving = ref(false); // 保存中フラグ
  const isMax = ref(false); // 保存中フラグ
  const isValidate = ref(false);
  const { user, username, companyId } = useUser(); // ユーザー情報
  const propertyId = ref(router.propertyId); // 物件ID
  const propertyName = ref(''); // 物件名
  const roomId = ref(router.roomId); // 部屋ID
  const planeId = ref(router.planeId); // 平面図ID
  const planeName = ref(''); // 平面図名
  const azimuth = ref(0); // 方位角（左手系北基準）

  /**
   * METHOD: 平面図データ読み込み
   * @return Promise<PlaneView>
   */
  const load = async () => {
    if (planeId.value == null) {
      return null;
    } else {
      // 平面図取得
      const result = await planeViewFetcher.getPlaneView(planeId.value);
      // 平面図名セット
      planeName.value = result.planeName;
      // 方位角セット（古いフォーマットの場合は0にする）
      azimuth.value = result.azimuth ? result.azimuth : 0;
      return result;
    }
  };

  /**
   * METHOD: 平面図データ保存
   */
  const save = async () => {
    // エラーチェック
    let errorMessage = '';
    // 平面図IDが存在する場合
    if (planeId.value) {
      // 平面図名が存在しない、または文字数が制限以上の場合
      if (!planeName.value || VALIDATE_NUM.TITLE_LENGTH_MAX < planeName.value.length) {
        errorMessage = MESSAGE_TEXT.SAVE_ERROR_VALIDATE;
      }
    }
    // 平面図IDが存在しない場合
    else {
      if (!isValidate.value) {
        errorMessage = MESSAGE_TEXT.SAVE_ERROR_VALIDATE;
      } else if (isMax.value) {
        errorMessage = MESSAGE_TEXT.SAVE_ERROR_MAX;
      }
    }
    if (errorMessage) {
      alert(errorMessage);
      return false;
    }

    // 保存開始
    isSaving.value = true;
    makeToast(MESSAGE_TEXT.SAVE_TEXT_SAVING, 'info');
    const extention = 'png';
    /** @type {Buffer} */
    const image = await generateImage();
    const fileName = planeName.value + '.' + extention;

    try {
      const links = await API.graphql(
        graphqlOperation(getS3Url, {
          level: 'public',
          file: [
            {
              fileName,
              fileAction: 'Add',
              propertyDirectory: {
                propertyId: propertyId.value,
                fileDirectory: 'planeView/images',
              },
            },
          ],
        })
      );

      // @ts-ignore
      const imageUrl = links.data.getS3Url[0].fileUrl;
      axios.put(
        // @ts-ignore
        imageUrl,
        new File([image], fileName),
        {
          // @ts-ignore
          'Content-Type': 'image/' + extention,
        }
      );

      // 平面図パターン情報
      const patterns = layerPatterns.map(
        p =>
          new PvPattern({
            name: p.name,
            layers: p.layersRoot.map(r => r.flat().map(f => f.toJsonObject())),
          })
      );

      // 平面図の新規作成時はcreate
      if (!isSaved.value && planeId.value == null) {
        const result = await planeViewFetcher.createPlaneView({
          ownerId: user.attributes.sub, // 作成ユーザーID
          ownerName: username, // TODO:
          companyId: companyId, // 会社ID
          // @ts-ignore
          propertyId: propertyId.value, // 物件ID
          // @ts-ignore
          roomId: roomId.value, // 部屋ID
          planeName: planeName.value, // 平面図名
          patterns: patterns, // 平面図パターン情報
          imageName: fileName, // ファイル名
          updatePerson: user.attributes.sub, // 更新ユーザーID
          azimuth: azimuth.value, // 方位角
        });

        // 平面図IDをセットする
        // @ts-ignore
        planeId.value = result.data.createPlaneView.id;
        // 新規フラグをfalseにする
        isNewPlaneView.value = false;
      } else {
        const result = await planeViewFetcher.updatePlaneView({
          id: planeId.value, // 平面図ID
          planeName: planeName.value, // 平面図名
          patterns: patterns, // 平面図パターン情報
          imageName: fileName, // ファイル名
          updatePerson: user.attributes.sub, // 更新ユーザーID
          azimuth: azimuth.value, // 方位角
        });
      }
      makeToast(MESSAGE_TEXT.SAVE_TEXT_SAVE_SUCCESS, 'primary');
      return true;
    } catch (error) {
      console.error(error);
      makeToast(MESSAGE_TEXT.SAVE_ERROR_SAVE_FAILURE, 'err');
      return false;
    } finally {
      isSaving.value = false;
      isSaved.value = true;
    }
  };

  return {
    load,
    save,
    isMax,
    isValidate,
    isNewPlaneView,
    isSaved,
    planeName,
    propertyId,
    propertyName,
    roomId,
    azimuth,
  };
};
