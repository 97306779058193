<!--
作成者：Lee HyeonSeok
作成日：2020.6.10
画面名：テキスト入力フォーム
機能：テキストを入力するフォーム
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <validation-provider
      :name="$attrs['data-vv-as'] || title"
      :rules="rules"
      v-slot="{ valid, errors }"
    >
      <b-form-group :label-for="name">
        <label v-if="appearTitle()" style="display: flex;">
          <span>{{ appearTitle() }}</span>
          <allyBadge v-if="hideBadgeFlag" :mode="mode" :rules="rules" />
          <allyBadge v-else-if="optionalOutput" :mode="mode" :rules="rules" />
        </label>
        <b-input-group>
          <b-form-input
            v-show="formFlag"
            :type="childType"
            :append="append"
            :id="name"
            :disabled="mode == 'View'"
            v-model="result"
            :state="state(valid, errors)"
            :placeholder="mode != 'View' ? placeholder : null"
            @focus="convertCheck"
            @change="converter"
            @blur="formChangeOff"
            @keyup.enter="nextFocus"
            :min="min"
            :max="max"
            :number="type === 'number'"
            :step="step"
            v-b-popover.hover.html="tips"
            ref="mainInput"
            class="allyMainInput"
            :class="{ small: typeof value === 'number' ? value.toString().length > 11 : false }"
            :autocomplete="autocomplete"
            :list="suggestList"
          />

          <b-form-input
            v-if="!formFlag"
            :id="'ally-text-disp-' + name"
            @focus="formChangeOn"
            :value="formDisp"
            :state="mode == 'View' ? null : result == null ? null : result == '' ? null : valid"
            :placeholder="mode != 'View' ? placeholder : null"
            v-b-popover.hover.html="tips"
            :disabled="mode == 'View'"
            class="allyMainInput"
            :class="{ small: typeof value === 'number' ? value.toString().length > 11 : false }"
            style="text-align: right; padding:0px 30px 0px 0px;"
          />

          <b-input-group-append v-if="append">
            <b-input-group-text style="color: black">
              {{ append }}
            </b-input-group-text>
          </b-input-group-append>
          <button
            v-if="type == 'password' || passwordFlg"
            class="btn btn-ally"
            @click="changeStatusPWVisibility"
          >
            <i v-if="childType == 'password'" class="icon-no-eyes" />
            <i v-if="childType == 'text'" class="icon-eyes" />
          </button>
        </b-input-group>

        <b-form-invalid-feedback :state="state(valid, errors)">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
    <!-- 電話番号入力時の注意書き -->
    <b-tooltip
      :target="name"
      placement="bottom"
      variant="secondary"
      custom-class="tooltip-text"
      v-if="isTel"
    >
      「-」は入れずに入力してください。
    </b-tooltip>
    <!-- 説明文 -->
    <b-tooltip
      :target="name"
      placement="bottom"
      variant="secondary"
      custom-class="tooltip-text"
      v-if="description"
    >
      {{ this.description }}
    </b-tooltip>
  </div>
</template>

<script>
  import moji from 'moji';
  import { extend } from 'vee-validate';
  import { max, min } from 'vee-validate/dist/rules';
  extend('max', max);
  extend('min', min);

  export default {
    // name: 物理名
    // mode: View/Edit/Add
    // value: 値
    // title: タイトル
    // nonAppear: trueの時、タイトルを表示しない
    // loading: ロード中プラグ
    // rules: vee-validate rule
    // optionalOutput: 任意で「必須」文字を出力する
    // hideBadge: 必須文字を非表示にする
    // placeholder: 項目が空欄の時、半透明な文字を入力
    // description: 入力項目の下部に説明文を表示する
    // tips カーソルを合わせるとヒントの文言を表示する
    // append 単位等を右に追加
    // fullWidth 全角に変換 （type = 'number'では無効）
    // halfWidth 半角に変換 （type = 'number'では無効）
    // step 値の間隔
    // floatLength 小数点桁数を指定 1→小数点1桁 2→小数点2桁
    // roundType 切り上げ('up')か切り捨て('down')か四捨五入('off')
    // separation type='number'時限定 separation="ture"をセットすると 3桁区切りを行う
    // autocomplete 自動入力をしたくない場合、"new-password"を設定する。
    // suggestList サジェストを表示したい場合、<datalist>タグのidをセットする。
    props: {
      name: String,
      mode: String,
      value: {},
      title: String,
      description: String,
      loading: Boolean,
      rules: String,
      placeholder: String,
      type: String,
      append: String,
      fullWidth: Boolean,
      halfWidth: Boolean,
      min: String,
      max: String,
      step: {
        type: String,
        default: 'any',
      },
      tips: String,
      floatLength: {
        type: Number,
        default: 0,
      },
      roundType: {
        type: String,
        default: 'off',
      },
      separation: Boolean,
      nonAppear: Boolean,
      autocomplete: {
        type: String,
        default: 'on',
      },
      suggestList: String,
      hideBadge: {
        type: Boolean,
        default: null,
      },
      optionalOutput: {
        type: Boolean,
        default: false,
      },
    },
    data: function() {
      //初期化
      return {
        result: this.value,
        formFlag: true,
        wanLoadFlag: true,
        isTel: false,
        //パスワードfield確認用
        passwordFlg: false,
        //子供のタイプを変更する場合があるため、typeを代入して私用
        childType: this.type,
        hideBadgeFlag: false,
      };
    },
    mounted: function() {
      //電話番号・FAX番号・携帯番号の時の設定
      if (this.rules != null && this.rules.includes('tel')) {
        this.isTel = true;
      }
      //三桁区切りが利用できるときの設定
      if (this.separation == true && this.type == 'number') {
        this.formFlag = false;
        this.formChangeOff();
      }

      //必須化項目の場合必須を表示する
      if (this.hideBadge == null && this.rules != null) {
        //文字列を確認する
        let Check = this.rules.indexOf('required');
        if (Check !== -1) {
          //必須化のルールがあるとき
          this.hideBadgeFlag = true;
        }
      }
    },
    methods: {
      nextFocus: function(event) {
        //ページ内のallyText内のinput要素を取得
        let elements = document.getElementsByClassName('allyMainInput');
        //配列型に変換
        elements = Array.from(elements);
        //非表示になっているinput要素の除外
        elements = elements.filter(element => element.style.display === '');
        //入力中のinput要素の次にあるinput要素の番号を取得
        const index = elements.findIndex(target => target === event.target);
        //インデックスチェック
        if (index === elements.length - 1) {
          return;
        }
        //次のinput要素にfocusをあてる
        elements[index + 1].focus();
      },
      //入力フォーム左上に出現するタイトルを制御する
      appearTitle: function() {
        if (!this.nonAppear) {
          return this.title;
        } else {
          return null;
        }
      },
      state: function(valid, errors) {
        if (
          (errors[0] ||
            (this.rules && this.result) ||
            (this.rules && this.rules.includes('required') && this.result)) &&
          this.mode != 'View'
        ) {
          return errors[0] ? false : valid ? true : null;
        }
        return null;
        // valid status
      },
      reloadValue: function() {
        this.result = this.value;
      },
      converter: function(e) {
        let convertFlag = true;

        if (e.isComposing == true) {
          convertFlag = false;
        }

        if (this.type === 'number') {
          // utility.jsのメソッド呼び出し
          this.result = this.formatNum(this.result, {
            floatLength: this.floatLength,
            roundType: this.roundType,
            separation: 0,
          });
          convertFlag = false;
        }

        //convertするとき処理
        if (convertFlag) {
          if (this.value || this.result) {
            //全角•半角変換
            if (this.fullWidth || this.halfWidth) {
              if (this.fullWidth !== undefined) {
                this.result = moji(this.result)
                  .convert('HS', 'ZS') // 半角スペース=>全角スペース
                  .convert('HE', 'ZE') // 半角英数=>全角英数
                  .convert('HK', 'ZK') // 半角カナ=>全角カナ
                  .toString();
              } else if (this.halfWidth !== undefined) {
                this.result = moji(this.result)
                  .convert('ZS', 'HS')
                  .convert('ZE', 'HE')
                  .convert('ZK', 'HK')
                  .toString();
              }
            }
            //ひらがな => カタカナ
            if (this.rules && this.rules.indexOf('onlykatakana') != -1) {
              this.result = moji(this.result)
                .convert('HG', 'KK')
                .toString();
            }
          }
        }

        if (this.result === '') {
          this.result = null;
        }
        if (this.wanLoadFlag == true) {
          this.wanLoadFlag = false;
        }
        // 値を変更すると値を返上
        this.$emit('input', this.result);
      },
      //focusをONにしたときの処理
      formChangeOn: function() {
        if ((this.separation == true || this.floatLength > 0) && this.type == 'number') {
          this.formFlag = true;
          this.$nextTick(() => {
            this.$refs.mainInput.focus();
          });
        }
        this.wanLoadFlag = false;
      },
      //focusをoffにしたときの処理
      formChangeOff: function() {
        if ((this.separation == true || this.floatLength > 0) && this.type == 'number') {
          this.formFlag = false;
        }
      },
      convertCheck: function() {
        this.wanLoadFlag = false;
      },
      //input feildのタイプがパスワードの場合、パスワードをtextに変更、見えるように処理（再度クリック時は隠す）
      changeStatusPWVisibility: function() {
        this.childType == 'password' ? (this.childType = 'text') : (this.childType = 'password');
        this.passwordFlg == 'true' ? (this.passwordFlg = false) : (this.passwordFlg = true);
      },
    },
    computed: {
      formDisp: function() {
        //数値を扱うとき処理する
        if (this.type == 'number' && this.result != null) {
          // utility.jsのメソッド呼び出し
          return this.formatNum(this.result, {
            floatLength: this.floatLength,
            roundType: this.roundType,
            separation: this.separation,
          });
        } else {
          return this.value;
        }
      },
    },
    watch: {
      value: function() {
        this.result = this.value;
      },
      loading: function() {
        // ロードが終わったら値を初期化
        if (this.loading === false) this.reloadValue();
      },
    },
  };
</script>
<style>
  .tooltip-text .tooltip-inner {
    max-width: 30rem;
    font-size: small;
  }
  .allyMainInput:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgb(40 167 69 / 25%);
  }
  .popover {
    max-width: 100%;
  }
  .form-control.small {
    font-size: 1px;
  }
</style>
