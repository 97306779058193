<!--
作成者：Lee HyeonSeok
作成日：2020.5.7
画面名：サイドバー
機能：交通サイドバー
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div class="sidebar-height" ref="sidebarContainer">
    <nav class="sidebar sidebar-offcanvas inherit-height" id="sidebar">
      <ul class="nav">
        <div class="text-center d-flex align-items-center justify-content-center sidebar_logo">
          <router-link
            @click.native="clickLoadFunc()"
            to="/"
            class="menu-title-user-info navbar-brand brand-logo"
            tag="a"
          >
            <div v-show="loadFlag" style="position: absolute; left: 30px; top: -50px; ">
              <Load width="80px" height="80px" />
            </div>
            <logo :visible="true" color="white" />
          </router-link>
          <div>
            <button
              class="navbar-toggler align-self-center"
              type="button"
              data-toggle="minimize"
              @click="openSidebar"
            >
              <span class="ti-view-list"></span>
            </button>
          </div>
        </div>
        <div class="menu-title-user-info sidebar_logo">
          <span
            class="
              menu-title-user-info
              user-info-font-color
              text-left
              d-flex
              align-items-left
              justify-content-left
            "
            style="margin-left: 5px"
            >{{ mail }}
          </span>
          <div class="user-info-dropdown" id="user-info-dropdown">
            <button
              class="ti-power-off ti-power-off-back"
              v-on:click="signOut"
              title="ログアウト"
            ></button>
            <button
              class="ti-lock ti-lock-back"
              v-if="group != 'systemAdmin'"
              v-on:click="goMyPage"
              title="マイページ詳細"
            ></button>
            <v-swatches
              v-model="color"
              colors="material-dark"
              popover-x="left"
              @input="updatethemeColor"
              row-length="4"
              swatch-size="25"
              :swatches="swatches"
              title="テーマカラー"
            >
              <i
                class="ti-paint-bucket ti-paint-bucket-back"
                :value="color"
                slot="trigger"
                style="width: '25px'"
              />
            </v-swatches>
            <button
              class="ti-home ti-home-back"
              v-on:click="goDashBoard"
              title="メインページ"
            ></button>
          </div>
        </div>
        <div v-if="group == 'systemAdmin'" style="margin:50%"></div>
        <li class="nav-item nav-item-property" v-b-toggle.property v-if="group != 'systemAdmin'">
          <a class="nav-link nav-link-property">
            <i class="ti-layers-alt menu-icon menu-icon-property"></i>
            <span class="menu-title title-color-property">シミュレーションアプリ</span>
          </a>
        </li>
        <b-collapse id="property" role="tabpanel">
          <div class="nav-item nav-item-property" v-if="group != 'systemAdmin'">
            <SidebarBtn
              v-for="(item, i) in propertyItems"
              :key="i"
              :item="item"
              @click="clickLink(item)"
              ref="sidebarBtn"
            />
          </div>
        </b-collapse>

        <li
          class="nav-item nav-item-realEstate"
          v-b-toggle.realEstate
          v-if="group != 'systemAdmin'"
        >
          <a class="nav-link nav-link-realEstate">
            <i class="ti-write menu-icon menu-icon-realEstate"></i>
            <span class="menu-title title-color-realEstate">不動産分析</span>
          </a>
        </li>
        <b-collapse id="realEstate" role="tabpanel">
          <SidebarBtn
            v-for="(item, i) in realEstateItems"
            :key="i"
            :item="item"
            @click="clickLink(item)"
            ref="sidebarBtn"
          />
        </b-collapse>
        <li class="nav-item nav-item-common" v-b-toggle.common v-if="group != 'systemAdmin'">
          <a class="nav-link nav-link-common">
            <i class="ti-world menu-icon menu-icon-common"></i>
            <span class="menu-title title-color-common">管理機能</span>
          </a>
        </li>
        <b-collapse id="common" role="tabpanel">
          <SidebarBtn
            v-for="(item, i) in commonItems"
            :key="i"
            :item="item"
            @click="clickLink(item)"
            ref="sidebarBtn"
          />
        </b-collapse>

        <SidebarBtn
          v-for="(item, i) in helpItems"
          :key="i"
          :item="item"
          @click="clickLink(item)"
          ref="sidebarBtn"
        />
      </ul>
    </nav>
    <FurnitureSimulator ref="furnitureSimulator" />
  </div>
</template>
<script>
  import logo from '../../components/Common/Logo';
  import { Auth } from 'aws-amplify';
  import VSwatches from 'vue-swatches';
  import {
    getItemsByGroup,
    commonItems,
    realEstateItems,
    propertyItems,
    companyInfoItems,
    helpItems,
    getMenuItems,
    checkLinkItem,
  } from '@/components/Common/siteMapItems.js';
  import FurnitureSimulator from '../../components/Common/FurnitureSimulator';
  import SidebarBtn from './SidebarBtn.vue';
  import Load from './Load';

  export default {
    components: {
      SidebarBtn,
      logo,
      VSwatches,
      FurnitureSimulator,
      Load,
    },
    data: function() {
      return {
        mail: null,
        color: null,
        isProcessing: false, // クリック連打無効用フラグ
        swatches: [
          '#1FBC9C',
          '#1CA085',
          '#2ECC70',
          '#27AF60',
          '#3398DB',
          '#2980B9',
          '#A463BF',
          '#8E43AD',
          '#3D556E',
          '#222F3D',
          '#F2C511',
          '#F39C19',
          '#E84B3C',
          '#C0382B',
          '#bf5213',
          '#BDC3C8',
        ],
        getItemsByGroup,
        openPageTitle: null,
        loadFlag: false,
      };
    },
    created: async function() {
      //cognito nickname→テーマ色
      await Auth.currentUserInfo().then(user => {
        if (user == null || user.attributes == null) {
          return;
        }

        //ユーザ指摘色がある場合、その色を指定
        if (user.attributes.nickname) {
          this.color = user.attributes.nickname;
        } else {
          //ユーザ指定色がない場合はデフォルトに設定
          this.color = '#1FBC9C';
        }
      });
      this.$emit('changeColortheme', this.color);
    },
    mounted: async function() {
      const currentUserInfo = await Auth.currentUserInfo();
      if (currentUserInfo == null || currentUserInfo.attributes == null) {
        return;
      }
      this.mail = currentUserInfo.attributes.email;

      // サイドバー開閉制御
      const body = document.body;
      const cb = () => {
        if (innerWidth <= 927) {
          body.classList.add('sidebar-icon-only');
          sessionStorage.setItem('sideIconOnly', true);
        } else {
          body.classList.remove('sidebar-icon-only');
          sessionStorage.setItem('sideIconOnly', false);
        }
      };
      cb();
      onresize = cb;
    },
    beforeDestroyed() {
      onresize = null;
    },
    computed: {
      functions() {
        return this.$store.state.functions;
      },
      commonItems() {
        return getMenuItems(commonItems, this.group, this.functions);
      },
      realEstateItems() {
        return getMenuItems(realEstateItems, this.group, this.functions);
      },
      propertyItems() {
        const items = getMenuItems(propertyItems, this.group, this.functions);

        //非表示項目を配列から削除する。
        return items.filter(item => item.hide != true);
      },
      companyInfoItems() {
        return getMenuItems(companyInfoItems, this.group, this.functions);
      },
      helpItems() {
        return getMenuItems(helpItems, this.group, this.functions);
      },
      group() {
        const user = this.$store.state.user;
        if (user == null) {
          return null;
        }
        const userGroup = user.signInUserSession.accessToken.payload['cognito:groups'] ?? '';
        if (userGroup !== undefined && userGroup.indexOf('systemAdmin') != -1) {
          return 'systemAdmin';
        } else if (userGroup.indexOf('admin') != -1) {
          return 'admin';
        } else {
          return 'member';
        }
      },
    },
    methods: {
      signOut: function() {
        //サインアウト機能
        //成功するとパス：SignOutに遷移、失敗するとコンソールにエラーを表示
        this.$router.push({ path: '/SignOut' });
      },
      goMyPage: function() {
        //マイページに遷移
        this.$router.push({ path: '/Mypage' });
      },
      goDashBoard: function() {
        //ダッシュボードに遷移
        this.$router.push({ path: '/DashBoard' });
      },
      updatethemeColor: async function() {
        //app.vueの色変更イベントトリガー
        this.$emit('changeColortheme', this.color);
        //amplify authクラスでテーマ変更内容を保存
        Auth.currentAuthenticatedUser().then(user =>
          Auth.updateUserAttributes(user, {
            email: this.mail,
            nickname: this.color,
          })
        );
      },
      openSidebar() {
        const ele = document.body;
        if (ele.classList.contains('sidebar-icon-only')) {
          ele.classList.remove('sidebar-icon-only');
          sessionStorage.setItem('sideIconOnly', false);
        } else {
          ele.classList.add('sidebar-icon-only');
          sessionStorage.setItem('sideIconOnly', true);
        }
      },
      async clickLink(item) {
        if (checkLinkItem(item)) {
          let selectSidebar = this.$refs.sidebarBtn.find(val => val.item.text === item.text);
          await this.$router.push(item.to).catch(err => {
            if (
              err.name !== 'NavigationDuplicated' &&
              !err.message.includes('Avoided redundant navigation to current location')
            ) {
              console.error(err);
              window.location.reload();
            }
          });
          selectSidebar.loadFlagFunc();
          this.openPageTitle = item.text;
        }
      },
      clickLoadFunc() {
        if (this.$route.name !== 'DashBoard') {
          if (!this.loadFlag) {
            this.loadFlag = true;
          }
        }
      },
    },
  };
</script>
<style scoped>
  .sidebar-height {
    z-index: 1031;
  }
  [role='button'] {
    outline: none;
  }
  #sidebar .nav .collapse {
    transition: 0.2s;
    /* background: red; */
    background: rgba(255, 255, 255, 0.3);
  }
  #sidebar .nav .disabled {
    color: lightgray;
  }
  #sidebar .nav .nav-item .nav-link .menu-title {
    font-size: small;
  }
  [role='tabpanel'] {
    padding-left: 1rem;
  }
  a .disabled {
    pointer-events: none;
  }
</style>
