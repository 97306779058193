<template>
  <div>
    <div class="row" v-if="hideSearchBox === undefined">
      <div class="col-6 offset-6">
        <b-input-group>
          <b-input-group-prepend>
            <b-dropdown :text="filter.targetLabel" variant="ally" :disabled="loading">
              <b-dropdown-item-button
                v-for="header in headers.filter(x =>
                  x.searchable !== undefined ? x.searchable : true
                )"
                :key="header.key"
                :value="header.key"
                @click="
                  filter.targetKey = header.key;
                  filter.targetLabel = header.label;
                  filter.textValue = null;
                "
                >{{ header.label }}</b-dropdown-item-button
              >
            </b-dropdown>
          </b-input-group-prepend>
          <b-form-input
            class="input-text"
            :disabled="loading"
            v-model="filter.textValue"
            :state="filter.textValue ? (filterError ? false : null) : null"
            v-if="mode != 'reportList' || filter.targetKey != 'status'"
          >
          </b-form-input>
          <b-form-select
            class="reportList"
            :options="[
              { text: '決裁待ち', value: '1' },
              { text: '決裁完了', value: '2' },
              { text: '返戻', value: '3' },
              { text: '提出完了', value: '4' },
            ]"
            v-model="filter.textValue"
            v-if="mode == 'reportList' && filter.targetKey == 'status'"
          />
          <b-input-group-append>
            <b-button variant="ally" @click="resetSearch" :disabled="loading">
              <i class="ti-close" />
            </b-button>
          </b-input-group-append>
          <b-form-invalid-feedback>{{ filterError }}</b-form-invalid-feedback>
        </b-input-group>
      </div>
    </div>
    <div class="row serch-data-table-top">
      <div class="col-12">
        <b-table
          thead-tr-class="serch-data-table-header"
          tbody-tr-class="serch-data-table-body"
          :busy="loading"
          hover
          striped
          :items="tableFilter"
          :fields="fields"
          @head-clicked="headerClick"
          show-empty
          responsive
        >
          <template v-slot:table-busy>
            <Load margin="50px auto" />
          </template>
          <template v-slot:empty>
            <div class="row">
              <div class="col-12 text-center">
                <svg
                  class="bi bi-exclamation-diamond-fill"
                  width="8em"
                  height="8em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <div class="alert">データがありません</div>
              </div>
            </div>
          </template>
          <template v-slot:cell()="row">
            <div v-for="header in [headerInfo(row.field.key)]" :key="header ? header.key : ''">
              <b-button
                :variant="header.button.variant ? header.button.variant : 'ally'"
                :disabled="checkDisabled(header.button.disabled, header, row)"
                @click="customEvent(header.button.event, row)"
                v-if="header.button"
              >
                {{ header.button.label ? header.button.label : getTdText(header, row) }}
              </b-button>
              <div v-if="header.publicText">
                <div v-if="flagCheck(row, header).flag">
                  <span v-html="flagCheck(row, header).viewText" />
                </div>
                <div v-if="flagCheck(row, header).flag == false">
                  <Load width="25px" height="25px" margin="0px" />
                </div>
              </div>
              <div
                v-if="header.publicImage"
                :style="isPlaneViewListModal ? planeViewImageStyle : imageStyle"
              >
                <img
                  :src="flagCheck(row, header).viewText"
                  v-if="flagCheck(row, header).flag"
                  :style="styles"
                />
                <div v-if="flagCheck(row, header).flag == false">
                  <Load width="25px" height="25px" margin="0px" />
                </div>
              </div>
              <div
                v-else-if="header.deployment != null && !isPlaneViewListModal"
                @click="
                  row.toggleDetails();
                  deployWidth();
                  childViewFlag(row);
                "
              >
                <b-button
                  size="sm"
                  class="mr-2"
                  variant="ally"
                  style="font-size:25px;"
                  v-if="childItemFunction(row).length > 0"
                >
                  <i :class="row.detailsShowing ? 'ti-minus' : 'ti-plus'" />
                </b-button>
              </div>
              <div v-else-if="!header.button">{{ getTdText(header, row) }}</div>
            </div>
          </template>
          <template v-slot:cell(detail)="row">
            <b-button variant="ally" @click="detailEvent(row)"> 詳細 </b-button>
          </template>
          <template v-slot:cell(delete)="row">
            <b-button
              variant="ally"
              @click="
                setdeleteTarget(row);
                $refs[targetName + 'deleteModal'].show();
              "
            >
              削除
            </b-button>
          </template>
          <template #row-details="row">
            <div>
              <div v-for="(item, i) of childItemFunction(row)" :key="i">
                <div class="row" style="height: 80px;">
                  <div
                    v-for="(width, index) of childWidth"
                    :key="index"
                    :style="'width:' + width + 'px'"
                    class="childTable"
                  >
                    <div v-if="row.fields[index].key == 'detail'">
                      <b-button @click="deploymentFunction(item, index, row.item.id)" class="btn"
                        >詳細</b-button
                      >
                    </div>
                    <div v-else-if="row.fields[index].key == 'delete'">
                      <b-button @click="deploymentFunction(item, index, row.item.id)" class="btn"
                        >削除</b-button
                      >
                    </div>
                    <div v-else-if="row.fields[index].key == 'select'">
                      <b-button @click="deploymentFunction(item, index, row.item.id)" class="btn">{{
                        row.fields[index].label
                      }}</b-button>
                    </div>
                    <div v-else>
                      <span v-if="getRoomData(item.id, index) != null">
                        <span v-if="getRoomData(item.id, index).viewFlag == false">
                          <Load width="25px" height="25px" margin="0px" />
                        </span>
                        <span v-if="getRoomData(item.id, index).viewFlag == true">
                          <span v-html="getRoomData(item.id, index).str"></span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </b-table>
        <div class="row serch-pagination-top serch-page-button serch-object">
          <b-button-group class="">
            <b-button variant="ally" @click="prev()" :disabled="currentPage <= 0">←</b-button>
            <b-button variant="ally" @click="next()" :disabled="isLastPage || null">→</b-button>
          </b-button-group>
        </div>
      </div>
    </div>
    <b-modal
      :ref="targetName + 'deleteModal'"
      title="削除確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="削除"
      @ok="deleteEvent()"
    >
      削除しますか？
    </b-modal>
    <allyAlert :code="'E101'" ref="errorAlert"></allyAlert>
  </div>
</template>

<script>
  import * as query from '../../graphql/queries';
  import { API, graphqlOperation } from 'aws-amplify';
  import Load from '../../components/Common/Load';
  var moment = require('moment-timezone');
  moment.tz.setDefault('Asia/Tokyo');

  export default {
    // headers: テーブルのヘッダー情報
    //            key: 【必須】GraphQLのデータ参照先を指定する。
    //            label:【必須】画面上に表示される論理名を設定する。
    //            publicImage: 表示したいイメージURLを返す関数を設定する
    //            publicText: 表示したい任意の文字を返す関数を設定する。
    //            sortable：trueを設定すると、その項目で昇順降順を切り替えることができるようになる
    //            searchable: falseをセットすると、検索項目の選択に表示されなくなる
    // targetName: テーブル名
    // labelEnums: 値のenum
    // hideDetail: 詳細ボタン表示プラグ
    // hideDelete: 削除ボタン表示プラグ
    // hideSearchBox: 検索ボックス表示フラグ
    // maxCount: 最大件数
    // dateDispState:  グリニッジ時間を日本時間に変更 null→日付のみ "time"→日付と時間 "wareki"→和暦で表示 "warekiTime"→和暦で表示（時間表示あり）
    // imageViewSize: headers.publicImageで表示する画像サイズを指定する 初期値{width: 153px , height: 73px}
    // isPlaneViewListModal: オートスケッチからモーダルが開かれた場合、trueがセットされる
    // listQueryNamePlaneView: PlaneViewのquery名
    props: [
      'headers',
      'targetName',
      'labelEnums',
      'hideDetail',
      'hideDelete',
      'filterFix',
      'hideSearchBox',
      'maxCount',
      'dateDispState',
      'imageViewSize',
      'isPlaneViewListModal',
      'listQueryNamePlaneView',
    ],
    data: function() {
      return {
        // 最大同時取得レコード数（バック) MAX60まで
        RECORDS_LIMIT: 60,
        // 最大同時表示レコード数(フロント)
        RECORDS_LIMIT_PAGE: 10,
        // テーブルデータ（内部）
        propertyItems: [],
        // ロード中プラグ
        loading: true,
        // フィルターデータ
        filter: {
          targetKey: null,
          targetLabel: '検索項目を選択',
          textValue: null,
          searchValue: null,
        },
        deleteTarget: null,
        // 現在表示中のページ
        currentPage: 0,
        //レポートリストかどうか判断する
        mode: null,
        textLoading: [],
        textRoomLoading: [],
        completeFirstLoad: false,
        sortOrder: {
          column: null,
          order: null,
        },
        childWidth: null,
        childItem: null,
        // PlaneView用アイテム
        planeViewItems: [],
        // 通常の画像用Style
        imageStyle: {
          width: '150px',
          margin: 'auto',
        },
        // オートスケッチ用の画像用Style
        planeViewImageStyle: {
          width: '100px',
          margin: 'auto',
        },
        // オートスケッチのデータ取得フラグ
        isGetItemsPlaneView: false,
      };
    },
    mounted: async function() {
      if (this.$router.currentRoute.query.page) {
        this.currentPage = sessionStorage.getItem('page' + this.targetName) ?? 0;
      } else {
        this.currentPage = 0;
        sessionStorage.setItem('page' + this.targetName, this.currentPage);
      }
      if (this.$router.currentRoute.path.endsWith('/Report/List')) {
        this.mode = 'reportList';
      }
      // 非同期で取得
      this.listItems();

      //ウィンドウサイズを変更した時
      window.addEventListener('resize', this.deployWidth);
    },
    beforeDestroy: function() {
      window.removeEventListener('resize', this.deployWidth);
    },
    computed: {
      // b-tableヘッダデータ
      fields() {
        const result = this.headers.map(x => {
          //各項目にクラス名を設定する。
          let className = x['sortable'] == true ? 'header-item sortable' : 'header-item';

          //昇順降順の時、指標文字を設定する。
          let label = x['label'].toString();
          if (this.sortOrder.column == x['key'] && x['sortable'] == true) {
            className += ' active';
            if (this.sortOrder.order == 'ASC') label += '▲';
            if (this.sortOrder.order == 'DESC') label += '▼';
          }
          let publicImage = '';
          if (x['publicImage'] != null) {
            publicImage = 'image';
          }
          return {
            key: x['key'],
            label: label,
            details: x['details'],
            thClass: className,
            tdClass: publicImage,
          };
        });
        // 詳細、削除ボタン追加処理
        if (this.hideDetail === undefined)
          result.push({ key: 'detail', label: '詳細', thClass: 'header-item' });
        if (this.hideDelete === undefined)
          result.push({ key: 'delete', label: '削除', thClass: 'header-item' });

        return result;
      },
      // クエリ名
      listQueryName() {
        return 'list' + this.targetName + 's';
      },
      // クエリ本文
      listQuery() {
        let str = this.isGetItemsPlaneView ? this.listQueryNamePlaneView : this.listQueryName;
        return query[str];
      },
      // オートスケッチ用クエリ本文
      listQueryForPlaneView() {
        return query[this.listQueryNamePlaneView];
      },
      // フィルターエラー
      filterError() {
        if (!this.filter.targetKey) return '条件を選択して下さい';
        else return null;
      },
      // 表示用テーブルデータ
      dispItems() {
        let newDispItems = this.propertyItems.concat();
        if (this.isPlaneViewListModal) {
          // オートスケッチから開かれていたらデータをオートスケッチ用に変える
          newDispItems = this.planeViewItems.concat();
        }
        var fiterArray = null;
        if (this.filter.targetKey != null && this.filter.textValue != null) {
          //検索ヘッダーがネストされている場合、ネストの最終項目を検索する
          if (this.filter.targetKey.includes('.')) {
            //ネスト項目は検索ができない為、ネストの最終オブジェクトを取り出す
            fiterArray = this.filter.targetKey.split('.');
            newDispItems = newDispItems.filter(item => {
              //最終ネストまでループする
              for (var i = 0; i < fiterArray.length - 1; i++) {
                item = item[fiterArray[i]];
              }
              return (
                //最終ネストで検索項目（ヘッダー）をセット、一致する文字列を探す
                item[fiterArray[fiterArray.length - 1]]?.includes(this.filter.textValue || '') ||
                false
              );
            });
          } else {
            if (this.filter.textValue != '') {
              //ネストされてない場合、現状の項目で検索を行う
              newDispItems = newDispItems.filter(item => {
                return item[this.filter.targetKey]?.includes(this.filter.textValue || '') || false;
              });
            }
          }
        }

        //日付データを変更
        newDispItems.forEach(element => {
          var keys = Object.keys(element);
          keys.forEach(key => {
            //表示の一律変更の指定
            var dispState = null;
            if (key == 'createdAt' || key == 'updatedAt' || key.indexOf('Date') != -1) {
              if (this.dateDispState == null || this.dateDispState == 'date') {
                dispState = 'date';
              } else if (this.dateDispState == 'time') {
                dispState = 'time';
              } else if (this.dateDispState == 'wareki') {
                dispState = 'wareki';
              } else if (this.dateDispState == 'warekiTime') {
                dispState = 'warekiTime';
              }
            }
            //個別に時間表示形式が指定されているとき
            this.headers.forEach(headKey => {
              if (headKey.key == key && headKey.dispState != null) {
                dispState = headKey.dispState;
              }
            });

            //条件が満たしているカラムの表示を設定する。
            if (dispState != null) {
              var date = new Date(element[key]);
              if (dispState == 'date') {
                element[key] =
                  date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate() + '';
              } else if (dispState == 'time') {
                element[key] =
                  date.getFullYear() +
                  '/' +
                  (date.getMonth() + 1) +
                  '/' +
                  date.getDate() +
                  '' +
                  ' ' +
                  ('0' + date.getHours()).slice(-2) +
                  ':' +
                  ('0' + date.getMinutes()).slice(-2);
              } else if (dispState == 'wareki') {
                element[key] = this.setSeirekiToWareki(
                  date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
                );
              } else if (dispState == 'warekiTime') {
                element[key] =
                  this.setSeirekiToWareki(
                    date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
                  ) +
                  ' ' +
                  ('0' + date.getHours()).slice(-2) +
                  '時 ' +
                  ('0' + date.getMinutes()).slice(-2) +
                  '分';
              }
            }
          });
        });
        //昇順降順設定時、表示順をソートする。
        if (this.sortOrder.column != null) {
          let head = this.headers.find(val => val.key == this.sortOrder.column);
          if (head != null) {
            if (head.sortable == true) {
              let fiterArray = this.sortOrder.column.split('.');
              try {
                //publicText利用している時、そのデータを取得する。
                let columns = null;
                if (this.textLoading != null)
                  columns = this.textLoading.filter(val => val.key == this.sortOrder.column);

                //ソート開始
                for (let i = 0; i < newDispItems.length - 1; i++) {
                  for (let j = i + 1; j < newDispItems.length; j++) {
                    let sort1 = null;
                    let sort2 = null;
                    //publicTextの項目出ない場合
                    if (head.publicText == null) {
                      sort1 = newDispItems[i];
                      sort2 = newDispItems[j];
                      for (var k = 0; k < fiterArray.length; k++) {
                        sort1 = sort1[fiterArray[k]];
                        sort2 = sort2[fiterArray[k]];
                      }
                    }
                    //publicTextの項目の場合
                    else if (head.publicText != null) {
                      sort1 = columns.find(val => val.id == newDispItems[i].id).viewText;
                      sort2 = columns.find(val => val.id == newDispItems[j].id).viewText;
                    }

                    if (
                      head.key == 'createdAt' ||
                      head.key == 'updatedAt' ||
                      head.key.indexOf('Date') != -1
                    ) {
                      sort1 = moment(sort1).format('YYYYMMDDHHmm');
                      sort2 = moment(sort2).format('YYYYMMDDHHmm');
                    }

                    //数値の先頭が数値の場合、数値のみを取り出す
                    if (sort1 != null) {
                      if (isNaN(sort1[0]) == false) sort1 = Number(sort1.replace(/[^0-9]/g, ''));
                    }
                    if (sort2 != null) {
                      if (isNaN(sort2[0]) == false) sort2 = Number(sort2.replace(/[^0-9]/g, ''));
                    }

                    //比較しソートの有無を確認する（ソート比較がNullの時正常にソートできないので、文字列に置き換える）
                    let sorts = (sort1 != null ? sort1 : '') < (sort2 != null ? sort2 : '');
                    if (this.sortOrder.order == 'ASC')
                      sorts = (sort1 != null ? sort1 : '') > (sort2 != null ? sort2 : '');

                    //比較したときtrueなら配置を入れ替える
                    if (sorts) {
                      let wneSort = newDispItems[i];
                      newDispItems[i] = newDispItems[j];
                      newDispItems[j] = wneSort;
                    }
                  }
                }
              } catch (e) {
                console.error(e);
              }
            }
          }
        }
        return newDispItems;
      },
      currentPageItems() {
        // ページのインデックス
        const from = this.currentPage * this.RECORDS_LIMIT_PAGE;
        const to = this.currentPage * this.RECORDS_LIMIT_PAGE + this.RECORDS_LIMIT_PAGE;

        return this.dispItems.slice(from, to);
      },
      isLastPage() {
        const result =
          this.currentPage >= Math.floor((this.dispItems.length - 1) / this.RECORDS_LIMIT_PAGE);
        return result;
      },
      //publicImageで表示する画像サイズを指定する。
      styles() {
        let width = '150px';
        let height = '73px';

        if (this.imageViewSize != null) {
          if (this.imageViewSize.width != null) width = this.imageViewSize.width;
          if (this.imageViewSize.height != null) height = this.imageViewSize.height;
        }
        return {
          '--width': width,
          '--height': height,
        };
      },
      //  テーブルのフィルター内容を制御する
      tableFilter() {
        if (this.mode === 'reportList') {
          const selectCustomerId = this.$route.query;
          if (selectCustomerId.customer === undefined) {
            // 顧客管理画面から遷移していない場合の条件（顧客管理から遷移していなければundefinedになるため）
            return this.currentPageItems;
          } else {
            let filterArray = this.currentPageItems.filter(
              currentPageItem => currentPageItem.customerId === selectCustomerId.customer
            );
            return filterArray;
          }
        } else {
          return this.currentPageItems;
        }
      },
      // COMPUTED: 平面図情報取得フラグによって平面図情報（true）か物件情報（false)のデータが入る
      items() {
        return this.isGetItemsPlaneView ? this.planeViewItems : this.propertyItems;
      },
    },
    methods: {
      // METHOD: アイテム取得
      getItems: async function(nextToken) {
        let tmpListQueryName = this.isGetItemsPlaneView
          ? this.listQueryNamePlaneView
          : this.listQueryName;
        const items = this.isGetItemsPlaneView ? this.planeViewItems : this.propertyItems;
        const queryVar = {
          limit: this.RECORDS_LIMIT,
        };

        if (this.filterFix) {
          queryVar.filter = this.filterFix;
        }
        queryVar.nextToken = nextToken;
        let queryResult = null;
        try {
          queryResult = await API.graphql(graphqlOperation(this.listQuery, queryVar));

          const result = queryResult.data[tmpListQueryName.replace('ArMeasures', 'ARMeasures')];
          if (this.isGetItemsPlaneView) {
            result.items = result.items.filter(val => val.propertyId !== null);
          }
          items.splice(items.length, 0, ...result.items);
          return result.nextToken;
        } catch (e) {
          //エラー発生
          try {
            console.error('graphQL lead error', e);
            if (e.data == null) {
              this.$bvModal.show('E101');
              return null;
            }
            //不正なデータが含まれているだけの場合は、他のデータを表示する。
            let result = e.data[tmpListQueryName.replace('ArMeasures', 'ARMeasures')];

            if (result.items == null) {
              this.$bvModal.show('E101');
              return null;
            }
            for (let item of result.items) {
              if (item != null) {
                items.push(item);
              }
            }
            return result.nextToken;
          } catch (e) {
            //不正なデータ以外のerrorの場合は、エラーメッセージを表示する。
            this.$bvModal.show('E101');
          }
        }
      },
      // METHOD: 初期化処理
      listItems: async function() {
        this.loading = true;
        this.propertyItems = [];
        this.planeViewItems = [];
        let nextToken = null;
        nextToken = await this.getItems(nextToken);

        const maxCount = this.maxCount || Number.MAX_SAFE_INTEGER;

        // すべてのデータを読み込むまでループ
        while (nextToken != null && maxCount > this.items.length) {
          nextToken = await this.getItems(nextToken);
        }

        this.items.slice(0, maxCount);
        if (this.isPlaneViewListModal) {
          this.isGetItemsPlaneView = true;
        }

        if (this.isPlaneViewListModal && this.isGetItemsPlaneView) {
          nextToken = null;
          // オートスケッチから開かれている場合
          nextToken = await this.getItems(nextToken);

          // すべてのデータを読み込むまでループ
          while (nextToken != null && maxCount > this.items.length) {
            nextToken = await this.getItems(nextToken);
          }

          this.planeViewItems = this.planeViewItems.slice(0, maxCount);
          for (let i = 0; i < this.propertyItems.length; i++) {
            for (let j = 0; j < this.planeViewItems.length; j++) {
              if (this.planeViewItems[j].propertyId == null) {
                continue;
              }
              let tmpItems = this.propertyItems.filter(
                val => val.id == this.planeViewItems[j].propertyId
              );
              if (tmpItems[0]?.roomInfo?.length > 0) {
                let tmpRoomName = tmpItems[0].roomInfo.filter(
                  val => val.id === this.planeViewItems[j].roomId
                );
                this.planeViewItems[j].roomName = tmpRoomName[0]?.roomName;
              }
              this.planeViewItems[j].commonValue = tmpItems[0]?.commonValue;
            }
          }
        }
        for (let i = 0; i < this.items.length; i++) {
          for (let j = 0; j < this.headers.length; j++) {
            if (this.headers[j].publicText != null) {
              this.textLoading.push({
                id: this.items[i].id,
                key: this.headers[j].key,
                view: 'text',
                flag: false,
                viewText: '',
              });
              this.publicText(this.items[i], this.headers[j]);
            }
            if (this.headers[j].publicImage != null) {
              this.textLoading.push({
                id: this.items[i].id,
                key: this.headers[j].key,
                view: 'image',
                flag: false,
                viewText: '',
              });
              this.publicImage(this.items[i], this.headers[j]);
            }
          }
        }
        this.loading = false;
        this.completeFirstLoad = true;

        //得られたリストをcreatedAtでソート
        this.sortList();
      },
      // カスタムイベント発生
      customEvent: function(event, row) {
        this.loading = true;
        this.$emit(event, row.item);
        this.loading = false;
      },
      // 詳細イベント発生
      detailEvent: function(row) {
        this.$emit('detailClicked', row.item);
      },
      // 削除イベント発生
      deleteEvent: function() {
        this.$emit('deleteClicked', this.deleteTarget.item);
      },
      setdeleteTarget: function(row) {
        this.deleteTarget = row;
      },
      // 次のページ移動
      next: function() {
        this.currentPage++;
        sessionStorage.setItem('page' + this.targetName, this.currentPage);
      },
      // 以前ページ移動
      prev: function() {
        if (this.currentPage > 0) {
          this.currentPage--;
          sessionStorage.setItem('page' + this.targetName, this.currentPage);
        }
      },
      // 検索
      search: async function() {
        if (this.filterError) return;
        this.dispItems = this.propertyItems;
      },
      checkDisabled: function(disabled, header, row) {
        if (disabled == null) {
          return false;
        } else if (typeof disabled === 'boolean') {
          return disabled;
        } else {
          return disabled(header, row);
        }
      },
      // セルのヘッダー情報を返します
      headerInfo: function(key) {
        const header = this.headers.find(x => x.key == key);
        if (header) return header;
        return null;
      },
      // enumからテキストを呼び出す
      getTdText: function(header, row) {
        const enumType = header.enumType;
        const dataType = header.dataType;
        const callback = header.callback;
        const value = row.value;

        if (callback) return callback(header, row);
        if (dataType == 'Date') return value.toString().split('T')[0];
        return enumType ? this.labelEnums[enumType][value] : value;
      },
      //検索リストをリロード
      resetSearch: async function() {
        // 検索条件初期化
        this.filter.searchValue = null;
        // 検索入力欄の初期化
        this.filter.textValue = null;
        // 検索種別ラベル初期化
        this.filter.targetLabel = '検索項目を選択';
        // 検索種別キー初期化
        this.filter.targetKey = null;
      },
      //リストのソート
      sortList: function() {
        if (this.isPlaneViewListModal) {
          this.planeViewItems = this.planeViewItems.sort(function(a, b) {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          });
        } else {
          this.propertyItems = this.propertyItems.sort(function(a, b) {
            return new Date(a.createdAt) - new Date(b.createdAt);
          });
        }
      },
      //和暦データ
      warekiChange: function(dateInput) {
        var _dateInput = new Date(dateInput), // 入力値
          _eras = [
            {
              name: '令和',
              kana: 'れいわ',
              initial: 'R',
              begin: '2019-05-01',
              end: '9999-12-31',
            },
            {
              name: '平成',
              kana: 'へいせい',
              initial: 'H',
              begin: '1989-01-08',
              end: '2019-04-30',
            },
            {
              name: '昭和',
              kana: 'しょうわ',
              initial: 'S',
              begin: '1926-12-25',
              end: '1989-01-07',
            },
            {
              name: '大正',
              kana: 'たいしょう',
              initial: 'T',
              begin: '1912-07-30',
              end: '1926-12-24',
            },
            {
              name: '明治',
              kana: 'めいじ',
              initial: 'M',
              begin: '1868-10-23',
              end: '1912-07-29',
            },
          ];
        // filterで絞り込み
        return _eras.filter(function(item) {
          var _dateBegin = new Date(item.begin + ' 00:00:00 +0900'),
            _dateEnd = new Date(item.end + ' 23:59:59 +0900'),
            _year = 0;

          // Date.getTimeを使って開始後かつ終了前を判定
          if (
            _dateInput.getTime() >= _dateBegin.getTime() &&
            _dateInput.getTime() <= _dateEnd.getTime()
          ) {
            // 年数を計算
            _year = _dateInput.getFullYear() - _dateBegin.getFullYear() + 1;

            // 1年なら元年にしてプロパティに追加
            item.year = _year;

            return item;
          }
        })[0];
      },
      setSeirekiToWareki: function(seirekiDate) {
        if (seirekiDate != null) {
          //西暦を和暦データに変換
          var wareki = this.warekiChange(seirekiDate);
          var date = new Date(seirekiDate);

          if (wareki != null) {
            //変換した和暦データをセット
            var result = {
              wareki: wareki.name,
              year: String(wareki.year),
              month: String(date.getMonth() + 1),
              date: String(date.getDate()),
            };
            return result.wareki + result.year + '年 ' + result.month + '月' + result.date + '日';
          }
        }
      },
      getGroup() {
        var userGroup = new Array();
        userGroup = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        if (userGroup.indexOf('systemAdmin') != -1) {
          return 'systemAdmin';
        } else if (userGroup.indexOf('admin') != -1) {
          return 'admin';
        }
        return 'member';
      },
      //呼び出し元からファンクションを呼び出す。
      publicText: async function(item, header) {
        let result = await header.publicText(item);
        let filterItem = this.textLoading.filter(function(val) {
          return val.id == item.id && val.key == header.key;
        });
        filterItem[0].viewText = result;
        filterItem[0].flag = true;
      },
      //呼び出し元ファンクションのロード完了しているか確認する。
      flagCheck: function(row, header) {
        let filterItem = this.textLoading.filter(function(val) {
          return val.id == row.item.id && val.key == header.key;
        });
        return filterItem[0] ? filterItem[0] : false;
      },
      publicImage: async function(item, header) {
        let image = await header.publicImage(item);
        let filterItem = this.textLoading.filter(function(val) {
          return val.id == item.id && val.key == header.key;
        });
        filterItem[0].viewText = image;
        filterItem[0].flag = true;
      },
      //ヘッダーの項目を押した時の処理
      headerClick: function(headName) {
        //非検索項目の時は設定しない
        let head = this.headers.find(val => val.key == headName);
        if (head == null) return;
        if (head.sortable == null) return;

        //現在のソートしている項目と同じ項目の時、昇順→降順→はずすを繰り返す。
        if (this.sortOrder.column == headName) {
          if (this.sortOrder.order == null) {
            this.sortOrder.order = 'ASC';
          } else if (this.sortOrder.order == 'ASC') {
            this.sortOrder.order = 'DESC';
          } else if (this.sortOrder.order == 'DESC') {
            this.sortOrder.order = null;
            this.sortOrder.column = null;
          }
        }
        //ソート中、別の項目をクリックした場合、その項目の昇順からソートをやり直す
        else if (this.sortOrder.column != headName) {
          this.sortOrder.column = headName;
          this.sortOrder.order = 'ASC';
        }
      },
      getItemLength: function() {
        return this.propertyItems.length;
      },
      /**
       * 子要素のに表示する情報を設定する。
       */
      async deploymentFunction(item, itemIndex, propertyId) {
        let header = this.headers.find(val => val.deployment != null);
        let str = await header.deployment(item, itemIndex, propertyId);
        return str;
      },
      /**
       * 子項目のサイズを設定する。
       */
      deployWidth() {
        let className = document.getElementsByClassName('header-item');
        let width = [];
        for (let wid of className) {
          width.push(wid.getBoundingClientRect().width);
        }
        this.childWidth = width;
      },
      /**
       * 子要素のデータ配列を設定する。
       */
      childItemFunction(row) {
        let header = this.headers.find(val => val.deployment != null);
        let item = row.item[header.key];
        if (item == null) item = [];
        return item;
      },
      /**
       * ルームの情報を表示する
       */
      childViewFlag: async function(row) {
        if (row.detailsShowing == true) return;
        let childItem = this.childItemFunction(row);
        for (let item of childItem) {
          this.textRoomLoading = this.textRoomLoading.filter(val => val.roomId != item.id);
          for (let i = 0; i < this.headers.length; i++) {
            //ヘッダー情報の「details」がfalseのとき、カラムに空欄を表示する
            if (this.headers[i].details != false) {
              this.textRoomLoading.push({
                roomId: item.id,
                index: i,
                viewFlag: false,
                str: 'Loading中',
              });
            } else if (this.headers[i].details == false) {
              this.textRoomLoading.push({
                roomId: item.id,
                index: i,
                viewFlag: true,
                str: '',
              });
            }
          }
        }
        for (let item of childItem) {
          for (let i = 0; i < this.headers.length; i++) {
            if (this.headers[i].button == null) {
              let data = this.getRoomData(item.id, i);
              this.deploymentFunction(item, i, row.item.id).then(value => {
                data.str = value;
                data.viewFlag = true;
              });
            }
          }
        }
      },
      /**
       * textRoomLoadingにセットしたroom情報を取得する
       */
      getRoomData(roomId, index) {
        let datas = this.textRoomLoading.filter(val => val.roomId == roomId);
        let data = datas.find(val => val.index == index);

        return data;
      },
      /**
       * 追加した部屋情報の文言を物件一覧に表示する。
       * @param {*} addRoomView
       * {
       *  roomId,
       *  addRoomData:{key,str}
       * }
       */
      setTextRoomLoading: async function(addRoomView) {
        let addTextRoomLoading = [];
        for (let data of addRoomView.addRoomData) {
          let headNumber = this.headers.findIndex(val => val.key === data.key);
          addTextRoomLoading.push({
            index: headNumber,
            roomId: addRoomView.roomId,
            str: data.str,
            viewFlag: true,
          });
        }
        this.textRoomLoading = [...this.textRoomLoading, ...addTextRoomLoading];
      },
    },
    watch: {
      propertyItems: {
        handler() {
          const maxCount = this.maxCount || Number.MAX_SAFE_INTEGER;
          if (this.propertyItems.length >= maxCount) {
            // 最大件数に達したらtrue
            this.$emit('itemsLengthMax', true);
          } else {
            // 最大件数でない場合はflase
            this.$emit('itemsLengthMax', false);
          }
        },
      },
      completeFirstLoad() {
        // 初回ロード完了時点で発火
        if (this.completeFirstLoad === true) {
          this.$emit('completeFirstLoad');
        }
      },
    },
    components: {
      Load,
    },
  };
</script>
<style scoped>
  .custom-select.reportList {
    height: auto;
  }

  .table td img {
    max-width: var(--width);
    max-height: var(--height);
    width: auto;
    height: auto;
    border-radius: 10%;
  }
  .input-group-prepend {
    padding-right: 4px;
  }
  .input-text {
    height: 48px;
  }
  .input-group-append {
    padding-left: 4px;
  }
</style>
<style>
  .table td.image {
    padding: 5px;
  }
  .header-item {
    user-select: none;
  }
  .sortable {
    transition: 0.2s;
  }
  .sortable:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .sortable.active {
    background-color: rgba(255, 255, 255, 0.4);
  }
  .sortable.active:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }

  .child-td {
    text-align: center;
    padding: 30px;
    background: #f2f2f2;
    height: 84.5px;
  }

  .childTable {
    margin-top: auto;
    margin-bottom: auto;
  }

  .childTable button {
    color: white;
    background-color: var(--colorTheme);
  }
  .childTable button:hover {
    color: black;
    background-color: var(--colorTheme);
  }
</style>
