// @ts-check

import { Vec } from '../../util/math/vector';
import { EventDataset } from '../preset/eventDataset';

const ACTION_TYPE = {
  NONE: 'none',
  CLICK2: 'click2',
  CLICK3: 'click3',
  DRAG_AND_DROP: 'dragAndDrop',
  MULTI_CLICK: 'multiClick',
};

const STATUS = {
  COMPLETE: 'complete',
  ROLLBACK: 'rollback',
  INCOMPLETE: 'incomplete',
  ABORT: 'abort',
};

/** @type {Record<string, (action: ActionContext) => string>} */
const DONE = {
  [ACTION_TYPE.NONE]: _ => {
    return '';
  },
  [ACTION_TYPE.CLICK2]: (/** @type {ActionContext} */ action) => {
    if (action.len === 2 && action.first.eq(action.last)) {
      return STATUS.ABORT;
    } else if (action.len === 2) {
      return STATUS.COMPLETE;
    } else {
      return STATUS.INCOMPLETE;
    }
  },
  [ACTION_TYPE.CLICK3]: (/** @type {ActionContext} */ action) => {
    if (action.len === 3 && action.first.eq(action.last)) {
      return STATUS.ABORT;
    } else if (action.len === 3) {
      return STATUS.COMPLETE;
    } else {
      return STATUS.INCOMPLETE;
    }
  },
  [ACTION_TYPE.DRAG_AND_DROP]: (/** @type {ActionContext} */ action) => {
    if (action.len === 2 && action.first.eq(action.last)) {
      return STATUS.ABORT;
    } else if (action.len === 2) {
      return STATUS.COMPLETE;
    } else {
      return STATUS.INCOMPLETE;
    }
  },
  [ACTION_TYPE.MULTI_CLICK]: () => {
    return STATUS.INCOMPLETE;
  },
};

/**
 *
 * @typedef {(context: ActionContext) => void} ActionHandler
 */

export class ActionContext {
  /** @type {Vec[]} */
  routes;
  /** @type {EventDataset[]} */
  eventDatasets;

  /**
   * @private
   * @param {{
   * routes: Vec[],
   * eventDatasets: EventDataset[],
   * }} param
   */
  constructor(param) {
    this.routes = param.routes;
    this.eventDatasets = param.eventDatasets;
  }

  get len() {
    return this.routes.length;
  }
  get first() {
    return this.routes[0];
  }
  get last() {
    return this.routes[this.routes.length - 1];
  }
  get dist() {
    return this.last.sub(this.first);
  }

  /**
   * アクションからインスタンス生成
   * @param {Vec[]} routes
   * @param {EventDataset[]} eventDatasets
   * @returns {ActionContext}
   */
  static from(routes, eventDatasets) {
    return new ActionContext({
      routes,
      eventDatasets,
    });
  }
}

export class Action {
  static ACTION_TYPE = ACTION_TYPE;
  static STATUS = STATUS;
  static DONE = DONE;
  /** @abstract @type {string} */
  actionType = ACTION_TYPE.CLICK2;
  /** @type {ActionHandler} */
  onComplete = () => {};
  /** @type {ActionHandler} */
  onAbort = () => {};
  /** @type {(context: ActionContext, dataset: EventDataset|null) => boolean|void} falseを返却すると強制終了 */
  onAdvanceOrRollBack = () => {};
  /** @type {ActionHandler} */
  onUpdate = () => {};
  /** @type {(context: ActionContext, dataset: EventDataset, status: string) => string} */
  onAfterCheckStatus = (_, __, status) => status;
}
