<template>
  <div id="realEstateSuitability">
    <div class="danger">
      ※単価を変更した場合、計算結果に影響を及ぼす可能性があります。
    </div>
    <b-card no-body>
      <b-tabs card no-fade>
        <b-tab title="住居用賃貸物件" style="overflow-x: auto;">
          <b-container id="rentalProperty">
            <b-row style="height:2em;">
              <b-col class="title"> </b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row class="float-left">
                    <b-col class="title">
                      {{ column.text }}
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title">
                間取り・名称
              </b-col>
              <b-col v-for="column in columns" :key="column.id" class="component ">
                <b-container>
                  <b-row>
                    <b-col cols="11" class="component ally-select" v-if="column.id < 4">
                      <allySelect
                        :name="'floorPlan' + column.id"
                        :items="floorPlanItems"
                        v-model="input.defaultValue.rentalProperty[column.id].floorPlanAndName"
                        rules="required"
                        :loading="loading"
                      />
                    </b-col>
                    <b-col cols="11" class="component ally-select" v-else>
                      <allySelect
                        :name="'floorPlan' + column.id"
                        :items="[...floorPlanItems, { text: '戸建', value: '戸建' }]"
                        v-model="input.defaultValue.rentalProperty[column.id].floorPlanAndName"
                        rules="required"
                        :loading="loading"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 間取りの<br />必要面積(㎡) </b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <allyText
                        rules="required|min_value:0|max_value:999999"
                        :name="'requireArea' + column.id + 1"
                        type="number"
                        mode="Edit"
                        v-model="input.defaultValue.rentalProperty[column.id].requireArea"
                        :loading="loading"
                        :title="'必要面積' + (column.id + 1)"
                        :nonAppear="true"
                        :separation="true"
                        roundType="down"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 工事費<br />(万円/戸) </b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <allyText
                        rules="required|min_value:0|max_value:999999"
                        :name="'constructionCostPerUnit' + column.id + 1"
                        type="number"
                        mode="Edit"
                        v-model="
                          input.defaultValue.rentalProperty[column.id].constructionCostPerUnit
                        "
                        :loading="loading"
                        :title="'工事費' + (column.id + 1)"
                        :nonAppear="true"
                        :separation="true"
                        roundType="down"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title">
                構造
              </b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col cols="11" class="component ally-select">
                      <allySelect
                        :name="'structure' + column.id"
                        :items="structureItems"
                        v-model="input.defaultValue.rentalProperty[column.id].structure"
                        mode="Edit"
                        rules="required"
                        :loading="loading"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <!-- ※2021/04/09の資料により'用途'設定は不要になった→'住宅'固定
            <b-row>
              <b-col class="title">
                用途
              </b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col cols="11" class="component ally-select">
                      <allySelect
                        :name="'kind' + column.id"
                        :items="kindItems"
                        v-model="input.defaultValue.rentalProperty[column.id].kind"
                        mode="Edit"
                        rules="required"
                        :loading="loading"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            -->
            <b-row>
              <b-col class="title">
                法定耐用年数
              </b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <b-form-input
                        disabled
                        class="currency"
                        :value="legalServiceLife[column.id] | currency"
                        :loading="loading"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 管理費率<br />(対賃料)(%) </b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <allyText
                        rules="required|min_value:0|max_value:100"
                        :name="'managementCostRate' + column.id"
                        type="number"
                        step="0.1"
                        mode="Edit"
                        v-model="input.defaultValue.rentalProperty[column.id].managementCostRate"
                        :loading="loading"
                        :title="'管理費率(対賃料)' + (column.id + 1)"
                        :nonAppear="true"
                        tips="不動産管理会社に支払う管理費率。"
                        :floatLength="1"
                        roundType="down"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 空室率<br />(対賃料)(%)</b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <allyText
                        rules="required|min_value:0|max_value:100"
                        :name="'vacancyRate' + column.id"
                        type="number"
                        step="0.1"
                        mode="Edit"
                        v-model="input.defaultValue.rentalProperty[column.id].vacancyRate"
                        :loading="loading"
                        :title="'空室率(対賃料)' + (column.id + 1)"
                        :nonAppear="true"
                        :floatLength="1"
                        roundType="down"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 初年度経費率<br />(対工事費)(%)</b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <allyText
                        rules="required|min_value:0|max_value:100"
                        :name="'firstYearExpenseRatio' + column.id"
                        type="number"
                        step="0.1"
                        mode="Edit"
                        v-model="input.defaultValue.rentalProperty[column.id].firstYearExpenseRatio"
                        :loading="loading"
                        :title="'初年度経費率(対工事費)' + (column.id + 1)"
                        :nonAppear="true"
                        tips="建物を建築するうえでかかる付帯費用の割合(対工事費)。"
                        :floatLength="1"
                        roundType="down"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 修繕費率<br />(対工事費)(%)</b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <allyText
                        rules="required|min_value:0|max_value:100"
                        :name="'rateOfRepairCosts' + column.id"
                        type="number"
                        step="0.1"
                        mode="Edit"
                        v-model="input.defaultValue.rentalProperty[column.id].rateOfRepairCosts"
                        :loading="loading"
                        :title="'修繕費率(対工事費)' + (column.id + 1)"
                        :nonAppear="true"
                        tips="経常的にかかる修繕費の割合。"
                        :floatLength="1"
                        roundType="down"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 一戸当たりの<br />修繕費(万円/年)</b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <allyText
                        rules="required|min_value:0|max_value:999999999"
                        :name="'repairCostsPerUnit' + column.id"
                        type="number"
                        step="0.1"
                        mode="Edit"
                        v-model="input.defaultValue.rentalProperty[column.id].repairCostsPerUnit"
                        :loading="loading"
                        :title="'一戸当たりの修繕費' + (column.id + 1)"
                        :nonAppear="true"
                        :separation="true"
                        :floatLength="1"
                        roundType="down"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 一戸当たりの<br />必要駐車場台数</b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <allyText
                        rules="required|min_value:0|max_value:100"
                        :name="'parkingLot' + column.id"
                        type="number"
                        mode="Edit"
                        v-model="input.defaultValue.rentalProperty[column.id].parkingLot"
                        :loading="loading"
                        :title="'一戸当たりの必要駐車場台数' + (column.id + 1)"
                        :nonAppear="true"
                        :separation="true"
                        roundType="down"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 一台当たりの<br />必要面積(㎡)</b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <allyText
                        rules="required|min_value:0|max_value:9999"
                        :name="'parkingSquare' + column.id"
                        type="number"
                        step="0.1"
                        mode="Edit"
                        v-model="input.defaultValue.rentalProperty[column.id].parkingSquare"
                        :loading="loading"
                        :title="'一台当たりの必要面積' + (column.id + 1)"
                        :nonAppear="true"
                        :separation="true"
                        :floatLength="1"
                        roundType="down"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 建物の固定資産税<br />評価率(対工事費)(%) </b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <allyText
                        rules="required|min_value:0|max_value:100"
                        :name="'propertyTaxAssessmentRate' + column.id"
                        type="number"
                        step="0.1"
                        mode="Edit"
                        v-model="
                          input.defaultValue.rentalProperty[column.id].propertyTaxAssessmentRate
                        "
                        :loading="loading"
                        :title="'固定資産税評価率(対工事費)' + (column.id + 1)"
                        :nonAppear="true"
                        tips="工事費の60～70％で評価されることが多い。"
                        :floatLength="1"
                        roundType="down"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 借家権割合<br />(%) </b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <allyText
                        rules="required|min_value:0|max_value:100"
                        :name="'percentageOfTenancyRights' + column.id"
                        type="number"
                        step="0.1"
                        mode="Edit"
                        v-model="
                          input.defaultValue.rentalProperty[column.id].percentageOfTenancyRights
                        "
                        :loading="loading"
                        :title="'借家権割合' + (column.id + 1)"
                        :nonAppear="true"
                        tips="一般的に30％で設定される。"
                        :floatLength="1"
                        roundType="down"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title">
                自己資金比率 <br />
                (対工事費)(%)
              </b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <allyText
                        rules="required|min_value:0|max_value:100"
                        :name="'equityRatio' + column.id"
                        type="number"
                        step="0.1"
                        mode="Edit"
                        v-model="input.defaultValue.rentalProperty[column.id].equityRatio"
                        :loading="loading"
                        :title="'自己資金比率' + (column.id + 1)"
                        :nonAppear="true"
                        :floatLength="1"
                        roundType="down"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 借入金利<br />(%) </b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <allyText
                        rules="required|min_value:0|max_value:100"
                        :name="'equityRatio' + column.id"
                        type="number"
                        step="0.01"
                        mode="Edit"
                        v-model="input.defaultValue.rentalProperty[column.id].borrowingRate"
                        :loading="loading"
                        :title="'借入金利' + (column.id + 1)"
                        :nonAppear="true"
                        :floatLength="2"
                        roundType="down"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 借入期間<br />(年) </b-col>
              <b-col v-for="column in columns" :key="column.id" class="component">
                <b-container>
                  <b-row>
                    <b-col class="component" style="margin-right:10px;">
                      <allyText
                        rules="required|min_value:0|max_value:45"
                        :name="'equityRatio' + column.id"
                        type="number"
                        mode="Edit"
                        v-model="input.defaultValue.rentalProperty[column.id].borrowingPeriod"
                        :loading="loading"
                        :title="'借入期間' + (column.id + 1)"
                        :nonAppear="true"
                        roundType="down"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
        <b-tab title="その他">
          <b-card-group columns>
            <b-card header="固都税" id="taxLand">
              <b-container>
                <b-row>
                  <b-col class="inputTitle mb-2">
                    固定資産税
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:100"
                      name="defaultValue-vacantLotOrLand-annualMaintenanceFee"
                      title="固定資産税"
                      type="number"
                      step="0.01"
                      v-model="input.defaultValue.taxLand.propertyTax"
                      :loading="loading"
                      :nonAppear="true"
                      :floatLength="2"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">％</b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle mb-2">
                    都市計画税
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:100"
                      name="defaultValue-vacantLotOrLand-annualMaintenanceFee"
                      title="都市計画税"
                      type="number"
                      step="0.01"
                      v-model="input.defaultValue.taxLand.cityPlanningTax"
                      :loading="loading"
                      :nonAppear="true"
                      :floatLength="2"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">％</b-col>
                </b-row>
              </b-container>
            </b-card>
            <b-card header="店舗・事務所">
              <b-container id="parking">
                <b-row>
                  <b-col class="inputTitle mb-2">
                    工事費
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:999999"
                      name="defaultValue-storeOffice-constructionCostPerUnit"
                      title="店舗・事務所:工事費"
                      type="number"
                      v-model="input.defaultValue.storeOffice.constructionCostPerUnit"
                      :loading="loading"
                      :nonAppear="true"
                      :separation="true"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">万円/坪</b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle my-2">
                    管理費率(対賃料)
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:100"
                      name="defaultValue-storeOffice-managementCostRate"
                      title="店舗・事務所:管理費率(対賃料)"
                      type="number"
                      step="0.1"
                      v-model="input.defaultValue.storeOffice.managementCostRate"
                      :loading="loading"
                      :nonAppear="true"
                      tips="不動産管理会社に支払う管理費率。"
                      :floatLength="1"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">％</b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle my-2">
                    空室率(対賃料)
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:100"
                      name="defaultValue-storeOffice-vacancyRate"
                      title="店舗・事務所:空室率(対賃料)"
                      type="number"
                      step="0.1"
                      v-model="input.defaultValue.storeOffice.vacancyRate"
                      :loading="loading"
                      :nonAppear="true"
                      :floatLength="1"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">％</b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle my-2">
                    自己資金比率(対工事費)
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:100"
                      name="defaultValue-storeOffice-equityRatio"
                      title="店舗・事務所:自己資金比率(対工事費)"
                      type="number"
                      step="0.1"
                      v-model="input.defaultValue.storeOffice.equityRatio"
                      :loading="loading"
                      :nonAppear="true"
                      :floatLength="1"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">％</b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle my-2">
                    借入金利
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:100"
                      name="defaultValue-storeOffice-borrowingRate"
                      title="店舗・事務所:借入金利"
                      type="number"
                      step="0.01"
                      v-model="input.defaultValue.storeOffice.borrowingRate"
                      :loading="loading"
                      :nonAppear="true"
                      :floatLength="2"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">％</b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle my-2">
                    借入期間
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:45"
                      name="defaultValue-storeOffice-borrowingPeriod"
                      title="店舗・事務所:借入期間"
                      type="number"
                      v-model="input.defaultValue.storeOffice.borrowingPeriod"
                      :loading="loading"
                      :nonAppear="true"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">年</b-col>
                </b-row>
              </b-container>
            </b-card>
            <b-card header="月極駐車場">
              <b-container id="parking">
                <b-row>
                  <b-col class="inputTitle my-2" cols="8">
                    １台あたりの必要面積
                  </b-col>
                  <b-col class="inputTitle my-2">
                    単位
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:999999"
                      name="defaultValue-monthlyParkingLot-parkingAreaPerCar"
                      title="月極駐車場:1台あたりの必要面積"
                      type="number"
                      step="0.1"
                      v-model="input.defaultValue.monthlyParkingLot.parkingAreaPerCar"
                      :loading="loading"
                      :nonAppear="true"
                      :tips="
                        '通路、展開スペース等を含む1台当たりの必要面積。\n一般的に7～9坪で設定する。'
                      "
                      :separation="true"
                      :floatLength="1"
                      roundType="down"
                    />
                  </b-col>
                  <b-col
                    cols="4"
                    :class="[
                      input.defaultValue.monthlyParkingLot.parkingAreaPerCarUnit == 1
                        ? 'squareMeter'
                        : 'landMeasurement',
                      'selectUnit',
                    ]"
                  >
                    <allySelect
                      title=""
                      :items="[
                        { value: 1, text: '㎡' },
                        { value: 2, text: '坪' },
                      ]"
                      v-model="input.defaultValue.monthlyParkingLot.parkingAreaPerCarUnit"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle my-2">
                    工事費
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:999999"
                      name="defaultValue-monthlyParkingLot-constructionCostPerUnit"
                      title="月極駐車場:工事費"
                      type="number"
                      v-model="input.defaultValue.monthlyParkingLot.constructionCostPerUnit"
                      :loading="loading"
                      :nonAppear="true"
                      :separation="true"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">万円/坪</b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle my-2">
                    管理費率(対賃料)
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:100"
                      name="defaultValue-monthlyParkingLot-managementCostRate"
                      title="月極駐車場:管理費率(対賃料)"
                      type="number"
                      step="0.1"
                      v-model="input.defaultValue.monthlyParkingLot.managementCostRate"
                      :loading="loading"
                      :nonAppear="true"
                      tips="不動産管理会社に支払う管理費率。"
                      :floatLength="1"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">％</b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle my-2">
                    空車率(対賃料)
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:100"
                      name="defaultValue-monthlyParkingLot-vacancyRate"
                      title="月極駐車場:空車率(対賃料)"
                      type="number"
                      step="0.1"
                      v-model="input.defaultValue.monthlyParkingLot.vacancyRate"
                      :loading="loading"
                      :nonAppear="true"
                      :floatLength="1"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">％</b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle my-2">
                    自己資金比率(対工事費)
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:100"
                      name="defaultValue-monthlyParkingLot-equityRatio"
                      title="月極駐車場:自己資金比率(対工事費)"
                      type="number"
                      step="0.1"
                      v-model="input.defaultValue.monthlyParkingLot.equityRatio"
                      :loading="loading"
                      :nonAppear="true"
                      :floatLength="1"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">％</b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle my-2">
                    借入金利
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:100"
                      name="defaultValue-monthlyParkingLot-borrowingRate"
                      title="月極駐車場:借入金利"
                      type="number"
                      step="0.01"
                      v-model="input.defaultValue.monthlyParkingLot.borrowingRate"
                      :loading="loading"
                      :nonAppear="true"
                      :floatLength="2"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">％</b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle my-2">
                    借入期間
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:45"
                      name="defaultValue-monthlyParkingLot-borrowingPeriod"
                      title="月極駐車場:借入期間"
                      type="number"
                      v-model="input.defaultValue.monthlyParkingLot.borrowingPeriod"
                      :loading="loading"
                      :nonAppear="true"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">年</b-col>
                </b-row>
              </b-container>
            </b-card>
            <b-card header="貸地" id="rentLand">
              <b-form-group>
                <b-container>
                  <b-row>
                    <b-col>
                      <b-form-radio
                        v-model="input.defaultValue.leasedLand.modeRent"
                        name="rentLandRadios"
                        value="1"
                        style="padding-top: 2px;"
                        >月地代</b-form-radio
                      >
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <allyText
                        :rules="
                          input.defaultValue.leasedLand.modeRent == 1
                            ? 'required|min_value:0|max_value:999999'
                            : undefined
                        "
                        name="defaultValue-leasedLand-monthlyLandRentPerSquareMeter"
                        type="number"
                        step="0.01"
                        :mode="input.defaultValue.leasedLand.modeRent == 1 ? 'Edit' : 'View'"
                        v-model="input.defaultValue.leasedLand.monthlyLandRentPerSquareMeter"
                        :loading="loading"
                        :floatLength="2"
                        title="貸地:月地代"
                        :nonAppear="true"
                        :separation="true"
                        roundType="down"
                      />
                    </b-col>
                    <b-col cols="4" class="unit">万円/坪</b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <b-form-radio
                        v-model="input.defaultValue.leasedLand.modeRent"
                        name="rentLandRadios"
                        value="2"
                        style="padding-top: 2px;"
                        >時価に対する年間地代利回り</b-form-radio
                      >
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <allyText
                        :rules="
                          input.defaultValue.leasedLand.modeRent == 2
                            ? 'required|min_value:0|max_value:100'
                            : undefined
                        "
                        name="defaultValue-leasedLand-annualLandRentYieldToMarketValue"
                        type="number"
                        step="0.1"
                        :mode="input.defaultValue.leasedLand.modeRent == 2 ? 'Edit' : 'View'"
                        v-model="input.defaultValue.leasedLand.annualLandRentYieldToMarketValue"
                        :loading="loading"
                        :floatLength="1"
                        roundType="down"
                      />
                    </b-col>
                    <b-col cols="4" class="unit">％</b-col>
                  </b-row>
                  <b-row>
                    <b-col class="inputTitle my-2">
                      管理費率(対地代)
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <allyText
                        rules="required|min_value:0|max_value:100"
                        name="defaultValue-leasedLand-managementCostRate"
                        type="number"
                        step="0.1"
                        v-model="input.defaultValue.leasedLand.managementCostRate"
                        :loading="loading"
                        title="貸地:管理費率(対地代)"
                        :nonAppear="true"
                        tips="不動産管理会社に支払う管理費率。"
                        :floatLength="1"
                        roundType="down"
                      />
                    </b-col>
                    <b-col cols="4" class="unit">％</b-col>
                  </b-row>
                </b-container>
              </b-form-group>
            </b-card>
            <b-card header="更地" id="vacantLand">
              <b-container>
                <b-row>
                  <b-col class="inputTitle mb-2">
                    年間維持管理費
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:999999"
                      name="defaultValue-vacantLotOrLand-annualMaintenanceFee"
                      title="更地:年間維持管理費"
                      type="number"
                      v-model="input.defaultValue.vacantLotOrLand.annualMaintenanceFee"
                      :loading="loading"
                      :nonAppear="true"
                      :separation="true"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">万円/年</b-col>
                </b-row>
              </b-container>
            </b-card>
            <b-card header="売却">
              <b-container id="sale">
                <!-- <b-row>
                  <b-col>
                    <allyText
                      rules="required"
                      name="defaultValue-sale-priceAtTheTimeOfPurchase"
                      title="購入時の価格"
                      type="number"
                      min="0"
                      max="999999"
                      v-model="input.defaultValue.sale.priceAtTheTimeOfPurchase"
                      :loading="loading"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">万円 </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required"
                      name="defaultValue-sale-otherTransferCosts"
                      title="その他譲渡費用"
                      type="number"
                      min="0"
                      max="999999"
                      v-model="input.defaultValue.sale.otherTransferCosts"
                      :loading="loading"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">万円 </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required"
                      name="defaultValue-sale-periodOfOwnership"
                      title="所有期間"
                      type="number"
                      min="0"
                      max="100"
                      v-model="input.defaultValue.sale.periodOfOwnership"
                      :loading="loading"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">年 </b-col>
                </b-row> -->
                <!--
                <b-row>
                  <b-col class="inputTitle mb-2">
                    譲渡費用率(対売却価格)
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required"
                      name="defaultValue-sale-transferCostRate"
                      title="売地:譲渡費用率(対売却額)"
                      type="number"
                      min="0"
                      max="100"
                      step="0.1"
                      v-model="input.defaultValue.sale.transferCostRate"
                      :loading="loading"
                      :nonAppear="true"
                      tips="売却時の仲介料等の付帯費用の割合。"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">％ </b-col>
                </b-row>
                -->
                <b-row>
                  <b-col class="inputTitle my-2">
                    譲渡所得税率
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle" offset="1">
                    短期
                  </b-col>
                </b-row>
                <b-row>
                  <b-col offset="1">
                    <allyText
                      rules="required|min_value:0|max_value:100"
                      name="defaultValue-sale-capitalGainsTaxRateShortTerm"
                      title="売地:短期"
                      type="number"
                      step="0.01"
                      v-model="input.defaultValue.sale.capitalGainsTaxRateShortTerm"
                      :loading="loading"
                      :nonAppear="true"
                      :floatLength="3"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">％ </b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle mt-2" offset="1">
                    長期
                  </b-col>
                </b-row>
                <b-row>
                  <b-col offset="1">
                    <allyText
                      rules="required|min_value:0|max_value:100"
                      name="defaultValue-sale-capitalGainsTaxRateLongTerm"
                      title="売地:長期"
                      type="number"
                      step="0.01"
                      v-model="input.defaultValue.sale.capitalGainsTaxRateLongTerm"
                      :loading="loading"
                      :nonAppear="true"
                      :floatLength="3"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">％ </b-col>
                </b-row>
                <b-row>
                  <b-col class="inputTitle my-2">
                    譲渡益の年間運用利回り
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <allyText
                      rules="required|min_value:0|max_value:100"
                      name="defaultValue-sale-annualInvestmentYield"
                      title="売地:譲渡益の年間運用利回り"
                      type="number"
                      step="0.01"
                      v-model="input.defaultValue.sale.annualInvestmentYield"
                      :loading="loading"
                      :nonAppear="true"
                      tips="売却益を金融商品等で運用した場合の利回り。"
                      :floatLength="3"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="4" class="unit">％ </b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-card-group>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
  export default {
    props: {
      modeRent: {
        type: String,
        required: true,
      },
      master: {},
    },
    data() {
      return {
        loading: false,
        input: this.moldDefaultValue(),
        columns: [
          { id: 0, text: '集合型賃貸①', checked: false },
          { id: 1, text: '集合型賃貸②', checked: false },
          { id: 2, text: '集合型賃貸③', checked: false },
          { id: 3, text: '集合型賃貸④', checked: false },
          { id: 4, text: '戸建賃貸①', checked: false },
          { id: 5, text: '戸建賃貸②', checked: false },
          { id: 6, text: '戸建賃貸③', checked: false },
        ],
        floorPlanItems: [
          { text: '1R', value: '1R' },
          { text: '1K', value: '1K' },
          { text: '1LDK', value: '1LDK' },
          { text: '2LDK', value: '2LDK' },
          { text: '3LDK', value: '3LDK' },
          { text: '4LDK', value: '4LDK' },
          { text: '5LDK', value: '5LDK' },
        ],
        structureItems: [
          { text: '木造', value: '木造' },
          { text: '鉄骨鉄筋コンクリート造', value: '鉄骨鉄筋コンクリート造' },
          { text: '鉄筋コンクリート造', value: '鉄筋コンクリート造' },
          { text: '軽量鉄骨造', value: '軽量鉄骨造' },
          { text: '重量鉄骨造', value: '重量鉄骨造' },
        ],
        kindItems: [
          { text: '事務所', value: '事務所' },
          { text: '店舗', value: '店舗' },
          { text: '住宅', value: '住宅' },
          { text: '旅館/ホテル', value: '旅館/ホテル' },
          { text: '病院/診療所', value: '病院/診療所' },
          { text: '工場/倉庫', value: '工場/倉庫' },
        ],
      };
    },
    mounted: async function() {
      this.input = await this.copyMaster();
    },
    methods: {
      //storeにコミットする。
      commitMaster: function(isFilledMaster) {
        let setMaster = {};
        setMaster.climingRate = this.master.climingRate;
        setMaster.constructionCost = this.master.constructionCost;
        setMaster.depreciationRate = this.master.depreciationRate;
        setMaster.incomeTax = this.master.incomeTax;
        setMaster.inheritanceTax = this.master.inheritanceTax;
        setMaster.legalServiceLife = this.master.legalServiceLife;
        setMaster.stampDuty = this.master.stampDuty;
        setMaster.taxRate = this.master.taxRate;
        setMaster.unitPrice = this.master.unitPrice;

        // 入力値の補正
        // 住居用賃貸物件タブ
        for (let i = 0; i < this.input.defaultValue.rentalProperty.length; i++) {
          this.input.defaultValue.rentalProperty[i].requireArea = Math.floor(
            this.input.defaultValue.rentalProperty[i].requireArea
          );
          this.input.defaultValue.rentalProperty[i].constructionCostPerUnit = Math.floor(
            this.input.defaultValue.rentalProperty[i].constructionCostPerUnit
          );
          this.input.defaultValue.rentalProperty[i].managementCostRate =
            Math.floor(this.input.defaultValue.rentalProperty[i].managementCostRate * 10) / 10;
          this.input.defaultValue.rentalProperty[i].vacancyRate =
            Math.floor(this.input.defaultValue.rentalProperty[i].vacancyRate * 10) / 10;
          this.input.defaultValue.rentalProperty[i].firstYearExpenseRatio =
            Math.floor(this.input.defaultValue.rentalProperty[i].firstYearExpenseRatio * 10) / 10;
          this.input.defaultValue.rentalProperty[i].rateOfRepairCosts =
            Math.floor(this.input.defaultValue.rentalProperty[i].rateOfRepairCosts * 10) / 10;
          this.input.defaultValue.rentalProperty[i].repairCostsPerUnit =
            Math.floor(this.input.defaultValue.rentalProperty[i].repairCostsPerUnit * 10) / 10;
          this.input.defaultValue.rentalProperty[i].parkingLot = Math.floor(
            this.input.defaultValue.rentalProperty[i].parkingLot
          );
          this.input.defaultValue.rentalProperty[i].parkingSquare =
            Math.floor(this.input.defaultValue.rentalProperty[i].parkingSquare * 10) / 10;
          this.input.defaultValue.rentalProperty[i].propertyTaxAssessmentRate =
            Math.floor(this.input.defaultValue.rentalProperty[i].propertyTaxAssessmentRate * 10) /
            10;
          this.input.defaultValue.rentalProperty[i].percentageOfTenancyRights =
            Math.floor(this.input.defaultValue.rentalProperty[i].percentageOfTenancyRights * 10) /
            10;
          this.input.defaultValue.rentalProperty[i].equityRatio =
            Math.floor(this.input.defaultValue.rentalProperty[i].equityRatio * 10) / 10;
          this.input.defaultValue.rentalProperty[i].borrowingRate =
            Math.floor(this.input.defaultValue.rentalProperty[i].borrowingRate * 100) / 100;
          this.input.defaultValue.rentalProperty[i].borrowingPeriod = Math.floor(
            this.input.defaultValue.rentalProperty[i].borrowingPeriod
          );
        }
        // その他タブ
        this.input.defaultValue.taxLand.propertyTax =
          Math.floor(this.input.defaultValue.taxLand.propertyTax * 100) / 100;
        this.input.defaultValue.taxLand.cityPlanningTax =
          Math.floor(this.input.defaultValue.taxLand.cityPlanningTax * 100) / 100;
        this.input.defaultValue.storeOffice.constructionCostPerUnit = Math.floor(
          this.input.defaultValue.storeOffice.constructionCostPerUnit
        );
        this.input.defaultValue.storeOffice.managementCostRate =
          Math.floor(this.input.defaultValue.storeOffice.managementCostRate * 10) / 10;
        this.input.defaultValue.storeOffice.vacancyRate =
          Math.floor(this.input.defaultValue.storeOffice.vacancyRate * 10) / 10;
        this.input.defaultValue.storeOffice.equityRatio =
          Math.floor(this.input.defaultValue.storeOffice.equityRatio * 10) / 10;
        this.input.defaultValue.storeOffice.borrowingRate =
          Math.floor(this.input.defaultValue.storeOffice.borrowingRate * 100) / 100;
        this.input.defaultValue.storeOffice.borrowingPeriod = Math.floor(
          this.input.defaultValue.storeOffice.borrowingPeriod
        );
        this.input.defaultValue.monthlyParkingLot.parkingAreaPerCar =
          Math.floor(this.input.defaultValue.monthlyParkingLot.parkingAreaPerCar * 10) / 10;
        this.input.defaultValue.monthlyParkingLot.constructionCostPerUnit = Math.floor(
          this.input.defaultValue.monthlyParkingLot.constructionCostPerUnit
        );
        this.input.defaultValue.monthlyParkingLot.managementCostRate =
          Math.floor(this.input.defaultValue.monthlyParkingLot.managementCostRate * 10) / 10;
        this.input.defaultValue.monthlyParkingLot.vacancyRate =
          Math.floor(this.input.defaultValue.monthlyParkingLot.vacancyRate * 10) / 10;
        this.input.defaultValue.monthlyParkingLot.equityRatio =
          Math.floor(this.input.defaultValue.monthlyParkingLot.equityRatio * 10) / 10;
        this.input.defaultValue.monthlyParkingLot.borrowingRate =
          Math.floor(this.input.defaultValue.monthlyParkingLot.borrowingRate * 100) / 100;
        this.input.defaultValue.monthlyParkingLot.borrowingPeriod = Math.floor(
          this.input.defaultValue.monthlyParkingLot.borrowingPeriod
        );
        this.input.defaultValue.leasedLand.monthlyLandRentPerSquareMeter = Math.floor(
          this.input.defaultValue.leasedLand.monthlyLandRentPerSquareMeter * 100
        ) / 100;
        this.input.defaultValue.leasedLand.annualLandRentYieldToMarketValue =
          Math.floor(this.input.defaultValue.leasedLand.annualLandRentYieldToMarketValue * 10) / 10;
        this.input.defaultValue.leasedLand.managementCostRate =
          Math.floor(this.input.defaultValue.leasedLand.managementCostRate * 10) / 10;
        this.input.defaultValue.vacantLotOrLand.annualMaintenanceFee = Math.floor(
          this.input.defaultValue.vacantLotOrLand.annualMaintenanceFee
        );
        this.input.defaultValue.sale.capitalGainsTaxRateShortTerm =
          Math.floor(this.input.defaultValue.sale.capitalGainsTaxRateShortTerm * 100) / 100;
        this.input.defaultValue.sale.capitalGainsTaxRateLongTerm =
          Math.floor(this.input.defaultValue.sale.capitalGainsTaxRateLongTerm * 100) / 100;
        this.input.defaultValue.sale.annualInvestmentYield =
          Math.floor(this.input.defaultValue.sale.annualInvestmentYield * 100) / 100;

        setMaster.defaultValue = this.input.defaultValue;
        setMaster.defaultValue.isFilledMaster = isFilledMaster;
        this.$store.commit('setMaster', setMaster);
      },
      //defaultValueの型設定メソッド
      moldDefaultValue: function() {
        let input = {};
        input.defaultValue = {
          rentalProperty: new Array(7).fill(null).map(() => ({
            floorPlanAndName: null,
            requireArea: null,
            constructionCostPerUnit: null,
            structure: null,
            kind: null,
            managementCostRate: null,
            vacancyRate: null,
            firstYearExpenseRatio: null,
            rateOfRepairCosts: null,
            repairCostsPerUnit: null,
            parkingLot: null,
            parkingSquare: null,
            propertyTaxAssessmentRate: null,
            percentageOfTenancyRights: null,
            legalServiceLife: null,
            equityRatio: null,
            borrowingRate: null,
            borrowingPeriod: null,
          })),
          monthlyParkingLot: {
            landArea: null,
            landAreaUnit: 2,
            parkingAreaPerCar: null,
            parkingAreaPerCarUnit: null,
            constructionCostPerUnit: null,
            managementCostRate: null,
            vacancyRate: null,
            equityRatio: null,
            borrowingRate: null,
            borrowingPeriod: null,
          },
          storeOffice: {
            constructionCostPerUnit: null,
            managementCostRate: null,
            vacancyRate: null,
            equityRatio: null,
            borrowingRate: null,
            borrowingPeriod: null,
          },
          leasedLand: {
            modeRent: null,
            monthlyLandRentPerSquareMeter: null,
            annualLandRentYieldToMarketValue: null,
            managementCostRate: null,
          },
          vacantLotOrLand: {
            annualMaintenanceFee: null,
          },
          sale: {
            priceAtTheTimeOfPurchase: null,
            otherTransferCosts: null,
            periodOfOwnership: null,
            annualInvestmentYield: null,
            transferCostRate: null,
            capitalGainsTaxRateShortTerm: null,
            capitalGainsTaxRateLongTerm: null,
          },
          taxLand: {
            propertyTax: null,
            cityPlanningTax: null,
          },
          isFilledMaster: null,
        };
        return input;
      },
      //store.master.defaultValueのコピーメソッド
      //コピーをしないとv-modelによってstoreも強制的に上書きされてしまう
      copyMaster: function() {
        let input = this.moldDefaultValue();
        Object.keys(this.master.defaultValue).forEach(key => {
          switch (key) {
            case 'isFilledMaster':
              input.defaultValue[key] = this.master.defaultValue[key];
              break;
            case 'rentalProperty':
              this.master.defaultValue[key].forEach((data, index) => {
                Object.keys(data).forEach(detail => {
                  input.defaultValue[key][index][detail] = data[detail];
                });
              });
              break;
            default:
              Object.keys(this.master.defaultValue[key]).forEach(detail => {
                input.defaultValue[key][detail] = this.master.defaultValue[key][detail];
              });
              break;
          }
        });
        // ※戸建賃貸の間取り名称は'戸建'に固定※
        for (let id of [4, 5, 6]) {
          if (input.defaultValue.rentalProperty[id]?.floorPlanAndName == null) {
            input.defaultValue.rentalProperty[id].floorPlanAndName = '戸建';
          }
        }
        return input;
      },
    },
    watch: {
      constructionCostsPerUnit: function() {
        this.master.defaultValue.rentalProperty.forEach((value, index) => {
          if (
            value.constructionCostPerUnit !=
              this.constructionCostsPerUnit[index].constructionCostPerUnit ||
            value.rateOfRepairCosts != this.constructionCostsPerUnit[index].rateOfRepairCosts
          ) {
            this.input.defaultValue.rentalProperty[index].repairCostsPerUnit =
              Math.round(
                (this.constructionCostsPerUnit[index].constructionCostPerUnit *
                  this.constructionCostsPerUnit[index].rateOfRepairCosts) /
                  10
              ) / 10;
          }
        });
      },
    },
    computed: {
      structure: function() {
        return this.master.defaultValue.rentalProperty.map(val => val.structure);
      },
      kind: function() {
        return this.master.defaultValue.rentalProperty.map(val => val.kind);
      },
      //一戸当たりの修繕費計算に使われる要素
      constructionCostsPerUnit: function() {
        let element = [];
        this.input.defaultValue.rentalProperty.forEach(value => {
          element.push({
            constructionCostPerUnit: value.constructionCostPerUnit,
            rateOfRepairCosts: value.rateOfRepairCosts,
          });
        });
        return element;
      },
      // 法定耐用年数
      legalServiceLife: function() {
        return this.columns.map((val, i) => {
          // マスタチェック
          if (this.master.legalServiceLife == null) {
            return null;
          }
          // 入力チェック
          if (
            // this.loading != false ||
            this.input.defaultValue.rentalProperty[i].structure == null || // 構造
            this.input.defaultValue.rentalProperty[i].kind == null // 用途
          ) {
            return null;
          }
          // マスタ検索
          var structure = this.input.defaultValue.rentalProperty[i].structure;
          var kind = this.input.defaultValue.rentalProperty[i].kind;
          for (let x of this.master.legalServiceLife) {
            if (structure == x[0] && kind == x[1]) {
              const result = Number(x[2]);
              this.master.defaultValue.rentalProperty[i].legalServiceLife = result;
              return result;
            }
          }
          return null;
        });
      },
    },
  };
</script>
<style scoped>
  #realEstateSuitability .nav-link {
    font-size: medium;
  }
  #realEstateSuitability table tr {
    height: 1.5em;
  }
  #realEstateSuitability .component {
    padding: 0;
  }
  #realEstateSuitability table tbody td,
  #realEstateSuitability table thead th {
    padding-top: 0.1em;
    padding-bottom: 0.1em;
  }
  #realEstateSuitability .title {
    padding-right: 0.5em;
    display: grid;
    text-align: right;
    font-weight: bold;
    align-items: center;
  }
  #realEstateSuitability .value {
    padding: 0;
    margin: 0;
    display: table-cell;
    align-items: center;
  }
  #realEstateSuitability .unit {
    padding-left: 0.5em;
    padding-right: 0;
    display: flex;
    text-align: left;
    align-items: center;
  }
  #realEstateSuitability .currency {
    text-align: right;
  }
  #realEstateSuitability .ally-select select {
    height: 2.875rem;
  }
  #realEstateSuitability .form-group {
    margin-top: 0;
    margin-bottom: 0;
  }
  #realEstateSuitability .form-control {
    padding: 0.5em;
  }
  #realEstateSuitability .form-group label,
  #realEstateSuitability .form-check-label {
    font-weight: bold;
  }
  #realEstateSuitability .checkbox,
  #realEstateSuitability .checkbox label {
    font-weight: bold;
  }
  #realEstateSuitability #actualInheritancePercentage tr {
    height: 1.5em;
  }
  #realEstateSuitability input[type='number'] {
    text-align: right;
  }
  #realEstateSuitability legend {
    padding-bottom: 0.5em;
    font-weight: bold;
  }
  #realEstateSuitability input[type='radio'] {
    margin-left: unset;
  }
  #realEstateSuitability input[name='rentLandRadios'] + label,
  #realEstateSuitability #spouse label {
    font-size: initial;
  }
  #realEstateSuitability .card {
    margin-top: 0.5em;
    border-top-color: gainsboro !important;
  }
  #realEstateSuitability #ownerInformation .card,
  #realEstateSuitability #ownerInformation .card-body,
  #realEstateSuitability #siteInformation .card,
  #realEstateSuitability #siteInformation .card-body {
    border: 0 !important;
    padding-top: 0.5em;
    padding-bottom: 0;
    padding-left: 0.25em;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  #realEstateSuitability .card-header {
    font-size: medium;
    font-weight: bold;
  }
  #defaultValue #rentalProperty,
  #masterPlan #rentalProperty {
    max-width: unset;
  }
  #defaultValue #parking .row,
  #defaultValue #sale .row,
  #siteConditions .row,
  #locationConditions .row {
    margin-bottom: 1em;
  }
  #defaultValue #parking .unit,
  #defaultValue #sale .unit,
  #defaultValue #vacantLand .unit,
  #siteConditions .unit {
    padding-bottom: 1em;
    align-items: flex-end;
  }
  #realEstateSuitability input,
  #realEstateSuitability .title,
  #realEstateSuitability .ally-select select,
  #realEstateSuitability .unit {
    font-size: small;
  }
  #realEstateSuitability .checkbox,
  #realEstateSuitability .checkbox label {
    font-size: medium;
  }
  #realEstateSuitability #graph .nav-link {
    font-size: unset;
    padding: 4px;
    margin: 0;
  }
  #realEstateSuitability .carousel-control-prev,
  #realEstateSuitability .carousel-control-next {
    width: 5%;
  }
  #realEstateSuitability #siteConditions #ownerInformation .card-columns,
  #realEstateSuitability #siteConditions #siteInformation .card-columns {
    column-count: 2;
  }
  #realEstateSuitability #multiple-file-preview img {
    width: 32px;
    height: 32px;
  }
  @media screen and (min-width: 576px) {
    #realEstateSuitability #defaultValue .card-columns,
    #realEstateSuitability #masterPlan .card-columns {
      column-count: 1;
    }
    #realEstateSuitability #siteConditions .card-columns {
      column-count: 1;
    }
  }
  @media screen and (min-width: 768px) {
    /* iPad     - 縦 :  768 */
    #realEstateSuitability #defaultValue .card-columns,
    #realEstateSuitability #masterPlan .card-columns,
    #realEstateSuitability #siteConditions .card-columns {
      column-count: 1;
    }
  }
  @media screen and (min-width: 992px) {
    /* iPad Pro - 縦 : 1024 */
    /* iPad     - 横 : 1024 */
    #realEstateSuitability #defaultValue .card-columns,
    #realEstateSuitability #masterPlan .card-columns,
    #realEstateSuitability #siteConditions .card-columns {
      column-count: 2;
    }
  }
  @media screen and (min-width: 1200px) {
    /* iPad Pro - 横 : 1366 */
    #realEstateSuitability #defaultValue .card-columns,
    #realEstateSuitability #masterPlan .card-columns {
      column-count: 3;
    }
    #realEstateSuitability #siteConditions .card-columns {
      column-count: 2;
    }
  }
  .image-upload > input {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .previewImage {
    width: 200px;
    height: 300px;
    border-radius: 0%;
  }
  .capitalGainsTaxRate,
  .inputTitle {
    font-weight: bold;
    font-size: 0.875rem;
  }
  .danger {
    color: orangered;
  }
</style>
