<template>
  <g
    :style="{
      'pointer-events': obj.pointerEvents,
    }"
  >
    <circle r="20" stroke="blue" stroke-width="5" fill="transparent" :data-id="obj.id" />
    <circle r="10" fill="blue" :data-id="obj.id" />
  </g>
</template>
<script>
  // @ts-check
  import { computed, defineComponent } from '@vue/composition-api';

  export default defineComponent({
    props: {
      obj: Object, // PvObject
    },
    setup(props) {
      const position = computed(() => {
        return props.obj.offset();
      });

      return {
        position,
      };
    },
  });
</script>
