<template>
  <CommonLayout title="ご挨拶">
    <template v-slot>
      <span style="font-weight: bold; font-size: 100px;">{{
        (commonInput.customerName || '********') + ' 様'
      }}</span>
      <div
        style="
                    font-size: 100px;
                    padding: 0 100px 0 120px;
                "
      >
        <br />
        <br />
        この度は当社をご用命を賜り、誠に有難うございます。
        <br />
        {{
          `${commonInput.customerName}様が相続対策（分割対策、納税資金対策、節税対策）の手法等を検討されるうえで、一つの参考にしていただければ幸いです。`
        }}
        <br />
        なお、本レポートは税金に関する基礎知識や一般的な事例を基にした税額計算の方法等をお知らせするものであり、個別具体的な情報をお示しするものではございません。
        <br />
        あくまでも参考情報としてお取り扱いいただき、個別具体的な税務相談や税額計算については必ず税理士に<br />相談されてください。
        <br /><br /><br />
        <div style="font-size: 100px; ">
          ※ご注意ください<br />
          ・本レポートでは、小規模宅地その他の特例は考慮していません。<br />
          ・本レポートに記載された税金に関する情報や税額計算のシミュレーションにより得られた税額を起因として、<br />&nbsp;&thinsp;ご利用者または第三者が損害を被った場合であっても、当社は一切の責任を負いかねます。
        </div>
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';

  export default {
    props: {
      commonInput: {
        type: Object,
        required: true,
      },
      companyInfo: {
        type: Array,
        required: true,
      },
    },
    components: {
      CommonLayout,
    },
    data() {
      return {};
    },
    computed: {
      companyName() {
        if (0 in this.companyInfo) {
          return this.companyInfo[0][1];
        }

        return '';
      },
    },
  };
</script>
