<template>
  <div>
    <!-- VIEW: 非決済モードの画面 -->
    <div>
      非決済モード設定
      <div class="col-12 mt-3">
        <b-button
          ref="changeButton"
          :class="'btn btn-ally' + privilegedClassStr"
          @click="$bvModal.show('non-settlement-modal')"
        >
          {{ displayTexts.button }}
        </b-button>
      </div>
    </div>
    <!-- VIEW: 確認モーダル -->
    <b-modal
      id="non-settlement-modal"
      size="lg"
      header-class="ally-modal text-center"
      footer-class="ally-modal text-center"
      body-class="ally-input-background"
      :title="'非決済モードの切替'"
      ok-variant="light"
      :ok-title="displayTexts.okTitle"
      cancel-title="キャンセル"
      :ok-disabled="
        !(
          functionSwitchMessage === SWITCH_MESSAGES.ON ||
          functionSwitchMessage === SWITCH_MESSAGES.OFF
        )
      "
      centered
      @ok="nonSettlementFunction()"
    >
      <div id="modal-main-message" class="mb-3">{{ displayTexts.confirm }}</div>
      <div id="modal-sub-message" class="ml-4 mt-2 mb-3">
        <h4 class="bold text-red" v-if="displayTexts.caution">ご確認ください</h4>
        {{ displayTexts.caution }}
      </div>
    </b-modal>
  </div>
</template>
<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { changeNonSettlement } from '../../graphql/queries';
  import { SWITCH_MESSAGES } from './Common';

  const moment = require('moment-timezone');
  moment.tz.setDefault('Asia/Tokyo');

  export default {
    props: ['companyInfo'],
    data: () => {
      return {};
    },
    computed: {
      /**
       *  COMPUTED: 画面側用のスイッチメッセージ
       */
      SWITCH_MESSAGES() {
        return SWITCH_MESSAGES;
      },
      /**
       *  COMPUTED: 非決済モードのスイッチメッセージ
       */
      functionSwitchMessage() {
        let result = SWITCH_MESSAGES.NG;
        // 非決済モードの状態を切り替えるメッセージをセット
        if (this.companyInfo && this.companyInfo.companyId) {
          result = this.companyInfo.privilegedFlag ? SWITCH_MESSAGES.OFF : SWITCH_MESSAGES.ON;
        }
        // NOTE: OFFかつ当月のライセンス解除情報が存在した場合、OFF_NGをセット
        //console.log('this.getIsLicenseCanceled()', this.getIsLicenseCanceled());
        if (result === SWITCH_MESSAGES.OFF && this.getIsLicenseCanceled()) {
          result = SWITCH_MESSAGES.OFF_NG;
        }
        return result;
      },
      privilegedClassStr() {
        return this.companyInfo.privilegedFlag ? ' privileged' : '';
      },
      /**
       *  COMPUTED: 表示用テキスト
       */
      displayTexts() {
        const texts = {
          button: '',
          confirm: this.companyInfo.companyName + ' 様',
          caution: '',
          okTitle: '',
        };
        texts.button = this.companyInfo.privilegedFlag ? '通常モードに切り替え' : '非決済モードに切り替え';
        switch (this.functionSwitchMessage) {
          case SWITCH_MESSAGES.ON:
            texts.confirm = texts.confirm + 'を、非決済モードへ移行してもよろしいですか？';
            texts.caution =
              '1. 非決済モードについて\n' +
              '　・会社に所属する全てのユーザーが、権限内で全機能を利用可能となります。\n' +
              '　・メンバーが管理者機能を利用可能になることはありません。\n\n' +
              '2. 今月のライセンス情報が存在する会社様について\n' +
              '　・今月ご利用分の料金は発生いたします。\n' +
              '　・非決済モードへ移行後、今月中に解除することはできません。翌月以降可能となります。\n' +
              '　・移行前にライセンス解約処理を行っていた場合も、今月中に解除することはできません。翌月以降可能となります。';
            texts.okTitle = '非決済モードへ移行する';
            break;
          case SWITCH_MESSAGES.OFF:
            texts.confirm = texts.confirm + 'の非決済モードを、解除してもよろしいですか？';
            texts.okTitle = '非決済モードを解除する';
            break;
          case SWITCH_MESSAGES.OFF_NG:
            texts.confirm = texts.confirm + 'の非決済モードを解除できません';
            texts.caution =
              '今月のライセンス情報が存在した会社様を非決済モードに移行した場合、今月中に解除することはできません。\n' +
              '翌月以降にお試しください。';
            texts.okTitle = '解除できません';
            break;
          default:
            texts.confirm = texts.confirm + 'の非決済モード切替ができません';
            texts.caution =
              'データに問題が生じている可能性があります。\n' +
              'システム管理者までお問い合わせください。';
            texts.okTitle = '切替できません';
            break;
        }
        return texts;
      },
    },
    methods: {
      /**
       *  METHOD: 非決済モード切り替え処理
       */
      async nonSettlementFunction() {
        // スイッチメッセージがON/OFF以外の場合、処理しない
        if (
          !(
            this.functionSwitchMessage === SWITCH_MESSAGES.ON ||
            this.functionSwitchMessage === SWITCH_MESSAGES.OFF
          )
        ) {
          return;
        }
        // ローディングアイコン表示
        this.$emit('loading', true);
        try {
          //非決済モード解除処理
          await API.graphql(
            graphqlOperation(changeNonSettlement, {
              date: this.today,
              functionSwitch: this.functionSwitchMessage,
              companyId: this.companyInfo.id,
            })
          );
        } catch (e) {
          console.error(e, {
            date: this.today,
            functionSwitch: this.functionSwitchMessage,
            companyId: this.companyInfo.id,
          });
        } finally {
          // 切替ボタンからフォーカスを外す
          this.$refs.changeButton.blur();
          // 画面Keyを更新して表示情報を更新する
          this.$emit('updateDisplayKey');
          // ローディングアイコン非表示
          this.$emit('loading', false);
        }
      },
      /**
       *  METHOD: 当月のライセンス解約情報が存在するか判定
       */
      getIsLicenseCanceled() {
        // ライセンス情報が存在するか確認
        if (!(this.companyInfo && this.companyInfo.license)) {
          return false;
        }
        // 今月解約されたライセンスがあるか確認
        return this.companyInfo.license?.some(license => {
          return license.cancelDate
            ? moment(license.cancelDate).isSame(moment(this.today), 'month')
            : false;
        });
      },
    },
  };
</script>
<style scoped>
  #non-settlement-modal #modal-main-message {
    font-size: 1.5rem;
    font-weight: bold;
  }
  #non-settlement-modal #modal-sub-message {
    white-space: pre-line;
  }
  .privileged {
    background-color: red;
    font-weight: bold;
    border-color: red;
  }
</style>
