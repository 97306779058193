// @ts-check

/**
 * 平面図テーブルを操作するときはこれを使う
 */

import API, { graphqlOperation } from '@aws-amplify/api';
import {
  createGroundFloorPlan,
  updateGroundFloorPlan,
  updateProperty,
} from '../../../graphql/mutations.js';

/**
 * レコード作成
 * @param {object} input
 * @returns
 */
export async function createPlaneView(input) {
  if (input.updatePerson == null) throw new Error('updatePersonは必須です');
  if (input.ownerId == null) throw new Error('ownerIdは必須です');
  if (input.ownerName == null) throw new Error('ownerNameは必須です');
  if (input.updateDate == null) input.updateDate = new Date();
  if (input.deleted == null) input.deleted = false;
  if (input.output == null) input.output = false;
  if (input.restored == null) input.restored = false;

  return await API.graphql(
    graphqlOperation(createGroundFloorPlan, {
      input,
    })
  );
}

/**
 * レコード更新
 * @param {object} input
 * @returns
 */
export async function updatePlaneView(input) {
  if (input.updatePerson == null) throw new Error('updatePersonは必須です');
  if (input.updateDate == null) {
    input.updateDate = new Date();
  }

  return await API.graphql(
    graphqlOperation(updateGroundFloorPlan, {
      input,
    })
  );
}

/**
 * 論理削除 (削除履歴以外の削除はこれ)
 * @param {string} propertyId
 * @param {string} planeId
 * @param {string} deletePerson
 */
export async function deletePlaneView(propertyId, planeId, deletePerson) {
  if (deletePerson == null) throw new Error('deletePersonは必須です');
  await API.graphql(
    graphqlOperation(updateProperty, {
      input: {
        id: propertyId,
        planeViewData: {
          newestImageName: null,
          updateDate: null,
        },
      },
    })
  );
  return await API.graphql(
    graphqlOperation(updateGroundFloorPlan, {
      input: { id: planeId, deleted: true, deleteDate: new Date(), deletePerson },
    })
  );
}

/**
 * 論理削除から復旧
 * @param {string} planeId
 * @param {string} restorePerson
 * @returns
 */
export async function restorePlaneView(planeId, restorePerson) {
  if (restorePerson == null) throw new Error('restorePersonは必須です');
  return await API.graphql(
    graphqlOperation(updateGroundFloorPlan, {
      input: {
        id: planeId,
        deleted: false,
        restored: true,
        restoreDate: new Date(),
        restorePerson,
      },
    })
  );
}

/**
 * 画像出力履歴更新
 * @param {string} planeId
 * @param {string} outputPerson
 */
export async function updateOutputHistoryPlaneView(planeId, outputPerson) {
  if (outputPerson == null) throw new Error('restorePersonは必須です');
  return await API.graphql(
    graphqlOperation(updateGroundFloorPlan, {
      input: {
        id: planeId,
        output: true,
        outputDate: new Date(),
        outputPerson,
      },
    })
  );
}
