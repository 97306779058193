var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ProperAnalytics',{attrs:{"title":"ALLY 相続シミュレーション","type":"inheritanceTax","inputClass":_vm.inheritanceTaxInput},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var input = ref.input;
var commonMode = ref.commonMode;
var calcFlg = ref.calcFlg;
return [_c('Input',{attrs:{"value":input,"mode":commonMode,"calcFlg":calcFlg}})]}},{key:"result",fn:function(ref){
var propertyList = ref.propertyList;
var commonInput = ref.commonInput;
return [_c('Report',{attrs:{"propertyList":propertyList,"commonInput":commonInput}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }