// @ts-check

import { Vec } from '../../../util/math/vector';
import { PathData } from '../../../util/path';
import { doorBase1 } from './parts';

/**
 * @param {number} width
 * @param {number} height
 */
export function doubleSlidingSash1(width, height) {
  const base = doorBase1(width, height);
  const body = new PathData();
  return [base, body];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function doubleSlidingSash2(width, height) {
  const base = doorBase1(width, height);
  const center = Math.round(width / 2);
  const h = height / 2;
  const w1 = (width * 6) / 11;
  const w2 = (width * 5) / 11;
  const h1 = height / 3;

  const body = new PathData({
    d:
      PathData.polygon([new Vec(center, -h), new Vec(center, h)]) +
      `M0 ${-h1 / 2} L${w1} ${-h1 / 2} M${w2} ${h1 / 2} L${width} ${h1 / 2}`,
    strokeWidth: 5,
  });

  return [base, body];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function doubleSlidingSash3(width, height) {
  const base = doorBase1(width, height);
  const h = height / 2;
  const w1 = width * 0.36;
  const w2 = width * 0.3;
  const w3 = width * 0.69;
  const w4 = width * 0.63;
  const h1 = height / 2.5 / 2;
  const body = new PathData({
    d:
      PathData.rect(0, -h, width / 3, h / 2) +
      `M0 ${-h1} L${w1} ${-h1} M${w2} ${0} L${w3} ${0} M${w4} ${h1} L${width} ${h1}`,
    strokeWidth: 3,
  });

  return [base, body];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function doubleSlidingSash4(width, height) {
  const base = doorBase1(width, height);
  const w1 = width / 4;
  const w2 = width / 2;
  const w3 = (width * 3) / 4;
  const h = height / 2;

  const w4 = width * 0.28;
  const w5 = width * 0.22;
  const w6 = width * 0.78;
  const w7 = width * 0.72;
  const h1 = height / 2.5 / 2;

  const body = new PathData({
    d:
      `M${w1},${-h} L${w1},${h} M${w2},${-h} L${w2},${h} M${w3},${-h} L${w3},${h}` +
      `M0 ${-h1} L${w4} ${-h1} M${w5} ${h1} L${w6} ${h1} M${w7} ${-h1} L${width} ${-h1}`,
    strokeWidth: 3,
  });

  return [base, body];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function shojiDoubleSlidingSash1(width, height) {
  const base = doorBase1(width, height);
  const body = new PathData({
    d: `M${0},${0} L${width},${0}`,
    strokeWidth: 3,
  });

  return [base, body];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function shojiDoubleSlidingSash2(width, height) {
  const center = Math.round(width / 2);
  const h = height / 2;
  const base = doorBase1(width, height);
  const body = new PathData({
    d: `M${0},${0} L${width},${0}` + PathData.polygon([new Vec(center, -h), new Vec(center, h)]),
    strokeWidth: 3,
  });

  return [base, body];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function shojiDoubleSlidingSash3(width, height) {
  const base = doorBase1(width, height);
  const h = height / 6;
  const w1 = width / 3;
  const w2 = (width * 2) / 3;
  const h1 = height / 2;
  const body = new PathData({
    d:
      `M${0},${-h} L${width},${-h} M${0},${h} L${width},${h}` +
      `M${w1},${-h1} L${w1},${h1} M${w2},${-h1} L${w2},${h1}`,
    strokeWidth: 3,
  });

  return [base, body];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function shojiDoubleSlidingSash4(width, height) {
  const base = doorBase1(width, height);
  const h = height / 6;
  const w1 = width / 4;
  const w2 = width / 2;
  const w3 = (width * 3) / 4;
  const h1 = height / 2;
  const body = new PathData({
    d:
      `M${0},${-h} L${width},${-h} M${0},${h} L${width},${h}` +
      `M${w1},${-h1} L${w1},${h1} M${w2},${-h1} L${w2},${h1} M${w3},${-h1} L${w3},${h1}`,
    strokeWidth: 3,
  });

  return [base, body];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function fixSash(width, height) {
  return doubleSlidingSash1(width, height);
}

/**
 * @param {number} width
 * @param {number} height
 */
export function fixSashAndSingleWindow(width, height) {
  const base = doorBase1(width, height);
  const w = (width * 2) / 3;
  const r = width / 3;
  const h1 = height / 2;
  const rx = width - r / Math.sqrt(2);
  const ry = -h1 - r / Math.sqrt(2);

  const body = new PathData({
    d: `M${width},${-h1} L${rx},${ry} A${r} ${r} ${45} ${0} ${0} ${w} ${-h1} Z M${w},${-h1} L${w},${h1}`,
    strokeWidth: 3,
  });
  return [base, body];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function fixSashAndDoubleWindow(width, height) {
  const base = doorBase1(width, height);
  const w = (width * 2) / 3;
  const r = width / 3;
  const h1 = height / 2;
  const rx = width - r / Math.sqrt(2);
  const ry = -h1 - r / Math.sqrt(2);
  const w2 = (width * 1) / 3;
  const rx2 = r / Math.sqrt(2);

  const body = new PathData({
    d:
      `M${width},${-h1} L${rx},${ry} A${r} ${r} ${45} ${0} ${0} ${w} ${-h1} Z M${w},${-h1} L${w},${h1}` +
      `M${0},${-h1} L${rx2},${-rx2} A${r} ${r} ${45} ${0} ${1} ${w2} ${-h1} Z M${w2},${-h1} L${w2},${h1}`,
    strokeWidth: 3,
  });

  return [base, body];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function openWindow(width, height) {
  return doubleSlidingSash1(width, height);
}

/**
 * @param {number} width
 * @param {number} height
 */
export function sideOpenWindow(width, height) {
  const base = doorBase1(width, height);
  const r = width;
  const h1 = height / 2;
  const rx = width - r / Math.sqrt(2);
  const ry = -h1 - r / Math.sqrt(2);
  const body = new PathData({
    d: `M${width},${-h1} L${rx},${ry} A${r} ${r} ${45} ${0} ${0} ${0} ${-h1} Z`,
    strokeWidth: 3,
  });
  return [base, body];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function sideSlideOpenWindow(width, height) {
  const base = doorBase1(width, height);
  const w1 = width / 4;
  const r = width / Math.sqrt(2) / 2;
  const w2 = (width * 3) / 4;
  const s1 = r / Math.sqrt(2);

  const body = new PathData({
    d:
      `M${w1},${0} L${w1 + s1},${-s1} M${w2},${0} L${w2 - s1 * 2},${-s1 * 2}` +
      `M${0},${0} L${width},${0}`,
    strokeWidth: 3,
  });

  return [base, body];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function upDownWindow(width, height) {
  const base = doorBase1(width, height);
  const body = new PathData({
    d: `M${0},${0} L${width},${0}`,
    strokeWidth: 3,
  });
  return [base, body];
}

/**
 * @param {number} width
 * @param {number} height
 */
export function jarologyWindow(width, height) {
  const d1 = 10;
  const d2 = 3;
  const w1 = width / 2;
  const h1 = height / 2;
  const body = new PathData({
    d: `M${w1 - d1 - d2},${h1} L${w1 - d1 + d2},${-h1} M${w1 - d2},${h1} L${w1 + d2},${-h1} M${w1 +
      d1 -
      d2},${h1} L${w1 + d1 + d2},${-h1}`,
    strokeWidth: 4,
  });

  const base = doorBase1(width, height);
  return [base, body];
}
