// @ts-check

import { ref } from '@vue/composition-api';
import { Vec } from '../util/math/vector';
import { useTree } from './tree';

export const useGuideTree = () => {
  const tree = useTree(true);

  const guideMarkerPos = ref(new Vec());

  return {
    guideObjectTree: tree.objectTree,
    guideRoot: tree.root,
    guideMarkerPos,
  };
};

/** @typedef {ReturnType<typeof useGuideTree>} GuideContainer */
