// @ts-check
import { PvCircleHandle } from '../../../node/node/circleHandle';
import { PvFloor } from '../../../node/node/floorNode';
import { cloneNode, cloneNodeStandalone } from '../../../node/tools/clone';
import { Action } from '../action';
import { ActionBuilder } from './actionBuilder';

export class CurveActionBuilder extends ActionBuilder {
  build() {
    const action = new Action();
    action.actionType = Action.ACTION_TYPE.DRAG_AND_DROP;

    const handle = /** @type {PvCircleHandle} */ (this.dataset.targetObject);

    const target = /** @type {PvFloor} */ (handle.target);
    const index = this.dataset.index;
    if (index == null) {
      throw new Error();
    }

    const { getGuideRoot } = this.context;
    /** @type {PvFloor} */
    let guideTarget = cloneNodeStandalone(target);
    getGuideRoot().appendChild(guideTarget);
    const guideHandle = cloneNode(handle);
    getGuideRoot().appendChild(guideHandle);

    action.onUpdate = ({ dist }) => {
      guideTarget?.removeSelf();
      guideTarget = cloneNodeStandalone(target);
      getGuideRoot().appendChild(guideTarget);
      guideTarget.curve(index, dist);
    };

    action.onComplete = ({ dist }) => {
      target.curve(index, dist);
    };

    return action;
  }
}
