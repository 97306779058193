(function($) {
  'use strict';
  $(function() {
    $('[data-toggle="offcanvas"]').on('click', function() {
      $('.sidebar-offcanvas').toggleClass('active');
    });

    //checkbox and radios
    $('.form-check label,.form-radio label').append('<i class="input-helper"></i>');
  });

  // focus input when clicking on search icon
  $('#navbar-search-icon').click(function() {
    $('#navbar-search-input').focus();
  });
})(window.jQuery);
