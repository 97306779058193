<!--
作成者：Lee HyeonSeok
作成日：2020.08.04
画面名：パスワード初期化
機能：パスワード初期化
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <ValidationObserver tag="div" ref="forgotValidator">
      <div class="row">
        <allyText
          title="E-mail"
          v-model="forgotPwUsername"
          placeholder="メールを入力して下さい"
          class="col-12"
          :mode="sent ? 'View' : 'Add'"
          rules="required|email"
        />
      </div>
      <div class="row" v-if="sent">
        <allyText
          class="col-12"
          title="認証コード"
          name="resetCode"
          v-model="code"
          rules="required"
        />
      </div>
      <div class="row" v-if="sent">
        <allyText
          class="col-12"
          title="新規パスワード"
          type="password"
          name="password"
          v-model="password"
          rules="required|password"
          :placeholder="'8文字以上・大小文字・数字含む'"
        />
      </div>
    </ValidationObserver>
    <div class="row grid-margin">
      <div class="col-12">
        <b-button v-if="!sent" @click="submit" variant="ally-login">
          初期化コード送信
        </b-button>
        <b-button v-if="sent" @click="verify" variant="ally-login">変更 </b-button>
        <b-button @click="backButton" variant="ally-login" class="passwordReset">戻る </b-button>
        <b-button v-if="sent" @click="signIn" variant="ally-login" class="passwordReset"
          >再送信
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="error">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
  import { AmplifyEventBus } from 'aws-amplify-vue';

  export default {
    name: 'ForgotPassword',
    data: function() {
      return {
        code: '',
        password: '',
        error: '',
        sent: false,
        forgotPwUsername: '',
      };
    },
    methods: {
      submit: function() {
        this.$refs.forgotValidator.validate().then(isValid => {
          if (!isValid) return;
          this.$Amplify.Auth.forgotPassword(this.forgotPwUsername)
            .then(() => {
              this.error = '';
              this.sent = true;
            })
            .catch(e => this.setError(e));
        });
      },
      verify: function() {
        this.$refs.forgotValidator.validate().then(isValid => {
          if (!isValid) return;
          this.$Amplify.Auth.forgotPasswordSubmit(this.forgotPwUsername, this.code, this.password)
            .then(() => {
              AmplifyEventBus.$emit('authState', 'signIn');
            })
            .catch(e => this.setError(e));
        });
      },
      signIn: function() {
        AmplifyEventBus.$emit('authState', 'signIn');
      },
      backButton: function(){
        this.$parent.changeDiplayMap('signIn')
      },
      setError: function(e) {
        this.error = this.$parent.getErrorString(e.code);
      },
    },
  };
</script>
