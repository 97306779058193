// @ts-check

import { DrawPolygonActionBuilder } from '../../action/builder/drawPolygonActionBuilder';
import { DrawRectActionBuilder } from '../../action/builder/drawRectActionBuilder';
import { SplitFloorActionBuilder } from '../../action/builder/splitFloorActionBuilder';
import { EventPresetBuilder } from '../eventPresetBuilder';
import { EventPreset } from '../eventPreset';

export class FloorPreset extends EventPresetBuilder {
  build() {
    const { getGlobalSettings } = this.context;

    const preset = new EventPreset();

    preset.click = dataset => {
      if (getGlobalSettings().floorType === 'rect') {
        this.actionManager.setAction(new DrawRectActionBuilder(this.context, dataset));
      } else if (getGlobalSettings().floorType === 'polygon') {
        this.actionManager.setAction(new DrawPolygonActionBuilder(this.context, dataset));
      } else if (getGlobalSettings().floorType === 'split') {
        this.actionManager.setAction(new SplitFloorActionBuilder(this.context, dataset));
      }
    };

    return preset;
  }
}
