<template>
  <div v-if="activeObject">
    <div class="pv-config-label">部屋名</div>
    <div>
      <input
        :class="formCommonClassString"
        :value="activeObject.style.spaceName"
        :disabled = "!isEditor"
        list="nameList"
        @change="e => changeSpaceName(e.srcElement.value)"
      />
      <!-- リスト部分 -->
      <datalist id="nameList">
        <option v-for="name in spaceNameList" :key="name.value" :label="name.value">
          {{ name.value }}
        </option>
      </datalist>
    </div>
  </div>
</template>
<script>
  import { computed, defineComponent, ref, toRefs, watch } from '@vue/composition-api';
  import { SPACE_NAME_LIST } from '../../../../lib/util/const';
  import { PvFloor } from '../../../../lib/node/node/floorNode';

  export default defineComponent({
    props: {
      activeObject: Object, // PROP: 選択中のオブジェクト
      isEditor: {  //  PROP: 編集権限の有無
        type: Boolean,
        default: true,
      }
    },
    setup(props, context) {
      const spaceNameList = ref(SPACE_NAME_LIST);
      const formCommonClassString = ref('col-12 p-1'); // DATA: サイドメニュー入力欄用共通class文字列

      /*
       * METHOD: 部屋名の変更
       */
       const changeSpaceName = (/** @type {string} */ val) => {
        PvFloor.setSpaceName(props.activeObject.parent, val);
      };

      return {
        spaceNameList,
        formCommonClassString, // サイドメニュー入力欄用共通class文字列
        changeSpaceName,
      };
    },
  });
</script>
