<!--
作成者：Lee HyeonSeok
作成日：2020.08.04
画面名：ログイン処理
機能：ログイン処理
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <load v-if="loading"></load>
    <div v-if="!loading">
    <ValidationObserver tag="div" slim ref="confirmValidator" v-if="!loading">
      <div class="row">
        <allyText
          title="認証コード(数字6桁)"
          v-model="confirmCode"
          placeholder="111111"
          class="col-12"
          rules="required|numeric"
        />
      </div>
      <div class="row grid-margin">
        <div class="col-12">
          <b-button variant="ally-login" @click="confirm">
            認証
          </b-button>   
          <b-button variant="ally-login" @click="resend" v-if="buttonCoolTime" disabled>
            00 : {{ cooltime >= 10 ? cooltime : "0"+cooltime }}
          </b-button>
          <b-button variant="ally-login" @click="resend" v-if="!buttonCoolTime">
            認証コード再送信
          </b-button> 
        </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="error">
          {{ error }}
        </div>
      </div>
    </ValidationObserver>
    </div>
    <allyAlert :code="'E105'" :title="'認証回数の制限超え'"></allyAlert>
    <allyAlert :code="'S101'" :title="'認証コード再送信'"></allyAlert>
  </div>
</template>

<script>
import load from '../../../components/Common/Load';
import { Auth } from 'aws-amplify';
import { AmplifyEventBus } from 'aws-amplify-vue';

  export default {
    name: 'SignIn',
    props: ['user'],
    data: function() {
      return {
        confirmCode: null,
        loading: false,
        error: null,
        buttonCoolTime: false,
        cooltime: 30,
      };
    },
    methods: {
      confirm: async function() {
        this.$refs.confirmValidator.validate().then(async isValid => {
            if(isValid){
                try {
                    this.loading = true;
                    //ユーザ認証
                    await Auth.confirmSignUp(this.user.user, this.confirmCode).
                    then(async() => {
                      //ユーザ認証が必要な場合、ユーザ情報がないため、再度ログイン処理を行いGQLなどの認証を可能にする
                      this.$Amplify.Auth.signIn(this.user.user, this.user.password)
                      .then(async data => {
                       //更新前に入力した会社情報を呼び出し、登録を行う
                       AmplifyEventBus.$emit('localUser', data);
                       this.$parent.reloadAfterCompnay(data.attributes.email, data.username);
                      })
                    })
                } catch (err) {
                  console.log(err)
                  this.loading = false;
                  this.setError(err);
                }
            }
        });
      },
      resend: async function() {
        try {
            this.loading = true
            await Auth.resendSignUp(this.user.user);
            this.$bvModal.show('S101');
            this.buttonCoolTime = true;
            this.coolTimeButton();
            this.loading = false
        } catch (err) {
            this.buttonCoolTime = false;
            console.log(err)
            this.setError(err);
        }
      },
      setError: function(e) {
        this.error = this.$parent.getErrorString(e.code, true);
      },
      coolTimeButton: function(){
        if(this.cooltime > 0){
          let buttonTimer = setInterval(() => this.cooltime -= 1 , 1000);
          setTimeout(() => { clearInterval(buttonTimer); this.buttonCoolTime = false; this.cooltime = 30}, 30000);
        }
      }
    },
    components: {
      load,
    },
  };
</script>
<style scoped>
.margin-top {
  margin-top: 15px;
}
</style>