// @ts-check

import { PvObject } from './pvObject';

export class PvGroup extends PvObject {
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'group';
  constructor() {
    super();
  }
}
