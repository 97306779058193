import { render, staticRenderFns } from "./GainOnSaleTotal.vue?vue&type=template&id=2df79121&"
import script from "./GainOnSaleTotal.vue?vue&type=script&lang=js&"
export * from "./GainOnSaleTotal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports