<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：初期投資/付帯費用/不動産取得税
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="RealEstateAcquisitionTax">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button
          id="BtnRealEstateAcquisitionTax"
          variant="link"
          v-b-toggle.tabRealEstateAcquisitionTax
        >
          不動産取得税
        </b-button>
      </b-card-header>
      <b-collapse id="tabRealEstateAcquisitionTax" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="2" class="title bold">
                種別
              </b-col>
              <b-col class="title">
                ※種類
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <b-row style="padding:0">
                      <b-col class="title-center bold">
                        不動産取得税
                      </b-col>
                      <b-col cols="2" />
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="title-center" style="padding:0;">
                変更
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2" class="title">土地</b-col>
              <b-col />
              <b-col>
                <b-row style="padding:0">
                  <b-col class="control">
                    <!--計算値-->
                    <b-form-input
                      disabled
                      class="currency"
                      :value="calculated(0) | currency"
                      v-if="
                        !investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[0]
                      "
                    />
                    <!--入力値-->
                    <allyText
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="investmentAnalytics.initialInvestment.realEstateAcquisitionTax[0]"
                      roundType="down"
                      v-else
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="checkbox" style="padding-top:0.5em">
                <b-form-checkbox
                  id="realEstateAcquisitionTax_0"
                  v-model="
                    investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[0]
                  "
                  name="realEstateAcquisitionTax_0"
                  @change="changeCheckBox(0)"
                  size="lg"
                  style="padding-left:2em"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row
              v-for="id of investmentAnalytics.existingBuildingCount"
              v-bind:key="`for01-${id}`"
            >
              <b-col cols="2" class="title">既存建物{{ id }}</b-col>
              <b-col>
                <b-form-input
                  v-if="
                    investmentAnalytics.initialInvestment.propertyDescription[id].kind == '共同住宅'
                  "
                  disabled
                  value="住宅（共同住宅）"
                />
                <b-form-input
                  v-else
                  disabled
                  :value="investmentAnalytics.initialInvestment.propertyDescription[id].kind"
                />
              </b-col>
              <b-col>
                <b-row style="padding:0">
                  <b-col>
                    <!--計算値-->
                    <b-form-input
                      disabled
                      class="currency"
                      :value="calculated(id) | currency"
                      v-if="
                        !investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[id]
                      "
                    />
                    <!--入力値-->
                    <allyText
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="investmentAnalytics.initialInvestment.realEstateAcquisitionTax[id]"
                      roundType="down"
                      v-else
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="title-center">
                <b-form-checkbox
                  :id="`realEstateAcquisitionTax_1_${id}`"
                  v-model="
                    investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[id]
                  "
                  :name="`realEstateAcquisitionTax_1_${id}`"
                  @change="changeCheckBox(id)"
                  size="lg"
                  style="padding-left:2em"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row v-for="id of investmentAnalytics.newBuildingCount" v-bind:key="`for02-${id}`">
              <b-col cols="2" class="title">新築建物{{ id }}</b-col>
              <b-col>
                <b-form-input
                  v-if="
                    investmentAnalytics.initialInvestment.propertyDescription[10 + id].kind ==
                      '共同住宅'
                  "
                  disabled
                  value="住宅（共同住宅）"
                />
                <b-form-input
                  v-else
                  disabled
                  :value="investmentAnalytics.initialInvestment.propertyDescription[10 + id].kind"
                />
              </b-col>
              <b-col>
                <b-row style="padding:0">
                  <b-col>
                    <!--計算値-->
                    <b-form-input
                      disabled
                      class="currency"
                      :value="calculated(10 + id) | currency"
                      v-if="
                        !investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[
                          10 + id
                        ]
                      "
                    />
                    <!--入力値-->
                    <allyText
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="
                        investmentAnalytics.initialInvestment.realEstateAcquisitionTax[10 + id]
                      "
                      roundType="down"
                      v-else
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="title-center">
                <b-form-checkbox
                  :id="`realEstateAcquisitionTax_2_${id}`"
                  v-model="
                    investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[10 + id]
                  "
                  :name="`realEstateAcquisitionTax2_${id}`"
                  @change="changeCheckBox(10 + id)"
                  size="lg"
                  style="padding-left:2em"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2" class="title">付属建物</b-col>
              <b-col />
              <b-col>
                <b-row style="padding:0">
                  <b-col class="control">
                    <!--計算値-->
                    <b-form-input
                      disabled
                      class="currency"
                      :value="calculated(21) | currency"
                      v-if="
                        !investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[21]
                      "
                    />
                    <!--入力値-->
                    <allyText
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="investmentAnalytics.initialInvestment.realEstateAcquisitionTax[21]"
                      roundType="down"
                      v-else
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="checkbox" style="padding-top:0.5em">
                <b-form-checkbox
                  id="realEstateAcquisitionTax_21"
                  v-model="
                    investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[21]
                  "
                  name="realEstateAcquisitionTax_21"
                  @change="changeCheckBox(21)"
                  size="lg"
                  style="padding-left:2em"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Calculation from './../Calculation';
  export default {
    mixins: [Calculation],
    async mounted() {
      await this.newBuildingInit();
      for (
        let i = 0;
        i < this.investmentAnalytics.initialInvestment.realEstateAcquisitionTax.length;
        i++
      ) {
        this.changeCheckBox(i);
      }
    },
    computed: {
      // 計算値
      calculated: function() {
        return function(id) {
          return id == 0
            ? this.realEstateAcquisitionTaxAmountGuideline(0) // 土地
            : id < 11
            ? this.realEstateAcquisitionTaxAmountGuideline(1, id - 1) // 既存建物
            : id < 21
            ? this.realEstateAcquisitionTaxAmountGuideline(2, id - 11) // 新築建物
            : this.realEstateAcquisitionTaxAmountGuideline(3); // 付属建物
        };
      },
    },
    methods: {
      // チェックボックス変更時
      changeCheckBox: function(id) {
        // 初期値設定
        if (!this.investmentAnalytics.initialInvestment.isChangedRealEstateAcquisitionTax[id]) {
          this.$set(
            this.investmentAnalytics.initialInvestment.realEstateAcquisitionTax,
            id,
            this.calculated(id)
          );
        }
      },
    },
  };
</script>
<style scoped>
  #RealEstateAcquisitionTax .checkbox {
    display: grid;
    text-align: center;
    align-items: center;
  }
</style>
