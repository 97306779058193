<template>
  <CommonLayout title="資金計画" :companyLogo="companyLogo" :companyName="companyName">
    <template v-slot>
      <div
        style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5mm; padding: 8mm; font-size: 50px"
      >
        <div
          style="position: absolute;
          width: 85%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);"
        >
          <div :style="titleStyle">資金計画</div>
          <div :style="unitPrice">単位：円</div>
          <table :style="tableStyle">
            <thead>
              <tr>
                <td :style="[headerStyle, tableFont]" style="width: 33%;">事業費</td>
                <td :style="[headerStyle, tableFont]" style="width: 33%;">値</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in totalProjectCostList" :key="i">
                <td :style="[bodyStyle, tableFont]">
                  {{ item.name }}
                </td>
                <td :style="[bodyStyle, tableFont, textRightStyle]">
                  {{
                    formatNum(item.amount, {
                      floatLength: 0,
                      separation: true,
                      rountType: 'down',
                    })
                  }}
                </td>
              </tr>
              <tr>
                <td :style="[subHeaderStyle, tableFont]" style="width: 33%;">総事業費</td>
                <td
                  :style="[subHeaderStyle, tableFont, textRightStyle]"
                  style="width: 33%; text-align: right;"
                >
                  {{
                    formatNum(totalProjectCostTotal, {
                      floatLength: 0,
                      separation: true,
                      rountType: 'down',
                    })
                  }}
                </td>
              </tr>
            </tbody>
          </table>

          <div :style="titleStyle">借入条件</div>
          <div :style="unitPrice">単位：円</div>
          <table class="mt-4" :style="tableStyle">
            <thead>
              <tr>
                <td :style="[headerStyle, tableFont]" style="width: 50%;">借入条件</td>
                <td :style="[headerStyle, tableFont]" style="width: 50%;">値</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td :style="[bodyStyle, tableFont]">
                  {{ borrowingConditionsList.length != 0 ? borrowingConditionsList[0].name : '' }}
                </td>
                <td :style="[bodyStyle, tableFont, textRightStyle]">
                  {{
                    formatNum(
                      borrowingConditionsList.length != 0 ? borrowingConditionsList[0].amount : 0,
                      {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      }
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td :style="[bodyStyle, tableFont]">
                  {{ borrowingConditionsList.length != 0 ? borrowingConditionsList[1].name : '' }}
                </td>
                <td :style="[bodyStyle, tableFont, textRightStyle]">
                  {{ borrowingConditionsList.length != 0 ? repaymentMethod : '' }}
                </td>
              </tr>
              <tr>
                <td :style="[bodyStyle, tableFont]">
                  {{ borrowingConditionsList.length != 0 ? borrowingConditionsList[2].name : '' }}
                </td>
                <td :style="[bodyStyle, tableFont, textRightStyle]">
                  {{
                    formatNum(
                      borrowingConditionsList.length != 0 ? borrowingConditionsList[2].amount : 0,
                      {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      }
                    )
                  }}
                  {{ borrowingConditionsList.length != 0 ? borrowingConditionsList[2].unit : '' }}
                </td>
              </tr>
              <tr>
                <td :style="[bodyStyle, tableFont]">
                  {{
                    borrowingConditionsList.length != 0
                      ? borrowingConditionsList[3].name +
                        `(${borrowingConditionsList[3].items[0].name})`
                      : 0
                  }}
                </td>
                <td :style="[bodyStyle, tableFont, textRightStyle]">
                  {{
                    borrowingConditionsList.length != 0
                      ? parseFloat(borrowingConditionsList[3].items[0].unit).toFixed(2) + ' %'
                      : 0
                  }}
                </td>
              </tr>
              <tr>
                <td :style="[bodyStyle, tableFont]">
                  {{
                    borrowingConditionsList.length != 0
                      ? borrowingConditionsList[3].name +
                        `(${borrowingConditionsList[3].items[1].name})`
                      : ''
                  }}
                </td>
                <td :style="[bodyStyle, tableFont, textRightStyle]">
                  {{
                    borrowingConditionsList.length != 0
                      ? parseFloat(borrowingConditionsList[3].items[1].unit).toFixed(2) + ' %'
                      : ''
                  }}
                </td>
              </tr>
            </tbody>
          </table>

          <div :style="titleStyle">預託金償還条件</div>
          <div :style="unitPrice">単位：円</div>
          <table class="mt-4" :style="tableStyle">
            <thead>
              <tr>
                <td :style="[headerStyle, tableFont]" style="width: 33%;">預託金償還条件</td>
                <td :style="[headerStyle, tableFont]" style="width: 33%;">値</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in depositRedemptionTermsList" :key="i">
                <td :style="[bodyStyle, tableFont]">
                  {{ item.name }}
                </td>
                <td :style="[bodyStyle, tableFont, textRightStyle]">
                  {{
                    formatNum(item.total || item.amount, {
                      floatLength: 0,
                      separation: true,
                      rountType: 'down',
                    })
                  }}{{ item.name == '償還期間' ? ' ' + borrowingConditionsList[2].unit : '' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import {
    headerStyle,
    subHeaderStyle,
    bodyStyle,
    titleStyle,
    unitPrice,
    tableFont,
    tableStyle,
    textRightStyle,
  } from '../../../../components/ExportReport/style';
  import Logo from '@/assets/report/reportImages/logo.png';

  export default {
    props: {
      companyLogo: String,
      companyName: String,
      borrowingConditionsList: Array,
      depositRedemptionTermsList: Array,
      totalProjectCostList: Array,
      totalProjectCostTotal: Number,
      repaymentMethod: String,
    },
    components: {
      CommonLayout,
    },
    data() {
      return {
        Logo,
        headerStyle,
        subHeaderStyle,
        bodyStyle,
        titleStyle,
        unitPrice,
        tableFont,
        tableStyle,
        textRightStyle,
      };
    },
  };
</script>
