import { ref, watchEffect } from '@vue/composition-api';

/**
 * SVGImageElementから情報を取得
 * @param {import("@vue/composition-api").ComputedRef<string|null>} imageUrl
 */
export const useLoadImage = imageUrl => {
  const image = new Image();

  /** @type {import('@vue/composition-api').Ref<number>} */
  const naturalWidth = ref(1);
  /** @type {import('@vue/composition-api').Ref<number>} */
  const naturalHeight = ref(1);

  watchEffect(() => {
    image.src = imageUrl.value;
    image.onload = () => {
      naturalWidth.value = image.naturalWidth;
      naturalHeight.value = image.naturalHeight;
    };
  });

  return {
    naturalWidth,
    naturalHeight,
  };
};
