// @ts-check

import { EventPresetBuilder } from '../eventPresetBuilder';
import { EventPreset } from '../eventPreset';

export class IconPreset extends EventPresetBuilder {
  build() {
    const preset = new EventPreset();

    return preset;
  }
}
