<template>
  <button :disabled="disabled" class="editor-button" @click="$emit('click')" :style="style">
    <div>
      <slot />
    </div>
  </button>
</template>
<script>
  export default {
    props: {
      size: {
        type: String,
        default: 'md',
      },
      selected: {
        type: Boolean,
        default: false,
      },
      backgroundColor: {
        type: String,
        default: 'white',
      },
      color: {
        type: String,
        default: 'gray',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      style() {
        let s = {};

        if (this.size === 'md') {
          s.width = '45px';
          s.height = '45px';
        } else if (this.size === 'sm') {
          s.width = '45px';
          s.height = '45px';
        }

        if (this.selected === true) {
          s['background-color'] = 'gray';
        } else {
          s['background-color'] = this.backgroundColor;
          s['color'] = this.color;
          s['font-weight'] = 'bold';
        }
        s['text-align'] = 'center';
        s['padding'] = '0';

        if (this.disabled) {
          s['background-color'] = 'gray';
        }

        return s;
      },
    },
  };
</script>
<style scoped>
  .editor-button {
    color: white;
    height: 30px;
    border: 0;
    user-select: none;
  }
  .editor-button:active {
    transform: translate(1px, 1px);
  }
  .editor-button:hover {
    opacity: 0.8;
    transition: opacity 0.3s;
  }
</style>
