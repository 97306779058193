<template>
  <div>
    <div class="row mb-2">
      <div class="col-1 title">
        集計期間
      </div>
      <div class="col-10">
        <b-form-select class="oldYear col-1" :options="yearItems" v-model="filter.oldYear" />年
        <b-form-select
          class="oldMonth col-1"
          :options="oldMonthItemComp"
          v-model="filter.oldMonth"
        />月 ~
        <b-form-select class="newYear col-1" :options="yearItems" v-model="filter.newYear" />年
        <b-form-select
          class="newMonth col-1"
          :options="newMonthItemComp"
          v-model="filter.newMonth"
        />月
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-1 title">都道府県</div>
      <div class="col-10">
        <b-form-select
          class=" oldYear col-2"
          :options="prefecturesItem"
          v-model="filter.prefectures"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-1 title">支払方法</div>
      <div class="col-10">
        <b-form-select class="col-3" :options="paymentTypeItem" v-model="filter.pymentPype" />
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col-1 title">ステータス</div>
      <div class="col-1">
        <b-form-checkbox v-model="filter.lackFlag">不足</b-form-checkbox>
      </div>
      <div class="col-1 ">
        <b-form-checkbox v-model="filter.excessFlag">超過</b-form-checkbox>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-1 title">フリーワード</div>
      <b-form-input
        class="ml-3 col-5"
        style=""
        type="text"
        maxlength="100"
        v-model="filter.freeSearch"
      />
      <b-button class="col-1 btn ml-3" @click="search" :disabled="loading">検索</b-button>
    </div>
    <div class="row mb-3"></div>
  </div>
</template>

<script>
  var moment = require('moment-timezone');
  moment.tz.setDefault('Asia/Tokyo');
  import wordData from '../js/wordData';

  export default {
    props: ['loading'],
    mixins: [wordData],
    data() {
      return {
        yearItems: [],
        monthItem: [],
        prefecturesItem: [],
        paymentTypeItem: [],
        filter: {
          oldYear: 2000,
          oldMonth: 1,
          newYear: 2000,
          newMonth: 1,
          prefectures: 0,
          pymentPype: -1,
          lackFlag: false,
          excessFlag: false,
          freeSearch: '',
        },
      };
    },
    created: function() {},
    mounted: async function() {
      this.filter.oldYear = parseInt(moment(this.today).format('YYYY'));
      this.filter.oldMonth = this.setMonth();
      this.filter.newYear = parseInt(moment(this.today).format('YYYY'));
      this.filter.newMonth = this.setMonth();

      let nowMonth =  moment(this.today).format('MM');
      let nowDay =  moment(this.today).format('DD');

      //２月の請求が掛かるまでは、去年のデータを表示する
      if(parseInt(nowMonth + nowDay) <= 206){
        this.filter.oldYear = parseInt(moment(this.today).subtract(1, 'years').format('YYYY'));
        this.filter.newYear = parseInt(moment(this.today).subtract(1, 'years').format('YYYY'));
      }

      this.setYearItem();
      this.setMonthItem();
      this.setPrefecturesItem();
      this.setPaymentTypesItem();
    },
    methods: {
      search() {
        let filters = [];

        //元にするkey名
        filters.push(
          { variable: 'settlementDate', func: this.dateComparison },
          { variable: 'customerNumber', func: this.prefecturesFilter },
          { variable: 'paymentMethod', func: this.paymentTypeFilter },
          { variable: 'depositDifference', func: this.excessOrDeficiencyFilter },
          { variable: 'companyName', func: this.freeWordFilter }
        );

        //View.vueでfilter処理を行う
        this.$emit('setItemsList', filters);
      },
      //集計期間(settlementDate)のfilter設定
      dateComparison: function(val, variable) {
        let billing = moment(val[variable]).format('YYYYMMDD');
        let oneDate = this.filter.oldYear + '-' + this.filter.oldMonth + '-01';
        let setOldDate = moment(oneDate).format('YYYYMM01');
        let one2Date = this.filter.newYear + '-' + this.filter.newMonth + '-01';
        let setNewDate = moment(one2Date).format('YYYYMM31');

        let oldFlag = parseInt(billing) >= parseInt(setOldDate);
        let newFlag = parseInt(billing) <= parseInt(setNewDate);
        //true falseで返却する
        if (oldFlag == newFlag) {
          return true;
        }
        return false;
      },
      //都道府県のfilter設定
      prefecturesFilter: function(val, variable) {
        //未選択の時はnullで返却
        if (this.filter.prefectures == 0) {
          return true;
        }

        //何らかの理由で顧客番号がない時は終了
        if (val[variable] == null) return false;

        //都道府県コードの確認
        let code = val[variable].slice(0, 2);
        let flag = parseInt(code) == parseInt(this.filter.prefectures);

        return flag;
      },
      //支払方法別選択
      paymentTypeFilter: function(val, variable) {
        if (this.filter.pymentPype == -1) return true;
        if (this.filter.pymentPype == null) return false;
        let flag = val[variable] == this.filter.pymentPype;
        return flag;
      },
      //過不足金確認
      excessOrDeficiencyFilter: function(val, variable) {
        if (this.filter.lackFlag == false && this.filter.excessFlag == false) return true;
        let depositDifference = val[variable];

        //不足分を表示
        if (this.filter.lackFlag == true) {
          if (depositDifference < 0) {
            return true;
          }
        }
        //超過分表示
        if (this.filter.excessFlag == true) {
          if (depositDifference > 0) {
            return true;
          }
        }
        return false;
      },
      //フリーワード検索
      freeWordFilter: function(val, variable) {
        if (this.filter.freeSearch == '') {
          return true;
        }
        let companyName = val[variable];
        let companyId = val.companyId;
        let customerNumber = val.customerNumber;

        //会社ID検索
        if (companyId?.indexOf(this.filter.freeSearch) > -1) {
          return true;
        }
        //会社名検索
        if (companyName?.indexOf(this.filter.freeSearch) > -1) {
          return true;
        }
        //顧客番号検索
        if (customerNumber?.indexOf(this.filter.freeSearch) > -1) {
          return true;
        }
        return false;
      },
      //選択できる年を設定
      setYearItem: function() {
        let nowYear = moment(this.today).format('YYYY');
        let nowMonth =  moment(this.today).format('MM');
        let nowDay =  moment(this.today).format('DD');
        for (let i = 2000; i <= parseInt(nowYear); i++) {
          
          //昨年のデータの内は表示しない
          if(parseInt(nowYear) == i){
            if(parseInt(nowMonth + nowDay) <= 206){
              return;
            }
          }
          this.yearItems.push({ text: i, value: i.toString() });
        }
      },
      //選択できる月を設定
      setMonthItem: function() {
        for (let i = 1; i <= 12; i++) {
          this.monthItem.push({ text: i, value: i.toString() });
        }
      },
      //選択できる都道府県を設定
      setPrefecturesItem: function() {
        for (let pref of this.getPrefectures()) {
          this.prefecturesItem.push({ text: pref.name, value: pref.no });
        }
      },
      //選択できる支払方法を設定
      setPaymentTypesItem: function() {
        this.paymentTypeItem = [];
        for (let payType of this.getPaymentType())
          this.paymentTypeItem.push({ text: payType.name, value: payType.no });
      },
      //先の日程は選択できないようにする
      searchMonthItem(year) {
        if (year == moment(this.today).format('YYYY')) {
          let item = [];
          let num = parseInt(moment(this.today).format('MM'));
          if (parseInt(moment(this.today).format('DD')) < 7) {
            num--;
          }
          for (let i = 1; i < num; i++) {
            item.push({ text: i, value: i.toString() });
          }
          return item;
        }
        return this.monthItem;
      },
      setMonth() {
        let month = parseInt(
          moment(this.today)
            .subtract(1, 'months')
            .format('MM')
        );
        let day = parseInt(
          moment(this.today)
            .subtract(1, 'months')
            .format('DD')
        );
        if (day < 7) {
          month = parseInt(
            moment(this.today)
              .subtract(2, 'months')
              .format('MM')
          );
        }
        return month;
      },
    },
    computed: {
      oldMonthItemComp() {
        return this.searchMonthItem(this.filter.oldYear);
      },
      newMonthItemComp() {
        return this.searchMonthItem(this.filter.newYear);
      },
    },
  };
</script>

<style scoped>
  .box {
    float: left;
  }

  .btn {
    color: white;
    background-color: var(--colorTheme);
  }

  .title {
    margin-top: auto;
    margin-bottom: auto;
  }
</style>
