<template>
  <div
    :class="{ loader: true, fadeout: !isLoading }"
    :style="{ 'pointer-events': isLoading ? 'auto' : 'none' }"
  >
    <div class="page-inner">
      <div>
        <img class="logo" src="/logo.png" />
        <b-skeleton width="100%"></b-skeleton>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from '@vue/composition-api';

  export default defineComponent({
    props: {
      isLoading: {
        type: Boolean,
      },
    },
  });
</script>
<style scoped>
  .page-inner {
    max-width: 500px;
    width: 100%;
    margin: auto;
  }

  .loader {
    background-color: var(--colorTheme);
    bottom: 0;
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
  }

  .logo {
    width: 150px;
  }

  .fadeout {
    animation: fadeout 1s forwards;
  }

  @keyframes fadeout {
    to {
      opacity: 0;
      visibility: hidden;
      translate: 0 -100vh;
    }
  }
</style>
