var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"DesignCosts"}},[_c('b-card',{staticClass:"category",attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabDesignCosts",modifiers:{"tabDesignCosts":true}}],attrs:{"id":"BtnDesignCosts","variant":"link"}},[_vm._v(" 設計監理費 ")])],1),_c('b-collapse',{attrs:{"id":"tabDesignCosts","role":"tabpanel"}},[_c('b-card-body',[_c('b-tabs',{attrs:{"fill":"","pills":""},model:{value:(_vm.investmentAnalytics.initialInvestment.isDetailDesignCost),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment, "isDetailDesignCost", $$v)},expression:"investmentAnalytics.initialInvestment.isDetailDesignCost"}},[_c('b-tab',{attrs:{"title":"概算入力"}},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v("総額")]),_c('b-col',{staticClass:"title ",attrs:{"cols":"2"}},[_vm._v(" (税抜) ")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"name":"DesignCostsTotal","type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(_vm.investmentAnalytics.initialInvestment.estimatedDesignCost),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment, "estimatedDesignCost", $$v)},expression:"investmentAnalytics.initialInvestment.estimatedDesignCost"}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}}),_c('b-col',{staticClass:"title",attrs:{"cols":"2"}},[_vm._v(" (税込) ")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.sumTax(
                            Math.floor(_vm.investmentAnalytics.initialInvestment.estimatedDesignCost)
                          ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col')],1)],1)],1),_c('b-tab',{attrs:{"title":"詳細入力"}},[_c('b-container',{staticClass:"detail"},[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("項目")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v("設計監理費")]),_c('b-col',{attrs:{"cols":"5"}})],1)],1),_c('b-col',{staticClass:"title-center "},[_vm._v("詳細")])],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v("建築設計")]),_c('b-col',{staticClass:"component",attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(Math.floor(_vm.investmentAnalytics.initialInvestment.designCost[0].amount))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"},[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabDesignCosts1",modifiers:{"tabDesignCosts1":true}}],staticClass:"btn-detail",attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabDesignCosts1","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("建築設計費率")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.designCost[0].quantity
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.designCost[0], "quantity", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.designCost[0].quantity\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("％")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"guideline"},[_vm._v(" ※目安 "),_c('button',{staticClass:"btn-ally",on:{"click":function($event){_vm.changeGuideline(
                                      0,
                                      Math.floor(
                                        (Math.floor(
                                          _vm.investmentAnalytics.initialInvestment.designCost[0]
                                            .quantity
                                        ) *
                                          (_vm.architecturalDesignCostConstructionPlanTotal +
                                            _vm.tax(_vm.architecturalDesignCostConstructionPlanTotal))) /
                                          100
                                      )
                                    )}}},[_vm._v(" 反映 ")])]),_c('b-col',{attrs:{"cols":"3"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("建築設計費")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.designCost[0].amount
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.designCost[0], "amount", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.designCost[0].amount\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("円")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(Math.floor(
                                      (Math.floor(
                                        _vm.investmentAnalytics.initialInvestment.designCost[0]
                                          .quantity
                                      ) *
                                        (_vm.architecturalDesignCostConstructionPlanTotal +
                                          _vm.tax(_vm.architecturalDesignCostConstructionPlanTotal))) /
                                        100
                                    ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("円")])],1)],1)],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v("造成設計")]),_c('b-col',{staticClass:"component",attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(Math.floor(_vm.investmentAnalytics.initialInvestment.designCost[1].amount))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"},[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabDesignCosts2",modifiers:{"tabDesignCosts2":true}}],staticClass:"btn-detail",attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabDesignCosts2","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("造成設計")]),_c('b-col',{staticClass:"ally-select"},[_c('allySelect',{attrs:{"name":"landscapeDesign","items":_vm.landscapeDesignItems},model:{value:(
                                _vm.investmentAnalytics.initialInvestment.designCost[1].content
                              ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.designCost[1], "content", $$v)},expression:"\n                                investmentAnalytics.initialInvestment.designCost[1].content\n                              "}})],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"guideline"},[_vm._v("※地積")]),_c('b-col',{attrs:{"cols":"3"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("造成面積")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"floatLength":2,"roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.designCost[1].quantity
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.designCost[1], "quantity", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.designCost[1].quantity\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("㎡")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","mode":"View","separation":true,"floatLength":2,"roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.propertyDescription[0]
                                      .area
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.propertyDescription[0]
                                      , "area", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.propertyDescription[0]\n                                      .area\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("㎡")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("単価")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice(
                                      '造成設計',
                                      _vm.investmentAnalytics.initialInvestment.designCost[1].content
                                    ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("円/㎡")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"guideline"},[_vm._v(" ※目安 "),_c('button',{staticClass:"btn-ally",on:{"click":function($event){_vm.changeGuideline(
                                      1,
                                      Math.floor(
                                        Math.floor(
                                          _vm.investmentAnalytics.initialInvestment.designCost[1]
                                            .quantity * 100
                                        ) / 100
                                      ) *
                                        _vm.getUnitPrice(
                                          '造成設計',
                                          _vm.investmentAnalytics.initialInvestment.designCost[1]
                                            .content
                                        )
                                    )}}},[_vm._v(" 反映 ")])]),_c('b-col',{attrs:{"cols":"3"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("設計費")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.designCost[1].amount
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.designCost[1], "amount", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.designCost[1].amount\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("円")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(Math.floor(
                                      (Math.floor(
                                        _vm.investmentAnalytics.initialInvestment.designCost[1]
                                          .quantity * 100
                                      ) /
                                        100) *
                                        _vm.getUnitPrice(
                                          '造成設計',
                                          _vm.investmentAnalytics.initialInvestment.designCost[1]
                                            .content
                                        )
                                    ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("円")])],1)],1)],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v("工事監理")]),_c('b-col',{staticClass:"component",attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(Math.floor(_vm.investmentAnalytics.initialInvestment.designCost[2].amount))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"},[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabDesignCosts3",modifiers:{"tabDesignCosts3":true}}],staticClass:"btn-detail",attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabDesignCosts3","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("工事監理費率")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.designCost[2].quantity
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.designCost[2], "quantity", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.designCost[2].quantity\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("％")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"guideline"},[_vm._v(" ※目安 "),_c('button',{staticClass:"btn-ally",on:{"click":function($event){_vm.changeGuideline(
                                      2,
                                      Math.floor(
                                        (Math.floor(
                                          _vm.investmentAnalytics.initialInvestment.designCost[2]
                                            .quantity
                                        ) *
                                          (_vm.architecturalDesignCostConstructionPlanTotal +
                                            _vm.tax(_vm.architecturalDesignCostConstructionPlanTotal))) /
                                          100
                                      )
                                    )}}},[_vm._v(" 反映 ")])]),_c('b-col',{attrs:{"cols":"3"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("工事監理費")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.designCost[2].amount
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.designCost[2], "amount", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.designCost[2].amount\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("円")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(Math.floor(
                                      (Math.floor(
                                        _vm.investmentAnalytics.initialInvestment.designCost[2]
                                          .quantity
                                      ) *
                                        (_vm.architecturalDesignCostConstructionPlanTotal +
                                          _vm.tax(_vm.architecturalDesignCostConstructionPlanTotal))) /
                                        100
                                    ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"3"}},[_vm._v("円")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }