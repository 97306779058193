<template>
  <b-card-group>
    <b-card header="所有者情報" id="ownerInformation" v-if="tabName === 'ownerInformation'">
      <b-card-group>
        <b-card class="shadow-none">
          <b-container>
            <b-row>
              <b-col>
                <allyText
                  :rules="
                    inputValue.siteConditions.ownerInformation.ownerType == 2
                      ? 'required|min_value:0|max_value:999999'
                      : ''
                  "
                  name="siteConditions-ownerInformation-estimatedEstateValue"
                  title="推定相続財産額"
                  type="number"
                  v-model="inputValue.siteConditions.ownerInformation.estimatedEstateValue"
                  :loading="loading"
                  tips="不動産だけでなく金融商品等を含む相続税計算上の課税価格。"
                  :separation="true"
                  roundType="down"
                />
              </b-col>
              <b-col cols="4" class="unit">
                万円
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
                <allyRadio
                  title="配偶者"
                  name="siteConditions-ownerInformation-spouse"
                  :items="[
                    {
                      text: '無',
                      value: 0,
                    },
                    {
                      text: '有',
                      value: 1,
                    },
                  ]"
                  v-model="inputValue.siteConditions.ownerInformation.spouse"
                  :rules="
                    inputValue.siteConditions.ownerInformation.ownerType == 2 ? 'required' : ''
                  "
                  :loading="loading"
                >
                </allyRadio>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyText
                  :rules="
                    inputValue.siteConditions.ownerInformation.ownerType == 2 &&
                    (inputValue.siteConditions.ownerInformation.numberOfParentsAlive > 0 ||
                      inputValue.siteConditions.ownerInformation.siblingsOfTheDeceased > 0)
                      ? undefined
                      : 'required|min_value:0|max_value:10'
                  "
                  name="siteConditions-ownerInformation-numberOfChildren"
                  title="子ども人数"
                  v-model="inputValue.siteConditions.ownerInformation.numberOfChildren"
                  type="number"
                  :mode="
                    inputValue.siteConditions.ownerInformation.ownerType == 2 &&
                    (inputValue.siteConditions.ownerInformation.numberOfParentsAlive > 0 ||
                      inputValue.siteConditions.ownerInformation.siblingsOfTheDeceased > 0)
                      ? 'View'
                      : 'Edit'
                  "
                  :loading="loading"
                  :separation="true"
                  roundType="down"
                />
              </b-col>
              <b-col cols="4" class="unit">人 </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyText
                  :rules="
                    inputValue.siteConditions.ownerInformation.ownerType == 2 &&
                    (inputValue.siteConditions.ownerInformation.numberOfChildren > 0 ||
                      inputValue.siteConditions.ownerInformation.siblingsOfTheDeceased > 0)
                      ? undefined
                      : 'required|min_value:0|max_value:2'
                  "
                  title="親生存人数"
                  v-model="inputValue.siteConditions.ownerInformation.numberOfParentsAlive"
                  type="number"
                  :mode="
                    inputValue.siteConditions.ownerInformation.numberOfChildren > 0 ||
                    inputValue.siteConditions.ownerInformation.siblingsOfTheDeceased > 0
                      ? 'View'
                      : 'Edit'
                  "
                  :loading="loading"
                  :separation="true"
                  roundType="down"
                />
              </b-col>
              <b-col cols="4" class="unit">人 </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyText
                  :rules="
                    inputValue.siteConditions.ownerInformation.ownerType == 2 &&
                    (inputValue.siteConditions.ownerInformation.numberOfChildren > 0 ||
                      inputValue.siteConditions.ownerInformation.numberOfParentsAlive > 0)
                      ? undefined
                      : 'required|min_value:0|max_value:10'
                  "
                  title="被相続人兄弟姉妹"
                  v-model="inputValue.siteConditions.ownerInformation.siblingsOfTheDeceased"
                  type="number"
                  :mode="
                    inputValue.siteConditions.ownerInformation.numberOfChildren > 0 ||
                    inputValue.siteConditions.ownerInformation.numberOfParentsAlive > 0
                      ? 'View'
                      : 'Edit'
                  "
                  :loading="loading"
                  :separation="true"
                  roundType="down"
                />
              </b-col>
              <b-col cols="4" class="unit">人 </b-col>
            </b-row>
          </b-container>
        </b-card>
        <b-card class="shadow-none">
          <b-container>
            <b-row>
              <b-col>
                <label class="form-check-label">相続人数</label>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-input disabled class="currency" v-model="numberOfHeirs" />
              </b-col>
              <b-col cols="4" class="unit">人</b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                  id="actualInheritancePercentage"
                  small
                  fixed
                  caption-top
                  :items="actualInheritancePercentage"
                  :fields="[
                    {
                      key: 'heiress',
                      label: '法定相続人',
                    },
                    {
                      key: 'percentage',
                      label: '法定相続割合',
                    },
                    {
                      key: 'realPercentage',
                      label: '実割合',
                    },
                  ]"
                >
                  <caption>
                    実相続割合
                  </caption>
                  <template v-slot:cell(percentage)="data">
                    <span :id="'percent' + data.index"
                      >{{ Math.round(data.item.percentage * 10000) / 100 }}%</span
                    >
                    <b-popover
                      content="法定相続分の割合。"
                      :target="'percent' + data.index"
                      triggers="hover"
                    />
                  </template>
                  <template v-slot:cell(realPercentage)="data">
                    <b-row>
                      <b-col cols="5" class="inputRealPercentage">
                        <!--
                        <b-form-input
                          type="number"
                          min="0"
                          max="100"
                          step="0.1"
                          :value="Math.round(data.item.percentage * 10000) / 100"
                          @input="setRealInheritanceRatio($event, data)"
                          v-b-popover.hover="'実際に分割する割合。'"
                        ></b-form-input>
                        -->
                        <allyText
                          v-if="data.item.heiress == '配偶者'"
                          title="実割合"
                          :nonAppear="true"
                          rules="required|min_value:0|max_value:100"
                          type="number"
                          step="0.01"
                          v-model="inputValue.siteConditions.ownerInformation.spouseRealRatio"
                          :tips="'実際に分割する割合。'"
                          :floatLength="2"
                          roundType="down"
                        />
                        <allyText
                          v-else-if="data.item.heiress == '子'"
                          title="実割合"
                          :nonAppear="true"
                          rules="required|min_value:0|max_value:100"
                          type="number"
                          step="0.01"
                          v-model="
                            inputValue.siteConditions.ownerInformation.childRealRatio[
                              inputValue.siteConditions.ownerInformation.spouse == null ||
                              inputValue.siteConditions.ownerInformation.spouse == 0
                                ? data.index
                                : data.index - 1
                            ]
                          "
                          :tips="'実際に分割する割合。'"
                          :floatLength="2"
                          roundType="down"
                        />
                        <allyText
                          v-else-if="data.item.heiress == '親'"
                          title="実割合"
                          :nonAppear="true"
                          rules="required|min_value:0|max_value:100"
                          type="number"
                          step="0.01"
                          v-model="
                            inputValue.siteConditions.ownerInformation.parentRealRatio[
                              inputValue.siteConditions.ownerInformation.spouse == null ||
                              inputValue.siteConditions.ownerInformation.spouse == 0
                                ? data.index
                                : data.index - 1
                            ]
                          "
                          :tips="'実際に分割する割合。'"
                          :floatLength="2"
                          roundType="down"
                        />
                        <allyText
                          v-else
                          title="実割合"
                          :nonAppear="true"
                          rules="required|min_value:0|max_value:100"
                          type="number"
                          step="0.01"
                          v-model="
                            inputValue.siteConditions.ownerInformation.siblingRealRatio[
                              inputValue.siteConditions.ownerInformation.spouse == null ||
                              inputValue.siteConditions.ownerInformation.spouse == 0
                                ? data.index
                                : data.index - 1
                            ]
                          "
                          :tips="'実際に分割する割合。'"
                          :floatLength="2"
                          roundType="down"
                        />
                      </b-col>
                      <b-col class="unit">
                        ％
                      </b-col>
                    </b-row>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-card-group>
    </b-card>
    <b-card header-tag="header" id="siteInformation" v-if="tabName === 'siteInformation'">
      <template #header>
        <div class="row" style="margin: 0;">
          <div class="col-2">敷地情報</div>

          <div class="col-10" style="text-align: right;">
            <b-row style="margin-bottom:0;">
              <b-col style="font-weight:normal;font-size:0.875rem;">
                ※路線価・地区区分の検索が必要な場合は下記をご覧ください。
              </b-col>
            </b-row>
            <b-row style="margin: 0px">
              <b-col>
                <a
                  href="https://www.chikamap.jp/chikamap/"
                  target="_blank"
                  style="margin-right : 5px;"
                >
                  <img src="../../images/ChikaMap_M.gif" />
                </a>
                <a href="https://www.rosenka.nta.go.jp/index.htm" target="_blank">
                  <img src="../../images/bannerForLink.jpg" />
                </a>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
      <b-card-group columns>
        <b-card class="shadow-none">
          <b-container>
            <b-row>
              <b-col>
                <allyText
                  title="敷地面積"
                  rules="required|min_value:0|max_value:99999"
                  name="siteConditions-siteInformation-surfaceArea"
                  type="number"
                  step="0.01"
                  v-model="inputValue.siteConditions.siteInformation.surfaceArea"
                  :loading="loading"
                  :separation="true"
                  :floatLength="2"
                  roundType="down"
                  :optionalOutput="true"
                />
              </b-col>
              <b-col
                cols="4"
                :class="[
                  inputValue.siteConditions.siteInformation.surfaceAreaUnit == 1
                    ? 'squareMeter'
                    : 'landMeasurement',
                  'selectUnit',
                ]"
              >
                <allySelect
                  title="単位"
                  :items="[
                    { value: 1, text: '㎡' },
                    { value: 2, text: '坪' },
                  ]"
                  v-model="inputValue.siteConditions.siteInformation.surfaceAreaUnit"
                />
              </b-col>
            </b-row>
          </b-container>
        </b-card>
        <b-card class="shadow-none">
          <b-container>
            <b-row>
              <b-col>
                <allyText
                  title="相続税路線価"
                  rules="required|min_value:0|max_value:99999999"
                  name="siteConditions-siteInformation-roadsideLandPrice"
                  type="number"
                  v-model="inputValue.siteConditions.siteInformation.roadsideLandPrice"
                  :loading="loading"
                  :separation="true"
                  :optionalOutput="true"
                  roundType="down"
                />
              </b-col>
              <b-col
                cols="4"
                :class="[
                  inputValue.siteConditions.siteInformation.roadsideLandPriceUnit == 1
                    ? 'squareMeter'
                    : 'landMeasurement',
                  'selectUnit',
                ]"
              >
                <allySelect
                  title="単位"
                  :items="[
                    { value: 1, text: '円/㎡' },
                    { value: 2, text: '円/坪' },
                  ]"
                  v-model="inputValue.siteConditions.siteInformation.roadsideLandPriceUnit"
                />
              </b-col>
            </b-row>
          </b-container>
        </b-card>
        <b-card class="shadow-none">
          <b-container>
            <b-row>
              <b-col>
                <allyText
                  title="建蔽率"
                  rules="min_value:0|max_value:100"
                  name="siteConditions-siteInformation-buildingToLandRatio"
                  type="number"
                  step="0.1"
                  v-model="inputValue.siteConditions.siteInformation.buildingToLandRatio"
                  :loading="loading"
                  :floatLength="1"
                  roundType="down"
                />
              </b-col>
              <b-col cols="4" class="unit">％</b-col>
            </b-row>
          </b-container>
        </b-card>
        <b-card class="shadow-none">
          <b-container>
            <b-row>
              <b-col>
                <allyText
                  title="容積率"
                  rules="min_value:0|max_value:1000"
                  name="siteConditions-siteInformation-floorAreaRatio"
                  type="number"
                  step="0.1"
                  v-model="inputValue.siteConditions.siteInformation.floorAreaRatio"
                  :loading="loading"
                  :floatLength="1"
                  roundType="down"
                />
              </b-col>
              <b-col cols="4" class="unit">％</b-col>
            </b-row>
          </b-container>
        </b-card>
        <b-card class="shadow-none">
          <b-container>
            <b-row>
              <b-col>
                <allyText
                  rules="required|min_value:0|max_value:100"
                  name="siteConditions-siteInformation-percentageOfLeaseholdInterest"
                  title="借地権割合"
                  type="number"
                  step="0.1"
                  v-model="inputValue.siteConditions.siteInformation.percentageOfLeaseholdInterest"
                  :loading="loading"
                  tips="路線価図に記載されている。"
                  :optionalOutput="true"
                  :floatLength="1"
                  roundType="down"
                />
              </b-col>
              <b-col cols="4" class="unit">％</b-col>
            </b-row>
          </b-container>
        </b-card>
        <b-card class="shadow-none">
          <b-container>
            <b-row>
              <b-col>
                <allyText
                  rules="required|min_value:0|max_value:999999"
                  name="siteConditions-siteInformation-propertyTaxAssessment"
                  title="固定資産税評価額"
                  type="number"
                  v-model="inputValue.siteConditions.siteInformation.propertyTaxAssessment"
                  :loading="loading"
                  :separation="true"
                  :optionalOutput="true"
                  roundType="down"
                />
              </b-col>
              <b-col cols="4" style="display: flex; padding-left:0px;">
                <span class="unit landMeasurement">円/坪</span>
                <span class="selectUnit" style="padding-left: 1rem;">
                  <allySelect
                    title="推定・実額"
                    :items="[
                      { text: '実額', value: 1 },
                      { text: '推定', value: 2 },
                    ]"
                    v-model="inputValue.siteConditions.siteInformation.presumption"
                  />
                </span>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
        <b-card class="shadow-none">
          <b-container>
            <b-row>
              <b-col>
                <allyText
                  rules="required|min_value:0|max_value:999999"
                  name="siteConditions-siteInformation-availableForSale"
                  title="売却額（目安）"
                  type="number"
                  v-model="inputValue.siteConditions.siteInformation.availableForSale"
                  :loading="loading"
                  :separation="true"
                  :optionalOutput="true"
                  roundType="down"
                />
              </b-col>
              <b-col cols="4" class="unit landMeasurement">円/坪</b-col>
            </b-row>
          </b-container>
        </b-card>
        <b-card class="shadow-none">
          <b-container>
            <b-row>
              <b-col>
                <allySelect
                  title="現況用途"
                  name="siteConditions-siteInformation-currentUse"
                  v-model="inputValue.siteConditions.siteInformation.currentUse"
                  :items="['更地', '月極駐車場', '貸地', '貸家建付地']"
                  :loading="loading"
              /></b-col>
            </b-row>
          </b-container>
        </b-card>
        <b-card class="shadow-none">
          <b-container>
            <b-row>
              <b-col>
                <allyText
                  rules="min_value:0"
                  title="現況収入"
                  name="siteConditions-siteInformation-currentIncome"
                  type="number"
                  v-model="inputValue.siteConditions.siteInformation.currentIncome"
                  :loading="loading"
                  :separation="true"
                  roundType="down"
                />
              </b-col>
              <b-col cols="4" class="unit">円／年</b-col>
            </b-row>
          </b-container>
        </b-card>
        <b-card class="shadow-none">
          <b-container>
            <b-row>
              <b-col>
                <allySelect
                  title="用途地域"
                  name="siteConditions-siteInformation-zoningDistrict"
                  v-model="inputValue.siteConditions.siteInformation.zoningDistrict"
                  :items="[
                    '第一種低層住宅専用地域',
                    '第二種低層住宅専用地域',
                    '第一種中高層住宅専用地域',
                    '第二種中高層住宅専用地域',
                    '第一種住居地域',
                    '第二種居住地域',
                    '準居住地域',
                    '田園住居地域',
                    '近隣商業地域',
                    '商業地域',
                    '準工業地域',
                    '工業地域',
                    '工業専用地域',
                    '無指定区域',
                    '都市計画区域外',
                  ]"
                  :loading="loading"
                />
              </b-col>
            </b-row>
          </b-container>
        </b-card>
        <b-card class="shadow-none">
          <b-container>
            <b-row>
              <b-col>
                <allyText
                  title="その他地域"
                  name="siteConditions-siteInformation-otherAreas"
                  v-model="inputValue.siteConditions.siteInformation.otherAreas"
                  :loading="loading"
              /></b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-card-group>
      <b-card class="shadow-none">
        <b-container>
          <b-row>
            <b-col>
              <allyImages
                title="土地写真"
                class="images"
                mode="Edit"
                v-model="landPicture"
                :loading="loading"
                name="landPicture"
                ref="landPicture"
                :max="1"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-card>
    </b-card>
  </b-card-group>
</template>
<script>
  export default {
    props: {
      inputValue: {
        type: Object,
        required: true,
      },
      numberOfHeirs: {
        type: Number,
        required: true,
      },
      actualInheritancePercentage: {
        type: Array,
        required: true,
      },
      calcFlg: {
        type: Boolean,
        required: true,
      },
      tabName: null, //所有者情報と敷地情報の切り替えフラグ
    },
    data() {
      return {
        loading: false,
      };
    },
    // methods: {
    //   //実相続割合入力時
    //   setRealInheritanceRatio: function(value, data) {
    //     let spouse = this.inputValue.siteConditions.ownerInformation.spouse;
    //     switch (data.item.heiress) {
    //       case '配偶者':
    //         this.inputValue.siteConditions.ownerInformation.spouseRealRatio = Number(value);
    //         break;
    //       case '子':
    //         if (spouse == null || spouse == 0) {
    //           this.inputValue.siteConditions.ownerInformation.childRealRatio[data.index] = Number(
    //             value
    //           );
    //         } else {
    //           this.inputValue.siteConditions.ownerInformation.childRealRatio[
    //             data.index - 1
    //           ] = Number(value);
    //         }
    //         break;
    //       case '親':
    //         if (spouse == null || spouse == 0) {
    //           this.inputValue.siteConditions.ownerInformation.parentRealRatio[data.index] = Number(
    //             value
    //           );
    //         } else {
    //           this.inputValue.siteConditions.ownerInformation.parentRealRatio[
    //             data.index - 1
    //           ] = Number(value);
    //         }
    //         break;
    //       case '兄弟姉妹':
    //         if (spouse == null || spouse == 0) {
    //           this.inputValue.siteConditions.ownerInformation.siblingRealRatio[data.index] = Number(
    //             value
    //           );
    //         } else {
    //           this.inputValue.siteConditions.ownerInformation.siblingRealRatio[
    //             data.index - 1
    //           ] = Number(value);
    //         }
    //         break;
    //     }
    //     data.item.realPercentage = Number(value);
    //   },
    // },
    computed: {
      landPicture: {
        get() {
          if (this.inputValue.siteConditions.siteInformation.landPicture == null) {
            return [];
          }
          return [this.inputValue.siteConditions.siteInformation.landPicture];
        },
        set(input) {
          if (input.length != 1) {
            this.inputValue.siteConditions.siteInformation.landPicture = null;
            return;
          }
          this.$refs.landPicture.fileUpload();
          this.inputValue.siteConditions.siteInformation.landPicture = input[0];
        },
      },
    },
  };
</script>
<style scoped>
  #ownerSiteConditions .card-group {
    padding-bottom: 2.5rem;
  }
  .inputRealPercentage {
    padding: 0;
  }
</style>
