// @ts-check

import { PvObject } from '../../../node/node/pvObject';
import { cloneNodeStandalone } from '../../../node/tools/clone';
import { Action } from '../action';
import { ActionBuilder } from './actionBuilder';
import { UseJudgeThePointInArea } from '../../../util/judgeThePointInArea';

export class MoveActionBuilder extends ActionBuilder {
  build() {
    const action = new Action();
    action.actionType = Action.ACTION_TYPE.DRAG_AND_DROP;
    const targetObject = this.dataset.targetObject;
    if (targetObject == null) {
      throw new Error('');
    }

    const selectedObjects = this.context.getSelectedObjects();

    /** @type {PvObject[]} */
    let targetObjects;
    // 対象が選択されている場合全部移動、選択されていない場合１つのみ
    if (selectedObjects.some(s => s.id === targetObject?.id)) {
      targetObjects = selectedObjects;
    } else {
      targetObjects = [targetObject];
    }

    const obj = this.dataset.targetObject;
    if (obj == null) {
      throw new Error('');
    }
    const guideObjects = targetObjects.map(obj => cloneNodeStandalone(obj));
    const defaultTransforms = guideObjects.map(guide => guide.transform.clone());
    guideObjects.forEach(guide => {
      this.context.getGuideRoot().appendChild(guide);
    });

    action.onUpdate = ({ dist }) => {
      guideObjects.forEach((guide, i) => {
        guide.transform = defaultTransforms[i].clone();
        guide.moveBy(dist);
      });
    };

    action.onComplete = ({ dist }) => {
      targetObjects.forEach(obj => {
        obj.moveBy(dist);
      });
      // 建具の場合
      if (targetObjects[0].type === 'door') {
        // 移動後の内外判定を行う
        const judgeThePointInArea = new UseJudgeThePointInArea();
        // 内外判定から建具追加情報を更新
        targetObjects[0].additionalInfo = judgeThePointInArea.getAdditionalInfoForMove(
          // @ts-ignore
          targetObjects[0]
        );
      }
    };

    return action;
  }
}
