//無道路地の計算
const noRoadLandCalc = async function(routePriceResult, input) {
  try {
    //補正率の計算
    var factor =
      (input.landPrice.front * input.noRoadLandSize) /
      (routePriceResult.resultPrice * input.landSize);

    //最大0.4上限である為、補正
    if (factor > 0.4) {
      factor = 0.4;
    }

    //リターン値の格納
    var result = {
      text: '無道路地の計算',
      resultPrice: Math.floor(routePriceResult.resultPrice * (1 - factor)),
      resultPriceType: 'H',
    };

    console.log('無道路地計算完了 : ', result);

    return result;
  } catch (e) {
    console.log('Error : ', e);
    throw new Error(e);
  }
};

export { noRoadLandCalc };
