// @ts-check
import { PvGuideMarker } from '../../../node/node/guideMarkerNode';
import { createNode } from '../../../node/node/pvNode';
import { PvStairs } from '../../../node/node/stairsNode';
import { cloneNode } from '../../../node/tools/clone';
import { Transform } from '../../../util/math/transform';
import { Vec } from '../../../util/math/vector';
import { Action } from '../action';
import { ActionBuilder } from '../../action/builder/actionBuilder';
import { makeToast } from '../../../util/util';
import { MESSAGE_TEXT } from '../../../util/const';
import { IconDataFetcher } from '../../../fetch/icon';

export class DrawStairsActionBuilder extends ActionBuilder {
  targetChildrenLength = 0; // DATA: 部屋の子オブジェクト数

  build() {
    const action = new Action();
    action.actionType = Action.ACTION_TYPE.MULTI_CLICK;

    const { getGuideRoot, getCurrentPos, getRoot, getObjectPreset } = this.context;
    // クリックしたオブジェクト情報を取得
    const clickTarget = this.dataset.targetObject;
    // 取得できていたら「床」のみ取得
    const target = clickTarget != null ? clickTarget.findUpByType('floor') : null;

    if (target?.type !== 'floor' && this.dataset.eventType !== 'mousemove') {
      makeToast(MESSAGE_TEXT.DRAW_HELP_ROOM_INSIDE);
      return;
    }

    // 螺旋階段の時
    if (getObjectPreset().code === 4301 || getObjectPreset().code === 4302) {
      // 階段ノードをクローンする
      const spiralStairs = cloneNode(/** @type {PvStairs} */ (this.context.getObjectPreset()));
      const repo = new IconDataFetcher();
      const currentPosition = getCurrentPos();

      // mousedownした時
      if (this.dataset.eventType === 'mousedown' && target) {
        // @ts-ignore
        repo.listIcons().then(response => {
          const a = response.find(value => value.code == 4300);
          // @ts-ignore
          spiralStairs.style.imageUrl = a.url; // 螺旋階段に画像のurlを持たせる
          spiralStairs.moveTo(currentPosition);
          // 螺旋階段のtransformをフォーマットする
          spiralStairs.transform = Transform.fromMatrix(
            target.getLocalTransform(spiralStairs.transform)
          );
          target?.appendChild(spiralStairs);
        });
      }

      // 「mousemove」 かつ 「部屋の子オブジェクト数に1足した値と操作対象の部屋の子オブジェクト数が同じ」 かつ 「操作対象の種類が階段」の時
      else if (
        this.dataset.eventType === 'mousemove' &&
        this.targetChildrenLength + 1 == target?.children.length &&
        target.lastChild?.type === 'stairs'
      ) {
        // @ts-ignore
        target.lastChild?.moveTo(currentPosition);
        // @ts-ignore
        target.lastChild.transform = Transform.fromMatrix(
          target.getLocalTransform(target.lastChild.transform)
        );
      }
      return;
    }

    makeToast(MESSAGE_TEXT.DRAW_HELP_STAIR_FINISH, 'primary');

    const guideMarker = createNode(PvGuideMarker);
    getGuideRoot().appendChild(guideMarker);
    guideMarker.moveTo(getCurrentPos());
    guideMarker.pointerEvents = 'all';

    const guide = cloneNode(/** @type {PvStairs} */ (this.context.getObjectPreset()));

    getGuideRoot().appendChild(guide);

    action.onUpdate = ({ routes }) => {
      guide.deformationFromRoutes(routes);
    };

    action.onComplete = ({ first, routes }) => {
      const stairs = cloneNode(/** @type {PvStairs} */ (this.context.getObjectPreset()));
      stairs.moveBy(new Vec(first.x, first.y));
      stairs.deformationFromRoutes(routes.map(r => r.sub(first)));

      if (target) {
        stairs.transform = Transform.fromMatrix(target.getLocalTransform(stairs.transform));
        target.appendChild(stairs);
      } else {
        getRoot().appendChild(stairs);
      }
    };

    action.onAdvanceOrRollBack = ({ last }, dataset) => {
      if (dataset?.id === guideMarker.id) {
        return false;
      } else {
        guideMarker.transform = guideMarker.transform.cloneWith({
          translate: last.clone(),
        });
      }
    };

    return action;
  }
}
