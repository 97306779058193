<template>
  <CommonLayout :title="title" :companyLogo="companyLogo" :companyName="companyName">
    <template v-slot>
      <div style="font-size: 40px; padding: 0px 100px;">
        <div style="position: absolute; left: 45%; top: 5.5%; font-size: 125px; color: black;">
          ◎{{ titleYear }}
        </div>
        <div>
          <table style="position:absolute;top:18%; left:5%; width:4.5%;">
            <tr>
              <th :style="[initialCostHeaderStyle, incomeAndExpenditureSmallFont, { width: initialCostHeaderWidth }]">
                初期投資
              </th>
            </tr>
          </table>
          <table style="position:absolute;top:18%; left:10%;">
            <tr>
              <th :style="[initialCostHeaderStyle, incomeAndExpenditureSmallFont, { width: item1ColWidth }]">
                借入金
              </th>
              <td :style="[tableInitialCostStyle, incomeAndExpenditureSmallFont, { width: tableInitialCostWidth }]">
                {{ initialCost.loans | currency }}円
              </td>
            </tr>
          </table>
          <table style="position:absolute;top:18%; left:25%;">
            <tr>
              <th :style="[initialCostHeaderStyle, incomeAndExpenditureSmallFont, { width: item1ColWidth }]">
                自己資金
              </th>
              <td :style="[tableInitialCostStyle, incomeAndExpenditureSmallFont, { width: tableInitialCostWidth }]">
                {{ initialCost.ownResources | currency }}円
              </td>
            </tr>
          </table>
          <table style="position:absolute;top:18%; left:40%;">
            <tr>
              <th :style="[initialCostHeaderStyle, incomeAndExpenditureSmallFont, { width: item1ColWidth }]">
                保証金
              </th>
              <td :style="[tableInitialCostStyle, incomeAndExpenditureSmallFont, { width: tableInitialCostWidth }]">
                {{ initialCost.deposit | currency }}円
              </td>
            </tr>
          </table>
          <table style="position:absolute;top:18%; left:55%;">
            <tr>
              <th :style="[initialCostHeaderStyle, incomeAndExpenditureSmallFont, { width: item1ColWidth }]">
                敷金
              </th>
              <td :style="[tableInitialCostStyle, incomeAndExpenditureSmallFont, { width: tableInitialCostWidth }]">
                {{ initialCost.securityDeposit | currency }}円
              </td>
            </tr>
          </table>
        </div>
        <div
            :style="
              typeList[1] === 'total'
                ? incomeAndExpenditureTotalFontUnitPrice
                : incomeAndExpenditureFontUnitPrice
            "
          >
            単位：万円
        </div>
        <template>
          <table
            :style="{
              width: '100%',
              'background-color': subHeaderStyle['background-color'],
              border: subHeaderStyle.border,
              'table-layout': 'fixed',
            }"
          >
            <thead>
              <tr>
                <th :style="[headerStyle, incomeAndExpenditureSmallFont]"></th>
                <th :style="[headerStyle, incomeAndExpenditureSmallFont, { width: item1ColWidth }]">
                  項目1
                </th>
                <th :style="[headerStyle, incomeAndExpenditureSmallFont, { width: item2ColWidth }]">
                  項目2
                </th>
                <th
                  v-for="i in yearNum"
                  :key="i"
                  :style="[headerStyle, incomeAndExpenditureSmallFont]"
                >
                  {{ start + i - 1 }}年目
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="typeList[1] === 'income'">
                <tr v-for="(income, i) in incomeList" :key="'incomeList' + i">
                  <td v-if="i === 0" :style="headerStyle" :rowspan="incomeList.length + 1">
                    収入
                  </td>
                  <td
                    v-if="income.index === 0"
                    :style="[subHeaderStyle, incomeAndExpenditureSmallFont]"
                    :rowspan="income.length"
                  >
                    {{ income.name }}
                  </td>
                  <td :style="[subHeaderStyle, incomeAndExpenditureSmallFont]">
                    {{ income.val.name }}
                  </td>
                  <td
                    v-for="(v, j) in income.val.values
                      ? appendEmptyArray(income.val.values.slice(start - 1, end))
                      : []"
                    :key="'val' + j"
                    :style="[tableNumberStyle, incomeAndExpenditureSmallFont]"
                  >
                    {{
                      v
                        ? formatNum(v / 10000, {
                            floatLength: 0,
                            separation: true,
                            rountType: 'down',
                          })
                        : ''
                    }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" :style="[subHeaderStyle, incomeAndExpenditureSmallFont]">
                    収入合計
                  </td>
                  <td
                    v-for="(item, i) in incomeTotal ? appendEmptyArray(incomeTotal) : []"
                    :key="'c' + i"
                    :style="[
                      tableNumberStyle,
                      formatNum(item / 10000, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      }).length <= 8
                        ? incomeAndExpenditureSmallFont
                        : incomeAndExpenditureBigNumFont,
                    ]"
                  >
                    {{
                      item
                        ? formatNum(item / 10000, {
                            floatLength: 0,
                            separation: true,
                            rountType: 'down',
                          })
                        : ''
                    }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div :style="incomeAndExpenditureFontUnitPrice">
            単位：万円
          </div>
          <table
            :style="{
              width: '100%',
              'background-color': subHeaderStyle['background-color'],
              border: subHeaderStyle.border,
              'table-layout': 'fixed',
            }"
          >
            <thead>
              <tr>
                <th :style="[headerStyle, incomeAndExpenditureSmallFont]"></th>
                <th :style="[headerStyle, incomeAndExpenditureSmallFont, { width: item1ColWidth }]">
                  項目1
                </th>
                <th :style="[headerStyle, incomeAndExpenditureSmallFont, { width: item2ColWidth }]">
                  項目2
                </th>
                <th
                  v-for="i in yearNum"
                  :key="i"
                  :style="[headerStyle, incomeAndExpenditureSmallFont]"
                >
                  {{ start + i - 1 }}年目
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="typeList[1] === 'income'">
                <tr v-for="(exp, i) in expenditureList" :key="'expenditureList' + i">
                  <td v-if="i === 0" :style="headerStyle" :rowspan="expenditureList.length + 1">
                    支出
                  </td>
                  <td
                    v-if="exp.index === 0"
                    :style="[subHeaderStyle, incomeAndExpenditureSmallFont]"
                    :rowspan="exp.length"
                  >
                    {{ exp.name }}
                  </td>
                  <td :style="[subHeaderStyle, incomeAndExpenditureSmallFont]">
                    {{ exp.val.name }}
                  </td>
                  <td
                    v-for="(v, j) in exp.val.values
                      ? appendEmptyArray(exp.val.values.slice(start - 1, end))
                      : []"
                    :key="'val' + j"
                    :style="[
                      tableNumberStyle,
                      formatNum(v / 10000, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      }).length <= 19
                        ? incomeAndExpenditureSmallFont
                        : incomeAndExpenditureBigNumFont,
                    ]"
                  >
                    {{
                      v
                        ? formatNum(v / 10000, {
                            floatLength: 0,
                            separation: true,
                            rountType: 'down',
                          })
                        : ''
                    }}
                  </td>
                </tr>
              </template>
            </tbody>
            <template v-if="typeList[1] === 'income'">
              <tbody
                v-for="(t, i) in result.expenditure.total ? result.expenditure.total : []"
                :key="'expenditureTotal' + i"
              >
                <tr>
                  <td :style="headerStyle"></td>
                  <td :style="[subHeaderStyle, incomeAndExpenditureSmallFont]">{{ t.name }}</td>
                  <td :style="subHeaderStyle"></td>
                  <td
                    v-for="(value, j) in t.values
                      ? appendEmptyArray(t.values.slice(start - 1, end))
                      : []"
                    :key="j"
                    :style="[
                      tableNumberStyle,
                      formatNum(value / 10000, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      }).length <= 19
                        ? incomeAndExpenditureSmallFont
                        : incomeAndExpenditureBigNumFont,
                    ]"
                  >
                    {{
                      value
                        ? formatNum(value / 10000, {
                            floatLength: 0,
                            separation: true,
                            rountType: 'down',
                          })
                        : ''
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
            <template v-if="type === '税法上'">
              <tbody
                v-for="(t, i) in result.total.items ? result.total.items : []"
                :key="'total' + i"
              >
                <tr>
                  <td :style="[headerStyle, incomeAndExpenditureSmallFont]"></td>
                  <td :style="[subHeaderStyle, incomeAndExpenditureSmallFont]">{{ t.name }}</td>
                  <td :style="[subHeaderStyle, incomeAndExpenditureSmallFont]"></td>
                  <td
                    v-for="(value, j) in t.values
                      ? appendEmptyArray(t.values.slice(start - 1, end))
                      : []"
                    :key="j"
                    :style="[tableNumberStyle, incomeAndExpenditureSmallFont]"
                  >
                    {{
                      value
                        ? formatNum(value / 10000, {
                            floatLength: 0,
                            separation: true,
                            rountType: 'down',
                          })
                        : ''
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
          <div :style="incomeAndExpenditureFontUnitPrice">
            単位：万円
          </div>
        </template>
        <template>
          <div style="font-size: 40px">
            <table
              :style="{
                width: '100%',
                'background-color': subHeaderStyle['background-color'],
                border: subHeaderStyle.border,
                'table-layout': 'fixed',
              }"
            >
              <thead>
                <tr>
                  <th :style="[headerStyle, incomeAndExpenditureFont, { width: otherColWidth }]">
                    項目
                  </th>
                  <th
                    v-for="i in yearNum"
                    :key="i"
                    :style="[headerStyle, incomeAndExpenditureFont]"
                  >
                    {{ start + i - 1 }}年目
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in result.total.items" :key="'total' + i">
                  <td :style="[subHeaderStyle, incomeAndExpenditureFont, { height: '10px' }]">
                    {{ item ? item.name : '' }}
                  </td>
                  <td
                    v-for="(v, j) in item.values
                      ? appendEmptyArray(item.values.slice(start - 1, end))
                      : []"
                    :key="'val' + j"
                    :style="[
                      tableNumberStyle,
                      formatNum(v / 10000, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      }).length <= 11
                        ? incomeAndExpenditureFont
                        : incomeAndExpenditureBigNumFont,
                    ]"
                  >
                    {{
                      v
                        ? formatNum(v / 10000, {
                            floatLength: 0,
                            separation: true,
                            rountType: 'down',
                          })
                        : ''
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="result.indicator.items.length > 0"
              :style="incomeAndExpenditureTotalFontUnitPrice"
            >
              単位：％
            </div>
            <table
              v-if="result.indicator.items.length > 0"
              :style="{
                width: '100%',
                'background-color': subHeaderStyle['background-color'],
                border: subHeaderStyle.border,
                'table-layout': 'fixed',
              }"
            >
              <thead>
                <tr>
                  <th :style="[headerStyle, incomeAndExpenditureFont, { width: otherColWidth }]">
                    項目
                  </th>
                  <th
                    v-for="i in yearNum"
                    :key="i"
                    :style="[headerStyle, incomeAndExpenditureFont]"
                  >
                    {{ start + i - 1 }}年目
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in result.indicator.items" :key="'indicator' + i">
                  <td
                    :style="[subHeaderStyle, incomeAndExpenditureFont]"
                    v-html="item ? item.name[0] + '' + item.name[1] : ''"
                  ></td>
                  <td
                    v-for="(v, j) in item.values
                      ? appendEmptyArray(item.values.slice(start - 1, end))
                      : []"
                    :key="'val' + j"
                    :style="[tableNumberStyle, incomeAndExpenditureFont]"
                  >
                    {{
                      v
                        ? formatNum(v, {
                            floatLength: 2,
                            rountType: 'off',
                          })
                        : ''
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import {
    headerStyle,
    initialCostHeaderStyle,
    partHeaderStyle,
    subHeaderStyle,
    tableNumberStyle,
    tableInitialCostStyle,
    incomeAndExpenditureFont,
    incomeAndExpenditureSmallFont,
    incomeAndExpenditureBigNumFont,
    incomeAndExpenditureTotalFontUnitPrice,
    incomeAndExpenditureFontUnitPrice,
  } from '../../../../components/ExportReport/style';

  export default {
    props: {
      companyLogo: String,
      companyName: String,
      result: Object,
      start: Number,
      end: Number,
      type: String,
      initialCost: Object,
    },

    components: {
      CommonLayout,
    },
    data() {
      return {
        headerStyle,
        initialCostHeaderStyle,
        partHeaderStyle,
        subHeaderStyle,
        tableNumberStyle,
        tableInitialCostStyle,
        incomeAndExpenditureFont,
        incomeAndExpenditureSmallFont,
        incomeAndExpenditureBigNumFont,
        incomeAndExpenditureTotalFontUnitPrice,
        incomeAndExpenditureFontUnitPrice,
        yearNum: 15,
        item1ColWidth: '350px',
        item2ColWidth: '350px',
        otherColWidth: '990px',
        tableInitialCostWidth: '400px',
        initialCostHeaderWidth: '200px',
      };
    },
    computed: {
      typeList() {
        if (this.type == null) {
          return [null, null];
        }
        return this.type.split(':');
      },
      title() {
        if (this.typeList[0] === 'cash') {
          return `キャッシュフロー計画書`;
        } else if (this.typeList[0] === 'tax') {
          return `損益計画書`;
        } else {
          return '';
        }
      },
      titleYear() {
        return `${this.start}～${this.start + 14}年後`;
      },
      incomeList() {
        if (this.result == null) {
          return [];
        }
        const result = this.result.income.items.reduce((pre, cur) => {
          return [
            ...pre,
            ...cur.rows.map((val, i, array) => {
              return {
                name: cur.name,
                val: val,
                length: array.length,
                index: i,
              };
            }),
          ];
        }, []);
        return result;
      },
      incomeTotal() {
        if (this.result == null) {
          return [];
        }

        return this.result.income.total[0].values.slice(this.start - 1, this.end);
      },
      expenditureList() {
        if (this.result == null) {
          return [];
        }

        const result = this.result.expenditure.items.reduce((pre, cur) => {
          return [
            ...pre,
            ...cur.rows.map((val, i, array) => {
              return {
                name: cur.name,
                val: val,
                length: array.length,
                index: i,
              };
            }),
          ];
        }, []);
        return result;
      },
    },
    methods: {
      appendEmptyArray(inputArray) {
        if (inputArray == null || inputArray.length == 0) {
          return new Array(15).fill(null);
        }
        return new Array(15).fill(null).map((_, i) => {
          if (i > this.end) {
            return null;
          }
          return inputArray[i] || null;
        });
      },
    },
  };
</script>
<style scoped>
  .col1 {
    width: 17%;
  }
  .col2 {
    width: 25%;
  }
  .col3 {
    text-align: right;
    width: 8%;
  }
  .col4 {
    width: 8%;
  }
  .col5 {
    text-align: right;
    width: 17%;
  }
  .col6 {
    width: 25%;
  }
</style>
