<template>
  <div>
    <div class="pv-config-label">種類</div>
    <div>
      <select name="stairs" v-model="activeObject.code" style="width: 100%">
        <option
          v-for="stairsItem in stairsListFilter"
          :value="stairsItem.code"
          :key="stairsItem.code"
          >{{ stairsItem.name }}</option
        ></select
      >
    </div>
    <div class="pv-config-label">幅</div>
    <div>
      <input
        type="number"
        style="width: 100%"
        :value="activeObject.width"
        step="5"
        @input="e => {
          setProperty('width', parseInt(e.srcElement.value));
          setProperty('height', parseInt(e.srcElement.value));
          setVertexes();
        }"
        :disabled="!isEditor"
      />
    </div>
    <div class="pv-config-label">一段あたりの高さ(m)</div>
    <div>
      <input
        type="number"
        min="0.01"
        max="10"
        step="0.01"
        style="width: 100%"
        :disabled="!isEditor"
        v-model="activeObject.additionalInfo.stepHeight"
      />
    </div>
    <div class="pv-config-label">全体の高さ(m)</div>
    <div>
      <input
        type="number"
        min="0.1"
        max="10"
        step="0.1"
        style="width: 100%"
        :value="activeObject.height3d.toFixed(2)"
        @input="e => setProperty('height3d', parseFloat(e.srcElement.value))"
        :disabled="!isEditor"
      />
    </div>
    <div v-if="(stairsListFilter[0].code === 4301 || stairsListFilter[0].code === 4302)">
      <div class="pv-config-label">一段あたりの角度</div>
      <div>
        <input
          type="number"
          min="15"
          max="90"
          step="1"
          style="width: 100%"
          v-model="activeObject.additionalInfo.stepAngle"
        />
      </div>
    </div>
    <div v-else-if="stairsListFilter[0].code === 4001">
      <div class="pv-config-label">左サイド</div>
      <input
        type="checkbox"
        v-model="activeObject.style.flipHorizontal"
        @change="e => setStyleProperty('flipHorizontal', e.target.checked)"
        :disabled="!isEditor"
      />
    </div>
    <div v-show="isVRSimulatorLicense">
      <div class="pv-config-label">手すり(VR用)</div>
      <allyRadio
        class="col-12 p-0"
        name="stringers-type"
        :items="[
          {
            text: ADDITIONAL_INFO_STRINGERS_TYPE.NONE.LOGICAL_NAME,
            value: ADDITIONAL_INFO_STRINGERS_TYPE.NONE.ID,
          },
          {
            text: ADDITIONAL_INFO_STRINGERS_TYPE.LEFT.LOGICAL_NAME,
            value: ADDITIONAL_INFO_STRINGERS_TYPE.LEFT.ID,
          },
          {
            text: ADDITIONAL_INFO_STRINGERS_TYPE.RIGHT.LOGICAL_NAME,
            value: ADDITIONAL_INFO_STRINGERS_TYPE.RIGHT.ID,
          },
        ]"
        v-model="activeObject.additionalInfo.stringers"
      />
    </div>
  </div>
</template>
<script>
  // @ts-check
  import { defineComponent, computed, ref } from '@vue/composition-api';
  import { ADDITIONAL_INFO_STRINGERS_TYPE } from '../../../../lib/util/const';
  import { PvObject } from '../../../../lib/node/node/pvObject';
  import { Rect } from '../../../../lib/util/math/rect';

  export default defineComponent({
    props: {
      activeObject: PvObject,
      isEditor: {
        //  PROP: 編集権限の有無
        type: Boolean,
        default: true,
      },
      isVRSimulatorLicense: Boolean,
      stairList: Array, // PROP: 階段リスト
    },
    setup(props, context) {
      const spiralStairsRight = ref(4301); // DATA: 螺旋階段(右回り)のcode
      const spiralStairsLeft = ref(4302); // DATA: 螺旋階段(左回り)のcode

      const setProperty = (/** @type {string} */ key, /** @type {any} */ val) => {
        props.activeObject[key] = val;
      };

      const setStyleProperty = (/** @type {string} */ key, /** @type {any} */ val) => {
        props.activeObject.style[key] = val;
      };

      /**
       * COMPUTED: 種類リストを螺旋階段とそれ以外に制限
       */
      const stairsListFilter = computed(() => {
        return props.stairList.filter(value => {
          if (
            props.activeObject.code === spiralStairsRight.value ||
            props.activeObject.code === spiralStairsLeft.value
          ) {
            return value.code === spiralStairsRight.value || value.code === spiralStairsLeft.value;
          } else {
            return value.code !== spiralStairsRight.value && value.code !== spiralStairsLeft.value;
          }
        });
      });

      const setVertexes = () => {
        if (
            props.activeObject.code === spiralStairsRight.value ||
            props.activeObject.code === spiralStairsLeft.value
          ) {
            // 座標の書き換え
            props.activeObject.vertexes = new Rect(
              0,
              0,
              props.activeObject.width * 2,
              props.activeObject.height * 2
            ).toVecs();
          }
      };

      return {
        setProperty,
        setStyleProperty,
        ADDITIONAL_INFO_STRINGERS_TYPE,
        stairsListFilter,
        setVertexes,
      };
    },
  });
</script>
