<template>
  <div
    :style="{
      width: this.width,
      height: this.height,
      'background-color': 'white',
      border: 'solid 1px',
      padding: '2%',
      'border-radius': '10px',
    }"
  >
    <slot></slot>
  </div>
</template>
<script>
  export default {
    props: {
      width: {
        type: String,
        default: '100%',
      },
      height: {
        type: String,
        default: 'auto',
      },
    },
    data() {
      return {};
    },
  };
</script>
