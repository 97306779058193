<!--
作成者：Matsui Taiga
作成日：2022.10.27
画面名：平面図情報一覧
機能：平面図情報一覧
親子関係：なし
更新者：
①
更新内容：
①
-->

<template>
  <div>
    <paginationV2
      ref="pagination"
      :headers="headers"
      targetName="Property"
      listQueryNamePlaneView="listPlaneViews"
      @editClicked="e => editPlane(e)"
      :hideDetail="isHideDetail"
      :hideDelete="isHideDelete"
      :imageViewSize="{ width: '150px', height: '73px' }"
      :isPlaneViewListModal="isPlaneViewListModal"
    ></paginationV2>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getS3Url, listPlaneViews, getAccount } from '../../graphql/queries.js';
  import LicenseCommon from '../License/js/Common';
  var moment = require('moment-timezone');
  API.configure(awsconfig);

  export default {
    mixins: [LicenseCommon],
    props: {
      isPlaneViewListModal: Boolean,
      targetName: String,
    },
    data: function() {
      return {
        companyId: null, // 会社ID
        propertyId: null, // 物件ID
        maxImage: 10, // 保存MAX件数
        itemsLengthMaxFlg: false,
        completeFirstLoadFlg: false,
        orderId: null, // オーダーID
        downloading: false, // ダウンロード中
        changeRightTarget: null,
        guestIdList: null,
        roomId: null,
        planeFilterFix: [],
        isHideDetail: true,
        isHideDelete: true,
        noDataImage: require('../../images/no-image.png'),
      };
    },
    async created() {
      // CREATED: 会社ID
      this.companyId = this.getCompanyId();
      // CREATED: 部屋ID
      this.roomId = this.$route.params.roomId;
      // CREATED: 物件ID
      this.propertyId = this.$route.params.id;
      // CREATED: 物件フィルターFixセット
      this.setPlaneFilterFix();
    },
    methods: {
      /**
       * METHOD: 会社ID取得
       */
      getCompanyId: function() {
        //アカウントから会社ID取得
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },
      /**
       * METHOD: 平面図 ビューエディター遷移
       */
      async goPlaneViewEditor(planeId = null, viewOnly = false) {
        const query = {};
        if (this.propertyId) query.propertyId = this.propertyId;
        if (this.roomId) query.roomId = this.roomId;
        await this.$router.replace(
          {
            path: `/PlaneView/${viewOnly ? 'View' : 'Edit'}/` + (planeId ?? ''),
            query,
          },
          () => {
            location.reload();
          },
          err => {
            location.reload();
          }
        );
      },
      /**
       * METHOD: 平面図 編集
       */
      editPlane: function(e) {
        this.propertyId = e.propertyId;
        this.roomId = e.roomId;
        const right = this.checkRight(
          this.$store.state.user.attributes.sub,
          e.guestIdList,
          e.ownerId
        );

        this.goPlaneViewEditor(e.id, !right);
      },

      /**
       * METHOD: ユーザ権限レベル取得
       * 'systemAdmin' or 'admin' or 'member'
       */
      getUserAuthLevel: function() {
        var userGroup = new Array();
        userGroup = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        if (userGroup.indexOf('systemAdmin') != -1) {
          return 'systemAdmin';
        } else if (userGroup.indexOf('admin') != -1) {
          return 'admin';
        }
        return 'member';
      },
      checkRight(myUserId, guestIdList, ownerId, userLevel = null) {
        if (userLevel == null) {
          userLevel = this.getUserAuthLevel();
        }
        const right =
          (guestIdList != null && guestIdList?.findIndex(v => myUserId === v) >= 0) ||
          ownerId === myUserId ||
          (ownerId == null && guestIdList == null) || // 両方未設定の場合
          userLevel === 'admin';
        return right;
      },
      /**
       * METHOD: フィルター設定を行う
       */
      setPlaneFilterFix() {
        const planeFilterFix = {
          companyId: { eq: this.companyId ?? undefined }, // 物件ID
          deleted: { ne: true },
        };
        // 部屋IDが存在する場合は条件としてセットし、無い場合は部屋IDが存在しないデータを条件とする
        planeFilterFix.roomId = this.roomId
          ? {
              eq: this.roomId,
            }
          : { attributeExists: false };

        this.planeFilterFix = planeFilterFix;
      },
      /*
       * METHOD: imageViewing
       */
      imageViewing: async function(e) {
        return await this.getImageViewing(e, null);
      },
      /*===============================================
       * METHOD: 物件情報のイメージURLを取得する。
       */
      getImageViewing: async function(items, roomId) {
        let params = {
          fileName: '',
          updateDate: new Date(0),
          directory: '',
        };
        // 部屋番号がある場合は設定
        if (items.roomId) {
          roomId = items.roomId;
        }

        // 平面図情報を取得する
        let groundFloorData = await this.getGroundFloorList({
          id: items.propertyId,
          roomId: roomId,
        });
        for (let groundFloor of groundFloorData) {
          if (items.imageName === groundFloor.imageName) {
            let currentUpdateDate = new Date(groundFloor.updateDate);
            params.fileName = groundFloor.imageName;
            params.updateDate = currentUpdateDate;
            params.directory = 'planeView/images';
          }
        }

        if (params.directory === '') {
          return this.noDataImage;
        }
        //画像をS3から呼び出す。
        let links = await API.graphql(
          graphqlOperation(getS3Url, {
            level: 'public',
            file: {
              fileName: params.fileName,
              fileAction: 'View',
              propertyDirectory: {
                propertyId: items.propertyId,
                fileDirectory: params.directory,
              },
            },
          })
        );

        return links.data.getS3Url[0].fileUrl;
      },
      /**
       * METHOD: 平面図を取得する
       */
      getGroundFloorList: async function(e) {
        //平面図の取得条件
        const queryVar = {
          limit: 60,
          companyId: await this.getCompanyId(),
          filter: {
            companyId: {
              eq: await this.getCompanyId(),
            },
            roomId: { eq: e.roomId },
            propertyId: {
              eq: e.id,
            },
            deleted: { eq: false },
          },
        };
        if (e.roomId == null) {
          queryVar.filter.roomId = { attributeExists: false };
        }
        queryVar.nextToken = null;

        var query = [];
        do {
          let items = await API.graphql(graphqlOperation(listPlaneViews, queryVar));
          query = [...query, ...items.data.listPlaneViews.items];
          queryVar.nextToken = items.data.listPlaneViews.nextToken;
        } while (queryVar.nextToken != null);
        return query;
      },
    },
    computed: {
      // COMPUTED: ALLYオートスケッチリスト・ヘッダー
      headers() {
        return [
          {
            key: 'simInfo',
            label: '画像',
            publicImage: this.imageViewing,
            searchable: false,
          },
          { key: 'commonValue.propertyName', label: '物件名', sortable: true },
          { key: 'roomName', label: '部屋番号', searchable: false },
          { key: 'commonValue.staffName', label: '担当者', sortable: true },
          {
            key: 'planeName',
            label: 'タイトル',
          },
          { key: 'updatedAt', label: '更新日', sortable: true },
          { key: 'ownerName', label: '作成者', sortable: true },
          {
            key: 'edit',
            label: '平面図',
            searchable: false,
            callback: (h, row) => {
              const id = this.$store.state.user.attributes.sub;
              const right = this.checkRight(id, row.item.guestIdList, row.item.ownerId);
              return right ? '編集' : '閲覧';
            },
            button: { event: 'editClicked' },
          },
        ];
      },
    },
  };
</script>
<style scoped>
  .add-plane {
    background-color: orange;
  }
  .list >>> .custom-control-input:checked ~ .custom-control-label::before {
    background: var(--colorTheme);
    border-color: var(--colorTheme);
  }
  .load {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 130px;
  }
</style>
