// @ts-check

import { PvObject } from './pvObject';

export class PvGuideMarker extends PvObject {
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'guideMarker';

  pointerEvents = 'none';

  onClick = () => {};
  /**
   */
  constructor() {
    super();
  }
}
