// @ts-check

import { Rect } from './math/rect';

export class BBoxCalculator {
  objectTree;

  constructor({ objectTree }) {
    this.objectTree = objectTree;
  }

  getBBox() {
    let maxX = 0;
    let maxY = 0;
    let minX = Number.MAX_SAFE_INTEGER;
    let minY = Number.MAX_SAFE_INTEGER;
    this.objectTree.value.forEach(obj => {
      const r = obj.getBBox();
      if (maxX < r.x + r.width) maxX = r.x + r.width;
      if (maxY < r.y + r.height) maxY = r.y + r.height;
      if (minX > r.x) minX = r.x;
      if (minY > r.y) minY = r.y;
    });

    return new Rect(minX, minY, maxX - minX, maxY - minY);
  }
}
