<!--
作成者：Suzuki Sota
作成日：
画面名：平面図削除履歴
機能：平面図削除履歴確認
親子関係：なし
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <h4>履歴一覧</h4>
    <div class="row">
      <div class="col-12">
        <router-link class="btn btn-ally float-right" :to="viewJump" type="submit">
          平面図一覧
        </router-link>
      </div>
    </div>
    <div id="app">
      <div>
        <allyRadio
          title="履歴"
          name="history"
          :items="[
            { text: '更新履歴', value: 1 },
            { text: '削除履歴', value: 2 },
            { text: '出力履歴', value: 3 },
            { text: '復元履歴', value: 4 },
          ]"
          :value="isActive"
          @input="activate"
        />
        <paginationV2
          v-show="isActive === 1"
          ref="updateList"
          :headers="updateHeaders"
          targetName="GroundFloorPlan"
          :filterFix="setFilterFix"
          hideDetail
          hideDelete
          hideSearchBox
          @completeFirstLoad="checkItemLength"
        ></paginationV2>
        <paginationV2
          v-if="completeFirstLoadFlg"
          v-show="isActive === 2"
          ref="deleteList"
          :headers="deleteHeaders"
          targetName="GroundFloorPlan"
          :filterFix="deleteFilter"
          hideDetail
          hideDelete
          hideSearchBox
          @restoreDeleteHistory="restoreDeleteHistory"
          @deleteDeleteHistory="deleteDeleteHistory"
        ></paginationV2>
        <paginationV2
          v-if="completeFirstLoadFlg"
          v-show="isActive === 3"
          ref="outputList"
          :headers="outputHeaders"
          targetName="GroundFloorPlan"
          :filterFix="outoputFilter"
          hideDetail
          hideDelete
          hideSearchBox
        ></paginationV2>
        <paginationV2
          v-if="completeFirstLoadFlg"
          v-show="isActive === 4"
          ref="restoreList"
          :headers="restoreHeaders"
          targetName="GroundFloorPlan"
          :filterFix="restoredFilter"
          hideDetail
          hideDelete
          hideSearchBox
        ></paginationV2>
      </div>
    </div>
  </div>
</template>
<script>
  import awsconfig from '../../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { deleteGroundFloorPlan } from '../../../graphql/mutations.js';

  //コンポーネント
  import { restorePlaneView } from './planeViewQuery';

  API.configure(awsconfig);

  export default {
    data: function() {
      return {
        itemLengthMaxFlg: false,
        completeFirstLoadFlg: false,
        restoreCount: 0,
        companys: {
          body: [],
          title: [],
        },
        id: '',
        roomId: '',
        printBtnFlag: false, //出力ボタン出現フラグ
        isMounted: false,
        isActive: 1, //isActiveによって履歴の種類を制御
        //更新履歴ヘッダー
        updateHeaders: [
          { key: 'planeName', label: '平面図名' },
          { key: 'updateDate', label: '最終更新日' },
          { key: 'updatePerson', label: '更新者' },
        ],
        //出力履歴ヘッダー
        outputHeaders: [
          { key: 'planeName', label: '平面図名' },
          { key: 'outputDate', label: '最終出力日' },
          { key: 'outputPerson', label: '出力者' },
        ],
        //復元履歴ヘッダー
        restoreHeaders: [
          { key: 'planeName', label: '平面図名' },
          { key: 'restoreDate', label: '復元日' },
          { key: 'restorePerson', label: '復元者' },
        ],
        //以上は全てPropertyのGQLを使っているため、後々keyの変更が必要
      };
    },
    created() {
      this.id = this.$route.params.id;
      this.roomId = this.$route.query.roomId;
    },
    computed: {
      //削除履歴ヘッダー
      deleteHeaders() {
        return [
          { key: 'planeName', label: '平面図名' },
          { key: 'deleteDate', label: '削除日' },
          {
            key: 'deletePerson',
            label: '削除者',
          },
          {
            key: 'restoreDeleteHistory',
            label: '復元',
            button: {
              label: '復元',
              event: 'restoreDeleteHistory',
              disabled: (header, row) => {
                const right = this.checkRight(
                  this.$store.state.user.attributes.sub,
                  row.item.guestIdList,
                  row.item.ownerId
                );
                return !right || this.itemLengthMaxFlg;
              },
            },
          },
          {
            key: 'deleteHistory',
            label: '削除',
            button: {
              label: '削除',
              event: 'deleteDeleteHistory',
              disabled: (header, row) => {
                const right = this.checkRight(
                  this.$store.state.user.attributes.sub,
                  row.item.guestIdList,
                  row.item.ownerId
                );
                return !right || this.itemLengthMaxFlg;
              },
            },
          },
        ];
      },
      /*リストの検索条件を設定する  */
      setFilterFix() {
        let filterFix = (filterFix = {
          propertyId: { eq: this.id },
          deleted: { eq: false },
        });

        if (this.roomId == null) {
          filterFix.roomId = { attributeExists: false };
        } else {
          filterFix.roomId = { eq: this.roomId };
        }
        return filterFix;
      },
      /* 削除済みリストのﾌｨﾙﾀｰをセットする*/
      deleteFilter() {
        let filterFix = {
          propertyId: { eq: this.id },
          deleted: { eq: true },
        };

        if (this.roomId == null) {
          filterFix.roomId = { attributeExists: false };
        } else {
          filterFix.roomId = { eq: this.roomId };
        }
        return filterFix;
      },
      /**出力済みリストのfilter */
      outoputFilter() {
        let filterFix = {
          propertyId: { eq: this.id },
          output: { eq: true },
        };
        if (this.roomId == null) {
          filterFix.roomId = { attributeExists: false };
        } else {
          filterFix.roomId = { eq: this.roomId };
        }
        return filterFix;
      },
      /**復元履歴のfilter */
      restoredFilter() {
        let filterFix = {
          propertyId: { eq: this.id },
          restored: { eq: true },
        };

        if (this.roomId == null) {
          filterFix.roomId = { attributeExists: false };
        } else {
          filterFix.roomId = { eq: this.roomId };
        }
        return filterFix;
      },
      /**前のページへ戻る */
      viewJump() {
        let str = `/Property/View/${this.id}` + '?fun=1';
        if (this.roomId != null) {
          str = `/Property/View/${this.id}/RoomView/${this.roomId}` + '?fun=1';
        }
        if (this.$route.query.propertyList == 'true') {
          str += '&propertyList=true';
        }
        return str;
      },
    },
    methods: {
      checkItemLength() {
        this.completeFirstLoadFlg = true;
        const length = this.$refs.updateList.getItemLength();
        if (length + this.restoreCount >= 10) {
          this.itemLengthMaxFlg = true;
        }
      },
      //出力ボタン制御
      printProperty: function(target) {
        if (target.length != 0) {
          this.printBtnFlag = false;
        } else {
          this.printBtnFlag = true;
        }
        this.printTarget = target;
      },
      async restoreDeleteHistory(e) {
        await restorePlaneView(
          e.id,
          this.$store.state.user.attributes.family_name +
            this.$store.state.user.attributes.given_name
        );
        this.$refs.deleteList.listItems();
        this.restoreCount++;
        this.checkItemLength();
      },
      async deleteDeleteHistory(e) {
        await API.graphql(
          graphqlOperation(deleteGroundFloorPlan, {
            input: {
              id: e.id,
            },
          })
        );
        this.$refs.deleteList.listItems();
      },
      activate(num) {
        this.isActive = num;
        if (num === 1) {
          this.$refs.updateList.listItems();
        } else if (num === 2) {
          this.$refs.deleteList.listItems();
        } else if (num === 3) {
          this.$refs.outputList.listItems();
        } else if (num === 4) {
          this.$refs.restoreList.listItems();
        }
      },
      checkRight(myUserId, guestIdList, ownerId, userLevel = null) {
        if (userLevel == null) {
          userLevel = this.getUserAuthLevel();
        }
        const right =
          (guestIdList != null && guestIdList?.findIndex(v => myUserId === v) >= 0) ||
          ownerId === myUserId ||
          (ownerId == null && guestIdList == null) || // 両方未設定の場合
          userLevel === 'admin';
        return right;
      },
      // ユーザ権限レベル取得
      getUserAuthLevel: function() {
        var userGroup = new Array();
        userGroup = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        if (userGroup.indexOf('systemAdmin') != -1) {
          return 'systemAdmin';
        } else if (userGroup.indexOf('admin') != -1) {
          return 'admin';
        }
        return 'member';
      },
    },
  };
</script>
