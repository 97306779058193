<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：初期投資/付帯費用/コンサルタント料
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="ConsultantFees">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button id="BtnConsultantFees" variant="link" v-b-toggle.tabConsultantFees>
          コンサルタント料
        </b-button>
      </b-card-header>
      <b-collapse id="tabConsultantFees" role="tabpanel">
        <b-card-body>
          <b-tabs fill pills v-model="investmentAnalytics.initialInvestment.isDetailConsultingCost">
            <b-tab title="概算入力">
              <b-container>
                <b-row>
                  <b-col cols="2" class="title bold">総額</b-col>
                  <b-col cols="2" class="title ">
                    (税抜)
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="component">
                        <allyText
                          name="ConsultantFeesTotal"
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="investmentAnalytics.initialInvestment.estimatedConsultingCost"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold" />
                  <b-col cols="2" class="title">
                    (税込)
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            sumTax(
                              Math.floor(
                                investmentAnalytics.initialInvestment.estimatedConsultingCost
                              )
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="詳細入力">
              <b-container class="detail">
                <b-row>
                  <b-col class="title bold">項目</b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col class="title-center bold">コンサルタント料</b-col>
                      <b-col cols="3" />
                    </b-row>
                  </b-col>
                  <b-col class="title-center ">
                    <b-row>
                      <b-col>詳細</b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">開発許可</b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(
                              investmentAnalytics.initialInvestment.consultingCost[0].amount
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabConsultantFees1>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabConsultantFees1" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">開発許可</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="ally-select">
                                  <allySelect
                                    name="developmentPermits"
                                    :items="developmentPermitsItems"
                                    v-model="
                                      investmentAnalytics.initialInvestment.consultingCost[0]
                                        .content
                                    "
                                  />
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※地積&nbsp;×&nbsp;1.2
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">開発区域面積</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.consultingCost[0]
                                        .quantity
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    mode="View"
                                    :separation="true"
                                    :value="
                                      (Math.floor(
                                        investmentAnalytics.initialInvestment.propertyDescription[0]
                                          .area * 100
                                      ) /
                                        100) *
                                        1.2
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '開発許可',
                                        investmentAnalytics.initialInvestment.consultingCost[0]
                                          .content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円/㎡</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline"
                                  >※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        0,
                                        Math.floor(
                                          (Math.floor(
                                            investmentAnalytics.initialInvestment.consultingCost[0]
                                              .quantity * 100
                                          ) /
                                            100) *
                                            getUnitPrice(
                                              '開発許可',
                                              investmentAnalytics.initialInvestment
                                                .consultingCost[0].content
                                            )
                                        )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">コンサルタント料</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.consultingCost[0].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      Math.floor(
                                        (Math.floor(
                                          investmentAnalytics.initialInvestment.consultingCost[0]
                                            .quantity * 100
                                        ) /
                                          100) *
                                          getUnitPrice(
                                            '開発許可',
                                            investmentAnalytics.initialInvestment.consultingCost[0]
                                              .content
                                          )
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">税務コンサル</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(
                              investmentAnalytics.initialInvestment.consultingCost[1].amount
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabConsultantFees2>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabConsultantFees2" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">件数</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.consultingCost[1]
                                        .quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">件</b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="getUnitPrice('税務コンサル', '有') | currency"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円/件</b-col>
                              </b-row>
                            </b-col>
                            <b-col class="guideline"
                              >※目安
                              <button
                                @click="
                                  changeGuideline(
                                    1,
                                    Math.floor(
                                      investmentAnalytics.initialInvestment.consultingCost[1]
                                        .quantity
                                    ) * getUnitPrice('税務コンサル', '有')
                                  )
                                "
                                class="btn-ally"
                              >
                                反映
                              </button>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">コンサルタント料</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.consultingCost[1].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(
                                        investmentAnalytics.initialInvestment.consultingCost[1]
                                          .quantity
                                      ) *
                                        getUnitPrice('税務コンサル', '有'))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">法務コンサル</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(
                              investmentAnalytics.initialInvestment.consultingCost[2].amount
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabConsultantFees3>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabConsultantFees3" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col cols="4" class="title bold">内容</b-col>
                            <b-col cols="6" class="ally-select">
                              <allySelect
                                name="legalConsultancy"
                                :items="legalConsultancyItems"
                                v-model="
                                  investmentAnalytics.initialInvestment.consultingCost[2].content
                                "
                              />
                            </b-col>
                            <b-col cols="1" />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">件数</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.consultingCost[2]
                                        .quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">件</b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '法務コンサル',
                                        investmentAnalytics.initialInvestment.consultingCost[2]
                                          .content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円/件</b-col>
                              </b-row>
                            </b-col>
                            <b-col class="guideline"
                              >※目安
                              <button
                                @click="
                                  changeGuideline(
                                    2,
                                    Math.floor(
                                      investmentAnalytics.initialInvestment.consultingCost[2]
                                        .quantity
                                    ) *
                                      getUnitPrice(
                                        '法務コンサル',
                                        investmentAnalytics.initialInvestment.consultingCost[2]
                                          .content
                                      )
                                  )
                                "
                                class="btn-ally"
                              >
                                反映
                              </button>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">コンサルタント料</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.consultingCost[2].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(
                                        investmentAnalytics.initialInvestment.consultingCost[2]
                                          .quantity
                                      ) *
                                        getUnitPrice(
                                          '法務コンサル',
                                          investmentAnalytics.initialInvestment.consultingCost[2]
                                            .content
                                        ))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">不動産コンサル</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(
                              investmentAnalytics.initialInvestment.consultingCost[3].amount
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabConsultantFees4>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabConsultantFees4" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">内容</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="ally-select">
                                  <allySelect
                                    name="realEstateConsultancy"
                                    :items="realEstateConsultancyItems"
                                    v-model="
                                      investmentAnalytics.initialInvestment.consultingCost[3]
                                        .content
                                    "
                                  />
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">件数</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.consultingCost[3]
                                        .quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">件</b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col cols="4" class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '不動産コンサル',
                                        investmentAnalytics.initialInvestment.consultingCost[3]
                                          .content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円/件</b-col>
                              </b-row>
                            </b-col>
                            <b-col cols="3" />
                          </b-row>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col class="guideline"
                              >※目安
                              <button
                                @click="
                                  changeGuideline(
                                    3,
                                    Math.floor(
                                      investmentAnalytics.initialInvestment.consultingCost[3]
                                        .quantity
                                    ) *
                                      getUnitPrice(
                                        '不動産コンサル',
                                        investmentAnalytics.initialInvestment.consultingCost[3]
                                          .content
                                      )
                                  )
                                "
                                class="btn-ally"
                              >
                                反映
                              </button>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">コンサルタント料</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.consultingCost[3].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(
                                        investmentAnalytics.initialInvestment.consultingCost[3]
                                          .quantity
                                      ) *
                                        getUnitPrice(
                                          '不動産コンサル',
                                          investmentAnalytics.initialInvestment.consultingCost[3]
                                            .content
                                        ))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">その他コンサル</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            investmentAnalytics.initialInvestment.otherConsultingCost | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabConsultantFees5>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabConsultantFees5" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">権利者調整</b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">人数</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="ally-select">
                                  <allySelect
                                    name="realEstateConsultancy"
                                    :items="adjustmentOfRightsHoldersItems"
                                    v-model="
                                      investmentAnalytics.initialInvestment.otherCostDetails[0]
                                        .content
                                    "
                                  />
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuidelinOtherCostDetails(
                                        0,
                                        getUnitPrice(
                                          '権利者調整',
                                          investmentAnalytics.initialInvestment.otherCostDetails[0]
                                            .content
                                        )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="2" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">金額</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.otherCostDetails[0]
                                        .amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '権利者調整',
                                        investmentAnalytics.initialInvestment.otherCostDetails[0]
                                          .content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuidelinOtherCostDetails(
                                        1,
                                        getUnitPrice('その他コンサル', '民事信託コンサル')
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="2" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">民事信託コンサル</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.otherCostDetails[1]
                                        .amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice('その他コンサル', '民事信託コンサル') | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuidelinOtherCostDetails(
                                        2,
                                        getUnitPrice('その他コンサル', '任意後見コンサル')
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="2" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">任意後見コンサル</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.otherCostDetails[2]
                                        .amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice('その他コンサル', '任意後見コンサル') | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuidelinOtherCostDetails(
                                        3,
                                        getUnitPrice('その他コンサル', 'その他')
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="2" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">その他</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.otherCostDetails[3]
                                        .amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="getUnitPrice('その他コンサル', 'その他') | currency"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12" class="borderline" />
                          </b-row>
                          <b-row>
                            <b-col cols="4" class="title bold text-right">合計</b-col>
                            <b-col cols="4" class="component">
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="detailsTotal() | currency"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Calculation from './../Calculation';
  export default {
    mixins: [Calculation],
    data: function() {
      return {
        // 開発許可
        developmentPermitsItems: this.getUnitPricesItems('開発許可'),
        // 税務コンサル
        taxConsultancyItems: this.getUnitPricesItems('税務コンサル'),
        // 法務コンサル
        legalConsultancyItems: this.getUnitPricesItems('法務コンサル'),
        // 不動産コンサル
        realEstateConsultancyItems: this.getUnitPricesItems('不動産コンサル'),
        // 権利者調整
        adjustmentOfRightsHoldersItems: this.getUnitPricesItems('権利者調整'),
      };
    },
    watch: {
      // マスタ更新時
      '$store.state.master.unitPrice': {
        handler: function() {
          // 選択項目更新
          this.developmentPermitsItems = this.getUnitPricesItems('開発許可');
          this.taxConsultancyItems = this.getUnitPricesItems('税務コンサル');
          this.legalConsultancyItems = this.getUnitPricesItems('法務コンサル');
          this.realEstateConsultancyItems = this.getUnitPricesItems('不動産コンサル');
          this.adjustmentOfRightsHoldersItems = this.getUnitPricesItems('権利者調整');
        },
        deep: false,
      },
    },
    methods: {
      // その他合計
      detailsTotal: function() {
        let total = this.investmentAnalytics.initialInvestment.otherCostDetails.reduce((a, v) => {
          return a + Math.floor(Number(v.amount));
        }, 0);
        if (total == 0) {
          total = null;
        }
        // 合計を更新
        Vue.set(this.investmentAnalytics.initialInvestment, 'otherConsultingCost', total);
        return total;
      },
      changeGuideline: function(id, sum) {
        Vue.set(this.investmentAnalytics.initialInvestment.consultingCost[id], 'amount', sum);
      },
      changeGuidelinOtherCostDetails: function(id, sum) {
        Vue.set(this.investmentAnalytics.initialInvestment.otherCostDetails[id], 'amount', sum);
      },
    },
  };
</script>
<style scoped>
  #ConsultantFees .detail .card-body {
    padding-top: 0;
    padding-bottom: 8px;
  }
  #ConsultantFees .guideline {
    padding-top: 22px;
    font-size: medium;
    text-align: center;
  }
</style>
