<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：初期投資/付帯費用/調査測量費
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="SurveyCosts">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button id="BtnSurveyCosts" variant="link" v-b-toggle.tabSurveyCosts>
          調査測量費
        </b-button>
      </b-card-header>
      <b-collapse id="tabSurveyCosts" role="tabpanel">
        <b-card-body>
          <b-tabs fill pills v-model="investmentAnalytics.initialInvestment.isDetailSurveyCost">
            <b-tab title="概算入力">
              <b-container>
                <b-row>
                  <b-col cols="2" class="title bold">総額</b-col>
                  <b-col cols="2" class="title ">
                    (税抜)
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="component">
                        <allyText
                          name="SurveyCostsTotal"
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="investmentAnalytics.initialInvestment.estimatedSurveyCost"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold" />
                  <b-col cols="2" class="title">
                    (税込)
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            sumTax(
                              Math.floor(investmentAnalytics.initialInvestment.estimatedSurveyCost)
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="詳細入力">
              <b-container class="detail">
                <b-row>
                  <b-col class="title bold">項目</b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col class="title-center bold">調査測量費</b-col>
                      <b-col cols="5" />
                    </b-row>
                  </b-col>
                  <b-col class="title-center ">詳細</b-col>
                </b-row>
                <b-row>
                  <b-col class="title">地耐力調査</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.surveyCost[0].amount)
                              | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabSurveyCosts1>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabSurveyCosts1" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">調査方法</b-col>
                            <b-col class="ally-select">
                              <allySelect
                                name="surveyCost1"
                                :items="surveyCostItems[0]"
                                v-model="
                                  investmentAnalytics.initialInvestment.surveyCost[0].content
                                "
                              />
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">調査本数</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.surveyCost[0].quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">本</b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '地耐力調査',
                                        investmentAnalytics.initialInvestment.surveyCost[0].content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円/本</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        0,
                                        Math.floor(
                                          investmentAnalytics.initialInvestment.surveyCost[0]
                                            .quantity
                                        ) *
                                          getUnitPrice(
                                            '地耐力調査',
                                            investmentAnalytics.initialInvestment.surveyCost[0]
                                              .content
                                          )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">調査費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.surveyCost[0].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(
                                        investmentAnalytics.initialInvestment.surveyCost[0].quantity
                                      ) *
                                        getUnitPrice(
                                          '地耐力調査',
                                          investmentAnalytics.initialInvestment.surveyCost[0]
                                            .content
                                        ))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">土壌汚染調査</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.surveyCost[1].amount)
                              | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabSurveyCosts2>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabSurveyCosts2" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">調査内容</b-col>
                            <b-col class="ally-select">
                              <allySelect
                                name="surveyCost2"
                                :items="surveyCostItems[1]"
                                v-model="
                                  investmentAnalytics.initialInvestment.surveyCost[1].content
                                "
                              />
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '土壌汚染調査',
                                        investmentAnalytics.initialInvestment.surveyCost[1].content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        1,
                                        getUnitPrice(
                                          '土壌汚染調査',
                                          investmentAnalytics.initialInvestment.surveyCost[1]
                                            .content
                                        )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">調査費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.surveyCost[1].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '土壌汚染調査',
                                        investmentAnalytics.initialInvestment.surveyCost[1].content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">公簿調査</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.surveyCost[2].amount)
                              | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabSurveyCosts3>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabSurveyCosts3" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline" style="padding-top:0;"
                                  >※筆数&nbsp;×&nbsp;2</b-col
                                >
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">筆数</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.surveyCost[2].quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">筆</b-col>
                              </b-row>
                            </b-col>

                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(
                                        investmentAnalytics.initialInvestment.propertyDescription[0]
                                          .quantity
                                      ) *
                                        2)
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">筆</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="getUnitPrice('公簿調査', '有') | currency"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        2,
                                        Math.floor(
                                          investmentAnalytics.initialInvestment.surveyCost[2]
                                            .quantity
                                        ) * getUnitPrice('公簿調査', '有')
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">調査費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.surveyCost[2].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(
                                        investmentAnalytics.initialInvestment.surveyCost[2].quantity
                                      ) *
                                        getUnitPrice('公簿調査', '有'))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">現況測量</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.surveyCost[3].amount)
                              | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabSurveyCosts4>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabSurveyCosts4" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">内容</b-col>
                            <b-col class="ally-select">
                              <allySelect
                                name="surveyCost4"
                                :items="surveyCostItems[3]"
                                v-model="
                                  investmentAnalytics.initialInvestment.surveyCost[3].content
                                "
                              />
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">※地積</b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">面積</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.surveyCost[3].quantity
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    mode="View"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.propertyDescription[0]
                                        .area
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '現況測量',
                                        investmentAnalytics.initialInvestment.surveyCost[3].content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        3,
                                        Math.floor(
                                          (Math.floor(
                                            investmentAnalytics.initialInvestment.surveyCost[3]
                                              .quantity * 100
                                          ) /
                                            100) *
                                            getUnitPrice(
                                              '現況測量',
                                              investmentAnalytics.initialInvestment.surveyCost[3]
                                                .content
                                            )
                                        )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">調査費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.surveyCost[3].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      Math.floor(
                                        (Math.floor(
                                          investmentAnalytics.initialInvestment.surveyCost[3]
                                            .quantity * 100
                                        ) /
                                          100) *
                                          getUnitPrice(
                                            '現況測量',
                                            investmentAnalytics.initialInvestment.surveyCost[3]
                                              .content
                                          )
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">確定測量</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.surveyCost[4].amount)
                              | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabSurveyCosts5>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabSurveyCosts5" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">内容</b-col>
                            <b-col class="ally-select">
                              <allySelect
                                name="surveyCost5"
                                :items="surveyCostItems[4]"
                                v-model="
                                  investmentAnalytics.initialInvestment.surveyCost[4].content
                                "
                              />
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">※地積</b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">面積</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.surveyCost[4].quantity
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    mode="View"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.propertyDescription[0]
                                        .area
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '確定測量',
                                        investmentAnalytics.initialInvestment.surveyCost[4].content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        4,
                                        Math.floor(
                                          (Math.floor(
                                            investmentAnalytics.initialInvestment.surveyCost[4]
                                              .quantity * 100
                                          ) /
                                            100) *
                                            getUnitPrice(
                                              '確定測量',
                                              investmentAnalytics.initialInvestment.surveyCost[4]
                                                .content
                                            )
                                        )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">調査費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.surveyCost[4].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      Math.floor(
                                        (Math.floor(
                                          investmentAnalytics.initialInvestment.surveyCost[4]
                                            .quantity * 100
                                        ) /
                                          100) *
                                          getUnitPrice(
                                            '確定測量',
                                            investmentAnalytics.initialInvestment.surveyCost[4]
                                              .content
                                          )
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">建築基準法適合調査</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.surveyCost[5].amount)
                              | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabSurveyCosts6>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabSurveyCosts6" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">内容</b-col>
                            <b-col cols="8" class="ally-select">
                              <allySelect
                                name="surveyCost6"
                                :items="surveyCostItems[5]"
                                v-model="
                                  investmentAnalytics.initialInvestment.surveyCost[5].content
                                "
                              />
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '建築基準法適合状況調査',
                                        investmentAnalytics.initialInvestment.surveyCost[5].content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        5,
                                        getUnitPrice(
                                          '建築基準法適合状況調査',
                                          investmentAnalytics.initialInvestment.surveyCost[5]
                                            .content
                                        )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">調査費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.surveyCost[5].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '建築基準法適合状況調査',
                                        investmentAnalytics.initialInvestment.surveyCost[5].content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Calculation from './../Calculation';
  import Vue from 'vue';
  export default {
    mixins: [Calculation],
    data: function() {
      return {
        // 調査費
        surveyCostItems: [
          // [0] 地耐力調査
          this.getUnitPricesItems('地耐力調査'),
          // [1] 土壌汚染調査
          this.getUnitPricesItems('土壌汚染調査'),
          // [2] 公簿調査
          this.getUnitPricesItems('公簿調査'),
          // [3] 現況測量
          this.getUnitPricesItems('現況測量'),
          // [4] 確定測量
          this.getUnitPricesItems('確定測量'),
          // [5] 建築基準法適合調査
          this.getUnitPricesItems('建築基準法適合状況調査'),
        ],
      };
    },
    methods: {
      changeGuideline: function(id, sum) {
        Vue.set(this.investmentAnalytics.initialInvestment.surveyCost[id], 'amount', sum);
      },
    },
    watch: {
      // マスタ更新時
      '$store.state.master.unitPrice': {
        handler: function() {
          // 選択項目更新
          this.surveyCostItems = [
            this.getUnitPricesItems('地耐力調査'),
            this.getUnitPricesItems('土壌汚染調査'),
            this.getUnitPricesItems('公簿調査'),
            this.getUnitPricesItems('現況測量'),
            this.getUnitPricesItems('確定測量'),
            this.getUnitPricesItems('建築基準法適合状況調査'),
          ];
        },
        deep: false,
      },
    },
  };
</script>
<style scoped>
  #SurveyCosts .detail .card-body {
    padding-top: 0;
    padding-bottom: 8px;
  }
</style>
