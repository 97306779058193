<!--
作成者：Park Chongmyung
作成日：2021.02.17
画面名：相続税メイン
機能：相続税のメインページ
親子関係：inheritanceTax（相続税）
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <ProperAnalytics
      title="ALLY 相続シミュレーション"
      type="inheritanceTax"
      :inputClass="inheritanceTaxInput"  
    >
      <template #input="{input, commonMode, calcFlg}">
        <Input :value="input" :mode="commonMode" :calcFlg="calcFlg" />
      </template>
      <template #result="{ propertyList, commonInput}">
        <Report :propertyList="propertyList" :commonInput="commonInput" />
      </template>
    </ProperAnalytics>
  </div>
</template>
<script>
  import ProperAnalytics from '@/components/ProperAnalytics/ProperAnalytics';
  import Input from './Input';
  import inheritanceTaxInput from '@/views/InheritanceTax/inputClass';
  import Report from './Report';

  export default {
    components: {
      ProperAnalytics,
      Input,
      Report,
    },
    data() {
      return {
        inheritanceTaxInput: inheritanceTaxInput,
      };
    },
  };
</script>
