<template>
  <g>
    <path
      v-if="!isSpiralStairs"
      data-part="floorBody"
      data-area="object"
      :data-id="obj.id"
      :d="ds"
      stroke="black"
      stroke-width="4"
      fill="transparent"
    />
    <image
      v-else
      data-area="object"
      :data-id="obj.id"
      :href="imageUrl"
      :width="obj.width * 2"
      :height="obj.height * 2"
      preserveAspectRatio="none"
      :transform="`scale(${obj.style.flipHorizontal ? -1 : 1}, ${obj.style.flipVertical ? -1 : 1})`"
      :transform-origin="`${obj.width} ${obj.height}`"
    />
  </g>
</template>
<script>
  import { computed, defineComponent } from '@vue/composition-api';
  import { stairsGenerator } from '../../lib/node/tools/stairsGenerator';
  import { useIconImages } from '../../lib/state/iconImages';
  import { loadImage } from '../../lib/util/util';
  import { Rect } from '../../lib/util/math/rect';

  export default defineComponent({
    props: {
      obj: Object,
    },
    setup(props) {
      // DATA: 螺旋階段かそれ以外かのフラグ
      const isSpiralStairs = props.obj.code === 4301 || props.obj.code === 4302 ? true : false;

      // COMPUTED: 「階段、コの字階段、L字階段」のパス情報を生成
      const ds = computed(() => {
        if (props.obj.vertexes.length < 2 || isSpiralStairs) {
          return '';
        }
        const [d1, d2] = stairsGenerator(
          props.obj.vertexes,
          !props.obj.style.flipHorizontal,
          props.obj.width,
          true
        );
        return d1 + d2;
      });

      const icons = useIconImages(); // DATA: アイコンデータ取得
      const ICON_SCALE = 2 / 18; // DATA: アイコン比率

      // COMPUTED: 螺旋階段のURLを作成
      const imageUrl = computed(() => {
        const data = icons.iconImages.value.find(v => v.url === props.obj.style.imageUrl);
        if (data == null) return '';
        return URL.createObjectURL(data.blob);
      });

      // 螺旋階段の場合
      if (isSpiralStairs) {
        // 螺旋階段の画像を取得
        loadImage(props.obj.style.imageUrl ?? '').then(img => {
          // 画像サイズの書き換え
          props.obj.height = props.obj.width;

          // 座標の書き換え
          props.obj.vertexes = new Rect(
            0,
            0,
            props.obj.width * 2,
            props.obj.height * 2,
          ).toVecs();
        });
      }

      return {
        ds,
        imageUrl,
        isSpiralStairs,
      };
    },
  });
</script>
