var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"EquipmentRenewal"}},[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v(" 設備更新工事 ")]),_c('b-col'),_c('b-col',{staticClass:"component"},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(_vm.investmentAnalytics.initialInvestment.constructionPlan.equipmentUpdateCost),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.constructionPlan, "equipmentUpdateCost", $$v)},expression:"investmentAnalytics.initialInvestment.constructionPlan.equipmentUpdateCost"}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"2"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title",attrs:{"cols":"1"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabEquipmentUpdateCostDetails",modifiers:{"tabEquipmentUpdateCostDetails":true}}],attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabEquipmentUpdateCostDetails","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_vm._l((_vm.itemsCount),function(id){return _c('b-row',{key:("equipmentUpdateCostDetails-" + id)},[_c('b-col',{staticClass:"title bold",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(id == 1 ? '詳細' : '')+" ")]),_c('b-col',{staticClass:"title",attrs:{"cols":"3"}},[(id <= _vm.defaultItems.length)?_c('span',[_vm._v(" "+_vm._s(_vm.defaultItems[id - 1])+" ")]):_c('span',[_c('allyText',{attrs:{"placeholder":"項目名を入力してください"},model:{value:(
                      _vm.investmentAnalytics.initialInvestment.constructionPlan
                        .equipmentUpdateCostDetails[id - 1].content
                    ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.constructionPlan
                        .equipmentUpdateCostDetails[id - 1], "content", $$v)},expression:"\n                      investmentAnalytics.initialInvestment.constructionPlan\n                        .equipmentUpdateCostDetails[id - 1].content\n                    "}})],1)]),_c('b-col',{staticClass:"component",attrs:{"cols":"3"}},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                        _vm.investmentAnalytics.initialInvestment.constructionPlan
                          .equipmentUpdateCostDetails[id - 1].amount
                      ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.constructionPlan
                          .equipmentUpdateCostDetails[id - 1], "amount", $$v)},expression:"\n                        investmentAnalytics.initialInvestment.constructionPlan\n                          .equipmentUpdateCostDetails[id - 1].amount\n                      "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"2"}},[_vm._v("円")])],1)],1),(id > _vm.defaultItems.length)?_c('b-col',{attrs:{"cols":"3"}},[_c('b-button',{attrs:{"variant":"ally"},on:{"click":function($event){return _vm.deleteItem(id - 1)}}},[_c('i',{staticClass:"ti-minus"})])],1):_vm._e()],1)}),_c('b-row',[_c('b-col',{attrs:{"cols":"9"}}),_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{attrs:{"variant":"ally"},on:{"click":_vm.addItem}},[_c('i',{staticClass:"ti-plus"})])],1)],1),_c('b-row',[_c('b-col',{staticClass:"borderline",attrs:{"cols":"9"}})],1),_c('b-row',[_c('b-col',{staticClass:"title bold text-right",attrs:{"cols":"6"}},[_vm._v("合計")]),_c('b-col',{staticClass:"component",attrs:{"cols":"3"}},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.investmentAnalytics.initialInvestment.constructionPlan
                          .equipmentUpdateCost)}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"2"}},[_vm._v("円")])],1)],1)],1)],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }