<template>
  <div id="InvestmentAnalyticsMain">
    <b-navbar
      fixed="top"
      style="background-color:white;box-shadow:none;display:inline-block;"
      :style="naviStyle"
    >
      <div class="ml-3">
        <h4 class="font-weight-bold">ALLY 不動産投資分析</h4>
      </div>
      <div class="col-12">
        <router-link to="/DashBoard" tag="button" class="btn btn-ally mx-2 float-right">
          メインページへもどる
        </router-link>
      </div>
      <div>
        <b-navbar-nav>
          <div>
            <ol>
              <li
                class="btnHeader btn btn-outline-ally"
                v-bind:class="{ active: mode === 'INPUT' }"
                v-on:click="
                  () => {
                    mode = 'INPUT';
                  }
                "
              >
                入力
              </li>
              ▶
              <li
                class="btnHeader btn btn-outline-ally"
                v-bind:class="{ active: mode === 'RESULT' }"
                v-on:click="
                  () => {
                    loadFlag == true ? (mode = 'RESULT') : null;
                  }
                "
              >
                プレビュー
              </li>
              ▶
              <li
                class="btnHeader btn btn-outline-ally"
                v-bind:class="{ active: mode === 'REPORT' }"
                v-on:click="loadFlag == true ? clickReport() : false"
              >
                レポート
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li
                variant="ally"
                @click="inputMasterModal()"
                v-if="groups == 'admin' && mode === 'INPUT'"
                class="btnHeader btn btn-ally"
              >
                規定値<br />変更
              </li>
              <li
                variant="ally"
                @click="clearInputData"
                v-if="mode === 'INPUT'"
                class="btnHeader btn btn-ally"
              >
                新規<br />作成
              </li>
              <li
                variant="ally"
                v-b-modal="'loadModal'"
                v-if="mode === 'INPUT'"
                class="btnHeader btn btn-ally"
              >
                呼び<br />出し
              </li>
              <li
                variant="ally"
                v-b-modal="'saveModal'"
                v-if="mode === 'INPUT'"
                class="btnHeader  btn btn-ally"
              >
                新規<br />保存
              </li>
              <li
                variant="ally"
                @click="overwriteSave"
                :disabled="investmentAnalytics.id == null"
                v-if="mode === 'INPUT'"
                class="btnHeader btn btn-ally"
              >
                上書き<br />保存
              </li>
              <li variant="ally" @click="scrollToTop()" class="btnHeader btn btn-ally">
                上に<br />戻る
              </li>
            </ol>
          </div>
        </b-navbar-nav>
      </div>
    </b-navbar>
    <div class="headerArea" />
    <b-alert
      :show="dismissCountDown"
      dismissible
      fade
      variant="success"
      @dismiss-count-down="countDownChanged"
    >
      {{ alertMessage }}
    </b-alert>
    <div v-if="mode === 'INPUT'">
      <InputMain ref="InputMain" @lastLoadFlag="getLoadFlag" />
    </div>
    <div v-if="mode === 'RESULT'">
      <ResultMain />
    </div>
    <div v-if="mode === 'REPORT'">
      <ReportMain />
    </div>
    <b-modal
      id="loadModal"
      size="xl"
      title="入力データ呼び出し"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-only
      ok-variant="light"
      ok-title="キャンセル"
      no-close-on-backdrop
    >
      <Pagination
        class="grid-margin"
        ref="pagination"
        :headers="headers"
        hideSearchBox
        hideDetail
        targetName="InvestmentAnalytics"
        @loadInput="loadInput"
        @deleteClicked="deleteInput"
      />
    </b-modal>
    <b-modal
      id="saveModal"
      title="新規保存"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="保存"
      @ok="saveInput"
      no-close-on-backdrop
    >
      <ValidationObserver tag="div" slim ref="saveInputCheck" class="row">
        <b-container>
          <b-row>
            <b-col>
              <allyText
                title="タイトル"
                name="inputTitle"
                v-model="investmentAnalytics.inputTitle"
                rules="required"
              />
            </b-col> </b-row
        ></b-container>
      </ValidationObserver>
    </b-modal>
    <b-modal
      id="inputMasterModal"
      size="xl"
      title="規定値変更"
      header-class="ally-modal"
      footer-class="ally-modal"
      ok-only
      ok-variant="light"
      ok-title="キャンセル"
      no-close-on-backdrop
    >
      <inputMaster type="mode == 'inheritabceTax' ? 'inheritanceTax' : 'realEstateSuitability'" />
    </b-modal>
    <allyAlert code="E0001"></allyAlert>
    <allyAlert code="E108"></allyAlert>
    <b-navbar v-if="mode === 'INPUT'">
      <b-navbar-nav class="ml-auto">
        <b-button variant="ally" :disabled="!loadFlag" @click="mode = 'RESULT'">
          プレビュー
        </b-button>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
  import Vue from 'vue';
  import InputMain from './Input/InputMain';
  import ResultMain from './Result/ResultMain';
  import ReportMain from './Report/ReportMain';
  import Pagination from '../../components/Common/PaginationV2';
  import inputMaster from '@/views/Master/EditableView';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getInvestmentAnalytics } from '../../graphql/queries';
  import {
    createInvestmentAnalytics,
    updateInvestmentAnalytics,
    deleteInvestmentAnalytics,
  } from '../../graphql/mutations';
  export default {
    data: function() {
      return {
        mode: 'INPUT',
        // ロードモーダル表示項目
        headers: [
          { key: 'inputDate', label: '作成日' },
          { key: 'inputTitle', label: 'タイトル' },
          { key: 'reportName', label: 'レポート名' },
          { key: 'updatedAt', label: '更新日時', dispState: 'time' },
          {
            key: 'download',
            label: 'ロード',
            button: { label: 'ロード', event: 'loadInput' },
          },
        ],
        // 読込/保存時のメッセージ
        dismissSecs: 5,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        alertMessage: null,
        groups: this.getGroup(),
        loadFlag: false,
        sideFlag: false,
        naviStyle: {
          'margin-left': '305px',
        },
      };
    },
    components: {
      InputMain,
      ResultMain,
      ReportMain,
      Pagination,
      inputMaster,
    },
    created: function() {
      // 入力値初期化
      this.investmentAnalytics.initialize();
    },
    watch: {
      sideFlag() {
        if (this.sideFlag == 'true') {
          Vue.set(this.naviStyle, 'margin-left', '70px');
        } else {
          Vue.set(this.naviStyle, 'margin-left', '305px');
        }
      },
    },
    mounted() {
      //sideBarの表示状態を取得する
      setInterval(() => {
        this.sideFlag = sessionStorage.getItem('sideIconOnly');
      }, 100);
    },
    methods: {
      //マスタデータ編集モーダルを開く
      inputMasterModal() {
        this.$bvModal.show('inputMasterModal');
      },
      //権限確認
      getGroup: function() {
        var userGroup = new Array();
        userGroup = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        if (userGroup.indexOf('systemAdmin') != -1) {
          return 'systemAdmin';
        } else if (userGroup.indexOf('admin') != -1) {
          return 'admin';
        }
      },
      // 上に戻る
      scrollToTop: function() {
        scrollTo(0, 0);
      },
      // 新規作成
      clearInputData: function() {
        if (confirm('入力された内容を破棄し、新規作成しますか？') === false) {
          return;
        }
        // 入力値初期化
        this.investmentAnalytics.initialize();
        if (this.$refs.InputMain != undefined) {
          // 初期投資/工事計画/チェックボックス初期化
          this.$refs.InputMain.$refs.InitialInvestment.$refs.ConstructionPlan.initCheckBoxes();
          // 初期投資/付帯費用/チェックボックス初期化
          this.$refs.InputMain.$refs.InitialInvestment.$refs.IncidentalExpenses.initCheckBoxes();
          // 機能：単年度収支計画
          this.$refs.InputMain.$refs.singleYearPlan?.initialize();
        }
      },
      // 入力データロード
      loadInput: function(e) {
        // 読込実行
        API.graphql(
          graphqlOperation(getInvestmentAnalytics, {
            id: e.id,
          })
        )
          .then(result => {
            // 読込結果を保持
            Vue.set(
              this.investmentAnalytics,
              'inputDate',
              result.data.getInvestmentAnalytics.inputDate
            );
            Vue.set(
              this.investmentAnalytics,
              'reportName',
              result.data.getInvestmentAnalytics.reportName
            );
            Vue.set(
              this.investmentAnalytics,
              'inputTitle',
              result.data.getInvestmentAnalytics.inputTitle
            );
            this.investmentAnalytics.propertyData = null;
            Vue.set(
              this.investmentAnalytics,
              'propertyData',
              result.data.getInvestmentAnalytics.propertyData
            );
            this.investmentAnalytics.ownerName = result.data.getInvestmentAnalytics.ownerName;
            this.investmentAnalytics.ownerId = result.data.getInvestmentAnalytics.ownerId;
            this.investmentAnalytics.staffName = result.data.getInvestmentAnalytics.staffName;
            Vue.set(
              this.investmentAnalytics,
              'existingBuildingCount',
              result.data.getInvestmentAnalytics.existingBuildingCount
            );
            Vue.set(
              this.investmentAnalytics,
              'newBuildingCount',
              result.data.getInvestmentAnalytics.newBuildingCount
            );

            //過去のデータが呼び出せなくなっているので調整
            if (result.data.getInvestmentAnalytics.initialInvestment.tradingPrice.length < 23) {
              let num =
                23 - result.data.getInvestmentAnalytics.initialInvestment.tradingPrice.length;
              for (let i = 0; i < num; i++) {
                result.data.getInvestmentAnalytics.initialInvestment.tradingPrice.push(null);
              }
            }

            Vue.set(
              this.investmentAnalytics,
              'initialInvestment',
              result.data.getInvestmentAnalytics.initialInvestment
            );
            Vue.set(
              this.investmentAnalytics,
              'newBuildingCount',
              result.data.getInvestmentAnalytics.newBuildingCount
            );
            Vue.set(
              this.investmentAnalytics,
              'singleYearPlan',
              result.data.getInvestmentAnalytics.singleYearPlan
            );
            Vue.set(
              this.investmentAnalytics,
              'longTermRepairPlan',
              result.data.getInvestmentAnalytics.longTermRepairPlan
            );
            Vue.set(
              this.investmentAnalytics,
              'salePlan',
              result.data.getInvestmentAnalytics.salePlan
            );
            if (this.investmentAnalytics.initialInvestment.propertyDescription.length < 23) {
              this.investmentAnalytics.initialInvestment.propertyDescription.push({
                location: null,
                address: {
                  address1: null,
                  address2: null,
                  address3: null,
                },
                quantity: null,
                structure: null,
                kind: null,
                purpose: null,
                area: null,
                dateOfConstruction: null,
                price: null,
              });
            }
            if (this.$refs.InputMain != undefined) {
              // 初期投資/工事計画/チェックボックス初期化
              this.$refs.InputMain.$refs.InitialInvestment?.$refs.ConstructionPlan?.initCheckBoxes();
              // 初期投資/付帯費用/チェックボックス初期化
              this.$refs.InputMain.$refs.InitialInvestment?.$refs.IncidentalExpenses?.initCheckBoxes();
              // 機能：単年度収支計画
              this.$refs.InputMain.$refs.SingleYearPlan?.initialize();
            }
            // 入力データID保持
            this.investmentAnalytics.id = e.id;
            // モーダルを閉じる
            this.$bvModal.hide('loadModal');
            // メッセージ出力
            this.showAlert(
              '呼び出しました。（タイトル：' + this.investmentAnalytics.inputTitle + '）'
            );
          })
          .catch(e => {
            // 例外発生時
            console.error('[投資分析]読込失敗', e);
            this.showError('E0001', e); // システムエラー
          });
      },
      // 保存データ削除
      deleteInput: function(e) {
        API.graphql(
          graphqlOperation(deleteInvestmentAnalytics, {
            input: {
              id: e.id,
            },
          })
        ).then(() => this.$refs.pagination.listItems());
      },
      // 新規保存
      saveInput: async function() {
        // 入力チェック
        const isValid = await this.$refs.saveInputCheck.validate();
        if (!isValid) {
          this.$bvModal.show('E108');
          return false;
        }
        // 現ID破棄
        this.investmentAnalytics.id = null;
        // 保存実行
        await API.graphql(
          graphqlOperation(createInvestmentAnalytics, {
            input: this.investmentAnalytics,
          })
        )
          .catch(e => {
            // 例外発生時
            console.error('[投資分析]保存失敗', e);
            this.showError('E0001', e); // システムエラー
          })
          .then(result => {
            // 新ID保持
            this.investmentAnalytics.id = result.data.createInvestmentAnalytics.id;
            // メッセージ出力
            this.scrollToTop();
            this.showAlert(
              '保存しました。（タイトル：' + this.investmentAnalytics.inputTitle + '）'
            );
          });
      },
      // 上書き保存
      overwriteSave: async function() {
        // 保存実行
        await API.graphql(
          graphqlOperation(updateInvestmentAnalytics, {
            input: this.investmentAnalytics,
          })
        )
          .catch(e => {
            // 例外発生時
            console.error('[投資分析]保存失敗');
            this.showError('E0001', e); // システムエラー
          })
          .then(() => {
            // メッセージ出力
            this.scrollToTop();
            this.showAlert(
              '上書き保存しました。（タイトル：' + this.investmentAnalytics.inputTitle + '）'
            );
          });
      },
      countDownChanged: function(dismissCountDown) {
        this.dismissCountDown = dismissCountDown;
      },
      showAlert: function(message) {
        this.alertMessage = message;
        this.dismissCountDown = this.dismissSecs;
      },
      clickReport() {
        const isValid =
          this.investmentAnalytics.inputDate != null &&
          this.investmentAnalytics.ownerName != null &&
          this.investmentAnalytics.reportName != null;
        if (isValid === false) {
          this.showError('E108');
          return;
        }
        this.mode = 'REPORT';
      },
      getLoadFlag(val) {
        this.loadFlag = val;
      },
    },
  };
</script>
<style scoped>
  #InvestmentAnalyticsMain ul.navbar-nav.ml-auto > button,
  #InvestmentAnalyticsMain ul.navbar-nav.ml-auto > .dropdown {
    margin-right: 5px;
  }
  #InvestmentAnalyticsMain > .alert-dismissible {
    font-size: medium;
  }
</style>
