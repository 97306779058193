// @ts-check

import { PvAbstractHandle } from './abstractHandleNode';

export class PvCircleHandle extends PvAbstractHandle {
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'circleHandle';
  /** @type {string} */
  targetId;
  constructor() {
    super();
  }
}
