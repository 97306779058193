<template>
  <CommonLayout title="ご留意ください">
    <template v-slot>
      <div
        style="
          font-size: 100px;
          padding: 0 120px;
        "
      >
        <br />
        <br />
        本レポートは一般的な事例を基にした試算であり、個別具体的な情報をお示しするものではございません。
        <br />
        あくまでも参考情報としてお取り扱いいただき、個別具体的な計算については必ず税理士等の専門家に<br />相談されてください。
        <br /><br /><br />
        <div style="font-size:100px; ">
          ・本レポートの試算結果については確実に保証されるものではありません。<br />
          ・本レポートのシミュレーションにより得られた数値を起因として、ご利用者または第三者が損害を被った場合<br />&nbsp;&thinsp;であっても、
          当社は一切の責任を負いかねます。
        </div>
      </div>
      <div
        style="
                font-size: 40mm;
                text-align: right;
                margin-top: 8%;
            "
      ></div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';

  export default {
    props: {},
    components: {
      CommonLayout,
    },
    data() {
      return {};
    },
    computed: {},
  };
</script>
