<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：初期投資/付帯費用/保険料負担
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="InsuranceFees">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button id="btnInsuranceFees" variant="link" v-b-toggle.tabInsuranceFees>
          保険料負担
        </b-button>
      </b-card-header>
      <b-collapse id="tabInsuranceFees" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col class="title bold">
                項目
              </b-col>
              <b-col class="title-center bold">
                <b-row>
                  <b-col class="title-center">
                    保険料
                  </b-col>
                  <b-col cols="2" />
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col cols="9" class="title-center">
                    ※目安
                  </b-col>
                  <button
                    class="col-3 btn-ally btn-reflection"
                    style="white-space: nowrap; padding: 0px;"
                    @click="changeGuideline(0, fireInsuranceFee(0))"
                  >
                    反映
                  </button>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="pt-1">
              <b-col class="title">
                <b-row>
                  <b-col class="title ">火災保険料</b-col>
                  <b-col class="title ">既存建物分<br />(5年分)</b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col class="component">
                    <allyText
                      name="insuranceFees0"
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="investmentAnalytics.initialInvestment.insuranceFees[0]"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col class="component">
                    <b-form-input
                      disabled
                      class="currency"
                      :value="fireInsuranceFee(0) | currency"
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="row-cols-1 mt-1">
              <b-row class="mx-0">
                <button
                  class="col-1 btn-ally btn-reflection mr-0 ml-auto"
                  style="white-space: nowrap; padding: 0px;"
                  @click="changeGuideline(1, fireInsuranceFee(1))"
                >
                  反映
                </button>
              </b-row>
              <b-row class="mx-0 pt-1">
                <b-col class="title">
                  <b-row>
                    <b-col class="title" />
                    <b-col class="title">新築建物分<br />(5年分)</b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="component">
                      <allyText
                        name="insuranceFees1"
                        type="number"
                        class="number"
                        :separation="true"
                        v-model="investmentAnalytics.initialInvestment.insuranceFees[1]"
                        roundType="down"
                      />
                    </b-col>
                    <b-col cols="1" class="unit">円</b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="component">
                      <b-form-input
                        disabled
                        class="currency"
                        :value="fireInsuranceFee(1) | currency"
                      />
                    </b-col>
                    <b-col cols="1" class="unit">円</b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-row>
            <b-row>
              <b-col class="title ">施設賠償責任保険料&nbsp;(1年分)</b-col>
              <b-col>
                <b-row>
                  <b-col class="component">
                    <allyText
                      name="insuranceFees2"
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="investmentAnalytics.initialInvestment.insuranceFees[2]"
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col />
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Calculation from './../Calculation';
  import Vue from 'vue';
  export default {
    mixins: [Calculation],
    data: function() {
      return {};
    },
    methods: {
      changeGuideline: function(id, sum) {
        Vue.set(this.investmentAnalytics.initialInvestment.insuranceFees, id, sum);
      },
    },
  };
</script>
