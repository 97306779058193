<template>
  <CommonLayout title="相続税計算">
    <ReportSubTitle subTitle="各相続人の詳細" />
    <div style="padding: 150px 100px;">
      <table style="width: 100%;" id="calcDetail" v-if="commonInput.family.length > 0">
        <thead>
          <tr>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">氏名</th>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">顧客との関係</th>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">納付税額(円)</th>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">
              実質相続金額(円)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in familyInheritanceInfo" :key="row.Id" style="font-size: 50px;">
            <td :style="bodyStyle">{{ row.name }}</td>
            <td :style="bodyStyle">{{ row.familyType }}</td>
            <td :style="[bodyStyle, { 'text-align': 'right', padding: '0 15px' }]">
              {{ row.payInheritanceTaxPrice > 0 ? row.payInheritanceTaxPrice.toLocaleString() : 0 }}
              円
            </td>
            <td :style="[bodyStyle, { 'text-align': 'right', padding: '0 15px' }]">
              {{
                row.inheritanceGetPrice - row.payInheritanceTaxPrice > 0
                  ? (row.inheritanceGetPrice - row.payInheritanceTaxPrice).toLocaleString()
                  : 0
              }}
              円
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import ReportSubTitle from '@/components/ExportReport/ReportSubTitle';
  import { headerStyle, subHeaderStyle, bodyStyle } from './style';
  export default {
    props: {
      commonInput: {
        type: Object,
        required: true,
      },
      familyInheritanceInfo: {
        type: Array,
        required: true,
      },
    },
    data: function() {
      return {
        listPage: [],
        headerStyle,
        subHeaderStyle,
        bodyStyle,
      };
    },
    components: {
      CommonLayout,
      ReportSubTitle,
    },
  };
</script>
