<template>
  <div id="LicenseCancel">
    <b-overlay :show="loading" opacity="0.25">
      ライセンス解約
      <b-container>
        <!-- 上部ボタン -->
        <b-row>
          <b-col>
            <b-navbar>
              <b-navbar-nav class="ml-auto">
                <router-link to="/License/Main" tag="button" class="btn btn-ally">
                  戻る
                </router-link>
              </b-navbar-nav>
            </b-navbar>
          </b-col>
        </b-row>
        <!-- ライセンス一覧 -->
        <b-row>
          <b-col>
            <b-table
              thead-tr-class="serch-data-table-header"
              tbody-tr-class="serch-data-table-body"
              striped
              bordered
              :items="licenseItems"
              :fields="fields"
              show-empty
            >
              <!-- カラム設定 -->
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.width }"
                />
              </template>
              <!-- 空の場合 -->
              <template v-slot:empty>
                <b-container v-if="!loading">
                  <b-row class="text-center">
                    <b-col>
                      <svg
                        class="bi bi-exclamation-diamond-fill"
                        width="8em"
                        height="8em"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                        />
                      </svg>
                      <b-alert id="alert" show>
                        ライセンスがありません
                      </b-alert>
                    </b-col>
                  </b-row>
                </b-container>
                <b-container v-else />
              </template>
              <!-- 数量表示 -->
              <template v-slot:cell(quantity)="row">
                {{ row.value | currency }}
              </template>
              <!-- 自動更新表示 -->
              <template v-slot:cell(isRenewAutomaticaly)="row">
                <b-button
                  variant="ally"
                  @click="confirmCancel(row.item)"
                  size="sm"
                  v-if="row.item.next >= 0"
                >
                  解約
                </b-button>
                <span v-else>
                  済
                </span>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
      <!-- ロード中 -->
      <template #overlay>
        <Load />
      </template>
    </b-overlay>
    <!-- モーダル -->
    <b-modal
      id="ConfirmModal"
      title="ライセンス解約"
      size="lg"
      header-class="ally-modal"
      body-class="confirm-modal"
      footer-class="ally-modal"
      centered
      cancel-variant="light"
      cancel-title="いいえ"
      ok-variant="light"
      ok-title="はい"
      @ok="cancelLicense"
    >
      <b-overlay :show="loadingModal" opacity="0.25">
        【{{ selectedItemName }}】 を解約します。よろしいですか？<br />
        <span class="normal"> ※{{ selectedItemDate }} まではご利用することができます。 </span>

        <ValidationObserver tag="div" slim ref="cancelValidation">
          <b-container v-if="selectedItemQuantity > 1">
            <b-row class="justify-content-md-center">
              <b-col cols="4">
                <allyText
                  title="解約するライセンス数"
                  type="number"
                  class="normal"
                  halfWidth
                  min="1"
                  :max="Number(selectedItemQuantity).toString()"
                  :mode="selectedItemQuantity == 1 ? 'View' : 'Edit'"
                  :rules="'required|numeric|between:1,' + selectedItemQuantity"
                  v-model="canselQuantity"
                  hideBadge
                />
              </b-col>
              <b-col cols="4">
                <allyText
                  title="引き続き使用するライセンス数"
                  type="number"
                  class="normal"
                  mode="View"
                  halfWidth
                  v-model="continuousQuantity"
                  hideBadge
                />
              </b-col>
            </b-row>
          </b-container>
        </ValidationObserver>
        <!-- ロード中 -->
        <template #overlay>
          <Load margin="0" />
        </template>
      </b-overlay>
    </b-modal>
    <!-- メッセージ -->
    <allyAlert code="E0001" />
  </div>
</template>

<!-- スクリプト -->
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { cancelLicense } from '@/graphql/mutations';
  import Load from '../../components/Common/Load';
  import Common from './js/Common';
  API.configure(awsconfig);

  // ライセンス一覧のヘッダ
  const headers = [
    {
      key: 'name',
      label: 'ライセンス名称',
    },
    {
      key: 'quantity',
      label: '数量',
      class: 'text-center',
    },
    {
      key: 'initialPurchaseDate',
      label: '利用開始日',
      formatter: v => {
        return v.replaceAll('-', '/');
      },
    },
    {
      key: 'endDate',
      label: '次回更新日',
      formatter: v => {
        return v.replaceAll('-', '/');
      },
    },
    {
      key: 'isRenewAutomaticaly',
      label: '解約',
      width: '15%',
      tdClass: 'renew',
    },
  ];
  export default {
    components: {
      Load,
    },
    mixins: [Common],

    data: function() {
      return {
        fields: headers, // ライセンス一覧のヘッダ
        isRegisteredCard: false, // カード登録有無
        master: null, // マスタ
        company: null, // 会社情報
        licenseItems: [], // ライセンス
        loading: true, // ロード中
        loadingModal: false, // ロード中（モーダル）
        selectedItem: null, // 選択項目
        canselQuantity: null, // 解約ライセンス数
      };
    },

    mounted: async function() {
      // マスタ取得
      this.master = await this.loadMaster();

      // ログインユーザーの会社IDを取得
      const companyId = this.getCompanyId();

      // 会社情報を取得
      this.company = await this.getCompany(companyId);

      // ライセンス読込
      await this.loadLicense();
      this.loading = false;
    },

    methods: {
      // ライセンス読込
      loadLicense: async function() {
        if (this.company.license == null) {
          return;
        }
        // 現在保有ライセンス取得
        const indexes = this.company.license
          .map((v, i) => {
            return v.startDate <= this.today && this.today <= v.endDate && v.isValid ? i : -1;
          })
          .filter(v => {
            return v >= 0;
          });
        for (let index of indexes) {
          const license = this.company.license[index];

          // 単価取得
          const price = this.getUnitPrice(license);

          // 更新情報検索
          let next = -1;
          let billingDate = null;
          if (license.isRenewAutomaticaly) {
            next = this.company.license.findIndex(value => {
              return value.id == license.id && value.startDate == this.getTomorrow(license.endDate);
            });
            if (next >= 0) {
              const nextLicense = this.company.license[next];
              if (this.company.payment) {
                if (this.company.payment[nextLicense.paymentId] != undefined) {
                  billingDate = this.company.payment[nextLicense.paymentId].billingDate;
                }
              }
            }
          }

          this.licenseItems.push({
            id: license.id,
            name: this.getName(license),
            period: license.period,
            quantity: license.quantity,
            amount: license.amount,
            startDate: license.startDate,
            endDate: license.endDate,
            isRenewAutomaticaly: license.isRenewAutomaticaly,
            uniPrice: price ? price.unitPrice + price.tax : null,
            next: next,
            billingDate: billingDate,
            initialPurchaseDate: license.initialPurchaseDate,
          });
        }

        // ライセンスと自動更新で集計
        const items = this.licenseItems.reduce((result, current) => {
          const element = result.find(
            p => p.id == current.id && p.isRenewAutomaticaly == current.isRenewAutomaticaly
          );
          if (element) {
            element.amount += current.amount;
            element.quantity += current.quantity;
            if (element.startDate > current.startDate) {
              element.startDate = current.startDate;
            }
          } else {
            result.push(current);
          }
          return result;
        }, []);
        this.licenseItems = items;
      },

      // 解約確認
      confirmCancel: async function(item) {
        // モーダル表示
        this.selectedItem = item;
        this.canselQuantity = item.quantity;
        this.$bvModal.show('ConfirmModal');
      },

      // ライセンス解約
      cancelLicense: async function(event) {
        event.preventDefault();

        // バリデーションチェック
        const isValid = await this.$refs.cancelValidation.validate();
        if (!isValid) {
          return;
        }

        const item = this.selectedItem;
        this.loadingModal = true;

        try {
          // 解約実施(Lambda)

          await API.graphql(
            graphqlOperation(cancelLicense, {
              date: this.today, // 処理日
              companyId: this.company.id, // ログインユーザーの会社ID
              licenseId: item.id, // ライセンスID
              quantity: this.canselQuantity, // 解約するライセンス数
            })
          );

          // 解約完了
          const msg = this.selectedItemName + ' を解約しました。';
          this.$bvModal.msgBoxOk(msg, {
            title: 'ライセンス解約',
            headerClass: 'ally-modal',
            footerClass: 'ally-modal',
            centered: true,
          });

          // 会社情報再取得
          this.company = await this.getCompany(this.company.id);

          // ライセンス再読込
          this.licenseItems = [];
          await this.loadLicense();
        } catch (e) {
          this.showError('E0001', e);
        } finally {
          this.loadingModal = false;
          this.$bvModal.hide('ConfirmModal');
        }
      },
      // ライセンス名取得
      getName: function(license) {
        return this.master?.find(m => {
          return m.id == license.id;
        })?.name;
      },
      // ライセンス単価取得
      getUnitPrice: function(license) {
        // ライセンスマスタ検索
        const m = this.master.find(m => {
          return m.id == license.id;
        });

        // 該当期間の価格取得
        let prices = m?.prices?.find(p => {
          return p.period.quantity == 1 && p.period.unit == 'month';
        });
        return prices;
      },
    },
    computed: {
      selectedItemName: function() {
        if (this.selectedItem) {
          return this.selectedItem.name;
        } else {
          return null;
        }
      },
      selectedItemDate: function() {
        if (this.selectedItem) {
          const ymd = this.selectedItem.endDate.split('-');
          return ymd[0] + '年' + ymd[1] + '月' + ymd[2] + '日';
        } else {
          return null;
        }
      },
      // 保持数量
      selectedItemQuantity: function() {
        if (this.selectedItem) {
          return this.selectedItem.quantity;
        } else {
          return null;
        }
      },
      // 継続数量
      continuousQuantity: function() {
        if (this.canselQuantity < 0 || this.selectedItemQuantity < this.canselQuantity) {
          return null;
        } else {
          return this.selectedItemQuantity - this.canselQuantity;
        }
      },
    },
  };
</script>

<!-- スタイル -->
<style scoped>
  #LicenseCancel >>> nav {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }
  #LicenseCancel >>> nav > ul > button {
    margin-left: 0.5rem;
  }
  #alert {
    background-color: transparent;
    border: none;
  }
  #LicenseCancel >>> .checkbox-column {
    padding: 0;
  }
  #LicenseCancel >>> .quantity-input {
    padding-top: 0;
    padding-bottom: 0;
  }
  #LicenseCancel >>> .custom-control-input:checked ~ .custom-control-label::before {
    background: var(--colorTheme);
    border-color: var(--colorTheme);
  }
  #LicenseCancel >>> .number .form-group {
    margin: 0;
  }
  #LicenseCancel >>> .number .form-group input[type='number'] {
    height: 1.5rem;
    padding-left: 0;
    padding-right: 0.5rem;
    text-align: right;
  }
  #LicenseCancel >>> .row-center {
    display: grid;
    text-align: center;
    align-items: center;
    padding: 0;
  }
  #LicenseCancel >>> .renew {
    display: table-cell;
    align-items: center;
  }
  #LicenseCancel >>> .container {
    max-width: 100%;
  }
</style>
<style>
  #ConfirmModal .confirm-modal {
    font-size: x-large;
    font-weight: bold;
    color: red;
  }
  #ConfirmModal .confirm-modal .normal {
    font-size: large;
    font-weight: normal;
    color: black;
    padding-left: 1rem;
  }
  #ConfirmModal .confirm-modal .normal label {
    font-weight: normal;
  }
  #ConfirmModal input[type='number'] {
    text-align: right;
  }
</style>
