// @ts-check

import { PvObject } from './pvObject';

export class PvAbstractHandle extends PvObject {
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'abstractHandle';
  /** @type {string} */
  targetId;

  constructor() {
    super();
  }

  /**
   * @abstract
   * @param {PvObject} obj
   * @returns {PvObject[]}
   */
  static fromTargetObject(obj) {
    obj;
    throw new Error('not implemented');
  }
}
