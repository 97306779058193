<!--
作成者：Nakatsuka Daisuke
作成日：2020.10.21
画面名：物件情報一覧
機能：物件情報一覧
親子関係：なし
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <div class="row grid-margin">
      <div class="col-12"></div>
      <div v-if="!imageCountFlag">
        <b style="color: red">※登録数が上限に達しました。他の画像を削除してご利用ください。</b>
      </div>
    </div>
    <SimulatorPagination
      ref="pagination"
      :headers="headers"
      targetName="PhotoSimulator"
      :propertyId="propertyId"
      :roomId="roomId"
      :isLicensedArSimulator="isLicensedArSimulator"
      @imageCountCheck="e => imageCountCheck(e)"
      @simulatorClicked="e => simulatorProperty(e)"
      @detailClicked="e => detailProperty(e)"
      @deleteClicked="(e, f) => deleteProperty(e, f)"
      @nameButton="(e, f) => nameButton(e, f)"
    ></SimulatorPagination>

    <!-- 削除処理時、暗転 -->
    <div id="fadeLayer" ref="fadeLayer"></div>

    <b-modal
      id="imageNameChange"
      title="名称変更"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="変更"
      :ok-disabled="changeModal.disabled"
      @ok="nameChangeSave"
    >
      <ValidationObserver ref="changeModal">
        <allyText title="名称変更" v-model="changeModal.name" rules="required|max:15" />
      </ValidationObserver>
    </b-modal>
    <allyAlert :code="'E101'" ref="errorAlert"></allyAlert>
  </div>
</template>
<script>
  import awsconfig from '../../../aws-exports';
  import { API } from 'aws-amplify';

  //graphQL
  import {
    updateProperty,
    deletePhotoSimulator,
    updatePhotoSimulator,
  } from '../../../graphql/mutations';
  import { getProperty, getPhotoSimulator } from '../../../graphql/queries';

  //コンポーネント
  import SimulatorPagination from './SimulatorPagination';

  API.configure(awsconfig);

  export default {
    props: ['commonValue', 'isLicensedArSimulator'],
    data: function() {
      return {
        propertyId: null,
        companys: {
          body: [],
          title: [],
        },
        isMounted: false,
        headers: [
          {
            key: 'simInfo.baseImageDisplayName',
            label: '画像名',
            button: { label: 'ロード', event: 'nameButton' },
          },
          { key: 'createdAt', label: '登録日時', dateTime: 'true' },
          { key: 'updatedAt', label: '更新日時', dateTime: 'true' },
        ],
        imageCountFlag: true,
        maxImage: 20, //保存MAX件数
        changeModal: {
          name: '',
          baseImageFileName: '',
          propertyInfo: null,
          disabled: false,
        },
        nameChangeSelectData: null,
        roomId: null,
      };
    },
    watch: {
      changeModal: {
        deep: true,
        handler() {
          if (this.$refs.changeModal == null) {
            this.changeModal.disabled = true;
            return;
          }
          this.$refs.changeModal.validate().then(async isValid => {
            this.changeModal.disabled = !isValid;
          });
        },
      },
    },
    mounted: async function() {
      this.propertyId = this.$route.params.id;
      this.roomId = this.$route.params.roomId;
    },
    methods: {
      deleteProperty: async function(body) {
        try {
          await this.setSimulationLog(body, 'delete');
          await this.$refs.pagination.initItems();
          let deleteData = {
            id: body.id,
          };
          //データを削除する（更新処理）
          await API.graphql({
            query: deletePhotoSimulator,
            variables: { input: deleteData },
          });
          this.$emit('logReload');
          await this.$refs.pagination.loadItems();
        } catch (e) {
          console.error(e);
          this.$bvModal.show('E101');
          this.$refs.pagination.listItems();
        }
      },
      simulatorProperty: function(body) {
        this.$router.push({
          path:
            '/Property/' +
            this.propertyId +
            '/WallPaperSimulator/Simulate/' +
            body.baseImageFileName,
        });
      },
      /**
       * 物件の詳細情報を表示する
       */
      detailProperty: function(body) {
        if (this.roomId == null) {
          this.$router.push({
            path:
              '/Property/' +
              this.propertyId +
              '/WallPaperSimulator/Edit/' +
              body.simInfo.baseImageFileName,
          });
        } else {
          let link =
            '/Property/' +
            this.propertyId +
            '/WallPaperSimulator/Edit/' +
            body.simInfo.baseImageFileName +
            '?room=' +
            this.roomId;
          if (this.$route.query.propertyList == 'true') link += '&propertyList=true';
          this.$router.push({
            path: link,
          });
        }
      },
      /**
       * シミュレーター名称変更モーダルを出す
       */
      nameButton: function(event) {
        this.nameChangeSelectData = event;
        this.changeModal.name = event.simInfo.baseImageDisplayName;
        this.changeModal.baseImageFileName = event.simInfo.baseImageFileName;
        this.$bvModal.show('imageNameChange');
      },
      /**
       * モーダルで入力した名称に変更する
       */
      nameChangeSave: async function() {
        this.$refs.changeModal.validate().then(async isValid => {
          if (isValid == false) return;
          try {
            //一覧情報をすべて削除する
            await this.$refs.pagination.initItems();

            //ログを設定する
            this.nameChangeSelectData.simInfo.baseImageDisplayName = this.changeModal.name;
            await this.setSimulationLog(this.nameChangeSelectData, 'edit');

            //シミュレーション情報を取得します
            let getPhoto = await API.graphql({
              query: getPhotoSimulator,
              variables: { id: this.nameChangeSelectData.id },
            });
            let simInfo = getPhoto.data.getPhotoSimulator.simInfo;
            simInfo.baseImageDisplayName = this.changeModal.name;

            var updloadData = {
              company: await this.getCompanyId(),
              id: getPhoto.data.getPhotoSimulator.id,
              simInfo: simInfo,
            };

            //更新処理
            await API.graphql({
              query: updatePhotoSimulator,
              variables: { input: updloadData },
            });

            //一覧情報を再設定する
            await this.$refs.pagination.loadItems();
            this.$emit('logReload');
          } catch (e) {
            console.error(e);
            this.$bvModal.show('E101');
            await this.$refs.pagination.loadItems();
          }
        });
      },
      /**
       * 履歴を作成する
       */
      setSimulationLog: async function(body, status) {
        let updloadData = {
          company: body.company,
          id: body.propertyId,
        };

        //データを削除する（更新処理）
        let getResult = await API.graphql({
          query: getProperty,
          variables: { id: body.propertyId },
        });

        let name =
          this.$store.state.user.attributes.family_name +
          ' ' +
          this.$store.state.user.attributes.given_name;

        let simulatorLog = {
          updateDate: new Date(),
          simId: body.id,
          fileUrlName: body.simInfo.baseImageFileName,
          fileName: body.simInfo.baseImageDisplayName,
          action: status,
          userName: name,
          userMail: this.$store.state.user.attributes.email,
        };

        if (this.roomId == null) {
          updloadData.simulationLog = getResult.data.getProperty.simulationLog;

          //削除ログを設定する
          updloadData.simulationLog.push(simulatorLog);
          if (updloadData.simulationLog.length > 100) {
            updloadData.simulationLog.shift();
          }
        } else {
          //RoomIDがあるときの処理
          updloadData.roomInfo = getResult.data.getProperty.roomInfo;
          let roomInfo = updloadData.roomInfo.find(val => val.id == this.roomId);
          if (roomInfo.simulationLog == null) roomInfo.simulationLog = [];
          roomInfo.simulationLog.push(simulatorLog);
          if (roomInfo.simulationLog.length > 100) {
            roomInfo.simulationLog.shift();
          }
        }

        try {
          //データを削除する（更新処理）
          await API.graphql({
            query: updateProperty,
            variables: { input: updloadData },
          });
        } catch (e) {
          console.log(e);
        }
      },
      imageCountCheck: function(count) {
        //５件超えたとき、追加ボタンを表示しない
        this.imageCountFlag = true;
        if (count >= this.maxImage) {
          this.imageCountFlag = false;
        }
      },
      //会社情報を取得する。
      getCompanyId: function() {
        //アカウントから会社ID取得
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },
      //Simulator一覧情報を再取得する
      listReload: async function() {
        await this.$refs.pagination.initItems();
        await this.$refs.pagination.loadItems();
        await this.$refs.pagination.getImagesData();
      },
    },
    // ページング処理コンポーネント呼び出し
    components: {
      SimulatorPagination,
    },
  };
</script>

<style>
  body {
    margin: 0;
    padding: 0;
  }

  #fadeLayer {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;

    background-color: #000000;
    opacity: 0.5;
    visibility: hidden;
    z-index: 2;
  }
</style>
