//路線価の計算
const multipleFloorAreaRatioCalc = async function(routePriceResult, input) {
  try {
    //リターン値の格納
    var result = {
      text: '容積率の異なる2以上の地域にわたる宅地の計算',
      resultPrice: Math.floor(
        routePriceResult.resultPrice * (1 - input.multipleFloorAreaRatioDeductionRate)
      ),
      resultPriceType: 'K',
    };

    console.log('容積率の異なる2以上の地域にわたる宅地の計算完了 : ', result);

    return result;
  } catch (e) {
    console.log('Error : ', e);
    throw new Error(e);
  }
};

export { multipleFloorAreaRatioCalc };
