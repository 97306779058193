<!--
作成者：honma@com-bo.co.jp
作成日：2021/11/02
画面名：必須／任意表示
機能：必須または任意を表示する。
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div :id="id" v-if="mode != 'View'" style="min-width:3rem;display:flex;align-items:end;">
    <span class="required" v-if="rules && rules.includes('aorbrequired')">
      <b>※どちらか必須</b>
    </span>
    <span class="required" v-else-if="rules && rules.includes('required')">
      <b>※必須</b>
    </span>
  </div>
</template>
<script>
  export default {
    props: {
      id: String,
      mode: String,
      rules: String,
    },
  };
</script>
<style scoped>
  .required {
    min-width: 3rem;
    padding-left: 0.5rem;
    font-size: small;
    color: red;
  }
</style>
