<template>
  <div
    style="
            font-size: 150px;
            padding-left: 50px;
            border-bottom: 10px solid #1d1d63;
            font-weight: bold;
            width: 100%;
        "
  >
    {{ subTitle }}
  </div>
</template>

<script>
  export default {
    props: {
      subTitle: {
        type: String,
        required: true,
      },
    },
  };
</script>
