<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：初期投資/付帯費用/支払手数料
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="PaymentFees">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button id="BtnPaymentFees" variant="link" v-b-toggle.tabPaymentFees>
          支払手数料
        </b-button>
      </b-card-header>
      <b-collapse id="tabPaymentFees" role="tabpanel">
        <b-card-body>
          <b-tabs
            fill
            pills
            v-model="investmentAnalytics.initialInvestment.paymentFees.isDetail"
            @input="changeTab"
          >
            <b-tab title="概算入力">
              <b-container>
                <b-row>
                  <b-col cols="2" class="title bold">総額</b-col>
                  <b-col cols="2" class="title">
                    (税抜)
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <allyText
                          name="PaymentFeesTotal"
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.paymentFees.estimatedPaymentFee
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold" />
                  <b-col cols="2" class="title">
                    (税込)
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            sumTax(
                              Math.floor(
                                investmentAnalytics.initialInvestment.paymentFees
                                  .estimatedPaymentFee
                              )
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="詳細入力">
              <b-container class="detail">
                <b-row>
                  <b-col class="title bold">《 司法書士 》</b-col>
                </b-row>
                <br />
                <b-row>
                  <b-col class="title bold">
                    項目
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="title-center bold">
                        件数
                      </b-col>
                      <b-col cols="1" />
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="title-center bold">
                        単価
                      </b-col>
                      <b-col cols="1" />
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="title-center bold">
                        金額
                      </b-col>
                      <b-col cols="1" />
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="title-center" style="padding:0;">
                    変更
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    所有権移転登記
                  </b-col>
                  <b-col>
                    <b-col />
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="getUnitPrice('支払手数料', '所有権移転登記') | currency"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <!--計算値-->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="registrationTaxTotal(18) | currency"
                          v-if="!investmentAnalytics.initialInvestment.paymentFees.isChanged[18]"
                        />
                        <!--入力値-->
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="investmentAnalytics.initialInvestment.paymentFees.amount[18]"
                          roundType="down"
                          v-else
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="checkbox">
                    <b-form-checkbox
                      id="paymentFees_18"
                      v-model="investmentAnalytics.initialInvestment.paymentFees.isChanged[18]"
                      name="paymentFees_18"
                      @change="changeCheckBox(18)"
                      size="lg"
                      style="padding-left:2em"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="title">
                    抵当権設定登記
                  </b-col>
                  <b-col />
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="getUnitPrice('支払手数料', '抵当権設定登記') | currency"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <!--計算値-->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="registrationTaxTotal(16) | currency"
                          v-if="!investmentAnalytics.initialInvestment.paymentFees.isChanged[16]"
                        />
                        <!--入力値-->
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="investmentAnalytics.initialInvestment.paymentFees.amount[16]"
                          roundType="down"
                          v-else
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="checkbox">
                    <b-form-checkbox
                      id="paymentFees_16"
                      v-model="investmentAnalytics.initialInvestment.paymentFees.isChanged[16]"
                      name="paymentFees_16"
                      @change="changeCheckBox(16)"
                      size="lg"
                      style="padding-left:2em"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    借地権設定登記
                  </b-col>
                  <b-col>
                    <b-col />
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="getUnitPrice('支払手数料', '借地権設定登記') | currency"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <!--計算値-->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="registrationTaxTotal(17) | currency"
                          v-if="!investmentAnalytics.initialInvestment.paymentFees.isChanged[17]"
                        />
                        <!--入力値-->
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="investmentAnalytics.initialInvestment.paymentFees.amount[17]"
                          roundType="down"
                          v-else
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="checkbox">
                    <b-form-checkbox
                      id="paymentFees_17"
                      v-model="investmentAnalytics.initialInvestment.paymentFees.isChanged[17]"
                      name="paymentFees_17"
                      @change="changeCheckBox(17)"
                      size="lg"
                      style="padding-left:2em"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    登記記録調査
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="
                            investmentAnalytics.initialInvestment.paymentFees.numberOfCases[0]
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        件
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="getUnitPrice('支払手数料', '登記記録調査') | currency"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <!--計算値-->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="paymentFeesGuideline(0) | currency"
                          v-if="!investmentAnalytics.initialInvestment.paymentFees.isChanged[0]"
                        />
                        <!--入力値-->
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="investmentAnalytics.initialInvestment.paymentFees.amount[0]"
                          roundType="down"
                          v-else
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="checkbox">
                    <b-form-checkbox
                      id="paymentFees_0"
                      v-model="investmentAnalytics.initialInvestment.paymentFees.isChanged[0]"
                      name="paymentFees_0"
                      @change="changeCheckBox(0)"
                      size="lg"
                      style="padding-left:2em"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    議事録作成
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="
                            investmentAnalytics.initialInvestment.paymentFees.numberOfCases[1]
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        件
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="getUnitPrice('支払手数料', '議事録作成') | currency"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <!--計算値-->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="paymentFeesGuideline(1) | currency"
                          v-if="!investmentAnalytics.initialInvestment.paymentFees.isChanged[1]"
                        />
                        <!--入力値-->
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="investmentAnalytics.initialInvestment.paymentFees.amount[1]"
                          roundType="down"
                          v-else
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="checkbox">
                    <b-form-checkbox
                      id="paymentFees_1"
                      v-model="investmentAnalytics.initialInvestment.paymentFees.isChanged[1]"
                      name="paymentFees_1"
                      @change="changeCheckBox(1)"
                      size="lg"
                      style="padding-left:2em"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    建物保存登記
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="
                            investmentAnalytics.initialInvestment.paymentFees.numberOfCases[2]
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        件
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="getUnitPrice('支払手数料', '建物保存登記') | currency"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <!--計算値-->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="paymentFeesGuideline(2) | currency"
                          v-if="!investmentAnalytics.initialInvestment.paymentFees.isChanged[2]"
                        />
                        <!--入力値-->
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="investmentAnalytics.initialInvestment.paymentFees.amount[2]"
                          roundType="down"
                          v-else
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="checkbox">
                    <b-form-checkbox
                      id="paymentFees_2"
                      v-model="investmentAnalytics.initialInvestment.paymentFees.isChanged[2]"
                      name="paymentFees_2"
                      @change="changeCheckBox(2)"
                      size="lg"
                      style="padding-left:2em"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <br />
                <b-row>
                  <b-col style="border-top:dashed 1px gray;" />
                </b-row>
                <br />
                <b-row>
                  <b-col class="title bold">《 土地家屋調査士 》</b-col>
                </b-row>
                <br />
                <b-row>
                  <b-col class="title bold">
                    項目
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="title-center bold">
                        件数
                      </b-col>
                      <b-col cols="1" />
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="title-center bold">
                        単価
                      </b-col>
                      <b-col cols="1" />
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="title-center bold">
                        金額
                      </b-col>
                      <b-col cols="1" />
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="title-center" style="padding:0;">
                    変更
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title" style="padding-top:0.7em">建物表示登記</b-col>
                </b-row>
                <b-row
                  v-for="id of investmentAnalytics.newBuildingCount"
                  v-bind:key="`forBuildingRepresentationRegistration-${id}`"
                >
                  <b-col class="title" style="margin-left: 1rem;margin-right: -1rem;">
                    新築建物{{ id }}
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="ally-select">
                        <allySelect
                          :items="buildingRegistrationItems"
                          v-model="
                            investmentAnalytics.initialInvestment.paymentFees
                              .buildingRepresentationRegistration[id - 1].content
                          "
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="paymentFeesGuideline(3 + id - 1) | currency"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <!--計算値-->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="paymentFeesGuideline(3 + id - 1) | currency"
                          v-if="
                            !investmentAnalytics.initialInvestment.paymentFees.isChanged[2 + id]
                          "
                        />
                        <!--入力値-->
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="investmentAnalytics.initialInvestment.paymentFees.amount[2 + id]"
                          roundType="down"
                          v-else
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="checkbox">
                    <b-form-checkbox
                      :id="`paymentFees_3_${id}`"
                      v-model="investmentAnalytics.initialInvestment.paymentFees.isChanged[2 + id]"
                      @change="changeCheckBox(2 + id)"
                      size="lg"
                      style="padding-left:2em"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    建物滅失登記
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="
                            investmentAnalytics.initialInvestment.paymentFees.numberOfCases[13]
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        件
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="getUnitPrice('支払手数料', '建物滅失登記') | currency"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <!--計算値-->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="paymentFeesGuideline(13) | currency"
                          v-if="!investmentAnalytics.initialInvestment.paymentFees.isChanged[13]"
                        />
                        <!--入力値-->
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="investmentAnalytics.initialInvestment.paymentFees.amount[13]"
                          roundType="down"
                          v-else
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="checkbox">
                    <b-form-checkbox
                      id="paymentFees_13"
                      v-model="investmentAnalytics.initialInvestment.paymentFees.isChanged[13]"
                      name="paymentFees_13"
                      @change="changeCheckBox(13)"
                      size="lg"
                      style="padding-left:2em"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    地目変更登記
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="
                            investmentAnalytics.initialInvestment.paymentFees.numberOfCases[14]
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        件
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="getUnitPrice('支払手数料', '地目変更登記') | currency"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <!--計算値-->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="paymentFeesGuideline(14) | currency"
                          v-if="!investmentAnalytics.initialInvestment.paymentFees.isChanged[14]"
                        />
                        <!--入力値-->
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="investmentAnalytics.initialInvestment.paymentFees.amount[14]"
                          roundType="down"
                          v-else
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="checkbox">
                    <b-form-checkbox
                      id="paymentFees_14"
                      v-model="investmentAnalytics.initialInvestment.paymentFees.isChanged[14]"
                      name="paymentFees_14"
                      @change="changeCheckBox(14)"
                      size="lg"
                      style="padding-left:2em"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <br />
                <b-row>
                  <b-col style="border-top:dashed 1px gray;" />
                </b-row>
                <br />
                <b-row>
                  <b-col class="title bold">《 不動産会社 》</b-col>
                </b-row>
                <br />
                <b-row>
                  <b-col class="title bold">
                    項目
                  </b-col>
                  <b-col />
                  <b-col />
                  <b-col>
                    <b-row>
                      <b-col class="title-center bold">
                        金額
                      </b-col>
                      <b-col cols="1" />
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="title-center" style="padding:0;">
                    変更
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    売買仲介料
                  </b-col>
                  <b-col />
                  <b-col />
                  <b-col>
                    <b-row>
                      <b-col>
                        <!--計算値-->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="paymentFeesGuideline(15) | currency"
                          v-if="!investmentAnalytics.initialInvestment.paymentFees.isChanged[15]"
                        />
                        <!--入力値-->
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="investmentAnalytics.initialInvestment.paymentFees.amount[15]"
                          roundType="down"
                          v-else
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="checkbox">
                    <b-form-checkbox
                      id="paymentFees_15"
                      v-model="investmentAnalytics.initialInvestment.paymentFees.isChanged[15]"
                      name="paymentFees_15"
                      @change="changeCheckBox(15)"
                      size="lg"
                      style="padding-left:2em"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    賃貸借仲介料
                  </b-col>
                  <b-col />
                  <b-col />
                  <b-col>
                    <b-row>
                      <b-col>
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="
                            investmentAnalytics.initialInvestment.paymentFees.leaseBrokerageFee
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" />
                </b-row>
                <br />
                <b-row>
                  <b-col style="border-top:dashed 1px gray;" />
                </b-row>
                <br />
                <b-row>
                  <b-col class="title bold">《 金融機関 》</b-col>
                </b-row>
                <br />
                <b-row>
                  <b-col class="title bold">
                    項目
                  </b-col>
                  <b-col />
                  <b-col>
                    <b-row>
                      <b-col class="title-center bold">
                        単価
                      </b-col>
                      <b-col cols="1" />
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="title-center bold">
                        金額
                      </b-col>
                      <b-col cols="1" />
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="title-center" style="padding:0;">
                    変更
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    融資事務手数料
                  </b-col>
                  <b-col>
                    <b-col />
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="getUnitPrice('支払手数料', '融資事務手数料') | currency"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <!--計算値-->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="registrationTaxTotal(19) | currency"
                          v-if="!investmentAnalytics.initialInvestment.paymentFees.isChanged[19]"
                        />
                        <!--入力値-->
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="investmentAnalytics.initialInvestment.paymentFees.amount[19]"
                          roundType="down"
                          v-else
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="checkbox">
                    <b-form-checkbox
                      id="paymentFees_19"
                      v-model="investmentAnalytics.initialInvestment.paymentFees.isChanged[19]"
                      name="paymentFees_19"
                      @change="changeCheckBox(19)"
                      size="lg"
                      style="padding-left:2em"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    固定金利手数料
                  </b-col>
                  <b-col>
                    <b-col />
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="getUnitPrice('支払手数料', '固定金利手数料') | currency"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <!--計算値-->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="registrationTaxTotal(20) | currency"
                          v-if="!investmentAnalytics.initialInvestment.paymentFees.isChanged[20]"
                        />
                        <!--入力値-->
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="investmentAnalytics.initialInvestment.paymentFees.amount[20]"
                          roundType="down"
                          v-else
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="checkbox">
                    <b-form-checkbox
                      id="paymentFees_20"
                      v-model="investmentAnalytics.initialInvestment.paymentFees.isChanged[20]"
                      name="paymentFees_20"
                      @change="changeCheckBox(20)"
                      size="lg"
                      style="padding-left:2em"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    抵当権設定手数料
                  </b-col>
                  <b-col>
                    <b-col />
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="getUnitPrice('支払手数料', '抵当権設定手数料') | currency"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <!--計算値-->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="registrationTaxTotal(21) | currency"
                          v-if="!investmentAnalytics.initialInvestment.paymentFees.isChanged[21]"
                        />
                        <!--入力値-->
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          v-model="investmentAnalytics.initialInvestment.paymentFees.amount[21]"
                          roundType="down"
                          v-else
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1" class="checkbox">
                    <b-form-checkbox
                      id="paymentFees_21"
                      v-model="investmentAnalytics.initialInvestment.paymentFees.isChanged[21]"
                      name="paymentFees_21"
                      @change="changeCheckBox(21)"
                      size="lg"
                      style="padding-left:2em"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Calculation from './../Calculation';
  export default {
    mixins: [Calculation],
    data: function() {
      return {
        // 建物表示登記
        buildingRegistrationItems: this.getUnitPricesItems('建物表示登記'),
      };
    },
    watch: {
      // マスタ更新時
      '$store.state.master.unitPrice': {
        handler: function() {
          // 選択項目更新
          this.buildingRegistrationItems = this.getUnitPricesItems('建物表示登記');
        },
        deep: false,
      },
    },
    computed: {
      registrationTaxTotal: function() {
        return function(kind) {
          // 「登録免許税」が無効
          if (this.investmentAnalytics.initialInvestment.incidentalExpenses[7] != 1) {
            return 0;
          }
          let value = null;
          let iip = this.investmentAnalytics.initialInvestment.paymentFees;
          switch (kind) {
            // 所有権移転登記
            case 18:
              {
                // 所有権移転 合計
                let total = this.registrationTaxAmount(0); // 土地
                for (let id = 0; id < this.investmentAnalytics.existingBuildingCount; id++) {
                  total += this.registrationTaxAmount(1, id); // 既存建物
                }
                total += this.registrationTaxAmount(7); // 付属建物

                if (total > 0) {
                  value = kind;
                } else {
                  return 0;
                }
              }
              break;
            // 抵当権設定登記
            case 16:
              {
                if (this.registrationTax(3) + this.registrationTax(4) > 0) {
                  value = kind;
                } else {
                  return 0;
                }
              }
              break;
            // 借地権設定登記
            case 17:
              {
                if (
                  this.registrationTax(5) > 0 ||
                  this.investmentAnalytics.initialInvestment.registrationTax.amount[23] > 0
                ) {
                  value = kind;
                } else {
                  return 0;
                }
              }
              break;
            // 金融機関手数料
            case 19:
              {
                if (this.registrationTax(3) + this.registrationTax(4) > 0) {
                  value = kind;
                } else {
                  return 0;
                }
              }
              break;
            case 20:
              {
                if (this.registrationTax(3) + this.registrationTax(4) > 0) {
                  value = kind;
                } else {
                  return 0;
                }
              }
              break;
            case 21:
              {
                if (this.registrationTax(3) + this.registrationTax(4) > 0) {
                  value = kind;
                } else {
                  return 0;
                }
              }
              break;
          }
          return this.paymentFeesGuideline(value);
        };
      },
      changeCheckBox: function() {
        return function(id) {
          // 初期値設定
          if (!this.investmentAnalytics.initialInvestment.paymentFees.isChanged[id]) {
            this.$set(
              this.investmentAnalytics.initialInvestment.paymentFees.amount,
              id,
              this.paymentFees(id)
            );
          }
          return this.paymentFees(id);
        };
      },
    },
    methods: {
      // タブ変更時
      changeTab: function(index) {
        // 詳細入力時
        if (index > 0) {
          // 各項目
          this.investmentAnalytics.initialInvestment.paymentFees.isChanged.forEach((v, i) => {
            // 未変更時
            if (!v) {
              // 計算値設定
              this.$set(
                this.investmentAnalytics.initialInvestment.paymentFees.amount,
                i,
                this.paymentFees(i)
              );
            }
          });
        }
      },
    },
  };
</script>
<style>
  #PaymentFees .detail .row .row {
    padding-top: 0 !important;
  }
  #PaymentFees .checkbox {
    display: grid;
    text-align: center;
    align-items: center;
    padding: 0;
  }
  #buildingRegistration select {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 22px;
  }
  #PaymentFees .card-body > .container {
    padding: 0;
  }
  #PaymentFees .detail .row {
    margin: 0;
  }
  #PaymentFees .detail .col {
    padding: 0 7px;
  }
</style>
