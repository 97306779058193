<template>
  <CommonLayout title="Ⅲ．実行可能な具体的活用法">
    <template v-slot>
      <ReportSubTitle subTitle="比較検討の前提条件各項目の解説" />
      <div style="display: flex;">
        <div style="width: 50%; padding: 1%; margin-left:-45px; margin-top: 20px;">
          <ReportTable
            :header="headerMonthlyParkingLot"
            :items="itemsMonthlyParkingLot"
            :style="tablseStyle"
          >
            <template v-slot:header="{ col }">
              <div style="font-size: 70px; text-align: center;" v-html="col.label"></div>
            </template>
            <template v-slot:table="{ col, item }">
              <div
                style="padding-right: 15px; font-size: 55px"
                v-html="item[col.name].toLocaleString('ja-JP', { maximumSignificantDigits: 20 })"
              ></div> </template
          ></ReportTable>
          <ReportTable :header="headerRentLand" :items="itemsRentLand" :style="tablseStyle">
            <template v-slot:header="{ col }">
              <div style="font-size: 70px; text-align: center;" v-html="col.label"></div>
            </template>
            <template v-slot:table="{ col, item }">
              <div
                style="padding-right: 15px; font-size: 55px"
                v-html="item[col.name].toLocaleString('ja-JP', { maximumSignificantDigits: 20 })"
              ></div> </template
          ></ReportTable>
          <ReportTable :header="headerEmpty" :items="itemsVacantLotOrLand" :style="tablseStyle">
            <template v-slot:header="{ col }">
              <div style="font-size: 70px; text-align: center;" v-html="col.label"></div>
            </template>
            <template v-slot:table="{ col, item }">
              <div
                style="padding-right: 15px; font-size: 55px"
                v-html="item[col.name].toLocaleString('ja-JP', { maximumSignificantDigits: 20 })"
              ></div> </template
          ></ReportTable>
        </div>
        <div style="width: 50%; padding: 1%; margin-top: 20px;">
          <ReportTable
            :header="headerStoreOffice"
            :items="itemsStoreOffice"
            :style="tablseStyle"
          >
            <template v-slot:header="{ col }">
              <div style="font-size: 70px; text-align: center;" v-html="col.label"></div>
            </template>
            <template v-slot:table="{ col, item }">
              <div
                style="padding-right: 15px; font-size: 55px"
                v-html="item[col.name].toLocaleString('ja-JP', { maximumSignificantDigits: 20 })"
              ></div> </template
          ></ReportTable>
          <ReportTable :header="headerSale" :items="itemsSale" :style="tablseStyle">
            <template v-slot:header="{ col }">
              <div style="font-size: 70px; text-align: center;" v-html="col.label"></div>
            </template>
            <template v-slot:table="{ col, item }">
              <div
                v-if="typeof item[col.name] == 'number'"
                style="padding-right: 15px; font-size: 55px"
                v-html="item[col.name].toLocaleString('ja-JP', { maximumSignificantDigits: 20 })"
              ></div>
              <div
                v-else
                style="padding-right: 15px; font-size: 55px"
                v-html="item[col.name]"
              ></div> </template
          ></ReportTable>
        </div>
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import ReportSubTitle from '@/components/ExportReport/ReportSubTitle';
  import ReportTable from '@/components/ExportReport/ReportTable';

  export default {
    props: {
      otherProperty: {
        type: Object,
        required: true,
      },
    },
    components: {
      CommonLayout,
      ReportSubTitle,
      ReportTable,
    },
    data() {
      return {
        tablseStyle: {
          margin: '2%',
        },
        headerStoreOffice: [
          { name: 'category', label: '店舗・事務所' },
          { name: 'num', label: '数値' },
          { name: 'detail', label: '詳細', width: 2 },
        ],
        headerMonthlyParkingLot: [
          { name: 'category', label: '月極駐車場' },
          { name: 'num', label: '数値' },
          { name: 'detail', label: '詳細', width: 2 },
        ],
        headerRentLand: [
          { name: 'category', label: '貸地' },
          { name: 'num', label: '数値' },
          { name: 'detail', label: '詳細', width: 2 },
        ],
        headerEmpty: [
          { name: 'category', label: '更地' },
          { name: 'num', label: '数値' },
          { name: 'detail', label: '詳細', width: 2 },
        ],
        headerSale: [
          { name: 'category', label: '売却' },
          { name: 'num', label: '数値' },
          { name: 'detail', label: '詳細', width: 2 },
        ],
        itemsStoreOffice: [
          {
            category: '工事費<br>（万円）',
            num:
              Math.floor(
                this.otherProperty.storeOffice.constructionCostPerUnit *
                    this.tsubo(
                      Math.floor(
                        Number(this.otherProperty.storeOffice.totalFlooraSpace) * 100
                      ) / 100
                    )
                ),
            detail: '改修費用等<br>（税込）',
          },
          {
            category: '延床面積<br>（㎡）',
            num: this.otherProperty.storeOffice.totalFlooraSpace,
            detail: '建物の面積',
          },
          {
            category: '月額賃料<br>（万円/月）',
            num: this.otherProperty.storeOffice.monthlyRent,
            detail: '建物の月額賃料',
          },
          {
            category: '自己資金<br>（万円）',
            num: this.otherProperty.storeOffice.personalFunds,
            detail: 'いわゆる頭金で残りは借入金となる',
          },
          {
            category: '借入金利<br>（%）',
            num: this.otherProperty.storeOffice.borrowingRate,
            detail: '固定金利として計算',
          },
          {
            category: '返済期間<br>（年）',
            num: this.otherProperty.storeOffice.borrowingPeriod,
            detail: '通常は１０年以内',
          },
        ],
        itemsMonthlyParkingLot: [
          {
            category: '工事費<br>（万円）',
            num:
              this.otherProperty.monthlyParkingLot.constructionCostPerUnit *
              this.otherProperty.monthlyParkingLot.parkingAreaPerCar *
              this.otherProperty.monthlyParkingLot.numberOfUnits,
            detail: '舗装費用・砂利敷き等<br>（税込）',
          },
          {
            category: '駐車台数<br>（台）',
            num: this.otherProperty.monthlyParkingLot.numberOfUnits,
            detail: '土地の広さより想定',
          },
          {
            category: '月額賃料<br>（万円/月）',
            num: this.otherProperty.monthlyParkingLot.monthlyRent,
            detail: '一台あたりの月賃料',
          },
          {
            category: '空車率<br>（%）',
            num: this.otherProperty.monthlyParkingLot.vacancyRate,
            detail: '賃貸期間中を通して見込む空車の割合',
          },
          {
            category: '委託管理比率<br>（%）',
            num: this.otherProperty.monthlyParkingLot.managementCostRate,
            detail: '管理業者に支払うお金',
          },
          {
            category: '自己資金<br>（万円）',
            num: this.otherProperty.monthlyParkingLot.personalFunds,
            detail: 'いわゆる頭金で残りは借入金となる',
          },
          {
            category: '借入金利<br>（%）',
            num: this.otherProperty.monthlyParkingLot.borrowingRate,
            detail: '固定金利として計算',
          },
          {
            category: '返済期間<br>（年）',
            num: this.otherProperty.monthlyParkingLot.borrowingPeriod,
            detail: '通常は１０年以内',
          },
        ],
        itemsRentLand: [
          {
            category: '月地代収入<br>（万円/月）',
            num: this.otherProperty.leasedLand.monthlyLandRentPerSquareMeter,
            detail: '基本は一括借上げでオーナーに経費は<br>発生しない',
          },
        ],
        itemsVacantLotOrLand: [
          {
            category: '年間維持管理費用<br>（万円/年）',
            num: this.otherProperty.vacantLotOrLand.annualMaintenanceFee,
            detail: '草刈り代、雑木の伐採費等',
          },
        ],
        itemsSale: [
          {
            category: '売却価格<br>（万円）',
            num: this.otherProperty.sale.salePrice,
            detail: '近隣の取引事例から想定',
          },
          {
            category: '取得費用<br>（万円）',
            num: this.otherProperty.sale.acquisitionCost,
            detail: '取得時の価格（不明なら売却価格の5%）',
          },
          {
            category: '仲介手数料<br>（万円）',
            num: this.otherProperty.sale.agencyFees,
            detail: '',
          },
          // TODO 質問中なので保留
          // {
          //   category: 'その他譲渡費用<br>（万円）',
          //   num: this.otherProperty.sale.otherTransferCosts,
          //   detail: '抵当権抹消登記費用等',
          // },
          {
            category: '譲渡所得税<br>（万円）',
            num: Math.ceil(this.otherProperty.sale.transferIncomeTax / 1000 ) / 10,
            detail:
              '売却費の' +
              (this.otherProperty.sale.periodOfOwnership <= 5
                ? this.otherProperty.sale.capitalGainsTaxRateShortTerm
                : this.otherProperty.sale.capitalGainsTaxRateLongTerm) +
              '%',
          },
          {
            category: '手取り現金の<br>運用利回り<br>（％）',
            num: this.otherProperty.sale.annualInvestmentYield,
            detail: '売却後の手取り現金を運用する際の<br>想定利回り',
          },
        ],
      };
    },
  };
</script>
