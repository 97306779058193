// @ts-check

import { PvObject } from '../../node/node/pvObject';
import { PvRoot } from '../../node/node/rootNode';
import { GlobalSettings } from '../../util/globalSettings';
import { Vec } from '../../util/math/vector';

/**
 * イベントハンドラー、Action内からアクセスできる値
 */
export class EventContext {
  /** @type {() => PvRoot} */
  getRoot;
  /** @type {() => PvRoot} */
  getGuideRoot;
  /** @type {() => GlobalSettings} */
  getGlobalSettings;
  /** @type {() => Vec} */
  getCurrentPos;
  /** @type {() => PvObject} */
  getObjectPreset;
  /** @type {() => PvObject[]} */
  getSelectedObjects;
  /** @type {(x: number, y: number) => void} */
  moveEditor;
  /** @type {() => void} */
  showContextMenu;

  /**
   *
   * @param {EventContext} params
   */
  constructor(params) {
    Object.assign(this, params);
  }
}
