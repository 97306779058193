// @ts-check

/**
 *  CLASS: 建具追加情報
 */
export class PvAdditionalInfo {
  /** @type {number}  PROPERTY: 開き方向 */
  open = 0;
  /** @type {number}  PROPERTY: ノブ方向 */
  knob = 0;
  /** @type {number}  PROPERTY: 引き方向 */
  sliding = 0;
  /** @type {boolean}  PROPERTY: 内外判定結果 */
  isPointInArea = false;
  /** @type {number}  PROPERTY: 階段 手すり方向 */
  stringers = 0;
  /** @type {number}  PROPERTY: 階段 合計段数 */
  steps = 0;
  /** @type {number}  PROPERTY: 階段 一段あたりの角度 */
  stepAngle = 0;
  /** @type {number}  PROPERTY: 階段 一段あたりの高さ */
  stepHeight = 0;

  /**
   *  FUNC: コンストラクタ
   * @param {Partial<PvAdditionalInfo>|null} additionalInfo
   */
  constructor(additionalInfo = null) {
    if (additionalInfo != null) {
      Object.assign(this, additionalInfo);
    }
  }

  /**
   *  FUNC: クローン
   * @returns {PvAdditionalInfo}
   */
  clone() {
    return new PvAdditionalInfo(this);
  }
}
