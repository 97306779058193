<!--
作成者：本間
作成日：2021.03.03
画面名：投資分析/出力
機能：長期収支グラフ
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <b-tab id="LongTermBalanceGraph" title="長期収支グラフ">
    <b-card-group columns style="column-count: 1;">
      <b-card title="長期収支グラフ" class="card-result">
        <allyLineChart :data="chartData" :options="chartOptions" :styles="chartStyles" />
      </b-card>
    </b-card-group>
  </b-tab>
</template>
<script>
  // ツールチップ設定
  const tooltips = {
    // 年(タイトル)
    year: function(tooltipItem) {
      return tooltipItem[0].xLabel + '年目';
    },
    // 金額
    money: function(tooltipItem, data) {
      if (isNaN(tooltipItem.value)) {
        return null;
      }
      var label = data.datasets[tooltipItem.datasetIndex].label || '';
      if (label) {
        label += ': ';
      }
      label += Math.round(Number(tooltipItem.value)).toLocaleString();
      return label;
    },
  };
  import Calculation1 from '../Input/Calculation';
  import Calculation2 from '../Result/Calculation';
  export default {
    props: ['model', 'resultMode'],
    mixins: [Calculation1, Calculation2],
    data: function() {
      return {
        graphBorderWidth: this.resultMode ? 2 : 10,
        graphpointRadius: this.resultMode ? 2 : 10,
        // グラフスタイル
        chartStyles: {
          height: '60vh',
          position: 'relative',
        },
        // グラフオプション
        chartOptions: {
          id: 0,
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
          },
          legend: {
            reverse: true, // グラフ項目表示のみ逆順に
            display: true,
            position: 'bottom',
            labels: {
              boxWidth: 18,
              fontSize: 18,
            },
            fullWidth: false,
          },
          tooltips: {
            mode: 'index',
            titleFontSize: 16,
            bodyFontSize: 16,
            bodySpacing: 4,
            callbacks: {
              title: tooltips.year,
              label: tooltips.money,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: '年',
                  padding: 0,
                  fontSize: 18,
                  fontStyle: 'bold',
                },
                ticks: {
                  fontSize: 18,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: '万円',
                  padding: 0,
                  fontSize: 18,
                  fontStyle: 'bold',
                },
                ticks: {
                  beginAtZero: true,
                  callback: function(value) {
                    return Number(value / 10000).toLocaleString();
                  },
                  fontSize: 18,
                },
              },
            ],
          },
          animation: {
            duration: 0,
          },
          hover: {
            animationDuration: 0,
          },
          responsiveAnimationDuration: 0,
        },
        // グラフデータ
        chartData: {
          labels: [],
          datasets: [],
        },
      };
    },
    watch: {},
    mounted: function() {
      // 売却年
      const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale));
      // グラフデータ
      this.chartData = {
        labels: Array(years)
          .fill(null)
          .map((_, i) => i + 1),
        datasets: [
          {
            label: '単年度現金収支', // 税引前現金収支
            data: this.cashFlowsBeforeTax,
            borderColor: '#ff5555',
            backgroundColor: '#ff5555',
            order: 2,
            type: 'bar',
            fill: false,
          },
          {
            label: '現金収支累計', // 税引前現金収支累計
            data: this.cumulativeTotals(this.cashFlowsBeforeTax),
            borderColor: '#FFC000',
            pointBackgroundColor: '#FFC000',
            borderWidth: this.graphBorderWidth,
            order: 1,
            fill: false,
            lineTension: 0,
            pointRadius: this.graphpointRadius,
          },
          {
            label: '借入金残高',
            data: this.loansOutstanding,
            borderColor: '#92D050',
            pointBackgroundColor: '#92D050',
            borderWidth: this.graphBorderWidth,
            order: 0,
            fill: false,
            lineTension: 0,
            pointRadius: this.graphpointRadius,
          },
        ],
      };
    },
  };
</script>
