<!--
作成者：本間
作成日：2021.03.02
画面名：投資分析/出力
機能：事業収支予測計算書（税法上）
→20230830損益計画書に変更
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <b-tab id="IncomeAndExpenditureProjectionsForTaxPurposes" title="損益計画書">
    <b-alert show variant="danger" class="text-center" v-if="result == null">
      売却時期を入力してください。
    </b-alert>
    <b-card-group columns style="column-count: 1;" v-else>
      <b-card v-for="page in Math.ceil(result.years / yearsPerPage)" v-bind:key="page">
        <b-card-title>
          損益計画書&nbsp;[{{ (page - 1) * yearsPerPage + 1 }}～{{
            Math.min(page * yearsPerPage, result.years)
          }}年目]
        </b-card-title>
        <b-container
          style="padding:0;margin:0;"
          v-bind:style="[{ 'max-width': tableWidth + 'px' }]"
        >
          <b-row v-if="page == 1" style="height:1.5em;" />
          <b-row >
            <b-col v-if="page == 1" style="min-height:20px;">
              <b-row>
                <b-col cols="1">
                  <b-container class="report pr-0">
                    <b-row style="position:absolute;"/>
                    <b-row>
                      <b-col class="title emboss text-left" style="padding-left:0.5rem;">初期投資</b-col>
                    </b-row>
                  </b-container>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col cols="3">
                      <b-container class="report">
                        <b-row style="position:absolute;"/>
                        <b-row>
                          <b-col class="title emboss text-left" style="padding-left:0.5rem;">借入金</b-col>
                          <b-col class="value">{{ initialCost.loans | currency }}円</b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                    <b-col  cols="3">
                      <b-container class="report">
                        <b-row style="position:absolute;"/>
                        <b-row>
                          <b-col class="title emboss text-left" style="padding-left:0.5rem;">自己資金</b-col>
                          <b-col class="value">{{ initialCost.ownResources | currency }}円</b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                    <b-col  cols="3">
                      <b-container class="report">
                        <b-row style="position:absolute;"/>
                        <b-row>
                          <b-col class="title emboss text-left" style="padding-left:0.5rem;">保証金</b-col>
                          <b-col class="value">{{ initialCost.deposit | currency }}円</b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                    <b-col  cols="3">
                      <b-container class="report">
                        <b-row style="position:absolute;"/>
                        <b-row>
                          <b-col class="title emboss text-left" style="padding-left:0.5rem;">敷金</b-col>
                          <b-col class="value">{{ initialCost.securityDeposit | currency }}円</b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-else></b-col>
            <b-col cols="1" class="text-right" style="display: flex;justify-content: flex-end;
                align-items: flex-end; padding-left:0;">
              単位：万円
            </b-col>
          </b-row>
          <b-row style="padding:0;margin:0;">
            <!-- 固定列 -->
            <b-col
              class="fixedColumns"
              style="padding:0;margin:0;"
              v-bind:style="[
                { width: fixedColumnsWidth + 'px' },
                { 'max-width': fixedColumnsWidth + 'px' },
              ]"
            >
              <b-container class="report">
                <b-row style="height:0.5em;" />
                <b-row>
                  <b-col class="title emboss" cols="3"></b-col>
                  <b-col class="title emboss">項目1</b-col>
                  <b-col class="title emboss">項目2</b-col>
                </b-row>
                <!-- 分割線 -->
                <b-row class="title" style="height:2px;">
                  <b-col />
                </b-row>
                <!-- 収益の部/明細行 -->
                <span
                  v-for="(item, index1) in result.income.items"
                  v-bind:key="`income1_${index1}`"
                >
                  <b-row
                    v-for="(row, index2) in item.rows"
                    v-bind:key="`income1_${index1}_${index2}`"
                  >
                    <b-col
                      class="title emboss"
                      v-bind:class="{ rowspan: index1 > 0 || index2 > 0 }"
                      cols="3"
                    >
                      {{ index1 == 0 && index2 == 0 ? result.income.name : '' }}
                    </b-col>
                    <b-col
                      class="subtitle"
                      v-bind:class="{
                        rowspan: index2 > 0,
                      }"
                    >
                      {{ index2 > 0 ? null : item.name }}
                    </b-col>
                    <b-col
                      class="subtitle"
                      v-bind:class="{
                        colspan: row.name == null,
                      }"
                    >
                      {{ row.name }}
                    </b-col>
                  </b-row>
                </span>
                <!-- 収入の部/集計行 -->
                <b-row
                  v-for="(item, index) in result.income.total"
                  v-bind:key="`income_total1_${index}`"
                >
                  <b-col class="title rowspan" cols="3"></b-col>
                  <b-col class="title text-left">{{ item.name }}</b-col>
                  <b-col class="title colspan"></b-col>
                </b-row>
                <!-- 分割線 -->
                <b-row class="title" style="height:2px;">
                  <b-col />
                </b-row>
                <!-- 費用の部/明細行 -->
                <span
                  v-for="(item, index1) in result.expenditure.items"
                  v-bind:key="`expenditure1_${index1}`"
                >
                  <b-row
                    v-for="(row, index2) in item.rows"
                    v-bind:key="`expenditure1_${index1}_${index2}`"
                  >
                    <b-col
                      class="title emboss"
                      v-bind:class="{ rowspan: index1 > 0 || index2 > 0 }"
                      cols="3"
                    >
                      {{ index1 == 0 && index2 == 0 ? result.expenditure.name : '' }}
                    </b-col>
                    <b-col
                      class="subtitle"
                      style="white-space:nowrap;overflow:hidden;text-overflow:clip;"
                      v-bind:class="{
                        rowspan: index2 > 0,
                      }"
                    >
                      {{ index2 > 0 ? null : item.name }}
                    </b-col>
                    <b-col
                      class="subtitle"
                      style="white-space:nowrap;overflow:hidden;text-overflow:clip;"
                      v-bind:class="{
                        colspan: row.name == null,
                      }"
                    >
                      {{ row.name }}
                    </b-col>
                  </b-row>
                </span>
                <!-- 費用の部/集計行 -->
                <b-row
                  v-for="(item, index) in result.expenditure.total"
                  v-bind:key="`expenditure_total1_${index}`"
                >
                  <b-col class="title rowspan" cols="3"></b-col>
                  <b-col class="title text-left">{{ item.name }}</b-col>
                  <b-col class="title colspan"></b-col>
                </b-row>
                <!-- 分割線 -->
                <b-row class="title" style="height:2px;">
                  <b-col />
                </b-row>
                <!-- 単年度損益、法人税 -->
                <b-row v-for="(item, index) in result.total.items" v-bind:key="`total1_${index}`">
                  <b-col class="title" cols="3" />
                  <b-col class="title colspan" />
                  <b-col class="title colspan emboss text-right">
                    {{ item.name }}
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
            <!-- データ列 -->
            <b-col class="dataColumns" style="padding:0;margin:0;overflow-x:auto;margin-left:-1px;">
              <b-container
                class="report"
                v-bind:style="[
                  { width: dataColumnsWidth + 'px' },
                  { 'max-width': dataColumnsWidth + 'px' },
                ]"
              >
                <b-row style="height:0.5em;" />
                <b-row>
                  <b-col
                    class="title emboss"
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                  >
                    {{
                      result.years >= (page - 1) * yearsPerPage + year
                        ? (page - 1) * yearsPerPage + year + '年目'
                        : ''
                    }}
                  </b-col>
                </b-row>
                <!-- 分割線 -->
                <b-row style="height:2px;">
                  <b-col
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                    class="title"
                    style="height:2px;"
                  >
                  </b-col>
                </b-row>
                <!-- 収入の部/明細行 -->
                <span
                  v-for="(item, index1) in result.income.items"
                  v-bind:key="`income2_${index1}`"
                >
                  <b-row
                    v-for="(row, index2) in item.rows"
                    v-bind:key="`income2_${index1}_${index2}`"
                  >
                    <b-col
                      v-for="year in yearsPerPage"
                      v-bind:class="{
                        blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                        blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                      }"
                      v-bind:key="year"
                      class="value"
                    >
                      {{
                        (page - 1) * yearsPerPage + year >= result.years + 1
                          ? null
                          : row.values[(page - 1) * yearsPerPage + year - 1] | tenThousandYen
                      }}
                    </b-col>
                  </b-row>
                </span>
                <!-- 収入の部/集計行 -->
                <b-row
                  v-for="(item, index) in result.income.total"
                  v-bind:key="`income_total2_${index}`"
                >
                  <b-col
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                    class="title text-right"
                  >
                    {{
                      (page - 1) * yearsPerPage + year >= result.years + 1
                        ? null
                        : item.values[(page - 1) * yearsPerPage + year - 1] | tenThousandYen
                    }}
                  </b-col>
                </b-row>
                <!-- 分割線 -->
                <b-row style="height:2px;">
                  <b-col
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                    class="title"
                    style="height:2px;"
                  >
                  </b-col>
                </b-row>
                <!-- 費用の部/明細行 -->
                <span
                  v-for="(item, index1) in result.expenditure.items"
                  v-bind:key="`expenditure2_${index1}`"
                >
                  <b-row
                    v-for="(row, index2) in item.rows"
                    v-bind:key="`expenditure2_${index1}_${index2}`"
                  >
                    <b-col
                      v-for="year in yearsPerPage"
                      v-bind:class="{
                        blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                        blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                      }"
                      v-bind:key="year"
                      class="value"
                    >
                      {{
                        (page - 1) * yearsPerPage + year >= result.years + 1
                          ? null
                          : row.values[(page - 1) * yearsPerPage + year - 1] | tenThousandYen
                      }}
                    </b-col>
                  </b-row>
                </span>
                <!-- 費用の部/集計行 -->
                <b-row
                  v-for="(item, index) in result.expenditure.total"
                  v-bind:key="`expenditure_total2_${index}`"
                >
                  <b-col
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                    class="title text-right"
                  >
                    {{
                      (page - 1) * yearsPerPage + year >= result.years + 1
                        ? null
                        : item.values[(page - 1) * yearsPerPage + year - 1] | tenThousandYen
                    }}
                  </b-col>
                </b-row>
                <!-- 分割線 -->
                <b-row style="height:2px;">
                  <b-col
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                    class="title"
                  >
                  </b-col>
                </b-row>
                <!-- 単年度収益、法人税-->
                <b-row v-for="(item, index) in result.total.items" v-bind:key="`total2_${index}`">
                  <b-col
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                    class="title text-right"
                  >
                    {{
                      (page - 1) * yearsPerPage + year >= result.years + 1
                        ? null
                        : item.values[(page - 1) * yearsPerPage + year - 1] | tenThousandYen
                    }}
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-container>
        <div></div>
      </b-card>
    </b-card-group>
  </b-tab>
</template>
<script>
  import Calculation1 from '../Input/Calculation';
  import Calculation2 from '../Result/Calculation';
  export default {
    props: ['model'],
    mixins: [Calculation1, Calculation2],
    data: function() {
      return {
        yearsPerPage: 15, // ページあたりの年数
        initialCost: {
          ownResources: null, // 自己資金
          loans: null, // 借入金額
          deposit: null, // 保証金
          securityDeposit: null, // 敷金
        }
      };
    },
    created: function() {},
    mounted: function() {
      if (this.yearsPerPage > this.result.years) {
        this.yearsPerPage = this.result.years;
      }
      const cp = this.investmentAnalytics.initialInvestment.capitalPlan; // 資金計画
      this.initialCost.ownResources = cp.ownResources; // 自己資金
      this.initialCost.loans = cp.loans; // 借入金額
      this.initialCost.deposit = cp.deposit; // 保証金
      this.initialCost.securityDeposit = cp.securityDeposit; // 敷金
    },
    computed: {
      // 表全体の幅
      tableWidth: function() {
        return this.fixedColumnsWidth + this.dataColumnsWidth;
      },
      // 固定列の幅
      fixedColumnsWidth: function() {
        return 360;
      },
      // データ列の幅
      dataColumnsWidth: function() {
        return this.yearsPerPage * 72;
      },
      // 計算結果
      result: function() {
        return this.initialize();
      },
    },
    methods: {
      // 入力チェック
      isEmpty: function(value) {
        return value == null || value == '' || value == undefined || Number.isNaN(value)
          ? true
          : false;
      },
      // 初期化
      initialize: function() {
        // 売却年
        const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale));
        if (years == 0) {
          return null;
        }
        // [収入]一時金・[支出]償還金
        let lumpSum = []; // [収入]一時金
        let redemption = []; // [支出]償還金
        {
          const cp = this.investmentAnalytics.initialInvestment.capitalPlan; // 資金計画
          if (Number(cp.deposit) > 0) {
            // 初期投資/資金計画/保証金	※初年度のみ
            lumpSum.push({ name: '保証金', values: [Number(cp.deposit)] });
            redemption.push({ name: '保証金', values: this.redemptions(cp.deposit) });
          }
          if (Number(cp.constructionCooperationFund) > 0) {
            // 初期投資/資金計画/建設協力金	※初年度のみ
            lumpSum.push({ name: '建設協力金', values: [Number(cp.constructionCooperationFund)] });
          }
          if (Number(cp.securityDeposit) > 0) {
            // 初期投資/資金計画/敷金	※初年度のみ
            lumpSum.push({ name: '敷金', values: [Number(cp.securityDeposit)] });
            redemption.push({ name: '敷金', values: this.redemptionsDeposit(cp.securityDeposit) });
          }
          if (Number(cp.keyMoney) > 0) {
            // 初期投資/資金計画/礼金	※初年度のみ
            lumpSum.push({ name: '礼金', values: [Number(cp.keyMoney)] });
          }
        }
        // [支払]建物借入金利息
        let interests = [];
        const interest = this.interestOnLoans;
        if (interest.length > 0) {
          interests.push({ name: null, values: interest });
        }
        // [支払]修繕費
        let repairs = [];
        let cost = this.minorRepairsTotal;
        if (cost > 0) {
          repairs.push({ name: '軽微な修繕', values: this.maintenanceCosts(cost) });
        }
        repairs.push({ name: '大規模改装', values: this.longTermRepairPlanTotals });
        // [支払]消耗品
        let consumables = [];
        if (this.consumablesTotal > 0) {
          consumables.push({
            name: null,
            values: this.maintenanceCosts(this.consumablesTotal),
          });
        }
        // [支払]原状回復費
        let restorationCosts = [];
        const restorationCost =
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[6].quantity
            )
          ) *
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[6].unitPrice
            )
          ) *
          12;
        if (restorationCost > 0) {
          restorationCosts.push({ name: null, values: this.maintenanceCosts(restorationCost) });
        }
        // [支払]保険料/火災保険
        let insuranceFees = [];
        const fireInsurance =
          Math.floor(Number(this.investmentAnalytics.initialInvestment.insuranceFees[0])) +
          Math.floor(Number(this.investmentAnalytics.initialInvestment.insuranceFees[1]));
        if (fireInsurance > 0) {
          insuranceFees.push({
            name: '火災保険',
            values: Array(years).fill(fireInsurance / 5),
          });
        }
        // [支払]保険料/施設賠償責任保険
        const fee = Math.floor(Number(this.investmentAnalytics.initialInvestment.insuranceFees[2]));
        if (fee > 0) {
          insuranceFees.push({ name: '施設賠償責任保険', values: this.maintenanceCosts(fee) });
        }
        // [支払]賃借料/賃料
        let rentalFees = [];
        if (
          this.investmentAnalytics.initialInvestment.incidentalExpenses[9] == 1 &&
          this.investmentAnalytics.initialInvestment.preCareerCommitment.isDetail == 1
        ) {
          const rent =
            Math.floor(
              Number(this.investmentAnalytics.initialInvestment.preCareerCommitment.monthlyRent)
            ) * 12;
          if (rent > 0) {
            rentalFees.push({ name: '賃料', values: this.maintenanceCosts(rent) });
          }
          // [支払]賃借料/占用使用料
          const usageFee =
            Math.floor(
              Number(
                this.investmentAnalytics.initialInvestment.preCareerCommitment.monthlyOccupancyFee
              )
            ) * 12;
          if (usageFee > 0) {
            rentalFees.push({ name: '占用使用料', values: this.maintenanceCosts(usageFee) });
          }
        } else if (this.investmentAnalytics.initialInvestment.incidentalExpenses[9] == 0) {
          // [支払]賃借料/賃料(事業開始前負担がOFFの場合の支払地代の手入力値)
          const paidRent =
            Math.floor(Number(this.investmentAnalytics.singleYearPlan.spendingPlan.monthlyRent)) *
            12;
          if (paidRent > 0) {
            rentalFees.push({ name: '賃料', values: this.maintenanceCosts(paidRent) });
          }
        }
        // [支払]利用料/Wi-Fi利用料
        let usageFees = [];
        const wifi =
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[7].quantity
            )
          ) *
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[7].unitPrice
            )
          ) *
          12;
        if (wifi > 0) {
          usageFees.push({ name: 'Wi-Fi利用料', values: this.maintenanceCosts(wifi) });
        }
        // [支出]広告費
        let advertisingExpenses = [];
        const expense =
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[9].quantity
            )
          ) *
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[9].unitPrice
            )
          );
        if (expense > 0) {
          advertisingExpenses.push({ name: null, values: this.maintenanceCosts(expense) });
        }
        let others = [];
        // // [支出]その他/水道光熱費
        // const water =
        //   Number(
        //     this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[12].quantity
        //   ) *
        //   Number(
        //     this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[12].unitPrice
        //   );
        // if (water > 0) {
        //   others.push({ name: '水道光熱費', values: this.maintenanceCosts(water) });
        // }
        // [支出]その他/諸雑費
        const miscellaneousExpenses = Math.floor(
          Number(
            this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[10].unitPrice
          )
        );
        if (miscellaneousExpenses > 0) {
          others.push({ name: '諸雑費', values: this.maintenanceCosts(miscellaneousExpenses) });
        }
        // [支出]その他/その他
        const other =
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[8].unitPrice
            )
          ) * 12;
        if (other > 0) {
          others.push({ name: 'その他', values: this.maintenanceCosts(other) });
        }
        // [支出]その他/予備費
        others.push({ name: '予備費', values: [] }); // あとで計算

        let result = {
          years: years,
          income: {
            name: '収入の部',
            items: [
              // 賃料
              { name: '賃料', rows: [{ name: null, values: this.annualRentTotal }] },
              // 共益費
              { name: '共益費', rows: [{ name: null, values: this.annualCommonServiceFeesTotal }] },
              // 駐車料
              { name: '駐車料', rows: [{ name: null, values: this.annualParkingFeesTotal }] },
              // 土地賃貸料
              {
                name: '土地賃貸料',
                rows: [{ name: null, values: this.annualLandRentalFeesTotal }],
              },
              // 一時金
              { name: '一時金', rows: lumpSum },
              // その他
              { name: 'その他', rows: [{ name: null, values: this.annualOthersTotal }] },
              // 空室損失
              { name: '空室損失', rows: [{ name: null, values: this.vacancyLosses }] },
              // 消費税
              { name: '消費税', rows: [{ name: null, values: this.consumptionTaxTotals }] },
              // 売却益
              { name: '売却益', rows: [{ name: null, values: this.gainOnSales }] },
            ],
            total: [], // あとで計算
          },
          expenditure: {
            name: '費用の部',
            items: [
              // 初年度経費
              /* {
                name: '初年度経費',
                rows: [{ name: null, values: [this.getCalcFirstYearExpenses()] }],
              }, */
              // 固定資産税
              {
                name: '固定資産税',
                rows: [
                  { name: '土地', values: this.propertyTaxLand },
                  { name: '既存建物', values: this.propertyTaxBuildings },
                  { name: '新築建物', values: this.propertyTaxNewBuildings },
                  { name: '付属建物', values: this.propertyTaxAttachedBuilding },
                  { name: '償却資産', values: this.propertyTaxDepreciableAssets },
                ],
              },
              // 都市計画税
              {
                name: '都市計画税',
                rows: [
                  { name: '土地', values: this.cityPlanningTaxLand },
                  { name: '既存建物', values: this.cityPlanningTaxBuildings },
                  { name: '新築建物', values: this.cityPlanningTaxNewBuildings },
                  { name: '付属建物', values: this.cityPlanningTaxAttachedBuilding },
                ],
              },
              // 建物借入金利息
              { name: '借入利息', rows: interests },
              // 償還金
              { name: '償還金', rows: redemption },
              // 修繕費
              { name: '修繕費', rows: repairs },
              // 消耗品
              { name: '消耗品', rows: consumables },
              // ビルメンテナンス
              { name: 'ビルメンテナンス', rows: this.buildingMaintenance() },
              // 管理料
              { name: '管理料', rows: [{ name: '賃貸管理料', values: [] /* あとで計算 */ }] },
              // 原状回復費
              { name: '原状回復費', rows: restorationCosts },
              // 保険料
              { name: '保険料', rows: insuranceFees },
              // 賃借料
              { name: '賃借料', rows: rentalFees },
              // 利用料
              { name: '利用料', rows: usageFees },
              // 広告費
              { name: '広告費', rows: advertisingExpenses },
              // その他
              { name: 'その他', rows: others },
              // 減価償却費
              { name: '減価償却費', rows: [] /* あとで計算 */ },
              // 消費税
              {
                name: '消費税',
                rows: [
                  { name: '維持修繕費分', values: [] }, // あとで計算
                  { name: '預かり消費税', values: [] }, // あとで計算
                ],
              },
            ],
            total: [], // あとで集計
          },
          total: {
            name: '単年度損益',
            items: [], // あとで集計
          },
        };
        // 収益の部/合計
        let incomeTotal = [];
        for (let i = 0; i < years; i++) {
          let subtotal = 0;
          for (let item of result.income.items) {
            for (let row of item.rows) {
              if (row.values[i] != undefined) {
                subtotal += isNaN(row.values[i]) ? 0 : Number(row.values[i]);
              }
            }
          }
          incomeTotal.push(subtotal);
        }
        result.income.total.push({ name: '収入合計', values: incomeTotal });
        // 費用の部/管理料
        {
          const item = result.expenditure.items.find(o => o.name == '管理料');
          const row = item.rows.find(o => o.name == '賃貸管理料');
          // 収益の部/合計を元に計算
          row.values = this.managementFees(incomeTotal);
        }
        // 費用の部/減価償却費
        {
          const item = result.expenditure.items.find(o => o.name == '減価償却費');

          // 既存建物分
          let buildingResult = [];
          for (
            let year = 0;
            year < Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
            year++
          ) {
            let buildingValue = 0;
            // 各既存建物の減価償却費を年目分毎に格納
            for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
              if (this.depreciationExpenses(0, i)[year] > 0) {
                buildingValue += this.depreciationExpenses(0, i)[year];
              } else {
                buildingValue += 0;
              }
              if (buildingValue == 0) {
                buildingValue = null;
              }
            }
            buildingResult.push(buildingValue);
          }
          item.rows.push({ name: '既存建物', values: buildingResult });

          // 新築建物分
          let newBuildingResult = [];
          for (
            let year = 0;
            year < Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)); // 売却年
            year++
          ) {
            let newBuildingValue = 0;
            // 各新築建物の減価償却費を年目分毎に格納
            for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
              if (this.depreciationExpenses(1, i)[year] > 0) {
                newBuildingValue += this.depreciationExpenses(1, i)[year];
              } else {
                newBuildingValue += 0;
              }
              if (newBuildingValue == 0) {
                newBuildingValue = null;
              }
            }
            newBuildingResult.push(newBuildingValue);
          }
          item.rows.push({ name: '新築建物', values: newBuildingResult });

          // 付属建物分
          item.rows.push({ name: '付属建物', values: this.depreciationExpenses(2) });
        }
        // 費用の部/予備費
        {
          const item = result.expenditure.items.find(o => o.name == 'その他');
          const row = item.rows.find(o => o.name == '予備費');
          const ratio =
            Math.floor(
              Number(this.investmentAnalytics.singleYearPlan.spendingPlan.reserveRate) * 10
            ) / 10;
          // ここまでの合計を算出
          for (let i = 0; i < years; i++) {
            let subtotal = 0;
            for (let item of result.expenditure.items) {
              for (let row of item.rows) {
                if (row.values[i] != undefined) {
                  subtotal += isNaN(row.values[i]) ? 0 : Number(row.values[i]);
                }
              }
            }
            // 単年度収支計画/費用の部/予備費率を掛ける
            row.values.push(Math.floor((subtotal * ratio) / 100));
          }
        }
        // 費用の部/消費税/維持修繕費
        {
          const item = result.expenditure.items.find(o => o.name == '消費税');
          const row = item.rows.find(o => o.name == '維持修繕費分');
          // ここまでの合計を算出
          for (let i = 0; i < years; i++) {
            let subtotal = 0;
            for (let item of result.expenditure.items) {
              // 下記項目のみ
              const applicableItems = ['修繕費', '消耗品', 'ビルメンテナンス'];
              if (!applicableItems.includes(item.name)) {
                continue;
              }
              for (let row of item.rows) {
                if (row.values[i] != undefined) {
                  subtotal += isNaN(row.values[i]) ? 0 : Number(row.values[i]);
                }
              }
            }
            // 消費税率を掛ける
            row.values.push(this.tax(subtotal));
          }
        }
        // 費用の部/消費税/預かり消費税
        {
          const item = result.expenditure.items.find(o => o.name == '消費税');
          const row = item.rows.find(o => o.name == '預かり消費税');
          // 収益の部/消費税と同じ
          const src = result.income.items.find(o => o.name == '消費税').rows[0];
          row.values = src.values;
        }
        // 費用の部/合計
        let expenditureTotal = [];
        for (let i = 0; i < years; i++) {
          let subtotal = 0;
          for (let item of result.expenditure.items) {
            for (let row of item.rows) {
              if (row.values[i] != undefined) {
                subtotal += isNaN(row.values[i]) ? 0 : Number(row.values[i]);
              }
            }
          }
          expenditureTotal.push(subtotal);
        }
        result.expenditure.total.push({ name: '費用合計', values: expenditureTotal });

        // 単年度損益、所得税・住民税、損益累計
        let total = [];
        let tax = [];
        {
          for (let i = 0; i < years; i++) {
            // 単年度損益
            total.push(incomeTotal[i] - expenditureTotal[i]);
            // 所得税・住民税
            const incomeTax = this.getIncomeTax(total[i]);
            const residentTax = this.getResidentTax(total[i]);
            tax.push(Math.max(0, incomeTax + residentTax));
          }
          result.total.items.push({ name: '単年度損益', values: total });
          result.total.items.push({ name: '損益累計', values: this.cumulativeTotals(total) }); // 損益累計
          //result.total.items.push({ name: '所得税・住民税', values: tax });

          // グローバル変数に保持(現金で参照)
          this.investmentAnalyticsTemp.incomeTaxAndInhabitantTax = tax; // 所得税・住民税
        }

        // 投資分析指標
        result.indicator = {
          name: '投資分析指標',
          items: [],
        };

        // [debug]計算結果
        //console.debug('[事業収支予測計算書（税法上）]');
        //console.debug(JSON.stringify(result));
        return result;
      },

      // 費用の部/ビルメンテナンス
      buildingMaintenance: function() {
        // 概算入力時
        if (this.investmentAnalytics.singleYearPlan.spendingPlan.isDetail[2] == 0) {
          const value = Math.floor(
            Number(this.investmentAnalytics.singleYearPlan.spendingPlan.approximateValue[2])
          );
          let result = [];
          if (value > 0) {
            result.push({
              values: this.calcChange({
                value: value,
                years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)),
                term: this.getRateOfIncrease('据え置き期間'),
                period: this.getRateOfIncrease('維持費-変動周期'),
                factor: this.getRateOfIncrease('維持費-変動係数'),
              }),
            });
          }
          return result;
        }
        // 詳細入力時
        else {
          let value = 0;
          for (
            let i = 0;
            i < this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance.length;
            i++
          ) {
            if (
              this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[i].name ==
              '警備'
            ) {
              value += Math.floor(
                Number(
                  this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[i].amount
                ) * 12
              );
            } else {
              value += Math.floor(
                Number(
                  this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[i].amount
                )
              );
            }
          }
          let result = [];
          if (value > 0) {
            result.push({
              values: this.calcChange({
                value: value,
                years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)),
                term: this.getRateOfIncrease('据え置き期間'),
                period: this.getRateOfIncrease('維持費-変動周期'),
                factor: this.getRateOfIncrease('維持費-変動係数'),
              }),
            });
          }
          return result;
        }
      },
      //初年度経費(付帯費用)
      getCalcIncidentalExpenses: function() {
        let costs = this.calcIncidentalExpensesComputed;
        return costs.total;
      },
      //損益計画書＞初年度経費(付帯費用の合計 + 工事費用税込み（新築工事除く）+ 新築工事費の消費税のみ + 本体価格（既存・付属建物）の消費税のみ + 土地本体価格（非課税）)
      getCalcFirstYearExpenses: function() {
        //付帯費用合計 + 工事費用* 消費税 - 新築工事の税抜工事費 + 本体価格（既存・付属建物）の消費税のみ + 土地本体価格（非課税）)
        let costs =
          this.getCalcIncidentalExpenses() +
          this.constructionPlanTotal * 1.1 -
          this.newConstructionCostsTotal +
          this.tradingPriceBuildingTax +
          this.investmentAnalytics.initialInvestment.tradingPrice[0];
        return costs;
      },
    },
  };
</script>
<style scoped>
  #IncomeAndExpenditureProjectionsForTaxPurposes .card .card-title {
    margin-bottom: 0.5em;
    font-size: x-large;
    font-weight: bold;
    text-align: center;
    color: black !important;
    text-shadow: 1px 1px 1px gray;
  }
  #IncomeAndExpenditureProjectionsForTaxPurposes .report .row,
  #IncomeAndExpenditureProjectionsForTaxPurposes .report .col {
    height: 20px;
    white-space: nowrap;
    font-size: small;
  }
  #IncomeAndExpenditureProjectionsForTaxPurposes .dataColumns .col {
    padding-right: 0.5em;
    padding-left: 0.5em;
  }
  #IncomeAndExpenditureProjectionsForTaxPurposes .report .small {
    font-size: small;
    width: 0;
  }
  #IncomeAndExpenditureProjectionsForTaxPurposes .blankFirst {
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    background-color: transparent !important;
  }
  #IncomeAndExpenditureProjectionsForTaxPurposes .blankNext {
    border-color: transparent !important;
    background-color: transparent !important;
  }
</style>
