<!--
作成者：本間肇
作成日：2020.7.15
画面名：テキスト入力フォーム
機能：テキストを入力するフォーム
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <validation-provider :name="title" :rules="rules" v-slot="{ valid, errors }">
    <b-form-group :label-for="name">
      <label v-if="title" style="display: flex;">
        {{ title }}
        <allyBadge :mode="hideBadge ? 'View' : mode" :rules="rules" />
      </label>
      <b-form-textarea
        :id="name"
        :readonly="mode == 'View'"
        v-model="result"
        :state="state(valid, errors)"
        :placeholder="placeholder"
        :rows="rows"
        :max-rows="max_rows"
      />
      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
  export default {
    // name: 物理名
    // mode: View/Edit/Add
    // value: 値
    // title: タイトル
    // loading: ロード中プラグ
    // rule: vee-validate rule
    // placeholder: 説明
    // hideBadge: バッジ非表示
    props: [
      'name',
      'mode',
      'value',
      'title',
      'loading',
      'rules',
      'placeholder',
      'rows',
      'max_rows',
      'hideBadge',
    ],
    data: function() {
      //初期化
      return { result: this.value };
    },
    methods: {
      state: function(valid, errors) {
        if (
          (errors[0] ||
            (this.rules && this.result) ||
            (this.rules && this.rules.includes('required') && this.result)) &&
          this.mode != 'View'
        ) {
          return errors[0] ? false : valid ? true : null;
        }
        return null;
        // valid status
      },
    },
    watch: {
      value: function() {
        this.result = this.value;
      },
      result: function() {
        // 値を変更すると値を返上
        this.$emit('input', this.result);
      },
      loading: function() {
        // ロードが終わったら値を初期化
        if (this.loading === false) this.result = this.value;
      },
    },
  };
</script>
