// @ts-check

import { callUnity, CALL_COMMAND } from '../../../../js/ar/unity';
import { LARGE_GRID_METER_DEFAULT, LARGE_GRID_PX } from './const';
import colors from 'css-color-names';
import { utils } from 'pixi.js';
import { Vec } from './math/vector';

/**
 *  FUNC:
 */
export function generateId() {
  return Math.random()
    .toString()
    .replace('.', '');
}

/**
 *  FUNC: f桁以下を四捨五入
 * @param {number} num
 * @param {number} f
 * @returns {number}
 */
export function round(num, f) {
  const t = Math.pow(10, f);
  return Math.round(num * t) / t;
}

/**
 *  FUNC: ブラウザ判定
 * @returns {string}
 */
export function checkBrowser() {
  if (browser != null) return browser;
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('msie') != -1) {
    return 'msie';
  } else if (userAgent.indexOf('edge') != -1) {
    return 'edge';
  } else if (userAgent.indexOf('chrome') != -1) {
    return 'chrome';
  } else {
    return 'safari';
  }
}

let browser;

export const inAppWebView = () => {
  return callUnity(CALL_COMMAND.START_FURNITURE_SIMULATOR);
};

export const isMobile = () => {
  if (window.matchMedia('(max-device-width: 1024px)').matches || inAppWebView()) {
    return true;
  } else {
    return false;
  }
};

/**
 *  FUNC: 配列の次の要素取得（ループする）
 * @param {number} index
 * @param {number} length 配列の長さ
 */
export function nextCycleIndex(index, length) {
  return (index + 1) % length;
}

/**
 *  FUNC: 配列の前の要素取得（ループする）
 * @param {number} index
 * @param {number} length 配列の長さ
 */
export function prevCycleIndex(index, length) {
  return (index - 1 + length) % length;
}

/**
 *  FUNC: meterToPx
 * @param {number} meter
 * @returns {number}
 */
export function meterToPx(meter) {
  return (meter / LARGE_GRID_METER_DEFAULT) * LARGE_GRID_PX;
}

/**
 *  FUNC: pxToMeter
 * @param {number} px
 * @returns {number}
 */
export function pxToMeter(px) {
  return (px / LARGE_GRID_PX) * LARGE_GRID_METER_DEFAULT;
}

/**
 *  FUNC: loadImage
 * @param {string} url
 * @returns {Promise<HTMLImageElement>}
 */
export function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = e => reject(e);
    img.src = url;
  });
}

/**
 *  FUNC: toastイベント発火()
 * @param {string} text
 * @param {string?} variant
 */
export function makeToast(text, variant = 'danger') {
  const e = new CustomEvent('toast');
  // @ts-ignore
  e.text = text;
  // @ts-ignore
  e.variant = variant;
  dispatchEvent(e);
}

/**
 *  FUNC:
 * @param {Blob} blob
 * @returns {Promise<string>}
 */
export async function blobToDataUrl(blob) {
  return await new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    const subscribe = () => {
      fileReader.addEventListener('abort', onAbort);
      fileReader.addEventListener('error', onError);
      fileReader.addEventListener('load', onLoad);
    };

    const unsubscribe = () => {
      fileReader.removeEventListener('abort', onAbort);
      fileReader.removeEventListener('error', onError);
      fileReader.removeEventListener('load', onLoad);
    };

    const onAbort = () => {
      unsubscribe();
      reject(new Error('abort'));
    };

    const onError = event => {
      unsubscribe();
      reject(event.target.error);
    };

    const onLoad = event => {
      unsubscribe();
      resolve(event.target.result);
    };

    subscribe();
    fileReader.readAsDataURL(blob);
  });
}
/**
 *  FUNC: colorStringToHex
 * @param {string | number} str
 */
export function colorStringToHex(str) {
  const hex = utils.string2hex(colors[str] ?? str);
  return hex;
}

/**
 *  FUNC: 重心座標を取得
 * @param {Vec[]} vertexes
 * @return {Vec}
 */
export function getBarycentricCoordinate(vertexes) {
  let sumVecX = 0;
  let sumVecY = 0;
  const length = vertexes.length;
  vertexes.forEach(vec => {
    sumVecX += vec.x;
    sumVecY += vec.y;
  });
  return new Vec(sumVecX / length, sumVecY / length);
}

/**
 *  FUNC: 数値判定（数値型、又は文字列型の数値であるか判定する）
 * @param {any} value
 */
export function isNumber(value) {
  const ruleIsNumberOnly = /^[0-9]+$/; // 半角数字のみ
  // const ruleIsLower = /^(?=.*?[a-z]).*$/; // 半角英小文字を含む
  // const ruleIsUpper = /^(?=.*?[A-Z]).*$/; // 半角英大文字を含む
  // const ruleIsFullWidthCharacter = /^(?=.*?[^\\x01-\\x7E]).*$/; // 全角文字を含む

  if (!ruleIsNumberOnly.test(value)) {
    // console.error('data', value);
    // 数字以外を含む場合はfalseを返す
    return false;
  }
  // console.log('data', value);
  // キャスト後の値がNumber型か判定
  return !Number.isNaN(parseInt(value));
}

/**
 * FUNC: 座標群の中で、任意の線分に一番近い線分を作る二点の座標を取得する
 * @param {Vec[]} multiplePoints // 座標群
 * @param {{
 * startPoint: Vec,
 * endPoint: Vec,
 * }} anyLineData // 任意の線分データ
 * @returns {{ startPoint: Vec; endPoint: Vec; }} // 座標群の中で、任意の線分に一番近い線分を作る二点の座標
 */
export function getMostSimilarLine(multiplePoints, anyLineData) {
  const anyLinePoints = [anyLineData.startPoint, anyLineData.endPoint];
  let maxCos = 0; // 任意の線分と座標群を結ぶ線分の最大コサイン類似度
  let minDistance = 1000; // 任意の線分から座標群で作る線分までの最短距離
  let startPointIndex = 0; // 座標群の中で、任意の線分に一番近い線分を作る開始点のインデックス
  let endPointIndex = 0; // 座標群の中で、任意の線分に一番近い線分を作る終了点のインデックス
  // 床座標の数だけ繰り返す
  multiplePoints.forEach((pointVec, pointIndex, array) => {
    const nextPointIndex = pointIndex == array.length - 1 ? 0 : pointIndex + 1;
    let ab1 = 0;
    let ab2 = 0;
    let ab3 = 0;
    // 任意の線分の座標の数だけ繰り返す
    const anyLineVec = anyLinePoints[1].sub(anyLinePoints[0]);
    const targetLineVec = array[nextPointIndex].sub(pointVec);
    ab1 = anyLineVec.x * targetLineVec.x + anyLineVec.y * targetLineVec.y;
    ab2 = anyLineVec.x * anyLineVec.x + anyLineVec.y * anyLineVec.y;
    ab3 = targetLineVec.x * targetLineVec.x + targetLineVec.y * targetLineVec.y;
    const cos = Math.abs(ab1 / (Math.sqrt(ab2) * Math.sqrt(ab3)));
    const distance = anyLinePoints[0].distFromLine(pointVec, array[nextPointIndex]);
    // コサイン類似度と任意の線分までの距離をチェック
    if (cos > maxCos - 0.5 && distance < minDistance) {
      // コサイン類似度が最大値より高い、かつ任意の線分までの距離が最短距離より近い場合
      // 最大コサイン類似度更新
      maxCos = cos;
      // 最短距離更新
      minDistance = distance;
      // 更新した線分の開始点のインデックスを更新
      startPointIndex = pointIndex;
      // 更新した線分の終了点のインデックスを更新
      endPointIndex = nextPointIndex;
    }
  });
  // 座標群の中で、任意の線分に一番近い線分を作る二点の座標を返す
  return { startPoint: multiplePoints[startPointIndex], endPoint: multiplePoints[endPointIndex] };
}

/**
 * FUNC: 二点の線分上を指定距離移動した座標を取得
 * @param {{
 * startPoint: Vec,
 * endPoint: Vec,
 * }} lineData 線分データ
 * @param {number} movingDistance 移動距離
 * @returns {Vec | null}
 */
export function getMovePoint(lineData, movingDistance) {
  const lineStartPoint = lineData.startPoint;
  const lineEndPoint = lineData.endPoint;
  if (
    isNaN(lineStartPoint.x) ||
    isNaN(lineStartPoint.y) ||
    isNaN(lineEndPoint.x) ||
    isNaN(lineEndPoint.y) ||
    isNaN(movingDistance)
  ) {
    return null;
  }
  const lineX = lineEndPoint.x - lineStartPoint.x;
  const lineY = lineEndPoint.y - lineStartPoint.y;
  const lineLength = Math.sqrt(Math.pow(lineX, 2) + Math.pow(lineY, 2));
  if (lineLength == 0) {
    return null;
  }
  // 座標の移動量を求める
  movingDistance = Math.abs(movingDistance);
  const percent = movingDistance / lineLength;
  if (percent == 0) {
    return null;
  }
  const moveX = lineX * percent;
  const moveY = lineY * percent;
  if ((moveX == 0 && lineX != 0) || (moveY == 0 && lineY != 0)) {
    return null;
  }
  // 移動後の座標を生成
  const movedPoint = new Vec(lineStartPoint.x + moveX, lineStartPoint.y + moveY);
  // 移動距離が線分の長さを超えているか判定
  const isOverX =
    (lineX > 0 && movedPoint.x > lineEndPoint.x) || (lineX < 0 && movedPoint.x < lineEndPoint.x);
  const isOverY =
    (lineY > 0 && movedPoint.y > lineEndPoint.y) || (lineY < 0 && movedPoint.y < lineEndPoint.y);
  // 移動距離が線分の長さを超えていたら線分の終了点を返す
  return new Vec(isOverX ? lineEndPoint.x : movedPoint.x, isOverY ? lineEndPoint.y : movedPoint.y);
}

/**
 * FUNC: 二点を結ぶ線分上にある、任意の点に一番近い座標を取得
 * @param {Vec} anyPoint 任意の点
 * @param {{
 * startPoint: Vec,
 * endPoint: Vec,
 * }} lineData 線分データ
 * @returns {Vec} 線分上にある、任意の点に一番近い座標
 */
export function getClosestPoint(anyPoint, lineData) {
  const lineStartPoint = lineData.startPoint; // 線分データの開始点
  const lineEndPoint = lineData.endPoint; // 線分データの終了点
  const anyVec = new Vec(anyPoint.x - lineStartPoint.x, anyPoint.y - lineStartPoint.y); // 任意の点のベクトル
  const lineVec = new Vec(lineEndPoint.x - lineStartPoint.x, lineEndPoint.y - lineStartPoint.y); // 線分のベクトル
  const dotProduct = anyVec.x * lineVec.x + anyVec.y * lineVec.y; // 内積
  if (dotProduct > 0) {
    const lineLength = lineStartPoint.getSideLength(lineEndPoint); // 線分の長さ
    const projection = dotProduct / lineLength; //
    if (projection < lineLength) {
      const moveVec = lineVec.scale(projection / lineLength);
      // 線分上の座標を返す
      return new Vec(lineStartPoint.x + moveVec.x, lineStartPoint.y + moveVec.y);
    } else {
      // 任意の点が、線分の終了点側の外にあるなら、終了点の座標を返す
      return lineEndPoint;
    }
  } else {
    // 任意の点が、線分の開始点側の外にあるなら、開始点の座標を返す
    return lineStartPoint;
  }
}

/**
 * FUNC: 任意の２点を結ぶ線分の進行方向または垂直方向に、対象の点から任意の距離移動した点を取得
 * @param {{
 * lineData: {
 * startPoint: Vec,
 * endPoint: Vec,
 * },
 * targetPoint: Vec,
 * movingDistance: number,
 * isGo: boolean,
 * isTargetRotation: boolean ,
 * isClockwiseRotation: boolean | null,
 * }} param
 *  lineData 線分データ
 *  targetPoint 対象となる頂点
 *  movingDistance 移動距離
 *  isGo 線分上の移動方向フラグ（falseは後退）
 *  isTargetRotate 対象となる頂点を回転するかのフラグ
 *  isClockwiseRotation 時計回り回転フラグ（nullの場合は回転しない）
 */
export function getOtherSidePoint(param) {
  // 階段の幅の分移動した線分上の頂点の、開始点からの移動量を取得
  const moveVec = getMovePoint(param.lineData, param.movingDistance)?.sub(
    param.lineData.startPoint
  );
  if (!moveVec) {
    console.error('moveVec is Null');
    return param.lineData.startPoint;
  }
  // 対象となる頂点の移動後の頂点を取得。対象となる頂点に対し、進行の場合は移動量を加算、後退の場合は減算する
  const movedPoint = param.isGo ? param.targetPoint.add(moveVec) : param.targetPoint.sub(moveVec);

  // 任意の点から任意の角度移動した先の頂点を取得。
  // 進行の場合は対象となる頂点を基準に移動後の頂点を回転。後退の場合は移動後の頂点を基準に対象となる頂点を回転。
  let correctionPoint = (param.isTargetRotation ? param.targetPoint : movedPoint).rotate(
    param.isClockwiseRotation == null ? 0 : param.isClockwiseRotation ? 90 : -90,
    param.isTargetRotation ? movedPoint : param.targetPoint
  );
  return correctionPoint;
}
