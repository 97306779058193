<!--
作成者：本間
作成日：2021.02.15
画面名：投資分析/入力
機能：初期投資/工事計画/設備更新工事
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="EquipmentRenewal">
    <!-- 概算入力 -->
    <b-row>
      <b-col class="title bold">
        設備更新工事
      </b-col>
      <b-col />
      <b-col class="component">
        <b-row>
          <b-col class="component">
            <allyText
              type="number"
              class="number"
              :separation="true"
              halfWidth
              v-model="investmentAnalytics.initialInvestment.constructionPlan.equipmentUpdateCost"
              roundType="down"
            />
          </b-col>
          <b-col cols="2" class="unit">円</b-col>
        </b-row>
      </b-col>
      <b-col cols="1" class="title">
        <b-button variant="ally" v-b-toggle.tabEquipmentUpdateCostDetails>
          <i class="when-open ti-angle-double-up" />
          <i class="when-closed ti-angle-double-down" />
        </b-button>
      </b-col>
    </b-row>
    <!-- 詳細入力 -->
    <b-row>
      <b-col>
        <b-collapse id="tabEquipmentUpdateCostDetails" role="tabpanel">
          <b-card-body>
            <b-container>
              <b-row v-for="id in itemsCount" v-bind:key="`equipmentUpdateCostDetails-${id}`">
                <b-col cols="3" class="title bold">
                  {{ id == 1 ? '詳細' : '' }}
                </b-col>
                <b-col cols="3" class="title">
                  <span v-if="id <= defaultItems.length">
                    {{ defaultItems[id - 1] }}
                  </span>
                  <span v-else>
                    <allyText
                      v-model="
                        investmentAnalytics.initialInvestment.constructionPlan
                          .equipmentUpdateCostDetails[id - 1].content
                      "
                      placeholder="項目名を入力してください"
                    />
                  </span>
                </b-col>
                <b-col cols="3" class="component">
                  <b-row>
                    <b-col class="component">
                      <allyText
                        type="number"
                        class="number"
                        :separation="true"
                        halfWidth
                        v-model="
                          investmentAnalytics.initialInvestment.constructionPlan
                            .equipmentUpdateCostDetails[id - 1].amount
                        "
                        roundType="down"
                      />
                    </b-col>
                    <b-col cols="2" class="unit">円</b-col>
                  </b-row>
                </b-col>
                <b-col cols="3" v-if="id > defaultItems.length">
                  <b-button variant="ally" @click="deleteItem(id - 1)">
                    <i class="ti-minus" />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="9" />
                <b-col cols="1">
                  <b-button variant="ally" @click="addItem">
                    <i class="ti-plus" />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="9" class="borderline" />
              </b-row>
              <b-row>
                <b-col cols="6" class="title bold text-right">合計</b-col>
                <b-col cols="3" class="component">
                  <b-row>
                    <b-col class="component">
                      <b-form-input
                        disabled
                        class="currency"
                        :value="
                          investmentAnalytics.initialInvestment.constructionPlan
                            .equipmentUpdateCost | currency
                        "
                      />
                    </b-col>
                    <b-col cols="2" class="unit">円</b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </b-collapse>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Calculation from './../Calculation';
  export default {
    mixins: [Calculation],
    data: function() {
      return {
        defaultItems: [
          'エアコン・空調',
          '給湯設備',
          'キッチン',
          'トイレ',
          '風呂',
          'キュービクル',
          'EV',
          '自動ドア',
        ],
        textBoxUpdateFlag: false,
      };
    },
    computed: {
      // 項目数
      itemsCount: function() {
        return this.investmentAnalytics.initialInvestment.constructionPlan
          .equipmentUpdateCostDetails.length;
      },
    },
    methods: {
      // 項目追加
      addItem: function() {
        this.investmentAnalytics.initialInvestment.constructionPlan.equipmentUpdateCostDetails.push(
          {
            content: null,
            amount: null,
          }
        );
      },
      // 項目削除
      deleteItem: function(id) {
        this.investmentAnalytics.initialInvestment.constructionPlan.equipmentUpdateCostDetails.splice(
          id,
          1
        );
      },
    },
    watch: {
      /**
       * 詳細を入力後、合計に適用する
       */
      'investmentAnalytics.initialInvestment.constructionPlan.equipmentUpdateCostDetails': {
        handler: function() {
          let total = 0;
          let noNullCheck = false;
          for (let i = 0; i < this.itemsCount; i++) {
            if (
              this.investmentAnalytics.initialInvestment.constructionPlan
                .equipmentUpdateCostDetails[i].amount != null
            ) {
              total += parseInt(
                this.investmentAnalytics.initialInvestment.constructionPlan
                  .equipmentUpdateCostDetails[i].amount
              );
              noNullCheck = true;
            }
          }
          if (noNullCheck) {
            // 概算を更新
            Vue.set(
              this.investmentAnalytics.initialInvestment.constructionPlan,
              'equipmentUpdateCost',
              total
            );
          }
          this.textBoxUpdateFlag = true;
        },
        deep: true,
      },
      /**
       * 合計適用したとき、詳細を削除する
       */
      'investmentAnalytics.initialInvestment.constructionPlan.equipmentUpdateCost': {
        handler: function() {
          if (this.textBoxUpdateFlag == false) {
            for (let i = 0; i < this.itemsCount; i++) {
              this.investmentAnalytics.initialInvestment.constructionPlan.equipmentUpdateCostDetails[
                i
              ].amount = null;
            }
          }
          this.textBoxUpdateFlag = false;
        },
        deep: false,
      },
    },
  };
</script>
<style scoped>
  #tabEquipmentUpdateCostDetails .row .row {
    padding-top: 0 !important;
  }
</style>
