/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccount = /* GraphQL */ `
  mutation CreateAccount($input: AccountInfoInput) {
    createAccount(input: $input) {
      address
      nickname
      birthdate
      phonenumber
      mobilePhoneNumber
      email
      phonenumberverified
      emailverified
      picture
      familyname
      preferredusername
      gender
      profile
      givenname
      updatedat
      name
      isAdmin
      sub
      serialNumber
      privilegedFlag
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount($input: AccountInfoInput) {
    updateAccount(input: $input) {
      address
      nickname
      birthdate
      phonenumber
      mobilePhoneNumber
      email
      phonenumberverified
      emailverified
      picture
      familyname
      preferredusername
      gender
      profile
      givenname
      updatedat
      name
      isAdmin
      sub
      serialNumber
      privilegedFlag
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount($sub: String) {
    deleteAccount(sub: $sub)
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup($sub: String) {
    deleteGroup(sub: $sub)
  }
`;
export const restoreAccount = /* GraphQL */ `
  mutation RestoreAccount($sub: String) {
    restoreAccount(sub: $sub)
  }
`;
export const permanentDeleteAccount = /* GraphQL */ `
  mutation PermanentDeleteAccount($sub: String) {
    permanentDeleteAccount(sub: $sub)
  }
`;
export const adminPasswordSetup = /* GraphQL */ `
  mutation AdminPasswordSetup($input: AccountInfoInput) {
    adminPasswordSetup(input: $input)
  }
`;
export const convertReport = /* GraphQL */ `
  mutation ConvertReport($input: convertReportInput) {
    convertReport(input: $input)
  }
`;
export const updateLicense = /* GraphQL */ `
  mutation UpdateLicense($debug: String) {
    updateLicense(debug: $debug)
  }
`;
export const assignLicense = /* GraphQL */ `
  mutation AssignLicense($id: String, $licenses: String) {
    assignLicense(id: $id, licenses: $licenses)
  }
`;
export const setCognitoAttributes = /* GraphQL */ `
  mutation SetCognitoAttributes($id: String, $attributes: String) {
    setCognitoAttributes(id: $id, attributes: $attributes)
  }
`;
export const changeRecurring = /* GraphQL */ `
  mutation ChangeRecurring($recurringId: String, $amount: Int, $tax: Int) {
    changeRecurring(recurringId: $recurringId, amount: $amount, tax: $tax) {
      statusCode
      body
    }
  }
`;
export const unregisterRecurring = /* GraphQL */ `
  mutation UnregisterRecurring($recurringId: String) {
    unregisterRecurring(recurringId: $recurringId) {
      statusCode
      body
    }
  }
`;
export const receivePaymentResults = /* GraphQL */ `
  mutation ReceivePaymentResults($body: String) {
    receivePaymentResults(body: $body) {
      statusCode
      body
    }
  }
`;
export const createBankAccount = /* GraphQL */ `
  mutation CreateBankAccount($id: String) {
    createBankAccount(id: $id)
  }
`;
export const deleteBankAccount = /* GraphQL */ `
  mutation DeleteBankAccount($id: String) {
    deleteBankAccount(id: $id)
  }
`;
export const purchaseLicense = /* GraphQL */ `
  mutation PurchaseLicense($input: PurchaseLicenseInput) {
    purchaseLicense(input: $input) {
      statusCode
      result
    }
  }
`;
export const cancelLicense = /* GraphQL */ `
  mutation CancelLicense(
    $date: AWSDate!
    $companyId: ID!
    $licenseId: String!
    $quantity: Int!
  ) {
    cancelLicense(
      date: $date
      companyId: $companyId
      licenseId: $licenseId
      quantity: $quantity
    )
  }
`;
export const createCompanyTemporaryStorage = /* GraphQL */ `
  mutation CreateCompanyTemporaryStorage(
    $input: CreateCompanyTemporaryStorageInput!
    $condition: ModelcompanyTemporaryStorageConditionInput
  ) {
    createCompanyTemporaryStorage(input: $input, condition: $condition) {
      username
      storageJson
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyTemporaryStorage = /* GraphQL */ `
  mutation UpdateCompanyTemporaryStorage(
    $input: UpdateCompanyTemporaryStorageInput!
    $condition: ModelcompanyTemporaryStorageConditionInput
  ) {
    updateCompanyTemporaryStorage(input: $input, condition: $condition) {
      username
      storageJson
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyTemporaryStorage = /* GraphQL */ `
  mutation DeleteCompanyTemporaryStorage(
    $input: DeleteCompanyTemporaryStorageInput!
    $condition: ModelcompanyTemporaryStorageConditionInput
  ) {
    deleteCompanyTemporaryStorage(input: $input, condition: $condition) {
      username
      storageJson
      createdAt
      updatedAt
    }
  }
`;
export const createARMeasure = /* GraphQL */ `
  mutation CreateARMeasure(
    $input: CreateARMeasureInput!
    $condition: ModelARMeasureConditionInput
  ) {
    createARMeasure(input: $input, condition: $condition) {
      id
      companyId
      propertyId
      floorPlanId
      interiors {
        code
        setNumber
        measureTypes {
          type
          height
          svg
          measurements {
            position {
              x
              y
              z
            }
            length
            angle
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateARMeasure = /* GraphQL */ `
  mutation UpdateARMeasure(
    $input: UpdateARMeasureInput!
    $condition: ModelARMeasureConditionInput
  ) {
    updateARMeasure(input: $input, condition: $condition) {
      id
      companyId
      propertyId
      floorPlanId
      interiors {
        code
        setNumber
        measureTypes {
          type
          height
          svg
          measurements {
            position {
              x
              y
              z
            }
            length
            angle
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteARMeasure = /* GraphQL */ `
  mutation DeleteARMeasure(
    $input: DeleteARMeasureInput!
    $condition: ModelARMeasureConditionInput
  ) {
    deleteARMeasure(input: $input, condition: $condition) {
      id
      companyId
      propertyId
      floorPlanId
      interiors {
        code
        setNumber
        measureTypes {
          type
          height
          svg
          measurements {
            position {
              x
              y
              z
            }
            length
            angle
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAnalyticsInput = /* GraphQL */ `
  mutation CreateAnalyticsInput(
    $input: CreateAnalyticsInputInput!
    $condition: ModelAnalyticsInputConditionInput
  ) {
    createAnalyticsInput(input: $input, condition: $condition) {
      id
      author
      inputTitle
      commonInput {
        reportTitle
        reportDate
        customerName
        customerId
        staffName
        family {
          lastName
          firstName
          lastNameKana
          firstNameKana
          familyType
          inheritanceFlg
          inheritancePrice
          inheritancePriceDeduction
          donationDeduction
          donation
          donations
          liability
          continuityInheritance
          continuityInheritanceFlg
          continuityInheritanceTerm
          secondaryInheritancePattern
          uniqueAsset
          inheritanceSystemFlg
        }
        lastName
        firstName
        lastNameKana
        firstNameKana
        familyType
        cash
        stocks
        otherAsset
        otherFinancialAsset
        inheritanceSystemFlg
        inheritanceFlg
        inheritancePrice
        inheritancePriceDeduction
        donationDeduction
        donation
        donations
        liability
        continuityInheritanceFlg
        secondaryInheritancePattern
        secondaryHeir
        uniqueAsset
        continuityInheritanceTerm
        propertyData {
          id
          roomId
        }
      }
      inputType
      roaInput {
        centerValue
        inheritanceTaxBase {
          assetsRealEstate {
            salseObjectPoint {
              address
              lotNum
              name
              usageSituation
              landSize
              buildingSize
              acquisitionYear
            }
            bs {
              inheritanceBasePrice {
                land
                building
              }
              ourstandingDebt
            }
            pl {
              income
              spending {
                landPropertyTax
                landCityPlanningTax
                buildingPropertyTax
                buildingCityPlanningTax
                maintenanceFee
              }
            }
            cashflowCalc {
              outstandingDebtTotal
              repaymentType
              repaymentPeriod
              borrowRate
            }
            comment
          }
          assetsNonRealEstate {
            ourstandingDebt
            income
            outstandingDebtTotal
          }
        }
        prevailingPriceBase {
          prevailingPrice {
            land
            building
          }
        }
        comment
        value {
          chartValue {
            x
            y
          }
          inheritanceTaxBase {
            No8
            No10
            No15
            No16
            No18
            No19
            No20
          }
          prevailingPriceBase {
            No8
            No10
            No19
            No20
          }
          baseSelected
        }
        isCalculated
      }
      inheritanceTaxInput {
        houseFixedAssetTax
        houseFixedAssetTaxFlg
        fixedAssetTax
        landMagnification
        realEstateName
        realEstateAddress {
          postalCode
          address1
          address2
          address3
        }
        liveFlag
        landNum
        landType
        commonComment
        ownerName
        ownerAddress {
          postalCode
          address1
          address2
          address3
        }
        userName
        userAddress {
          postalCode
          address1
          address2
          address3
        }
        calcMode
        landSize
        landPrice {
          front
          back
          side1
          side2
        }
        propertyTax {
          tax
          areaRate
        }
        frontageDistance
        depthDistance
        useType
        areaType
        irregularLandFlg
        irregularLandFrontageDistance
        irregularLandDepthDistance
        bigLandFlg
        noRoadLandFlg
        noRoadLandSize
        slantedLandFlg
        slantedLandDirection
        slantedLandSize
        multipleFloorAreaRatioFlg
        multipleFloorAreaRatioDeductionRate
        corner {
          side1Corner
          side2Corner
          backCorner
        }
        setBackFlg
        setBackSize
        farmlandFlag
        changedFlag
        preparationSize
        preparationUnitPrice
        loggingSize1
        loggingSize2
        loggingUnitPrice
        stabilizationSize
        stabilizationUnitPrice
        earthworkSize
        earthworkHight
        earthworkUnitPrice
        sheathingLength
        sheathingHight
        sheathingUnitPrice
        slopeAngle
        cityRoadFlg
        cityRoadFactor
        golfFlg
        golfCost
        rentLandRate
        rentHouseRate
        lendRate
        rentHouseFlg
        lendHouseRate
        topographicMap
        topographicMapDataUrl
        memo
        totalPrice
        resultValue {
          calcArray {
            text
            resultPrice
            resultPriceType
          }
          resultPriceType
          resultPrice
        }
        userAssetInput {
          cash
          lifeInsurance
          car
          etc
        }
        isCalculated
        noRoadLandCheckFlg
      }
      realEstateInput {
        chartData
        inputDate
        siteConditions {
          ownerInformation {
            owner {
              familyName
              name
            }
            ownerType
            estimatedEstateValue
            spouse
            numberOfChildren
            numberOfParentsAlive
            siblingsOfTheDeceased
            spouseRealRatio
            childRealRatio
            parentRealRatio
            siblingRealRatio
          }
          siteInformation {
            surfaceArea
            surfaceAreaUnit
            landLocation
            lotSize
            buildingToLandRatio
            floorAreaRatio
            percentageOfLeaseholdInterest
            roadsideLandPrice
            roadsideLandPriceUnit
            propertyTaxAssessment
            presumption
            availableForSale
            currentUse
            currentIncome
            zoningDistrict
            otherAreas
            landPicture
          }
        }
        locationRequirement {
          name
          memo
          traffic
          landUseInTheVicinity
          guideMap
          concreteApplicationImages {
            vacantLandAndLeasedLand
            monthlyParkingLot
            storeOffice
            groupRental
            groupRentalFamily
            detachedHousesForRent
            sellAndCashOut
          }
        }
        overallPlan {
          rentalProperty {
            checked
            monthlyRent
            numberOfFloors
            numberOfHouses
            annualRepairCosts
            numberOfLevels
            personalFunds
            borrowingRate
            borrowingPeriod
          }
          monthlyParkingLot {
            monthlyRent
            numberOfUnits
            personalFunds
            borrowingRate
            borrowingPeriod
            equityRatio
          }
          storeOffice {
            monthlyRent
            totalFlooraSpace
            personalFunds
            borrowingRate
            borrowingPeriod
            equityRatio
          }
          sale {
            salePrice
            purchasePrice
            agencyFees
            transferIncomeTax
            netProceeds
            acquisitionCost
            periodOfOwnership
            termOfTransferIncomeTax
            priceAtTheTimeOfPurchase
            otherTransferCosts
          }
        }
        isCalculated
      }
      comment
      createdAt
      updatedAt
    }
  }
`;
export const updateAnalyticsInput = /* GraphQL */ `
  mutation UpdateAnalyticsInput(
    $input: UpdateAnalyticsInputInput!
    $condition: ModelAnalyticsInputConditionInput
  ) {
    updateAnalyticsInput(input: $input, condition: $condition) {
      id
      author
      inputTitle
      commonInput {
        reportTitle
        reportDate
        customerName
        customerId
        staffName
        family {
          lastName
          firstName
          lastNameKana
          firstNameKana
          familyType
          inheritanceFlg
          inheritancePrice
          inheritancePriceDeduction
          donationDeduction
          donation
          donations
          liability
          continuityInheritance
          continuityInheritanceFlg
          continuityInheritanceTerm
          secondaryInheritancePattern
          uniqueAsset
          inheritanceSystemFlg
        }
        lastName
        firstName
        lastNameKana
        firstNameKana
        familyType
        cash
        stocks
        otherAsset
        otherFinancialAsset
        inheritanceSystemFlg
        inheritanceFlg
        inheritancePrice
        inheritancePriceDeduction
        donationDeduction
        donation
        donations
        liability
        continuityInheritanceFlg
        secondaryInheritancePattern
        secondaryHeir
        uniqueAsset
        continuityInheritanceTerm
        propertyData {
          id
          roomId
        }
      }
      inputType
      roaInput {
        centerValue
        inheritanceTaxBase {
          assetsRealEstate {
            salseObjectPoint {
              address
              lotNum
              name
              usageSituation
              landSize
              buildingSize
              acquisitionYear
            }
            bs {
              inheritanceBasePrice {
                land
                building
              }
              ourstandingDebt
            }
            pl {
              income
              spending {
                landPropertyTax
                landCityPlanningTax
                buildingPropertyTax
                buildingCityPlanningTax
                maintenanceFee
              }
            }
            cashflowCalc {
              outstandingDebtTotal
              repaymentType
              repaymentPeriod
              borrowRate
            }
            comment
          }
          assetsNonRealEstate {
            ourstandingDebt
            income
            outstandingDebtTotal
          }
        }
        prevailingPriceBase {
          prevailingPrice {
            land
            building
          }
        }
        comment
        value {
          chartValue {
            x
            y
          }
          inheritanceTaxBase {
            No8
            No10
            No15
            No16
            No18
            No19
            No20
          }
          prevailingPriceBase {
            No8
            No10
            No19
            No20
          }
          baseSelected
        }
        isCalculated
      }
      inheritanceTaxInput {
        houseFixedAssetTax
        houseFixedAssetTaxFlg
        fixedAssetTax
        landMagnification
        realEstateName
        realEstateAddress {
          postalCode
          address1
          address2
          address3
        }
        liveFlag
        landNum
        landType
        commonComment
        ownerName
        ownerAddress {
          postalCode
          address1
          address2
          address3
        }
        userName
        userAddress {
          postalCode
          address1
          address2
          address3
        }
        calcMode
        landSize
        landPrice {
          front
          back
          side1
          side2
        }
        propertyTax {
          tax
          areaRate
        }
        frontageDistance
        depthDistance
        useType
        areaType
        irregularLandFlg
        irregularLandFrontageDistance
        irregularLandDepthDistance
        bigLandFlg
        noRoadLandFlg
        noRoadLandSize
        slantedLandFlg
        slantedLandDirection
        slantedLandSize
        multipleFloorAreaRatioFlg
        multipleFloorAreaRatioDeductionRate
        corner {
          side1Corner
          side2Corner
          backCorner
        }
        setBackFlg
        setBackSize
        farmlandFlag
        changedFlag
        preparationSize
        preparationUnitPrice
        loggingSize1
        loggingSize2
        loggingUnitPrice
        stabilizationSize
        stabilizationUnitPrice
        earthworkSize
        earthworkHight
        earthworkUnitPrice
        sheathingLength
        sheathingHight
        sheathingUnitPrice
        slopeAngle
        cityRoadFlg
        cityRoadFactor
        golfFlg
        golfCost
        rentLandRate
        rentHouseRate
        lendRate
        rentHouseFlg
        lendHouseRate
        topographicMap
        topographicMapDataUrl
        memo
        totalPrice
        resultValue {
          calcArray {
            text
            resultPrice
            resultPriceType
          }
          resultPriceType
          resultPrice
        }
        userAssetInput {
          cash
          lifeInsurance
          car
          etc
        }
        isCalculated
        noRoadLandCheckFlg
      }
      realEstateInput {
        chartData
        inputDate
        siteConditions {
          ownerInformation {
            owner {
              familyName
              name
            }
            ownerType
            estimatedEstateValue
            spouse
            numberOfChildren
            numberOfParentsAlive
            siblingsOfTheDeceased
            spouseRealRatio
            childRealRatio
            parentRealRatio
            siblingRealRatio
          }
          siteInformation {
            surfaceArea
            surfaceAreaUnit
            landLocation
            lotSize
            buildingToLandRatio
            floorAreaRatio
            percentageOfLeaseholdInterest
            roadsideLandPrice
            roadsideLandPriceUnit
            propertyTaxAssessment
            presumption
            availableForSale
            currentUse
            currentIncome
            zoningDistrict
            otherAreas
            landPicture
          }
        }
        locationRequirement {
          name
          memo
          traffic
          landUseInTheVicinity
          guideMap
          concreteApplicationImages {
            vacantLandAndLeasedLand
            monthlyParkingLot
            storeOffice
            groupRental
            groupRentalFamily
            detachedHousesForRent
            sellAndCashOut
          }
        }
        overallPlan {
          rentalProperty {
            checked
            monthlyRent
            numberOfFloors
            numberOfHouses
            annualRepairCosts
            numberOfLevels
            personalFunds
            borrowingRate
            borrowingPeriod
          }
          monthlyParkingLot {
            monthlyRent
            numberOfUnits
            personalFunds
            borrowingRate
            borrowingPeriod
            equityRatio
          }
          storeOffice {
            monthlyRent
            totalFlooraSpace
            personalFunds
            borrowingRate
            borrowingPeriod
            equityRatio
          }
          sale {
            salePrice
            purchasePrice
            agencyFees
            transferIncomeTax
            netProceeds
            acquisitionCost
            periodOfOwnership
            termOfTransferIncomeTax
            priceAtTheTimeOfPurchase
            otherTransferCosts
          }
        }
        isCalculated
      }
      comment
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnalyticsInput = /* GraphQL */ `
  mutation DeleteAnalyticsInput(
    $input: DeleteAnalyticsInputInput!
    $condition: ModelAnalyticsInputConditionInput
  ) {
    deleteAnalyticsInput(input: $input, condition: $condition) {
      id
      author
      inputTitle
      commonInput {
        reportTitle
        reportDate
        customerName
        customerId
        staffName
        family {
          lastName
          firstName
          lastNameKana
          firstNameKana
          familyType
          inheritanceFlg
          inheritancePrice
          inheritancePriceDeduction
          donationDeduction
          donation
          donations
          liability
          continuityInheritance
          continuityInheritanceFlg
          continuityInheritanceTerm
          secondaryInheritancePattern
          uniqueAsset
          inheritanceSystemFlg
        }
        lastName
        firstName
        lastNameKana
        firstNameKana
        familyType
        cash
        stocks
        otherAsset
        otherFinancialAsset
        inheritanceSystemFlg
        inheritanceFlg
        inheritancePrice
        inheritancePriceDeduction
        donationDeduction
        donation
        donations
        liability
        continuityInheritanceFlg
        secondaryInheritancePattern
        secondaryHeir
        uniqueAsset
        continuityInheritanceTerm
        propertyData {
          id
          roomId
        }
      }
      inputType
      roaInput {
        centerValue
        inheritanceTaxBase {
          assetsRealEstate {
            salseObjectPoint {
              address
              lotNum
              name
              usageSituation
              landSize
              buildingSize
              acquisitionYear
            }
            bs {
              inheritanceBasePrice {
                land
                building
              }
              ourstandingDebt
            }
            pl {
              income
              spending {
                landPropertyTax
                landCityPlanningTax
                buildingPropertyTax
                buildingCityPlanningTax
                maintenanceFee
              }
            }
            cashflowCalc {
              outstandingDebtTotal
              repaymentType
              repaymentPeriod
              borrowRate
            }
            comment
          }
          assetsNonRealEstate {
            ourstandingDebt
            income
            outstandingDebtTotal
          }
        }
        prevailingPriceBase {
          prevailingPrice {
            land
            building
          }
        }
        comment
        value {
          chartValue {
            x
            y
          }
          inheritanceTaxBase {
            No8
            No10
            No15
            No16
            No18
            No19
            No20
          }
          prevailingPriceBase {
            No8
            No10
            No19
            No20
          }
          baseSelected
        }
        isCalculated
      }
      inheritanceTaxInput {
        houseFixedAssetTax
        houseFixedAssetTaxFlg
        fixedAssetTax
        landMagnification
        realEstateName
        realEstateAddress {
          postalCode
          address1
          address2
          address3
        }
        liveFlag
        landNum
        landType
        commonComment
        ownerName
        ownerAddress {
          postalCode
          address1
          address2
          address3
        }
        userName
        userAddress {
          postalCode
          address1
          address2
          address3
        }
        calcMode
        landSize
        landPrice {
          front
          back
          side1
          side2
        }
        propertyTax {
          tax
          areaRate
        }
        frontageDistance
        depthDistance
        useType
        areaType
        irregularLandFlg
        irregularLandFrontageDistance
        irregularLandDepthDistance
        bigLandFlg
        noRoadLandFlg
        noRoadLandSize
        slantedLandFlg
        slantedLandDirection
        slantedLandSize
        multipleFloorAreaRatioFlg
        multipleFloorAreaRatioDeductionRate
        corner {
          side1Corner
          side2Corner
          backCorner
        }
        setBackFlg
        setBackSize
        farmlandFlag
        changedFlag
        preparationSize
        preparationUnitPrice
        loggingSize1
        loggingSize2
        loggingUnitPrice
        stabilizationSize
        stabilizationUnitPrice
        earthworkSize
        earthworkHight
        earthworkUnitPrice
        sheathingLength
        sheathingHight
        sheathingUnitPrice
        slopeAngle
        cityRoadFlg
        cityRoadFactor
        golfFlg
        golfCost
        rentLandRate
        rentHouseRate
        lendRate
        rentHouseFlg
        lendHouseRate
        topographicMap
        topographicMapDataUrl
        memo
        totalPrice
        resultValue {
          calcArray {
            text
            resultPrice
            resultPriceType
          }
          resultPriceType
          resultPrice
        }
        userAssetInput {
          cash
          lifeInsurance
          car
          etc
        }
        isCalculated
        noRoadLandCheckFlg
      }
      realEstateInput {
        chartData
        inputDate
        siteConditions {
          ownerInformation {
            owner {
              familyName
              name
            }
            ownerType
            estimatedEstateValue
            spouse
            numberOfChildren
            numberOfParentsAlive
            siblingsOfTheDeceased
            spouseRealRatio
            childRealRatio
            parentRealRatio
            siblingRealRatio
          }
          siteInformation {
            surfaceArea
            surfaceAreaUnit
            landLocation
            lotSize
            buildingToLandRatio
            floorAreaRatio
            percentageOfLeaseholdInterest
            roadsideLandPrice
            roadsideLandPriceUnit
            propertyTaxAssessment
            presumption
            availableForSale
            currentUse
            currentIncome
            zoningDistrict
            otherAreas
            landPicture
          }
        }
        locationRequirement {
          name
          memo
          traffic
          landUseInTheVicinity
          guideMap
          concreteApplicationImages {
            vacantLandAndLeasedLand
            monthlyParkingLot
            storeOffice
            groupRental
            groupRentalFamily
            detachedHousesForRent
            sellAndCashOut
          }
        }
        overallPlan {
          rentalProperty {
            checked
            monthlyRent
            numberOfFloors
            numberOfHouses
            annualRepairCosts
            numberOfLevels
            personalFunds
            borrowingRate
            borrowingPeriod
          }
          monthlyParkingLot {
            monthlyRent
            numberOfUnits
            personalFunds
            borrowingRate
            borrowingPeriod
            equityRatio
          }
          storeOffice {
            monthlyRent
            totalFlooraSpace
            personalFunds
            borrowingRate
            borrowingPeriod
            equityRatio
          }
          sale {
            salePrice
            purchasePrice
            agencyFees
            transferIncomeTax
            netProceeds
            acquisitionCost
            periodOfOwnership
            termOfTransferIncomeTax
            priceAtTheTimeOfPurchase
            otherTransferCosts
          }
        }
        isCalculated
      }
      comment
      createdAt
      updatedAt
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      customerNumber
      companyId
      companyName
      bossLastName
      bossFirstName
      tel
      mail
      address {
        postalCode
        address1
        address2
        address3
      }
      qualification
      registrationNum
      companyLogo
      deleteDate
      license {
        id
        period {
          quantity
          unit
        }
        quantity
        amount
        tax
        startDate
        endDate
        paymentId
        isRenewAutomaticaly
        isValid
        initialPurchaseDate
        cancelDate
        cancelReason
      }
      payment {
        id
        payType
        orderId
        recurringId
        purchaseDate
        billingDate
        amount
        tax
        paymentResult
        invoice
        depositRefundInfo {
          status
          amount
          memo
          adjustmentDate
        }
      }
      payType
      bankTransfer {
        orderId
        accessID
        accessPass
        bankCode
        bankName
        branchCode
        branchName
        accountType
        accountNumber
        accountHolderName
      }
      deposit {
        transferDate
        transferName
        transferBankName
        transferBranchName
        transferAmount
      }
      memo
      accountTransfer {
        transactionId
      }
      paymentChangeReservation {
        changeDate
        payType
        reservationDate
      }
      privilegedFlag
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      customerNumber
      companyId
      companyName
      bossLastName
      bossFirstName
      tel
      mail
      address {
        postalCode
        address1
        address2
        address3
      }
      qualification
      registrationNum
      companyLogo
      deleteDate
      license {
        id
        period {
          quantity
          unit
        }
        quantity
        amount
        tax
        startDate
        endDate
        paymentId
        isRenewAutomaticaly
        isValid
        initialPurchaseDate
        cancelDate
        cancelReason
      }
      payment {
        id
        payType
        orderId
        recurringId
        purchaseDate
        billingDate
        amount
        tax
        paymentResult
        invoice
        depositRefundInfo {
          status
          amount
          memo
          adjustmentDate
        }
      }
      payType
      bankTransfer {
        orderId
        accessID
        accessPass
        bankCode
        bankName
        branchCode
        branchName
        accountType
        accountNumber
        accountHolderName
      }
      deposit {
        transferDate
        transferName
        transferBankName
        transferBranchName
        transferAmount
      }
      memo
      accountTransfer {
        transactionId
      }
      paymentChangeReservation {
        changeDate
        payType
        reservationDate
      }
      privilegedFlag
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      customerNumber
      companyId
      companyName
      bossLastName
      bossFirstName
      tel
      mail
      address {
        postalCode
        address1
        address2
        address3
      }
      qualification
      registrationNum
      companyLogo
      deleteDate
      license {
        id
        period {
          quantity
          unit
        }
        quantity
        amount
        tax
        startDate
        endDate
        paymentId
        isRenewAutomaticaly
        isValid
        initialPurchaseDate
        cancelDate
        cancelReason
      }
      payment {
        id
        payType
        orderId
        recurringId
        purchaseDate
        billingDate
        amount
        tax
        paymentResult
        invoice
        depositRefundInfo {
          status
          amount
          memo
          adjustmentDate
        }
      }
      payType
      bankTransfer {
        orderId
        accessID
        accessPass
        bankCode
        bankName
        branchCode
        branchName
        accountType
        accountNumber
        accountHolderName
      }
      deposit {
        transferDate
        transferName
        transferBankName
        transferBranchName
        transferAmount
      }
      memo
      accountTransfer {
        transactionId
      }
      paymentChangeReservation {
        changeDate
        payType
        reservationDate
      }
      privilegedFlag
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyMaster = /* GraphQL */ `
  mutation CreateCompanyMaster(
    $input: CreateCompanyMasterInput!
    $condition: ModelcompanyMasterConditionInput
  ) {
    createCompanyMaster(input: $input, condition: $condition) {
      companyId
      classification
      classificationDetail
      masterName
      version
      editability {
        subdivision
        editLine {
          permission
          terms
        }
        editItem {
          key
          type
          digits
          terms
        }
      }
      value
      year
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyMaster = /* GraphQL */ `
  mutation UpdateCompanyMaster(
    $input: UpdateCompanyMasterInput!
    $condition: ModelcompanyMasterConditionInput
  ) {
    updateCompanyMaster(input: $input, condition: $condition) {
      companyId
      classification
      classificationDetail
      masterName
      version
      editability {
        subdivision
        editLine {
          permission
          terms
        }
        editItem {
          key
          type
          digits
          terms
        }
      }
      value
      year
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyMaster = /* GraphQL */ `
  mutation DeleteCompanyMaster(
    $input: DeleteCompanyMasterInput!
    $condition: ModelcompanyMasterConditionInput
  ) {
    deleteCompanyMaster(input: $input, condition: $condition) {
      companyId
      classification
      classificationDetail
      masterName
      version
      editability {
        subdivision
        editLine {
          permission
          terms
        }
        editItem {
          key
          type
          digits
          terms
        }
      }
      value
      year
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      customerType
      customerName
      department
      lastName
      firstName
      lastNameKana
      firstNameKana
      tel
      phone
      fax
      mail
      gender
      birthday
      comment
      address {
        postalCode
        address1
        address2
        address3
      }
      family {
        lastName
        firstName
        lastNameKana
        firstNameKana
        tel
        phone
        mail
        gender
        birthday
        comment
        familyType
        businessCard
        companyInfo {
          companyName
          position
          tel
          mail
          fax
        }
      }
      businessCard
      companyInfo {
        companyName
        position
        tel
        mail
        fax
      }
      company
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      customerType
      customerName
      department
      lastName
      firstName
      lastNameKana
      firstNameKana
      tel
      phone
      fax
      mail
      gender
      birthday
      comment
      address {
        postalCode
        address1
        address2
        address3
      }
      family {
        lastName
        firstName
        lastNameKana
        firstNameKana
        tel
        phone
        mail
        gender
        birthday
        comment
        familyType
        businessCard
        companyInfo {
          companyName
          position
          tel
          mail
          fax
        }
      }
      businessCard
      companyInfo {
        companyName
        position
        tel
        mail
        fax
      }
      company
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      customerType
      customerName
      department
      lastName
      firstName
      lastNameKana
      firstNameKana
      tel
      phone
      fax
      mail
      gender
      birthday
      comment
      address {
        postalCode
        address1
        address2
        address3
      }
      family {
        lastName
        firstName
        lastNameKana
        firstNameKana
        tel
        phone
        mail
        gender
        birthday
        comment
        familyType
        businessCard
        companyInfo {
          companyName
          position
          tel
          mail
          fax
        }
      }
      businessCard
      companyInfo {
        companyName
        position
        tel
        mail
        fax
      }
      company
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerNumber = /* GraphQL */ `
  mutation CreateCustomerNumber(
    $input: CreateCustomerNumberInput!
    $condition: ModelCustomerNumberConditionInput
  ) {
    createCustomerNumber(input: $input, condition: $condition) {
      prefecturesCode
      prefecturesName
      serialCode
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerNumber = /* GraphQL */ `
  mutation UpdateCustomerNumber(
    $input: UpdateCustomerNumberInput!
    $condition: ModelCustomerNumberConditionInput
  ) {
    updateCustomerNumber(input: $input, condition: $condition) {
      prefecturesCode
      prefecturesName
      serialCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerNumber = /* GraphQL */ `
  mutation DeleteCustomerNumber(
    $input: DeleteCustomerNumberInput!
    $condition: ModelCustomerNumberConditionInput
  ) {
    deleteCustomerNumber(input: $input, condition: $condition) {
      prefecturesCode
      prefecturesName
      serialCode
      createdAt
      updatedAt
    }
  }
`;
export const createDeleteQue = /* GraphQL */ `
  mutation CreateDeleteQue(
    $input: CreateDeleteQueInput!
    $condition: ModelDeleteQueConditionInput
  ) {
    createDeleteQue(input: $input, condition: $condition) {
      id
      companyName
      bossLastName
      bossFirstName
      tel
      mail
      address {
        postalCode
        address1
        address2
        address3
      }
      qualification
      registrationNum
      companyLogo
      accountNum
      deleteFlg
      createdAt
      updatedAt
    }
  }
`;
export const updateDeleteQue = /* GraphQL */ `
  mutation UpdateDeleteQue(
    $input: UpdateDeleteQueInput!
    $condition: ModelDeleteQueConditionInput
  ) {
    updateDeleteQue(input: $input, condition: $condition) {
      id
      companyName
      bossLastName
      bossFirstName
      tel
      mail
      address {
        postalCode
        address1
        address2
        address3
      }
      qualification
      registrationNum
      companyLogo
      accountNum
      deleteFlg
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeleteQue = /* GraphQL */ `
  mutation DeleteDeleteQue(
    $input: DeleteDeleteQueInput!
    $condition: ModelDeleteQueConditionInput
  ) {
    deleteDeleteQue(input: $input, condition: $condition) {
      id
      companyName
      bossLastName
      bossFirstName
      tel
      mail
      address {
        postalCode
        address1
        address2
        address3
      }
      qualification
      registrationNum
      companyLogo
      accountNum
      deleteFlg
      createdAt
      updatedAt
    }
  }
`;
export const createGroundFloorPlan = /* GraphQL */ `
  mutation CreateGroundFloorPlan(
    $input: CreateGroundFloorPlanInput!
    $condition: ModelGroundFloorPlanConditionInput
  ) {
    createGroundFloorPlan(input: $input, condition: $condition) {
      id
      companyId
      propertyId
      roomId
      ownerId
      ownerName
      guestIdList
      planeName
      objectSnapshots {
        id
        type
        subType
        config {
          stroke
          strokeWidth
          strokeLinecap
          fill
          patternWidth
          patternOffsetX
          patternOffsetY
          preserveAspectRatio
          imageUrl
          textTranslate {
            x
            y
          }
          areaSizeUnit
          customText
          ratio
          flipHorizontal
          flipVertical
          iconUrl
          layoutName
        }
        vertexes {
          x
          y
        }
        arcPoints
        transform {
          rotate
          translate
          scale
        }
        children
        width
        height
        height3d
        depth
        index
        wallType
        walls {
          id
          type
          subType
          config {
            stroke
            strokeWidth
            strokeLinecap
            fill
            patternWidth
            patternOffsetX
            patternOffsetY
            preserveAspectRatio
            imageUrl
            textTranslate {
              x
              y
            }
            areaSizeUnit
            customText
            ratio
            flipHorizontal
            flipVertical
            iconUrl
            layoutName
          }
          vertexes {
            x
            y
          }
          arcPoints
          transform {
            rotate
            translate
            scale
          }
          children
          width
          height
          height3d
          depth
          index
          wallType
          walls {
            id
            type
            subType
            config {
              stroke
              strokeWidth
              strokeLinecap
              fill
              patternWidth
              patternOffsetX
              patternOffsetY
              preserveAspectRatio
              imageUrl
              textTranslate {
                x
                y
              }
              areaSizeUnit
              customText
              ratio
              flipHorizontal
              flipVertical
              iconUrl
              layoutName
            }
            vertexes {
              x
              y
            }
            arcPoints
            transform {
              rotate
              translate
              scale
            }
            children
            width
            height
            height3d
            depth
            index
            wallType
            walls {
              id
              type
              subType
              config {
                stroke
                strokeWidth
                strokeLinecap
                fill
                patternWidth
                patternOffsetX
                patternOffsetY
                preserveAspectRatio
                imageUrl
                textTranslate {
                  x
                  y
                }
                areaSizeUnit
                customText
                ratio
                flipHorizontal
                flipVertical
                iconUrl
                layoutName
              }
              vertexes {
                x
                y
              }
              arcPoints
              transform {
                rotate
                translate
                scale
              }
              children
              width
              height
              height3d
              depth
              index
              wallType
              walls {
                id
                type
                subType
                config {
                  stroke
                  strokeWidth
                  strokeLinecap
                  fill
                  patternWidth
                  patternOffsetX
                  patternOffsetY
                  preserveAspectRatio
                  imageUrl
                  textTranslate {
                    x
                    y
                  }
                  areaSizeUnit
                  customText
                  ratio
                  flipHorizontal
                  flipVertical
                  iconUrl
                  layoutName
                }
                vertexes {
                  x
                  y
                }
                arcPoints
                transform {
                  rotate
                  translate
                  scale
                }
                children
                width
                height
                height3d
                depth
                index
                wallType
                walls {
                  id
                  type
                  subType
                  config {
                    stroke
                    strokeWidth
                    strokeLinecap
                    fill
                    patternWidth
                    patternOffsetX
                    patternOffsetY
                    preserveAspectRatio
                    imageUrl
                    textTranslate {
                      x
                      y
                    }
                    areaSizeUnit
                    customText
                    ratio
                    flipHorizontal
                    flipVertical
                    iconUrl
                    layoutName
                  }
                  vertexes {
                    x
                    y
                  }
                  arcPoints
                  transform {
                    rotate
                    translate
                    scale
                  }
                  children
                  width
                  height
                  height3d
                  depth
                  index
                  wallType
                  walls {
                    id
                    type
                    subType
                    config {
                      stroke
                      strokeWidth
                      strokeLinecap
                      fill
                      patternWidth
                      patternOffsetX
                      patternOffsetY
                      preserveAspectRatio
                      imageUrl
                      textTranslate {
                        x
                        y
                      }
                      areaSizeUnit
                      customText
                      ratio
                      flipHorizontal
                      flipVertical
                      iconUrl
                      layoutName
                    }
                    vertexes {
                      x
                      y
                    }
                    arcPoints
                    transform {
                      rotate
                      translate
                      scale
                    }
                    children
                    width
                    height
                    height3d
                    depth
                    index
                    wallType
                    walls {
                      id
                      type
                      subType
                      config {
                        stroke
                        strokeWidth
                        strokeLinecap
                        fill
                        patternWidth
                        patternOffsetX
                        patternOffsetY
                        preserveAspectRatio
                        imageUrl
                        areaSizeUnit
                        customText
                        ratio
                        flipHorizontal
                        flipVertical
                        iconUrl
                        layoutName
                      }
                      vertexes {
                        x
                        y
                      }
                      arcPoints
                      transform {
                        rotate
                        translate
                        scale
                      }
                      children
                      width
                      height
                      height3d
                      depth
                      index
                      wallType
                      walls {
                        id
                        type
                        subType
                        arcPoints
                        children
                        width
                        height
                        height3d
                        depth
                        index
                        wallType
                      }
                    }
                    ctrlPoints {
                      start {
                        x
                        y
                      }
                      end {
                        x
                        y
                      }
                    }
                  }
                  ctrlPoints {
                    start {
                      x
                      y
                    }
                    end {
                      x
                      y
                    }
                  }
                }
                ctrlPoints {
                  start {
                    x
                    y
                  }
                  end {
                    x
                    y
                  }
                }
              }
              ctrlPoints {
                start {
                  x
                  y
                }
                end {
                  x
                  y
                }
              }
            }
            ctrlPoints {
              start {
                x
                y
              }
              end {
                x
                y
              }
            }
          }
          ctrlPoints {
            start {
              x
              y
            }
            end {
              x
              y
            }
          }
        }
        ctrlPoints {
          start {
            x
            y
          }
          end {
            x
            y
          }
        }
      }
      editorSetting {
        width
        height
        compass
        rotate
      }
      imageName
      updateDate
      updatePerson
      deleted
      deleteDate
      deletePerson
      output
      outputDate
      outputPerson
      restored
      restoreDate
      restorePerson
      createdAt
      updatedAt
    }
  }
`;
export const updateGroundFloorPlan = /* GraphQL */ `
  mutation UpdateGroundFloorPlan(
    $input: UpdateGroundFloorPlanInput!
    $condition: ModelGroundFloorPlanConditionInput
  ) {
    updateGroundFloorPlan(input: $input, condition: $condition) {
      id
      companyId
      propertyId
      roomId
      ownerId
      ownerName
      guestIdList
      planeName
      objectSnapshots {
        id
        type
        subType
        config {
          stroke
          strokeWidth
          strokeLinecap
          fill
          patternWidth
          patternOffsetX
          patternOffsetY
          preserveAspectRatio
          imageUrl
          textTranslate {
            x
            y
          }
          areaSizeUnit
          customText
          ratio
          flipHorizontal
          flipVertical
          iconUrl
          layoutName
        }
        vertexes {
          x
          y
        }
        arcPoints
        transform {
          rotate
          translate
          scale
        }
        children
        width
        height
        height3d
        depth
        index
        wallType
        walls {
          id
          type
          subType
          config {
            stroke
            strokeWidth
            strokeLinecap
            fill
            patternWidth
            patternOffsetX
            patternOffsetY
            preserveAspectRatio
            imageUrl
            textTranslate {
              x
              y
            }
            areaSizeUnit
            customText
            ratio
            flipHorizontal
            flipVertical
            iconUrl
            layoutName
          }
          vertexes {
            x
            y
          }
          arcPoints
          transform {
            rotate
            translate
            scale
          }
          children
          width
          height
          height3d
          depth
          index
          wallType
          walls {
            id
            type
            subType
            config {
              stroke
              strokeWidth
              strokeLinecap
              fill
              patternWidth
              patternOffsetX
              patternOffsetY
              preserveAspectRatio
              imageUrl
              textTranslate {
                x
                y
              }
              areaSizeUnit
              customText
              ratio
              flipHorizontal
              flipVertical
              iconUrl
              layoutName
            }
            vertexes {
              x
              y
            }
            arcPoints
            transform {
              rotate
              translate
              scale
            }
            children
            width
            height
            height3d
            depth
            index
            wallType
            walls {
              id
              type
              subType
              config {
                stroke
                strokeWidth
                strokeLinecap
                fill
                patternWidth
                patternOffsetX
                patternOffsetY
                preserveAspectRatio
                imageUrl
                textTranslate {
                  x
                  y
                }
                areaSizeUnit
                customText
                ratio
                flipHorizontal
                flipVertical
                iconUrl
                layoutName
              }
              vertexes {
                x
                y
              }
              arcPoints
              transform {
                rotate
                translate
                scale
              }
              children
              width
              height
              height3d
              depth
              index
              wallType
              walls {
                id
                type
                subType
                config {
                  stroke
                  strokeWidth
                  strokeLinecap
                  fill
                  patternWidth
                  patternOffsetX
                  patternOffsetY
                  preserveAspectRatio
                  imageUrl
                  textTranslate {
                    x
                    y
                  }
                  areaSizeUnit
                  customText
                  ratio
                  flipHorizontal
                  flipVertical
                  iconUrl
                  layoutName
                }
                vertexes {
                  x
                  y
                }
                arcPoints
                transform {
                  rotate
                  translate
                  scale
                }
                children
                width
                height
                height3d
                depth
                index
                wallType
                walls {
                  id
                  type
                  subType
                  config {
                    stroke
                    strokeWidth
                    strokeLinecap
                    fill
                    patternWidth
                    patternOffsetX
                    patternOffsetY
                    preserveAspectRatio
                    imageUrl
                    textTranslate {
                      x
                      y
                    }
                    areaSizeUnit
                    customText
                    ratio
                    flipHorizontal
                    flipVertical
                    iconUrl
                    layoutName
                  }
                  vertexes {
                    x
                    y
                  }
                  arcPoints
                  transform {
                    rotate
                    translate
                    scale
                  }
                  children
                  width
                  height
                  height3d
                  depth
                  index
                  wallType
                  walls {
                    id
                    type
                    subType
                    config {
                      stroke
                      strokeWidth
                      strokeLinecap
                      fill
                      patternWidth
                      patternOffsetX
                      patternOffsetY
                      preserveAspectRatio
                      imageUrl
                      textTranslate {
                        x
                        y
                      }
                      areaSizeUnit
                      customText
                      ratio
                      flipHorizontal
                      flipVertical
                      iconUrl
                      layoutName
                    }
                    vertexes {
                      x
                      y
                    }
                    arcPoints
                    transform {
                      rotate
                      translate
                      scale
                    }
                    children
                    width
                    height
                    height3d
                    depth
                    index
                    wallType
                    walls {
                      id
                      type
                      subType
                      config {
                        stroke
                        strokeWidth
                        strokeLinecap
                        fill
                        patternWidth
                        patternOffsetX
                        patternOffsetY
                        preserveAspectRatio
                        imageUrl
                        areaSizeUnit
                        customText
                        ratio
                        flipHorizontal
                        flipVertical
                        iconUrl
                        layoutName
                      }
                      vertexes {
                        x
                        y
                      }
                      arcPoints
                      transform {
                        rotate
                        translate
                        scale
                      }
                      children
                      width
                      height
                      height3d
                      depth
                      index
                      wallType
                      walls {
                        id
                        type
                        subType
                        arcPoints
                        children
                        width
                        height
                        height3d
                        depth
                        index
                        wallType
                      }
                    }
                    ctrlPoints {
                      start {
                        x
                        y
                      }
                      end {
                        x
                        y
                      }
                    }
                  }
                  ctrlPoints {
                    start {
                      x
                      y
                    }
                    end {
                      x
                      y
                    }
                  }
                }
                ctrlPoints {
                  start {
                    x
                    y
                  }
                  end {
                    x
                    y
                  }
                }
              }
              ctrlPoints {
                start {
                  x
                  y
                }
                end {
                  x
                  y
                }
              }
            }
            ctrlPoints {
              start {
                x
                y
              }
              end {
                x
                y
              }
            }
          }
          ctrlPoints {
            start {
              x
              y
            }
            end {
              x
              y
            }
          }
        }
        ctrlPoints {
          start {
            x
            y
          }
          end {
            x
            y
          }
        }
      }
      editorSetting {
        width
        height
        compass
        rotate
      }
      imageName
      updateDate
      updatePerson
      deleted
      deleteDate
      deletePerson
      output
      outputDate
      outputPerson
      restored
      restoreDate
      restorePerson
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroundFloorPlan = /* GraphQL */ `
  mutation DeleteGroundFloorPlan(
    $input: DeleteGroundFloorPlanInput!
    $condition: ModelGroundFloorPlanConditionInput
  ) {
    deleteGroundFloorPlan(input: $input, condition: $condition) {
      id
      companyId
      propertyId
      roomId
      ownerId
      ownerName
      guestIdList
      planeName
      objectSnapshots {
        id
        type
        subType
        config {
          stroke
          strokeWidth
          strokeLinecap
          fill
          patternWidth
          patternOffsetX
          patternOffsetY
          preserveAspectRatio
          imageUrl
          textTranslate {
            x
            y
          }
          areaSizeUnit
          customText
          ratio
          flipHorizontal
          flipVertical
          iconUrl
          layoutName
        }
        vertexes {
          x
          y
        }
        arcPoints
        transform {
          rotate
          translate
          scale
        }
        children
        width
        height
        height3d
        depth
        index
        wallType
        walls {
          id
          type
          subType
          config {
            stroke
            strokeWidth
            strokeLinecap
            fill
            patternWidth
            patternOffsetX
            patternOffsetY
            preserveAspectRatio
            imageUrl
            textTranslate {
              x
              y
            }
            areaSizeUnit
            customText
            ratio
            flipHorizontal
            flipVertical
            iconUrl
            layoutName
          }
          vertexes {
            x
            y
          }
          arcPoints
          transform {
            rotate
            translate
            scale
          }
          children
          width
          height
          height3d
          depth
          index
          wallType
          walls {
            id
            type
            subType
            config {
              stroke
              strokeWidth
              strokeLinecap
              fill
              patternWidth
              patternOffsetX
              patternOffsetY
              preserveAspectRatio
              imageUrl
              textTranslate {
                x
                y
              }
              areaSizeUnit
              customText
              ratio
              flipHorizontal
              flipVertical
              iconUrl
              layoutName
            }
            vertexes {
              x
              y
            }
            arcPoints
            transform {
              rotate
              translate
              scale
            }
            children
            width
            height
            height3d
            depth
            index
            wallType
            walls {
              id
              type
              subType
              config {
                stroke
                strokeWidth
                strokeLinecap
                fill
                patternWidth
                patternOffsetX
                patternOffsetY
                preserveAspectRatio
                imageUrl
                textTranslate {
                  x
                  y
                }
                areaSizeUnit
                customText
                ratio
                flipHorizontal
                flipVertical
                iconUrl
                layoutName
              }
              vertexes {
                x
                y
              }
              arcPoints
              transform {
                rotate
                translate
                scale
              }
              children
              width
              height
              height3d
              depth
              index
              wallType
              walls {
                id
                type
                subType
                config {
                  stroke
                  strokeWidth
                  strokeLinecap
                  fill
                  patternWidth
                  patternOffsetX
                  patternOffsetY
                  preserveAspectRatio
                  imageUrl
                  textTranslate {
                    x
                    y
                  }
                  areaSizeUnit
                  customText
                  ratio
                  flipHorizontal
                  flipVertical
                  iconUrl
                  layoutName
                }
                vertexes {
                  x
                  y
                }
                arcPoints
                transform {
                  rotate
                  translate
                  scale
                }
                children
                width
                height
                height3d
                depth
                index
                wallType
                walls {
                  id
                  type
                  subType
                  config {
                    stroke
                    strokeWidth
                    strokeLinecap
                    fill
                    patternWidth
                    patternOffsetX
                    patternOffsetY
                    preserveAspectRatio
                    imageUrl
                    textTranslate {
                      x
                      y
                    }
                    areaSizeUnit
                    customText
                    ratio
                    flipHorizontal
                    flipVertical
                    iconUrl
                    layoutName
                  }
                  vertexes {
                    x
                    y
                  }
                  arcPoints
                  transform {
                    rotate
                    translate
                    scale
                  }
                  children
                  width
                  height
                  height3d
                  depth
                  index
                  wallType
                  walls {
                    id
                    type
                    subType
                    config {
                      stroke
                      strokeWidth
                      strokeLinecap
                      fill
                      patternWidth
                      patternOffsetX
                      patternOffsetY
                      preserveAspectRatio
                      imageUrl
                      textTranslate {
                        x
                        y
                      }
                      areaSizeUnit
                      customText
                      ratio
                      flipHorizontal
                      flipVertical
                      iconUrl
                      layoutName
                    }
                    vertexes {
                      x
                      y
                    }
                    arcPoints
                    transform {
                      rotate
                      translate
                      scale
                    }
                    children
                    width
                    height
                    height3d
                    depth
                    index
                    wallType
                    walls {
                      id
                      type
                      subType
                      config {
                        stroke
                        strokeWidth
                        strokeLinecap
                        fill
                        patternWidth
                        patternOffsetX
                        patternOffsetY
                        preserveAspectRatio
                        imageUrl
                        areaSizeUnit
                        customText
                        ratio
                        flipHorizontal
                        flipVertical
                        iconUrl
                        layoutName
                      }
                      vertexes {
                        x
                        y
                      }
                      arcPoints
                      transform {
                        rotate
                        translate
                        scale
                      }
                      children
                      width
                      height
                      height3d
                      depth
                      index
                      wallType
                      walls {
                        id
                        type
                        subType
                        arcPoints
                        children
                        width
                        height
                        height3d
                        depth
                        index
                        wallType
                      }
                    }
                    ctrlPoints {
                      start {
                        x
                        y
                      }
                      end {
                        x
                        y
                      }
                    }
                  }
                  ctrlPoints {
                    start {
                      x
                      y
                    }
                    end {
                      x
                      y
                    }
                  }
                }
                ctrlPoints {
                  start {
                    x
                    y
                  }
                  end {
                    x
                    y
                  }
                }
              }
              ctrlPoints {
                start {
                  x
                  y
                }
                end {
                  x
                  y
                }
              }
            }
            ctrlPoints {
              start {
                x
                y
              }
              end {
                x
                y
              }
            }
          }
          ctrlPoints {
            start {
              x
              y
            }
            end {
              x
              y
            }
          }
        }
        ctrlPoints {
          start {
            x
            y
          }
          end {
            x
            y
          }
        }
      }
      editorSetting {
        width
        height
        compass
        rotate
      }
      imageName
      updateDate
      updatePerson
      deleted
      deleteDate
      deletePerson
      output
      outputDate
      outputPerson
      restored
      restoreDate
      restorePerson
      createdAt
      updatedAt
    }
  }
`;
export const createInvestmentAnalytics = /* GraphQL */ `
  mutation CreateInvestmentAnalytics(
    $input: CreateInvestmentAnalyticsInput!
    $condition: ModelInvestmentAnalyticsConditionInput
  ) {
    createInvestmentAnalytics(input: $input, condition: $condition) {
      id
      inputDate
      existingBuildingCount
      newBuildingCount
      initialInvestment {
        propertyDescription {
          location
          address {
            postalCode
            address1
            address2
            address3
          }
          quantity
          structure
          kind
          purpose
          area
          dateOfConstruction
          price
        }
        tradingPrice
        totalTradingPrice
        propertyTaxValuation
        propertyTax
        cityPlanningTax
        propertyTaxRate
        cityPlanningTaxRate
        constructionPlan {
          selected
          newConstructionCost
          repairWorkCost
          equipmentUpdateCost
          repairWorkCostDetails {
            content
            quantity
            unitPrice
            amount
          }
          equipmentUpdateCostDetails {
            content
            quantity
            unitPrice
            amount
          }
          constructionCost {
            content
            quantity
            unitPrice
            amount
          }
          constructionInciDentalWork
          otherConstructionFee
          constructionReserves
          demolitionCount
        }
        incidentalExpenses
        consultingCost {
          content
          quantity
          unitPrice
          amount
        }
        otherConsultingCost
        otherCostDetails {
          content
          quantity
          unitPrice
          amount
        }
        estimatedConsultingCost
        isDetailConsultingCost
        designCost {
          content
          quantity
          unitPrice
          amount
        }
        estimatedDesignCost
        isDetailDesignCost
        surveyCost {
          content
          quantity
          unitPrice
          amount
        }
        estimatedSurveyCost
        isDetailSurveyCost
        licensingCost {
          content
          quantity
          unitPrice
          amount
        }
        estimatedLicensingCost
        isDetailLicensingCost
        contributionsAndDeposits {
          content
          quantity
          unitPrice
          amount
        }
        estimatedContributions
        estimatedDeposits
        isDetailContributionsAndDeposits
        stampDuty
        isChangedStampDuty
        estimatedStampDuty
        isDetailStampDuty
        realEstateAcquisitionTax
        isChangedRealEstateAcquisitionTax
        registrationTax {
          causeOfRegistration
          isChanged
          amount
          numberOfAdditionalMortgages
          numberOfRegisteredRecordsSurveyed
        }
        paymentFees {
          numberOfCases
          buildingRepresentationRegistration {
            content
            quantity
            unitPrice
            amount
          }
          leaseBrokerageFee
          bankGuarantee
          estimatedPaymentFee
          isDetail
          isChanged
          amount
        }
        preCareerCommitment {
          monthlyRent
          affordability
          duration
          monthlyOccupancyFee
          interestBeforeStartingBusiness
          isChanged
          borrowingRate
          approximateValue
          isDetail
        }
        insuranceFees
        otherExpenses {
          ritualCost
          neighborhoodWatchCosts {
            content
            quantity
            unitPrice
            amount
          }
          evictionFeesQuantity
          estimatedEvictionFees
          businessCompensation {
            content
            quantity
            unitPrice
            amount
          }
          estimatedBusinessCompensation
          advertisingExpensesDetails {
            content
            quantity
            unitPrice
            amount
          }
          advertisingExpenses
          specialExpenses {
            content
            quantity
            unitPrice
            amount
          }
          estimatedSpecialExpenses
          miscellaneousExpenses
          reserveRate
          estimatedReserveFund
          estimatedOtherExpenses
          isDetail
        }
        capitalPlan {
          deposit
          constructionCooperationFund
          securityDeposit
          keyMoney
          ownResources
          loans
          borrowedAmount
          adjustment
          borrowingRateApplicationYears
          borrowingRate
          repaymentPeriod
          repaymentMethod
          redemptionPeriod
        }
        memo
      }
      inputTitle
      reportName
      ownerName
      ownerId
      staffName
      singleYearPlan {
        incomePlan {
          rent {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          commonServiceFees {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          parkingFees {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          landRentalFees {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          others {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          itemNames
        }
        spendingPlan {
          monthlyRent
          expenditureItems {
            content
            quantity
            unitPrice
            amount
          }
          managementFeeType
          managementFeeValue
          numberOfRooms
          reserveRate
          buildingMaintenance {
            content
            quantity
            unitPrice
            amount
          }
          otherUnitPrice
          isDetail
          approximateValue
        }
      }
      longTermRepairPlan {
        majorRenovation {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        equipmentRenewal {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        externalStructure {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        others {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        isApproximation
        approximations {
          name
          frequency
          cost
        }
      }
      salePlan {
        timeOfSale
        breakdownOfSalePrice {
          land
          building
          buildingDetail
          depreciatedAssets
          assumedLandPriceIncrease
        }
        expensesAtTheTimeOfSale {
          demolition {
            total
            details {
              content
              quantity
              unitPrice
              amount
            }
            isDetail
          }
          repairAndValueUpWork {
            total
            details {
              content
              quantity
              unitPrice
              amount
            }
            isDetail
          }
          surveyingCosts {
            content
            quantity
            unitPrice
            amount
          }
          soilContaminationSurvey
          asbestosUseStudy
          equipmentFunctionSurvey
          inspection
          otherSurveys
          reserveRate
        }
        registrationFees
        evictionFees
        businessCompensation {
          content
          quantity
          unitPrice
          amount
        }
        contractCosts
        paymentFees
        consultingFees {
          content
          quantity
          unitPrice
          amount
        }
        salesAdvertisingCosts {
          content
          quantity
          unitPrice
          amount
        }
        others {
          content
          quantity
          unitPrice
          amount
        }
        governingBody
        capital
      }
      propertyData {
        id
        roomId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInvestmentAnalytics = /* GraphQL */ `
  mutation UpdateInvestmentAnalytics(
    $input: UpdateInvestmentAnalyticsInput!
    $condition: ModelInvestmentAnalyticsConditionInput
  ) {
    updateInvestmentAnalytics(input: $input, condition: $condition) {
      id
      inputDate
      existingBuildingCount
      newBuildingCount
      initialInvestment {
        propertyDescription {
          location
          address {
            postalCode
            address1
            address2
            address3
          }
          quantity
          structure
          kind
          purpose
          area
          dateOfConstruction
          price
        }
        tradingPrice
        totalTradingPrice
        propertyTaxValuation
        propertyTax
        cityPlanningTax
        propertyTaxRate
        cityPlanningTaxRate
        constructionPlan {
          selected
          newConstructionCost
          repairWorkCost
          equipmentUpdateCost
          repairWorkCostDetails {
            content
            quantity
            unitPrice
            amount
          }
          equipmentUpdateCostDetails {
            content
            quantity
            unitPrice
            amount
          }
          constructionCost {
            content
            quantity
            unitPrice
            amount
          }
          constructionInciDentalWork
          otherConstructionFee
          constructionReserves
          demolitionCount
        }
        incidentalExpenses
        consultingCost {
          content
          quantity
          unitPrice
          amount
        }
        otherConsultingCost
        otherCostDetails {
          content
          quantity
          unitPrice
          amount
        }
        estimatedConsultingCost
        isDetailConsultingCost
        designCost {
          content
          quantity
          unitPrice
          amount
        }
        estimatedDesignCost
        isDetailDesignCost
        surveyCost {
          content
          quantity
          unitPrice
          amount
        }
        estimatedSurveyCost
        isDetailSurveyCost
        licensingCost {
          content
          quantity
          unitPrice
          amount
        }
        estimatedLicensingCost
        isDetailLicensingCost
        contributionsAndDeposits {
          content
          quantity
          unitPrice
          amount
        }
        estimatedContributions
        estimatedDeposits
        isDetailContributionsAndDeposits
        stampDuty
        isChangedStampDuty
        estimatedStampDuty
        isDetailStampDuty
        realEstateAcquisitionTax
        isChangedRealEstateAcquisitionTax
        registrationTax {
          causeOfRegistration
          isChanged
          amount
          numberOfAdditionalMortgages
          numberOfRegisteredRecordsSurveyed
        }
        paymentFees {
          numberOfCases
          buildingRepresentationRegistration {
            content
            quantity
            unitPrice
            amount
          }
          leaseBrokerageFee
          bankGuarantee
          estimatedPaymentFee
          isDetail
          isChanged
          amount
        }
        preCareerCommitment {
          monthlyRent
          affordability
          duration
          monthlyOccupancyFee
          interestBeforeStartingBusiness
          isChanged
          borrowingRate
          approximateValue
          isDetail
        }
        insuranceFees
        otherExpenses {
          ritualCost
          neighborhoodWatchCosts {
            content
            quantity
            unitPrice
            amount
          }
          evictionFeesQuantity
          estimatedEvictionFees
          businessCompensation {
            content
            quantity
            unitPrice
            amount
          }
          estimatedBusinessCompensation
          advertisingExpensesDetails {
            content
            quantity
            unitPrice
            amount
          }
          advertisingExpenses
          specialExpenses {
            content
            quantity
            unitPrice
            amount
          }
          estimatedSpecialExpenses
          miscellaneousExpenses
          reserveRate
          estimatedReserveFund
          estimatedOtherExpenses
          isDetail
        }
        capitalPlan {
          deposit
          constructionCooperationFund
          securityDeposit
          keyMoney
          ownResources
          loans
          borrowedAmount
          adjustment
          borrowingRateApplicationYears
          borrowingRate
          repaymentPeriod
          repaymentMethod
          redemptionPeriod
        }
        memo
      }
      inputTitle
      reportName
      ownerName
      ownerId
      staffName
      singleYearPlan {
        incomePlan {
          rent {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          commonServiceFees {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          parkingFees {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          landRentalFees {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          others {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          itemNames
        }
        spendingPlan {
          monthlyRent
          expenditureItems {
            content
            quantity
            unitPrice
            amount
          }
          managementFeeType
          managementFeeValue
          numberOfRooms
          reserveRate
          buildingMaintenance {
            content
            quantity
            unitPrice
            amount
          }
          otherUnitPrice
          isDetail
          approximateValue
        }
      }
      longTermRepairPlan {
        majorRenovation {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        equipmentRenewal {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        externalStructure {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        others {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        isApproximation
        approximations {
          name
          frequency
          cost
        }
      }
      salePlan {
        timeOfSale
        breakdownOfSalePrice {
          land
          building
          buildingDetail
          depreciatedAssets
          assumedLandPriceIncrease
        }
        expensesAtTheTimeOfSale {
          demolition {
            total
            details {
              content
              quantity
              unitPrice
              amount
            }
            isDetail
          }
          repairAndValueUpWork {
            total
            details {
              content
              quantity
              unitPrice
              amount
            }
            isDetail
          }
          surveyingCosts {
            content
            quantity
            unitPrice
            amount
          }
          soilContaminationSurvey
          asbestosUseStudy
          equipmentFunctionSurvey
          inspection
          otherSurveys
          reserveRate
        }
        registrationFees
        evictionFees
        businessCompensation {
          content
          quantity
          unitPrice
          amount
        }
        contractCosts
        paymentFees
        consultingFees {
          content
          quantity
          unitPrice
          amount
        }
        salesAdvertisingCosts {
          content
          quantity
          unitPrice
          amount
        }
        others {
          content
          quantity
          unitPrice
          amount
        }
        governingBody
        capital
      }
      propertyData {
        id
        roomId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvestmentAnalytics = /* GraphQL */ `
  mutation DeleteInvestmentAnalytics(
    $input: DeleteInvestmentAnalyticsInput!
    $condition: ModelInvestmentAnalyticsConditionInput
  ) {
    deleteInvestmentAnalytics(input: $input, condition: $condition) {
      id
      inputDate
      existingBuildingCount
      newBuildingCount
      initialInvestment {
        propertyDescription {
          location
          address {
            postalCode
            address1
            address2
            address3
          }
          quantity
          structure
          kind
          purpose
          area
          dateOfConstruction
          price
        }
        tradingPrice
        totalTradingPrice
        propertyTaxValuation
        propertyTax
        cityPlanningTax
        propertyTaxRate
        cityPlanningTaxRate
        constructionPlan {
          selected
          newConstructionCost
          repairWorkCost
          equipmentUpdateCost
          repairWorkCostDetails {
            content
            quantity
            unitPrice
            amount
          }
          equipmentUpdateCostDetails {
            content
            quantity
            unitPrice
            amount
          }
          constructionCost {
            content
            quantity
            unitPrice
            amount
          }
          constructionInciDentalWork
          otherConstructionFee
          constructionReserves
          demolitionCount
        }
        incidentalExpenses
        consultingCost {
          content
          quantity
          unitPrice
          amount
        }
        otherConsultingCost
        otherCostDetails {
          content
          quantity
          unitPrice
          amount
        }
        estimatedConsultingCost
        isDetailConsultingCost
        designCost {
          content
          quantity
          unitPrice
          amount
        }
        estimatedDesignCost
        isDetailDesignCost
        surveyCost {
          content
          quantity
          unitPrice
          amount
        }
        estimatedSurveyCost
        isDetailSurveyCost
        licensingCost {
          content
          quantity
          unitPrice
          amount
        }
        estimatedLicensingCost
        isDetailLicensingCost
        contributionsAndDeposits {
          content
          quantity
          unitPrice
          amount
        }
        estimatedContributions
        estimatedDeposits
        isDetailContributionsAndDeposits
        stampDuty
        isChangedStampDuty
        estimatedStampDuty
        isDetailStampDuty
        realEstateAcquisitionTax
        isChangedRealEstateAcquisitionTax
        registrationTax {
          causeOfRegistration
          isChanged
          amount
          numberOfAdditionalMortgages
          numberOfRegisteredRecordsSurveyed
        }
        paymentFees {
          numberOfCases
          buildingRepresentationRegistration {
            content
            quantity
            unitPrice
            amount
          }
          leaseBrokerageFee
          bankGuarantee
          estimatedPaymentFee
          isDetail
          isChanged
          amount
        }
        preCareerCommitment {
          monthlyRent
          affordability
          duration
          monthlyOccupancyFee
          interestBeforeStartingBusiness
          isChanged
          borrowingRate
          approximateValue
          isDetail
        }
        insuranceFees
        otherExpenses {
          ritualCost
          neighborhoodWatchCosts {
            content
            quantity
            unitPrice
            amount
          }
          evictionFeesQuantity
          estimatedEvictionFees
          businessCompensation {
            content
            quantity
            unitPrice
            amount
          }
          estimatedBusinessCompensation
          advertisingExpensesDetails {
            content
            quantity
            unitPrice
            amount
          }
          advertisingExpenses
          specialExpenses {
            content
            quantity
            unitPrice
            amount
          }
          estimatedSpecialExpenses
          miscellaneousExpenses
          reserveRate
          estimatedReserveFund
          estimatedOtherExpenses
          isDetail
        }
        capitalPlan {
          deposit
          constructionCooperationFund
          securityDeposit
          keyMoney
          ownResources
          loans
          borrowedAmount
          adjustment
          borrowingRateApplicationYears
          borrowingRate
          repaymentPeriod
          repaymentMethod
          redemptionPeriod
        }
        memo
      }
      inputTitle
      reportName
      ownerName
      ownerId
      staffName
      singleYearPlan {
        incomePlan {
          rent {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          commonServiceFees {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          parkingFees {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          landRentalFees {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          others {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          itemNames
        }
        spendingPlan {
          monthlyRent
          expenditureItems {
            content
            quantity
            unitPrice
            amount
          }
          managementFeeType
          managementFeeValue
          numberOfRooms
          reserveRate
          buildingMaintenance {
            content
            quantity
            unitPrice
            amount
          }
          otherUnitPrice
          isDetail
          approximateValue
        }
      }
      longTermRepairPlan {
        majorRenovation {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        equipmentRenewal {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        externalStructure {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        others {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        isApproximation
        approximations {
          name
          frequency
          cost
        }
      }
      salePlan {
        timeOfSale
        breakdownOfSalePrice {
          land
          building
          buildingDetail
          depreciatedAssets
          assumedLandPriceIncrease
        }
        expensesAtTheTimeOfSale {
          demolition {
            total
            details {
              content
              quantity
              unitPrice
              amount
            }
            isDetail
          }
          repairAndValueUpWork {
            total
            details {
              content
              quantity
              unitPrice
              amount
            }
            isDetail
          }
          surveyingCosts {
            content
            quantity
            unitPrice
            amount
          }
          soilContaminationSurvey
          asbestosUseStudy
          equipmentFunctionSurvey
          inspection
          otherSurveys
          reserveRate
        }
        registrationFees
        evictionFees
        businessCompensation {
          content
          quantity
          unitPrice
          amount
        }
        contractCosts
        paymentFees
        consultingFees {
          content
          quantity
          unitPrice
          amount
        }
        salesAdvertisingCosts {
          content
          quantity
          unitPrice
          amount
        }
        others {
          content
          quantity
          unitPrice
          amount
        }
        governingBody
        capital
      }
      propertyData {
        id
        roomId
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPhotoSimulator = /* GraphQL */ `
  mutation CreatePhotoSimulator(
    $input: CreatePhotoSimulatorInput!
    $condition: ModelPhotoSimulatorConditionInput
  ) {
    createPhotoSimulator(input: $input, condition: $condition) {
      company
      propertyId
      roomId
      id
      simInfo {
        baseImageFileName
        baseImageDisplayName
        savePeten {
          id
          order
          saveName
          layersInfo {
            id
            layerDisplayName
            layerImageFileName
            material
            materialScale
            color
            layerColor
            transparent
            brightness
            saturation
            PerspectiveData {
              name
              x
              y
            }
          }
        }
        selectPattern
        simCreateDate
        simUpdateDate
      }
      quickSimulationFlag
      createdAt
      updatedAt
    }
  }
`;
export const updatePhotoSimulator = /* GraphQL */ `
  mutation UpdatePhotoSimulator(
    $input: UpdatePhotoSimulatorInput!
    $condition: ModelPhotoSimulatorConditionInput
  ) {
    updatePhotoSimulator(input: $input, condition: $condition) {
      company
      propertyId
      roomId
      id
      simInfo {
        baseImageFileName
        baseImageDisplayName
        savePeten {
          id
          order
          saveName
          layersInfo {
            id
            layerDisplayName
            layerImageFileName
            material
            materialScale
            color
            layerColor
            transparent
            brightness
            saturation
            PerspectiveData {
              name
              x
              y
            }
          }
        }
        selectPattern
        simCreateDate
        simUpdateDate
      }
      quickSimulationFlag
      createdAt
      updatedAt
    }
  }
`;
export const deletePhotoSimulator = /* GraphQL */ `
  mutation DeletePhotoSimulator(
    $input: DeletePhotoSimulatorInput!
    $condition: ModelPhotoSimulatorConditionInput
  ) {
    deletePhotoSimulator(input: $input, condition: $condition) {
      company
      propertyId
      roomId
      id
      simInfo {
        baseImageFileName
        baseImageDisplayName
        savePeten {
          id
          order
          saveName
          layersInfo {
            id
            layerDisplayName
            layerImageFileName
            material
            materialScale
            color
            layerColor
            transparent
            brightness
            saturation
            PerspectiveData {
              name
              x
              y
            }
          }
        }
        selectPattern
        simCreateDate
        simUpdateDate
      }
      quickSimulationFlag
      createdAt
      updatedAt
    }
  }
`;
export const createPlaneView = /* GraphQL */ `
  mutation CreatePlaneView(
    $input: CreatePlaneViewInput!
    $condition: ModelPlaneViewConditionInput
  ) {
    createPlaneView(input: $input, condition: $condition) {
      id
      companyId
      propertyId
      roomId
      ownerId
      ownerName
      guestIdList
      planeName
      patterns {
        name
        layers {
          id
          type
          subType
          style {
            stroke
            spaceName
            strokeWidth
            strokeLinecap
            strokeDasharray
            fill
            patternWidth
            patternOffsetX
            patternOffsetY
            patternRotate
            patternOpacity
            imageName
            imageUrl
            ratio
            flipHorizontal
            flipVertical
            fontSize
            textAnchor
          }
          vertexes {
            x
            y
          }
          arcPoints
          transform {
            rotate
            translate {
              x
              y
            }
            scale {
              x
              y
            }
            origin {
              x
              y
            }
          }
          firstChildId
          rightBrotherId
          width
          height
          height3d
          mountingHeight
          index
          code
          additionalInfo {
            open
            knob
            sliding
            isPointInArea
            stringers
            steps
            stepAngle
            stepHeight
          }
          alias
          areaSizeUnit
          text
          showAreaSize
          originalWidth
          originalHeight
          modelPath
        }
      }
      imageName
      azimuth
      updateDate
      updatePerson
      deleted
      deleteDate
      deletePerson
      output
      outputDate
      outputPerson
      restored
      restoreDate
      restorePerson
      createdAt
      updatedAt
    }
  }
`;
export const updatePlaneView = /* GraphQL */ `
  mutation UpdatePlaneView(
    $input: UpdatePlaneViewInput!
    $condition: ModelPlaneViewConditionInput
  ) {
    updatePlaneView(input: $input, condition: $condition) {
      id
      companyId
      propertyId
      roomId
      ownerId
      ownerName
      guestIdList
      planeName
      patterns {
        name
        layers {
          id
          type
          subType
          style {
            stroke
            spaceName
            strokeWidth
            strokeLinecap
            strokeDasharray
            fill
            patternWidth
            patternOffsetX
            patternOffsetY
            patternRotate
            patternOpacity
            imageName
            imageUrl
            ratio
            flipHorizontal
            flipVertical
            fontSize
            textAnchor
          }
          vertexes {
            x
            y
          }
          arcPoints
          transform {
            rotate
            translate {
              x
              y
            }
            scale {
              x
              y
            }
            origin {
              x
              y
            }
          }
          firstChildId
          rightBrotherId
          width
          height
          height3d
          mountingHeight
          index
          code
          additionalInfo {
            open
            knob
            sliding
            isPointInArea
            stringers
            steps
            stepAngle
            stepHeight
          }
          alias
          areaSizeUnit
          text
          showAreaSize
          originalWidth
          originalHeight
          modelPath
        }
      }
      imageName
      azimuth
      updateDate
      updatePerson
      deleted
      deleteDate
      deletePerson
      output
      outputDate
      outputPerson
      restored
      restoreDate
      restorePerson
      createdAt
      updatedAt
    }
  }
`;
export const deletePlaneView = /* GraphQL */ `
  mutation DeletePlaneView(
    $input: DeletePlaneViewInput!
    $condition: ModelPlaneViewConditionInput
  ) {
    deletePlaneView(input: $input, condition: $condition) {
      id
      companyId
      propertyId
      roomId
      ownerId
      ownerName
      guestIdList
      planeName
      patterns {
        name
        layers {
          id
          type
          subType
          style {
            stroke
            spaceName
            strokeWidth
            strokeLinecap
            strokeDasharray
            fill
            patternWidth
            patternOffsetX
            patternOffsetY
            patternRotate
            patternOpacity
            imageName
            imageUrl
            ratio
            flipHorizontal
            flipVertical
            fontSize
            textAnchor
          }
          vertexes {
            x
            y
          }
          arcPoints
          transform {
            rotate
            translate {
              x
              y
            }
            scale {
              x
              y
            }
            origin {
              x
              y
            }
          }
          firstChildId
          rightBrotherId
          width
          height
          height3d
          mountingHeight
          index
          code
          additionalInfo {
            open
            knob
            sliding
            isPointInArea
            stringers
            steps
            stepAngle
            stepHeight
          }
          alias
          areaSizeUnit
          text
          showAreaSize
          originalWidth
          originalHeight
          modelPath
        }
      }
      imageName
      azimuth
      updateDate
      updatePerson
      deleted
      deleteDate
      deletePerson
      output
      outputDate
      outputPerson
      restored
      restoreDate
      restorePerson
      createdAt
      updatedAt
    }
  }
`;
export const createProperty = /* GraphQL */ `
  mutation CreateProperty(
    $input: CreatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    createProperty(input: $input, condition: $condition) {
      company
      id
      commonValue {
        propertyName
        roomNumber
        address {
          postalCode
          address1
          address2
          address3
        }
        owner
        memo
        quickSimLogTime
        staffName
      }
      roomInfo {
        id
        roomName
        simulationLog {
          simId
          fileUrlName
          fileName
          action
          userName
          userMail
          updateDate
        }
      }
      simulationLog {
        simId
        fileUrlName
        fileName
        action
        userName
        userMail
        updateDate
      }
      planeViewData {
        newestImageName
        updateDate
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProperty = /* GraphQL */ `
  mutation UpdateProperty(
    $input: UpdatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    updateProperty(input: $input, condition: $condition) {
      company
      id
      commonValue {
        propertyName
        roomNumber
        address {
          postalCode
          address1
          address2
          address3
        }
        owner
        memo
        quickSimLogTime
        staffName
      }
      roomInfo {
        id
        roomName
        simulationLog {
          simId
          fileUrlName
          fileName
          action
          userName
          userMail
          updateDate
        }
      }
      simulationLog {
        simId
        fileUrlName
        fileName
        action
        userName
        userMail
        updateDate
      }
      planeViewData {
        newestImageName
        updateDate
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProperty = /* GraphQL */ `
  mutation DeleteProperty(
    $input: DeletePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    deleteProperty(input: $input, condition: $condition) {
      company
      id
      commonValue {
        propertyName
        roomNumber
        address {
          postalCode
          address1
          address2
          address3
        }
        owner
        memo
        quickSimLogTime
        staffName
      }
      roomInfo {
        id
        roomName
        simulationLog {
          simId
          fileUrlName
          fileName
          action
          userName
          userMail
          updateDate
        }
      }
      simulationLog {
        simId
        fileUrlName
        fileName
        action
        userName
        userMail
        updateDate
      }
      planeViewData {
        newestImageName
        updateDate
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReportList = /* GraphQL */ `
  mutation CreateReportList(
    $input: CreateReportListInput!
    $condition: ModelreportListConditionInput
  ) {
    createReportList(input: $input, condition: $condition) {
      id
      company
      fileTitle
      fileName
      status
      mail
      customerId
      customerName
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateReportList = /* GraphQL */ `
  mutation UpdateReportList(
    $input: UpdateReportListInput!
    $condition: ModelreportListConditionInput
  ) {
    updateReportList(input: $input, condition: $condition) {
      id
      company
      fileTitle
      fileName
      status
      mail
      customerId
      customerName
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteReportList = /* GraphQL */ `
  mutation DeleteReportList(
    $input: DeleteReportListInput!
    $condition: ModelreportListConditionInput
  ) {
    deleteReportList(input: $input, condition: $condition) {
      id
      company
      fileTitle
      fileName
      status
      mail
      customerId
      customerName
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createSystemMaster = /* GraphQL */ `
  mutation CreateSystemMaster(
    $input: CreateSystemMasterInput!
    $condition: ModelsystemMasterConditionInput
  ) {
    createSystemMaster(input: $input, condition: $condition) {
      classification
      classificationDetail
      masterName
      version
      editability {
        subdivision
        editLine {
          permission
          terms
        }
        editItem {
          key
          type
          digits
          terms
        }
      }
      year
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateSystemMaster = /* GraphQL */ `
  mutation UpdateSystemMaster(
    $input: UpdateSystemMasterInput!
    $condition: ModelsystemMasterConditionInput
  ) {
    updateSystemMaster(input: $input, condition: $condition) {
      classification
      classificationDetail
      masterName
      version
      editability {
        subdivision
        editLine {
          permission
          terms
        }
        editItem {
          key
          type
          digits
          terms
        }
      }
      year
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteSystemMaster = /* GraphQL */ `
  mutation DeleteSystemMaster(
    $input: DeleteSystemMasterInput!
    $condition: ModelsystemMasterConditionInput
  ) {
    deleteSystemMaster(input: $input, condition: $condition) {
      classification
      classificationDetail
      masterName
      version
      editability {
        subdivision
        editLine {
          permission
          terms
        }
        editItem {
          key
          type
          digits
          terms
        }
      }
      year
      value
      createdAt
      updatedAt
    }
  }
`;
