// @ts-check

import * as turf from '@turf/turf';
import { Geo } from './geometry';
import { Vec } from './vector';

/**
 * @param {turf.helpers.Feature<turf.helpers.Polygon | turf.helpers.MultiPolygon, {[name: string]: any}>} turfPolygon
 * @returns {Vec[]}
 */
export function toVec(turfPolygon) {
  return turfPolygon.geometry.coordinates[0].map(v => new Vec(v[0], v[1]));
}

/**
 *
 * @param {Vec[]} vecs
 */
export function createTurfPolygon(vecs) {
  const first = vecs[0];
  return turf.polygon([[...vecs.map(v => [v.x, v.y]), [first.x, first.y]]]);
}

/**
 * @param {Vec} start
 * @param {Vec} end
 */
export function createTurfLine(start, end) {
  return turf.lineString([
    [start.x, start.y],
    [end.x, end.y],
  ]);
}

/**
 * @param {Vec[]} polygon1
 * @param {Vec[]} polygon2
 * @returns {{index1: number, index2: number, position: Vec}[]}
 */
export function intersect(polygon1, polygon2) {
  /** @type {{index1: number, index2: number, position: Vec}[]} */
  const crossPoints = [];
  const len1 = polygon1.length;
  const len2 = polygon2.length;
  for (let i = 0; i < len1; i++) {
    const start1 = polygon1[i];
    const end1 = polygon1[(i + 1) % len1];

    for (let j = 0; j < len2; j++) {
      const start2 = polygon2[j % len2];
      const end2 = polygon2[(j + 1) % len2];
      const judge = Geo.judgeCrossPointOnLine(start1, end1, start2, end2);
      if (judge) {
        const crossPoint = Vec.getCrossPointOnLine(start1, end1, start2, end2);
        if (crossPoint == null) continue;
        crossPoints.push({ index1: i, index2: j, position: crossPoint });
      }
    }
  }

  return crossPoints;
}

/**
 *
 * @param {Vec[]} polygon1
 * @param {Vec[]} polygon2
 */
export function difference(polygon1, polygon2) {
  const p1 = createTurfPolygon(polygon1);
  const p2 = createTurfPolygon(polygon2);
  const dif = turf.difference(p1, p2);
  // @ts-ignore
  const vec = toVec(dif);
  vec.pop();
  return vec;
}
