// @ts-check

/**
 * 操作が失敗するとエラーを投げるWeakMap
 * @template {{}} T
 * @template S
 * @extends WeakMap<T,S>
 */
export class StrictWeakMap extends WeakMap {
  /**
   * @param {T} val
   * @returns {S}
   */
  get(val) {
    if (!this.has(val)) {
      console.error('取得対象のオブジェクトは登録されていません。: ', val, this);
      throw new Error();
    }

    // @ts-ignore
    return super.get(val);
  }

  /**
   * @param {T} val
   * @param {S} node
   */
  set(val, node) {
    if (this.has(val)) {
      console.error('セット対象のオブジェクトは既に登録されています。: ', val, this);
      throw new Error();
    }
    return super.set(val, node);
  }

  /**
   * @param {T} val
   */
  delete(val) {
    if (!this.has(val)) {
      console.error('削除対象のオブジェクトは登録されていません。: ', val, this);
      throw new Error();
    }
    return super.delete(val);
  }
}
