<template>
  <CommonLayout title="相続税計算">
    <ReportSubTitle subTitle="権利の評価金額" />
    <div style="padding: 150px 100px;">
      <table style="width: 100%; table-layout: fixed;" id="calcDetail">
        <thead>
          <tr style="font-size: 50px">
            <th width="20%" :style="headerStyle">土地・建物名</th>
            <th width="20%" :style="headerStyle">相続価格ベース<br />価値評価金額（円）</th>
            <!-- <th width="20%" :style="headerStyle">実価格ベース<br />価値評価額（円）</th>-->
            <th :style="[headerStyle, { width: '450px' }]">コメント</th>
          </tr>
        </thead>
        <tbody style="background-color: white">
          <tr v-for="row in inheritanceInfoList" :key="row.Id" style="font-size: 50px;">
            <td :style="bodyStyle">
              {{ row.realEstateName }}
            </td>
            <td :style="[bodyStyle, { 'text-align': 'right', padding: '0 15px'}]">
              {{
                (
                  row.totalPrice + (row.houseFixedAssetTax ? row.houseFixedAssetTax : 0)
                ).toLocaleString()
              }}
              円
            </td>
            <!-- 実価格ベースの削除 
             <td :style="[bodyStyle, { 'text-align': 'right' }]">
              {{
                (
                  (row.totalPrice + (row.houseFixedAssetTax ? row.houseFixedAssetTax : 0)) *
                  1.25
                ).toLocaleString()
              }}
              円
            </td>
            -->
            <td :style="bodyStyle">{{ row.memo || '' }}</td>
          </tr>
          <tr v-if="inheritanceInfoList.length <= 0">
            <td colspan="3">
              詳細項目がありません
            </td>
          </tr>
          <!-- <tr>
            <td :style="subHeaderStyle">
              合計
            </td>
            <td :style="[subHeaderStyle, { 'text-align': 'right' }]">
              {{ totalInheritanceTaxProperValue.toLocaleString() }} 円
            </td>
            <td :style="[subHeaderStyle, { 'text-align': 'right' }]">
              {{ realTotalInheritanceTaxProperValue.toLocaleString() }} 円
            </td>
            <td :style="subHeaderStyle"></td>
          </tr> -->
        </tbody>
      </table>
    </div>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import ReportSubTitle from '@/components/ExportReport/ReportSubTitle';
  import { headerStyle, subHeaderStyle, bodyStyle } from './style';
  export default {
    props: {
      inheritanceInfoList: {
        type: Array,
        required: true,
      },
      totalInheritanceTaxProperValue: {
        type: Number,
        required: true,
      },
      realTotalInheritanceTaxProperValue: {
        type: Number,
        required: true,
      },
    },
    data: function() {
      return {
        listPage: [],
        headerStyle,
        subHeaderStyle,
        bodyStyle,
      };
    },
    components: {
      CommonLayout,
      ReportSubTitle,
    },
  };
</script>
