// @ts-check

import { EventContext } from './eventContext';
import { ActionManager } from '../action/actionManager';
import { EventPreset } from './eventPreset';

/**
 * 与えられた情報からEventPresetを構築する
 */
export class EventPresetBuilder {
  /** @type {EventContext} */
  context;
  /** @type {ActionManager} */
  actionManager;
  constructor({ context, actionManager }) {
    this.context = context;
    this.actionManager = actionManager;
  }
  /**
   * @returns {EventPreset}
   */
  build() {
    throw new Error('overrideしてください');
  }
}
