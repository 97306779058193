export default class InvestmentAnalytics {
  // コンストラクタ
  constructor() {
    this.initialize();
  }
  // 初期化
  initialize() {
    // 入力データID
    this.id = null;
    // 入力日付
    this.inputDate = new Date().toISOString().slice(0, 10);
    // レポート名
    this.reportName = null;
    // 所有者名
    this.ownerName = null;
    // 所有者ID
    this.ownerId = null;
    // 担当者名
    this.staffName = null;
    // 既存建物棟数
    this.existingBuildingCount = 1;
    // 新築建物棟数
    this.newBuildingCount = 1;
    // 初期投資
    this.initialInvestment = {
      propertyDescription: Array(23)
        .fill(null)
        .map(function() {
          return {
            location: null,
            address: {
              address1: null,
              address2: null,
              address3: null,
            },
            quantity: null,
            structure: null,
            kind: null,
            purpose: null,
            area: null,
            dateOfConstruction: null,
            price: null,
          };
        }),
      tradingPrice: Array(23).fill(null),
      totalTradingPrice: null,
      propertyTaxValuation: Array(12).fill(null),
      propertyTax: Array(23).fill(null),
      cityPlanningTax: Array(22).fill(null),
      propertyTaxRate: null,
      cityPlanningTaxRate: null,
      constructionPlan: {
        selected: Array(11).fill(0),
        newConstructionCost: Array(10).fill(null),
        repairWorkCost: null,
        equipmentUpdateCost: null,
        repairWorkCostDetails: Array(9)
          .fill(null)
          .map(function() {
            return {
              content: null,
              quantity: null,
              amount: null,
              unitPrice: null,
            };
          }),
        equipmentUpdateCostDetails: Array(8)
          .fill(null)
          .map(function() {
            return {
              content: null,
              quantity: null,
              amount: null,
              unitPrice: null,
            };
          }),
        constructionCost: Array(19)
          .fill(null)
          .map(function() {
            return {
              content: null,
              quantity: null,
              amount: null,
              unitPrice: null,
            };
          }),
        constructionInciDentalWork: null,
        otherConstructionFee: null,
        constructionReserves: null,
        demolitionCount: 0,
      },
      incidentalExpenses: Array(12).fill(0),
      consultingCost: Array(4)
        .fill(null)
        .map(function() {
          return {
            content: null,
            quantity: null,
            amount: null,
            unitPrice: null,
          };
        }),
      otherConsultingCost: null,
      otherCostDetails: Array(4)
        .fill(null)
        .map(function() {
          return {
            content: null,
            quantity: null,
            amount: null,
            unitPrice: null,
          };
        }),
      estimatedConsultingCost: null,
      isDetailConsultingCost: 0,
      designCost: Array(3)
        .fill(null)
        .map(function() {
          return {
            content: null,
            quantity: null,
            amount: null,
            unitPrice: null,
          };
        }),
      estimatedDesignCost: null,
      isDetailDesignCost: 0,
      surveyCost: [
        {
          content: null,
          quantity: null,
          amount: null,
          unitPrice: null,
        },
        {
          content: null,
          quantity: null,
          amount: null,
          unitPrice: null,
        },
        {
          content: null,
          quantity: null,
          amount: null,
          unitPrice: null,
        },
        {
          content: null,
          quantity: null,
          amount: null,
          unitPrice: null,
        },
        {
          content: null,
          quantity: null,
          amount: null,
          unitPrice: null,
        },
        {
          content: '完了検査済',
          quantity: null,
          amount: null,
          unitPrice: null,
        },
      ],
      estimatedSurveyCost: null,
      isDetailSurveyCost: 0,
      licensingCost: Array(14)
        .fill(null)
        .map(function() {
          return {
            content: null,
            quantity: null,
            amount: null,
            unitPrice: null,
          };
        }),
      estimatedLicensingCost: null,
      isDetailLicensingCost: 0,
      contributionsAndDeposits: Array(8)
        .fill(null)
        .map(function() {
          return {
            content: null,
            quantity: null,
            amount: null,
            unitPrice: null,
          };
        }),
      estimatedContributions: null,
      estimatedDeposits: null,
      isDetailContributionsAndDeposits: 0,
      stampDuty: Array(6).fill(0),
      isChangedStampDuty: Array(6).fill(false),
      estimatedStampDuty: null,
      isDetailStampDuty: 0,
      realEstateAcquisitionTax: Array(22).fill(null),
      isChangedRealEstateAcquisitionTax: Array(22).fill(false),
      registrationTax: {
        causeOfRegistration: Array(12).fill(null),
        isChanged: Array(26).fill(false),
        amount: Array(26).fill(null),
        numberOfAdditionalMortgages: null,
        numberOfRegisteredRecordsSurveyed: null,
      },
      paymentFees: {
        numberOfCases: Array(21).fill(null),
        buildingRepresentationRegistration: Array(10)
          .fill(null)
          .map(function() {
            return {
              content: null,
              quantity: null,
              amount: null,
              unitPrice: null,
            };
          }),
        leaseBrokerageFee: null,
        estimatedPaymentFee: null,
        isDetail: 0,
        isChanged: Array(16).fill(false),
        amount: Array(16).fill(null),
      },
      preCareerCommitment: {
        monthlyRent: null,
        affordability: null,
        duration: Array(5).fill(null),
        monthlyOccupancyFee: null,
        interestBeforeStartingBusiness: null,
        isChanged: false,
        borrowingRate: null,
        approximateValue: null,
        isDetail: 0,
      },
      insuranceFees: Array(3).fill(null),
      otherExpenses: {
        ritualCost: null,
        neighborhoodWatchCosts: {
          content: null,
          quantity: null,
          amount: null,
          unitPrice: null,
        },
        evictionFeesQuantity: Array(2).fill(null),
        estimatedEvictionFees: null,
        businessCompensation: Array(2)
          .fill(null)
          .map(function() {
            return {
              content: null,
              quantity: null,
              amount: null,
              unitPrice: null,
            };
          }),
        estimatedBusinessCompensation: null,
        advertisingExpensesDetails: Array(3)
          .fill(null)
          .map(function() {
            return {
              content: null,
              quantity: null,
              amount: null,
              unitPrice: null,
            };
          }),
        advertisingExpenses: null,
        specialExpenses: Array(3)
          .fill(null)
          .map(function() {
            return {
              content: null,
              quantity: null,
              amount: null,
              unitPrice: null,
            };
          }),
        estimatedSpecialExpenses: null,
        miscellaneousExpenses: null,
        reserveRate: null,
        estimatedReserveFund: null,
        estimatedOtherExpenses: null,
        isDetail: 0,
      },
      capitalPlan: {
        deposit: null,
        constructionCooperationFund: null,
        securityDeposit: null,
        keyMoney: null,
        ownResources: null,
        loans: null,
        borrowingRateApplicationYears: null,
        borrowingRate: [null, null],
        repaymentPeriod: null,
        repaymentMethod: null,
        redemptionPeriod: null,
      },
      memo: Array(3).fill(null),
    };
    // 単年度収支計画
    this.singleYearPlan = {
      incomePlan: {
        rent: Array(20)
          .fill(null)
          .map(function() {
            return { taxationNumber: null, taxExemptNumber: null, taxation: null, taxExempt: null };
          }),
        commonServiceFees: Array(20)
          .fill(null)
          .map(function() {
            return { taxationNumber: null, taxExemptNumber: null, taxation: null, taxExempt: null };
          }),
        parkingFees: Array(1)
          .fill(null)
          .map(function() {
            return { taxationNumber: null, taxExemptNumber: null, taxation: null, taxExempt: null };
          }),
        landRentalFees: Array(1)
          .fill(null)
          .map(function() {
            return { taxationNumber: null, taxExemptNumber: null, taxation: null, taxExempt: null };
          }),
        others: Array(1)
          .fill(null)
          .map(function() {
            return { taxationNumber: null, taxExemptNumber: null, taxation: null, taxExempt: null };
          }),
        itemNames: Array(1).fill(null),
      },
      spendingPlan: {
        monthlyRent: 0,
        expenditureItems: Array(14)
          .fill(null)
          .map((v, i) => {
            return {
              content: null,
              quantity: i >= 9 ? 1 : null,
              amount: null,
              unitPrice: null,
            };
          }),
        managementFeeType: null,
        managementFeeValue: null,
        numberOfRooms: null,
        reserveRate: null,
        buildingMaintenance: Array(19)
          .fill(null)
          .map(function() {
            return {
              content: null,
              quantity: null,
              amount: null,
              unitPrice: null,
            };
          }),
        otherUnitPrice: null,
        isDetail: [0, 0, 0],
        approximateValue: [null, null, null],
      },
    };
    // 長期修繕計画
    this.longTermRepairPlan = {
      majorRenovation: Array(46)
        .fill(null)
        .map(function() {
          return { implementationTime: null, quantity: null, amount: null, isChanged: false };
        }),
      equipmentRenewal: Array(36)
        .fill(null)
        .map(function() {
          return { implementationTime: null, quantity: null, amount: null, isChanged: false };
        }),
      externalStructure: Array(10)
        .fill(null)
        .map(function() {
          return { implementationTime: null, quantity: null, amount: null, isChanged: false };
        }),
      others: Array(6)
        .fill(null)
        .map(function() {
          return {
            itemName: null,
            implementationTime: null,
            quantity: null,
            unitPrice: null,
            amount: null,
            isChanged: false,
          };
        }),
      isApproximation: 0, // Approximation="概算"だが、0:概算 1:詳細 として使う(DynamoDBの変数を変更せず再利用)
      approximations: [{ name: null, frequency: null, cost: null }],
    };
    // 売却
    this.salePlan = {
      timeOfSale: 45,
      breakdownOfSalePrice: {
        land: null,
        building: null,
        buildingDetail: Array(20).fill(null),
        depreciatedAssets: null,
        assumedLandPriceIncrease: null,
      },
      expensesAtTheTimeOfSale: {
        demolition: {
          total: null,
          details: Array(20)
            .fill(null)
            .map(function() {
              return {
                content: null,
                quantity: null,
                amount: null,
                unitPrice: null,
              };
            }),
        },
        repairAndValueUpWork: {
          total: null,
          details: Array(3)
            .fill(null)
            .map(function() {
              return {
                content: null,
                quantity: null,
                amount: null,
                unitPrice: null,
              };
            }),
        },
        surveyingCosts: {
          content: null,
          quantity: null,
          amount: null,
          unitPrice: null,
        },
        soilContaminationSurvey: null,
        asbestosUseStudy: null,
        equipmentFunctionSurvey: null,
        inspection: null,
        otherSurveys: null,
        reserveRate: null,
      },
      registrationFees: Array(9).fill(null),
      evictionFees: Array(2).fill(null),
      businessCompensation: Array(2)
        .fill(null)
        .map(function() {
          return {
            content: null,
            quantity: null,
            amount: null,
            unitPrice: null,
          };
        }),
      contractCosts: Array(4).fill(null),
      paymentFees: Array(2).fill(null),
      consultingFees: Array(3)
        .fill(null)
        .map(function() {
          return {
            content: null,
            quantity: null,
            amount: null,
            unitPrice: null,
          };
        }),
      salesAdvertisingCosts: Array(3)
        .fill(null)
        .map(function() {
          return {
            content: null,
            quantity: null,
            amount: null,
            unitPrice: null,
          };
        }),
      others: Array(2)
        .fill(null)
        .map(function() {
          return {
            content: null,
            quantity: null,
            amount: null,
            unitPrice: null,
          };
        }),
      governingBody: '個人',
      capital: '1億円以下',
    };
  }
}
