var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row grid-margin"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v(" 顧客情報"+_vm._s(_vm.mode == 'Add' ? '登録' : _vm.mode == 'View' ? '詳細' : '編集')+" ")])]),_c('div',{staticClass:"row grid-margin"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row ally-tab",attrs:{"id":"customerType"}},[(_vm.value.customerType == '法人' || _vm.mode == 'Add')?_c('label',{staticClass:"col-2",class:{
            'ally-radio-selected': _vm.value.customerType == '法人',
          },staticStyle:{"text-align":"center"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.customerType),expression:"value.customerType"}],staticClass:"form-check-input",attrs:{"type":"radio","name":"customerType","value":"法人"},domProps:{"checked":_vm._q(_vm.value.customerType,"法人")},on:{"change":function($event){return _vm.$set(_vm.value, "customerType", "法人")}}}),_c('h3',[_vm._v("法人")])]):_vm._e(),(_vm.value.customerType == '個人' || _vm.mode == 'Add')?_c('label',{staticClass:"col-2",class:{
            'ally-radio-selected': _vm.value.customerType == '個人',
          },staticStyle:{"text-align":"center"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.customerType),expression:"value.customerType"}],staticClass:"form-check-input",attrs:{"type":"radio","name":"customerType","value":"個人"},domProps:{"checked":_vm._q(_vm.value.customerType,"個人")},on:{"change":function($event){return _vm.$set(_vm.value, "customerType", "個人")}}}),_c('h3',[_vm._v("個人")])]):_vm._e()])]),_c('div',{staticClass:"ally-input-background card col-12"},[_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{ref:"customer",attrs:{"slim":""}},[(_vm.value.customerType == '法人')?_c('ViewCorp',{ref:"ViewCorp",attrs:{"mode":_vm.mode},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):(_vm.value.customerType == '個人')?_c('ViewPersonal',{ref:"ViewPersonal",attrs:{"mode":_vm.mode},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_vm._e()],1)],1)])]),(_vm.value.customerType == '個人')?_c('div',[_c('div',{staticClass:"ally-input-background card"},[_c('div',{staticClass:"card-body"},[_c('ViewFamily',{attrs:{"mode":_vm.mode,"value":_vm.value},on:{"familyModalOpen":function (e, index) { return _vm.modalOpen(e, index); }}})],1)])]):_vm._e(),(_vm.comp == 'false')?_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-12"},[(_vm.mode == 'Add')?_c('button',{staticClass:"btn btn-ally float-right",attrs:{"type":"submit"},on:{"click":_vm.create}},[_vm._v(" 登録 ")]):_vm._e(),_c('b-button-group',{staticClass:"float-right"},[(_vm.mode == 'View')?_c('b-button',{staticStyle:{"margin-right":"10px"},attrs:{"variant":"ally"},on:{"click":function($event){return _vm.$router.push({
              path: '/Report/List',
              query: { customer: _vm.id },
            })}}},[_vm._v("レポート管理")]):_vm._e(),(_vm.mode == 'View')?_c('b-button',{attrs:{"variant":"ally"},on:{"click":function($event){return _vm.$router.push('/Customer/Edit/' + _vm.id)}}},[_vm._v(" 編集 ")]):_vm._e()],1),(_vm.mode == 'Edit')?_c('button',{staticClass:"btn btn-ally float-right",on:{"click":_vm.update}},[_vm._v(" 保存 ")]):_vm._e(),(this.viewMode == null)?_c('router-link',{staticClass:"btn btn-ally float-right",staticStyle:{"margin-right":"10px","margin-left":"10px"},attrs:{"to":"/Customer/List?page=true","tag":"button"}},[_vm._v("一覧に戻る")]):_vm._e()],1)]):_vm._e(),_c('allyAlert',{attrs:{"code":"S100"},on:{"okAction":function($event){return _vm.pageLink()}}}),_c('allyAlert',{attrs:{"code":"E0001"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }