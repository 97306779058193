//マスタデータのロード

const bigLandCalc = async function(routePriceResult, input, master) {
  try {
    var routePriceMaster = null;

    //グラフx偏の格納変数
    var x = 0;

    //規模格差補正マスタロード
    if (input.bigLandFlg) {
      //三大都市
      routePriceMaster = master.interitanceMaster91.slice(1);

      if (input.landSize >= 5000) {
        x = 3;
      } else if (input.landSize >= 3000) {
        x = 2;
      } else if (input.landSize >= 1000) {
        x = 1;
      } else {
        x = 0;
      }
    } else {
      //その他
      routePriceMaster = master.interitanceMaster92.slice(1);

      if (input.landSize >= 5000) {
        x = 2;
      } else if (input.landSize >= 3000) {
        x = 1;
      } else {
        x = 0;
      }
    }

    //補正率の計算
    var factor = parseFloat(
      (
        ((input.landSize * routePriceMaster[x][1] + routePriceMaster[x][2]) / input.landSize) *
        0.8
      ).toFixed(2)
    );

    //返却変数
    var result = null;

    //地価格納
    result = {
      text: '地積規模が大きな宅地の計算',
      resultPrice: Math.floor(routePriceResult.resultPrice * factor),
      resultPriceType: 'G',
    };
    console.log('地積規模が大きな宅地計算完了 : ', result);

    return result;
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
};

export { bigLandCalc };
