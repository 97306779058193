// @ts-check

import { Vec } from '../../util/math/vector';

const WALL_OFFSET = 10;
const STEP = 20;

/**
 * 階段のpathを生成する
 * @param {Vec[]} vcs
 * @param {boolean} leftSide
 * @param {number} width
 * @param {boolean} wallOffset
 * @returns {string[]}
 */
export function stairsGenerator(vcs, leftSide, width, wallOffset) {
  let d1 = '';
  let d2 = '';
  const vecs = vcs.slice();
  const vecLen = vecs.length;
  for (let i = 0; i < vecLen; i++) {
    if (i === 0) continue;

    const v = vecs[i];
    const prev = vecs[i - 1] ?? new Vec();
    const line = v.sub(prev);
    const prevLine = i >= 2 ? prev.sub(vecs[i - 2]) : new Vec(1, 0);

    let nextLine;
    let nextAngle = 999;
    if (i !== vecs.length - 1) {
      nextLine = vecs[i + 1].sub(v);
      nextAngle = ((leftSide ? -1 : 1) * (((line.angle(nextLine) ?? 0) * 180) / Math.PI)) % 180;
    }

    const len = line.absVal();
    if (len < width) continue;
    const angle = ((leftSide ? -1 : 1) * ((prevLine.angle(line) ?? 0) * 180)) / Math.PI;

    // 階段本体
    const lineUnit = line.unit();
    const hVec = lineUnit.scale(width);
    const wUnit = lineUnit.normal();
    const wVec = wUnit.reverse().scale(width);
    const anglePlus = 0 < angle && angle < 180;
    const nextAnglePlus = 0 < nextAngle && nextAngle < 180;
    if (i > 1 && nextAnglePlus && anglePlus) {
      d1 += generateUnitStair(prev.add(hVec), v.sub(hVec), width, leftSide, wallOffset);
    } else if (i > 1 && anglePlus) {
      d1 += generateUnitStair(prev.add(hVec), v, width, leftSide, wallOffset);
    } else if (nextAnglePlus) {
      d1 += generateUnitStair(prev, v.sub(hVec), width, leftSide, wallOffset);
    } else {
      d1 += generateUnitStair(prev, v, width, leftSide, wallOffset);
    }
    // 踊り場
    if (i !== vecs.length - 1) {
      if (nextAngle < -135 || nextAngle === 0) {
        d2 += generateOdoriba(v, v.add(wVec), false, wallOffset, true);
        // d2 += generateOdoriba(v.sub(wVec), v, true, wallOffset);
      } else if (-135 <= nextAngle && nextAngle < -45) {
        d2 += leftSide
          ? generateOdoriba(v.sub(wVec), v, true, wallOffset)
          : generateOdoriba(v, v.add(wVec), false, wallOffset);
      } else if (nextAngle === 0) {
        //
      } else if (0 < nextAngle && nextAngle <= 180) {
        d2 += leftSide
          ? generateOdoriba(v.sub(hVec).sub(wVec), v.sub(hVec), false, wallOffset)
          : generateOdoriba(v.sub(hVec), v.add(wVec).sub(hVec), true, wallOffset);
      }
    }
  }
  return [d1, d2];
}

/**
 * @param {Vec} start
 * @param {Vec} end
 * @param {number} width
 * @param {boolean} leftSide
 * @param {boolean} wallOffset
 * @returns {string}
 */
function generateUnitStair(start, end, width, leftSide, wallOffset) {
  let res = '';
  const line = end.sub(start);
  if (line.absVal() === 0) return '';
  const lineUnit = line.unit();
  const len = line.absVal();
  const wVec = leftSide
    ? line.nUnit().scale(width)
    : line
        .nUnit()
        .reverse()
        .scale(width);
  const f1 = wVec.scale(wallOffset ? WALL_OFFSET / width : 0);
  const f2 = wVec.scale(wallOffset ? (width - WALL_OFFSET) / width : 1);

  const p1 = start.add(f1);
  const p2 = end.add(f1);
  const p3 = start.add(f2);
  const p4 = end.add(f2);

  res += `M${p1.x},${p1.y} L${p2.x},${p2.y} L${p4.x},${p4.y} L${p3.x},${p3.y} Z `;

  // 段差の線
  let s = 0;
  while (s <= len) {
    const sVec = lineUnit.scale(s);
    res += `M${p1.x + sVec.x},${p1.y + sVec.y} L${p3.x + sVec.x},${p3.y + sVec.y} `;
    s += STEP;
  }

  return res;
}

/**
 * @param {Vec} start
 * @param {Vec} end
 * @param {boolean} flip
 * @param {boolean} wallOffset
 * @param {boolean} double
 */
function generateOdoriba(start, end, flip, wallOffset, double = false) {
  double;
  const width = start.sub(end).absVal();
  const wVec = end.sub(start);
  const f1 = wVec.scale(wallOffset ? WALL_OFFSET / width : 0);
  const f2 = wVec.scale(wallOffset ? (width - WALL_OFFSET) / width : 1);
  let res = '';
  const p1 = start.add(f1);
  const p2 = start.add(f2);
  if (wVec.absVal() === 0) return '';
  const sVec = wVec.normal();
  const f3 = sVec.scale(wallOffset ? (width - WALL_OFFSET) / width : 1);
  const sSmall = sVec.unit().scale(wallOffset ? WALL_OFFSET : 0);

  let corner;
  corner;

  if (flip) {
    const p3 = p2.add(sSmall);
    corner = p3;
    const wSmall = wVec.unit().scale(wallOffset ? WALL_OFFSET : 0);
    const p4 = p3.add(wSmall);
    const p5 = start.add(wVec).add(f3);
    const p6 = p1.add(f3);
    if (double) {
      res += `M${p1.x},${p1.y} L${p2.x},${p2.y} L${p3.x},${p3.y} L${p4.x},${p4.y} L${p5.x},${p5.y} L${p6.x},${p6.y} Z `;
    } else {
      res += `M${p1.x},${p1.y} L${p2.x},${p2.y} L${p3.x},${p3.y} L${p4.x},${p4.y} L${p5.x},${p5.y} L${p6.x},${p6.y} Z `;
    }
  } else {
    const p3 = p2.add(f3);
    const wSmall = wVec.unit().scale(wallOffset ? WALL_OFFSET : 0);
    const p4 = p3.add(f2.reverse());
    const p5 = start.add(sSmall);
    const p6 = p5.add(wSmall);
    corner = p6;
    if (double) {
      res += `M${p1.x},${p1.y} L${p2.x},${p2.y} L${p3.x},${p3.y} L${p4.x -
        wVec.x +
        wSmall.x},${p4.y - wVec.y + wSmall.y} L${p1.x - wVec.x},${p1.y - wVec.y} L${p1.x -
        wSmall.x * 2},${p1.y - wSmall.y * 2} L${p5.x - wSmall.x},${p5.y - wSmall.y} L${p6.x},${
        p6.y
      } Z `;
    } else {
      res += `M${p1.x},${p1.y} L${p2.x},${p2.y} L${p3.x},${p3.y} L${p4.x},${p4.y} L${p5.x},${p5.y} L${p6.x},${p6.y} Z `;
    }
  }
  // 斜線
  // if (flip) {
  //   const p5 = p1.add(f3.scale(1 / 2));
  //   const p6 = start.add(f3).add(wVec.scale(1 / 2));
  //   res += `M${corner.x},${corner.y} L${p5.x},${p5.y} M${corner.x},${corner.y} L${p6.x},${p6.y} `;
  // } else {
  //   const p5 = p2.add(f3.scale(1 / 2));
  //   const p6 = start.add(f3).add(wVec.scale(1 / 2));
  //   res += `M${corner.x},${corner.y} L${p5.x},${p5.y} M${corner.x},${corner.y} L${p6.x},${p6.y} `;
  // }
  return res;
}
