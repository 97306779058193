// @ts-check

import { PathData } from '../../../util/path';

/**
 * @param {number} width
 * @param {number} height
 */
export function doorBase1(width, height) {
  const path = new PathData({
    d: PathData.rect(0, -height / 2, width, height),
    stroke: 'black',
    fill: 'white',
    strokeWidth: 5,
  });
  return path;
}
