<template>
  <div v-if="activeObject">
    <div class="pv-config-label">位置</div>
    <div>
      <input
        type="number"
        style="width: 45%; display: inline-block"
        :value="activeObject.transform.translate.x"
        @input="
          e =>
            updateTransform(
              'translate',
              new Vec(parseInt(isNaN(e.srcElement.value) ? 0 : e.srcElement.value), activeObject.transform.translate.y)
            )
        "
        :disabled="!isEditor"
      />
      x
      <input
        type="number"
        style="width: 45%; display: inline-block"
        :value="activeObject.transform.translate.y"
        @input="
          e =>
            updateTransform(
              'translate',
              new Vec(activeObject.transform.translate.x, parseInt(isNaN(e.srcElement.value) ? 0 : e.srcElement.value))
            )
        "
        :disabled="!isEditor"
      />
    </div>
    <div class="pv-config-label">回転</div>
    <div>
      <input
        type="number"
        style="width: 100%"
        :value="activeObject.transform.rotateDeg"
        max="360"
        step="5"
        @input="e => updateTransform('rotate', parseInt(isNaN(e.srcElement.value) ? 0 : e.srcElement.value))"
        :disabled="!isEditor"
      />
    </div>
  </div>
</template>
<script>
  // @ts-check
  import { computed, defineComponent } from '@vue/composition-api';
  import { Vec } from '../../../../lib/util/math/vector';
  import { Matrix } from '../../../../lib/util/math/matrix';

  export default defineComponent({
    props: {
      activeObject: Object,
      isEditor: {  //  PROP: 編集権限の有無
        type: Boolean,
        default: true,
      },
    },
    setup(props, context) {
      /** @type {import('@vue/composition-api').ComputedRef<Matrix>} */
      const matrix = computed(() => {
        return props.activeObject?.absoluteMatrix?.();
      });

      const translate = computed(() => {
        if (props.activeObject) {
          const m = matrix.value;
          return Vec.from(m.point(props.activeObject?.transform?.translate));
        }

        return '';
      });

      const updateTransform = (/** @type {string} */ key, /** @type {any} */ val) => {
        if (key === 'translate') {
          props.activeObject.moveTo(val);
        } else if (key === 'rotate') {
          props.activeObject.rotateTo(val);
        }
      };

      return {
        translate,
        updateTransform,
        Vec,
      };
    },
  });
</script>
