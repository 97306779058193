// @ts-check

import { Arc } from './math/arc';
import { Vec } from './math/vector';

/**
 * svg pathを作成するためのクラス
 */
export class PathData {
  /** @type {string} */
  d = '';
  /** @type {string} */
  fill = 'white';
  /** @type {string} */
  stroke = 'black';
  /** @type {number} */
  strokeWidth = 0;
  /** @type {string} */
  strokeDasharray = '';
  /** @type {number} */
  offsetX = 0;
  /** @type {number} */
  offsetY = 0;

  /**
   * @param {Partial<PathData>|null} params
   */
  constructor(params = null) {
    if (params) {
      Object.assign(this, params);
    }
  }

  /**
   * 単純な多角形
   * @param {Vec[]} vecs
   * @returns {string}
   */
  static polygon(vecs) {
    return `M ${vecs.map(vec => `${vec.x},${vec.y} `).join('L ')}` + 'Z';
  }

  /**
   * 曲線を含むパス
   * @param {Vec[]} positions
   * @param {number[]?} arcPoints
   * @returns {string}
   */
  static path(positions, arcPoints = null, closed = true) {
    if (positions.length === 0) {
      return '';
    }
    if (arcPoints == null || arcPoints.length === 0) {
      arcPoints = positions.map(() => 0);
    }

    let d = '';
    let len = positions.length;
    for (let i = 0; i < len + 1; i++) {
      const prev = positions[i % len];
      const current = positions[(i + 1) % len];
      if (i === 0) {
        d += ` M${current.x},${current.y}`;
      } else {
        const dist = current.sub(prev);
        let arc;
        if (dist.absVal() !== 0) {
          // 制御点の絶対座標
          arc = current.centerPoint(prev).add(dist.nUnit().scale(arcPoints[i % len]));
        } else {
          arc = new Vec();
        }
        // 弧の定義
        if (arcPoints[i % len] === 0) {
          d += ` L ${current.x},${current.y}`;
        } else {
          d += this.createArcPathCommand(prev, arc, current);
        }
      }
    }

    if (closed) {
      d += ' Z';
    }

    return d;
  }

  /**
   * 長方形
   * @param {number} x
   * @param {number} y
   * @param {number} width
   * @param {number} height
   * @returns {string}
   */
  static rect(x, y, width, height) {
    return `M${x},${y} h${width} v${height} h${-width} Z`;
  }

  /**
   * 二点間のパス
   * @param {Vec[]} positions
   * @param {number} arcPoint
   * @returns {string}
   */
  static linePath(positions, arcPoint) {
    if (positions.length != 2) {
      throw new Error('');
    }
    if (arcPoint !== 0) {
      const dist = positions[1].sub(positions[0]);
      const arc = positions[1].centerPoint(positions[0]).add(dist.nUnit().scale(arcPoint));

      return (
        `M ${positions[0].x} ${positions[0].y} ` +
        this.createArcPathCommand(positions[0], arc, positions[1])
      );
    } else {
      return `M ${positions[0].x} ${positions[0].y} L ${positions[1].x} ${positions[1].y}`;
    }
  }

  /**
   * 弧のPath コマンドを生成
   * @private
   * @param {Vec} start
   * @param {Vec} mid
   * @param {Vec} end
   * @returns {string}
   */
  static createArcPathCommand(start, mid, end) {
    const { r, isLarger, isRightRotation, position } = Arc.getArc(start, mid, end);
    if (r == null) {
      return ` L ${end.x},${end.y}`;
    } else {
      return ` A ${r} ${r} 0 ${isLarger ? 1 : 0} ${isRightRotation ? 1 : 0} ${position.x} ${
        position.y
      }`;
    }
  }
}
