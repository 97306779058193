// @ts-check

/**
 * 操作が失敗するとエラーを投げるMap
 * @template T
 * @template S
 * @extends Map<T,S>
 */
export class StrictMap extends Map {
  /**
   * @param {T} id
   * @returns {S}
   */
  get(id) {
    if (!this.has(id)) {
      throw new Error('取得対象のオブジェクトは登録されていません。: ' + id);
    }

    // @ts-ignore
    return super.get(id);
  }

  /**
   * @param {T} id
   * @param {S} item
   */
  set(id, item) {
    if (this.has(id)) {
      throw new Error('登録対象のオブジェクトは既に登録されています。: ' + id);
    }
    return super.set(id, item);
  }

  /**
   * @param {T} id
   */
  delete(id) {
    if (!this.has(id)) {
      throw new Error('削除対象のオブジェクトは登録されていません。: ' + id);
    }
    return super.delete(id);
  }
}
