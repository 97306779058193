<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：初期投資/付帯費用/その他経費
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="OtherExpenses">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button id="btnOtherExpenses" variant="link" v-b-toggle.tabOtherExpenses>
          その他経費
        </b-button>
      </b-card-header>
      <b-collapse id="tabOtherExpenses" role="tabpanel">
        <b-card-body>
          <b-tabs fill pills v-model="investmentAnalytics.initialInvestment.otherExpenses.isDetail">
            <b-tab title="概算入力">
              <b-container>
                <b-row>
                  <b-col cols="4" class="title bold">総額</b-col>
                  <!--
                  <b-col cols="2" class="title bold">総額</b-col>
                  <b-col cols="2" class="title">
                    (税抜)
                  </b-col>
                  -->
                  <b-col>
                    <b-row>
                      <b-col>
                        <allyText
                          name="estimatedOtherExpenses"
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.otherExpenses
                              .estimatedOtherExpenses
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
                <!--
                <b-row>
                  <b-col cols="2" class="title bold" />
                  <b-col cols="2" class="title">
                    (税込)
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            sumTax(
                              investmentAnalytics.initialInvestment.otherExpenses
                                .estimatedOtherExpenses
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
                -->
              </b-container>
            </b-tab>
            <b-tab title="詳細入力">
              <b-container class="detail">
                <b-row>
                  <b-col class="title bold">項目</b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col class="title-center bold">その他経費</b-col>
                      <b-col cols="2" />
                    </b-row>
                  </b-col>
                  <b-col class="title-center ">詳細</b-col>
                </b-row>
                <b-row>
                  <b-col class="title ">祭祀費</b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col>
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="investmentAnalytics.initialInvestment.otherExpenses.ritualCost"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title" />
                </b-row>
                <b-row>
                  <b-col class="title ">近隣対策費</b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col>
                        <!--
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.otherExpenses
                              .neighborhoodWatchCosts.amount
                          "
                        />
                        -->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(
                              investmentAnalytics.initialInvestment.otherExpenses
                                .neighborhoodWatchCosts.amount
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button variant="ally" v-b-toggle.tabOtherExpenses1>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabOtherExpenses1" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">対策対象者数</b-col>
                                <b-col cols="2" />
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">単価</b-col>
                                <b-col cols="2" />
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">金額</b-col>
                                <b-col cols="2" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.otherExpenses
                                        .neighborhoodWatchCosts.quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="2" class="unit">人</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.otherExpenses
                                        .neighborhoodWatchCosts.unitPrice
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="2" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="neighborhoodWatchCostsTotal() | currency"
                                  />
                                </b-col>
                                <b-col cols="2" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title ">立退料</b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col>
                        <!--
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.otherExpenses
                              .estimatedEvictionFees
                          "
                        />
                        -->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            investmentAnalytics.initialInvestment.otherExpenses
                              .estimatedEvictionFees | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button variant="ally" v-b-toggle.tabOtherExpenses2>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabOtherExpenses2" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title-center bold">
                              項目
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">
                                  数量
                                </b-col>
                                <b-col cols="2" />
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">
                                  単価
                                </b-col>
                                <b-col cols="1" />
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">
                                  金額
                                </b-col>
                                <b-col cols="1" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title">
                              個人
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.otherExpenses
                                        .evictionFeesQuantity[0]
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  件
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="getUnitPrice('立退料', '個人') | currency"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  円
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(
                                        investmentAnalytics.initialInvestment.otherExpenses
                                          .evictionFeesQuantity[0]
                                      ) *
                                        getUnitPrice('立退料', '個人'))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  円
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title">
                              法人・個人事業者
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.otherExpenses
                                        .evictionFeesQuantity[1]
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  件
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="getUnitPrice('立退料', '法人・個人事業主') | currency"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  円
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(
                                        investmentAnalytics.initialInvestment.otherExpenses
                                          .evictionFeesQuantity[1]
                                      ) *
                                        getUnitPrice('立退料', '法人・個人事業主'))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  円
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="borderline" />
                          </b-row>
                          <b-row>
                            <b-col class="title">
                              合計
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(
                                        investmentAnalytics.initialInvestment.otherExpenses
                                          .evictionFeesQuantity[0]
                                      ) +
                                        Math.floor(
                                          investmentAnalytics.initialInvestment.otherExpenses
                                            .evictionFeesQuantity[1]
                                        ))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  件
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    readonly
                                    class="currency"
                                    :value="evictionFeesTotal() | currency"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  円
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title ">営業補償</b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col>
                        <!--
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.otherExpenses
                              .estimatedBusinessCompensation
                          "
                        />
                        -->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            investmentAnalytics.initialInvestment.otherExpenses
                              .estimatedBusinessCompensation | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button variant="ally" v-b-toggle.tabOtherExpenses3>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabOtherExpenses3" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title-center bold">
                              項目
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">
                                  数量
                                </b-col>
                                <b-col cols="1" />
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">
                                  単価
                                </b-col>
                                <b-col cols="1" />
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">
                                  金額
                                </b-col>
                                <b-col cols="1" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row v-for="id in 2" v-bind:key="`for05-${id}`">
                            <b-col class="ally-select">
                              <allySelect
                                :items="businessCompensationItems"
                                v-model="
                                  investmentAnalytics.initialInvestment.otherExpenses
                                    .businessCompensation[id - 1].content
                                "
                              />
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.otherExpenses
                                        .businessCompensation[id - 1].quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  件
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '営業補償',
                                        investmentAnalytics.initialInvestment.otherExpenses
                                          .businessCompensation[id - 1].content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  円
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(
                                        investmentAnalytics.initialInvestment.otherExpenses
                                          .businessCompensation[id - 1].quantity
                                      ) *
                                        getUnitPrice(
                                          '営業補償',
                                          investmentAnalytics.initialInvestment.otherExpenses
                                            .businessCompensation[id - 1].content
                                        ))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  円
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="borderline" />
                          </b-row>
                          <b-row>
                            <b-col class="title">
                              合計
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(
                                        investmentAnalytics.initialInvestment.otherExpenses
                                          .businessCompensation[0].quantity
                                      ) +
                                        Math.floor(
                                          investmentAnalytics.initialInvestment.otherExpenses
                                            .businessCompensation[1].quantity
                                        ))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  件
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="businessCompensationTotal() | currency"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  円
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title ">広告費</b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col>
                        <!--
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.otherExpenses.advertisingExpenses
                          "
                        />
                        -->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            investmentAnalytics.initialInvestment.otherExpenses.advertisingExpenses
                              | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button variant="ally" v-b-toggle.tabOtherExpenses6>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabOtherExpenses6" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title-center bold">
                              項目
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">
                                  数量
                                </b-col>
                                <b-col cols="1" />
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">
                                  単価
                                </b-col>
                                <b-col cols="1" />
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">
                                  金額
                                </b-col>
                                <b-col cols="1" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row v-for="id in 3" v-bind:key="`for07-${id}`">
                            <b-col class="ally-select">
                              <allySelect
                                :items="advertisingExpensesItems"
                                v-model="
                                  investmentAnalytics.initialInvestment.otherExpenses
                                    .advertisingExpensesDetails[id - 1].content
                                "
                              />
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.otherExpenses
                                        .advertisingExpensesDetails[id - 1].quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  件
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '広告費',
                                        investmentAnalytics.initialInvestment.otherExpenses
                                          .advertisingExpensesDetails[id - 1].content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  円
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(
                                        investmentAnalytics.initialInvestment.otherExpenses
                                          .advertisingExpensesDetails[id - 1].quantity
                                      ) *
                                        getUnitPrice(
                                          '広告費',
                                          investmentAnalytics.initialInvestment.otherExpenses
                                            .advertisingExpensesDetails[id - 1].content
                                        ))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  円
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title ">特殊経費</b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col>
                        <!--
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.otherExpenses
                              .estimatedSpecialExpenses
                          "
                        />
                        -->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            investmentAnalytics.initialInvestment.otherExpenses
                              .estimatedSpecialExpenses | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button variant="ally" v-b-toggle.tabOtherExpenses4>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabOtherExpenses4" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col cols="6" class="title-center bold">
                              細目
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="title-center bold">
                                  金額
                                </b-col>
                                <b-col cols="1" />
                              </b-row>
                            </b-col>
                            <b-col cols="2" />
                          </b-row>
                          <b-row v-for="id in 3" v-bind:key="`for06-${id}`">
                            <b-col cols="6">
                              <allyText
                                v-model="
                                  investmentAnalytics.initialInvestment.otherExpenses
                                    .specialExpenses[id - 1].content
                                "
                              />
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.otherExpenses
                                        .specialExpenses[id - 1].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  円
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col cols="2" />
                          </b-row>
                          <b-row>
                            <b-col class="borderline" />
                            <b-col cols="2" />
                          </b-row>
                          <b-row>
                            <b-col cols="6" class="title">
                              合計
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="specialExpensesTotal() | currency"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  円
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col cols="2" />
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title ">諸雑費</b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col>
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.otherExpenses
                              .miscellaneousExpenses
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title" />
                </b-row>
                <b-row>
                  <b-col class="title ">予備費</b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col>
                        <!--
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.otherExpenses.estimatedReserveFund
                          "
                        />
                        -->
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            investmentAnalytics.initialInvestment.otherExpenses
                              .estimatedReserveFund | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button variant="ally" v-b-toggle.tabOtherExpenses5>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabOtherExpenses5" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title">
                              総事業費(予備費を除く)
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="totalProjectCostExcludeReserve | currency"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  円
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title">
                              予備費率
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    :floatLength="2"
                                    v-model="
                                      investmentAnalytics.initialInvestment.otherExpenses
                                        .reserveRate
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  ％
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="borderline" />
                            <b-col cols="3" />
                          </b-row>
                          <b-row>
                            <b-col class="title">
                              予備費
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="reserveFundsTotal() | currency"
                                  />
                                </b-col>
                                <b-col cols="1" class="unit">
                                  円
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Calculation from './../Calculation';
  export default {
    mixins: [Calculation],
    data: function() {
      return {
        // 営業補償
        businessCompensationItems: this.getUnitPricesItems('営業補償'),
        // 広告費
        advertisingExpensesItems: this.getUnitPricesItems('広告費'),
      };
    },
    computed: {
      // 広告費/合計
      advertisingExpensesTotal: function() {
        let total = 0;
        this.investmentAnalytics.initialInvestment.otherExpenses.advertisingExpensesDetails.forEach(
          v => {
            total += Math.floor(v.quantity) * this.getUnitPrice('広告費', v.content);
          }
        );
        return total;
      },
    },
    watch: {
      // マスタ更新時
      '$store.state.master.unitPrice': {
        handler: function() {
          // 選択項目更新
          this.businessCompensationItems = this.getUnitPricesItems('営業補償');
        },
        deep: false,
      },
      // 広告費/合計 更新時
      advertisingExpensesTotal: {
        handler: function() {
          // 概算値更新
          this.investmentAnalytics.initialInvestment.otherExpenses.advertisingExpenses = this.advertisingExpensesTotal;
        },
        deep: false,
      },
    },
    methods: {
      // 入力チェック
      isEmpty: function(value) {
        return value == null || value == '' || value == undefined || Number.isNaN(value)
          ? true
          : false;
      },
      // 近隣対策費/合計
      neighborhoodWatchCostsTotal: function() {
        let total =
          Math.floor(
            Number(
              this.investmentAnalytics.initialInvestment.otherExpenses.neighborhoodWatchCosts
                .quantity
            )
          ) *
          Math.floor(
            Number(
              this.investmentAnalytics.initialInvestment.otherExpenses.neighborhoodWatchCosts
                .unitPrice
            )
          );
        // 概算を更新
        Vue.set(
          this.investmentAnalytics.initialInvestment.otherExpenses.neighborhoodWatchCosts,
          'amount',
          total
        );
        return total;
      },
      // 立退料/合計
      evictionFeesTotal: function() {
        let total = 0;
        total +=
          Math.floor(
            Number(this.investmentAnalytics.initialInvestment.otherExpenses.evictionFeesQuantity[0])
          ) * this.getUnitPrice('立退料', '個人');
        total +=
          Math.floor(
            Number(this.investmentAnalytics.initialInvestment.otherExpenses.evictionFeesQuantity[1])
          ) * this.getUnitPrice('立退料', '法人・個人事業主');
        // 概算を更新
        Vue.set(
          this.investmentAnalytics.initialInvestment.otherExpenses,
          'estimatedEvictionFees',
          total
        );
        return total;
      },
      // 営業補償/合計
      businessCompensationTotal: function() {
        if (
          this.isEmpty(
            this.investmentAnalytics.initialInvestment.otherExpenses.businessCompensation[0]
              .quantity
          ) &&
          this.isEmpty(
            this.investmentAnalytics.initialInvestment.otherExpenses.businessCompensation[1]
              .quantity
          )
        ) {
          return null;
        }
        let total = 0;
        total +=
          Math.floor(
            Number(
              this.investmentAnalytics.initialInvestment.otherExpenses.businessCompensation[0]
                .quantity
            )
          ) *
          this.getUnitPrice(
            '営業補償',
            this.investmentAnalytics.initialInvestment.otherExpenses.businessCompensation[0].content
          );
        total +=
          Math.floor(
            Number(
              this.investmentAnalytics.initialInvestment.otherExpenses.businessCompensation[1]
                .quantity
            )
          ) *
          this.getUnitPrice(
            '営業補償',
            this.investmentAnalytics.initialInvestment.otherExpenses.businessCompensation[1].content
          );
        // 概算を更新
        Vue.set(
          this.investmentAnalytics.initialInvestment.otherExpenses,
          'estimatedBusinessCompensation',
          total
        );
        return total;
      },

      // 特殊経費/合計
      specialExpensesTotal: function() {
        if (
          this.isEmpty(
            this.investmentAnalytics.initialInvestment.otherExpenses.specialExpenses[0].amount
          ) &&
          this.isEmpty(
            this.investmentAnalytics.initialInvestment.otherExpenses.specialExpenses[1].amount
          ) &&
          this.isEmpty(
            this.investmentAnalytics.initialInvestment.otherExpenses.specialExpenses[2].amount
          )
        ) {
          return null;
        }

        let total = this.investmentAnalytics.initialInvestment.otherExpenses.specialExpenses.reduce(
          (acc, value) => {
            return acc + Math.floor(Number(value.amount));
          },
          0
        );
        // 概算を更新
        Vue.set(
          this.investmentAnalytics.initialInvestment.otherExpenses,
          'estimatedSpecialExpenses',
          total
        );
        return total;
      },
      // 予備費
      reserveFundsTotal: function() {
        let total = 0;
        if (!this.isEmpty(this.investmentAnalytics.initialInvestment.otherExpenses.reserveRate)) {
          total = Math.floor(
            (this.totalProjectCostExcludeReserve *
              Math.floor(
                Number(this.investmentAnalytics.initialInvestment.otherExpenses.reserveRate) * 100
              )) /
              100 /
              100
          );
        }
        // 概算を更新
        Vue.set(
          this.investmentAnalytics.initialInvestment.otherExpenses,
          'estimatedReserveFund',
          total
        );
        return total;
      },
    },
  };
</script>
<style>
  #tabOtherExpenses .card-body > .container {
    padding: 0;
  }
  #tabOtherExpenses .detail .row {
    margin: 0;
  }
  #tabOtherExpenses .detail .col {
    padding: 0 7px;
  }
</style>
