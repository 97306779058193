<template>
  <CommonLayout
    :title="Math.floor(investmentAnalytics.salePlan.timeOfSale) + '年後の売却益（詳細）'"
    :companyLogo="companyLogo"
    :companyName="companyName"
  >
    <template v-slot>
      <div style="display: flex;  height:350px;">
        <div style="display: grid; font-size: 40px; width: 50%; margin: 70px 100px;">
          <div style="width: 100%;">
            <div :style="topHeaderStyle">詳細</div>
            <GainOnSale1 :result="result" />
            <GainOnSale2 :result="result" :spendingList="spendingList" />
          </div>
        </div>
        <div style="display: grid; font-size: 40px; width: 50%; margin: 70px 100px;">
          <div style="width: 100%;">
            <div :style="topHeaderStyle">詳細</div>
            <GainOnSale3 :result="result" :spendingList="spendingList" />
          </div>
        </div>
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import GainOnSale1 from './GainOnSale1';
  import GainOnSale2 from './GainOnSale2';
  import GainOnSale3 from './GainOnSale3';
  import {
    headerStyle,
    partHeaderStyle,
    subHeaderStyle,
    bodyStyle,
    titleStyle,
    unitPrice,
    tableFont,
    tableStyle,
    topHeaderStyle,
  } from '../../../../components/ExportReport/style';

  export default {
    props: {
      companyLogo: String,
      companyName: String,
      result: Object,
    },

    components: {
      CommonLayout,
      GainOnSale1,
      GainOnSale2,
      GainOnSale3,
    },
    data() {
      return {
        gainOnSaleResult: null,
        headerStyle,
        partHeaderStyle,
        subHeaderStyle,
        bodyStyle,
        titleStyle,
        unitPrice,
        tableFont,
        tableStyle,
        topHeaderStyle,
        col1Style: {
          width: '17%',
        },
        col2Style: {
          width: '25%',
        },
        col3Style: {
          'text-align': 'right',
          width: '8%',
        },
        col4Style: {
          width: '8%',
        },
        col5Style: {
          'text-align': 'center',
          width: '17%',
        },
        col6Style: {
          width: '25%',
        },
      };
    },
    computed: {
      spendingList() {
        const maxLineNum = 34; // １ページの最大行数

        if (this.result == null) {
          return [];
        }
        const items = this.result.spending.items;

        let lineCount = this.result.income.items.length; // 収入の部の行数
        const resultList = [];
        let tempList = [];
        items.forEach((val, i) => {
          if (val.items == null) {
            return;
          }
          lineCount += val.items.length;

          if (lineCount > maxLineNum) {
            resultList.push(tempList);
            tempList = [val];
            lineCount = 0;
          } else {
            tempList.push(val);
          }

          // 末尾対応
          if (i == items.length - 1) {
            resultList.push(tempList);
          }
        });

        return resultList;
      },
    },
  };
</script>
