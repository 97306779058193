// @ts-check

import { PvFloor } from '../../../node/node/floorNode';
import { PvGuideMarker } from '../../../node/node/guideMarkerNode';
import { createNode } from '../../../node/node/pvNode';
import { Action } from '../action';
import { ActionBuilder } from '../../action/builder/actionBuilder';

export class DrawRectActionBuilder extends ActionBuilder {
  build() {
    const action = new Action();
    action.actionType = Action.ACTION_TYPE.CLICK2;

    const guideMarker = createNode(PvGuideMarker);
    this.context.getGuideRoot().appendChild(guideMarker);
    guideMarker.moveTo(this.context.getCurrentPos());

    /** @type {PvFloor} */
    const guide = createNode(PvFloor);
    guide.style = this.context.getObjectPreset().style.clone();
    guide.style.stroke = 'black';
    guide.style.strokeWidth = 20;

    this.context.getGuideRoot().appendChild(guide);

    action.onUpdate = ({ first, last }) => {
      guide.deformateFromStartEnd(first, last);
    };

    // 矩形床作成時
    action.onComplete = ({ routes }) => {
      // 床作成時の設定値を受け取る
      const floor = PvFloor.createRect(
        routes, // 座標
        this.context.getObjectPreset().style.clone(), // スタイル
        this.context.getObjectPreset().height3d // 高さ3D
      );
      this.context.getRoot().appendChild(floor);
    };

    return action;
  }
}
