<!--
作成者：本間
作成日：2020.10.01
画面名：折れ線グラフ
機能：折れ線グラフ
親子関係：
更新者：
①
更新内容：
①
-->
<script>
  import { Line, mixins } from 'vue-chartjs';
  export default {
    extends: Line,
    mixins: [mixins.reactiveData],
    props: ['data', 'options'],
    mounted() {
      this.chartData = this.data;
    },
    watch: {
      data: function() {
        this.chartData = this.data;
      },
    },
  };
</script>
