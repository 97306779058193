<template>
  <!-- 高さを調整しないとスクロールバーが出るので直してください -->
  <div
    style="display: flex; background-color: var(--colorTheme); user-select: none;"
    :style="{
      height: height + 'px',
    }"
  >
    <button
      class="btn editor-back-btn"
      @click="$emit('back')"
      v-b-popover.hover.top="'エディターを閉じる'"
    >
      <i
        class="icon-01"
        style="filter:brightness(200%);background-color: transparent;font-size:2rem;"
      />
    </button>
    <div class="header-inner">
      <div v-if="isEditor">
        <EditorBtn
          class="editor-btn"
          @click="$emit('undo')"
          v-b-popover.hover.bottom="'戻る ctrl+z'"
        >
          <i class="icon-02" />
        </EditorBtn>
        <EditorBtn
          class="editor-btn"
          @click="$emit('redo')"
          v-b-popover.hover.bottom="'進む ctrl+shift+z'"
        >
          <i class="icon-03" />
        </EditorBtn>
      </div>
      <div class="ml-4">
        <!-- <EditorBtn
          class="editor-btn"
          :selected="mode === 'pan'"
          @click="$emit('mode', 'pan')"
          v-b-popover.hover.bottom="'移動'"
        >
          <i
            class="ti-move"
            v-bind:style="[{ filter: 'brightness(' + (mode === 'pan' ? 200 : 100) + '%)' }]"
          />
        </EditorBtn> -->
        <EditorBtn
          class="editor-btn"
          :selected="mode === 'select'"
          @click="$emit('mode', 'select')"
          v-b-popover.hover.bottom="'選択'"
        >
          <i
            class="icon-04"
            v-bind:style="[{ filter: 'brightness(' + (mode === 'select' ? 200 : 100) + '%)' }]"
          />
        </EditorBtn>
        <div v-if="isEditor" style="float: right;">
          <EditorBtn
            class="editor-btn"
            :selected="mode === 'floor'"
            @click="$emit('mode', 'floor')"
            v-b-popover.hover.bottom="'部屋'"
          >
            <i
              class="icon-05"
              v-bind:style="[{ filter: 'brightness(' + (mode === 'floor' ? 200 : 100) + '%)' }]"
            />
          </EditorBtn>
          <EditorBtn
            class="editor-btn"
            :selected="mode === 'icon'"
            @click="$emit('mode', 'icon')"
            v-b-popover.hover.bottom="'アイコン'"
          >
            <i
              class="icon-06"
              v-bind:style="[{ filter: 'brightness(' + (mode === 'icon' ? 200 : 100) + '%)' }]"
            />
          </EditorBtn>
          <EditorBtn
            class="editor-btn"
            :selected="mode === 'door'"
            @click="$emit('mode', 'door')"
            v-b-popover.hover.bottom="'ドア・窓'"
          >
            <i
              class="icon-23"
              v-bind:style="[{ filter: 'brightness(' + (mode === 'door' ? 200 : 100) + '%)' }]"
            />
          </EditorBtn>
          <EditorBtn
            class="editor-btn"
            :selected="mode === 'wall'"
            @click="$emit('mode', 'wall')"
            v-b-popover.hover.bottom="'壁'"
          >
            <i
              class="icon-24"
              v-bind:style="[{ filter: 'brightness(' + (mode === 'wall' ? 200 : 100) + '%)' }]"
            />
          </EditorBtn>
          <EditorBtn
            class="editor-btn"
            :selected="mode === 'stairs'"
            @click="$emit('mode', 'stairs')"
            v-b-popover.hover.bottom="'階段'"
          >
            <i
              class="icon-25"
              v-bind:style="[{ filter: 'brightness(' + (mode === 'stairs' ? 200 : 100) + '%)' }]"
            />
          </EditorBtn>
        </div>
      </div>
      <div class="ml-4">
        <EditorBtn
          class="editor-btn"
          @click="$emit('zoom', 5)"
          v-b-popover.hover.bottom="'拡大'"
          :disabled="layerScale >= 10"
        >
          <i class="icon-20" />
        </EditorBtn>
        <EditorBtn
          class="editor-btn"
          @click="$emit('zoom', -5)"
          v-b-popover.hover.bottom="'縮小'"
          :disabled="layerScale <= 0"
        >
          <i class="icon-21" />
        </EditorBtn>
      </div>
      <div class="ml-4" v-if="isEditor">
        <EditorBtn
          class="editor-btn"
          @click="$emit('length-menu')"
          v-b-popover.hover.bottom="'部屋の単位の設定'"
        >
          <i class="icon-15" />
        </EditorBtn>

        <EditorBtn
          class="editor-btn"
          @click="$emit('grid-menu')"
          v-b-popover.hover.bottom="'グリッドの設定'"
        >
          <i class="icon-19" />
        </EditorBtn>
      </div>
      <div class="ml-4" v-if="isEditor">
        <EditorBtn
          class="editor-btn"
          @click="$emit('downloadImage', 'png')"
          :selected="isImageGenerating"
          v-b-popover.hover.bottom="'平面図をダウンロード'"
        >
          <i v-show="!isImageGenerating" class="icon-16"/>
          <b-spinner small v-show="isImageGenerating" label="Spinning"></b-spinner
        ></EditorBtn>
        <EditorBtn
          v-if="!viewOnly"
          class="editor-btn"
          @click="$emit('startMeasurement')"
          v-b-popover.hover.bottom="ALLY_LICENSE.AR_MEASUREMENT_APP.LOGICAL_NAME"
        >
          <i class="icon-17" />
        </EditorBtn>
        <EditorBtn
          v-if="!viewOnly && isARSimulatorAppLicense"
          class="editor-btn"
          @click="$emit('startFurnitureSimulator')"
          v-b-popover.hover.bottom="ALLY_LICENSE.AR_SIMULATOR_APP.LOGICAL_NAME"
        >
          <i v-show="!isSaving" class="icon-22" />
        </EditorBtn>
        <EditorBtn
          v-if="!viewOnly"
          class="editor-btn"
          @click="$emit('openPlaneViewListModal')"
          v-b-popover.hover.bottom="'平面図一覧'"
        >
          <i v-show="!isSaving" class="icon-list" />
        </EditorBtn>
        <EditorBtn
          v-if="!viewOnly"
          class="editor-btn"
          @click="$emit('save')"
          :selected="isSaving"
          v-b-popover.hover.bottom="'保存 ctrl+s'"
        >
          <i v-show="!isSaving" class="icon-18" />
          <b-spinner small v-show="isSaving" label="Spinning"></b-spinner>
        </EditorBtn>
        <EditorBtn
          v-if="!viewOnly && isVRSimulatorLicense"
          class="editor-btn"
          @click="$emit('vrView', 'AllyVR Main')"
          v-b-popover.hover.bottom="ALLY_LICENSE.VR_SIMULATOR.LOGICAL_NAME"
          :disabled="isLoadingMaterials"
        >
          <Load width="25px" height="25px" margin="-9px 0px 0px 0px" v-if="isLoadingMaterials" />
          <i v-show="!isLoadingMaterials" class="icon-vr-simulator" />
        </EditorBtn>
      </div>
      <div
        class="editor-checkbox ml-4"
        v-if="!viewOnly && 1 < layerLength"
        style="margin-left: 1rem;"
      >
        <b-form-checkbox
          :checked="isDisplayLower"
          @change="$emit('changeDisplayLower', !isDisplayLower)"
          name="showLength"
          style=" "
        >
          <span>下層表示</span>
        </b-form-checkbox>
      </div>
      <!-- <EditorBtn class="editor-btn ml-4" @click="$emit('setting')"
      ><i v-show="!isSaving" class="ti-settings" v-b-popover.hover.bottom="'設定'"></i>
      <b-spinner small v-show="isSaving" label="Spinning"></b-spinner>
    </EditorBtn> -->
    </div>
  </div>
</template>
<script>
  // @ts-check
  import { onMounted, ref, getCurrentInstance } from '@vue/composition-api';
  import EditorBtn from './EditorBtn.vue';
  import Load from '../../../../components/Common/Load.vue';
  import { ALLY_LICENSE } from '../../lib/util/const';
  export default {
    components: {
      EditorBtn,
      Load,
    },
    props: {
      height: Number,
      mode: String,
      isSaving: Boolean,
      isImageGenerating: Boolean,
      viewOnly: Boolean,
      layerScale: Number,
      isLoadingMaterials: Boolean,
      isARSimulatorAppLicense: Boolean,
      isVRSimulatorLicense: Boolean,
      isEditor: {
        //  PROP: 編集権限の有無
        type: Boolean,
        default: true,
      },
      layerLength: {
        //  PROP: 設定されている階層の数
        type: Number,
        default: 0,
      },
      isDisplayLower: {
        //  PROP: 下階表示フラグ
        type: Boolean,
        default: true,
      },
    },
    data: function() {
      return {};
    },
    setup(_, ctx) {
      return { ALLY_LICENSE };
    },
  };
</script>
<style scoped>
  .editor-btn {
    margin-left: 2px;
    border-radius: 3px;
  }
  .editor-back-btn {
    color: white;
  }
  .editor-back-btn:hover {
    color: black;
  }
  .header-inner {
    display: flex;
    align-items: center;
  }
  .ctrl-root {
    width: 100%;
    display: flex;
    background-color: white;
  }
  .btn-icon {
    font-size: 2rem;
  }
  i {
    font-size: 2rem;
  }
  .editor-checkbox {
    z-index: 0;
  }
  .editor-checkbox span {
    color: #ffffff;
    font-weight: bold;
  }
</style>
<style>
  .editor-btn .allyLoad {
    z-index: 0;
  }
</style>
