<template>
  <div>
    <div class="menu-btn-group">
      <TileBtn
        :block="false"
        height="30px"
        :selected="floorType === 'rect'"
        @click="changeType('rect')"
      >
        矩形
      </TileBtn>
      <TileBtn
        :block="false"
        height="30px"
        :selected="floorType === 'polygon'"
        @click="changeType('polygon')"
      >
        多角形
      </TileBtn>
      <TileBtn
        :block="false"
        height="30px"
        :selected="floorType === 'split'"
        @click="changeType('split')"
      >
        分割
      </TileBtn>
    </div>
    <div
      class="item-list-container floor-material-list"
      :style="{ height: scrollBarHeight + 'px', padding: '10px' }"
    >
      <div class="floor-parameters col-12 mx-0 px-0 py-2">
        <div class="pv-config-label">部屋名</div>
        <div>
          <input
            :class="formCommonClassString"
            :value="objectPreset.style.spaceName"
            list="nameList"
            @change="e => $emit('changeSpaceName', e.srcElement.value)"
          />
          <datalist id="nameList">
            <option v-for="name in spaceNameList" :key="name.value" :label="name.value">
              {{ name.value }}
            </option>
          </datalist>
        </div>
        <div class="row mx-0 px-0">
          <div class="col-6 pl-0 pr-2">
            <div class="pv-config-label">壁の厚み(px)</div>
            <div>
              <input
                v-if="objectPreset"
                :class="formCommonClassString"
                type="number"
                :value="objectPreset.style.strokeWidth"
                step="5"
                @input="e => $emit('changeStrokeWidth', e.srcElement.value)"
              />
            </div>
          </div>
          <div class="col-6 pl-2 pr-0">
            <div class="pv-config-label">壁の高さ(m)</div>
            <div>
              <input
                v-if="objectPreset"
                :class="formCommonClassString"
                type="number"
                min="0"
                max="10"
                step="0.1"
                :value="objectPreset.height3d.toFixed(2)"
                @input="e => $emit('changeHeight3d', e.srcElement.value)"
              />
            </div>
          </div>
        </div>
        <div class="pv-config-label">壁の色</div>
        <div>
          <select
            :class="formCommonClassString"
            type="number"
            :checked="objectPreset.style.stroke"
            @change="e => $emit('changeStrokeColor', e.srcElement.value)"
          >
            <option v-for="color in wallColorList" :key="color.value" :value="color.value">
              {{ color.label }}
            </option>
          </select>
        </div>

        <div class="pv-config-label mt-3">素材</div>
        <allyRadio
          class="col-12 p-0"
          name="material-type"
          :items="[
            { text: '単色', value: MATERIAL_TYPE.SINGLE_COLOR },
            { text: 'パターン', value: MATERIAL_TYPE.PATTERN },
          ]"
          v-model="isActiveFloorMaterialType"
        />

        <!-- 素材 パターン設定 -->
        <div
          class="material-setting mx-0 px-0 my-2"
          v-show="isActiveFloorMaterialType === MATERIAL_TYPE.PATTERN"
        >
          <div class="pv-config-label">パターン幅(px)</div>
          <div>
            <input
              :class="formCommonClassString"
              type="number"
              min="0"
              max="1000"
              :value="patternWidth"
              @change="e => $emit('changePatternWidth', e.srcElement.value)"
            />
          </div>
        </div>

        <!-- 素材 パターン 分類 -->
        <div class="col-12 mb-1 p-0" v-show="isActiveFloorMaterialType === MATERIAL_TYPE.PATTERN">
          <!-- 大分類ボタン -->
          <button
            v-for="category in categories"
            :key="category.displayName"
            @click="selectCategory(category)"
            :class="{
              'btn btn-outline-ally category-btn mr-1 mb-2 p-2': true,
              active: categoryIndex === category.sortNo && !isSearch,
            }"
          >
            {{ category.displayName }}
          </button>
          <div class="row m-0 p-0" v-if="categories.length" style="display: flex; width: 100%;">
            <div
              :class="'col-' + 12 / categories[categoryIndex].child.length + ' m-0 p-0'"
              v-for="middleCategory in categories[categoryIndex].child"
              :key="middleCategory.id"
            >
              <!-- 中分類ボタン -->
              <button
                :class="{
                  'btn btn-outline-ally category-btn mr-1 p-2': true,
                  active: middleCategorySystemName === middleCategory.systemName && !isSearch,
                }"
                @click="selectMiddleCategory(middleCategory)"
                :style="'width: ' + (100 - categories[categoryIndex].child.length * 2) + '%;'"
              >
                {{ middleCategory.displayName }}
              </button>
            </div>
          </div>
        </div>
        <!-- 素材 パターン検索 -->
        <div
          class="material-setting mx-0 px-0 my-2"
          v-show="isActiveFloorMaterialType === MATERIAL_TYPE.PATTERN"
        >
          <div id="popover">
            <input
              :class="formCommonClassString"
              type="text"
              v-model="searchMessage"
              placeholder="品番検索"
            />
            <b-popover
              content="品番で検索できます。"
              target="popover"
              triggers="hover focus"
              placement="right"
              boundary="viewport"
            />
          </div>
        </div>

        <div class="mt-2 mb-4">
          <!-- 素材 単色 -->
          <div v-show="isActiveFloorMaterialType === MATERIAL_TYPE.SINGLE_COLOR">
            <v-swatches
              class=""
              v-model="vModelSingleColor"
              swatches="text-advanced"
              show-fallback
              popover-x="left"
              row-length="4"
              swatch-size="16"
              fallback-input-type="color"
              fallback-ok-text="閉じる"
              :inline="true"
            >
              <div slot="trigger" class="color-bucket">■</div>
            </v-swatches>
          </div>

          <!-- 素材 パターン リスト部分 -->
          <GridView
            v-show="isActiveFloorMaterialType === MATERIAL_TYPE.PATTERN"
            :items="selectCategoryMaterial"
            :height="gridHeight"
            :currentItem="currentMaterial"
            @clickItem="val => changeMaterial(val.name)"
          >
            <template #item="{ item }">
              <img
                v-if="item.url && !isLoading"
                :src="item.url"
                loading="lazy"
                ref="floorImg"
                class="floor-disp-img"
                alt=""
                width="100%"
                height="50"
              />
              <div
                v-else
                class="floor-disp-img"
                style="height: 50px"
                :style="{ backgroundColor: currentMaterial.color }"
              ></div>
              <div>
                {{ item.name }}
              </div>
            </template>
          </GridView>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import VSwatches from 'vue-swatches';
  // @ts-check
  import {
    computed,
    defineComponent,
    onMounted,
    ref,
    toRefs,
    watch,
    onUnmounted,
  } from '@vue/composition-api';
  import TileBtn from '../PvBtn.vue';
  import ItemList from '../ItemList.vue';
  import GridView from './GridView.vue';
  import { WALL_COLOR_LIST, MATERIAL_TYPE, SPACE_NAME_LIST } from '../../../lib/util/const';
  import materialMaster from '../../../../Property/materialMaster';

  export default defineComponent({
    components: {
      TileBtn,
      ItemList,
      GridView,
      VSwatches,
    },
    props: {
      material: Array,
      floorType: String, // GlobalSettings
      objectPreset: Object, // PvObject
      height: Number,
      isLoading: Boolean,
    },
    setup(props, context) {
      const isActiveFloorMaterialType = ref(1); // DATA: 選択中の床マテリアルタイプ
      const vModelSingleColor = ref('#ffffff'); // DATA: Vモデル：単色
      const listRef = ref(null);
      const categories = ref([]); // DATA: カテゴリー
      const categoryIndex = ref('0'); //  DATA: 選択している大項目カテゴリー添字
      const middleCategorySystemName = ref(''); //  DATA: 選択している中項目カテゴリー
      const wallColorList = ref(WALL_COLOR_LIST); // DATA: 壁色リスト
      const spaceNameList = ref(SPACE_NAME_LIST); // DATA: 部屋名リスト
      const formCommonClassString = ref('col-12 p-1'); // DATA: サイドメニュー入力欄用共通class文字列
      const searchMessage = ref(''); // DATA: 検索文字
      const isSearch = ref(false); // DATA: 文字検索のフラグ
      const scrollBarHeight = ref(0); // DATA: スクロールの縦幅

      /**
       * METHOD: スクロールバーの高さ再計算
       */
      const calculateScrollBarHeight = () => {
        scrollBarHeight.value = props.height - 60;
      };

      onMounted(async () => {
        // MOUNTED: マスターデータからデータを取得
        const masterData = await materialMaster.methods.getMaterialClassList(5);
        categories.value = await masterData[0].child;
        middleCategorySystemName.value = categories.value[0].child[0].systemName;

        // MOUNTED: リストの縦幅
        scrollBarHeight.value = props.height - 60; // menu-headerの高さ分を引く

        // MOUNTED: windowの縦幅を自動検知リスナ
        window.addEventListener('resize', calculateScrollBarHeight);
      });

      onUnmounted(() => {
        // UNMOUNTED: windowの縦幅を自動検知リスナ解除
        removeEventListener('resize', calculateScrollBarHeight);
      });

      // COMPUTED: GridView用heightの算出(素材リストの表示範囲)
      const gridHeight = computed(() => {
        // 検索またはカテゴリによるフィルター後の素材数 / 表示列数 * １素材のpx数
        let height = Math.round(selectCategoryMaterial.value.length / 2) * 100;
        if (height > 480) {
          // 表示領域が超えた場合にスクロールバーが消えないよう固定する
          height = 480;
        }
        return height;
      });

      const { material } = toRefs(props);
      const selectCategoryMaterial = ref(material.value); //  DATA: 選択したカテゴリーの素材

      /**
       * METHOD: 検索文字列を入力する。ヒットしたデータを返却する
       * @param {String} searchMessage 検索文言
       * @returns 検索結果を出力
       * [
       *  [カラーコード],[カラーコード],
       * ]
       */
      const getMaterialSearch = async searchMessage => {
        selectCategoryMaterial.value = [];
        if (searchMessage.value == '' || searchMessage.value == null) {
          materialFilter();
          return;
        }
        // 文字が入力されたフラグを建てる
        isSearch.value = true;

        //前方一致
        //let regexp = new RegExp('^' + searchMessage + '(.*?)', 'g');
        //部分一致
        let regexp = new RegExp(searchMessage.value + '(.*?)', 'g');

        selectCategoryMaterial.value = await material.value.filter(val => val.name.match(regexp));
      };

      /**
       *  METHOD: 素材を選別する
       */
      const materialFilter = async () => {
        selectCategoryMaterial.value = await material.value.filter(m => {
          return m.class === middleCategorySystemName.value;
        });
      };

      const changeType = (/** @type {string} */ mode) => {
        context.emit('changeFloorType', mode);
      };

      /** @type {import('@vue/composition-api').Ref<string>} */
      const currentMaterialName = ref(vModelSingleColor.value);
      const currentMaterial = computed(() => {
        // 名前がマテリアルに存在するかチェック
        const result = material.value.find(
          (/** @type {{ name: string; }} */ ma) => ma.name === currentMaterialName.value
        );
        if (result) {
          // 名前がマテリアルに存在する場合、単色の値を消す
          vModelSingleColor.value = null;
        }
        // 名前がマテリアルに存在する場合はマテリアル。無ければColorに色名をセットして返す。
        return result ?? { url: '', color: currentMaterialName.value };
      });

      const patternWidth = computed(() => {
        return props.objectPreset.style.patternWidth;
      });

      const changeMaterial = (/** @type {string} */ name) => {
        currentMaterialName.value = name;
        context.emit('changeMaterial', currentMaterial.value);
      };

      /**
       * WATCH: カラーパレットの値をマテリアルに反映
       */
      watch([vModelSingleColor], () => {
        // 値がある場合のみ処理する
        if (vModelSingleColor.value) {
          changeMaterial(vModelSingleColor.value);
        }
      });

      /**
       * WATCH: 選択したカテゴリーで素材を再仕分け
       */
      watch([material, middleCategorySystemName], () => {
        materialFilter();
      });

      /**
       *  WATCH: 入力した文字列で素材検索
       */
      watch(searchMessage, () => {
        getMaterialSearch(searchMessage);
      });

      /**
       *  METHOD: 素材カテゴリ選択の反映
       */
      const selectCategory = category => {
        isSearch.value = false; // フラグの初期化
        searchMessage.value = ''; // 検索文字の初期化
        categoryIndex.value = category.sortNo;
      };

      /**
       *  METHOD: 素材中項目カテゴリ選択の反映
       */
      const selectMiddleCategory = middleCategory => {
        isSearch.value = false; // フラグの初期化
        searchMessage.value = ''; // 検索文字の初期化
        middleCategorySystemName.value = middleCategory.systemName;
      };

      return {
        MATERIAL_TYPE, // 壁マテリアルタイプ
        isActiveFloorMaterialType, // 選択中の床マテリアルタイプ
        vModelSingleColor, // Vモデル：単色
        changeType,
        changeMaterial,
        currentMaterial,
        currentMaterialName,
        patternWidth,
        listRef,
        scrollBarHeight,
        wallColorList,
        spaceNameList,
        categories,
        categoryIndex,
        selectCategory,
        middleCategorySystemName,
        selectMiddleCategory,
        selectCategoryMaterial,
        materialFilter,
        formCommonClassString, // サイドメニュー入力欄用共通class文字列
        searchMessage, // 検索文字列
        getMaterialSearch, // 素材検索関数
        isSearch, // 検索フラグ
        gridHeight, // スクロールバーの高さ
      };
    },
  });
</script>
<style scoped>
  button.category-btn {
    font-size: 0.5rem;
  }
  .menu-btn-group {
    display: flex;
    font-size: 0.8rem;
  }
  .layout-menu-back {
    color: black;
    background-color: white;
  }
  .floor-disp {
    user-select: none;
    padding: 10px;
  }
  .floor-parameters {
    padding: 10px;
  }
</style>
<style>
  /* カラーパレット「fallback-input-type」表示調整 */
  .vue-swatches__fallback__input--wrapper .vue-swatches__fallback__input {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  /* カラーパレット「fallback-ok-text」のボタンサイズ調整 */
  .vue-swatches__fallback__button {
    white-space: nowrap;
    font-size: 6px;
    padding: 3px 5px;
  }
  /* 素材タブのZ位置調整(ALLYロゴ表示時に「単色」が表示されてしまう対策) */
  .floor-parameters .btn-group .btn.active {
    z-index: 0;
  }
  /* スクロールバーのスタイル(item-list) */
  .item-list-container {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .item-list-container::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .item-list-container::-webkit-scrollbar-track {
    background: #eeeeee;
  }
  .item-list-container::-webkit-scrollbar-thumb {
    background: var(--colorTheme);
    border-radius: 10px;
  }
</style>
