var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"GroundImprovementWork"}},[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("地盤改良工事")]),_c('b-col'),_c('b-col',{staticClass:"component"},[_c('b-row',[_c('b-col',{staticClass:"component"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
              _vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[18].amount
            ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[18], "amount", $$v)},expression:"\n              investmentAnalytics.initialInvestment.constructionPlan.constructionCost[18].amount\n            "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"2"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title",attrs:{"cols":"1"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabInitialInvestment5A",modifiers:{"tabInitialInvestment5A":true}}],attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabInitialInvestment5A","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v("建築棟数")]),_c('b-col',{staticClass:"component",attrs:{"cols":"3"}},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                    _vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[18]
                      .quantity
                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[18]
                      , "quantity", $$v)},expression:"\n                    investmentAnalytics.initialInvestment.constructionPlan.constructionCost[18]\n                      .quantity\n                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("棟")])],1),_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v("単価")]),_c('b-col',{staticClass:"component",attrs:{"cols":"3"}},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice('地盤改良工事', '有'))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円/棟")])],1),_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"2"}},[_vm._v("工事費")]),_c('b-col',{staticClass:"component",attrs:{"cols":"3"}},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                    _vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[18]
                      .amount
                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[18]
                      , "amount", $$v)},expression:"\n                    investmentAnalytics.initialInvestment.constructionPlan.constructionCost[18]\n                      .amount\n                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")]),_c('b-col',{staticClass:"title-right",staticStyle:{"white-space":"nowrap"},attrs:{"cols":"1"}},[_vm._v("※目安")]),_c('b-col',{staticClass:"component",attrs:{"cols":"3"}},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")((Math.floor(
                      _vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[18]
                        .quantity
                    ) *
                      _vm.getUnitPrice('地盤改良工事', '有')))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")]),_c('button',{staticClass:"btn btn-ally mr-1 cols='1'",staticStyle:{"width":"50px","white-space":"nowrap","padding-left":"10px"},on:{"click":function($event){_vm.changeGuideline(
                    _vm.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[18]
                      .quantity * _vm.getUnitPrice('地盤改良工事', '有')
                  )}}},[_vm._v(" 反映 ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }