/**
 * 素材materialの分類情報を記載する。
 */

import { API, graphqlOperation } from 'aws-amplify';
import { getSystemMaster } from '../../graphql/queries';
export default {
  data: function() {
    return {
      imageSortData: null,
      getValue: null,
    };
  },
  async created() {},
  methods: {
    /**
     * マテリアルソートリストを取得
     * @param {number} trueCheckNo 4→フォトシミュ 5→オートスケッチ
     * @returns
     */
    async getMaterialClassList(trueCheckNo) {
      const result = await this.getListData(trueCheckNo);
      return result;
    },
    //特定のマテリアルソートリストを取得
    async getMaterialClass(systemName, trueCheckNo) {
      let data = null;
      let list = await this.getListData(trueCheckNo);
      data = list.find(val => val.systemName == systemName);
      return data;
    },

    /**=================================================================
     * graphQLからソート情報を取得
     */
    async getListData(trueCheckNo) {
      //一度取得している場合はそのまま返却する
      if (this.imageSortData != null) {
        return this.imageSortData;
      }
      try {
        //マスタ情報をロード
        let masResult = await API.graphql(
          graphqlOperation(getSystemMaster, {
            classification: 'materialImage',
            classificationDetail: '2',
          })
        );
        this.getValue = masResult.data.getSystemMaster.value;

        //取得データを作成
        let materialClassList = [];
        let parents = this.getValue.filter(val => val[3] == '0');
        materialClassList = this.getChildData(parents, trueCheckNo);
        this.imageSortData = materialClassList;
        return materialClassList;
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * 取得データを親子関係の配列で作成
     */
    getChildData(parents, trueCheckNo) {
      let parentsData = [];
      for (let parent of parents) {
        //各機能で利用するかどうか確認する
        let check = false;
        if (trueCheckNo == null) {
          check = true;
        } else {
          if (parent[trueCheckNo] == 'TRUE') {
            check = true;
          }
        }

        //チェックの結果がtrueのとき、データを作成する
        if (check) {
          let data = {
            displayName: parent[0],
            systemName: parent[1],
            sortNo: parent[2],
            child: [],
          };
          let childs = this.getValue.filter(val => val[3] == parent[1]);
          data.child = this.getChildData(childs, trueCheckNo);
          parentsData.push(data);
        }
      }
      return parentsData;
    },
  },
};
