<!--
作成者：honma@com-bo.co.jp
作成日：2021/10/26
画面名：家具シミュレーター
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <!-- モーダル -->
    <b-modal
      ref="funitureSimulatorModal"
      id="funitureSimulatorModal"
      header-class="ally-modal text-center"
      footer-class="ally-modal text-center"
      body-class="ally-input-background"
      title="確認"
      centered
      ok-only
      size="md"
    >
      <div style="text-align: center">
        こちらの機能は iPhone または iPad でご利用いただけます。
      </div>
      <table class="ar-measure">
        <tbody>
          <tr>
            <td colspan="3">
              <a
                href="https://apps.apple.com/jp/app/ally-ar%E3%82%B7%E3%83%9F%E3%83%A5%E3%83%AC%E3%83%BC%E3%82%BF%E3%83%BC/id1572609232?itscg=30200&amp;itsct=apps_box_appicon"
                style="width: 128px; height: 128px; border-radius: 22%;"
                target="_blank"
              >
                <img
                  src="@/images/ar/ally-ar.png"
                  alt="ALLY - ARシミュレーター"
                  style="width: 128px; height: 128px; border-radius: 22%;"
                />
              </a>
              <img src="@/images/ar/title.png" />
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <a
                href="https://apps.apple.com/jp/app/ally-ar%E3%82%B7%E3%83%9F%E3%83%A5%E3%83%AC%E3%83%BC%E3%82%BF%E3%83%BC/id1572609232?itsct=apps_box_badge&amp;itscg=30200"
                style="border-radius: 13px; width: 225px; height: 83px;"
                target="_blank"
              >
                <img
                  src="@/images/ar/black.svg"
                  alt="Download on the App Store"
                  style="border-radius: 13px; width: 225px; height: 83px;"
                />
              </a>
            </td>
            <td rowspan="4">
              <img src="@/images/ar/qr-code.jpg" style="width: 128px; height: 128px;" />
            </td>
          </tr>
          <tr>
            <td rowspan="3">推奨デバイス：</td>
            <td>
              iPhone 12 以降の Pro 及び Pro Max<br />
              iPad Pro 11インチ(第2世代)以降<br />
              iPad Pro 12.9インチ(第4世代)以降<br />
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>
<script>
  import { callUnity, CALL_COMMAND } from '@/js/ar/unity';
  export default {
    methods: {
      // 家具シミュレーター開始
      start: async function() {
        const result = callUnity(CALL_COMMAND.START_FURNITURE_SIMULATOR);
        if (!result) {
          this.$refs.funitureSimulatorModal.show();
        }
      },
    },
    computed: {
      showFlg() {
        return this.$store.state.showFurnitureSimulator;
      },
    },
    watch: {
      showFlg() {
        if (this.$store.state.showFurnitureSimulator) {
          this.start();
        }
      },
    },
  };
</script>
<style scoped>
  .ar-measure {
    font-size: small;
  }
  .ar-measure td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    vertical-align: top;
  }
</style>
