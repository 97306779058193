<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：売却
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="SalePlan">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button id="btnSalePlan" variant="link" v-b-toggle.tabSalePlan @click="viewFlag = true">
          売却
        </b-button>
      </b-card-header>
      <b-collapse id="tabSalePlan" role="tabpanel" v-if="viewFlag">
        <b-card>
          <b-card-group columns>
            <b-card no-body class="category">
              <b-card-header role="tab">
                <b-button variant="link">売却時期</b-button>
              </b-card-header>
              <b-card-body>
                <table>
                  <tbody>
                    <tr>
                      <td style="width:100px;">
                        <allyText
                          name="timeOfSale"
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          min="1"
                          max="45"
                          v-model="investmentAnalytics.salePlan.timeOfSale"
                          roundType="down"
                        />
                      </td>
                      <td>&nbsp;年後</td>
                    </tr>
                  </tbody>
                </table>
              </b-card-body>
            </b-card>
            <b-card no-body class="category">
              <b-card-header role="tab">
                <b-button id="btnSalePlan1" variant="link" v-b-toggle.tabSalePlan1>
                  売却代金内訳
                </b-button>
              </b-card-header>
              <b-collapse id="tabSalePlan1" role="tabpanel">
                <b-card-body>
                  <b-container class="detail">
                    <b-row>
                      <b-col cols="3" class="title">※売却時点での想定地価上昇率</b-col>
                      <b-col cols="3">
                        <b-row>
                          <b-col class="component">
                            <allyText
                              name="assumedLandPriceIncrease"
                              type="number"
                              class="number"
                              :separation="true"
                              :floatLength="2"
                              halfWidth
                              v-model="
                                investmentAnalytics.salePlan.breakdownOfSalePrice
                                  .assumedLandPriceIncrease
                              "
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">％</b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="borderline" />
                    </b-row>
                    <b-row>
                      <b-col class="title-center bold">種別</b-col>
                      <b-col>
                        <b-row>
                          <b-col class="title-center bold">価格</b-col>
                          <b-col cols="2" />
                        </b-row>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col class="title-center">※目安</b-col>
                          <b-col cols="2" />
                        </b-row>
                      </b-col>
                      <b-col />
                    </b-row>
                    <b-row>
                      <b-col class="title">土地</b-col>
                      <b-col>
                        <b-row>
                          <b-col class="component">
                            <allyText
                              name="breakdownOfSalePrice-land"
                              type="number"
                              class="number"
                              :separation="true"
                              halfWidth
                              v-model="investmentAnalytics.salePlan.breakdownOfSalePrice.land"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                        </b-row>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col class="component">
                            <b-form-input
                              disabled
                              class="currency"
                              :value="landSalesPriceGuideline | currency"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                        </b-row>
                      </b-col>
                      <b-col class="title">
                        <b-button
                          id="btnLandSalesPrice"
                          variant="ally"
                          @click="changeGuidelineSaleLand('land', landSalesPriceGuideline)"
                        >
                          反映
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">建物</b-col>
                      <b-col>
                        <b-row>
                          <b-col class="component">
                            <allyText
                              name="breakdownOfSalePrice-building"
                              type="number"
                              class="number"
                              :separation="true"
                              halfWidth
                              @click="deleteDetails()"
                              v-model="investmentAnalytics.salePlan.breakdownOfSalePrice.building"
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                        </b-row>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col class="component">
                            <b-form-input
                              disabled
                              class="currency"
                              :value="buildingSalesPriceGuideline | currency"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                        </b-row>
                      </b-col>
                      <b-col class="title">
                        <b-button
                          id="btnBuildingSalesPrice"
                          variant="ally"
                          v-on:click="changeGuidelineAllSaleBuild()"
                        >
                          反映
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col></b-col>
                      <b-col></b-col>
                      <b-col></b-col>
                      <b-col>
                        <b-button
                          id="btnBuildingSalesPrice"
                          variant="ally"
                          v-b-toggle.tabBuildingSalesPrice
                        >
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" style="padding:0">
                        <b-collapse id="tabBuildingSalesPrice" role="tabpanel">
                          <b-card-body style="padding:0;">
                            <b-container style="padding:0;">
                              <b-row
                                v-for="id of investmentAnalytics.existingBuildingCount"
                                :key="`tabBuildingSalesPrice1-${id}`"
                                style="padding-top:0.5em;margin:0;"
                              >
                                <b-col class="title"> 既存建物{{ id }} </b-col>
                                <b-col>
                                  <b-row>
                                    <b-col class="component">
                                      <allyText
                                        :name="'buildingDetail' + id"
                                        type="number"
                                        class="number"
                                        :separation="true"
                                        halfWidth
                                        v-model="
                                          investmentAnalytics.salePlan.breakdownOfSalePrice
                                            .buildingDetail[id - 1]
                                        "
                                        v-on:input="buildingDetailTotal"
                                        roundType="down"
                                      />
                                    </b-col>
                                    <b-col cols="2" class="unit">円</b-col>
                                  </b-row>
                                </b-col>
                                <b-col>
                                  <b-row>
                                    <b-col class="component">
                                      <b-form-input
                                        disabled
                                        class="currency"
                                        :value="amortizedBalanceAtSale(0, id - 1) | currency"
                                      />
                                    </b-col>
                                    <b-col cols="2" class="unit">円</b-col>
                                  </b-row>
                                </b-col>
                                <b-col class="title">
                                  <b-button
                                    id="btnExistingBuildingSalesPrice"
                                    variant="ally"
                                    @click="
                                      changeGuidelineCitySaleBuild(
                                        id - 1,
                                        amortizedBalanceAtSale(0, id - 1)
                                      )
                                    "
                                  >
                                    反映
                                  </b-button>
                                </b-col>
                              </b-row>
                              <b-row
                                v-for="id of investmentAnalytics.newBuildingCount"
                                :key="`tabBuildingSalesPrice2-${id}`"
                                style="padding-top:0.5em;margin:0;"
                              >
                                <b-col class="title"> 新規建物{{ id }} </b-col>
                                <b-col>
                                  <b-row>
                                    <b-col class="component">
                                      <allyText
                                        :name="'buildingDetail' + (id + 10)"
                                        type="number"
                                        class="number"
                                        :separation="true"
                                        halfWidth
                                        v-model="
                                          investmentAnalytics.salePlan.breakdownOfSalePrice
                                            .buildingDetail[10 + id - 1]
                                        "
                                        v-on:input="buildingDetailTotal"
                                        roundType="down"
                                      />
                                    </b-col>
                                    <b-col cols="2" class="unit">円</b-col>
                                  </b-row>
                                </b-col>
                                <b-col>
                                  <b-row>
                                    <b-col class="component">
                                      <b-form-input
                                        disabled
                                        class="currency"
                                        :value="amortizedBalanceAtSale(1, id - 1) | currency"
                                      />
                                    </b-col>
                                    <b-col cols="2" class="unit">円</b-col>
                                  </b-row>
                                </b-col>
                                <b-col class="title">
                                  <b-button
                                    id="btnNewBuildingSalesPrice"
                                    variant="ally"
                                    @click="
                                      changeGuidelineCitySaleBuild(
                                        10 + id - 1,
                                        amortizedBalanceAtSale(1, id - 1)
                                      )
                                    "
                                  >
                                    反映
                                  </b-button>
                                </b-col>
                              </b-row>
                              <b-row style="margin:0 5px;">
                                <b-col class="borderline" />
                              </b-row>
                              <b-row style="margin:0;padding-bottom:0.5em;">
                                <b-col class="title">合計</b-col>
                                <b-col>
                                  <b-row>
                                    <b-col class="component">
                                      <b-form-input
                                        disabled
                                        class="currency"
                                        :value="buildingDetailTotal() | currency"
                                      />
                                    </b-col>
                                    <b-col cols="2" class="unit">円</b-col>
                                  </b-row>
                                </b-col>
                                <b-col>
                                  <b-row>
                                    <b-col class="component">
                                      <b-form-input
                                        disabled
                                        class="currency"
                                        :value="buildingSalesPriceGuideline | currency"
                                      />
                                    </b-col>
                                    <b-col cols="2" class="unit">円</b-col>
                                  </b-row>
                                </b-col>
                                <b-col> </b-col>
                              </b-row>
                            </b-container>
                          </b-card-body>
                        </b-collapse>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">付属建物</b-col>
                      <b-col>
                        <b-row>
                          <b-col class="component">
                            <allyText
                              name="depreciatedAssets"
                              type="number"
                              class="number"
                              :separation="true"
                              halfWidth
                              v-model="
                                investmentAnalytics.salePlan.breakdownOfSalePrice.depreciatedAssets
                              "
                              roundType="down"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                        </b-row>
                      </b-col>
                      <b-col />
                      <b-col />
                    </b-row>
                    <b-row>
                      <b-col class="borderline" />
                    </b-row>
                    <b-row>
                      <b-col class="title">消費税</b-col>
                      <b-col>
                        <b-row>
                          <b-col class="component">
                            <b-form-input
                              disabled
                              class="currency"
                              :value="
                                tax(
                                  investmentAnalytics.salePlan.breakdownOfSalePrice.building,
                                  Math.floor(
                                    investmentAnalytics.salePlan.breakdownOfSalePrice
                                      .depreciatedAssets
                                  )
                                ) | currency
                              "
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                        </b-row>
                      </b-col>
                      <b-col />
                      <b-col />
                    </b-row>
                    <b-row>
                      <b-col class="title">合計</b-col>
                      <b-col>
                        <b-row>
                          <b-col class="component">
                            <b-form-input
                              disabled
                              class="currency"
                              :value="salePriceTotal | currency"
                            />
                          </b-col>
                          <b-col cols="2" class="unit">円</b-col>
                        </b-row>
                      </b-col>
                      <b-col />
                      <b-col />
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-card-group>
          <b-card-group columns style="column-count:1;">
            <b-card no-body class="category">
              <b-card-header role="tab">
                <b-button id="btnSalePlan2" variant="link" v-b-toggle.tabSalePlan2>
                  売却時経費
                </b-button>
              </b-card-header>
              <b-collapse id="tabSalePlan2" role="tabpanel" visible>
                <b-card-body>
                  <b-card-group columns id="groupExpensesAtSale">
                    <b-card no-body style="margin:0;padding:0;border:none;box-shadow:none;">
                      <b-card-group class="columns" columns style="column-count:1;">
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button id="btnSalePlan21" variant="link" v-b-toggle.tabSalePlan21>
                              解体工事
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSalePlan21" role="tabpanel">
                            <b-card-body>
                              <b-tabs
                                fill
                                pills
                                v-model="
                                  investmentAnalytics.salePlan.expensesAtTheTimeOfSale.demolition
                                    .isDetail
                                "
                              >
                                <b-tab title="概算入力">
                                  <b-container>
                                    <b-row>
                                      <b-col cols="3" class="title bold">工事費合計</b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <allyText
                                              name="demolitionTotal"
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                                  .demolition.total
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col />
                                    </b-row>
                                  </b-container>
                                </b-tab>
                                <b-tab title="詳細入力">
                                  <b-container>
                                    <b-row>
                                      <b-col>
                                        <b-tabs fill pills class="card" v-model="selectedTab[0]">
                                          <b-tab
                                            v-for="id in investmentAnalytics.existingBuildingCount"
                                            v-bind:key="`existingBuilding-${id}`"
                                            :title="`既存建物${id}`"
                                          >
                                            <b-card-body>
                                              <b-container>
                                                <b-row>
                                                  <b-col cols="3" class="title bold"
                                                    >工事内容</b-col
                                                  >
                                                  <b-col class="ally-select">
                                                    <allySelect
                                                      :name="'demolition1-' + id"
                                                      :items="demolitionItems"
                                                      v-model="
                                                        investmentAnalytics.salePlan
                                                          .expensesAtTheTimeOfSale.demolition
                                                          .details[id - 1].content
                                                      "
                                                    />
                                                  </b-col>
                                                  <b-col>
                                                    <b-row>
                                                      <b-col class="guideline">※延床面積</b-col>
                                                      <b-col cols="3" />
                                                    </b-row>
                                                  </b-col>
                                                </b-row>
                                                <b-row>
                                                  <b-col cols="3" class="title bold"
                                                    >解体面積</b-col
                                                  >
                                                  <b-col>
                                                    <b-row>
                                                      <b-col class="component">
                                                        <allyText
                                                          type="number"
                                                          class="number"
                                                          :separation="true"
                                                          v-model="
                                                            investmentAnalytics.salePlan
                                                              .expensesAtTheTimeOfSale.demolition
                                                              .details[id - 1].quantity
                                                          "
                                                          :floatLength="2"
                                                          roundType="down"
                                                        />
                                                      </b-col>
                                                      <b-col cols="3" class="unit">㎡</b-col>
                                                    </b-row>
                                                  </b-col>
                                                  <b-col>
                                                    <b-row>
                                                      <b-col class="component">
                                                        <allyText
                                                          type="number"
                                                          class="number"
                                                          mode="View"
                                                          :separation="true"
                                                          v-model="
                                                            investmentAnalytics.initialInvestment
                                                              .propertyDescription[id].area
                                                          "
                                                          :floatLength="2"
                                                          roundType="down"
                                                        />
                                                      </b-col>
                                                      <b-col cols="3" class="unit">㎡</b-col>
                                                    </b-row>
                                                  </b-col>
                                                </b-row>
                                                <b-row>
                                                  <b-col cols="3" class="title bold"
                                                    >標準 工事単価</b-col
                                                  >
                                                  <b-col>
                                                    <b-row>
                                                      <b-col class="component">
                                                        <b-form-input
                                                          disabled
                                                          class="currency"
                                                          :value="
                                                            getUnitPrice(
                                                              '解体工事',
                                                              investmentAnalytics.salePlan
                                                                .expensesAtTheTimeOfSale.demolition
                                                                .details[id - 1].content
                                                            ) | currency
                                                          "
                                                        />
                                                      </b-col>
                                                      <b-col cols="3" class="unit">円/㎡</b-col>
                                                    </b-row>
                                                  </b-col>
                                                  <b-col>
                                                    <b-row>
                                                      <b-col class="guideline">※目安</b-col>
                                                      <b-col cols="3" />
                                                    </b-row>
                                                  </b-col>
                                                </b-row>
                                                <b-row>
                                                  <b-col cols="3" class="title bold">工事費</b-col>
                                                  <b-col>
                                                    <b-row>
                                                      <b-col class="component">
                                                        <allyText
                                                          type="number"
                                                          class="number"
                                                          :separation="true"
                                                          halfWidth
                                                          v-model="
                                                            investmentAnalytics.salePlan
                                                              .expensesAtTheTimeOfSale.demolition
                                                              .details[id - 1].amount
                                                          "
                                                          roundType="down"
                                                        />
                                                      </b-col>
                                                      <b-col cols="3" class="unit">円</b-col>
                                                    </b-row>
                                                  </b-col>
                                                  <b-col>
                                                    <b-row>
                                                      <b-col class="component">
                                                        <b-form-input
                                                          disabled
                                                          class="currency"
                                                          :value="
                                                            Math.floor(
                                                              (Math.floor(
                                                                investmentAnalytics.salePlan
                                                                  .expensesAtTheTimeOfSale
                                                                  .demolition.details[id - 1]
                                                                  .quantity * 100
                                                              ) /
                                                                100) *
                                                                getUnitPrice(
                                                                  '解体工事',
                                                                  investmentAnalytics.salePlan
                                                                    .expensesAtTheTimeOfSale
                                                                    .demolition.details[id - 1]
                                                                    .content
                                                                )
                                                            ) | currency
                                                          "
                                                        />
                                                      </b-col>
                                                      <b-col cols="3" class="unit">円</b-col>
                                                      <button
                                                        class="col-3 btn-ally"
                                                        style="white-space: nowrap; padding: 0px;"
                                                        @click="
                                                          changeGuideline(
                                                            id - 1,
                                                            Math.floor(
                                                              (Math.floor(
                                                                investmentAnalytics.salePlan
                                                                  .expensesAtTheTimeOfSale
                                                                  .demolition.details[id - 1]
                                                                  .quantity * 100
                                                              ) /
                                                                100) *
                                                                getUnitPrice(
                                                                  '解体工事',
                                                                  investmentAnalytics.salePlan
                                                                    .expensesAtTheTimeOfSale
                                                                    .demolition.details[id - 1]
                                                                    .content
                                                                )
                                                            )
                                                          )
                                                        "
                                                      >
                                                        反映
                                                      </button>
                                                    </b-row>
                                                  </b-col>
                                                </b-row>
                                              </b-container>
                                            </b-card-body>
                                          </b-tab>
                                        </b-tabs>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col>
                                        <b-tabs fill pills class="card" v-model="selectedTab[1]">
                                          <b-tab
                                            v-for="id in investmentAnalytics.newBuildingCount"
                                            v-bind:key="`newBuilding-${id}`"
                                            :title="`新築建物${id}`"
                                          >
                                            <b-card-body>
                                              <b-container>
                                                <b-row>
                                                  <b-col cols="3" class="title bold"
                                                    >工事内容</b-col
                                                  >
                                                  <b-col class="ally-select">
                                                    <allySelect
                                                      :name="'demolition1-' + id"
                                                      :items="demolitionItems"
                                                      v-model="
                                                        investmentAnalytics.salePlan
                                                          .expensesAtTheTimeOfSale.demolition
                                                          .details[id - 1 + 10].content
                                                      "
                                                    />
                                                  </b-col>
                                                  <b-col>
                                                    <b-row>
                                                      <b-col class="guideline">※延床面積</b-col>
                                                      <b-col cols="3" />
                                                    </b-row>
                                                  </b-col>
                                                </b-row>
                                                <b-row>
                                                  <b-col cols="3" class="title bold"
                                                    >解体面積</b-col
                                                  >
                                                  <b-col>
                                                    <b-row>
                                                      <b-col class="component">
                                                        <allyText
                                                          type="number"
                                                          class="number"
                                                          :separation="true"
                                                          v-model="
                                                            investmentAnalytics.salePlan
                                                              .expensesAtTheTimeOfSale.demolition
                                                              .details[id - 1 + 10].quantity
                                                          "
                                                          :floatLength="2"
                                                          roundType="down"
                                                        />
                                                      </b-col>
                                                      <b-col cols="3" class="unit">㎡</b-col>
                                                    </b-row>
                                                  </b-col>
                                                  <b-col>
                                                    <b-row>
                                                      <b-col class="component">
                                                        <allyText
                                                          type="number"
                                                          class="number"
                                                          mode="View"
                                                          :separation="true"
                                                          v-model="
                                                            investmentAnalytics.initialInvestment
                                                              .propertyDescription[id + 10].area
                                                          "
                                                          :floatLength="2"
                                                          roundType="down"
                                                        />
                                                      </b-col>
                                                      <b-col cols="3" class="unit">㎡</b-col>
                                                    </b-row>
                                                  </b-col>
                                                </b-row>
                                                <b-row>
                                                  <b-col cols="3" class="title bold"
                                                    >標準 工事単価</b-col
                                                  >
                                                  <b-col>
                                                    <b-row>
                                                      <b-col class="component">
                                                        <b-form-input
                                                          disabled
                                                          class="currency"
                                                          :value="
                                                            getUnitPrice(
                                                              '解体工事',
                                                              investmentAnalytics.salePlan
                                                                .expensesAtTheTimeOfSale.demolition
                                                                .details[id - 1 + 10].content
                                                            ) | currency
                                                          "
                                                        />
                                                      </b-col>
                                                      <b-col cols="3" class="unit">円/㎡</b-col>
                                                    </b-row>
                                                  </b-col>
                                                  <b-col>
                                                    <b-row>
                                                      <b-col class="guideline">※目安</b-col>
                                                      <b-col cols="3" />
                                                    </b-row>
                                                  </b-col>
                                                </b-row>
                                                <b-row>
                                                  <b-col cols="3" class="title bold">工事費</b-col>
                                                  <b-col>
                                                    <b-row>
                                                      <b-col class="component">
                                                        <allyText
                                                          type="number"
                                                          class="number"
                                                          :separation="true"
                                                          halfWidth
                                                          v-model="
                                                            investmentAnalytics.salePlan
                                                              .expensesAtTheTimeOfSale.demolition
                                                              .details[id - 1 + 10].amount
                                                          "
                                                          roundType="down"
                                                        />
                                                      </b-col>
                                                      <b-col cols="3" class="unit">円</b-col>
                                                    </b-row>
                                                  </b-col>
                                                  <b-col>
                                                    <b-row>
                                                      <b-col class="component">
                                                        <b-form-input
                                                          disabled
                                                          class="currency"
                                                          :value="
                                                            Math.floor(
                                                              (Math.floor(
                                                                investmentAnalytics.salePlan
                                                                  .expensesAtTheTimeOfSale
                                                                  .demolition.details[id - 1 + 10]
                                                                  .quantity * 100
                                                              ) /
                                                                100) *
                                                                getUnitPrice(
                                                                  '解体工事',
                                                                  investmentAnalytics.salePlan
                                                                    .expensesAtTheTimeOfSale
                                                                    .demolition.details[id - 1 + 10]
                                                                    .content
                                                                )
                                                            ) | currency
                                                          "
                                                        />
                                                      </b-col>
                                                      <b-col cols="3" class="unit">円</b-col>
                                                      <button
                                                        class="col-3 btn-ally"
                                                        style="white-space: nowrap; padding: 0px;"
                                                        @click="
                                                          changeGuideline(
                                                            id - 1 + 10,
                                                            Math.floor(
                                                              (Math.floor(
                                                                investmentAnalytics.salePlan
                                                                  .expensesAtTheTimeOfSale
                                                                  .demolition.details[id - 1 + 10]
                                                                  .quantity * 100
                                                              ) /
                                                                100) *
                                                                getUnitPrice(
                                                                  '解体工事',
                                                                  investmentAnalytics.salePlan
                                                                    .expensesAtTheTimeOfSale
                                                                    .demolition.details[id - 1 + 10]
                                                                    .content
                                                                )
                                                            )
                                                          )
                                                        "
                                                      >
                                                        反映
                                                      </button>
                                                    </b-row>
                                                  </b-col>
                                                </b-row>
                                              </b-container>
                                            </b-card-body>
                                          </b-tab>
                                        </b-tabs>
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                </b-tab>
                              </b-tabs>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button id="btnSalePlan22" variant="link" v-b-toggle.tabSalePlan22>
                              修繕・Valueアップ工事費
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSalePlan22" role="tabpanel">
                            <b-card-body>
                              <b-tabs
                                fill
                                pills
                                v-model="
                                  investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                    .repairAndValueUpWork.isDetail
                                "
                              >
                                <b-tab title="概算入力">
                                  <b-container>
                                    <b-row>
                                      <b-col cols="3" class="title bold">工事費合計</b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <allyText
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                                  .repairAndValueUpWork.total
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col />
                                    </b-row>
                                  </b-container>
                                </b-tab>
                                <b-tab title="詳細入力">
                                  <b-container>
                                    <b-row>
                                      <b-col cols="3" class="title bold">修繕工事</b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <allyText
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                                  .repairAndValueUpWork.details[0].amount
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col />
                                    </b-row>
                                    <b-row>
                                      <b-col cols="3" class="title bold">リニューアル工事</b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <allyText
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                                  .repairAndValueUpWork.details[1].amount
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col />
                                    </b-row>
                                    <b-row>
                                      <b-col cols="3" class="title bold">設備更新工事</b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <allyText
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                                  .repairAndValueUpWork.details[2].amount
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col />
                                    </b-row>
                                    <b-row>
                                      <b-col cols="3" class="borderline" />
                                      <b-col class="borderline" />
                                      <b-col />
                                    </b-row>
                                    <b-row>
                                      <b-col cols="3" class="title">消費税</b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="
                                                tax(
                                                  Math.floor(
                                                    investmentAnalytics.salePlan
                                                      .expensesAtTheTimeOfSale.repairAndValueUpWork
                                                      .details[0].amount
                                                  ),
                                                  Math.floor(
                                                    investmentAnalytics.salePlan
                                                      .expensesAtTheTimeOfSale.repairAndValueUpWork
                                                      .details[1].amount
                                                  ),
                                                  Math.floor(
                                                    investmentAnalytics.salePlan
                                                      .expensesAtTheTimeOfSale.repairAndValueUpWork
                                                      .details[2].amount
                                                  )
                                                ) | currency
                                              "
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col />
                                    </b-row>
                                    <b-row>
                                      <b-col cols="3" class="title">合計</b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="repairTotal | currency"
                                            />
                                          </b-col>
                                          <b-col cols="3" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col />
                                    </b-row>
                                  </b-container>
                                </b-tab>
                              </b-tabs>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button id="btnSalePlan23" variant="link" v-b-toggle.tabSalePlan23>
                              測量費
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSalePlan23" role="tabpanel">
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="3" class="title bold">確定測量</b-col>
                                  <b-col class="ally-select">
                                    <allySelect
                                      name="surveying"
                                      :items="surveyingItems"
                                      v-model="
                                        investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                          .surveyingCosts.content
                                      "
                                    />
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="guideline">※地積</b-col> <b-col cols="3" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title bold">面積</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="surveyingCosts-quantity"
                                          style="text-align:right;"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                              .surveyingCosts.quantity
                                          "
                                          :floatLength="2"
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">㎡</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          type="number"
                                          class="number"
                                          mode="View"
                                          :separation="true"
                                          v-model="
                                            investmentAnalytics.initialInvestment
                                              .propertyDescription[0].area
                                          "
                                          :floatLength="2"
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">㎡</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title bold">単価</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getUnitPrice(
                                              '確定測量',
                                              investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                                .surveyingCosts.content
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/㎡</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="guideline">※目安</b-col> <b-col cols="3" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title bold">測量費</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="surveyingCosts-amount"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                              .surveyingCosts.amount
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            Math.floor(
                                              (Math.floor(
                                                investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                                  .surveyingCosts.quantity * 100
                                              ) /
                                                100) *
                                                getUnitPrice(
                                                  '確定測量',
                                                  investmentAnalytics.salePlan
                                                    .expensesAtTheTimeOfSale.surveyingCosts.content
                                                )
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円</b-col>
                                      <button
                                        class="col-3 btn-ally"
                                        style="white-space: nowrap; padding: 0px;"
                                        @click="
                                          changeGuideline2(
                                            Math.floor(
                                              (Math.floor(
                                                investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                                  .surveyingCosts.quantity * 100
                                              ) /
                                                100) *
                                                getUnitPrice(
                                                  '確定測量',
                                                  investmentAnalytics.salePlan
                                                    .expensesAtTheTimeOfSale.surveyingCosts.content
                                                )
                                            )
                                          )
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button id="btnSalePlan24" variant="link" v-b-toggle.tabSalePlan24>
                              調査費
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSalePlan24" role="tabpanel">
                            <b-card-body>
                              <b-container>
                                <b-row style="height: 2em">
                                  <b-col cols="3" class="title bold">項目</b-col>
                                  <b-col class="title-center bold">内容</b-col>
                                  <b-col class="title">
                                    <b-row>
                                      <b-col class="text-center bold">金額</b-col>
                                      <b-col cols="3" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title">土壌汚染調査</b-col>
                                  <b-col class="ally-select">
                                    <allySelect
                                      name="soilContaminationSurvey"
                                      :items="soilContaminationSurveyItems"
                                      v-model="
                                        investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                          .soilContaminationSurvey
                                      "
                                    />
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getUnitPrice(
                                              '土壌汚染調査',
                                              investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                                .soilContaminationSurvey
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title">石綿使用調査</b-col>
                                  <b-col class="ally-select">
                                    <allySelect
                                      name="asbestosUseStudy"
                                      :items="asbestosUseStudyItems"
                                      v-model="
                                        investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                          .asbestosUseStudy
                                      "
                                    />
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getUnitPrice(
                                              'アスベスト調査',
                                              investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                                .asbestosUseStudy
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title">設備機能調査</b-col>
                                  <b-col class="ally-select">
                                    <allySelect
                                      name="equipmentFunctionSurvey"
                                      :items="equipmentFunctionSurveyItems"
                                      v-model="
                                        investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                          .equipmentFunctionSurvey
                                      "
                                    />
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getUnitPrice(
                                              '設備機能調査',
                                              investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                                .equipmentFunctionSurvey
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title">インスペクション</b-col>
                                  <b-col class="ally-select">
                                    <allySelect
                                      name="inspection"
                                      :items="inspectionItems"
                                      v-model="
                                        investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                          .inspection
                                      "
                                    />
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getUnitPrice(
                                              'インスペクション',
                                              investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                                .inspection
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title">その他調査</b-col>
                                  <b-col class="ally-select" />
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="otherSurveys"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                              .otherSurveys
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="borderline" />
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title">消費税</b-col>
                                  <b-col class="ally-select" />
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="tax(surveyCostAtSaleTotal) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="3" class="title">合計</b-col>
                                  <b-col class="ally-select" />
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="sumTax(surveyCostAtSaleTotal) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button id="btnSalePlan25" variant="link" v-b-toggle.tabSalePlan25>
                              登記費用
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSalePlan25" role="tabpanel">
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="2" class="title bold">項目</b-col>
                                  <b-col cols="2" class="title bold">細目</b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="title-center bold">数量</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">単価</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">金額</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row v-for="id in 9" v-bind:key="`registrationFees-${id}`">
                                  <b-col
                                    cols="2"
                                    class="title"
                                    v-if="registrationFees[id - 1].subtitle != null"
                                  >
                                    {{ registrationFees[id - 1].title }}
                                  </b-col>
                                  <b-col cols="4" class="title" v-else>
                                    {{ registrationFees[id - 1].title }}
                                  </b-col>
                                  <b-col
                                    cols="2"
                                    class="title"
                                    v-if="registrationFees[id - 1].subtitle != null"
                                  >
                                    {{ registrationFees[id - 1].subtitle }}
                                  </b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'registrationFees' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.salePlan.registrationFees[id - 1]
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">
                                        {{ registrationFees[id - 1].unit }}
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="registrationFees[id - 1].unitPrice | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.salePlan.registrationFees[id - 1]
                                            ) *
                                              registrationFees[id - 1].unitPrice)
                                              | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button id="btnSalePlan26" variant="link" v-b-toggle.tabSalePlan26>
                              立退料
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSalePlan26" role="tabpanel">
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="4" class="title bold">項目</b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="title-center bold">件数</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">単価</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">金額</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row v-for="id in 2" v-bind:key="`evictionFees-${id}`">
                                  <b-col cols="4" class="title" style="padding-left:1em;">
                                    {{ id == 1 ? '個人' : '法人・個人事業者' }}
                                  </b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'evictionFees' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.salePlan.evictionFees[id - 1]
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">件</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getUnitPrice(
                                              '立退料',
                                              id == 1 ? '個人' : '法人・個人事業主'
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.salePlan.evictionFees[id - 1]
                                            ) *
                                              getUnitPrice(
                                                '立退料',
                                                id == 1 ? '個人' : '法人・個人事業主'
                                              ))
                                              | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </b-card-group>
                    </b-card>
                    <b-card no-body style="margin:0;padding:0;border:none;box-shadow:none;">
                      <b-card-group class="columns" columns style="column-count:1;">
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button id="btnSalePlan27" variant="link" v-b-toggle.tabSalePlan27>
                              営業補償
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSalePlan27" role="tabpanel">
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="4" class="title bold">項目</b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="title-center bold">件数</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">単価</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">金額</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row v-for="id in 2" v-bind:key="`businessCompensation-${id}`">
                                  <b-col cols="4" class="ally-select">
                                    <allySelect
                                      :name="'businessCompensation' + id"
                                      :items="businessCompensationItems"
                                      v-model="
                                        investmentAnalytics.salePlan.businessCompensation[id - 1]
                                          .content
                                      "
                                    />
                                  </b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'businessCompensation-quantity' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.salePlan.businessCompensation[
                                              id - 1
                                            ].quantity
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">件</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getUnitPrice(
                                              '営業補償',
                                              investmentAnalytics.salePlan.businessCompensation[
                                                id - 1
                                              ].content
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.salePlan.businessCompensation[
                                                id - 1
                                              ].quantity
                                            ) *
                                              getUnitPrice(
                                                '営業補償',
                                                investmentAnalytics.salePlan.businessCompensation[
                                                  id - 1
                                                ].content
                                              ))
                                              | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button id="btnSalePlan28" variant="link" v-b-toggle.tabSalePlan28>
                              契約費用
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSalePlan28" role="tabpanel">
                            <b-card-body>
                              <b-container>
                                <b-row style="height: 2em">
                                  <b-col class="title bold">項目</b-col>
                                  <b-col class="title bold">細目</b-col>
                                  <b-col class="title-center bold">
                                    <b-row>
                                      <b-col>契約金額</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col class="title-center bold">
                                    <b-row>
                                      <b-col>印紙代</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">売買契約</b-col>
                                  <b-col />
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="salePriceTotal | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="getStampDuty(salePriceTotal, 0) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">工事請負契約</b-col>
                                  <b-col class="title">解体</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="demolitionTotal | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="getStampDuty(demolitionTotal, 1) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col />
                                  <b-col class="title">修繕工事</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="repairTotal | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="getStampDuty(repairTotal, 1) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col />
                                  <b-col class="title">その他工事</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="contractCosts0"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="investmentAnalytics.salePlan.contractCosts[0]"
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getStampDuty(
                                              Math.floor(
                                                investmentAnalytics.salePlan.contractCosts[0]
                                              ),
                                              1
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">コンサルティング契約</b-col>
                                  <b-col class="title">弁護士</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="contractCosts1"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="investmentAnalytics.salePlan.contractCosts[1]"
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getStampDuty(
                                              Math.floor(
                                                investmentAnalytics.salePlan.contractCosts[1]
                                              ),
                                              1
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col />
                                  <b-col class="title">税理士</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="contractCosts2"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="investmentAnalytics.salePlan.contractCosts[2]"
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getStampDuty(
                                              Math.floor(
                                                investmentAnalytics.salePlan.contractCosts[2]
                                              ),
                                              1
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col />
                                  <b-col class="title">コンサル会社</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="contractCosts3"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="investmentAnalytics.salePlan.contractCosts[3]"
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getStampDuty(
                                              Math.floor(
                                                investmentAnalytics.salePlan.contractCosts[3]
                                              ),
                                              1
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button id="btnSalePlan29" variant="link" v-b-toggle.tabSalePlan29>
                              支払手数料
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSalePlan29" role="tabpanel">
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="4" class="title bold">項目</b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="title-center bold">件数</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">単価</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">金額</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row v-for="id in 2" v-bind:key="`paymentFees-${id}`">
                                  <b-col cols="4" class="title">
                                    {{ id == 1 ? '賃貸借契約変更手続き' : '抵当権抹消手数料' }}
                                  </b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'paymentFees' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="investmentAnalytics.salePlan.paymentFees[id - 1]"
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">件</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getUnitPrice(
                                              id == 1 ? '賃貸借契約変更手続き' : '抵当権抹消手数料'
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.salePlan.paymentFees[id - 1]
                                            ) *
                                              getUnitPrice(
                                                id == 1
                                                  ? '賃貸借契約変更手続き'
                                                  : '抵当権抹消手数料'
                                              ))
                                              | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button id="btnSalePlan2A" variant="link" v-b-toggle.tabSalePlan2A>
                              コンサル料
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSalePlan2A" role="tabpanel">
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="2" class="title bold">項目</b-col>
                                  <b-col cols="2" class="title bold">細目</b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="title-center bold">件数</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">単価</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">金額</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row v-for="id in 3" v-bind:key="`consultingFees-${id}`">
                                  <b-col cols="2" class="title">
                                    {{ consultingFees[id - 1].title }}
                                  </b-col>
                                  <b-col cols="2" class="ally-select">
                                    <allySelect
                                      :name="'consultingFees' + id"
                                      :items="consultingFees[id - 1].items"
                                      v-model="
                                        investmentAnalytics.salePlan.consultingFees[id - 1].content
                                      "
                                    />
                                  </b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'consultingFees-quantity' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.salePlan.consultingFees[id - 1]
                                              .quantity
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">件</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getUnitPrice(
                                              consultingFees[id - 1].name,
                                              investmentAnalytics.salePlan.consultingFees[id - 1]
                                                .content
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.salePlan.consultingFees[id - 1]
                                                .quantity
                                            ) *
                                              getUnitPrice(
                                                consultingFees[id - 1].name,
                                                investmentAnalytics.salePlan.consultingFees[id - 1]
                                                  .content
                                              ))
                                              | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button id="btnSalePlan2B" variant="link" v-b-toggle.tabSalePlan2B>
                              広告費
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSalePlan2B" role="tabpanel">
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="4" class="title bold">項目</b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="title-center bold">件数</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">単価</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">金額</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row v-for="id in 3" v-bind:key="`salesAdvertisingCosts-${id}`">
                                  <b-col cols="4" class="ally-select">
                                    <allySelect
                                      :name="'salesAdvertisingCosts' + id"
                                      :items="salesAdvertisingCostsItems"
                                      v-model="
                                        investmentAnalytics.salePlan.salesAdvertisingCosts[id - 1]
                                          .content
                                      "
                                    />
                                  </b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'salesAdvertisingCosts-quantity' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.salePlan.salesAdvertisingCosts[
                                              id - 1
                                            ].quantity
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">件</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getUnitPrice(
                                              '広告費',
                                              investmentAnalytics.salePlan.salesAdvertisingCosts[
                                                id - 1
                                              ].content
                                            ) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.salePlan.salesAdvertisingCosts[
                                                id - 1
                                              ].quantity
                                            ) *
                                              getUnitPrice(
                                                '広告費',
                                                investmentAnalytics.salePlan.salesAdvertisingCosts[
                                                  id - 1
                                                ].content
                                              ))
                                              | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button id="btnSalePlan2C" variant="link" v-b-toggle.tabSalePlan2C>
                              その他
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSalePlan2C" role="tabpanel">
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="4" class="title bold">項目</b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="title-center bold">件数</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">単価</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">金額</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row v-for="id in 2" v-bind:key="`others-${id}`">
                                  <b-col cols="4" class="title">
                                    {{ id == 1 ? '諸雑費' : '違約金' }}
                                  </b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'others-quantity' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.salePlan.others[id - 1].quantity
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">件</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'others-unitPrice' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.salePlan.others[id - 1].unitPrice
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.salePlan.others[id - 1].quantity
                                            ) *
                                              Math.floor(
                                                investmentAnalytics.salePlan.others[id - 1]
                                                  .unitPrice
                                              ))
                                              | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row style="height:1em;padding:0;" />
                                <b-row style="height:1em;padding:0;">
                                  <b-col style="border-top:dashed 1px gray;" />
                                </b-row>
                                <b-row>
                                  <b-col cols="4" class="title">
                                    予備費率
                                  </b-col>
                                  <b-col cols="2">
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="reserveRate"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          :floatLength="2"
                                          v-model="
                                            investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                              .reserveRate
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">％</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </b-card-group>
                    </b-card>
                  </b-card-group>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="category">
              <b-card-header role="tab">
                <b-button variant="link" v-b-toggle.tabSalePlan3>
                  譲渡所得税計算
                </b-button>
              </b-card-header>
              <b-collapse id="tabSalePlan3" role="tabpanel">
                <b-card-body>
                  <b-container>
                    <b-card-group class="columns" columns>
                      <b-card no-body class="category">
                        <b-card-header role="tab">
                          <b-button variant="link" v-b-toggle.tabSalePlan31>
                            取得費
                          </b-button>
                        </b-card-header>
                        <b-collapse id="tabSalePlan31" role="tabpanel">
                          <b-card-body>
                            <b-container>
                              <b-row>
                                <b-col cols="2" class="title bold">項目</b-col>
                                <b-col class="title bold">構造</b-col>
                                <b-col class="title bold">種類</b-col>
                                <b-col class="title bold">償却後&nbsp;取得価格</b-col>
                              </b-row>
                              <b-row>
                                <b-col cols="2" class="title">土地</b-col>
                                <b-col class="component" />
                                <b-col class="component" />
                                <b-col>
                                  <b-row>
                                    <b-col class="component">
                                      <b-form-input
                                        disabled
                                        class="currency"
                                        :value="
                                          Math.floor(
                                            investmentAnalytics.initialInvestment.tradingPrice[0]
                                          ) | currency
                                        "
                                      />
                                    </b-col>
                                    <b-col cols="2" class="unit">円</b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                              <b-row
                                v-for="eb of investmentAnalytics.existingBuildingCount"
                                v-bind:key="`for43-${eb}`"
                              >
                                <b-col cols="2" class="title">既存建物{{ eb }}</b-col>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    v-model="
                                      investmentAnalytics.initialInvestment.propertyDescription[eb]
                                        .structure
                                    "
                                  />
                                </b-col>
                                <b-col class="component">
                                  <b-form-input
                                    v-if="
                                      investmentAnalytics.initialInvestment.propertyDescription[eb]
                                        .kind == '共同住宅'
                                    "
                                    disabled
                                    value="住宅（共同住宅）"
                                  />
                                  <b-form-input
                                    v-else
                                    disabled
                                    v-model="
                                      investmentAnalytics.initialInvestment.propertyDescription[eb]
                                        .kind
                                    "
                                  />
                                </b-col>
                                <b-col>
                                  <b-row>
                                    <b-col class="component">
                                      <b-form-input
                                        disabled
                                        class="currency"
                                        :value="amortizedBalanceAtSale(0, eb - 1) | currency"
                                      />
                                    </b-col>
                                    <b-col cols="2" class="unit">円</b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                              <b-row
                                v-for="nb in investmentAnalytics.newBuildingCount"
                                v-bind:key="`for44-${nb}`"
                              >
                                <b-col cols="2" class="title">新築建物{{ nb }}</b-col>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    v-model="
                                      investmentAnalytics.initialInvestment.propertyDescription[
                                        10 + nb
                                      ].structure
                                    "
                                  />
                                </b-col>
                                <b-col class="component">
                                  <b-form-input
                                    v-if="
                                      investmentAnalytics.initialInvestment.propertyDescription[
                                        10 + nb
                                      ].kind == '共同住宅'
                                    "
                                    disabled
                                    value="住宅（共同住宅）"
                                  />
                                  <b-form-input
                                    v-else
                                    disabled
                                    v-model="
                                      investmentAnalytics.initialInvestment.propertyDescription[
                                        10 + nb
                                      ].kind
                                    "
                                  />
                                </b-col>
                                <b-col>
                                  <b-row>
                                    <b-col class="component">
                                      <b-form-input
                                        disabled
                                        class="currency"
                                        :value="amortizedBalanceAtSale(1, nb - 1) | currency"
                                      />
                                    </b-col>
                                    <b-col cols="2" class="unit">円</b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col cols="2" class="title">付属建物</b-col>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    v-model="
                                      investmentAnalytics.initialInvestment.propertyDescription[21]
                                        .structure
                                    "
                                  />
                                </b-col>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    v-model="
                                      investmentAnalytics.initialInvestment.propertyDescription[21]
                                        .kind
                                    "
                                  />
                                </b-col>
                                <b-col>
                                  <b-row>
                                    <b-col class="component">
                                      <b-form-input
                                        disabled
                                        class="currency"
                                        :value="amortizedBalanceAtSale(2) | currency"
                                      />
                                    </b-col>
                                    <b-col cols="2" class="unit">円</b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col class="borderline" />
                              </b-row>
                              <b-row>
                                <b-col cols="2" class="title">合計</b-col>
                                <b-col class="component" />
                                <b-col class="component" />
                                <b-col>
                                  <b-row>
                                    <b-col class="component">
                                      <b-form-input
                                        disabled
                                        class="currency"
                                        :value="acquisitionFeesTotal | currency"
                                      />
                                    </b-col>
                                    <b-col cols="2" class="unit">円</b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </b-container>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                      <b-card no-body class="category">
                        <b-card-header role="tab">
                          <b-button variant="link" v-b-toggle.tabSalePlan32>
                            譲渡費
                          </b-button>
                        </b-card-header>
                        <b-collapse id="tabSalePlan32" role="tabpanel">
                          <b-card-body>
                            <b-container>
                              <b-row>
                                <b-col class="title bold">項目</b-col>
                                <b-col>
                                  <b-row>
                                    <b-col class="title-center bold">金額</b-col>
                                    <b-col cols="2" />
                                  </b-row>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col class="title">売買仲介料</b-col>
                                <b-col>
                                  <b-row>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component">
                                          <b-form-input
                                            disabled
                                            class="currency"
                                            :value="brokerageFees | currency"
                                          />
                                        </b-col>
                                        <b-col cols="2" class="unit">円</b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col class="title">売買契約印紙代</b-col>
                                <b-col>
                                  <b-row>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component">
                                          <b-form-input
                                            disabled
                                            class="currency"
                                            :value="getStampDuty(salePriceTotal, 0) | currency"
                                          />
                                        </b-col>
                                        <b-col cols="2" class="unit">円</b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col class="title">測量費</b-col>
                                <b-col>
                                  <b-row>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component">
                                          <b-form-input
                                            disabled
                                            class="currency"
                                            :value="
                                              investmentAnalytics.salePlan.expensesAtTheTimeOfSale
                                                .surveyingCosts.amount | currency
                                            "
                                          />
                                        </b-col>
                                        <b-col cols="2" class="unit">円</b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col class="title">立退料・補償料</b-col>
                                <b-col>
                                  <b-row>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component">
                                          <b-form-input
                                            disabled
                                            class="currency"
                                            :value="evictionAndCompensationFees | currency"
                                          />
                                        </b-col>
                                        <b-col cols="2" class="unit">円</b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col class="title">解体費</b-col>
                                <b-col>
                                  <b-row>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component">
                                          <b-form-input
                                            disabled
                                            class="currency"
                                            :value="demolitionTotal | currency"
                                          />
                                        </b-col>
                                        <b-col cols="2" class="unit">円</b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col class="title">補修費</b-col>
                                <b-col>
                                  <b-row>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component">
                                          <b-form-input
                                            disabled
                                            class="currency"
                                            :value="repairTotal | currency"
                                          />
                                        </b-col>
                                        <b-col cols="2" class="unit">円</b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col class="borderline" />
                              </b-row>
                              <b-row>
                                <b-col class="title">合計</b-col>
                                <b-col>
                                  <b-row>
                                    <b-col>
                                      <b-row>
                                        <b-col class="component">
                                          <b-form-input
                                            disabled
                                            class="currency"
                                            :value="transferCostTotal | currency"
                                          />
                                        </b-col>
                                        <b-col cols="2" class="unit">円</b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </b-container>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </b-card-group>
                    <b-container>
                      <b-row>
                        <b-col class="borderline" />
                      </b-row>
                      <b-row>
                        <b-col cols="2" class="title bold">運営母体</b-col>
                        <b-col cols="4" class="title">
                          <allyRadio
                            title=""
                            name="governingBody"
                            :items="[
                              { text: '個人', value: '個人' },
                              { text: '法人', value: '法人' },
                            ]"
                            v-model="investmentAnalytics.salePlan.governingBody"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col
                          v-if="investmentAnalytics.salePlan.governingBody == '法人'"
                          cols="2"
                          class="title bold"
                        >
                          資本金
                        </b-col>
                        <b-col
                          v-if="investmentAnalytics.salePlan.governingBody == '法人'"
                          cols="4"
                          class="title"
                        >
                          <allyRadio
                            title=""
                            name="capital"
                            :items="[
                              { text: '1億円以下', value: '1億円以下' },
                              { text: '1億円超', value: '1億円超' },
                            ]"
                            v-model="investmentAnalytics.salePlan.capital"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="borderline" />
                      </b-row>
                    </b-container>
                    <b-card-group class="columns" columns>
                      <span v-if="investmentAnalytics.salePlan.governingBody == '個人'">
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button variant="link" v-b-toggle.tabSalePlan33>
                              譲渡所得税
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSalePlan33" role="tabpanel" visible>
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col class="title bold">項目</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">金額</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">売買代金</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="salePriceTotal | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">⊖&nbsp;取得費</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="acquisitionFeesTotal | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">⊖&nbsp;譲渡費</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="transferCostTotal | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">課税所得</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="taxableIncome | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col
                                    style="height:0.5rem;margin-top:0.5rem;border-top:dashed 1px gray;"
                                  />
                                </b-row>
                                <b-row>
                                  <b-col class="title">課税区分</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input disabled :value="taxableCategory" />
                                      </b-col>
                                      <b-col cols="2" class="unit" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">適用税率</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            getTransferIncomeTaxRate(
                                              investmentAnalytics.salePlan.timeOfSale
                                            )
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">％</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">譲渡所得税・住民税</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="transferIncomeTax | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </span>
                      <span v-if="investmentAnalytics.salePlan.governingBody == '法人'">
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button variant="link" v-b-toggle.tabSalePlan34>
                              法人税
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSalePlan34" role="tabpanel" visible>
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col class="title bold">項目</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">金額</b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">売買代金</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="salePriceTotal | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">⊖&nbsp;取得費</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="acquisitionFeesTotal | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">⊖&nbsp;譲渡費</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="transferCostTotal | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">課税所得</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="taxableIncome | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col
                                    style="height:0.5rem;margin-top:0.5rem;border-top:dashed 1px gray;"
                                  />
                                </b-row>
                                <b-row>
                                  <b-col class="title">法人税</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="corporationTax | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">住民税</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="municipalTax | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">事業税</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="businessTax | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title">法人税・住民税・事業税</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (corporationTax + municipalTax + businessTax) | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </span>
                    </b-card-group>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-card-group>
        </b-card>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Calculation from './Calculation';
  export default {
    mixins: [Calculation],
    components: {},
    data: function() {
      return {
        viewFlag: true,
        selectedTab: [0, 0],
        // 解体工事
        demolitionItems: null,
        // 測量費
        surveyingItems: null,
        // 調査費/土壌汚染調査
        soilContaminationSurveyItems: null,
        // 調査費/石綿使用調査
        asbestosUseStudyItems: null,
        // 調査費/設備機能調査
        equipmentFunctionSurveyItems: null,
        // 調査費/インスペクション
        inspectionItems: null,
        // 登記費用
        registrationFees: [
          // [0] 抵当権抹消登記
          {
            title: '抵当権抹消登記',
            subtitle: null,
            unit: '筆',
            unitPrice: null,
          },
          // [1] 借地権抹消登記
          {
            title: '借地権抹消登記',
            subtitle: null,
            unit: '筆',
            unitPrice: null,
          },
          // [2] 表示変更登記/住所変更
          {
            title: '表示変更登記',
            subtitle: '住所変更',
            unit: '件',
            unitPrice: null,
          },
          // [3] 表示変更登記/表題部変更
          {
            title: null,
            subtitle: '表題部変更',
            unit: '件',
            unitPrice: null,
          },
          // [4] 建物滅失登記
          {
            title: '建物滅失登記',
            subtitle: null,
            unit: '件',
            unitPrice: null,
          },
          // [5] 公簿調査
          {
            title: '公簿調査',
            subtitle: null,
            unit: '筆',
            unitPrice: null,
          },
          // [6] 司法書士報酬/抵当権抹消
          {
            title: '司法書士報酬',
            subtitle: '抵当権抹消',
            unit: '件',
            unitPrice: null,
          },
          // [7] 司法書士報酬/借地権抹消
          {
            title: '',
            subtitle: '借地権抹消',
            unit: '件',
            unitPrice: null,
          },
          // [8] 司法書士報酬/公簿調査
          {
            title: '',
            subtitle: '公簿調査',
            unit: '筆',
            unitPrice: null,
          },
        ],
        // 営業補償
        businessCompensationItems: null,
        // コンサル料
        consultingFees: [
          { title: '弁護士', name: '法務コンサル', items: null },
          { title: '税理士', name: '税務コンサル', items: null },
          {
            title: 'コンサル会社',
            name: '不動産コンサル',
            items: null,
          },
        ],
        // 広告費
        salesAdvertisingCostsItems: null,
      };
    },
    mounted: function() {
      // 単価更新
      this.updateUnitPrice();
    },
    watch: {
      // マスタ更新時
      '$store.state.master.unitPrice': {
        handler: function() {
          // 単価更新
          this.updateUnitPrice();
        },
        deep: false,
      },
    },
    methods: {
      // 単価更新
      updateUnitPrice: function() {
        this.demolitionItems = this.getUnitPricesItems('解体工事');
        this.surveyingItems = this.getUnitPricesItems('確定測量');
        this.soilContaminationSurveyItems = this.getUnitPricesItems('土壌汚染調査');
        this.asbestosUseStudyItems = this.getUnitPricesItems('アスベスト調査');
        this.equipmentFunctionSurveyItems = this.getUnitPricesItems('設備機能調査');
        this.inspectionItems = this.getUnitPricesItems('インスペクション');
        this.businessCompensationItems = this.getUnitPricesItems('営業補償');
        // 登記費用
        this.registrationFees[0].unitPrice = this.getTax('登録免許税/抵当権/抹消登記');
        this.registrationFees[1].unitPrice = this.getTax('登録免許税/借地権/抹消登記');
        this.registrationFees[2].unitPrice = this.getUnitPrice('登記費用', '表示変更登記/住所変更');
        this.registrationFees[3].unitPrice = this.getUnitPrice(
          '登記費用',
          '表示変更登記/表題部変更'
        );
        this.registrationFees[4].unitPrice = this.getUnitPrice('登記費用', '建物滅失登記');
        this.registrationFees[5].unitPrice = this.getUnitPrice('登記費用', '公簿調査');
        this.registrationFees[6].unitPrice = this.getUnitPrice(
          '登記費用',
          '司法書士報酬/抵当権抹消'
        );
        this.registrationFees[7].unitPrice = this.getUnitPrice(
          '登記費用',
          '司法書士報酬/借地権抹消'
        );
        this.registrationFees[8].unitPrice = this.getUnitPrice('登記費用', '司法書士報酬/公簿調査');
        // コンサル料
        this.consultingFees.forEach(v => {
          v.items = this.getUnitPricesItems(v.name);
        });
        this.salesAdvertisingCostsItems = this.getUnitPricesItems('広告費');
      },
      // 売却代金内訳/建物/合計
      buildingDetailTotal: function() {
        let total = 0;
        // 既存建物
        for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
          total += Math.floor(
            Number(this.investmentAnalytics.salePlan.breakdownOfSalePrice.buildingDetail[i])
          );
        }
        // 新築建物
        for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
          total += Math.floor(
            Number(this.investmentAnalytics.salePlan.breakdownOfSalePrice.buildingDetail[10 + i])
          );
        }

        if (total == 0) return 0;
        // 概算を更新
        Vue.set(this.investmentAnalytics.salePlan.breakdownOfSalePrice, 'building', total);

        return total;
      },
      changeGuideline: function(id, sum) {
        Vue.set(
          this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.demolition.details[id],
          'amount',
          sum
        );
      },
      changeGuideline2: function(sum) {
        Vue.set(
          this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.surveyingCosts,
          'amount',
          sum
        );
      },
      // 売却代金内訳・土地目安値を反映する
      changeGuidelineSaleLand: function(id, sum) {
        Vue.set(this.investmentAnalytics.salePlan.breakdownOfSalePrice, id, sum);
      },
      // 売却代金内訳・既存新規建物目安値を一括反映する
      changeGuidelineAllSaleBuild() {
        for (
          let i = 0;
          i < this.investmentAnalytics.salePlan.breakdownOfSalePrice.buildingDetail.length + 1;
          i++
        ) {
          if (i < 10) {
            // 既存建物
            this.$nextTick(function() {
              Vue.set(
                this.investmentAnalytics.salePlan.breakdownOfSalePrice.buildingDetail,
                i,
                this.amortizedBalanceAtSale(0, i)
              );
            });
          } else {
            // 新築建物
            this.$nextTick(function() {
              Vue.set(
                this.investmentAnalytics.salePlan.breakdownOfSalePrice.buildingDetail,
                i,
                this.amortizedBalanceAtSale(1, i - 10)
              );
            });
          }
        }
      },
      // 売却代金内訳・既存新規建物目安値を個別反映する
      changeGuidelineCitySaleBuild: function(id, sum) {
        Vue.set(this.investmentAnalytics.salePlan.breakdownOfSalePrice.buildingDetail, id, sum);
      },
      //概算入力の値を変更した場合、詳細入力のデータを０クリアする
      deleteDetails() {
        // 既存建物
        for (let i = 0; i < this.investmentAnalytics.existingBuildingCount; i++) {
          this.investmentAnalytics.salePlan.breakdownOfSalePrice.buildingDetail[i] = 0;
        }
        // 新築建物
        for (let i = 0; i < this.investmentAnalytics.newBuildingCount; i++) {
          this.investmentAnalytics.salePlan.breakdownOfSalePrice.buildingDetail[10 + i] = 0;
        }
      },
    },
  };
</script>
<style scoped>
  #SalePlan .component,
  #SalePlan .ally-select {
    padding: 0 5px;
  }
  #InvestmentAnalytics #SalePlan .guideline {
    padding-top: 22px !important;
  }
  @media screen and (min-width: 576px) {
    #InvestmentAnalytics #SalePlan .columns {
      column-count: 1;
    }
  }
  @media screen and (min-width: 768px) {
    /* iPad     - 縦 :  768 */
    #InvestmentAnalytics #SalePlan .columns {
      column-count: 1;
    }
  }
  @media screen and (min-width: 992px) {
    /* iPad Pro - 縦 : 1024 */
    /* iPad     - 横 : 1024 */
    #InvestmentAnalytics #SalePlan .columns {
      column-count: 1;
    }
  }
  @media screen and (min-width: 1200px) {
    /* iPad Pro - 横 : 1366 */
    /* PC(FullHD) - 横 : 1920 */
    #InvestmentAnalytics #SalePlan .columns {
      column-count: 2;
    }
  }
</style>
