// @ts-check
import { HandleFlg } from '../tools/handleFlg';
import { PvObject } from './pvObject';

export class PvText extends PvObject {
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'text';
  handleFlg = new HandleFlg({
    vertex: false,
    edge: false,
    curve: false,
    rotate: false,
    outline: true,
  });
  constructor() {
    super();
  }
}
