<template>
  <div class="col-md-12 grid-margin">
    <div
      class="allyLoad text-center"
      :style="{
        width: width ? width : '100px',
        height: height ? height : '100px',
        margin: margin ? margin : '100px 0px 0px 0px',
      }"
    >
      <hr />
      <hr />
      <hr />
      <hr />
    </div>
  </div>
</template>

<script>
  export default {
    props: ['width', 'height', 'margin'],
  };
</script>

<style scoped>
  .allyLoad {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .allyLoad hr {
    border: 0;
    margin: 0;
    width: 40%;
    height: 40%;
    position: absolute;
    border-radius: 50%;
    animation: spin 2s ease infinite;
  }

  .allyLoad :first-child {
    background: #19a68c;
    animation-delay: -1.5s;
  }
  .allyLoad :nth-child(2) {
    background: #f63d3a;
    animation-delay: -1s;
  }
  .allyLoad :nth-child(3) {
    background: #fda543;
    animation-delay: -0.5s;
  }
  .allyLoad :last-child {
    background: #193b48;
  }

  @keyframes spin {
    0%,
    100% {
      transform: translate(0);
    }
    25% {
      transform: translate(160%);
    }
    50% {
      transform: translate(160%, 160%);
    }
    75% {
      transform: translate(0, 160%);
    }
  }
</style>
