import { render, staticRenderFns } from "./ItemList.vue?vue&type=template&id=27187b00&scoped=true&"
import script from "./ItemList.vue?vue&type=script&lang=js&"
export * from "./ItemList.vue?vue&type=script&lang=js&"
import style0 from "./ItemList.vue?vue&type=style&index=0&id=27187b00&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27187b00",
  null
  
)

export default component.exports