// @ts-check

import { computed, ref } from '@vue/composition-api';
import { EventPreset } from '../event/preset/eventPreset';
import { Vec } from '../util/math/vector';
import { EventDataset } from '../event/preset/eventDataset';
import { EventContext } from '../event/preset/eventContext';
import { ActionManager } from '../event/action/actionManager';
import { DoorPreset } from '../event/preset/builder/door';
import { GlobalPreset } from '../event/preset/builder/global';
import { PanPreset } from '../event/preset/builder/pan';
import { SelectPreset } from '../event/preset/builder/select';
import { FloorPreset } from '../event/preset/builder/floor';
import { StairsPreset } from '../event/preset/builder/stairs';
import { IconPreset } from '../event/preset/builder/icon';
import { WallPreset } from '../event/preset/builder/wall';

/**
 * 各イベントプリセットに設定したイベントを
 * 単一の mousedown, mouseup, mousemove, click に変換する
 * @param {{
 * mode: import('@vue/composition-api').Ref<string>,
 * eventContext: EventContext
 * actionManager: ActionManager
 * isEditor: import('@vue/composition-api').Ref<boolean>
 * }} params
 */
export const useEventConverter = ({ mode, eventContext, actionManager, isEditor }) => {
  const targetId = ref(null);

  /** @type {Record<string, EventPreset>} */
  const _eventPresets = ({
    global: new GlobalPreset({ context: eventContext, actionManager }).build(),
    pan: new PanPreset({ context: eventContext, actionManager }).build(),
    select: new SelectPreset({ context: eventContext, actionManager }).build(),
    floor: new FloorPreset({ context: eventContext, actionManager }).build(),
    wall: new WallPreset({ context: eventContext, actionManager }).build(),
    stairs: new StairsPreset({ context: eventContext, actionManager }).build(),
    icon: new IconPreset({ context: eventContext, actionManager }).build(),
    door: new DoorPreset({ context: eventContext, actionManager }).build(),
  });

  const currentPreset = computed(() => {
    return _eventPresets[mode.value];
  });
  const globalPreset = computed(() => {
    return _eventPresets.global;
  });

  let mousedownFlg = false;
  let draggingFlg = false;
  let start = new Vec();
  let clickTimeStamp = 0;

  /** @type {(e: Event) => void} */
  const click = e => {
    const dataset = EventDataset.fromEvent(e);
    if (dataset.button !== 'left') {
      return;
    }
    // 連打無効
    if (e.timeStamp - clickTimeStamp < 200) return;
    // @ts-ignore
    targetId.value = e.target.dataset.id;

    currentPreset.value.click(dataset);
    globalPreset.value.click(dataset);

    clickTimeStamp = e.timeStamp;
  };

  /** @type {(e: Event) => void} */
  const mousedown = e => {
    const eventDataset = EventDataset.fromEvent(e);
    if (eventDataset.button !== 'left') {
      return;
    }
    mousedownFlg = true;
    draggingFlg = false;
    // @ts-ignore
    start = new Vec(e.offsetX, e.offsetY);
    // @ts-ignore
    targetId.value = e.target.dataset.id;

    currentPreset.value.mousedown(eventDataset);
    globalPreset.value.mousedown(eventDataset);
  };

  let throttleFlg = true;
  /** @type {(e: Event) => void} */
  const mousemove = e => {
    if (!throttleFlg || !isEditor.value) {
      //編集権限の無しの場合(isEditor)
      return;
    }

    throttleFlg = false;
    requestAnimationFrame(() => {
      // @ts-ignore
      targetId.value = e.target.dataset.id;

      if (mousedownFlg === true && draggingFlg === false) {
        draggingFlg = true;
        currentPreset.value.dragstart(EventDataset.fromEvent(e, { eventType: 'dragstart' }));
        globalPreset.value.dragstart(EventDataset.fromEvent(e, { eventType: 'dragstart' }));
      }
      if (mousedownFlg === true && draggingFlg === true) {
        currentPreset.value.drag(EventDataset.fromEvent(e, { eventType: 'drag' }));
        globalPreset.value.drag(EventDataset.fromEvent(e, { eventType: 'drag' }));
      }

      currentPreset.value.mousemove(EventDataset.fromEvent(e, { eventType: 'mousemove' }));
      globalPreset.value.mousemove(EventDataset.fromEvent(e, { eventType: 'mousemove' }));
      throttleFlg = true;
    });
  };

  /** @type {(e: Event) => void} */
  const mouseup = e => {
    const dataset = EventDataset.fromEvent(e);
    if (dataset.button !== 'left') {
      return;
    }

    // @ts-ignore
    targetId.value = e.target.dataset.id;

    if (draggingFlg === true) {
      currentPreset.value.dragend(EventDataset.fromEvent(e, { eventType: 'dragend' }));
      globalPreset.value.dragend(EventDataset.fromEvent(e, { eventType: 'dragend' }));
    }
    mousedownFlg = false;
    start = new Vec();
    currentPreset.value.mouseup(EventDataset.fromEvent(e, { eventType: 'mouseup' }));
    globalPreset.value.mouseup(EventDataset.fromEvent(e, { eventType: 'mouseup' }));
  };

  /** @type {(e: Event) => void} */
  const mouseleave = e => {
    currentPreset.value.mouseleave(EventDataset.fromEvent(e, { eventType: 'mouseleave' }));
    globalPreset.value.mouseleave(EventDataset.fromEvent(e, { eventType: 'mouseleave' }));
  };

  const contextmenu = e => {
    currentPreset.value.rightclick(EventDataset.fromEvent(e));
    globalPreset.value.rightclick(EventDataset.fromEvent(e));
  };

  return {
    click,
    mousedown,
    mousemove,
    mouseup,
    mouseleave,
    contextmenu,
  };
};
