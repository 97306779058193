<!--
作成者：本間
作成日：2021.03.01
画面名：投資分析/出力
機能：利回り
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <b-tab id="Yield" title="利回り">
    <b-card class="card-result" title="利回り">
      <b-card-group columns style="column-count:1;">
        <!-- 表面利回り -->
        <b-card no-body class="category">
          <b-card-header role="tab">
            表面利回り
          </b-card-header>
          <b-card-body>
            <b-container>
              <b-row>
                <b-col class="grid" cols="3">
                  <b-row>
                    <b-col class="grid" cols="12">年間収入</b-col>
                    <b-col class="grid" cols="12">
                      <b-row>
                        <b-col cols="1" />
                        <b-col class="grid divider" />
                        <b-col cols="1" />
                      </b-row>
                    </b-col>
                    <b-col class="grid" cols="12">物件価格</b-col>
                  </b-row>
                </b-col>
                <b-col class="grid" cols="1">＝</b-col>
                <b-col class="grid" cols="2">
                  <b-row>
                    <b-col class="grid" cols="12">
                      {{ result.yearlyRentTotal | tenThousandYen }}万円
                    </b-col>
                    <b-col class="grid" cols="12">
                      <b-row>
                        <b-col cols="1" />
                        <b-col class="grid divider" />
                        <b-col cols="1" />
                      </b-row>
                    </b-col>
                    <b-col class="grid" cols="12">
                      {{ result.propertyPrice | tenThousandYen }}万円
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="grid" cols="1">＝</b-col>
                <b-col class="grid" cols="1">{{
                  isNaN(result.yields[0]) ? '0' : result.yields[0] | ratio
                }}</b-col>
                <b-col class="left red">
                  ※年間収入は、満室時における家賃・駐⾞料の１年間の合計額です。
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </b-card>
        <!-- ローン返済期間中の現金収支、及び実質利回り -->
        <b-card no-body class="category">
          <b-card-header role="tab">
            ローン返済期間中の現金収支、及び実質利回り
          </b-card-header>
          <b-card-body>
            <b-container>
              <b-row>
                <b-col class="grid" cols="3">ローン期間中における<br />1ヶ月当たり現金収支</b-col>
                <b-col class="grid" cols="1">＝</b-col>
                <b-col class="grid" cols="2">
                  {{
                    isNaN(result.cashFlowPerMonthEndOfLoan)
                      ? '0.0'
                      : result.cashFlowPerMonthEndOfLoan | tenThousandYen
                  }}万円
                </b-col>
                <b-col class="grid" cols="1">・・・</b-col>
                <b-col class="grid left" cols="1">①</b-col>
                <b-col class="grid red left">
                  ※キャッシュフロー計画書の、ローン返済終了年における「現金収支累計」より計算しています。
                </b-col>
              </b-row>
              <b-row style="padding-top:2em;padding-bottom:1em;">
                <b-col class="grid green left">
                  [建築価格に対する利回り]
                </b-col>
              </b-row>
              <b-row>
                <b-col class="grid" cols="3">
                  <b-row>
                    <b-col class="grid" cols="12">年間現金収支(①×12ヶ月)</b-col>
                    <b-col class="grid" cols="12">
                      <b-row>
                        <b-col cols="1" />
                        <b-col class="grid divider" />
                        <b-col cols="1" />
                      </b-row>
                    </b-col>
                    <b-col class="grid" cols="12">初期投資額</b-col>
                  </b-row>
                </b-col>
                <b-col class="grid" cols="1">＝</b-col>
                <b-col class="grid" cols="2">
                  <b-row>
                    <b-col class="grid" cols="12">
                      {{
                        isNaN(result.cashFlowPerMonthEndOfLoan)
                          ? '0.0'
                          : (result.cashFlowPerMonthEndOfLoan * 12) | tenThousandYen
                      }}万円
                    </b-col>
                    <b-col class="grid" cols="12">
                      <b-row>
                        <b-col cols="1" />
                        <b-col class="grid divider" />
                        <b-col cols="1" />
                      </b-row>
                    </b-col>
                    <b-col class="grid" cols="12">
                      {{ result.initialCost | tenThousandYen }}万円
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="grid" cols="1">＝</b-col>
                <b-col class="grid" cols="1">{{
                  isNaN(result.yields[1]) ? '0' : result.yields[1] | ratio
                }}</b-col>
              </b-row>

              <b-row style="padding-top:2em;padding-bottom:1em;">
                <b-col class="grid green left">
                  [自己資金に対する利回り]
                </b-col>
              </b-row>
              <b-row>
                <b-col class="grid" cols="3">
                  <b-row>
                    <b-col class="grid" cols="12">年間現金収支(①×12ヶ月)</b-col>
                    <b-col class="grid" cols="12">
                      <b-row>
                        <b-col cols="1" />
                        <b-col class="grid divider" />
                        <b-col cols="1" />
                      </b-row>
                    </b-col>
                    <b-col class="grid" cols="12">自己資金</b-col>
                  </b-row>
                </b-col>
                <b-col class="grid" cols="1">＝</b-col>
                <b-col class="grid" cols="2">
                  <b-row>
                    <b-col class="grid" cols="12">
                      {{
                        isNaN(result.cashFlowPerMonthEndOfLoan)
                          ? '0.0'
                          : (result.cashFlowPerMonthEndOfLoan * 12) | tenThousandYen
                      }}万円
                    </b-col>
                    <b-col class="grid" cols="12">
                      <b-row>
                        <b-col cols="1" />
                        <b-col class="grid divider" />
                        <b-col cols="1" />
                      </b-row>
                    </b-col>
                    <b-col class="grid" cols="12">
                      {{ result.ownResources | tenThousandYen }}万円
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="grid" cols="1">＝</b-col>
                <b-col class="grid" cols="1">{{
                  isNaN(result.yields[2]) ? '0' : result.yields[2] | ratio
                }}</b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </b-card>
        <!-- ローン返済終了直後の現金収支、及び実質利回り -->
        <b-card no-body class="category" v-if="result.cashFlowPerMonthAfterEndOfLoan != null">
          <b-card-header role="tab">
            ローン返済終了直後の現金収支、及び実質利回り
          </b-card-header>
          <b-card-body>
            <b-container>
              <b-row>
                <b-col class="grid" cols="3">ローン返済終了直後の<br />1ヶ月当たり現金収支</b-col>
                <b-col class="grid" cols="1">＝</b-col>
                <b-col class="grid" cols="2">
                  {{ result.cashFlowPerMonthAfterEndOfLoan | tenThousandYen }}万円
                </b-col>
                <b-col class="grid" cols="1">・・・</b-col>
                <b-col class="grid left" cols="1">②</b-col>
                <b-col class="grid red left">
                  ※キャッシュフロー計画書の、ローン終了翌年の数値です。
                </b-col>
              </b-row>
              <b-row style="padding-top:2em;padding-bottom:1em;">
                <b-col class="grid green left">
                  [建築価格に対する利回り]
                </b-col>
              </b-row>
              <b-row>
                <b-col class="grid" cols="3">
                  <b-row>
                    <b-col class="grid" cols="12">年間現金収支(②×12ヶ月)</b-col>
                    <b-col class="grid" cols="12">
                      <b-row>
                        <b-col cols="1" />
                        <b-col class="grid divider" />
                        <b-col cols="1" />
                      </b-row>
                    </b-col>
                    <b-col class="grid" cols="12">初期投資額</b-col>
                  </b-row>
                </b-col>
                <b-col class="grid" cols="1">＝</b-col>
                <b-col class="grid" cols="2">
                  <b-row>
                    <b-col class="grid" cols="12">
                      {{ (result.cashFlowPerMonthAfterEndOfLoan * 12) | tenThousandYen }}万円
                    </b-col>
                    <b-col class="grid" cols="12">
                      <b-row>
                        <b-col cols="1" />
                        <b-col class="grid divider" />
                        <b-col cols="1" />
                      </b-row>
                    </b-col>
                    <b-col class="grid" cols="12">
                      {{ result.initialCost | tenThousandYen }}万円
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="grid" cols="1">＝</b-col>
                <b-col class="grid" cols="1">{{
                  isNaN(result.yields[3]) ? '0' : result.yields[3] | ratio
                }}</b-col>
              </b-row>

              <b-row style="padding-top:2em;padding-bottom:1em;">
                <b-col class="grid green left">
                  [自己資金に対する利回り]
                </b-col>
              </b-row>
              <b-row>
                <b-col class="grid" cols="3">
                  <b-row>
                    <b-col class="grid" cols="12">年間現金収支(②×12ヶ月)</b-col>
                    <b-col class="grid" cols="12">
                      <b-row>
                        <b-col cols="1" />
                        <b-col class="grid divider" />
                        <b-col cols="1" />
                      </b-row>
                    </b-col>
                    <b-col class="grid" cols="12">自己資金</b-col>
                  </b-row>
                </b-col>
                <b-col class="grid" cols="1">＝</b-col>
                <b-col class="grid" cols="2">
                  <b-row>
                    <b-col class="grid" cols="12">
                      {{ (result.cashFlowPerMonthAfterEndOfLoan * 12) | tenThousandYen }}万円
                    </b-col>
                    <b-col class="grid" cols="12">
                      <b-row>
                        <b-col cols="1" />
                        <b-col class="grid divider" />
                        <b-col cols="1" />
                      </b-row>
                    </b-col>
                    <b-col class="grid" cols="12">
                      {{ result.ownResources | tenThousandYen }}万円
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="grid" cols="1">＝</b-col>
                <b-col class="grid" cols="1">{{
                  isNaN(result.yields[4]) ? '0' : result.yields[4] | ratio
                }}</b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </b-card>
      </b-card-group>
    </b-card>
  </b-tab>
</template>
<script>
  import { toast } from '@aws-amplify/ui';
  import Calculation1 from '../Input/Calculation';
  import Calculation2 from '../Result/Calculation';
  export default {
    props: [],
    components: {},
    mixins: [Calculation1, Calculation2],
    data: function() {
      return {
        result: {
          yearlyRentTotal: null, // 年間収入
          propertyPrice: null, // 物件価格
          initialCost: null, // 初期投資額
          ownResources: null, // 自己資金
          timeOfSale: null, // 売却時期
          repaymentPeriod: null, // 返済期間
          yields: Array(5).fill(null), // 利回り
        },
      };
    },
    created: function() {},
    mounted: function() {
      // 年間収入
      this.result.yearlyRentTotal =
        this.rentTotal * 12 + // 賃料合計
        this.commonServiceFeesTotal * 12 + // 共益費合計
        this.parkingFeesTotal * 12 + // 駐車料合計
        this.landRentalFeesTotal * 12 + // 土地賃貸料合計
        this.othersTotal * 12; // その他合計
      // 物件価格
      this.result.propertyPrice = Math.floor(
        Number(this.investmentAnalytics.initialInvestment.tradingPrice[0])
      ); // 土地本体価格
      this.result.propertyPrice += this.sumTax(this.tradingPriceBuilding); // 売買代金 建物合計
      this.result.propertyPrice += this.sumTax(this.newConstructionCostsTotal); // 新築工事費 合計
      // 初期投資額
      this.result.initialCost = this.result.propertyPrice;
      this.result.initialCost += this.incidentalExpensesTotal; //付帯費用/合計  ※予備費を除く
      this.result.initialCost += this.reserveFunds; //予備費
      // 自己資金
      this.result.ownResources = Math.floor(
        Number(this.investmentAnalytics.initialInvestment.capitalPlan.ownResources)
      );
      // 売却時期
      this.result.timeOfSale = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale));
      // 返済期間
      this.result.repaymentPeriod = Math.floor(
        Number(this.investmentAnalytics.initialInvestment.capitalPlan.repaymentPeriod)
      );
      // ローン終了年（または売却年）
      const year = Math.min(this.result.timeOfSale, this.result.repaymentPeriod);
      // 現金収支累計
      const cts = this.cumulativeTotals(this.investmentAnalyticsTemp.cashFlowsAfterTax);
      // 1ヶ月当たり現金収支(ローン終了年)
      this.result.cashFlowPerMonthEndOfLoan = cts[year - 1] / year / 12; // ローン終了年の現金収支累計÷借入期間÷12
      // 1ヶ月当たり現金収支(ローン終了翌年)
      this.result.cashFlowPerMonthAfterEndOfLoan =
        this.investmentAnalyticsTemp.cashFlowsAfterTax[this.result.repaymentPeriod] / 12; // ローン終了翌年の単年度現金収支÷12
      // 利回り
      this.result.yields = [
        (this.result.yearlyRentTotal * 100) / this.result.propertyPrice, // 表面利回り
        (this.result.cashFlowPerMonthEndOfLoan * 12 * 100) / this.result.initialCost, // ローン返済期間中[対建築価格]
        (this.result.cashFlowPerMonthEndOfLoan * 12 * 100) / this.result.ownResources, // ローン返済期間中[対自己資金]
        (this.result.cashFlowPerMonthAfterEndOfLoan * 12 * 100) / this.result.initialCost, // ローン返済終了直後[対建築価格]
        (this.result.cashFlowPerMonthAfterEndOfLoan * 12 * 100) / this.result.ownResources, // ローン返済終了直後[対自己資金]
      ];
    },
    watch: {
      'investmentAnalyticsTemp.cashFlowsAfterTax': function(newValue) {
        // ローン終了年（または売却年）
        const year = Math.min(this.result.timeOfSale, this.result.repaymentPeriod);
        // 現金収支累計
        const cts = this.cumulativeTotals(newValue);
        // 1ヶ月当たり現金収支(ローン終了年)
        this.result.cashFlowPerMonthEndOfLoan = cts[year - 1] / year / 12; // ローン終了年の現金収支累計÷借入期間÷12
        // 1ヶ月当たり現金収支(ローン終了翌年)
        this.result.cashFlowPerMonthAfterEndOfLoan = newValue[this.result.repaymentPeriod] / 12; // ローン終了翌年の単年度現金収支÷12
        // 利回り
        this.$set(
          this.result.yields,
          1,
          (this.result.cashFlowPerMonthEndOfLoan * 12 * 100) / this.result.initialCost
        ); // ローン返済期間中[対建築価格]
        this.$set(
          this.result.yields,
          2,
          (this.result.cashFlowPerMonthEndOfLoan * 12 * 100) / this.result.ownResources
        ); // ローン返済期間中[対自己資金]
        this.$set(
          this.result.yields,
          3,
          (this.result.cashFlowPerMonthAfterEndOfLoan * 12 * 100) / this.result.initialCost
        ); // ローン返済終了直後[対建築価格]
        this.$set(
          this.result.yields,
          4,
          (this.result.cashFlowPerMonthAfterEndOfLoan * 12 * 100) / this.result.ownResources
        ); // ローン返済終了直後[対自己資金]
      },
    },
    filters: {
      // 比率編集
      ratio(value) {
        // https://omachizura.com/2016/12/laravel-number-comma.html
        const re = /(\d)(?=(\d\d\d)+(?!\d))/g; //正規表現
        return value == 0
          ? '0.0％'
          : value == null || value == '' || value == undefined || isNaN(value)
          ? null
          : Number(Number(value).toFixed(1))
              .toLocaleString('ja-JP', {
                style: 'decimal',
              })
              .replace(re, '$1,') + '％';
      },
    },
    methods: {},
  };
</script>
<style scoped>
  #Yield .category {
    border-color: black;
  }
  #Yield .category > .card-header {
    transition: 0.2s;
    background: var(--colorTheme);
    color: #fff;
    text-shadow: 1px 1px 1px #000000, -1px 1px 1px #000000, 1px -1px 1px #000000,
      -1px -1px 1px #000000, 1px 0px 1px #000000, 0px 1px 1px #000000, -1px 0px 1px #000000,
      0px -1px 1px #000000;
  }
  #Yield .red {
    color: red;
    font-size: small;
  }
  #Yield .green {
    color: green;
  }
  #Yield .grid {
    display: grid;
    text-align: center;
    align-items: center;
    padding: 0;
  }
  #Yield .left {
    display: grid;
    text-align: left;
    align-items: center;
  }
  #Yield .divider {
    border-top: 1px solid black;
    margin-top: 11px;
    height: 12px;
  }
</style>
