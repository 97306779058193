<!--
作成者：matsui taiga
作成日：2022.7.13
画面名：準備中
機能：
親子関係：
更新者：高橋
①
更新内容：
①
-->
<template>
  <div
    class="m-0 p-0 col-12 d-flex align-items-center justify-content-center text-center"
    style="height:100vh;"
  >
    <div v-if="isVRSimulatorLicense" style="width:100%; height:100%;">
      <iframe
        title="webgl"
        name="webgl"
        style="width:100%; height:100%;"
        ref="unityWebglFrame"
        src="/planeView/vr/index.html"
      ></iframe>
      <noframes>
        frame not supported
      </noframes>
    </div>
    <div v-if="!isVRSimulatorLicense">
      {{ MESSAGE_TEXT.NO_LICENSE_VR }}
    </div>
  </div>
</template>
<script>
  // @ts-check
  import { ALLY_LICENSE, MESSAGE_TEXT } from '../PlaneView/lib/util/const';
  import {
    computed,
    defineComponent,
    getCurrentInstance,
    nextTick,
    onMounted,
    onUnmounted,
    onBeforeUnmount,
    ref,
    toRefs,
    watch,
  } from '@vue/composition-api';
  export default defineComponent({
    props: [],
    data: function() {
      return {
        jsonInfo: {},
      };
    },
    setup() {
      const PLANE_VIEW_WINDOW = window.opener; // DATA: オートスケッチ画面オブジェクト
      const unityWebglFrame = ref(null); // DATA: ref名：UnityWebGL表示iFrame

      /**
       * COMPUTED: VRシミュレーターライセンスチェック
       */
      const isVRSimulatorLicense = computed(() => {
        const isLicense = getCurrentInstance()?.proxy.$store.state.functions?.some((
          /** @type {number} */ v
        ) => {
          return v == ALLY_LICENSE.VR_SIMULATOR.ID; // ALLY VRシミュレーター
        });
        if (!isLicense) {
          alert(MESSAGE_TEXT.NO_LICENSE_VR);
          // 別タブの場合は閉じる
          window.close();
          // 別タブでは無い（window.close()が効かない）場合、前の画面に戻す
          history.back();
        }
        return isLicense;
      });

      /**
       * METHOD: 受信メッセージ分岐処理
       * @param {{
       * origin: string
       * source: any
       * data: {
       *  action: string
       *  message: string
       * }
       * }} event
       */
      const switchPostMessage = event => {
        console.log('switchPostMessage');
        if (event.origin === window.location.origin && event.data.action) {
          // 送信元URLを配列で取得
          const urlArray = event.source.location.pathname.split('/');
          // 送信元名（サブディレクトリ名/画面名）を、全て大文字に変換して取得
          const senderName = (urlArray[1] + '/' + urlArray[2]).toUpperCase();
          // 送信元名チェック
          console.log('◆◆VRView 送信元:', senderName);
          switch (senderName) {
            // iFrameのWebGLから
            case 'PLANEVIEW/VR':
              console.log('◆◆VR Message', event.data.action, event.data.message);
              postMessageForWindow(PLANE_VIEW_WINDOW, event.data.action, event.data.message);
              break;
            // オートスケッチから
            case 'PLANEVIEW/EDIT':
              console.log('◆◆PlaneView Message', event.data.action, event.data.message);
              postMessageForWindow(window.frames[0], event.data.action, event.data.message);
              break;
            default:
              console.error('送信元名が不正です :', senderName);
              console.error('◆◆PV Message', event.data.action, event.data.message);
              break;
          }
        }
      };
      /**
       * METHOD: メッセージ送信
       * @param {Window} window
       * @param {string} action
       * @param {string} message
       */
      const postMessageForWindow = (window, action, message) => {
        window.postMessage(
          {
            action: action,
            message: message,
          },
          window.location.origin
        );
      };
      /**
       * METHOD: メッセージ受信リスナ
       */
      addEventListener('message', switchPostMessage, {
        once: false,
        passive: true,
        capture: false,
      });

      /**
       * METHOD: VR画面更新前リスナ
       */
      addEventListener('beforeunload', e => {
        // オートスケッチへbeforeunload報告
        postMessageForWindow(PLANE_VIEW_WINDOW, 'beforeunload', 'VR View beforeunload');
      });

      return {
        unityWebglFrame, // ref名：UnityWebGL表示iFrame
        isVRSimulatorLicense, // ライセンス
        MESSAGE_TEXT, // メッセージ
      };
    },
  });
</script>
<style scoped></style>
