// @ts-check
import { DEFAULT_WALL_WIDTH } from '../../util/const';
import { PvStyle } from './style';

export const WALL_STYLE_PRESETS = {
  spandrel: new PvStyle({
    stroke: 'black',
    fill: 'none',
    strokeDasharray: 'none',
    strokeWidth: DEFAULT_WALL_WIDTH,
  }),
  falling: new PvStyle({
    stroke: 'gray',
    fill: 'none',
    strokeDasharray: 'none',
    strokeWidth: DEFAULT_WALL_WIDTH,
  }),
  handrail: new PvStyle({
    stroke: 'black',
    fill: 'white',
    strokeDasharray: 'none',
    strokeWidth: DEFAULT_WALL_WIDTH,
  }),
  none: new PvStyle({
    stroke: 'transparent',
    fill: 'none',
    strokeDasharray: 'none',
    strokeWidth: DEFAULT_WALL_WIDTH,
  }),
  split: new PvStyle({
    stroke: 'black',
    strokeDasharray: '30 10',
    strokeWidth: DEFAULT_WALL_WIDTH,
  }),
  erase: new PvStyle({
    stroke: 'white',
    // strokeDasharray: '30 10',
    strokeWidth: DEFAULT_WALL_WIDTH,
  }),
};
