<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：初期投資/付帯費用/設計監理費
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="DesignCosts">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button id="BtnDesignCosts" variant="link" v-b-toggle.tabDesignCosts>
          設計監理費
        </b-button>
      </b-card-header>
      <b-collapse id="tabDesignCosts" role="tabpanel">
        <b-card-body>
          <b-tabs fill pills v-model="investmentAnalytics.initialInvestment.isDetailDesignCost">
            <b-tab title="概算入力">
              <b-container>
                <b-row>
                  <b-col cols="2" class="title bold">総額</b-col>
                  <b-col cols="2" class="title ">
                    (税抜)
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="component">
                        <allyText
                          name="DesignCostsTotal"
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="investmentAnalytics.initialInvestment.estimatedDesignCost"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold" />
                  <b-col cols="2" class="title">
                    (税込)
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            sumTax(
                              Math.floor(investmentAnalytics.initialInvestment.estimatedDesignCost)
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="詳細入力">
              <b-container class="detail">
                <b-row>
                  <b-col class="title bold">項目</b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col class="title-center bold">設計監理費</b-col>
                      <b-col cols="5" />
                    </b-row>
                  </b-col>
                  <b-col class="title-center ">詳細</b-col>
                </b-row>
                <b-row>
                  <b-col class="title">建築設計</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.designCost[0].amount)
                              | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabDesignCosts1>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabDesignCosts1" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">建築設計費率</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.designCost[0].quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">％</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        0,
                                        Math.floor(
                                          (Math.floor(
                                            investmentAnalytics.initialInvestment.designCost[0]
                                              .quantity
                                          ) *
                                            (architecturalDesignCostConstructionPlanTotal +
                                              tax(architecturalDesignCostConstructionPlanTotal))) /
                                            100
                                        )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">建築設計費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.designCost[0].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>

                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      Math.floor(
                                        (Math.floor(
                                          investmentAnalytics.initialInvestment.designCost[0]
                                            .quantity
                                        ) *
                                          (architecturalDesignCostConstructionPlanTotal +
                                            tax(architecturalDesignCostConstructionPlanTotal))) /
                                          100
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">造成設計</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.designCost[1].amount)
                              | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabDesignCosts2>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabDesignCosts2" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">造成設計</b-col>
                            <b-col class="ally-select">
                              <allySelect
                                name="landscapeDesign"
                                :items="landscapeDesignItems"
                                v-model="
                                  investmentAnalytics.initialInvestment.designCost[1].content
                                "
                              />
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">※地積</b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">造成面積</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.designCost[1].quantity
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    mode="View"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.propertyDescription[0]
                                        .area
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '造成設計',
                                        investmentAnalytics.initialInvestment.designCost[1].content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円/㎡</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        1,
                                        Math.floor(
                                          Math.floor(
                                            investmentAnalytics.initialInvestment.designCost[1]
                                              .quantity * 100
                                          ) / 100
                                        ) *
                                          getUnitPrice(
                                            '造成設計',
                                            investmentAnalytics.initialInvestment.designCost[1]
                                              .content
                                          )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">設計費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.designCost[1].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      Math.floor(
                                        (Math.floor(
                                          investmentAnalytics.initialInvestment.designCost[1]
                                            .quantity * 100
                                        ) /
                                          100) *
                                          getUnitPrice(
                                            '造成設計',
                                            investmentAnalytics.initialInvestment.designCost[1]
                                              .content
                                          )
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">工事監理</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.designCost[2].amount)
                              | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabDesignCosts3>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabDesignCosts3" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">工事監理費率</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.designCost[2].quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">％</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        2,
                                        Math.floor(
                                          (Math.floor(
                                            investmentAnalytics.initialInvestment.designCost[2]
                                              .quantity
                                          ) *
                                            (architecturalDesignCostConstructionPlanTotal +
                                              tax(architecturalDesignCostConstructionPlanTotal))) /
                                            100
                                        )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">工事監理費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.designCost[2].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      Math.floor(
                                        (Math.floor(
                                          investmentAnalytics.initialInvestment.designCost[2]
                                            .quantity
                                        ) *
                                          (architecturalDesignCostConstructionPlanTotal +
                                            tax(architecturalDesignCostConstructionPlanTotal))) /
                                          100
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Calculation from './../Calculation';
  import Vue from 'vue';
  export default {
    mixins: [Calculation],
    data: function() {
      return {
        // 造成設計
        landscapeDesignItems: this.getUnitPricesItems('造成設計'),
      };
    },
    watch: {
      // マスタ更新時
      '$store.state.master.unitPrice': {
        handler: function() {
          // 選択項目更新
          this.landscapeDesignItems = this.getUnitPricesItems('造成設計');
        },
        deep: false,
      },
    },
    methods: {
      changeGuideline: function(id, sum) {
        Vue.set(this.investmentAnalytics.initialInvestment.designCost[id], 'amount', sum);
      },
    },
  };
</script>
<style scoped>
  #DesignCosts .detail .card-body {
    padding-top: 0;
    padding-bottom: 8px;
  }
  #DesignCosts .guideline {
    padding-top: 22px;
    font-size: medium;
    text-align: center;
  }
</style>
