// @ts-check

export const DEFAULT_SCALE = 0.2;

/** @type {{name: string, subType: string, category?: string, dispTransform?: string, code?: number}[]} */
export const doorList = [
  // 窓
  {
    name: '引違いサッシ（2枚）',
    subType: 'doubleSlidingSash2',
  },
  {
    name: '引違いサッシ（3枚）',
    subType: 'doubleSlidingSash3',
  },
  {
    name: '引違いサッシ（4枚）',
    subType: 'doubleSlidingSash4',
  },
  {
    name: '障子付引違いサッシ（2枚）',
    subType: 'shojiDoubleSlidingSash2',
  },
  {
    name: '障子付引違いサッシ（3枚）',
    subType: 'shojiDoubleSlidingSash3',
  },
  {
    name: '障子付引違いサッシ（4枚）',
    subType: 'shojiDoubleSlidingSash4',
  },
  { // TODO: 追加必要
    name: '三角出窓（片開き）',
    subType: '',
  },
  { // TODO: 追加必要
    name: '出窓（FIX）',
    subType: '',
  },
  { // TODO: 追加必要
    name: '出窓（引き違い）',
    subType: '',
  },
  { // TODO: 追加必要
    name: '出窓（両袖開き）',
    subType: '',
  },
  { // TODO: 追加必要
    name: '弓型出窓（ボウウィンドウ）',
    subType: '',
  },
  { // TODO: 追加必要
    name: 'コーナー出窓',
    subType: '',
  },
  {
    name: 'はめ殺し窓（FIX）',
    subType: 'fixSash',
  },
  {
    name: '開き窓',
    subType: 'sideOpenWindow',
    dispTransform: `translate(25, 80) scale(${DEFAULT_SCALE})`,
  },
  {
    name: '開き窓（横滑り出し窓）',
    subType: 'sideSlideOpenWindow',
    dispTransform: `translate(25, 70) scale(${DEFAULT_SCALE})`,
  },
  {
    name: '上げ下げ窓',
    subType: 'upDownWindow',
  },
  {
    name: 'ジャロジー窓（ルーバー）',
    subType: 'jarologyWindow',
  },
  { // TODO: 追加必要
    name: 'シャッター付き窓',
    subType: '',
  },

  // ドア
  {
    name: '玄関 引違い戸（1枚）',
    subType: 'entrancePullDoor1',
  },
  {
    name: '玄関 引違い戸（2枚）',
    subType: 'entrancePullDoor2',
  },
  {
    name: '玄関 引違い戸（3枚）',
    subType: 'entrancePullDoor3',
  },
  {
    name: '玄関 引違い戸（4枚）',
    subType: 'entrancePullDoor4',
  },
  {
    name: '引違い戸（2枚）',
    subType: 'doubleSlidingDoor2',
  },
  {
    name: '引違い戸（3枚）',
    subType: 'doubleSlidingDoor3',
  },
  {
    name: '引違い戸（4枚）',
    subType: 'doubleSlidingDoor4',
  },
  {
    name: '引戸（二枚）',
    subType: 'doubleSlidingDoorSingle2',
    dispTransform: `translate(20, 50) scale(0.15)`,
  },
  {
    name: '引戸（三枚）',
    subType: 'doubleSlidingDoorSingle3',
    dispTransform: `translate(25, 50) scale(0.15)`,
  },
  {
    name: '引戸（引き込み）',
    subType: 'doubleSlidingDoorRetract',
    dispTransform: `translate(25, 50) scale(0.1)`,
  },
  {
    name: '引戸（両引き）',
    subType: 'doubleSlidingDoorDouble',
    dispTransform: `translate(45, 50) scale(0.12)`,
  },
  {
    name: '開口部（開口）',
    subType: 'opening',
    category: 'window',
  },
  {
    name: '開口部（下がり壁開口）',
    subType: 'openingDownWall',
    category: 'window',
  },
  {
    name: '開口部（腰壁開口）',
    subType: 'openingWaistWall',
    category: 'window',
  },
  {
    name: '開口部（くり貫き開口）',
    subType: 'openingDig',
    category: 'window',
  },
  {
    name: '玄関ドア（片開き）',
    subType: 'entranceSingleDoor',
    dispTransform: `translate(40, 75) scale(0.12)`,
  },
  {
    name: '玄関ドア（親子）',
    subType: 'entranceParentChildDoor',
    dispTransform: `translate(40, 75) scale(0.15)`,
  },
  {
    name: '玄関ドア（両開き）',
    subType: 'entranceDoubleDoor',
    dispTransform: `translate(35, 75) scale(0.18)`,
  },
  {
    name: '玄関ドア（片開き+フィックス）',
    subType: 'entranceSingleFixDoor',
    dispTransform: `translate(35, 75) scale(0.18)`,
  },
  {
    name: '玄関ドア（親子袖つき）',
    subType: 'entranceParentChildSleeveDoor',
    dispTransform: `translate(40, 75) scale(0.18)`,
  },
  {
    name: 'ドア（片開き）',
    subType: 'singleDoor',
    dispTransform: `translate(40, 75) scale(0.12)`,
  },
  {
    name: '両引きドア（親子開き）',
    subType: 'doubleParentChildDoor',
    dispTransform: `translate(40, 75) scale(0.18)`,
  },
  {
    name: '両引きドア（両開き）',
    subType: 'doubleDoor',
    dispTransform: `translate(35, 75) scale(0.18)`,
  },
  {
    name: '折戸（1枚）',
    subType: 'foldingDoor1',
    dispTransform: `translate(35, 65) scale(${DEFAULT_SCALE})`,
  },
  {
    name: '折戸（2枚）',
    subType: 'foldingDoor2',
  },
  {
    name: '折戸（3枚）',
    subType: 'foldingDoor3',
  },
  {
    name: '折戸（4枚）',
    subType: 'foldingDoor4',
  },
];
