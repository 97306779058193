<!--
作成者：Lee HyeonSeok
作成日：2020.6.12
画面名：会社情報一覧
機能：会社情報一覧
親子関係：なし
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <h4 class="font-weight-bold">会社情報一覧</h4>
    <div class="row grid-margin">
      <div class="col-12">
        <router-link to="Add" tag="button" class="btn btn-ally float-right">追加</router-link>
      </div>
    </div>
    <paginationV2
      ref="pagination"
      :headers="headers"
      :filterFix="filterFix"
      targetName="Company"
      @detailClicked="e => detailCompany(e)"
      @deleteClicked="e => deleteCompany(e)"
    ></paginationV2>
    <div id="fadeLayer" ref="fadeLayer"></div>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { API, Auth, graphqlOperation } from 'aws-amplify';
  import { listAccounts } from '../../graphql/queries';
  import { createDeleteQue, deleteCompany, permanentDeleteAccount } from '../../graphql/mutations';

  API.configure(awsconfig);
  Auth.configure(awsconfig);
  var moment = require('moment-timezone');
  moment.tz.setDefault('Asia/Tokyo');

  export default {
    data: function() {
      return {
        companys: {
          body: [],
          title: [],
        },
        isMounted: false,
        headers: [
          { key: 'customerNumber', label: '顧客番号', sortable: true },
          { key: 'companyName', label: '会社名', sortable: true },
          { key: 'tel', label: '電話番号', sortable: true },
          { key: 'mail', label: 'E-mail', sortable: true },
          { key: 'createdAt', label: '登録日', sortable: true },
          { key: 'updatedAt', label: '更新日', sortable: true },
        ],
        filterFix: {
          deleteDate: {
            notContains: '',
          },
        },
      };
    },
    mounted: async function() {},
    methods: {
      deleteCompany: async function(body) {
        try {
          this.$refs.fadeLayer.style.visibility = 'visible';
          const date = new Date();
          date.setDate(date.getDate() + 7);

          //DeleteQueのデータに変換する。
          let data = {
            id: body.id,
            companyName: body.companyName,
            bossLastName: body.bossLastName,
            bossFirstName: body.bossFirstName,
            tel: body.tel,
            mail: body.mail,
            address: body.address,
            qualification: body.qualification,
            registrationNum: body.registrationNum,
            companyLogo: body.companyLogo,
            accountNum: body.accountNum,
            deleteFlg: body.deleteFlg,
          };

          //削除した会社情報を保存する。
          //graphQL処理
          await API.graphql(
            graphqlOperation(createDeleteQue, {
              input: {
                ...data,
                deleteDate: undefined,
                createdAt: undefined,
                updatedAt: undefined,
              },
            })
          );

          //ユーザーを削除する(復元不可)
          let users = await this.getAcount('Company-' + data.id);
          if (users.items != null) {
            for (let user of users.items) {
              await API.graphql(graphqlOperation(permanentDeleteAccount, { sub: user.sub }));
            }
          }

          //会社情報を削除する（復元不可）
          await API.graphql(
            graphqlOperation(deleteCompany, {
              input: {
                id: data.id,
              },
            })
          );
        } catch (e) {
          console.error(e);
          alert('会社削除に失敗しました。');
        }
        this.$refs.pagination.listItems();
        this.$refs.fadeLayer.style.visibility = 'hidden';
      },
      detailCompany: function(body) {
        this.$router.push({
          path: '/Company/View/' + body.id,
        });
      },
      // アカウント情報取得
      getAcount: async function(companyId) {
        let queryVar = {
          limit: 60,
          nextToken: null,
          filter: {
            sub: {
              contains: companyId,
            },
          },
        };
        try {
          const queryResult = await API.graphql(graphqlOperation(listAccounts, queryVar));
          //console.log('queryResult', queryResult.data.listAccounts);
          return queryResult.data.listAccounts;
        } catch (e) {
          console.error(e);
        }
      },
    },
    // ページング処理コンポーネント呼び出し
    computed: {
      currentDate() {
        const date = new Date();
        const mm = `0${date.getMonth() + 1}`.slice(-2);
        const dd = `0${date.getDate()}`.slice(-2);

        return `${date.getFullYear()}-${mm}-${dd}`;
      },
    },
  };
</script>

<style scoped>
  #fadeLayer {
    position: absolute;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;

    background-color: #000000;
    opacity: 0.5;
    visibility: hidden;
    z-index: 1;
  }
</style>
