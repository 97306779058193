// @ts-check

import { onMounted, ref } from '@vue/composition-api';
import { IconDataFetcher } from '../fetch/icon';

export const useIconImages = () => {
  /** @type {import('@vue/composition-api').Ref<import('../util/iconList').IconData[]>} */
  const iconImages = ref([]);
  const iconCategoryList = ref([]);
  const isLoadingIconImages = ref(false);

  onMounted(async () => {
    isLoadingIconImages.value = true;
    const repo = new IconDataFetcher();
    // @ts-ignore
    iconImages.value = await repo.listIcons();
    iconCategoryList.value = await repo.IconCategoryList();
    isLoadingIconImages.value = false;
  });

  return {
    iconImages,
    iconCategoryList,
    isLoadingIconImages,
  };
};
