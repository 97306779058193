// @ts-check
import {
  applyToPoint,
  compose,
  decomposeTSR,
  fromTriangles,
  identity,
  inverse,
  isAffineMatrix,
  rotate,
  scale,
  smoothMatrix,
  toCSS,
  toString,
  toSVG,
  translate,
} from 'transformation-matrix';
import { Transform } from './transform';
import { convertMat } from './utils';
import { Vec } from './vector';

export class IMatrix {
  /** @type {number} */
  a;
  /** @type {number} */
  b;
  /** @type {number} */
  c;
  /** @type {number} */
  d;
  /** @type {number} */
  e;
  /** @type {number} */
  f;
}

export class Matrix {
  /** @type {number} */
  a;
  /** @type {number} */
  b;
  /** @type {number} */
  c;
  /** @type {number} */
  d;
  /** @type {number} */
  e;
  /** @type {number} */
  f;
  /** @param {number[]?} array */
  constructor(array = null) {
    if (array) {
      this.a = array[0];
      this.b = array[1];
      this.c = array[2];
      this.d = array[3];
      this.e = array[4];
      this.f = array[5];
    } else {
      this.a = 1;
      this.b = 0;
      this.c = 0;
      this.d = 1;
      this.e = 0;
      this.f = 0;
    }
  }

  /**
   * @returns {Matrix}
   */
  static identity() {
    const mat = identity();
    return new Matrix([mat.a, mat.b, mat.c, mat.d, mat.e, mat.f]);
  }

  /**
   * @returns {Transform}
   */
  decompose() {
    const transform = decomposeTSR(this);
    const rot = transform.rotation.angle;

    const tr = new Transform({
      translate: new Vec(transform.translate.tx, transform.translate.ty),
      rotate: rot,
      scale: new Vec(transform.scale.sx, transform.scale.sy),
    });

    return tr;
  }

  /**
   * @returns {Matrix}
   */
  inverse() {
    return convertMat(inverse(this));
  }

  /**
   *
   * @returns {boolean}
   */
  isAffineMatrix() {
    return isAffineMatrix(this);
  }

  /**
   *
   * @param {Vec} pt
   * @returns {Vec}
   */
  point(pt) {
    const point = applyToPoint(this, pt);
    return new Vec(point.x, point.y);
  }

  /**
   *
   * @param {Vec[]} pts
   * @returns {Vec[]}
   */
  points(pts) {
    return pts.map(pt => this.point(pt));
  }

  /**
   * @param {number} x
   * @param {number} y
   * @returns {Matrix}
   */
  translate(x, y) {
    const trmat = translate(x, y);
    return convertMat(compose(this, trmat));
  }

  /**
   *
   * @param {number} sx
   * @param {number} sy
   * @param {number} x
   * @param {number} y
   * @returns {Matrix}
   */
  scale(sx, sy = sx, x = 0, y = 0) {
    const scmat = scale(sx, sy, x, y);
    return convertMat(compose(this, scmat));
  }

  /**
   * @param {number} rad
   * @param {number} x
   * @param {number} y
   * @returns {Matrix}
   */
  rotate(rad, x = 0, y = 0) {
    const rotmat = rotate(rad, x, y);
    return convertMat(compose(this, rotmat));
  }

  /**
   *
   * @param {number} deg
   * @param {number} x
   * @param {number} y
   * @returns {Matrix}
   */
  rotateDeg(deg, x = 0, y = 0) {
    const rad = (deg * Math.PI) / 180;
    return this.rotate(rad, x, y);
  }

  /**
   * @param {IMatrix[]} mats
   */
  transform(...mats) {
    return convertMat(compose(this, ...mats));
  }

  /**
   *
   * @param {number} precision
   * @returns {Matrix}
   */
  smooth(precision = 10000000000) {
    return convertMat(smoothMatrix(this, precision));
  }

  /**
   *
   * @returns {string}
   */
  toSVG() {
    return toSVG(this);
  }

  /**
   * @returns {string}
   */
  toCSS() {
    return toCSS(this);
  }

  /**
   * @returns {string}
   */
  toString() {
    return toString(this);
  }

  /**
   *
   * @param {Vec[]} t1
   * @param {Vec[]} t2
   * @returns {Matrix}
   */
  fromTriangles(t1, t2) {
    return convertMat(fromTriangles(t1, t2));
  }

  clone() {
    return new Matrix([this.a, this.b, this.c, this.d, this.e, this.f]);
  }

  copy() {
    return this.clone();
  }
}
