<template>
  <div>
    <component
      id="LicenseDetailModal"
      ref="LicenseDetailModal"
      :title="companyName + ' ' + titleDate + '詳細'"
      header-class="ally-modal text-center"
      footer-class="ally-modal text-center"
      ok-title="閉じる"
      size="lg"
      ok-only
      :is="isModal ? 'b-modal': 'div'"

    >
      <div class="col-12 p-3">
        <!-- タイトル -->
        <h4>
          <span v-if="isModal && !pagenation" class="hyperlink" @click="companyLink(company.id)">{{ companyName }}</span>
          {{ titleDate }}
        </h4>
        <br />
        <!-- ライセンスリスト -->
        <div v-if="!updateLicenseList && !isNonCurrentMonthData" class="text-red">
          ライセンス情報が見つかりませんでした
        </div>
        <!-- 会社情報詳細画面用 -->
        <div v-if="!updateLicenseList && isNonCurrentMonthData" class="text-red">
          今月の決済情報はありません
        </div>
        <b-table
          v-if="updateLicenseList"
          thead-tr-class="serch-data-table-header"
          tbody-tr-class="serch-data-table-body"
          striped
          :items="licenseListItems"
          :fields="licenseListfields"
          show-empty
          responsive="sm"
          :foot-clone="true"
          tfoot-class="serch-data-table-foot"
        >
          <!-- ライセンスリスト body -->
          <template v-slot:cell(licenseName)="row">
            <div
              class="wrap"
              style="
                word-break: break-all; 
                white-space: normal; 
                text-align: left; 
                line-height: 1.3em;"
            >
              {{ row.item.licenseName }}
            </div>
          </template>
          <template v-slot:cell(MonthOfUse)="row">
            <div>{{ row.item.MonthOfUse }}月分</div>
          </template>
          <!-- ライセンスリスト foot -->
          <template #foot()="">
            <div></div>
          </template>
          <template #foot(MonthOfUse)="">
            <div>合 計 :</div>
          </template>
          <template #foot(quantity)="">
            <div>{{ totalQuantity }}</div>
          </template>
          <template #foot(cost)="">
            <div>{{ totalCost.toLocaleString() }}</div>
          </template>
        </b-table>
        <div v-if="updateLicenseList" :class="paymentInfoClassStyle">
          <div class="col-3 m-0 p-0">
            <h5 class="bold">支払方法</h5>
            <p>
              {{ payType }}
            </p>
          </div>
          <div class="col-3 m-0 p-0">
            <h5 class="bold">オーダーID/自動売上ID</h5>
            <p>
              {{ recurringId }}
            </p>
          </div>
        <div v-if="updateLicenseList" :class="paymentInfoClassStyle">
          <div class="col-3 m-0 p-0">
            <h5 class="bold">決済日</h5>
            <p>
              {{ settlementDate }}
            </p>
          </div>
          <div class="col-3 m-0 p-0">
            <h5 class="bold">入金日</h5>
            <p :class="isLatePayment ? 'text-red ' : ''">
              {{ paymentDay }}
            </p>
          </div>
          <div class="col-3 m-0 p-0"></div>
        </div>
        <div v-if="updateLicenseList" :class="paymentInfoClassStyle">
          <div class="col-3 m-0 p-0">
            <h5 class="bold">入金額</h5>
            <p :class="depositAmount >= 0 ? '' : 'text-red '">
              {{ depositAmount != 0 ? depositAmount.toLocaleString() : nonValueText }}
            </p>
          </div>
          <div class="col-3 m-0 p-0">
            <h5 class="bold">入金差額</h5>
            <p :class="depositDifference >= 0 ? '' : 'text-red '">
              {{ depositDifference != 0 ? depositDifference.toLocaleString() : nonValueText }}
            </p>
          </div>
          <div class="col-3 m-0 p-0">
            <h5 class="bold">調整差額</h5>
            <p :class="adjustmentDifference >= 0 ? '' : 'text-red '">
              {{ adjustmentDifference != 0 ? adjustmentDifference.toLocaleString() : nonValueText }}
            </p>
          </div>
          <div class="col-3 m-0 p-0"></div>
        </div>
        <div v-if="updateLicenseList" :class="paymentInfoClassStyle">
          <div class="col-12 m-0 p-0">
            <h5 class="bold">備考</h5>
            <p>
              {{ status + memo }}
            </p>
            <br v-if="status && unpaidHistory" />
            <p v-for="(history, index) in unpaidHistory" :key="'history-' + index + '_' + history">
              {{ history }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="pagenation">
      <b-row class="d-grid gap-2 d-md-flex justify-content-md-end align-items-center">
        <b-col cols="1">
          <b-button v-on:click="prevOption" :disabled="isFirstOption" class="btn btn-ally">＜</b-button>
        </b-col>
        <b-col cols="3">
          <b-form-select
            v-on:change="updateList"
            v-model="selectDate"
            :options="selectOptions"
          />
        </b-col>
        <b-col cols="1">
          <b-button v-on:click="nextOption" :disabled="isLastOption" class="btn btn-ally float-right">＞</b-button>
        </b-col>
      </b-row>
    </div>
    </component>
  </div>
</template>

<script>
  var moment = require('moment-timezone');
  import { API, graphqlOperation } from 'aws-amplify';
  import { getSystemMaster, getCognitoAttributes } from '../../../graphql/queries';
  import wordData from '../js/wordData.js';
  import util from '../js/util.js';
  export default {
    props: {
      isModal:{
        type:Boolean,
        default:true
      }
    },
    data() {
      return {
        paymentInfoClassStyle: 'paymentInfo row col-12 mx-0 my-4 p-0',
        getItemData: null,
        company: null,
        paymentData: null,
        licenseMaster: [],
        companyName: '',
        titleDate: '',
        licenseListfields: [
          { key: 'licenseName', label: 'ライセンス名' },
          { key: 'startDate', label: '利用開始日' },
          { key: 'endDate', label: '利用終了日' },
          { key: 'MonthOfUse', label: '請求月' },
          { key: 'quantity', label: '個数' },
          { key: 'cost', label: '料金' },
        ],
        licenseListItems: [],
        totalQuantity: '', // ライセンス総数
        totalCost: '', // 総額
        payType: '', // 支払方法
        recurringId: '', // オーダーID/自動売上ID
        settlementDate: '', // 決済日
        paymentDay: '', // 入金日
        isLatePayment: false, // 支払遅延フラグ
        depositAmount: '', // 入金額
        depositDifference: '', // 入金差額
        adjustmentDifference: '', // 調整差額
        status: '', // 調整方法
        memo: '', // メモ
        unpaidHistory: [], // 未納履歴
        nonValueText: '-', // 表示値無し代替テキスト
        selectDate: null, // 年月選択肢
        pagenation: false, // ページネーション表示
        multiMonthPayment: [], // 過去分の決済
        isNonCurrentMonthData: false, // 会社詳細情報画面用、当月のデータがないとき
        selectOptions: [], // セレクトボックスデータ
        isFirstOption: false,
        isLastOption: true,
      };
    },
    mixins: [wordData, util],
    mounted: async function() {
      // ライセンスマスタ読込
      // ロード
      await API.graphql(
        graphqlOperation(getSystemMaster, {
          classification: 'license',
          classificationDetail: '1',
        })
      )
        .then(result => {
          const resultMap = new Map();
          for (const val of result.data.getSystemMaster.value) {
            const item = JSON.parse(val);
            item.updatedAt = result.data.getSystemMaster.updatedAt;
            resultMap.set(item.id, item);
          }
          this.licenseMaster = resultMap;
          if(!this.isModal) {
            this.$emit("createDisp");
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    computed: {
      // COMPUTED: 定数：未納
      depositRefundStatusUnpaid() {
        return this.getDepositRefundStatus('UNPAID').no;
      },
      // COMPUTED: 定数：入金済
      depositRefundStatusPaySuccess() {
        return this.getDepositRefundStatus('PAY_SUCCESS').no;
      },
      // COMPUTED: 定数：入金対応
      depositRefundStatusDirectPayment() {
        return this.getDepositRefundStatus('DIRECT_PAYMENT').no;
      },
      // COMPUTED: 定数：返金対応
      depositRefundStatusDirectRefund() {
        return this.getDepositRefundStatus('DIRECT_REFUND').no;
      },
      // COMPUTED: 定数：翌月清算
      depositRefundStatusNextUse() {
        return this.getDepositRefundStatus('NEXT_USE').no;
      },
      // COMPUTED: 定数：清算済
      depositRefundStatusSettled() {
        return this.getDepositRefundStatus('SETTLED').no;
      },
      // COMPUTED: ライセンス情報更新
      updateLicenseList() {
        return this.licenseListItems.length > 0;
      }
    },
    methods: {
      // METHOD: モーダル表示
      // 複数月データの分割とセレクトボックスデータ作成
      itemsSegment(items) {
        let select = null;
        this.multiMonthPayment = items;
        this.selectOptions = [];
        let nowMonth = moment().add(-1,'month').startOf('month').format('YYYY-MM-01');
        for(let selectList in items){
          this.selectOptions.push({
            value: items[selectList].paymentId,
            text: this.changeDateString(items[selectList].salesConfirmationDate, -1, 'YYYY年M月'),
          });
          if(items[selectList].salesConfirmationDate === nowMonth) {
            select = items[selectList].paymentId; 
          }
        }

        this.selectDate = (select == null) ? items[0].paymentId : select;

        // ボタンのdisable更新
        this.isFirstOption = (this.selectDate === this.selectOptions[this.selectOptions.length -1].value) ? true : false;
        this.isLastOption = (this.selectDate === this.selectOptions[0].value) ? true : false;

        let item = null;
        item = items.filter(li =>  li.salesConfirmationDate === nowMonth);
        // 先月より以前のデータしかなかった場合は先頭データを渡す
        return (item[0] == null) ? items[0] : item[0];
      },
      showModal(item, company, isMultiple = false) {
        this.getItemData = item;
        this.company = company;
        this.pagenation = isMultiple;
        if(this.pagenation) {
          // 複数月来ている場合は表示する単月のみ準備
          this.getItemData = this.itemsSegment(item);
        }
        // リストアイテム生成
        this.createLicenseListItems();
        this.setData();
        this.$bvModal.show('LicenseDetailModal');
      },
      // モーダルではなく画面の一部として表示
      // item:決済情報を取得する
      // company:会社情報・ライセンス情報を取得する
      // nonCurrent:今月のデータのみなかった場合trueになる、会社詳細情報画面のみ使用
      showDisplay(item, company, nonCurrent) {
        this.getItemData = item;
        this.company = company;
        this.isNonCurrentMonthData = nonCurrent;
        // 決済情報がない場合は処理を進めない
        if(item!=null){
          // リストアイテム生成
          this.createLicenseListItems();
          this.setData();
        }
      },
      // METHOD: リストアイテム生成
      createLicenseListItems() {
        this.licenseListItems = [];
        // 表示するライセンス情報を取得
        const showTargetLicenses = this.company.license.filter(li => {
          return (
            // 対象月の前月のライセンス情報（未納、または直接入金の場合）
            (li.paymentId === this.createPaymentId(this.getItemData.salesConfirmationDate, -2) &&
              this.company.payment.find(
                pay =>
                  pay.id === li.paymentId &&
                  pay.depositRefundInfo &&
                  (pay.depositRefundInfo.status === this.depositRefundStatusUnpaid || // 調整方法が未納
                    pay.depositRefundInfo.status === this.depositRefundStatusDirectPayment) // 調整方法が入金対応
              )) ||
            // 対象月のライセンス情報
            li.paymentId === this.createPaymentId(this.getItemData.salesConfirmationDate, -1)
          );
        });
        // リストアイテム生成
        this.totalQuantity = 0; // 総ライセンス数

        if (showTargetLicenses.length) {
          showTargetLicenses.map(li => {
            this.licenseListItems.push({
              licenseName: this.licenseMaster.get(parseInt(li.id)).name, // ライセンス名
              startDate: li.startDate, // 利用開始日 (お客様指示でinitialPurchaseDateから変更)
              endDate: li.endDate, // 利用終了日
              MonthOfUse: this.changeDateString(li.startDate, 0, 'M'), // 請求月
              quantity: li.quantity, // 個数
              cost:
                li.amount > 0 ? Number(li.amount + li.tax).toLocaleString('ja-JP') : this.nonValueText, // 料金
            });
            // 数量加算
            this.totalQuantity += li.quantity;
          });
        }
        // 請求額セット
        this.totalCost =
          this.getItemData.billingAmount > 0 ? this.getItemData.billingAmount : this.nonValueText;
      },

      // METHOD: 表示値セット
      setData() {
        // 会社名
        this.companyName = this.company.companyName;
        // タイトル
        this.titleDate = this.changeDateString(
          this.getItemData.salesConfirmationDate,
          -1,
          'YYYY年M月分'
        );
        // 支払方法
        const payType = this.getPaymentType().find(
          payType => payType.no == parseInt(this.getItemData.paymentMethod)
        );
        this.payType = payType ? payType.name : this.nonValueText;
        // 決済日
        this.settlementDate = this.changeDateString(
          this.getItemData.settlementDate,
          0,
          'YYYY年M月D日'
        );
        // 入金日
        this.paymentDay = this.getItemData.paymentDay
          ? this.changeDateString(this.getItemData.paymentDay, 0, 'YYYY年M月D日')
          : this.nonValueText;
        // 支払遅延フラグ
        this.isLatePayment = moment(this.getItemData.paymentDay).isAfter(
          this.getItemData.settlementDate,
          'day'
        );
        // 入金額
        this.depositAmount = this.getItemData.depositAmount;
        // 入金差額
        this.depositDifference = this.getItemData.depositDifference;
        // 調整差額
        this.adjustmentDifference = this.getItemData.adjustmentDifference;
        // 備考
        const payment = this.company.payment.find(
          // 当月分に調整方法情報が存在するかチェック
          pay =>
            pay.id == this.createPaymentId(this.getItemData.salesConfirmationDate, -1) &&
            pay.depositRefundInfo
        );
        // オーダーID/自動売上ID取得
        const recurringId = this.company.payment.find(pay => {
          return( pay.id == this.createPaymentId(this.getItemData.salesConfirmationDate, -1) && pay.recurringId)
        });
        this.recurringId = (recurringId == null) ? "" : recurringId.recurringId;
        
        //あおぞらではオーダーIDを選択する
        if(payType?.no == 36){
          this.recurringId = this.company?.bankTransfer?.orderId;
        }

        if (payment) {
          this.status = payment.depositRefundInfo.status
            ? this.getDepositRefundStatus(payment.depositRefundInfo.status).displayName // 調整方法情報が存在する場合はその表示名
            : ''; // 存在しなければ空文字。
          this.memo = payment.depositRefundInfo.memo
            ? '：' + payment.depositRefundInfo.memo // 当月分の調整方法にメモが存在すれば表示する。
            : ''; // 存在しなければ空文字。
        }

        // 未納履歴
        const unpaid = this.company.payment.filter(
          pay =>
            (pay.id == this.createPaymentId(this.getItemData.salesConfirmationDate, -2) || // 前月分
              pay.id == this.createPaymentId(this.getItemData.salesConfirmationDate, -1)) && // 当月分
            pay.depositRefundInfo && // 調整方法が存在する
            (pay.depositRefundInfo.status ==
              this.getDepositRefundStatus(this.depositRefundStatusUnpaid).no || // 調整方法が未納
              pay.depositRefundInfo.status ==
                this.getDepositRefundStatus(this.depositRefundStatusDirectPayment).no) // 調整方法が入金対応
        );
        this.unpaidHistory = unpaid.map(unpaid => {
          return (
            this.changeDateString(unpaid.id, 0, 'YYYY年M月分') + // 取得した決済情報.idから日付を生成
            this.getDepositRefundStatus(this.depositRefundStatusUnpaid).displayName // 「未納」追記
          );
        });
      },

      // METHOD: 会社名ハイパーリンク
      companyLink(companyId) {
        this.$router.push('/Company/View/' + companyId);
      },

      // 選択肢変更
      updateList(event)
      {
        // リストで選択された値
        let id = event;
        let payment = [];
        payment = this.multiMonthPayment.filter(li =>
          li.paymentId === id
        );
        this.getItemData = payment[0];
        // リストアイテム生成
        this.createLicenseListItems();
        this.setData();
        // ボタンのdisable更新
        this.isFirstOption = (this.selectDate === this.selectOptions[this.selectOptions.length -1].value) ? true : false;
        this.isLastOption = (this.selectDate === this.selectOptions[0].value) ? true : false;
      },
      // 選択肢に対応したボタン押下(＜)
      prevOption()
      {
        if(this.selectOptions.length > 0) {
          let selectIndex = this.selectOptions.findIndex(option =>
            option.value === this.selectDate
          );
          if(selectIndex < this.selectOptions.length -1){
            let newIndex = (selectIndex + 1 + this.selectOptions.length) % this.selectOptions.length;
            this.selectDate = this.selectOptions[newIndex].value;
            this.updateList(this.selectDate);
          }
        }
      },
      // 選択肢に対応したボタン押下(＞)
      nextOption()
      {
        if(this.selectOptions.length > 0) {
          let selectIndex = this.selectOptions.findIndex(option =>
            option.value === this.selectDate
          );
          if(selectIndex > 0){
            let newIndex = (selectIndex - 1 + this.selectOptions.length) % this.selectOptions.length;
            this.selectDate = this.selectOptions[newIndex].value;
            this.updateList(this.selectDate);
          }
        }
      },
    },
  };
</script>

<style>
  .hyperlink {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  .text-red {
    color: red;
  }
</style>