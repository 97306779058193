export const headerStyle = {
  padding: '0 4px',
  border: 'solid black 1px',
  'background-color': '#1d1d63',
  color: 'white',
  'text-align': 'center',
};
export const subHeaderStyle = {
  padding: '0 4px',
  border: 'solid black 1px',
  'background-color': '#ccc',
  'text-align': 'center',
};
export const bodyStyle = {
  padding: '0 4px',
  border: 'solid black 1px',
  'background-color': 'white',
  'text-align': 'center',
};
export const bodyStylePrice = {
  padding: '0 15px',
  border: 'solid black 1px',
  'background-color': 'white',
  'text-align': 'right',
};
export const partHeaderStyle = {
  padding: '0 4px',
  border: 'solid black 1px',
  'background-color': '#7a7a7a',
  color: 'white',
  'text-align': 'center',
};
