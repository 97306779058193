<template>
  <div style="width: 100%; background-color: white; box-shadow: 2px 2px 4px; padding: 2%;">
    <div
      style="
                padding: 2px;
                border-bottom: 5px double #1d1d63;
                display: flex;
            "
      :style="headerStyle"
    >
      <div
        v-for="col in header"
        :key="col.label"
        :style="{
          'flex-basis': col.width ? col.width * 100 + '%' : '100%',
        }"
      >
        <slot name="header" :col="col">{{ col.label }}</slot>
      </div>
    </div>
    <div
      style="
                display: flex;
                border-bottom: 1px solid #1d1d63;
                padding: 2px;
            "
      :style="tableStyle"
      v-for="(item, i) in items"
      :key="i"
    >
      <div
        v-for="col in header"
        :key="col.name"
        :style="{
          'text-align': typeof item[col.name] === 'number' ? 'right' : 'center',
          'flex-basis': col.width ? col.width * 100 + '%' : '100%',
        }"
      >
        <slot name="table" :col="col" :item="item">{{ item[col.name] }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      header: {
        type: Array, // [{name, label, width},]
        required: true,
      },
      items: {
        type: Array, // [{header[0].name: "", header[1].name: "", ...},]
        required: true,
      },
      headerStyle: {
        type: Object,
        default: () => {},
      },
      tableStyle: {
        type: Object,
        default: () => {},
      },
    },
  };
</script>
