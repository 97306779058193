import { v4 as uuidv4 } from 'uuid';

export default {
 data: function() {
   return {
     maxRoom: 100, //部屋情報の最大登録数
   };
 },
 async created() {},
 methods: {
    /**
     * 既存の部屋情報一覧に１件部屋情報を追加する。
     * @param {*} roomInfo 
     * @returns {
     *  data: getProperty
     *  status: "success" / "error"  成功 / 失敗
     * }
     */
    addRoom: async function(roomInfo, newRoomName) {
      if (roomInfo == null) roomInfo = [];
      if (roomInfo.length >= this.maxRoom) {
        return{
          data: roomInfo,
          status: "error",
          addRoomId: null,
        };
      }

      let uuid = await uuidv4();
      roomInfo.push({
        id: uuid,
        roomName: newRoomName,
      });
    
      return{
        data: roomInfo,
        status: "success",
        addRoomId: uuid
      };
    },
    //部屋情報の最大保存数を取得する
    getMaxRoom(){
        return this.maxRoom;
    },
  },
}