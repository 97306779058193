<template>
  <b-row>
    <b-col>
      <!-- VIEW: 階層の設定フォーム -->
      <ValidationObserver ref="updateLayersForm" v-slot="ObserverProps">
        <div class="mb-2">
          <span class="pv-config-label">階層の設定</span>
          <span style="margin-left: 1rem;">※最大{{ SAVE_LIMIT.LAYER }}階層まで</span>
        </div>
        <div class="row mx-0 mb-4 px-0">
          <allyText
            class="col-3 my-0 ml-0 mr-1 p-0"
            title="階層を設定する場合、階数"
            :nonAppear="true"
            append="階層"
            type="number"
            min="1"
            max="5"
            step="1"
            v-model="layerNum"
            @change="val => (layerNum = val ? val : 1)"
            :rules="'required|numeric|between:1,' + SAVE_LIMIT.LAYER"
            :hideBadge="true"
          />
          <div class="col-2 mr-1 mt-0 mb-3 p-0">
            <button
              class="btn btn-ally col-12 m0 "
              :disabled="ObserverProps.invalid || layerNum == planeViewLayers.length"
              @click="$emit('updateLayers', { layerNum: layerNum })"
            >
              保存
            </button>
          </div>
        </div>
      </ValidationObserver>
      <ValidationObserver ref="addPatternForm" v-slot="ObserverProps">
        <!-- VIEW: パターン追加フォーム -->
        <div class=" mx-0 mb-3 px-0">
          <div class="mb-2">
            <span class="pv-config-label">パターンの追加</span>
            <span style="margin-left: 1rem;"
              >※表示中のパターンを複製します。合計{{ SAVE_LIMIT.PATTERN }}パターンまで</span
            >
          </div>
          <allyText
            class="col-12  m-0 p-0"
            title="パターンを追加する場合、パターン名"
            :nonAppear="true"
            placeholder="パターン名を入力してください"
            v-model="patternName"
            @change="val => (patternName = val ? val : '')"
            :rules="'required|max:' + VALIDATE_NUM.TITLE_LENGTH_MAX"
            :hideBadge="true"
          />
          <div class="col-12  m-0 p-0 text-right">
            <button
              class="btn btn-ally col-2 ml-3 "
              :disabled="ObserverProps.invalid"
              @click="$emit('addPattern', { name: patternName })"
            >
              追加
            </button>
          </div>
        </div>
      </ValidationObserver>
      <ValidationObserver ref="renamePatternForm" v-slot="ObserverProps">
        <!-- VIEW: パターンの名称変更と削除フォーム -->
        <div class="mb-2">
          <span class="pv-config-label">パターンの名称変更と削除</span>
        </div>
        <allySelect
          class="col-12 m-0 p-0"
          :mode="planeViewPatterns.length == 1 ? 'View' : 'Add'"
          v-model="selectPatternIndex"
          type="number"
          :items="patternItems"
          rules="required"
          :hideBadge="true"
        />
        <div class="col-12 mb-2 p-0 text-center bold">↓</div>
        <allyText
          class="col-12 m-0 p-0"
          title="パターンの名称を変更する場合、新しい名称"
          :nonAppear="true"
          placeholder="新しい名称を入力してください"
          v-model="patternNewName"
          @change="val => (patternNewName = val ? val : '')"
          :rules="'required|max:' + VALIDATE_NUM.TITLE_LENGTH_MAX"
          :hideBadge="true"
        />
        <div class="col-12  m-0 mb-3 p-0 text-right">
          <button
            class="btn btn-ally col-2 mr-3"
            :disabled="ObserverProps.invalid || planeViewPatterns.length <= selectPatternIndex"
            @click="$emit('renamePattern', { index: selectPatternIndex, newName: patternNewName })"
          >
            名称変更
          </button>
          <button
            class="btn btn-ally col-2 m-0"
            :disabled="
              patternItems.length == 1 ||
                selectPatternIndex < 0 ||
                patternItems.length <= selectPatternIndex
            "
            @click="$emit('splicePatterns', { index: selectPatternIndex })"
          >
            削除
          </button>
        </div>
      </ValidationObserver>
      <!-- VIEW: メッセージ -->
      <div class="guidance-message">
        <!-- 物件未選択時 -->
        <!-- 枚数最大時 -->
      </div>
    </b-col>
  </b-row>
</template>
<script>
  // IMPORT:
  import {
    computed,
    defineComponent,
    getCurrentInstance,
    nextTick,
    onMounted,
    onUnmounted,
    ref,
    toRefs,
    watch,
  } from '@vue/composition-api';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getProperty, listPlaneViews } from '../../../../graphql/queries';
  import PropertyList from '@/views/Property/List';
  import { useRouter } from '../../lib/state/router';
  import PropertyViewer from '../../../Property/View';
  import { MESSAGE_TEXT, SAVE_LIMIT, VALIDATE_NUM } from '../../lib/util/const';

  export default defineComponent({
    components: {
      PropertyList,
      PropertyViewer,
    },
    props: {
      patternIndex: {
        // PROP: 現在表示中のパターンのインデックス
        type: Number,
        required: true,
      },
      planeViewPatterns: {
        // PROP: 平面図パターン
        type: Array,
        required: true,
      },
      planeViewLayers: {
        // PROP: 平面図レイヤー
        type: Array,
        required: true,
      },
    },
    setup(props, context) {
      const layerNum = ref(props.planeViewLayers.length);
      const patternName = ref('');
      const patternItems = computed(() => {
        const items = [];
        props.planeViewPatterns.forEach((p, index) => {
          items.push({ value: index, text: p.name });
        });
        return items;
      });
      const selectPatternIndex = ref(props.patternIndex);
      const patternNewName = ref('');

      const updateLayersForm = ref();
      const isDisableSaveLayerNum = ref(true);
      const isDisableSaveNewPattern = ref(true);

      onMounted(async () => {});

      // パターン削除時にプルダウンの選択アイテムを更新する
      watch(
        [patternItems],
        () => {
          // 選択中のインデックスが削除後の配列に存在しない場合は、最後のインデックスをセットする
          selectPatternIndex.value =
            0 <= props.patternIndex && props.patternIndex <= props.planeViewPatterns.length - 1
              ? props.patternIndex
              : props.planeViewPatterns.length - 1;
        },
        { deep: true }
      );

      return {
        layerNum,
        patternName,
        patternItems,
        selectPatternIndex,
        patternNewName,
        updateLayersForm,

        isDisableSaveLayerNum,
        isDisableSaveNewPattern,

        MESSAGE_TEXT, // メッセージ文
        VALIDATE_NUM, // バリデート値
        SAVE_LIMIT,
      };
    },
  });
</script>
<style scoped>
  /* メッセージ */
  .guidance-message p {
    font-size: small;
    font-weight: bold;
    color: red;
  }
  /* 検索・新規ボタン */
  .search-or-create button {
    height: 10px;
    line-height: 2px;
  }
</style>
