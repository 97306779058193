// @ts-check
import { PvObject } from './pvObject';

export class PvRoot extends PvObject {
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'root';
  constructor() {
    super();
  }
}
