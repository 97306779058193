<template>
  <button
    @mousedown="e => $emit('mousedown', e)"
    @mouseup="e => $emit('mouseup', e)"
    @click="e => $emit('click', e)"
    class="right-click-menu-btn"
    :class="[selected ? 'active' : undefined]"
    :style="buttonCSS"
    :disabled="!isEditor"
  >
    <slot />
  </button>
</template>
<script>
  import { computed } from '@vue/composition-api';
  export default {
    props: {
      block: {
        type: Boolean,
        default: true,
      },
      margin: {
        type: String,
        default: '0',
      },
      width: {
        type: String,
        default: '100%',
      },
      height: {
        type: String,
        default: 'auto',
      },
      selected: {
        type: Boolean,
        default: false,
      },
      border: {
        type: String,
        default: 'none',
      },
      borderRadius: {
        type: String,
        default: '0',
      },
      roundedOnlyTop: {
        type: Boolean,
        default: false,
      },
      backgroundColor: {
        type: String,
        default: 'white',
      },
      color: {
        type: String,
        default: 'black',
      },
      hoverBackground: {
        type: String,
        default: 'gray',
      },
      hoverColor: {
        type: String,
        default: 'white',
      },
      disabledBackground: {
        type: String,
        default: 'gray',
      },
      disabledColor: {
        type: String,
        default: 'black',
      },
      opacity: {
        type: String,
        default: 'unset',
      },
      isEditor: {
        //  PROP: 編集権限の有無
        type: Boolean,
        default: true,
      },
    },
    setup(props) {
      /**
       * COMPUTED: ボタンCSS
       */
      const buttonCSS = computed(() => {
        return {
          '--display': props.block ? 'block' : undefined,
          '--width': props.width,
          '--height': props.height,
          '--border': props.border,
          '--border-radius': props.borderRadius,
          '--background-color': props.backgroundColor,
          '--color': props.color,
          '--hover-background': props.hoverBackground,
          '--hover-color': props.hoverColor,
          '--disabled-background': props.disabledBackground,
          '--disabled-color': props.disabledColor,
          '--margin': props.margin,
          '--opacity': props.opacity,
        };
      });
      return {
        buttonCSS,
      };
    },
  };
</script>
<style scoped>
  .right-click-menu-btn {
    display: var(--display);
    width: var(--width);
    height: var(--height);
    border-radius: var(--border-radius);
    margin: var(--margin);
    padding: 0.3em 1em;
    text-decoration: none;
    color: var(--color);
    border: var(--border);
    transition: 0.05s;
    user-select: none;
    background-color: var(--background-color);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.2);
    opacity: var(--opacity);
  }

  .right-click-menu-btn:hover {
    background: var(--hover-background);
    color: var(--hover-color);
  }
  .right-click-menu-btn:disabled {
    background: var(--disabled-background);
    color: var(--disabled-color);
  }
  .right-click-menu-btn.active {
    background: var(--colorTheme);
    color: white;
  }
</style>
