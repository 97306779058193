<template>
  <g :transform="transform">
    <defs>
      <filter :id="'circle-handle-shadow-' + obj.id">
        <feDropShadow dx="3" dy="3" stdDeviation="0.2" flood-color="gray" />
      </filter>
    </defs>
    <circle
      :r="r"
      class="handle-1"
      style="pointer-events: none;"
      :style="{
        filter: `url(#circle-handle-shadow-${obj.id})`,
      }"
      stroke="white"
      stroke-width="8"
      fill="white"
    />
    <circle
      :r="r"
      class="handle-1"
      fill="transparent"
      :stroke="obj.style.stroke"
      :stroke-width="r * 0.5"
      :data-index="obj.index"
      :data-id="obj.id"
      data-area="handle"
      :data-part="obj.subType"
    />
  </g>
</template>
<script>
  // @ts-check
  import { computed, defineComponent, ref } from '@vue/composition-api';

  export default defineComponent({
    props: {
      obj: Object, // PvObject
    },
    setup() {
      const r = ref(14);
      const transform = computed(() => {
        return `scale(${1}, ${1})`;
      });
      return { transform, r };
    },
  });
</script>
<style scoped>
  .handle-1 {
    cursor: pointer;
    pointer-events: all;
  }
  .handle-1:hover {
    transition: r 0.1s ease-out;
    fill: 'pink';
    r: 16;
  }
</style>
