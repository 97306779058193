<template>
  <g
    :transform="transform"
    :transform-origin="`${origin.x} ${origin.y}`"
    style="pointer-events: none"
  >
    <path :d="d" stroke="black" stroke-width="2" fill="none" />
    <text
      :transform="`rotate(${flipText ? 180 : 0}, ${totalTextOffset.x}, ${totalTextOffset.y + 50})`"
      dominant-baseline="text-before-edge"
      :x="totalTextOffset.x"
      :y="totalTextOffset.y"
      :dy="index === 0 || index === 3 ? '1px' : '15px'"
      text-anchor="middle"
      font-size="35"
      >{{ totalText }}</text
    >
    <text
      v-for="(s, i) in scaleTextOffsets"
      dominant-baseline="text-before-edge"
      :transform="
        `rotate(${flipText ? 180 : 0}, ${scaleTextOffsets[i].x}, ${scaleTextOffsets[i].y + 20})`
      "
      :key="i"
      :x="scaleTextOffsets[i].x"
      :y="scaleTextOffsets[i].y"
      :dy="index === 0 || index === 3 ? '15px' : '0px'"
      text-anchor="middle"
      font-size="35"
    >
      {{ scalesText[i] }}
    </text>
  </g>
</template>
<script>
  // @ts-check
  import { computed, defineComponent } from '@vue/composition-api';
  import { Vec } from '../../lib/util/math/vector';
  import { LENGTH_TEXT_DIGITS_AFTER_DECIMAL_POINT } from '../../lib/util/const';

  const DIST = 100;
  const H2 = 60;

  export default defineComponent({
    props: {
      obj: Object, // PvObject
      selectedIdList: Set,
    },
    setup(props) {
      const scales = computed(() => {
        return props.obj.scales;
      });
      const intervals = computed(() => {
        return props.obj.getScaleIntervals();
      });
      const origin = computed(() => {
        return new Vec();
      });
      const leftBottom = computed(() => {
        return { x: origin.value.x, y: origin.value.y - H2 };
      });
      const index = computed(() => {
        return props.obj.index;
      });
      const isHorizontal = computed(() => {
        return props.obj.isHorizontal;
      });
      /**
       * @param {number} val
       * @returns {string}
       */
      const getLengthText = val => {
        if (val < 100) return '';
        // ピクセルを設定単位(m/mm)へ変換
        const length = val * props.obj.lengthScale;
        // 単位がmmの場合は小数点以下四捨五入、mの場合は小数点以下２位(LENGTH_TEXT_DIGITS_AFTER_DECIMAL_POINT)まで表示。
        const lenStr = props.obj.unit == 'mm'
           ? Math.round(length).toString() 
           : length.toFixed(LENGTH_TEXT_DIGITS_AFTER_DECIMAL_POINT);
        return flipText.value ? lenStr.split('').join('') : lenStr;
      };
      const width = computed(() => {
        return isHorizontal.value ? props.obj.parent?.width : props.obj.parent?.height;
      });
      const flipText = computed(() => {
        return index.value === 1 || index.value === 2;
      });
      const totalText = computed(() => {
        if (scales.value.length < 2) {
          return 0;
        }
        return getLengthText(width.value);
      });
      const totalTextOffset = computed(() => {
        if (scales.value.length < 2) {
          return { x: 0, y: 0 };
        }

        return {
          x: leftBottom.value.x + width.value / 2,
          y: leftBottom.value.y - H2 * 2 - 40,
        };
      });

      const scalesText = computed(() => {
        const inner = intervals.value.filter(
          (/** @type {number} */ v) => v !== 0 && v !== width.value
        );
        return inner.map((/** @type {number} */ s) => {
          return getLengthText(s);
        });
      });

      const scaleTextOffsets = computed(() => {
        if (scales.value.length < 2 || scales.value.length < 2) {
          return '';
        }

        const dist = props.obj.getCenterOfScales(scales.value);

        return dist.map((/** @type {number} */ d) => {
          return new Vec(d + leftBottom.value.x, leftBottom.value.y - H2);
        });
      });

      const d = computed(() => {
        if (scales.value.length < 2) {
          return '';
        }

        const d1 = `M${leftBottom.value.x}, ${leftBottom.value.y} v${-H2} h${width.value} v${H2} M${
          leftBottom.value.x
        }, ${leftBottom.value.y - H2} v${-H2} h${width.value} v${H2} `;

        const d2 = `M${leftBottom.value.x}, ${leftBottom.value.y - H2} ${intervals.value
          .map((/** @type {number} */ s) => `h${s} v${H2} v${-H2} `)
          .join('')}`;

        return d1 + d2;
      });

      const transform = computed(() => {
        switch (index.value) {
          case 0:
            return `translate(0, ${-DIST}) rotate(0)`;
          case 1:
            return `translate(${DIST},0) rotate(90)`;
          case 2:
            return `translate(0, ${DIST}) rotate(180)`;
          case 3:
            return `translate(${-DIST}, 0) rotate(270)`;
        }
      });

      return {
        d,
        transform,
        totalText,
        origin,
        totalTextOffset,
        flipText,
        scaleTextOffsets,
        scales,
        index,
        scalesText,
      };
    },
  });
</script>
