<template>
  <image
    href="https://allys3163848-dev.s3.ap-northeast-1.amazonaws.com/Master/planeView/common/water-mark.png"
    width="1000"
    height="1000"
  />
</template>
<script>
  import { defineComponent } from '@vue/composition-api';

  export default defineComponent({
    props: {
      isShown: {
        type: Boolean,
        required: true,
      },
    },
    setup() {},
  });
</script>
