// @ts-check

import { Action } from '../../action/action';
import { EventPresetBuilder } from '../eventPresetBuilder';
import { EventPreset } from '../eventPreset';

export class GlobalPreset extends EventPresetBuilder {
  build() {
    const { context, actionManager } = this;
    const preset = new EventPreset();
    const { getCurrentPos, getRoot, getGuideRoot, showContextMenu } = context;

    preset.mousemove = dataset => {
      if (!this.actionManager.isDisabled()) {
        this.actionManager.update(getCurrentPos(), dataset);
      }
    };

    preset.rightclick = dataset => {
      const current = getCurrentPos();
      actionManager.rollback(getCurrentPos(), dataset);
      if (context.getGlobalSettings().mode !== 'select') {
        this.actionManager.rollback(current, dataset);
      } else {
        showContextMenu();
      }
    };

    preset.click = dataset => {
      if (
        !this.actionManager.isDisabled() &&
        dataset.button === 'left' &&
        dataset.target !== 'outside'
      ) {
        this.actionManager.advance(getCurrentPos(), dataset);
      }
      // 共通
      if (dataset.button === 'left' || dataset.button == null) {
        // tagetのタイプ
        if (dataset.target === 'object') {
          // 処理なし
        } else if (dataset.target === 'layer') {
          getRoot().unselectAll();
        } else if (dataset.target === 'outside') {
          return;
        }
      } else if (
        dataset.button === 'right' &&
        this.actionManager.actionType() !== Action.ACTION_TYPE.MULTI_CLICK
      ) {
        // アクションキャンセル
        this.actionManager.disable();
        getGuideRoot().removeChildrenAll();
      }
    };

    preset.dragstart = dataset => {
      if (
        !this.actionManager.isDisabled() &&
        this.actionManager.actionType() === Action.ACTION_TYPE.DRAG_AND_DROP
      ) {
        this.actionManager.advance(getCurrentPos(), dataset);
      }
    };

    preset.dragend = dataset => {
      if (
        !this.actionManager.isDisabled() &&
        this.actionManager.actionType() === Action.ACTION_TYPE.DRAG_AND_DROP
      ) {
        this.actionManager.advance(getCurrentPos(), dataset);
      }
    };

    preset.mouseleave = () => {};

    return preset;
  }
}
