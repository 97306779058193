var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'scrollbar-base': true,
    transparent: _vm.transparent || _vm.dragging,
  },staticStyle:{"background-color":"white"},style:({
    width: _vm.horizontal ? _vm.length + 'px' : _vm.THUMB_WIDTH + 'px',
    height: _vm.horizontal ? _vm.THUMB_WIDTH + 'px' : _vm.length + 'px',
  })},[_c('button',{style:({
      position: 'absolute',
      right: 0,
      width: _vm.horizontal ? _vm.THUMB_HEIGHT + 'px' : _vm.THUMB_WIDTH + 'px',
      height: _vm.horizontal ? _vm.THUMB_WIDTH + 'px' : _vm.THUMB_HEIGHT + 'px',
      'border-radius': '10px',
      'background-color': 'var(--colorTheme)',
      top: _vm.horizontal ? '0' : _vm.position + 'px',
      left: _vm.horizontal ? _vm.position + 'px' : '0',
      cursor: 'move',
      border: '0',
      padding: _vm.THUMB_PADDING / 2 + 'px',
    }),on:{"touchstart":_vm.dragstart,"touchmove":function($event){$event.preventDefault();return _vm.move.apply(null, arguments)},"mousedown":_vm.dragstart,"mousemove":function($event){$event.preventDefault();return _vm.move.apply(null, arguments)},"mouseover":function($event){_vm.transparent = true},"mouseleave":function($event){_vm.transparent = false}}})])}
var staticRenderFns = []

export { render, staticRenderFns }