import store from '../../store';

const PRIVILEGED = 'privileged'; // 非決済モード

export const getItemsByGroup = (items, group) => {
  if (group == null) {
    return [];
  }

  return (
    items.filter(item => {
      return item.groups.find(v => v === group);
    }) || []
  );
};

// 項目取得（ダッシュボード・サイドバー用、ライセンス考慮）
export const getMenuItems = (items, group, functions) => {
  let results = getItemsByGroup(items, group);
  for (let item of results) {
    let disabled = false;
    // NOTE: ライセンスが無い場合、非活性
    if (item.functionNumber != undefined && functions != null) {
      disabled = !functions.some(v => {
        return v == item.functionNumber;
      });
    }
    // 項目にdisabledGroupsがある場合
    if (item.disabledGroups != undefined) {
      disabled = item.disabledGroups.some(v => {
        // NOTE: 非決済モードの場合、disabledGroupsにPRIVILEGEDがある項目は非活性
        if (store.state.privilegedFlag) {
          return v == PRIVILEGED;
        }
        // それ以外は現状の値を返す
        else {
          return disabled;
        }
      });
    }
    item.disabled = disabled;
  }
  return results;
};

let isProcessing = false;
export const checkLinkItem = item => {
  // 連打無効
  if (isProcessing) return;

  isProcessing = true;
  const to = item.to ?? '';
  if (item.disabled) {
    isProcessing = false;
    return false;
  }
  // メールを開く場合
  else if (to.startsWith('mailto:')) {
    location.href = to;
    isProcessing = false;
    return false;
  }
  // スクリプト実行
  else if (item.exec) {
    item.exec();
    isProcessing = false;
    return false;
  } else {
    isProcessing = false;
  }

  isProcessing = false;
  return true;
};

export const commonItems = [
  // {
  //   icon: 'ti-dashboard',
  //   text: 'メインページ',
  //   to: '/DashBoard',
  //   groups: ['admin', 'member'],
  // },
  {
    icon: 'ti-star',
    text: '顧客管理',
    to: '/Customer',
    groups: ['admin', 'member'],
    line: 2,
    column: 0,
  },
  {
    icon: 'ti-home',
    text: '物件管理',
    to: '/Property/List',
    groups: ['admin', 'member'],
    line: 2,
    column: 1,
  },
  {
    icon: 'ti-user',
    text: 'アカウント管理',
    to: '/Account',
    groups: ['admin'],
    line: 3,
    column: 0,
  },
  {
    icon: 'ti-id-badge',
    text: 'ライセンス管理',
    to: '/License',
    groups: ['admin'],
    line: 3,
    column: 1,
    disabledGroups: [PRIVILEGED],
  },
  {
    icon: 'ti-desktop',
    text: '自社情報管理',
    to: '/MyCompany/View/',
    groups: ['admin'],
    line: 3,
    column: 2,
  },
];

export const realEstateItems = [
  {
    icon: 'icon-real-estate-portfolio',
    text: 'ALLY 不動産ポートフォリオ分析',
    to: '/ROA/Main',
    groups: ['admin', 'member'],
    line: 1,
    column: 0,
    functionNumber: 5,
  },
  {
    icon: 'icon-real-estate-application',
    text: 'ALLY 不動産活用診断',
    to: '/RealEstateSuitability/Main',
    groups: ['admin', 'member'],
    line: 1,
    column: 1,
    functionNumber: 4,
  },
  {
    icon: 'fas fa-hand-holding-usd',
    text: 'ALLY 相続シミュレーション',
    to: '/InheritanceTax',
    groups: ['admin', 'member'],
    line: 1,
    column: 2,
    functionNumber: 6,
  },
  {
    icon: 'fas fa-chart-line',
    text: 'ALLY 不動産投資分析',
    to: '/InvestmentAnalytics/Main',
    groups: ['admin', 'member'],
    line: 1,
    column: 3,
    functionNumber: 3,
  },
  {
    icon: 'ti-files',
    text: 'レポート管理',
    to: '/Report/List',
    groups: ['admin', 'member'],
    line: 1,
    column: 4,
  },
];

export const propertyItems = [
  {
    icon: 'icon-image-simulator',
    text: '<div>ALLY フォト</div><div>（画像シミュレーター）</div>',
    to: '/Property/new/WallPaperSimulator/Edit/new',
    groups: ['admin', 'member'],
    line: 0,
    column: 0,
    functionNumber: 8,
  },
  {
    icon: 'icon-ar-simulator',
    text: 'ALLY - ARシミュレーター',
    exec: () => {
      store.dispatch('startFurnitureSimulator');
    },
    groups: ['admin', 'member'],
    line: 0,
    column: 1,
    functionNumber: 10,
  },
  {
    icon: 'ti-pencil-alt',
    text: '<div>ALLY オートスケッチ</div><div>（部屋計測・間取図自動作成）</div>',
    to: '/PlaneView/Edit',
    groups: ['admin', 'member'],
    line: 0,
    column: 2,
    functionNumber: 9,
  },
];

export const companyInfoItems = [
  {
    icon: 'ti-desktop',
    text: '会社情報管理',
    to: '/Company',
    groups: ['systemAdmin'],
  },
  {
    icon: 'ti-alert',
    text: '会社削除情報管理',
    to: '/DeleteCompany',
    groups: ['systemAdmin'],
  },
  {
    icon: 'ti-clipboard',
    text: '売上一覧表',
    to: '/SalesList/View',
    groups: ['systemAdmin'],
  },
];

export const helpItems = [
  {
    icon: 'icon-manual',
    text: 'マニュアル・規約',
    to: '/Manual',
    groups: ['admin', 'member'],
    line: 3,
    column: 3,
  },
  {
    icon: 'ti-email',
    text: 'お問い合わせ',
    to: 'mailto:ally@dynastage.co.jp',
    groups: ['admin', 'member'],
    line: 3,
    column: 4,
  },
];
