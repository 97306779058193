// @ts-check
import { computed, reactive, ref, watch } from '@vue/composition-api';
import { createNode } from '../node/node/pvNode';
import { PvObject } from '../node/node/pvObject';
import { PvPattern } from '../node/node/pvPattern';
import { PvRootPattern } from '../node/node/pvRootPattern';
import { PvRoot } from '../node/node/rootNode';
import { composeTree } from '../node/tools/composeTree';
import { cloneNode } from '../node/tools/clone';
import { makeToast } from '../util/util';
import { MESSAGE_TEXT, SAVE_LIMIT } from '../util/const';
// import { CONSTRUCTOR_MAP } from '../node/tools/constructorMap';

/**
 * METHOD: ルート生成
 * @param {number} rootNum
 * @returns {PvRoot[]}
 */
const createLayersRoot = rootNum => {
  return /** @type {PvRoot[]} */ (new Array(rootNum)
    .fill(null)
    .map(() => reactive(createNode(PvRoot))));
};

export const useTree = (guide = false) => {
  // パターンのインデックス
  const patternIndex = ref(0);
  // 階層のインデックス
  const layerIndex = ref(0);
  // ルートの配列を作る
  const displayKey = ref(new Date().toISOString());

  // ルートの配列を作る
  const layersRoot = createLayersRoot(1);

  // パターン(ルートの二次元配列）を作る
  const layerPatterns = reactive(
    /** @type {PvRootPattern[]} */ (new Array(1)
      .fill(null)
      .map(() => new PvRootPattern(layersRoot)))
  );

  /**
   * METHOD: 選択中のパターンから、任意のルートを取得
   * @param {number} layerIndex // 任意のインデックス
   * @returns
   */
  const getRoot = layerIndex => {
    return layerPatterns[patternIndex.value].layersRoot[layerIndex];
  };

  /** @type {import("@vue/composition-api").Ref<PvRoot>} 指定されたインデックスのルート */
  // @ts-ignore
  const root = ref(getRoot(layerIndex.value));

  /** @type {import("@vue/composition-api").Ref<PvRoot>|null} 指定されたインデックスの下階のルート */
  // @ts-ignore
  const lowerRoot = ref(null);

  /**
   * WATCH: ルート及び下層ルートを更新する
   * 選択中のパターンインデックス、選択中の階層インデックス、UI更新用keyを監視
   */
  watch(
    [patternIndex, layerIndex, displayKey],
    () => {
      if (
        patternIndex.value > layerPatterns.length - 1 ||
        layerIndex.value > layerPatterns[patternIndex.value].layersRoot.length - 1
      ) {
        return;
      }
      // ルート更新
      // @ts-ignore
      root.value = getRoot(layerIndex.value);

      // 下層ルート更新（階層インデックスが0なら表示しないためnull）
      // @ts-ignore
      lowerRoot.value = layerIndex.value == 0 ? null : getRoot(layerIndex.value - 1);
    },
    { deep: true }
  );

  /**
   * ルートからオブジェクトの配列を取得
   * @param {*} rootObject
   * @returns
   */
  const getObjectTree = rootObject => {
    if (!rootObject) {
      return null;
    }
    return rootObject.flatWithoutRoot();
  };

  /** @type {import("@vue/composition-api").ComputedRef<PvObject[]>} 現在のオブジェクトの配列 */
  const objectTree = computed(() => {
    return getObjectTree(root.value);
  });

  /** @type {import("@vue/composition-api").ComputedRef<PvObject[]>} 下階のオブジェクトの配列 */
  const lowerObjectTree = computed(() => {
    return lowerRoot ? getObjectTree(lowerRoot.value) : null;
  });

  /**
   * METHOD: パターンごとの平面図の配列を読み込む
   * @param {PvPattern[]} patterns
   */
  const loadTree = patterns => {
    for (let i = 0; i < patterns.length; i++) {
      layerPatterns[i] = new PvRootPattern(createLayersRoot(patterns[i].layers.length));
      layerPatterns[i].name = patterns[i].name;

      for (let i2 = 0; i2 < patterns[i].layers.length; i2++) {
        const newLayerNodes = patterns[i].layers[i2];
        layerPatterns[i].layersRoot[i2].removeChildrenAll();
        const newRoot = cloneNode(composeTree(newLayerNodes));
        const children = cloneNode(newRoot).children;
        children.forEach(n => {
          layerPatterns[i].layersRoot[i2].appendChild(cloneNode(n));
        });
      }
    }
  };

  /**
   * METHOD: 階層更新
   * @param {{
   * layerNum: number // 階数
   * }} val
   */
  const updateLayers = (/** @type {{ layerNum: number; }} */ val) => {
    // 値が制限内ではない場合
    if (!(1 <= val.layerNum && val.layerNum <= SAVE_LIMIT.LAYER)) {
      // トースト表示
      makeToast(MESSAGE_TEXT.LAYER_ERROR_LIMIT);
      // 処理しない
      return;
    }
    // 既に指定された階数の場合
    else if (val.layerNum == layerPatterns[patternIndex.value].layersRoot.length) {
      // トースト表示
      makeToast(MESSAGE_TEXT.LAYER_ERROR_SAME_LENGTH);
      // 処理しない
      return;
    }

    // 差分取得
    const lengthDiff = val.layerNum - layerPatterns[patternIndex.value].layersRoot.length;

    // 差分が増加の場合
    if (0 < lengthDiff) {
      for (let index = 0; index < lengthDiff; index++) {
        // 差分の数だけ配列を追加する
        layerPatterns[patternIndex.value].layersRoot.push(createNode(PvRoot));
      }
    }

    // 差分が減少の場合
    else if (lengthDiff < 0) {
      // 確認アラートを表示
      if (!confirm(MESSAGE_TEXT.LAYER_WARNING_DELETE)) {
        // 処理しない
        return;
      }
      // 差分の数だけ配列を削除する
      layerPatterns[patternIndex.value].layersRoot.splice(val.layerNum, Math.abs(lengthDiff));
    }

    // トースト表示
    makeToast('階層を' + val.layerNum + '階層へ変更しました', 'primary');
    // 最上階をセット
    layerIndex.value = layerPatterns[patternIndex.value].layersRoot.length - 1;
    // UIのKeyを更新し再描画
    updateDisplayKey();
  };

  /**
   * METHOD: パターン追加
   * @param {{
   * name: string // パターン名
   * }} val
   */
  const addPattern = (/** @type {{ name: string; }} */ val) => {
    // 現在のパターン数が上限以上の場合
    if (SAVE_LIMIT.PATTERN <= layerPatterns.length) {
      // トースト表示
      makeToast(MESSAGE_TEXT.PATTERN_ERROR_LIMIT);
      // 処理しない
      return;
    }
    // 値が無い場合
    else if (!val.name) {
      // トースト表示
      makeToast('パターンを追加できませんでした\nパターン名「' + val.name + '」は無効です');
      // 処理しない
      return;
    }
    // 表示中のパターンをクローン
    const clone = new PvRootPattern(
      // @ts-ignore
      layerPatterns[patternIndex.value].layersRoot.map(root => cloneNode(root))
    );
    // 名称に入力値をセット
    clone.name = val.name;
    // 平面図データに追加
    layerPatterns.push(clone);
    // トースト表示
    makeToast(MESSAGE_TEXT.PATTERN_TEXT_ADD, 'primary');
    // 最後のインデックスをセット（表示更新）
    patternIndex.value = layerPatterns.length - 1;
  };

  /**
   * METHOD: パターン名変更
   * @param {{
   * index: number // 指定インデックス
   * newName: string // パターン名
   * }} val
   */
  const renamePattern = (/** @type {{ index: number; newName: string; }} */ val) => {
    // 設定されたインデックスが配列に存在しない場合
    if (!(0 <= val.index && val.index < layerPatterns.length)) {
      // トースト表示
      makeToast(MESSAGE_TEXT.PATTERN_ERROR_NEWNAME);
      // 処理しない
      return;
    } else if (!val.newName) {
      // トースト表示
      makeToast('パターン名を変更できませんでした\nパターン名「' + val.newName + '」は無効です');
      // 処理しない
      return;
    }
    // 指定されたパターンの名称に入力値をセット
    layerPatterns[val.index].name = val.newName;
    // トースト表示
    makeToast(MESSAGE_TEXT.PATTERN_TEXT_NEWNAME, 'primary');
    // UIのKeyを更新し再描画
    updateDisplayKey();
  };

  /**
   * METHOD: パターン削除
   * @param {{
   * index: number // 指定インデックス
   * }} val
   */
  const splicePatterns = (/** @type {{ index: number; }} */ val) => {
    // 設定されたインデックスが配列に存在しない場合
    if (layerPatterns.length - 1 < val.index) {
      // トースト表示
      makeToast(MESSAGE_TEXT.PATTERN_ERROR_DELETE);
      // 処理しない
      return;
    } else if (val.index == 0 && layerPatterns.length == 1) {
      // トースト表示
      makeToast(MESSAGE_TEXT.PATTERN_ERROR_DELETE_ALL);
      // 処理しない
      return;
    }

    // 確認アラートを表示
    if (!confirm('パターン名「' + layerPatterns[val.index].name + '」を削除してもよろしいですか')) {
      return;
    }

    // 指定インデックスのパターンを削除
    layerPatterns.splice(val.index, 1);

    // トースト表示
    makeToast(MESSAGE_TEXT.PATTERN_TEXT_DELETE, 'primary');

    // 選択中パターンのインデックスが削除後に存在しない場合
    if (!(0 <= patternIndex.value && patternIndex.value <= layerPatterns.length - 1)) {
      // 最後のインデックスをセット
      patternIndex.value = layerPatterns.length - 1;
    }
    // UIのKeyを更新し再描画
    updateDisplayKey();
  };

  /**
   * METHOD: UIのKeyに現在の日時を文字列としてセット
   * UIの再描画に利用
   */
  const updateDisplayKey = () => {
    displayKey.value = new Date().toISOString();
  };

  return {
    objectTree,
    lowerObjectTree,
    root,
    lowerRoot,
    layerIndex,
    layersRoot,
    patternIndex,
    layerPatterns,
    displayKey,

    loadTree,
    addPattern,
    renamePattern,
    splicePatterns,
    updateLayers,
  };
};
