<template>
  <div>
    <div style="display: flex; font-size: 0.9rem">
      <TileBtn
        :block="false"
        width="50%"
        height="30px"
        :selected="objectPreset.subType !== 'erase'"
        @click="changeType(DEFAULT_WALL_TYPE)"
      >
        <i class="ti-pencil-alt2"></i>
      </TileBtn>
      <TileBtn
        :block="false"
        width="50%"
        height="30px"
        :selected="objectPreset.subType === 'erase'"
        @click="changeType('erase')"
      >
        <i class="ti-eraser"></i>
      </TileBtn>
    </div>
    <div style="padding: 10px">
      <div>
        <div class="pv-config-label">厚み(px)</div>
        <div>
          <input
            type="number"
            min="0"
            max="1000"
            style="width: 100%; text-align: center"
            :value="objectPreset.style.strokeWidth"
            @change="e => $emit('changeStrokeWidth', parseFloat(e.srcElement.value))"
          />
        </div>
        <div class="pv-config-label">高さ(m)</div>
        <div>
          <input
            type="number"
            min="0"
            max="10"
            step="0.1"
            style="width: 100%; text-align: center"
            :value="objectPreset.height3d.toFixed(2)"
            @change="e => $emit('changeHeight3d', parseFloat(e.srcElement.value))"
          />
        </div>
        <div class="pv-config-label">壁タイプ</div>
        <div>
          <select
            type="number"
            style="width: 100%"
            :checked="objectPreset.style.stroke"
            @change="e => changeType(e.srcElement.value)"
          >
            <option v-for="item in wallTypeList" :key="item.name" :value="item.name">
              {{ item.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // @ts-check
  import { defineComponent, ref } from '@vue/composition-api';
  import { DEFAULT_WALL_TYPE, WALL_TYPES } from '../../../lib/util/const';
  import TileBtn from '../PvBtn.vue';

  export default defineComponent({
    components: {
      TileBtn,
    },
    props: {
      objectPreset: Object, // PvObject
    },
    setup(_, context) {
      const changeType = (/** @type {string} */ mode) => {
        context.emit('changeWallType', mode);
      };

      const wallTypeList = ref(WALL_TYPES);

      return { changeType, DEFAULT_WALL_TYPE, wallTypeList };
    },
  });
</script>
