// @ts-check

import { onMounted, reactive, ref } from '@vue/composition-api';
import { PvObject } from '../node/node/pvObject';
import { PvRootPattern } from '../node/node/pvRootPattern';
import { PvRoot } from '../node/node/rootNode';
import { cloneNode } from '../node/tools/clone';

/**
 * @param {PvRootPattern[]} layerPatterns
 * @param {import("@vue/composition-api").Ref<number>} patternIndex
 * @param {import("@vue/composition-api").Ref<number>} layerIndex
 */
export const useHistoryController = (layerPatterns, patternIndex, layerIndex) => {
  // 履歴にはパターンごとに保存する
  // _history[パターン][カーソル][レイヤー][ノード]
  /** @type {PvObject[][][][]} */
  const _history = reactive([]);

  // カーソルもパターンごとに保存する
  // cursor[パターン]
  /** @type {import("@vue/composition-api").Ref<number>[]} */
  const cursor = reactive([]);

  // 保存するときのレイヤーインデックスを履歴と同時に保存する
  // レイヤーが削除されたときに履歴から該当のものを削除するときに使う
  // _operation[パターン][カーソル].layerIndex
  /** @type {{layerIndex: number}[][]} */
  const _operation = reactive([]);

  const redo = () => {
    if (cursor[patternIndex.value].value === _history[patternIndex.value].length - 1) {
      return;
    }
    cursor[patternIndex.value].value += 1;

    const objs = _history[patternIndex.value][cursor[patternIndex.value].value];

    if (objs == null) {
      return;
    }

    // 全レイヤーを作り直す
    for (let i = 0; i < layerPatterns[patternIndex.value].layersRoot.length; i++) {
      layerPatterns[patternIndex.value].layersRoot[i].removeChildrenAll();
      if (objs[i]) {
        objs[i].forEach(o => {
          layerPatterns[patternIndex.value].layersRoot[i].appendChild(cloneNode(o));
        });
      }
    }
  };

  const undo = () => {
    if (cursor[patternIndex.value].value === 0) {
      return;
    }
    cursor[patternIndex.value].value -= 1;

    const objs = _history[patternIndex.value][cursor[patternIndex.value].value];

    // 全レイヤーを作り直す
    for (let i = 0; i < layerPatterns[patternIndex.value].layersRoot.length; i++) {
      layerPatterns[patternIndex.value].layersRoot[i].removeChildrenAll();
      if (objs[i]) {
        objs[i].forEach(o => {
          layerPatterns[patternIndex.value].layersRoot[i].appendChild(cloneNode(o));
        });
      }
    }
  };

  const saveHistory = (val = -1) => {
    let index = 0;
    if (val == -1) {
      index = patternIndex.value;
    } else {
      index = val;
    }

    if (_history.length == index) {
      _history.push([]);
      _operation.push([]);
      cursor.push(ref(-1));
    }

    if (_history[index].length - 1 > cursor[index].value) {
      _history[index].splice(cursor[index].value + 1);
      _operation[index].splice(cursor[index].value + 1);
    }

    // console.log(`SaveHistory parttern=${index} / layer=${layerIndex.value}`);

    const object = createObject(index);
    // object とパターンインデックス、レイヤーインデックスを履歴に保存する
    _history[index].push(object);
    _operation[index].push({ layerIndex: layerIndex.value });
    cursor[index].value += 1;
  };

  /**
   * METHOD: 全レイヤーを object に保存する
   * @param {number} index // パターンインデックス
   */
  const createObject = index => {
    const object = new Array(layerPatterns[index].layersRoot.length);
    for (let i = 0; i < layerPatterns[index].layersRoot.length; i++) {
      const children = layerPatterns[index].layersRoot[i].children;
      const root = children.map(c => cloneNode(c));
      object[i] = [];
      for (let j = 0; j < root.length; j++) {
        object[i].push(root[j]);
      }
    }
    return object;
  };

  /**
   * METHOD: 履歴から該当するパターンを削除する
   * @param {number} index // パターンインデックス
   */
  const deletePattern = index => {
    _operation.splice(index, 1);
    _history.splice(index, 1);
    cursor.splice(index, 1);
  };

  /**
   * METHOD: 履歴から該当するパターンのレイヤーを削除する
   * @param {number} patternIndex // パターンインデックス
   * @param {number} index        // レイヤーインデックス
   */
  const deleteLayer = (patternIndex, index) => {
    for (let i = 0; i < _operation[patternIndex].length; i++) {
      if (_operation[patternIndex][i].layerIndex === index) {
        _operation[patternIndex].splice(i, 1);
        _history[patternIndex].splice(i, 1);
        cursor[patternIndex].value--;
        i--;
      }
    }
  };

  onMounted(() => {
    saveHistory();
  });

  return {
    redo,
    undo,
    saveHistory,
    deletePattern,
    deleteLayer,
  };
};

/** @typedef {ReturnType<typeof useHistoryController>} HistoryController */
