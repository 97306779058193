<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：単年度収支計画
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="SingleYearPlan">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button
          id="btnSingleYearPlan"
          variant="link"
          v-b-toggle.tabSingleYearPlan
          @click="viewFlag = true"
        >
          単年度収支計画
        </b-button>
      </b-card-header>
      <b-collapse id="tabSingleYearPlan" role="tabpanel" v-if="viewFlag">
        <b-card-body>
          <b-card-group columns style="column-count:1;">
            <b-card no-body class="category">
              <b-card-header role="tab">
                <b-button id="btnSingleYearPlan1" variant="link" v-b-toggle.tabSingleYearPlan1>
                  収入の部
                </b-button>
              </b-card-header>
              <b-collapse id="tabSingleYearPlan1" role="tabpanel" visible v-if="lazLoading[0]">
                <b-card-body>
                  <b-card-group columns class="detail-columns" id="groupSingleYearPlan1">
                    <b-card no-body style="margin:0;padding:0;border:none;box-shadow:none;">
                      <b-card-group class="columns" columns style="column-count:1;">
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              id="btnSingleYearPlan11"
                              variant="link"
                              v-b-toggle.tabSingleYearPlan11
                            >
                              賃料
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSingleYearPlan11" role="tabpanel" v-if="lazLoading[1]">
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="2" class="title bold">種別</b-col>
                                  <b-col cols="2" class="title-center bold" style="padding:0;">
                                    課税分/非課税分
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">月間賃料合計</b-col>
                                      <b-col cols="1" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center">建物小計</b-col>
                                      <b-col cols="1" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row
                                  v-for="id of Number(investmentAnalytics.existingBuildingCount)"
                                  v-bind:key="`rent1-${id}`"
                                >
                                  <b-col cols="2" class="title">既存建物{{ id }}</b-col>
                                  <b-col cols="2" class="title-center">
                                    <b-row>
                                      <b-col style="padding:0;">
                                        課税分
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col style="padding:0;">
                                        非課税分
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'taxation' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan.rent[
                                              id - 1
                                            ].taxation
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'taxExempt' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan.rent[
                                              id - 1
                                            ].taxExempt
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col class="title">
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.singleYearPlan.incomePlan.rent[
                                                id - 1
                                              ].taxation
                                            ) +
                                              Math.floor(
                                                investmentAnalytics.singleYearPlan.incomePlan.rent[
                                                  id - 1
                                                ].taxExempt
                                              ))
                                              | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row
                                  v-for="id of Number(investmentAnalytics.newBuildingCount)"
                                  v-bind:key="`rent2-${id}`"
                                >
                                  <b-col cols="2" class="title">新築建物{{ id }}</b-col>
                                  <b-col cols="2" class="title-center">
                                    <b-row>
                                      <b-col style="padding:0;">
                                        課税分
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col style="padding:0;">
                                        非課税分
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'taxationNew' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan.rent[
                                              10 + id - 1
                                            ].taxation
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'taxExemptNew' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan.rent[
                                              10 + id - 1
                                            ].taxExempt
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col class="title">
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.singleYearPlan.incomePlan.rent[
                                                10 + id - 1
                                              ].taxation
                                            ) +
                                              Math.floor(
                                                investmentAnalytics.singleYearPlan.incomePlan.rent[
                                                  10 + id - 1
                                                ].taxExempt
                                              ))
                                              | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="borderline" />
                                </b-row>
                                <b-row>
                                  <b-col class="title-right">
                                    合計
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="rentTotal | currency"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                                <b-row>
                                  <b-col class="title-right"> 消費税 </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="tax(rentTotalTaxation) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              id="btnSingleYearPlan12"
                              variant="link"
                              v-b-toggle.tabSingleYearPlan12
                            >
                              共益費
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSingleYearPlan12" role="tabpanel" v-if="lazLoading[2]">
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="2" class="title bold">種別</b-col>
                                  <b-col cols="2" class="title-center bold" style="padding:0;">
                                    課税分/非課税分
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">月間共益費合計</b-col>
                                      <b-col cols="1" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center">建物小計</b-col>
                                      <b-col cols="1" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row
                                  v-for="id of Number(investmentAnalytics.existingBuildingCount)"
                                  v-bind:key="`commonServiceFees1-${id}`"
                                >
                                  <b-col cols="2" class="title">既存建物{{ id }}</b-col>
                                  <b-col cols="2" class="title-center">
                                    <b-row>
                                      <b-col style="padding:0;">
                                        課税分
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col style="padding:0;">
                                        非課税分
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'commonServiceFees-taxation' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan
                                              .commonServiceFees[id - 1].taxation
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'commonServiceFees-taxExempt' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan
                                              .commonServiceFees[id - 1].taxExempt
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col class="title">
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.singleYearPlan.incomePlan
                                                .commonServiceFees[id - 1].taxation
                                            ) +
                                              Math.floor(
                                                investmentAnalytics.singleYearPlan.incomePlan
                                                  .commonServiceFees[id - 1].taxExempt
                                              ))
                                              | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row
                                  v-for="id of Number(investmentAnalytics.newBuildingCount)"
                                  v-bind:key="`commonServiceFees2-${id}`"
                                >
                                  <b-col cols="2" class="title">新築建物{{ id }}</b-col>
                                  <b-col cols="2" class="title-center">
                                    <b-row>
                                      <b-col style="padding:0;">
                                        課税分
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col style="padding:0;">
                                        非課税分
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'commonServiceFees-taxation' + (id + 10)"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan
                                              .commonServiceFees[10 + id - 1].taxation
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'commonServiceFees-taxExempt' + (id + 10)"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan
                                              .commonServiceFees[10 + id - 1].taxExempt
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col class="title">
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.singleYearPlan.incomePlan
                                                .commonServiceFees[10 + id - 1].taxation
                                            ) +
                                              Math.floor(
                                                investmentAnalytics.singleYearPlan.incomePlan
                                                  .commonServiceFees[10 + id - 1].taxExempt
                                              ))
                                              | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="borderline" />
                                </b-row>
                                <b-row>
                                  <b-col class="title-right">
                                    合計
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="commonServiceFeesTotal | currency"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                                <b-row>
                                  <b-col class="title-right">
                                    消費税
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="tax(commonServiceFeesTotalTaxation) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </b-card-group>
                    </b-card>
                    <b-card no-body style="margin:0;padding:0;border:none;box-shadow:none;">
                      <b-card-group class="columns" columns style="column-count:1;">
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              id="btnSingleYearPlan13"
                              variant="link"
                              v-b-toggle.tabSingleYearPlan13
                            >
                              駐車料
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSingleYearPlan13" role="tabpanel" v-if="lazLoading[3]">
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col class="title-center bold">
                                    課税分/非課税分
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">台数</b-col>
                                      <b-col cols="1" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">月間駐車料</b-col>
                                      <b-col cols="1" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">合計</b-col>
                                      <b-col cols="1" />
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row
                                  v-for="id of investmentAnalytics.singleYearPlan.incomePlan
                                    .parkingFees.length"
                                  v-bind:key="`parkingFees1-${id}`"
                                >
                                  <b-col class="title-center">
                                    <b-row>
                                      <b-col style="padding:0;">
                                        課税分
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col style="padding:0;">
                                        非課税分
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'parkingFees-taxationNumber' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan
                                              .parkingFees[id - 1].taxationNumber
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">台</b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'parkingFees-taxExemptNumber' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan
                                              .parkingFees[id - 1].taxExemptNumber
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">台</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'parkingFees-taxation' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan
                                              .parkingFees[id - 1].taxation
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'parkingFees-taxExempt' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan
                                              .parkingFees[id - 1].taxExempt
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.singleYearPlan.incomePlan
                                                .parkingFees[id - 1].taxation
                                            ) *
                                              Math.floor(
                                                investmentAnalytics.singleYearPlan.incomePlan
                                                  .parkingFees[id - 1].taxationNumber
                                              ))
                                              | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.singleYearPlan.incomePlan
                                                .parkingFees[id - 1].taxExempt
                                            ) *
                                              Math.floor(
                                                investmentAnalytics.singleYearPlan.incomePlan
                                                  .parkingFees[id - 1].taxExemptNumber
                                              ))
                                              | currency
                                          "
                                        />
                                      </b-col>

                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="borderline" />
                                </b-row>
                                <b-row>
                                  <b-col cols="9" class="title-right">
                                    合計
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="parkingFeesTotal | currency"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="9" class="title-right">
                                    消費税
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="tax(parkingFeesTotalTaxation) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              variant="link"
                              v-b-toggle.tabSingleYearPlan15
                              id="btnSingleYearPlan15"
                            >
                              土地賃貸料
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSingleYearPlan15" role="tabpanel" v-if="lazLoading[4]">
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col class="title-center bold">
                                    課税分/非課税分
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold">月間土地賃貸料合計</b-col>
                                      <b-col cols="1" />
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                                <b-row
                                  v-for="id of investmentAnalytics.singleYearPlan.incomePlan
                                    .landRentalFees.length"
                                  v-bind:key="`landRentalFees1-${id}`"
                                >
                                  <b-col class="title-center">
                                    <b-row>
                                      <b-col style="padding:0;">
                                        課税分
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col style="padding:0;">
                                        非課税分
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'land-taxation' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan
                                              .landRentalFees[id - 1].taxation
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'land-taxExempt' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan
                                              .landRentalFees[id - 1].taxExempt
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>

                                <b-row>
                                  <b-col cols="8" class="borderline" />
                                </b-row>
                                <b-row>
                                  <b-col class="title-right">
                                    合計
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="landRentalFeesTotal | currency"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                                <b-row>
                                  <b-col class="title-right">
                                    消費税
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="tax(landRentalFeesTotalTaxation) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              variant="link"
                              v-b-toggle.tabSingleYearPlan14
                              id="btnSingleYearPlan14"
                            >
                              その他
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSingleYearPlan14" role="tabpanel" v-if="lazLoading[5]">
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="3" class="title bold">項目</b-col>
                                  <b-col cols="2" class="title-center bold" style="padding:0;">
                                    課税分/非課税分
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center bold" style="padding:0;">
                                        月間売上合計
                                      </b-col>
                                      <b-col cols="1" class="unit" />
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="title-center">小計</b-col>
                                      <b-col cols="1" class="unit" />
                                    </b-row>
                                  </b-col>
                                  <b-col cols="1" />
                                </b-row>
                                <b-row
                                  v-for="id of investmentAnalytics.singleYearPlan.incomePlan.others
                                    .length"
                                  v-bind:key="`others1-${id}`"
                                >
                                  <b-col cols="3" class="title">
                                    <allyText
                                      name="itemNames"
                                      v-model="
                                        investmentAnalytics.singleYearPlan.incomePlan.itemNames[
                                          id - 1
                                        ]
                                      "
                                      placeholder="項目名を入力してください"
                                    />
                                  </b-col>
                                  <b-col cols="2" class="title-center">
                                    <b-row>
                                      <b-col style="padding:0;">
                                        課税分
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col style="padding:0;">
                                        非課税分
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'others-taxation' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan.others[
                                              id - 1
                                            ].taxation
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'others-taxExempt' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.incomePlan.others[
                                              id - 1
                                            ].taxExempt
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col class="title">
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.singleYearPlan.incomePlan.others[
                                                id - 1
                                              ].taxation
                                            ) +
                                              Math.floor(
                                                investmentAnalytics.singleYearPlan.incomePlan
                                                  .others[id - 1].taxExempt
                                              ))
                                              | currency
                                          "
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col cols="1" class="title">
                                    <b-button
                                      variant="ally"
                                      size="sm"
                                      v-if="id > 1"
                                      @click="deleteItem(id - 1)"
                                    >
                                      <i class="ti-minus" />
                                    </b-button>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col cols="5" />
                                  <b-col />
                                  <b-col cols="1">
                                    <b-button variant="ally" size="sm" @click="addItem">
                                      <i class="ti-plus" />
                                    </b-button>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="borderline" />
                                </b-row>
                                <b-row>
                                  <b-col cols="5" class="title-right">
                                    合計
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="othersTotal | currency"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                                <b-row>
                                  <b-col cols="5" class="title-right">
                                    消費税
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="tax(othersTotalTaxation) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </b-card-group>
                    </b-card>
                  </b-card-group>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="category">
              <b-card-header role="tab">
                <b-button variant="link" v-b-toggle.tabSingleYearPlan2>
                  支出の部
                </b-button>
              </b-card-header>
              <b-collapse id="tabSingleYearPlan2" role="tabpanel" visible v-if="lazLoading[6]">
                <b-card-body>
                  <b-card-group columns class="detail-columns" id="groupSingleYearPlan2">
                    <b-card no-body style="margin:0;padding:0;border:none;box-shadow:none;">
                      <b-card-group class="columns" columns style="column-count:1;">
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              variant="link"
                              v-b-toggle.tabSingleYearPlan21
                              id="btnSingleYearPlan21"
                            >
                              軽微な修繕
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSingleYearPlan21" role="tabpanel" v-if="lazLoading[7]">
                            <b-card-body>
                              <b-tabs
                                fill
                                pills
                                v-model="
                                  investmentAnalytics.singleYearPlan.spendingPlan.isDetail[0]
                                "
                              >
                                <b-tab title="概算入力">
                                  <b-container>
                                    <b-row>
                                      <b-col cols="2" class="title bold">総額</b-col>
                                      <b-col cols="2" class="title ">
                                        (税抜)
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <allyText
                                              name="approximateValue0"
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.singleYearPlan.spendingPlan
                                                  .approximateValue[0]
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col />
                                    </b-row>
                                    <b-row>
                                      <b-col cols="2" class="title bold" />
                                      <b-col cols="2" class="title">
                                        (税込)
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="
                                                sumTax(
                                                  Math.floor(
                                                    investmentAnalytics.singleYearPlan.spendingPlan
                                                      .approximateValue[0]
                                                  )
                                                ) | currency
                                              "
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col />
                                    </b-row>
                                  </b-container>
                                </b-tab>
                                <b-tab id="tabSingleYearPlan21Detail" title="詳細入力">
                                  <b-container>
                                    <b-row>
                                      <b-col cols="2" class="title bold">
                                        項目
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="title-center bold">
                                            数量
                                          </b-col>
                                          <b-col cols="4" />
                                        </b-row>
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="title-center bold">
                                            単価
                                          </b-col>
                                          <b-col cols="3" />
                                        </b-row>
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="title-center bold">
                                            年間修繕費
                                          </b-col>
                                          <b-col cols="2" />
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                    <b-row
                                      v-for="id in [1, 2, 3, 4]"
                                      v-bind:key="`expenditureItems1-${id}`"
                                    >
                                      <b-col cols="2" class="title">
                                        {{ expenditureItems[id - 1].title }}
                                      </b-col>
                                      <b-col style="padding-right:0;">
                                        <b-row>
                                          <b-col class="component">
                                            <allyText
                                              :name="'expenditureItems-unit' + id"
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.singleYearPlan.spendingPlan
                                                  .expenditureItems[id - 1].quantity
                                              "
                                              :floatLength="2"
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="4" class="unit">
                                            {{ expenditureItems[id - 1].unit[0] }}
                                          </b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col style="padding-right:0;">
                                        <b-row>
                                          <b-col class="component">
                                            <allyText
                                              :name="'expenditureItems-unitPrice' + id"
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.singleYearPlan.spendingPlan
                                                  .expenditureItems[id - 1].unitPrice
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="4" class="unit">
                                            {{ expenditureItems[id - 1].unit[1] }}
                                          </b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col style="padding-right:0;">
                                        <b-row>
                                          <b-col class="component">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="
                                                Math.floor(
                                                  (Math.floor(
                                                    investmentAnalytics.singleYearPlan.spendingPlan
                                                      .expenditureItems[id - 1].quantity * 10
                                                  ) /
                                                    10) *
                                                    Math.floor(
                                                      investmentAnalytics.singleYearPlan
                                                        .spendingPlan.expenditureItems[id - 1]
                                                        .unitPrice
                                                    ) *
                                                    expenditureItems[id - 1].count
                                                ) | currency
                                              "
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col class="borderline" />
                                    </b-row>
                                    <b-row>
                                      <b-col cols="2" class="title">
                                        合計
                                      </b-col>
                                      <b-col style="padding-right:0;" />
                                      <b-col style="padding-right:0;" />
                                      <b-col style="padding-right:0;">
                                        <b-row>
                                          <b-col class="component">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="minorRepairsTotal | currency"
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col cols="2" class="title">
                                        消費税
                                      </b-col>
                                      <b-col style="padding-right:0;" />
                                      <b-col style="padding-right:0;" />
                                      <b-col style="padding-right:0;">
                                        <b-row>
                                          <b-col class="component">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="tax(minorRepairsTotal) | currency"
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col cols="2" class="title">
                                        税込合計
                                      </b-col>
                                      <b-col style="padding-right:0;" />
                                      <b-col style="padding-right:0;" />
                                      <b-col style="padding-right:0;">
                                        <b-row>
                                          <b-col class="component">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="sumTax(minorRepairsTotal) | currency"
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                </b-tab>
                              </b-tabs>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              variant="link"
                              v-b-toggle.tabSingleYearPlan22
                              id="btnSingleYearPlan22"
                            >
                              消耗品
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSingleYearPlan22" role="tabpanel" v-if="lazLoading[8]">
                            <b-card-body>
                              <b-tabs
                                fill
                                pills
                                v-model="
                                  investmentAnalytics.singleYearPlan.spendingPlan.isDetail[1]
                                "
                              >
                                <b-tab title="概算入力">
                                  <b-container>
                                    <b-row>
                                      <b-col cols="2" class="title bold">総額</b-col>
                                      <b-col cols="2" class="title ">
                                        (税抜)
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <allyText
                                              name="approximateValue1"
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.singleYearPlan.spendingPlan
                                                  .approximateValue[1]
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col />
                                    </b-row>
                                    <b-row>
                                      <b-col cols="2" class="title bold" />
                                      <b-col cols="2" class="title">
                                        (税込)
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="
                                                sumTax(
                                                  Math.floor(
                                                    investmentAnalytics.singleYearPlan.spendingPlan
                                                      .approximateValue[1]
                                                  )
                                                ) | currency
                                              "
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col />
                                    </b-row>
                                  </b-container>
                                </b-tab>
                                <b-tab title="詳細入力">
                                  <b-container>
                                    <b-row>
                                      <b-col cols="2" class="title bold">
                                        項目
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="title-center bold">数量</b-col>
                                          <b-col cols="4" />
                                        </b-row>
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="title-center bold">単価(月)</b-col>
                                          <b-col cols="2" />
                                        </b-row>
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="title-center bold">
                                            年間修繕費
                                          </b-col>
                                          <b-col cols="2" />
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                    <b-row
                                      v-for="id in [5, 6]"
                                      v-bind:key="`expenditureItems2-${id}`"
                                    >
                                      <b-col cols="2" class="title">
                                        {{ expenditureItems[id - 1].title }}
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <allyText
                                              :name="'expenditureItems-quantity' + id"
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.singleYearPlan.spendingPlan
                                                  .expenditureItems[id - 1].quantity
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="4" class="unit">
                                            {{ expenditureItems[id - 1].unit }}
                                          </b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <allyText
                                              :name="'expenditureItems-unitPrice' + id"
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.singleYearPlan.spendingPlan
                                                  .expenditureItems[id - 1].unitPrice
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="
                                                (Math.floor(
                                                  investmentAnalytics.singleYearPlan.spendingPlan
                                                    .expenditureItems[id - 1].quantity
                                                ) *
                                                  Math.floor(
                                                    investmentAnalytics.singleYearPlan.spendingPlan
                                                      .expenditureItems[id - 1].unitPrice
                                                  ) *
                                                  12)
                                                  | currency
                                              "
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col class="borderline" />
                                    </b-row>
                                    <b-row>
                                      <b-col cols="2" class="title"> 合計 </b-col>
                                      <b-col />
                                      <b-col />
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="consumablesTotal | currency"
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col cols="2" class="title"> 消費税 </b-col>
                                      <b-col />
                                      <b-col />
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="tax(consumablesTotal) | currency"
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col cols="2" class="title">
                                        税込合計
                                      </b-col>
                                      <b-col />
                                      <b-col />
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="sumTax(consumablesTotal) | currency"
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                </b-tab>
                              </b-tabs>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              variant="link"
                              v-b-toggle.tabSingleYearPlan23
                              id="btnSingleYearPlan23"
                            >
                              ビルメンテナンス
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabSingleYearPlan23" role="tabpanel" v-if="lazLoading[9]">
                            <b-card-body>
                              <b-tabs
                                fill
                                pills
                                v-model="
                                  investmentAnalytics.singleYearPlan.spendingPlan.isDetail[2]
                                "
                              >
                                <b-tab title="概算入力">
                                  <b-container>
                                    <b-row>
                                      <b-col cols="2" class="title bold">総額</b-col>
                                      <b-col cols="2" class="title ">
                                        (税抜)
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <allyText
                                              name="buildingMaintenance-quantity"
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.singleYearPlan.spendingPlan
                                                  .approximateValue[2]
                                              "
                                              roundType="down"
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col />
                                    </b-row>
                                    <b-row>
                                      <b-col cols="2" class="title bold" />
                                      <b-col cols="2" class="title">
                                        (税込)
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="
                                                sumTax(
                                                  Math.floor(
                                                    investmentAnalytics.singleYearPlan.spendingPlan
                                                      .approximateValue[2]
                                                  )
                                                ) | currency
                                              "
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col />
                                    </b-row>
                                  </b-container>
                                </b-tab>
                                <b-tab title="詳細入力">
                                  <b-container>
                                    <b-row>
                                      <b-col class="title bold">
                                        項目
                                      </b-col>
                                      <b-col cols="2" class="title bold" style="padding:0;">
                                        年間実施回数
                                      </b-col>
                                      <b-col cols="1" class="title-right bold" style="padding:0;">
                                        基準
                                      </b-col>
                                      <b-col class="title-center bold">
                                        <b-row>
                                          <b-col>
                                            単価
                                          </b-col>
                                          <b-col cols="1" class="unit" />
                                        </b-row>
                                      </b-col>
                                      <b-col class="title-center bold">
                                        <b-row>
                                          <b-col>
                                            年間修繕費
                                          </b-col>
                                          <b-col cols="1" class="unit" />
                                        </b-row>
                                      </b-col>
                                      <b-col cols="1" class="title-center" style="padding:0;">
                                        変更
                                      </b-col>
                                    </b-row>
                                    <b-row
                                      v-for="id in buildingMaintenance.length"
                                      v-bind:key="`buildingMaintenance-${id}`"
                                    >
                                      <b-col class="title">
                                        {{ buildingMaintenance[id - 1].title }}
                                      </b-col>
                                      <b-col cols="2" style="padding:0;">
                                        <b-row>
                                          <b-col class="component">
                                            <b-form-input
                                              :name="'buildingMaintenance-quantity' + id"
                                              type="number"
                                              min="0"
                                              @change="buildingMaintenanceQuantityChange(id - 1)"
                                              v-model="
                                                investmentAnalytics.singleYearPlan.spendingPlan
                                                  .buildingMaintenance[id - 1].quantity
                                              "
                                            />
                                          </b-col>
                                          <b-col cols="2" class="unit pl-0">回</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col cols="1" class="title-right" style="padding:0;">
                                        <span v-if="buildingMaintenance[id - 1].criteria != null">
                                          {{ buildingMaintenance[id - 1].criteria }}回
                                        </span>
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <!-- その他…入力 -->
                                            <allyText
                                              :name="'otherUnitPrice' + id"
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              min="0"
                                              v-if="id == 18"
                                              v-model="
                                                investmentAnalytics.singleYearPlan.spendingPlan
                                                  .otherUnitPrice
                                              "
                                              roundType="down"
                                            />
                                            <!--  ↑宅配ロッカー保守管理を載せる場合は18→19 -->
                                            <!-- その他以外…表示 -->
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="
                                                buildingMaintenance[id - 1].unitPrice | currency
                                              "
                                              v-else
                                            />
                                          </b-col>
                                          <b-col cols="1" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col>
                                        <b-row>
                                          <b-col class="component">
                                            <!--計算値-->
                                            <!-- currencyがあると不具合が発生する  -->
                                            <b-form-input
                                              disabled
                                              class="currency"
                                              :value="
                                                ((Math.floor(
                                                  investmentAnalytics.singleYearPlan.spendingPlan
                                                    .buildingMaintenance[id - 1].quantity * 100
                                                ) /
                                                  100) *
                                                  (id < 18
                                                    ? buildingMaintenance[id - 1].unitPrice
                                                    : Math.floor(
                                                        investmentAnalytics.singleYearPlan
                                                          .spendingPlan.otherUnitPrice
                                                      )))
                                                  | currency
                                              "
                                              v-if="!buildingMaintenance[id - 1].isChanged"
                                            />
                                            <!--入力値-->
                                            <allyText
                                              type="number"
                                              class="number"
                                              :separation="true"
                                              halfWidth
                                              v-model="
                                                investmentAnalytics.singleYearPlan.spendingPlan
                                                  .buildingMaintenance[id - 1].amount
                                              "
                                              roundType="down"
                                              v-else
                                            />
                                          </b-col>
                                          <b-col cols="1" class="unit">円</b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col cols="1" class="checkbox">
                                        <b-form-checkbox
                                          v-model="buildingMaintenance[id - 1].isChanged"
                                          @change="changeCheckBox(id - 1)"
                                          size="lg"
                                          style="padding-left:2em"
                                          :value="1"
                                          :unchecked-value="0"
                                        >
                                        </b-form-checkbox>
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                </b-tab>
                              </b-tabs>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </b-card-group>
                    </b-card>
                    <b-card no-body style="margin:0;padding:0;border:none;box-shadow:none;">
                      <b-card-group class="columns" columns style="column-count:1;">
                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              id="btnSingleYearPlan24"
                              variant="link"
                              v-b-toggle.tabSingleYearPlan24
                            >
                              固定資産税・都市計画税</b-button
                            >
                          </b-card-header>
                          <b-collapse
                            id="tabSingleYearPlan24"
                            role="tabpanel"
                            v-if="lazLoading[10]"
                          >
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col class="title bold" cols="2">
                                    種別
                                  </b-col>
                                  <b-col cols="2" />
                                  <b-col />
                                  <b-col class="title-center">
                                    <b-row>
                                      <b-col>
                                        ※目安
                                      </b-col>
                                      <button
                                        class="col-3 btn-ally btn-reflection"
                                        style="white-space: nowrap; padding: 0px;"
                                        @click="
                                          changePropertyTaxGuideline(0, propertyTaxGuideline(0))
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row class="pt-1">
                                  <b-col class="title" cols="2">
                                    土地
                                  </b-col>
                                  <b-col cols="2" class="title">
                                    <b-row> <b-col style="padding:0;">固定資産税</b-col> </b-row>
                                    <b-row class="mt-1" />
                                    <b-row> <b-col style="padding:0;">都市計画税</b-col> </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="propertyTax0"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment.propertyTax[0]
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                    <b-row class="btn-reflection mt-1" />
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="cityPlanningTax0"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment.cityPlanningTax[0]
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="propertyTaxGuideline(0) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                    <b-row>
                                      <button
                                        class="col-3 btn-ally btn-reflection ml-auto mr-0 mt-1"
                                        style="white-space: nowrap; padding: 0px;"
                                        @click="
                                          changeCityPlanningTaxGuideline(
                                            0,
                                            cityPlanningTaxGuideline(0)
                                          )
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="cityPlanningTaxGuideline(0) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row
                                  v-for="id of investmentAnalytics.existingBuildingCount"
                                  v-bind:key="`for05-${id}`"
                                >
                                  <b-col class="title " cols="2">既存建物{{ id }}</b-col>
                                  <b-col class="title" cols="2">
                                    <b-row class="mt-1" />
                                    <b-row> <b-col style="padding:0;">固定資産税</b-col> </b-row>
                                    <b-row class="mt-1" />
                                    <b-row> <b-col style="padding:0;">都市計画税</b-col> </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row class="btn-reflection" />
                                    <b-row class="mt-1">
                                      <b-col class="component">
                                        <allyText
                                          :name="'propertyTax' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment.propertyTax[id]
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                    <b-row class="btn-reflection mt-1" />
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'cityPlanningTax' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment.cityPlanningTax[
                                              id
                                            ]
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <button
                                        class="col-3 btn-ally btn-reflection mt-1 mr-0 ml-auto"
                                        style="white-space: nowrap; padding: 0px;"
                                        @click="
                                          changePropertyTaxGuideline(id, propertyTaxGuideline(id))
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="propertyTaxGuideline(id) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                    <b-row>
                                      <button
                                        class="col-3 btn-ally btn-reflection mt-1 mr-0 ml-auto"
                                        style="white-space: nowrap; padding: 0px;"
                                        @click="
                                          changeCityPlanningTaxGuideline(
                                            id,
                                            cityPlanningTaxGuideline(id)
                                          )
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="cityPlanningTaxGuideline(id) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row
                                  v-for="id of investmentAnalytics.newBuildingCount"
                                  :key="`for12-${id}`"
                                >
                                  <b-col class="title" cols="2">新築建物{{ id }}</b-col>
                                  <b-col class="title" cols="2">
                                    <b-row class="mt-1" />
                                    <b-row> <b-col style="padding:0;">固定資産税</b-col> </b-row>
                                    <b-row class="mt-1" />
                                    <b-row> <b-col style="padding:0;">都市計画税</b-col> </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row class="btn-reflection mt-1" />
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'propertyTax' + (10 + id)"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment.propertyTax[
                                              10 + id
                                            ]
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                    <b-row class="btn-reflection mt-1" />
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'cityPlanningTax' + (id + 10)"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment.cityPlanningTax[
                                              10 + id
                                            ]
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <button
                                        class="col-3 btn-ally btn-reflection mt-1 ml-auto mr-0"
                                        style="white-space: nowrap; padding: 0px;"
                                        @click="
                                          changePropertyTaxGuideline(
                                            10 + id,
                                            propertyTaxGuideline(10 + id)
                                          )
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="propertyTaxGuideline(10 + id) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                    <b-row>
                                      <button
                                        class="col-3 btn-ally btn-reflection mt-1 ml-auto mr-0"
                                        style="white-space: nowrap; padding: 0px;"
                                        @click="
                                          changeCityPlanningTaxGuideline(
                                            10 + id,
                                            cityPlanningTaxGuideline(10 + id)
                                          )
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="cityPlanningTaxGuideline(10 + id) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="title" cols="2">
                                    付属建物
                                  </b-col>
                                  <b-col class="title" cols="2">
                                    <b-row class="mt-1" />
                                    <b-row> <b-col style="padding:0;">固定資産税</b-col> </b-row>
                                    <b-row class="mt-1" />
                                    <b-row> <b-col style="padding:0;">都市計画税</b-col> </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row class="btn-reflection mt-1" />
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="propertyTax21"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment.propertyTax[21]
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                    <b-row class="btn-reflection mt-1" />
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="cityPlanningTax21"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.initialInvestment
                                              .cityPlanningTax[21]
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <button
                                        class="col-3 btn-ally btn-reflection mt-1 ml-auto mr-0"
                                        style="white-space: nowrap; padding: 0px;"
                                        @click="
                                          changePropertyTaxGuideline(21, propertyTaxGuideline(21))
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="propertyTaxGuideline(21) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                    <b-row>
                                      <button
                                        class="col-3 btn-ally btn-reflection mt-1 mr-0 ml-auto"
                                        style="white-space: nowrap; padding: 0px;"
                                        @click="
                                          changeCityPlanningTaxGuideline(
                                            21,
                                            cityPlanningTaxGuideline(21)
                                          )
                                        "
                                      >
                                        反映
                                      </button>
                                    </b-row>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="cityPlanningTaxGuideline(21) | currency"
                                        />
                                      </b-col>
                                      <b-col cols="3" class="unit">円/年</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>

                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button variant="link" v-b-toggle.tabPreBusinessBurden1>
                              支払地代
                            </b-button>
                          </b-card-header>
                          <b-collapse id="tabPreBusinessBurden1" role="tabpanel">
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col class="title bold">月額</b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          v-if="
                                            this.investmentAnalytics.initialInvestment
                                              .incidentalExpenses[9]
                                          "
                                          name="monthlyRent"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          mode="View"
                                          v-model="
                                            investmentAnalytics.initialInvestment
                                              .preCareerCommitment.monthlyRent
                                          "
                                          roundType="down"
                                        />
                                        <allyText
                                          v-else
                                          name="monthlyRent"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          v-model="
                                            investmentAnalytics.singleYearPlan.spendingPlan
                                              .monthlyRent
                                          "
                                          mode="Edit"
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="1" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>

                        <b-card no-body class="category">
                          <b-card-header role="tab">
                            <b-button
                              id="btnSingleYearPlan25"
                              variant="link"
                              v-b-toggle.tabSingleYearPlan25
                            >
                              その他
                            </b-button>
                          </b-card-header>
                          <b-collapse
                            id="tabSingleYearPlan25"
                            role="tabpanel"
                            v-if="lazLoading[11]"
                          >
                            <b-card-body>
                              <b-container>
                                <b-row>
                                  <b-col cols="2" class="title bold">
                                    項目
                                  </b-col>
                                  <b-col class="title-center">
                                    <b-row>
                                      <b-col class="title-center bold">
                                        数量/月
                                      </b-col>
                                      <b-col cols="4" />
                                    </b-row>
                                  </b-col>
                                  <b-col class="title-center">
                                    <b-row>
                                      <b-col class="title-center bold">
                                        単価(税込)
                                      </b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col class="title bold">
                                    年間維持費/修繕費
                                  </b-col>
                                </b-row>
                                <b-row
                                  v-for="id in [7, 8, 9]"
                                  v-bind:key="`expenditureItems3-${id}`"
                                >
                                  <b-col cols="2" class="title">
                                    {{ expenditureItems[id - 1].title }}
                                  </b-col>
                                  <b-col>
                                    <b-row v-if="expenditureItems[id - 1].unit[0] != null">
                                      <b-col class="component">
                                        <allyText
                                          :name="'expenditureItems-quantity' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.spendingPlan
                                              .expenditureItems[id - 1].quantity
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="4" class="unit">
                                        {{ expenditureItems[id - 1].unit[0] }}
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'expenditureItems-unitPrice' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.spendingPlan
                                              .expenditureItems[id - 1].unitPrice
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit"> </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.singleYearPlan.spendingPlan
                                                .expenditureItems[id - 1].quantity
                                            ) *
                                              Math.floor(
                                                investmentAnalytics.singleYearPlan.spendingPlan
                                                  .expenditureItems[id - 1].unitPrice
                                              ) *
                                              expenditureItems[id - 1].count)
                                              | currency
                                          "
                                          v-if="expenditureItems[id - 1].unit[0] != null"
                                        />
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.singleYearPlan.spendingPlan
                                                .expenditureItems[id - 1].unitPrice
                                            ) *
                                              expenditureItems[id - 1].count)
                                              | currency
                                          "
                                          v-else
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                  <b-col cols="2" class="title">
                                    管理料
                                  </b-col>
                                  <b-col />
                                  <b-col />
                                </b-row>
                                <b-row>
                                  <b-col class="component" cols="2">
                                    <b-form-select
                                      v-model="
                                        investmentAnalytics.singleYearPlan.spendingPlan
                                          .managementFeeType
                                      "
                                      :options="managementFeeItems"
                                      size="sm"
                                    >
                                    </b-form-select>
                                  </b-col>
                                  <b-col>
                                    <b-row
                                      v-if="
                                        investmentAnalytics.singleYearPlan.spendingPlan
                                          .managementFeeType == 2
                                      "
                                    >
                                      <b-col class="component">
                                        <allyText
                                          name="numberOfRooms"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.spendingPlan
                                              .numberOfRooms
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="4" class="unit">
                                        区画
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row
                                      v-if="
                                        investmentAnalytics.singleYearPlan.spendingPlan
                                          .managementFeeType == 1 ||
                                          investmentAnalytics.singleYearPlan.spendingPlan
                                            .managementFeeType == 2
                                      "
                                    >
                                      <b-col class="component">
                                        <allyText
                                          name="managementFeeValue"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.spendingPlan
                                              .managementFeeValue
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit"> </b-col>
                                    </b-row>
                                    <b-row
                                      v-if="
                                        investmentAnalytics.singleYearPlan.spendingPlan
                                          .managementFeeType == 3
                                      "
                                    >
                                      <b-col class="component">
                                        <allyText
                                          name="managementFeeValue"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          :floatLength="1"
                                          v-model="
                                            investmentAnalytics.singleYearPlan.spendingPlan
                                              .managementFeeValue
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">
                                        ％
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row
                                      v-if="
                                        investmentAnalytics.singleYearPlan.spendingPlan
                                          .managementFeeType == 1 ||
                                          investmentAnalytics.singleYearPlan.spendingPlan
                                            .managementFeeType == 2
                                      "
                                    >
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="managementFee() | currency"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                  <b-col style="border-top:dashed 1px gray;" />
                                </b-row>
                                <br />
                                <b-row>
                                  <b-col cols="2" class="title bold">
                                    項目
                                  </b-col>
                                  <b-col class="title-center">
                                    <b-row>
                                      <b-col class="title-center bold">
                                        数量/年
                                      </b-col>
                                      <b-col cols="4" />
                                    </b-row>
                                  </b-col>
                                  <b-col class="title-center">
                                    <b-row>
                                      <b-col class="title-center bold">
                                        単価(税込)
                                      </b-col>
                                      <b-col cols="2" />
                                    </b-row>
                                  </b-col>
                                  <b-col class="title bold">
                                    年間維持費/修繕費
                                  </b-col>
                                </b-row>
                                <b-row
                                  v-for="id in [10, 11]"
                                  v-bind:key="`expenditureItems3-${id}`"
                                >
                                  <b-col cols="2" class="title">
                                    {{ expenditureItems[id - 1].title }}
                                  </b-col>
                                  <b-col>
                                    <b-row v-if="expenditureItems[id - 1].unit[0] != null">
                                      <b-col class="component">
                                        <allyText
                                          :name="'expenditureItems-quantity' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.spendingPlan
                                              .expenditureItems[id - 1].quantity
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="4" class="unit">
                                        {{ expenditureItems[id - 1].unit[0] }}
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          :name="'expenditureItems-unitPrice' + id"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.spendingPlan
                                              .expenditureItems[id - 1].unitPrice
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit"> </b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.singleYearPlan.spendingPlan
                                                .expenditureItems[id - 1].quantity
                                            ) *
                                              Math.floor(
                                                investmentAnalytics.singleYearPlan.spendingPlan
                                                  .expenditureItems[id - 1].unitPrice
                                              ) *
                                              expenditureItems[id - 1].count)
                                              | currency
                                          "
                                          v-if="expenditureItems[id - 1].unit[0] != null"
                                        />
                                        <b-form-input
                                          disabled
                                          class="currency"
                                          :value="
                                            (Math.floor(
                                              investmentAnalytics.singleYearPlan.spendingPlan
                                                .expenditureItems[id - 1].unitPrice
                                            ) *
                                              expenditureItems[id - 1].count)
                                              | currency
                                          "
                                          v-else
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">円</b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                  <b-col style="border-top:dashed 1px gray;" />
                                </b-row>
                                <br />
                                <b-row>
                                  <b-col cols="2" class="title">
                                    予備費率
                                  </b-col>
                                  <b-col />
                                  <b-col>
                                    <b-row>
                                      <b-col class="component">
                                        <allyText
                                          name="reserveRate"
                                          type="number"
                                          class="number"
                                          :separation="true"
                                          :floatLength="1"
                                          halfWidth
                                          v-model="
                                            investmentAnalytics.singleYearPlan.spendingPlan
                                              .reserveRate
                                          "
                                          roundType="down"
                                        />
                                      </b-col>
                                      <b-col cols="2" class="unit">％</b-col>
                                    </b-row>
                                  </b-col>
                                  <b-col />
                                </b-row>
                              </b-container>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </b-card-group>
                    </b-card>
                  </b-card-group>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-card-group>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Calculation from './Calculation';
  import Vue from 'vue';
  export default {
    mixins: [Calculation],
    components: {},
    data: function() {
      return {
        viewFlag: true,
        // 表示情報/ビルメンテナンス
        buildingMaintenance: [
          {
            title: '定期清掃',
            unitPrice: null,
            isChanged: false,
            criteria: null,
          },
          {
            title: 'ガラス清掃',
            unitPrice: null,
            isChanged: false,
            criteria: null,
          },
          {
            title: '貯水槽点検・清掃',
            unitPrice: null,
            isChanged: false,
            criteria: 1,
          },
          {
            title: '水質検査11項目',
            unitPrice: null,
            isChanged: false,
            criteria: 1,
          },
          {
            title: '雑排水槽点検・清掃',
            unitPrice: null,
            isChanged: false,
            criteria: 1,
          },
          {
            title: 'EV保守点検',
            unitPrice: null,
            isChanged: false,
            criteria: 12,
          },
          {
            title: 'EV法定検査代行',
            unitPrice: null,
            isChanged: false,
            criteria: 12,
          },
          {
            title: '自動ドア保守点検',
            unitPrice: null,
            isChanged: false,
            criteria: 3,
          },
          {
            title: '消防設備保守点検',
            unitPrice: null,
            isChanged: false,
            criteria: 2,
          },
          {
            title: '防火対象物点検',
            unitPrice: null,
            isChanged: false,
            criteria: 1,
          },
          {
            title: '建築設備定期検査',
            unitPrice: null,
            isChanged: false,
            criteria: 1,
          },
          {
            title: '防火設備定期検査',
            unitPrice: null,
            isChanged: false,
            criteria: 1,
          },
          {
            title: '特殊建築物定期調査',
            unitPrice: null,
            isChanged: false,
            criteria: '1/3',
          },
          {
            title: '除草',
            unitPrice: null,
            isChanged: false,
            criteria: null,
          },
          {
            title: '除雪',
            unitPrice: null,
            isChanged: false,
            criteria: null,
          },
          {
            title: '警備',
            unitPrice: null,
            isChanged: false,
            criteria: null,
          },
          {
            title: 'キュービクル保守点検',
            unitPrice: null,
            isChanged: false,
            criteria: 12,
          },
          //          {
          //            title: '宅配ロッカー保守管理',
          //            unitPrice: null,
          //            isChanged: false,
          //         },
          {
            title: 'その他',
            unitPrice: null,
            isChanged: false,
          },
        ],
        // 表示情報/支出項目
        expenditureItems: [
          // 軽微な修繕
          { title: '貸室内', unit: ['区画', '円/月'], count: 12 },
          { title: '共用部', unit: ['回/月', '円/回'], count: 12 },
          { title: '舗装', unit: ['回/月', '円/回'], count: 12 },
          { title: '外構', unit: ['回/月', '円/回'], count: 12 },
          // 消耗品
          { title: '貸室内', unit: '区画' },
          { title: '共用部', unit: '式' },
          // その他
          { title: '原状回復費', unit: ['区画', '円/月'], count: 12 },
          { title: 'Wi-Fi利用料', unit: ['回線', '円/月'], count: 12 },
          { title: 'その他', unit: [null, '円/月'], count: 12 },
          { title: '広告費', unit: ['回', '円/回'], count: 1 },
          { title: '諸雑費', unit: [null, '円/年'], count: 1 },
          //          { title: '賃借料', unit: [null, '円/月'], count: 12 },
          //          { title: '占用使用料', unit: [null, '円/月'], count: 12 },
          //          { title: '水道光熱費', unit: [null, '円/月'], count: 12 },
        ],
        // 管理費
        managementFeeItems: [
          { text: '', value: null },
          { text: '定額', value: 1 },
          { text: '１区画あたり', value: 2 },
          { text: '収入の', value: 3 },
        ],
        lazLoading: [],
        lazNum: 12,
      };
    },
    created() {
      for (let i = 0; i < this.lazNum; i++) {
        this.lazLoading.push(false);
      }
      let count = 0;
      //ページが重たくなるので、段階敵に表示する
      let inter = setInterval(() => {
        Vue.set(this.lazLoading, count, true);
        count++;
        if (count >= this.lazNum) {
          clearInterval(inter);
        }
      }, 100);
    },
    mounted: function() {
      // 単価更新
      this.buildingMaintenance.forEach(v => {
        v.unitPrice = this.getUnitPrice('ビルメンテナンス費用', v.title);
      });
      for (
        let i = 0;
        i < this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance.length;
        i++
      ) {
        let building = this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[i];
        if (building.amount != null) {
          if (
            parseInt(building.quantity) *
              (i < 17
                ? parseInt(this.buildingMaintenance[i].unitPrice)
                : this.investmentAnalytics.singleYearPlan.spendingPlan.otherUnitPrice) !=
            parseInt(building.amount)
          ) {
            this.buildingMaintenance[i].isChanged = 1; //true
          }
        }
      }
    },
    methods: {
      initialize() {
        for (
          let i = 0;
          i < this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance.length;
          i++
        ) {
          let building = this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[
            i
          ];
          if (building.amount != null) {
            if (
              parseInt(building.quantity) *
                (i < 17
                  ? parseInt(this.buildingMaintenance[i].unitPrice)
                  : this.investmentAnalytics.singleYearPlan.spendingPlan.otherUnitPrice) !=
              parseInt(building.amount)
            ) {
              this.buildingMaintenance[i].isChanged = 1; //true
            }
          }
        }
      },
      // チェックボックス変更時
      changeCheckBox: function(id) {
        // 初期値設定
        if (!this.buildingMaintenance[id].isChanged) {
          let amount =
            Math.floor(
              this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[id].quantity
            ) *
            (id < 18
              ? this.buildingMaintenance[id].unitPrice
              : Math.floor(this.investmentAnalytics.singleYearPlan.spendingPlan.otherUnitPrice));
          Vue.set(
            this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[id],
            'amount',
            amount
          );
        }
      },
      //個数を変更したときにも内部の値を反映する
      buildingMaintenanceQuantityChange: function(id) {
        // allyTextで@changeが使えないので、ここで補正処理をする
        this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[id].quantity =
          this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[id].quantity < 0
            ? 0
            : Math.floor(
                this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[id]
                  .quantity * 100
              ) / 100;
        if (!this.buildingMaintenance[id].isChanged) {
          let amount =
            Math.floor(
              this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[id].quantity
            ) *
            (id < 18
              ? this.buildingMaintenance[id].unitPrice
              : this.investmentAnalytics.singleYearPlan.spendingPlan.otherUnitPrice);
          Vue.set(
            this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[id],
            'amount',
            amount
          );
        }
      },
      // 項目追加
      addItem: function() {
        this.investmentAnalytics.singleYearPlan.incomePlan.itemNames.push(null);
        this.investmentAnalytics.singleYearPlan.incomePlan.others.push({
          taxation: null,
          taxExempt: null,
        });
      },
      // 項目削除
      deleteItem: function(id) {
        this.investmentAnalytics.singleYearPlan.incomePlan.itemNames.splice(id, 1);
        this.investmentAnalytics.singleYearPlan.incomePlan.others.splice(id, 1);
      },
      changeCityPlanningTaxGuideline: function(id, sum) {
        Vue.set(this.investmentAnalytics.initialInvestment.cityPlanningTax, id, sum);
      },
      changePropertyTaxGuideline: function(id, sum) {
        Vue.set(this.investmentAnalytics.initialInvestment.propertyTax, id, sum);
      },
    },
    computed: {
      // 単年度収支計画/支出の部/軽微な修繕/貸室内/数量
      section: function() {
        return this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[0].quantity;
      },
    },
    watch: {
      // マスタ更新時
      '$store.state.master.unitPrice': {
        handler: function() {
          // 単価更新
          this.buildingMaintenance.forEach(v => {
            v.unitPrice = this.getUnitPrice('ビルメンテナンス費用', v.title);
          });
        },
        deep: false,
      },
      // 単年度収支計画/支出の部/軽微な修繕/貸室内/数量 変更時
      section: {
        handler: function() {
          // 単年度収支計画/支出の部/消耗品/貸室内/数量 を更新
          this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[4].quantity = this.section;
          // 単年度収支計画/支出の部/その他/原状回復費/数量 を更新
          this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[6].quantity = this.section;
        },
        deep: false,
      },
      // 単年度収支計画/支出の部/メンテナンス/詳細入力/その他/単価 変更時
      'investmentAnalytics.singleYearPlan.spendingPlan.otherUnitPrice': {
        handler: function() {
          let amount =
            this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[17].quantity *
            this.investmentAnalytics.singleYearPlan.spendingPlan.otherUnitPrice;
          Vue.set(
            this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[17],
            'amount',
            amount
          );
        },
      },
    },
  };
</script>
<style scoped>
  #SingleYearPlan .unit {
    padding-left: 0.5em;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  #SingleYearPlan .component {
    padding: 1px;
  }
  #SingleYearPlan select {
    height: 2.875rem;
    font-size: 0.875rem;
  }
  #SingleYearPlan .row .row {
    margin: 0;
  }
  #SingleYearPlan .checkbox {
    display: grid;
    text-align: center;
    align-items: center;
    padding: 0;
  }
  @media screen and (min-width: 576px) {
    #InvestmentAnalytics #SingleYearPlan .detail-columns {
      column-count: 1;
    }
  }
  @media screen and (min-width: 768px) {
    /* iPad     - 縦 :  768 */
    #InvestmentAnalytics #SingleYearPlan .detail-columns {
      column-count: 1;
    }
  }
  @media screen and (min-width: 992px) {
    /* iPad Pro - 縦 : 1024 */
    /* iPad     - 横 : 1024 */
    #InvestmentAnalytics #SingleYearPlan .detail-columns {
      column-count: 1;
    }
  }
  @media screen and (min-width: 1200px) {
    /* iPad Pro - 横 : 1366 */
    /* PC(FullHD) - 横 : 1920 */
    #InvestmentAnalytics #SingleYearPlan .detail-columns {
      column-count: 2;
    }
  }
</style>
