<template>
  <g>
    <path
      v-for="(path, i) in pathList"
      :key="i"
      data-area="object"
      :data-id="obj.id"
      :d="path.d"
      :stroke="path.stroke"
      :stroke-width="path.strokeWidth"
      :stroke-dasharray="path.strokeDasharray"
      :fill="path.fill"
      :transform="`scale(${obj.style.flipHorizontal ? -1 : 1}, ${obj.style.flipVertical ? -1 : 1})`"
      :transform-origin="`${obj.doorWidth / 2} ${obj.doorHeight / 2}`"
    />
  </g>
</template>
<script>
  // @ts-check
  import { defineComponent, computed } from '@vue/composition-api';
  import { doorGenerator } from '../../lib/node/tools/doorGenerator';

  export default defineComponent({
    props: {
      obj: Object, // PvObject
    },
    setup(props) {
      const width = computed(() => {
        if (props.obj.vertexes.length < 2) {
          return 0;
        }
        return props.obj.vertexes[1].sub(props.obj.vertexes[0]).absVal();
      });
      const pathList = computed(() => {
        return doorGenerator(props.obj.subType, width.value, props.obj.style.strokeWidth);
      });

      return {
        pathList,
      };
    },
  });
</script>
