<template>
  <g> </g>
</template>
<script>
  // @ts-check
  import { defineComponent } from '@vue/composition-api';

  export default defineComponent({
    props: {
      obj: Object, // PvObject
      selectedIdList: Set,
    },
    setup() {},
  });
</script>
