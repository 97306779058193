<template>
  <b-container
    class="item-list-container floor-material-list"
    :style="{ height: listHeight + 'px' }"
  >
    <b-row>
      <b-col cols="6" style="padding: 4px" v-for="(item, i) in items" :key="i">
        <div
          class="item-list list-card"
          :class="[item === currentItem ? 'isSelected' : null, isEditor ? null : 'noIsEditor']"
          @click="isEditor ? $emit('clickItem', item) : null"
        >
          <slot name="item" :item="item" :index="i" />
        </div>
      </b-col>
    </b-row>
    <div class="item-list" v-for="(item, i) in items.length === 0 ? new Array(20) : []" :key="i">
      <b-skeleton></b-skeleton>
    </div>
  </b-container>
</template>
<script>
  import { computed, defineComponent } from '@vue/composition-api';

  export default defineComponent({
    props: {
      items: {
        type: Array,
        required: true,
      },
      height: {
        type: Number,
        required: true,
      },
      currentItem: {
        type: Object,
        default: null,
      },
      isEditor: {
        //  PROP: 編集権限の有無
        type: Boolean,
        default: true,
      },
    },
    setup(props) {
      // COMPUTED: 素材リストの表示範囲
      const listHeight = computed(() => {
        return props.height;
      });

      return {
        listHeight,
      };
    },
  });
</script>
<style scoped>
  .item-list-container {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .item-list-container::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .item-list-container::-webkit-scrollbar-track {
    background: #eeeeee;
  }
  .item-list-container::-webkit-scrollbar-thumb {
    background: var(--colorTheme);
    border-radius: 10px;
  }
  .item-list {
    border-top-style: solid;
    border-top-color: #eeeeee;
    text-align: center;
    font-size: 13px;
    user-select: none;
    font-weight: bold;
  }
  .item-list:hover {
    background-color: #aaaaaa;
    cursor: pointer;
  }
  .item-list.isSelected {
    outline: solid 3px var(--colorTheme);
  }
  .item-list.noIsEditor {
    background-color: #aaaaaa;
  }
  .list-card {
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 5px;
  }
</style>
