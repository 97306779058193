<template>
  <div>
    <div class="menu-btn-group">
      <TileBtn
        :block="false"
        height="30px"
        :selected="doorWindow === INTERIOR_MASTER_CATEGORY_TYPE.DOORS"
        @click="doorWindow = INTERIOR_MASTER_CATEGORY_TYPE.DOORS"
      >
        ドア
      </TileBtn>
      <TileBtn
        :block="false"
        height="30px"
        :selected="doorWindow === INTERIOR_MASTER_CATEGORY_TYPE.WINDOWS"
        @click="doorWindow = INTERIOR_MASTER_CATEGORY_TYPE.WINDOWS"
      >
        窓
      </TileBtn>
    </div>
    <div style="padding: 0 5px;">
      <div class="floor-disp">
        <div>
          <div
            v-show="isLoading"
            class="floor-disp-img"
            style="height: 100px; display: flex; justify-content: center; align-items: center;"
          >
            <b-spinner label="Spinning" variant="success" type="grow"></b-spinner>
          </div>
        </div>
      </div>
      <div class="pv-config-label">厚み(px)</div>
      <div>
        <input
          type="number"
          style="width: 100%"
          :value="objectPreset.style.strokeWidth"
          step="5"
          @input="e => $emit('changeStrokeWidth', parseFloat(e.srcElement.value))"
        />
      </div>
      <div class="pv-config-label">高さ(m)</div>
      <div>
        <input
          type="number"
          min="0"
          max="10"
          step="0.01"
          style="width: 100%"
          :value="objectPreset.height3d.toFixed(2)"
          @input="e => $emit('changeHeight3d', parseFloat(e.srcElement.value))"
        />
      </div>
      <div class="pv-config-label">取付け高さ(m)</div>
      <div>
        <input
          type="number"
          min="0"
          max="10"
          step="0.01"
          style="width: 100%"
          :value="objectPreset.mountingHeight.toFixed(2)"
          @input="e => $emit('changeMountingHeight', parseFloat(e.srcElement.value))"
        />
      </div>
      <GridView
        class="mt-2"
        :items="doorListFiltered"
        :height="listHeight"
        :currentItem="currentDoor"
        @clickItem="door => changeType(door)"
      >
        <template #item="{ item, index }">
          <div style="height: 110px">
            <svg width="70" height="70" viewBox="-20,-350,400,400">
              <path
                v-for="(path, j) in pathList[index]"
                :key="j"
                :d="path.d"
                :stroke="path.stroke"
                :stroke-width="path.strokeWidth"
                :stroke-dasharray="path.strokeDasharray"
                :fill="path.fill"
              />
            </svg>
            <div style="font-size: 10px">
              {{ item.name }}
            </div>
          </div>
        </template>
      </GridView>
    </div>
  </div>
</template>
<script>
  // @ts-check
  import { computed, defineComponent, ref } from '@vue/composition-api';
  import TileBtn from '../PvBtn.vue';
  import ItemList from '../ItemList.vue';
  import GridView from './GridView.vue';
  import { INTERIOR_MASTER_CATEGORY_TYPE } from '../../../lib/util/const';
  import { doorGenerator } from '../../../lib/node/tools/doorGenerator';

  export default defineComponent({
    components: {
      TileBtn,
      ItemList,
      GridView,
    },
    props: {
      doorType: String, // GlobalSettings
      objectPreset: Object, // PvObject
      height: Number,
      isLoading: Boolean,
      doorWindowList: Array, // PROP: 建具リスト
    },
    setup(props, context) {
      const doorWindow = ref(INTERIOR_MASTER_CATEGORY_TYPE.DOORS); // DATA: 建具カテゴリ

      /**
       * COMPUTED: リストの縦幅
       */
      const listHeight = computed(() => {
        return props.height - 200;
      });

      /** @type {import('@vue/composition-api').Ref<number>} */
      const patternWidth = computed(() => {
        return props.objectPreset.style.patternWidth;
      });

      /**
       * METHOD: 建具が選択されたら建具情報を取得する
       */
      const changeType = (
        /** @type {import('../../../lib/state/interiorMaster').InteriorMasterDataForPlaneView} */ door
      ) => {
        context.emit('changeType', door);
      };

      /**
       * COMPUTED: 建具カテゴリが変更されたら建具アイコンリストを更新する
       */
      const doorListFiltered = computed(() => {
        // @ts-ignore
        return props.doorWindowList.filter(door => door.type === doorWindow.value);
      });

      /**
       * COMPUTED: 選択された建具情報を取得する
       */
      const currentDoor = computed(() => {
        if (!props.objectPreset.subType) {
          return;
        }
        const door = props.doorWindowList.find(door => door.subType === props.objectPreset.subType);
        if (door) {
          changeType(door);
        }
        // @ts-ignore
        return door;
      });

      const pathList = computed(() => {
        return doorListFiltered.value.map(d => {
          // @ts-ignore
          return doorGenerator(d.subType, 300, 20);
        });
      });

      return {
        INTERIOR_MASTER_CATEGORY_TYPE,
        changeType,
        patternWidth,
        listHeight,
        doorListFiltered,
        doorWindow,
        pathList,
        currentDoor,
      };
    },
  });
</script>
<style scoped>
  .menu-btn-group {
    display: flex;
    font-size: 0.8rem;
  }
  .layout-menu-back {
    color: black;
    background-color: white;
  }
  .floor-disp {
    user-select: none;
    padding: 5px 10px;
  }
  .floor-disp-img {
    border: #ccc solid 5px;
  }
  .floor-parameters {
    padding: 10px;
  }
</style>
