// @ts-check

export const LARGE_GRID_PX_DEFAULT = 100; // 大グリッド初期ピクセル
export const LARGE_GRID_METER_DEFAULT = 0.91; // 大グリッド初期メートル
export const LARGE_GRID_PX = LARGE_GRID_PX_DEFAULT; // 大グリッドピクセル
export const LARGE_GRID_METER = LARGE_GRID_METER_DEFAULT; // 大グリッド1個あたりの長さ(メートル)
export const GRID_DIV_NUM = 4; // グリッド分割数
export const SMALL_GRID_PX_DEFAULT = LARGE_GRID_PX / GRID_DIV_NUM; // 小グリッド初期ピクセル
export const HEADER_HEIGHT = 60; // ヘッダー縦幅
export const LEFT_MENU_WIDTH = 200; // 左メニュー横幅
export const LAYER_WIDTH = 15000; // レイヤー横幅
export const PAPER_RATIO = 210 / 297; // 印刷比率
export const LAYER_HEIGHT = LAYER_WIDTH * PAPER_RATIO; // レイヤー縦幅
export const LAYER_SCALE_DEFAULT = 0.3; // 初期レイヤースケール
export const GUIDE_OPACITY = 0.7; // ガイド不透明度
export const HOVER_COLOR = '#4ed9f5';
export const SCROLL_BAR_HEIGHT = 20;
export const LENGTH_TEXT_DIGITS_AFTER_DECIMAL_POINT = 2; // 寸法の小数部桁数
// ARデータ用オフセット値
export const AR_BASE_OFFSET = {
  X: 800,
  Y: 600,
};
// 保存制限数
export const SAVE_LIMIT = {
  PLANE_VIEW: 10,
  PATTERN: 5,
  LAYER: 5,
};
export const WALL_COLOR_LIST = [
  { label: '黒', value: 'black' },
  { label: 'グレー', value: 'grey' },
  { label: '透明', value: 'transparent' },
  { label: '赤', value: 'red' },
  { label: '青', value: 'blue' },
  { label: '黄', value: 'yellow' },
  { label: '緑', value: 'green' },
  { label: 'オレンジ', value: 'orange' },
  { label: '紫', value: 'purple' },
];
export const SPACE_NAME_LIST = [
  { value: '和室' },
  { value: '洋室' },
  { value: 'LDK' },
  { value: 'LD' },
  { value: 'DK' },
  { value: 'ダイニング' },
  { value: '台所' },
  { value: '洗面・脱衣' },
  { value: '浴室' },
  { value: 'トイレ' },
  { value: 'ホール' },
  { value: '廊下' },
  { value: '玄関' },
  { value: 'ポーチ' },
  { value: '車庫' },
  { value: '押入' },
  { value: '床の間' },
  { value: '仏間' },
  { value: 'リビング' },
  { value: '子供室' },
  { value: '寝室' },
  { value: '書斎' },
  { value: '食堂' },
  { value: 'キッチン' },
  { value: '湯沸室' },
  { value: '家事室' },
  { value: 'ユーティリティー' },
  { value: '縁側' },
  { value: '納戸' },
  { value: 'クローゼット' },
  { value: '物入' },
  { value: 'ユニットバス' },
  { value: '勝手口' },
  { value: '倉庫' },
  { value: '物干場' },
  { value: '吹抜' },
  { value: 'サンルーム' },
  { value: 'ベランダ' },
  { value: 'バルコニー' },
  { value: 'テラス' },
];
export const MEASURE_SELECT_LIST = [
  { value: 'mm', text: 'ミリメートル (mm)' },
  { value: 'm', text: 'メートル (m)' },
];
export const SPACE_SELECT_LIST = [
  { value: '帖', text: '尺貫法 (帖)' },
  { value: '㎡', text: '平方メートル (㎡)' },
];

export const MODE = {
  Pan: 'pan',
  SELECT: 'select',
  FLOOR: 'floor',
  ICON: 'icon',
  DOOR: 'door',
  WALL: 'wall',
  STAIERS: 'stairs',
};
export const OBJECT_TYPE = {
  pan: '移動',
  floor: '床',
  icon: 'アイコン',
  door: 'ドア・窓',
  wall: '壁',
  stairs: '階段',
  text: 'テキスト',
};
export const WALL_TYPES = [
  { name: 'spandrel', label: '腰壁' },
  { name: 'falling', label: '下がり壁' },
  { name: 'handrail', label: '手すり' },
];
export const WALL_TYPE_LABELS = {};
export const DEFAULT_WALL_TYPE = 'spandrel';
export const DEFAULT_DOOR_TYPE = 'singleDoor';
export const DEFAULT_WALL_WIDTH = 20;

export const FLOOR_MODE = {
  RECT: 'rect',
  POLYGON: 'polygon',
  SPLIT: 'split',
};

export const UNIT = {
  mm: 'mm',
};

export const LENGTH_PER_PX = {
  // NOTE: 表示される長さが1000倍の値だった為、1/1000に修正
  mm: 9.1,
  m: 0.0091,
};

export const PAPER_SIZE = {
  A4: { width: 0.297, height: 0.21 },
  A3: { width: 0.42, height: 0.297 },
  A2: { width: 0.594, height: 0.42 },
};

export const PAPER_SCALE = [50, 100, 200, 300];

export const PAPER_SIZE_OPTIONS = [
  {
    value: 'A4',
    text: 'A4',
  },
  {
    value: 'A3',
    text: 'A3',
  },
  {
    value: 'A2',
    text: 'A2',
  },
];

export const PAPER_SCALE_OPTIONS = [
  {
    value: 50,
    text: '1/50',
  },
  {
    value: 100,
    text: '1/100',
  },
  {
    value: 200,
    text: '1/200',
  },
  // 300は不可
  // {
  //   value: 300,
  //   text: '1/300',
  // },
];

export const MATERIALS_SORT = {
  CROSS: '1',
  FLOORING: '2',
};

export const INTERIOR_MASTER_CATEGORY_TYPE = {
  PLANES: 1,
  DOORS: 2,
  WINDOWS: 3,
  STAIRS: 4,
};

// 建具追加情報 開き方向
export const ADDITIONAL_INFO_OPEN_TYPE = {
  INWARD: 1,
  OUTWARD: 2,
};
// 建具追加情報 ノブ方向
export const ADDITIONAL_INFO_KNOB_TYPE = {
  LEFT: 1,
  RIGHT: 2,
};
// 建具追加情報 引き方向
export const ADDITIONAL_INFO_SLIDING_TYPE = {
  LEFT: 1,
  RIGHT: 2,
};
// 階段追加情報 側桁 方向
export const ADDITIONAL_INFO_STRINGERS_TYPE = {
  NONE: { ID: 0, LOGICAL_NAME: '無し' },
  LEFT: { ID: 1, LOGICAL_NAME: '左' },
  RIGHT: { ID: 2, LOGICAL_NAME: '右' },
  DOUBLE: { ID: 3, LOGICAL_NAME: '左右' },
};

// マテリアルタイプ
export const MATERIAL_TYPE = {
  SINGLE_COLOR: 1,
  PATTERN: 2,
};

// 高さ初期値（メートル）
export const HEIGHT3D_DEFAULT = {
  WALL: 2.5,
  DOOR: 2.4,
  STEP: 0.15,
};

// バリデート値
export const VALIDATE_NUM = {
  TITLE_LENGTH_MAX: 40,
};

// ALLY-PROライセンス番号
export const ALLY_LICENSE = {
  PHOTO_SIMULATOR: { ID: 8, LOGICAL_NAME: 'フォトシミュレーター' },
  AUTO_SKETCH: { ID: 9, LOGICAL_NAME: 'ALLY オートスケッチ（部屋計測・間取図自動作成）' },
  AR_MEASUREMENT_APP: { ID: 10, LOGICAL_NAME: 'AR計測' },
  AR_SIMULATOR_APP: { ID: 10, LOGICAL_NAME: 'ALLY - ARシミュレーター' },
  VR_SIMULATOR: { ID: 9, LOGICAL_NAME: 'VRシミュレーター' }, // オートスケッチと同じ
};

// メッセージ
export const MESSAGE_TEXT = {
  NO_LICENSE_VR:
    'ライセンスが確認できないため、VRシミュレーターがご利用できません。\nライセンスを購入してください。',
  SAVE_TEXT_PLEASE_SELECT_PROPERTY: '右のボタンから物件を指定してください。',
  SAVE_TEXT_SAVING: '保存しています',
  SAVE_TEXT_SAVE_SUCCESS: '保存が完了しました。',
  SAVE_WARNING_PLANE_VIEW_MAX_PROPERTY: '他の物件または部屋番号を指定してください。',
  SAVE_WARNING_PLANE_VIEW_MAX_ROOM: ' ※これ以上新規保存はできません',
  SAVE_WARNING_LIMIT_ALERT: ' 枚を超える保存はできません。',
  SAVE_ERROR_VALIDATE: '保存に失敗しました\n入力内容をご確認ください',
  SAVE_ERROR_MAX: 'これ以上保存できません\n別の物件・部屋を指定してください',
  SAVE_ERROR_SAVE_FAILURE: '保存に失敗しました。',
  SELECT_WARNING_MOVE_LIMIT: '移動可能なのは一つのみです',
  SELECT_HELP_MULTIPLE_KEY: 'ctrlキーで複数選択ができます',
  DRAW_HELP_WALL_SELECT: '部屋周辺の壁を選択してください',
  DRAW_HELP_ROOM_INSIDE: '床を選択してください',
  DRAW_HELP_POLYGON_FINISH: '開始点をクリックして完了',
  DRAW_HELP_STAIR_FINISH: '終点をクリックして完了',
  ICON_HELP_DROP_ROOM_INSIDE: '部屋に向かってドロップしてください',
  PATTERN_TEXT_ADD: 'パターンを追加しました',
  PATTERN_TEXT_NEWNAME: 'パターン名を変更しました',
  PATTERN_TEXT_DELETE: 'パターンを削除しました',
  PATTERN_ERROR_LIMIT:
    'パターンを追加できませんでした\nパターンは最大' + SAVE_LIMIT.PATTERN + 'パターンまでです',
  PATTERN_ERROR_NEWNAME:
    'パターン名を変更できませんでした\n指定されたパターンが見つかりませんでした',
  PATTERN_ERROR_DELETE: 'パターンを削除できませんでした\n指定されたパターンが見つかりませんでした',
  PATTERN_ERROR_DELETE_ALL:
    'パターンを削除できませんでした\nパターンを全て削除することはできません',
  LAYER_ERROR_LIMIT:
    '階層の変更ができませんでした。\n階層は最大' + SAVE_LIMIT.LAYER + '階層までです',
  LAYER_ERROR_SAME_LENGTH: '階層の変更ができませんでした。\n既に指定された階数です',
  LAYER_WARNING_DELETE: '階層を減らすと最上階のデータから削除されます。\nよろしいですか？',
};
