<template>
  <CommonLayout title="サマリー" :companyLogo="companyLogo" :companyName="companyName">
    <template v-slot>
      <div
        style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5mm; padding: 25px 100px; font-size: 60px"
      >
        <div style="width: 100%;">
          <div :style="unitPrice">単位：円</div>
          <table :style="tableStyle">
            <thead>
              <tr>
                <th :style="[headerStyle, tableFont]" style="width: 33%;">項目</th>
                <th :style="[headerStyle, tableFont]" style="width: 33%;">合計</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(t, i) in totalList" :key="i">
                <td :style="[subHeaderStyle]" style="width: 33%;">{{ t.name }}</td>
                <td :style="[bodyStyle, col2Style]" class="col2" style="margin: 100%;">
                  {{
                    formatNum(t.total || t.amount > 0 ? t.total || t.amount : 0, {
                      floatLength: 0,
                      separation: true,
                      rountType: 'down',
                    })
                  }}
                </td>
              </tr>
              <tr>
                <td :style="[headerStyle, tableFont]" style="width: 33%;">合計</td>
                <td :style="[headerStyle, tableFont, col2Style]" style="width: 33%;">
                  {{
                    formatNum(investmentPlanResult.totalProjectCost, {
                      floatLength: 0,
                      separation: true,
                      rountType: 'down',
                    })
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <div style="font-size: 60px; margin-bottom: -130px;">資金計画</div>
          <div :style="unitPrice">単位：円</div>
          <table :style="tableStyle">
            <thead>
              <tr>
                <td :style="[headerStyle, tableFont]" style="width: 33%;">事業費</td>
                <td :style="[headerStyle, tableFont]" style="width: 33%;">値</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in totalProjectCostList" :key="i">
                <td :style="[subHeaderStyle]">
                  {{ item.name }}
                </td>
                <td :style="[bodyStyle, textRightStyle]">
                  {{
                    formatNum(item.amount, {
                      floatLength: 0,
                      separation: true,
                      rountType: 'down',
                    })
                  }}
                </td>
              </tr>
              <tr>
                <td :style="[headerStyle, tableFont]" style="width: 33%;">総事業費</td>
                <td
                  :style="[headerStyle, tableFont, textRightStyle]"
                  style="width: 33%; text-align: right;"
                >
                  {{
                    formatNum(totalProjectCostTotal, {
                      floatLength: 0,
                      separation: true,
                      rountType: 'down',
                    })
                  }}
                </td>
              </tr>
            </tbody>
          </table>

          <div style="font-size: 60px; margin-bottom: -130px; padding-top: 100px;">借入条件</div>
          <div :style="unitPrice">単位：円</div>
          <table class="mt-4" :style="tableStyle">
            <thead>
              <tr>
                <td :style="[headerStyle, tableFont]" style="width: 50%;">借入条件</td>
                <td :style="[headerStyle, tableFont]" style="width: 50%;">値</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td :style="[subHeaderStyle]">
                  {{ borrowingConditionsList.length != 0 ? borrowingConditionsList[0].name : '' }}
                </td>
                <td :style="[bodyStyle, textRightStyle]">
                  {{
                    formatNum(
                      borrowingConditionsList.length != 0 ? borrowingConditionsList[0].amount : 0,
                      {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      }
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td :style="[subHeaderStyle]">
                  {{ borrowingConditionsList.length != 0 ? borrowingConditionsList[1].name : '' }}
                </td>
                <td :style="[bodyStyle, textRightStyle]">
                  {{ borrowingConditionsList.length != 0 ? repaymentMethod : '' }}
                </td>
              </tr>
              <tr>
                <td :style="[subHeaderStyle]">
                  {{ borrowingConditionsList.length != 0 ? borrowingConditionsList[2].name : '' }}
                </td>
                <td :style="[bodyStyle, textRightStyle]">
                  {{
                    formatNum(
                      borrowingConditionsList.length != 0 ? borrowingConditionsList[2].amount : 0,
                      {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      }
                    )
                  }}
                  {{ borrowingConditionsList.length != 0 ? borrowingConditionsList[2].unit : '' }}
                </td>
              </tr>
              <tr>
                <td :style="[subHeaderStyle]">
                  {{
                    borrowingConditionsList.length != 0
                      ? borrowingConditionsList[3].name +
                        `(${borrowingConditionsList[3].items[0].name})`
                      : 0
                  }}
                </td>
                <td :style="[bodyStyle, textRightStyle]">
                  {{
                    borrowingConditionsList.length != 0
                      ? parseFloat(borrowingConditionsList[3].items[0].unit).toFixed(2) + ' %'
                      : 0
                  }}
                </td>
              </tr>
              <tr>
                <td :style="[subHeaderStyle]">
                  {{
                    borrowingConditionsList.length != 0
                      ? borrowingConditionsList[3].name +
                        `(${borrowingConditionsList[3].items[1].name})`
                      : ''
                  }}
                </td>
                <td :style="[bodyStyle, textRightStyle]">
                  {{
                    borrowingConditionsList.length != 0
                      ? parseFloat(borrowingConditionsList[3].items[1].unit).toFixed(2) + ' %'
                      : ''
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="width: 100%;  padding-left: 100px;">
          <div style="font-size: 60px; margin-bottom: -130px; padding-top: 40px;">預託金償還条件</div>
          <div :style="unitPrice">単位：円</div>
          <table class="mt-4" :style="tableStyle">
            <thead>
              <tr>
                <td :style="[headerStyle, tableFont]" style="width: 33%;">預託金償還条件</td>
                <td :style="[headerStyle, tableFont]" style="width: 33%;">値</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in depositRedemptionTermsList" :key="i">
                <td :style="[subHeaderStyle]">
                  {{ item.name }}
                </td>
                <td :style="[bodyStyle, textRightStyle]">
                  {{
                    formatNum(item.total || item.amount, {
                      floatLength: 0,
                      separation: true,
                      rountType: 'down',
                    })
                  }}{{ item.name == '償還期間' ? ' ' + borrowingConditionsList[2].unit : '' }}
                </td>
              </tr>
            </tbody>
          </table>
          <div style="font-size: 60px; margin-bottom: -10px; padding-top: 85px;">経費・係数</div>
          <div :style="unitPrice"></div>

          <table style="width: 100%;">
            <thead>
              <tr>
                <td :style="[headerStyle, tableFont]" style="width: 33%;">項目</td>
                <td :style="[headerStyle, tableFont]" style="width: 33%;">値</td>
              </tr>
            </thead>
          </table>
          <table style="width: 100%">
            <tbody>
              <tr v-for="(item, i) in result.expensesCoefficientsList.items" :key="i">
                <td :style="[subHeaderStyle]" style="width: 33%;">
                  {{ item.name }}
                </td>
                <td
                  :style="[bodyStyle, textRightStyle]"
                  style="width: 33%;"
                  v-if="item.unit != null"
                >
                  {{ item.unit }}
                </td>
                <td :style="[bodyStyle, textRightStyle]" style="width: 33%;" v-else>
                  {{
                    formatNum(item.amount, {
                      floatLength: 0,
                      separation: true,
                      rountType: 'down',
                    }) + ' 円'
                  }}
                </td>
              </tr>

              <tr>
                <td
                  :style="[partHeaderStyle, tableFont]"
                  style="width: 100%; text-align: center"
                  colspan="4"
                >
                  固定資産税・都市計画税
                </td>
              </tr>
              <tr v-for="(item, i) in result.fixedCityTax.items" :key="`fixedCity-${i}`">
                <td :style="[subHeaderStyle]">
                  {{ item.name }}
                </td>
                <td :style="[bodyStyle, textRightStyle]" v-if="item.unit != null">
                  {{ item.unit }}
                </td>
                <td :style="[bodyStyle, textRightStyle]" v-else>
                  {{
                    formatNum(item.amount, {
                      floatLength: 0,
                      separation: true,
                      rountType: 'down',
                    }) + ' 円'
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import Calculation1 from '@/views/InvestmentAnalytics/Input/Calculation';
  import Calculation2 from '@/views/InvestmentAnalytics/Result/Calculation';
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import {
    headerStyle,
    partHeaderStyle,
    subHeaderStyle,
    bodyStyle,
    titleStyle,
    unitPrice,
    tableFont,
    tableStyle,
    textRightStyle,
  } from '../../../../components/ExportReport/style';
  export default {
    mixins: [Calculation1, Calculation2],
    props: {
      companyLogo: String,
      companyName: String,
      borrowingConditionsList: Array,
      depositRedemptionTermsList: Array,
      totalProjectCostList: Array,
      totalProjectCostTotal: Number,
      repaymentMethod: String,
      investmentPlanResult: Object,
    },
    components: {
      CommonLayout,
    },
    data() {
      return {
        headerStyle,
        partHeaderStyle,
        subHeaderStyle,
        bodyStyle,
        titleStyle,
        unitPrice,
        tableFont,
        tableStyle,
        textRightStyle,
        col2Style: {
          'text-align': 'right',
        },
        result: null,
      };
    },
    created: function() {
      this.result = this.initialize();
    },
    computed: {
      totalList() {
        let result = [];
        result.push(this.investmentPlanResult.basePrice);
        result[0].name = '購入価格';
        result.push(this.investmentPlanResult.constructionCosts);
        result.push(this.investmentPlanResult.incidentalExpenses);

        return result;
      },
      propertyTaxBuildingTotal: function() {
        let total = 0;
        // 既存建物
        for (let i = 1; i <= this.investmentAnalytics.existingBuildingCount; i++) {
          total += Math.floor(Number(this.investmentAnalytics.initialInvestment.propertyTax[i]));
        }
        return total;
      },
    },
    methods: {
      initialize: function() {
        // 維持修繕費
        let MaintenanceRepairCosts = 0;
        // 軽微な修繕
        MaintenanceRepairCosts += this.minorRepairsTotal;
        // 消耗品
        MaintenanceRepairCosts += this.consumablesTotal;
        // ビルメンテナンス
        MaintenanceRepairCosts += this.buildingMaintenanceTotal;

        // 固定資産税・都市計画税
        let iv = this.investmentAnalytics.initialInvestment;
        let buildingTotal = 0;
        // 既存建物
        for (let i = 1; i <= this.investmentAnalytics.existingBuildingCount; i++) {
          // 固定資産税
          buildingTotal += Math.floor(Number(iv.propertyTax[i]));
          // 都市計画税
          buildingTotal += Math.floor(Number(iv.cityPlanningTax[i]));
        }
        // 新築建物
        for (let i = 1; i <= this.investmentAnalytics.newBuildingCount; i++) {
          // 固定資産税
          buildingTotal += Math.floor(Number(iv.propertyTax[10 + i]));
          // 都市計画税
          buildingTotal += Math.floor(Number(iv.cityPlanningTax[10 + i]));
        }

        let result = {
          expensesCoefficientsList: {
            name: '経費・係数',
            items: [
              {
                name: '賃料上昇率',
                amount: null,
                unit: Number(this.getRateOfIncrease('賃料-変動係数')).toFixed(2) + ' %',
              },
              {
                name: '当初の空室率（対満室家賃）',
                amount: null,
                unit: Number(this.getRateOfIncrease('空室率-当初')).toFixed(2) + ' %',
              },
              {
                name: this.getRateOfIncrease('空室率-変更年数') + '年目以降の空室率',
                amount: null,
                unit: Number(this.getRateOfIncrease('空室率-変更年数以降')).toFixed(2) + ' %',
              },
              {
                name: '維持修繕費',
                amount: Number(MaintenanceRepairCosts),
                unit: null,
              },
              {
                name: '維持修繕費上昇率',
                amount: null,
                unit: Number(this.getRateOfIncrease('維持費-変動係数')).toFixed(2) + ' %',
              },
              {
                name: '大規模改装費 15年目までに',
                amount:
                  Math.floor(Number(this.LargeScaleRenovationCosts(15))) > 0
                    ? Math.floor(Number(this.LargeScaleRenovationCosts(15)))
                    : 0,
                unit: null,
              },
              {
                name: '大規模改装費 30年目までに',
                amount:
                  Math.floor(Number(this.LargeScaleRenovationCosts(30))) > 0
                    ? Math.floor(Number(this.LargeScaleRenovationCosts(30)))
                    : 0,
                unit: null,
              },
              {
                name: '大規模改装費 45年目までに',
                amount:
                  Math.floor(Number(this.LargeScaleRenovationCosts(45))) > 0
                    ? Math.floor(Number(this.LargeScaleRenovationCosts(45)))
                    : 0,
                unit: null,
              },
              {
                name: '期待利回り',
                amount: null,
                unit: Number(this.getRateOfIncrease('割引率')).toFixed(2) + ' %',
              },
            ],
          },
          fixedCityTax: {
            name: '固定資産税・都市計画税',
            items: [
              {
                name: '土地部分',
                amount: Number(
                  Math.floor(Number(iv.propertyTax[0])) + Math.floor(Number(iv.cityPlanningTax[0]))
                ),
                unit: null,
              },
              {
                name: '変動率（土地部分）',
                amount: null,
                unit: Number(this.getRateOfIncrease('固都税-土地-変動係数')).toFixed(2) + ' %',
              },
              {
                name: '建物部分',
                amount: Number(
                  // 新築＋既存建物
                  Math.floor(buildingTotal) +
                    // 付属建物（固定資産税）
                    Math.floor(Number(iv.propertyTax[21])) +
                    // 付属建物（都市計画税）
                    Math.floor(Number(iv.cityPlanningTax[21])) +
                    // 償却資産（固定資産税のみ）
                    Math.floor(Number(iv.propertyTax[22]))
                ),
                unit: null,
              },
              {
                name: '変動率（建物：木造）',
                amount: null,
                unit: Number(this.getRateOfIncrease('固都税-建物-木造-変動係数')).toFixed(2) + ' %',
              },
              {
                name: '変動率（建物：軽量鉄骨造）',
                amount: null,
                unit:
                  Number(this.getRateOfIncrease('固都税-建物-軽量鉄骨造-変動係数')).toFixed(2) +
                  ' %',
              },
              {
                name: '変動率（建物：重量鉄骨造）',
                amount: null,
                unit:
                  Number(this.getRateOfIncrease('固都税-建物-重量鉄骨造-変動係数')).toFixed(2) +
                  ' %',
              },
              {
                name: '変動率（建物：鉄筋コンクリート造）',
                amount: null,
                unit:
                  Number(this.getRateOfIncrease('固都税-建物-鉄筋コンクリート造-変動係数')).toFixed(
                    2
                  ) + ' %',
              },
              {
                name: '変動率（建物：鉄骨鉄筋コンクリート造）',
                amount: null,
                unit:
                  Number(
                    this.getRateOfIncrease('固都税-建物-鉄骨鉄筋コンクリート造-変動係数')
                  ).toFixed(2) + ' %',
              },
            ],
          },
        };
        return result;
      },
    },
  };
</script>
