<template>
  <div>
    <ProperAnalytics
      title="ALLY 不動産活用診断"
      type="realEstate"
      :inputClass="realEstateInput"
    >
      <template #input="{ input, calcFlg }">
        <Input :inputValue="input" :calcFlg="calcFlg" />
      </template>
      <template #result="{ propertyList, commonInput }">
        <Report :propertyList="propertyList" :commonInput="commonInput" />
      </template>
    </ProperAnalytics>
  </div>
</template>
<script>
  import ProperAnalytics from '@/components/ProperAnalytics/ProperAnalytics';
  import Report from './Report';
  import Input from './Input';
  import realEstateInput from './inputClass';

  export default {
    components: {
      ProperAnalytics,
      Input,
      Report,
    },
    data() {
      return {
        realEstateInput: realEstateInput,
      };
    },
  };
</script>
