// @ts-check

import { onMounted, ref } from '@vue/composition-api';
import { FloorMaterialFetcher } from '../fetch/floorMaterials';

/** @type {() => any[]} */
export let getMaterials;

export const useFloorMaterials = () => {
  /** @type {import('@vue/composition-api').Ref<any[]>} */
  const floorMaterials = ref([]);
  getMaterials = () => floorMaterials.value;
  const isLoadingFloorMaterials = ref(false);

  onMounted(async () => {
    isLoadingFloorMaterials.value = true;
    const repo = new FloorMaterialFetcher();
    const result = await repo.listFloorMaterials();
    floorMaterials.value = result.map(v => ({
      ...v,
      url: v.url,
    }));

    isLoadingFloorMaterials.value = false;
  });

  return {
    floorMaterials,
    isLoadingFloorMaterials,
  };
};
