<template>
  <div
    class="report-page"
    style="margin: auto; background-image: url(https://allys3163848-dev.s3-ap-northeast-1.amazonaws.com/Master/report/reportCoverImage.png); background-repeat: no-repeat; background-size: 100% 100%"
    :style="style"
  >
    <div class="report-root" style="position: relative; height: 100%;">
      <div class="report-header-design " style="height: 50%;" :style="{
            'text-align': 'center',
            position: 'absolute',
            top: 500 * scale + 'px',
            left: 200 * scale + 'px',
          }">
        <div
          :style="{
            color: 'white',
            'font-size': 100 * scale + 'px',
            'padding-left': '2mm',
            'text-align': 'left',
            margin: '700px 0px 0px 300px',
          }"
        >
          報告日: {{ commonInput.reportDate }}
        </div>
        <div
          :style="{
            color: 'white',
            'font-size': 100 * scale + 'px',
            'padding-left': '2mm',
            'text-align': 'left',
            margin: '0px 0px 0px 300px',
          }"
        >
          お客様名: {{ commonInput.customerName }} 様
        </div>
      </div>
      <div :style="fontStyle" style="color: white;">
        {{ title }}
      </div>
      <div>
        <div
          :style="{
            'text-align': 'center',
            position: 'absolute',
            bottom: 250 * scale + 'px',
            left: 250 * scale + 'px',
          }"
        >
          <div
            v-for="(item, i) in companyInfoFixed"
            :key="i"
            :style="{
              display: 'flex',
              color: 'white',
              'font-size': 100 * scale + 'px',
            }"
          >
            <div style="white-space: nowrap; width:17%; margin: 0px 170px 0px 0px;">{{ item[0] }}</div>
            <div
              :style="{
                'font-size': 110
                 * scale + 'px',
                'margin-left': '18px',
              }"
            >
              {{ item[1] }}
            </div>
          </div>
        </div>
        <div
          :style="{
            position: 'absolute',
            bottom: 10 * scale + 'px',
            position: 10 * scale + 'px',
          }"
        >
          <img :src="companyLogo" alt="" width="250" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { REPORT_SCALE, REPORT_SIZE } from './const';
  import backImage from '../../assets/report/reportImages/reportCoverImage.png';
  import { AsYouType } from 'libphonenumber-js';

  export default {
    props: {
      title: String,
      commonInput: Object,
      companyInfo: Array,
      companyLogo: String,
      reportSize: {
        type: String,
        default: 'A4',
      },
    },
    data() {
      return {
        backImage,
        date: new Date().toLocaleDateString('ja'),
        REPORT_SCALE,
        scale: REPORT_SIZE[this.reportSize].scale,
      };
    },
    computed: {
      fontStyle(){  // COMPUTED: フォントのサイズを文字数(５文字ずつ（最大１５文字）)で調節する関数
        let fontSize = "1200%";
        let marginSize = "10% 4%";
        return {
                position: "absolute",
                margin: marginSize,
                'font-size': fontSize,
                width: "60%",
                'text-align': "left",
                top: "30%",
                };
      },
      style() {
        const { width, height } = REPORT_SIZE[this.reportSize];
        return {
          width: `calc(${width}mm * ${REPORT_SCALE})`,
          height: `calc(${height}mm * ${REPORT_SCALE})`,
        };
      },
      // 会社情報を修正
      companyInfoFixed() {
        const info = this.companyInfo;
        return info.map(inf => {
          if (inf[0] === '電話番号') {
            // 電話番号にハイフン追加
            try {
              const result = new AsYouType('JP').input(inf[1]);
              return [inf[0], result];
            } catch (error) {
              return inf;
            }
          } else if (inf[0] === '担当者名') {
            // 担当者名設定
            return ['担当者名', this.commonInput.staffName];
          }
          return inf;
        });
      },
    },
  };
</script>
<style scoped>
  .report-page {
    background-size: 100% 100%;
  }
</style>
