<template>
  <div
    class="report-page"
    :style="[
      style,
      {
        margin: '0 auto',
        position: 'relative',
        'background-image':
          'url(https://allys3163848-dev.s3-ap-northeast-1.amazonaws.com/Master/report/reportCommonImage3.png)',
        'background-repeat': 'no-repeat',
        'background-size': '100% 100%',
      },
    ]"
  >
    <div>
      <div
        class="report-header-design"
        :style="{
          'font-size': 200 * scale + 'px',
          'padding-left': 200 * scale + 'px',
          'padding-top': 120 * scale + 'px',
          'margin-bottom': -125 + 'px',
          height: '100%',
          'text-align': 'left',
        }"
      >
        {{ title }}
      </div>
    </div>
    <div :style="bodyStyle">
      <slot></slot>
    </div>
  </div>
</template>
<script>
  // import ReportHeader from '@/components/ExportReport/ReportHeader';
  import { REPORT_SCALE, REPORT_SIZE } from './const';
  // import ReportImage from './ReportImage';
  import logo from '@/assets/report/reportImages/logo.png';

  export default {
    props: {
      title: String,
      reportSize: {
        type: String,
        default: 'A4',
      },
    },
    components: {
      // ReportImage,
    },
    data() {
      return {
        logo,
        scale: REPORT_SIZE[this.reportSize].scale,
        width: REPORT_SIZE[this.reportSize].width,
        height: REPORT_SIZE[this.reportSize].height,
      };
    },
    computed: {
      style() {
        return {
          width: `calc(${this.width}mm * ${REPORT_SCALE})`,
          height: `calc(${this.height}mm * ${REPORT_SCALE} - 2px)`,
        };
      },
      bodyStyle() {
        return {
          width: '100%',
          height: '100%',
          color: 'black',
          'padding-top': 430 * this.scale + 'px',
          'padding-left': 180 * this.scale + 'px',
          'padding-right': 180 * this.scale + 'px',
        };
      },
    },
  };
</script>
<style scoped></style>
