var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-page",staticStyle:{"margin":"auto","background-image":"url(https://allys3163848-dev.s3-ap-northeast-1.amazonaws.com/Master/report/reportCoverImage.png)","background-repeat":"no-repeat","background-size":"100% 100%"},style:(_vm.style)},[_c('div',{staticClass:"report-root",staticStyle:{"position":"relative","height":"100%"}},[_c('div',{staticClass:"report-header-design ",staticStyle:{"height":"50%"},style:({
          'text-align': 'center',
          position: 'absolute',
          top: 500 * _vm.scale + 'px',
          left: 200 * _vm.scale + 'px',
        })},[_c('div',{style:({
          color: 'white',
          'font-size': 100 * _vm.scale + 'px',
          'padding-left': '2mm',
          'text-align': 'left',
          margin: '700px 0px 0px 300px',
        })},[_vm._v(" 報告日: "+_vm._s(_vm.commonInput.reportDate)+" ")]),_c('div',{style:({
          color: 'white',
          'font-size': 100 * _vm.scale + 'px',
          'padding-left': '2mm',
          'text-align': 'left',
          margin: '0px 0px 0px 300px',
        })},[_vm._v(" お客様名: "+_vm._s(_vm.commonInput.customerName)+" 様 ")])]),_c('div',{staticStyle:{"color":"white"},style:(_vm.fontStyle)},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',[_c('div',{style:({
          'text-align': 'center',
          position: 'absolute',
          bottom: 250 * _vm.scale + 'px',
          left: 250 * _vm.scale + 'px',
        })},_vm._l((_vm.companyInfoFixed),function(item,i){return _c('div',{key:i,style:({
            display: 'flex',
            color: 'white',
            'font-size': 100 * _vm.scale + 'px',
          })},[_c('div',{staticStyle:{"white-space":"nowrap","width":"17%","margin":"0px 170px 0px 0px"}},[_vm._v(_vm._s(item[0]))]),_c('div',{style:({
              'font-size': 110
               * _vm.scale + 'px',
              'margin-left': '18px',
            })},[_vm._v(" "+_vm._s(item[1])+" ")])])}),0),_c('div',{style:({
          position: 'absolute',
          bottom: 10 * _vm.scale + 'px',
          position: 10 * _vm.scale + 'px',
        })},[_c('img',{attrs:{"src":_vm.companyLogo,"alt":"","width":"250"}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }