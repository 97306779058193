// @ts-check

import { Rect } from '../../util/math/rect';
import { Transform } from '../../util/math/transform';
import { Vec } from '../../util/math/vector';
import { nextCycleIndex } from '../../util/util';
import { PvMeasurePart } from './measurePartNode';
import { createNode } from './pvNode';
import { PvObject } from './pvObject';

export class PvMeasure extends PvObject {
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'measure';
  constructor() {
    super();
  }

  /**
   * @param {PvObject[]} objs
   * @param {string} unit
   * @returns {PvMeasure}
   */
  static fromObjects(objs, unit) {
    const measure = createNode(this);
    const vertexes = objs
      .filter(o => o.type === 'floor')
      .map(o => { var v = o.absoluteVertexes();
                  var a = o.arcPoints;
                  var r = [];
                  for (let i = 0; i < v.length; i++) {
                    r.push(v[i]);
                    if (i < a.length && a[i] != 0) {
                      // 弧になっていたらベクトルを追加
                      let lineVec = { startPoint: v[i], endPoint: v[nextCycleIndex(i, v.length)] };
                      let dist = lineVec.endPoint.sub(lineVec.startPoint);
                      let arc = lineVec.startPoint.centerPoint(lineVec.endPoint).add(
                        dist
                          .normal()
                          .unit()
                          .scale(a[i])
                      );
                      r.push(arc);
                    }
                  }
                  return r;
                })
                .flat();
    const parts = [
      createNode(PvMeasurePart),
      createNode(PvMeasurePart),
      createNode(PvMeasurePart),
      createNode(PvMeasurePart),
    ];

    const rect = Rect.fromVecs(vertexes);
    measure.width = rect.width;
    measure.height = rect.height;
    const offset = new Vec(rect.x, rect.y);

    /** @type {number[][]} */
    const scaleList = [[], [], [], []];

    for (const vertex of vertexes) {
      const index = PvMeasure._judegeArea(
        vertex,
        new Vec(rect.x, rect.y),
        new Vec(rect.x + rect.width, rect.y + rect.height)
      );

      const next = nextCycleIndex(index, 4);
      switch (index) {
        case 0:
          scaleList[index].push(vertex.sub(offset).x);
          scaleList[next].push(vertex.sub(offset).y);
          break;
        case 1:
          scaleList[index].push(vertex.sub(offset).y);
          scaleList[next].push(rect.width - vertex.sub(offset).x);
          break;
        case 2:
          scaleList[index].push(rect.width - vertex.sub(offset).x);
          scaleList[next].push(rect.height - vertex.sub(offset).y);
          break;
        case 3:
          scaleList[index].push(rect.height - vertex.sub(offset).y);
          scaleList[next].push(vertex.sub(offset).x);
          break;
        default:
          throw new Error('invalid index');
      }
    }

    for (let i = 0; i < 4; i++) {
      const scales1 = Array.from(
        new Set([0, ...scaleList[i], i % 2 === 0 ? rect.width : rect.height])
      );
      scales1.sort((a, b) => a - b);
      parts[i].scales = scales1;
      parts[i].index = i;
      parts[i].unit = unit;
    }

    parts[1].transform = new Transform({
      translate: new Vec(rect.width, 0),
    });
    parts[2].transform = new Transform({
      translate: new Vec(rect.width, rect.height),
    });
    parts[3].transform = new Transform({
      translate: new Vec(0, rect.height),
    });

    measure.transform = new Transform({
      translate: new Vec(rect.x, rect.y),
    });
    measure.appendChildren(parts);

    return measure;
  }

  /**
   * targetがstart,endで定義される四角形のどのエリアにあるか判定
   * 右上から時計回りに0~3の数字を割り当てる
   * @param {Vec} target
   * @param {Vec} start
   * @param {Vec} end
   * @returns {number}
   */
  static _judegeArea(target, start, end) {
    const center = new Vec((end.x + start.x) / 2, (end.y + start.y) / 2);
    if (target.x > center.x && target.y <= center.y) {
      return 0;
    } else if (target.x > center.x && target.y > center.y) {
      return 1;
    } else if (target.x <= center.x && target.y > center.y) {
      return 2;
    } else if (target.x <= center.x && target.y <= center.y) {
      return 3;
    } else {
      throw new Error('aaaa');
    }
  }
}
