<template>
  <div id="LicensePurchase">
    <b-overlay :show="loading" opacity="0.25">
      ライセンス購入
      <b-container>
        <!-- 上部ボタン -->
        <b-row>
          <b-col>
            <b-navbar>
              <b-navbar-nav class="ml-auto">
                <router-link to="/License/Main" tag="button" class="btn btn-ally">
                  戻る
                </router-link>
              </b-navbar-nav>
            </b-navbar>
          </b-col>
        </b-row>
        <!-- ライセンス一覧 -->
        <b-row>
          <b-col>
            <b-table
              thead-tr-class="serch-data-table-header"
              tbody-tr-class="serch-data-table-body"
              striped
              bordered
              :items="licenseItems"
              :fields="fields"
              show-empty
            >
              <!-- カラム設定 -->
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.width }"
                />
              </template>
              <!-- 空の場合 -->
              <template v-slot:empty>
                <b-container v-if="!loading">
                  <b-row class="text-center">
                    <b-col>
                      <svg
                        class="bi bi-exclamation-diamond-fill"
                        width="8em"
                        height="8em"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                        />
                      </svg>
                      <b-alert id="alert" show>
                        ライセンスがありません
                      </b-alert>
                    </b-col>
                  </b-row>
                </b-container>
                <b-container v-else />
              </template>
              <!-- ライセンス名称 -->
              <template v-slot:cell(name)="row">
                <span>{{ row.item.name }}</span>
              </template>
              <!-- 単価表示 -->
              <template v-slot:cell(price)="row"> {{ row.item.price | currency }}円 </template>
              <!-- 数量入力 -->
              <template v-slot:cell(quantity)="row">
                <allyText
                  type="number"
                  class="number"
                  halfWidth
                  min="0"
                  :value="row.item.quantity"
                  @input="
                    val => {
                      licenseItems.splice(row.index, 1, {
                        ...row.item,
                        quantity: val,
                      });
                    }
                  "
                />
              </template>
              <!-- 金額計算 -->
              <template v-slot:cell(amount)="row">
                <b-container style="height:3rem;align-items:center;">
                  <!-- 購入額の行 -->
                  <b-row
                    style="display:grid;align-items:center;height:1.5rem;"
                    v-if="row.item.quantity > 0"
                  >
                    <b-col
                      :style="{
                        'margin-top': row.item.freePeriod > 0 ? '0' : '17px',
                      }"
                    >
                      <span class="text-right">
                        {{
                          (row.item.quantity *
                            (row.item.period.options[row.item.period.selected].price +
                              row.item.period.options[row.item.period.selected].tax))
                            | currency
                        }}円
                      </span>
                    </b-col>
                  </b-row>
                  <!-- 割引額の行 -->
                  <b-row style="height:1.5rem;" v-if="!loading && row.item.freePeriod > 0">
                    <b-col
                      style="display:inline-fle;align-items:center;-align:right;"
                      :style="{
                        'text-align': row.item.quantity > 0 ? 'right' : 'center',
                        'margin-top': row.item.quantity > 0 ? '0' : '12px',
                      }"
                    >
                      <b-badge pill class="shadow" variant="primary" style="width:fit-content;">
                        無料ライセンス
                      </b-badge>
                      <span style="color:red;" v-if="row.item.quantity > 0">
                        ▲{{
                          (row.item.quantity *
                            (row.item.period.options[row.item.period.selected].price +
                              row.item.period.options[row.item.period.selected].tax))
                            | currency
                        }}円
                      </span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <!-- 購入金額合計 -->
        <b-row class="total" v-if="total > 0">
          <b-col>
            <b>購入金額合計：&nbsp; {{ discountedTotal | currency }}{{ '円' }}</b>
          </b-col>
          <b-col>(税込)</b-col>
        </b-row>
        <!-- 次回更新日 -->
        <b-row class="total" v-if="totalWithoutTax > 0">
          <b-col>
            <b>{{ nextBillingText }}：&nbsp;{{ nextBillingDate }}</b>
          </b-col>
        </b-row>
        <!-- 下部ボタン -->
        <b-row v-if="totalWithoutTax > 0">
          <b-col>
            <b-navbar>
              <b-navbar-nav class="ml-auto">
                <b-button variant="ally" @click="purchaseLicense" :disabled="!paymentCheckFlag">
                  購入
                </b-button>
              </b-navbar-nav>
            </b-navbar>
          </b-col>
        </b-row>
      </b-container>
      <!-- ロード中 -->
      <template #overlay>
        <Load />
      </template>
    </b-overlay>

    <!-- モーダル -->
    <b-modal
      id="ProcessingPayment"
      title="ライセンス購入"
      header-class="ally-modal"
      footer-class="ally-modal"
      centered
      ok-only
      ok-variant="light"
      ok-title="OK"
      @ok="closeModal()"
    >
      ライセンス割当画面にて、購入したライセンスを割り当ててください。
    </b-modal>
    <b-modal
      id="ConfirmPurchaseModal"
      title="ライセンス購入"
      header-class="ally-modal"
      footer-class="ally-modal"
      centered
      size="lg"
      ok-variant="light"
      cancel-variant="light"
      cancel-title="キャンセル"
      @ok="confirmPurchase"
    >
      以下の内容でライセンスを購入します。
      <span v-if="payType == paymentTypeCreditCard.toString()">
        よろしいですか？
      </span>

      <b-table
        :items="confirmItems"
        bordered
        table-class="confirm-table"
        thead-tr-class="confirm-header"
        details-td-class="confirm-items"
      >
      </b-table>
      <span v-if="payType == paymentTypeBankTransfer.toString()">
        <br />
        期限までに下記口座へお振込みをお願いいたします。<br />
        振込手数料はお客様のご負担となります。<br />
        なお、期限までにお振込みいただけない場合、ライセンスは自動的に解約となります。
        <b-table
          :items="bankInfo"
          bordered
          table-class="confirm-table"
          thead-tr-class="confirm-header"
          details-td-class="confirm-items"
        >
        </b-table>
      </span>
    </b-modal>
  </div>
</template>

<!-- スクリプト -->
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { purchaseLicense } from '../../graphql/mutations';
  import Load from '../../components/Common/Load';
  import Common from './js/Common';
  import { Auth } from 'aws-amplify';
  import wordData from './js/wordData.js';
  API.configure(awsconfig);
  const zenginCode = require('zengin-code');
  var moment = require('moment-timezone');
  moment.tz.setDefault('Asia/Tokyo');

  // ライセンス一覧のヘッダ
  const headers = [
    {
      key: 'name',
      label: 'ライセンス名称',
      thClass: 'text-center',
      tdClass: 'text-left',
    },
    {
      key: 'price',
      label: '月額料金（税込）／１ライセンス',
      thClass: 'text-center',
      tdClass: 'text-right',
    },
    {
      key: 'quantity',
      label: '数量',
      thClass: 'text-center',
      tdClass: 'quantity-input',
      width: '10%',
    },
    {
      key: 'amount',
      label: '月額料金合計（税込）',
      thClass: 'text-center',
      tdClass: 'text-right',
    },
  ];
  export default {
    components: {
      Load,
    },
    mixins: [Common, wordData],
    data: function() {
      return {
        companyId: null, // ログインユーザーの会社ID
        fields: headers, // ライセンス一覧のヘッダ
        master: null, // マスタ
        company: {}, // 会社情報
        licenseItems: [], // ライセンス
        loading: true, // ロード中
        date: null, // 日
        paymentCheckFlag: false,
      };
    },

    mounted: async function() {
      const date = new Date(this.today);
      this.date = date.getDate();
      // MOUNTED: ログインユーザーの会社IDを取得
      this.companyId = this.getCompanyId();

      // MOUNTED: データ取得
      const result = await Promise.all([this.loadMaster(), this.getCompany(this.companyId)]);
      this.master = result[0];
      this.company = result[1];

      let items = [];
      this.master.forEach(m => {
        if (m.functions != null) {
          return;
        }
        if (m.id > 0) {
          // MOUNTED: 期間追加
          const options = [];
          m.prices.forEach(p => {
            if (p.period.unit == 'month') {
              options.push({
                value: 0,
                text: this.showPeriod(p.period),
                price: p.unitPrice,
                tax: p.tax,
                period: p.period,
              });
            }
          });

          // MOUNTED: ライセンス追加
          items.push({
            id: m == undefined ? '' : m.id,
            name: m == undefined ? '' : m.name,
            price: options[0].price + options[0].tax,
            period: { selected: 0, options: options },
            quantity: 0,
            amount: 0,
            freePeriod: this.calcFreePeriod(m.id),
          });
        }

        // ライセンス追加
        this.licenseItems = items;
      });
      this.paymentCheck();
      this.loading = false;
    },
    computed: {
      // COMPUTED: 定数：クレジットカード
      paymentTypeCreditCard() {
        return this.getPaymentType('CREDIT_CARD').no;
      },
      // COMPUTED: 定数：あおぞら銀行
      paymentTypeBankTransfer() {
        return this.getPaymentType('BANK_TRANSFER').no;
      },
      // COMPUTED: 定数：口座振替オンライン
      paymentTypeAccountTransferPaper() {
        return this.getPaymentType('ACCOUNT_TRANSFER_PAPER').no;
      },
      // COMPUTED: 定数：口座振替ペーパー
      paymentTypeAccountTransferOnline() {
        return this.getPaymentType('ACCOUNT_TRANSFER_ONLINE').no;
      },
      // COMPUTED: 支払い方法
      payType: function() {
        return this.company.payType?.toString?.() ?? '';
      },
      // COMPUTED: ライセンス(パックは除外)
      licenses() {
        return this.company?.license ?? [];
      },
      // COMPUTED: 購入済み機能
      paidFunctionsSet() {
        const set = new Set();
        const master = this.master;
        if (master == null) return set;

        this.licenses.forEach(li => {
          const functions = master.find(m => m.id === li.id)?.functions;
          if (functions) {
            functions.forEach(f => {
              set.add(f);
            });
          } else {
            set.add(li.id);
          }
        });

        return set;
      },
      // COMPUTED: 合計(初月度)(値引き含む)
      discountedTotal: function() {
        return this.total - this.totalFreePeriodDiscount;
      },
      // COMPUTED: 合計(初月度)(値引き含まず)
      total: function() {
        // 税抜合計＋税額合計
        return this.totalWithoutTax + this.totalTax;
      },
      // COMPUTED: 税抜合計
      totalWithoutTax: function() {
        return this.licenseItems.reduce((acc, cur) => {
          // 数量×単価の累計
          return acc + cur.quantity * cur.period.options[cur.period.selected].price;
        }, 0);
      },
      // COMPUTED: 税額合計
      totalTax: function() {
        return this.licenseItems.reduce((acc, cur) => {
          // 数量×税額の累計
          return acc + cur.quantity * cur.period.options[cur.period.selected].tax;
        }, 0);
      },
      // COMPUTED: 無料ライセンスの値引き分合計
      totalFreePeriodDiscount: function() {
        return this.licenseItems.reduce((acc, cur) => {
          if (cur.freePeriod > 0) {
            return (
              acc +
              cur.quantity * cur.period.options[cur.period.selected].price +
              cur.quantity * cur.period.options[cur.period.selected].tax
            );
          } else {
            return acc;
          }
        }, 0);
      },
      // COMPUTED: 合計(初月度)
      firstMonthTotal: function() {
        // 税抜合計(初月度)＋税額合計(初月度)
        return this.firstMonthWithoutTax + this.firstMonthTax;
      },
      // COMPUTED: 税抜合計(初月度)
      firstMonthWithoutTax: function() {
        return this.licenseItems.reduce((acc, cur) => {
          // 数量×単価の累計（無料考慮）
          return (
            acc +
            cur.quantity *
              cur.period.options[cur.period.selected].price *
              (cur.freePeriod > 0 ? 0 : 1)
          );
        }, 0);
      },
      // COMPUTED: 税額合計(初月度)
      firstMonthTax: function() {
        return this.licenseItems.reduce((acc, cur) => {
          // 数量×税額の累計（無料考慮）
          return (
            acc +
            cur.quantity *
              cur.period.options[cur.period.selected].tax *
              (cur.freePeriod > 0 ? 0 : 1)
          );
        }, 0);
      },
      /**
       *  COMPUTED: 次回決済日|引落日|振込期限
       * ※支払い方法が「クレジットカード」の場合
       * ※無料期間がある場合、その月数を加算
       */
      nextBillingDate: function() {
        // 購入ライセンスの無料期間の最小値を算出
        let freePeriod = 99;
        for (let item of this.licenseItems) {
          if (item.quantity > 0) {
            if (freePeriod > item.freePeriod) {
              freePeriod = item.freePeriod;
            }
          }
        }
        switch (this.payType) {
          // クレジットカード対応
          case this.paymentTypeCreditCard.toString():
            // 翌月27日＋無料期間
            return moment(this.today)
              .add(freePeriod + 1, 'months')
              .format('YYYY-MM-27')
              .replaceAll('-', '/');
          // あおぞら対応
          case this.paymentTypeBankTransfer.toString():
            return moment(this.today)
              .add(freePeriod + 1, 'months')
              .endOf('month')
              .format('YYYY-MM-27')
              .replaceAll('-', '/');
          // 口座振替対応
          case this.paymentTypeAccountTransferOnline.toString():
          case this.paymentTypeAccountTransferPaper.toString():
            // 翌月27日＋無料期間
            return moment(this.today)
              .add(freePeriod + 1, 'months')
              .format('YYYY-MM-27')
              .replaceAll('-', '/');
          default:
            return null;
        }
      },
      // COMPUTED: 次回支払日ラベル
      nextBillingText: function() {
        switch (this.payType) {
          // クレカ
          case this.paymentTypeCreditCard.toString():
            return '次回決済日';
          // あおぞら
          case this.paymentTypeBankTransfer.toString():
            return '次回振込期限';
          // 口座振替
          case this.paymentTypeAccountTransferOnline.toString():
          case this.paymentTypeAccountTransferPaper.toString():
            return '次回お引き落とし日';
          // その他
          default:
            return '???';
        }
      },
      // COMPUTED: 購入ライセンス
      purchaseLicenses: function() {
        let licenses = '';
        if (this.licenseItems) {
          for (let item of this.licenseItems.filter(v => {
            return v.quantity > 0;
          })) {
            licenses += item.name + ' × ' + item.quantity + '\n';
          }
        }
        return licenses;
      },
      // COMPUTED: 確認画面項目
      confirmItems: function() {
        return [
          { name: '購入ライセンス', value: this.purchaseLicenses },
          {
            name: '購入金額合計',
            value:
              (this.totalFirst == this.totalNormal
                ? this.totalFirst
                : this.totalFirst + '\r\n※無料トライアル期間終了後 ' + this.totalNormal) + '／月額',
          },
          {
            name: this.nextBillingText,
            value: this.nextBillingDate,
          },
        ];
      },
      // COMPUTED: 購入金額合計（初月度）
      totalFirst: function() {
        return (
          Number(this.firstMonthTotal).toLocaleString('ja-JP', { style: 'decimal' }) + ' 円(税込)'
        );
      },
      // COMPUTED: 購入金額合計（通常）
      totalNormal: function() {
        return Number(this.total).toLocaleString('ja-JP', { style: 'decimal' }) + ' 円(税込)';
      },
      // COMPUTED: 振込口座情報
      bankInfo: function() {
        const b = this.company ? this.company.bankTransfer : null;
        return !b
          ? []
          : [
              { name: '銀行名', value: this.getBankName(b) + ' (' + b.bankCode + ')' },
              { name: '支店名', value: this.getBranchName(b) + ' (' + b.branchCode + ')' },
              { name: '預金種別', value: '普通' },
              { name: '口座番号', value: b.accountNumber },
              { name: '口座名義', value: b.accountHolderName },
            ];
      },
    },
    methods: {
      // METHOD: 無料期間計算
      calcFreePeriod: function(id) {
        let result;
        // 購入履歴検索
        const found = this.company.license?.find(l => l.id == id);
        // 未購入の場合
        if (found === undefined) {
          // 購入日が１日
          if (this.date == 1) {
            result = 1; // 1ヶ月間無料
          }
          // 購入日が２日以降
          else {
            result = 2; // 2ヶ月間無料
          }
        }
        // 購入済の場合
        else {
          // 今月１日
          const thisMonth1st = moment(this.today)
            .date(1)
            .format('YYYY-MM-DD');
          // 先月１日
          const lastMonth1st = moment(this.today)
            .subtract(1, 'months')
            .date(1)
            .format('YYYY-MM-DD');
          // 購入日
          const purchaseDate = moment(found.initialPurchaseDate);
          // 購入日が今月２日以降
          if (purchaseDate.isAfter(thisMonth1st)) {
            result = 2; // 2ヶ月間無料
          }
          // 購入日が前月２日以降
          else if (purchaseDate.isAfter(lastMonth1st)) {
            result = 1; // 1ヶ月間無料
          }
          // 購入日が前月１日以降
          else {
            result = 0; // 無料期間無し(=有料)
          }
        }
        // 結果を返す
        return result;
      },
      // METHOD: 無料期間以内か
      isWithinFreePeriod(firstPurchaseDate, date) {
        const add = date === 1 ? 0 : 1;
        const expireDate = moment(firstPurchaseDate)
          .add(add, 'months')
          .endOf('month')
          .format('YYYY-MM-DD');
        return moment(this.today).isSameOrBefore(expireDate);
      },
      // METHOD: 銀行名取得
      getBankName: function(b) {
        try {
          return zenginCode[b.bankCode].name;
        } catch {
          return b.bankName;
        }
      },
      // METHOD: 支店名取得
      getBranchName: function(b) {
        try {
          return zenginCode[b.bankCode].branches[b.branchCode].name;
        } catch {
          return b.branchName;
        }
      },
      // METHOD: 次回課金日
      billingDate: function(period) {
        // 今日＋指定期間－１日
        return moment(this.today)
          .add(period.quantity, period.unit + 's')
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
      },
      // METHOD: 購入ボタン押下
      purchaseLicense: async function() {
        this.$bvModal.show('ConfirmPurchaseModal');
      },
      // METHOD: 購入確認OK
      confirmPurchase: async function() {
        this.loading = true;

        try {
          const licenses = this.licenseItems
            .filter(v => {
              return v.quantity > 0;
            })
            .map(l => {
              const target = l.period.options[l.period.selected];
              return {
                id: l.id,
                quantity: l.quantity,
                unitPrice: target.price,
                tax: target.tax * l.quantity,
                freePeriod: l.freePeriod,
              };
            });

          const purchaseLicenseResult = await API.graphql(
            graphqlOperation(purchaseLicense, {
              input: {
                companyId: this.companyId,
                date: moment(this.today).format('YYYY-MM-DD'),
                licenses,
              },
            })
          );

          // ログインユーザーのライセンス情報を更新する。
          const user = await Auth.currentAuthenticatedUser();
          user.getUserAttributes((err, attributes) => {
            const licenses = attributes.find(v => v.Name == 'custom:licenses')?.Value;
            if (licenses) {
              this.$store.state.user.attributes['custom:licenses'] = licenses;
            }
          });

          const { statusCode } = purchaseLicenseResult.data.purchaseLicense;
          if (statusCode == 200) {
            this.$bvModal.show('ProcessingPayment');
          } else {
            this.showError('E0001');
          }
        } catch (error) {
          console.error(error);
          this.showError('E0001', error);
        } finally {
          this.loading = false;
        }
      },
      // METHOD: モーダルクローズ
      closeModal() {
        // ライセンス割当画面へ遷移
        this.$router.push('/License/Assign');
      },
      // METHOD: 未納状態のライセンスがあるか確認
      async paymentCheck() {
        if (this.company.payment != null) {
          if (this.company.payment.length > 0) {
            let paymentValids = this.company.payment.filter(val => {
              let timeFlag = moment(this.today).format('YYMM');
              return val.paymentResult == false && parseInt(val.id) < parseInt(timeFlag);
            });
            this.paymentCheckFlag = paymentValids.length == 0;
            return;
          }
        }
        this.paymentCheckFlag = true;
      },
    },
  };
</script>
<style scoped>
  #LicensePurchase >>> nav {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
  }
  #LicensePurchase >>> nav > ul > button {
    margin-left: 0.5rem;
  }
  #alert {
    background-color: transparent;
    border: none;
  }
  #LicensePurchase >>> select {
    font-size: 0.875rem;
  }
  #LicensePurchase >>> .number .form-group {
    margin: 0;
  }
  #LicensePurchase >>> .number .form-group input[type='number'] {
    height: 30px;
    padding-left: 0;
    padding-right: 0.5rem;
    text-align: right;
  }
  #LicensePurchase >>> td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .total {
    text-align: right;
    font-size: x-large;
    padding-top: 8px;
    margin: 0;
  }
  .total > div:nth-child(1) {
    padding-right: 0;
  }
  .total > div:nth-child(2) {
    padding: 0;
    font-size: medium;
    display: grid;
    align-items: center;
    max-width: 3rem;
  }
  .total .first-month .sub {
    font-size: medium;
  }
  #LicensePurchase >>> .badge-pill {
    border-radius: 5px;
  }
  #LicensePurchase >>> .checkbox-column {
    padding: 0;
  }
  #LicensePurchase >>> .checkbox-column > div {
    padding-left: 2.5rem;
  }
  #LicensePurchase >>> .custom-control-input:checked ~ .custom-control-label::before {
    background: var(--colorTheme);
    border-color: var(--colorTheme);
  }
  .confirm-table {
    margin-top: 0.5rem;
  }
  .confirm-table >>> .confirm-header {
    display: none;
  }
  .confirm-table >>> td {
    white-space: pre-wrap;
    font-size: large;
    line-height: 150%;
    padding: 0.5rem 1rem;
  }
  .confirm-table >>> td:first-child {
    color: white;
    background-color: var(--colorTheme);
  }
  .bank-info .row {
    min-height: 2rem;
    border-top: 1px solid gainsboro;
  }
  .bank-info .row:last-child {
    border-bottom: 1px solid gainsboro;
  }
  .bank-info .row > div {
    display: grid;
    align-items: center;
    border-left: 1px solid gainsboro;
    word-break: break-all;
  }
  .bank-info .row > div:first-child {
    color: white;
    background-color: var(--colorTheme);
  }
  .bank-info .row > div:last-child {
    border-right: 1px solid gainsboro;
  }
  .bank-info .col {
    font-size: large;
  }
</style>
<style>
  #ProcessingPayment .modal-dialog {
    min-width: fit-content;
  }
</style>
