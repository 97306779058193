<!--
作成者：Park chongmyung
作成日：2020.9.15
画面名：チェックボックスフォーム
機能：チェックボックスコンポネント
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <validation-provider :name="title" :rules="rules" v-slot="{ valid, errors }">
    <b-form-group :label-for="name">
      <label v-if="title" style="display: flex;">
        {{ title }}
        <allyBadge :mode="hideBadge ? 'View' : mode" :rules="rules" />
      </label>
      <b-form-checkbox-group
        :id="name"
        v-model="result"
        :options="items"
        :disabled="mode == 'View'"
        class="ally-checkbox"
      ></b-form-checkbox-group>
      <b-form-invalid-feedback :state="state(valid, errors)">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
  export default {
    //name: 物理名
    //mode: View/Edit/Add
    //value: 値
    //title: タイトル
    //items: 選択オプション
    //loading: ロード中プラグ
    //hideBadge: バッジ非表示
    props: ['name', 'mode', 'value', 'title', 'items', 'loading', 'rules', 'hideBadge'],
    data: function() {
      //初期化
      return { result: this.value == null ? [] : this.value };
    },
    methods: {
      state: function(valid, errors) {
        if (
          (errors[0] ||
            (this.rules && this.result) ||
            (this.rules && this.rules.includes('required') && this.result)) &&
          this.mode != 'View'
        ) {
          return errors ? false : valid ? true : null;
        }
        return null;
        // valid status
      },
    },
    watch: {
      value: function() {
        this.result = this.value;
      },
      result: function() {
        // 値を変更すると値を返上
        this.$emit('input', this.result);
      },
      loading: function() {
        // ロードが終わったら値を初期化
        if (this.loading === false) this.result = this.value;
      },
    },
  };
</script>

<style scoped>
  .ally-checkbox {
    background-color: white;
    width: 100%;
  }
</style>
