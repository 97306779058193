<template>
  <div class="azimuth-input" v-if="isEditor && isAzimuthInput">
    <div>
      方位角：<input
      type="number"
      max="180"
      min="-180"
      style="width:5em;"
      :value="azimuthInput"
      @input="e => {
        let val = parseFloat(e.srcElement.value);
        if (val > 180) val = 180;
        if (val < -180) val = -180;
        $emit('change-azimuth', val);
      }"
      >&nbsp;度
    </div>
    <div>
      <input
        type="range"
        max="180"
        min="-180"
        :value="azimuthInput"
        @input="e => {
          let val = parseFloat(e.srcElement.value);
          $emit('change-azimuth', val);
        }"
      >
    </div>
  </div>
</template>
<script>
  import { ref, defineComponent } from '@vue/composition-api';
  import TileBtn from './PvBtn.vue';

  export default defineComponent({
    props: {
      azimuthInput: {
        type: Number,
        required: true,
      },
      isAzimuthInput: {
        type: Boolean,
        default: false,
      },
      isEditor: {  //  PROP: 編集権限の有無
        type: Boolean,
        default: true,
      }
    },
    setup() {
    },
  });
</script>
<style scoped>
  .azimuth-input {
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 5px;
    right: 50px;
  }
</style>