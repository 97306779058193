<template>
  <text
    :data-id="obj.id"
    data-area="object"
    :text-anchor="obj.style.textAnchor"
    :text-length="obj.style.textLength"
    :length-adjust="obj.style.textAdjust"
    :font-size="obj.style.fontSize"
    fill="black"
    stroke="grey"
    stroke-width="1"
  >
    {{ obj.text }}
  </text>
</template>
<script>
  // @ts-check
  import { defineComponent } from '@vue/composition-api';

  export default defineComponent({
    props: {
      obj: Object, // PvObject
    },
    setup() {},
  });
</script>
