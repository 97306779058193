<template>
  <CommonLayout title="Ⅱ．物件の概要">
    <template v-slot>
      <ReportSubTitle subTitle="用途地域" />
      <ReportCard style="margin-top: 150px; margin-left: 2px; padding-bottom: 150px;">
        <div style="display: grid; grid-template-columns: 1fr; font-size: 60px;">
          <div
            style="display: grid; grid-template-columns: 0.5fr 1fr 2fr 4fr; font-weight: bold; font-size: 70px; margin-bottom: 50px;"
          >
            <div
              v-for="(head, index) in header"
              :key="index"
              style="border-bottom: solid 5px #1d1d63; text-align: center;"
            >
              {{ head.label }}
            </div>
          </div>
          <div
            v-for="(item, index) in items"
            :key="index"
            style="display: grid; grid-template-columns: 0.4fr 1.2fr 2fr 3.1fr; padding: 7.5px"
          >
            <div v-for="(col, _, i) in item" :key="i" style="border-bottom: solid #1d1d63; padding-left: 40px;">
              <div
                v-if="item.type === zoningDistrict && i === 0"
                style="font-size: 65px; color: red; padding-left: 50px;"
              >
                ✓
              </div>
              <span v-else>
                {{ col }}
              </span>
            </div>
          </div>
        </div>
      </ReportCard>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import ReportSubTitle from '@/components/ExportReport/ReportSubTitle';
  import noImage from '@/assets/report/reportImages/noimage.png';
  import ReportCard from '@/components/ExportReport/ReportCard';

  export default {
    props: {
      realEstateInput: Object,
    },
    components: {
      CommonLayout,
      ReportSubTitle,
      ReportCard,
    },
    data() {
      return {
        zoningDistrict: '',
        noImage: noImage,
        header: [
          { name: 'match', label: '', width: 2 },
          { name: 'type', label: '種別', width: 2 },
          { name: 'purpose', label: '目的', width: 2 },
          { name: 'overView', label: '用途地域の概要', width: 4 },
        ],
        items: [
          {
            match: '',
            type: '第一種低層住宅専用地域',
            purpose: '低層住宅の良好な住環境の保護',
            overView: '50㎡までの小規模な店舗兼住宅や小中学校等も可',
          },
          {
            match: '',
            type: '第二種低層住宅専用地域',
            purpose: '主に低層住宅用',
            overView: '150㎡以内の日常生活に必要な店舗（コンビニ等）も可',
          },
          {
            match: '',
            type: '第一種中高層住宅専用地域',
            purpose: '中高層住宅の良好な住環境の保護',
            overView: '500㎡以内の一定の店舗や中規模な公共施設、病院、大学等も可',
          },
          {
            match: '',
            type: '第二種中高層住宅専用地域',
            purpose: '中高層住宅の良好な住環境の保護',
            overView: '1,500㎡までの一定の店舗や事務所等も可',
          },
          {
            match: '',
            type: '第一種住居地域',
            purpose: '住環境を守りつつ一定の利用も',
            overView: '3,000㎡までの店舗・事務所・ホテル等や、ごく小規模な工場も可',
          },
          {
            match: '',
            type: '第二種居住地域',
            purpose: '住宅と商業施設等の共存',
            overView: '大規模な店舗、カラオケボックス、パチンコ等も可',
          },
          {
            match: '',
            type: '準居住地域',
            purpose: '幹線道路沿いで自動車関連施設等との共存',
            overView: '小規模な映画館、車庫、倉庫等も可',
          },
          {
            match: '',
            type: '田園住居地域',
            purpose: '農業利用と調和した低層住宅の良好な住環境の保護',
            overView: '低層住居専用地域に建設可能なものや500㎡までの農業用施設も可',
          },
          {
            match: '',
            type: '近隣商業地域',
            purpose: '日用品の買い物など',
            overView:
              'ほどんどの商業施設・事務所のほか、住宅・店舗・ホテル・パチンコ屋・カラオケボックス・映画館・車庫・小規模の工場も可',
          },
          {
            match: '',
            type: '商業地域',
            purpose: '銀行・映画館・飲食店・事務所等',
            overView: '高層ビルや風俗施設等、工場関係以外はほぼ何でも可',
          },
          {
            match: '',
            type: '準工業地域',
            purpose: '軽工業主体の工場やサービス施設等',
            overView: '住居や商店も可（花火工場やコンビナートは不可）',
          },
          {
            match: '',
            type: '工業地域',
            purpose: '主に工場用',
            overView: '住宅や店舗も可（学校、病院、ホテルは不可）',
          },
          {
            match: '',
            type: '工業専用地域',
            purpose: '工場専用',
            overView: '住宅、店舗、学校、病院、ホテルも不可',
          },
          {
            match: '',
            type: '無指定区域',
            purpose: '都市計画区域内において区域分けがされていない区域',
            overView:
              '接道義務を満たせば家は建設可　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　（未開発で水道、ガス等も自分で引く必要がある場合もあり）',
          },
          {
            match: '',
            type: '都市計画区域外',
            purpose: '都市計画が定められていない区域',
            overView:
              '農地法、自然公園法等の規制が無ければ家は建設可　　　　　　　　　　　　　　　　　　　　　　　　　　　　　（未開発の原野のような場所も多いので現実的には建てられない場合もある）',
          },
        ],
      };
    },
    mounted() {
      this.zoningDistrict = this.realEstateInput.siteConditions.siteInformation.zoningDistrict;
    },
  };
</script>
