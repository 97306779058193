//Import
// vue
import Vue from 'vue';
import Router from 'vue-router';
// vue
import Login from '../views/Main/Login.vue';
import DashBoard from '../views/Main/DashBoard';
import AccountList from '../views/Account/List';
import AccountViewer from '../views/Account/View';
import AccountRestore from '../views/Account/Restore';
import ReportList from '../views/ReportList/List';
import CustomerList from '../views/Customer/List';
import CustomerViewer from '../views/Customer/View';
import CompanyList from '../views/Company/List';
import CompanyViewer from '../views/Company/View';
import MyPageViewer from '../views/Mypage/View';
import InheritanceTax from '../views/InheritanceTax/Main';
import RealEstateSuitability from '../views/RealEstateSuitability/Main';
import Roa from '../views/Roa/Main';
import InvestmentAnalyticsMain from '../views/InvestmentAnalytics/Main';
import NotFound from '../views/Main/Error';
import ManualViewer from '../views/Manual/View';
import MasterViewer from '../views/Master/View';
import PropertyList from '../views/Property/List';
import PropertyViewer from '../views/Property/View';
import PropertyRoomViewer from '../views/Property/roomView';
import WallPaperSimulator from '../views/Property/WallpaperSimulator/Main';
import WallPaperSimulatorNewMain from '../views/Property/WallpaperSimulator/NewSimulatorMain';
import PlaneList from '../views/Property/PlaneView/List';
import PlaneViewHistory from '../views/Property/PlaneView/History';
import PlaneView from '../views/Property/PlaneView/View';
import AllyVR from '../views/AllyVR/Main';
// Module parse failed: Unexpected token (1:0) You may need an appropriate loader to handle this file type, currently no loaders are configured to process this file. See https://webpack.js.org/concepts#loaders
// (モジュールの解析に失敗しました: 予期しないトークンです(1:0)。このファイル タイプを処理するには、適切なローダーが必要な場合があります。現在、このファイルを処理するように構成されているローダーはありません。https://webpack.js.org/concepts#loaders を参照してください)
// import AllyVRWebGL from '../../public/planeView/vr/index.html';
import History from '../views/Property/PlaneView/History';
import DeleteCompany from '../views/DeleteCompany/List';
import DeleteCompanyView from '../views/DeleteCompany/View';
import LicenseMain from '../views/License/Main';
import LicensePurchase from '../views/License/Purchase';
import LicenseCancel from '../views/License/Cancel';
import LicenseAssign from '../views/License/Assign';
import EditorMain from '../views/PlaneView/EditorMain.vue';
import SalesList from '../views/SalesList/View';

import PaymentMethod from '../views/License/PaymentMethod';

//メンテナンスページ
import Maintenance from '../views/Main/Maintenance/Main';

// store
import store from '../store';
import utility from '../mixins/utility';

// Amplify読み込み
import { AmplifyEventBus } from 'aws-amplify-vue';
import { AmplifyPlugin } from 'aws-amplify-vue';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import awsconfig from '../aws-exports';
import axios from 'axios';
import { isRegisteredCard } from '@/graphql/queries';
// master取得
import loadMaster from '@/components/Common/Master/LoadMaster';
import InheritanceMasterLoad from '@/components/Common/Master/InheritanceMasterLoad';
import loadMaintenance from '@/components/Common/Master/loadMaintenance';
// Common
import Common from '../views/License/js/Common';

/**
 * 定数
 */
const CREDIT_CARD = '0'; // クレジットカード
const PRIVILEGED = 'privileged'; // 非決済モード

// 変数宣言
// 必要変数をセットアップ及びロードする

Vue.use(Router);
Vue.use(AmplifyPlugin, AmplifyModules);
Amplify.configure(awsconfig);

// Adminフラグ
var authLevel = null;
// ユーザセッション情報をロードするためCognitoと通信
const COGNITO_URL = `https://cognito-idp.${awsconfig.aws_project_region}.amazonaws.com/${awsconfig.aws_user_pools_id}`;

// ルーティング設定
// Routerの経路を指定

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
    //各ページのルーティング設定
    {
      path: '/Login',
      name: 'Login',
      component: Login,
      meta: {
        plainLayout: true,
      },
    },
    {
      path: '/DashBoard',
      name: 'DashBoard',
      component: DashBoard,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Account',
      redirect: '/Account/List',
    },
    {
      path: '/Account/List',
      name: 'Account List',
      component: AccountList,
      meta: {
        requiresAuth: true,
        enableUser: ['systemAdmin', 'admin'],
      },
    },
    {
      path: '/Account/Add/:newSerialNumber',
      name: 'Account Add',
      component: AccountViewer,
      meta: {
        requiresAuth: true,
        enableUser: ['systemAdmin', 'admin'],
      },
    },
    {
      path: '/Account/View/:id',
      name: 'Account View',
      component: AccountViewer,
      meta: {
        requiresAuth: true,
        enableUser: ['systemAdmin', 'admin'],
      },
    },
    {
      path: '/Account/Edit/:id',
      name: 'Account Edit',
      component: AccountViewer,
      meta: {
        requiresAuth: true,
        enableUser: ['systemAdmin', 'admin'],
      },
    },
    {
      path: '/Account/Restore',
      name: 'Account Restore',
      component: AccountRestore,
      meta: {
        requiresAuth: true,
        enableUser: ['admin'],
      },
    },
    {
      path: '/Report',
      redirect: '/Report/List',
    },
    {
      path: '/Report/List',
      name: 'Report List',
      component: ReportList,
      meta: {
        requiresAuth: true,
        enableUser: ['admin', 'member'],
      },
    },
    {
      path: '/Customer',
      redirect: '/Customer/List',
    },
    {
      path: '/Customer/List',
      name: 'Customer List',
      component: CustomerList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Customer/Add',
      name: 'Customer Add',
      component: CustomerViewer,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Customer/View/:id',
      name: 'Customer View',
      component: CustomerViewer,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Customer/Edit/:id',
      name: 'Customer Edit',
      component: CustomerViewer,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Company',
      redirect: '/Company/List',
    },
    {
      path: '/Company/List',
      name: 'Company List',
      component: CompanyList,
      meta: {
        requiresAuth: true,
        enableUser: ['systemAdmin'],
      },
    },
    {
      path: '/Company/Add',
      name: 'Company Add',
      component: CompanyViewer,
      meta: {
        enableUser: ['systemAdmin'],
      },
    },
    {
      path: '/Company/View/:id',
      name: 'Company View',
      component: CompanyViewer,
      meta: {
        requiresAuth: true,
        enableUser: ['systemAdmin'],
      },
    },
    {
      path: '/Company/Edit/:id',
      name: 'Company Edit',
      component: CompanyViewer,
      meta: {
        requiresAuth: true,
        enableUser: ['systemAdmin'],
      },
    },
    {
      path: '/MyCompany/View/',
      name: 'MyCompany View',
      component: CompanyViewer,
      meta: {
        requiresAuth: true,
        enableUser: ['admin'],
      },
    },
    {
      path: '/MyCompany/Edit/:serchName',
      name: 'MyCompany Edit',
      component: CompanyViewer,
      meta: {
        requiresAuth: true,
        enableUser: ['admin'],
      },
    },
    {
      path: '/Mypage',
      redirect: '/Mypage/View',
    },
    {
      path: '/Mypage/View',
      name: 'Mypage View',
      component: MyPageViewer,
      props: route => ({ id: route.query.id }),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Mypage/Edit',
      name: 'Mypage Edit',
      component: MyPageViewer,
      props: route => ({ id: route.query.id }),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Manual',
      redirect: '/Manual/View',
    },
    {
      path: '/Manual/View',
      name: 'Manual View',
      component: ManualViewer,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/InheritanceTax/Main',
      name: 'InheritanceTax Main',
      component: InheritanceTax,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Roa/Main',
      name: 'Roa Main',
      component: Roa,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/RealEstateSuitability/Main',
      name: 'RealEstateSuitability Main',
      component: RealEstateSuitability,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/InvestmentAnalytics/Main',
      name: 'InvestmentAnalytics Main',
      component: InvestmentAnalyticsMain,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Master',
      redirect: '/Master/View',
    },
    {
      path: '/Master/View',
      name: 'Master View',
      component: MasterViewer,
      meta: {
        requiresAuth: true,
        enableUser: ['systemAdmin'],
      },
    },
    {
      path: '/Property',
      redirect: '/Property/List',
    },
    {
      path: '/Property/List',
      name: 'Property List',
      component: PropertyList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Property/Add',
      name: 'Property Add',
      component: PropertyViewer,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Property/Edit/:id',
      name: 'Property Edit',
      component: PropertyViewer,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Property/View/:id',
      name: 'Property View',
      component: PropertyViewer,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Property/View/:id/RoomView/:roomId',
      name: 'Room View',
      component: PropertyRoomViewer,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Property/:id/WallPaperSimulator',
      redirect: '/Property/:id/WallPaperSimulator/List',
    },
    {
      path: '/Property/:id/WallPaperSimulator/List',
      name: 'WallPaperSimulator List',
      component: WallPaperSimulator,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Property/new/WallPaperSimulator/Edit/new',
      name: 'WallPaperSimulatorNewMain Edit',
      component: WallPaperSimulatorNewMain,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Property/:id/WallPaperSimulator/Add',
      name: 'WallPaperSimulator Add',
      component: WallPaperSimulator,
      meta: {
        requiresAuth: true,
        plainLayout: true,
      },
    },
    {
      path: '/Property/:id/WallPaperSimulator/Edit/:image',
      name: 'WallPaperSimulator Edit',
      component: WallPaperSimulator,
      meta: {
        requiresAuth: true,
        plainLayout: true,
      },
    },
    {
      path: '/Property/:id/PlaneView/List',
      name: 'PlaneView List',
      component: PlaneList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Property/:id/PlaneView/History',
      name: 'PlaneView History',
      component: PlaneViewHistory,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/PlaneView/Edit/:planeId?',
      name: 'Property PlaneEdit',
      component: EditorMain,
      meta: {
        requiresAuth: true,
        plainLayout: true,
      },
    },
    {
      path: '/PlaneView/View/:planeId?',
      name: 'Property PlaneEdit View',
      component: EditorMain,
      meta: {
        requiresAuth: true,
        plainLayout: true,
      },
    },
    {
      path: '/AllyVR',
      name: 'AllyVR Main',
      component: AllyVR,
      meta: {
        requiresAuth: true,
        plainLayout: true,
      },
    },
    // {
    //   path: '/planeView/vr/index.html',
    //   name: 'AllyVR WebGL',
    //   component: AllyVRWebGL,
    //   meta: {
    //     requiresAuth: true,
    //     plainLayout: true,
    //   },
    // },
    {
      path: '/Property/:id/RoomAdd',
      name: 'Property PlaneRoomAdd',
      component: PlaneView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Property/:id/RoomEdit',
      name: 'Property PlaneRoomEdit',
      component: PlaneView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/Property/:id/History',
      name: 'Plane History',
      component: History,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/InheritanceTax',
      name: 'InheritanceTax/Input',
      component: InheritanceTax,
      meta: {
        requiresAuth: true,
      },
    },
    //システム共通
    {
      path: '/SignOut',
      name: 'SingOut',
      meta: {
        signOutFlag: true,
      },
      redirect: '/Login',
    },
    {
      path: '/DeleteCompany',
      name: 'DeleteCompany',
      component: DeleteCompany,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/DeleteCompany/View/:id',
      name: 'DeleteCompany/View',
      component: DeleteCompanyView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/License',
      redirect: '/License/Main',
    },
    {
      path: '/License/Main',
      name: 'LicenseMain',
      component: LicenseMain,
      meta: {
        requiresAuth: true,
        enableUser: ['admin'],
        disabledGroups: [PRIVILEGED],
      },
    },

    {
      path: '/License/Purchase',
      name: 'LicensePurchase',
      component: LicensePurchase,
      meta: {
        requiresAuth: true,
        enableUser: ['admin'],
        disabledGroups: [PRIVILEGED],
      },
    },
    {
      path: '/License/Cancel',
      name: 'LicenseCancel',
      component: LicenseCancel,
      meta: {
        requiresAuth: true,
        enableUser: ['admin'],
        disabledGroups: [PRIVILEGED],
      },
    },
    {
      path: '/License/Assign',
      name: 'LicenseAssign',
      component: LicenseAssign,
      meta: {
        requiresAuth: true,
        enableUser: ['admin'],
        disabledGroups: [PRIVILEGED],
      },
    },
    {
      path: '/PaymentMethod',
      redirect: '/PaymentMethod/View',
    },
    {
      path: '/PaymentMethod/Add',
      name: 'PaymentMethod Add',
      component: PaymentMethod,
      meta: {
        requiresAuth: true,
        enableUser: ['admin'],
        disabledGroups: [PRIVILEGED],
      },
    },
    {
      path: '/PaymentMethod/View',
      name: 'PaymentMethod View',
      component: PaymentMethod,
      meta: {
        requiresAuth: true,
        enableUser: ['admin'],
        disabledGroups: [PRIVILEGED],
      },
    },
    {
      path: '/PaymentMethod/Edit',
      name: 'PaymentMethod Edit',
      component: PaymentMethod,
      meta: {
        requiresAuth: true,
        enableUser: ['admin'],
        disabledGroups: [PRIVILEGED],
      },
    },
    {
      path: '/NotFound',
      name: 'Not Found',
      component: NotFound,
    },
    {
      path: '*',
      redirect: '/Login',
    },
    {
      path: '/Maintenance/Main',
      name: 'Maintenance',
      component: Maintenance,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/SalesList/View',
      name: 'SalesList View',
      component: SalesList,
      meta: {
        enableUser: ['systemAdmin'],
        plainLayout: true,
      },
    },
  ],
});

// router.beforeEachが二回走るのを防ぐため
let beforeFrom = '';
let beforeTo = '';

// Routing事前処理の設定
// RouterのNavigation Guard、ログイン処理（セッション保存を含む）をセットアップ
router.beforeEach(async (to, from, next) => {
  if (to.redirectedFrom == '/planeView/vr/index.html') {
    console.log('index.html指定');
    return next();
  }
  if (beforeFrom === from.path && beforeTo === to.path) {
    return;
  }
  beforeFrom = from.path;
  beforeTo = to.path;

  if (to.path.endsWith('/InvestmentAnalytics/Main')) {
    await getMaster('realEstate');
  } else if (to.path.endsWith('/InheritanceTax')) {
    await getMaster('inheritance');
  } else if (to.path.endsWith('/RealEstateSuitability/Main')) {
    await getMaster('realEstate');
  }
  //ルーティング元によってページ移動の警告メッセージを出力
  //保存フラグの初期化
  store.commit('setSaveFlg', null);

  // NOTE: ユーザがSingOutボタンをクリックした場合ログアウトする
  if (to.redirectedFrom == '/SignOut') {
    //SignOut処理
    Vue.prototype.$Amplify.Auth.signOut();
    //セッションクリア
    clearStore();

    return next();
  }
  // NOTE: 遷移先がログイン画面以外、かつセッションにユーザ情報がない場合（未ログイン）にはログインページにリダイレクト
  else if (to.path != '/Login' && !store.state.user) {
    await Auth.signOut();
    //セッションクリア
    clearStore();
    AmplifyEventBus.$emit('localUser', null);
    AmplifyEventBus.$emit('authState', 'signedOut');
    return next('/Login');
  }
  // NOTE: 遷移先がログイン画面以外、かつセッションにユーザ情報がある場合（ユーザが認証が必要なページに接続）
  else if (to.path != '/Login' && store.state.user) {
    // NOTE: 会社が登録されてないユーザのセッションが残っている場合、ログアウトし再ログイン＆会社登録を誘導する
    if (store.state.user.signInUserSession.accessToken.payload['cognito:groups'] === undefined) {
      await Auth.signOut();
      //セッションクリア
      clearStore();
      AmplifyEventBus.$emit('localUser', null);
      AmplifyEventBus.$emit('authState', 'signedOut');
      alert('セッション情報が満了されました。再度ログインしてください。');
      return next('/Login');
    }

    // NOTE: メンテナンス状態か確認
    const [getMaintenanceInfo, error] = await Promise.all([loadMaintenance(), authentication()]);

    if (getMaintenanceInfo.statusCode == '200') {
      if (getMaintenanceInfo.result.maintenanceFlag == 'true') {
        if (!to.path.startsWith('/Maintenance/Main')) {
          return router.push('/Maintenance/Main');
        }
      }
    } else if (getMaintenanceInfo.statusCode == 500) {
      console.error('メンテナンス状態のチェックでエラーが発生しました。', getMaintenanceInfo);
    }

    // NOTE: 会社情報の取得

    const companyId = Common.methods.getCompanyId(store.state.user);
    const company = companyId ? await Common.methods.getCompany(companyId) : null;
    if (company) {
      // storeに非決済モードフラグをセット
      store.commit('setPrivilegedFlag', company.privilegedFlag);
    }

    // NOTE: 重複ログインチェック
    await utility.methods.updateLicensedFunctions(store);
    if (!error) {
      // 遷移先の権限情報を取得
      var enableUser = to.meta.enableUser;
      // ユーザーの権限を取得
      getUserAuthLevel();
      // NOTE: 権限が必要ない場合、或いはユーザが適切な権限をもっている場合は対象ページ接続を許可
      if (!enableUser || enableUser.includes(authLevel)) {
        // NOTE: 会社情報が存在し、管理者権限、かつ非決済モードではない場合、決済・ライセンス状況により指定画面へ強制遷移
        if (company && authLevel === 'admin' && !store.state.privilegedFlag) {
          // クレジットカードの登録状況を取得
          const isRegisteredCard = await getIsRegisteredCard();
          // 決済情報が存在しない、かつ遷移先が支払方法選択画面以外の場合
          if (!company.payType && to.name !== 'PaymentMethod Add') {
            // 支払方法選択画面へ遷移
            return next({ path: '/PaymentMethod/Add' });
          } else if (!company.payType && to.name === 'PaymentMethod Add') {
            //遷移させない
          }
          // 決済方法がクレジットカードかつ、カード未登録、かつ遷移先が支払方法変更画面以外の場合
          else if (
            company?.payType == CREDIT_CARD &&
            !isRegisteredCard &&
            to.name !== 'PaymentMethod Edit'
          ) {
            alert(
              '「クレジットカード情報管理」ボタンを押し、お支払いに利用するクレジットカードを登録してください。'
            );
            // 支払方法変更画面へ遷移
            return next({ path: '/PaymentMethod/Edit' });
          }
          // ライセンスの購入履歴が存在しない、かつ遷移先がライセンス購入画面以外の場合
          else if (
            !(company.license && 0 < company.license.length) &&
            to.name !== 'LicensePurchase'
          ) {
            alert('ライセンスを購入してください。');
            // ライセンス購入画面へ遷移
            return next({ path: '/License/Purchase' });
          }
        }

        // NOTE: ライセンスチェック
        let licensed = false;
        switch (to.name) {
          case 'Roa Main':
            licensed = store.state.functions.some(v => {
              return v == 5; // ALLY 不動産ポートフォリオ分析
            });
            break;
          case 'RealEstateSuitability Main':
            licensed = store.state.functions.some(v => {
              return v == 4; // ALLY 不動産活用診断
            });
            break;
          case 'InheritanceTax/Input':
            licensed = store.state.functions.some(v => {
              return v == 6; // ALLY 相続シミュレーション
            });
            break;
          case 'InvestmentAnalytics Main':
            licensed = store.state.functions.some(v => {
              return v == 3; // ALLY 不動産投資分析
            });
            break;
          case 'WallPaperSimulatorNewMain Edit':
            licensed = store.state.functions.some(v => {
              return v == 8; // ALLY フォト（画像シミュレーター）
            });
            break;
          case 'Property PlaneEdit':
          case 'Property PlaneEdit View':
            licensed = store.state.functions.some(v => {
              return v == 9; // ALLY オートスケッチ（部屋計測・間取図自動作成）
            });
            break;
          default:
            licensed = true;
        }
        if (!licensed) {
          return next({ path: '/NotFound' });
        }

        // NOTE: 非決済モード中かつ、遷移先のdisableGroupsにprivileged（非決済モード）が含まれる場合は遷移させない
        if (
          store.state.privilegedFlag &&
          to.meta.disabledGroups?.some(v => {
            return v === PRIVILEGED;
          })
        ) {
          // 遷移元がダッシュボードの場合は画面を更新し、それ以外はダッシュボードへ遷移
          return from.name === 'DashBoard'
            ? window.location.reload()
            : next({ path: '/DashBoard' });
        }
        // 遷移先へ画面遷移
        return next();
      } else {
        // NOTE: 権限がない場合は接続を遮断
        alert('正しい方法で接続してください。');
        //ログイン画面からのリクエストはログイン画面にリダイレクト
        if (to.path == '/Login') {
          return next('/Login');
        }
        //上記以外の場合はRoutingを中断
        return next('/NotFound');
      }
    } else {
      return next('/Login');
    }
  }
  // NOTE: ユーザ情報がある場合、DashBoard以外でページがない場合にエラーページを出力
  else if (store.state.user) {
    if (to.redirectedFrom == '/' || to.path == '/Login') return next({ path: '/DashBoard' });
    return next({ path: '/NotFound' });
  } else if (to.path != '/Login') {
    return next('/Login');
  } else return next();
});

/**
 *  FUNC: クレジットカードが登録済か判定
 * @returns {boolean}
 */
const getIsRegisteredCard = async () => {
  if (store.state.user == null) return false;
  const groups = store.state.user?.signInUserSession?.accessToken?.payload?.['cognito:groups'];
  const groupIndex = groups.findIndex(group => {
    return group.startsWith('Company-');
  }, 'Company-');
  const companyId = groups[groupIndex].slice(8);
  try {
    const result = await API.graphql(graphqlOperation(isRegisteredCard, { companyId }));
    if (result.data.isRegisteredCard.statusCode == '200') {
      return JSON.parse(result.data.isRegisteredCard.body).result;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

// FUNC: アクセストークンの有効期限を確認
const authentication = async () => {
  //Cognitoと通信しAccessTokenが有効であることを確認
  var result = Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
    .then(async data => {
      //cognitoにhttp通信
      await axios.post(
        COGNITO_URL,
        {
          AccessToken: data.signInUserSession.accessToken.jwtToken,
        },
        {
          headers: {
            'Content-Type': 'application/x-amz-json-1.1',
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser',
          },
        }
      );
      return false;
    })
    .catch(async error => {
      //有効ではない場合はエラー処理を行う
      if (error && error.response && error.response.status == 400) {
        alert('別機器からログインされました。ログアウトします。');
      } else {
        //上記以外のエラー
        alert('セッション情報が満了されました。再度ログインしてください。');
      }
      await Auth.signOut();
      clearStore();
      AmplifyEventBus.$emit('localUser', null);
      AmplifyEventBus.$emit('authState', 'signedOut');
      router.push('/SignOut');
      return true;
    });
  return result;
};

// FUNC: 現在のユーザの権限を確認する
const getUserAuthLevel = () => {
  //Cognitoグループから権限を取得
  var groups = store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
  //権限がある場合は対象権限をセットアップ
  if (groups && groups.includes('systemAdmin')) {
    authLevel = 'systemAdmin';
  } else if (groups && groups.includes('admin')) {
    authLevel = 'admin';
  } else {
    authLevel = 'member';
  }
};

// FUNC: セッション情報を初期化する
const clearStore = () => {
  store.replaceState({});
};

// ログイン状態管理
AmplifyEventBus.$on('authState', async state => {
  if (state === 'signedOut') {
    clearStore();
  }
});

/**
 *  FUNC: マスタデータの取得
 * @param {*} target
 */
const getMaster = async target => {
  let master = await loadMaster();
  if (target == 'realEstate') {
    master = await loadMaster();
  } else if (target == 'inheritance') {
    master = await InheritanceMasterLoad();
  }

  if (master !== 'error') {
    store.commit('setMaster', master);
  }
};

export default router;
