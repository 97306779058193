// @ts-check

import { PvCircleHandle } from '../../../node/node/circleHandle';
import { cloneNode, cloneNodeStandalone } from '../../../node/tools/clone';
import { Vec } from '../../../util/math/vector';
import { Action } from '../action';
import { ActionBuilder } from './actionBuilder';
import { PvObject } from '../../../node/node/pvObject';
import { nextCycleIndex, prevCycleIndex } from '../../../util/util';

export class MoveVertexActionBuilder extends ActionBuilder {
  build() {
    const action = new Action();
    action.actionType = Action.ACTION_TYPE.DRAG_AND_DROP;

    const handle = /** @type {PvCircleHandle} */ (this.dataset.targetObject);
    const target = /** @type {PvObject} */ (handle.target);
    const index = this.dataset.index ?? -1;
    // @ts-ignore
    const guideTarget = cloneNodeStandalone(target);
    this.context.getGuideRoot().appendChild(guideTarget);
    const guideHandle = cloneNode(handle);
    this.context.getGuideRoot().appendChild(guideHandle);

    const handleTransform = guideHandle.absoluteTransform();

    let prevDist = new Vec();

    action.onUpdate = ({ dist }) => {
      guideHandle.transform = handleTransform.clone();
      guideHandle.moveBy(dist);
      guideTarget.moveVertexBy(index, prevDist.reverse());
      guideTarget.moveVertexBy(index, dist.clone());
      prevDist = dist;
    };

    action.onComplete = ({ dist }) => {
      const targetVertex = target.vertexes[index].add(dist);
      const length = target.vertexes.length;
      const nextIndex = nextCycleIndex(index, length);
      const prevIndex = prevCycleIndex(index, length);
      if (
        target.vertexes.some((v, i) => {
          return v.eq(targetVertex) && (nextIndex === i || prevIndex === i);
        })
      ) {
        //
      }

      target.moveVertexBy(index, dist);
    };

    return action;
  }
}
