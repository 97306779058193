// @ts-check

import { cloneNodeStandalone } from '../../../node/tools/clone';
import { Vec } from '../../../util/math/vector';
import { Action } from '../action';
import { ActionBuilder } from './actionBuilder';

export class ResizePrintAreaBuilder extends ActionBuilder {
  build() {
    const action = new Action();
    action.actionType = Action.ACTION_TYPE.DRAG_AND_DROP;
    const { index } = this.dataset;
    const targetObject = this.dataset.targetObject;
    if (targetObject == null) {
      throw new Error('');
    }

    const guideObject = cloneNodeStandalone(targetObject);
    this.context.getGuideRoot().appendChild(guideObject);

    let prev = new Vec();
    action.onUpdate = ({ dist }) => {
      guideObject.moveVertexBy(index ?? -1, prev.reverse());
      guideObject.moveVertexBy(index ?? -1, dist);
      prev = dist;
    };
    action.onComplete = ({ dist }) => {
      targetObject.moveVertexBy(index ?? -1, dist);
    };

    return action;
  }
}
