// @ts-check

import { Vec } from '../../util/math/vector';
import { HandleFlg } from '../tools/handleFlg';
import { PvObject } from './pvObject';
import { UseJudgeThePointInArea } from '../../util/judgeThePointInArea';
import {
  HEIGHT3D_DEFAULT,
  ADDITIONAL_INFO_OPEN_TYPE,
  ADDITIONAL_INFO_KNOB_TYPE,
  ADDITIONAL_INFO_SLIDING_TYPE,
} from '../../util/const';

export class PvDoor extends PvObject {
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'door';
  handleFlg = new HandleFlg({
    vertex: true,
    edge: false,
    curve: false,
    rotate: false,
  });
  height3d = HEIGHT3D_DEFAULT.DOOR;
  constructor() {
    super();
  }

  get doorWidth() {
    if (this.vertexes.length < 2) return 0;
    return this.vertexes[1].x;
  }

  set doorWidth(val) {
    if (this.vertexes.length < 2) {
      return;
    }
    this.vertexes.splice(0, 2, new Vec(), new Vec(val, 0));
  }

  get doorHeight() {
    if (this.vertexes.length < 2) return 0;
    return this.vertexes[1].y;
  }

  set doorHeight(val) {
    this.vertexes.splice(0, 2, new Vec(), new Vec(0, val));
  }

  /**
   *
   * @param {number} index
   * @param {Vec} vec
   */
  moveVertexBy(index, vec) {
    let first, last;
    if (index === 0) {
      first = this.vertexes[0].add(vec);
      last = this.vertexes[1].rotate(this.transform.rotateDeg);
      this.moveBy(vec);
    } else if (index === 1) {
      first = this.vertexes[0].clone();
      last = this.vertexes[1].rotate(this.transform.rotateDeg).add(vec);
    } else {
      throw new Error('頂点の数が2以上です。');
    }

    this.deformateFromStartEnd(last.sub(first));
  }

  /**
   * @param {Vec} vec
   */
  deformateFromStartEnd(vec) {
    const { len, rotate } = Vec.vecToVecRotation(vec);
    this.vertexes = [new Vec(), new Vec(len, 0)];
    this.transform = this.transform.cloneWith({
      rotate,
    });

    if (!this.parent) {
      return;
    }
    // 移動後の内外判定を行う
    const judgeThePointInArea = new UseJudgeThePointInArea();
    // 内外判定から建具追加情報を更新
    this.additionalInfo = judgeThePointInArea.getAdditionalInfoForMove(this);
  }

  /**
   * AdditionalInfoの値をStyleに反映
   */
  reflectAdditionalInfoToStyle() {
    // ARから取得した開き方向が0以外かチェック
    if (this.additionalInfo.open != 0) {
      // ARから取得した開き方向に合わせて、内外判定結果をセットする
      this.additionalInfo.isPointInArea =
        this.additionalInfo.open === ADDITIONAL_INFO_OPEN_TYPE.INWARD;
    }
    // 表示した建具の内外判定
    const judgeThePointInArea = new UseJudgeThePointInArea();
    // @ts-ignore
    const isPointInAreaAtDisplay = judgeThePointInArea.isPointInArea(
      // @ts-ignore
      this.parent.vertexes,
      // @ts-ignore
      judgeThePointInArea.createTargetPoint(this.parent, this)
    );
    // 表示中の建具の内外判定結果と、ARのデータを元にセットした内外判定結果が同じかチェック
    if (this.additionalInfo.isPointInArea != isPointInAreaAtDisplay) {
      // 違う場合反転
      this.style.flipVertical = !this.style.flipVertical;
    }
    // 表示中の建具の内外判定結果と、ARから取得したノブの向きをチェック
    if (
      (isPointInAreaAtDisplay == true &&
        this.additionalInfo.knob === ADDITIONAL_INFO_KNOB_TYPE.LEFT) ||
      (isPointInAreaAtDisplay == false &&
        this.additionalInfo.knob === ADDITIONAL_INFO_KNOB_TYPE.RIGHT)
    ) {
      // 内判定かつノブが左向きの場合、外判定かつノブが右向きの場合は反転
      this.style.flipHorizontal = !this.style.flipHorizontal;
    }
    // 表示中の建具の内外判定結果と、ARから取得した引き方向をチェック
    if (
      (isPointInAreaAtDisplay == true &&
        this.additionalInfo.sliding === ADDITIONAL_INFO_SLIDING_TYPE.LEFT) ||
      (isPointInAreaAtDisplay == false &&
        this.additionalInfo.sliding === ADDITIONAL_INFO_SLIDING_TYPE.RIGHT)
    ) {
      // 内判定かつ引き方向が左向きの場合、外判定かつ引き方向が右向きの場合は反転
      // 反転
      this.style.flipHorizontal = !this.style.flipHorizontal;
    }
  }
}
