// @ts-check

import { computed, ref } from '@vue/composition-api';
import { PvObject } from '../node/node/pvObject';
import { PvRoot } from '../node/node/rootNode';

/**
 * @param {{
 * objectTree: import("@vue/composition-api").ComputedRef<PvObject[]>
 * root: import("@vue/composition-api").Ref<PvRoot>
 * }} params
 */
export const useObjectSelector = ({ objectTree, root }) => {
  /** @type {import('@vue/composition-api').Ref<string|null>} */
  const activeObjectId = ref(null);
  /** @type {import('@vue/composition-api').ComputedRef<PvObject|null>} */
  const activeObject = computed(() => {
    /** @type {PvObject|null} */
    let a = null;
    root.value.dfSearch(n => {
      if (n.isActive) {
        a = n;
        return false;
      }
    });
    return a;
  });

  const selectedObjects = computed(() => {
    const tree = objectTree.value;
    return tree.filter(obj => obj.isSelected);
  });

  /** @type {import('@vue/composition-api').Ref<Set<string>>} */
  const selectedIdSet = computed(() => {
    const set = new Set();
    selectedObjects.value.forEach(node => {
      set.add(node.id);
    });

    return set;
  });

  return {
    activeObject,
    activeObjectId,
    selectedObjects,
    selectedIdSet,
  };
};

/** @typedef {ReturnType<typeof useObjectSelector>} ObjectSelector */
