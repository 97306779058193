<!--
作成者：kuroda
作成日：2020.10.01
画面名：投資分析項目入力コンポーネント
機能：投資分析項目を入力
親子関係：なし
更新者：
更新内容：
-->
<template>
  <b-card id="InvestmentAnalytics" no-body>
    <ValidationObserver tag="div" ref="inputCheck">
      <!-- 初期投資 -->
      <InitialInvestment ref="InitialInvestment" v-if="initialInvestmentFlag" />
      <div style="position: relative;">
        <b-card-header role="tab" v-if="!initialInvestmentFlag" class="mb-2">
          <b-button variant="link">
            共通情報
          </b-button>
          <div style="position: absolute">
            <Load width="25px" height="25px" margin="-25px 100px" />
          </div>
        </b-card-header>
        <b-card-header role="tab" v-if="!initialInvestmentFlag">
          <b-button variant="link">
            初期投資
          </b-button>
          <div style="position: absolute">
            <Load width="25px" height="25px" margin="-25px 100px" />
          </div>
        </b-card-header>
      </div>
      <br />
      <!-- 単年度収支計画 -->
      <div style="position: relative;">
        <SingleYearPlan ref="SingleYearPlan" v-if="singleYearPlanFlag" />
        <b-card-header role="tab" v-if="!singleYearPlanFlag">
          <b-button variant="link">
            単年度収支計画
          </b-button>
          <div style="position: absolute">
            <Load width="25px" height="25px" margin="-25px 160px" />
          </div>
        </b-card-header>
      </div>
      <br />
      <!-- 長期修繕計画 -->
      <div style="position: relative;">
        <RepairPlan ref="RepairPlan" v-if="repairPlanFlag" />
        <b-card-header role="tab" v-if="!repairPlanFlag">
          <b-button variant="link">
            長期修繕計画
          </b-button>
          <div style="position: absolute">
            <Load width="25px" height="25px" margin="-25px 140px" />
          </div>
        </b-card-header>
      </div>
      <br />
      <!-- 売却 -->
      <div style="position: relative;">
        <SalePlan ref="SalePlan" v-if="salePlanFlag" />
        <b-card-header role="tab" v-if="!salePlanFlag">
          <b-button variant="link">
            売却
          </b-button>
          <div style="position: absolute">
            <Load width="25px" height="25px" margin="-25px 70px" />
          </div>
        </b-card-header>
      </div>
    </ValidationObserver>
  </b-card>
</template>
<script>
  import InitialInvestment from './InitialInvestment';
  import SingleYearPlan from './SingleYearPlan';
  import RepairPlan from './RepairPlan';
  import SalePlan from './SalePlan';
  import Load from '../../../components/Common/Load.vue';
  export default {
    components: {
      InitialInvestment,
      SingleYearPlan,
      RepairPlan,
      SalePlan,
      Load,
    },
    data: function() {
      return {
        loading: false,
        inputValues: { test: null },
        initialInvestmentFlag: false,
        singleYearPlanFlag: false,
        repairPlanFlag: false,
        salePlanFlag: false,
      };
    },
    mounted() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.initialInvestmentFlag = true;
        }, 200);
        setTimeout(() => {
          this.singleYearPlanFlag = true;
        }, 1300);
        setTimeout(() => {
          this.repairPlanFlag = true;
        }, 2500);
        setTimeout(() => {
          this.salePlanFlag = true;
        }, 2600);
      });
    },
    watch: {
      salePlanFlag: {
        handler: function() {
          this.$emit('lastLoadFlag', this.salePlanFlag);
        },
      },
    },
  };
</script>
<style>
  #InvestmentAnalytics {
    padding: 1em;
    border-top-color: gainsboro !important;
  }
  #InvestmentAnalytics .card {
    border-top-color: gainsboro !important;
  }
  #InvestmentAnalytics .card-body {
    margin-top: 0;
    margin-bottom: 0;
    padding: 1em;
  }
  #InvestmentAnalytics .card-body > .container {
    padding: 0.5em;
  }
  #InvestmentAnalytics .card-header {
    font-size: medium;
    font-weight: bold;
    padding: 0;
  }
  #InvestmentAnalytics .card-header .btn-link,
  #InvestmentAnalytics .nav-link {
    font-size: large;
    font-weight: bold;
  }
  #InvestmentAnalytics .nav-pills .nav-link.active {
    background-color: var(--colorTheme);
    margin-left: 1px;
    margin-right: 1px;
  }
  #InvestmentAnalytics .nav-pills .nav-link {
    background-color: gainsboro;
  }
  #InvestmentAnalytics .card-columns {
    column-count: 1;
  }
  #InvestmentAnalytics .form-group {
    margin-top: 0;
    margin-bottom: 0;
  }
  .bold {
    font-weight: bold;
  }
  .center {
    text-align: center;
  }
  #InvestmentAnalytics .title {
    display: grid;
    text-align: left;
    align-items: center;
  }
  #InvestmentAnalytics .title-right {
    display: grid;
    text-align: right;
    align-items: center;
  }
  #InvestmentAnalytics .title-center {
    display: grid;
    text-align: center;
    align-items: center;
  }
  #InvestmentAnalytics .title-guideline {
    display: grid;
    text-align: center;
    align-items: end;
  }
  #InvestmentAnalytics .guideline {
    padding-top: 22px;
    font-size: medium;
    text-align: center;
  }
  #InvestmentAnalytics legend {
    padding-bottom: 0.5em;
    font-weight: bold;
  }
  #InvestmentAnalytics .unit {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    text-align: left;
    align-items: center;
  }
  #InvestmentAnalytics .container > .row > .col > .row {
    padding-top: 0;
  }
  #InvestmentAnalytics .ally-select {
    padding-right: 15px;
  }
  #InvestmentAnalytics .ally-select select {
    height: 2.875rem;
    font-size: 0.875rem;
    padding-left: 22px;
  }
  #InvestmentAnalytics .currency {
    text-align: right;
  }
  #accordion211 .checkbox,
  #accordion212 .checkbox,
  #accordion213 .checkbox,
  #accordion214 .checkbox {
    display: grid;
    text-align: center;
    align-items: center;
  }
  #constructionPlan .card-body .card-body,
  .detail .card-body {
    background-color: ivory;
    border: solid 1px lightgray;
  }
  #InvestmentAnalytics #IncidentalExpenses .custom-control-label span,
  #InvestmentAnalytics #ConstructionPlan .custom-control-label span {
    font-weight: normal;
    font-size: medium;
  }
  #InvestmentAnalytics .row {
    padding-top: 0.5em;
  }
  #InvestmentAnalytics .row:first-child {
    padding-top: 0;
  }

  #InvestmentAnalytics .category > .card-header {
    transition: 0.2s;
    background: var(--colorTheme);
  }
  #InvestmentAnalytics .category > .card-header > .btn-link {
    color: #fff;
    text-shadow: 1px 1px 1px #000000, -1px 1px 1px #000000, 1px -1px 1px #000000,
      -1px -1px 1px #000000, 1px 0px 1px #000000, 0px 1px 1px #000000, -1px 0px 1px #000000,
      0px -1px 1px #000000;
  }
  #InvestmentAnalytics .category .category > .card-header {
    transition: 0.2s;
    background: rgba(var(--colorThemeRGB), 0.7);
  }
  #InvestmentAnalytics .category .category .category > .card-header {
    transition: 0.2s;
    background: rgba(var(--colorThemeRGB), 0.4);
  }
  #InvestmentAnalytics .category .category .category .category > .card-header {
    transition: 0.2s;
    background: rgba(var(--colorThemeRGB), 0.1);
  }

  #InvestmentAnalytics .custom-control-input:checked ~ .custom-control-label::before {
    background: var(--colorTheme);
    border-color: var(--colorTheme);
  }
  #InvestmentAnalytics input[type='number'],
  #InvestmentAnalytics .number input[type='text'] {
    text-align: right;
  }

  #InvestmentAnalytics .borderline {
    height: 0.5rem;
    margin-top: 0.5rem;
    border-top: solid 1px gray;
  }

  .btnHeader {
    padding: 10px !important;
    margin-right: 10px;
    margin-bottom: 5px;
    min-width: 95px;
    max-height: 50px;
  }
  .headerArea {
    height: 140px;
  }

  @media screen and (min-width: 576px) {
    #InvestmentAnalytics .card-columns {
      column-count: 1;
    }
  }
  @media screen and (min-width: 768px) {
    /* iPad     - 縦 :  768 */
    #InvestmentAnalytics .card-columns {
      column-count: 1;
    }
    .headerArea {
      height: 260px;
    }
  }
  @media screen and (min-width: 1071px) {
    .headerArea {
      height: 190px;
    }
  }
  @media screen and (min-width: 1350px) {
    /* iPad Pro - 縦 : 1024 */
    /* iPad     - 横 : 1024 */
    #InvestmentAnalytics .card-columns {
      column-count: 1;
    }
    .headerArea {
      height: 150px;
    }
  }
  @media screen and (min-width: 1230px) {
    /* iPad Pro - 横 : 1366 */
    /* PC(FullHD) - 横 : 1920 */
    #InvestmentAnalytics .card-columns,
    #InvestmentAnalytics #groupObjectSummary,
    #InvestmentAnalytics #groupIncidentalExpenses,
    #InvestmentAnalytics #groupExpensesAtSale {
      column-count: 2;
    }
    #InvestmentAnalytics .card-columns .card-columns {
      column-count: 1;
    }
    #InvestmentAnalytics .container {
      max-width: unset;
      padding: 1em;
    }
    .headerArea {
      height: 150px;
    }
  }
  @media screen and (min-width: 1320px) {
    .headerArea {
      height: 140px;
    }
  }
  @media screen and (min-width: 1699px) {
    .headerArea {
      height: 80px;
    }
  }
  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }
  .btn-detail {
    width: 4rem;
  }
</style>
