<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：初期投資/付帯費用/事業開始前負担
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="PreBusinessBurden">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button id="btnPreBusinessBurden" variant="link" v-b-toggle.tabPreBusinessBurden>
          事業開始前負担
        </b-button>
      </b-card-header>
      <b-collapse id="tabPreBusinessBurden" role="tabpanel">
        <b-card-body>
          <b-tabs
            fill
            pills
            v-model="investmentAnalytics.initialInvestment.preCareerCommitment.isDetail"
          >
            <b-tab title="概算入力">
              <b-container>
                <b-row>
                  <b-col cols="2" class="title bold">総額</b-col>
                  <b-col cols="2" class="title">
                    (税抜)
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <allyText
                          name="approximateValue"
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="
                            investmentAnalytics.initialInvestment.preCareerCommitment
                              .approximateValue
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold" />
                  <b-col cols="2" class="title">
                    (税込)
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            sumTax(
                              Math.floor(
                                investmentAnalytics.initialInvestment.preCareerCommitment
                                  .approximateValue
                              )
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="詳細入力">
              <b-card-group columns>
                <b-card no-body class="category">
                  <b-card-header role="tab">
                    <b-button variant="link" v-b-toggle.tabPreBusinessBurden0>
                      事業開始前期間
                    </b-button>
                  </b-card-header>
                  <b-collapse id="tabPreBusinessBurden0" role="tabpanel">
                    <b-card-body>
                      <b-container>
                        <b-row>
                          <b-col class="title bold ">工事期間</b-col>
                          <b-col>
                            <b-row>
                              <b-col class="component">
                                <allyText
                                  name="duration0"
                                  type="number"
                                  class="number"
                                  :separation="true"
                                  v-model="
                                    investmentAnalytics.initialInvestment.preCareerCommitment
                                      .duration[0]
                                  "
                                  roundType="down"
                                />
                              </b-col>
                              <b-col cols="1" class="unit">ヶ月</b-col>
                            </b-row>
                          </b-col>
                          <b-col />
                        </b-row>
                        <b-row>
                          <b-col class="title bold ">募集/FR期間</b-col>
                          <b-col>
                            <b-row>
                              <b-col class="component">
                                <allyText
                                  name="duration1"
                                  type="number"
                                  class="number"
                                  :separation="true"
                                  v-model="
                                    investmentAnalytics.initialInvestment.preCareerCommitment
                                      .duration[1]
                                  "
                                  roundType="down"
                                />
                              </b-col>
                              <b-col cols="1" class="unit">ヶ月</b-col>
                            </b-row>
                          </b-col>
                          <b-col />
                        </b-row>
                        <b-row>
                          <b-col class="title bold ">立退き期間</b-col>
                          <b-col>
                            <b-row>
                              <b-col class="component">
                                <allyText
                                  name="duration2"
                                  type="number"
                                  class="number"
                                  :separation="true"
                                  v-model="
                                    investmentAnalytics.initialInvestment.preCareerCommitment
                                      .duration[2]
                                  "
                                  roundType="down"
                                />
                              </b-col>
                              <b-col cols="1" class="unit">ヶ月</b-col>
                            </b-row>
                          </b-col>
                          <b-col />
                        </b-row>
                      </b-container>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="category">
                  <b-card-header role="tab">
                    <b-button variant="link" v-b-toggle.tabPreBusinessBurden1>
                      賃借料
                    </b-button>
                  </b-card-header>
                  <b-collapse id="tabPreBusinessBurden1" role="tabpanel">
                    <b-card-body>
                      <b-container>
                        <b-row>
                          <b-col class="title bold">月額賃借料</b-col>
                          <b-col>
                            <b-row>
                              <b-col class="component">
                                <allyText
                                  name="monthlyRent"
                                  type="number"
                                  class="number"
                                  :separation="true"
                                  v-model="
                                    investmentAnalytics.initialInvestment.preCareerCommitment
                                      .monthlyRent
                                  "
                                  roundType="down"
                                />
                              </b-col>
                              <b-col cols="1" class="unit">円</b-col>
                            </b-row>
                          </b-col>
                          <b-col />
                        </b-row>
                        <b-row style="padding-top:">
                          <b-col class="title  bold">負担率</b-col>
                          <b-col>
                            <b-row>
                              <b-col class="component">
                                <allyText
                                  name="affordability"
                                  type="number"
                                  class="number"
                                  :separation="true"
                                  :floatLength="2"
                                  v-model="
                                    investmentAnalytics.initialInvestment.preCareerCommitment
                                      .affordability
                                  "
                                  roundType="down"
                                />
                              </b-col>
                              <b-col cols="1" class="unit">％</b-col>
                            </b-row>
                          </b-col>
                          <b-col />
                        </b-row>
                        <b-row>
                          <b-col class="title  bold">事業開始前賃借料</b-col>
                          <b-col>
                            <b-row>
                              <b-col class="component">
                                <b-form-input
                                  disabled
                                  class="currency"
                                  :value="preBusinessRent | currency"
                                />
                              </b-col>
                              <b-col cols="1" class="unit">円</b-col>
                            </b-row>
                          </b-col>
                          <b-col />
                        </b-row>

                        <b-row>
                          <b-col class="title bold ">工事期間</b-col>
                          <b-col>
                            <b-row>
                              <b-col class="component">
                                <b-form-input
                                  disabled
                                  class="currency"
                                  :value="
                                    investmentAnalytics.initialInvestment.preCareerCommitment
                                      .duration[0] | currency
                                  "
                                />
                              </b-col>
                              <b-col cols="1" class="unit">ヶ月</b-col>
                            </b-row>
                          </b-col>
                          <b-col />
                        </b-row>
                        <b-row>
                          <b-col class="title bold ">募集/FR期間</b-col>
                          <b-col>
                            <b-row>
                              <b-col class="component">
                                <b-form-input
                                  disabled
                                  class="currency"
                                  :value="
                                    investmentAnalytics.initialInvestment.preCareerCommitment
                                      .duration[1] | currency
                                  "
                                />
                              </b-col>
                              <b-col cols="1" class="unit">ヶ月</b-col>
                            </b-row>
                          </b-col>
                          <b-col />
                        </b-row>
                        <b-row>
                          <b-col class="title bold ">立退き期間</b-col>
                          <b-col>
                            <b-row>
                              <b-col class="component">
                                <b-form-input
                                  disabled
                                  class="currency"
                                  :value="
                                    investmentAnalytics.initialInvestment.preCareerCommitment
                                      .duration[2] | currency
                                  "
                                />
                              </b-col>
                              <b-col cols="1" class="unit">ヶ月</b-col>
                            </b-row>
                          </b-col>
                          <b-col />
                        </b-row>
                      </b-container>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="category">
                  <b-card-header role="tab">
                    <b-button
                      variant="link"
                      v-b-toggle.tabPreBusinessBurden2
                      id="btnPreBusinessBurden2"
                    >
                      占用使用料
                    </b-button>
                  </b-card-header>
                  <b-collapse id="tabPreBusinessBurden2" role="tabpanel">
                    <b-card-body>
                      <b-container>
                        <b-row>
                          <b-col class="title  bold">月額占用料</b-col>
                          <b-col>
                            <b-row>
                              <b-col class="component">
                                <allyText
                                  name="monthlyOccupancyFee"
                                  type="number"
                                  class="number"
                                  :separation="true"
                                  v-model="
                                    investmentAnalytics.initialInvestment.preCareerCommitment
                                      .monthlyOccupancyFee
                                  "
                                  roundType="down"
                                />
                              </b-col>
                              <b-col cols="2" class="unit">円</b-col>
                            </b-row>
                          </b-col>
                          <b-col />
                        </b-row>
                        <b-row>
                          <b-col class="title  bold">事業開始前期間</b-col>
                          <b-col>
                            <b-row>
                              <b-col class="component">
                                <allyText
                                  name="duration3"
                                  type="number"
                                  class="number"
                                  mode="View"
                                  :separation="true"
                                  :value="preBusinessPeriod"
                                />
                              </b-col>
                              <b-col cols="2" class="unit">ヶ月</b-col>
                            </b-row>
                          </b-col>
                          <b-col />
                        </b-row>
                      </b-container>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="category">
                  <b-card-header role="tab">
                    <b-button
                      variant="link"
                      v-b-toggle.tabPreBusinessBurden3
                      id="btnPreBusinessBurden3"
                    >
                      支払利息
                    </b-button>
                  </b-card-header>
                  <b-collapse id="tabPreBusinessBurden3" role="tabpanel">
                    <b-card-body>
                      <b-container>
                        <b-row>
                          <b-col class="title  bold" style="margin-top: 45px;">借入額</b-col>
                          <b-col>
                            <b-row>
                              <b-col cols="12" style="height: 45px;"></b-col>
                              <b-col class="component">
                                <allyText
                                  name="borrowedAmount"
                                  type="number"
                                  class="number"
                                  :separation="true"
                                  v-model="
                                    investmentAnalytics.initialInvestment.capitalPlan.borrowedAmount
                                  "
                                  roundType="down"
                                />
                              </b-col>
                              <b-col cols="1" class="unit">円</b-col>
                            </b-row>
                          </b-col>
                          <b-col>
                            <b-row style=" white-space: nowrap;">
                              <b-col
                                class="guideline pl-0"
                                style="cursor: help;"
                                v-b-popover.hover.topleft="
                                  '登録免許税までの事業費合計に' +
                                    this.magnification +
                                    '倍した金額を表示しています。「初期投資＞資金計画＞借入金」の借入金とは計算方法が異なります。'
                                "
                              >
                                ※目安 <u>(?)</u>
                              </b-col>
                              <button
                                class="btn btn-ally btn-reflection mr-1 mt-auto md-0"
                                @click="
                                  changeGuideline(
                                    'borrowedAmount',
                                    Math.floor(totalProjectCostExclude * magnification)
                                  )
                                "
                              >
                                反映
                              </button>
                            </b-row>
                            <b-row>
                              <b-col cols="10" class="component pr-0 pl-auto">
                                <b-form-input
                                  disabled
                                  class="currency"
                                  :value="
                                    Math.floor(totalProjectCostExclude * magnification) | currency
                                  "
                                />
                              </b-col>
                              <b-col cols="1" class="unit pl-1">円</b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col class="title  bold">利率</b-col>
                          <b-col>
                            <b-row>
                              <b-col class="component">
                                <allyText
                                  name="borrowingRate"
                                  type="number"
                                  class="number"
                                  :separation="true"
                                  :floatLength="2"
                                  v-model="
                                    investmentAnalytics.initialInvestment.preCareerCommitment
                                      .borrowingRate
                                  "
                                  roundType="down"
                                />
                              </b-col>
                              <b-col cols="1" class="unit">％</b-col>
                            </b-row>
                          </b-col>
                          <b-col />
                        </b-row>
                        <b-row>
                          <b-col class="title bold">事業開始前期間</b-col>
                          <b-col>
                            <b-row>
                              <b-col class="component">
                                <allyText
                                  name="duration4"
                                  type="number"
                                  class="number"
                                  mode="View"
                                  :separation="true"
                                  :value="preBusinessPeriod"
                                />
                              </b-col>
                              <b-col cols="1" class="unit">ヶ月</b-col>
                            </b-row>
                          </b-col>
                          <b-col class="title-center" style="align-items: end;"> </b-col>
                        </b-row>
                        <b-row>
                          <b-col class="title bold">事業開始前利息</b-col>
                          <b-col>
                            <b-row>
                              <b-col class="component">
                                <!--計算値-->
                                <b-form-input
                                  disabled
                                  class="currency"
                                  :value="interestExpenseTotal2 | currency"
                                  v-if="
                                    !investmentAnalytics.initialInvestment.preCareerCommitment
                                      .isChanged
                                  "
                                />
                                <!--入力値-->
                                <allyText
                                  type="number"
                                  class="number"
                                  :separation="true"
                                  v-model="
                                    investmentAnalytics.initialInvestment.preCareerCommitment
                                      .interestBeforeStartingBusiness
                                  "
                                  v-else
                                />
                              </b-col>
                              <b-col cols="1" class="unit">円</b-col>
                            </b-row>
                          </b-col>
                          <b-col class="title-center"> </b-col>
                        </b-row>
                      </b-container>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </b-card-group>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Calculation from './../Calculation';
  import Vue from 'vue';
  export default {
    mixins: [Calculation],
    methods: {
      // チェックボックス変更時
      changeCheckBox: function() {
        // 初期値設定
        if (!this.investmentAnalytics.initialInvestment.preCareerCommitment.isChanged) {
          this.$set(
            this.investmentAnalytics.initialInvestment.preCareerCommitment,
            'interestBeforeStartingBusiness',
            Math.round(this.interestExpenseTotal2)
          );
        }
      },
      //目安値を調整金に反映する
      changeGuideline: function(id, sum) {
        this.investmentAnalytics.initialInvestment.capitalPlan.borrowedAmount = 0;
        Vue.set(this.investmentAnalytics.initialInvestment.capitalPlan, id, Math.round(sum));
      },
    },
  };
</script>
<style scoped>
  #tabPreBusinessBurden1 .card-body,
  #tabPreBusinessBurden2 .card-body,
  #tabPreBusinessBurden3 .card-body {
    padding-top: 0;
  }
</style>
