<template>
  <CommonLayout
    title="単年度収支計画(満室想定)"
    :companyLogo="companyLogo"
    :companyName="companyName"
  >
    <template v-slot>
      <div style="display: flex; height: 350px;">
        <div style="display: grid; font-size: 40px; width: 50%;">
          <div style="width: 100%; padding: 70px 100px;">
            <div :style="topHeaderStyle">概略</div>
            <div :style="titleStyle">収入の部</div>
            <div :style="unitPrice">単位：円</div>
            <table style="width: 100%; margin-top: 50px;">
              <thead>
                <tr>
                  <th :style="[headerStyle, col1Style]" class="col1">項目</th>
                  <th :style="headerStyle">金額</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in singleYearBalanceIncomeList" :key="i">
                  <td :style="[subHeaderStyle, col1Style]" class="col1">{{ !item || item.name }}</td>
                  <td :style="[bodyStyle, col5Style]" class="col5">
                    {{
                      formatNum(item.total || item.amount, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table style="width: 100%">
              <tbody>
                <tr>
                  <td :style="headerStyle" style="width: 50%">合計</td>
                  <td :style="{ ...headerStyle, 'text-align': 'right' }" style="widht: 50%;">
                    {{
                      formatNum(singleYearBalanceResult.income.total, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div :style="titleStyle">
              支出の部
            </div>
            <div :style="unitPrice">
              単位：円
            </div>
            <table style="width: 100%">
              <thead>
                <tr>
                  <th :style="headerStyle" style="width: 50%;">項目</th>
                  <th :style="headerStyle" style="width: 50%;">金額</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in singleYearBalanceSpendingList" :key="'spending' + i">
                  <td :style="[subHeaderStyle]" style="width: 50%;">
                    {{ item.name }}
                  </td>
                  <td :style="{ ...bodyStyle, 'text-align': 'right' }" style="width: 50%;">
                    {{
                      formatNum(item.total || item.amount, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table style="width: 100%">
              <tbody>
                <tr>
                  <td :style="headerStyle" style="width: 50%">合計</td>
                  <td :style="{ ...headerStyle, 'text-align': 'right' }" style="widht: 50%">
                    {{
                      formatNum(singleYearBalanceResult.spending.total, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table style="width: 100%">
              <tbody>
                <tr>
                  <td :style="headerStyle" style="width: 50%">収支</td>
                  <td :style="{ ...headerStyle, 'text-align': 'right' }" style="widht: 50%">
                    {{
                      formatNum(singleYearBalanceResult.income.total - singleYearBalanceResult.spending.total, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div style="font-size: 50px; margin-top: 30px;">
              <div :style="topHeaderStyle2">詳細</div>
              <SingleYearPlan1
                :companyLogo="companyLogo"
                :companyName="companyName"
                :singleYearBalanceResult="singleYearBalanceResult"
                Mode="income"
              />
            </div>
          </div>
        </div>
        <div style="float: right; font-size: 50px; width: 50%; height: 50%; padding: 70px 80px;">
          <div :style="topHeaderStyle2">詳細</div>
          <SingleYearPlan1
            :companyLogo="companyLogo"
            :companyName="companyName"
            :singleYearBalanceResult="singleYearBalanceResult"
            Mode="spending"
          />
        </div>
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import SingleYearPlan1 from './SingleYearPlan1';
  import {
    headerStyle,
    headerStyle2,
    partHeaderStyle,
    subHeaderStyle,
    bodyStyle,
    titleStyle,
    unitPrice,
    tableFont,
    tableStyle,
    topHeaderStyle,
    topHeaderStyle2,
  } from '../../../../components/ExportReport/style';

  export default {
    props: {
      companyLogo: String,
      companyName: String,
      singleYearBalanceResult: Object,
    },

    components: {
      CommonLayout,
      SingleYearPlan1,
    },
    data() {
      return {
        headerStyle,
        headerStyle2,
        partHeaderStyle,
        subHeaderStyle,
        bodyStyle,
        titleStyle,
        unitPrice,
        tableFont,
        tableStyle,
        topHeaderStyle,
        topHeaderStyle2,
        col1Style: {
          width: '17%',
        },
        col2Style: {
          width: '17%',
        },
        col3Style: {
          'text-align': 'right',
          width: '8%',
        },
        col4Style: {
          width: '8%',
        },
        col5Style: {
          'text-align': 'right',
          width: '17%',
        },
        col6Style: {
          width: '33%',
        },
      };
    },
    computed: {
      singleYearBalanceIncomeList() {
        return this.singleYearBalanceResult.income.items;
      },
      singleYearBalanceSpendingList() {
        return this.singleYearBalanceResult.spending.items;
      },
    },
  };
</script>
