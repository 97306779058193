// @ts-check
import { rotate, translate, scale as sc } from 'transformation-matrix';
import { Matrix } from './matrix';
import { radToDeg, degToRad } from './utils';
import { Vec } from './vector';

export class Transform {
  /** @type {Vec} */
  origin;
  /** @type {Vec} */
  translate;
  /** @type {number} */
  rotate;
  /** @type {Vec}*/
  scale;

  /**
   * @param {Partial<Transform>?} params
   */
  constructor(params = null) {
    this.translate = params?.translate
      ? new Vec(params.translate.x, params.translate.y)
      : new Vec();
    this.origin = params?.origin ? new Vec(params.origin.x, params.origin.y) : new Vec();
    this.rotate = params?.rotate ?? 0;
    this.scale = params?.scale ? new Vec(params.scale.x, params.scale.y) : new Vec(1, 1);
  }

  get rotateDeg() {
    return (Math.round(radToDeg(this.rotate)) + 360) % 360;
  }

  /**
   * @param {Matrix} mat
   * @returns {Transform}
   */
  static fromMatrix(mat) {
    const tr = mat.decompose();
    return new Transform({
      translate: tr.translate,
      rotate: tr.rotate,
      scale: tr.scale,
      origin: tr.origin,
    });
  }

  /**
   * @param {Vec} translate
   * @returns {Transform}
   */
  translateTo(translate) {
    const tr = this.cloneWith({ translate });
    return tr;
  }

  /**
   * @param {Vec} vec
   * @returns {Transform}
   */
  translateBy(vec) {
    const tr = this.cloneWith({ translate: this.translate.add(vec) });
    return tr;
  }

  /**
   * @param {number} rotate
   * @returns {Transform}
   */
  rotateTo(rotate) {
    const tr = this.cloneWith({
      rotate,
    });
    return tr;
  }

  /**
   * @param {number} deg
   * @returns {Transform}
   */
  rotateDegTo(deg) {
    const tr = this.cloneWith({ rotate: degToRad(deg) });
    return tr;
  }

  /**
   * @param {number} deg
   * @returns {Transform}
   */
  rotateDegBy(deg) {
    const tr = this.cloneWith({ rotate: this.rotate + degToRad(deg) });
    return tr;
  }

  /**
   * @param {number} rad
   * @returns {Transform}
   */
  rotateBy(rad) {
    const tr = this.cloneWith({ rotate: this.rotate + rad });
    return tr;
  }

  /**
   * @param {Vec} scale
   * @returns {Transform}
   */
  scaleTo(scale) {
    const tr = this.cloneWith({ scale });
    return tr;
  }

  /**
   * @param {Vec} scale
   * @returns {Transform}
   */
  scaleBy(scale) {
    const tr = this.cloneWith({
      scale: new Vec(this.scale.x * scale.x, this.scale.y * scale.y),
    });
    return tr;
  }

  /**
   * @returns {Matrix}
   */
  matrix() {
    const mat = new Matrix();
    const tr = translate(this.translate.x, this.translate.y);
    const rot = rotate(this.rotate, this.origin.x, this.origin.y);
    const sca = sc(this.scale.x, this.scale.y, this.origin.x, this.origin.y);
    return mat.transform(tr, rot, sca);
  }

  clone() {
    return this.cloneWith({});
  }

  /**
   * @param {Partial<Transform>} params
   */
  cloneWith(params) {
    const translate = params.translate
      ? new Vec(params.translate.x, params.translate.y)
      : this.translate;
    const origin = params.origin ? new Vec(params.origin.x, params.origin.y) : this.origin;
    const clone = new Transform({
      translate,
      rotate: params.rotate ?? this.rotate,
      scale: new Vec(params.scale?.x ?? this.scale.x, params.scale?.y ?? this.scale.y),
      origin,
    });
    return clone;
  }
}
