import { API, graphqlOperation } from 'aws-amplify';
import { getMaintenanceFlag } from '@/graphql/queries';

//メンテナンス時間の取得
export default async function loadMaintenance() {
  //メンテナンスを強制回避モード=========================================

  let mainteForcedAvoidance = false;
  try {
    if (process.env.VUE_APP_DEBUG_MODE == 'TRUE') mainteForcedAvoidance = true;
  } catch {
    mainteForcedAvoidance = false;
  }
  //===================================================================

  if (mainteForcedAvoidance == false) {
    //メンテ中か確認する
    try {
      let result = await API.graphql(
        graphqlOperation(getMaintenanceFlag, {
          void: 'null',
        })
      );
      return result.data.getMaintenanceFlag;
    } catch (e) {
      console.error('読み込み失敗', e);
      return {
        statusCode: 500,
      };
    }
  } else if (mainteForcedAvoidance == true) {
    console.log('メンテナンス回避モード');
    return {
      statusCode: 200,
      error: null,
      result: {
        maintenanceFlag: 'false',
        remarks: null,
        startTime: null,
      },
    };
  }
}
