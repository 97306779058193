<template>
  <g>
    <defs>
      <pattern
        v-if="imageUrl"
        :id="patternId"
        :width="obj.style.patternWidth"
        :height="patternHeight"
        patternUnits="userSpaceOnUse"
        :x="obj.style.patternOffsetX"
        :y="obj.style.patternOffsetY"
        :patternTransform="'rotate('+ (isNaN(obj.style.patternRotate) ? 0 : obj.style.patternRotate) + ')'"
      >
        <image
          v-if="imageUrl"
          ref="image"
          :href="imageUrl"
          :width="patternWidth"
          :height="patternHeight"
          crossorigin="anonymous"
          :opacity="obj.style.patternOpacity / 100"
          />
      </pattern>
    </defs>
    <path
      :d="d"
      data-part="body"
      data-area="object"
      :data-id="obj.id"
      :fill="fill"
      :fill-opacity="obj.style.patternOpacity / 100"
      :stroke="obj.style.stroke"
      :stroke-width="obj.style.strokeWidth"
      class="pv-floor"
    />
    <path
      v-for="(v, i) in obj.vertexes"
      :key="i + 'edge'"
      :d="edgeD[i]"
      :data-id="obj.id"
      :data-index="i"
      data-part="edge"
      data-area="object"
      fill="transparent"
      stroke="transparent"
      :stroke-width="obj.style.strokeWidth + 5"
    />
    <circle
      v-for="(v, i) in obj.vertexes"
      :key="i + 'vertex'"
      :cx="v.x"
      :cy="v.y"
      r="3"
      :data-id="obj.id"
      :data-index="i"
      data-part="vertex"
      data-area="object"
      fill="black"
      stroke="transparent"
      :stroke-width="obj.style.strokeWidth"
    />
  </g>
</template>
<script>
  // @ts-check
  import { computed, defineComponent, toRefs, Ref, ref } from '@vue/composition-api';
  import { useLoadImage } from '../../lib/state/loadImage';
  import { PvObject } from '../../lib/node/node/pvObject';
  import { nextCycleIndex } from '../../lib/util/util';
  import { useFloorMaterials } from '../../lib/state/floorMaterials';
  import { PathData } from '../../lib/util/path';

  export default defineComponent({
    props: {
      obj: Object, // PvObject
    },
    setup(props) {
      /** @type {{obj: Ref<PvObject>}} */
      // @ts-ignore
      const { obj } = toRefs(props);

      const { floorMaterials } = useFloorMaterials();

      const imageUrl = computed(() => {
        const o = obj.value;
        const material = floorMaterials.value.find(f => f.url === o.style.imageUrl);

        // imageUrlもblobも無い場合は終了
        if (!o.style.imageUrl && material?.blob == null) {
          return null;
        }
        // blobが存在するならcreateObjectURL()、imageUrlだけならimageUrlを返す
        return material?.blob ? URL.createObjectURL(material?.blob) : o.style.imageUrl;
      });

      const { naturalWidth, naturalHeight } = useLoadImage(imageUrl);

      const patternWidth = computed(() => {
        return obj.value.style.patternWidth;
      });

      const patternHeight = computed(() => {
        const o = obj.value;
        return (o.style.patternWidth * naturalHeight.value) / naturalWidth.value;
      });

      /** @type {Ref<imageUrl|null>} */
      const image = ref(null);

      const d = computed(() => {
        const o = obj.value;
        return PathData.path(obj.value.vertexes, o.arcPoints);
      });

      const patternId = computed(() => {
        return `bg-image-${obj.value.id}`;
      });

      const fill = computed(() => {
        const o = obj.value;
        if (imageUrl.value != '' && imageUrl.value != null) {
          return `url(#${patternId.value})`;
        } else {
          return o.style.fill;
        }
      });

      const edgeD = computed(() => {
        const o = obj.value;
        if (o.vertexes.length < 2) {
          return '';
        }
        return o.vertexes.map((v, i) => {
          const next = o.vertexes[nextCycleIndex(i, o.vertexes.length)];
          if (next.sub(v).absSquare() === 0) {
            return '';
          }
          return PathData.linePath([v, next], o.arcPoints[i]);
        });
      });

      return {
        d,
        edgeD,
        patternId,
        imageUrl,
        image,
        patternWidth,
        patternHeight,
        naturalWidth,
        naturalHeight,
        fill,
      };
    },
  });
</script>
