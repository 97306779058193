<!--
作成者：Lee HyeonSeok
作成日：2020.6.10
画面名：イメージ入力フォーム
機能：イメージをダウンロード、照会する。
親子関係：
更新者：NakatsukaDaisuke
①
更新内容：バリデーション不具合修正
①
-->
<template>
  <div class="form-group">
    <label v-if="title" style="display: flex;">
      {{ title }}
      <allyBadge :mode="hideBadge ? 'View' : mode" :rules="rules" />
    </label>
    <div>
      <label
        class="btn btn-ally form-control"
        :for="getName + 'images'"
        :class="mode == 'View' ? 'labelDisable' : ''"
        v-if="imageCount() < imageMax && mode != 'View'"
        >{{ butonLabel == null ? '画像選択' : butonLabel }}</label
      >
      <ValidationProvider ref="validation" :rules="ImageRules" v-slot="{ errors, validate }">
        <b-form-file
          v-model="setImageData"
          :state="Boolean(setImageData)"
          :id="getName + 'images'"
          multiple="multiple"
          @change="
            e => {
              validateInfo = validate(e);
              fileLoad(e);
            }
          "
          v-show="false"
          :disabled="mode == 'View' || imageDownloading === true"
          accept="image/png, image/jpeg"
        ></b-form-file>
        <span v-if="errors[0] != null" style="color: #dc3545; font-size: 80%;">
          <div>{{ errorMessage(errors) }}</div>
        </span>
      </ValidationProvider>
      <div id="multiple-file-preview" class="row">
        <div class="col-2" v-if="imageDownloading">
          <load width="53px" height="33px" margin="30px 0px 0px 0px"></load>
        </div>
        <div class="col-5" v-for="(image, i) in imageList" :key="i">
          <img
            v-if="image && !image.isDeleted && !imageDownloading"
            :src="image.fileDataURL"
            style="max-width:150px;height:100px;border-radius:10%;"
            @click="viewImage(i)"
          />
        </div>
      </div>
    </div>
    <b-modal
      :id="'imagemodal_' + getName"
      @ok="deleteImg"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      :ok-title="mode == 'View' ? 'キャンセル' : '削除'"
      :ok-only="mode == 'View'"
    >
      <img :src="currentImage.fileDataURL" class="col-12" v-if="currentImage" />
    </b-modal>
    <div>
      <allyAlert :code="'E0002'" :params="{ number: imageMax }" ref="imageLimit"></allyAlert>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import awsconfig from '../../../aws-exports';

  import { API, graphqlOperation } from 'aws-amplify';
  import { getS3Url } from '../../../graphql/queries';
  import load from '../../../components/Common/Load';
  import { extend } from 'vee-validate';
  import { required, ext, size } from 'vee-validate/dist/rules';
  import { v4 as uuidv4 } from 'uuid';

  API.configure(awsconfig);

  extend('required', required);
  extend('ext', ext);
  extend('size', size);

  export default {
    // mode: View/Edit/Add
    // title: 項目のタイトル
    // value: 値
    // loading: ロード中確認
    // max: 画像アップロード制限数を設定（デフォルト値 = 6 ）
    // butonLabel: ボタンに表示する文言
    // propertyId: 物件情報のフォルダを指定する。
    // propertyDirectory: 物件情報のさらに下層のフォルダを指定する。
    // hideBadge: バッジ非表示
    props: [
      'mode',
      'value',
      'title',
      'loading',
      'name',
      'private',
      'max',
      'butonLabel',
      'propertyId',
      'propertyDirectory',
      'rules',
      'hideBadge',
      'companyId',
    ],
    data: function() {
      return {
        level: this.private === undefined ? 'public' : 'private',
        imageDownloading: true,
        initCount: null,
        images: null,
        currentImage: null,
        imageList: null,
        validateInfo: false,
        imageMax: 6, //最大アップロード画像枚数（default）
        imageSize: 5120, //画像サイズ（KB）
        extension: 'jpg,png,jpeg', //アップロード可能拡張子
        setImageData: null,
        getName: this.name,
      };
    },
    computed: {
      //ruleの設定
      ImageRules() {
        let rule = '';
        if (this.rules != null) rule += this.rules;
        if (this.extension != null) rule += '|ext:' + this.extension; //アップロード可能な拡張子
        if (this.imageSize != null) rule += '|size:' + this.imageSize; //画像の上限サイズ
        return rule;
      },
    },
    mounted: async function() {
      if (this.max) this.imageMax = this.max;
      await this.getImages();
    },
    watch: {
      images: {
        deep: true,
        handler: function() {
          if (this.images == null) {
            this.imageList = null;
            return;
          }
          // 表示するイメージリストを変更
          this.imageList = this.images.filter(x => x.isDeleted != true);
          // 値を変更すると値を返上
          this.$emit(
            'input',
            this.imageList.map(x => x.fileName)
          );
        },
      },
      loading: function() {
        // ロードが終わったら値を初期化
        if (this.loading === false) {
          this.result = this.value;
          this.getImages();
        }
      },
    },
    methods: {
      getImages: async function() {
        try {
          this.imageDownloading = true;
          if (this.images == null) this.images = [];
          if (!this.value) return;
          //ファイル名だけの時
          // S3からイメージURLを受けてくる
          var links = await API.graphql(
            graphqlOperation(getS3Url, {
              level: this.level,
              group: this.companyId ? 'Company-' + this.companyId : null,
              file: this.value.map(x => {
                return {
                  fileName: x,
                  fileAction: 'View',
                  propertyDirectory: {
                    propertyId: this.propertyId,
                    fileDirectory: this.propertyDirectory || '/',
                  },
                };
              }),
            })
          );
          // Download
          for (var i = 0; i < links.data.getS3Url.length; i++) {
            var link = links.data.getS3Url[i];

            var response = await axios({
              url: link.fileUrl,
              responseType: 'blob',
            });

            await this.blobToDataURL(new Blob([response.data]), link.fileName);
          }
        } finally {
          this.imageDownloading = false;
        }
      },
      /**
       * フォームから画像を削除する。
       */
      clearFiles: function() {
        // 初期化
        this.images = [];
        this.setImageData = null;
        this.$refs.validation.reset();
      },
      fileLoad: async function(e) {
        var files = await e.target.files;

        // イメージ数チェック
        if (this.images && this.imageCount() + files.length > this.imageMax) {
          this.$refs.imageLimit.show('E0002');
          this.clearFiles();
          return;
        }

        //対応していない拡張子の場合、画像を保存しない
        var valInfo = await this.validateInfo.then(value => {
          return value;
        });
        if (valInfo.valid == false) {
          return;
        }

        // イメージロード
        for (var i = 0; i <= files.length; i++) {
          var file = files[i];
          var reader = new FileReader();
          if (file) reader.readAsDataURL(file);

          reader.onload = this.addImage;
        }
      },
      addImage: async function(imageOrigin) {
        // Image-DataURL
        var image = imageOrigin.target.result;
        // Image-Blob
        var fileBlob = this.dataURLtoBlob(image);

        // イメージHash
        //var fileHash = md5(image);
        var fileHash = await uuidv4();
        // イメージ拡張子
        var fileExt = image.substring('data:image/'.length, image.indexOf(';base64'));
        // ファイル名
        var fileName = fileHash + '.' + fileExt;
        var isExists = this.images == null ? false : this.images.find(x => x.fileName == fileName);

        //再度追加したらisDeletedフラグfalse
        if (isExists) {
          isExists.isDeleted = false;
          return;
        }

        //画像を選択
        this.images.push({
          fileBlob: fileBlob, // blob
          fileName: fileName, // ファイル名
          fileDataURL: image, // イメージDataUrl
          fileExt: fileExt, // ファイルタイプ
          isAdded: true, // 追加プラグ
          isDeleted: false, // 削除プラグ
        });
      },
      viewImage: function(i) {
        // イメージモダル呼び出し
        this.currentImage = this.imageList[i];
        this.currentImage.index = i;
        this.$bvModal.show('imagemodal_' + this.name);
      },
      dataURLtoBlob: function(dataurl) {
        // DataURL => Blob
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      },
      blobToDataURL: async function(blob, fileName) {
        return new Promise(resolve => {
          // Blob => DataURL
          var reader = new FileReader();
          reader.onload = event => {
            this.images.push({
              fileBlob: blob,
              fileName: fileName,
              fileDataURL: event.target.result,
              isAdded: false,
              isDeleted: false,
            });
            resolve();
          };
          reader.readAsDataURL(blob);
        });
      },
      /**
       * 編集中の画像を削除する。
       */
      deleteImg: function() {
        // イメージ削除
        if (this.mode == 'View') return;
        else if (this.mode == 'Edit') this.currentImage.isDeleted = true;
        else if (this.mode == 'Add') {
          this.images.splice(this.currentImage.index, 1);
          if (this.images.length <= 0) this.clearFiles();
        }
      },
      fileUpload: async function() {
        var links = await API.graphql(
          graphqlOperation(getS3Url, {
            level: this.level,
            group: this.companyId ? 'Company-' + this.companyId : null,
            file: this.images
              .filter(x => x.isAdded || x.isDeleted)
              .map(x => {
                return {
                  fileName: x.fileName,
                  fileAction: x.isAdded ? 'Add' : 'Delete',
                  propertyDirectory: {
                    propertyId: this.propertyId,
                    fileDirectory: this.propertyDirectory || '/',
                  },
                };
              }),
          })
        );
        for (var i = 0; i < links.data.getS3Url.length; i++) {
          var link = links.data.getS3Url[i];
          var file = await this.images.filter(x => x.fileName == link.fileName)[0];
          if (!file) continue;
          await axios.put(link.fileUrl, await new File([file.fileBlob], link.fileName), {
            'Content-Type': 'image/' + file.fileExt,
          });
        }
      },
      // イメージ数(削除を除く)
      imageCount: function() {
        if (this.images == null) return 0;
        return this.images.filter(v => {
          return !v.isDeleted;
        }).length;
      },
      /**
       * エラーメッセージを設定
       */
      errorMessage: function(errors) {
        let str = '';
        if (errors[0].indexOf('必須項目') >= 0) {
          str = '画像が選択されていません';
        } else if (errors[0].indexOf('有効なファイル形式') >= 0) {
          str = '有効なファイル形式ではありません';
        } else if (errors[0].indexOf('B以内でなければなりません') >= 0) {
          str = '画像のサイズは' + this.imageSize + 'KB以内でなければなりません';
        }
        return str;
      },
    },
    components: {
      load,
    },
  };
</script>

<style scoped>
  input[type='file'] {
    display: none;
  }
  .labelDisable {
    opacity: 0.65;
  }
  img {
    width: auto !important;
  }
</style>
