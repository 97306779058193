// @ts-check

import { CurveActionBuilder } from '../../action/builder/curveActionBuilder';
import { MoveActionBuilder } from '../../action/builder/moveActionBuilder';
import { MoveEdgeActionBuilder } from '../../action/builder/moveEdgeActionBuilder';
import { MoveVertexActionBuilder } from '../../action/builder/moveVertexActionBuilder';
import { EventPresetBuilder } from '../eventPresetBuilder';
import { EventPreset } from '../eventPreset';
import { RotateActionBuilder } from '../../action/builder/rotateActionBuilder';
import { ResizePrintAreaBuilder } from '../../action/builder/resizePrintAreaBuilder';

export class SelectPreset extends EventPresetBuilder {
  build() {
    const { getRoot } = this.context;

    const preset = new EventPreset();

    preset.click = dataset => {
      if (dataset.button === 'left' || dataset.button == null) {
        if (!dataset.ctrlKey) getRoot().unselectAll();

        if (dataset.target === 'object') {
          if (dataset.ctrlKey) {
            // CTRLキー押下時：トグル動作
            dataset.targetObject?.selectToggle();
          } else {
            // 通常時：単一選択
            dataset.targetObject?.select();
          }
        }
      }
    };

    preset.dragstart = dataset => {
      if (dataset.target === 'layer') {
        // 処理なし
      } else if (dataset.target === 'handle') {
        const part = dataset.part;
        if (part === 'rotate') {
          this.actionManager.setAction(new RotateActionBuilder(this.context, dataset));
        } else if (part === 'curve') {
          this.actionManager.setAction(new CurveActionBuilder(this.context, dataset));
        } else if (part === 'edge') {
          this.actionManager.setAction(new MoveEdgeActionBuilder(this.context, dataset));
        } else if (part === 'vertex') {
          this.actionManager.setAction(new MoveVertexActionBuilder(this.context, dataset));
        } else {
          //
        }
      } else if (dataset.target === 'object') {
        this.actionManager.setAction(new MoveActionBuilder(this.context, dataset));
      } else if (dataset.target === 'print-area') {
        const part = dataset.part;
        if (part === 'vertex') {
          this.actionManager.setAction(new ResizePrintAreaBuilder(this.context, dataset));
        } else {
          this.actionManager.setAction(new MoveActionBuilder(this.context, dataset));
        }
      }
    };

    return preset;
  }
}
