<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：初期投資/付帯費用/許認可費
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="LicensingFees">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button id="BtnLicensingFees" variant="link" v-b-toggle.tabLicensingFees>
          許認可費
        </b-button>
      </b-card-header>
      <b-collapse id="tabLicensingFees" role="tabpanel">
        <b-card-body>
          <b-tabs fill pills v-model="investmentAnalytics.initialInvestment.isDetailLicensingCost">
            <b-tab title="概算入力">
              <b-container>
                <b-row>
                  <b-col cols="2" class="title bold">総額</b-col>
                  <b-col cols="2" class="title ">
                    (税抜)
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="component">
                        <allyText
                          name="LicensingFeesTotal"
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="investmentAnalytics.initialInvestment.estimatedLicensingCost"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
                <b-row>
                  <b-col cols="2" class="title bold" />
                  <b-col cols="2" class="title">
                    (税込)
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            sumTax(
                              Math.floor(
                                investmentAnalytics.initialInvestment.estimatedLicensingCost
                              )
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="詳細入力">
              <b-container class="detail">
                <b-row>
                  <b-col class="title bold">項目</b-col>
                  <b-col>
                    <b-row>
                      <b-col class="title-center bold">許認可費</b-col>
                      <b-col cols="5" />
                    </b-row>
                  </b-col>
                  <b-col class="title-center ">詳細</b-col>
                </b-row>
                <b-row>
                  <b-col class="title">建築確認申請</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(
                              investmentAnalytics.initialInvestment.licensingCost[0].amount
                            ) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabLicensingFees1>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabLicensingFees1" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">申請内容</b-col>
                            <b-col class="ally-select">
                              <allySelect
                                name="licensingCost1"
                                :items="licensingCostItems[0]"
                                v-model="
                                  investmentAnalytics.initialInvestment.licensingCost[0].content
                                "
                              />
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">申請件数</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[0]
                                        .quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">件</b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '建築確認申請',
                                        investmentAnalytics.initialInvestment.licensingCost[0]
                                          .content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円/件</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        0,
                                        Math.floor(
                                          investmentAnalytics.initialInvestment.licensingCost[0]
                                            .quantity
                                        ) *
                                          getUnitPrice(
                                            '建築確認申請',
                                            investmentAnalytics.initialInvestment.licensingCost[0]
                                              .content
                                          )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">申請費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[0].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(
                                        investmentAnalytics.initialInvestment.licensingCost[0]
                                          .quantity
                                      ) *
                                        getUnitPrice(
                                          '建築確認申請',
                                          investmentAnalytics.initialInvestment.licensingCost[0]
                                            .content
                                        ))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">建築完了検査</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.licensingCost[1].amount) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabLicensingFees2>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabLicensingFees2" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">申請件数</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[1]
                                        .quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">件</b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="getUnitPrice('建築完了検査', '有') | currency"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円/件</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        1,
                                        Math.floor(investmentAnalytics.initialInvestment.licensingCost[1]
                                          .quantity) * getUnitPrice('建築完了検査', '有')
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">検査費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[1].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(investmentAnalytics.initialInvestment.licensingCost[1]
                                        .quantity) *
                                        getUnitPrice('建築完了検査', '有'))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">構造計算適合判定</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.licensingCost[2].amount) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabLicensingFees3>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabLicensingFees3" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">申請件数</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[2]
                                        .quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">件</b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="getUnitPrice('構造計算適合判定', '有') | currency"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円/件</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        2,
                                        Math.floor(investmentAnalytics.initialInvestment.licensingCost[2]
                                          .quantity) * getUnitPrice('構造計算適合判定', '有')
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">検査費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[2].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(investmentAnalytics.initialInvestment.licensingCost[2]
                                        .quantity) *
                                        getUnitPrice('構造計算適合判定', '有'))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">道路切り下げ工事許可<br />(道路法24条許可)</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.licensingCost[3].amount) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabLicensingFees4>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabLicensingFees4" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">申請件数</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[3]
                                        .quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">件</b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="getUnitPrice('道路切り下げ工事許可', '有') | currency"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円/件</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        3,
                                        Math.floor(investmentAnalytics.initialInvestment.licensingCost[3]
                                          .quantity) * getUnitPrice('道路切り下げ工事許可', '有')
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">申請費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[3].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(investmentAnalytics.initialInvestment.licensingCost[3]
                                        .quantity) *
                                        getUnitPrice('道路切り下げ工事許可', '有'))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">開発許可</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.licensingCost[4].amount) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabLicensingFees5>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabLicensingFees5" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline" style="padding-top:0;">※地積</b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col class="title bold">申請内容</b-col>
                            <b-col class="ally-select">
                              <allySelect
                                name="licensingCost5"
                                :items="licensingCostItems[4]"
                                v-model="
                                  investmentAnalytics.initialInvestment.licensingCost[4].content
                                "
                              />
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    mode="View"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.propertyDescription[0]
                                        .area
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline" style="padding-top:0;">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        4,
                                        getUnitPrice(
                                          '開発許可申請',
                                          investmentAnalytics.initialInvestment.licensingCost[4]
                                            .content
                                        )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">申請費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[4].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '開発許可申請',
                                        investmentAnalytics.initialInvestment.licensingCost[4]
                                          .content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">都市計画法53条許可</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.licensingCost[5].amount) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabLicensingFees6>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabLicensingFees6" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline" style="padding-top:0;">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        5,
                                        getUnitPrice('都市計画法53条許可申請', '有')
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">申請費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[5].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="getUnitPrice('都市計画法53条許可申請', '有') | currency"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">農地転用許可</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.licensingCost[6].amount) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabLicensingFees7>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabLicensingFees7" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline" style="padding-top:0;"
                                  >※目安
                                  <button
                                    @click="
                                      changeGuideline(6, getUnitPrice('農転5条許可申請', '有'))
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">申請費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[6].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="getUnitPrice('農転5条許可申請', '有') | currency"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">宅造規制法許可</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.licensingCost[7].amount) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabLicensingFees8>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabLicensingFees8" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline" style="padding-top:0;">※地積</b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">申請内容</b-col>
                            <b-col class="ally-select">
                              <allySelect
                                name="licensingCost8"
                                :items="licensingCostItems[7]"
                                v-model="
                                  investmentAnalytics.initialInvestment.licensingCost[7].content
                                "
                              />
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    mode="View"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.propertyDescription[0]
                                        .area
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '宅地造成等許可申請',
                                        investmentAnalytics.initialInvestment.licensingCost[7]
                                          .content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        7,
                                        getUnitPrice(
                                          '宅地造成等許可申請',
                                          investmentAnalytics.initialInvestment.licensingCost[7]
                                            .content
                                        )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">申請費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[7].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '宅地造成等許可申請',
                                        investmentAnalytics.initialInvestment.licensingCost[7]
                                          .content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">公拡法届出</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.licensingCost[8].amount) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabLicensingFees9>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabLicensingFees9" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline" style="padding-top:0;">
                                  ※目安
                                  <button
                                    @click="changeGuideline(8, getUnitPrice('公拡法申請', '有'))"
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">申請費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[8].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="getUnitPrice('公拡法申請', '有') | currency"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">土対法届出</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.licensingCost[9].amount) | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabLicensingFeesA>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabLicensingFeesA" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline" style="padding-top:0;">
                                  ※目安
                                  <button
                                    @click="changeGuideline(9, getUnitPrice('土対法申請', '有'))"
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">申請費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[9].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="getUnitPrice('土対法申請', '有') | currency"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">大店立地法</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.licensingCost[10].amount)
                              | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabLicensingFeesB>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabLicensingFeesB" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline" style="padding-top:0;">※地積</b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">面積</b-col>
                            <b-col class="ally-select">
                              <allySelect
                                name="licensingCost11"
                                :items="licensingCostItems[10]"
                                v-model="
                                  investmentAnalytics.initialInvestment.licensingCost[10].content
                                "
                              />
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    mode="View"
                                    :separation="true"
                                    v-model="
                                      investmentAnalytics.initialInvestment.propertyDescription[0]
                                        .area
                                    "
                                    :floatLength="2"
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">㎡</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline" style="padding-top:0;">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        10,
                                        getUnitPrice(
                                          '大店立地法申請',
                                          investmentAnalytics.initialInvestment.licensingCost[10]
                                            .content
                                        )
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">申請費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[10].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      getUnitPrice(
                                        '大店立地法申請',
                                        investmentAnalytics.initialInvestment.licensingCost[10]
                                          .content
                                      ) | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">占用許可</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.licensingCost[11].amount)
                              | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">
                        円
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabLicensingFeesC>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabLicensingFeesC" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col class="title bold">申請件数</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[11]
                                        .quantity
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">件</b-col>
                              </b-row>
                            </b-col>
                            <b-col />
                          </b-row>
                          <b-row>
                            <b-col class="title bold">単価</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="getUnitPrice('占用許可', '有') | currency"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円/件</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="guideline">
                                  ※目安
                                  <button
                                    @click="
                                      changeGuideline(
                                        11,
                                        Math.floor(investmentAnalytics.initialInvestment.licensingCost[11]
                                          .quantity) * getUnitPrice('占用許可', '有')
                                      )
                                    "
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">申請費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[11].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="
                                      (Math.floor(investmentAnalytics.initialInvestment.licensingCost[11]
                                        .quantity) *
                                        getUnitPrice('占用許可', '有'))
                                        | currency
                                    "
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">消防法</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            Math.floor(investmentAnalytics.initialInvestment.licensingCost[12].amount)
                              | currency
                          "
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title-center">
                    <b-row>
                      <b-col>
                        <b-button class="btn-detail" variant="ally" v-b-toggle.tabLicensingFeesD>
                          <i class="when-open ti-angle-double-up" />
                          <i class="when-closed ti-angle-double-down" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-collapse id="tabLicensingFeesD" role="tabpanel">
                      <b-card-body>
                        <b-container>
                          <b-row>
                            <b-col />
                            <b-col />
                            <b-col>
                              <b-row>
                                <b-col class="guideline" style="padding-top:0;">
                                  ※目安
                                  <button
                                    @click="changeGuideline(12, getUnitPrice('消防許可', '有'))"
                                    class="btn-ally"
                                  >
                                    反映
                                  </button>
                                </b-col>
                                <b-col cols="3" />
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="title bold">申請費</b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <allyText
                                    type="number"
                                    class="number"
                                    :separation="true"
                                    halfWidth
                                    v-model="
                                      investmentAnalytics.initialInvestment.licensingCost[12].amount
                                    "
                                    roundType="down"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-row>
                                <b-col class="component">
                                  <b-form-input
                                    disabled
                                    class="currency"
                                    :value="getUnitPrice('消防許可', '有') | currency"
                                  />
                                </b-col>
                                <b-col cols="3" class="unit">円</b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-card-body>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">その他申請費用</b-col>
                  <b-col cols="5" class="component">
                    <b-row>
                      <b-col class="component">
                        <allyText
                          type="number"
                          class="number"
                          :separation="true"
                          halfWidth
                          v-model="investmentAnalytics.initialInvestment.licensingCost[13].amount"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="1" class="unit">円</b-col>
                    </b-row>
                  </b-col>
                  <b-col class="title" />
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Calculation from './../Calculation';
  import Vue from 'vue';
  export default {
    mixins: [Calculation],
    data: function() {
      return {
        // 許認可費用
        licensingCostItems: [
          // [0] 建築確認申請
          this.getUnitPricesItems('建築確認申請'),
          // [1] 建築完了検査
          this.getUnitPricesItems('建築完了検査'),
          // [2] 構造計算適合判定
          this.getUnitPricesItems('構造計算適合判定'),
          // [3] 道路法24条許可
          this.getUnitPricesItems('道路法24条許可'),
          // [4] 開発許可
          this.getUnitPricesItems('開発許可申請'),
          // [5] 都市計画法53条許可
          this.getUnitPricesItems('都市計画法53条許可申請'),
          // [6] 農地転用許可
          this.getUnitPricesItems('農転5条許可申請'),
          // [7] 宅造規制法許可
          this.getUnitPricesItems('宅地造成等許可申請'),
          // [8] 公拡法届出
          this.getUnitPricesItems('公拡法申請'),
          // [9] 土対法届出
          this.getUnitPricesItems('土対法申請'),
          // [10] 大店立地法
          this.getUnitPricesItems('大店立地法申請'),
          // [11] 占用許可
          this.getUnitPricesItems('占用許可'),
          // [12]	消防法
          this.getUnitPricesItems('消防許可'),
        ],
      };
    },
    methods: {
      changeGuideline: function(id, sum) {
        Vue.set(this.investmentAnalytics.initialInvestment.licensingCost[id], 'amount', sum);
      },
    },
    watch: {
      // マスタ更新時
      '$store.state.master.unitPrice': {
        handler: function() {
          // 選択項目更新
          this.licensingCostItems = [
            this.getUnitPricesItems('建築確認申請'),
            this.getUnitPricesItems('建築完了検査'),
            this.getUnitPricesItems('構造計算適合判定'),
            this.getUnitPricesItems('道路法24条許可'),
            this.getUnitPricesItems('開発許可申請'),
            this.getUnitPricesItems('都市計画法53条許可申請'),
            this.getUnitPricesItems('農転5条許可申請'),
            this.getUnitPricesItems('宅地造成等許可申請'),
            this.getUnitPricesItems('公拡法申請'),
            this.getUnitPricesItems('土対法申請'),
            this.getUnitPricesItems('大店立地法申請'),
            this.getUnitPricesItems('占用許可'),
            this.getUnitPricesItems('消防許可'),
          ];
        },
        deep: false,
      },
    },
  };
</script>
<style scoped>
  #LicensingFees .detail .card-body {
    padding-top: 0;
    padding-bottom: 8px;
  }
  #LicensingFees .guideline {
    padding-top: 22px;
    font-size: medium;
    text-align: center;
  }
</style>
