<template>
  <CommonLayout :title="title">
    <template v-slot>
      <ReportSubTitle :subTitle="subTitle" />
      <div
        style="
                    display: flex;
                    padding-left: 210px;
                    padding-top: 50px;
                "
      >
        <div
          style="
                    width: 50%;
                "
        >
          <div
            style="
                        font-size: 25mm;
                        font-weight: bold;
                        text-align: center;
                        padding-top: 7%;
                        padding-bottom: 3%;
                    "
          >
            {{ chartCaption }}
          </div>
          <ReportCard height="530mm">
            <template v-slot>
              <div style="padding: 10mm 0 0 0;">
                <ReportCanvas v-if="chartType === 'allyBarChartV'" width="100%" height="1800px">
                  <template v-slot>
                    <allyBarChartV
                      :data="chartData"
                      :options="chartOption"
                      style="height: 1800px; width: 2500px"
                    />
                  </template>
                </ReportCanvas>
                <ReportCanvas v-if="chartType === 'allyLineChart'" width="100%" height="1800px">
                  <template v-slot>
                    <allyLineChart
                      :data="chartData"
                      :options="chartOption"
                      style="height: 1800px; width: 2500px"
                    />
                  </template>
                </ReportCanvas>
                <ReportCanvas v-if="chartType === 'allyBarChartH'" width="100%" height="1800px">
                  <template v-slot>
                    <allyBarChartH
                      :data="chartData"
                      :options="chartOption"
                      style="height: 1800px; width: 2500px"
                    />
                  </template>
                </ReportCanvas>
              </div>
            </template>
          </ReportCard>
        </div>
        <div
          style="
                    width: 50%;
                    height: 100%;
                    padding: 3% 4% 1% 4%;
                "
        >
          <ReportCard height="600mm">
            <template>
              <div
                style="
                                font-size: 30mm;
                                padding: 5mm 0 0 20mm;
                            "
              >
                解説
              </div>
              <hr />
              <div
                v-for="(comment, i) in commentList"
                :key="i"
                style="
                                    padding: 20px 0 0 20mm;
                                    font-size: 60px;
                                "
                v-html="comment.replace('script', '')"
              ></div>
            </template>
          </ReportCard>
        </div>
      </div>
    </template>
  </CommonLayout>
</template>

<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import ReportSubTitle from '@/components/ExportReport/ReportSubTitle';
  import ReportCanvas from '@/components/ExportReport/ReportCanvas';
  import ReportCard from '@/components/ExportReport/ReportCard';

  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      subTitle: {
        type: String,
        required: true,
      },
      chartCaption: {
        type: String,
        default: '',
      },
      chartType: {
        type: String,
        required: true,
      },
      chartData: {
        type: Object,
        required: true,
      },
      commentList: {
        type: Array,
        required: true,
      },
      chartOption: {
        type: Object,
        required: true,
      },
    },
    components: {
      CommonLayout,
      ReportSubTitle,
      ReportCanvas,
      ReportCard,
    },
  };
</script>
