<template>
  <CommonLayout
    :title="Math.floor(investmentAnalytics.salePlan.timeOfSale) + '年後の売却益（概略）'"
    :companyLogo="companyLogo"
    :companyName="companyName"
  >
    <template v-slot>
      <div style="display: flex;" v-if="result != null">
        <div style="display: grid; font-size: 60px; width: 100%; margin: 70px 100px;">
          <div style="width: 100%;">
            <div :style="topHeaderStyle">概略</div>
            <div :style="titleStyle">
              収入の部
            </div>
            <div :style="unitPrice">
              単位：円
            </div>
            <table style="width: 100%">
              <thead>
                <tr>
                  <th :style="[headerStyle]">項目</th>
                  <th :style="[headerStyle]">金額</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in result.income.items[0].items" :key="i">
                  <td :style="subHeaderStyle" style="width: 50%">{{ !item || item.name }}</td>
                  <td :style="{ ...bodyStyle, 'text-align': 'right' }" style="width: 50%">
                    {{
                      formatNum(!item || item.amount || item.total, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table style="width: 100%">
              <tbody>
                <tr>
                  <td :style="headerStyle" style="width: 50%">合計</td>
                  <td :style="{ ...headerStyle, 'text-align': 'right' }" style="widht: 50%">
                    {{
                      formatNum(result.income.total, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                </tr>
              </tbody>
            </table>

            <div :style="titleStyle">
              支出の部
            </div>
            <div :style="unitPrice">
              単位：円
            </div>
            <table style="width: 100%">
              <thead>
                <tr>
                  <th :style="[headerStyle, { width: '50%' }]">項目</th>
                  <th :style="[headerStyle, { width: '50%' }]">
                    金額
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in result.spending.items" :key="i">
                  <td :style="subHeaderStyle">{{ item.name }}</td>
                  <td :style="{ ...bodyStyle, 'text-align': 'right' }">
                    {{
                      formatNum(item.amount || item.total, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                </tr>
                <tr>
                  <td :style="headerStyle" style="width: 50%">支出合計</td>
                  <td :style="{ ...headerStyle, 'text-align': 'right' }" style="width: 50%">
                    {{
                      formatNum(result.spending.total, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                </tr>
                <tr>
                  <td :style="subHeaderStyle" style="width: 50%">収支</td>
                  <td :style="{ ...subHeaderStyle, 'text-align': 'right' }" style="width: 50%">
                    {{
                      formatNum(result.total, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                </tr>
                <tr>
                  <td :style="subHeaderStyle" style="width: 50%">譲渡税</td>
                  <td :style="{ ...subHeaderStyle, 'text-align': 'right' }" style="width: 50%;">
                    {{
                      formatNum(result.transferTax, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                </tr>
                <tr>
                  <td :style="headerStyle" style="width: 50%">売却益</td>
                  <td :style="{ ...headerStyle, 'text-align': 'right' }" style="width: 50%">
                    {{
                      formatNum(result.gainOnSaleAfterTax, {
                        floatLength: 0,
                        separation: true,
                        rountType: 'down',
                      })
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import {
    headerStyle,
    partHeaderStyle,
    subHeaderStyle,
    bodyStyle,
    titleStyle,
    unitPrice,
    tableFont,
    tableStyle,
    topHeaderStyle,
  } from '../../../../components/ExportReport/style';

  export default {
    props: {
      companyLogo: String,
      companyName: String,
      result: Object,
    },
    components: {
      CommonLayout,
    },
    data() {
      return {
        gainOnSaleResult: null,
        headerStyle,
        partHeaderStyle,
        subHeaderStyle,
        bodyStyle,
        titleStyle,
        unitPrice,
        tableFont,
        tableStyle,
        topHeaderStyle,
        col1Style: {
          width: '17%',
        },
        col2Style: {
          width: '25%',
        },
        col3Style: {
          'text-align': 'right',
          width: '8%',
        },
        col4Style: {
          width: '8%',
        },
        col5Style: {
          'text-align': 'center',
          width: '17%',
        },
        col6Style: {
          width: '25%',
        },
      };
    },
  };
</script>
