// @ts-check

/**
 * SVGのスタイル等の設定
 */
export class PvStyle {
  stroke = '';
  spaceName = '';
  strokeWidth = 20;
  strokeLinecap = 'butt';
  strokeDasharray = '';
  fill = '';
  patternWidth = 200;
  patternOffsetX = 0;
  patternOffsetY = 0;
  patternRotate = 0;    // パターンの回転
  patternOpacity = 100; // パターンの透明度(0:透明～100:非透明 ※属性値指定時に1/100する)
  imageName = null;
  imageUrl = '';
  /** @type {number|null} */
  ratio = null;
  flipHorizontal = false; // 左右反転
  flipVertical = false; // 上下反転
  textAnchor = 'middle';
  fontSize = 10;

  /**
   * @param {Partial<PvStyle>|null} style
   */
  constructor(style = null) {
    if (style != null) {
      Object.assign(this, style);
    }
  }

  /**
   * クローン
   * @returns {PvStyle}
   */
  clone() {
    return new PvStyle(this);
  }
}
