<template>
  <div>
    <div class="outer-frame" :style="styles" v-if="loadFlag">
      <div
        @mousedown="onMouseDown"
        @mousemove="onMouseMove"
        @mouseup="onMouseUp"
        @mouseout="onMouseUp"
      >
        <div class="buttons" style="padding-left: 30%;" v-if="viewFlag == true">
          <div class="inner-button" style="right:50px" @click="rightButton">
            →
          </div>
        </div>
        <div class="buttons" v-if="viewFlag == true">
          <div class="inner-button" style="right:20px" @click="leftButton">←</div>
        </div>

        <div class="d-flex" style="width: 100%; height: 100%;">
          <div
            class="inner-frame"
            v-for="(image, index) of slideImages"
            :key="index"
            :style="_listStyle"
          >
            <div class="imageMargin">
              <img :src="image" class="images" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading" v-if="loadFlag == false">
      <Load margin="30px 0px" />
    </div>
    <div class="loading" v-if="viewFlag == false">
      <img :src="noImageUrl" />
    </div>
  </div>
</template>
<script>
  import Load from '../../../components/Common/Load';
  export default {
    props: [
      'slideImages', //画像URLの配列を渡す
      'max_width',
      'max_height',
      'loadFlag',
    ],
    data: function() {
      return {
        mouseFlag: false,
        startX: null,
        currentNum: 0,
        diffX: 0,
        viewFlag: false,
        imageCount: 0,
        imegeCountEnd: 1,
        noImageUrl: require('../../../images/no-image.png'),
      };
    },
    computed: {
      // バインドするスタイルを生成
      styles() {
        return {
          '--maxWidth': this.max_width == null ? 'auto' : this.max_width + 'px',
          '--maxHeight': this.max_height == null ? 'auto' : this.max_height + 'px',
        };
      },
      _listStyle() {
        return {
          transition: this.startX == null ? '' : 'none',
          transform: `translate3d(${-100 * this.currentNum}%, 0, 0) translate3d(${
            this.diffX
          }px, 0, 0)`,
        };
      },
    },
    mounted: function() {
      if (this.slideImages != null) {
        if (this.slideImages.length > 0) {
          this.viewFlag = true;
          this.currentNum = 0;
          this.imageCount = this.slideImages.length;
        }
      }
    },
    watch: {
      slideImages() {
        if (this.slideImages != null) {
          if (this.slideImages.length > 0) {
            this.viewFlag = true;
            this.currentNum = 0;
            this.imageCount = this.slideImages.length;
          }
        }
      },
    },
    methods: {
      onMouseDown(e) {
        this.moveFlag = true;
        this.startX = e.clientX;
      },
      onMouseMove(e) {
        if (this.startX == null) {
          return;
        }
        this.diffX = e.clientX - this.startX;
      },
      onMouseUp() {
        this.moveFlag = false;
        this.startX = null;
        if (this.diffX > 20) {
          this.currentNum = Math.max(this.currentNum - 1, 0);
          if (this.diffX > Number(this.max_width) + 20)
            this.currentNum = Math.max(this.currentNum - 1, 0);
        }
        if (this.diffX < -20) {
          this.currentNum = Math.min(this.currentNum + 1, this.imageCount - this.imegeCountEnd);
          if (this.diffX < -Number(this.max_width) - 20)
            this.currentNum = Math.min(this.currentNum + 1, this.imageCount - this.imegeCountEnd);
        }
        this.diffX = 0;
      },
      leftButton() {
        this.currentNum = Math.max(this.currentNum - 1, 0);
      },
      rightButton() {
        this.currentNum = Math.min(this.currentNum + 1, this.imageCount - this.imegeCountEnd);
      },
    },
    components: {
      Load,
    },
  };
</script>

<style scoped>
  .outer-frame {
    overflow-x: hidden;
    width: auto;
    height: auto;
    background-color: #353535;
  }

  .inner-frame {
    padding: 10px;
    transition: transform 0.5s;
    pointer-events: none;
    user-select: none;
  }
  .images {
    max-width: var(--maxWidth);
    max-height: var(--maxHeight);
    pointer-events: none;
    user-select: none;
  }
  .buttons {
    z-index: 1;
    position: absolute;
    height: var(--maxHeight);
    user-select: none;
  }

  .inner-button {
    position: relative;
    top: 35%;
    color: white;
    width: 30px;
    height: 50%;
    background-color: #b1b1b1;
    padding: 15px 5px;
    opacity: 0.4;
    transition: 0.2s;
    border-radius: 5px;
  }

  .inner-button:hover {
    opacity: 1;
  }

  .inner-button:active {
    background-color: #ffffff;
  }

  .loading {
    width: var(--maxWidth);
    height: var(--maxHeight);
  }
</style>
<style scoped>
  .table td img {
    width: auto;
    height: auto;
    border-radius: 0%;
  }
</style>
