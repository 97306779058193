<template>
  <div class="margin-bottom">
    <div class="info-box">
      <b-container class="main">
        <b-row class="info-title">
          特定商取引法に基づく表記
        </b-row>

        <b-row class="info-content form-control overflow-auto " readonly>
          <b-col style="padding:0;">
            <div class="title">サービスの名称</div>
            <div>ALLY-PRO</div>
            <div><br /></div>
            <div class="title">事業者の名称</div>
            <div>ダイナステージ株式会社</div>
            <div><br /></div>
            <div class="title">事業者の所在地</div>
            <div>〒020-0861 岩手県盛岡市仙北二丁目7番6号</div>
            <div><br /></div>
            <div class="title">通信販売業務の責任者</div>
            <div>菊池 聡</div>
            <div><br /></div>
            <div class="title">特商法表記に関するお問い合わせ先</div>
            <div>TEL：019-656-0300</div>
            <div>Mail ally@dynastage.co.jp</div>
            <div><br /></div>
            <div class="title">購入方法</div>
            <div>Webページ</div>
            <div><br /></div>
            <div class="title">利用料</div>
            <div class="title">◆商品の価格表示箇所</div>
            <div>ALLY-PROサイト内の料金表ページに記載しております。</div>
            <div><br /></div>
            <div class="title">◆商品代金以外の必要料金</div>
            <div>お振込み手数料はお客様のご負担となります。（銀行振込の場合）</div>
            <div>※なお、動作に必要な環境は、お客様が自ら整備するものとします。</div>
            <div><br /></div>
            <div><br /></div>
            <div class="title">動作環境</div>
            <div>ALLY-PRO</div>
            <div>推奨OS：Windows10以上 / Mac 最新版OS</div>
            <div>推奨ブラウザ：Google Chrome 最新版</div>
            <div><br /></div>
            <div>ALLY-ARシミュレーター・ALLY オートスケッチ</div>
            <div>対象デバイス：iPhone12 Pro以降 / iPad Pro 11-inch (第2世代) 以降</div>
            <div><br /></div>
            <div class="title">お支払い方法</div>
            <div>下記お支払い方法がお選びいただけます。</div>
            <div><br /></div>
            <div>・クレジットカード決済</div>
            <div>・銀行振込</div>
            <div>・口座振替</div>
            <div><br /></div>
            <div class="title">お支払い期限</div>
            <div>
              月額利用料は、無料トライアル期間が終了した翌月1日に初めてのご請求が発生し、毎月27日（口座振替で27日が土日祝日の場合は翌営業日）に前月利用分を請求いたします。
            </div>
            <div>
              なお、クレジットカードでお支払いの場合のお客様のお支払い時期は、お客様とクレジットカード会社等との会員規約に基づきます。
            </div>
            <div><br /></div>
            <div class="title">商品の引き渡し時期</div>
            <div>ライセンス購入手続き完了後、すみやかにご利用いただけます。</div>
            <div><br /></div>
            <div class="title">サービスのキャンセル・返品（返金）</div>
            <div>
              お客様のアカウントページにて、ライセンスの解約（翌月のサービス利用料請求を停止）を行うことができます。
            </div>
            <div>
              なお、商品の特性上、購入確定後のキャンセル・交換・返品（返金）はお受けできません。
            </div>
            <div><br /></div>
            <div class="title">瑕疵がある場合</div>
            <div>購入した商品を使用できない場合、上記の連絡先までお問い合わせください。</div>
            <div><br /></div>
            <div class="title">プライバシーポリシー</div>
            <div>ALLY-PROサイト内のプライバシーポリシーをご確認ください。</div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="check-info">
      <b-form-checkbox v-model="infoChecker">
        上記の内容を確認した上、「特定商取引法に基づく表記」に同意します
      </b-form-checkbox>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'info',
    props: ['infoCheck'],
    data: function() {
      return {
        infoChecker: this.infoCheck,
        space: '　',
      };
    },
    watch: {
      infoChecker: function() {
        this.$emit('update:infoCheck', this.infoChecker);
      },
    },
  };
</script>
<style scoped>
  .margin-bottom {
    margin-bottom: 15px;
  }
  .info-box {
    background-color: rgba(43, 160, 132, 0.2);
  }
  .info-title {
    font-weight: bold;
    padding-top: 5px;
    margin-left: -5px;
  }
  .info-content {
    width: calc(30px + 100%);
    height: calc(35rem - 43px);
    font-size: 14px;
  }
  .info-content table td:first-child {
    display: flex;
  }
  .check-info {
    margin-top: 15px;
    margin-bottom: 10px;
    text-align: center;
  }
  .check-info >>> .custom-control-input:checked ~ .custom-control-label::before {
    background: var(--colorTheme);
    border-color: var(--colorTheme);
  }
  .title {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 4px;
  }
</style>
