<!--
作成者：Lee HyeonSeok
作成日：2020.6.3
画面名：個人顧客用フォーム
機能：個人顧客用フォーム
親子関係：View.vue
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <div class="row">
      <div class="col-md-5">
        <div class="row">
          <allyText
            class="col-6"
            title="姓"
            name="lastName"
            v-model="lastName"
            :mode="mode"
            rules="required"
            placeholder="新井"
            optionalOutput
          />
          <allyText
            class="col-6"
            title="名"
            name="firstName"
            v-model="firstName"
            :mode="mode"
            rules="required"
            placeholder="太郎"
            optionalOutput
          />
        </div>
        <div class="row">
          <allyText
            class="col-6"
            title="姓（カナ）"
            name="lastNameKana"
            v-model="lastNameKana"
            :mode="mode"
            rules="required|onlykatakana"
            placeholder="アライ"
            optionalOutput
          />
          <allyText
            class="col-6"
            title="名（カナ）"
            name="firstNameKana"
            v-model="firstNameKana"
            :mode="mode"
            rules="required|onlykatakana"
            placeholder="タロウ"
            optionalOutput
          />
        </div>
        <div class="row">
          <allyText
            class="col-12"
            title="電話番号"
            name="tel"
            v-model="tel"
            :mode="mode"
            rules="tel"
            placeholder="0196000000"
            optionalOutput
          />
        </div>
        <div class="row">
          <allyText
            class="col-12"
            title="携帯番号"
            name="phone"
            v-model="phone"
            :mode="mode"
            rules="tel"
            placeholder="09000000000"
            optionalOutput
          />
        </div>
        <div class="row">
          <allyText
            class="col-12"
            title="FAX番号"
            name="fax"
            v-model="fax"
            :mode="mode"
            rules="tel"
            placeholder="0196000000"
            optionalOutput
          />
        </div>
        <div class="row">
          <allyText
            class="col-12"
            title="E-mail"
            name="mail"
            v-model="mail"
            :mode="mode"
            rules="email"
            placeholder="xxx@xxx.xx.jp"
            optionalOutput
          />
        </div>
        <div class="row">
          <allyRadio
            class="col-12"
            title="性別"
            name="gender"
            :items="[
              { text: '男', value: 1 },
              { text: '女', value: 2 },
            ]"
            v-model="gender"
            :mode="mode"
            rules="required"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="col-6" style="border-right: 1px solid rgb(154, 154, 154); height: 100%"></div>
      </div>
      <div class="col-md-5">
        <div class="row">
          <allyDate
            class="col-12"
            title="生年月日"
            name="birthday"
            v-model="birthday"
            :mode="mode"
          />
        </div>
        <allyAddress v-model="address" :mode="mode" rules="required" :optionalOutput="true"/>
        <businessCard
          :originBusinessCard="businessCard"
          :originCompanyInfo="companyInfo"
          :mode="mode"
          @input="updatebusinessCardInfo => setBusinessInfo(updatebusinessCardInfo)"
          ref="businessCard"
        />
      </div>
    </div>

    <!--家族情報-->
    <b-modal
      id="familyModal"
      size="lg"
      title="家族情報追加"
      header-class="ally-modal"
      footer-class="ally-modal"
      body-class="ally-input-background"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="追加"
      :ok-disabled="mode == 'View' ? true : false"
      @ok="pushFamily"
      @hidden="familyModalClear"
    >
      <ValidationObserver tag="div" class="row" slim ref="familyModal">
        <div>
          <div class="col-md-6">
            <div class="row">
              <allyText
                class="col-6"
                title="姓"
                name="familyModalValues.lastName"
                v-model="familyModalValues.lastName"
                :mode="mode"
                rules="required"
                :loading="modalLoading"
                placeholder="新井"
              />
              <allyText
                class="col-6"
                title="名"
                name="familyModalValues.firstName"
                v-model="familyModalValues.firstName"
                :mode="mode"
                rules="required"
                :loading="modalLoading"
                placeholder="太郎"
              />
            </div>
            <div class="row">
              <allyText
                class="col-6"
                title="姓（カナ）"
                name="familyModalValues.lastNameKana"
                v-model="familyModalValues.lastNameKana"
                :mode="mode"
                rules="required|onlykatakana"
                :loading="modalLoading"
                placeholder="アライ"
              />
              <allyText
                class="col-6"
                title="名（カナ）"
                name="familyModalValues.firstNameKana"
                v-model="familyModalValues.firstNameKana"
                :mode="mode"
                rules="required|onlykatakana"
                :loading="modalLoading"
                placeholder="タロウ"
              />
            </div>
            <div class="row">
              <allyText
                class="col-12"
                title="電話番号"
                name="familyModalValues.tel"
                v-model="familyModalValues.tel"
                :mode="mode"
                rules="tel"
                :loading="modalLoading"
                placeholder="0196000000"
              />
            </div>
            <div class="row">
              <allyText
                class="col-12"
                title="携帯番号"
                name="familyModalValues.phone"
                v-model="familyModalValues.phone"
                :mode="mode"
                rules="tel"
                :loading="modalLoading"
                placeholder="09000000000"
              />
            </div>
            <div class="row">
              <allyText
                class="col-12"
                title="E-mail"
                name="familyModalValues.mail"
                v-model="familyModalValues.mail"
                :mode="mode"
                rules="email"
                :loading="modalLoading"
                placeholder="xxx@xxx.xx.jp"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <allyRadio
                class="col-12"
                title="性別"
                name="familyModalValues.gender"
                :items="[
                  { text: '男', value: 1 },
                  { text: '女', value: 2 },
                ]"
                v-model="familyModalValues.gender"
                :mode="mode"
                rules="required"
                :loading="modalLoading"
              />
            </div>
            <div class="row">
              <allyDate
                class="col-12"
                title="生年月日"
                name="familyModalValues.birthday"
                v-model="familyModalValues.birthday"
                :mode="mode"
                :loading="modalLoading"
              />
            </div>
            <div class="row">
              <allyText
                class="col-12"
                title="コメント"
                name="familyModalValues.comment"
                v-model="familyModalValues.comment"
                :mode="mode"
                :loading="modalLoading"
                placeholder="コメント"
              />
            </div>
            <div class="row">
              <allySelect
                class="col-12"
                title="顧客との関係"
                name="familyModalValues.familyType"
                :items="[
                  { value: '夫', text: '夫' },
                  { value: '妻', text: '妻' },
                  { value: '父', text: '父' },
                  { value: '母', text: '母' },
                  { value: '祖父', text: '祖父' },
                  { value: '祖母', text: '祖母' },
                  { value: '兄', text: '兄' },
                  { value: '弟', text: '弟' },
                  { value: '姉', text: '姉' },
                  { value: '妹', text: '妹' },
                  { value: '子', text: '子' },
                ]"
                v-model="familyModalValues.familyType"
                :mode="mode"
                rules="required"
                :loading="modalLoading"
              />
            </div>
            <businessCard
              :originBusinessCard="familyModalValues.businessCard"
              :originCompanyInfo="familyModalValues.companyInfo"
              :mode="mode"
              :loading="modalLoading"
              @input="updatebusinessCardInfo => familySetBusinessInfo(updatebusinessCardInfo)"
              name="familyModal"
              ref="familyModalBusinessCard"
            />
          </div>
        </div>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
  import businessCard from '../../components/Customer/businessCard';

  export default {
    props: ['mode', 'value'],
    data: function() {
      var result = this.value;

      result.familyModalValues = {};

      result.modalLoading = false;

      return result;
    },
    methods: {
      setBusinessInfo: function(result) {
        this.businessCard = result.updateBusinessCardMap;
        this.companyInfo = result.updateBusinessCard;
      },
      familySetBusinessInfo: function(result) {
        this.familyModalValues.companyInfo = result.updateBusinessCard;
        this.familyModalValues.businessCard = result.updateBusinessCardMap;
      },
      pushFamily: function(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.$refs.familyModal.validate().then(async isValid => {
          if (isValid && this.familyModalValues.editIndex > -1) {
            var result = this.family[this.familyModalValues.editIndex];

            result.lastName = this.familyModalValues.lastName;
            result.firstName = this.familyModalValues.firstName;
            result.lastNameKana = this.familyModalValues.lastNameKana;
            result.firstNameKana = this.familyModalValues.firstNameKana;
            result.tel = this.familyModalValues.tel;
            result.phone = this.familyModalValues.phone;
            result.mail = this.familyModalValues.mail;
            result.gender = this.familyModalValues.gender;
            result.birthday = this.familyModalValues.birthday;
            result.comment = this.familyModalValues.comment;
            result.familyType = this.familyModalValues.familyType;
            result.businessCard = this.familyModalValues.businessCard;
            result.companyInfo = this.familyModalValues.companyInfo;
            await this.$refs.familyModalBusinessCard.fileUpload();

            this.$bvModal.hide('familyModal');
            this.familyModalClear();
          } else if (isValid) {
            if (this.family == null) this.family = [];
            this.family.push({
              lastName: this.familyModalValues.lastName,
              firstName: this.familyModalValues.firstName,
              lastNameKana: this.familyModalValues.lastNameKana,
              firstNameKana: this.familyModalValues.firstNameKana,
              tel: this.familyModalValues.tel,
              phone: this.familyModalValues.phone,
              mail: this.familyModalValues.mail,
              gender: this.familyModalValues.gender,
              birthday: this.familyModalValues.birthday,
              comment: this.familyModalValues.comment,
              familyType: this.familyModalValues.familyType,
              businessCard: this.familyModalValues.businessCard,
              companyInfo: this.familyModalValues.companyInfo,
            });

            await this.$refs.familyModalBusinessCard.fileUpload();
            this.$bvModal.hide('familyModal');
            this.familyModalClear();
          }
        });
      },
      familyModalClear: function(item, index) {
        (this.familyModalValues.lastName = item ? item.lastName : null),
          (this.familyModalValues.firstName = item ? item.firstName : null),
          (this.familyModalValues.lastNameKana = item ? item.lastNameKana : null),
          (this.familyModalValues.firstNameKana = item ? item.firstNameKana : null),
          (this.familyModalValues.tel = item ? item.tel : null),
          (this.familyModalValues.phone = item ? item.phone : null),
          (this.familyModalValues.mail = item ? item.mail : null),
          (this.familyModalValues.gender = item ? item.gender : null),
          (this.familyModalValues.birthday = item ? item.birthday : null),
          (this.familyModalValues.comment = item ? item.comment : null),
          (this.familyModalValues.familyType = item ? item.familyType : null),
          (this.familyModalValues.businessCard = item ? item.businessCard : null),
          (this.familyModalValues.companyInfo = item ? item.companyInfo : null),
          (this.familyModalValues.editIndex = index);
      },
      modalOpen: async function(item, index) {
        this.$bvModal.show('familyModal');
        this.modalLoading = true;

        // this.familyModalValues = item;
        // this.familyModalValues.editIndex = index;

        this.$nextTick();
        this.modalLoading = false;
        this.$forceUpdate();
        this.familyModalClear(item, index);
      },
      deleteItem: function(index) {
        this.family.splice(index, 1);
      },
      fileUpload: async function() {
        await this.$refs.businessCard.fileUpload();
      },
    },
    watch: {
      firstName: function() {
        this.customerName = this.lastName + ' ' + this.firstName;
      },
      lastName: function() {
        this.customerName = this.lastName + ' ' + this.firstName;
      },
    },
    components: {
      businessCard,
    },
  };
</script>

<style scoped>
  .ally-divide {
    border: solid 1px black;
  }
</style>
