<!--
作成者：本間
作成日：2021.02.15
画面名：投資分析/入力
機能：初期投資/工事計画/建築付帯工事
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="ConstructionIncidentalWork">
    <div class="mb-2">
      <b-row>
        <b-col class="title bold">
          建築付帯工事
        </b-col>
        <b-col />
        <b-col class="component">
          <b-row>
            <b-col class="component">
              <allyText
                :separation="true"
                type="number"
                class="currency"
                v-model="
                  investmentAnalytics.initialInvestment.constructionPlan.constructionInciDentalWork
                "
                roundType="down"
              />
            </b-col>
            <b-col cols="2" class="unit">円</b-col>
          </b-row>
        </b-col>
        <b-col cols="1" class="title">
          <b-button variant="ally" @click="sumViewFlag = !sumViewFlag">
            <i class="when-open ti-angle-double-up" v-if="sumViewFlag" />
            <i class="when-closed ti-angle-double-down" v-if="!sumViewFlag" />
          </b-button>
        </b-col>
      </b-row>
    </div>
    <span class="card " style="width: 110%;" v-if="sumViewFlag">
      <div class="mt-2"></div>
      <div v-for="id in 4" v-bind:key="`constructionIncidentalWorkList-${id}`" style="width:91%;">
        <!-- 概算入力 -->
        <b-row>
          <b-col class="title bold"> </b-col>
          <b-col class="title">
            {{ constructionIncidentalWorkList.title[id - 1] }}
          </b-col>
          <b-col class="component">
            <b-row>
              <b-col class="component">
                <allyText
                  type="number"
                  class="number"
                  :separation="true"
                  halfWidth
                  v-model="
                    investmentAnalytics.initialInvestment.constructionPlan.constructionCost[id + 1]
                      .amount
                  "
                  roundType="down"
                />
              </b-col>
              <b-col cols="2" class="unit">円</b-col>
            </b-row>
          </b-col>
          <b-col cols="1" class="title">
            <b-button variant="ally" v-b-toggle="`tabInitialInvestment56${id}`">
              <i class="when-open ti-angle-double-up" />
              <i class="when-closed ti-angle-double-down" />
            </b-button>
          </b-col>
        </b-row>
        <!-- 詳細入力 -->
        <b-row>
          <b-col>
            <b-collapse :id="`tabInitialInvestment56${id}`" role="tabpanel">
              <b-card-body>
                <b-container>
                  <b-row v-if="id != 4">
                    <b-col cols="2" class="title bold">工事種別</b-col>
                    <b-col cols="3" class="ally-select">
                      <allySelect
                        :name="`constructionIncidentalWork1-${id}`"
                        :items="constructionIncidentalWorkList.items[id - 1]"
                        v-model="
                          investmentAnalytics.initialInvestment.constructionPlan.constructionCost[
                            id + 1
                          ].content
                        "
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="2" class="title bold">
                      {{ constructionIncidentalWorkList.unitTitle[id - 1] }}
                    </b-col>
                    <b-col cols="3" class="component">
                      <allyText
                        type="number"
                        class="number"
                        :separation="true"
                        halfWidth
                        v-model="
                          investmentAnalytics.initialInvestment.constructionPlan.constructionCost[
                            id + 1
                          ].quantity
                        "
                        roundType="down"
                      />
                    </b-col>
                    <b-col cols="1" class="unit">
                      {{ constructionIncidentalWorkList.unit[id - 1] }}</b-col
                    >
                  </b-row>
                  <b-row>
                    <b-col cols="2" class="title bold">単価</b-col>
                    <b-col cols="3" class="component">
                      <b-form-input
                        disabled
                        class="currency"
                        :value="
                          getUnitPrice(
                            constructionIncidentalWorkList.title[id - 1],
                            id == 4
                              ? '有'
                              : investmentAnalytics.initialInvestment.constructionPlan
                                  .constructionCost[id + 1].content
                          ) | currency
                        "
                      />
                    </b-col>
                    <b-col cols="1" class="unit">円</b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="2" class="title bold">工事費</b-col>
                    <b-col cols="3" class="component">
                      <allyText
                        type="number"
                        class="number"
                        :separation="true"
                        halfWidth
                        v-model="
                          investmentAnalytics.initialInvestment.constructionPlan.constructionCost[
                            id + 1
                          ].amount
                        "
                        roundType="down"
                      />
                    </b-col>
                    <b-col cols="1" class="unit">円</b-col>
                    <b-col cols="1" class="title-right" style="white-space: nowrap;">※目安</b-col>
                    <b-col cols="3" class="component">
                      <b-form-input
                        disabled
                        class="currency"
                        :value="
                          (Math.floor(
                            investmentAnalytics.initialInvestment.constructionPlan.constructionCost[
                              id + 1
                            ].quantity
                          ) *
                            getUnitPrice(
                              constructionIncidentalWorkList.title[id - 1],
                              id == 4
                                ? '有'
                                : investmentAnalytics.initialInvestment.constructionPlan
                                    .constructionCost[id + 1].content
                            ))
                            | currency
                        "
                      />
                    </b-col>
                    <b-col cols="1" class="unit">円</b-col>
                    <button
                      class="btn btn-ally mr-1 cols='1'"
                      style="width: 50px; white-space: nowrap; padding-left: 10px; "
                      @click="
                        changeGuideline(
                          id + 1,
                          investmentAnalytics.initialInvestment.constructionPlan.constructionCost[
                            id + 1
                          ].quantity *
                            getUnitPrice(
                              constructionIncidentalWorkList.title[id - 1],
                              id == 4
                                ? '有'
                                : investmentAnalytics.initialInvestment.constructionPlan
                                    .constructionCost[id + 1].content
                            )
                        )
                      "
                    >
                      反映
                    </button>
                  </b-row>
                </b-container>
              </b-card-body>
              <div class="mt-2"></div>
            </b-collapse>
          </b-col>
        </b-row>
      </div>
    </span>
  </div>
</template>
<script>
  import Vue from 'vue';
  export default {
    data: function() {
      return {
        // 建築付帯工事
        constructionIncidentalWorkList: {
          title: ['水道工事', '下水道工事', 'ガス管工事', '電柱移設工事'],
          items: [
            this.getUnitPricesItems('水道工事'),
            this.getUnitPricesItems('下水道工事'),
            this.getUnitPricesItems('ガス管工事'),
            this.getUnitPricesItems('電柱移設工事'),
          ],
          unitTitle: ['新規引込個数', '新規引込個数', '新規引込個数', '移設本数'],
          unit: ['個', '個', '個', '本'],
        },
        sumViewFlag: false,
        textBoxUpdateFlag: false,
      };
    },
    methods: {
      changeGuideline: function(id, sum) {
        Vue.set(
          this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[id],
          'amount',
          sum
        );
      },
    },
    watch: {
      // マスタ更新時
      '$store.state.master.unitPrice': {
        handler: function() {
          // 単価更新
          this.constructionIncidentalWorkList.items = [
            this.getUnitPricesItems('水道工事'),
            this.getUnitPricesItems('下水道工事'),
            this.getUnitPricesItems('ガス管工事'),
            this.getUnitPricesItems('電柱移設工事'),
          ];
        },
        deep: false,
      },
      /**
       * 詳細を入力後、合計に適用する
       */
      'investmentAnalytics.initialInvestment.constructionPlan.constructionCost': {
        handler: function() {
          let sum = 0;
          let noNullCheck = false;
          for (let i = 2; i <= 5; i++) {
            if (
              this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[i]
                .amount == null
            ) {
              sum += 0;
            } else {
              sum += parseInt(
                this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[i]
                  .amount
              );
              noNullCheck = true;
            }
          }
          if (noNullCheck == true) {
            Vue.set(
              this.investmentAnalytics.initialInvestment.constructionPlan,
              'constructionInciDentalWork',
              sum
            );
          }
          this.textBoxUpdateFlag = true;
        },
        deep: true,
      },
      /**
       * 合計適用したとき、詳細を削除する
       */
      'investmentAnalytics.initialInvestment.constructionPlan.constructionInciDentalWork': {
        handler: function(val) {
          if (this.textBoxUpdateFlag == false) {
            for (let i = 2; i <= 5; i++) {
              this.investmentAnalytics.initialInvestment.constructionPlan.constructionCost[
                i
              ].amount = null;
            }
          }
          this.textBoxUpdateFlag = false;
        },
        deep: false,
      },
    },
  };
</script>
