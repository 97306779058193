<template>
  <div>
    <b-list-group>
      <b-list-group-item>
        <b-form-group label="グリッド表示">
          <b-form-checkbox
            id="showGrid"
            :checked="showGrid"
            @change="val => $emit('showGrid', val)"
            name="showGrid"
            switch
          >
            グリッドを表示する。
          </b-form-checkbox>
        </b-form-group>
      </b-list-group-item>
      <!-- <b-list-group-item>
        <b-form-group label="ポインター位置補正">
          <b-form-checkbox id="pointerOnGrid" :checked="pointerOnGrid" name="pointerOnGrid" switch>
            ポインター位置をグリッドに合わせる。
          </b-form-checkbox>
        </b-form-group>
      </b-list-group-item> -->
      <b-list-group-item>
        <b-form-group label="大グリッド幅">
          <b-form-select
            :value="gridSize"
            @change="val => $emit('gridSize', val)"
            :options="[
              // { text: '400mm', value: 400 },
              { text: '910mm', value: 910 },
              { text: '1000mm', value: 1000 },
            ]"
          ></b-form-select>
        </b-form-group>
      </b-list-group-item>
    </b-list-group>
    <!-- <b-row>
      <b-col class="" sm="12">
        <b-form-checkbox id="showOnImage" v-model="settings.showOnImage" name="showOnImage"
          >画像出力時のグリッド表示
        </b-form-checkbox>
      </b-col>
    </b-row> -->
    <button
      style="position: absolute; height: 60px; width: 10px; top: 0; right: 0; opacity: 0"
      @click="() => (counter += 1)"
    ></button>
  </div>
</template>
<script>
  import { defineComponent, ref, watch } from '@vue/composition-api';
  export default defineComponent({
    props: {
      gridSize: {
        type: Number,
        required: true,
      },
      showGrid: {
        type: Boolean,
        required: true,
      },
    },
    setup() {
      const counter = ref(0);

      watch([counter], () => {
        if (counter.value === 5) {
          window.location.href = 'http://192.168.1.85:8080/';
        }
      });

      return {
        counter,
      };
    },
  });
</script>
<style scoped>
  .list-items {
    pointer-events: none;
  }
</style>
