<template>
  <div class="edit-menu-root" v-if="isEditor">
    <TileBtn
      class="editor-btn"
      borderRadius="100%"
      :width="btnSize"
      :height="btnSize"
      @click="$emit('select-all')"
      v-b-popover.hover.bottom="'全選択 ctrl+a'"
    >
      <i class="icon-07" />
    </TileBtn>
    <TileBtn
      class="editor-btn"
      borderRadius="100%"
      :width="btnSize"
      :height="btnSize"
      @click="$emit('move-front')"
      v-b-popover.hover.bottom="'前面へ移動'"
    >
      <i class="icon-08" />
    </TileBtn>
    <TileBtn
      class="editor-btn"
      borderRadius="100%"
      :width="btnSize"
      :height="btnSize"
      @click="$emit('move-back')"
      v-b-popover.hover.bottom="'背面へ移動'"
    >
      <i class="icon-10" />
    </TileBtn>
    <TileBtn
      class="editor-btn"
      borderRadius="100%"
      :width="btnSize"
      :height="btnSize"
      @click="$emit('forefront')"
      v-b-popover.hover.bottom="'最前面へ移動'"
    >
      <i class="icon-09" />
    </TileBtn>
    <TileBtn
      class="editor-btn"
      borderRadius="100%"
      :width="btnSize"
      :height="btnSize"
      @click="$emit('backmost')"
      v-b-popover.hover.bottom="'最背面へ移動'"
    >
      <i class="icon-11" />
    </TileBtn>
    <TileBtn
      class="editor-btn"
      borderRadius="100%"
      :width="btnSize"
      :height="btnSize"
      @click="$emit('rotate-left')"
      v-b-popover.hover.bottom="'左回転'"
    >
      <i class="icon-12" />
    </TileBtn>
    <TileBtn
      class="editor-btn"
      borderRadius="100%"
      :width="btnSize"
      :height="btnSize"
      @click="$emit('rotate-right')"
      style="transform: scale(-1, 1);"
      v-b-popover.hover.bottom="'右回転'"
    >
      <i class="icon-12" />
    </TileBtn>
    <TileBtn
      class="editor-btn"
      borderRadius="100%"
      :width="btnSize"
      :height="btnSize"
      @click="$emit('copy')"
      v-b-popover.hover.bottom="'コピー ctrl+c'"
    >
      <i class="icon-13" />
    </TileBtn>
    <TileBtn
      class="editor-btn"
      borderRadius="100%"
      :width="btnSize"
      :height="btnSize"
      @click="$emit('delete')"
      v-b-popover.hover.bottom="'削除 ctrl+x'"
    >
      <i class="icon-14" />
    </TileBtn>
    <TileBtn
      class="editor-btn"
      borderRadius="100%"
      :width="btnSize"
      :height="btnSize"
      @click="$emit('merge')"
      v-b-popover.hover.bottom="'部屋を結合'"
    >
      <i class="ti-layout" style="font-size: 1rem; margin-left: -4px" />
    </TileBtn>
    <TileBtn
      class="editor-btn"
      borderRadius="100%"
      :width="btnSize"
      :height="btnSize"
      :selected="resizeHandleFlg"
      @click="$emit('handle')"
      v-b-popover.hover.bottom="'ハンドル表示 ctrl+h'"
    >
      <i class="ti-arrows-corner" style="font-size: 1rem; margin-left: -4px" />
    </TileBtn>
  </div>
</template>
<script>
  import { ref, defineComponent } from '@vue/composition-api';
  import TileBtn from './PvBtn.vue';

  export default defineComponent({
    props: {
      resizeHandleFlg: {
        type: Boolean,
        required: true,
      },
      isEditor: {  //  PROP: 編集権限の有無
        type: Boolean,
        default: true,
      }
    },
    components: {
      TileBtn,
    },
    setup() {
      const btnSize = ref('40px');
      return {
        btnSize,
      };
    },
  });
</script>
<style scoped>
  @keyframes fadeInAnime {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  .edit-menu-root {
    display: flex;
    position: absolute;
    top: 10px;
    right: 100px;
    animation-name: fadeInAnime;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }
  .editor-btn {
    margin-left: 5px;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }
  [class^='icon-'],
  [class*=' icon-'] {
    font-size: 1.2rem;
    margin-left: -5px;
  }
</style>
