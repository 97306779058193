// @ts-check

import { Matrix } from '../../util/math/matrix';
import { Rect } from '../../util/math/rect';
import { Transform } from '../../util/math/transform';
import { degToRad } from '../../util/math/utils';
import { Vec } from '../../util/math/vector';
import { nextCycleIndex } from '../../util/util';
import { HandleFlg } from '../tools/handleFlg';
import { PvStyle } from '../tools/style';
import { PvAdditionalInfo } from '../tools/additionalInfo';
import { getNode, PvNode } from './pvNode';
import { Config } from '../../../../Property/PlaneView/SvgEditor/lib/svgObjects/params/config.js';

/** 一時的なパラメータ名 コピーしない */
export const tempParamsMap = {
  isSelected: true,
  isActive: true,
};

/**
 *  CLASS: エディタ上に表示されるオブジェクトのデータ
 * @extends PvNode<PvObject>
 */
export class PvObject extends PvNode {
  /** @type {string|null}  PROPERTY: アクティブ・オブジェクトID */
  static activeObjectId = null;
  /** @type {HandleFlg}  PROPERTY: handleFlg */
  handleFlg = new HandleFlg();
  /** @type {string}  PROPERTY: ユニークID */
  id;
  /** @type {import('../../types/pvNode').PvNodeType}  PROPERTY: 種別(floor, icon, door, wall, stairs, text, root) */
  type;
  /** @type {string}  PROPERTY: 分類 */
  subType = '';
  /** @type {PvStyle}  PROPERTY: スタイル情報 */
  style = new PvStyle();
  /** @type {Vec[]}  PROPERTY: 頂点の相対位置 ※Transform.translateを基準とする。頂点は時計回りで格納。よって、壁ベクトルの左が外壁、右が内壁になる */
  vertexes = [];
  /** @type {number[]} PROPERTY: 弧と辺の距離(辺の中点から曲線までの最長距離) ※vertexesと対応 */
  arcPoints = [];
  /** @type {Transform}  PROPERTY: アフィン変換(位置、回転) */
  transform = new Transform();
  /** @type {string|null}  PROPERTY: 第一子ID */
  firstChildId = null;
  /** @type {string|null}  PROPERTY: 右兄弟ID */
  rightBrotherId = null;
  /** @type {number}  PROPERTY: 横方向の長さ */
  width = 0;
  /** @type {number}  PROPERTY: 縦方向の長さ */
  height = 0;
  /** @type {number}  PROPERTY: 立体的な高さ */
  height3d = 0;
  /** @type {number}  PROPERTY: 取り付け高さ */
  mountingHeight = 0;
  /** @type {number}  PROPERTY: インデックス */
  index = -1;

  /** @type {number}  PROPERTY: 建具種別 */
  code = -1;
  /** @type {PvAdditionalInfo}  PROPERTY: 建具追加情報 */
  additionalInfo = new PvAdditionalInfo();

  /** @type {string|null}  PROPERTY: 特別なノードに付ける別名 */
  alias = null;
  /** @type {string|null}  PROPERTY: 広さの単位 */
  areaSizeUnit = '帖';
  /** @type {string}  PROPERTY: テキスト */
  text = '';
  /** @type {Boolean} */
  showAreaSize = true;
  constructor() {
    super();
  }

  /** @type {boolean}  PROPERTY: 選択中フラグ */
  isSelected;

  /** @type {boolean}  PROPERTY: アクティブフラグ */
  isActive;

  /**
   * METHOD: アクティベート
   */
  activate(flg = true) {
    if (flg == false) {
      this.isActive = false;
      PvObject.activeObjectId = null;
      return;
    }

    if (PvObject.activeObjectId != null) {
      const obj = /** @type {PvObject} */ (getNode(PvObject.activeObjectId));
      if (obj != null) {
        obj.isActive = false;
      }
    }
    PvObject.activeObjectId = this.id;
    this.isActive = true;
  }

  /**
   * METHOD: 親のタイプで検索
   * @param {string} type
   * @returns {PvObject|null}
   */
  findUpByType(type) {
    let result = null;
    this.upSearch(n => {
      if (n.type === type) {
        result = n;
      }
    });
    return result;
  }

  /**
   * METHOD: targetの座標をローカル座標に変換し
   * @param {Transform} target
   * @returns {Matrix}
   */
  getLocalTransform(target) {
    return this.absoluteMatrix()
      .inverse()
      .transform(target.matrix());
  }

  /**
   * METHOD: 現在の角度から指定分移動
   * @param {Vec} dr
   */
  moveBy(dr) {
    this.transform = this.transform.cloneWith({
      translate: this.transform.translate.add(dr),
    });
  }

  /**
   * METHOD: 指定座標に移動
   * @param {Vec} to
   */
  moveTo(to) {
    this.transform = this.transform.cloneWith({
      translate: to,
    });
  }

  /**
   * METHOD: 現在の角度から指定分回転
   * @param {number} dr
   */
  rotateBy(dr) {
    this.transform = this.transform.cloneWith({
      rotate: this.transform.rotate + degToRad(dr),
    });
  }

  /**
   * METHOD: 指定角度まで回転
   * @param {number} to
   */
  rotateTo(to) {
    this.transform = this.transform.cloneWith({
      rotate: degToRad(to),
    });
  }

  /**
   * METHOD: scaleTo
   * @param {number} scale
   */
  scaleTo(scale) {
    this.transform = this.transform.cloneWith({
      scale: new Vec(scale, scale),
    });
  }

  /**
   * METHOD: scaleXTo
   * @param {number} scaleX
   */
  scaleXTo(scaleX) {
    this.transform = this.transform.cloneWith({
      scale: new Vec(scaleX, this.transform.scale.y),
    });
  }

  /**
   * METHOD: scaleXBy
   * @param {number} scaleX
   */
  scaleXBy(scaleX) {
    this.transform = this.transform.cloneWith({
      scale: new Vec(scaleX * this.transform.scale.x, this.transform.scale.y),
    });
  }

  /**
   * METHOD: scaleYTo
   * @param {number} scaleY
   */
  scaleYTo(scaleY) {
    this.transform = this.transform.cloneWith({
      scale: new Vec(this.transform.scale.x, scaleY),
    });
  }

  /**
   * METHOD: scaleYBy
   * @param {number} scaleY
   */
  scaleYBy(scaleY) {
    this.transform = this.transform.cloneWith({
      scale: new Vec(this.transform.scale.x, scaleY * this.transform.scale.y),
    });
  }

  /**
   * METHOD: setOrigin
   * @param {Vec} origin
   */
  setOrigin(origin) {
    this.transform = this.transform.cloneWith({
      origin: origin,
    });
  }

  /**
   * METHOD: moveVertexBy
   * @param {number} index
   * @param {Vec} vec
   */
  moveVertexBy(index, vec) {
    this.vertexes.splice(index, 1, this.vertexes[index].add(vec));
  }

  /**
   * METHOD: moveEdgeBy
   * @param {number} index
   * @param {Vec} vec
   */
  moveEdgeBy(index, vec) {
    const clone = this.vertexes.slice();
    clone.splice(index, 1, this.vertexes[index].add(vec));
    const nextIndex = nextCycleIndex(index, this.vertexes.length);
    clone.splice(nextIndex, 1, this.vertexes[nextIndex].add(vec));
    this.vertexes = clone;
  }

  /**
   * METHOD: 始点終点により変形
   * @param {Vec} start
   * @param {Vec} end
   */
  deformateFromStartEnd(start, end) {
    const rect = Rect.fromStartEnd(start, end);

    this.vertexes = [
      new Vec(),
      new Vec(rect.width),
      new Vec(rect.width, rect.height),
      new Vec(0, rect.height),
    ];
    this.moveTo(new Vec(rect.x, rect.y));
  }

  /**
   * METHOD: targetの座標系に移動
   * @param {PvObject} target
   */
  exchangeCoordinate(target) {
    this.transform = Transform.fromMatrix(target.getLocalTransform(this.transform));
  }

  /**
   * METHOD: オフセット
   */
  offset() {
    return this.toAbsolutePoint(new Vec());
  }

  /**
   * METHOD: 絶対座標系に投影
   * @returns {Matrix}
   */
  absoluteMatrix() {
    /** @type {PvObject|null} */
    let obj = this;
    const absMat = Matrix.identity();
    const matList = [];
    while (obj) {
      matList.unshift(obj.transform.matrix());
      obj = obj.parent;
    }

    return absMat.transform(...matList);
  }

  /**
   * METHOD: 絶対座標系のTransformを返す
   * @returns {Transform}
   */
  absoluteTransform() {
    return Transform.fromMatrix(this.absoluteMatrix());
  }

  /**
   * METHOD: 頂点の絶対位置を返す
   * @returns {Vec[]}
   */
  absoluteVertexes() {
    const mat = this.absoluteMatrix();
    return mat.points(this.vertexes).map(v => Vec.from(v));
  }

  /**
   * METHOD: このオブジェクトのローカル座標系上の点を絶対位置に変換して返す
   * @param {Vec} vec
   * @returns {Vec}
   */
  toAbsolutePoint(vec) {
    return Vec.from(this.absoluteMatrix().point(vec));
  }

  /**
   * METHOD: バウンディングボックス取得
   * @returns {Rect}
   */
  getBBox() {
    return Rect.fromVecs(this.absoluteVertexes());
  }

  /**
   * METHOD: toJsonObject
   * @returns {Partial<this>}
   */
  toJsonObject() {
    const SAVED_PROPERTY_BLACK_LIST = {
      targetId: true,
      handleFlg: true,
      isSelected: true,
      isActive: true,
    };
    const result = {};
    Object.keys(this).forEach(key => {
      if (SAVED_PROPERTY_BLACK_LIST[key]) {
        return;
      }
      result[key] = this[key];
    });

    return result;
  }

  /**
   * METHOD: select
   */
  select() {
    this.isSelected = true;
    this.activate();
  }

  /**
   * METHOD: 子要素をすべて選択
   */
  selectAllChildren() {
    this.forEachChild(node => {
      node.isSelected = true;
      node.activate();
    });
  }

  /**
   * METHOD: すべて選択解除
   */
  unselectAll() {
    this.dfSearch(node => {
      node.isSelected = false;
      node.activate(false);
    });
  }

  /**
   * METHOD: 選択状態を反転
   */
  selectToggle() {
    this.isSelected = !this.isSelected;
    this.activate(this.isSelected);
  }
}
