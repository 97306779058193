// @ts-check
import { PvCircleHandle } from '../../../node/node/circleHandle';
import { cloneNode, cloneNodeStandalone } from '../../../node/tools/clone';
import { Vec } from '../../../util/math/vector';
import { Action } from '../action';
import { ActionBuilder } from './actionBuilder';
import { PvObject } from '../../../node/node/pvObject';
import { nextCycleIndex } from '../../../util/util';

export class MoveEdgeActionBuilder extends ActionBuilder {
  build() {
    const action = new Action();
    action.actionType = Action.ACTION_TYPE.DRAG_AND_DROP;

    const handle = /** @type {PvCircleHandle} */ (this.dataset.targetObject);
    const target = /** @type {PvObject} */ (handle.target);
    const index = this.dataset.index;
    if (index == null) {
      throw new Error();
    }
    const guideHandle = cloneNode(handle);
    this.context.getGuideRoot().appendChild(guideHandle);

    const guideTarget = cloneNodeStandalone(target);
    this.context.getGuideRoot().appendChild(guideTarget);
    let prevVec = new Vec();
    action.onUpdate = ({ dist }) => {
      guideTarget.moveEdgeBy(index, prevVec.reverse());
      guideTarget.moveEdgeBy(index, dist);
      prevVec = dist;
    };

    action.onComplete = ({ dist }) => {
      const targetVertex1 = target.vertexes[index].add(dist);
      const targetVertex2 = target.vertexes[nextCycleIndex(index, target.vertexes.length)].add(
        dist
      );
      if (target.vertexes.some(v => v.eq(targetVertex1) || v.eq(targetVertex2))) {
        return;
      }

      target.moveEdgeBy(index, dist);
    };
    return action;
  }
}
