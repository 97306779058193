import { API, graphqlOperation } from 'aws-amplify';
import { getSystemMaster, getCompany } from '../../../graphql/queries';

export default {
  filters: {
    // 金額書式編集
    yen(value) {
      return value == 0
        ? null
        : value == null || value == '' || value == undefined || isNaN(value)
        ? null
        : Math.round(Number(value)).toLocaleString('ja-JP', {
            style: 'currency',
            currency: 'JPY',
          });
    },
  },
  methods: {
    // 金額書式編集
    formatYen(value) {
      return value == 0
        ? null
        : value == null || value == '' || value == undefined || isNaN(value)
        ? null
        : Math.round(Number(value)).toLocaleString('ja-JP', {
            style: 'currency',
            currency: 'JPY',
          });
    },

    // 指定日の翌日(YYYY-MM-DD)
    getTomorrow: function(today) {
      let dt = new Date(Date.parse(today));
      dt.setDate(dt.getDate() + 1);
      return dt.toISOString().slice(0, 10);
    },

    // 期間表示
    showPeriod: function(value) {
      return value.quantity + (value.unit == 'year' ? '年' : value.unit == 'month' ? 'ヶ月' : '日');
    },

    // ログインユーザーの会社IDを取得
    getCompanyId: function(user = null) {
      if (user == null) {
        user = this.$store.state.user;
      }
      const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
      const groupIndex = groups.findIndex(group => {
        return group.startsWith('Company-');
      }, 'Company-');

      // systemAdminなら
      if (groupIndex == -1) {
        return null;
      } else {
        return groups[groupIndex].slice(8);
      }
    },

    // ライセンスマスタ取得
    loadMaster: async function() {
      const result = await API.graphql(
        graphqlOperation(getSystemMaster, {
          classification: 'license',
          classificationDetail: '1',
        })
      );

      // 文字列⇒JSON変換
      let items = [];
      result.data.getSystemMaster.value.forEach(element => {
        items.push(JSON.parse(element));
      });

      return items;
    },

    // 会社情報取得
    getCompany: async function(id) {
      const result = await API.graphql(graphqlOperation(getCompany, { id: id }));
      return result.data.getCompany;
    },
  },
};
