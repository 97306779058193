<template>
  <div style="margin: 25px; height: 100%;">
    <div class="row">
      <div class="col-12">
        <router-link to="/DashBoard" tag="button" class="btn btn-ally float-right">
          メインページへもどる
        </router-link>
        <b-button @click="printingButton()" class="mr-2 btn btn-ally float-right">印刷</b-button>
      </div>
    </div>
    <Searchs ref="Searchs" :loading="loading" @setItemsList="val => setItemsList(val)" />
    <div class="table-overflow">
      <b-table
        thead-tr-class="serch-data-table-header"
        tbody-tr-class="serch-data-table-body"
        :busy="loading"
        hover
        striped
        :items="paymentList"
        :fields="fields"
        show-empty
        responsive="sm"
        ref="salesLists"
        :foot-clone="true"
        tfoot-class="serch-data-table-header table-header"
      >
        <template v-slot:table-busy>
          <!--Loading中-->
          <Load />
        </template>
        <template v-slot:empty>
          <!-- itemsが０件の時 -->
          <div class="row">
            <div class="col-12 text-center">
              <svg
                class="bi bi-exclamation-diamond-fill"
                width="8em"
                height="8em"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                />
              </svg>
              <div class="alert">データがありません</div>
            </div>
          </div>
        </template>
        <template v-slot:cell()="row">
          <div v-html="deficitData(zeroDisp(row.item[row.field.key]))"></div>
        </template>
        <template v-slot:cell(companyName)="row">
          <div @click="companyLink(row.item.companyId)" v-b-popover.hover="row.item.companyId">
            <div v-html="row.item[row.field.key]"></div>
          </div>
        </template>
        <template v-slot:cell(paymentMethod)="row">
          {{ paymentMethodWord(row.item[row.field.key]) }}
        </template>
        <template v-slot:cell(adjustmentMethod)="row">
          <div v-if="row.item[row.field.key] == 0">-</div>
          <div v-else>
            <span class="blue" @click="adjustClick(row.item)">{{ row.item[row.field.key] }}</span>
          </div>
        </template>
        <template v-slot:cell(paymentDay)="row">
          <div v-html="getPaymentDayHtml(row.item[row.field.key], row.item)"></div>
        </template>

        <template v-slot:cell(detail)="row">
          <div class="btn" @click="licenseDetaiClick(row.item)">{{ row.item[row.field.key] }}</div>
        </template>
        <!-- foot -->
        <template #foot(customerNumber)="">
          <div>合計</div>
        </template>
        <template #foot(companyName)="">
          <div>{{ paymentList.length }}件</div>
        </template>
        <template #foot(salesAmount)="">
          <div v-html="deficitData(getAmountSum('salesAmount'))"></div>
        </template>
        <template #foot(depositAmount)="">
          <div v-html="deficitData(getAmountSum('depositAmount'))"></div>
        </template>
        <template #foot(depositDifference)="">
          <div v-html="deficitData(getAmountSum('depositDifference'))"></div>
        </template>
        <template #foot(adjustmentDifference)="">
          <div v-html="deficitData(getAmountSum('adjustmentDifference'))"></div>
        </template>
        <template #foot(adjustmentMethod)="">
          <div
            v-html="
              deficitData(
                getSum(getAmountSum('adjustmentDifference'), getAmountSum('depositDifference'))
              )
            "
          ></div>
        </template>
        <template #foot()=""> <div></div> </template>
      </b-table>
      <div class="pt-4"></div>
    </div>
    <AdjustmentMethodModal ref="adjustmentMethodModal" @initialize="initialize" />
    <LicenseDetailModal ref="LicenseDetailModal" />
  </div>
</template>

<script>
  var moment = require('moment-timezone');
  moment.tz.setDefault('Asia/Tokyo');
  import Load from '../../components/Common/Load';
  import datasGraphQL from './js/datasGraphQL.js';
  import wordData from './js/wordData.js';
  import util from './js/util.js';
  import Searchs from './sub/Searchs';
  import AdjustmentMethodModal from './sub/AdjustmentMethodModal';
  import LicenseDetailModal from './sub/LicenseDetailModal';

  export default {
    data() {
      return {
        loading: true,
        paymentList: [],
        fields: [],
        companys: null,
        aozoraPayment: null,
      };
    },
    mixins: [datasGraphQL, wordData, util],
    components: { Load, Searchs, AdjustmentMethodModal, LicenseDetailModal },
    mounted: async function() {
      await this.initialize();
    },
    methods: {
      /**
       * データ初期化
       */
      initialize: async function() {
        this.loading = true;
        this.companys = null;
        this.aozoraPayment = null;

        //  MOUNTED: 項目一覧
        this.setfields();

        //  MOUNTED: 会社情報一覧取得
        this.companys = await this.getCompanys();
        this.aozoraPayment = await this.getAozoraPaymentData(this.companys);

        //  MOUNTED: データ一覧
        this.$refs.Searchs.search();
        this.loading = false;
      },
      /**
       *  METHOD: リストフィールドセット
       */
      setfields() {
        this.fields = [
          { key: 'customerNumber', label: '顧客番号', thClass: 'field-01' },
          { key: 'companyName', label: '会社名', thClass: 'field-02' },
          { key: 'salesAmount', label: '売上額', thClass: 'field-03' },
          { key: 'billingAmount', label: '請求額', thClass: 'field-04' },
          { key: 'depositAmount', label: '入金額', thClass: 'field-05' },
          { key: 'depositDifference', label: '入金差額', thClass: 'field-06' },
          { key: 'adjustmentDifference', label: '調整差額', thClass: 'field-07' },
          { key: 'adjustmentMethod', label: '調整方法', thClass: 'field-08' },
          { key: 'salesConfirmationDate', label: '売上確定日', thClass: 'field-09' },
          { key: 'settlementDate', label: '決済日', thClass: 'field-10' },
          { key: 'paymentDay', label: '入金日', thClass: 'field-11' },
          { key: 'paymentMethod', label: '支払方法', thClass: 'field-12' },
          { key: 'detail', label: '詳細', thClass: 'field-13' },
        ];
      },
      /**
       *  METHOD: 表示データ一覧を取得
       */
      setItemsList: function(filters) {
        //リストを初期化する
        let paymentList = [];

        //一覧情報の作成
        for (let company of this.companys) {
          if (company.payment != null) {
            for (let payment of company.payment) {
              //配列化する
              paymentList.push({
                companyId: company.id,
                customerNumber: company.customerNumber,
                companyName: this.companyNameView(company.companyName, company),
                settlementDate: payment.billingDate,
                salesAmount: parseInt(payment.amount) + parseInt(payment.tax),
                billingAmount: this.getBillingAmount(payment, company),
                depositAmount: this.getDepositAmount(payment, company),
                depositDifference:
                  parseInt(this.getDepositAmount(payment, company)) -
                  parseInt(this.getBillingAmount(payment, company)),
                adjustmentDifference: this.getAdjustmentDifference(payment, company),
                adjustmentMethod: this.adjustmentCheck(company, payment),
                salesConfirmationDate: moment(payment.billingDate).format('YYYY-MM-01'),
                paymentDay: this.getPaymentDay(payment, company),
                paymentMethod: payment.payType,
                detail: '内訳',
                paymentId: payment.id,
              });
            }
          }
        }

        //filterがnullの場合はそのまま返却する
        if (filters == null) {
          this.paymentList = paymentList;
          return;
        }

        //filter処理を行う
        for (let fil of filters) {
          paymentList = paymentList.filter(val => fil.func(val, fil.variable));
        }
        this.paymentList = paymentList;
      },
      /**
       *  METHOD: 支払方法を文字列化する
       */
      paymentMethodWord(str) {
        let payTypes = this.getPaymentType();
        let payType = payTypes.find(val => val.no == str);

        //取得できなかった場合空欄を返却
        if (payType == null) return '-';

        return payType.name;
      },
      /**
       *  METHOD: 0の時、ハイフンに変更する
       */
      zeroDisp: function(str) {
        if (str == null) {
          return '-';
        } else if (str == 0) {
          return '-';
        }

        return str;
      },
      /**
       *  METHOD: 入金額処理
       */
      getDepositAmount: function(payment, company) {
        let sum = 0;

        //あおぞらの場合
        if (payment.payType == this.getPaymentType('BANK_TRANSFER').no) {
          let aozora = this.aozoraPayment.find(val => val.id == company.id);
          if (aozora != null) {
            //console.log('aozora', aozora);
            let getPayment = aozora.pay.filter(val => {
              return moment(val[0]).isBetween(
                moment(payment.billingDate)
                  .subtract(1, 'months')
                  .add(1, 'days'),
                moment(payment.billingDate),
                null,
                '[]'
              );
            });

            for (let pay of getPayment) {
              sum += parseInt(pay[4]);
            }
            if (payment?.depositRefundInfo?.status == 3) {
              sum += payment.depositRefundInfo.amount;
            }

            return sum;
          } else {
            if (payment?.depositRefundInfo?.status == 3) {
              return payment.depositRefundInfo.amount;
            }
            return 0;
          }
        }
        //クレカと、口座振替の場合
        else {
          //先月の決済情報を取得
          const lastMonthPaymentId = this.createPaymentId(payment.id, -1);

          //先月の請求情報が未納の場合かつ、入金状態の場合、入金額を加算する
          let pay = company.payment.find(val => val.id == lastMonthPaymentId);
          if (pay?.depositRefundInfo != null) {
            //未納
            if (pay.depositRefundInfo.status == 1) {
              if (pay.paymentResult == true) {
                sum += parseInt(pay.amount) + parseInt(pay.tax);
              }
            }
          }

          //trueの時入金が完了状態にする
          if (payment?.paymentResult == true) {
            if (payment?.depositRefundInfo?.status == 1) {
              //一度でも未納なら加算しない
            } else {
              sum += parseInt(payment.amount) + parseInt(payment.tax);
            }
          }
        }

        return sum;
      },
      /**
       *  METHOD: 調整が必要な場合表示
       * adjustmentCheck
       */
      adjustmentCheck: function(company, payment) {
        let depositRefundInfo = company.payment.find(val => val.id == payment.id).depositRefundInfo;
        if (depositRefundInfo == null) return 0;
        let depositRefund = this.getDepositRefundStatus();
        let data = depositRefund.find(val => val.no == depositRefundInfo.status);
        if (data == null) return 0;
        return data.displayName;
      },
      /**
       *  METHOD: 調整方法を選択モーダルを表示する
       */
      adjustClick(item) {
        let company = this.companys.find(val => val.id == item.companyId);
        this.$refs.adjustmentMethodModal.showModal(company, item);
      },
      licenseDetaiClick(item) {
        let company = this.companys.find(val => val.id == item.companyId);
        this.$refs.LicenseDetailModal.showModal(item, company);
      },
      /** METHOD: 会社情報ページへ遷移する */
      companyLink(companyId) {
        this.$router.push('/Company/View/' + companyId);
      },
      /** METHOD: 請求不払いがある場合、赤字で表示する */
      companyNameView(companyName, company) {
        let flag = true;
        for (let payment of company.payment) {
          if (payment.paymentResult == false) {
            flag = false;
          }
        }
        if (flag == false) {
          return '<dev class="red">' + companyName + '_!</dev>';
        }
        return '<dev class="blue">' + companyName + '</dev>';
      },
      getAmountSum(str) {
        let sum = 0;
        for (let payment of this.paymentList) {
          sum += parseInt(payment[str]);
        }

        return sum;
      },
      getSum(num1, num2) {
        return parseInt(num1) + parseInt(num2);
      },
      deficitData(num) {
        let str = '<div>' + num.toLocaleString() + '</div>';
        if (parseInt(num) < 0) {
          str = '<div style="color: red">' + num.toLocaleString() + '</div>';
        }
        return str;
      },
      /**
       *  METHOD: 請求額
       */
      getBillingAmount(payment, company) {
        let sum = 0;

        if (payment.payType != this.getPaymentType('BANK_TRANSFER').no) {
          //先月の決済情報を取得
          const lastMonthPaymentId = this.createPaymentId(payment.id, -1);

          //先月の請求情報が未納の場合、請求額を加算する
          let pay = company.payment.find(pay => pay.id == lastMonthPaymentId);
          if (pay?.depositRefundInfo != null) {
            if (pay.depositRefundInfo.status == 1) {
              sum += parseInt(pay.amount) + parseInt(pay.tax);
            }
          }

          sum += parseInt(payment.amount) + parseInt(payment.tax);
        }
        //あおぞらの場合
        else {
          // あおぞらの前月までの振込総額
          let aozoraTotal = this.aozoraAllTotal(company, payment);

          // 当月までの請求総額
          let claimTotal = 0;
          let companyPayment = company.payment.filter(pay => {
            return pay.id <= payment.id && pay.payType == this.getPaymentType('BANK_TRANSFER').no;
          });
          for (let payment of companyPayment) {
            claimTotal += parseInt(payment.amount) + parseInt(payment.tax);
          }

          //入金返金対応分を調整する
          let adjustmentTotal = 0;
          for (let pay of companyPayment) {
            if (pay.id != payment.id) {
              //入金対応
              if (pay?.depositRefundInfo?.status == '3') {
                adjustmentTotal -= pay.depositRefundInfo.amount;
              }
              //返金対応
              else if (pay?.depositRefundInfo?.status == '4') {
                adjustmentTotal += pay.depositRefundInfo.amount;
              }
            }
          }
          //それぞれの値を計算する
          sum = claimTotal - aozoraTotal + adjustmentTotal;
        }
        return sum;
      },
      /**
       *  METHOD: 調整差額
       */
      getAdjustmentDifference(payment, company) {
        //console.log('あおぞら', this.aozoraPayment);

        let num = 0;
        if (payment?.depositRefundInfo?.amount == null) return 0;

        switch (payment?.depositRefundInfo?.status) {
          //返金対応、清算済みの場合はマイナス表示する
          case '4':
          case '6':
            num = -parseInt(payment?.depositRefundInfo?.amount);
            break;

          //入金対応
          case '3':
            num = parseInt(payment?.depositRefundInfo?.amount) - (payment.amount + payment.tax);
            break;

          //その他の対応
          default:
            num = parseInt(payment?.depositRefundInfo?.amount);
            break;
        }

        return num;
      },
      /**
       *  METHOD: 入金日
       */
      getPaymentDay: function(payment, company) {
        // 決済が完了されている場合
        if (payment.paymentResult == true) {
          // あおぞら銀行の場合
          if (payment.payType == this.getPaymentType('BANK_TRANSFER').no) {
            let pay = this.aozoraPayment.find(val => val.id == company.id);
            if (pay != null) {
              let date = '2000-01-01';
              //最新の日付を取得
              for (let p of pay.pay) {
                let d = p[0].substr(0, 4) + '-' + p[0].substr(4, 2) + '-' + p[0].substr(6, 2);
                if (moment(date).isBefore(d)) {
                  if (moment(payment.billingDate).isAfter(d)) {
                    date = d;
                  } else {
                    //未納の時、翌月分を記入する
                    if (payment?.depositRefundInfo?.status == '1') {
                      date = d;
                    }
                  }
                }
              }
              if (payment.paymentResult == true) {
                if (payment?.depositRefundInfo?.status == '3') {
                  return moment(payment?.depositRefundInfo?.adjustmentDate).format('YYYY-MM-DD');
                }
              }
              if (date == '2000-01-01') return null;
              return date;
            }
            return moment(payment.billingDate).format('YYYY-MM-DD');
          }
          // それ以外の場合
          else {
            //未納ステータスの場合、
            if (payment?.depositRefundInfo?.status == '1') {
              //未納が残っているとき
              let paymentId = ('000' + payment.id).slice(-4);
              var year = paymentId.slice(0, 2);
              var month = paymentId.slice(-2);
              var oneMonthAgoPaymentId = moment('20' + year + '-' + month + '-01')
                .add(1, 'months')
                .format('YYMM');
              let oneMonthAgoPayment = company.payment.find(
                val => val.id == parseInt(oneMonthAgoPaymentId)
              );
              if (oneMonthAgoPayment?.paymentResult == true) {
                return moment(oneMonthAgoPayment.billingDate).format('YYYY-MM-DD');
              }
            } else if (payment?.depositRefundInfo?.status == '3') {
              //入金対応を行った場合 日付を対応日に変更
              return moment(payment?.depositRefundInfo?.adjustmentDate).format('YYYY-MM-DD');
            } else {
              return moment(payment.billingDate).format('YYYY-MM-DD');
            }
          }
        }
      },
      /**
       *  METHOD: 入金日を超えている場合は赤字で表示
       */
      getPaymentDayHtml(date, item) {
        if (item['settlementDate'] == null) return '<dev>-</dev>';
        if (date != '-' && date != null) {
          if (moment(item['settlementDate']).isBefore(date)) {
            return '<dev style="color:red">' + date + '</dev>';
          } else {
            return '<dev style="">' + date + '</dev>';
          }
        }
        return '<dev>-</dev>';
      },
      /**
       *  METHOD: 印刷
       */
      printingButton() {
        console.log('test');
        window.print();
      },
      /**
       * 前月までの、あおぞら料金を取得する
       */
      aozoraAllTotal(company, payment) {
        let aozoraTotal = 0;
        let aozoraPayment = this.aozoraPayment.find(pay => pay.id == company.id);
        if (aozoraPayment) {
          let aozoraBeforeLastMonthPayment = aozoraPayment.pay.filter(pay => {
            return moment(pay[0]).isBefore(moment(payment.billingDate), 'month');
          });
          aozoraBeforeLastMonthPayment.map(pay => (aozoraTotal += parseInt(pay[4])));
        }
        return aozoraTotal;
      },
    },
  };
</script>

<style scoped>
  .btn {
    color: white;
    background-color: var(--colorTheme);
  }
  .underBar {
    height: 30px;
    color: white;
    background-color: var(--colorTheme);
  }
  .table-overflow {
    overflow-x: scroll;
    overflow-y: hidden;
  }
</style>
<style>
  .table-header.serch-data-table-header {
    position: relative;
    top: 30px;
  }
  .blue {
    color: blue;
    text-decoration: underline;
  }
  .blue:hover {
    color: skyblue;
  }
  .red {
    color: red;
    text-decoration: underline;
  }
  .red:hover {
    color: lightsalmon;
  }
</style>
