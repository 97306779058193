import { API, graphqlOperation } from 'aws-amplify';
import { getCompanyMaster, getSystemMaster } from '@/graphql/queries';
import store from '@/store';

const keyList = [
  'legalServiceLife', // 法定耐用年数表
  'depreciationRate', // 減価償却資産の償却率表
  'inheritanceTax', // 相続税の速算表
  'stampDuty', // 印紙税
  'constructionCost', // 建物の標準的な建築価額表
  'taxRate', // 税率
  'climingRate', // 上昇率
  'unitPrice', // 単価表
  'incomeTax', // 所得税の速算表
  'companyMaster', //会社マスタ ※未利用データ
  'newBuildingTax', //新築建物課税標準価格認定基準表
  'hokkaidoLegalDepartment', //北海道市町村別法務部
  'agingDepreciationRate', //経年減価補正率
  'premiumFactor', //保険料長期係数
];

export default async function loadMaster() {
  var user = store.state.user;
  if (user === null) {
    return;
  }

  const defaultValue = await loadMasterData();

  const companyMaster = await loadCompanyMaster(user);
  if (companyMaster != null) {
    if (companyMaster.taxRate != null) {
      //譲渡所得税税率設定
      if (!defaultValue.defaultValue.sale.capitalGainsTaxRateShortTerm) {
        const shortTerm = Number(companyMaster.taxRate[2][1]);
        defaultValue.defaultValue.sale.capitalGainsTaxRateShortTerm = shortTerm;
      }
      if (!defaultValue.defaultValue.sale.capitalGainsTaxRateLongTerm) {
        const longTerm = Number(companyMaster.taxRate[3][1]);
        defaultValue.defaultValue.sale.capitalGainsTaxRateLongTerm = longTerm;
      }
      //companyMaster.defaultValue = defaultValue.defaultValue;
      //return companyMaster;      
    }
  }
  const systemMaster = await loadSystemMaster();
  if (systemMaster == null) {
    return 'error';
  }
  if (!defaultValue.defaultValue.sale.capitalGainsTaxRateShortTerm) {
    const shortTerm = Number(systemMaster.taxRate[2][1]);
    defaultValue.defaultValue.sale.capitalGainsTaxRateShortTerm = shortTerm;
  }
  if (!defaultValue.defaultValue.sale.capitalGainsTaxRateLongTerm) {
    const longTerm = Number(systemMaster.taxRate[3][1]);
    defaultValue.defaultValue.sale.capitalGainsTaxRateLongTerm = longTerm;
  }
  //systemMaster.defaultValue = defaultValue.defaultValue;

  
  let master = {};
  for(let i = 0; i < keyList.length; i++){
    if(companyMaster[keyList[i]] == null){
      master[keyList[i]] = systemMaster[keyList[i]];
    } else {
      master[keyList[i]] = companyMaster[keyList[i]];
    }
  }

  master.defaultValue = defaultValue.defaultValue;
  return master;
}

async function loadCompanyMaster(user) {
  const results = [];

  keyList.forEach((_, index) => {
    results[index] = API.graphql(
      graphqlOperation(getCompanyMaster, {
        companyId: getGroup(user),
        classification: 'realEstateSuitability',
        classificationDetail: (index + 1).toString(),
      })
    );
  });

  const master = {};

  try {
    const valueList = await Promise.all(results);
    valueList.forEach((value, index) => {
      if (value.data.getCompanyMaster == null) {
        master[keyList[index]] = null;
      } else {
        master[keyList[index]] = value.data.getCompanyMaster.value.slice(1);
      }
    });
  } catch (error) {
    return null;
  }

  return master;
}

async function loadSystemMaster() {
  const results = [];

  keyList.forEach((_, index) => {
    results[index] = API.graphql(
      graphqlOperation(getSystemMaster, {
        classification: 'realEstateSuitability',
        classificationDetail: (index + 1).toString(),
      })
    );
  });

  const master = {};

  try {
    const valueList = await Promise.all(results);
    valueList.forEach((value, index) => {
      if (value.data.getSystemMaster == null) {
        master[keyList[index]] = null;
      }else{
        master[keyList[index]] = value.data.getSystemMaster.value.slice(1);
      }
    });
  } catch (error) {
    return null;
  }

  return master;
}

function getGroup(user) {
  const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
  const groupIndex = groups.findIndex(group => {
    return group.startsWith('Company-');
  }, 'Company-');
  if (groups[groupIndex] == null) {
    return null;
  }
  return groups[groupIndex];
}

function setDefaultValueMold() {
  let inputMaster = {};
  inputMaster.defaultValue = {
    rentalProperty: new Array(7).fill(null).map(() => ({
      floorPlanAndName: null,
      requireArea: null,
      constructionCostPerUnit: null,
      structure: null,
      kind: null,
      managementCostRate: null,
      vacancyRate: null,
      firstYearExpenseRatio: null,
      rateOfRepairCosts: null,
      repairCostsPerUnit: null,
      parkingLot: null,
      parkingSquare: null,
      propertyTaxAssessmentRate: null,
      percentageOfTenancyRights: null,
      legalServiceLife: null,
      equityRatio: null,
      borrowingRate: null,
      borrowingPeriod: null,
    })),
    monthlyParkingLot: {
      landArea: null,
      landAreaUnit: null,
      parkingAreaPerCar: null,
      parkingAreaPerCarUnit: null,
      constructionCostPerUnit: null,
      managementCostRate: null,
      vacancyRate: null,
      equityRatio: null,
      borrowingRate: null,
      borrowingPeriod: null,
    },
    storeOffice: {
      constructionCostPerUnit: null,
      managementCostRate: null,
      vacancyRate: null,
      equityRatio: null,
      borrowingRate: null,
      borrowingPeriod: null,
    },
    leasedLand: {
      modeRent: null,
      monthlyLandRentPerSquareMeter: null,
      annualLandRentYieldToMarketValue: null,
      managementCostRate: null,
    },
    vacantLotOrLand: {
      annualMaintenanceFee: null,
    },
    sale: {
      priceAtTheTimeOfPurchase: null,
      otherTransferCosts: null,
      periodOfOwnership: null,
      annualInvestmentYield: null,
      transferCostRate: null,
      capitalGainsTaxRateShortTerm: null,
      capitalGainsTaxRateLongTerm: null,
    },
    taxLand: {
      propertyTax: null,
      cityPlanningTax: null,
    },
    isFilledMaster: null,
  };
  return inputMaster;
}

async function loadMasterData() {
  var user = store.state.user;
  try {
    var result = await API.graphql(
      graphqlOperation(getCompanyMaster, {
        companyId: getGroup(user),
        classification: 'realEstateSuitability',
        classificationDetail: '10',
      })
    );
  } catch (e) {
    console.log(e);
  }

  let inputMaster = setDefaultValueMold();

  if (result.data.getCompanyMaster != null) {
    let specified = result.data.getCompanyMaster.value;
    specified.forEach(x => {
      let arg = x[0].split('-');
      switch (arg[0]) {
        case 'isFilledMaster':
          inputMaster.defaultValue.isFilledMaster = x[1];
          break;
        case 'rentalProperty':
          inputMaster.defaultValue.rentalProperty.forEach((value, index) => {
            if (index === parseInt(arg[1]) && x[0].indexOf(arg[2]) != -1) {
              let data = x[1] == null || x[1] == '' ? null : Number(x[1]);
              if (isNaN(data)) {
                value[arg[2]] = x[1];
              } else {
                value[arg[2]] = data;
              }
              //※2021/04/09の資料により'用途'設定は不要になった→'住宅'固定
              value['kind'] = '住宅';
            }
          });
          break;
        default:
          Object.keys(inputMaster.defaultValue[arg[0]]).forEach(value => {
            if (arg[1] === value) {
              let data = x[1] == null || x[1] == '' ? x[1] : Number(x[1]);
              if (isNaN(data)) {
                inputMaster.defaultValue[arg[0]][value] = x[1];
              } else {
                inputMaster.defaultValue[arg[0]][value] = data;
              }
            }
          });
          break;
      }
    });
  }
  return inputMaster;
}
