// @ts-check
import { reactive } from '@vue/composition-api';
import { GlobalSettings } from '../util/globalSettings';

/**
 * グローバル設定
 */
export const useGlobalSettings = () => {
  const globalSetting = reactive(new GlobalSettings());

  return globalSetting;
};
