<template>
  <div>
    <img
      src="../../images/ally-rogo-white3.png"
      style="width : 100% ; heigh : 100%"
      v-if="visible == true && color == 'white'"
    />
    <img
      src="../../images/ally-rogo-black3.png"
      style="width : 100% ; heigh : 100%"
      v-if="visible == true && color == 'black'"
    />
  </div>
</template>

<script>
  export default {
    props: ['visible', 'color'],
  };
</script>
