<!--
作成者：Lee HyeonSeok
作成日：2020.5.22
画面名：顧客情報一覧
機能：顧客情報一覧
親子関係：なし
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <h4 class="font-weight-bold">顧客情報一覧</h4>
    <div class="row grid-margin" v-if="mode === 'View'">
      <div class="col-12">
        <router-link to="/DashBoard" tag="button" class="btn btn-ally float-right">
          メインページへもどる
        </router-link>
        <router-link to="/Customer/Add" tag="button" class="btn btn-ally mx-2 float-right"
          >追加</router-link
        >
      </div>
    </div>
    <paginationV2
      ref="pagination"
      :headers="headers"
      targetName="Customer"
      :hideDetail="mode === 'Search' || undefined"
      :hideDelete="mode === 'Search' || undefined"
      @detailClicked="e => detailCustomer(e)"
      @deleteClicked="e => deleteCustomer(e)"
      @selectClicked="e => selectCustomer(e)"
    ></paginationV2>
  </div>
</template>
<script>
  import awsconfig from '../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { deleteCustomer } from '../../graphql/mutations';

  API.configure(awsconfig);

  export default {
    props: {
      mode: {
        type: String,
        default: 'View',
      },
    },
    data: function() {
      return {
        headers: [
          { key: 'customerType', label: '顧客区分' },
          { key: 'customerName', label: '顧客名' },
          { key: 'tel', label: '電話番号' },
          { key: 'mail', label: 'E-mail' },
        ],
      };
    },
    created() {
      // 検索モードのとき
      if (this.mode === 'Search')
        this.headers.push({
          key: 'select',
          label: '選択',
          button: { label: '選択', event: 'selectClicked' },
        });
    },
    methods: {
      deleteCustomer: async function(row) {
        await API.graphql(graphqlOperation(deleteCustomer, { input: { id: row.id } }));
        this.$refs.pagination.listItems();
      },
      detailCustomer: function(row) {
        this.$router.push({
          path: '/Customer/View/' + row.id,
        });
      },
      selectCustomer: function(row) {
        this.$emit('selectCustomer', row);
      },
    },
  };
</script>
