<template>
  <div>
    <div>
      <div class="pv-config-label">サイズ</div>
      <input
        type="number"
        step="5"
        style="width: 45%; display: inline-block"
        :value="activeObject.width * activeObject.transform.scale.x"
        @input="e => changeProperty('iconWidth', parseInt(e.srcElement.value))"
        :disabled="!isEditor"
      />
      x
      <input
        type="number"
        step="5"
        style="width: 45%; display: inline-block"
        :value="activeObject.height * activeObject.transform.scale.y"
        @input="e => changeProperty('iconHeight', parseInt(e.srcElement.value))"
        :disabled="!isEditor"
      />
      <div style="padding: 4px; display: flex; justify-content: right;">
        <PvBtn width="100" style="font-size: 10px; width: 100px;" @click="resetSize" :isEditor="isEditor"
          >リセット</PvBtn
        >
      </div>
      <div class="pv-config-label">反転</div>
      <div style="display: flex">
        <div style="flex: 50%">
          <span>左右</span>
          <input
            type="checkbox"
            :value="activeObject.style.flipHorizontal"
            @change="e => flipX(e.target.checked)"
            :disabled="!isEditor"
          />
        </div>
        <div style="flex: 50%">
          <span>上下</span>
          <input
            type="checkbox"
            :value="activeObject.style.flipVertical"
            @change="e => flipY(e.target.checked)"
            :disabled="!isEditor"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // @ts-check
  import { defineComponent } from '@vue/composition-api';
  import PvBtn from '../../PvBtn.vue';

  export default defineComponent({
    components: {
      PvBtn,
    },
    props: {
      activeObject: Object,
      isEditor: {  //  PROP: 編集権限の有無
        type: Boolean,
        default: true,
      },
    },
    setup(props, context) {
      const flipX = (/** @type {boolean} */ val) => {
        props.activeObject.style.flipHorizontal = val;
      };

      const flipY = (/** @type {boolean} */ val) => {
        props.activeObject.style.flipVertical = val;
      };

      const changeProperty = (/** @type {string} */ key, /** @type {any} */ val) => {
        props.activeObject[key] = val;
      };

      const resetSize = () => {
        props.activeObject.resetSize();
      };

      return {
        flipX,
        flipY,
        changeProperty,
        resetSize,
      };
    },
  });
</script>
