<template>
  <a @click="loadFlagFunc()" @click.prevent="e => $emit('click', e)" class="sidebar-btn nav-item nav-item-help" tag="li">
    <a class="nav-link nav-link-help" :class="[item.disabled ? 'disabled' : '']">
      <div style="position: relative; width: auto">
        <i style="font-size: 1.3rem;" :class="['menu-icon', 'menu-icon-help', item.icon]"></i>
        <div v-if="this.loadFlag && this.item.text != 'お問い合わせ' && !this.item.disabled" style="position: absolute; left: 11px; top: 14px">
          <Load width="30px" height="30px" margin="0px" />
        </div>
      </div>
        <span
          class="menu-title"
          data-toggle="collapse"
          data-target="#report-dropdown"
          v-html="item.text"
        ></span>
    </a>
  </a>
</template>
<script>
  import Load from './Load';
  export default {
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    components: {
      Load,
    },
    data: function() {
      return {
      loadFlag: false,
      };
    },
    methods: {
      //  ロードフラグ反転
      loadFlagFunc: function() {
        if (this.loadFlag) {
          this.loadFlag = false;
        } else {
          this.loadFlag = true;
        }
      }
    }
  };
</script>
<style scoped>
  .sidebar-btn {
    cursor: pointer;
  }
  .mail-link {
    cursor: pointer;
  }
  a .disabled {
    color: lightgray !important;
  }
  .menu-title {
    font-size: small !important;
  }
  .sidebar-btn:hover .menu-title {
    text-decoration: underline;
  }
</style>
