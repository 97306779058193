// @ts-check

import { generateId } from '../../util/util';
import { StrictMap } from '../../util/strictMap';
import { StrictWeakMap } from '../../util/strictWeakMap';
import { cloneParams } from '../tools/clone';
import { reactive } from '@vue/composition-api';

/** @type {StrictMap<string, PvNode>} */
const master = new StrictMap();

/**
 * @template {PvNode} T
 * @param {string} id
 * @returns {T}
 */
export const getNode = id => {
  // @ts-ignore
  return master.get(id);
};

export const cleanNodes = () => {
  master.clear();
};

/** parentプロパティ外では使用不可  */
/** @type {StrictWeakMap<PvNode, PvNode>} */
const $$parentRefs = new StrictWeakMap();

// createNode以外でコンストラクタが呼ばれることを防ぐためのフラグ
let $$createFlg = false;

/**
 * ノードとしての基本的なロジックを持つ
 * 基本的に状態は持たない
 * @template {PvNode} T 関連ノードの型
 */
export class PvNode {
  /** @type {string} */
  id;
  /** @type {import('../../types/pvNode').PvNodeType} */
  type;
  /** @type {string|null} */
  firstChildId = null;
  /** @type {string|null} */
  rightBrotherId = null;
  /** @type {string|null} ハンドルのターゲット */
  targetId = null;

  #__count = -1;

  constructor() {
    if (!$$createFlg) {
      throw new Error('ノードの生成にはcreateNodeを使用してください');
    }
  }

  /**
   * @param {PvNode} nodeRef
   * @returns {boolean}
   */
  static has(nodeRef) {
    return master.has(nodeRef.id);
  }

  /** @type {number} */
  get count() {
    return this.#__count;
  }

  /** @param {number} val */
  set count(val) {
    this.#__count = val;
  }

  /** @type {T|null} */
  get firstChild() {
    const id = this.firstChildId;
    if (id == null) {
      return null;
    }
    // @ts-ignore
    return master.get(id);
  }

  /**
   * @param {T|null} node
   */
  set firstChild(node) {
    this.firstChildId = node?.id ?? null;
  }

  /** @type {T|null} */
  get rightBrother() {
    const id = this.rightBrotherId;
    if (id == null) {
      return null;
    }
    // @ts-ignore
    return master.get(id);
  }

  /**
   * @param {T|null} nodeRef
   */
  set rightBrother(nodeRef) {
    this.rightBrotherId = nodeRef?.id ?? null;
  }

  /** @type {T|null} */
  get target() {
    const id = this.targetId;
    if (id == null) {
      return null;
    }
    // @ts-ignore
    return master.get(id);
  }

  /** @type {T|null} */
  get parent() {
    if (!$$parentRefs.has(this)) {
      return null;
    }
    // @ts-ignore
    return $$parentRefs.get(this);
  }

  /**
   * @param {T|null} nodeRef
   */
  set parent(nodeRef) {
    if ($$parentRefs.has(this)) {
      $$parentRefs.delete(this);
    }

    if (nodeRef != null) {
      $$parentRefs.set(this, nodeRef);
    }
  }

  /** @type {T|null} */
  get root() {
    let root = null;
    this.upSearch(node => {
      if (node.parent == null) {
        root = node;
      }
    });
    return root;
  }

  /** @type {T|null} */
  get leftBrother() {
    if (this.parent == null) {
      return null;
    }

    return this.parent.findChild((/** @type {T} */ obj) => {
      return obj.rightBrother?.id === this.id;
    });
  }

  /** @type {T|null} */
  get lastChild() {
    if (!master.has(this.firstChild?.id ?? '')) {
      return null;
    }

    let current = this.firstChild;
    let count = 0;
    while (current) {
      if (count > 200) {
        // デバッグ用
        console.error('無限ループを中断しました。');
        return current;
      }

      const rightBrotherId = current.rightBrotherId;
      if (rightBrotherId == null) {
        break;
      }
      current = current.rightBrother;
      count++;
    }

    return current;
  }

  /** @type {PvNode|null} */
  get next() {
    return this.rightBrother ? this.rightBrother : this.firstChild;
  }

  /** @type {T[]} */
  get children() {
    const ids = [];
    if (this.firstChildId == null) return [];

    /** @type {T} */
    // @ts-ignore
    let current = this.firstChild;
    const appeared = new Map();
    while (current) {
      if (appeared.has(current)) {
        console.error('同一ツリー内でノードの重複があります。', current.id);
        return [];
      }
      appeared.set(current.id, current);
      ids.push(current);

      const rightBrotherId = current.rightBrotherId;
      if (rightBrotherId == null) {
        break;
      }

      // @ts-ignore
      current = master.get(rightBrotherId);
    }

    return ids;
  }

  /**
   * 親が存在しかつ最左端かどうか
   * @returns {boolean}
   */
  get isLeftmost() {
    return (this.parent && this.parent.firstChild === this) ?? false;
  }

  get depth() {
    const depth = this.parent?.depth;
    return depth == null ? 0 : depth + 1;
  }

  /**
   * 検索
   * @param {(obj: T) => boolean} callback
   * @returns {T|null}
   */
  findChild(callback) {
    let target = this.firstChild;
    while (target) {
      const result = callback(target);
      if (result) {
        return target;
      }
      target = target.rightBrother;
    }
    return null;
  }

  /**
   * @param {T} nodeRef
   */
  appendChild(nodeRef) {
    const lastChild = this.lastChild;

    nodeRef.parent = this;
    nodeRef.rightBrother = null;
    // 最初の要素なら
    if (lastChild === null) {
      this.firstChild = nodeRef;
    } else {
      lastChild.rightBrother = nodeRef;
    }
  }

  /**
   *
   * @param {T} nodeRef
   */
  appendChildFirst(nodeRef) {
    nodeRef.parent = this;
    nodeRef.rightBrother = this.firstChild;
    this.firstChild = nodeRef;
  }

  /**
   * 左側に挿入
   * @param {T} obj
   */
  insertLeft(obj) {
    if (obj == null) throw new Error('nullは挿入できません。');
    obj.parent = this.parent;

    if (this.isLeftmost) {
      // @ts-ignore
      this.parent.firstChild = obj;
    } else {
      // @ts-ignore
      this.leftBrother.rightBrother = obj;
    }
    obj.rightBrother = this;
  }

  /**
   * @param {T} obj
   */
  insertRight(obj) {
    if (obj == null) throw new Error('nullは挿入できません。');

    obj.parent = this.parent;

    obj.rightBrother = this.rightBrother;
    this.rightBrother = obj;
  }

  /**
   * @param {T[]} objs
   */
  appendChildren(objs) {
    if (objs.length === 0) {
      return;
    }

    if (this.firstChild == null) {
      this.firstChild = objs[0];
    } else {
      // @ts-ignore
      this.lastChild.rightBrother = objs[0];
    }

    for (let i = 0; i < objs.length; i++) {
      const o = objs[i];
      const next = objs[i + 1];
      o.rightBrother = next ?? null;
      o.parent = this;
    }
  }

  /**
   *
   * @param {string} id
   * @returns {T}
   */
  _removeChild(id) {
    if (this.firstChild == null) {
      throw new Error('childが存在しません。');
    }

    /** @type {T} */
    let current = this.firstChild;
    while (current) {
      if (current.id === id) {
        if (current.isLeftmost) {
          this.firstChild = current.rightBrother;
        } else {
          // @ts-ignore
          current.leftBrother.rightBrother = current.rightBrother;
        }
        current.parent = null;
        return current;
      }

      if (current.rightBrother == null) {
        break;
      }
      current = current.rightBrother;
    }
    console.error(id);
    throw new Error('削除対象のオブジェクトが見つかりません: ' + id);
  }

  // TODO
  /**
   * @param {string} id
   * @returns {T} 削除されたオブジェクト
   */
  removeChild(id) {
    return this._removeChild(id);
  }

  /**
   * 自身を親から削除
   */
  removeSelf() {
    this.parent?.removeChild(this.id);
  }

  /**
   * @returns {T[]} 削除されたオブジェクトのリスト
   */
  removeChildrenAll() {
    const result = this.children;
    result.forEach(c => {
      c.rightBrother = null;
      c.parent = null;
    });
    this.firstChild = null;

    return result;
  }

  /**
   * 子要素ループ
   * @param {import('../../types/treeType').SearchCallback<T>} callback
   */
  forEachChild(callback) {
    let current = this.firstChild;
    while (current) {
      if (callback(current) === false) {
        break;
      }
      current = current.rightBrother;
    }
  }

  /**
   * 深さ優先探索
   * @param {import('../../types/treeType').SearchCallback<T>} callback
   */
  dfSearch(callback) {
    const rec = (/** @type {T} */ n) => {
      for (const child of n.children) {
        if (callback(child) === false) {
          break;
        }
        rec(child);
      }
    };
    // @ts-ignore
    if (callback(this) === false) {
      return;
    }
    // @ts-ignore
    rec(this);
  }

  /**
   * 幅優先探索
   * @param {import('../../types/treeType').SearchCallback<T>} callback
   */
  bfSearch(callback) {
    let breakFlg = false;
    const breakCallback = () => {
      breakFlg = true;
    };

    /**
     * @param {T} nodeRef
     */
    const rec = nodeRef => {
      const children = [];
      let next = nodeRef.firstChild;
      while (next) {
        if (callback(next) === false) {
          break;
        }
        children.push(next);
        next = next.rightBrother;
      }

      for (const c of children) {
        if (c.firstChild != null) {
          rec(c.firstChild);
        }
      }
    };

    // @ts-ignore
    callback(this, breakCallback);
    if (breakFlg) return;
    // @ts-ignore
    rec(this);
  }

  /**
   * @param {import('../../types/treeType').SearchCallback<T>} callback
   */
  upSearch(callback) {
    const th = /** @type {unknown} */ (this);
    let target = /** @type {T} */ (th);
    while (target) {
      if (callback(target) === false) {
        break;
      }

      if (!target.parent == null) {
        return null;
      }

      target = /** @type {any} */ (target.parent);
    }
  }

  /**
   * @param {(node: PvNode) => boolean} filterFunction
   * @returns {T[]}
   */
  filter(filterFunction) {
    const filtered = [];
    this.dfSearch(o => {
      if (filterFunction(o)) {
        filtered.push(o);
      }
    });

    return filtered;
  }

  /**
   * @returns {T[]}
   */
  flat() {
    const result = [];
    this.dfSearch(o => {
      result.push(o);
    });
    return result;
  }

  /**
   * @returns {T[]}
   */
  flatWithoutRoot() {
    const result = [];
    for (const child of this.children) {
      child.dfSearch(c => {
        result.push(c);
      });
    }

    return result;
  }

  /**
   * TODO 不具合あり！！！！
   * ノードを入れ替える
   * 順番が予め分かっている必要がある
   * @param {T} right
   */
  swap2Children(right) {
    const left = this;
    if (left == null || right == null) throw new Error('要素がnullです');
    if (left.id === right.id) throw new Error('同一のオブジェクトです');
    if (left.parent !== right.parent) throw new Error('異なるツリー間での入れ替えはできません。');

    const parent = left.parent;
    if (parent == null) {
      throw new Error('');
    }

    /** @type {T|null} */
    const rightRightBrother = right.rightBrother;
    /** @type {T|null} */
    const leftRightBrother = left.rightBrother;

    left.rightBrotherId = rightRightBrother?.id ?? null;
    right.rightBrotherId =
      leftRightBrother?.id === right.id ? left.id : leftRightBrother?.id ?? null;

    if (parent.firstChild.id === left.id) {
      parent.firstChildId = right.id;
    } else if (left.leftBrother != null) {
      left.leftBrother.rightBrother = right;
    }
  }

  /**
   * 左（背面）に一つ移動
   */
  shiftLeft() {
    const leftBrother = this.leftBrother;
    if (leftBrother == null) {
      return;
    }

    leftBrother.swap2Children(this);
  }

  /**
   * 右（前）に一つ移動
   */
  shiftRight() {
    const rightBrother = this.rightBrother;
    if (rightBrother == null) {
      return;
    }

    this.swap2Children(rightBrother);
  }

  /**
   * 右端（最前面）に移動
   */
  shiftRightmost() {
    const parent = this.parent;
    parent?.removeChild(this.id);
    parent?.appendChild(this);
  }

  /**
   * 左端（最背面）に移動
   */
  shiftLeftmost() {
    const parent = this.parent;
    parent?.removeChild(this.id);
    parent?.appendChildFirst(this);
  }
}

/** @type {Map<string, number>} デバック用識別番号 */
const counter = new Map();

/**
 * @param {string} type
 * @return {number}
 */
function getCounter(type) {
  if (!counter.has(type)) {
    counter.set(type, 0);
  }

  const count = counter.get(type);
  if (count == null) {
    throw new Error('');
  }
  counter.set(type, count + 1);
  return count;
}

/**
 * 新規作成時に使用
 * @template {PvNode} T
 * @param {import('../../types/utilType').Constructor<T>} constructor
 * @param {Partial<T>} params
 * @param {string?} id
 * @returns {T}
 */
// @ts-ignore
export function createNode(constructor, params = {}, id = null) {
  $$createFlg = true;
  const node = /** @type {T} */ (reactive(new constructor()));
  $$createFlg = false;

  cloneParams(node, params);
  const newId = id ?? generateId();

  master.set(newId, node);

  node.id = newId;
  node.count = getCounter(node.type);
  return node;
}
