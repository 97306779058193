<!--
作成者：Lee HyeonSeok
作成日：2020.08.04
画面名：ログイン処理
機能：ログイン処理
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <button
      @click="debug"
      style="position: fixed; top: 0; left: 46%; width: 200px; opacity: 0"
    ></button>
    <load v-if="loading"></load>
    <ValidationObserver tag="div" slim ref="siginInValidator" v-if="!loading">
      <div class="row">
        <allyText
          name="id"
          title="ID（E-mail）"
          v-model="signInUsername"
          placeholder="mail@example.com"
          class="col-12"
          rules="required|email"
          hideBadge
        />
        <b-link class="forgot-id" @click="$bvModal.show('checkIdModal')">
          IDを忘れた方はこちら
        </b-link>
        <b-link v-if="isArApp" class="reset-id" @click="resetId">
          IDリセット
        </b-link>
      </div>
      <div class="row">
        <allyText
          name="password"
          title="パスワード"
          v-model="password"
          type="password"
          placeholder="********"
          @keyup.enter="signIn"
          class="col-12"
          rules="required"
          hideBadge
        />
        <b-link class="forgot-id" @click="forgot">
          パスワードを忘れた方はこちら
        </b-link>
      </div>
      <b-row class="pt-1">
        <b-col>
          <b-button class="btn-login w-100" id="login" @click="signIn">
            ログイン
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="isArApp" class="pt-3">
        <b-col>
          <b-button class="btn-auto-login" @click="clickAutoLoginBtn">
            自動ログイン
          </b-button>
        </b-col>
      </b-row>
      <b-row class="pt-4"> </b-row>
      <hr />
      <b-row class="pt-1">
        <b-col style="display:grid;align-items:center;text-align:right;">
          初めての方はこちら
        </b-col>
        <b-col style="text-align:left;">
          <b-button class="btn-login" @click="signUpModalOpen">
            新規登録
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" v-if="error">
          {{ error }}
        </b-col>
      </b-row>
    </ValidationObserver>
    <b-modal
      id="signUpModal"
      size="lg"
      dialog-class="signup-dialog"
      content-class="signup-content"
      header-class="signup-modal"
      body-class="ally-input-background signup-body"
      title="新規登録"
      centered
      hide-footer
      @close="onClose"
      no-close-on-backdrop
      no-close-on-esc
    >
      <SignUp ref="signup" @closeSignUpModal="closeSignUpModal()" />
    </b-modal>
    <input
      id="auto-login"
      style="display:none;"
      type="button"
      v-on:click="autoLogin('honma@com-bo.co.jp', 'Combo001!!!!')"
      value="test"
    />
    <!-- 「登録情報確認」モーダル -->
    <b-modal
      id="checkIdModal"
      dialog-class="signup-dialog"
      content-class="signup-content"
      header-class="signup-modal"
      body-class="ally-input-background signup-body"
      footer-class="signup-footer"
      title="登録情報確認"
      centered
      @ok="startCheckId"
      no-close-on-backdrop
      no-close-on-esc
      ok-title="確認"
      ok-variant="ally"
      :ok-disabled="checkId.loading"
      cancel-title="キャンセル"
      cancel-variant="ally"
      :cancel-disabled="checkId.loading"
    >
      <b-alert :show="checkId.found == false" variant="danger" dismissible>
        <b>入力された内容では登録されていません。</b>
      </b-alert>
      入力された情報を元に、登録状況を確認します。<br />
      以下の項目をすべて入力し、確認ボタンを押してください。<br />
      <br />
      <b-overlay :show="checkId.loading" opacity="0.25">
        <ValidationObserver tag="div" slim ref="checkIdValidation">
          <b-container>
            <b-row>
              <b-col>
                <allyText
                  title="会社名"
                  v-model="checkId.companyName"
                  placeholder="〇〇株式会社"
                  rules="zen|required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyText
                  title="氏名"
                  v-model="checkId.userLastName"
                  placeholder="新井"
                  rules="required"
                />
              </b-col>
              <b-col>
                <allyText
                  title=" 氏名"
                  style="color:transparent;"
                  v-model="checkId.userFirstName"
                  placeholder="太郎"
                  rules="required"
                  hideBadge
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <allyText
                  title="携帯電話番号"
                  v-model="checkId.phoneNumber"
                  :placeholder="'0196000000'"
                  rules="tel|required"
                />
              </b-col>
            </b-row>
          </b-container>
        </ValidationObserver>
        <!-- ロード中 -->
        <template #overlay>
          <load margin="0px" />
        </template>
      </b-overlay>
    </b-modal>
    <!-- 「登録情報送信」モーダル -->
    <b-modal
      id="sendSmsModal"
      dialog-class="signup-dialog"
      content-class="signup-content"
      header-class="signup-modal"
      body-class="ally-input-background signup-body"
      footer-class="signup-footer"
      title="登録情報送信"
      centered
      @ok="sendSms"
      no-close-on-backdrop
      no-close-on-esc
      :ok-title="checkId.sended ? '閉じる' : '送信'"
      ok-variant="ally"
      ok-only
      :ok-disabled="checkId.loading"
    >
      <b-overlay :show="checkId.loading" opacity="0.25">
        <b-alert :show="checkId.sended == true" variant="success">
          <b>送信に成功しました。</b>
        </b-alert>
        <b-alert :show="checkId.sended == false" variant="danger">
          <b>送信に失敗しました。</b>
        </b-alert>
        登録が確認できました。<br />
        携帯電話番号 {{ checkId.phoneNumber }} に登録されているIDを送信します。<br />
        <!-- ロード中 -->
        <template #overlay>
          <load margin="0px" />
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
  import { AmplifyEventBus } from 'aws-amplify-vue';
  import load from '../../../components/Common/Load';
  import SignUp from './SignUp/Input.vue';
  import { API, graphqlOperation } from 'aws-amplify';
  import { checkAccount, sendShortMessage } from '@/graphql/queries';
  import { callUnity, checkUnity, CALL_COMMAND } from '@/js/ar/unity';

  import { Auth } from '@aws-amplify/auth';
  import aws_exports from '@/aws-exports';
  Auth.configure(aws_exports);

  export default {
    name: 'SignIn',
    data: function() {
      return {
        password: '',
        signInUsername: '',
        loading: false,
        error: '',
        debugCount: 0,
        receiveIdPassword: null,
        isArApp: false,
        checkId: {
          companyName: null, // 会社名
          userLastName: null, // 利用者氏名[姓]
          userFirstName: null, // 利用者氏名[名]
          phoneNumber: null, // 携帯電話番号
          found: null, // 検索結果
          sended: null, // SMS送信状態
          loading: false, // 処理状態
        },
      };
    },
    async created() {
      window.autoLogin = (id, pass) => {
        this.autoLogin(id, pass);
      };
      this.isArApp = await this.checkArApp();
    },
    methods: {
      checkArApp() {
        let tryCount = 0;
        const check = () => {
          return checkUnity();
        };
        const checkTimer = resolve =>
          setTimeout(() => {
            if (tryCount === 50) {
              resolve(false);
              return;
            }
            tryCount++;
            if (check()) {
              resolve(true);
            } else {
              checkTimer(resolve);
            }
          }, 100);
        return new Promise(resolve => checkTimer(resolve));
      },
      clickAutoLoginBtn() {
        callUnity(CALL_COMMAND.START_AUTO_LOGIN);
      },
      resetId: function() {
        callUnity(CALL_COMMAND.RESET_USER_ID);
      },
      autoLogin: async function(id, pass) {
        this.signInUsername = id;
        this.password = pass;
        await this.$nextTick();
        await this.signIn();
      },
      signIn: async function() {
        this.$refs.siginInValidator.validate().then(isValid => {
          if (!isValid) return;
          this.loading = true;
          this.$Amplify.Auth.signIn(this.signInUsername, this.password)
            .then(async data => {
              if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.$emit('forgotPassword', data);
                return AmplifyEventBus.$emit('authState', 'requireNewPassword');
              } else {
                callUnity(CALL_COMMAND.SEND_USER_ID_AND_PASSWORD, {
                  id: this.signInUsername,
                  pass: this.password,
                });
                if (data.signInUserSession.accessToken.payload['cognito:groups'] === undefined) {
                  AmplifyEventBus.$emit('localUser', data);
                  this.$emit('confirmsignupcustomflow', this.signInUsername, data);
                  AmplifyEventBus.$emit('authState', 'confirmsignupcustomflow');
                } else {
                  AmplifyEventBus.$emit('localUser', data);
                  return AmplifyEventBus.$emit('authState', 'signedIn');
                }
              }
            })
            .catch(e => {
              if (e.code == 'UserNotConfirmedException') {
                var userInfo = { user: this.signInUsername, password: this.password };
                this.$emit('confirmSignUp', userInfo);
                AmplifyEventBus.$emit('authState', 'confirmSignUp');
              }
              this.setError(e);
            })
            .finally(() => {
              this.loading = false;
            });
        });
      },
      forgot: function() {
        this.$parent.changeDiplayMap('forgotPassword');
      },
      setError: function(e) {
        this.error = this.$parent.getErrorString(e.code, true);
      },
      // 登録情報確認開始
      startCheckId: async function(bvModalEvent) {
        bvModalEvent.preventDefault();
        // バリデーションチェック
        const isValid = await this.$refs.checkIdValidation.validate();
        if (!isValid) {
          return;
        }
        try {
          this.checkId.found = null;
          // 入力禁止
          this.checkId.loading = true;

          // アカウント登録確認
          let result;
          try {
            // ダミーユーザーでサインイン
            await Auth.signIn(
              process.env.VUE_APP_UNAUTHENTICATED_ID,
              process.env.VUE_APP_UNAUTHENTICATED_PWD
            );
            // Lambda実行
            result = await API.graphql(graphqlOperation(checkAccount, this.checkId));
          } finally {
            // サインアウト
            await Auth.signOut();
          }

          // 登録されていない場合
          if (!result.data.checkAccount) {
            this.checkId.found = false;
            return;
          }
          // 登録されている場合、属性からメールアドレスを取得する。
          const data = JSON.parse(result.data.checkAccount);
          this.checkId.email = data.Attributes.find(v => {
            return v.Name == 'email';
          }).Value;
          // 「登録情報確認」モーダルを閉じる。
          this.$bvModal.hide('checkIdModal');
          // 「登録情報送信」モーダルを開く。
          this.$bvModal.show('sendSmsModal');
        } finally {
          // 入力許可
          this.checkId.loading = false;
        }
      },
      // 登録情報送信
      sendSms: async function(bvModalEvent) {
        // 送信済みであれば閉じる。
        if (this.checkId.sended) {
          return;
        }
        try {
          bvModalEvent.preventDefault();
          // 入力禁止
          this.checkId.loading = true;

          let result;
          try {
            // ダミーユーザーでサインイン
            await Auth.signIn(
              process.env.VUE_APP_UNAUTHENTICATED_ID,
              process.env.VUE_APP_UNAUTHENTICATED_PWD
            );
            // Lambda実行
            result = await API.graphql(
              graphqlOperation(sendShortMessage, {
                message: `IDは ${this.checkId.email} です。`,
                phoneNumber: this.checkId.phoneNumber,
              })
            );
          } finally {
            // サインアウト
            await Auth.signOut();
          }

          // 結果取得
          const data = JSON.parse(result.data.sendShortMessage);
          if (data.MessageId) {
            // 送信成功
            this.checkId.sended = true;
          } else {
            // 送信失敗
            this.checkId.sended = false;
          }
        } catch (e) {
          // 例外発生
          this.checkId.sended = false;
        } finally {
          // 入力許可
          this.checkId.loading = false;
        }
      },
      signUpModalOpen: function() {
        this.$bvModal.show('signUpModal');
      },
      closeSignUpModal: function() {
        this.$bvModal.hide('signUpModal');
      },
      onClose: function(ev) {
        // 認証コード入力のみ、閉じないようにする。
        if (this.$refs.signup.page == this.$refs.signup.CODE.CERT) {
          ev.preventDefault();
          alert('認証コードを入力してください。');
        }
      },
      debug() {
        if (this.debugCount < 6) {
          this.debugCount++;
          return;
        }
        const val = localStorage.getItem('debugIpLastNum');
        const result = prompt('http://192.168.******:8080', val);
        const r = new RegExp(/^[0-9.]+$/);
        if (r.test(result)) {
          localStorage.setItem('debugIpLastNum', result);
          window.location.href = `http://192.168.${result}:8080`;
        } else {
          alert('');
        }
      },
    },
    components: {
      load,
      SignUp,
    },
  };
</script>
<style scoped>
  .margin-top {
    margin-top: 15px;
  }
  .forgot-id {
    font-size: x-small;
    position: absolute;
    padding-top: 4px;
    left: 150px;
  }
  .reset-id {
    font-size: x-small;
    position: absolute;
    padding-top: 4px;
    left: 270px;
  }
  .alert {
    font-size: medium;
  }
  .allyLoad {
    margin: 0;
  }
  .btn-login {
    color: #fff;
    background-color: var(--colorTheme);
    border: 6px double var(--colorTheme);
    font-size: large;
  }
  .btn-auto-login {
    color: #fff;
    background-color: var(--colorTheme);
    border: none;
    outline: 6px double var(--colorTheme);
    margin: 6px;
    width: calc(100% - 12px);
    font-size: large;
  }
  hr {
    border-top: 1px solid black;
    height: 1em;
    text-align: center;
    overflow: visible;
  }
  hr::after {
    content: 'または';
    background: white;
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    top: -1em;
    padding: 0 1em;
  }
</style>
