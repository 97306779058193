<template>
  <g>
    <path
      data-part="wallBody"
      data-area="object"
      :data-id="obj.id"
      :d="d"
      :stroke="obj.style.stroke"
      :stroke-width="obj.style.strokeWidth"
      :stroke-dasharray="obj.style.strokeDasharray"
      fill="none"
    />
  </g>
</template>
<script>
  // @ts-check
  import { computed, defineComponent } from '@vue/composition-api';
  import { PathData } from '../../lib/util/path';

  export default defineComponent({
    props: {
      obj: Object, // PvObject
    },
    setup(props) {
      const d = computed(() => {
        if (props.obj.vertexes.length < 2) {
          return '';
        }

        if (props.obj.vertexes[1].sub(props.obj.vertexes[0]).absSquare() === 0) {
          return '';
        }
        return PathData.linePath(props.obj.vertexes, props.obj.arcPoints[0]);
      });
      return {
        d,
      };
    },
  });
</script>
