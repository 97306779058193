<template>
  <div v-if="activeObject" class="col-12 mx-0 px-0 py-2">
    <div class="pv-config-label">部屋名</div>
    <div>
      <input
        :class="formCommonClassString"
        :value="activeObject.style.spaceName"
        :disabled = "!isEditor"
        list="nameList"
        @change="e => changeSpaceName(e.srcElement.value)"
      />
      <datalist id="nameList">
        <option v-for="name in spaceNameList" :key="name.value" :label="name.value">
          {{ name.value }}
        </option>
      </datalist>
    </div>
    <div class="row mx-0 px-0">
      <div class="col-6 pl-0 pr-2">
        <div class="pv-config-label">壁の厚み(px)</div>
        <div>
          <input
            :class="formCommonClassString"
            type="number"
            min="0"
            step="5"
            :value="activeObject.style.strokeWidth"
            @input="
              e =>
                setStyleProperty(
                  'strokeWidth',
                  parseInt(isNaN(e.srcElement.value) ? 0 : e.srcElement.value)
                )
            "
            :disabled="!isEditor"
          />
        </div>
      </div>
      <div class="col-6 pl-2 pr-0">
        <div class="pv-config-label">壁の高さ(m)</div>
        <div>
          <input
            :class="formCommonClassString"
            type="number"
            min="0"
            max="10"
            step="0.1"
            :value="activeObject.height3d.toFixed(2)"
            @input="
              e =>
                setProperty(
                  'height3d',
                  parseFloat(isNaN(e.srcElement.value) ? 0 : e.srcElement.value)
                )
            "
            :disabled="!isEditor"
          />
        </div>
      </div>
    </div>
    <div class="pv-config-label">壁の色</div>
    <div>
      <select
        :class="formCommonClassString"
        type="number"
        :value="activeObject.style.stroke"
        @change="e => setStyleProperty('stroke', e.srcElement.value)"
        :disabled="!isEditor"
      >
        <option v-for="color in wallColorList" :key="color.value" :value="color.value">
          {{ color.label }}
        </option>
      </select>
    </div>

    <div class="pv-config-label mt-3">素材</div>
    <allyRadio
      class="col-12 p-0"
      name="material-type"
      :items="[
        { text: '単色', value: MATERIAL_TYPE.SINGLE_COLOR },
        { text: 'パターン', value: MATERIAL_TYPE.PATTERN },
      ]"
      v-model="isActiveFloorMaterialType"
    />
    <!-- 素材 パターン 分類 -->

    <!-- 素材 共通設定 -->
    <div class="material-setting ">
      <div class="pv-config-label">不透明度</div>
      <div>
        <input
          :class="formCommonClassString"
          type="number"
          :value="activeObject.style.patternOpacity"
          min="0"
          max="100"
          @input="
            e =>
              changePatternOpacity(
                parseInt(isNaN(e.srcElement.value) ? 0 : e.srcElement.value)
              )
          "
          :disabled="!isEditor"
        />
      </div>
    </div>

    <!-- 素材 パターン設定 -->
    <div
      class="material-setting mx-0 px-0 mt-2"
      v-show="isActiveFloorMaterialType === MATERIAL_TYPE.PATTERN"
    >
      <div class="pv-config-label">位置</div>
      <div class="d-flex">
        <input
          class="flex-fill"
          type="number"
          :value="activeObject.style.patternOffsetX"
          @input="
            e =>
              setStyleProperty(
                'patternOffsetX',
                parseInt(isNaN(e.srcElement.value) ? 0 : e.srcElement.value)
              )
          "
          :disabled="!isEditor"
          style="min-width: 0;"
        />
        <div class="flex-shrink-1 mx-1">x</div>
        <input
          class="flex-fill"
          type="number"
          :value="activeObject.style.patternOffsetY"
          @input="
            e =>
              setStyleProperty(
                'patternOffsetY',
                parseInt(isNaN(e.srcElement.value) ? 0 : e.srcElement.value)
              )
          "
          :disabled="!isEditor"
          style="min-width: 0;"
        />
      </div>
      <div class="row mx-0 px-0">
        <div class="col-6 pl-0 pr-2">
          <div class="pv-config-label">回転</div>
          <div>
            <input
              :class="formCommonClassString"
              type="number"
              :value="activeObject.style.patternRotate"
              max="360"
              step="5"
              @input="
                e =>
                  changePatternRotate(
                    parseInt(isNaN(e.srcElement.value) ? 0 : e.srcElement.value)
                  )
              "
              :disabled="!isEditor"
            />
          </div>
        </div>
        <div class="col-6 pl-2 pr-0">
          <div class="pv-config-label">幅</div>
          <div>
            <input
              :class="formCommonClassString"
              type="number"
              min="5"
              max="1000"
              step="5"
              :value="activeObject.style.patternWidth"
              @input="
                e =>
                  setStyleProperty(
                    'patternWidth',
                    parseInt(isNaN(e.srcElement.value) ? 0 : e.srcElement.value)
                  )
              "
              :disabled="!isEditor"
            />
          </div>
        </div>

        <div
          class="col-12 mt-2 mb-0 p-0"
          v-show="isActiveFloorMaterialType === MATERIAL_TYPE.PATTERN"
        >
          <!-- 大分類ボタン -->
          <button
            v-for="category in categories"
            :key="category.displayName"
            :class="{
              'btn btn-outline-ally category-btn mr-1 mb-2 p-2': true,
              active: categoryIndex === category.sortNo && !searchMessage,
            }"
            @click="selectCategory(category)"
          >
            {{ category.displayName }}
          </button>
          <div class="row m-0 p-0" v-if="categories.length" style="display: flex; width: 100%;">
            <div
              :class="'col-' + 12 / categories[categoryIndex].child.length + ' m-0 p-0'"
              v-for="middleCategory in categories[categoryIndex].child"
              :key="middleCategory.id"
            >
              <!-- 中分類ボタン -->
              <button
                @click="selectMiddleCategory(middleCategory)"
                :class="{
                  'btn btn-outline-ally category-btn mr-1 p-2': true,
                  active: middleCategorySystemName === middleCategory.systemName && !searchMessage,
                }"
                :style="'width: ' + (100 - categories[categoryIndex].child.length * 2) + '%;'"
              >
                {{ middleCategory.displayName }}
              </button>
            </div>
          </div>
        </div>
        <!-- 素材 パターン検索 -->
        <div
          class="material-setting mx-0 px-0 mt-2 mb-0"
          style="width: 100%;"
          v-show="isActiveFloorMaterialType === MATERIAL_TYPE.PATTERN"
        >
          <div id="popover">
            <input
              :class="formCommonClassString"
              type="text"
              v-model="searchMessage"
              placeholder="品番検索"
            />
            <b-popover
              content="品番で検索できます。"
              target="popover"
              triggers="hover focus"
              placement="right"
              boundary="viewport"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 mb-4">
      <!-- 素材 単色 -->
      <div v-show="isActiveFloorMaterialType === MATERIAL_TYPE.SINGLE_COLOR">
        <v-swatches
          class=""
          v-model="vModelSingleColor"
          swatches="text-advanced"
          :show-fallback="isEditor"
          popover-x="left"
          row-length="4"
          swatch-size="16"
          fallback-input-type="color"
          fallback-ok-text="閉じる"
          :inline="true"
          :disabled="!isEditor"
        >
        </v-swatches>
      </div>

      <!-- 素材 パターン リスト部分 -->
      <GridView
        v-show="isActiveFloorMaterialType === MATERIAL_TYPE.PATTERN"
        :items="selectCategoryMaterial"
        :height="gridHeight"
        @clickItem="val => changeMaterial(val.name)"
        :currentItem="currentMaterial"
        :isEditor="isEditor"
      >
        <template #item="{ item }">
          <img
            v-if="item.url"
            :src="item.url"
            loading="lazy"
            ref="floorImg"
            class="floor-disp-img"
            alt=""
            width="100%"
            height="50"
          />
          <div
            v-else
            class="floor-disp-img"
            style="height: 50px"
            :style="{ backgroundColor: currentMaterial.color }"
          ></div>
          <div>
            {{ item.name }}
          </div>
        </template>
      </GridView>
    </div>
  </div>
</template>
<script>
  import VSwatches from 'vue-swatches';
  // @ts-check
  import { computed, defineComponent, ref, toRefs, watch, onMounted } from '@vue/composition-api';
  import Select from '../../../../../../components/Common/Form/Select.vue';
  import { WALL_COLOR_LIST, MATERIAL_TYPE, SPACE_NAME_LIST } from '../../../../lib/util/const';
  import GridView from '../GridView.vue';
  import { PvFloor } from '../../../../lib/node/node/floorNode';
  import materialMaster from '../../../../../Property/materialMaster';

  export default defineComponent({
    components: {
      GridView,
      Select,
      VSwatches,
    },
    props: {
      activeObject: Object, // PROP: 選択中のオブジェクト
      materials: {
        // PROP: マテリアル
        type: Array,
        default: () => [],
      },
      height: Number, // PROP: 高さ
      isEditor: {
        //  PROP: 編集権限の有無
        type: Boolean,
        default: true,
      },
    },
    setup(props, context) {
      const isActiveFloorMaterialType = ref(MATERIAL_TYPE.SINGLE_COLOR); // DATA: 選択中の床マテリアルタイプ
      const vModelSingleColor = ref('#ffffff'); // DATA: Vモデル：単色
      const wallColorList = ref(WALL_COLOR_LIST); // DATA: 壁色リスト
      const spaceNameList = ref(SPACE_NAME_LIST); // DATA: 部屋名リスト
      const searchMessage = ref(''); // DATA: 検索文字
      let isSearch = false; // DATA: 文字検索のフラグ

      const formCommonClassString = ref('col-12 p-1'); // DATA: サイドメニュー入力欄用共通class文字列

      const setStyleProperty = (/** @type {string} */ key, /** @type {any} */ val) => {
        props.activeObject.style[key] = val;
      };
      const setProperty = (/** @type {string} */ key, /** @type {any} */ val) => {
        props.activeObject[key] = val;
      };

      const categories = ref([]); // DATA: カテゴリー
      const categoryIndex = ref('0'); //  DATA: 選択している大項目カテゴリー添字
      const middleCategorySystemName = ref(''); //  DATA: 選択している中項目カテゴリー

      onMounted(async () => {
        // MOUNTED: マスターデータからデータを取得
        const masterData = await materialMaster.methods.getMaterialClassList(5);
        categories.value = await masterData[0].child;
        middleCategorySystemName.value = categories.value[0].child[0].systemName;
      });

      // COMPUTED: GridView用heightの算出(素材リストの表示範囲)
      const gridHeight = computed(() => {
        // 検索またはカテゴリによるフィルター後の素材数 / 表示列数 * １素材のpx数
        let height = Math.round(selectCategoryMaterial.value.length / 2) * 100;
        if (height > 450) {
          // 表示領域が超えた場合にスクロールバーが消えないよう固定する
          height = 450;
        }
        return height;
      });

      const { materials } = toRefs(props); // DATA: PROPSのマテリアル
      const selectCategoryMaterial = ref(materials.value); //  DATA: 選択したカテゴリーの素材

      /**
       *  METHOD: 素材を選別する
       */
      const materialFilter = async () => {
        selectCategoryMaterial.value = await materials.value.filter(m => {
          return m.class === middleCategorySystemName.value;
        });
      };

      /**
       * METHOD: タイプ変更
       */
      const changeType = (/** @type {string} */ mode) => {
        context.emit('changeFloorType', mode);
      };

      /**
       * COMPUTED: 現在のマテリアル
       */
      /** @type {import('@vue/composition-api').ComputedRef<any>} */
      const currentMaterial = computed(() => {
        // 名前がマテリアルに存在するかチェック
        const activePattern = materials.value.find(
          (/** @type {{ name: string; }} */ ma) => ma.name === props.activeObject.style.imageName
        );
        if (activePattern) {
          //  categories配列をコピー
          const cloneCategories = JSON.parse(JSON.stringify(categories.value));
          //  選択素材のカテゴリーを取得した変数を宣言
          const activeCategory = ref(
            //  大項目カテゴリーを仕分け
            cloneCategories.filter(category => {
              //  中項目カテゴリーを仕分け、システム名が条件
              const activeChild = category.child.filter(
                child => child.systemName === activePattern.class
              );
              if (activeChild.length > 0) {
                //  コピーした配列の一部を選択したカテゴリーで制限
                category.child = activeChild;
                return category;
              }
            })
          );
          // 名前がマテリアルに存在する場合、単色の値を消す
          vModelSingleColor.value = null;
          // 素材タブをパターンにする
          isActiveFloorMaterialType.value = MATERIAL_TYPE.PATTERN;
          if (activeCategory.value.length > 0 && !isSearch) {
            // 大項目カテゴリーをマテリアルが所属するカテゴリーにする
            categoryIndex.value = activeCategory.value[0].sortNo;
            // 中項目カテゴリーをマテリアルが所属するカテゴリーにする
            middleCategorySystemName.value = activePattern.class;
          }
        } else {
          // 名前がマテリアルに存在しない場合、色名を設定する
          vModelSingleColor.value = props.activeObject.style.fill;
          // 素材タブを単色にする
          isActiveFloorMaterialType.value = MATERIAL_TYPE.SINGLE_COLOR;
        }
        // 名前がマテリアルに存在する場合はマテリアル。無ければColorに色名をセットして返す。
        return activePattern ?? { url: '', color: props.activeObject.style.fill };
      });

      /**
       * METHOD: 検索文字列を入力する。ヒットしたデータを返却する
       * @param {String} searchMessage 検索文言
       * @returns 検索結果を出力
       * [
       *  [カラーコード],[カラーコード],
       * ]
       */
      const getMaterialSearch = async searchMessage => {
        selectCategoryMaterial.value = [];
        if (searchMessage.value == '' || searchMessage.value == null) {
          materialFilter();
          return;
        }
        // 文字が入力されたフラグを建てる
        isSearch = true;

        //前方一致
        //let regexp = new RegExp('^' + searchMessage + '(.*?)', 'g');
        //部分一致
        let regexp = new RegExp(searchMessage.value + '(.*?)', 'g');

        selectCategoryMaterial.value = await materials.value.filter(val => val.name.match(regexp));
      };

      /**
       * METHOD: マテリアル変更
       */
      const changeMaterial = (/** @type {string} */ name) => {
        const activePattern = materials.value.find(
          (/** @type {{ name: string; }} */ ma) => ma.name === name
        );

        if (activePattern && activePattern.url) {
          // パターン画像の場合
          props.activeObject.style.imageName = activePattern.name;
          props.activeObject.style.imageUrl = activePattern.url;
          props.activeObject.style.fill = null;
        } else {
          // 単色の場合
          props.activeObject.style.imageName = null;
          props.activeObject.style.imageUrl = null;
          props.activeObject.style.fill = vModelSingleColor.value;
        }
      };

      /**
       * WATCH: 選択したカテゴリーで素材を再仕分け
       */
      watch([middleCategorySystemName], () => {
        materialFilter();
      });

      /**
       *  METHOD: 素材カテゴリ選択の反映
       */
      const selectCategory = category => {
        isSearch = false; // フラグの初期化
        searchMessage.value = ''; // 検索文字の初期化
        categoryIndex.value = category.sortNo;
      };

      /**
       *  METHOD: 素材中項目カテゴリ選択の反映
       */
      const selectMiddleCategory = middleCategory => {
        isSearch = false; // フラグの初期化
        searchMessage.value = ''; // 検索文字の初期化
        middleCategorySystemName.value = middleCategory.systemName;
      };

      /**
       * WATCH: 単色の値をマテリアルに反映
       */
      watch([vModelSingleColor], () => {
        // 値がある場合のみ処理する
        if (vModelSingleColor.value) {
          changeMaterial(vModelSingleColor.value);
        }
      });

      /*
       * METHOD: 部屋名の変更
       */
      const changeSpaceName = (/** @type {string} */ val) => {
        PvFloor.setSpaceName(props.activeObject, val);
      };

      /*
       * METHOD: パターン回転値の補正
       */
      const changePatternRotate = (/** @type {any} */ val) => {
        //※-360度以上の値が直接入力された場合に非対応
        props.activeObject.style.patternRotate = (val + 360) % 360;
      };

      /*
       * METHOD: 不透明度の補正
       */
      const changePatternOpacity = (/** @type {any} */ val) => {
        if (val > 100) {
          props.activeObject.style.patternOpacity = 100;
        } else if (val < 0) {
          props.activeObject.style.patternOpacity = 0;
        } else {
          props.activeObject.style.patternOpacity = val;
        }
      };

      /**
       *  WATCH: 入力した文字列で素材検索
       */
      watch(searchMessage, () => {
        getMaterialSearch(searchMessage);
      });

      return {
        MATERIAL_TYPE, // 壁マテリアルタイプ
        isActiveFloorMaterialType, // 選択中の床マテリアルタイプ
        vModelSingleColor, // Vモデル：単色
        setStyleProperty,
        setProperty,
        changeType,
        changeMaterial,
        currentMaterial,
        wallColorList,
        spaceNameList,
        categories,
        categoryIndex,
        selectCategory,
        middleCategorySystemName,
        selectMiddleCategory,
        selectCategoryMaterial,
        formCommonClassString, // サイドメニュー入力欄用共通class文字列
        searchMessage, // 検索文字列
        getMaterialSearch, // 素材検索関数
        isSearch, // 検索フラグ
        gridHeight,
        changeSpaceName,
        changePatternRotate,
        changePatternOpacity,
      };
    },
  });
</script>
<style scoped>
  button.category-btn {
    font-size: 0.5rem;
  }
</style>
<style>
  .v-swatches:disabled {
    background-color: #cccccc;
  }
</style>
