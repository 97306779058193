<template>
  <div v-if="result != null">
    <div>
      <div>
        <div :style="titleStyle">
          支出の部
        </div>
        <div :style="unitPrice">
          単位：円
        </div>
        <table style="width: 100%">
          <thead>
            <tr>
              <th :style="[headerStyle, col1Style]">項目1</th>
              <th :style="[headerStyle, col2Style]">項目2</th>
              <th :style="[headerStyle, col3Style]">数量</th>
              <th :style="[headerStyle, col4Style]">単位</th>
              <th :style="[headerStyle]">金額</th>
              <th :style="[headerStyle, col6Style]">摘要</th>
            </tr>
          </thead>
          <tbody v-for="(item, i) in spendingList[1]" :key="i">
            <tr v-for="(it, j) in item.items" :key="j">
              <td v-if="j === 0" :style="[subHeaderStyle, col1Style]" :rowspan="item.items.length">
                {{ !item || item.name }}
              </td>
              <td :style="[subHeaderStyle, col2Style]">{{ it.name }}</td>
              <td :style="[bodyStyle, col3Style, right]">
                {{ item ? it.quantity : null }}
              </td>
              <td :style="[bodyStyle, col4Style]">
                {{ item ? it.unit : null }}
              </td>
              <td :style="[bodyStyle, col5Style]">
                {{
                  formatNum(it.amount || it.total, {
                    floatLength: 0,
                    separation: true,
                    rountType: 'down',
                  })
                }}
              </td>
              <td :style="[bodyStyle, col6Style]">
                {{ item ? item.summary : null }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <table style="width: 100%">
      <tbody>
        <tr>
          <th :style="headerStyle" style="width: 50%; text-align: center">
            支出 合計
          </th>
          <th

            style="
            width: 50%; 
            border: solid black 1px;
            background-color: #1d1d63;
            color: white;
            text-align: right;
            padding: 0px 4px;
            "
          >
            {{
              formatNum(result.spending.total, {
                floatLength: 0,
                separation: true,
                rountType: 'down',
              })
            }}
          </th>
        </tr>
        <tr>
          <th :style="subHeaderStyle" style="width: 50%; text-align: center">
            収支
          </th>
          <th
            style="
            width: 50%; 
            text-align: right;
            border: 1px solid black;
            background-color: rgb(238, 238, 238);
            padding: 0px 4px;
            "
          >
            {{
              formatNum(result.total, {
                floatLength: 0,
                separation: true,
                rountType: 'down',
              })
            }}
          </th>
        </tr>
        <tr>
          <th :style="subHeaderStyle" style="width: 50%; text-align: center">
            譲渡税
          </th>
          <th
            style="
            width: 50%;
            text-align: right;
            border: 1px solid black;
            background-color: rgb(238, 238, 238);
            padding: 0px 4px;
          "
          >
            {{
              formatNum(result.transferTax, {
                floatLength: 0,
                separation: true,
                rountType: 'down',
              })
            }}
          </th>
        </tr>
        <tr>
          <th :style="headerStyle" style="width: 50%; text-align: center">
            売却益
          </th>
          <th
            style="
            width: 50%; 
            border: solid black 1px;
            background-color: #1d1d63;
            color: white;
            text-align: right;
            padding: 0px 4px;
            "
          >
            {{
              formatNum(result.gainOnSaleAfterTax, {
                floatLength: 0,
                separation: true,
                rountType: 'down',
              })
            }}
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import {
    headerStyle,
    partHeaderStyle,
    subHeaderStyle,
    bodyStyle,
    titleStyle,
    unitPrice,
    tableFont,
    tableStyle,
    topHeaderStyle,
  } from '../../../../components/ExportReport/style';
  import Calculation from '../../Result/Calculation';

  export default {
    mixins: [Calculation],
    props: {
      result: Object,
      spendingList: Array,
    },
    components: {},
    data() {
      return {
        headerStyle,
        partHeaderStyle,
        subHeaderStyle,
        bodyStyle,
        titleStyle,
        unitPrice,
        tableFont,
        tableStyle,
        topHeaderStyle,
        col1Style: {
          width: '17%',
        },
        col2Style: {
          width: '25%',
        },
        col3Style: {
          width: '8%',
        },
        col4Style: {
          width: '8%',
        },
        col5Style: {
          'text-align': 'right',
          width: '17%',
        },
        col6Style: {
          width: '25%',
        },
        right: {
          'text-align': 'right',
        },
      };
    },
  };
</script>
