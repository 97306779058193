// @ts-check

import { db } from './indexedDB';

const onMemoryCacheMap = new Map();
let isUpdating = false;

/**
 * キャッシュ確認
 * created_atカラムを参照し、expirePeriodの日数が過ぎていなければキャッシュを返却
 * @param {string} indexedDBTableName
 * @param {() => Promise<any>} fetcher
 * @param {{
 * forceRefresh?: boolean
 * expirePeriod?: number
 * }} options
 * @returns {Promise<any|null>} 期限内のキャッシュがあればキャッシュを、なければnullを返す
 */
export async function checkDBCache(
  indexedDBTableName,
  fetcher,
  { forceRefresh, expirePeriod } = {
    forceRefresh: false,
    expirePeriod: 1,
  }
) {
  const onMemoryCache = onMemoryCacheMap.get(indexedDBTableName);
  if (onMemoryCache) {
    return onMemoryCache;
  }

  // テーブル存在チェック
  const table = db[indexedDBTableName];
  if (table == null) {
    throw new Error('テーブルがありません。');
  }

  // キャッシュ取得
  const caches = await table.toArray();

  if (!forceRefresh && caches.length > 0) {
    const firstRecord = caches[0];
    const expire = new Date(firstRecord.created_at);
    // キャッシュのcreated_atに一日加算（最終更新日時から１日間は既存キャッシュを利用する）
    expire.setDate(expire.getDate() + (expirePeriod ?? 1));

    // 有効期間を過ぎていればクリアしキャッシュ更新
    if (expire < new Date() && !isUpdating) {
      isUpdating = true;
      table.clear();
      // データ取得、キャッシュ登録は非同期で行う
      fetcher().then(result => {
        onMemoryCacheMap.set(indexedDBTableName, result);

        for (let i = 0; i < result.length; i++) {
          // @ts-ignore
          table.put({
            ...result[i],
            created_at: new Date(),
          });
        }
        isUpdating = false;
      });
    }
    return caches;
  }

  const result = await fetcher();
  onMemoryCacheMap.set(indexedDBTableName, result);

  for (let i = 0; i < result.length; i++) {
    // @ts-ignore
    table.put({
      ...result[i],
      created_at: new Date(),
    });
  }

  return result;
}
