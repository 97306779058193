<!--
作成者：本間
作成日：2021.03.02
画面名：投資分析/出力
機能：事業収支予測計算書（現金）
→20230830「キャッシュフロー計画書」に変更
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <b-tab id="IncomeAndExpenditureProjectionsCash" title="キャッシュフロー計画書">
    <b-alert show variant="danger" class="text-center" v-if="result == null">
      売却時期を入力してください。
    </b-alert>
    <b-card-group columns style="column-count:1;" v-else>
      <b-card v-for="page in Math.ceil(result.years / yearsPerPage)" v-bind:key="page">
        <b-card-title>
          キャッシュフロー計画書&nbsp;[{{ (page - 1) * yearsPerPage + 1 }}～{{
            Math.min(page * yearsPerPage, result.years)
          }}年目]
        </b-card-title>
        <b-container
          style="padding:0;margin:0;"
          v-bind:style="[{ 'max-width': tableWidth + 'px' }]"
        >
          <b-row v-if="page == 1" style="height:1.5em;" />
          <b-row >
            <b-col v-if="page == 1" style="min-height:20px;">
              <b-row>
                <b-col cols="1">
                  <b-container class="report pr-0">
                    <b-row style="position:absolute;"/>
                    <b-row>
                      <b-col class="title emboss text-left" style="padding-left:0.5rem;">初期投資</b-col>
                    </b-row>
                  </b-container>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col cols="3">
                      <b-container class="report">
                        <b-row style="position:absolute;"/>
                        <b-row>
                          <b-col class="title emboss text-left" style="padding-left:0.5rem;">借入金</b-col>
                          <b-col class="value">{{ initialCost.loans | currency }}円</b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                    <b-col  cols="3">
                      <b-container class="report">
                        <b-row style="position:absolute;"/>
                        <b-row>
                          <b-col class="title emboss text-left" style="padding-left:0.5rem;">自己資金</b-col>
                          <b-col class="value">{{ initialCost.ownResources | currency }}円</b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                    <b-col  cols="3">
                      <b-container class="report">
                        <b-row style="position:absolute;"/>
                        <b-row>
                          <b-col class="title emboss text-left" style="padding-left:0.5rem;">保証金</b-col>
                          <b-col class="value">{{ initialCost.deposit | currency }}円</b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                    <b-col  cols="3">
                      <b-container class="report">
                        <b-row style="position:absolute;"/>
                        <b-row>
                          <b-col class="title emboss text-left" style="padding-left:0.5rem;">敷金</b-col>
                          <b-col class="value">{{ initialCost.securityDeposit | currency }}円</b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-else></b-col>
            <b-col cols="1" class="text-right" style="display: flex;justify-content: flex-end;
                align-items: flex-end; padding-left:0;">
              単位：万円
            </b-col>
          </b-row>
          <b-row style="padding:0;margin:0;">
            <!-- 固定列 -->
            <b-col
              class="fixedColumns"
              style="padding:0;margin:0;"
              v-bind:style="[
                { width: fixedColumnsWidth + 'px' },
                { 'max-width': fixedColumnsWidth + 'px' },
              ]"
            >
              <b-container class="report">
                <b-row style="height:0.5em;" />
                <b-row>
                  <b-col class="title emboss" cols="3"></b-col>
                  <b-col class="title emboss">項目1</b-col>
                  <b-col class="title emboss">項目2</b-col>
                </b-row>
                <!-- 分割線 -->
                <b-row class="title" style="height:2px;">
                  <b-col />
                </b-row>
                <!-- 現金収入/明細行 -->
                <span
                  v-for="(item, index1) in result.income.items"
                  v-bind:key="`income1_${index1}`"
                >
                  <b-row
                    v-for="(row, index2) in item.rows"
                    v-bind:key="`income1_${index1}_${index2}`"
                  >
                    <b-col
                      class="title emboss"
                      v-bind:class="{ rowspan: index1 > 0 || index2 > 0 }"
                      cols="3"
                    >
                      {{ index1 == 0 && index2 == 0 ? result.income.name : '' }}
                    </b-col>
                    <b-col
                      class="subtitle"
                      v-bind:class="{
                        rowspan: index2 > 0,
                      }"
                    >
                      {{ index2 > 0 ? null : item.name }}
                    </b-col>
                    <b-col
                      class="subtitle"
                      v-bind:class="{
                        colspan: row.name == null,
                      }"
                    >
                      {{ row.name }}
                    </b-col>
                  </b-row>
                </span>
                <!-- 現金収入/集計行 -->
                <b-row
                  v-for="(item, index) in result.income.total"
                  v-bind:key="`income_total1_${index}`"
                >
                  <b-col class="title rowspan" cols="3"></b-col>
                  <b-col class="title text-left">{{ item.name }}</b-col>
                  <b-col class="title colspan"></b-col>
                </b-row>
                <!-- 分割線 -->
                <b-row class="title" style="height:2px;">
                  <b-col />
                </b-row>
                <!-- 現金支出/明細行 -->
                <span
                  v-for="(item, index1) in result.expenditure.items"
                  v-bind:key="`expenditure1_${index1}`"
                >
                  <b-row
                    v-for="(row, index2) in item.rows"
                    v-bind:key="`expenditure1_${index1}_${index2}`"
                  >
                    <b-col
                      class="title emboss"
                      v-bind:class="{ rowspan: index1 > 0 || index2 > 0 }"
                      cols="3"
                    >
                      {{ index1 == 0 && index2 == 0 ? result.expenditure.name : '' }}
                    </b-col>
                    <b-col
                      class="subtitle"
                      style="white-space:nowrap;overflow:hidden;text-overflow:clip;"
                      v-bind:class="{
                        rowspan: index2 > 0,
                      }"
                    >
                      {{ index2 > 0 ? null : item.name }}
                    </b-col>
                    <b-col
                      class="subtitle"
                      style="white-space:nowrap;overflow:hidden;text-overflow:clip;"
                      v-bind:class="{
                        colspan: item.name == row.name || row.name == null,
                      }"
                    >
                      {{ item.name == row.name ? null : row.name }}
                    </b-col>
                  </b-row>
                </span>
                <!-- 現金支出/集計行 -->
                <b-row
                  v-for="(item, index) in result.expenditure.total"
                  v-bind:key="`expenditure_total1_${index}`"
                >
                  <b-col class="title rowspan" cols="3"></b-col>
                  <b-col class="title text-left">{{ item.name }}</b-col>
                  <b-col class="title colspan"></b-col>
                </b-row>
                <!-- 分割線 -->
                <b-row class="title" style="height:2px;">
                  <b-col />
                </b-row>
                <!-- 集計項目 -->
                <b-row v-for="(item, index) in result.total.items" v-bind:key="`total1_${index}`">
                  <b-col class="title" />
                  <b-col class="title colspan" />
                  <b-col class="title colspan emboss text-right" style="padding-right:0.5rem;">
                    {{ item.name }}
                  </b-col>
                </b-row>
                <!-- 分割線 -->
                <b-row style="height:0.5em;border-top:1px solid black" />
                <!-- 投資分析指標 -->
                <b-row
                  v-for="(item, index) in result.indicator.items"
                  v-bind:key="`indicator_1_${index}`"
                >
                  <b-col class="title emboss" v-bind:class="{ rowspan: index > 0 }" cols="3">
                    {{ index == 0 ? result.indicator.name : '' }}
                  </b-col>
                  <b-col
                    class="subtitle text-left"
                    v-bind:class="{ colspan: item.name[0] == null }"
                  >
                    {{ item.name[0] }}
                  </b-col>
                  <b-col class="subtitle text-left colspan">
                    {{ item.name[1] }}
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
            <!-- データ列 -->
            <b-col class="dataColumns" style="padding:0;margin:0;overflow-x:auto;margin-left:-1px;">
              <b-container
                class="report"
                v-bind:style="[
                  { width: dataColumnsWidth + 'px' },
                  { 'max-width': dataColumnsWidth + 'px' },
                ]"
              >
              <b-row style="height:0.5em;" />
                <b-row>
                  <b-col
                    class="title emboss"
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                  >
                    {{
                      result.years >= (page - 1) * yearsPerPage + year
                        ? (page - 1) * yearsPerPage + year + '年目'
                        : ''
                    }}
                  </b-col>
                </b-row>
                <!-- 分割線 -->
                <b-row style="height:2px;">
                  <b-col
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                    class="title"
                    style="height:2px;"
                  >
                  </b-col>
                </b-row>
                <!-- 現金収入/明細行 -->
                <span
                  v-for="(item, index1) in result.income.items"
                  v-bind:key="`income2_${index1}`"
                >
                  <b-row
                    v-for="(row, index2) in item.rows"
                    v-bind:key="`income2_${index1}_${index2}`"
                  >
                    <b-col
                      v-for="year in yearsPerPage"
                      v-bind:class="{
                        blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                        blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                      }"
                      v-bind:key="year"
                      class="value"
                    >
                      {{
                        (page - 1) * yearsPerPage + year >= result.years + 1
                          ? null
                          : row.values[(page - 1) * yearsPerPage + year - 1] | tenThousandYen
                      }}
                    </b-col>
                  </b-row>
                </span>
                <!-- 現金収入/集計行 -->
                <b-row
                  v-for="(item, index) in result.income.total"
                  v-bind:key="`income_total2_${index}`"
                >
                  <b-col
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                    class="title text-right"
                  >
                    {{
                      (page - 1) * yearsPerPage + year >= result.years + 1
                        ? null
                        : item.values[(page - 1) * yearsPerPage + year - 1] | tenThousandYen
                    }}
                  </b-col>
                </b-row>
                <!-- 分割線 -->
                <b-row style="height:2px;">
                  <b-col
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                    class="title"
                    style="height:2px;"
                  >
                  </b-col>
                </b-row>
                <!-- 現金支出/明細行 -->
                <span
                  v-for="(item, index1) in result.expenditure.items"
                  v-bind:key="`expenditure2_${index1}`"
                >
                  <b-row
                    v-for="(row, index2) in item.rows"
                    v-bind:key="`expenditure2_${index1}_${index2}`"
                  >
                    <b-col
                      v-for="year in yearsPerPage"
                      v-bind:class="{
                        blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                        blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                      }"
                      v-bind:key="year"
                      class="value"
                    >
                      {{
                        (page - 1) * yearsPerPage + year >= result.years + 1
                          ? null
                          : row.values[(page - 1) * yearsPerPage + year - 1] | tenThousandYen
                      }}
                    </b-col>
                  </b-row>
                </span>
                <!-- 現金支出/集計行 -->
                <b-row
                  v-for="(item, index) in result.expenditure.total"
                  v-bind:key="`expenditure_total2_${index}`"
                >
                  <b-col
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                    class="title text-right"
                  >
                    {{
                      (page - 1) * yearsPerPage + year >= result.years + 1
                        ? null
                        : item.values[(page - 1) * yearsPerPage + year - 1] | tenThousandYen
                    }}
                  </b-col>
                </b-row>
                <!-- 分割線 -->
                <b-row style="height:2px;">
                  <b-col
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                    class="title"
                  >
                  </b-col>
                </b-row>
                <!-- 集計項目 -->
                <b-row v-for="(item, index) in result.total.items" v-bind:key="`total2_${index}`">
                  <b-col
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                    class="title text-right"
                  >
                    <span v-if="item.unit == '％'">
                      {{
                        (page - 1) * yearsPerPage + year >= result.years + 1
                          ? null
                          : item.values[(page - 1) * yearsPerPage + year - 1] | ratio
                      }}
                    </span>
                    <span v-else>
                      {{
                        (page - 1) * yearsPerPage + year >= result.years + 1
                          ? null
                          : item.values[(page - 1) * yearsPerPage + year - 1] | tenThousandYen
                      }}
                    </span>
                  </b-col>
                </b-row>
                <!-- 分割線 -->
                <b-row style="height:0.5em;">
                  <b-col
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                    style="border-left-color:transparent;"
                  >
                  </b-col>
                </b-row>
                <!-- 投資分析指標 -->
                <b-row
                  v-for="(item, index) in result.indicator.items"
                  v-bind:key="`indicator_2_${index}`"
                >
                  <b-col
                    v-for="year in yearsPerPage"
                    v-bind:class="{
                      blankFirst: (page - 1) * yearsPerPage + year == result.years + 1,
                      blankNext: (page - 1) * yearsPerPage + year > result.years + 1,
                    }"
                    v-bind:key="year"
                    class="value"
                  >
                    <span v-if="item.unit == null">
                      {{
                        (page - 1) * yearsPerPage + year >= result.years + 1
                          ? null
                          : item.values[(page - 1) * yearsPerPage + year - 1] | nounit
                      }}
                    </span>
                    <span v-else>
                      {{
                        (page - 1) * yearsPerPage + year >= result.years + 1
                          ? null
                          : item.values[(page - 1) * yearsPerPage + year - 1] | ratio
                      }}
                    </span>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-container>
        <div></div>
      </b-card>
    </b-card-group>
  </b-tab>
</template>
<script>
  import Calculation1 from '../Input/Calculation';
  import Calculation2 from '../Result/Calculation';
  export default {
    props: ['model'],
    mixins: [Calculation1, Calculation2],
    data: function() {
      return {
        yearsPerPage: 15, // ページあたりの年数
        initialCost: {
          ownResources: null, // 自己資金
          loans: null, // 借入金額
          deposit: null, // 保証金
          securityDeposit: null, // 敷金
        }
      };
    },
    created: function() {},
    mounted: function() {
      const cp = this.investmentAnalytics.initialInvestment.capitalPlan; // 資金計画
      this.initialCost.ownResources = cp.ownResources; // 自己資金
      this.initialCost.loans = cp.loans; // 借入金額
      this.initialCost.deposit = cp.deposit; // 保証金
      this.initialCost.securityDeposit = cp.securityDeposit; // 敷金
    },
    computed: {
      // 表全体の幅
      tableWidth: function() {
        return this.fixedColumnsWidth + this.dataColumnsWidth;
      },
      // 固定列の幅
      fixedColumnsWidth: function() {
        return 360;
      },
      // データ列の幅
      dataColumnsWidth: function() {
        return this.yearsPerPage * 72;
      },
      // 計算結果
      result: function() {
        return this.initialize();
      },
    },
    methods: {
      // 入力チェック
      isEmpty: function(value) {
        return value == null || value == '' || value == undefined || Number.isNaN(value)
          ? true
          : false;
      },
      // 初期化
      initialize: function() {
        // 売却年
        const years = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale));
        if (years == 0) {
          return null;
        }
        // [収入]一時金・[支出]償還金
        let lumpSum = []; // [収入]一時金
        let redemption = []; // [支出]償還金
        {
          const cp = this.investmentAnalytics.initialInvestment.capitalPlan; // 資金計画
          if (Number(cp.deposit) > 0) {
            // 初期投資/資金計画/保証金	※初年度のみ
            lumpSum.push({ name: '保証金', values: [Number(cp.deposit)] });
            redemption.push({ name: '保証金', values: this.redemptions(cp.deposit) });
          }
          if (Number(cp.constructionCooperationFund) > 0) {
            // 初期投資/資金計画/建設協力金	※初年度のみ
            lumpSum.push({ name: '建設協力金', values: [Number(cp.constructionCooperationFund)] });
          }
          if (Number(cp.securityDeposit) > 0) {
            // 初期投資/資金計画/敷金	※初年度のみ
            lumpSum.push({ name: '敷金', values: [Number(cp.securityDeposit)] });
            redemption.push({ name: '敷金', values: this.redemptionsDeposit(cp.securityDeposit) });
          }
          if (Number(cp.keyMoney) > 0) {
            // 初期投資/資金計画/礼金	※初年度のみ
            lumpSum.push({ name: '礼金', values: [Number(cp.keyMoney)] });
          }
        }
        // [支払]借入返済金
        let repayments = [];
        const repayment = this.repayments;
        if (repayment.length > 0) {
          repayments.push({ name: '借入返済金', values: repayment });
        }
        // [支払]修繕費
        let repairs = [];
        let cost = this.minorRepairsTotal;
        if (cost > 0) {
          repairs.push({ name: '軽微な修繕', values: this.maintenanceCosts(cost) });
        }
        repairs.push({ name: '大規模改装', values: this.longTermRepairPlanTotals });
        // [支払]消耗品
        let consumables = [];
        if (this.consumablesTotal > 0) {
          consumables.push({
            name: null,
            values: this.maintenanceCosts(this.consumablesTotal),
          });
        }
        // [支払]原状回復費
        let restorationCosts = [];
        const restorationCost =
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[6].quantity
            )
          ) *
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[6].unitPrice
            )
          ) *
          12;
        if (restorationCost > 0) {
          restorationCosts.push({ name: null, values: this.maintenanceCosts(restorationCost) });
        }
        // [支払]保険料/火災保険
        let insuranceFees = [];
        const fireInsurance =
          Math.floor(Number(this.investmentAnalytics.initialInvestment.insuranceFees[0])) +
          Math.floor(Number(this.investmentAnalytics.initialInvestment.insuranceFees[1]));
        if (fireInsurance > 0) {
          let array = Array(years).fill(0);
          for (let i = 0; i < years; i++) {
            if (i % 5 == 0) {
              array[i] = fireInsurance;
            }
          }
          insuranceFees.push({
            name: '火災保険',
            values: array,
          });
        }
        // [支払]保険料/施設賠償責任保険
        const fee = Math.floor(Number(this.investmentAnalytics.initialInvestment.insuranceFees[2]));
        if (fee > 0) {
          insuranceFees.push({ name: '施設賠償責任保険', values: this.maintenanceCosts(fee) });
        }
        let rentalFees = [];
        if (
          this.investmentAnalytics.initialInvestment.incidentalExpenses[9] == 1 &&
          this.investmentAnalytics.initialInvestment.preCareerCommitment.isDetail == 1
        ) {
          // [支払]賃借料/賃料
          const rent =
            Math.floor(
              Number(this.investmentAnalytics.initialInvestment.preCareerCommitment.monthlyRent)
            ) * 12;
          if (rent > 0) {
            rentalFees.push({ name: '賃料', values: this.maintenanceCosts(rent) });
          }
          // [支払]賃借料/占用使用料
          const usageFee =
            Math.floor(
              Number(
                this.investmentAnalytics.initialInvestment.preCareerCommitment.monthlyOccupancyFee
              )
            ) * 12;
          if (usageFee > 0) {
            rentalFees.push({ name: '占用使用料', values: this.maintenanceCosts(usageFee) });
          }
        } else if (this.investmentAnalytics.initialInvestment.incidentalExpenses[9] == 0) {
          // [支払]賃借料/賃料(事業開始前負担がOFFの場合の支払地代の手入力値)
          const paidRent =
            Math.floor(Number(this.investmentAnalytics.singleYearPlan.spendingPlan.monthlyRent)) *
            12;
          if (paidRent > 0) {
            rentalFees.push({ name: '賃料', values: this.maintenanceCosts(paidRent) });
          }
        }
        // [支払]利用料/Wi-Fi利用料
        let usageFees = [];
        const wifi =
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[7].quantity
            )
          ) *
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[7].unitPrice
            )
          ) *
          12;
        if (wifi > 0) {
          usageFees.push({ name: 'Wi-Fi利用料', values: this.maintenanceCosts(wifi) });
        }
        // [支出]広告費
        let advertisingExpenses = [];
        const expense =
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[9].quantity
            )
          ) *
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[9].unitPrice
            )
          );
        if (expense > 0) {
          advertisingExpenses.push({ name: null, values: this.maintenanceCosts(expense) });
        }
        let others = [];
        // // [支出]その他/水道光熱費
        // const water =
        //   Number(
        //     this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[12].quantity
        //   ) *
        //   Number(
        //     this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[12].unitPrice
        //   );
        // if (water > 0) {
        //   others.push({ name: '水道光熱費', values: this.maintenanceCosts(water) });
        // }
        // [支出]その他/諸雑費
        const miscellaneousExpenses = Math.floor(
          Number(
            this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[10].unitPrice
          )
        );
        if (miscellaneousExpenses > 0) {
          others.push({ name: '諸雑費', values: this.maintenanceCosts(miscellaneousExpenses) });
        }
        // [支出]その他/その他
        const other =
          Math.floor(
            Number(
              this.investmentAnalytics.singleYearPlan.spendingPlan.expenditureItems[8].unitPrice
            )
          ) * 12;
        if (other > 0) {
          others.push({ name: 'その他', values: this.maintenanceCosts(other) });
        }
        // [支出]その他/予備費
        others.push({ name: '予備費', values: [] }); // あとで計算

        let result = {
          years: years,
          income: {
            name: '現金収入',
            items: [
              // 賃料
              { name: '賃料', rows: [{ name: null, values: this.annualRentTotal }] },
              // 共益費
              { name: '共益費', rows: [{ name: null, values: this.annualCommonServiceFeesTotal }] },
              // 駐車料
              { name: '駐車料', rows: [{ name: null, values: this.annualParkingFeesTotal }] },
              // 土地賃貸料
              {
                name: '土地賃貸料',
                rows: [{ name: null, values: this.annualLandRentalFeesTotal }],
              },
              // 一時金
              { name: '一時金', rows: lumpSum },
              // その他
              { name: 'その他', rows: [{ name: null, values: this.annualOthersTotal }] },
              // 空室損失
              { name: '空室損失', rows: [{ name: null, values: this.vacancyLosses }] },
              // 消費税
              { name: '消費税', rows: [{ name: null, values: this.consumptionTaxTotals }] },
              // 売却益
              { name: '売却益', rows: [{ name: null, values: this.gainOnSales }] },
            ],
            total: [], // あとで計算
          },
          expenditure: {
            name: '現金支出',
            items: [
              // // 初期投資
              // {
              //   name: '初期投資',
              //   rows: [
              //     {
              //       name: '初期投資',
              //       values: [this.totalProjectCost],
              //     },
              //   ],
              // },
              // 固定資産税
              {
                name: '固定資産税',
                rows: [
                  { name: '土地', values: this.propertyTaxLand },
                  { name: '既存建物', values: this.propertyTaxBuildings },
                  { name: '新築建物', values: this.propertyTaxNewBuildings },
                  { name: '付属建物', values: this.propertyTaxAttachedBuilding },
                  { name: '償却資産', values: this.propertyTaxDepreciableAssets },
                ],
              },
              // 都市計画税
              {
                name: '都市計画税',
                rows: [
                  { name: '土地', values: this.cityPlanningTaxLand },
                  { name: '既存建物', values: this.cityPlanningTaxBuildings },
                  { name: '新築建物', values: this.cityPlanningTaxNewBuildings },
                  { name: '付属建物', values: this.cityPlanningTaxAttachedBuilding },
                ],
              },
              // 借入返済金
              { name: '借入返済金', rows: repayments },
              // 償還金
              { name: '償還金', rows: redemption },
              // 修繕費
              { name: '修繕費', rows: repairs },
              // 消耗品
              { name: '消耗品', rows: consumables },
              // ビルメンテナンス
              { name: 'ビルメンテナンス', rows: this.buildingMaintenance() },
              // 管理料
              { name: '管理料', rows: [{ name: '賃貸管理料', values: [] /* あとで計算 */ }] },
              // 原状回復費
              { name: '原状回復費', rows: restorationCosts },
              // 保険料
              { name: '保険料', rows: insuranceFees },
              // 賃借料
              { name: '賃借料', rows: rentalFees },
              // 利用料
              { name: '利用料', rows: usageFees },
              // 広告費
              { name: '広告費', rows: advertisingExpenses },
              // その他
              { name: 'その他', rows: others },
              // 消費税
              {
                name: '消費税',
                rows: [
                  { name: '維持修繕費分', values: [] }, // あとで計算
                  { name: '預かり消費税', values: [] }, // あとで計算
                ],
              },
            ],
            total: [], // あとで集計
          },
          total: {
            name: null,
            items: [], // あとで集計
          },
        };
        // 現金収入/合計
        let incomeTotal = [];
        for (let i = 0; i < years; i++) {
          let subtotal = 0;
          for (let item of result.income.items) {
            for (let row of item.rows) {
              if (row.values[i] != undefined) {
                subtotal += isNaN(row.values[i]) ? 0 : Number(row.values[i]);
              }
            }
          }
          incomeTotal.push(subtotal);
        }
        result.income.total.push({ name: '収入合計', values: incomeTotal });
        // 現金支出/管理料
        {
          const item = result.expenditure.items.find(o => o.name == '管理料');
          const row = item.rows.find(o => o.name == '賃貸管理料');
          // 現金収入/合計を元に計算
          row.values = this.managementFees(incomeTotal);
        }
        // 現金支出/予備費
        {
          const item = result.expenditure.items.find(o => o.name == 'その他');
          const row = item.rows.find(o => o.name == '予備費');
          const ratio =
            Math.floor(
              Number(this.investmentAnalytics.singleYearPlan.spendingPlan.reserveRate) * 10
            ) / 10;
          // ここまでの合計を算出
          for (let i = 0; i < years; i++) {
            let subtotal = 0;
            for (let item of result.expenditure.items) {
              for (let row of item.rows) {
                if (row.values[i] != undefined) {
                  subtotal += isNaN(row.values[i]) ? 0 : Number(row.values[i]);
                }
              }
            }
            // 単年度収支計画/現金支出/予備費率を掛ける
            row.values.push((subtotal * ratio) / 100);
          }
        }
        // 現金支出/消費税/維持修繕費
        {
          const item = result.expenditure.items.find(o => o.name == '消費税');
          const row = item.rows.find(o => o.name == '維持修繕費分');
          // ここまでの合計を算出
          for (let i = 0; i < years; i++) {
            let subtotal = 0;
            for (let item of result.expenditure.items) {
              // 下記項目のみ
              const applicableItems = ['修繕費', '消耗品', 'ビルメンテナンス'];
              if (!applicableItems.includes(item.name)) {
                continue;
              }
              for (let row of item.rows) {
                if (row.values[i] != undefined) {
                  subtotal += isNaN(row.values[i]) ? 0 : Number(row.values[i]);
                }
              }
            }
            // 消費税率を掛ける
            row.values.push(this.tax(subtotal));
          }
        }
        // 現金支出/消費税/預かり消費税
        {
          const item = result.expenditure.items.find(o => o.name == '消費税');
          const row = item.rows.find(o => o.name == '預かり消費税');
          // 現金収入/消費税と同じ
          const src = result.income.items.find(o => o.name == '消費税').rows[0];
          row.values = src.values;
        }
        // 現金支出/合計
        let expenditureTotal = [];
        // 運営費(OPEX)合計 ※借入返済金と初期投資を含めない支出合計 ※投資分析DCF法の計算に使用
        let opexTotal = [];

        // 初期投資を0年目として持っておく
        if (this.totalProjectCost !== null) {
          expenditureTotal.push(this.totalProjectCost);
        }
        for (let i = 0; i < years; i++) {
          let subtotal = 0;
          let opexSubtotal = 0;
          for (let item of result.expenditure.items) {
            for (let row of item.rows) {
              if (row.values[i] != undefined) {
                let expenditure = isNaN(row.values[i]) ? 0 : Number(row.values[i]);
                subtotal += expenditure;
                if (row.name == '借入返済金' || row.name == '初期投資') {
                  opexSubtotal += 0;
                } else {
                  opexSubtotal += expenditure;
                }
              }
            }
          }
          expenditureTotal.push(subtotal);
          opexTotal.push(opexSubtotal);
        }
        result.expenditure.total.push({ name: '支出合計', values: expenditureTotal.slice(1) });
        // グローバル変数に保存
        this.investmentAnalyticsTemp.incomeTotal = incomeTotal; // 収入合計
        this.investmentAnalyticsTemp.expenditureTotal = expenditureTotal; // 支出合計
        this.investmentAnalyticsTemp.opexTotal = opexTotal; // 運営費(OPEX)合計 ※借入返済金を含めない支出合計 ※投資分析DCF法の計算に使用
        // 集計項目
        const taxes = this.investmentAnalyticsTemp.incomeTaxAndInhabitantTax;
        const cbt = this.cashFlowsBeforeTax; // 単年度現金収支（税引前現金収支）
        const cat = this.cashFlowsAfterTax; // 税引後現金収支
        const noi = this.noiPerYear; // 各年の純収益（NOI）
        this.investmentAnalyticsTemp.cashFlowsAfterTax = cat; // グローバル変数へ保存(投資分析、利回りで参照)
        this.investmentAnalyticsTemp.cashNoi = noi; // グローバル変数へ保存(投資分析、DCF法で参照)
        let total = [
          { name: '単年度現金収支', values: cbt }, // 単年度現金収支（税引前現金収支）
          { name: '現金収支累計', values: this.cumulativeTotals(cbt) }, // 現金収支累計
          { name: '借入金残高', values: this.loansOutstanding }, // 借入金残高
          //{ name: '賃貸事業に対する\n所得税・住民税', values: taxes }, // 賃貸事業に対する所得税・住民税
          //{ name: '税引後現金収支', values: cat }, // 税引後現金収支,
          //{ name: '税引後現金収支累計', values: this.cumulativeTotals(cat) }, // 税引後現金収支累計
        ];
        {
          total.forEach(o => {
            result.total.items.push(o);
          });
        }
        // 投資分析指標
        {
          // 投資分析指標
          result.indicator = {
            name: '投資分析指標',
            items: [
              { name: ['純利回り', '(FCR)'], values: this.freeAndClearReturn, unit: '％' },
              { name: ['自己資金配当率', '(CCR)'], values: this.cashOnCashReturn, unit: '％' },
              {
                name: ['損益分岐入居率', '(BER)'],
                values: this.breakEvenOccupancyRates,
                unit: '％',
              },
              { name: ['債務回収率', '(DSCR)'], values: this.debtServiceCoverageRatio, unit:'％' },
            ],
          };
          // グローバル変数に保持(投資分析で参照)
          this.investmentAnalyticsTemp.indicators.fcr = result.indicator.items[0].values; // 純利回り(FCR)
          this.investmentAnalyticsTemp.indicators.ccr = result.indicator.items[1].values; // 自己資金配当率(CCR)
          this.investmentAnalyticsTemp.indicators.ber = result.indicator.items[2].values; // 損益分岐入居率(BER)
          this.investmentAnalyticsTemp.indicators.dscr = result.indicator.items[3].values; // 債務回収率(DSCR)
        }

        if (this.yearsPerPage > result.years) {
          this.yearsPerPage = result.years;
        }
        // [debug]計算結果
        //console.debug('[事業収支予測計算書（現金）]');
        return result;
      },
      // 現金支出/ビルメンテナンス
      buildingMaintenance: function() {
        let result = [];
        let value = 0;
        // 概算入力時
        if (this.investmentAnalytics.singleYearPlan.spendingPlan.isDetail[2] == 0) {
          value = Math.floor(
            Number(this.investmentAnalytics.singleYearPlan.spendingPlan.approximateValue[2])
          );
          // 詳細入力時
        } else {
          for (
            let i = 0;
            i < this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance.length;
            i++
          ) {
            if (
              this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[i].name ==
              '警備'
            ) {
              value += Math.floor(
                Number(
                  this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[i].amount
                ) * 12
              );
            } else {
              value += Math.floor(
                Number(
                  this.investmentAnalytics.singleYearPlan.spendingPlan.buildingMaintenance[i].amount
                )
              );
            }
          }
        }

        if (value > 0) {
          result.push({
            values: this.calcChange({
              value: value,
              years: Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale)),
              term: this.getRateOfIncrease('据え置き期間'),
              period: this.getRateOfIncrease('維持費-変動周期'),
              factor: this.getRateOfIncrease('維持費-変動係数'),
            }),
          });
        }

        return result;
      },
    },
    filters: {
      // 単位無し ※債務回収率(DSCR)用
      nounit(value) {
        return value == null || value == '' || value == undefined || Number.isNaN(value)
          ? null
          : Number(Number(value).toFixed(2)).toLocaleString('ja-JP', {
              style: 'decimal',
              minimumFractionDigits: 2,
            });
      },
    },
  };
</script>
<style scoped>
  #IncomeAndExpenditureProjectionsCash .card .card-title {
    margin-bottom: 0.5em;
    font-size: x-large;
    font-weight: bold;
    text-align: center;
    color: black !important;
    text-shadow: 1px 1px 1px gray;
  }
  #IncomeAndExpenditureProjectionsCash .report .row,
  #IncomeAndExpenditureProjectionsCash .report .col {
    height: 20px;
    white-space: nowrap;
    font-size: small;
  }
  #IncomeAndExpenditureProjectionsCash .dataColumns .col {
    padding-right: 0.5em;
    padding-left: 0.5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }
  #IncomeAndExpenditureProjectionsCash .report .small {
    font-size: small;
    width: 0;
  }
  #IncomeAndExpenditureProjectionsCash .report .title.emboss {
    padding: 0;
  }
  #IncomeAndExpenditureProjectionsCash .blankFirst {
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    background-color: transparent !important;
  }
  #IncomeAndExpenditureProjectionsCash .blankNext {
    border-color: transparent !important;
    background-color: transparent !important;
  }
</style>
