// @ts-check

import { getCurrentInstance } from '@vue/composition-api';

export const useUser = () => {
  const instance = getCurrentInstance();
  if (instance == null) {
    throw new Error();
  }
  /**
   * @type {{
   * attributes: {
   * sub: string,
   * famity_name: string,
   * given_name: string
   * },
   * signInUserSession: {
   * accessToken: any
   * }
   * }}
   */
  const user = instance.proxy.$store.state.user;

  /** @type {string} */
  const companyId = user.signInUserSession.accessToken.payload['cognito:groups'].find(val =>
    val.match(/^Company-*./)
  );

  return {
    user,
    username: (user.attributes.famity_name ?? '') + ' ' + (user.attributes.given_name ?? ''),
    companyId,
  };
};
