// @ts-check

import { cloneNodeWithTransform } from '../../../node/tools/clone';
import { radToDeg } from '../../../util/math/utils';
import { Action } from '../action';
import { ActionBuilder } from './actionBuilder';

export class RotateActionBuilder extends ActionBuilder {
  build() {
    const action = new Action();
    action.actionType = Action.ACTION_TYPE.DRAG_AND_DROP;
    const handle = this.dataset.targetObject;
    const target = handle?.target;
    if (target == null) {
      throw new Error('targetObjectが設定されていません。');
    }

    let guideTarget = cloneNodeWithTransform(target);
    this.context.getGuideRoot().appendChild(guideTarget.rootClone);

    const origin = target.toAbsolutePoint(target?.transform.origin);
    const v1 = this.context.getCurrentPos().sub(origin);

    // 10度ごと
    const filter = angle => {
      const deg = radToDeg(angle) % 360;
      const amari = deg % 10;
      return deg - amari;
    };

    action.onUpdate = ({ last }) => {
      guideTarget.rootClone?.removeSelf();
      guideTarget.targetClone?.removeSelf();
      guideTarget = cloneNodeWithTransform(target);
      this.context.getGuideRoot().appendChild(guideTarget.rootClone);
      const v2 = last.sub(origin);
      const angle = v1.angle(v2) ?? 0;
      guideTarget.targetClone.rotateBy(filter(angle));
    };

    action.onComplete = ({ last }) => {
      const v2 = last.sub(origin);
      const angle = v1.angle(v2) ?? 0;
      target.rotateBy(filter(angle));
    };

    return action;
  }
}
