<template>
  <img ref="logoURL" :src="imageUrl" :style="imgStyle" />
</template>

<script>
  import noImage from '@/assets/report/reportImages/noimage.png';

  export default {
    props: {
      imgPath: String,
      imgStyle: Object,
    },
    data() {
      return {
        imageUrl: '',
        noImage: noImage,
      };
    },
    mounted() {
      this.getBase64Url();
    },
    methods: {
      getBase64Url() {
        const tempImage = document.createElement('img');
        tempImage.src =
          this.imgPath ||
          'https://allys3163848-dev.s3-ap-northeast-1.amazonaws.com/Master/report/noimage.png';
        tempImage.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = tempImage.width;
          canvas.height = tempImage.height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(tempImage, 0, 0);
          this.imageUrl = canvas.toDataURL('image/png');
        };
      },
    },
  };
</script>
