var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"OtherExpenses"}},[_c('b-card',{staticClass:"category",attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabOtherExpenses",modifiers:{"tabOtherExpenses":true}}],attrs:{"id":"btnOtherExpenses","variant":"link"}},[_vm._v(" その他経費 ")])],1),_c('b-collapse',{attrs:{"id":"tabOtherExpenses","role":"tabpanel"}},[_c('b-card-body',[_c('b-tabs',{attrs:{"fill":"","pills":""},model:{value:(_vm.investmentAnalytics.initialInvestment.otherExpenses.isDetail),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses, "isDetail", $$v)},expression:"investmentAnalytics.initialInvestment.otherExpenses.isDetail"}},[_c('b-tab',{attrs:{"title":"概算入力"}},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title bold",attrs:{"cols":"4"}},[_vm._v("総額")]),_c('b-col',[_c('b-row',[_c('b-col',[_c('allyText',{staticClass:"number",attrs:{"name":"estimatedOtherExpenses","type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                          _vm.investmentAnalytics.initialInvestment.otherExpenses
                            .estimatedOtherExpenses
                        ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses
                            , "estimatedOtherExpenses", $$v)},expression:"\n                          investmentAnalytics.initialInvestment.otherExpenses\n                            .estimatedOtherExpenses\n                        "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col')],1)],1)],1),_c('b-tab',{attrs:{"title":"詳細入力"}},[_c('b-container',{staticClass:"detail"},[_c('b-row',[_c('b-col',{staticClass:"title bold"},[_vm._v("項目")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v("その他経費")]),_c('b-col',{attrs:{"cols":"2"}})],1)],1),_c('b-col',{staticClass:"title-center "},[_vm._v("詳細")])],1),_c('b-row',[_c('b-col',{staticClass:"title "},[_vm._v("祭祀費")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(_vm.investmentAnalytics.initialInvestment.otherExpenses.ritualCost),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses, "ritualCost", $$v)},expression:"investmentAnalytics.initialInvestment.otherExpenses.ritualCost"}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title"})],1),_c('b-row',[_c('b-col',{staticClass:"title "},[_vm._v("近隣対策費")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(Math.floor(
                            _vm.investmentAnalytics.initialInvestment.otherExpenses
                              .neighborhoodWatchCosts.amount
                          ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"},[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabOtherExpenses1",modifiers:{"tabOtherExpenses1":true}}],attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabOtherExpenses1","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v("対策対象者数")]),_c('b-col',{attrs:{"cols":"2"}})],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v("単価")]),_c('b-col',{attrs:{"cols":"2"}})],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v("金額")]),_c('b-col',{attrs:{"cols":"2"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .neighborhoodWatchCosts.quantity
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .neighborhoodWatchCosts, "quantity", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.otherExpenses\n                                      .neighborhoodWatchCosts.quantity\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"2"}},[_vm._v("人")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .neighborhoodWatchCosts.unitPrice
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .neighborhoodWatchCosts, "unitPrice", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.otherExpenses\n                                      .neighborhoodWatchCosts.unitPrice\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"2"}},[_vm._v("円")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.neighborhoodWatchCostsTotal())}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"2"}},[_vm._v("円")])],1)],1)],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title "},[_vm._v("立退料")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.investmentAnalytics.initialInvestment.otherExpenses
                            .estimatedEvictionFees)}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"},[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabOtherExpenses2",modifiers:{"tabOtherExpenses2":true}}],attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabOtherExpenses2","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v(" 項目 ")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v(" 数量 ")]),_c('b-col',{attrs:{"cols":"2"}})],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v(" 単価 ")]),_c('b-col',{attrs:{"cols":"1"}})],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v(" 金額 ")]),_c('b-col',{attrs:{"cols":"1"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v(" 個人 ")]),_c('b-col',[_c('b-row',[_c('b-col',[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .evictionFeesQuantity[0]
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .evictionFeesQuantity, 0, $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.otherExpenses\n                                      .evictionFeesQuantity[0]\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 件 ")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice('立退料', '個人'))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 円 ")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")((Math.floor(
                                      _vm.investmentAnalytics.initialInvestment.otherExpenses
                                        .evictionFeesQuantity[0]
                                    ) *
                                      _vm.getUnitPrice('立退料', '個人')))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 円 ")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v(" 法人・個人事業者 ")]),_c('b-col',[_c('b-row',[_c('b-col',[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .evictionFeesQuantity[1]
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .evictionFeesQuantity, 1, $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.otherExpenses\n                                      .evictionFeesQuantity[1]\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 件 ")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice('立退料', '法人・個人事業主'))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 円 ")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")((Math.floor(
                                      _vm.investmentAnalytics.initialInvestment.otherExpenses
                                        .evictionFeesQuantity[1]
                                    ) *
                                      _vm.getUnitPrice('立退料', '法人・個人事業主')))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 円 ")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"borderline"})],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v(" 合計 ")]),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")((Math.floor(
                                      _vm.investmentAnalytics.initialInvestment.otherExpenses
                                        .evictionFeesQuantity[0]
                                    ) +
                                      Math.floor(
                                        _vm.investmentAnalytics.initialInvestment.otherExpenses
                                          .evictionFeesQuantity[1]
                                      )))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 件 ")])],1)],1),_c('b-col'),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"readonly":"","value":_vm._f("currency")(_vm.evictionFeesTotal())}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 円 ")])],1)],1)],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title "},[_vm._v("営業補償")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.investmentAnalytics.initialInvestment.otherExpenses
                            .estimatedBusinessCompensation)}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"},[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabOtherExpenses3",modifiers:{"tabOtherExpenses3":true}}],attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabOtherExpenses3","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v(" 項目 ")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v(" 数量 ")]),_c('b-col',{attrs:{"cols":"1"}})],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v(" 単価 ")]),_c('b-col',{attrs:{"cols":"1"}})],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v(" 金額 ")]),_c('b-col',{attrs:{"cols":"1"}})],1)],1)],1),_vm._l((2),function(id){return _c('b-row',{key:("for05-" + id)},[_c('b-col',{staticClass:"ally-select"},[_c('allySelect',{attrs:{"items":_vm.businessCompensationItems},model:{value:(
                                _vm.investmentAnalytics.initialInvestment.otherExpenses
                                  .businessCompensation[id - 1].content
                              ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses
                                  .businessCompensation[id - 1], "content", $$v)},expression:"\n                                investmentAnalytics.initialInvestment.otherExpenses\n                                  .businessCompensation[id - 1].content\n                              "}})],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .businessCompensation[id - 1].quantity
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .businessCompensation[id - 1], "quantity", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.otherExpenses\n                                      .businessCompensation[id - 1].quantity\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 件 ")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice(
                                      '営業補償',
                                      _vm.investmentAnalytics.initialInvestment.otherExpenses
                                        .businessCompensation[id - 1].content
                                    ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 円 ")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")((Math.floor(
                                      _vm.investmentAnalytics.initialInvestment.otherExpenses
                                        .businessCompensation[id - 1].quantity
                                    ) *
                                      _vm.getUnitPrice(
                                        '営業補償',
                                        _vm.investmentAnalytics.initialInvestment.otherExpenses
                                          .businessCompensation[id - 1].content
                                      )))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 円 ")])],1)],1)],1)}),_c('b-row',[_c('b-col',{staticClass:"borderline"})],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v(" 合計 ")]),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")((Math.floor(
                                      _vm.investmentAnalytics.initialInvestment.otherExpenses
                                        .businessCompensation[0].quantity
                                    ) +
                                      Math.floor(
                                        _vm.investmentAnalytics.initialInvestment.otherExpenses
                                          .businessCompensation[1].quantity
                                      )))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 件 ")])],1)],1),_c('b-col'),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.businessCompensationTotal())}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 円 ")])],1)],1)],1)],2)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title "},[_vm._v("広告費")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.investmentAnalytics.initialInvestment.otherExpenses.advertisingExpenses)}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"},[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabOtherExpenses6",modifiers:{"tabOtherExpenses6":true}}],attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabOtherExpenses6","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v(" 項目 ")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v(" 数量 ")]),_c('b-col',{attrs:{"cols":"1"}})],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v(" 単価 ")]),_c('b-col',{attrs:{"cols":"1"}})],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v(" 金額 ")]),_c('b-col',{attrs:{"cols":"1"}})],1)],1)],1),_vm._l((3),function(id){return _c('b-row',{key:("for07-" + id)},[_c('b-col',{staticClass:"ally-select"},[_c('allySelect',{attrs:{"items":_vm.advertisingExpensesItems},model:{value:(
                                _vm.investmentAnalytics.initialInvestment.otherExpenses
                                  .advertisingExpensesDetails[id - 1].content
                              ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses
                                  .advertisingExpensesDetails[id - 1], "content", $$v)},expression:"\n                                investmentAnalytics.initialInvestment.otherExpenses\n                                  .advertisingExpensesDetails[id - 1].content\n                              "}})],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .advertisingExpensesDetails[id - 1].quantity
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .advertisingExpensesDetails[id - 1], "quantity", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.otherExpenses\n                                      .advertisingExpensesDetails[id - 1].quantity\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 件 ")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.getUnitPrice(
                                      '広告費',
                                      _vm.investmentAnalytics.initialInvestment.otherExpenses
                                        .advertisingExpensesDetails[id - 1].content
                                    ))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 円 ")])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")((Math.floor(
                                      _vm.investmentAnalytics.initialInvestment.otherExpenses
                                        .advertisingExpensesDetails[id - 1].quantity
                                    ) *
                                      _vm.getUnitPrice(
                                        '広告費',
                                        _vm.investmentAnalytics.initialInvestment.otherExpenses
                                          .advertisingExpensesDetails[id - 1].content
                                      )))}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 円 ")])],1)],1)],1)})],2)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title "},[_vm._v("特殊経費")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.investmentAnalytics.initialInvestment.otherExpenses
                            .estimatedSpecialExpenses)}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"},[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabOtherExpenses4",modifiers:{"tabOtherExpenses4":true}}],attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabOtherExpenses4","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title-center bold",attrs:{"cols":"6"}},[_vm._v(" 細目 ")]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"title-center bold"},[_vm._v(" 金額 ")]),_c('b-col',{attrs:{"cols":"1"}})],1)],1),_c('b-col',{attrs:{"cols":"2"}})],1),_vm._l((3),function(id){return _c('b-row',{key:("for06-" + id)},[_c('b-col',{attrs:{"cols":"6"}},[_c('allyText',{model:{value:(
                                _vm.investmentAnalytics.initialInvestment.otherExpenses
                                  .specialExpenses[id - 1].content
                              ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses
                                  .specialExpenses[id - 1], "content", $$v)},expression:"\n                                investmentAnalytics.initialInvestment.otherExpenses\n                                  .specialExpenses[id - 1].content\n                              "}})],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .specialExpenses[id - 1].amount
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .specialExpenses[id - 1], "amount", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.otherExpenses\n                                      .specialExpenses[id - 1].amount\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 円 ")])],1)],1),_c('b-col',{attrs:{"cols":"2"}})],1)}),_c('b-row',[_c('b-col',{staticClass:"borderline"}),_c('b-col',{attrs:{"cols":"2"}})],1),_c('b-row',[_c('b-col',{staticClass:"title",attrs:{"cols":"6"}},[_vm._v(" 合計 ")]),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.specialExpensesTotal())}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 円 ")])],1)],1),_c('b-col',{attrs:{"cols":"2"}})],1)],2)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"title "},[_vm._v("諸雑費")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"halfWidth":"","roundType":"down"},model:{value:(
                          _vm.investmentAnalytics.initialInvestment.otherExpenses
                            .miscellaneousExpenses
                        ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses
                            , "miscellaneousExpenses", $$v)},expression:"\n                          investmentAnalytics.initialInvestment.otherExpenses\n                            .miscellaneousExpenses\n                        "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title"})],1),_c('b-row',[_c('b-col',{staticClass:"title "},[_vm._v("予備費")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.investmentAnalytics.initialInvestment.otherExpenses
                            .estimatedReserveFund)}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v("円")])],1)],1),_c('b-col',{staticClass:"title-center"},[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.tabOtherExpenses5",modifiers:{"tabOtherExpenses5":true}}],attrs:{"variant":"ally"}},[_c('i',{staticClass:"when-open ti-angle-double-up"}),_c('i',{staticClass:"when-closed ti-angle-double-down"})])],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-collapse',{attrs:{"id":"tabOtherExpenses5","role":"tabpanel"}},[_c('b-card-body',[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v(" 総事業費(予備費を除く) ")]),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.totalProjectCostExcludeReserve)}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 円 ")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v(" 予備費率 ")]),_c('b-col',[_c('b-row',[_c('b-col',[_c('allyText',{staticClass:"number",attrs:{"type":"number","separation":true,"floatLength":2,"roundType":"down"},model:{value:(
                                    _vm.investmentAnalytics.initialInvestment.otherExpenses
                                      .reserveRate
                                  ),callback:function ($$v) {_vm.$set(_vm.investmentAnalytics.initialInvestment.otherExpenses
                                      , "reserveRate", $$v)},expression:"\n                                    investmentAnalytics.initialInvestment.otherExpenses\n                                      .reserveRate\n                                  "}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" ％ ")])],1)],1),_c('b-col')],1),_c('b-row',[_c('b-col',{staticClass:"borderline"}),_c('b-col',{attrs:{"cols":"3"}})],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_vm._v(" 予備費 ")]),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-input',{staticClass:"currency",attrs:{"disabled":"","value":_vm._f("currency")(_vm.reserveFundsTotal())}})],1),_c('b-col',{staticClass:"unit",attrs:{"cols":"1"}},[_vm._v(" 円 ")])],1)],1),_c('b-col')],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }