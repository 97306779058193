// @ts-check

import { Vec } from '../../util/math/vector';
import { HandleFlg } from '../tools/handleFlg';
import { createNode } from './pvNode';
import { PvObject } from './pvObject';
import { MeasureType } from '../../util/arMeasure';
import { HEIGHT3D_DEFAULT } from '../../util/const';
import { PvAdditionalInfo } from '../tools/additionalInfo';

export class PvStairs extends PvObject {
  code = 4001;
  /** @type {import('../../types/pvNode').PvNodeType} */
  type = 'stairs';
  handleFlg = new HandleFlg({
    vertex: true,
    edge: false,
    curve: false,
    rotate: false,
  });
  height = HEIGHT3D_DEFAULT.STEP;
  height3d = HEIGHT3D_DEFAULT.WALL;
  additionalInfo = new PvAdditionalInfo({stepAngle: 15, stepHeight: 0.15})

  constructor() {
    super();
  }

  /**
   * @param {Vec[]} routes
   */
  static fromRoutes(routes) {
    const obj = createNode(PvStairs);
    obj.vertexes = routes.map(r => r.clone());
    return obj;
  }

  /**
   * @param {Vec[]} routes
   */
  deformationFromRoutes(routes) {
    this.vertexes = routes.map(r => r.clone());
  }
}
