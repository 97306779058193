// @ts-check

import { MaterialFetcher } from './materials';
import { checkDBCache } from '../../../../js/cache';
import { MATERIALS_SORT } from '../util/const';

export class FloorMaterialFetcher {
  async _listFloorMaterials() {
    const repo = new MaterialFetcher();
    const result = await repo.listMaterials(MATERIALS_SORT.FLOORING);
    return result;
  }

  /**
   *
   * @returns {Promise<{name: string, color?: string, url?: string}[]>}
   */
  async listFloorMaterials(forceRefresh = false) {
    const result = await checkDBCache('floorMaterialCache', this._listFloorMaterials, {
      forceRefresh,
    });

    result.sort((a, b) => a.index - b.index);
    return this._setOtherMaterial(result);
  }

  /**
   * @param {{name: string, color?: string, url?: string}[]} materials
   * @returns {{name: string, color?: string, url?: string}[]}
   */
  _setOtherMaterial(materials) {
    const colors = [
      // {
      //   name: '白',
      //   color: 'white',
      // },
      {
        name: '斜線',
        class: 'other',
        // @ts-ignore
        url: require('../../../../images/otherFloorMaterials/hatching.svg'),
      },
      {
        name: 'ドット',
        class: 'other',
        // @ts-ignore
        url: require('../../../../images/otherFloorMaterials/dot.svg'),
      },
    ];
    return [...colors, ...materials];
  }
}
