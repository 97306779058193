<template>
  <CommonLayout title="相続税計算">
    <ReportSubTitle subTitle="相続税計算" />
    <div style="padding: 100px 100px">
      <table
        style="margin-top: 50px; width: 100%; font-size: 40px"
        id="calcDetail"
        v-if="commonInput.family.length > 0"
      >
        <thead>
          <tr>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">相続税評価額参考価格（円）</th>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">現金(円)</th>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">生命保険(円)</th>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">その他金融資産(円)</th>
            <th :style="[headerStyle, { padding: '0 0.5%', 'font-size': '50px' }]">その他(円)</th>
          </tr>
        </thead>
        <tbody>
          <tr style="font-size: 50px;">
            <td :style="[bodyStyle, { 'text-align': 'right', padding: '0 15px'}]">
              {{ totalInheritanceTaxProperValue.toLocaleString() }} 円
            </td>
            <td :style="[bodyStyle, { 'text-align': 'right', padding: '0 15px'}]">
              {{ commonInput.cash > 0 ? commonInput.cash.toLocaleString() : 0 }} 円
            </td>
            <td :style="[bodyStyle, { 'text-align': 'right', padding: '0 15px'}]">
              {{ commonInput.stocks > 0 ? commonInput.stocks.toLocaleString() : 0 }} 円
            </td>
            <td :style="[bodyStyle, { 'text-align': 'right', padding: '0 15px'}]">
              {{
                commonInput.otherFinancialAsset > 0
                  ? commonInput.otherFinancialAsset.toLocaleString()
                  : 0
              }}
              円
            </td>
            <td :style="[bodyStyle, { 'text-align': 'right', padding: '0 15px'}]">
              {{ commonInput.otherAsset > 0 ? commonInput.otherAsset.toLocaleString() : 0 }} 円
            </td>
          </tr>

          <tr style="font-size: 50px;">
            <td :style="[subHeaderStyle]" colspan="4">
              課税価格の合計額
            </td>
            <td :style="[subHeaderStyle, { 'text-align': 'right', padding: '0 15px'}]">
              {{
                totalInheritanceTaxAssetValue > 0
                  ? totalInheritanceTaxAssetValue.toLocaleString()
                  : 0
              }}
              円
            </td>
          </tr>

          <tr style="font-size: 50px;">
            <td :style="[subHeaderStyle]" colspan="4">
              課税遺産総額
            </td>
            <td :style="[subHeaderStyle, { 'text-align': 'right', padding: '0 15px'}]">
              {{
                totalInheritanceTaxAssetValue > 0
                  ? totalInheritanceTaxAssetValue.toLocaleString()
                  : 0
              }}
              円
            </td>
          </tr>

          <tr style="font-size: 50px;">
            <td :style="[subHeaderStyle]" colspan="4">
              相続税の総額
            </td>
            <td :style="[subHeaderStyle, { 'text-align': 'right', padding: '0 15px'}]">
              {{ totalInheritanceTax > 0 ? totalInheritanceTax.toLocaleString() : 0 }} 円
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import ReportSubTitle from '@/components/ExportReport/ReportSubTitle';
  import { headerStyle, subHeaderStyle, bodyStyle } from './style';
  export default {
    props: {
      commonInput: {
        type: Object,
        required: true,
      },
      totalInheritanceTaxAssetValue: {
        type: Number,
        required: true,
      },
      totalInheritanceTaxProperValue: {
        type: Number,
        required: true,
      },
      totalInheritanceTax: {
        type: Number,
        required: true,
      },
    },
    data: function() {
      return {
        listPage: [],
        headerStyle,
        subHeaderStyle,
        bodyStyle,
      };
    },
    components: {
      CommonLayout,
      ReportSubTitle,
    },
  };
</script>
