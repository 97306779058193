import awsconfig from '@/aws-exports';
import { API, graphqlOperation } from 'aws-amplify';

//graphQL
import { getSystemMaster, getCompanyMaster } from '@/graphql/queries';
API.configure(awsconfig);

export default {
  data: function() {
    return {
      newBuildingTaxMaster: null,
      hokkaidoLegalDepartmentMaster: null,
    };
  },
  async mounted() {
    await this.newBuildingInit();
  },
  methods: {
    getGroup: function() {
      var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];

      var groupIndex = groups.findIndex(group => {
        return group.startsWith('Company-');
      }, 'Company-');
      return groups[groupIndex];
    },
    /**
     * 環境変数を取得する
     */
    newBuildingInit: async function() {
      if (this.newBuildingTaxMaster != null) return;
      let masterData11 = null;
      let masterData12 = null;

      let result = await API.graphql(
        graphqlOperation(getCompanyMaster, {
          companyId: this.getGroup(),
          classification: 'realEstateSuitability',
          classificationDetail: 11,
        })
      );
      if (!result.data.getCompanyMaster) {
        result = await API.graphql(
          graphqlOperation(getSystemMaster, {
            classification: 'realEstateSuitability',
            classificationDetail: 11,
          })
        );
        masterData11 = result.data.getSystemMaster;
      } else {
        masterData11 = result.data.getCompanyMaster;
      }
      result = await API.graphql(
        graphqlOperation(getCompanyMaster, {
          companyId: this.getGroup(),
          classification: 'realEstateSuitability',
          classificationDetail: 12,
        })
      );
      if (!result.data.getCompanyMaster) {
        result = await API.graphql(
          graphqlOperation(getSystemMaster, {
            classification: 'realEstateSuitability',
            classificationDetail: 12,
          })
        );
        masterData12 = result.data.getSystemMaster;
      } else {
        masterData12 = result.data.getCompanyMaster;
      }

      this.newBuildingTaxMaster = masterData11.value;
      this.newBuildingTaxMaster.shift();
      this.hokkaidoLegalDepartmentMaster = masterData12.value;
      this.hokkaidoLegalDepartmentMaster.shift();
    },
    /**
     * 11.新築建物課税標準価格認定基準表を取得する
     */
    getNewBuilding(propertyDescription) {
      let item = null;
      if (this.newBuildingTaxMaster == null) return null;
      if (this.hokkaidoLegalDepartmentMaster == null) return null;
      if (propertyDescription?.address != null) {
        let code = this.getPrefectures().find(
          val => val.name == propertyDescription.address.address1
        );
        if (code != null) {
          let no = ('0' + code.no).slice(-2);

          //北海道の時、地方法務部を抽出する
          let filterItem = this.newBuildingTaxMaster.filter(val => val[0] == no);
          if (no == 1) {
            let legal = null;
            for (let hokkaido of this.hokkaidoLegalDepartmentMaster) {
              if (propertyDescription.address.address2?.indexOf(hokkaido[2]) > -1) {
                legal = hokkaido;
                break;
              }
            }
            if (legal != null) {
              filterItem = filterItem.filter(val => val[1] == legal[0]);
            }
          }
          item = filterItem.filter(val => {
            let kind = null;
            if (propertyDescription.kind == '事務所') kind = '店舗・事務所・百貨店・銀行';
            if (propertyDescription.kind == '店舗') kind = '店舗・事務所・百貨店・銀行';
            if (propertyDescription.kind == '居宅') kind = '居宅';
            if (propertyDescription.kind == '共同住宅') kind = '共同住宅';
            if (propertyDescription.kind == '旅館/ホテル') kind = '旅館・料亭・ホテル';
            if (propertyDescription.kind == '病院/診療所') kind = '劇場・病院';
            if (propertyDescription.kind == '工場/倉庫') kind = '工場・倉庫・市場';
            if (propertyDescription.kind == '物置') kind = '工場・倉庫・市場';
            if (propertyDescription.kind == '車庫') kind = '工場・倉庫・市場';
            return val[3] == propertyDescription.structure && val[4] == kind;
          });
        }
      }
      return item;
    },
    /**
     * 都道府県情報
     * @returns
     */
    getPrefectures() {
      return [
        { no: 0, name: '-' },
        { no: 1, name: '北海道' },
        { no: 2, name: '青森県' },
        { no: 3, name: '岩手県' },
        { no: 4, name: '宮城県' },
        { no: 5, name: '秋田県' },
        { no: 6, name: '山形県' },
        { no: 7, name: '福島県' },
        { no: 8, name: '茨城県' },
        { no: 9, name: '栃木県' },
        { no: 10, name: '群馬県' },
        { no: 11, name: '埼玉県' },
        { no: 12, name: '千葉県' },
        { no: 13, name: '東京都' },
        { no: 14, name: '神奈川県' },
        { no: 15, name: '新潟県' },
        { no: 16, name: '富山県' },
        { no: 17, name: '石川県' },
        { no: 18, name: '福井県' },
        { no: 19, name: '山梨県' },
        { no: 20, name: '長野県' },
        { no: 21, name: '岐阜県' },
        { no: 22, name: '静岡県' },
        { no: 23, name: '愛知県' },
        { no: 24, name: '三重県' },
        { no: 25, name: '滋賀県' },
        { no: 26, name: '京都府' },
        { no: 27, name: '大阪府' },
        { no: 28, name: '兵庫県' },
        { no: 29, name: '奈良県' },
        { no: 30, name: '和歌山県' },
        { no: 31, name: '鳥取県' },
        { no: 32, name: '島根県' },
        { no: 33, name: '岡山県' },
        { no: 34, name: '広島県' },
        { no: 35, name: '山口県' },
        { no: 36, name: '徳島県' },
        { no: 37, name: '香川県' },
        { no: 38, name: '愛媛県' },
        { no: 39, name: '高知県' },
        { no: 40, name: '福岡県' },
        { no: 41, name: '佐賀県' },
        { no: 42, name: '長崎県' },
        { no: 43, name: '熊本県' },
        { no: 44, name: '大分県' },
        { no: 45, name: '宮崎県' },
        { no: 46, name: '鹿児島県' },
        { no: 47, name: '沖縄県' },
      ];
    },
  },
};
