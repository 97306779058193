import Dexie from 'dexie';
export const db = new Dexie('ally');
db.version(3).stores({
  propertyListInputs: `++id,data`,
  floorMaterialCache: '++id, name, index, url, created_at',
  wallMaterialCache: '++id, name, index, url, created_at',
  iconImageCache: '++id, filiname, name, category, blob, url, created_at',
});
db.propertyListInputs.clear();
export const indexedDB = {
  install(Vue) {
    Vue.prototype.$db = db;
  },
};
