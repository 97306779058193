import { API, graphqlOperation } from 'aws-amplify';
import { getSystemMaster } from '../graphql/queries';
import { callUnity, CALL_COMMAND } from '@/js/ar/unity';

export default {
  filters: {
    // 数値書式編集
    currency(value) {
      return value == 0
        ? 0
        : value == null || value == '' || value == undefined || isNaN(value)
        ? null
        : Math.round(Number(value)).toLocaleString('ja-JP', {
            style: 'decimal',
          });
    },
    // 万円
    tenThousandYen(value) {
      // https://omachizura.com/2016/12/laravel-number-comma.html
      const re = /(\d)(?=(\d\d\d)+(?!\d))/g; //正規表現
      return value == 0
        ? '0.0'
        : value == null || value == '' || value == undefined || isNaN(value)
        ? null
        : Number(value / 10000)
            .toFixed(1)
            .replace(re, '$1,');
    },
    // 比率編集
    ratio(value) {
      return value == null || value == '' || value == undefined || Number.isNaN(value)
        ? null
        : Number(Number(value).toFixed(2)).toLocaleString('ja-JP', {
            style: 'decimal',minimumFractionDigits: 2
          }) + '％';
    },
    // 築年数編集
    filterAgeOfBuilding(value) {
      return value == null || value == undefined || isNaN(value)
        ? null
        : '築後 ' + Number(value) + '年経過';
    },
  },
  methods: {
    // グループ取得
    getGroup: function() {
      var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
      var groupIndex = groups.findIndex(group => {
        return group.startsWith('Company-');
      }, 'Company-');
      return groups[groupIndex];
    },
    // ライセンスマスタ読込
    loadLicenseMaster: async function() {
      // ロード
      const result = await API.graphql(
        graphqlOperation(getSystemMaster, {
          classification: 'license',
          classificationDetail: '1',
        })
      );

      // 文字列⇒JSON変換
      let masters = [];
      result.data.getSystemMaster.value.forEach(element => {
        masters.push(JSON.parse(element));
      });

      return masters;
    },
    // ライセンス保有機能更新
    updateLicensedFunctions: async function(store = null) {
      if (store == null) {
        store = this.$store;
      }
      if (store.state.user?.attributes == null) {
        return;
      }
      let functions = [];
      const masters = await this.loadLicenseMaster();
      const attribute = store.state.user.attributes['custom:licenses'];
      const licenses = attribute == null ? [] : attribute.split(',');
      for (let license of licenses) {
        let master = masters.find(v => {
          return String(v.id) == String(license);
        });
        if (master == null) {
          master = { id: parseInt(license), name: 'システムマスタ未登録ライセンス' };
        }
        if (master != null) {
          if (master.functions == null) {
            // 単機能購入時
            functions.push(master.id);
          } else {
            // パック購入時
            for (let f of master.functions) {
              const m = masters.find(v => {
                return String(v.id) == String(f);
              });
              functions.push(m.id);
            }
          }
        }
      }
      functions = [...new Set(functions)]; // 重複排除
      ////console.debug({ '[utility.js] before': store.state.functions });
      store.commit('setFunctions', functions);
      ////console.debug({ '[utility.js] after': store.state.functions });
    },

    // 法定耐用年数取得
    // 引数：構造、種別
    getLegalServiceLife: function(structure, kind) {
      if (this.$store.state.master == null || this.$store.state.master.legalServiceLife == null) {
        return null;
      }
      let masters = this.$store.state.master.legalServiceLife.filter(
        x => x[0] == structure && x[1] == kind
      );
      if (masters.length == 1) {
        return Number(masters[0][2]);
      }
      return null;
    },
    // 償却率取得
    // 引数：年、種別(1:定額法-償却率、2:定率法-償却率、3:定率法-改定償却率、4:定率法-保証率)
    getDepreciationRate: function(year, kind) {
      if (this.$store.state.master == null || this.$store.state.master.depreciationRate == null) {
        return null;
      }
      let masters = this.$store.state.master.depreciationRate.filter(x => x[0] == year);
      if (masters.length == 1) {
        return Number(masters[0][kind]);
      }
      return null;
    },
    // 印紙税額取得
    // 引数：契約金額, 項目種別 0:売買契約 1:工事契約 2:コンサル契約 3:設計監理契約 4:金銭消費賃借契約 5:賃貸借契約 ※売却での、項目内の印紙税取得の場合 0:第１号文書 1:第２号文書かを直接引数に渡しています
    getStampDuty: function(price, id) {
      if (this.$store.state.master == null || this.$store.state.master.stampDuty == null) {
        return null;
      }
      //印紙税文書の種類
      let kind;

      //kind = 0:第１号文書(売買契約、金銭消費賃借契約、賃貸借契約) 1:第２号文書(工事契約、コンサル契約、設計監理契約)
      switch(id){
        case 0:kind = 0;
                break;
        case 1:
        case 2:
        case 3:kind = 1;
                break;
        case 4:
        case 5:kind = 0;
                break;
        default: kind = null;
      }

      if(kind == 0){
        for (let x of this.$store.state.master.stampDuty){
          if('第１号文書' == x[2] && price <= Number(x[0])){
            return Number(x[1]);
          }
        }
      }else if(kind == 1){
        for (let x of this.$store.state.master.stampDuty){
          if('第２号文書' == x[2] && price <= Number(x[0])){
            return Number(x[1]);
          }
        }
      }
      return null;
    },
    // 所得税額取得
    // 引数：課税所得
    getIncomeTax: function(price) {
      if (price <= 0) {
        return null;
      }
      if (this.$store.state.master == null || this.$store.state.master.incomeTax == null) {
        return null;
      }
      const value = Math.floor(price / 1000) * 1000; // 千円未満端数切捨;
      for (let x of this.$store.state.master.incomeTax) {
        if (value <= Number(x[0])) {
          // 課税所得×税率－控除額
          return (value * Number(x[1])) / 100 - x[2];
        }
      }
      return null;
    },
    // 住民税額取得
    // 引数：課税所得
    getResidentTax: function(price) {
      if (price <= 0) {
        return null;
      }
      return (price * this.getTax('住民税率')) / 100;
    },
    // 譲渡所得税率取得
    // 引数：所有期間
    getTransferIncomeTaxRate: function(year) {
      if (year == null || year == '' || year == undefined || isNaN(year) || year < 1) {
        return null;
      }
      return this.getTax(year <= 5 ? '短期所得税税率' : '長期所得税税率');
    },
    // 譲渡所得税額取得
    // 引数：課税所得、所有期間
    getTransferIncomeTax: function(income, year) {
      let result = 0;
      //措法35の控除対応は指定がないのでコメントアウト
      // 3,000万円特別控除(措法35)
      // income -= 3000 * 10000;
      if (income <= 0) {
        return 0;
      }
      //措法31条の3の税率対応は指定がないのでコメントアウト
      // // 所有期間10年以上
      // if (year >= 10) {
      //   // 10年超所有軽減税率の特例(措法31条の3)
      //   // 譲渡所得6,000万円以下：14.21％
      //   var rate1 = this.getTax('10年超所有軽減税率の特例');
      //   var income1 = Math.min(income, 6000 * 10000); // 6,000万円以下
      //   // 譲渡所得6,000万円超：20.315％
      //   var rate2 = this.getTax('長期所得税税率');
      //   var income2 = Math.max(0, income - 6000 * 10000); // 6,000万円超
      //   // 課税譲渡所得×所得税率
      //   result += (income1 * rate1) / 100; // 6,000万円以下
      //   result += (income2 * rate2) / 100; // 6,000万円超
      // }
      // // 所有期間10年未満
      // else {
      // 5年超  …長期所得税税率(20.315%)
      // 5年以下…短期所得税税率(39.63%)
      var rate = this.getTransferIncomeTaxRate(year);
      // 課税所得×所得税率
      result = (income * rate) / 100;
      // }
      return result;
    },
    // 法人税額取得
    // 引数：課税所得、資本金(種別)
    getCorporationTax: function(income, kind) {
      let result = 0;
      if (income <= 0) {
        return 0;
      }
      if (kind == '1億円以下') {
        // [資本金1億以下]
        // (課税所得≦800万円の部分)×15%
        var rate1 = this.getTax('法人税/資本金１億円以下/年800万円以下の部分');
        var income1 = Math.min(income, 800 * 10000); // 800万円以下
        // (課税所得＞800万円の部分)×23.2%
        var rate2 = this.getTax('法人税/資本金１億円以下/年800万円超の部分');
        var income2 = Math.max(0, income - 800 * 10000); // 800万円超
        // 課税所得×税率
        result += (income1 * rate1) / 100; // 800万円以下
        result += (income2 * rate2) / 100; // 800万円超
      }
      // [資本金1億超］
      else if (kind == '1億円超') {
        // 課税所得×23.2 %
        var rate = this.getTax('法人税/資本金１億円超');
        result = (income * rate) / 100;
      }
      // 上記以外
      else {
        result = null;
      }
      return result;
    },
    // 上昇率取得
    // 引数：項目名
    getRateOfIncrease: function(name) {
      if (this.$store.state.master != null && this.$store.state.master.climingRate != null) {
        let masters = this.$store.state.master.climingRate.filter(x => x[0] == name);
        if (masters.length == 1) {
          return Number(masters[0][1]);
        }
      }
      return null;
    },
    // 単価表をもとにSELECT項目取得
    // 引数：項目名
    getUnitPricesItems: function(name) {
      let items = [];
      items.push({
        text: '',
        value: '',
      });

      if (this.$store.state.master != null && this.$store.state.master.unitPrice != null) {
        let masters = this.$store.state.master.unitPrice.filter(x => x[0] == name);
        if (Array.isArray(name)) {
          switch (name.length) {
            case 2:
              masters = this.$store.state.master.unitPrice.filter(
                x => x[0] == name[0] && x[1] == name[1]
              );
              break;
            case 3:
              masters = this.$store.state.master.unitPrice.filter(
                x => x[0] == name[0] && x[1] == name[1] && x[2] == name[2]
              );
              break;
          }
        }
        for (let m of masters) {
          items.push({
            text: m[1],
            value: m[1],
          });
        }
      }
      return items;
    },
    // 単価取得
    getUnitPrice: function(name, cond) {
      if (this.$store.state.master == null || this.$store.state.master.unitPrice == null) {
        return null;
      }
      let masters = this.$store.state.master.unitPrice.filter(x =>
        cond === undefined ? x[0] == name : x[0] == name && x[1] == cond
      );
      if (masters.length == 1) {
        return Number(masters[0][2]);
      }
      return null;
    },
    // 税率(税額)取得
    // 引数：項目名
    getTax: function(name) {
      if (this.$store.state.master == null || this.$store.state.master.taxRate == null) {
        return null;
      }
      var masters = this.$store.state.master.taxRate.filter(x => x[0] == name);
      if (masters.length != 1) {
        return null;
      }
      return Number(masters[0][1]);
    },
    // 合計計算
    sum: function(...args) {
      let total = 0;
      args.forEach(arg => (total += Number(arg)));
      return total;
    },
    // 消費税額計算
    tax: function(...args) {
      let total = 0;
      args.forEach(arg => (total += Number(arg)));
      return Math.floor((total * this.getTax('消費税率')) / 100);
    },
    // 税込合計計算
    sumTax: function(...args) {
      let total = 0;
      args.forEach(arg => (total += Number(arg)));
      return total + Math.floor((total * this.getTax('消費税率')) / 100);
    },
    // ㎡⇒坪換算
    tsubo: function(arg) {
      // 小数点第3位から切捨て
      return Math.floor(Number(arg) * 0.3025 * 100) / 100;
    },
    // 変動計算
    // 引数：
    //   value  : 変動対象値(円)
    //   years  : 算出期間(年)
    //   term   : 据置期間(年)
    //   period : 変動周期(年)
    //   factor : 変動係数(％)
    // 戻り値：計算結果配列
    calcChange: function(param) {
      let result = [];
      // 変動周期未入力・・・変動なし
      if (param.period == null || param.period === undefined) {
        param.period = param.years;
      }
      // 変動係数未入力・・・変動なし
      if (param.factor == null || param.factor === undefined) {
        param.factor = 0;
      }
      for (let year = 1; year <= param.years; year++) {
        let tmp = Math.max(0, Math.ceil((year - param.term) / param.period));
        // 据置期間＝０の場合、初回は変動なし
        if (param.term == 0) {
          tmp--;
        }
        if (Array.isArray(param.value)) {
          result.push(param.value[year - 1] * Math.pow(1 + param.factor / 100, tmp));
        } else {
          result.push(param.value * Math.pow(1 + param.factor / 100, tmp));
        }
      }
      return result;
    },
    // 和暦に変換
    warekiChange: function(dateInput) {
      var _dateInput = new Date(dateInput), // 入力値
        _eras = [
          {
            name: '令和',
            kana: 'れいわ',
            initial: 'R',
            begin: '2019-05-01',
            end: '9999-12-31',
          },
          {
            name: '平成',
            kana: 'へいせい',
            initial: 'H',
            begin: '1989-01-08',
            end: '2019-04-30',
          },
          {
            name: '昭和',
            kana: 'しょうわ',
            initial: 'S',
            begin: '1926-12-25',
            end: '1989-01-07',
          },
          {
            name: '大正',
            kana: 'たいしょう',
            initial: 'T',
            begin: '1912-07-30',
            end: '1926-12-24',
          },
          {
            name: '明治',
            kana: 'めいじ',
            initial: 'M',
            begin: '1868-10-23',
            end: '1912-07-29',
          },
        ];
      // filterで絞り込み
      return _eras.filter(function(item) {
        var _dateBegin = new Date(item.begin + ' 00:00:00 +0900'),
          _dateEnd = new Date(item.end + ' 23:59:59 +0900'),
          _year = 0;

        // Date.getTimeを使って開始後かつ終了前を判定
        if (
          _dateInput.getTime() >= _dateBegin.getTime() &&
          _dateInput.getTime() <= _dateEnd.getTime()
        ) {
          // 年数を計算
          _year = _dateInput.getFullYear() - _dateBegin.getFullYear() + 1;

          // 1年なら元年にしてプロパティに追加
          item.year = _year;

          return item;
        }
      })[0];
    },
    /**
     * 数値のフォーマット変換
     * @param {number} input 入力値
     * @param {object} config 設定
     * @param {number} config.floatLength 小数点表示の桁数
     * @param {string} config.roundType 'off'=四捨五入, 'up'=切り上げ, 'down'=切り捨て
     * @param {boolean} config.separation 'カンマ区切り'
     * @returns {string}
     */
    formatNum(input, config) {
      if (config == null) {
        config = {};
      }
      let { floatLength, roundType, separation } = config;

      if (floatLength == null) {
        floatLength = 0;
      }

      if (roundType == null) {
        roundType = 'off';
      }

      if (separation == null) {
        separation = false;
      }

      if (input === null) {
        return null;
      }

      //数値を扱うとき処理する
      let num = Number(input);
      if (Number.isNaN(num) || !Number.isFinite(num)) {
        return null;
      }
      let shiftNum = Math.pow(10, floatLength); // 小数点ずらし用
      if (floatLength == null) floatLength = 0;

      //小数点を表示する
      if (floatLength >= 0) {
        let inputNum = num;
        num = Number(num);
        if (roundType === 'up') {
          num = Math.ceil(num * shiftNum) / shiftNum;
        } else if (roundType === 'down') {
          // 丸め誤差対策
          if (num.toString().includes('.')) {
            let power = num.toString().split('.')[1].length;
            num = (num * (10**power));
            num = Math.floor(num * shiftNum) / shiftNum / (10**power);
            num = Number(num.toFixed(floatLength));
            // toFixedで四捨五入されて数値が繰り上がらないように対策
            if (inputNum < num) {
              num = Math.floor(inputNum * shiftNum) / shiftNum;
            }
          }
        } else if (roundType === 'off') {
          num = Math.round(num * shiftNum) / shiftNum;
        }
        num = num.toFixed(floatLength);
      }
      //カンマ表示する
      if (separation == true) {
        num = Number(num);
        num = num.toLocaleString(undefined, {
          minimumFractionDigits: floatLength,
          maximumFractionDigits: floatLength,
        });
      }

      return num.toString();
    },
    // 家具シミュレーター開始
    startFurnitureSimulator: function() {
      const result = callUnity(CALL_COMMAND.START_FURNITURE_SIMULATOR);
      if (!result) {
        this.$bvModal.show('mobileOnly');
      }
    },
  },
};
