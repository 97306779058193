// @ts-check

import { Vec } from './vector';

/**
 * 図形関係のメソッド
 */
export class Geo {
  /**
   * 重複削除
   * @param {Vec[]} vecs
   * @returns {Vec[]}
   */
  static deduplicate(vecs) {
    /** @type {Vec[]} */
    let tmp = [];
    for (let i = 0; i < vecs.length; i++) {
      if (tmp.find(t => t.eq(vecs[i])) == null) {
        tmp.push(vecs[i]);
      }
    }

    return tmp;
  }

  /**
   * ベジェ曲線を計算
   * @param {number} div 分割数
   * @param {Vec[]} vecList 長さ4のVec配列
   */
  static bezierCurveList(div, vecList) {
    const bezier = (/** @type {number} */ t) => {
      const x =
        Math.pow(1 - t, 3) * vecList[0].x +
        3 * Math.pow(1 - t, 2) * t * vecList[1].x +
        3 * (1 - t) * Math.pow(t, 2) * vecList[2].x +
        Math.pow(t, 3) * vecList[3].x;
      const y =
        Math.pow(1 - t, 3) * vecList[0].y +
        3 * Math.pow(1 - t, 2) * t * vecList[1].y +
        3 * (1 - t) * Math.pow(t, 2) * vecList[2].y +
        Math.pow(t, 3) * vecList[3].y;
      return new Vec(x, y);
    };

    let t = 0;
    const sec = 1 / div;
    const result = [];
    for (let i = 0; i < div - 1; i++) {
      t += sec;
      result.push(bezier(t));
    }
    return result;
  }

  /**
   * 直線の方程式の定数を求める
   * @param {Vec} v1
   * @param {Vec} v2
   * @returns {number[] | null} [傾き,y切片]
   */
  static getLineDef(v1, v2) {
    if (v1 === v2) {
      throw new Error('v1 === v2の場合直線の方程式を定義できません。');
    }
    const p = (v1.y - v2.y) / (v1.x - v2.x);
    if (!isFinite(p)) return null;
    const q = v2.y - v2.x * p;
    return [p, q];
  }

  /**
   * 多角形の面積
   * @param {Vec[]} vectors 多角形の頂点ベクトル
   * @returns {number} px^2
   */
  static getAreaSize(vectors) {
    let sum = 0;
    const len = vectors.length;
    for (let i = 0; i < len; i++) {
      const v1 = vectors[i];
      const v2 = vectors[(i + 1) % len];
      sum += v1.cross(v2);
    }
    const ans = sum / 2;
    return Math.abs(ans);
  }

  /**
   *
   * @param {Vec} start1
   * @param {Vec} end1
   * @param {Vec} start2
   * @param {Vec} end2
   * @returns
   */
  static judgeCrossPointOnLine(start1, end1, start2, end2) {
    if (start1.eq(start2) || start1.eq(end2) || end1.eq(start2) || end1.eq(end2)) {
      return true;
    }
    let s, t;
    s = (start1.x - end1.x) * (start2.y - start1.y) - (start1.y - end1.y) * (start2.x - start1.x);
    t = (start1.x - end1.x) * (end2.y - start1.y) - (start1.y - end1.y) * (end2.x - start1.x);
    if (s * t > 0) return false;

    s = (start2.x - end2.x) * (start1.y - start2.y) - (start2.y - end2.y) * (start1.x - start2.x);
    t = (start2.x - end2.x) * (end1.y - start2.y) - (start2.y - end2.y) * (end1.x - start2.x);
    if (s * t > 0) return false;
    return true;
  }
}
