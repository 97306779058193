<template>
  <div>
    <div class="mt-5"></div>
    <h2 class="heading">更新履歴</h2>
    <div class="pt-3" v-if="logList.length <= 0" style="background-color: #f5f5f5;">
      <div class="col-12 text-center">
        <svg
          class="bi bi-exclamation-diamond-fill"
          width="7em"
          height="7em"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
          />
        </svg>
        <div class="alert">データがありません</div>
      </div>
    </div>
    <ul class="news">
      <li class="news_item" v-for="(log, index) of logList" :key="index">
        <div style="display: flex;align-items: center;">
          <time class="news_date">{{ log.updateDate }}</time>
          <span :class="'news_category ' + log.action">{{ log.jaAction }}</span>
          <span class="news_text"
            >{{ log.userName }}さんが「{{ log.fileName }}」を{{ log.jaAction }}しました。</span
          >
        </div>
      </li>
    </ul>
    <h4>※最新100件まで表示されます。</h4>
  </div>
</template>

<script>
  export default {
    props: ['simulationLog'],
    data: function() {
      return {
        logList: [],
      };
    },
    watch: {
      async simulationLog() {
        await this.logLoed();
      },
    },
    mounted: async function() {},
    methods: {
      //ログをLoadingして表示する。
      async logLoed() {
        this.logList = [];
        if (this.simulationLog == null) return;
        for (let log of this.simulationLog) {
          let action = null;
          switch (log.action) {
            case 'add':
              action = '追加';
              break;
            case 'delete':
              action = '削除';
              break;
            case 'edit':
              action = '変更';
              break;
          }

          this.logList.unshift({
            updateDate: await this.getDateTime(log.updateDate),
            userName: log.userName,
            userMail: log.userMail,
            fileName: log.fileName,
            action: log.action,
            jaAction: action,
          });
        }
      },

      //日時の表示フォーマットを調整
      async getDateTime(date) {
        let dateTime = await new Date(date);
        let str =
          dateTime.getFullYear() +
          '/' +
          ('0' + (dateTime.getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + dateTime.getDate()).slice(-2) +
          ' ' +
          ('0' + dateTime.getHours()).slice(-2) +
          ':' +
          ('0' + dateTime.getMinutes()).slice(-2) +
          '';
        return str;
      },
    },
  };
</script>

<style scoped>
  .heading {
    margin-bottom: 0;
    padding: 10px 12px;
    font-size: 0.9rem;
    letter-spacing: 1px;
    color: white;
    background: var(--colorTheme);
    transition: 0.15s;
    /*background: url('../my-images/my-bg-slash.png');*/
  }
  .news {
    padding: 0px;
    max-height: 550px;
    overflow-y: auto;
    margin-bottom: 50px;
    list-style: none;
  }
  .news_item div {
    display: block;
    padding: 12px 15px;
    color: #000;
    font-size: 0; /* 追加 */
    transition: background-color 0.1s;
  }
  .news_item div:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .news_item:nth-of-type(even) {
    background: rgba(var(--colorThemeRGB), 0.2);
    transition: 0.15s;
    /*background: url('../my-images/my-bg-slash.png');*/
  }
  .news_date {
    display: inline-block;
    width: 19%;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
  }
  .news_category {
    display: inline-block;
    width: 8%;
    font-size: 1rem;
    background-color: #d03c56;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    letter-spacing: 1px;
    line-height: 1.6;
  }
  .news_category.add {
    background-color: #00cc00; /* 追加 */
  }
  .news_category.delete {
    background-color: #ff0000; /* 削除 */
  }
  .news_category.edit {
    background-color: #0066ff; /* 変更 */
  }

  .news_text {
    display: inline-block;
    width: 73%;
    font-size: 1.2rem;
    padding-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
