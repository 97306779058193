<template>
  <div class="back">
    <div class="container ">
      <div class="board"></div>
      <div class="row" v-if="loading == false">
        <div class=" col-12  mt-5 ">
          <h2>ALLY-PROは、現在メンテナンス中です</h2>
        </div>
        <div class=" col-12  mt-5 ">
          メンテナンス時間は下記のようになっております。
        </div>
        <div class="col-12">開始時間: {{ dateFormat(startDateTime) }}</div>
        <div class="col-12">終了時間: {{ dateFormat(endDateTime) }} (予定)</div>
        <div class="col-12 mt-2" v-html="maintStr"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import loadMaintenance from '../../../components/Common/Master/loadMaintenance.js';
  export default {
    data: function() {
      return {
        startDateTime: null,
        endDateTime: null,
        maintStr: null,
        loading: false,
      };
    },
    mounted: async function() {
      let maintenance = await loadMaintenance();

      if (maintenance.result.maintenanceFlag == 'true') {
        this.loading = true;
        this.startDateTime = new Date(maintenance.result.startTime);
        this.endDateTime = new Date(maintenance.result.endTime);
        this.maintStr = maintenance.result.remarks;
        this.loading = false;
      } else if (maintenance.result.maintenanceFlag == 'false') {
        this.$router.push('/DashBoard');
      }

      //console.log('メンテ画面起動', maintenance);
    },
    methods: {
      dateFormat(date) {
        if (date == null) return '';
        return (
          date.getFullYear() +
          '年' +
          (date.getMonth() + 1) +
          '月' +
          date.getDate() +
          '日 ' +
          ('0' + date.getHours()).slice(-2) +
          '時' +
          ('0' + date.getMinutes()).slice(-2) +
          '分'
        );
      },
    },
  };
</script>

<style scoped>
  .back {
    position: fixed;
    left: 0;
    top: 0;
    width: 99vw;
    height: 100vh;
  }
  .board {
    position: fixed;
    left: 0;
    top: 0;
    background: white;
    width: 100%;
    height: 100%;
  }
</style>

<style></style>
