// @ts-check

import { PvObject } from '../node/pvObject';
import { PvRoot } from '../node/rootNode';
import { PvGroup } from '../node/groupNode';
import { PvFloor } from '../node/floorNode';
import { PvIcon } from '../node/iconNode';
import { PvDoor } from '../node/doorNode';
import { PvWall } from '../node/wallNode';
import { PvStairs } from '../node/stairsNode';
import { PvCircleHandle } from '../node/circleHandle';
import { PvText } from '../node/textNode';
import { PvAbstractHandle } from '../node/abstractHandleNode';
import { PvGuideMarker } from '../node/guideMarkerNode';
import { PvMeasure } from '../node/measureNode';
import { PvMeasurePart } from '../node/measurePartNode';
import { PvHandle } from '../node/handleNode';
import { PvPrintArea } from '../node/printAreaNode';
import { createNode } from '../node/pvNode';
import { Transform } from '../../util/math/transform';
import { PvStyle } from './style';
import { PvAdditionalInfo } from './additionalInfo';
import { Vec } from '../../util/math/vector';

/** @type {Record<import("../../types/pvNode").PvNodeType, import("../../types/utilType").Constructor<PvObject>>} */
export const CONSTRUCTOR_MAP = {
  unknown: PvObject,
  root: PvRoot,
  group: PvGroup,
  floor: PvFloor,
  icon: PvIcon,
  door: PvDoor,
  wall: PvWall,
  stairs: PvStairs,
  text: PvText,
  printArea: PvPrintArea,
  abstractHandle: PvAbstractHandle,
  handle: PvHandle,
  circleHandle: PvCircleHandle,
  guideMarker: PvGuideMarker,
  measure: PvMeasure,
  measurePart: PvMeasurePart,
};

/**
 * データからnodeツリーを構築する
 * @param {PvObject[]} paramsList
 */
export function composeTree(paramsList) {
  const rootIndex = paramsList.findIndex(p => p.type === 'root');
  if (rootIndex == -1) {
    throw new Error('rootが含まれていません');
  }

  const convertedParams = paramsList.map(p => convertParams(p));

  const rootParams = convertedParams.splice(rootIndex, 1)[0];

  /** @type {Record<string, PvObject>} */
  const paramsMap = {};
  convertedParams.forEach(p => (paramsMap[p.id] = p));
  paramsMap[rootParams.id] = rootParams;

  /** @type {PvObject[]} */
  const nodeList = createNodeList(rootParams, paramsMap);
  /** @type {Record<string, PvObject>} */
  const nodeMap = {};
  nodeList.forEach(n => (nodeMap[n.id] = n));
  const rootNode = nodeList[0];

  compose(rootNode, paramsMap, nodeMap);

  return rootNode;
}

/**
 *
 * @param {PvObject} params
 * @returns {PvObject}
 */
function convertParams(params) {
  const p = {};
  Object.keys(params).forEach(key => {
    if (key === 'transform') {
      p[key] = new Transform(params[key]);
    } else if (key === 'style') {
      p[key] = new PvStyle(params[key]);
    } else if (key === 'vertexes') {
      p[key] = params[key].map(p => new Vec(p.x, p.y));
    } else if (key === 'additionalInfo') {
      p[key] =  new PvAdditionalInfo(params[key]);
    } else {
      p[key] = params[key];
    }
  });
  // @ts-ignore
  return p;
}

/**
 * @param {PvObject} root
 * @param {Record<string, PvObject>} paramsMap
 */
function createNodeList(root, paramsMap) {
  /** @type {PvObject[]} */
  const nodeList = [];
  /**
   *
   * @param {PvObject} params
   */
  const rec = params => {
    if (params.type == null) {
      throw new Error('');
    }
    const node = createNode(
      CONSTRUCTOR_MAP[params.type ?? ''],
      { ...params, firstChildId: null, rightBrotherId: null },
      params.id
    );
    nodeList.push(node);

    /** @type {PvObject|null} */
    let currentParams = paramsMap[params.firstChildId ?? ''];

    while (currentParams) {
      const child = rec(currentParams);
      if (child == null) {
        break;
      }
      currentParams = paramsMap[currentParams.rightBrotherId ?? ''];
    }
    return node;
  };

  rec(root);
  return nodeList;
}
/**
 *
 * @param {PvObject} root
 * @param {Record<string, PvObject>} paramsMap
 * @param {Record<string, PvObject>} nodeMap
 */
function compose(root, paramsMap, nodeMap) {
  /**
   *
   * @param {PvObject} node
   */
  const rec = node => {
    let currentId = paramsMap[node.id]?.firstChildId;
    while (currentId) {
      const child = nodeMap[currentId];
      node.appendChild(child);
      rec(child);
      currentId = paramsMap[currentId].rightBrotherId;
    }
  };
  rec(root);
}
