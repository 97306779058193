<template>
  <div>
    <div class="pv-config-label">厚み(px)</div>
    <div>
      <input
        type="number"
        style="width: 100%"
        :value="activeObject.style.strokeWidth"
        step="5"
        @input="e => setStyleProperty('strokeWidth', parseInt(e.srcElement.value))"
        :disabled="!isEditor"
      />
    </div>
    <div class="pv-config-label">高さ(m)</div>
    <div>
      <input
        type="number"
        min="0"
        max="10"
        step="0.1"
        style="width: 100%"
        :value="activeObject.height3d.toFixed(2)"
        @input="e => setProperty('height3d', parseFloat(e.srcElement.value))"
        :disabled="!isEditor"
      />
    </div>
  </div>
</template>
<script>
  // @ts-check
  import { defineComponent } from '@vue/composition-api';

  export default defineComponent({
    props: {
      activeObject: Object,
      isEditor: {
        //  PROP: 編集権限の有無
        type: Boolean,
        default: true,
      },
    },
    setup(props) {
      const setStyleProperty = (/** @type {string} */ key, /** @type {any} */ val) => {
        props.activeObject.style[key] = val;
      };
      const setProperty = (/** @type {string} */ key, /** @type {any} */ val) => {
        props.activeObject[key] = val;
      };

      return {
        setStyleProperty,
        setProperty,
      };
    },
  });
</script>
