//@ts-check

/**
 * @param {number} deg
 */
export function degToRad(deg) {
  return (deg / 180) * Math.PI;
}

/**
 * @param {number} rad
 */
export function radToDeg(rad) {
  return (rad * 180) / Math.PI;
}
