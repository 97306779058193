<!--
作成者：本間
作成日：2021.01.26
画面名：投資分析/入力
機能：初期投資/付帯費用/登録免許税
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div id="RegistrationTax">
    <b-card no-body class="category">
      <b-card-header role="tab">
        <b-button id="BtnRegistrationTax" variant="link" v-b-toggle.tabRegistrationTax>
          登録免許税
        </b-button>
      </b-card-header>
      <b-collapse id="tabRegistrationTax" role="tabpanel">
        <b-card-body>
          <b-container class="detail">
            <b-row>
              <b-col class="title bold"> 項目 </b-col>
              <b-col class="title-center bold"> 登記原因／件数 </b-col>
              <b-col class="title-center bold">
                <b-row style="padding: 0">
                  <b-col>登録免許税</b-col>
                  <b-col cols="2" />
                </b-row>
              </b-col>
              <b-col cols="2" class="title-center" style="padding: 0">変更</b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 土地 </b-col>
              <b-col class="ally-select">
                <allySelect
                  name="causeOfRegistration-0"
                  :items="causeOfRegistrationItems[0]"
                  v-model="
                    investmentAnalytics.initialInvestment.registrationTax.causeOfRegistration[0]
                  "
                />
              </b-col>
              <b-col class="component">
                <b-row>
                  <b-col class="component">
                    <b-form-input
                      disabled
                      class="currency"
                      :value="registrationTax(0) | currency"
                      v-if="!investmentAnalytics.initialInvestment.registrationTax.isChanged[0]"
                    />
                    <allyText
                      name="registrationTaxAmount"
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="investmentAnalytics.initialInvestment.registrationTax.amount[0]"
                      roundType="down"
                      v-else
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="component checkbox" style="padding: 0">
                <b-form-checkbox
                  id="registrationTax_0"
                  v-model="investmentAnalytics.initialInvestment.registrationTax.isChanged[0]"
                  name="registrationTax_0"
                  @change="changeCheckBox(0)"
                  size="lg"
                  style="padding-left: 2em; padding-top: 0.5em"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row
              v-for="id of investmentAnalytics.existingBuildingCount"
              v-bind:key="`for03-${id}`"
            >
              <b-col class="title">既存建物{{ id }}</b-col>
              <b-col class="ally-select">
                <allySelect
                  :name="'causeOfRegistration-' + id"
                  :items="causeOfRegistrationItems[0]"
                  v-model="
                    investmentAnalytics.initialInvestment.registrationTax.causeOfRegistration[id]
                  "
                />
              </b-col>
              <b-col class="component">
                <b-row>
                  <b-col class="component">
                    <b-form-input
                      disabled
                      class="currency"
                      :value="registrationTax(1, id - 1) | currency"
                      v-if="!investmentAnalytics.initialInvestment.registrationTax.isChanged[id]"
                    />
                    <allyText
                      :name="'registrationTaxExistingProperty-' + id"
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="investmentAnalytics.initialInvestment.registrationTax.amount[id]"
                      roundType="down"
                      v-else
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="component checkbox">
                <b-form-checkbox
                  :id="`registrationTax_${id}`"
                  v-model="investmentAnalytics.initialInvestment.registrationTax.isChanged[id]"
                  :name="`registrationTax_${id}`"
                  @change="changeCheckBox(id)"
                  size="lg"
                  style="padding-left: 2em; padding-top: 0.5em"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row v-for="id of investmentAnalytics.newBuildingCount" v-bind:key="`for04-${id}`">
              <b-col class="title">新築建物{{ id }}</b-col>
              <b-col />
              <b-col>
                <b-row>
                  <b-col>
                    <b-form-input
                      disabled
                      class="currency"
                      :value="registrationTax(2, id - 1) | currency"
                      v-if="
                        !investmentAnalytics.initialInvestment.registrationTax.isChanged[10 + id]
                      "
                    />
                    <allyText
                      :name="'registrationTaxNewProperty-' + id"
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="
                        investmentAnalytics.initialInvestment.registrationTax.amount[10 + id]
                      "
                      roundType="down"
                      v-else
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="component checkbox">
                <b-form-checkbox
                  :id="`registrationTax_${10 + id}`"
                  v-model="investmentAnalytics.initialInvestment.registrationTax.isChanged[10 + id]"
                  :name="`registrationTax_${10 + id}`"
                  @change="changeCheckBox(10 + id)"
                  size="lg"
                  style="padding-left: 2em; padding-top: 0.5em"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 付属建物 </b-col>
              <b-col class="ally-select">
                <allySelect
                  name="causeOfRegistration-25"
                  :items="causeOfRegistrationItems[0]"
                  v-model="
                    investmentAnalytics.initialInvestment.registrationTax.causeOfRegistration[25]
                  "
                />
              </b-col>
              <b-col class="component">
                <b-row>
                  <b-col class="component">
                    <b-form-input
                      disabled
                      class="currency"
                      :value="registrationTax(7) | currency"
                      v-if="!investmentAnalytics.initialInvestment.registrationTax.isChanged[25]"
                    />
                    <allyText
                      name="registrationTaxAmount"
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="investmentAnalytics.initialInvestment.registrationTax.amount[25]"
                      roundType="down"
                      v-else
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="component checkbox" style="padding: 0">
                <b-form-checkbox
                  id="registrationTax_25"
                  v-model="investmentAnalytics.initialInvestment.registrationTax.isChanged[25]"
                  name="registrationTax_25"
                  @change="changeCheckBox(25)"
                  size="lg"
                  style="padding-left: 2em; padding-top: 0.5em"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 抵当権 </b-col>
              <b-col />
              <b-col>
                <b-row>
                  <b-col>
                    <b-form-input
                      disabled
                      class="currency"
                      :value="registrationTax(3) | currency"
                      v-if="!investmentAnalytics.initialInvestment.registrationTax.isChanged[21]"
                    />
                    <allyText
                      name="registrationTax21"
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="investmentAnalytics.initialInvestment.registrationTax.amount[21]"
                      roundType="down"
                      v-else
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="component checkbox">
                <b-form-checkbox
                  id="registrationTax_21"
                  v-model="investmentAnalytics.initialInvestment.registrationTax.isChanged[21]"
                  @change="changeCheckBox(21)"
                  size="lg"
                  style="padding-left: 2em; padding-top: 0.5em"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right" style="font-size: smaller">
                <span
                  style="cursor: help;"
                  v-b-popover.hover.bottomleft="
                    '登録免許税までの事業費合計に' +
                      this.magnification +
                      '倍した金額を表示しています。'
                  "
                >
                  ※総事業費:&nbsp;{{ (totalProjectCostExclude * this.magnification) | currency }}円
                  <u>(?)</u>
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title" style="margin-left: 2em; margin-right: -2em"> ※追加設定 </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <allyText
                      name="totalProjectCost"
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="
                        investmentAnalytics.initialInvestment.registrationTax
                          .numberOfAdditionalMortgages
                      "
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="2" class="unit">件</b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <b-form-input
                      disabled
                      class="currency"
                      :value="registrationTax(4) | currency"
                      v-if="!investmentAnalytics.initialInvestment.registrationTax.isChanged[22]"
                    />
                    <allyText
                      name="registrationTax22"
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="investmentAnalytics.initialInvestment.registrationTax.amount[22]"
                      roundType="down"
                      v-else
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="component checkbox">
                <b-form-checkbox
                  id="registrationTax_22"
                  v-model="investmentAnalytics.initialInvestment.registrationTax.isChanged[22]"
                  @change="changeCheckBox(22)"
                  size="lg"
                  style="padding-left: 2em; padding-top: 0.5em"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right" style="font-size: smaller">
                <span
                  style="cursor: help;"
                  v-b-popover.hover.bottomleft="
                    '抵当権に値が入力されていると総事業費に計上されます。抵当権設定登記の金額を変更したい場合は、支払手数料項目内にて調整可能です。'
                  "
                >
                  ※抵当権設定登記(税別):&nbsp;{{
                    this.getUnitPrice('支払手数料', '抵当権設定登記') | currency
                  }}円
                  <u>(?)</u>
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 借地権 </b-col>
              <b-col class="ally-select">
                <allySelect
                  name="causeOfRegistration-12"
                  :items="causeOfRegistrationItems[1]"
                  v-model="
                    investmentAnalytics.initialInvestment.registrationTax.causeOfRegistration[11]
                  "
                />
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <b-form-input
                      disabled
                      class="currency"
                      :value="registrationTax(5) | currency"
                      v-if="!investmentAnalytics.initialInvestment.registrationTax.isChanged[23]"
                    />
                    <allyText
                      name="registrationTax23"
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="investmentAnalytics.initialInvestment.registrationTax.amount[23]"
                      roundType="down"
                      v-else
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="component checkbox">
                <b-form-checkbox
                  id="registrationTax_23"
                  v-model="investmentAnalytics.initialInvestment.registrationTax.isChanged[23]"
                  @change="changeCheckBox(23)"
                  size="lg"
                  style="padding-left: 2em; padding-top: 0.5em"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="title"> 登記記録調査 </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <allyText
                      name="numberOfRegisteredRecordsSurveyed"
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="
                        investmentAnalytics.initialInvestment.registrationTax
                          .numberOfRegisteredRecordsSurveyed
                      "
                      roundType="down"
                    />
                  </b-col>
                  <b-col cols="2" class="unit">件</b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <b-form-input
                      disabled
                      class="currency"
                      :value="registrationTax(6) | currency"
                      v-if="!investmentAnalytics.initialInvestment.registrationTax.isChanged[24]"
                    />
                    <allyText
                      name="registrationTax24"
                      type="number"
                      class="number"
                      :separation="true"
                      v-model="investmentAnalytics.initialInvestment.registrationTax.amount[24]"
                      roundType="down"
                      v-else
                    />
                  </b-col>
                  <b-col cols="1" class="unit">円</b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="component checkbox">
                <b-form-checkbox
                  id="registrationTax_24"
                  v-model="investmentAnalytics.initialInvestment.registrationTax.isChanged[24]"
                  @change="changeCheckBox(24)"
                  size="lg"
                  style="padding-left: 2em; padding-top: 0.5em"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
  import Calculation from './../Calculation';
  import newBuildingFunction from './../newBuildingFunction';

  import Vue from 'vue';
  export default {
    mixins: [Calculation, newBuildingFunction],
    data: function() {
      return {
        // 登記原因
        causeOfRegistrationItems: [
          // 土地/建物
          [
            { text: '', value: null },
            { text: '売買・贈与', value: '売買・贈与' },
            { text: '相続', value: '相続' },
          ],
          // 借地権
          [
            { text: '', value: null },
            { text: '設定登記', value: '設定登記' },
            { text: '設定仮登記', value: '設定仮登記' },
          ],
        ],
      };
    },
    async mounted() {
      await this.newBuildingInit();
      for (
        let i = 0;
        i < this.investmentAnalytics.initialInvestment.propertyDescription.length;
        i++
      ) {
        let registrationTax = this.investmentAnalytics.initialInvestment.registrationTax;
        if (!registrationTax.isChanged[i]) {
          this.getCalcRegistrationTax(i);
        }
      }
    },
    methods: {
      // チェックボックス変更時
      changeCheckBox: function(id) {
        // 初期値設定
        this.getCalcRegistrationTax(id);
      },
      getCalcRegistrationTax: function(id) {
        let registrationTax = this.investmentAnalytics.initialInvestment.registrationTax;
        // 土地
        if (id == 0) {
          Vue.set(registrationTax.amount, id, this.registrationTax(0));
        }
        // 既存建物
        else if (id <= 10) {
          Vue.set(registrationTax.amount, id, this.registrationTax(1, id - 1));
        }
        // 新築建物
        else if (id <= 20) {
          Vue.set(registrationTax.amount, id, this.registrationTax(2, id - 11));
        }
        // 抵当権
        else if (id == 21) {
          Vue.set(registrationTax.amount, id, this.registrationTax(3));
        }
        // 抵当権追加設定
        else if (id == 22) {
          Vue.set(registrationTax.amount, id, this.registrationTax(4));
        }
        // 借地権
        else if (id == 23) {
          Vue.set(registrationTax.amount, id, this.registrationTax(5));
        }
        // 登記記録調査
        else if (id == 24) {
          Vue.set(registrationTax.amount, id, this.registrationTax(6));
        }
        // 付属建物
        else if (id == 25) {
          Vue.set(registrationTax.amount, id, this.registrationTax(7));
        }
      },
    },
    watch: {
      // 登記記録調査 件数変更時
      'investmentAnalytics.initialInvestment.registrationTax.numberOfRegisteredRecordsSurveyed': {
        handler: function() {
          this.$set(
            this.investmentAnalytics.initialInvestment.paymentFees.numberOfCases,
            0,
            this.investmentAnalytics.initialInvestment.registrationTax
              .numberOfRegisteredRecordsSurveyed
          );
        },
        deep: false,
      },
      'investmentAnalytics.initialInvestment.propertyDescription': {
        handler: function() {
          for (
            let i = 0;
            i < this.investmentAnalytics.initialInvestment.propertyDescription.length;
            i++
          ) {
            let registrationTax = this.investmentAnalytics.initialInvestment.registrationTax;
            if (!registrationTax.isChanged[i]) {
              this.getCalcRegistrationTax(i);
            }
          }
        },
        deep: true,
      },
    },
  };
</script>
<style scoped>
  #RegistrationTax .checkbox {
    display: grid;
    text-align: center;
    align-items: center;
  }
  #RegistrationTax .checkbox > .custom-checkbox {
    padding-top: 0 !important;
  }
</style>
