<!--
作成者：Park Chongmyung
作成日：2020.6.3
画面名：相続税マスタコンポネント
機能：相続税マスタの登録・修正・照会
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div>
    <div class="row">
      <allySelect
        class="col-8"
        title="分類"
        name="master"
        :items="[
          { text: '奥行価格補正率表', value: '1' },
          { text: '側方路線影響加算率表', value: '2' },
          { text: '二方路線影響加算率表', value: '3' },
          {
            text: '不整形地補正率を算定する際の地積区分表',
            value: '4',
          },
          { text: '不整形地補正率表', value: '5' },
          { text: '間口狭小補正率表', value: '6' },
          { text: '奥行長大補正率表', value: '7' },
          { text: 'がけ地補正率表', value: '8' },
          {
            text: '規模格差補正率を算定する際の表イ三大都市圏に所在する宅地(三大都市)',
            value: '9.1',
          },
          {
            text: '規模格差補正率を算定する際の表イ三大都市圏に所在する宅地(その他)',
            value: '9.2',
          },
          {
            text: '相続税計算税マスタ',
            value: '10',
          },
          {
            text: '宅地造成費',
            value: '11',
          },
          {
            text: '傾斜地造成費',
            value: '12',
          },
        ]"
        v-model="classificationDetail"
      />
    </div>
    <hr />
    <!-- <b-form-checkbox switch @change="modeChange" hidden>変実</b-form-checkbox> -->
    <b-table
      thead-tr-class="serch-data-table-header"
      tbody-tr-class="serch-data-table-body"
      hover
      striped
      :items="items"
      :fields="fields"
      caption-top
    >
      <template v-slot:table-caption>{{ masterName }}</template>
      <template v-slot:cell(value1)="data">
        {{ isEditable('value1') ? '' : data.item.value1 }}
        <allyText v-if="isEditable('value1')" :value="data.item.value1" />
      </template>
      <template v-slot:cell(value2)="data">
        {{ isEditable('value2') ? '' : data.item.value2 }}
        <allyText v-if="isEditable('value2')" :value="data.item.value2" />
      </template>
      <template v-slot:cell(value3)="data">
        {{ isEditable('value3') ? '' : data.item.value3 }}
        <allyText v-if="isEditable('value3')" :value="data.item.value3" />
      </template>
      <template v-slot:cell(value4)="data">
        {{ isEditable('value4') ? '' : data.item.value4 }}
        <allyText v-if="isEditable('value4')" :value="data.item.value4" />
      </template>
      <template v-slot:cell(value5)="data">
        {{ isEditable('value5') ? '' : data.item.value5 }}
        <allyText v-if="isEditable('value5')" :value="data.item.value5" />
      </template>
      <template v-slot:cell(value6)="data">
        {{ isEditable('value6') ? '' : data.item.value6 }}
        <allyText v-if="isEditable('value6')" :value="data.item.value6" />
      </template>
      <template v-slot:cell(value7)="data">
        {{ isEditable('value7') ? '' : data.item.value7 }}
        <allyText v-if="isEditable('value7')" :value="data.item.value7" />
      </template>
      <template v-slot:cell(value8)="data">
        {{ isEditable('value8') ? '' : data.item.value8 }}
        <allyText v-if="isEditable('value8')" :value="data.item.value8" /> </template
      >t
    </b-table>
    <b-button v-if="mode == 'Edit'">保存</b-button>
  </div>
</template>
<script>
  import awsconfig from '../../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getSystemMaster, getCompanyMaster } from '../../../graphql/queries';

  API.configure(awsconfig);

  export default {
    props: ['masterType', 'companyId'],
    data: function() {
      return {
        mode: null,
        masterName: null,
        items: [],
        fields: [],
        id: null,
        classificationDetail: null,
        loading: null,
        userType: '',
        needCreate: false,
        editablemap: [
          {
            classificationDetail: 1,
            editable: [
              'value1',
              'value2',
              'value3',
              'value4',
              'value5',
              'value6',
              'value7',
              'value8',
            ],
          },
          {
            classificationDetail: 2,
            editable: ['value1', 'value2'],
          },
          {
            classificationDetail: 3,
            editable: ['value1'],
          },
          {
            classificationDetail: 4,
            editable: ['value1', 'value2', 'value3'],
          },
          {
            classificationDetail: 5,
            editable: ['value1', 'value2', 'value3', 'value4', 'value5', 'value6'],
          },
          {
            classificationDetail: 6,
            editable: [
              'value1',
              'value2',
              'value3',
              'value4',
              'value5',
              'value6',
              'value7',
              'value8',
            ],
          },
          {
            classificationDetail: 7,
            editable: ['value1', 'value2', 'value3', 'value4', 'value5', 'value6', 'value7'],
          },
          {
            classificationDetail: 8,
            editable: ['value1', 'value2', 'value3', 'value4'],
          },
          {
            classificationDetail: 91,
            editable: ['value1', 'value2'],
          },
          {
            classificationDetail: 92,
            editable: ['value1', 'value2'],
          },
          {
            classificationDetail: 10,
            editable: ['value1', 'value2', 'value3'],
          },
        ],
      };
    },
    methods: {
      getGroup: function() {
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];

        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex].replace('Company-', '');
      },
      loadMasterData: async function() {
        try {
          this.loading == true;
          this.masteClear();

          var masterData;
          //マスタ情報をロード
          var result = await API.graphql(
            graphqlOperation(getCompanyMaster, {
              companyId: this.getGroup(),
              classification: this.masterType,
              classificationDetail: this.classificationDetail,
            })
          );

          if (result) masterData = result.data.getCompanyMaster;

          if (!masterData) {
            this.needCreate = true;
            //マスタ情報をロード
            result = await API.graphql(
              graphqlOperation(getSystemMaster, {
                classification: this.masterType,
                classificationDetail: this.classificationDetail,
              })
            );
            masterData = result.data.getSystemMaster;
          }

          //マスタデータの名前指定
          this.masterName = masterData.masterName;

          //マスタデータの格納
          for (var e = 1; e < masterData.value.length; e++) {
            //配列宣言
            var itmesInput = {};
            for (var i = 0; i < masterData.value[0].length; i++) {
              //最初のループの場合ヘッダ名を設定
              if (e == 1) {
                var fieldInput = {
                  key: 'value' + i,
                  label: masterData.value[0][i],
                };
                this.fields.push(fieldInput);
              }
              //b-tableのアイテムを設定
              var keyName = 'value' + i;

              //以下のマスタを出力する場合
              if (
                masterData.classificationDetail == '1' ||
                masterData.classificationDetail == '6' ||
                masterData.classificationDetail == '7'
              ) {
                //奥行価格補正率表
                //間口狭小補正率表
                //奥行長大補正率表な場合
                if (keyName == 'value0') {
                  switch (e) {
                    case 1:
                      itmesInput[keyName] = masterData.value[e][i] + '未満';
                      break;
                    case masterData.value.length - 1:
                      itmesInput[keyName] = masterData.value[e - 1][i] + '以上';
                      break;
                    default:
                      itmesInput[keyName] =
                        (parseInt(masterData.value[e-1][i])) +
                        '以上' +
                        masterData.value[e][i] +
                        '未満' ;
                      break;
                  }
                } else if(masterData.value[0][i] == "開始日" || masterData.value[0][i] == "終了日"){
                  itmesInput[keyName] = masterData.value[e][i];
                } else {
                  itmesInput[keyName] = parseFloat(masterData.value[e][i]).toFixed(2);
                }
              } else if (masterData.classificationDetail == '8') {
                //がけ地補正率表
                if (keyName == 'value0') {
                  if (e == masterData.value.length - 1) {
                    itmesInput[keyName] =
                      parseFloat(masterData.value[e - 1][i]) + '以上';
                  } else {
                    itmesInput[keyName] = parseFloat(masterData.value[e][i]) + '以上';
                  }
                }else if(masterData.value[0][i] == "開始日" || masterData.value[0][i] == "終了日"){
                  itmesInput[keyName] = masterData.value[e][i];
                } else {
                  itmesInput[keyName] = parseFloat(masterData.value[e][i]).toFixed(2);
                }
              }else if(masterData.classificationDetail == '10'){
                //相続税計算マスタ
                if (keyName == 'value0') {
                  if (isFinite(masterData.value[e][i])) {
                    itmesInput[keyName] = parseFloat(masterData.value[e][i]).toLocaleString() + ' 万円以下';
                  } else {
                    itmesInput[keyName] = masterData.value[e][i];
                  }
                } else if(masterData.value[0][i] == "開始日" || masterData.value[0][i] == "終了日"){
                  itmesInput[keyName] = masterData.value[e][i];
                } else if(keyName == 'value1') {
                  itmesInput[keyName] = parseFloat(masterData.value[e][i]);
                } else {
                  itmesInput[keyName] = parseFloat(masterData.value[e][i]).toLocaleString() + ' 万円';
                }
              } else if (this.classificationDetail == '4') {
                //不整形地補正率を算定する際の地積区分表の場合に出力
                if (keyName == 'value2') {
                  itmesInput[keyName] = parseFloat(masterData.value[e][1]).toLocaleString() + '/' + parseFloat(masterData.value[e][3]).toLocaleString();
                } else if(keyName == 'value0') {
                  itmesInput[keyName] = masterData.value[e][i];
                } else if(masterData.value[0][i] == "開始日" || masterData.value[0][i] == "終了日"){
                  itmesInput[keyName] = masterData.value[e][i];
                } else {
                  itmesInput[keyName] = parseFloat(masterData.value[e][i]).toLocaleString();
                }
              } else if (this.classificationDetail == '9.1' || this.classificationDetail == '9.2') {
                //規模格差補正率 
                if (keyName == 'value1') {
                  itmesInput[keyName] = parseFloat(masterData.value[e][i]).toFixed(2);
                } else {
                  itmesInput[keyName] = masterData.value[e][i];
                }
              } else if (this.classificationDetail == '11') {
                //宅地造成費
                if (keyName != 'value0' && keyName != 'value1' && keyName != 'value7' && keyName != 'value8') {
                  itmesInput[keyName] = parseFloat(masterData.value[e][i]).toLocaleString();
                } else {
                  itmesInput[keyName] = masterData.value[e][i];
                }
              } else if (this.classificationDetail == '12') {
                //傾斜地造成費
                if (keyName != 'value0' && keyName != 'value1' && keyName != 'value8' && keyName != 'value9') {
                  itmesInput[keyName] = parseFloat(masterData.value[e][i]).toLocaleString();
                } else {
                  itmesInput[keyName] = masterData.value[e][i];
                }
              } else if (this.classificationDetail == '13') {
                //都市計画道路予定地の区域内にある宅地の評価
                if (keyName == 'value0' || keyName == 'value1' || keyName == 'value2') {
                  itmesInput[keyName] = masterData.value[e][i];
                } else {
                  itmesInput[keyName] = parseFloat(masterData.value[e][i]).toFixed(2);
                }
              } else {
                //上記以外のマスタ
                if (keyName == 'value0') {
                    itmesInput[keyName] = masterData.value[e][i];
                }else if(masterData.value[0][i] == "開始日" || masterData.value[0][i] == "終了日"){
                  itmesInput[keyName] = masterData.value[e][i];
                } else {
                  itmesInput[keyName] = parseFloat(masterData.value[e][i]).toFixed(2);
                }
              }
            }

            this.items.push(itmesInput);
            this.loading == false;
          }
        } catch (err) {
          window.alert('データロードに失敗しました。再度、ボタンを押してください。');
          console.log(err);
          this.loading == false;
        }
      },
      save: async function() {},
      masteClear: function() {
        this.items = [];
        this.fields = [];
        this.masterName = null;
      },
      isEditable: function(cell) {
        return (
          this.mode == 'Edit' &&
          this.editablemap.filter(
            x =>
              x.classificationDetail == this.classificationDetail &&
              x.editable.filter(y => y == cell).length > 0
          ).length > 0
        );
      },
      modeChange: function(data) {
        if (!data) this.mode = 'View';
        else this.mode = 'Edit';
      },
    },
    watch: {
      classificationDetail: async function() {
        await this.loadMasterData();
      },
    },
  };
</script>

<style scoped>
  .ally-radio {
    width: calc(100% / var(--radioCount));
    border: solid 1px #ced4da;
    text-align: center;
    height: 2.875rem;
    padding: 0.875rem 1.375rem;
    background-color: white;
  }

  .ally-radio input {
    visibility: hidden;
  }

  .ally-radio-selected {
    color: white;
    background-color: var(--colorTheme);
  }

  .ally-tab label {
    margin-bottom: 0;
  }
</style>
