/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getS3Url = /* GraphQL */ `
  query GetS3Url($level: String, $group: String, $file: [targetFile]) {
    getS3Url(level: $level, group: $group, file: $file) {
      fileName
      fileUrl
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($sub: String) {
    getAccount(sub: $sub) {
      address
      nickname
      birthdate
      phonenumber
      mobilePhoneNumber
      email
      phonenumberverified
      emailverified
      picture
      familyname
      preferredusername
      gender
      profile
      givenname
      updatedat
      name
      isAdmin
      sub
      serialNumber
      privilegedFlag
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $nextToken: String
    $filter: AccountInfoFilter
    $limit: Int
  ) {
    listAccounts(nextToken: $nextToken, filter: $filter, limit: $limit) {
      items {
        address
        nickname
        birthdate
        phonenumber
        mobilePhoneNumber
        email
        phonenumberverified
        emailverified
        picture
        familyname
        preferredusername
        gender
        profile
        givenname
        updatedat
        name
        isAdmin
        sub
        serialNumber
        privilegedFlag
      }
      nextToken
    }
  }
`;
export const exportReport = /* GraphQL */ `
  query ExportReport($input: convertReportInput) {
    exportReport(input: $input)
  }
`;
export const isRegisteredCard = /* GraphQL */ `
  query IsRegisteredCard($companyId: String) {
    isRegisteredCard(companyId: $companyId) {
      statusCode
      body
    }
  }
`;
export const registerCard = /* GraphQL */ `
  query RegisterCard($companyId: String, $companyName: String) {
    registerCard(companyId: $companyId, companyName: $companyName) {
      statusCode
      body
    }
  }
`;
export const enterPayment = /* GraphQL */ `
  query EnterPayment($companyId: String, $amount: Int, $tax: Int) {
    enterPayment(companyId: $companyId, amount: $amount, tax: $tax) {
      statusCode
      body
    }
  }
`;
export const registCompany = /* GraphQL */ `
  query RegistCompany($input: registCompany) {
    registCompany(input: $input)
  }
`;
export const getLicense = /* GraphQL */ `
  query GetLicense($id: String) {
    getLicense(id: $id) {
      statusCode
      body
    }
  }
`;
export const getCognitoAttributes = /* GraphQL */ `
  query GetCognitoAttributes($id: String) {
    getCognitoAttributes(id: $id)
  }
`;
export const downloadPlaneViews = /* GraphQL */ `
  query DownloadPlaneViews(
    $companyId: String
    $propertyId: String
    $files: [String]
    $watermark: Boolean
  ) {
    downloadPlaneViews(
      companyId: $companyId
      propertyId: $propertyId
      files: $files
      watermark: $watermark
    )
  }
`;
export const sendShortMessage = /* GraphQL */ `
  query SendShortMessage($message: String, $phoneNumber: String) {
    sendShortMessage(message: $message, phoneNumber: $phoneNumber)
  }
`;
export const checkAccount = /* GraphQL */ `
  query CheckAccount(
    $companyName: String
    $userLastName: String
    $userFirstName: String
    $phoneNumber: String
  ) {
    checkAccount(
      companyName: $companyName
      userLastName: $userLastName
      userFirstName: $userFirstName
      phoneNumber: $phoneNumber
    )
  }
`;
export const generateInvoice = /* GraphQL */ `
  query GenerateInvoice($input: GenerateInvoiceInput) {
    generateInvoice(input: $input) {
      statusCode
      error
      url
    }
  }
`;
export const calculateBusinessDay = /* GraphQL */ `
  query CalculateBusinessDay($date: AWSDate, $increment: Int) {
    calculateBusinessDay(date: $date, increment: $increment)
  }
`;
export const getToday = /* GraphQL */ `
  query GetToday($void: String) {
    getToday(void: $void)
  }
`;
export const getRegisterAccountTransferTranID = /* GraphQL */ `
  query GetRegisterAccountTransferTranID(
    $companyId: String
    $retURL: String
    $bankCode: String
    $branchCode: String
    $accountType: String
    $accountNumber: String
    $accountName: String
  ) {
    getRegisterAccountTransferTranID(
      companyId: $companyId
      retURL: $retURL
      bankCode: $bankCode
      branchCode: $branchCode
      accountType: $accountType
      accountNumber: $accountNumber
      accountName: $accountName
    ) {
      statusCode
      result {
        tranID
        token
        startUrl
        errCode
        errInfo
      }
    }
  }
`;
export const getMaintenanceFlag = /* GraphQL */ `
  query GetMaintenanceFlag($void: String) {
    getMaintenanceFlag(void: $void) {
      statusCode
      result {
        maintenanceFlag
        startTime
        endTime
        remarks
      }
      error
    }
  }
`;
export const isRegisterAccountTransfer = /* GraphQL */ `
  query IsRegisterAccountTransfer($tranId: String) {
    isRegisterAccountTransfer(tranId: $tranId) {
      statusCode
      result {
        siteID
        memberID
        tranID
        status
        bankCode
        branchCode
        accountType
        accountNumber
        accountName
        accountIdentification
        errCode
        errInfo
      }
    }
  }
`;
export const changePaymentMethods = /* GraphQL */ `
  query ChangePaymentMethods(
    $companyId: String
    $oldPayType: String
    $newPayType: String
    $date: String
    $accountTransfer: AccountTransferInput
  ) {
    changePaymentMethods(
      companyId: $companyId
      oldPayType: $oldPayType
      newPayType: $newPayType
      date: $date
      accountTransfer: $accountTransfer
    ) {
      statusCode
      result
      error
    }
  }
`;
export const getAozoraPayment = /* GraphQL */ `
  query GetAozoraPayment(
    $accessID: String
    $accessPass: String
    $orderID: String
  ) {
    getAozoraPayment(
      accessID: $accessID
      accessPass: $accessPass
      orderID: $orderID
    ) {
      statusCode
      result
    }
  }
`;
export const changeOrUnregisterRecurring = /* GraphQL */ `
  query ChangeOrUnregisterRecurring($companyId: String, $date: String) {
    changeOrUnregisterRecurring(companyId: $companyId, date: $date) {
      statusCode
      result
    }
  }
`;
export const changeNonSettlement = /* GraphQL */ `
  query ChangeNonSettlement(
    $date: String
    $functionSwitch: String
    $companyId: String
  ) {
    changeNonSettlement(
      date: $date
      functionSwitch: $functionSwitch
      companyId: $companyId
    ) {
      statusCode
      body
    }
  }
`;
export const getCompanyTemporaryStorage = /* GraphQL */ `
  query GetCompanyTemporaryStorage($username: String!) {
    getCompanyTemporaryStorage(username: $username) {
      username
      storageJson
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyTemporaryStorages = /* GraphQL */ `
  query ListCompanyTemporaryStorages(
    $username: String
    $filter: ModelcompanyTemporaryStorageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyTemporaryStorages(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        storageJson
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getARMeasure = /* GraphQL */ `
  query GetARMeasure($id: ID!) {
    getARMeasure(id: $id) {
      id
      companyId
      propertyId
      floorPlanId
      interiors {
        code
        setNumber
        measureTypes {
          type
          height
          svg
          measurements {
            position {
              x
              y
              z
            }
            length
            angle
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listARMeasures = /* GraphQL */ `
  query ListARMeasures(
    $filter: ModelARMeasureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listARMeasures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        propertyId
        floorPlanId
        interiors {
          code
          setNumber
          measureTypes {
            type
            height
            svg
            measurements {
              position {
                x
                y
                z
              }
              length
              angle
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnalyticsInput = /* GraphQL */ `
  query GetAnalyticsInput($id: ID!) {
    getAnalyticsInput(id: $id) {
      id
      author
      inputTitle
      commonInput {
        reportTitle
        reportDate
        customerName
        customerId
        staffName
        family {
          lastName
          firstName
          lastNameKana
          firstNameKana
          familyType
          inheritanceFlg
          inheritancePrice
          inheritancePriceDeduction
          donationDeduction
          donation
          donations
          liability
          continuityInheritance
          continuityInheritanceFlg
          continuityInheritanceTerm
          secondaryInheritancePattern
          uniqueAsset
          inheritanceSystemFlg
        }
        lastName
        firstName
        lastNameKana
        firstNameKana
        familyType
        cash
        stocks
        otherAsset
        otherFinancialAsset
        inheritanceSystemFlg
        inheritanceFlg
        inheritancePrice
        inheritancePriceDeduction
        donationDeduction
        donation
        donations
        liability
        continuityInheritanceFlg
        secondaryInheritancePattern
        secondaryHeir
        uniqueAsset
        continuityInheritanceTerm
        propertyData {
          id
          roomId
        }
      }
      inputType
      roaInput {
        centerValue
        inheritanceTaxBase {
          assetsRealEstate {
            salseObjectPoint {
              address
              lotNum
              name
              usageSituation
              landSize
              buildingSize
              acquisitionYear
            }
            bs {
              inheritanceBasePrice {
                land
                building
              }
              ourstandingDebt
            }
            pl {
              income
              spending {
                landPropertyTax
                landCityPlanningTax
                buildingPropertyTax
                buildingCityPlanningTax
                maintenanceFee
              }
            }
            cashflowCalc {
              outstandingDebtTotal
              repaymentType
              repaymentPeriod
              borrowRate
            }
            comment
          }
          assetsNonRealEstate {
            ourstandingDebt
            income
            outstandingDebtTotal
          }
        }
        prevailingPriceBase {
          prevailingPrice {
            land
            building
          }
        }
        comment
        value {
          chartValue {
            x
            y
          }
          inheritanceTaxBase {
            No8
            No10
            No15
            No16
            No18
            No19
            No20
          }
          prevailingPriceBase {
            No8
            No10
            No19
            No20
          }
          baseSelected
        }
        isCalculated
      }
      inheritanceTaxInput {
        houseFixedAssetTax
        houseFixedAssetTaxFlg
        fixedAssetTax
        landMagnification
        realEstateName
        realEstateAddress {
          postalCode
          address1
          address2
          address3
        }
        liveFlag
        landNum
        landType
        commonComment
        ownerName
        ownerAddress {
          postalCode
          address1
          address2
          address3
        }
        userName
        userAddress {
          postalCode
          address1
          address2
          address3
        }
        calcMode
        landSize
        landPrice {
          front
          back
          side1
          side2
        }
        propertyTax {
          tax
          areaRate
        }
        frontageDistance
        depthDistance
        useType
        areaType
        irregularLandFlg
        irregularLandFrontageDistance
        irregularLandDepthDistance
        bigLandFlg
        noRoadLandFlg
        noRoadLandSize
        slantedLandFlg
        slantedLandDirection
        slantedLandSize
        multipleFloorAreaRatioFlg
        multipleFloorAreaRatioDeductionRate
        corner {
          side1Corner
          side2Corner
          backCorner
        }
        setBackFlg
        setBackSize
        farmlandFlag
        changedFlag
        preparationSize
        preparationUnitPrice
        loggingSize1
        loggingSize2
        loggingUnitPrice
        stabilizationSize
        stabilizationUnitPrice
        earthworkSize
        earthworkHight
        earthworkUnitPrice
        sheathingLength
        sheathingHight
        sheathingUnitPrice
        slopeAngle
        cityRoadFlg
        cityRoadFactor
        golfFlg
        golfCost
        rentLandRate
        rentHouseRate
        lendRate
        rentHouseFlg
        lendHouseRate
        topographicMap
        topographicMapDataUrl
        memo
        totalPrice
        resultValue {
          calcArray {
            text
            resultPrice
            resultPriceType
          }
          resultPriceType
          resultPrice
        }
        userAssetInput {
          cash
          lifeInsurance
          car
          etc
        }
        isCalculated
        noRoadLandCheckFlg
      }
      realEstateInput {
        chartData
        inputDate
        siteConditions {
          ownerInformation {
            owner {
              familyName
              name
            }
            ownerType
            estimatedEstateValue
            spouse
            numberOfChildren
            numberOfParentsAlive
            siblingsOfTheDeceased
            spouseRealRatio
            childRealRatio
            parentRealRatio
            siblingRealRatio
          }
          siteInformation {
            surfaceArea
            surfaceAreaUnit
            landLocation
            lotSize
            buildingToLandRatio
            floorAreaRatio
            percentageOfLeaseholdInterest
            roadsideLandPrice
            roadsideLandPriceUnit
            propertyTaxAssessment
            presumption
            availableForSale
            currentUse
            currentIncome
            zoningDistrict
            otherAreas
            landPicture
          }
        }
        locationRequirement {
          name
          memo
          traffic
          landUseInTheVicinity
          guideMap
          concreteApplicationImages {
            vacantLandAndLeasedLand
            monthlyParkingLot
            storeOffice
            groupRental
            groupRentalFamily
            detachedHousesForRent
            sellAndCashOut
          }
        }
        overallPlan {
          rentalProperty {
            checked
            monthlyRent
            numberOfFloors
            numberOfHouses
            annualRepairCosts
            numberOfLevels
            personalFunds
            borrowingRate
            borrowingPeriod
          }
          monthlyParkingLot {
            monthlyRent
            numberOfUnits
            personalFunds
            borrowingRate
            borrowingPeriod
            equityRatio
          }
          storeOffice {
            monthlyRent
            totalFlooraSpace
            personalFunds
            borrowingRate
            borrowingPeriod
            equityRatio
          }
          sale {
            salePrice
            purchasePrice
            agencyFees
            transferIncomeTax
            netProceeds
            acquisitionCost
            periodOfOwnership
            termOfTransferIncomeTax
            priceAtTheTimeOfPurchase
            otherTransferCosts
          }
        }
        isCalculated
      }
      comment
      createdAt
      updatedAt
    }
  }
`;
export const listAnalyticsInputs = /* GraphQL */ `
  query ListAnalyticsInputs(
    $filter: ModelAnalyticsInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnalyticsInputs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        inputTitle
        commonInput {
          reportTitle
          reportDate
          customerName
          customerId
          staffName
          family {
            lastName
            firstName
            lastNameKana
            firstNameKana
            familyType
            inheritanceFlg
            inheritancePrice
            inheritancePriceDeduction
            donationDeduction
            donation
            donations
            liability
            continuityInheritance
            continuityInheritanceFlg
            continuityInheritanceTerm
            secondaryInheritancePattern
            uniqueAsset
            inheritanceSystemFlg
          }
          lastName
          firstName
          lastNameKana
          firstNameKana
          familyType
          cash
          stocks
          otherAsset
          otherFinancialAsset
          inheritanceSystemFlg
          inheritanceFlg
          inheritancePrice
          inheritancePriceDeduction
          donationDeduction
          donation
          donations
          liability
          continuityInheritanceFlg
          secondaryInheritancePattern
          secondaryHeir
          uniqueAsset
          continuityInheritanceTerm
          propertyData {
            id
            roomId
          }
        }
        inputType
        roaInput {
          centerValue
          inheritanceTaxBase {
            assetsRealEstate {
              salseObjectPoint {
                address
                lotNum
                name
                usageSituation
                landSize
                buildingSize
                acquisitionYear
              }
              bs {
                inheritanceBasePrice {
                  land
                  building
                }
                ourstandingDebt
              }
              pl {
                income
                spending {
                  landPropertyTax
                  landCityPlanningTax
                  buildingPropertyTax
                  buildingCityPlanningTax
                  maintenanceFee
                }
              }
              cashflowCalc {
                outstandingDebtTotal
                repaymentType
                repaymentPeriod
                borrowRate
              }
              comment
            }
            assetsNonRealEstate {
              ourstandingDebt
              income
              outstandingDebtTotal
            }
          }
          prevailingPriceBase {
            prevailingPrice {
              land
              building
            }
          }
          comment
          value {
            chartValue {
              x
              y
            }
            inheritanceTaxBase {
              No8
              No10
              No15
              No16
              No18
              No19
              No20
            }
            prevailingPriceBase {
              No8
              No10
              No19
              No20
            }
            baseSelected
          }
          isCalculated
        }
        inheritanceTaxInput {
          houseFixedAssetTax
          houseFixedAssetTaxFlg
          fixedAssetTax
          landMagnification
          realEstateName
          realEstateAddress {
            postalCode
            address1
            address2
            address3
          }
          liveFlag
          landNum
          landType
          commonComment
          ownerName
          ownerAddress {
            postalCode
            address1
            address2
            address3
          }
          userName
          userAddress {
            postalCode
            address1
            address2
            address3
          }
          calcMode
          landSize
          landPrice {
            front
            back
            side1
            side2
          }
          propertyTax {
            tax
            areaRate
          }
          frontageDistance
          depthDistance
          useType
          areaType
          irregularLandFlg
          irregularLandFrontageDistance
          irregularLandDepthDistance
          bigLandFlg
          noRoadLandFlg
          noRoadLandSize
          slantedLandFlg
          slantedLandDirection
          slantedLandSize
          multipleFloorAreaRatioFlg
          multipleFloorAreaRatioDeductionRate
          corner {
            side1Corner
            side2Corner
            backCorner
          }
          setBackFlg
          setBackSize
          farmlandFlag
          changedFlag
          preparationSize
          preparationUnitPrice
          loggingSize1
          loggingSize2
          loggingUnitPrice
          stabilizationSize
          stabilizationUnitPrice
          earthworkSize
          earthworkHight
          earthworkUnitPrice
          sheathingLength
          sheathingHight
          sheathingUnitPrice
          slopeAngle
          cityRoadFlg
          cityRoadFactor
          golfFlg
          golfCost
          rentLandRate
          rentHouseRate
          lendRate
          rentHouseFlg
          lendHouseRate
          topographicMap
          topographicMapDataUrl
          memo
          totalPrice
          resultValue {
            calcArray {
              text
              resultPrice
              resultPriceType
            }
            resultPriceType
            resultPrice
          }
          userAssetInput {
            cash
            lifeInsurance
            car
            etc
          }
          isCalculated
          noRoadLandCheckFlg
        }
        realEstateInput {
          chartData
          inputDate
          siteConditions {
            ownerInformation {
              owner {
                familyName
                name
              }
              ownerType
              estimatedEstateValue
              spouse
              numberOfChildren
              numberOfParentsAlive
              siblingsOfTheDeceased
              spouseRealRatio
              childRealRatio
              parentRealRatio
              siblingRealRatio
            }
            siteInformation {
              surfaceArea
              surfaceAreaUnit
              landLocation
              lotSize
              buildingToLandRatio
              floorAreaRatio
              percentageOfLeaseholdInterest
              roadsideLandPrice
              roadsideLandPriceUnit
              propertyTaxAssessment
              presumption
              availableForSale
              currentUse
              currentIncome
              zoningDistrict
              otherAreas
              landPicture
            }
          }
          locationRequirement {
            name
            memo
            traffic
            landUseInTheVicinity
            guideMap
            concreteApplicationImages {
              vacantLandAndLeasedLand
              monthlyParkingLot
              storeOffice
              groupRental
              groupRentalFamily
              detachedHousesForRent
              sellAndCashOut
            }
          }
          overallPlan {
            rentalProperty {
              checked
              monthlyRent
              numberOfFloors
              numberOfHouses
              annualRepairCosts
              numberOfLevels
              personalFunds
              borrowingRate
              borrowingPeriod
            }
            monthlyParkingLot {
              monthlyRent
              numberOfUnits
              personalFunds
              borrowingRate
              borrowingPeriod
              equityRatio
            }
            storeOffice {
              monthlyRent
              totalFlooraSpace
              personalFunds
              borrowingRate
              borrowingPeriod
              equityRatio
            }
            sale {
              salePrice
              purchasePrice
              agencyFees
              transferIncomeTax
              netProceeds
              acquisitionCost
              periodOfOwnership
              termOfTransferIncomeTax
              priceAtTheTimeOfPurchase
              otherTransferCosts
            }
          }
          isCalculated
        }
        comment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      customerNumber
      companyId
      companyName
      bossLastName
      bossFirstName
      tel
      mail
      address {
        postalCode
        address1
        address2
        address3
      }
      qualification
      registrationNum
      companyLogo
      deleteDate
      license {
        id
        period {
          quantity
          unit
        }
        quantity
        amount
        tax
        startDate
        endDate
        paymentId
        isRenewAutomaticaly
        isValid
        initialPurchaseDate
        cancelDate
        cancelReason
      }
      payment {
        id
        payType
        orderId
        recurringId
        purchaseDate
        billingDate
        amount
        tax
        paymentResult
        invoice
        depositRefundInfo {
          status
          amount
          memo
          adjustmentDate
        }
      }
      payType
      bankTransfer {
        orderId
        accessID
        accessPass
        bankCode
        bankName
        branchCode
        branchName
        accountType
        accountNumber
        accountHolderName
      }
      deposit {
        transferDate
        transferName
        transferBankName
        transferBranchName
        transferAmount
      }
      memo
      accountTransfer {
        transactionId
      }
      paymentChangeReservation {
        changeDate
        payType
        reservationDate
      }
      privilegedFlag
      createdAt
      updatedAt
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerNumber
        companyId
        companyName
        bossLastName
        bossFirstName
        tel
        mail
        address {
          postalCode
          address1
          address2
          address3
        }
        qualification
        registrationNum
        companyLogo
        deleteDate
        license {
          id
          period {
            quantity
            unit
          }
          quantity
          amount
          tax
          startDate
          endDate
          paymentId
          isRenewAutomaticaly
          isValid
          initialPurchaseDate
          cancelDate
          cancelReason
        }
        payment {
          id
          payType
          orderId
          recurringId
          purchaseDate
          billingDate
          amount
          tax
          paymentResult
          invoice
          depositRefundInfo {
            status
            amount
            memo
            adjustmentDate
          }
        }
        payType
        bankTransfer {
          orderId
          accessID
          accessPass
          bankCode
          bankName
          branchCode
          branchName
          accountType
          accountNumber
          accountHolderName
        }
        deposit {
          transferDate
          transferName
          transferBankName
          transferBranchName
          transferAmount
        }
        memo
        accountTransfer {
          transactionId
        }
        paymentChangeReservation {
          changeDate
          payType
          reservationDate
        }
        privilegedFlag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyMaster = /* GraphQL */ `
  query GetCompanyMaster(
    $companyId: String!
    $classification: String!
    $classificationDetail: String!
  ) {
    getCompanyMaster(
      companyId: $companyId
      classification: $classification
      classificationDetail: $classificationDetail
    ) {
      companyId
      classification
      classificationDetail
      masterName
      version
      editability {
        subdivision
        editLine {
          permission
          terms
        }
        editItem {
          key
          type
          digits
          terms
        }
      }
      value
      year
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyMasters = /* GraphQL */ `
  query ListCompanyMasters(
    $companyId: String
    $classificationClassificationDetail: ModelcompanyMasterPrimaryCompositeKeyConditionInput
    $filter: ModelcompanyMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyMasters(
      companyId: $companyId
      classificationClassificationDetail: $classificationClassificationDetail
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        companyId
        classification
        classificationDetail
        masterName
        version
        editability {
          subdivision
          editLine {
            permission
            terms
          }
          editItem {
            key
            type
            digits
            terms
          }
        }
        value
        year
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      customerType
      customerName
      department
      lastName
      firstName
      lastNameKana
      firstNameKana
      tel
      phone
      fax
      mail
      gender
      birthday
      comment
      address {
        postalCode
        address1
        address2
        address3
      }
      family {
        lastName
        firstName
        lastNameKana
        firstNameKana
        tel
        phone
        mail
        gender
        birthday
        comment
        familyType
        businessCard
        companyInfo {
          companyName
          position
          tel
          mail
          fax
        }
      }
      businessCard
      companyInfo {
        companyName
        position
        tel
        mail
        fax
      }
      company
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerType
        customerName
        department
        lastName
        firstName
        lastNameKana
        firstNameKana
        tel
        phone
        fax
        mail
        gender
        birthday
        comment
        address {
          postalCode
          address1
          address2
          address3
        }
        family {
          lastName
          firstName
          lastNameKana
          firstNameKana
          tel
          phone
          mail
          gender
          birthday
          comment
          familyType
          businessCard
          companyInfo {
            companyName
            position
            tel
            mail
            fax
          }
        }
        businessCard
        companyInfo {
          companyName
          position
          tel
          mail
          fax
        }
        company
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerNumber = /* GraphQL */ `
  query GetCustomerNumber($prefecturesCode: String!) {
    getCustomerNumber(prefecturesCode: $prefecturesCode) {
      prefecturesCode
      prefecturesName
      serialCode
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerNumbers = /* GraphQL */ `
  query ListCustomerNumbers(
    $prefecturesCode: String
    $filter: ModelCustomerNumberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomerNumbers(
      prefecturesCode: $prefecturesCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        prefecturesCode
        prefecturesName
        serialCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDeleteQue = /* GraphQL */ `
  query GetDeleteQue($id: ID!) {
    getDeleteQue(id: $id) {
      id
      companyName
      bossLastName
      bossFirstName
      tel
      mail
      address {
        postalCode
        address1
        address2
        address3
      }
      qualification
      registrationNum
      companyLogo
      accountNum
      deleteFlg
      createdAt
      updatedAt
    }
  }
`;
export const listDeleteQues = /* GraphQL */ `
  query ListDeleteQues(
    $filter: ModelDeleteQueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeleteQues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        bossLastName
        bossFirstName
        tel
        mail
        address {
          postalCode
          address1
          address2
          address3
        }
        qualification
        registrationNum
        companyLogo
        accountNum
        deleteFlg
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroundFloorPlan = /* GraphQL */ `
  query GetGroundFloorPlan($id: ID!) {
    getGroundFloorPlan(id: $id) {
      id
      companyId
      propertyId
      roomId
      ownerId
      ownerName
      guestIdList
      planeName
      objectSnapshots {
        id
        type
        subType
        config {
          stroke
          strokeWidth
          strokeLinecap
          fill
          patternWidth
          patternOffsetX
          patternOffsetY
          preserveAspectRatio
          imageUrl
          textTranslate {
            x
            y
          }
          areaSizeUnit
          customText
          ratio
          flipHorizontal
          flipVertical
          iconUrl
          layoutName
        }
        vertexes {
          x
          y
        }
        arcPoints
        transform {
          rotate
          translate
          scale
        }
        children
        width
        height
        height3d
        depth
        index
        wallType
        walls {
          id
          type
          subType
          config {
            stroke
            strokeWidth
            strokeLinecap
            fill
            patternWidth
            patternOffsetX
            patternOffsetY
            preserveAspectRatio
            imageUrl
            textTranslate {
              x
              y
            }
            areaSizeUnit
            customText
            ratio
            flipHorizontal
            flipVertical
            iconUrl
            layoutName
          }
          vertexes {
            x
            y
          }
          arcPoints
          transform {
            rotate
            translate
            scale
          }
          children
          width
          height
          height3d
          depth
          index
          wallType
          walls {
            id
            type
            subType
            config {
              stroke
              strokeWidth
              strokeLinecap
              fill
              patternWidth
              patternOffsetX
              patternOffsetY
              preserveAspectRatio
              imageUrl
              textTranslate {
                x
                y
              }
              areaSizeUnit
              customText
              ratio
              flipHorizontal
              flipVertical
              iconUrl
              layoutName
            }
            vertexes {
              x
              y
            }
            arcPoints
            transform {
              rotate
              translate
              scale
            }
            children
            width
            height
            height3d
            depth
            index
            wallType
            walls {
              id
              type
              subType
              config {
                stroke
                strokeWidth
                strokeLinecap
                fill
                patternWidth
                patternOffsetX
                patternOffsetY
                preserveAspectRatio
                imageUrl
                textTranslate {
                  x
                  y
                }
                areaSizeUnit
                customText
                ratio
                flipHorizontal
                flipVertical
                iconUrl
                layoutName
              }
              vertexes {
                x
                y
              }
              arcPoints
              transform {
                rotate
                translate
                scale
              }
              children
              width
              height
              height3d
              depth
              index
              wallType
              walls {
                id
                type
                subType
                config {
                  stroke
                  strokeWidth
                  strokeLinecap
                  fill
                  patternWidth
                  patternOffsetX
                  patternOffsetY
                  preserveAspectRatio
                  imageUrl
                  textTranslate {
                    x
                    y
                  }
                  areaSizeUnit
                  customText
                  ratio
                  flipHorizontal
                  flipVertical
                  iconUrl
                  layoutName
                }
                vertexes {
                  x
                  y
                }
                arcPoints
                transform {
                  rotate
                  translate
                  scale
                }
                children
                width
                height
                height3d
                depth
                index
                wallType
                walls {
                  id
                  type
                  subType
                  config {
                    stroke
                    strokeWidth
                    strokeLinecap
                    fill
                    patternWidth
                    patternOffsetX
                    patternOffsetY
                    preserveAspectRatio
                    imageUrl
                    textTranslate {
                      x
                      y
                    }
                    areaSizeUnit
                    customText
                    ratio
                    flipHorizontal
                    flipVertical
                    iconUrl
                    layoutName
                  }
                  vertexes {
                    x
                    y
                  }
                  arcPoints
                  transform {
                    rotate
                    translate
                    scale
                  }
                  children
                  width
                  height
                  height3d
                  depth
                  index
                  wallType
                  walls {
                    id
                    type
                    subType
                    config {
                      stroke
                      strokeWidth
                      strokeLinecap
                      fill
                      patternWidth
                      patternOffsetX
                      patternOffsetY
                      preserveAspectRatio
                      imageUrl
                      textTranslate {
                        x
                        y
                      }
                      areaSizeUnit
                      customText
                      ratio
                      flipHorizontal
                      flipVertical
                      iconUrl
                      layoutName
                    }
                    vertexes {
                      x
                      y
                    }
                    arcPoints
                    transform {
                      rotate
                      translate
                      scale
                    }
                    children
                    width
                    height
                    height3d
                    depth
                    index
                    wallType
                    walls {
                      id
                      type
                      subType
                      config {
                        stroke
                        strokeWidth
                        strokeLinecap
                        fill
                        patternWidth
                        patternOffsetX
                        patternOffsetY
                        preserveAspectRatio
                        imageUrl
                        areaSizeUnit
                        customText
                        ratio
                        flipHorizontal
                        flipVertical
                        iconUrl
                        layoutName
                      }
                      vertexes {
                        x
                        y
                      }
                      arcPoints
                      transform {
                        rotate
                        translate
                        scale
                      }
                      children
                      width
                      height
                      height3d
                      depth
                      index
                      wallType
                      walls {
                        id
                        type
                        subType
                        arcPoints
                        children
                        width
                        height
                        height3d
                        depth
                        index
                        wallType
                      }
                    }
                    ctrlPoints {
                      start {
                        x
                        y
                      }
                      end {
                        x
                        y
                      }
                    }
                  }
                  ctrlPoints {
                    start {
                      x
                      y
                    }
                    end {
                      x
                      y
                    }
                  }
                }
                ctrlPoints {
                  start {
                    x
                    y
                  }
                  end {
                    x
                    y
                  }
                }
              }
              ctrlPoints {
                start {
                  x
                  y
                }
                end {
                  x
                  y
                }
              }
            }
            ctrlPoints {
              start {
                x
                y
              }
              end {
                x
                y
              }
            }
          }
          ctrlPoints {
            start {
              x
              y
            }
            end {
              x
              y
            }
          }
        }
        ctrlPoints {
          start {
            x
            y
          }
          end {
            x
            y
          }
        }
      }
      editorSetting {
        width
        height
        compass
        rotate
      }
      imageName
      updateDate
      updatePerson
      deleted
      deleteDate
      deletePerson
      output
      outputDate
      outputPerson
      restored
      restoreDate
      restorePerson
      createdAt
      updatedAt
    }
  }
`;
export const listGroundFloorPlans = /* GraphQL */ `
  query ListGroundFloorPlans(
    $filter: ModelGroundFloorPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroundFloorPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        propertyId
        roomId
        ownerId
        ownerName
        guestIdList
        planeName
        objectSnapshots {
          id
          type
          subType
          config {
            stroke
            strokeWidth
            strokeLinecap
            fill
            patternWidth
            patternOffsetX
            patternOffsetY
            preserveAspectRatio
            imageUrl
            textTranslate {
              x
              y
            }
            areaSizeUnit
            customText
            ratio
            flipHorizontal
            flipVertical
            iconUrl
            layoutName
          }
          vertexes {
            x
            y
          }
          arcPoints
          transform {
            rotate
            translate
            scale
          }
          children
          width
          height
          height3d
          depth
          index
          wallType
          walls {
            id
            type
            subType
            config {
              stroke
              strokeWidth
              strokeLinecap
              fill
              patternWidth
              patternOffsetX
              patternOffsetY
              preserveAspectRatio
              imageUrl
              textTranslate {
                x
                y
              }
              areaSizeUnit
              customText
              ratio
              flipHorizontal
              flipVertical
              iconUrl
              layoutName
            }
            vertexes {
              x
              y
            }
            arcPoints
            transform {
              rotate
              translate
              scale
            }
            children
            width
            height
            height3d
            depth
            index
            wallType
            walls {
              id
              type
              subType
              config {
                stroke
                strokeWidth
                strokeLinecap
                fill
                patternWidth
                patternOffsetX
                patternOffsetY
                preserveAspectRatio
                imageUrl
                textTranslate {
                  x
                  y
                }
                areaSizeUnit
                customText
                ratio
                flipHorizontal
                flipVertical
                iconUrl
                layoutName
              }
              vertexes {
                x
                y
              }
              arcPoints
              transform {
                rotate
                translate
                scale
              }
              children
              width
              height
              height3d
              depth
              index
              wallType
              walls {
                id
                type
                subType
                config {
                  stroke
                  strokeWidth
                  strokeLinecap
                  fill
                  patternWidth
                  patternOffsetX
                  patternOffsetY
                  preserveAspectRatio
                  imageUrl
                  textTranslate {
                    x
                    y
                  }
                  areaSizeUnit
                  customText
                  ratio
                  flipHorizontal
                  flipVertical
                  iconUrl
                  layoutName
                }
                vertexes {
                  x
                  y
                }
                arcPoints
                transform {
                  rotate
                  translate
                  scale
                }
                children
                width
                height
                height3d
                depth
                index
                wallType
                walls {
                  id
                  type
                  subType
                  config {
                    stroke
                    strokeWidth
                    strokeLinecap
                    fill
                    patternWidth
                    patternOffsetX
                    patternOffsetY
                    preserveAspectRatio
                    imageUrl
                    textTranslate {
                      x
                      y
                    }
                    areaSizeUnit
                    customText
                    ratio
                    flipHorizontal
                    flipVertical
                    iconUrl
                    layoutName
                  }
                  vertexes {
                    x
                    y
                  }
                  arcPoints
                  transform {
                    rotate
                    translate
                    scale
                  }
                  children
                  width
                  height
                  height3d
                  depth
                  index
                  wallType
                  walls {
                    id
                    type
                    subType
                    config {
                      stroke
                      strokeWidth
                      strokeLinecap
                      fill
                      patternWidth
                      patternOffsetX
                      patternOffsetY
                      preserveAspectRatio
                      imageUrl
                      textTranslate {
                        x
                        y
                      }
                      areaSizeUnit
                      customText
                      ratio
                      flipHorizontal
                      flipVertical
                      iconUrl
                      layoutName
                    }
                    vertexes {
                      x
                      y
                    }
                    arcPoints
                    transform {
                      rotate
                      translate
                      scale
                    }
                    children
                    width
                    height
                    height3d
                    depth
                    index
                    wallType
                    walls {
                      id
                      type
                      subType
                      config {
                        stroke
                        strokeWidth
                        strokeLinecap
                        fill
                        patternWidth
                        patternOffsetX
                        patternOffsetY
                        preserveAspectRatio
                        imageUrl
                        areaSizeUnit
                        customText
                        ratio
                        flipHorizontal
                        flipVertical
                        iconUrl
                        layoutName
                      }
                      vertexes {
                        x
                        y
                      }
                      arcPoints
                      transform {
                        rotate
                        translate
                        scale
                      }
                      children
                      width
                      height
                      height3d
                      depth
                      index
                      wallType
                      walls {
                        id
                        type
                        subType
                        arcPoints
                        children
                        width
                        height
                        height3d
                        depth
                        index
                        wallType
                      }
                    }
                    ctrlPoints {
                      start {
                        x
                        y
                      }
                      end {
                        x
                        y
                      }
                    }
                  }
                  ctrlPoints {
                    start {
                      x
                      y
                    }
                    end {
                      x
                      y
                    }
                  }
                }
                ctrlPoints {
                  start {
                    x
                    y
                  }
                  end {
                    x
                    y
                  }
                }
              }
              ctrlPoints {
                start {
                  x
                  y
                }
                end {
                  x
                  y
                }
              }
            }
            ctrlPoints {
              start {
                x
                y
              }
              end {
                x
                y
              }
            }
          }
          ctrlPoints {
            start {
              x
              y
            }
            end {
              x
              y
            }
          }
        }
        editorSetting {
          width
          height
          compass
          rotate
        }
        imageName
        updateDate
        updatePerson
        deleted
        deleteDate
        deletePerson
        output
        outputDate
        outputPerson
        restored
        restoreDate
        restorePerson
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvestmentAnalytics = /* GraphQL */ `
  query GetInvestmentAnalytics($id: ID!) {
    getInvestmentAnalytics(id: $id) {
      id
      inputDate
      existingBuildingCount
      newBuildingCount
      initialInvestment {
        propertyDescription {
          location
          address {
            postalCode
            address1
            address2
            address3
          }
          quantity
          structure
          kind
          purpose
          area
          dateOfConstruction
          price
        }
        tradingPrice
        totalTradingPrice
        propertyTaxValuation
        propertyTax
        cityPlanningTax
        propertyTaxRate
        cityPlanningTaxRate
        constructionPlan {
          selected
          newConstructionCost
          repairWorkCost
          equipmentUpdateCost
          repairWorkCostDetails {
            content
            quantity
            unitPrice
            amount
          }
          equipmentUpdateCostDetails {
            content
            quantity
            unitPrice
            amount
          }
          constructionCost {
            content
            quantity
            unitPrice
            amount
          }
          constructionInciDentalWork
          otherConstructionFee
          constructionReserves
          demolitionCount
        }
        incidentalExpenses
        consultingCost {
          content
          quantity
          unitPrice
          amount
        }
        otherConsultingCost
        otherCostDetails {
          content
          quantity
          unitPrice
          amount
        }
        estimatedConsultingCost
        isDetailConsultingCost
        designCost {
          content
          quantity
          unitPrice
          amount
        }
        estimatedDesignCost
        isDetailDesignCost
        surveyCost {
          content
          quantity
          unitPrice
          amount
        }
        estimatedSurveyCost
        isDetailSurveyCost
        licensingCost {
          content
          quantity
          unitPrice
          amount
        }
        estimatedLicensingCost
        isDetailLicensingCost
        contributionsAndDeposits {
          content
          quantity
          unitPrice
          amount
        }
        estimatedContributions
        estimatedDeposits
        isDetailContributionsAndDeposits
        stampDuty
        isChangedStampDuty
        estimatedStampDuty
        isDetailStampDuty
        realEstateAcquisitionTax
        isChangedRealEstateAcquisitionTax
        registrationTax {
          causeOfRegistration
          isChanged
          amount
          numberOfAdditionalMortgages
          numberOfRegisteredRecordsSurveyed
        }
        paymentFees {
          numberOfCases
          buildingRepresentationRegistration {
            content
            quantity
            unitPrice
            amount
          }
          leaseBrokerageFee
          bankGuarantee
          estimatedPaymentFee
          isDetail
          isChanged
          amount
        }
        preCareerCommitment {
          monthlyRent
          affordability
          duration
          monthlyOccupancyFee
          interestBeforeStartingBusiness
          isChanged
          borrowingRate
          approximateValue
          isDetail
        }
        insuranceFees
        otherExpenses {
          ritualCost
          neighborhoodWatchCosts {
            content
            quantity
            unitPrice
            amount
          }
          evictionFeesQuantity
          estimatedEvictionFees
          businessCompensation {
            content
            quantity
            unitPrice
            amount
          }
          estimatedBusinessCompensation
          advertisingExpensesDetails {
            content
            quantity
            unitPrice
            amount
          }
          advertisingExpenses
          specialExpenses {
            content
            quantity
            unitPrice
            amount
          }
          estimatedSpecialExpenses
          miscellaneousExpenses
          reserveRate
          estimatedReserveFund
          estimatedOtherExpenses
          isDetail
        }
        capitalPlan {
          deposit
          constructionCooperationFund
          securityDeposit
          keyMoney
          ownResources
          loans
          borrowedAmount
          adjustment
          borrowingRateApplicationYears
          borrowingRate
          repaymentPeriod
          repaymentMethod
          redemptionPeriod
        }
        memo
      }
      inputTitle
      reportName
      ownerName
      ownerId
      staffName
      singleYearPlan {
        incomePlan {
          rent {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          commonServiceFees {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          parkingFees {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          landRentalFees {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          others {
            taxationNumber
            taxExemptNumber
            taxation
            taxExempt
          }
          itemNames
        }
        spendingPlan {
          monthlyRent
          expenditureItems {
            content
            quantity
            unitPrice
            amount
          }
          managementFeeType
          managementFeeValue
          numberOfRooms
          reserveRate
          buildingMaintenance {
            content
            quantity
            unitPrice
            amount
          }
          otherUnitPrice
          isDetail
          approximateValue
        }
      }
      longTermRepairPlan {
        majorRenovation {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        equipmentRenewal {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        externalStructure {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        others {
          itemName
          implementationTime
          quantity
          unitPrice
          isChanged
          amount
        }
        isApproximation
        approximations {
          name
          frequency
          cost
        }
      }
      salePlan {
        timeOfSale
        breakdownOfSalePrice {
          land
          building
          buildingDetail
          depreciatedAssets
          assumedLandPriceIncrease
        }
        expensesAtTheTimeOfSale {
          demolition {
            total
            details {
              content
              quantity
              unitPrice
              amount
            }
            isDetail
          }
          repairAndValueUpWork {
            total
            details {
              content
              quantity
              unitPrice
              amount
            }
            isDetail
          }
          surveyingCosts {
            content
            quantity
            unitPrice
            amount
          }
          soilContaminationSurvey
          asbestosUseStudy
          equipmentFunctionSurvey
          inspection
          otherSurveys
          reserveRate
        }
        registrationFees
        evictionFees
        businessCompensation {
          content
          quantity
          unitPrice
          amount
        }
        contractCosts
        paymentFees
        consultingFees {
          content
          quantity
          unitPrice
          amount
        }
        salesAdvertisingCosts {
          content
          quantity
          unitPrice
          amount
        }
        others {
          content
          quantity
          unitPrice
          amount
        }
        governingBody
        capital
      }
      propertyData {
        id
        roomId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInvestmentAnalyticss = /* GraphQL */ `
  query ListInvestmentAnalyticss(
    $filter: ModelInvestmentAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvestmentAnalyticss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inputDate
        existingBuildingCount
        newBuildingCount
        initialInvestment {
          propertyDescription {
            location
            address {
              postalCode
              address1
              address2
              address3
            }
            quantity
            structure
            kind
            purpose
            area
            dateOfConstruction
            price
          }
          tradingPrice
          totalTradingPrice
          propertyTaxValuation
          propertyTax
          cityPlanningTax
          propertyTaxRate
          cityPlanningTaxRate
          constructionPlan {
            selected
            newConstructionCost
            repairWorkCost
            equipmentUpdateCost
            repairWorkCostDetails {
              content
              quantity
              unitPrice
              amount
            }
            equipmentUpdateCostDetails {
              content
              quantity
              unitPrice
              amount
            }
            constructionCost {
              content
              quantity
              unitPrice
              amount
            }
            constructionInciDentalWork
            otherConstructionFee
            constructionReserves
            demolitionCount
          }
          incidentalExpenses
          consultingCost {
            content
            quantity
            unitPrice
            amount
          }
          otherConsultingCost
          otherCostDetails {
            content
            quantity
            unitPrice
            amount
          }
          estimatedConsultingCost
          isDetailConsultingCost
          designCost {
            content
            quantity
            unitPrice
            amount
          }
          estimatedDesignCost
          isDetailDesignCost
          surveyCost {
            content
            quantity
            unitPrice
            amount
          }
          estimatedSurveyCost
          isDetailSurveyCost
          licensingCost {
            content
            quantity
            unitPrice
            amount
          }
          estimatedLicensingCost
          isDetailLicensingCost
          contributionsAndDeposits {
            content
            quantity
            unitPrice
            amount
          }
          estimatedContributions
          estimatedDeposits
          isDetailContributionsAndDeposits
          stampDuty
          isChangedStampDuty
          estimatedStampDuty
          isDetailStampDuty
          realEstateAcquisitionTax
          isChangedRealEstateAcquisitionTax
          registrationTax {
            causeOfRegistration
            isChanged
            amount
            numberOfAdditionalMortgages
            numberOfRegisteredRecordsSurveyed
          }
          paymentFees {
            numberOfCases
            buildingRepresentationRegistration {
              content
              quantity
              unitPrice
              amount
            }
            leaseBrokerageFee
            bankGuarantee
            estimatedPaymentFee
            isDetail
            isChanged
            amount
          }
          preCareerCommitment {
            monthlyRent
            affordability
            duration
            monthlyOccupancyFee
            interestBeforeStartingBusiness
            isChanged
            borrowingRate
            approximateValue
            isDetail
          }
          insuranceFees
          otherExpenses {
            ritualCost
            neighborhoodWatchCosts {
              content
              quantity
              unitPrice
              amount
            }
            evictionFeesQuantity
            estimatedEvictionFees
            businessCompensation {
              content
              quantity
              unitPrice
              amount
            }
            estimatedBusinessCompensation
            advertisingExpensesDetails {
              content
              quantity
              unitPrice
              amount
            }
            advertisingExpenses
            specialExpenses {
              content
              quantity
              unitPrice
              amount
            }
            estimatedSpecialExpenses
            miscellaneousExpenses
            reserveRate
            estimatedReserveFund
            estimatedOtherExpenses
            isDetail
          }
          capitalPlan {
            deposit
            constructionCooperationFund
            securityDeposit
            keyMoney
            ownResources
            loans
            borrowedAmount
            adjustment
            borrowingRateApplicationYears
            borrowingRate
            repaymentPeriod
            repaymentMethod
            redemptionPeriod
          }
          memo
        }
        inputTitle
        reportName
        ownerName
        ownerId
        staffName
        singleYearPlan {
          incomePlan {
            rent {
              taxationNumber
              taxExemptNumber
              taxation
              taxExempt
            }
            commonServiceFees {
              taxationNumber
              taxExemptNumber
              taxation
              taxExempt
            }
            parkingFees {
              taxationNumber
              taxExemptNumber
              taxation
              taxExempt
            }
            landRentalFees {
              taxationNumber
              taxExemptNumber
              taxation
              taxExempt
            }
            others {
              taxationNumber
              taxExemptNumber
              taxation
              taxExempt
            }
            itemNames
          }
          spendingPlan {
            monthlyRent
            expenditureItems {
              content
              quantity
              unitPrice
              amount
            }
            managementFeeType
            managementFeeValue
            numberOfRooms
            reserveRate
            buildingMaintenance {
              content
              quantity
              unitPrice
              amount
            }
            otherUnitPrice
            isDetail
            approximateValue
          }
        }
        longTermRepairPlan {
          majorRenovation {
            itemName
            implementationTime
            quantity
            unitPrice
            isChanged
            amount
          }
          equipmentRenewal {
            itemName
            implementationTime
            quantity
            unitPrice
            isChanged
            amount
          }
          externalStructure {
            itemName
            implementationTime
            quantity
            unitPrice
            isChanged
            amount
          }
          others {
            itemName
            implementationTime
            quantity
            unitPrice
            isChanged
            amount
          }
          isApproximation
          approximations {
            name
            frequency
            cost
          }
        }
        salePlan {
          timeOfSale
          breakdownOfSalePrice {
            land
            building
            buildingDetail
            depreciatedAssets
            assumedLandPriceIncrease
          }
          expensesAtTheTimeOfSale {
            demolition {
              total
              details {
                content
                quantity
                unitPrice
                amount
              }
              isDetail
            }
            repairAndValueUpWork {
              total
              details {
                content
                quantity
                unitPrice
                amount
              }
              isDetail
            }
            surveyingCosts {
              content
              quantity
              unitPrice
              amount
            }
            soilContaminationSurvey
            asbestosUseStudy
            equipmentFunctionSurvey
            inspection
            otherSurveys
            reserveRate
          }
          registrationFees
          evictionFees
          businessCompensation {
            content
            quantity
            unitPrice
            amount
          }
          contractCosts
          paymentFees
          consultingFees {
            content
            quantity
            unitPrice
            amount
          }
          salesAdvertisingCosts {
            content
            quantity
            unitPrice
            amount
          }
          others {
            content
            quantity
            unitPrice
            amount
          }
          governingBody
          capital
        }
        propertyData {
          id
          roomId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhotoSimulator = /* GraphQL */ `
  query GetPhotoSimulator($id: ID!) {
    getPhotoSimulator(id: $id) {
      company
      propertyId
      roomId
      id
      simInfo {
        baseImageFileName
        baseImageDisplayName
        savePeten {
          id
          order
          saveName
          layersInfo {
            id
            layerDisplayName
            layerImageFileName
            material
            materialScale
            color
            layerColor
            transparent
            brightness
            saturation
            PerspectiveData {
              name
              x
              y
            }
          }
        }
        selectPattern
        simCreateDate
        simUpdateDate
      }
      quickSimulationFlag
      createdAt
      updatedAt
    }
  }
`;
export const listPhotoSimulators = /* GraphQL */ `
  query ListPhotoSimulators(
    $filter: ModelPhotoSimulatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotoSimulators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        company
        propertyId
        roomId
        id
        simInfo {
          baseImageFileName
          baseImageDisplayName
          savePeten {
            id
            order
            saveName
            layersInfo {
              id
              layerDisplayName
              layerImageFileName
              material
              materialScale
              color
              layerColor
              transparent
              brightness
              saturation
              PerspectiveData {
                name
                x
                y
              }
            }
          }
          selectPattern
          simCreateDate
          simUpdateDate
        }
        quickSimulationFlag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlaneView = /* GraphQL */ `
  query GetPlaneView($id: ID!) {
    getPlaneView(id: $id) {
      id
      companyId
      propertyId
      roomId
      ownerId
      ownerName
      guestIdList
      planeName
      patterns {
        name
        layers {
          id
          type
          subType
          style {
            stroke
            spaceName
            strokeWidth
            strokeLinecap
            strokeDasharray
            fill
            patternWidth
            patternOffsetX
            patternOffsetY
            patternRotate
            patternOpacity
            imageName
            imageUrl
            ratio
            flipHorizontal
            flipVertical
            fontSize
            textAnchor
          }
          vertexes {
            x
            y
          }
          arcPoints
          transform {
            rotate
            translate {
              x
              y
            }
            scale {
              x
              y
            }
            origin {
              x
              y
            }
          }
          firstChildId
          rightBrotherId
          width
          height
          height3d
          mountingHeight
          index
          code
          additionalInfo {
            open
            knob
            sliding
            isPointInArea
            stringers
            steps
            stepAngle
            stepHeight
          }
          alias
          areaSizeUnit
          text
          showAreaSize
          originalWidth
          originalHeight
          modelPath
        }
      }
      imageName
      azimuth
      updateDate
      updatePerson
      deleted
      deleteDate
      deletePerson
      output
      outputDate
      outputPerson
      restored
      restoreDate
      restorePerson
      createdAt
      updatedAt
    }
  }
`;
export const listPlaneViews = /* GraphQL */ `
  query ListPlaneViews(
    $filter: ModelPlaneViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaneViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        propertyId
        roomId
        ownerId
        ownerName
        guestIdList
        planeName
        patterns {
          name
          layers {
            id
            type
            subType
            style {
              stroke
              spaceName
              strokeWidth
              strokeLinecap
              strokeDasharray
              fill
              patternWidth
              patternOffsetX
              patternOffsetY
              patternRotate
              patternOpacity
              imageName
              imageUrl
              ratio
              flipHorizontal
              flipVertical
              fontSize
              textAnchor
            }
            vertexes {
              x
              y
            }
            arcPoints
            transform {
              rotate
              translate {
                x
                y
              }
              scale {
                x
                y
              }
              origin {
                x
                y
              }
            }
            firstChildId
            rightBrotherId
            width
            height
            height3d
            mountingHeight
            index
            code
            additionalInfo {
              open
              knob
              sliding
              isPointInArea
              stringers
              steps
              stepAngle
              stepHeight
            }
            alias
            areaSizeUnit
            text
            showAreaSize
            originalWidth
            originalHeight
            modelPath
          }
        }
        imageName
        azimuth
        updateDate
        updatePerson
        deleted
        deleteDate
        deletePerson
        output
        outputDate
        outputPerson
        restored
        restoreDate
        restorePerson
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProperty = /* GraphQL */ `
  query GetProperty($id: ID!) {
    getProperty(id: $id) {
      company
      id
      commonValue {
        propertyName
        roomNumber
        address {
          postalCode
          address1
          address2
          address3
        }
        owner
        memo
        quickSimLogTime
        staffName
      }
      roomInfo {
        id
        roomName
        simulationLog {
          simId
          fileUrlName
          fileName
          action
          userName
          userMail
          updateDate
        }
      }
      simulationLog {
        simId
        fileUrlName
        fileName
        action
        userName
        userMail
        updateDate
      }
      planeViewData {
        newestImageName
        updateDate
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPropertys = /* GraphQL */ `
  query ListPropertys(
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        company
        id
        commonValue {
          propertyName
          roomNumber
          address {
            postalCode
            address1
            address2
            address3
          }
          owner
          memo
          quickSimLogTime
          staffName
        }
        roomInfo {
          id
          roomName
          simulationLog {
            simId
            fileUrlName
            fileName
            action
            userName
            userMail
            updateDate
          }
        }
        simulationLog {
          simId
          fileUrlName
          fileName
          action
          userName
          userMail
          updateDate
        }
        planeViewData {
          newestImageName
          updateDate
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReportList = /* GraphQL */ `
  query GetReportList($id: ID!) {
    getReportList(id: $id) {
      id
      company
      fileTitle
      fileName
      status
      mail
      customerId
      customerName
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listReportLists = /* GraphQL */ `
  query ListReportLists(
    $filter: ModelreportListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company
        fileTitle
        fileName
        status
        mail
        customerId
        customerName
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSystemMaster = /* GraphQL */ `
  query GetSystemMaster(
    $classification: String!
    $classificationDetail: String!
  ) {
    getSystemMaster(
      classification: $classification
      classificationDetail: $classificationDetail
    ) {
      classification
      classificationDetail
      masterName
      version
      editability {
        subdivision
        editLine {
          permission
          terms
        }
        editItem {
          key
          type
          digits
          terms
        }
      }
      year
      value
      createdAt
      updatedAt
    }
  }
`;
export const listSystemMasters = /* GraphQL */ `
  query ListSystemMasters(
    $classification: String
    $classificationDetail: ModelStringKeyConditionInput
    $filter: ModelsystemMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSystemMasters(
      classification: $classification
      classificationDetail: $classificationDetail
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        classification
        classificationDetail
        masterName
        version
        editability {
          subdivision
          editLine {
            permission
            terms
          }
          editItem {
            key
            type
            digits
            terms
          }
        }
        year
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
