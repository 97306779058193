<!--
作成者：本間
作成日：2021.02.05
画面名：投資分析/出力
機能：売却益
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <b-tab id="GainOnSale" title="売却益">
    <b-card class="card-result" :title="Math.floor(investmentAnalytics.salePlan.timeOfSale) + '年後の売却益'">
      <b-container class="report">
        <b-row>
          <b-col class="text-right">
            単位：円
          </b-col>
        </b-row>
        <b-row>
          <b-col class="title emboss">項目1</b-col>
          <b-col class="title emboss">項目2</b-col>
          <b-col class="title emboss">項目3</b-col>
          <b-col class="title emboss" cols="1">数量</b-col>
          <b-col class="title emboss" cols="1">単位</b-col>
          <b-col class="title emboss">金額</b-col>
          <b-col class="title emboss">摘要</b-col>
        </b-row>
        <b-row
          class="title"
          style="height:2px;border-top:1px solid black;border-left:1px solid black;border-right:1px solid black;
          background-color:"
        />
        <!-- 収入の部 -->
        <span v-for="(item1, index1) in result.income.items" v-bind:key="`income_${index1}`">
          <span v-if="item1.items == undefined">
            <b-row>
              <b-col class="subtitle" v-bind:class="{ rowspan: index1 > 0 }">
                {{ index1 == 0 ? result.income.name : '' }}
              </b-col>
              <b-col class="subtitle">{{ item1.name }}</b-col>
              <b-col class="subtitle colspan" />
              <b-col class="value" cols="1">{{ item1.quantity | currency }}</b-col>
              <b-col class="unit" cols="1">{{ item1.unit }}</b-col>
              <b-col class="value">{{ item1.amount | currency }}</b-col>
              <b-col>{{ item1.summary }}</b-col>
            </b-row>
          </span>
          <span v-else>
            <b-row
              v-for="(item2, index2) in item1.items"
              v-bind:key="`income_${index1}__${index2}`"
            >
              <b-col class="subtitle" v-bind:class="{ rowspan: index1 > 0 || index2 > 0 }">
                {{ index1 == 0 && index2 == 0 ? result.income.name : '' }}
              </b-col>
              <b-col class="subtitle" v-bind:class="{ rowspan: index2 > 0 }">
                {{ index2 == 0 ? item1.name : '' }}
              </b-col>
              <b-col class="subtitle">{{ item2.name }}</b-col>
              <b-col class="value" cols="1">{{ item2.amount > 0 ? item2.quantity : '' }}</b-col>
              <b-col class="unit" cols="1">{{ item2.amount > 0 ? item2.unit : '' }}</b-col>
              <b-col class="value" v-if="item2.amount > 0">{{
                parseInt(item2.amount) | currency
              }}</b-col>
              <b-col class="value" v-else></b-col>
              <b-col>{{ item2.amount > 0 ? item2.summary : '' }}</b-col>
            </b-row>
          </span>
        </span>
        <b-row>
          <b-col class="title text-right emboss" cols="8">収入の部&nbsp;合計</b-col>
          <b-col class="title text-right">{{ result.income.total | currency }}</b-col>
          <b-col class="title text-left"></b-col>
        </b-row>
        <b-row
          class="title"
          style="height:2px;border-top:1px solid black;border-left:1px solid black;border-right:1px solid black;
          background-color:"
        />
        <!-- 支出の部 -->
        <span v-for="(item1, index1) in result.spending.items" v-bind:key="`spending_${index1}`">
          <span v-if="item1.items == undefined">
            <b-row>
              <b-col class="subtitle" v-bind:class="{ rowspan: index1 > 0 }">
                {{ index1 == 0 ? result.spending.name : null }}
              </b-col>
              <b-col class="subtitle">{{ item1.name }}</b-col>
              <b-col class="subtitle colspan" />
              <b-col class="value" cols="1">{{ item1.quantity | currency }}</b-col>
              <b-col class="unit" cols="1">{{ item1.unit }}</b-col>
              <b-col class="value">{{ item1.amount | currency }}</b-col>
              <b-col>{{ item1.summary }}</b-col>
            </b-row>
          </span>
          <span v-else>
            <b-row
              v-for="(item2, index2) in item1.items"
              v-bind:key="`spending_${index1}__${index2}`"
            >
              <b-col class="subtitle" v-bind:class="{ rowspan: index1 > 0 || index2 > 0 }">
                {{ index1 == 0 && index2 == 0 ? result.spending.name : null }}
              </b-col>
              <b-col class="subtitle" v-bind:class="{ rowspan: index2 > 0 }">
                {{ index2 == 0 ? item1.name : '' }}
              </b-col>
              <b-col class="subtitle">{{ item2.name }}</b-col>
              <b-col class="value" cols="1">{{ item2.amount > 0 ? item2.quantity : '' }}</b-col>
              <b-col class="unit" cols="1">{{ item2.amount > 0 ? item2.unit : '' }}</b-col>
              <b-col class="value" v-if="item2.amount > 0">{{
                parseInt(item2.amount) | currency
              }}</b-col>
              <b-col class="value" v-else></b-col>
              <b-col>{{ item2.amount > 0 ? item2.summary : '' }}</b-col>
            </b-row>
            <!-- 小計 -->
            <b-row>
              <b-col class="subtitle" style="border-top-color:transparent;"> </b-col>
              <b-col class="subtitle" style="border-top-color:transparent;"> </b-col>
              <b-col class="subtitle" style="border-left-color:transparent;"> </b-col>
              <b-col class="subtitle" style="border-left-color:transparent;" cols="1" />
              <b-col class="subtitle text-right" style="border-left-color:transparent;" cols="1">
                小計
              </b-col>
              <b-col class="subtitle text-right">{{ item1.total | currency }}</b-col>
              <b-col class="subtitle">{{ item1.summary }}</b-col>
            </b-row>
          </span>
        </span>
        <b-row>
          <b-col class="title text-right emboss" cols="8">支出の部&nbsp;合計</b-col>
          <b-col class="title text-right">{{ result.spending.total | currency }}</b-col>
          <b-col class="title" />
        </b-row>

        <b-row
          class="title"
          style="height:2px;border-top:1px solid black;border-left:1px solid black;border-right:1px solid black;
          background-color:"
        />
        <b-row>
          <b-col class="title text-right emboss" cols="8">収支</b-col>
          <b-col class="title text-right">{{ result.total | currency }}</b-col>
          <b-col class="title" />
        </b-row>
        <b-row>
          <b-col class="title text-right emboss" cols="8">
            譲渡税(譲渡所得税/法人税、住民税、事業税)
          </b-col>
          <b-col class="title text-right">{{ result.transferTax | currency }}</b-col>
          <b-col class="title" />
        </b-row>
        <b-row>
          <b-col class="title text-right emboss " cols="8">売却益(税引後)</b-col>
          <b-col class="title text-right"> {{ result.gainOnSaleAfterTax | currency }}</b-col>
          <b-col class="title" />
        </b-row>
      </b-container>
    </b-card>
  </b-tab>
</template>
<script>
  import Calculation from '../Input/Calculation';
  export default {
    props: ['model'],
    mixins: [Calculation],
    data: function() {
      return { result: null };
    },
    created: function() {
      this.result = this.initialize();
    },
    mounted: function() {},
    methods: {
      // 入力チェック
      isEmpty: function(value) {
        return value == null || value == '' || value == undefined || Number.isNaN(value)
          ? true
          : false;
      },
      // 初期化
      initialize: function() {
        // 各項目計算
        let items = {
          // 収入の部
          income: {
            name: '収入の部',
            items: null,
          },
          // 支出の部
          spending: {
            name: '支出の部',
            items: null,
          },
          total: null, // 収支
          transferTax: null, // 譲渡税（譲渡所得税/法人税，住民税，事業税）
          gainOnSaleAfterTax: null, // 売却益（税引後）
        };
        // [収入の部]計算メイン
        items.income.items = this.calcIncome();
        // [収入の部]合計算出
        items.income.total = items.income.items.reduce((a, v) => {
          return a + (isNaN(v.amount) ? v.total : v.amount);
        }, 0);
        // [支出の部]計算メイン
        items.spending.items = this.calcSpending(items.income);
        // [支出の部]合計算出
        items.spending.total = items.spending.items.reduce((a, v) => {
          return a + (isNaN(v.amount) ? v.total : v.amount);
        }, 0);
        // 収支
        items.total = items.income.total - items.spending.total;
        // 譲渡税（譲渡所得税/法人税，住民税，事業税）
        items.transferTax = this.transferTax();
        // 売却益（税引後）
        items.gainOnSaleAfterTax = items.total - items.transferTax;
        // グローバル変数に保持(収支計画書で参照)
        this.investmentAnalyticsTemp.gainOnSaleAfterTax = items.gainOnSaleAfterTax;
        // [debug]計算結果
        //console.debug('[売却益]');
        //console.debug(JSON.stringify(items));
        return items;
      },
      // 計算(収入の部)
      calcIncome: function() {
        let result = [];
        let items = [];
        const sp = this.investmentAnalytics.salePlan.breakdownOfSalePrice;
        // 土地
        items.push({
          name: '土地',
          quantity: 1,
          unit: '式',
          amount: Math.floor(Number(sp.land)),
          summary: null,
        });
        // 建物
        items.push({
          name: '建物',
          quantity: 1,
          unit: '式',
          amount: Math.floor(Number(sp.building)),
          summary: null,
        });
        // 付属建物
        items.push({
          name: '付属建物',
          quantity: 1,
          unit: '式',
          amount: Math.floor(Number(sp.depreciatedAssets)),
          summary: null,
        });
        // 消費税
        {
          let tax = this.tax(
            Math.floor(Number(sp.building)) + Math.floor(Number(sp.depreciatedAssets))
          );
          if (tax > 0) {
            items.push({
              name: '消費税',
              quantity: this.getTax('消費税率'),
              unit: '％',
              amount: tax,
              summary: null,
            });
          }
        }
        // 本体価格
        result.push({
          name: '本体価格',
          items: items,
          total: items.reduce((a, v) => {
            return a + v.amount;
          }, 0),
        });
        return result;
      },
      // 計算(支出の部)
      calcSpending: function(income) {
        let result = [];
        // 解体費
        {
          let total = this.demolitionTotal;
          if (total > 0) {
            let items = [];
            // 解体工事
            items.push({
              name: '解体工事',
              quantity: 1,
              unit: '式',
              amount: total,
              summary: null,
            });
            // 消費税
            items.push({
              name: '消費税',
              quantity: this.getTax('消費税率'),
              unit: '％',
              amount: this.tax(total),
              summary: null,
            });
            // 解体費
            result.push({
              name: '解体費',
              items: items,
              total: this.sumTax(total),
            });
          }
        }
        // 修繕・Valueup工事費
        // 概算入力時
        if (
          this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.repairAndValueUpWork.isDetail ==
          0
        ) {
          let total = Math.floor(
            Number(
              this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.repairAndValueUpWork.total
            )
          );
          if (total > 0) {
            let items = [];
            // 工事費合計
            items.push({
              name: '工事費合計',
              quantity: 1,
              unit: '式',
              amount: total,
              summary: null,
            });
            // 消費税
            items.push({
              name: '消費税',
              quantity: this.getTax('消費税率'),
              unit: '％',
              amount: this.tax(total),
              summary: null,
            });
            // 修繕・Valueup工事費
            result.push({
              name: '修繕・Valueup工事費',
              items: items,
              total: this.sumTax(total),
            });
          }
        }
        // 詳細入力時
        else {
          let items = [];
          // 修繕工事
          items.push({
            name: '修繕工事',
            quantity: 1,
            unit: '式',
            amount: Math.floor(
              Number(
                this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.repairAndValueUpWork
                  .details[0].amount
              )
            ),
            summary: null,
          });
          // リニューアル工事
          items.push({
            name: 'リニューアル工事',
            quantity: 1,
            unit: '式',
            amount: Math.floor(
              Number(
                this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.repairAndValueUpWork
                  .details[1].amount
              )
            ),
            summary: null,
          });
          // 設備更新工事
          items.push({
            name: '設備更新工事',
            quantity: 1,
            unit: '式',
            amount: Math.floor(
              Number(
                this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.repairAndValueUpWork
                  .details[2].amount
              )
            ),
            summary: null,
          });
          // ここまでの合計
          let total = items.reduce((a, v) => {
            return a + v.amount;
          }, 0);
          // 消費税
          items.push({
            name: '消費税',
            quantity: this.getTax('消費税率'),
            unit: '％',
            amount: this.tax(total),
            summary: null,
          });
          // 修繕・Valueup工事費
          result.push({
            name: '修繕・Valueup工事費',
            items: items,
            total: this.sumTax(total),
          });
        }
        // 測量費
        {
          let items = [];
          let total = Math.floor(
            Number(this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.surveyingCosts.amount)
          );
          // 確定測量
          items.push({
            name: '確定測量',
            quantity: 1,
            unit: '式',
            amount: total,
            summary: null,
          });
          // 消費税
          items.push({
            name: '消費税',
            quantity: this.getTax('消費税率'),
            unit: '％',
            amount: this.tax(total),
            summary: null,
          });
          // 測量費
          result.push({
            name: '測量費',
            items: items,
            total: this.sumTax(total),
          });
        }
        // 調査費
        {
          let items = [];
          // 土壌汚染調査
          items.push({
            name: '土壌汚染調査',
            quantity: 1,
            unit: '式',
            amount: this.getUnitPrice(
              '土壌汚染調査',
              this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.soilContaminationSurvey
            ),
            summary: null,
          });
          // 石綿使用調査
          items.push({
            name: '石綿使用調査',
            quantity: 1,
            unit: '式',
            amount: Number(
              this.getUnitPrice(
                'アスベスト調査',
                this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.asbestosUseStudy
              )
            ),
            summary: null,
          });
          // 設備機能調査
          items.push({
            name: '設備機能調査',
            quantity: 1,
            unit: '式',
            amount: this.getUnitPrice(
              '設備機能調査',
              this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.equipmentFunctionSurvey
            ),
            summary: null,
          });
          // インスペクション
          items.push({
            name: 'インスペクション',
            quantity: 1,
            unit: '式',
            amount: this.getUnitPrice(
              'インスペクション',
              this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.inspection
            ),
            summary: null,
          });
          // その他調査
          items.push({
            name: 'その他調査',
            quantity: 1,
            unit: '式',
            amount: Math.floor(
              Number(this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.otherSurveys)
            ),
            summary: null,
          });
          // ここまでの合計
          let total = items.reduce((a, v) => {
            return a + (isNaN(v.amount) ? v.total : v.amount);
          }, 0);
          // 消費税
          items.push({
            name: '消費税',
            quantity: this.getTax('消費税率'),
            unit: '％',
            amount: this.tax(total),
            summary: null,
          });
          // 調査費
          result.push({
            name: '調査費',
            items: items,
            total: this.sumTax(total),
          });
        }
        // 登記費用
        {
          const registrationFees = this.investmentAnalytics.salePlan.registrationFees;
          let items = [];
          // 抵当権抹消登記
          items.push({
            name: '抵当権抹消登記',
            quantity: 1,
            unit: '式',
            amount:
              Math.floor(Number(registrationFees[0])) * this.getTax('登録免許税/抵当権/抹消登記'),
            summary: null,
          });
          // 借地権抹消登記
          items.push({
            name: '借地権抹消登記',
            quantity: 1,
            unit: '式',
            amount:
              Math.floor(Number(registrationFees[1])) * this.getTax('登録免許税/借地権/抹消登記'),
            summary: null,
          });
          // 表示変更登記
          items.push({
            name: '表示変更登記',
            quantity: 1,
            unit: '式',
            amount:
              Math.floor(Number(registrationFees[2])) *
                this.getUnitPrice('登記費用', '表示変更登記/住所変更') +
              Math.floor(Number(registrationFees[3])) *
                this.getUnitPrice('登記費用', '表示変更登記/表題部変更'),
            summary: null,
          });
          // 建物滅失登記
          items.push({
            name: '建物滅失登記',
            quantity: 1,
            unit: '式',
            amount:
              Math.floor(Number(registrationFees[4])) *
              this.getUnitPrice('登記費用', '建物滅失登記'),
            summary: null,
          });
          // 公簿調査
          items.push({
            name: '公簿調査',
            quantity: 1,
            unit: '式',
            amount:
              Math.floor(Number(registrationFees[5])) * this.getUnitPrice('登記費用', '公簿調査'),
            summary: null,
          });
          // 司法書士報酬
          items.push({
            name: '司法書士報酬',
            quantity: 1,
            unit: '式',
            amount:
              Math.floor(Number(registrationFees[6])) *
                this.getUnitPrice('登記費用', '司法書士報酬/抵当権抹消') +
              Math.floor(Number(registrationFees[7])) *
                this.getUnitPrice('登記費用', '司法書士報酬/借地権抹消') +
              Math.floor(Number(registrationFees[8])) *
                this.getUnitPrice('登記費用', '司法書士報酬/公簿調査'),
            summary: null,
          });
          // ここまでの合計
          let total = items.reduce((a, v) => {
            return a + (isNaN(v.amount) ? v.total : v.amount);
          }, 0);
          // 消費税
          items.push({
            name: '消費税',
            quantity: this.getTax('消費税率'),
            unit: '％',
            amount: this.tax(total),
            summary: null,
          });
          // 登記費用
          result.push({
            name: '登記費用',
            items: items,
            total: this.sumTax(total),
          });
        }
        // 立退・補償金
        {
          let items = [];
          // 立退料
          const evictionFees = this.investmentAnalytics.salePlan.evictionFees;
          items.push({
            name: '立退料',
            quantity: 1,
            unit: '式',
            amount:
              Math.floor(Number(evictionFees[0])) * this.getUnitPrice('立退料', '個人') +
              Math.floor(Number(evictionFees[1])) * this.getUnitPrice('立退料', '法人・個人事業主'),
            summary: null,
          });
          // 営業補償
          const bc = this.investmentAnalytics.salePlan.businessCompensation;
          items.push({
            name: '営業補償',
            quantity: 1,
            unit: '式',
            amount:
              Math.floor(Number(bc[0].quantity)) * this.getUnitPrice('営業補償', bc[0].content) +
              Math.floor(Number(bc[1].quantity)) * this.getUnitPrice('営業補償', bc[1].content),
            summary: null,
          });
          // 立退・補償金
          result.push({
            name: '立退・補償金',
            items: items,
            total: items.reduce((a, v) => {
              return a + v.amount;
            }, 0),
          });
        }
        // 契約費用
        {
          let items = [];
          // 売買契約
          if (this.salePriceTotal >= 0) {
            items.push({
              name: '売買契約',
              quantity: 1,
              unit: '式',
              amount: this.getStampDuty(this.salePriceTotal, 0),
              summary: null,
            });
          }
          // 工事請負契約
          if (this.demolitionTotal >= 0 || this.repairTotal >= 0) {
            items.push({
              name: '工事請負契約',
              quantity: 1,
              unit: '式',
              amount:
                this.getStampDuty(this.demolitionTotal, 1) +
                this.getStampDuty(this.repairTotal, 1) +
                this.getStampDuty(
                  Math.floor(Number(this.investmentAnalytics.salePlan.contractCosts[0])), 1
                ),
              summary: null,
            });
          }
          // コンサル契約
          items.push({
            name: 'コンサル契約',
            quantity: 1,
            unit: '式',
            amount:
              this.getStampDuty(
                Math.floor(Number(this.investmentAnalytics.salePlan.contractCosts[1])), 1
              ) +
              this.getStampDuty(
                Math.floor(Number(this.investmentAnalytics.salePlan.contractCosts[2])), 1
              ) +
              this.getStampDuty(
                Math.floor(Number(this.investmentAnalytics.salePlan.contractCosts[3])), 1
              ),
            summary: null,
          });
          // 契約費用
          result.push({
            name: '契約費用',
            items: items,
            total: items.reduce((a, v) => {
              return a + v.amount;
            }, 0),
          });
        }
        // 支払手数料
        {
          let items = [];
          // 売買仲介
          // [収入の部 合計]の３％＋6万円
          if (income.total >= 0) {
            items.push({
              name: '売買仲介',
              quantity: 1,
              unit: '式',
              amount: income.total ? (income.total * 3) / 100 + 60000 : '',
              summary: null,
            });
          }
          // 賃貸借契約変更
          const paymentFees = this.investmentAnalytics.salePlan.paymentFees;
          items.push({
            name: '賃貸借契約変更',
            quantity: 1,
            unit: '式',
            amount: Math.floor(Number(paymentFees[0])) * this.getUnitPrice('賃貸借契約変更手続き'),
            summary: null,
          });
          // 抵当権抹消手数料
          items.push({
            name: '抵当権抹消手数料',
            quantity: 1,
            unit: '式',
            amount: Math.floor(Number(paymentFees[1])) * this.getUnitPrice('抵当権抹消手数料'),
            summary: '金融機関手数料',
          });
          // ここまでの合計
          let total = items.reduce((a, v) => {
            return a + (isNaN(v.amount) ? v.total : v.amount);
          }, 0);
          // 消費税
          items.push({
            name: '消費税',
            quantity: this.getTax('消費税率'),
            unit: '％',
            amount: this.tax(total),
            summary: null,
          });
          // 支払手数料
          result.push({
            name: '支払手数料',
            items: items,
            total: this.sumTax(total),
          });
        }
        // コンサルティング料
        {
          const params = [
            { title: '弁護士', name: '法務コンサル' },
            { title: '税理士', name: '税務コンサル' },
            {
              title: 'コンサル会社',
              name: '不動産コンサル',
            },
          ];
          const consultingFees = this.investmentAnalytics.salePlan.consultingFees;
          let items = [];
          params.forEach((param, index) => {
            items.push({
              name: param.title,
              quantity: 1,
              unit: '式',
              amount:
                Math.floor(Number(consultingFees[index].quantity)) *
                this.getUnitPrice(param.name, consultingFees[index].content),
              summary: null,
            });
          });
          // ここまでの合計
          let total = items.reduce((a, v) => {
            return a + (isNaN(v.amount) ? v.total : v.amount);
          }, 0);
          // 消費税
          items.push({
            name: '消費税',
            quantity: this.getTax('消費税率'),
            unit: '％',
            amount: this.tax(total),
            summary: null,
          });
          // コンサルティング料
          result.push({
            name: 'コンサルティング料',
            items: items,
            total: this.sumTax(total),
          });
        }
        // 広告費
        {
          const costs = this.investmentAnalytics.salePlan.salesAdvertisingCosts;
          let items = [];
          // 広告費
          items.push({
            name: '広告費',
            quantity: 1,
            unit: '式',
            amount:
              Math.floor(Number(costs[0].quantity)) *
                this.getUnitPrice('広告費', costs[0].content) +
              Math.floor(Number(costs[1].quantity)) *
                this.getUnitPrice('広告費', costs[1].content) +
              Math.floor(Number(costs[2].quantity)) * this.getUnitPrice('広告費', costs[2].content),
            summary: null,
          });
          if (items.length > 0) {
            // ここまでの合計
            let total = items.reduce((a, v) => {
              return a + (isNaN(v.amount) ? v.total : v.amount);
            }, 0);
            // 消費税
            items.push({
              name: '消費税',
              quantity: this.getTax('消費税率'),
              unit: '％',
              amount: this.tax(total),
              summary: null,
            });
            // 広告費
            result.push({
              name: '広告費',
              items: items,
              total: this.sumTax(total),
            });
          }
        }
        // その他
        {
          const others = this.investmentAnalytics.salePlan.others;
          let items = [];
          // 諸雑費
          items.push({
            name: '諸雑費',
            quantity: 1,
            unit: '式',
            amount:
              Math.floor(Number(others[0].quantity)) * Math.floor(Number(others[0].unitPrice)),
            summary: null,
          });
          // 違約金
          items.push({
            name: '違約金',
            quantity: 1,
            unit: '式',
            amount:
              Math.floor(Number(others[1].quantity)) * Math.floor(Number(others[1].unitPrice)),
            summary: null,
          });
          // 予備費
          const reserveRate =
            Math.floor(
              Number(this.investmentAnalytics.salePlan.expensesAtTheTimeOfSale.reserveRate) * 100
            ) / 100;
          // ここまでの合計
          let total =
            result.reduce((a, v) => {
              // 各小計
              return a + v.total;
            }, 0) +
            items.reduce((a, v) => {
              // その他（諸雑費＋違約金）
              return a + v.amount;
            }, 0);
          // 予備費
          items.push({
            name: '予備費',
            quantity: reserveRate,
            unit: '％',
            amount: Math.floor((total * reserveRate) / 100),
            summary: null,
          });
          // その他
          result.push({
            name: 'その他',
            items: items,
            total: items.reduce((a, v) => {
              return a + (isNaN(v.amount) ? v.total : v.amount);
            }, 0),
          });
        }
        // 消費税
        let basePrice = income.items.find(a => a.name == '本体価格');
        let consumptionTax = basePrice.items.find(a => a.name == '消費税');
        if (!this.isEmpty(consumptionTax)) {
          let items = [
            {
              name: '預かり消費税',
              quantity: 1,
              unit: '式',
              amount: consumptionTax.amount,
              summary: null,
            },
          ];
          result.push({
            name: '消費税',
            items: items,
            total: items[0].amount,
          });
        }
        return result;
      },
      // 譲渡税
      transferTax: function() {
        let tax = 0;
        switch (this.investmentAnalytics.salePlan.governingBody) {
          case '個人':
            // 譲渡所得税・住民税
            tax += this.transferIncomeTax;
            break;
          case '法人':
            // 法人税・住民税・事業税
            tax += this.corporationTax;
            tax += this.municipalTax;
            tax += this.businessTax;
            break;
          default:
            tax = null;
            break;
        }
        return tax;
      },
    },
  };
</script>
<style scoped>
  #GainOnSale .subtotal > div:nth-child(2) {
    border-left: 1px solid black;
    border-right: 0 !important;
  }
</style>
