// @ts-check

import { PathData } from '../../../util/path';
import {
  entrancePullDoor1,
  entrancePullDoor2,
  entrancePullDoor3,
  entrancePullDoor4,
  doubleSlidingDoor1,
  doubleSlidingDoor2,
  doubleSlidingDoor3,
  doubleSlidingDoor4,
  doubleSlidingDoorSingle2,
  doubleSlidingDoorSingle3,
  doubleSlidingDoorRetract,
  doubleSlidingDoorDouble,
  shutterDoor,
  opening,
  openingDownWall,
  openingWaistWall,
  openingDig,
  entranceSingleDoor,
  entranceDoubleDoor,
  entranceSingleFixDoor,
  entranceParentChildDoor,
  entranceParentChildSleeveDoor,
  singleDoor,
  doubleParentChildDoor,
  doubleDoor,
  foldingDoor1,
  foldingDoor2,
  foldingDoor3,
  foldingDoor4,
  foldingOpenDoor,
} from './door';
import {
  doubleSlidingSash1,
  doubleSlidingSash2,
  doubleSlidingSash3,
  doubleSlidingSash4,
  shojiDoubleSlidingSash1,
  shojiDoubleSlidingSash2,
  shojiDoubleSlidingSash3,
  shojiDoubleSlidingSash4,
  fixSash,
  fixSashAndSingleWindow,
  fixSashAndDoubleWindow,
  openWindow,
  sideOpenWindow,
  sideSlideOpenWindow,
  upDownWindow,
  jarologyWindow,
} from './window';

const generatorList = {
  entrancePullDoor1, // 玄関 引違い戸（1枚）
  entrancePullDoor2, // 玄関 引違い戸（2枚）
  entrancePullDoor3, // 玄関 引違い戸（3枚）
  entrancePullDoor4, // 玄関 引違い戸（4枚）
  doubleSlidingDoor1, // 引違い戸（1枚）
  doubleSlidingDoor2, // 引違い戸（2枚）
  doubleSlidingDoor3, // 引違い戸（3枚）
  doubleSlidingDoor4, // 引違い戸（4枚）
  doubleSlidingDoorSingle2, // 引戸（二枚）
  doubleSlidingDoorSingle3, // 引戸（三枚）
  doubleSlidingDoorRetract, // 
  doubleSlidingDoorDouble, // 引戸（両引き）
  shutterDoor, // シャッター
  opening, // 開口部（開口）
  openingDownWall, // 開口部（下がり壁開口）
  openingWaistWall, // 開口部（腰壁開口）
  openingDig, // 開口部（くり貫き開口）
  entranceSingleDoor, // 玄関ドア（片開き）
  entranceDoubleDoor, // 玄関ドア（両開き）
  entranceSingleFixDoor, // 玄関ドア（片開き+フィックス）
  entranceParentChildDoor, // 玄関ドア（親子）
  entranceParentChildSleeveDoor, // 玄関ドア（親子袖付き）
  singleDoor, // ドア（片開き）
  doubleParentChildDoor, // ドア（親子）
  doubleDoor, // ドア（両開き）
  foldingDoor1, // 折戸（1枚）
  foldingDoor2, // 折戸（2枚）
  foldingDoor3, // 折戸（3枚）
  foldingDoor4, // 折戸（4枚）
  foldingOpenDoor, // 
  doubleSlidingSash1, // 引違いサッシ（1枚）
  doubleSlidingSash2, // 引違いサッシ（2枚）
  doubleSlidingSash3, // 引違いサッシ（3枚）
  doubleSlidingSash4, // 引違いサッシ（4枚）
  shojiDoubleSlidingSash1, // 障子付引違いサッシ（1枚）
  shojiDoubleSlidingSash2, // 障子付引違いサッシ（2枚）
  shojiDoubleSlidingSash3, // 障子付引違いサッシ（3枚）
  shojiDoubleSlidingSash4, // 障子付引違いサッシ（4枚）
  fixSash, // はめ殺し窓（FIX）
  fixSashAndSingleWindow, // 
  fixSashAndDoubleWindow, // 
  openWindow, // 旧開き窓
  sideOpenWindow, // 開き窓
  sideSlideOpenWindow, // 開き窓（横滑り出し窓）
  upDownWindow, // 上げ下げ窓
  jarologyWindow, // ジャロジー窓（ルーバー）
};

/**
 *
 * @param {string} subType
 * @param {number} width
 * @param {number} height
 * @returns {PathData[]}
 */
export const doorGenerator = (subType, width, height) => {
  const fn = generatorList[subType];
  if (!fn) {
    throw new Error(`${subType} is not found`);
  }

  return fn(width, height);
};
