// @ts-check

import { computed, ref } from '@vue/composition-api';
import { PvMeasure } from '../node/node/measureNode';
import { PvObject } from '../node/node/pvObject';

/**
 * @param {{
 * objectTree: import('@vue/composition-api').ComputedRef<PvObject[]>
 * }} params
 */
export const useMeasure = ({ objectTree }) => {
  const unitOfMeasure = ref('m');
  const showMeasure = ref(true);

  const measureTree = computed(() => {
    if (!showMeasure.value || objectTree.value.length === 0) return [];

    const root = PvMeasure.fromObjects(objectTree.value, unitOfMeasure.value);
    const list = root.flat();
    return list;
  });

  return {
    measureTree,
    unitOfMeasure,
    showMeasure,
  };
};
