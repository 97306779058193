<template>
  <div
    class="menu-root"
    :style="{
      height: height + 'px',
      'max-height': height + 'px',
    }"
  >
    <div class="menu-header">
      {{ objectType }}
    </div>
    <slot />
  </div>
</template>
<script>
  // @ts-check
  import { defineComponent, computed } from '@vue/composition-api';
  import { OBJECT_TYPE } from '../../../lib/util/const';

  export default defineComponent({
    props: {
      mode: String,
      activeObject: Object, // PvObject
      height: Number,
    },
    setup(props) {
      const objectType = computed(() => {
        if (props.mode === 'select') {
          // @ts-ignore
          return OBJECT_TYPE[props.activeObject?.type] ?? '-';
        } else {
          // @ts-ignore
          return OBJECT_TYPE[props.mode];
        }
      });

      return { objectType };
    },
  });
</script>
<style scoped>
  .menu-root {
    background-color: white;
    height: 100%;
    width: 100%;
  }
  .menu-header {
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    background-color: #333;
    color: white;
  }
</style>

