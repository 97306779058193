/**
作成者：
作成日：
画面名：投資分析/出力
機能：ローン計算
親子関係：
更新者：
①
更新内容：
①
**/
export default {
  methods: {
    // ローン計算メイン
    // 引数：借入金額、返済期間[年]、借入金利[％]×２、金利適用期間[年]、返済方法
    // 戻り値：返済金額、残高（※年毎）
    calcLoan: function(param) {
      let result = [];
      // 返済方法が「元利均等方式」の場合
      if (param.method == '元利均等方式') {
        // ・月の返済金額は固定（金利変更時に再計算）
        // ・年毎に返済金額および残高を算出

        // 月々の金利①
        let monthRates1 = param.rates[0] / 1200;
        // 返済期間の方が適用期間よりも長かった場合  月利②
        let monthRates2 = null;
        if (param.period > param.applicationPeriod) {
          monthRates2 = param.rates[1] / 1200;
        }

        //月々の返済金額① 月々返済額＝借入金額×（月利①）/ (1 - (1 + 月利①) ^ (- 返済回数))
        let repayment1 =
          (param.amount * monthRates1) / (1 - Math.pow(1 + monthRates1, -param.period * 12));
        let repayment2 = 0;
        let setAmount = 0;
        if (param.period <= param.applicationPeriod) {
          for (let i = 0; i < param.period; i++) {
            //ローン残高  (月返済額① - ((1 + 月利①) ^ (返済年数 * 12)) * (月返済額① - 借入金額 * 月利①)) / 月利①
            let setBalance =
              (repayment1 -
                Math.pow(1 + monthRates1, (i + 1) * 12) *
                  (repayment1 - param.amount * monthRates1)) /
              monthRates1;
            // 年毎のPPMT
            let setYearPPMT = 0;
            for (let k = 1; k <= 12; k++) {
              // 月々のPPMT = 月の返済額 / (1 + 月利①) ^ (返済期間 - 支払い回 + 1）
              let setMonthPPMT =
                repayment1 / Math.pow(1 + monthRates1, param.period * 12 - (i * 12 +  k) + 1);
                setYearPPMT += setMonthPPMT;
            }
            result.push({ balance: setBalance, amount: repayment1 * 12, ppmt: setYearPPMT });
          }
        } else {
          //金利1の適用期間までの計算
          for (let i = 0; i < param.applicationPeriod; i++) {
            //ローン残高  (月返済額① - ((1 + 月利①) ^ (返済年数 * 12)) * (月返済額① - 借入金額 * 月利①)) / 月利①
            let setBalance =
              (repayment1 -
                Math.pow(1 + monthRates1, (i + 1) * 12) *
                  (repayment1 - param.amount * monthRates1)) /
              monthRates1;
            // 年毎のPPMT
            let setYearPPMT = 0;
            for (let k = 1; k <= 12; k++) {
              let setMonthPPMT =
                // 月々のPPMT = 月の返済額 / (1 + 月利①) ^ (返済期間 - 支払い回 + 1)
                repayment1 / Math.pow(1 + monthRates1, param.period * 12 - (i * 12 +  k) + 1);
                setYearPPMT += setMonthPPMT;
            }
            result.push({ balance: setBalance, amount: repayment1 * 12, ppmt: setYearPPMT });
            //適用期間最終年数
            if (i == param.applicationPeriod - 1) {
              setAmount = setBalance;
              //月々の返済金額② 月々返済額 ＝ (ローン残高 × 月利②) / (1 - (1 + 月利②)＾(- ( 返済期間 - 適用期間) ) * 12))
              repayment2 =
                (setAmount * monthRates2) /
                (1 - Math.pow(1 + monthRates2, -(param.period - param.applicationPeriod) * 12));
            }
          }
          //それ以降の計算
          for (let j = param.applicationPeriod; j < param.period; j++) {
            //月利②を適用
            let setBalance =
              (repayment2 -
                Math.pow(1 + monthRates2, (j - param.applicationPeriod + 1) * 12) *
                  (repayment2 - setAmount * monthRates2)) /
              monthRates2;
            // 年毎のPPMT
            let setYearPPMT = 0;
            for (let k = 1; k <= 12; k++) {
              let setMonthPPMT =
                // 月々のPPMT = 月の返済額 / (1 + 月利②) ^ (返済期間 - 支払い回 + 1）
                repayment2 / Math.pow(1 + monthRates2, param.period * 12 - (j * 12 +  k) + 1);
                setYearPPMT += setMonthPPMT;
              }
            result.push({ balance: setBalance, amount: repayment2 * 12, ppmt: setYearPPMT });
          }
        }
      }
      // 返済方法が「元金均等方式」の場合
      else if (param.method == '元金均等方式') {
        // ・返済金額は毎月変動（元金返済額は固定）
        // ・月毎に返済金額および残高を算出、年毎に返済金額を合計

        //月々の金利①
        let monthRates1 = param.rates[0] / 1200;
        //返済期間の方が適用期間よりも長かった場合 月利(②)
        let monthRates2 = null;
        if (param.period > param.applicationPeriod) {
          monthRates2 = param.rates[1] / 1200;
        }
        //年毎の返済金額
        let yearRepayment = 0;
        //月毎の返済金額
        let monthRepayment = 0;
        //残高
        let balance = param.amount;
        //金利切り替え時の残高を保存する
        let setAmount = 0;
        // 元金充当額
        let ppmt = 0;

        // 元金充当額(年毎) = 借入金 / 返済期間
        ppmt = param.amount / param.period;
        //N回目の返済額 ＝ 借入金額/返済回数 + (残高 * 月利)
        if (param.period <= param.applicationPeriod) {
          for (let i = 1; i <= param.period * 12; i++) {
            monthRepayment = param.amount / (param.period * 12) + balance * monthRates1;
            yearRepayment += monthRepayment;
            //ローン残高＝借入金額-（借入金額/（返済期間×12））×返済年数
            balance = param.amount - (param.amount / (param.period * 12)) * i;
            if (i % 12 == 0) {
              result.push({ balance: balance, amount: yearRepayment, ppmt: ppmt});
              yearRepayment = 0;
            }
          }
        } else {
          for (let i = 1; i <= param.applicationPeriod * 12; i++) {
            //金利1の適用期間までの計算
            monthRepayment = param.amount / (param.period * 12) + balance * monthRates1;
            yearRepayment += monthRepayment;
            //ローン残高＝借入金額-（借入金額/（返済期間×12））×返済年数
            balance = param.amount - (param.amount / (param.period * 12)) * i;
            if (i % 12 == 0) {
              result.push({ balance: balance, amount: yearRepayment, ppmt: ppmt});
              yearRepayment = 0;
            }
            //金利切替時の残高を格納
            if (i == param.applicationPeriod * 12) {
              setAmount = balance;
            }
          }
          //それ以降の計算
          for (let j = 1; j <= (param.period - param.applicationPeriod) * 12; j++) {
            //月利②を適用
            monthRepayment =
              setAmount / ((param.period - param.applicationPeriod) * 12) + balance * monthRates2;
            yearRepayment += monthRepayment;
            balance = balance - setAmount / ((param.period - param.applicationPeriod) * 12);
            if (j % 12 == 0) {
              result.push({ balance: balance, amount: yearRepayment, ppmt: ppmt});
              yearRepayment = 0;
            }
          }
        }
      }
      // その他
      else {
        return null;
      }
      // 最終年の残高は０とする
      result[param.period - 1].balance = 0;

      return result;
    },
  },
};