//路線価の計算
//import { API, graphqlOperation } from 'aws-amplify';
//import { getSystemMaster } from '@/graphql/queries';

const routePriceCalc = async function(input, master) {
  try {
    //奥行価格補正率対象値
    var routePriceMasterValue = 0;
    //2路線3路線奥行価格補正率対象値
    var frontageRoutePriceMasterValue = 0;
    //路線価の計算結果変数
    var resultPrice = 0;
    var resultPriceType = 'A';
    var calcArray = [];
    var text = null;
    var calcArrayItem = {
      text,
      resultPrice,
      resultPriceType,
    };

    //奥行価格補正率表マスタロード
    var routePriceMaster = master.interitanceMaster1.slice(1);

    //側方路線影響加算率表マスタロード
    var multipleRouterPriceSideMaster = master.interitanceMaster2.slice(1);

    //二方路線影響加算率表マスタロード
    var multipleRouterPriceTwoMaster = master.interitanceMaster3.slice(1);

    //奥行価格補正率表の計算
    // 正面と裏面の奥行価格補正率(奥行距離から求める)
    for (let i = 0; i < routePriceMaster.length; i++) {
      //Frontからの奥行距離を確認、マスタデータの行を検索
      if (input.depthDistance < routePriceMaster[i][0]) {
        //Frontからの地区区分を確認、マスタデータの列を検索
        routePriceMasterValue = parseFloat(routePriceMaster[i][input.areaType]);
        break;
      }
    }
    //2路線3路線用奥行価格補正率表の計算
    // 側方の奥行価格補正率(間口距離から求める)
    for (let i = 0; i < routePriceMaster.length; i++) {
      if (input.frontageDistance < routePriceMaster[i][0]) {
        //Frontからの地区区分を確認、マスタデータの列を検索
        frontageRoutePriceMasterValue = parseFloat(routePriceMaster[i][input.areaType]);
        break;
      }
    }
    //一路線に面する宅地の計算
    resultPriceType = 'A';
    resultPrice = Math.floor(routePriceMasterValue * input.landPrice.front);
    calcArrayItem = {
      text: '一路線に面する宅地の計算',
      resultPriceType: resultPriceType,
      resultPrice: resultPrice,
    };
    calcArray.push(calcArrayItem);

    //    console.log('front calc end', resultPrice);

    //側方•二法路線影響加算率表の計算
    if (input.landPrice.side1 || input.landPrice.side2 || input.landPrice.back) {
      //マスタのy変の検索用偏すセットアップ
      var y = input.areaType - 1;

      //二路線に面する宅地
      if (input.landPrice.side1) {
        //側面１の加算率表の計算
        let side1Master = null;
        if (input.corner.side1Corner) {
          // 角地 準角地の場合(2.側方路線影響加算率表)
          side1Master = parseFloat(multipleRouterPriceSideMaster[y][input.corner.side1Corner]);
        } else {
          // 対象外の場合(3.二方路線影響加算率表)
          side1Master = parseFloat(multipleRouterPriceTwoMaster[y][1]);
        }
        resultPriceType = 'B';
        resultPrice =
          resultPrice +
          Math.floor(input.landPrice.side1 * frontageRoutePriceMasterValue * side1Master);
        calcArrayItem = {
          text: '二路線に面する宅地',
          resultPriceType: resultPriceType,
          resultPrice: resultPrice,
        };
        calcArray.push(calcArrayItem);
      }

      //三路線に面する宅地
      if (input.landPrice.side2) {
        let side2Master = null;
        //側面２の加算率表の計算
        if (input.corner.side2Corner) {
          // 角地 準角地の場合(2.側方路線影響加算率表)
          side2Master = parseFloat(multipleRouterPriceSideMaster[y][input.corner.side2Corner]);
        } else {
          // 対象外の場合(3.二方路線影響加算率表)
          side2Master = parseFloat(multipleRouterPriceTwoMaster[y][1]);
        }
        resultPriceType = 'C';
        resultPrice =
          resultPrice +
          Math.floor(input.landPrice.side2 * frontageRoutePriceMasterValue * side2Master);
        calcArrayItem = {
          text: '三路線に面する宅地',
          resultPriceType: resultPriceType,
          resultPrice: resultPrice,
        };
        calcArray.push(calcArrayItem);
      }

      //四路線に面する宅地
      if (input.landPrice.back) {
        let backMaster = null;
        //裏面の加算率表の計算
        if (input.corner.backCorner) {
          // 角地 準角地の場合(2.側方路線影響加算率表)
          backMaster = parseFloat(multipleRouterPriceSideMaster[y][input.corner.backCorner]);
        } else {
          // 対象外の場合(3.二方路線影響加算率表)
          backMaster = parseFloat(multipleRouterPriceTwoMaster[y][1]);
        }
        resultPriceType = 'D';
        resultPrice =
          resultPrice + Math.floor(input.landPrice.back * routePriceMasterValue * backMaster);
        calcArrayItem = {
          text: '四路線に面する宅地',
          resultPriceType: resultPriceType,
          resultPrice: resultPrice,
        };
        calcArray.push(calcArrayItem);
      }
    }

    var result = {
      calcArray: calcArray,
      resultPriceType: resultPriceType,
      resultPrice: resultPrice,
    };

    //    console.log('路線価計算終了 : ', result);

    return result;
  } catch (e) {
    // console.log("Error : ",e)
    throw new Error(e);
  }
};

export { routePriceCalc };
