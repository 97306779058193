// @ts-check

import { PanActionBuilder } from '../../action/builder/panActionBuilder';
import { EventPresetBuilder } from '../eventPresetBuilder';
import { EventPreset } from '../eventPreset';

export class PanPreset extends EventPresetBuilder {
  build() {
    const preset = new EventPreset();

    preset.dragstart = dataset => {
      this.actionManager.setAction(new PanActionBuilder(this.context, dataset));
    };

    return preset;
  }
}
