// @ts-check

import { Vec } from './math/vector';
import { PvFloor } from '../node/node/floorNode';
import { PvDoor } from '../node/node/doorNode';
import { PvAdditionalInfo } from '../node/tools/additionalInfo';
import {
  ADDITIONAL_INFO_OPEN_TYPE,
  ADDITIONAL_INFO_KNOB_TYPE,
  ADDITIONAL_INFO_SLIDING_TYPE,
} from './const';
import { PvObject } from '../node/node/pvObject';

export class UseJudgeThePointInArea {
  /**
   * FUNC: 内外判定の対象座標を求める
   * @param {PvFloor} targetFloor // 床ノード
   * @param {PvObject} door // 建具ノード
   */
  createTargetPoint(targetFloor, door) {
    const doorStartPoint = door.transform.translate; // 床ノードの0,0を基準とした建具アイコンの始点座標
    // console.log('doorStartPoint:', doorStartPoint);

    const distance = Math.sqrt(Math.pow(door.vertexes[1].x, 2) + Math.pow(door.vertexes[1].y, 2));
    const projection = new Vec(
      distance * Math.cos(door.transform.rotate),
      distance * Math.sin(door.transform.rotate)
    ); // 建具アイコンの始点を基準（{0, 0}）とした終点座標
    const doorLocalCenterPoint = new Vec(projection.x / 2, projection.y / 2); // 建具アイコンの始点を0,0とした配置座標の中央
    // console.log('doorLocalCenterPoint:', doorLocalCenterPoint);
    const doorCenterPoint = new Vec(
      doorStartPoint.x + doorLocalCenterPoint.x,
      doorStartPoint.y + doorLocalCenterPoint.y
    ); // 床ノードの0,0を基準とした建具アイコンの配置座標の中央
    // console.log('doorCenterPoint:', doorCenterPoint);
    const r = targetFloor.style.strokeWidth / 2; // 床ノードの壁の厚みの半分
    // console.log('r:', r);
    const doorRadian = Math.atan2(projection.y, projection.x); // 建具アイコンの角度（ラジアン）
    const degree = doorRadian * (180 / Math.PI); // 建具アイコンの角度（度）※確認用
    // console.log('radian:', doorRadian, ', degree:', degree);
    const movingValue = new Vec(r * Math.sin(doorRadian), r * Math.cos(doorRadian));
    // console.log('movingValue:', movingValue);
    const p = new Vec(doorCenterPoint.x + movingValue.x, doorCenterPoint.y - movingValue.y); // 座標点P（床ノードの0,0を基準とした建具アイコンの配置座標中央から壁の厚みの1/2移動した地点）
    return p;
  }

  /**
   * FUNC: 部屋に対する対称座標の内外判定
   * @param {Vec[]} floorVertexes // 床ノードの各頂点座標 vertexes
   * @param {Vec} targetPoint // 内外判定の対象座標
   * @returns {boolean}
   */
  isPointInArea(floorVertexes, targetPoint) {
    let wn = 0;
    for (let i = 0; i <= floorVertexes.length - 1; i++) {
      let nextIndex = i == floorVertexes.length - 1 ? 0 : i + 1; // NOTE: オリジナルでは正しく機能しなかった為、最後の頂点+最初の頂点の組み合わせも確認するように修正
      // 上向きの辺、下向きの辺によって処理が分かれる。
      // 上向きの辺。点Pがy軸方向について、始点と終点の間にある。ただし、終点は含まない。(ルール1)
      if (floorVertexes[i].y <= targetPoint.y && floorVertexes[nextIndex].y > targetPoint.y) {
        // 辺は点pよりも右側にある。ただし、重ならない。(ルール4)
        // 辺が点pと同じ高さになる位置を特定し、その時のxの値と点pのxの値を比較する。
        let vt =
          (targetPoint.y - floorVertexes[i].y) / (floorVertexes[nextIndex].y - floorVertexes[i].y);
        if (
          targetPoint.x <
          floorVertexes[i].x + vt * (floorVertexes[nextIndex].x - floorVertexes[i].x)
        ) {
          ++wn; //ここが重要。上向きの辺と交差した場合は+1
        }
      }
      // 下向きの辺。点Pがy軸方向について、始点と終点の間にある。ただし、始点は含まない。(ルール2)
      else if (floorVertexes[i].y > targetPoint.y && floorVertexes[nextIndex].y <= targetPoint.y) {
        // 辺は点pよりも右側にある。ただし、重ならない。(ルール4)
        // 辺が点pと同じ高さになる位置を特定し、その時のxの値と点pのxの値を比較する。
        let vt =
          (targetPoint.y - floorVertexes[i].y) / (floorVertexes[nextIndex].y - floorVertexes[i].y);
        if (
          targetPoint.x <
          floorVertexes[i].x + vt * (floorVertexes[nextIndex].x - floorVertexes[i].x)
        ) {
          --wn; //ここが重要。下向きの辺と交差した場合は-1
        }
      }
      // ルール1,ルール2を確認することで、ルール3も確認できている。
    }
    return wn != 0;
  }

  /**
   * FUNC: 建具追加情報取得
   * baseAdditionalInfoはマスタの設定値。0の項目は設定不要
   * @param {PvAdditionalInfo} baseAdditionalInfo
   * @param {boolean} isPointInArea
   * @returns {PvAdditionalInfo}
   */
  getAdditionalInfo(baseAdditionalInfo, isPointInArea) {
    const additionalInfo = new PvAdditionalInfo({
      open: 0,
      knob: 0,
      sliding: 0,
      isPointInArea: isPointInArea,
      stringers: 0,
    });
    // 開き方向
    if (baseAdditionalInfo.open !== 0) {
      additionalInfo.open = isPointInArea
        ? ADDITIONAL_INFO_OPEN_TYPE.INWARD
        : ADDITIONAL_INFO_OPEN_TYPE.OUTWARD;
    }
    // ノブ方向
    if (baseAdditionalInfo.knob !== 0) {
      additionalInfo.knob = isPointInArea
        ? ADDITIONAL_INFO_KNOB_TYPE.RIGHT
        : ADDITIONAL_INFO_KNOB_TYPE.LEFT;
    }
    // 引き方向
    if (baseAdditionalInfo.sliding !== 0) {
      additionalInfo.sliding = isPointInArea
        ? ADDITIONAL_INFO_SLIDING_TYPE.LEFT
        : ADDITIONAL_INFO_SLIDING_TYPE.RIGHT;
    }
    return additionalInfo;
  }

  /**
   * FUNC: 建具追加情報変更
   * @param {PvAdditionalInfo} baseAdditionalInfo
   * @param {boolean} isPointInArea
   * @returns {PvAdditionalInfo}
   */
  changeAdditionalInfo(baseAdditionalInfo, isPointInArea) {
    // 前回の判定結果と今回が同じかチェック
    if (baseAdditionalInfo.isPointInArea == isPointInArea) {
      // 同じならそのまま返す
      return baseAdditionalInfo;
    }

    const additionalInfo = new PvAdditionalInfo({
      open: baseAdditionalInfo.open,
      knob: baseAdditionalInfo.knob,
      sliding: baseAdditionalInfo.sliding,
      isPointInArea: isPointInArea,
      stringers: baseAdditionalInfo.stringers,
    });
    // 開き方向
    if (baseAdditionalInfo.open !== 0) {
      additionalInfo.open =
        additionalInfo.open == ADDITIONAL_INFO_OPEN_TYPE.OUTWARD
          ? ADDITIONAL_INFO_OPEN_TYPE.INWARD
          : ADDITIONAL_INFO_OPEN_TYPE.OUTWARD;
    }
    // ノブ方向
    if (baseAdditionalInfo.knob !== 0) {
      additionalInfo.knob =
        additionalInfo.knob == ADDITIONAL_INFO_KNOB_TYPE.LEFT
          ? ADDITIONAL_INFO_KNOB_TYPE.RIGHT
          : ADDITIONAL_INFO_KNOB_TYPE.LEFT;
    }
    // 引き方向
    if (baseAdditionalInfo.sliding !== 0) {
      additionalInfo.sliding =
        additionalInfo.sliding == ADDITIONAL_INFO_SLIDING_TYPE.RIGHT
          ? ADDITIONAL_INFO_SLIDING_TYPE.LEFT
          : ADDITIONAL_INFO_SLIDING_TYPE.RIGHT;
    }
    return additionalInfo;
  }

  /**
   * FUNC: 移動時の判定による建具追加情報
   * @param {PvDoor} door
   * @returns {PvAdditionalInfo}
   */
  getAdditionalInfoForMove(door) {
    // @ts-ignore
    const targetPoint = this.createTargetPoint(door.parent, door);
    // @ts-ignore
    const isPointInArea = this.isPointInArea(door.parent.vertexes, targetPoint);
    return this.changeAdditionalInfo(door.additionalInfo, isPointInArea);
  }
}
