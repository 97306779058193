<template>
  <div class="">
    <div class="row serch-data-table-top">
      <div class="col-12">
        <b-table
          thead-tr-class="serch-data-table-header"
          tbody-tr-class="serch-data-table-body"
          :busy="loading"
          :fixed="true"
          hover
          striped
          :items="currentPageItems"
          :fields="fields"
          show-empty
        >
          <template v-slot:table-busy>
            <Load />
          </template>
          <template v-slot:empty>
            <div class="row">
              <div class="col-12 text-center">
                <svg
                  class="bi bi-exclamation-diamond-fill"
                  width="8em"
                  height="8em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <div class="alert">データがありません</div>
              </div>
            </div>
          </template>
          <template v-slot:cell()="row">
            <div v-for="header in [headerInfo(row.field.key)]" :key="header ? header.key : ''">
              <div
                :variant="header.button.variant ? header.button.variant : 'ally'"
                @click="customEvent(header.button.event, row)"
                v-if="header.button && isLicensedArSimulator == true"
              >
                {{ getTdText(header, row) }}<i class="ml-3 ti-pencil-alt" />
              </div>
              <div v-if="header.button && isLicensedArSimulator == false">
                {{ getTdText(header, row) }}
              </div>
              <div v-if="header.dateTime">
                {{ getDateTimes(getTdText(header, row)) }}
              </div>
              <div v-if="!header.button && !header.dateTime">
                {{ getTdText(header, row) }}
              </div>
            </div>
          </template>
          <template v-slot:cell(images)="row">
            <div>
              <div class="table-image">
                <SlideImage
                  :slideImages="
                    imagesList.find(val => val.id == row.item.id) != null
                      ? imagesList.find(val => val.id == row.item.id).img
                      : null
                  "
                  :max_width="150"
                  :max_height="90"
                  :loadFlag="true"
                />
              </div>
            </div>
          </template>
          <template v-slot:cell(detail)="row">
            <b-button
              variant="ally"
              @click="detailEvent(row)"
            >
              開始
            </b-button>
          </template>
          <template v-slot:cell(delete)="row">
            <b-button
              variant="ally"
              @click="deleteModalView(row)"
              :disabled="isLicensedArSimulator == false"
            >
              削除
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      :ref="targetName + 'deleteModal'"
      title="削除確認"
      header-class="ally-modal"
      footer-class="ally-modal"
      cancel-variant="light"
      cancel-title="キャンセル"
      ok-variant="light"
      ok-title="削除"
      @ok="deleteEvent()"
    >
      削除しますか？
    </b-modal>
  </div>
</template>

<script>
  import * as query from '../../../graphql/queries';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getS3Url } from '../../../graphql/queries';
  import Load from '../../../components/Common/Load';
  import SlideImage from './slideImage.vue';

  export default {
    // headers: テーブルのヘッダ
    // targetName: テーブル名
    // labelEnums: 値のenum
    // hideDetail: 詳細ボタン表示プラグ
    // hideDelete: 削除ボタン表示プラグ
    // hideSearchBox: 検索ボックス表示フラグ
    // maxCount: 最大件数
    // companyId: 会社ID
    props: [
      'headers',
      'targetName',
      'labelEnums',
      'hideSimulator',
      'hideDetail',
      'hideDelete',
      'filterFix',
      'hideSearchBox',
      'maxCount',
      'propertyId',
      'roomId',
      'isLicensedArSimulator',
    ],
    data: function() {
      return {
        // 最大同時取得レコード数
        RECORDS_LIMIT: 60,
        // テーブルデータ（内部）
        items: [],
        // ロード中プラグ
        loading: true,
        // フィルターデータ
        filter: {
          targetKey: null,
          targetLabel: '選択',
          textValue: null,
          searchValue: null,
        },
        deleteTarget: null,
        // 現在表示中のページ
        currentPage: 0,
        getPropertyInfo: null,
        companyId: null,
        imagesList: [],
      };
    },
    mounted: async function() {
      // 非同期で取得
      this.companyId = await this.getCompanyId();
      await this.listItems();
      await this.getImagesData();
    },
    computed: {
      // b-tableヘッダデータ
      fields() {
        const result = this.headers.map(x => {
          return {
            key: x['key'],
            label: x['label'],
          };
        });
        result.splice(1, 0, {
          key: 'images',
          label: 'イメージ',
          thStyle: 'width: 30%', //イメージheaderのCSS
        });
        // 詳細、削除ボタン追加処理
        if (this.hideDetail === undefined) result.push({ key: 'detail', label: 'シミュレーター' });
        if (this.hideSimulator === undefined)
          if (this.hideDelete === undefined)
            //result.push({ key: "simulator", label: "シミュレート" });
            result.push({ key: 'delete', label: '削除' });

        return result;
      },
      // クエリ名
      listQueryName() {
        return 'list' + this.targetName + 's';
      },
      // クエリ本文
      listQuery() {
        return query[this.listQueryName];
      },
      // フィルターエラー
      filterError() {
        if (!this.filter.targetKey) return '条件を選択して下さい';
        else return null;
      },
      // 表示用テーブルデータ
      dispItems() {
        let newDispItems = this.items;

        if (this.filter.targetKey != null && this.filter.textValue != null) {
          newDispItems = newDispItems.filter(item => {
            return item[this.filter.targetKey]?.includes(this.filter.textValue || '') || false;
          });
        }

        return newDispItems;
      },
      currentPageItems() {
        // ページのインデックス
        const from = this.currentPage * this.RECORDS_LIMIT;
        const to = this.currentPage * this.RECORDS_LIMIT + this.RECORDS_LIMIT;

        return this.dispItems.slice(from, to);
      },
      isLastPage() {
        const result =
          this.currentPage >= Math.floor((this.dispItems.length - 1) / this.RECORDS_LIMIT);
        return result;
      },
    },
    methods: {
      //イメージ画像を呼び出す。
      deleteModalView: function(row) {
        this.setdeleteTarget(row);
        this.$refs[this.targetName + 'deleteModal'].show();
      },
      /**
       * サムネイルの画像を取得する
       */
      getImagesData: async function() {
        this.imagesList = [];
        for (var item of this.items) {
          //パターンサムネがない時はベース画像を取得します。
          let dir = 'baseImage';
          let str = item.simInfo.baseImageFileName;

          if (item.simInfo.savePeten == null) item.simInfo.savePeten = [];
          //パターンサムネがある場合は取得する。
          if (item.simInfo.savePeten.length != 0) {
            let image = [];
            //パターンの数だけ画像を取得する。
            for (let paten of item.simInfo.savePeten) {
              if (paten.id != null) {
                //パータンがあるとき、画像を取得する
                dir = 'thumbnailImage';
                let fileName = item.simInfo.baseImageFileName.replace(/\.[^/.]+$/, '');
                str = fileName + '_' + paten.id + '.png';
                let links = await this.getS3Thumbnail(str, dir);
                image.push(links.data.getS3Url[0].fileUrl);
              } else {
                //パータンがない時、ベースの画像を取得する。
                dir = 'baseImage';
                str = item.simInfo.baseImageFileName;
                let links = await this.getS3Thumbnail(str, dir);
                image.push(links.data.getS3Url[0].fileUrl);
              }
            }
            this.imagesList.push({ id: item.id, img: image });
          } else {
            //新規画像登録後は、一旦ベースイメージを表示する。
            let links = await this.getS3Thumbnail(str, dir);
            let image = [];
            image.push(links.data.getS3Url[0].fileUrl);
            this.imagesList.push({ id: item.id, img: image });
          }
        }
      },
      /**
       * 画像を取得する
       */
      getS3Thumbnail: async function(str, dir) {
        var links = await API.graphql(
          graphqlOperation(getS3Url, {
            level: 'public',
            file: {
              fileName: str,
              fileAction: 'View',
              propertyDirectory: {
                propertyId: this.propertyId,
                fileDirectory: 'simulation/' + dir,
              },
            },
          })
        );
        return links;
      },
      // アイテム取得
      getItems: async function() {
        const queryVar = {
          limit: this.RECORDS_LIMIT,
          company: this.companyId,
          filter: { propertyId: { eq: this.propertyId }, roomId: { eq: this.roomId } },
        };
        if (this.roomId == null) delete queryVar.filter.roomId;
        queryVar.nextToken = null;
        try {
          let items = [];
          let nextToken = null;

          //propertyを呼び出す
          do {
            let queryResult = await API.graphql(graphqlOperation(this.listQuery, queryVar));
            let list = 'list' + this.targetName + 's';
            nextToken = queryResult.data[list].nextToken;
            items = [...items, ...queryResult.data[list].items];
            queryVar.nextToken = nextToken;
          } while (nextToken != null);
          if (this.roomId == null) items = items.filter(val => val.roomId == null);
          this.items = items;
          this.imageCountCheck(this.items.length);
        } catch (e) {
          console.error(e);
        }
      },
      // 初期化処理
      listItems: async function() {
        await this.initItems();
        await this.loadItems();
      },
      //一覧を初期化し、ローディング中にする
      initItems: async function() {
        this.loading = true;
        this.items = [];
        this.currentPage = 0;
      },
      //最新の一覧を取得し、表示する
      loadItems: async function() {
        await this.getItems();
        this.loading = false;
      },
      // カスタムイベント発生
      customEvent: function(event, row) {
        this.$emit(event, row.item, this.getPropertyInfo);
      },
      // シミュレーターイベント発生
      simulatorEvent: function(row) {
        this.$emit('simulatorClicked', row.item);
      },
      // 詳細イベント発生
      detailEvent: function(row) {
        this.$emit('detailClicked', row.item);
      },
      // 削除イベント発生
      deleteEvent: async function() {
        await this.$emit('deleteClicked', this.deleteTarget.item);
      },
      setdeleteTarget: function(row) {
        this.deleteTarget = row;
      },
      // 次のページ移動
      next: function() {
        this.currentPage++;
      },
      // 以前ページ移動
      prev: function() {
        if (this.currentPage > 0) {
          this.currentPage--;
        }
      },
      // 検索
      search: async function() {
        if (this.filterError) return;
        this.dispItems = this.item;
      },
      // セルのヘッダー情報を返します
      headerInfo: function(key) {
        const header = this.headers.find(x => x.key == key);
        if (header) return header;
        return null;
      },
      // enumからテキストを呼び出す
      getTdText: function(header, row) {
        const enumType = header.enumType;
        const dataType = header.dataType;
        const callback = header.callback;
        const value = row.value;

        if (callback) return callback(header, row);
        if (dataType == 'Date') return value.toString().split('T')[0];
        return enumType ? this.labelEnums[enumType][value] : value;
      },
      //検索リストをリロード
      resetSearch: async function() {
        // 検索条件初期化
        this.filter.searchValue = null;
        // 検索入力欄の初期化
        this.filter.textValue = null;
        // 検索種別ラベル初期化
        this.filter.targetLabel = '選択';
        // 検索種別キー初期化
        this.filter.targetKey = null;
      },
      imageCountCheck: function(count) {
        //現在の表示枚数を親コンポーネントに送信する。
        this.$emit('imageCountCheck', count);
      },

      //レイヤー情報が０件のとき、シミュレーターボタンを非活性にする。
      disabledCheckFlag: function(row) {
        let flag = false;
        if (row.item.layersInfo.length == 0) {
          flag = true;
        }
        return flag;
      },
      getCompanyId: async function() {
        //アカウントから会社ID取得
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },
      /**
       *  日時を'YYYY/MM/DD hh:mm'変換する
       */
      getDateTimes: function(str) {
        let date = new Date(str);
        let backStr = date.getFullYear() + '/';
        backStr += ('0' + (date.getMonth() + 1)).slice(-2) + '/';
        backStr += ('0' + date.getDate()).slice(-2) + ' ';
        backStr += ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
        return backStr;
      },
    },
    components: {
      Load,
      SlideImage,
    },
  };
</script>

<style scoped>
  .sim-btn {
    background-color: orange;
  }
  .sim-btn:disabled {
    background-color: lightgray;
  }
  .table-image {
    height: 110px;
    border-radius: 10%;
  }
</style>
