<template>
  <div id="">
    無料ライセンス適用期間
    <b-table
      thead-tr-class="serch-data-table-header"
      tbody-tr-class="serch-data-table-body"
      striped
      bordered
      :items="licenses"
      :fields="headers"
      show-empty
    >
    <template v-slot:empty>
      ロード中
    </template>
    <template v-slot:cell()="row">
      {{ row.value }}
    </template>
    </b-table>
  </div>
</template>

<!-- スクリプト -->
<script>
  import awsconfig from '../../aws-exports';
  import { API } from 'aws-amplify';
  import Common from './Common';
  import moment from 'moment';
  API.configure(awsconfig);


  export default {
    props: ['companyInfo'],
    components: {},
    mixins: [Common],

    data: function() {
      return {
        master: null,
        company: null,
        licenses: [],
        headers:[
        {
          key: 'name',
          label: 'ライセンス名称',
        },
        {
          key: 'schedule',
          label: '無料期間',
        },
        ]
      };
    },
    mounted: async function() {

      // マスタ取得
      this.master = await this.loadMaster();

      // ライセンス読込
      await this.loadLicense();

    },
    methods: {
      // ライセンス読込
      loadLicense: async function() {
        if (this.company?.license == null) {
          return;
        }
        let licenses = this.company.license;

        //ライセンスの種類分繰り返す
        for(let mastar of this.master){
          if (mastar.id == 0) continue;
          let data = {};
          data.name = mastar.name;
          let paymentNullLicenes = licenses.filter(val => val.paymentId == null);
          let license = paymentNullLicenes.filter(val => val.id == mastar.id);
            
          //license購入履歴あり
          if(license.length > 0){
            let startDate = moment(license[0].initialPurchaseDate).format("YYYY/MM/DD");
            let endDate;
            if(license.length == 1){ //一ヶ月無料
              endDate = moment(license[0].initialPurchaseDate).endOf('month').format("YYYY/MM/DD");
            }else if(license.length == 2){//二ヶ月無料
              endDate = moment(license[0].initialPurchaseDate).add('months', 1).endOf('month').format("YYYY/MM/DD");
            }
            data.schedule = startDate + "から" + endDate + "まで";

          }else{
            data.schedule = "購入履歴なし"
          }
          this.licenses.push(data);
        }
        
      },
    },
    watch: {
      //compnayInfoが更新されたとき、処理を行う
      companyInfo: function() {
        this.company = this.companyInfo;
        
        // ライセンス読込
        this.loadLicense();
      },
    },
  };
</script>

<!-- スタイル -->
<style scoped>
 
</style>
