<template>
  <CommonLayout title="ポートフォリオ分析">
    <template v-slot>
      <div style="padding: 60px 20px; margin-left: 10px;">
        <ReportCard width="2750px" height="2750px" style="margin: auto; border-style: none;">
          <template v-slot>
            <ReportCanvas size="100%" class="chart-canvas" style="left: 5px;">
              <template v-slot>
                <ROAChart ref="roaChart" :width="3800" :height="3800" :chartValue="chartValues"/>
              </template>
            </ReportCanvas>
          </template>
        </ReportCard>
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import ROAChart from '../ROAChart';
  import ReportCanvas from '@/components/ExportReport/ReportCanvas';
  import ReportCard from '@/components/ExportReport/ReportCard';

  export default {
    props: {
      chartValues: {
        type: Array,
        required: true,
      },
      comment: {
        type: String,
        required: false,
      },
    },
    components: {
      CommonLayout,
      ROAChart,
      ReportCanvas,
      ReportCard,
      // ReportTable,
    },
    data() {
      return {};
    },
    methods: {
      async loadChart() {
        await this.$nextTick();
        await this.$refs.roaChart.loadChart();
      },
    },
  };
</script>
