export default class CommonInput {
  constructor() {
    this.reportTitle = '';
    this.reportDate = '';
    this.customerName = '';
    this.customerId = '';
    this.staffName = '';
    //家族情報が必定な場合
    this.family = [];
    this.lastName = null;
    this.firstName = null;
    this.lastNameKana = null;
    this.firstNameKana = null;
    this.familyType = null;
    //相続税の計算項目
    this.cash = null;
    this.stocks = null;
    this.otherAsset = null;
    this.otherFinancialAsset = null;
    this.inheritanceFlg = false;
    this.inheritancePrice = 0;
    this.inheritancePriceDeduction = null;
    this.donationDeduction = null;
    this.donation = 0;
    this.donations = [];
    this.liability = null;
    this.continuityInheritanceFlg = null;
    this.secondaryInheritancePattern = null;
    this.secondaryHeir = 0;
    this.uniqueAsset = 0;
    this.continuityInheritanceTerm = 0;
    this.inheritanceSystemFlg = 1;
  }
}
