// @ts-check

import { onMounted, onUnmounted, ref, computed } from '@vue/composition-api';
import { PvObject } from '../node/node/pvObject';
import { PvRoot } from '../node/node/rootNode';
import { ArMeasure } from '../util/arMeasure';
import { BBoxCalculator } from '../util/bboxCalculator';
import { AR_BASE_OFFSET } from '../util/const';
import { Vec } from '../util/math/vector';
import { useInteriorMaster } from '../state/interiorMaster';

/**
 *
 * @param {{
 * layerWidth: import('@vue/composition-api').Ref<number>
 * layerHeight: import('@vue/composition-api').Ref<number>
 * root: import('@vue/composition-api').Ref<PvRoot>
 * objectTree: import('@vue/composition-api').Ref<PvObject[]>
 * saveHistory: () => void
 * presets: Record<string, PvObject>
 * save: Object
 * }} param0
 * @returns
 */
export const useAr = ({
  layerWidth,
  layerHeight,
  root,
  objectTree,
  saveHistory,
  presets,
  save,
}) => {
  const debugJson = ref('');
  const debugJsonModal = ref(null);
  const interiorMaster = useInteriorMaster();
  const interiorMasterDataForPlaneView = computed(() => {
    return interiorMaster.interiorMasterDataForPlaneView.value;
  });

  /** @type {(json: import('../util/arMeasure').ArJson) => Promise<void>} */
  const loadAr = async json => {
    // すでに部屋があればその方位角を利用する
    const adjAzimuth = root.value.firstChildId == null ? null : save.azimuth.value;
    const arMeasure = new ArMeasure({
      presets,
    });
    const bboxCalculator = new BBoxCalculator({
      objectTree,
    });
    const box = bboxCalculator.getBBox();
    const offXTmp = box.x + box.width + (adjAzimuth ? AR_BASE_OFFSET.X * 0.2 : AR_BASE_OFFSET.X);
    let offX, offY;
    if (offXTmp > layerWidth.value) {
      offX = 0;
      offY = AR_BASE_OFFSET.Y + box.y + box.height;
    } else {
      offX = offXTmp;
      offY = AR_BASE_OFFSET.Y + (offXTmp > layerWidth.value ? box.y + box.height : 0);
    }

    await arMeasure.load(
      json,
      new Vec(offX, offY),
      interiorMasterDataForPlaneView.value,
      adjAzimuth
    );
    const objects = arMeasure.takeSvgObject();
    for (const obj of objects) {
      root.value.appendChild(obj);
    }
    save.azimuth.value = arMeasure.getAzimuth();

    saveHistory();
  };

  /**
   * METHOD: Unityイベント
   */
  const unityEvent = async (/** @type {{ data: { type: string; value: any; }; }} */ e) => {
    if (e.data.type !== 'unity') {
      return;
    }
    // ARからJson取得
    let json = e.data.value;
    let obj;
    try {
      obj = JSON.parse(json);
    } catch (error) {
      console.error(error);
      return;
    }
    if (origin.startsWith('http://')) {
      // デバッグ
      debugJson.value = JSON.stringify(json);
      // @ts-ignore
      debugJsonModal.value.show();
    }

    try {
      // ロードAR
      await loadAr(obj);
    } catch (error) {
      alert(error);
      console.error(error);
    }
  };

  onMounted(() => {
    // MOUNTED: リスナ登録
    addEventListener('message', unityEvent);
  });

  onUnmounted(() => {
    // UNMOUNTED: リスナ解除
    removeEventListener('message', unityEvent);
  });

  return {
    debugJson,
    debugJsonModal,
    loadAr,
  };
};
