<template>
  <div>
    <MaterialSearch
      outerLeft="-300"
      outerWidth="300"
      :activeData="activeLayerData.color"
      :activeMaterial="activeLayerData.material"
      @getClickColor="getClickColor"
      @materialClick="materialClick"
    />
    <div class="align-items-center">
      <span v-for="hierarchy in getNavigation.length" :key="'hierarchy-' + hierarchy">
        <div class="sort d-flex">
          <div v-for="(item, index) of childsHierarchy(hierarchy)" :key="'item' + index">
            <div
              v-if="selectSort != null"
              class="sort-buttn"
              :style="'width: ' + sortButtonWidth / childsHierarchy(hierarchy).length + 'px;'"
              :class="{ active: item.sortNo == selectSort[hierarchy - 1] }"
              @click="changeSortMode(hierarchy - 1, item.sortNo)"
              :key="'item-buttn' + index"
            >
              <span
                v-html="
                  stringCheck(item.displayName, sortButtonWidth / childsHierarchy(hierarchy).length)
                "
              ></span>
            </div>
          </div>
        </div>
      </span>
      <div ref="materials" style=" overflow-y: scroll;" v-if="getNavigation != 0">
        <!-- colorは特別-->
        <span v-show="getNavigation.find(val => val.systemName == 'color') != null">
          <div v-if="getNavigation.find(val => val.systemName == 'selectColor') != null">
            <div class="material text-center p-3" @click="simulate()">
              <v-swatches
                class="color-style"
                swatches="text-advanced"
                v-model="activeLayerData.color"
                show-fallback
                fallback-input-type="color"
                popover-x="left"
                :disabled="false"
                :inline="true"
                :swatch-size="16"
              ></v-swatches>
            </div>
          </div>
          <div class="material text-center p-3">
            <NittoColorData
              @getClickColor="getClickColor"
              :activeColor="activeLayerData.color"
              :seccondSort="selectSort[getNavigation.length - 1]"
              ref="NittoColorData"
            />
          </div>
        </span>
        <span v-show="getNavigation.find(val => val.systemName == 'color') == null">
          <div v-for="(material, index) in materialInfo" :key="index" ref="material">
            <div
              v-if="material.class == getNavigation[getNavigation.length - 1].systemName"
              class="material text-center pl-3"
              :class="{
                select: material.name == activeLayer.material,
              }"
            >
              <div class="row">
                <div class="col-8" style="padding:0px 0px 0px 15px;">
                  <span v-if="material.viewFlag == true">
                    <span class="vectorSize mr-2">← </span>
                    <span class="sizes">{{ Math.round(material.width) / 10 }}cm </span>
                    <span class="vectorSize ml-2">→</span>
                  </span>
                  <div class="material-border" @click="materialClick(material.url, material.name)">
                    <img
                      id="material-img"
                      :class="'material-img ' + material.systemName + ' index' + index"
                      crossorigin="anonymous"
                      :ref="'index' + index"
                      :src="material.url"
                    />
                  </div>
                  <span class="material-name">{{ material.name }}</span>
                </div>
                <div class="col-4" style="position: relative; top: 35px; left: -15px ">
                  <span v-if="material.viewFlag == true">
                    <span class="vectorSize">↑<br /> </span>
                    <span class="sizes"
                      >{{ Math.round(material.height) / 10 }}<br />
                      cm <br />
                    </span>
                    <span class="vectorSize">↓</span></span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-12"></div>
              </div>
            </div>
          </div>
        </span>
        <div class="material text-center"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { getSystemMaster, getS3Url } from '../../../../graphql/queries';
  import VSwatches from 'vue-swatches';
  import MaterialClass from '../../materialMaster';
  import NittoColorData from './NittoColorData';
  import MaterialSearch from './MaterialSearch';

  export default {
    props: ['activeLayer', 'windowIconSize'],
    data: function() {
      return {
        materialInfo: [], //GQLから取得した素材を格納
        sortButtonWidth: 250, //素材ソートボタンの合計横幅
        materialClassList: null,
        selectSort: [], //現在選択しているソートを保存
      };
    },
    mixins: [MaterialClass],
    created: async function() {
      await this.setSortInfoData();
    },
    mounted: async function() {
      await this.setMaterial();

      this.setMaterialTabSize();

      //Image画像のサイズを取得する。
      for (let i = 0; i < this.materialInfo.length; i++) {
        this.getSize(i);
      }

      window.addEventListener('resize', () => {
        //ウィンドウサイズ変更したとき自動で演算し直す
        this.setMaterialTabSize();
      });

      //マウント処理が完了をmainに通知
      this.$emit('completeComponent', 'material');
    },
    watch: {
      /**
       * 新しいレイヤーの選択時に適用素材の項目が開かれるように設定
       */
      activeLayer: {
        deep: true,
        handler: function() {
          if (this.selectSort != null) {
            //素材を選択しているとき
            if (this.activeLayer?.material != null) {
              if (this.materialInfo.length == 0) return null;
              //選択中の素材項目を取得する
              let getNavis = this.checkHierarchy(
                this.materialInfo.find(val => val.name == this.activeLayer?.material).class
              );
              for (let i = 0; i < getNavis.length; i++) {
                this.selectSort.splice(i, 1, getNavis[i].sortNo);
              }
            }
            //色を選択している時
            else if (this.activeLayer?.material == null) {
              this.selectSort = [];
              for (let i = 0; i < 10; i++) {
                this.selectSort.push('0');
              }
              let colorData = this.$refs.NittoColorData.checkActiveColor(this.activeLayer?.color);
              if (colorData != null) {
                this.selectSort.splice(this.getNavigation.length - 1, 1, colorData[2]);
              }
            }
            this.setMaterialTabSize();
          }
        },
      },
    },
    methods: {
      /**
       * マテリアルソートのデータを取得
       */
      setSortInfoData: async function() {
        let materialClassList = await this.getMaterialClassList(4);
        this.materialClassList = materialClassList;

        //選択中のソート情報を調整する
        for (let i = 0; i < 10; i++) {
          this.selectSort.push('0');
        }
      },
      /**
       * マテリアル情報を取得
       */
      setMaterial: async function() {
        //マスタ情報をロード
        let masResult = await API.graphql(
          graphqlOperation(getSystemMaster, {
            classification: 'materialImage',
            classificationDetail: '1',
          })
        );
        //マスタから素材情報を取得
        let materialData = masResult.data.getSystemMaster;
        let links = [];

        //ファイルのディレクトリ情報を取得
        materialData.value.forEach((material, key) => {
          if (key != 0) {
            links.push({
              name: material[0], //素材名
              info: material[1], //素材の種類（壁紙、外壁、フローリングなど）
              class: material[3], //第二ソート
              dir: 'Simulate/' + material[2], //ファイルディレクトリ名
            });
          }
        });

        //getS3にアクセス
        //ファイル名だけの時
        // S3からイメージURLを受けてくる
        var s3Result = await API.graphql(
          graphqlOperation(getS3Url, {
            level: 'Master',
            file: links.map(x => {
              return {
                fileName: x.dir,
                fileAction: 'View',
              };
            }),
          })
        );

        //情報を統合する。
        s3Result.data.getS3Url.forEach((image, key) => {
          this.materialInfo.push({
            name: links[key].name,
            sort: links[key].info,
            class: links[key].class,
            systemName: this.materialClassList.find(val => val.sortNo == links[key].info)
              .systemName,
            url: image.fileUrl.replace(/\?.*/, ''),
            width: null,
            height: null,
            viewFlag: false,
          });
        });
      },
      /**
       * 素材情報をクリックした時の処理
       */
      materialClick: function(url, name) {
        this.activeLayer.color = null;
        this.$emit('simulate', url, name);
        this.$emit('materialModeDrawing');
      },
      simulate: function() {
        this.$emit('simulate', null, null, true);
        this.$emit('materialModeDrawing');
      },
      /**
       *  <img>要素 → Base64形式の文字列に変換
       *  @param HTMLImageElement img
       *  @param string mime_type "image/png", "image/jpeg" など
       *  @return string base64URL
       */
      imageToBase64: function(img, mime_type) {
        let canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        return canvas.toDataURL(mime_type);
      },
      /**
       * 素材リストを切り替え
       * @param int hier
       * @param String sortMode
       */
      changeSortMode: function(hier, sortMode) {
        this.selectSort.splice(hier, 1, sortMode.toString());
        for (let i = hier + 1; i < this.selectSort.length; i++) {
          this.selectSort[i] = '0';
        }
        this.setMaterialTabSize();
      },
      /**
       * 素材一覧の表示する高さを調整する
       */
      setMaterialTabSize: function() {
        if (this.$refs.materials != null) {
          this.$refs.materials.style.height = this.windowIconSize() - 80 + 'px';
        }
      },
      /**
       * name情報と一致するmaterial情報を取得する。
       */
      getMaterial(name) {
        let material = this.materialInfo.find(val => val.name == name);
        return material;
      },
      /**
       * 画像の縦横サイズを取得する
       * @param {*} index
       */
      getSize: async function(index) {
        //画像のサイズを取得
        let url = this.materialInfo[index].url;
        let image = await new Promise(function(resolve) {
          let image = new Image();
          image.crossOrigin = 'anonymous';
          image.onload = () => {
            resolve(image);
          };
          image.src = url;
        });

        //サイズをセット
        this.materialInfo[index].width = image.width;
        this.materialInfo[index].height = image.height;
        this.materialInfo[index].viewFlag = true;
      },
      /**日塗工のカラー素材をクリックした際処理実行*/
      getClickColor: function(getCollar) {
        this.activeLayerData.color = getCollar[1];
        this.simulate();
      },
      /**
       * systemNameが、項目リストのどの位置にあるか検知する
       * @param {*} systemName
       */
      checkHierarchy: function(systemName) {
        if (this.materialClassList == null) return null;
        let getData = this.getChild(this.materialClassList.concat(), systemName);
        return getData;
      },
      getChild(childs, systemName) {
        if (childs == null) return null;
        for (let child of childs) {
          let get = this.getChild(child.child, systemName);
          if (get.length != 0) {
            get.unshift(child);
            return get;
          }
          if (child.systemName == systemName) {
            return [child];
          }
        }
        return [];
      },
      /**
       * 文字列が長い時、font-sizeを小さくする。
       * @param {String} name
       * @param {int} fremeSize
       */
      stringCheck(name, frameSize) {
        let fontSize = 15;
        while (fontSize * name.length > frameSize - 5) {
          fontSize--;
        }
        let str = '<span style="white-space: nowrap; font-size: ' + fontSize + 'px">';
        str += name;
        str += '</span>';
        return str;
      },
    },
    computed: {
      /**
       * 選択中のレイヤー情報を取得
       */
      activeLayerData: function() {
        if (this.activeLayer == null) {
          return {
            color: null,
            material: null,
          };
        }
        return this.activeLayer;
      },
      /**
       * 現在の素材選択情報を配列として取得する
       */
      getNavigation: function() {
        if (this.materialClassList == null) return [];
        let navi = [];
        try {
          let childs = this.materialClassList[this.selectSort[0]];
          navi.push(childs);
          let count = 1;
          while (childs.child.length != 0 && childs.child != null) {
            childs = childs.child[this.selectSort[count]];
            navi.push(childs);
            count++;
          }
          return navi;
        } catch (e) {
          console.error(e);
          return [];
        }
      },
      /**
       * 選択した階層のソート情報が取得される
       * @param {string} hierarchy
       */
      childsHierarchy: function() {
        return function(hierarchy) {
          try {
            let materialClassList = this.materialClassList.concat();
            for (let i = 1; i < hierarchy; i++) {
              materialClassList = materialClassList[this.selectSort[i - 1]].child.concat();
            }
            return materialClassList;
          } catch (e) {
            console.error(e);
            return [];
          }
        };
      },
    },
    components: {
      VSwatches,
      NittoColorData,
      MaterialSearch,
    },
  };
</script>
<style scoped>
  .align-items-center {
    padding-right: 0px;
  }
  .material {
    color: #fff;
    cursor: pointer;
    height: 160px;
  }
  .material.select {
    background: rgb(121, 109, 0);
  }
  .material-img {
    max-width: 135px;
    max-height: 110px;
  }
  .material-border {
    display: table-cell;
    text-align: center;
    min-width: 140px;
    min-height: 140px;
    height: 104px;
    border: 2px solid white;
    vertical-align: middle;
  }
  .material-name {
    font-size: 1em;
  }

  .sort-buttn {
    width: auto;
    height: 30px;
    border: thick double #32a1ce;
    text-align: center;
    color: white;
  }
  .sort-buttn.active {
    background: var(--colorTheme);
  }
  .sort-buttn:hover {
    background: var(--colorTheme);
    transition: 0.2s;
    cursor: pointer;
  }
  .sizes {
    font-size: 12px;
    color: aqua;
  }
  .vectorSize {
    font-size: 16px;
    color: aqua;
  }
</style>

<style>
  .vue-swatches__wrapper {
    padding-top: 3px;
  }

  .vue-swatches__fallback__input--wrapper .vue-swatches__fallback__input {
    padding-top: 2px;
    padding-bottom: 2px;
    width: 155px;
  }
</style>
