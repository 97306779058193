import { render, staticRenderFns } from "./License.vue?vue&type=template&id=310b3a84&scoped=true&"
import script from "./License.vue?vue&type=script&lang=js&"
export * from "./License.vue?vue&type=script&lang=js&"
import style0 from "./License.vue?vue&type=style&index=0&id=310b3a84&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310b3a84",
  null
  
)

export default component.exports