<template>
  <g>
    <image
      data-area="object"
      :data-id="obj.id"
      :href="imageUrl"
      :width="obj.width"
      :height="obj.height"
      preserveAspectRatio="none"
      :transform="`scale(${obj.style.flipHorizontal ? -1 : 1}, ${obj.style.flipVertical ? -1 : 1})`"
      :transform-origin="`${obj.width / 2} ${obj.height / 2}`"
    />
  </g>
</template>
<script>
  import { computed, defineComponent } from '@vue/composition-api';
  import { useIconImages } from '../../lib/state/iconImages';

  export default defineComponent({
    props: {
      obj: Object,
    },
    setup(props) {
      const icons = useIconImages();

      const imageUrl = computed(() => {
        const data = icons.iconImages.value.find(v => v.url === props.obj.style.imageUrl);
        if (data == null) return '';
        return URL.createObjectURL(data.blob);
      });

      return {
        imageUrl,
      };
    },
  });
</script>
