export default {
  //修正予定
  E0001: 'エラーが発生しました。',
  E0002: '最大##number##件まで登録できます。',

  //エラー
  E101: 'システムエラーです。管理者に問い合わせください。',
  E103: 'パスワードは大文字、小文字、数字、特殊文字を含めて入力してください。',
  E105: '試行の制限を超えました。しばらくしてから試してください。',
  E106: '既存パスワードをもう一度、確認してください。',
  E107: 'パスワードは必ず10桁以上にしてください。',
  E108: '必須項目を入力してください。',
  E109: '選択された項目がありません。',
  E110: '所有者情報を確認した上、再度チェックボタンを押してください。',
  E111: '入力値が変更されました。再度、計算ボタンを押してください。',
  E112: '現在ログイン中のユーザー情報は、マイページで変更してください。',
  E113: '入力されたE-mailアドレスは既に使用されています。別のアドレスをご利用ください。',
  E114: '現在ログイン中のアカウントのため、削除できません。別のアカウントで再度お試しください。',
  E115: '配偶者が２名以上に入力されています。相続人情報を再度、確認してください',
  E116: '父母が２名以上に入力されています。相続人情報を再度、確認してください',
  E117: '相続比率の総計は100％を超えることはできません。',
  E118: '既に登録されている法人番号です',
  E119: '祖父母が２名以上に入力されています。相続人情報を再度、確認してください',
  E120: '二次相続計算に必要な家族情報の入力が足りません。',
  //成功
  S100: '正常に処理しました。',
  S101: '認証コードを再送信しました。メールをご確認ください。',

  //警告

  //その他
};
