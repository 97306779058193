<!--
作成者：本間
作成日：2020.9.14
画面名：不動産適正項目入力コンポネント
機能：不動産適正の項目を入力
親子関係：なし
更新者：
更新内容：
-->
<template>
  <div id="realEstateSuitability">
    <b-card no-body>
      <b-tabs card no-fade>
        <b-tab title="相続条件" id="ownerSiteConditions">
          <!--
          <b-row>
            <allyRadio
              class="col-12"
              title="法人・個人"
              name="ownerType"
              :items="[
                { text: '法人', value: 1 },
                { text: '個人', value: 2 },
              ]"
              v-model="inputValue.siteConditions.ownerInformation.ownerType"
              rules="required"
            />
          </b-row>
          <div style="color: red;">
            ※ 法人様の場合は所有者情報の記載は不要です。
          </div>
          -->
          <InputSiteConditions
            v-if="inputValue.siteConditions.ownerInformation.ownerType == 2"
            :tabName="'ownerInformation'"
            :inputValue="inputValue"
            :numberOfHeirs="numberOfHeirs"
            :actualInheritancePercentage="actualInheritancePercentage"
            :calcFlg="calcFlg"
            ref="siteConditions"
          ></InputSiteConditions>
        </b-tab>
        <b-tab title="敷地条件" id="siteConditions">
          <InputSiteConditions
            :tabName="'siteInformation'"
            :inputValue="inputValue"
            :numberOfHeirs="numberOfHeirs"
            :actualInheritancePercentage="actualInheritancePercentage"
            :calcFlg="calcFlg"
            ref="siteConditions"
          ></InputSiteConditions>
        </b-tab>
        <b-tab title="立地条件" id="locationConditions">
          <InputLocationConditions
            :inputValue="inputValue"
            :columns="columns"
            :calcFlg="calcFlg"
            ref="locationConditions"
          ></InputLocationConditions>
        </b-tab>
        <b-tab title="全体計画" id="masterPlan">
          <b-tabs card no-fade>
            <b-tab title="住居用賃貸物件">
              <b-container id="rentalProperty">
                <b-row style="text-align:left;">
                  <b-col class="title"></b-col>
                  <b-col v-for="column in columns" :key="column.id" class="component">
                    <b-container>
                      <b-row>
                        <b-col class="component" v-if="column.id < 4">
                          <b-form-checkbox
                            :id="'check' + column.id"
                            v-model="column.checked"
                            :name="'check' + column.id"
                            class="checkbox"
                            v-b-popover.hover.top="'アパート・賃貸マンション'"
                          >
                            {{ column.text }}
                          </b-form-checkbox>
                        </b-col>
                        <b-col class="component" v-else>
                          <b-form-checkbox
                            :id="'check' + column.id"
                            v-model="column.checked"
                            :name="'check' + column.id"
                            class="checkbox"
                            v-b-popover.hover.top="'一戸建ての賃貸'"
                          >
                            {{ column.text }}
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    賃料
                  </b-col>
                  <b-col v-for="column in columns" :key="column.id" class="component">
                    <b-container>
                      <b-row>
                        <b-col class="component">
                          <allyText
                            :rules="column.checked ? 'required|min_value:0|max_value:999999' : ''"
                            :mode="column.checked ? 'Edit' : 'View'"
                            :name="'monthlyRent' + column.id"
                            type="number"
                            step="0.1"
                            :title="'賃料' + (column.id + 1)"
                            :nonAppear="true"
                            :loading="loading"
                            :value="inputValue.overallPlan.rentalProperty[column.id].monthlyRent"
                            @input="inputMonthlyRent($event, column.id)"
                            :floatLength="1"
                            :separation="true"
                            roundType="down"
                          />
                        </b-col>
                        <b-col cols="4" class="unit">万円/月/戸</b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    間取り・名称
                  </b-col>
                  <b-col v-for="column in columns" :key="column.id" class="component ">
                    <b-container>
                      <b-row>
                        <b-col class="component ally-select">
                          <b-form-input
                            disabled
                            :name="'floorPlan' + column.id"
                            :value="master.defaultValue.rentalProperty[column.id].floorPlanAndName"
                            :loading="loading"
                          />
                        </b-col>
                        <b-col cols="4" class="unit"></b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    戸数
                  </b-col>
                  <b-col v-for="column in columns" :key="column.id" class="component">
                    <b-container>
                      <b-row>
                        <b-col class="component">
                          <allyText
                            :rules="column.checked ? 'required|min_value:0|max_value:99999' : ''"
                            :mode="!columns[column.id].checked ? 'View' : 'Edit'"
                            type="number"
                            :value="inputValue.overallPlan.rentalProperty[column.id].numberOfHouses"
                            @input="inputNumberOfHouses($event, column.id)"
                            :title="'戸数' + (column.id + 1)"
                            :nonAppear="true"
                            :loading="loading"
                            :separation="true"
                            roundType="down"
                          />
                        </b-col>
                        <b-col cols="4" class="unit">
                          戸
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    工事費
                  </b-col>
                  <b-col v-for="id in [0, 1, 2, 3, 4, 5, 6]" :key="id" class="component">
                    <b-container>
                      <b-row>
                        <b-col class="component">
                          <b-form-input
                            disabled
                            class="currency"
                            :value="constructionCosts(id) | currency"
                            :loading="loading"
                          />
                        </b-col>
                        <b-col cols="4" class="unit">万円</b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">初年度経費</b-col>
                  <b-col v-for="column in columns" :key="column.id" class="component">
                    <b-container>
                      <b-row>
                        <b-col class="component">
                          <b-form-input
                            disabled
                            class="currency"
                            :value="
                              parseInt(
                                constructionCosts(column.id) * (expenseRate(column.id) / 100)
                              ) | currency
                            "
                            :loading="loading"
                          />
                        </b-col>
                        <b-col cols="4" class="unit">万円</b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    自己資金
                  </b-col>
                  <b-col v-for="column in columns" :key="column.id" class="component">
                    <b-container>
                      <b-row>
                        <b-col class="component">
                          <allyText
                            :rules="column.checked ? 'required|min_value:0|max_value:999999' : ''"
                            :name="'inputValue-overallPlan-personalFunds' + column.id"
                            type="number"
                            step="0.1"
                            :mode="!columns[column.id].checked ? 'View' : 'Edit'"
                            :value="personalFunds(column.id)"
                            @input="inputPersonalFunds($event, column.id)"
                            :title="'自己資金' + (column.id + 1)"
                            :nonAppear="true"
                            :separation="true"
                            :loading="loading"
                            roundType="down"
                          />
                        </b-col>
                        <b-col cols="4" class="unit">万円</b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    借入金額
                  </b-col>
                  <b-col v-for="id in [0, 1, 2, 3, 4, 5, 6]" :key="id" class="component">
                    <b-container>
                      <b-row>
                        <b-col class="component">
                          <b-form-input
                            disabled
                            class="currency"
                            :value="loanAmount(id) | currency"
                          />
                        </b-col>
                        <b-col cols="4" class="unit">万円</b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    借入金利
                  </b-col>
                  <b-col v-for="column in columns" :key="column.id" class="component">
                    <b-container>
                      <b-row>
                        <b-col class="component">
                          <allyText
                            :rules="column.checked ? 'required|min_value:0|max_value:100' : ''"
                            type="number"
                            step="0.01"
                            :mode="!columns[column.id].checked ? 'View' : 'Edit'"
                            :value="setBorrowingRate(column.id)"
                            @input="inputBorrowingRate($event, column.id)"
                            :title="'借入金利' + (column.id + 1)"
                            :nonAppear="true"
                            :loading="loading"
                            :floatLength="2"
                            roundType="down"
                          />
                        </b-col>
                        <b-col cols="4" class="unit">％ </b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    借入期間
                  </b-col>
                  <b-col v-for="column in columns" :key="column.id" class="component">
                    <b-container>
                      <b-row>
                        <b-col class="component">
                          <allyText
                            :rules="column.checked ? 'required|min_value:0|max_value:45' : ''"
                            type="number"
                            :mode="!columns[column.id].checked ? 'View' : 'Edit'"
                            :value="setBorrowingPeriod(column.id)"
                            @input="inputBorrowingPeriod($event, column.id)"
                            :title="'借入期間' + (column.id + 1)"
                            :nonAppear="true"
                            :loading="loading"
                            :separation="true"
                            roundType="down"
                          />
                        </b-col>
                        <b-col cols="4" class="unit">年</b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col style="text-align:right;">
                    【初年度】
                  </b-col>
                  <b-col v-for="id in [0, 1, 2, 3, 4, 5, 6]" :key="id" class="component"> </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">年間修繕費</b-col>
                  <b-col v-for="column in columns" :key="column.id" class="component">
                    <b-container>
                      <b-row>
                        <b-col class="component">
                          <allyText
                            :rules="column.checked ? 'required|min_value:0|max_value:99999999' : ''"
                            :mode="!columns[column.id].checked ? 'View' : 'Edit'"
                            :name="'annualRepairCosts' + column.id"
                            type="number"
                            step="0.1"
                            :value="
                              inputValue.overallPlan.rentalProperty[column.id].annualRepairCosts
                            "
                            @input="inputAnnualRepairCosts($event, column.id)"
                            :loading="loading"
                            :title="'年間修繕費' + (column.id + 1)"
                            :nonAppear="true"
                            :separation="true"
                            roundType="down"
                          />
                        </b-col>
                        <b-col cols="4" class="unit">円/年</b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    固都税 建物
                  </b-col>
                  <b-col v-for="column in columns" :key="column.id" class="component">
                    <b-container>
                      <b-row>
                        <b-col
                          class="component"
                          v-b-popover.hover="'固定資産税・都市計画税のこと。'"
                        >
                          <b-form-input
                            disabled
                            class="currency"
                            :value="taxBuiding[column.id] | currency"
                          />
                        </b-col>
                        <b-col cols="4" class="unit">円</b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="title">
                    固都税 土地
                  </b-col>
                  <b-col v-for="id in [0, 1, 2, 3, 4, 5, 6]" :key="id" class="component">
                    <b-container>
                      <b-row>
                        <b-col
                          class="component"
                          v-b-popover.hover="'固定資産税・都市計画税のこと。'"
                        >
                          <b-form-input disabled class="currency" :value="taxLand(id) | currency" />
                        </b-col>
                        <b-col cols="4" class="unit">円</b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col id="tt1" class="title">
                    <label>手取額</label>
                    <b style="color: gray">※税抜</b>
                  </b-col>
                  <b-col v-for="id in [0, 1, 2, 3, 4, 5, 6]" :key="id" class="component">
                    <b-container>
                      <b-row>
                        <b-col class="component">
                          <b-form-input
                            disabled
                            class="currency"
                            :value="monthlyIncomeExcludingTax(id) | currency"
                          />
                        </b-col>
                        <b-col cols="4" class="unit">円/月</b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col id="tt2" class="title">
                    手取額<br />
                    <b style="color: gray">※税込</b>
                  </b-col>
                  <b-col v-for="id in [0, 1, 2, 3, 4, 5, 6]" :key="id" class="component">
                    <b-container>
                      <b-row>
                        <b-col class="component">
                          <b-form-input
                            disabled
                            class="currency"
                            :value="monthlyIncomeIncludingTax(id) | currency"
                          />
                        </b-col>
                        <b-col cols="4" class="unit">円/月</b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="その他">
              <b-card-group columns>
                <b-card header="店舗・事務所" id="storeOffice">
                  <b-container>
                    <b-row>
                      <b-col class="title">
                        賃料
                      </b-col>
                      <b-col class="component">
                        <allyText
                          rules="required|min_value:0|max_value:999999"
                          type="number"
                          step="0.1"
                          mode="Edit"
                          name="monthlyRent"
                          :loading="loading"
                          title="店舗・事務所:賃料"
                          :nonAppear="true"
                          :value="inputValue.overallPlan.storeOffice.monthlyRent"
                          @input="inputMonthlyRent($event, 8)"
                          :floatLength="1"
                          :separation="true"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">万円/月</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        延床面積
                      </b-col>
                      <b-col class="component">
                        <allyText
                          rules="required|min_value:0|max_value:999999"
                          type="number"
                          step="0.1"
                          mode="Edit"
                          name="totalFlooraSpace"
                          :loading="loading"
                          title="店舗・事務所:延床面積"
                          :nonAppear="true"
                          v-model="inputValue.overallPlan.storeOffice.totalFlooraSpace"
                          :floatLength="2"
                          :separation="true"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">㎡</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        工事費
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="constructionCosts(8) | currency"
                          :loading="loading"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">万円</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        自己資金
                      </b-col>
                      <b-col class="component">
                        <allyText
                          rules="required|min_value:0|max_value:999999"
                          name="inputValue-overallPlan-storeOffice-personalFunds"
                          type="number"
                          mode="Edit"
                          :value="personalFunds(8)"
                          @input="inputPersonalFunds($event, 8)"
                          title="店舗・事務所:自己資金"
                          :nonAppear="true"
                          :separation="true"
                          :loading="loading"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">万円</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        借入金額
                      </b-col>
                      <b-col class="component">
                        <b-form-input disabled class="currency" :value="loanAmount(8) | currency" />
                      </b-col>
                      <b-col cols="2" class="unit">万円</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        借入金利
                      </b-col>
                      <b-col class="component">
                        <allyText
                          rules="required|min_value:0|max_value:100"
                          type="number"
                          step="0.01"
                          mode="Edit"
                          title="店舗・事務所:借入金利"
                          :nonAppear="true"
                          :value="setBorrowingRate(8)"
                          @input="inputBorrowingRate($event, 8)"
                          :loading="loading"
                          :floatLength="2"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">％</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        借入期間
                      </b-col>
                      <b-col class="component">
                        <allyText
                          rules="required|min_value:0|max_value:45"
                          type="number"
                          mode="Edit"
                          title="店舗・事務所:借入期間"
                          :nonAppear="true"
                          :value="setBorrowingPeriod(8)"
                          @input="inputBorrowingPeriod($event, 8)"
                          :loading="loading"
                          :separation="true"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">年</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        固都税 土地
                      </b-col>
                      <b-col class="component" v-b-popover.hover="'固定資産税・都市計画税のこと。'">
                        <b-form-input disabled class="currency" :value="taxLand(8) | currency" />
                      </b-col>
                      <b-col cols="2" class="unit">円/年</b-col>
                    </b-row>
                    <b-row>
                      <b-col id="tt3" class="title">
                        手取額
                        <b style="color:gray;">※税抜</b>
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="monthlyIncomeExcludingTax(8) | currency"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">円/月</b-col>
                    </b-row>
                    <b-row>
                      <b-col id="tt4" class="title">
                        手取額
                        <b style="color: gray">※税込</b>
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="monthlyIncomeIncludingTax(8) | currency"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">円/月</b-col>
                    </b-row>
                  </b-container>
                </b-card>
                <b-card header="月極駐車場" id="storeOffice">
                  <b-container>
                    <b-row>
                      <b-col class="title">
                        賃料
                      </b-col>
                      <b-col class="component">
                        <allyText
                          rules="required|min_value:0|max_value:999999"
                          type="number"
                          step="0.1"
                          mode="Edit"
                          name="monthlyRent"
                          title="月極駐車場:賃料"
                          :nonAppear="true"
                          :loading="loading"
                          :value="inputValue.overallPlan.monthlyParkingLot.monthlyRent"
                          @input="inputMonthlyRent($event, 7)"
                          :floatLength="1"
                          :separation="true"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">万円/台/月</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        台数
                      </b-col>
                      <b-col class="component">
                        <allyText
                          rules="required|min_value:0|max_value:999999"
                          type="number"
                          mode="Edit"
                          title="台数"
                          :nonAppear="true"
                          :value="inputValue.overallPlan.monthlyParkingLot.numberOfUnits"
                          @input="inputNumberOfHouses($event, 7)"
                          :loading="loading"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">台</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        工事費
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="constructionCosts(7) | currency"
                          :loading="loading"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">万円</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        自己資金
                      </b-col>
                      <b-col class="component">
                        <allyText
                          rules="required|min_value:0|max_value:999999"
                          name="inputValue-overallPlan-monthlyParkingLot-personalFunds"
                          type="number"
                          mode="Edit"
                          title="月極駐車場:自己資金"
                          :nonAppear="true"
                          :value="personalFunds(7)"
                          @input="inputPersonalFunds($event, 7)"
                          :separation="true"
                          :loading="loading"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">万円</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        借入金額
                      </b-col>
                      <b-col class="component">
                        <b-form-input disabled class="currency" :value="loanAmount(7) | currency" />
                      </b-col>
                      <b-col cols="2" class="unit">万円</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        借入金利
                      </b-col>
                      <b-col class="component">
                        <allyText
                          class="currency"
                          rules="required|min_value:0|max_value:100"
                          type="number"
                          step="0.01"
                          mode="Edit"
                          title="月極駐車場:借入金利"
                          :nonAppear="true"
                          :value="setBorrowingRate(7)"
                          @input="inputBorrowingRate($event, 7)"
                          :loading="loading"
                          :floatLength="2"
                          :separation="true"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">％</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        借入期間
                      </b-col>
                      <b-col class="component">
                        <allyText
                          rules="required|min_value:0|max_value:45"
                          type="number"
                          mode="Edit"
                          title="月極駐車場:借入期間"
                          :nonAppear="true"
                          :value="setBorrowingPeriod(7)"
                          @input="inputBorrowingPeriod($event, 7)"
                          :loading="loading"
                          :separation="true"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">年</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        固都税 土地
                      </b-col>
                      <b-col class="component" v-b-popover.hover="'固定資産税・都市計画税のこと。'">
                        <b-form-input disabled class="currency" :value="taxLand(7) | currency" />
                      </b-col>
                      <b-col cols="2" class="unit">円/年</b-col>
                    </b-row>
                    <b-row>
                      <b-col id="tt5" class="title">
                        手取額
                        <b style="color: gray">※税抜</b>
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="monthlyIncomeExcludingTax(7) | currency"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">円/月</b-col>
                    </b-row>
                    <b-row>
                      <b-col id="tt6" class="title">
                        手取額
                        <b style="color: gray">※税込</b>
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="monthlyIncomeIncludingTax(7) | currency"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">円/月</b-col>
                    </b-row>
                  </b-container>
                </b-card>
                <b-card header="貸地" id="rentLand">
                  <b-container>
                    <b-row>
                      <b-col class="title">
                        地代収入
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="incomeLandRent() | currency"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">円/月</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        固都税 土地
                      </b-col>
                      <b-col class="component" v-b-popover.hover="'固定資産税・都市計画税のこと。'">
                        <b-form-input disabled class="currency" :value="taxLand(9) | currency" />
                      </b-col>
                      <b-col cols="2" class="unit">円/年</b-col>
                    </b-row>
                    <b-row>
                      <b-col id="tt7" class="title">
                        手取額
                        <b style="color:gray;">※税抜</b>
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="monthlyIncomeExcludingTax(9) | currency"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">円/月</b-col>
                    </b-row>
                    <b-row>
                      <b-col id="tt8" class="title">
                        手取額
                        <b style="color: gray">※税込</b>
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="monthlyIncomeIncludingTax(9) | currency"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">円/月</b-col>
                    </b-row>
                  </b-container>
                </b-card>
                <b-card header="更地" id="vacantLand">
                  <b-container>
                    <b-row>
                      <b-col class="title">
                        年間維持管理費
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="
                            (master.defaultValue.vacantLotOrLand.annualMaintenanceFee * 10000)
                              | currency
                          "
                        />
                      </b-col>
                      <b-col cols="2" class="unit">円/年</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        固都税 土地
                      </b-col>
                      <b-col class="component" v-b-popover.hover="'固定資産税・都市計画税のこと。'">
                        <b-form-input disabled class="currency" :value="taxLand(10) | currency" />
                      </b-col>
                      <b-col cols="2" class="unit">円/年</b-col>
                    </b-row>
                    <b-row>
                      <b-col id="tt9" class="title">
                        手取額
                        <b style="color:gray;">※税抜</b>
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="monthlyIncomeExcludingTax(10) | currency"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">円/月</b-col>
                    </b-row>
                    <b-row>
                      <b-col id="tt10" class="title">
                        手取額
                        <b style="color:gray;">※税込</b>
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="monthlyIncomeIncludingTax(10) | currency"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">円/月</b-col>
                    </b-row>
                  </b-container>
                </b-card>
                <b-card header="売却" id="sale">
                  <b-container>
                    <b-row>
                      <b-col class="title">
                        売却価格
                      </b-col>
                      <!--
                      <b-col class="component">
                        <b-form-input class="currency" :value="salePrice() | currency" />
                      </b-col>
                      -->
                      <b-col class="component">
                        <allyText
                          rules="required"
                          title="売却価格|min_value:0|max_value:99999999"
                          type="number"
                          :floatLength="0"
                          :value="inputValue.overallPlan.sale.salePrice"
                          @input="inputSalePrice($event)"
                          :loading="loading"
                          :nonAppear="true"
                          :separation="true"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">万円</b-col>
                    </b-row>
                    <!--
                    <b-row>
                      <b-col class="title">
                        購入時の価格
                      </b-col>
                      <b-col class="component">
                        <allyText
                          name="overallPlan-sale-priceAtTheTimeOfPurchase"
                          title="購入時の価格"
                          type="number"
                          min="0"
                          max="999999"
                          v-model="inputValue.overallPlan.sale.priceAtTheTimeOfPurchase"
                          :loading="loading"
                          :nonAppear="true"
                          :separation="true"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">万円</b-col>
                    </b-row>
                    -->
                    <b-row>
                      <b-col class="title">
                        取得費用
                      </b-col>
                      <b-col class="component">
                        <allyText
                          rules="required|min_value:0|max_value:99999999"
                          title="取得費"
                          type="number"
                          :value="inputValue.overallPlan.sale.acquisitionCost"
                          @input="inputAcquisitionCost($event)"
                          :loading="loading"
                          :nonAppear="true"
                          :separation="true"
                          :tips="
                            '取得費用が不明な場合、または売却価格の５％未満の場合は売却価格×５％で計算してください。'
                          "
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">万円</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        所有期間
                      </b-col>
                      <b-col class="component">
                        <allyText
                          rules="required|min_value:0|max_value:100"
                          name="overallPlan-sale-periodOfOwnership"
                          title="所有期間"
                          type="number"
                          v-model="inputValue.overallPlan.sale.periodOfOwnership"
                          :loading="loading"
                          :nonAppear="true"
                          :separation="true"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">年</b-col>
                    </b-row>
                    <!--
                    <b-row>
                      <b-col class="title">
                        取得価格
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="purchasePrice() | currency"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">万円</b-col>
                    </b-row>
                    -->
                    <b-row>
                      <b-col class="title">
                        売却時仲介手数料
                      </b-col>
                      <b-col class="component">
                        <!--
                        <allyText
                          rules="required"
                          title="売却時仲介手数料"
                          type="number"
                          min="0"
                          max="99999999"
                          v-model="inputValue.overallPlan.sale.agencyFees"
                          :loading="loading"
                          :nonAppear="true"
                          :separation="true"
                        />
                        -->
                        <b-form-input disabled class="currency" :value="agencyFees() | currency" />
                      </b-col>
                      <b-col cols="2" class="unit">万円</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        その他譲渡費用
                      </b-col>
                      <b-col class="component">
                        <!--
                        <b-form-input
                          disabled
                          class="currency"
                          :value="otherTransferCosts() | currency"
                        />
                        -->
                        <allyText
                          rules="required|min_value:0|max_value:99999999"
                          title="その他譲渡費用"
                          type="number"
                          :value="inputValue.overallPlan.sale.otherTransferCosts"
                          @input="inputOtherTransferCosts($event)"
                          :loading="loading"
                          :nonAppear="true"
                          :separation="true"
                          roundType="down"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">万円</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        譲渡所得税率期間
                      </b-col>
                      <b-col class="component">
                        <!-- <b-form-radio
                          v-model="inputValue.overallPlan.sale.termOfTransferIncomeTax"
                          button-variant="outline-ally"
                          class="ally-radio incomeTaxRate"
                          value="short"
                          size="md"
                          button
                          >短期</b-form-radio
                        >
                        <b-form-radio
                          v-model="inputValue.overallPlan.sale.termOfTransferIncomeTax"
                          button-variant="outline-ally"
                          class="ally-radio incomeTaxRate"
                          value="long"
                          size="md"
                          button
                          >長期</b-form-radio
                        > -->
                        <allySelect
                          :items="[
                            { text: '短期', value: 'short' },
                            { text: '長期', value: 'long' },
                          ]"
                          v-model="inputValue.overallPlan.sale.termOfTransferIncomeTax"
                          mode="View"
                        />
                      </b-col>
                      <b-col cols="2" class="unit"></b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        譲渡所得税
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="transferIncomeTax() | currency"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">円</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        手取額
                      </b-col>
                      <b-col class="component">
                        <b-form-input disabled class="currency" :value="netProceeds() | currency" />
                      </b-col>
                      <b-col cols="2" class="unit">円</b-col>
                    </b-row>
                    <b-row>
                      <b-col class="title">
                        年間運用利回り
                      </b-col>
                      <b-col class="component">
                        <b-form-input
                          disabled
                          class="currency"
                          :value="master.defaultValue.sale.annualInvestmentYield"
                        />
                      </b-col>
                      <b-col cols="2" class="unit">％</b-col>
                    </b-row>
                  </b-container>
                </b-card>
              </b-card-group>
            </b-tab>
          </b-tabs>
        </b-tab>
        <b-tab title="グラフ" id="graph" @click="updateCharts" lazy>
          <b-card header-tag="header">
            <template v-slot:header>
              {{ selectCharts[chartId].text }}
            </template>
            <b-carousel
              id="carouselChart"
              v-model="chartId"
              background="white"
              controls
              img-width="100"
              img-height="50"
              :interval="0"
            >
              <b-carousel-slide>
                <template v-slot:img>
                  <div style="position: relative">
                    <allyLineChart
                      :data="chartData[0]"
                      :options="chartOptions[0]"
                      :styles="chartStyles"
                    />
                    <div
                      style="background-color: white; font-size: 12px;  width: 1.8rem; height: 45vh; position: absolute; top: 0%; left: 0%; display: inline-block; vertical-align: middle"
                    >
                      <span style="position: absolute; top: 45%;">
                        万円
                      </span>
                    </div>
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template v-slot:img>
                  <div style="position: relative">
                    <allyBarChartV
                      :data="chartData[1]"
                      :options="chartOptions[1]"
                      :styles="chartStyles"
                    />
                    <div
                      style="background-color: white; font-size: 12px;  width: 1.8rem; height: 45vh; position: absolute; top: 0%; left: 0%; display: inline-block; vertical-align: middle"
                    >
                      <span style="position: absolute; top: 45%;">
                        万円
                      </span>
                    </div>
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template v-slot:img>
                  <div style="position: relative">
                    <allyBarChartV
                      :data="chartData[2]"
                      :options="chartOptions[2]"
                      :styles="chartStyles"
                    />
                    <div
                      style="background-color: white; font-size: 12px;  width: 1.8rem; height: 45vh; position: absolute; top: 0%; left: 0%; display: inline-block; vertical-align: middle"
                    >
                      <span style="position: absolute; top: 45%;">
                        万円
                      </span>
                    </div>
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template v-slot:img>
                  <div style="position: relative">
                    <allyLineChart
                      :data="chartData[3]"
                      :options="chartOptions[3]"
                      :styles="chartStyles"
                    />
                    <div
                      style="background-color: white; font-size: 12px;  width: 1.8rem; height: 45vh; position: absolute; top: 0%; left: 0%; display: inline-block; vertical-align: middle"
                    >
                      <span style="position: absolute; top: 45%;">
                        万円
                      </span>
                    </div>
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template v-slot:img>
                  <div style="position: relative">
                    <allyBarChartV
                      :data="chartData[4]"
                      :options="chartOptions[4]"
                      :styles="chartStyles2"
                    />
                    <div
                      style="background-color: rgba(0, 0, 0, 0); font-size: 12px;  width: 1.8rem; height: 45vh; position: absolute; top: 0%; left: 0%; display: inline-block; vertical-align: middle"
                    >
                      <span style="position: absolute; top: 45%;">
                        万円
                      </span>
                    </div>
                    <div
                      style="background-color: rgba(0, 0, 0, 0); font-size: 12px;  width: 1.8rem; height: 45vh; position: absolute; top: 0%; right: 0%; display: inline-block; vertical-align: middle"
                    >
                      <span style="position: absolute; top: 45%;">
                        ％
                      </span>
                    </div>
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template v-slot:img>
                  <div style="position: relative">
                    <allyBarChartV
                      :data="chartData[5]"
                      :options="chartOptions[5]"
                      :styles="chartStyles"
                    />
                    <div
                      style="background-color: white; font-size: 12px;  width: 1.8rem; height: 45vh; position: absolute; top: 0%; left: 0%; display: inline-block; vertical-align: middle"
                    >
                      <span style="position: absolute; top: 45%;">
                        万円
                      </span>
                    </div>
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template v-slot:img>
                  <div style="position: relative">
                    <allyLineChart
                      :data="chartData[6]"
                      :options="chartOptions[6]"
                      :styles="chartStyles"
                    />
                    <div
                      style="background-color: white; font-size: 12px;  width: 1.8rem; height: 45vh; position: absolute; top: 0%; left: 0%; display: inline-block; vertical-align: middle"
                    >
                      <span style="position: absolute; top: 45%;">
                        万円
                      </span>
                    </div>
                  </div>
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template v-slot:img>
                  <allyBarChartH
                    :data="chartData[7]"
                    :options="chartOptions[7]"
                    :styles="chartStyles"
                /></template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template v-slot:img>
                  <allyBarChartH
                    :data="chartData[8]"
                    :options="chartOptions[8]"
                    :styles="chartStyles"
                /></template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template v-slot:img>
                  <div style="position: relative">
                    <allyBarChartV
                      :data="chartData[9]"
                      :options="chartOptions[9]"
                      :styles="chartStyles"
                    />
                    <div
                      style="background-color: white; font-size: 12px;  width: 1.8rem; height: 45vh; position: absolute; top: 0%; left: 0%; display: inline-block; vertical-align: middle"
                    >
                      <span style="position: absolute; top: 45%;">
                        万円
                      </span>
                    </div>
                  </div>
                </template>
              </b-carousel-slide>
            </b-carousel>
            <b-card-footer>
              <b-form-radio-group
                id="radioGroup1"
                v-model="chartId"
                buttons
                size="sm"
                name="radioGroup1"
                style="width:100%;"
              >
                <b-form-radio
                  v-for="n in [0, 1, 2, 3, 4]"
                  :value="selectCharts[n].value"
                  v-bind:key="n"
                  style="width:20%;font-size:x-small;"
                  button-variant="outline-primary"
                  class="align-middle"
                >
                  <span style="font-size: 15px">{{ selectCharts[n].text }}</span>
                </b-form-radio>
              </b-form-radio-group>
              <b-form-radio-group
                id="radioGroup2"
                v-model="chartId"
                buttons
                size="sm"
                name="radioGroup2"
                style="width:100%;padding-top:1px;"
              >
                <b-form-radio
                  v-for="n in [5, 6, 7, 8, 9]"
                  :value="selectCharts[n].value"
                  v-bind:key="n"
                  style="width:20%;font-size:x-small;"
                  button-variant="outline-primary"
                  class="align-middle"
                >
                  <span style="font-size: 15px">{{ selectCharts[n].text }}</span>
                </b-form-radio>
              </b-form-radio-group>
            </b-card-footer>
          </b-card>
        </b-tab>
      </b-tabs>
      <b-tooltip
        target="tt1"
        placement="bottomright"
        title="土地の固定資産税および都市計画税を除いた手取額"
      >
      </b-tooltip>
      <b-tooltip
        target="tt2"
        placement="bottomright"
        title="土地の固定資産税および都市計画税を含めた手取額"
      >
      </b-tooltip>
      <b-tooltip
        target="tt3"
        placement="bottomright"
        title="土地の固定資産税および都市計画税を除いた手取額"
      >
      </b-tooltip>
      <b-tooltip
        target="tt4"
        placement="bottomright"
        title="土地の固定資産税および都市計画税を含めた手取額"
      >
      </b-tooltip>
      <b-tooltip
        target="tt5"
        placement="bottomright"
        title="土地の固定資産税および都市計画税を除いた手取額"
      >
      </b-tooltip>
      <b-tooltip
        target="tt6"
        placement="bottomright"
        title="土地の固定資産税および都市計画税を含めた手取額"
      >
      </b-tooltip>
      <b-tooltip
        target="tt7"
        placement="bottomright"
        title="土地の固定資産税および都市計画税を除いた手取額"
      >
      </b-tooltip>
      <b-tooltip
        target="tt8"
        placement="bottomright"
        title="土地の固定資産税および都市計画税を含めた手取額"
      >
      </b-tooltip>
      <b-tooltip
        target="tt9"
        placement="bottomright"
        title="土地の固定資産税および都市計画税を除いた手取額"
      >
      </b-tooltip>
      <b-tooltip
        target="tt10"
        placement="bottomright"
        title="土地の固定資産税および都市計画税を含めた手取額"
      >
      </b-tooltip>
    </b-card>
  </div>
</template>
<script>
  import Vue from 'vue';
  import InputSiteConditions from './InputSiteConditions';
  import InputLocationConditions from './InputLocationConditions';
  import chartOptions from './chartOptions';
  import { mapState } from 'vuex';
  //----------
  // 定数定義
  //----------
  // 色設定
  const colors = [
    'rgba(192,0,0,0.5)',
    'rgba(255,0,0,0.5)',
    'rgba(255,192,0,0.5)',
    'rgba(255,255,0,0.5)',
    'rgba(146,208,80,0.5)',
    'rgba(0,176,80,0.5)',
    'rgba(0,176,240,0.5)',
    'rgba(144,238,144,0.5)',
    'rgba(0,112,192,0.5)',
    'rgba(0,32,96,0.5)',
    'rgba(112,48,160,0.5)',
  ];
  export default {
    props: {
      inputValue: Object,
      calcFlg: Boolean,
    },
    components: {
      InputSiteConditions,
      InputLocationConditions,
    },
    data: function() {
      return {
        chartOptions: chartOptions(false),
        chartStyles: {
          height: '50vh',
          position: 'relative',
        },
        chartStyles2: {
          height: '50vh',
          width: '94%',
          position: 'relative',
          left: '30px',
        },
        taxRate: {
          property: null, // 固定資産税率
          cityPlanning: null, // 都市計画税率
          income: {
            shortTerm: null, // 短期所得税税率
            longTerm: null, // 長期所得税税率
            specialCase: null, // 10年超所有軽減税率の特例
          },
        },
        value: {},
        loading: null,
        chartId: 0,
        selectCharts: [
          { value: 0, text: 'ローン残高' },
          { value: 1, text: '月間収支の比較' },
          { value: 2, text: '土地の固定資産税比較(年間)' },
          { value: 3, text: '現金収支累計' },
          { value: 4, text: '賃貸経営安定度' },
          { value: 5, text: '活用法別相続財産評価額（活用直後）' },
          { value: 6, text: '将来の活用法別相続財産評価額の推移' },
          { value: 7, text: '5年後の相続税額と収支累計' },
          { value: 8, text: '10年後の相続税額と収支累計' },
          { value: 9, text: '相続税支払後の残資産' },
        ],
        // グラフデータ
        chartData: [null, null, null, null, null, null, null, null, null, null],
        // 入力値
        modeRent: '1',
        // images
        landPicture: null,
        guideMap: null,
        vacantLandAndLeasedLand: null,
        monthlyParkingLot: null,
        groupRental: null,
        groupRentalFamily: null,
        detachedHousesForRent: null,
        sellAndCashOut: null,
        // 間取り
        floorPlanItems: [
          { text: '1R', value: '1R' },
          { text: '1K', value: '1K' },
          { text: '1LDK', value: '1LDK' },
          { text: '2LDK', value: '2LDK' },
          { text: '3LDK', value: '3LDK' },
          { text: '4LDK', value: '4LDK' },
          { text: '5LDK', value: '5LDK' },
        ],
        // 賃貸のみ
        columns: [
          { id: 0, text: '集合型賃貸①', checked: false },
          { id: 1, text: '集合型賃貸②', checked: false },
          { id: 2, text: '集合型賃貸③', checked: false },
          { id: 3, text: '集合型賃貸④', checked: false },
          { id: 4, text: '戸建賃貸①', checked: false },
          { id: 5, text: '戸建賃貸②', checked: false },
          { id: 6, text: '戸建賃貸③', checked: false },
        ],
        // 構造
        structureItems: [
          { text: '木造', value: '木造' },
          { text: '鉄骨鉄筋コンクリート造', value: '鉄骨鉄筋コンクリート造' },
          { text: '鉄筋コンクリート造', value: '鉄筋コンクリート造' },
          { text: '軽量鉄骨造', value: '軽量鉄骨造' },
          { text: '重量鉄骨造', value: '重量鉄骨造' },
        ],
        kindItems: [
          { text: '事務所', value: '事務所' },
          { text: '店舗', value: '店舗' },
          { text: '住宅', value: '住宅' },
          { text: '旅館/ホテル', value: '旅館/ホテル' },
          { text: '病院/診療所', value: '病院/診療所' },
          { text: '工場/倉庫', value: '工場/倉庫' },
        ],
        // surfaceArea: this.inputValue.siteConditions.siteInformation.surfaceArea, // 敷地面積(坪):TODO
        // roadsideLandPrice: this.inputValue.siteConditions.siteInformation.roadsideLandPrice, // 路線価(円/坪):TODO
        // 上昇率
        climingRate: {
          deferralPeriod: null, // 据え置き期間
          // 賃料
          rent: {
            changePeriod: null, // 変動周期
            coefficientOfVariation: null, // 変動係数
          },
          // 固都税
          tax: {
            // 土地
            land: {
              changePeriod: null, // 変動周期
              coefficientOfVariation: null, // 変動係数
            },
            //建物
            building: {
              木造: {
                changePeriod: null, // 変動周期
                coefficientOfVariation: null, // 変動係数
              },
              軽量鉄骨造: {
                changePeriod: null, // 変動周期
                coefficientOfVariation: null, // 変動係数
              },
              重量鉄骨造: {
                changePeriod: null, // 変動周期
                coefficientOfVariation: null, // 変動係数
              },
              鉄筋コンクリート造: {
                changePeriod: null, // 変動周期
                coefficientOfVariation: null, // 変動係数
              },
              鉄骨鉄筋コンクリート造: {
                changePeriod: null, // 変動周期
                coefficientOfVariation: null, // 変動係数
              },
            },
          },
          // 維持費
          maintenanceCosts: {
            changePeriod: null, // 変動周期
            coefficientOfVariation: null, // 変動係数
          },
        },
      };
    },
    watch: {
      //チェックの保存（できる限りcolumnを弄りたくないのでwatchで監視、rentalPropertyに保存する）
      columns: {
        handler: function(value) {
          value.forEach((item, index) => {
            this.inputValue.overallPlan.rentalProperty[index].checked = item.checked;
          });
        },
        deep: true,
      },
      //年間修繕費
      annualRepairCosts: function(value, oldValue) {
        value.forEach((value, index) => {
          if (value != oldValue[index]) {
            this.inputValue.overallPlan.rentalProperty[index].annualRepairCosts = Math.floor(
              value * this.master.defaultValue.rentalProperty[index].repairCostsPerUnit * 10000
            );
          }
        });
      },
      //戸数目安
      numberOfHouses: function() {
        if (
          this.inputValue.siteConditions.siteInformation.floorAreaRatio != null &&
          this.surfaceArea != null
        ) {
          this.inputValue.overallPlan.rentalProperty.forEach((value, index) => {
            // let number = this.counts(index); //戸数
            let requireArea = this.master.defaultValue.rentalProperty[index].requireArea; //間取りの必要面積
            let surfaceArea = this.surfaceArea; //敷地面積
            let floorAreaRatio = this.inputValue.siteConditions.siteInformation.floorAreaRatio; //容積率
            let parkingLot = this.master.defaultValue.rentalProperty[index].parkingLot; //一戸当たりの必要駐車場台数
            let parkingSquare = this.master.defaultValue.rentalProperty[index].parkingSquare; //1台当たりの必要面積

            //最大延床面積 = 敷地面積 * 容積率
            let totalFloorArea = surfaceArea * (floorAreaRatio / 100);
            //最大戸数 = 最大延床面積 / 間取りの必要面積
            let maxNumber = Math.floor(totalFloorArea / requireArea);

            //最大戸数 >= 戸数 ⋀ 必要面積 + (戸数 * 一戸当たりの必要駐車場台数 * 1台当たりの必要面積) <= 敷地面積を満たす最大値を戸数の目安として出力
            let x = (requireArea + parkingLot * parkingSquare) * maxNumber;
            while (x > surfaceArea) {
              maxNumber--;
              x = requireArea + maxNumber * parkingLot * parkingSquare;
            }
            value.numberOfHouses = maxNumber;
          });
        }
      },
      //相続税路線価
      'inputValue.siteConditions.siteInformation.roadsideLandPrice': function() {
        this.calcPriceSub();
      },
      //相続税路線価の単位
      'inputValue.siteConditions.siteInformation.roadsideLandPriceUnit': function() {
        this.calcPriceSub();
      },
      //固定資産税評価額の推定or実績
      'inputValue.siteConditions.siteInformation.presumption': function() {
        this.calcPriceSub(false);
      },
      countForFund: function(value, oldValue) {
        value.forEach((element, index) => {
          if (element != oldValue[index]) {
            var constructionCost = this.constructionCosts(index);
            this.personalFunds = { cost: constructionCost, id: index };
          }
        });
      },
      'inputValue.overallPlan.sale.periodOfOwnership': function(value) {
        if (value == null || Math.floor(value) <= 5) {
          this.inputValue.overallPlan.sale.termOfTransferIncomeTax = 'short';
        } else {
          this.inputValue.overallPlan.sale.termOfTransferIncomeTax = 'long';
        }
      },
      agencyFees: function() {
        this.inputValue.overallPlan.sale.agencyFees = this.agencyFees;
      },
      calcFlg: {
        handler(after) {
          if (after === true) {
            this.updateCharts();
          }
        },
      },
      'inputValue.siteConditions.siteInformation.surfaceArea': {
        deep: true,
        handler: function() {
          let numberOfUnits = Math.floor(this.surfaceArea / this.parkingAreaPerCar);
          this.inputValue.overallPlan.monthlyParkingLot.numberOfUnits = numberOfUnits;
        },
      },
      'inputValue.siteConditions.siteInformation.surfaceAreaUnit': {
        deep: true,
        handler: function() {
          let numberOfUnits = Math.floor(this.surfaceArea / this.parkingAreaPerCar);
          this.inputValue.overallPlan.monthlyParkingLot.numberOfUnits = numberOfUnits;
        },
      },
      surfaceArea: {
        handler: function() {
          this.autoSalesPrice();
        },
      },
      'inputValue.siteConditions.siteInformation.availableForSale': {
        handler: function() {
          this.autoSalesPrice();
        },
      },
    },
    filters: {
      // 数値書式編集
      currency(value) {
        return Math.floor(Number(value)).toLocaleString('ja-JP', {
          style: 'decimal',
        });
      },
    },
    computed: {
      ...mapState(['master']),
      // 構造
      structure: function() {
        return this.master.defaultValue.rentalProperty.map(val => val.structure);
      },
      kind: function() {
        return this.master.defaultValue.rentalProperty.map(val => val.kind);
      },
      //敷地面積単位換算（㎡として返す）
      surfaceArea: function() {
        if (this.inputValue.siteConditions.siteInformation.surfaceAreaUnit == 1) {
          //単位が㎡の場合
          return (
            Math.floor(Number(this.inputValue.siteConditions.siteInformation.surfaceArea) * 100) /
            100
          );
        } else {
          //単位が坪の場合
          return (
            Math.floor(Number(this.inputValue.siteConditions.siteInformation.surfaceArea) * 100) /
            100 /
            0.3025
          );
        }
      },
      //相続税路線価単位換算（㎡として返す）
      roadsideLandPrice: function() {
        if (this.inputValue.siteConditions.siteInformation.roadsideLandPriceUnit == 1) {
          //㎡の場合
          return Math.floor(
            Number(this.inputValue.siteConditions.siteInformation.roadsideLandPrice)
          );
        } else {
          //坪の場合
          return (
            Math.floor(Number(this.inputValue.siteConditions.siteInformation.roadsideLandPrice)) *
            0.3025
          );
        }
      },
      //固定資産税評価額
      propertyTaxAssessment: function() {
        return Math.floor(
          Number(this.inputValue.siteConditions.siteInformation.propertyTaxAssessment)
        );
      },
      //売却額（目安）
      availableForSale: function() {
        return Math.floor(Number(this.inputValue.siteConditions.siteInformation.availableForSale));
      },
      //月極駐車場：全体面積単位換算
      landArea: function() {
        if (this.master.defaultValue.monthlyParkingLot.landAreaUnit == 1) {
          //㎡の場合
          return this.master.defaultValue.monthlyParkingLot.landArea;
        } else {
          //坪の場合
          return this.master.defaultValue.monthlyParkingLot.landArea * 0.3025;
        }
      },
      //戸数の目安
      numberOfHouses: function() {
        return [this.surfaceArea, this.inputValue.siteConditions.siteInformation.floorAreaRatio];
      },
      //月極駐車場：１台当たりの面積
      parkingAreaPerCar: function() {
        if (this.master.defaultValue.monthlyParkingLot.parkingAreaPerCarUnit == 1) {
          //㎡の場合
          return this.master.defaultValue.monthlyParkingLot.parkingAreaPerCar;
        } else {
          //坪の場合
          return this.master.defaultValue.monthlyParkingLot.parkingAreaPerCar / 0.3025;
        }
      },
      // 建物固都税計算
      taxBuiding: function() {
        let buildings = Array(this.columns.length + 2).fill(null);
        return buildings.map((val, id) => {
          // 入力チェック
          // if (this.loading != false) {
          //   return null;
          // }
          var cost = this.cost(id); // 1戸当り工事費
          var num = this.counts(id); // 戸数
          var rate = this.assessmentRate(id); // 固定資産税評価率
          var structure = this.structure[id]; // 構造
          if (id < 7) {
            if (
              !this.columns[id].checked || // 未選択
              cost == null || // 1戸当り工事費
              num == null || // 戸数
              rate == null || // 固定資産税評価率
              structure == null // 構造
            ) {
              return null;
            }
          } else {
            //月極駐車場(建物がないため0を返す)
            if (id == 7) {
              return 0;
            }
            //店舗・事務所
            if (
              (id == 8 && cost == null) || // 1戸当り工事費
              num == null || // 戸数
              rate == null // 固定資産税評価率
            ) {
              return null;
            }
          }
          // 建物固都税計算
          return this.calcTaxBuilding({
            constructionCostPerUnit: cost, // 1戸当り工事費
            numberOfHouses: num, // 戸数
            propertyTaxAssessmentRate: rate, // 固定資産税評価率
            structure: structure, // 構造
            year: 0, // 年数
          });
        });
      },
      // 法定耐用年数
      legalServiceLife: function() {
        return this.columns.map((val, i) => {
          // マスタチェック
          if (this.master.legalServiceLife == null) {
            return null;
          }
          // 入力チェック
          if (
            // this.loading != false ||
            this.structure[i] == null || // 構造
            this.kind[i] == null // 用途
          ) {
            return null;
          }
          // マスタ検索
          var structure = this.structure[i];
          var kind = this.kind[i];
          for (let x of this.master.legalServiceLife) {
            if (structure == x[0] && kind == x[1]) {
              const result = Number(x[2]);
              this.master.defaultValue.rentalProperty[i].legalServiceLife = result;
              return result;
            }
          }
          return null;
        });
      },
      // 相続人数
      numberOfHeirs: function() {
        return this.actualInheritancePercentage.length;
      },
      // 実相続割合
      actualInheritancePercentage: function() {
        var ret = [];
        // 配偶者
        var spouse = Number(this.inputValue.siteConditions.ownerInformation.spouse);
        // 子供人数
        var children = Math.floor(
          Number(this.inputValue.siteConditions.ownerInformation.numberOfChildren)
        );
        // 親生存人数
        var parents = Math.floor(
          Number(this.inputValue.siteConditions.ownerInformation.numberOfParentsAlive)
        );
        // 被相続人兄弟姉妹
        var siblings = Math.floor(
          Number(this.inputValue.siteConditions.ownerInformation.siblingsOfTheDeceased)
        );
        //実割合の配列
        var spouseRealRatio = this.inputValue.siteConditions.ownerInformation.spouseRealRatio;
        if (spouse > 0) {
          if (children > 0) {
            ret.push({ heiress: '配偶者', percentage: 1 / 2, realPercentage: spouseRealRatio });
            for (let i = 0; i < children; i++) {
              ret.push({
                heiress: '子',
                percentage: 1 / 2 / children,
                realPercentage: this.inputValue.siteConditions.ownerInformation.childRealRatio[i],
              });
            }
          } else if (parents > 0) {
            ret.push({ heiress: '配偶者', percentage: 2 / 3, realPercentage: spouseRealRatio });
            for (let i = 0; i < parents; i++) {
              ret.push({
                heiress: '親',
                percentage: 1 / 3 / parents,
                realPercentage: this.inputValue.siteConditions.ownerInformation.parentRealRatio[i],
              });
            }
          } else if (siblings > 0) {
            ret.push({ heiress: '配偶者', percentage: 3 / 4, realPercentage: spouseRealRatio });
            for (let i = 0; i < siblings; i++) {
              ret.push({
                heiress: '兄弟姉妹',
                percentage: 1 / 4 / siblings,
                realPercentage: this.inputValue.siteConditions.ownerInformation.siblingRealRatio[i],
              });
            }
          } else {
            ret.push({ heiress: '配偶者', percentage: 1, realPercentage: spouseRealRatio });
          }
        } else {
          if (children > 0) {
            for (let i = 0; i < children; i++) {
              ret.push({
                heiress: '子',
                percentage: 1 / children,
                realPercentage: this.inputValue.siteConditions.ownerInformation.childRealRatio[i],
              });
            }
          } else if (parents > 0) {
            for (let i = 0; i < parents; i++) {
              ret.push({
                heiress: '親',
                percentage: 1 / parents,
                realPercentage: this.inputValue.siteConditions.ownerInformation.parentRealRatio[i],
              });
            }
          } else if (siblings > 0) {
            for (let i = 0; i < siblings; i++) {
              ret.push({
                heiress: '兄弟姉妹',
                percentage: 1 / siblings,
                realPercentage: this.inputValue.siteConditions.ownerInformation.siblingRealRatio[i],
              });
            }
          }
        }
        return ret;
      },
      // 土地固都税計算
      taxLand: function() {
        return function(id) {
          // 入力チェック
          // if (this.loading != false) {
          //   return null;
          // }
          if (
            (id < 7 && !this.columns[id].checked) || // 未選択
            this.surfaceArea == null || // 敷地面積
            this.propertyTaxAssessment == 0 // 固定資産税評価額
          ) {
            return null;
          }
          // 土地固都税計算
          var isResidence = false;
          if (this.structure[id] != null) {
            if (this.kind[id] === '住宅') {
              isResidence = true;
            }
          }
          return this.calcTaxLand({
            surfaceArea: this.surfaceArea, // 敷地面積
            numberOfHouses: this.counts(id), // 戸数
            propertyTaxAssessment: this.propertyTaxAssessment, // 固定資産税評価額
            isResidence: isResidence, // 住宅の場合、true
            year: 0, // 年数
            id: id,
          });
        };
      },
      // 工事費
      constructionCosts: function() {
        return function(id) {
          var cost = this.cost(id); // 1戸当り工事費
          var num = this.counts(id); // 戸数
          var area = this.tsubo(this.surfaceArea); // 敷地面積
          // 入力チェック
          if (
            (id < 7 && !this.columns[id].checked) || // 未選択
            cost == null || // 1戸当り工事費
            num == null // 戸数
          ) {
            return null;
          }
          if (id == 8) {
            // 店舗・事務所
            if (this.inputValue.overallPlan.storeOffice.totalFlooraSpace) {
              return Math.floor(
                cost *
                  this.tsubo(
                    Math.floor(
                      Number(this.inputValue.overallPlan.storeOffice.totalFlooraSpace) * 100
                    ) / 100
                  )
              );
            }
            return 0;
          } else if (id == 7) {
            //月極駐車場
            // //工事費の算出時に自己資金も割り出す
            // this.inputValue.overallPlan.monthlyParkingLot.personalFunds =
            //   (cost * area * this.master.defaultValue.monthlyParkingLot.equityRatio) / 100;
            return Math.floor(cost * area); //万円/坪 x 坪
          }
          // 1戸当り工事費×戸数
          return cost * num;
        };
      },
      countForFund: function() {
        return [
          this.inputValue.overallPlan.rentalProperty[0].numberOfHouses,
          this.inputValue.overallPlan.rentalProperty[1].numberOfHouses,
          this.inputValue.overallPlan.rentalProperty[2].numberOfHouses,
          this.inputValue.overallPlan.rentalProperty[3].numberOfHouses,
          this.inputValue.overallPlan.rentalProperty[4].numberOfHouses,
          this.inputValue.overallPlan.rentalProperty[5].numberOfHouses,
          this.inputValue.overallPlan.rentalProperty[6].numberOfHouses,
          // this.inputValue.overallPlan.monthlyParkingLot.numberOfUnits,   //工事費が台数に依存しないのでコメントアウト
        ];
      },
      forAgenceFees: function() {
        return [this.surfaceArea, this.availableForSale];
      },
      // //固定資産税評価額計算要素
      // propertyTaxAssessment: function() {
      //   return [
      //     this.inputValue.siteConditions.siteInformation.presumption,
      //     this.inputValue.siteConditions.siteInformation.roadsideLandPrice,
      //   ];
      // },
      //年間修繕費計算要素
      annualRepairCosts: function() {
        let element = [];
        //計算に使うのは一戸当たりの修繕費と戸数だが、画面で変更されるのは戸数だけなので、戸数の配列を返す
        this.inputValue.overallPlan.rentalProperty.forEach(value => {
          element.push(value.numberOfHouses);
        });
        return element;
      },
      //自己資金
      personalFunds: {
        get: function() {
          return function(id) {
            return id < 7
              ? this.inputValue.overallPlan.rentalProperty[id].personalFunds
              : id == 7
              ? this.inputValue.overallPlan.monthlyParkingLot.personalFunds
              : this.inputValue.overallPlan.storeOffice.personalFunds;
          };
        },
        set: function(constructionCost) {
          if (constructionCost.id < 7) {
            this.inputValue.overallPlan.rentalProperty[
              constructionCost.id
            ].personalFunds = Math.floor(
              (constructionCost.cost *
                this.master.defaultValue.rentalProperty[constructionCost.id].equityRatio) /
                100
            );
          } else if (constructionCost.id == 7) {
            //※月極駐車場の工事費は台数に関係ないが、台数が変化する度に自己資金が再計算されていた
            this.inputValue.overallPlan.monthlyParkingLot.personalFunds = Math.floor(
              (constructionCost.cost * this.master.defaultValue.monthlyParkingLot.equityRatio) / 100
            );
          } else {
            //※店舗・事務所には戸数・台数が無いためここには来ない
            this.inputValue.overallPlan.storeOffice.personalFunds = Math.floor(
              (constructionCost.cost * this.master.defaultValue.storeOffice.equityRatio) / 100
            );
          }
        },
      },
      // 借入金額
      loanAmount: function() {
        return function(id) {
          // 入力チェック
          if (
            (id < 7 && !this.columns[id].checked) || // 未選択
            this.cost(id) == null || // 1戸当り工事費
            this.counts(id) == null // 戸数
          ) {
            return null;
          }
          // 店舗・事務所用係数
          if (id == 8) {
            if (this.inputValue.overallPlan.storeOffice.totalFlooraSpace) {
              return Math.max(
                0,
                Math.floor(
                  this.cost(id) *
                    this.tsubo(
                      Math.floor(
                        Number(this.inputValue.overallPlan.storeOffice.totalFlooraSpace) * 100
                      ) / 100
                    ) *
                    (1 + this.expenseRate(id) / 100)
                ) - this.funds(id)
              );
            } else {
              return 0;
            }
          }

          var tmp = 0;
          if (id == 7) {
            //月極駐車場
            //工事費(万円/坪)x敷地面積(坪)x(1+初年度経費率(=0))－自己資金
            tmp =
              Math.floor(
                this.cost(id) * this.tsubo(this.surfaceArea) * (1 + this.expenseRate(id) / 100)
              ) - this.funds(id);
          } else {
            // (1戸当り工事費×戸数)×(1+初年度経費率)－自己資金
            tmp =
              Math.floor(this.cost(id) * this.counts(id) * (1 + this.expenseRate(id) / 100)) -
              this.funds(id);
          }
          // 負の場合は０
          return Math.max(tmp, 0);
        };
      },
      // 月の手取額(土地固都税除く）
      monthlyIncomeExcludingTax: function() {
        return function(id) {
          var tmp = null;
          switch (id) {
            // 貸地
            case 9:
              // 月地代収入
              tmp =
                this.incomeLandRent() *
                (1 - this.master.defaultValue.leasedLand.managementCostRate / 100);
              // tmp = this.incomeLandRent();
              break;
            // 更地
            case 10:
              // －(年間維持管理費/12)
              if (this.master.defaultValue.vacantLotOrLand.annualMaintenanceFee != null) {
                tmp = -(
                  (this.master.defaultValue.vacantLotOrLand.annualMaintenanceFee * 10000) /
                  12
                );
              }
              break;
            // 賃貸
            default:
              // 入力チェック
              if (
                (id < 7 && !this.columns[id].checked) || // 未選択
                this.cost(id) == null || // 1戸当り工事費
                this.counts(id) == null || // 戸数
                this.vacancyRate(id) == null // 空室率
              ) {
                return null;
              }
              // 月額賃料×戸数×(1－空室率)
              // － (年間修繕費＋建物固都税＋年間管理費)/12
              // － (ローン返済額)
              tmp =
                this.monthlyRent(id) * 10000 * this.counts(id) * (1 - this.vacancyRate(id) / 100) -
                (this.repairCosts(id) + this.taxBuiding[id] + this.managementCosts(id)) / 12 -
                this.loanRepaymentAmount(id);
              break;
          }
          return Math.floor(tmp);
        };
      },
      // 月の手取額(土地固都税含む）
      monthlyIncomeIncludingTax: function() {
        return function(id) {
          var tmp = null;
          switch (id) {
            // 貸地
            case 9:
              // 月地代収入－(土地固都税/12)
              tmp = this.monthlyIncomeExcludingTax(id) - this.taxLand(id) / 12;
              break;
            // 更地
            case 10:
              // －(年間維持管理費＋土地固都税)/12
              if (this.master.defaultValue.vacantLotOrLand.annualMaintenanceFee != null) {
                tmp =
                  -(
                    this.master.defaultValue.vacantLotOrLand.annualMaintenanceFee * 10000 +
                    this.taxLand(id)
                  ) / 12;
              }
              break;
            // 賃貸
            default:
              // 入力チェック
              if (
                (id < 7 && !this.columns[id].checked) || // 未選択
                this.cost(id) == null || // 1戸当り工事費
                this.counts(id) == null || // 戸数
                this.vacancyRate(id) == null // 空室率
              ) {
                return null;
              }
              // 月額賃料×戸数×(1－空室率)
              // － (年間修繕費＋建物固都税＋土地固都税＋年間管理費)/12
              // － (ローン返済額)
              tmp =
                this.monthlyRent(id) * 10000 * this.counts(id) * (1 - this.vacancyRate(id) / 100) -
                (this.repairCosts(id) +
                  this.taxBuiding[id] +
                  this.taxLand(id) +
                  this.managementCosts(id)) /
                  12 -
                this.loanRepaymentAmount(id);
              break;
          }
          return Math.floor(tmp);
        };
      },
      // 年間修繕費
      repairCosts: function() {
        return function(id) {
          // 入力チェック
          if (id < 7) {
            // 賃貸物件
            if (this.columns[id].checked) {
              // 選択中
              // computedで計算済みの年間修繕費(＝戸数 x １戸当りの修繕費)
              return Math.floor(
                Number(this.inputValue.overallPlan.rentalProperty[id]?.annualRepairCosts)
              );
            }
            // 未選択
            return null;
          }

          // その他
          return 0;
        };
      },
      // 年間管理費
      managementCosts: function() {
        return function(id) {
          // 入力チェック
          if (
            (id < 7 && !this.columns[id].checked) || // 未選択
            this.monthlyRent(id) == null || // 月額賃料
            this.counts(id) == null || // 戸数
            this.managementRate(id) == null // 管理費率
          ) {
            return null;
          }
          // (月額賃料×12)×戸数×管理費率
          var tmp =
            this.monthlyRent(id) * 10000 * 12 * this.counts(id) * (this.managementRate(id) / 100);
          return Math.floor(tmp);
        };
      },
      // ローン返済額
      loanRepaymentAmount: function() {
        return function(id) {
          // 借入金利
          var borrowingRate = this.borrowingRate(id);
          // 借入期間
          var borrowingPeriod = this.borrowingPeriod(id);
          // 入力チェック
          if (
            (id < 7 && !this.columns[id].checked) || // 未選択
            borrowingRate == null || // 借入金利
            borrowingPeriod == null // 借入期間
          ) {
            return null;
          }
          // (借入金額*借入金利)/(1-POWER(1+借入金利,-借入期間))
          var rate = borrowingRate / 100 / 12;
          var period = borrowingPeriod * 12;
          if (Math.pow(1 + rate, -period) == 1) {
            return null;
          }
          var amount = (this.loanAmount(id) * 10000 * rate) / (1 - Math.pow(1 + rate, -period));
          return amount;
        };
      },
      // 月地代収入
      incomeLandRent: function() {
        return function() {
          // 坪当りの月地代
          var rentAmount = this.master.defaultValue.leasedLand.monthlyLandRentPerSquareMeter;
          // 時価に対する年間地代利回り
          var rentRate = this.master.defaultValue.leasedLand.annualLandRentYieldToMarketValue;
          // 売却可能額
          var availableForSale = this.availableForSale;
          switch (Number(this.master.defaultValue.leasedLand.modeRent)) {
            // 月地代×敷地面積
            case 1:
              if (rentAmount != null && this.surfaceArea != null) {
                return Math.floor(rentAmount * 10000 * this.tsubo(this.surfaceArea));
              }
              break;
            // 売却可能額×敷地面積×地代利回り(％)/12
            case 2:
              if (availableForSale != 0 && this.surfaceArea != null && rentRate != null) {
                return Math.floor(
                  (availableForSale * this.tsubo(this.surfaceArea) * (rentRate / 100)) / 12
                );
              }
              break;
          }
          return null;
        };
      },
      // 売却価格
      salePrice: function() {
        return function() {
          // 路線価×敷地面積
          const result = Math.round((this.roadsideLandPrice * this.surfaceArea) / 10000);
          this.inputValue.overallPlan.sale.salePrice = result;
          return result;
        };
      },
      // // 取得価格
      // purchasePrice: function() {
      //   return function() {
      //     // 購入時の価格 または 売却価格の5% の高いほう
      //     var v1 = this.inputValue.overallPlan.sale.priceAtTheTimeOfPurchase != null ?
      //       this.inputValue.overallPlan.sale.priceAtTheTimeOfPurchase * 10000 :
      //       0;
      //     var v2 = this.inputValue.overallPlan.sale.salePrice * 10000 * 0.05;

      //     const result = Math.round((Math.max(v1, v2) / 10000) * 10) / 10;
      //     this.inputValue.overallPlan.sale.purchasePrice = result;
      //     return result;
      //   };
      // },
      // 仲介手数料
      agencyFees: function() {
        //正確には売却価格が2,000万円の場合、(200万円x5%)+(200万円x4%)+(1,600万円x3%)=66万円だが、
        //200万円以下、400万円以下、それ以上で分けた速算式を用いる
        return function() {
          //仲介手数料=売却価格x比率+補正値
          let rate = 0.05;
          let correction = 0;
          if (this.inputValue.overallPlan.sale.salePrice > 400) {
            rate = 0.03;
            correction = 60000;
          } else if (this.inputValue.overallPlan.sale.salePrice > 200) {
            rate = 0.04;
            correction = 20000;
          }
          let result = Math.round(
            (this.inputValue.overallPlan.sale.salePrice * 10000 * rate + correction) / 10000
          );
          this.inputValue.overallPlan.sale.agencyFees = result;
          return result;
        };
      },
      // 譲渡所得税
      // transferIncomeTax: function() {
      //   return function() {
      //     // 課税譲渡所得＝売却価格－(購入価格＋仲介手数料＋その他譲渡費用)
      //     var income =
      //       this.salePrice() -
      //       (this.purchasePrice() +
      //         this.agencyFees() +
      //         this.master.defaultValue.sale.otherTransferCosts * 10000);
      //     income -= 3000 * 10000; // 3,000万円特別控除(措法35)
      //     if (income <= 0) {
      //       this.inputValue.overallPlan.sale.transferIncomeTax = 0;
      //       return 0;
      //     }
      //     // 所有期間10年以上
      //     if (this.master.defaultValue.sale.periodOfOwnership >= 10) {
      //       // 10年超所有軽減税率の特例(措法31条の3)
      //       // 譲渡所得6,000万円以下：14.21％
      //       var tmp1 = Math.min(income, 6000 * 10000); // 6,000万円以下
      //       // 譲渡所得6,000万円超：20.315％
      //       var tmp2 = Math.max(0, income - 6000 * 10000); // 6,000万円超
      //       // 課税譲渡所得×所得税率

      //       const result =
      //         (tmp1 * this.taxRate.income.specialCase) / 100 +
      //         (tmp2 * this.taxRate.income.longTerm) / 100;

      //       this.inputValue.overallPlan.sale.transferIncomeTax = result;

      //       return result;
      //     }
      //     // 所有期間10年未満
      //     else {
      //       // 5年超  …長期所得税税率(20.315%)
      //       // 5年以下…短期所得税税率(39.63%)
      //       var rate =
      //         this.master.defaultValue.sale.periodOfOwnership <= 5
      //           ? this.taxRate.income.shortTerm
      //           : this.taxRate.income.longTerm;
      //       const result = (income * rate) / 100;
      //       this.inputValue.overallPlan.sale.transferIncomeTax = result;
      //       // 課税譲渡所得×所得税率
      //       return result;
      //     }
      //   };
      // },
      // 譲渡所得税
      transferIncomeTax: function() {
        return function() {
          // 課税譲渡所得＝売却価格－(取得費用＋売却時仲介手数料＋その他譲渡費用)
          var income =
            (this.inputValue.overallPlan.sale.salePrice -
              (this.inputValue.overallPlan.sale.acquisitionCost +
                this.inputValue.overallPlan.sale.agencyFees +
                this.inputValue.overallPlan.sale.otherTransferCosts)) *
            10000;
          //措法35の控除対応は指定がないのでコメントアウト
          // income -= 3000 * 10000; // 3,000万円特別控除(措法35)
          if (income <= 0) {
            this.inputValue.overallPlan.sale.transferIncomeTax = 0;
            return 0;
          }
          //措法31条の3の税率対応は指定がないのでコメントアウト
          // // 所有期間10年以上
          // if (this.inputValue.overallPlan.sale.periodOfOwnership >= 10) {
          //   this.inputValue.overallPlan.sale.termOfTransferIncomeTax = 'long';
          //   // 10年超所有軽減税率の特例(措法31条の3)
          //   // 譲渡所得6,000万円以下：14.21％
          //   var tmp1 = Math.min(income, 6000 * 10000); // 6,000万円以下
          //   // 譲渡所得6,000万円超：20.315％
          //   var tmp2 = Math.max(0, income - 6000 * 10000); // 6,000万円超
          //   // 課税譲渡所得×所得税率

          //   const result =
          //     Math.round(
          //       (((tmp1 * this.taxRate.income.specialCase) / 100 +
          //         (tmp2 * this.taxRate.income.longTerm) / 100) /
          //         10000)
          //     );

          //   this.inputValue.overallPlan.sale.transferIncomeTax = result;

          //   return result;
          // }
          // // 所有期間10年未満
          // else {
          // 5年超  …長期所得税税率(20.315%)
          // 5年以下…短期所得税税率(39.63%)
          var rate = null;
          if (Math.floor(this.inputValue.overallPlan.sale.periodOfOwnership) <= 5) {
            rate = this.master.defaultValue.sale.capitalGainsTaxRateShortTerm;
            this.inputValue.overallPlan.sale.termOfTransferIncomeTax = 'short';
          } else {
            rate = this.master.defaultValue.sale.capitalGainsTaxRateLongTerm;
            this.inputValue.overallPlan.sale.termOfTransferIncomeTax = 'long';
          }
          // if (this.inputValue.overallPlan.sale.termOfTransferIncomeTax) {
          //   rate =
          //     this.inputValue.overallPlan.sale.termOfTransferIncomeTax == 'short'
          //       ? this.master.defaultValue.sale.capitalGainsTaxRateShortTerm
          //       : this.master.defaultValue.sale.capitalGainsTaxRateLongTerm;
          // }
          const result = Math.floor((income * rate) / 100);
          this.inputValue.overallPlan.sale.transferIncomeTax = result;
          // 課税譲渡所得×所得税率
          return result;
          // }
        };
      },
      // 手取額
      netProceeds: function() {
        return function() {
          // 手取額 = 売却価格 - (取得費用 + 仲介手数料 + 譲渡費用 + 譲渡所得税)
          var s = this.inputValue.overallPlan.sale.salePrice; //売却価格
          // var ac = this.inputValue.overallPlan.sale.acquisitionCost;    //取得費用
          var ag = this.inputValue.overallPlan.sale.agencyFees; //売却時仲介手数料
          var o = this.inputValue.overallPlan.sale.otherTransferCosts; //その他譲渡費用
          var t = this.inputValue.overallPlan.sale.transferIncomeTax; //譲渡所得税
          // const result = (s - (ac + ag + o + t)) * 10000;
          const result = (s - (ag + o)) * 10000 - t;
          this.inputValue.overallPlan.sale.netProceeds = result;
          return result;
        };
      },
    },
    methods: {
      // setData
      setData: async function(data) {
        if (data != null && data != undefined) {
          this.loading = true;
          // ファイル初期化
          this.$refs.landPicture.images = null;
          this.$refs.guideMap.images = null;
          this.$refs.vacantLandAndLeasedLand.images = null;
          this.$refs.monthlyParkingLot.images = null;
          this.$refs.groupRental.images = null;
          this.$refs.groupRentalFamily.images = null;
          this.$refs.detachedHousesForRent.images = null;
          this.$refs.sellAndCashOut.images = null;
          // 入力値設定
          this.$set(this, 'inputValue', data);
          // チェックボックス設定
          for (let i = 0; i < 7; i++) {
            this.columns[i].checked =
              this.master.defaultValue.rentalProperty[i].floorPlanAndName == null ? false : true;
          }
          // 坪当りの月地代
          if (null != this.master.defaultValue.leasedLand.monthlyLandRentPerSquareMeter) {
            this.master.defaultValue.leasedLand.modeRent = 1;
          }
          // 時価に対する年間地代利回り
          else if (null != this.master.defaultValue.leasedLand.annualLandRentYieldToMarketValue) {
            this.master.defaultValue.leasedLand.modeRent = 1;
          }
          await this.$nextTick();
          this.loading = false;
        }
      },
      // 間取り・名称
      name: function(id) {
        return id < 4
          ? '集合型賃貸/' + this.master.defaultValue.rentalProperty[id].floorPlanAndName
          : '';
      },
      // 月額賃料
      monthlyRent: function(id) {
        return id < 7
          ? this.inputValue.overallPlan.rentalProperty[id].monthlyRent
          : id == 7
          ? this.inputValue.overallPlan.monthlyParkingLot.monthlyRent
          : this.inputValue.overallPlan.storeOffice.monthlyRent;
      },
      // 1戸/1坪当り工事費
      cost: function(id) {
        return id < 7
          ? this.master.defaultValue.rentalProperty[id].constructionCostPerUnit
          : id == 7
          ? this.master.defaultValue.monthlyParkingLot.constructionCostPerUnit
          : this.master.defaultValue.storeOffice.constructionCostPerUnit;
      },
      // 管理費率(対賃料)
      managementRate: function(id) {
        return id < 7
          ? this.master.defaultValue.rentalProperty[id].managementCostRate
          : id == 7
          ? this.master.defaultValue.monthlyParkingLot.managementCostRate
          : this.master.defaultValue.storeOffice.managementCostRate;
      },
      // 空室率/空車率(対賃料)
      vacancyRate: function(id) {
        return id < 7
          ? this.master.defaultValue.rentalProperty[id].vacancyRate
          : id == 7
          ? this.master.defaultValue.monthlyParkingLot.vacancyRate
          : this.master.defaultValue.storeOffice.vacancyRate;
      },
      // 初年度経費率(対工事費)
      expenseRate: function(id) {
        return id < 7 ? this.master.defaultValue.rentalProperty[id].firstYearExpenseRatio : 0;
      },
      // // 修繕費率(対工事費)
      // repairRate: function(id) {
      //   return id < 7 ? this.master.defaultValue.rentalProperty[id].rateOfRepairCosts : 0;
      // },
      // 固定資産税評価率(対工事費)
      assessmentRate: function(id) {
        return id < 7
          ? this.master.defaultValue.rentalProperty[id].propertyTaxAssessmentRate
          : null;
      },
      //借入金利
      setBorrowingRate: function(id) {
        //集合型・戸建の借入金利
        if (id < 7) {
          if (this.inputValue.overallPlan.rentalProperty[id].borrowingRate == null) {
            this.inputValue.overallPlan.rentalProperty[
              id
            ].borrowingRate = this.master.defaultValue.rentalProperty[id].borrowingRate;
          }
          return this.inputValue.overallPlan.rentalProperty[id].borrowingRate;
        } else if (id == 7) {
          //月極駐車場の借入金利
          if (this.inputValue.overallPlan.monthlyParkingLot.borrowingRate == null) {
            this.inputValue.overallPlan.monthlyParkingLot.borrowingRate = this.master.defaultValue.monthlyParkingLot.borrowingRate;
          }
          return this.inputValue.overallPlan.monthlyParkingLot.borrowingRate;
        } else {
          //店舗・事務所の借入金利
          if (this.inputValue.overallPlan.storeOffice.borrowingRate == null) {
            this.inputValue.overallPlan.storeOffice.borrowingRate = this.master.defaultValue.storeOffice.borrowingRate;
          }
          return this.inputValue.overallPlan.storeOffice.borrowingRate;
        }
      },
      //借入期間
      setBorrowingPeriod: function(id) {
        if (id < 7) {
          if (this.inputValue.overallPlan.rentalProperty[id].borrowingPeriod == null) {
            this.inputValue.overallPlan.rentalProperty[
              id
            ].borrowingPeriod = this.master.defaultValue.rentalProperty[id].borrowingPeriod;
          }
          return this.inputValue.overallPlan.rentalProperty[id].borrowingPeriod;
        } else if (id == 7) {
          if (this.inputValue.overallPlan.monthlyParkingLot.borrowingPeriod == null) {
            this.inputValue.overallPlan.monthlyParkingLot.borrowingPeriod = this.master.defaultValue.monthlyParkingLot.borrowingPeriod;
          }
          return this.inputValue.overallPlan.monthlyParkingLot.borrowingPeriod;
        } else {
          if (this.inputValue.overallPlan.storeOffice.borrowingPeriod == null) {
            this.inputValue.overallPlan.storeOffice.borrowingPeriod = this.master.defaultValue.storeOffice.borrowingPeriod;
          }
          return this.inputValue.overallPlan.storeOffice.borrowingPeriod;
        }
      },
      // 借家権割合
      tenancyRate: function(id) {
        return this.master.defaultValue.rentalProperty[id].percentageOfTenancyRights;
      },
      // // 階数
      // levels: function(id) {
      //   return this.inputValue.overallPlan.rentalProperty[id].numberOfLevels;
      // },
      // 戸数/台数
      counts: function(id) {
        return id < 7
          ? this.inputValue.overallPlan.rentalProperty[id].numberOfHouses
          : id == 7
          ? this.inputValue.overallPlan.monthlyParkingLot.numberOfUnits
          : 1; //店舗・事務所は１として扱う
      },
      // 自己資金
      funds: function(id) {
        return id < 7
          ? this.inputValue.overallPlan.rentalProperty[id].personalFunds
          : id == 7
          ? this.inputValue.overallPlan.monthlyParkingLot.personalFunds
          : this.inputValue.overallPlan.storeOffice.personalFunds;
      },
      // 賃料入力
      inputMonthlyRent: function(value, id) {
        if (id < 7) {
          this.inputValue.overallPlan.rentalProperty[id].monthlyRent = Math.floor(value * 10) / 10;
        } else if (id == 7) {
          this.inputValue.overallPlan.monthlyParkingLot.monthlyRent = Math.floor(value * 10) / 10;
        } else {
          this.inputValue.overallPlan.storeOffice.monthlyRent = Math.floor(value * 10) / 10;
        }
      },
      // 戸数・台数入力
      inputNumberOfHouses: function(value, id) {
        if (id < 7) {
          this.inputValue.overallPlan.rentalProperty[id].numberOfHouses = Math.floor(value);
        } else if (id == 7) {
          this.inputValue.overallPlan.monthlyParkingLot.numberOfUnits = Math.floor(value);
        }
      },
      // 自己資金入力
      inputPersonalFunds: function(value, id) {
        if (id < 7) {
          this.inputValue.overallPlan.rentalProperty[id].personalFunds = Math.floor(value);
        } else if (id == 7) {
          this.inputValue.overallPlan.monthlyParkingLot.personalFunds = Math.floor(value);
        } else {
          this.inputValue.overallPlan.storeOffice.personalFunds = Math.floor(value);
        }
      },
      // 借入金利
      borrowingRate: function(id) {
        return id < 7
          ? this.inputValue.overallPlan.rentalProperty[id].borrowingRate
          : id == 7
          ? this.inputValue.overallPlan.monthlyParkingLot.borrowingRate
          : this.inputValue.overallPlan.storeOffice.borrowingRate;
      },
      // 借入金利入力
      inputBorrowingRate: function(value, id) {
        if (id < 7) {
          this.inputValue.overallPlan.rentalProperty[id].borrowingRate =
            Math.floor(value * 100) / 100;
        } else if (id == 7) {
          this.inputValue.overallPlan.monthlyParkingLot.borrowingRate =
            Math.floor(value * 100) / 100;
        } else {
          this.inputValue.overallPlan.storeOffice.borrowingRate = Math.floor(value * 100) / 100;
        }
      },
      // 借入期間
      borrowingPeriod: function(id) {
        return id < 7
          ? this.inputValue.overallPlan.rentalProperty[id].borrowingPeriod
          : id == 7
          ? this.inputValue.overallPlan.monthlyParkingLot.borrowingPeriod
          : this.inputValue.overallPlan.storeOffice.borrowingPeriod;
      },
      // 借入期間入力
      inputBorrowingPeriod: function(value, id) {
        if (id < 7) {
          this.inputValue.overallPlan.rentalProperty[id].borrowingPeriod = Math.floor(value);
        } else if (id == 7) {
          this.inputValue.overallPlan.monthlyParkingLot.borrowingPeriod = Math.floor(value);
        } else {
          this.inputValue.overallPlan.storeOffice.borrowingPeriod = Math.floor(value);
        }
      },
      // 年間修繕費入力
      inputAnnualRepairCosts: function(value, id) {
        this.inputValue.overallPlan.rentalProperty[id].annualRepairCosts = Math.floor(value);
        // if (id < 7) {
        //   this.inputValue.overallPlan.rentalProperty[id].annualRepairCosts = Math.floor(value);
        // } else if (id == 7) {
        //   this.inputValue.overallPlan.monthlyParkingLot.annualRepairCosts = Math.floor(value);
        // } else {
        //   this.inputValue.overallPlan.storeOffice.annualRepairCosts = Math.floor(value);
        // }
      },
      // 売却価格入力
      inputSalePrice: function(value) {
        this.inputValue.overallPlan.sale.salePrice = Math.floor(value * 10) / 10;
      },
      // 取得費入力
      inputAcquisitionCost: function(value) {
        this.inputValue.overallPlan.sale.acquisitionCost = Math.floor(value);
      },
      // その他譲渡費用入力
      inputOtherTransferCosts: function(value) {
        this.inputValue.overallPlan.sale.otherTransferCosts = Math.floor(value);
      },
      // グラフ更新
      updateCharts: function() {
        var bs = this.calcBalanceSheet(); // 収支累計計算
        this.updateChartLoan();
        this.updateChartMonthlyIncome();
        this.updateChartLandPropertyTax();
        this.updateChartBalanceSheetTotal(bs);
        this.updateChartRentalManagementStability();
        this.updateChartPropertyValuation();
        this.updateChartInheritedProperty();
        var tax = [];
        for (let y of [5, 10]) {
          tax[y] = this.calcInheritanceTax(y, bs); // 相続税計算
          this.updateChartInheritanceTax(y, bs, tax[y]);
          if (y == 10) {
            this.updateChartRemainingAssets(y, bs, tax[y]);
          }
        }
        this.inputValue.setChartData(this.chartData);
      },
      // グラフ更新(住宅ローン残高)
      updateChartLoan: function() {
        // データ取得
        var datasets = this.createLoanDatasets();
        // 最大年数取得
        var maxYear = 0;
        datasets.forEach(dataset => {
          maxYear = Math.max(maxYear, dataset.data.length - 1);
        });
        // 横軸ラベル設定
        var labels = [];
        for (var i = 0; i <= maxYear; i++) {
          labels.push(i.toString());
        }
        // 更新
        Vue.set(this.chartData, 0, {
          labels: labels,
          datasets: datasets,
        });
      },
      // グラフ更新(月間収支の比較)
      updateChartMonthlyIncome: function() {
        var labels = [];
        var before = [];
        var after = [];
        // 賃貸
        this.columns.forEach(column => {
          if (column.checked) {
            labels.push(this.nvl(this.name(column.id), column.text));
            var bp = this.borrowingPeriod(column.id); // 借入期間
            if (bp > 0) {
              // ローン有
              // 返済中＝初年度の月手取額（土地固都税を除く）
              before.push(this.calcMonthlyIncome(column.id, 1));
              // 終了後＝終了翌年の月手取額（土地固都税を除く）
              after.push(this.calcMonthlyIncome(column.id, Number(bp) + 1));
            } else {
              // ローン無
              // 返済中＝なし
              before.push([null, null]);
              // 終了後＝初年度の月手取額（土地固都税を除く）
              after.push(this.calcMonthlyIncome(column.id, 1));
            }
          }
        });
        // 月極駐車場
        labels.push('月極駐車場');
        var bp = this.borrowingPeriod(7); // 借入期間
        // ローン有の場合
        if (bp > 0) {
          // 返済中＝初年度の月手取額（土地固都税を除く）
          before.push(this.calcMonthlyIncome(7, 1));
          // 終了後＝終了翌年の月手取額（土地固都税を除く）
          after.push(this.calcMonthlyIncome(7, Number(bp) + 1));
        }
        // ローン無の場合
        else {
          // 返済中＝なし
          before.push(null);
          // 終了後＝初年度の月手取額（土地固都税を除く）
          after.push(this.calcMonthlyIncome(7, 1));
        }
        // 店舗・事務所
        labels.push('店舗・事務所');
        var bp2 = this.borrowingPeriod(8); // 借入期間
        // ローン有の場合
        if (bp2 > 0) {
          // 返済中＝初年度の月手取額（土地固都税を除く）
          before.push(this.calcMonthlyIncome(8, 1));
          // 終了後＝終了翌年の月手取額（土地固都税を除く）
          after.push(this.calcMonthlyIncome(8, Number(bp2) + 1));
        }
        // ローン無の場合
        else {
          // 返済中＝なし
          before.push(null);
          // 終了後＝初年度の月手取額（土地固都税を除く）
          after.push(this.calcMonthlyIncome(8, 1));
        }
        // 貸地
        labels.push('貸地');
        before.push(this.calcMonthlyIncome(9, 1)); // 終了後＝初年度の月手取額（土地固都税を除く）
        after.push(null); // 返済中＝なし
        // 更地
        labels.push('更地');
        before.push(this.calcMonthlyIncome(10, 1)); // 終了後＝初年度の月手取額（土地固都税を除く）
        after.push(null); // 返済中＝なし
        // 売却
        labels.push('売却');
        before.push(this.calcMonthlyIncome(11, 1)); // 終了後＝初年度の月手取額（土地固都税を除く）
        after.push(null); // 返済中＝なし

        // 更新
        Vue.set(this.chartData, 1, {
          labels: labels,
          datasets: [
            {
              label: '返済中',
              backgroundColor: 'rgba(0,0,255,0.5)',
              borderWidth: 1,
              data: before,
            },
            {
              label: '返済終了後',
              backgroundColor: '#1d1d63',
              borderWidth: 1,
              data: after,
            },
          ],
        });
      },
      // グラフ更新(土地の固定資産税比較(年間))
      updateChartLandPropertyTax: function() {
        var labels = [];
        var values = [];
        // 賃貸
        this.columns.forEach(column => {
          if (column.checked) {
            labels.push(this.nvl(this.name(column.id), column.text));
            values.push([0, this.taxLand(column.id) / 10000]);
          }
        });
        var tax = this.taxLand(7) / 10000; // 賃貸以外は同じ
        // 月極駐車場
        labels.push('月極駐車場');
        values.push([0, tax]);
        // 店舗・事務所
        labels.push('店舗・事務所');
        values.push([0, this.taxLand(8) / 10000]);
        // 貸地
        labels.push('貸地');
        values.push([0, tax]);
        // 更地
        labels.push('更地');
        values.push([0, tax]);
        // 更新
        Vue.set(this.chartData, 2, {
          labels: labels,
          datasets: [
            {
              label: '固定資産税',
              backgroundColor: '#1d1d63',
              borderWidth: 1,
              data: values,
            },
          ],
        });
      },
      // グラフ更新(現金収支累計)
      updateChartBalanceSheetTotal: function(bs) {
        // データ取得
        var datasets = this.createBalanceSheetDatasets(bs);
        // 横軸ラベル設定
        var labels = [];
        for (var i = 0; i <= 45; i++) {
          labels.push(i.toString());
        }
        // 更新
        Vue.set(this.chartData, 3, {
          labels: labels,
          datasets: datasets,
        });
      },
      // グラフ更新(賃貸経営安定度)
      updateChartRentalManagementStability: function() {
        var labels = [];
        var values = [[], []];
        // 賃貸
        this.columns.forEach(column => {
          if (column.checked) {
            labels.push(this.nvl(this.name(column.id), column.text));
            // 単位：万円
            values[0].push(this.loanAmount(column.id));
            // 単位：％
            values[1].push(this.calcBreakevenVacancyRate(column.id) * 100);
          }
        });
        var datasets = [
          {
            type: 'bar',
            yAxisID: 'LEFT',
            label: '借入金額',
            backgroundColor: '#1d1d63',
            borderWidth: 1,
            data: values[0],
            order: 2,
          },
          {
            type: 'line',
            yAxisID: 'RIGHT',
            label: '損益分岐点空室率',
            data: values[1],
            borderColor: 'rgba(127,127,255,1)',
            pointBackgroundColor: 'rgba(127,127,255,1)',
            borderWidth: 8,
            fill: false,
            lineTension: 0,
            pointRadius: 16,
            order: 1,
          },
        ];
        // 更新
        Vue.set(this.chartData, 4, {
          labels: labels,
          datasets: datasets,
        });
      },
      // グラフ更新(不動産の活用法別財産評価額)
      updateChartPropertyValuation: function() {
        var labels = [];
        var data = [];
        // 賃貸
        this.columns.forEach(column => {
          if (column.checked) {
            labels.push(this.nvl(this.name(column.id), column.text));
            // 借地権割合
            var r1 = this.inputValue.siteConditions.siteInformation.percentageOfLeaseholdInterest;
            // 借家権割合
            var r2 = this.tenancyRate(column.id);
            // 戸数
            var num = this.counts(column.id);
            // 固定資産税評価率(対工事費)
            var propertyTaxAssessmentRate = this.assessmentRate(column.id);
            // ※土地＝路線価×敷地面積
            //   ×(1－借地権割合×借家権割合)
            //   x(1－空室率)
            var land =
              this.roadsideLandPrice *
              this.surfaceArea *
              (1 - (r1 / 100) * (r2 / 100)) *
              (1 - this.vacancyRate(column.id) / 100);
            // ※建物＝(1戸当り工事費×戸数×固定資産税評価率)
            //   ×(1－借家権割合)
            //   x(1－空室率)
            var building =
              this.cost(column.id) *
              10000 *
              num *
              (propertyTaxAssessmentRate / 100) *
              (1 - r2 / 100) *
              (1 - this.vacancyRate(column.id) / 100);
            // (※土地＋※建物)－(自己資金＋借入金額)
            var tmp = Math.round(
              land + building - (this.funds(column.id) + this.loanAmount(column.id)) * 10000
            ); // 自己資金＋借入金額
            data.push(tmp / 10000);
          }
        });
        // [月極駐車場]
        // (路線価×敷地面積)－(自己資金＋借入金額)
        var tmp =
          this.roadsideLandPrice * this.surfaceArea - // 路線価*敷地面積
          (this.funds(7) + this.loanAmount(7)) * 10000; // 自己資金＋借入金額
        labels.push('月極駐車場');
        data.push(tmp / 10000);
        // 店舗・事務所
        labels.push('店舗・事務所');
        data.push(
          (this.roadsideLandPrice * this.surfaceArea -
            (this.funds(8) + this.loanAmount(8)) * 10000) /
            10000
        );

        // [貸地/更地] 路線価×敷地面積
        tmp = this.roadsideLandPrice * this.surfaceArea;
        labels.push('貸地');
        data.push(tmp / 10000);
        labels.push('更地');
        data.push(tmp / 10000);
        // [売却] 手取額
        labels.push('売却');
        data.push(this.netProceeds() / 10000);

        // 更新
        Vue.set(this.chartData, 5, {
          labels: labels,
          datasets: [
            {
              label: '財産評価額',
              backgroundColor: '#1d1d63',
              borderWidth: 1,
              data: data,
            },
          ],
        });
      },
      // グラフ更新(将来の相続財産額の推移)
      updateChartInheritedProperty: function() {
        // データ取得
        var datasets = this.createInheritedPropertyDatasets();
        // 横軸ラベル設定
        var labels = [];
        for (var i = 0; i <= 45; i++) {
          labels.push(i.toString());
        }
        // 更新
        Vue.set(this.chartData, 6, {
          labels: labels,
          datasets: datasets,
        });
      },
      // グラフ更新(n年後の相続税額と収支累計)
      updateChartInheritanceTax: function(year, bs, taxIn) {
        var labels = [];
        var total = []; // 収支累計
        var taxOut = []; // 相続税額
        // 賃貸
        this.columns.forEach(column => {
          if (column.checked) {
            labels.push(this.nvl(this.name(column.id), column.text));
            var tmp = bs[column.id][year];
            if (tmp > 0) {
              tmp *= 0.8;
            }
            total.push([0, tmp]); // 収支累計
            taxOut.push([0, taxIn[column.id] == null ? null : taxIn[column.id] / 10000]); // 相続税額
          }
        });
        // 月極駐車場
        labels.push('月極駐車場');
        var tmp = bs[7][year];
        if (tmp > 0) {
          tmp *= 0.8;
        }
        total.push([0, tmp]); // 収支累計
        taxOut.push([0, taxIn[7] / 10000]); // 相続税額
        // 店舗・事務所
        labels.push('店舗・事務所');
        total.push([0, bs[8][year]]); // 収支累計
        taxOut.push([0, taxIn[8] / 10000]); // 相続税額
        // 貸地
        labels.push('貸地');
        tmp = bs[9][year];
        if (tmp > 0) {
          tmp *= 0.8;
        }
        total.push([0, tmp]); // 収支累計
        taxOut.push([0, taxIn[9] / 10000]); // 相続税額
        // 更地
        labels.push('更地');
        total.push([0, bs[10][year]]); // 収支累計
        taxOut.push([0, taxIn[10] / 10000]); // 相続税額
        // 売却
        labels.push('売却');
        total.push([
          0,
          (bs[11][year] * 10000 + this.netProceeds() - this.roadsideLandPrice * this.surfaceArea) /
            10000,
        ]); // 収支累計
        taxOut.push([0, taxIn[11] / 10000]); // 相続税額

        var datasets = [
          {
            label: '相続税額',
            backgroundColor: 'rgba(0,0,255,0.5)',
            borderWidth: 1,
            data: taxOut,
          },
          {
            label: '収支累計',
            backgroundColor: '#1d1d63',
            borderWidth: 1,
            data: total,
          },
        ];
        // 更新
        switch (Number(year)) {
          case 5:
            Vue.set(this.chartData, 7, {
              labels: labels,
              datasets: datasets,
            });
            break;
          case 10:
            Vue.set(this.chartData, 8, {
              labels: labels,
              datasets: datasets,
            });
            break;
        }
      },
      // グラフ更新(相続税支払後の残資産)
      updateChartRemainingAssets: function(year, bs, tax) {
        var labels = [];
        var assets = []; // 残資産
        var loan = []; // 残債
        // 資産(土地)＝路線価×敷地面積
        var land = this.roadsideLandPrice * this.surfaceArea;
        // 売却時の手取り現金
        var net = this.netProceeds();
        // 推定相続財産額を加算
        var inheritance =
          this.inputValue.siteConditions.ownerInformation.estimatedEstateValue * 10000;
        // [売却以外の場合]
        //    ⼟地も含めた現在の総資産額(仮)
        //  ＋10年間の現金収支累計額
        //  －10年後の相続税額
        // 賃貸
        this.columns.forEach(column => {
          if (column.checked) {
            labels.push(this.nvl(this.name(column.id), column.text));
            // // 資産(建物)＝1戸当り工事費×戸数×固定資産税評価率
            // var building =
            //   this.cost(column.id) *
            //   10000 *
            //   this.counts(column.id) *
            //   (this.assessmentRate(column.id) / 100);
            // // ※「固都税 上昇率」を考慮
            // var structure = this.structure[column.id];
            // building = this.calcChange({
            //   value: building, // 変動対象
            //   year: year, // 年数
            //   term: this.climingRate.deferralPeriod, // 据置期間
            //   period: this.climingRate.tax.building[structure].changePeriod, // 変動周期
            //   factor: this.climingRate.tax.building[structure].coefficientOfVariation, // 変動係数
            // });
            assets.push(
              // (land + // 資産(土地)
              // building + // 資産(建物)
              (inheritance + // 推定相続財産額を加算
              bs[column.id][year] * 10000 - // 現金収支累計
                tax[column.id]) / // 相続税
                10000
            );
            // ローン有
            var bp = this.borrowingPeriod(column.id); // 借入期間
            if (bp > 0) {
              loan.push(
                this.loanBalance(
                  this.loanRepaymentAmount(column.id),
                  this.borrowingRate(column.id) / 100,
                  this.loanAmount(column.id) * 10000,
                  year
                ) / 10000
              );
            }
            // ローン無
            else {
              loan.push(null);
            }
          }
        });
        // 月極駐車場
        labels.push('月極駐車場');
        assets.push(
          // (land + // 資産(土地)
          (inheritance + // 推定相続財産額を加算
          bs[7][year] * 10000 - // 現金収支累計
            tax[7]) / // 相続税
            10000
        );
        // ローン有
        var bp = this.borrowingPeriod(7); // 借入期間
        if (bp > 0) {
          loan.push(
            this.loanBalance(
              this.loanRepaymentAmount(7),
              this.borrowingRate(7) / 100,
              this.loanAmount(7) * 10000,
              year
            ) / 10000
          );
        }
        // ローン無
        else {
          loan.push(null);
        }
        // 店舗・事務所
        labels.push('店舗・事務所');
        assets.push(
          // (land + // 資産(土地)
          (inheritance + // 推定相続財産額を加算
          bs[8][year] * 10000 - // 現金収支累計
            tax[8]) / // 相続税
            10000
        );
        // ローン有
        var bp2 = this.borrowingPeriod(8); // 借入期間
        if (bp2 > 0) {
          loan.push(
            this.loanBalance(
              this.loanRepaymentAmount(8),
              this.borrowingRate(8) / 100,
              this.loanAmount(8) * 10000,
              year
            ) / 10000
          );
        }
        // ローン無
        else {
          loan.push(null);
        }

        // 貸地
        labels.push('貸地');
        assets.push(
          // (land + // 資産(土地)
          (inheritance + // 推定相続財産額を加算
          bs[9][year] * 10000 - // 現金収支累計
            tax[9]) / // 相続税
            10000
        );
        // 更地
        labels.push('更地');
        assets.push(
          // (land + // 資産(土地)
          (inheritance + // 推定相続財産額を加算
          bs[10][year] * 10000 - // 現金収支累計
            tax[10]) / // 相続税
            10000
        );
        // [売却の場合]
        //    ⼟地も含めた現在の総資産額(仮)
        //  －路線価による⼟地価格
        //  ＋売却時の手取り現金
        //  ＋10年間の現金収支累計額
        //  －10年後の相続税額
        // 売却
        labels.push('売却');
        assets.push(
          (inheritance - // 推定相続財産額を加算
          land + // 路線価による⼟地価格
          net + // 売却時の手取り現金
          bs[11][year] * 10000 - // 現金収支累計
            tax[11]) / // 相続税
            10000
        );

        // 更新
        Vue.set(this.chartData, 9, {
          labels: labels,
          datasets: [
            {
              label: '残資産',
              backgroundColor: 'rgba(0,0,255,0.5)',
              borderWidth: 1,
              data: assets,
            },
            {
              label: year + '年後の残債',
              backgroundColor: '#1d1d63',
              borderWidth: 1,
              data: loan,
            },
          ],
        });
      },
      // グラフデータ生成(ローン残高)
      createLoanDatasets: function() {
        var ds = [];
        for (var id in [0, 1, 2, 3, 4, 5, 6, 7, 8]) {
          // 返済金額
          var pay = this.loanRepaymentAmount(id);
          // ラベル
          var lbl =
            id == 7
              ? '月極駐車場'
              : id == 8
              ? '店舗・事務所'
              : this.nvl(this.name(id), this.columns[id].text);
          if (pay > 0) {
            // ローン残高計算
            var data = this.calcLoanBalance(
              pay,
              this.borrowingRate(id) / 100,
              this.loanAmount(id) * 10000,
              this.borrowingPeriod(id)
            );
            ds.push({
              label: lbl,
              data: data,
              borderColor: colors[id],
              pointBackgroundColor: colors[id],
              borderWidth: 10,
              fill: false,
              lineTension: 0,
              pointRadius: 4,
            });
          }
        }
        return ds;
      },
      // ローン残高計算
      calcLoanBalance: function(repayment, rate, amount, period) {
        var data = [];
        for (var n = 0; n <= period; n++) {
          data.push(this.loanBalance(repayment, rate, amount, n) / 10000);
        }
        return data;
      },
      // グラフデータ生成(月間収支の比較)
      calcMonthlyIncome: function(id, year) {
        // 年間の手取額（土地固都税を除く）
        var total = 0;
        // 賃貸
        if (id < 7) {
          var income = this.monthlyRent(id) * 10000 * this.counts(id) * 12; // 初年度収入
          var repayment = this.loanRepaymentAmount(id); // 返済額(月)
          var repair = this.repairCosts(id); //年間修繕費
          // 家賃収入 ※「賃料 上昇率」を適用
          var tmp1 = this.calcChange({
            value: income, // 変動対象
            year: year, // 年数
            term: this.climingRate.deferralPeriod, // 据置期間
            period: this.climingRate.rent.changePeriod, // 変動周期
            factor: this.climingRate.rent.coefficientOfVariation, // 変動係数
          });
          total += tmp1;
          // 空室損失＝収入×空室率
          var tmp2 = (tmp1 * this.vacancyRate(id)) / 100;
          total -= tmp2;
          // 年間管理費＝収入×管理費率
          var tmp3 = (tmp1 * this.managementRate(id)) / 100;
          total -= tmp3;
          // 年間修繕費   ※「維持費 上昇率」を適用
          var tmp4 = this.calcChange({
            value: repair, // 変動対象
            year: year, // 年数
            term: this.climingRate.deferralPeriod, // 据置期間
            period: this.climingRate.maintenanceCosts.changePeriod, // 変動周期
            factor: this.climingRate.maintenanceCosts.coefficientOfVariation, // 変動係数
          });
          total -= tmp4;
          // 土地固都税を除く
          // 建物固都税
          var structure = this.structure[id];
          var tmp6 = this.calcTaxBuilding({
            constructionCostPerUnit: this.cost(id), // 1戸当り工事費
            numberOfHouses: this.counts(id), // 戸数
            propertyTaxAssessmentRate: this.assessmentRate(id), // 固定資産税評価率
            structure: structure, // 構造
            year: year, // 年数
          });
          total -= tmp6;
          // 年間ローン返済額
          if (year <= this.borrowingPeriod(id)) {
            total -= repayment * 12;
          }
        }
        switch (id) {
          // 月極駐車場
          case 7:
            income = this.monthlyRent(id) * 10000 * this.counts(id) * 12; // 初年度収入
            repayment = this.loanRepaymentAmount(id); // 返済額(月)
            repair = this.repairCosts(id); // 年間修繕費
            // 家賃収入 ※「賃料 上昇率」を適用
            tmp1 = this.calcChange({
              value: income, // 変動対象
              year: year, // 年数
              term: this.climingRate.deferralPeriod, // 据置期間
              period: this.climingRate.rent.changePeriod, // 変動周期
              factor: this.climingRate.rent.coefficientOfVariation, // 変動係数
            });
            total += tmp1;
            // 空車損失＝収入×空車率
            tmp2 = (tmp1 * this.vacancyRate(id)) / 100;
            total -= tmp2;
            // 年間管理費＝収入×管理費率
            tmp3 = (tmp1 * this.managementRate(id)) / 100;
            total -= tmp3;
            // 土地固都税を除く
            // 年間ローン返済額
            if (year <= this.borrowingPeriod(id)) {
              total -= repayment * 12;
            }
            break;
          // 店舗・事務所
          case 8:
            income = this.monthlyRent(id) * 10000 * this.counts(id) * 12; // 初年度収入
            repayment = this.loanRepaymentAmount(id); // 返済額(月)
            repair = this.repairCosts(id); // 年間修繕費
            // 家賃収入 ※「賃料 上昇率」を適用
            tmp1 = this.calcChange({
              value: income, // 変動対象
              year: year, // 年数
              term: this.climingRate.deferralPeriod, // 据置期間
              period: this.climingRate.rent.changePeriod, // 変動周期
              factor: this.climingRate.rent.coefficientOfVariation, // 変動係数
            });
            total += tmp1;
            // 空車損失＝収入×空車率
            tmp2 = (tmp1 * this.vacancyRate(id)) / 100;
            total -= tmp2;
            // 年間管理費＝収入×管理費率
            tmp3 = (tmp1 * this.managementRate(id)) / 100;
            total -= tmp3;
            // 土地固都税を除く
            // 年間ローン返済額
            if (year <= this.borrowingPeriod(id)) {
              total -= repayment * 12;
            }
            break;
          // 貸地
          case 9:
            income = this.incomeLandRent() * 12;
            // 収入 ※「賃料 上昇率」を適用
            tmp1 = this.calcChange({
              value: income, // 変動対象
              year: year, // 年数
              term: this.climingRate.deferralPeriod, // 据置期間
              period: this.climingRate.rent.changePeriod, // 変動周期
              factor: this.climingRate.rent.coefficientOfVariation, // 変動係数
            });
            total += tmp1;
            // 土地固都税を除く
            break;
          // 更地
          case 10:
            // 年間維持管理費
            var fee = this.calcChange({
              value: this.master.defaultValue.vacantLotOrLand.annualMaintenanceFee * 10000, // 変動対象
              year: year, // 年数
              term: this.climingRate.deferralPeriod, // 据置期間
              period: this.climingRate.maintenanceCosts.changePeriod, // 変動周期
              factor: this.climingRate.maintenanceCosts.coefficientOfVariation, // 変動係数
            });
            // 土地固都税を除く
            total -= fee;
            break;
          // 売却
          case 11:
            var price = this.netProceeds(); // 手取額
            var rate = this.master.defaultValue.sale.annualInvestmentYield; // 利回り
            // 将来価値＝元金×POWER(1+利率,期間)
            var previous = price * Math.pow(1 + rate / 100, year - 1); // 前年度
            var current = price * Math.pow(1 + rate / 100, year); // 当年度
            // 前年度との差分を追加
            total += current - previous;
            break;
        }
        // 単位：月、万円
        return [0, total / 12 / 10000];
      },
      // グラフデータ生成(現金収支累計)
      createBalanceSheetDatasets: function(data) {
        var ds = [];
        // 賃貸
        this.columns.forEach(column => {
          if (column.checked) {
            // ラベル
            var label = this.nvl(this.name(column.id), column.text);
            ds.push({
              label: label,
              data: data[column.id],
              borderColor: colors[column.id],
              pointBackgroundColor: colors[column.id],
              borderWidth: 10,
              fill: false,
              lineTension: 0,
              pointRadius: 4,
            });
          }
        });
        // 月極駐車場
        ds.push({
          label: '月極駐車場',
          data: data[7],
          borderColor: colors[7],
          pointBackgroundColor: colors[7],
          borderWidth: 10,
          fill: false,
          lineTension: 0,
          pointRadius: 4,
        });
        // 店舗・事務所
        ds.push({
          label: '店舗・事務所',
          data: data[8],
          borderColor: colors[8],
          pointBackgroundColor: colors[8],
          borderWidth: 10,
          fill: false,
          lineTension: 0,
          pointRadius: 4,
        });
        // 貸地
        ds.push({
          label: '貸地',
          data: data[9],
          borderColor: colors[9],
          pointBackgroundColor: colors[9],
          borderWidth: 10,
          fill: false,
          lineTension: 0,
          pointRadius: 4,
        });
        // 更地
        ds.push({
          label: '更地',
          data: data[10],
          borderColor: colors[10],
          pointBackgroundColor: colors[10],
          borderWidth: 10,
          fill: false,
          lineTension: 0,
          pointRadius: 4,
        });
        // 売却
        ds.push({
          label: '売却',
          data: data[11],
          borderColor: colors[11],
          pointBackgroundColor: colors[11],
          borderWidth: 10,
          fill: false,
          lineTension: 0,
          pointRadius: 4,
        });

        return ds;
      },
      // 収支累計計算
      calcBalanceSheet: function() {
        var bs = [];
        // 賃貸
        this.columns.forEach(column => {
          bs.push(column.checked ? this.calcBalanceSheetRent(column.id) : null);
        });
        // 月極駐車場
        bs.push(this.calcBalanceSheetParking());
        //店舗・事務所
        bs.push(this.calcBalanceStoreOffice());
        // 貸地
        bs.push(this.calcBalanceSheetLeasedLand());
        // 更地
        bs.push(this.calcBalanceSheetVacantLand());
        // 売却
        bs.push(this.calcBalanceSheetSale());

        return bs;
      },
      // 収支累計計算／賃貸
      calcBalanceSheetRent: function(id) {
        var data = [null];
        var total = 0;
        var income = this.monthlyRent(id) * 10000 * this.counts(id) * 12; // 初年度収入
        var repayment = this.loanRepaymentAmount(id); // 返済額(月)
        var repair = this.repairCosts(id); //年間修繕費
        for (var year = 1; year <= 45; year++) {
          // 家賃収入 ※「賃料 上昇率」を適用
          var tmp1 = this.calcChange({
            value: income, // 変動対象
            year: year, // 年数
            term: this.climingRate.deferralPeriod, // 据置期間
            period: this.climingRate.rent.changePeriod, // 変動周期
            factor: this.climingRate.rent.coefficientOfVariation, // 変動係数
          });
          total += tmp1;
          // 空室損失＝収入×空室率
          var tmp2 = (tmp1 * this.vacancyRate(id)) / 100;
          total -= tmp2;
          // 年間管理費＝収入×管理費率
          var tmp3 = (tmp1 * this.managementRate(id)) / 100;
          total -= tmp3;
          // 年間修繕費   ※「維持費 上昇率」を適用
          var tmp4 = this.calcChange({
            value: repair, // 変動対象
            year: year, // 年数
            term: this.climingRate.deferralPeriod, // 据置期間
            period: this.climingRate.maintenanceCosts.changePeriod, // 変動周期
            factor: this.climingRate.maintenanceCosts.coefficientOfVariation, // 変動係数
          });
          total -= tmp4;
          // 土地固都税
          var isResidence = false;
          if (this.structure[id] != null) {
            if (this.kind[id] === '住宅') {
              isResidence = true;
            }
          }
          var tmp5 = this.calcTaxLand({
            surfaceArea: this.surfaceArea, // 敷地面積
            numberOfHouses: this.counts(id), // 戸数
            propertyTaxAssessment: this.propertyTaxAssessment, // 固定資産税評価額
            isResidence: isResidence, // 住宅の場合、true
            year: year, // 年数
          });
          total -= tmp5;
          // 建物固都税
          var tmp6 = this.calcTaxBuilding({
            constructionCostPerUnit: this.cost(id), // 1戸当り工事費
            numberOfHouses: this.counts(id), // 戸数
            propertyTaxAssessmentRate: this.assessmentRate(id), // 固定資産税評価率
            structure: this.structure[id], // 構造
            year: year, // 年数
          });
          total -= tmp6;
          // 年間ローン返済額
          if (year <= this.borrowingPeriod(id)) {
            total -= repayment * 12;
          }
          // 累計
          data.push(Math.round(total) / 10000);
        }
        return data;
      },
      // 収支累計計算／月極駐車場
      calcBalanceSheetParking: function() {
        // 月額賃料
        var monthlyRent = this.monthlyRent(7);
        // 台数
        var numberOfUnits = this.counts(7);
        // 空車率(対賃料)
        var vacancyRate = this.vacancyRate(7);
        // 管理費率(対賃料)
        var managementCostRate = this.managementRate(7);
        var data = [null];
        var total = 0;
        var income = monthlyRent * 10000 * numberOfUnits * 12; // 初年度収入
        var repayment = this.loanRepaymentAmount(7); // 返済額(月)
        for (var year = 1; year <= 45; year++) {
          // 収入＝月額賃料×台数×12
          var tmp1 = this.calcChange({
            value: income, // 変動対象
            year: year, // 年数
            term: this.climingRate.deferralPeriod, // 据置期間
            period: this.climingRate.rent.changePeriod, // 変動周期
            factor: this.climingRate.rent.coefficientOfVariation, // 変動係数
          });
          total += tmp1;
          // 空車損失＝収入×空車率
          var tmp2 = (tmp1 * vacancyRate) / 100;
          total -= tmp2;
          // 年間管理費＝収入×管理費率
          var tmp3 = (tmp1 * managementCostRate) / 100;
          total -= tmp3;
          // 土地固都税
          var tmp4 = this.calcTaxLand({
            surfaceArea: this.surfaceArea, // 敷地面積
            propertyTaxAssessment: this.propertyTaxAssessment, // 固定資産税評価額
            year: year, // 年数
          });
          total -= tmp4;
          // 年間ローン返済額
          if (year <= this.inputValue.overallPlan.monthlyParkingLot.borrowingPeriod) {
            total -= repayment * 12;
          }
          // 累計
          data.push(total / 10000);
        }
        return data;
      },
      // 収支累計計算／貸地
      calcBalanceSheetLeasedLand: function() {
        var data = [null];
        var total = 0;
        var value = this.incomeLandRent() * 12;
        for (var year = 1; year <= 45; year++) {
          // 月地代収入×12
          var income = this.calcChange({
            value: value, // 変動対象
            year: year, // 年数
            term: this.climingRate.deferralPeriod, // 据置期間
            period: this.climingRate.rent.changePeriod, // 変動周期
            factor: this.climingRate.rent.coefficientOfVariation, // 変動係数
          });
          // 土地固都税
          var tax = this.calcTaxLand({
            surfaceArea: this.surfaceArea, // 敷地面積
            propertyTaxAssessment: this.propertyTaxAssessment, // 固定資産税評価額
            year: year, // 年数
          });
          // 収入－管理費－土地固都税を累計
          total +=
            income -
            Math.floor((income * this.master.defaultValue.leasedLand.managementCostRate) / 100) -
            tax; // ↑Excelに合わせるとこうなる
          data.push(total / 10000);
        }
        return data;
      },
      // 収支累計計算／更地
      calcBalanceSheetVacantLand: function() {
        var data = [null];
        var total = 0;
        for (var year = 1; year <= 45; year++) {
          // 年間維持管理費
          var fee = this.calcChange({
            value: this.master.defaultValue.vacantLotOrLand.annualMaintenanceFee * 10000, // 変動対象
            year: year, // 年数
            term: this.climingRate.deferralPeriod, // 据置期間
            period: this.climingRate.maintenanceCosts.changePeriod, // 変動周期
            factor: this.climingRate.maintenanceCosts.coefficientOfVariation, // 変動係数
          });
          // 土地固都税
          var tax = this.calcTaxLand({
            surfaceArea: this.surfaceArea, // 敷地面積
            propertyTaxAssessment: this.propertyTaxAssessment, // 固定資産税評価額
            year: year, // 年数
          });
          // 年間維持管理費と土地固都税を累計（マイナス）
          total -= fee + tax;
          data.push(total / 10000);
        }
        return data;
      },
      // 収支累計計算／売却
      calcBalanceSheetSale: function() {
        var data = [null];
        var price = this.netProceeds(); // 手取額
        var rate = this.master.defaultValue.sale.annualInvestmentYield; // 利回り
        var base = price; // 手取額(初期値)
        for (var year = 1; year <= 45; year++) {
          // 将来価値＝元金×POWER(1+利率,期間)
          var now = price * Math.pow(1 + rate / 100, year);
          // 初期値との差分を追加
          data.push((now - base) / 10000);
        }
        return data;
      },
      // 収支累計計算／店舗・事務所
      calcBalanceStoreOffice: function() {
        // 月額賃料
        var monthlyRent = this.monthlyRent(8);
        // 空車率(対賃料)
        var vacancyRate = this.vacancyRate(8);
        // 管理費率(対賃料)
        var managementCostRate = this.managementRate(8);
        var data = [null];
        var total = 0;
        var income = monthlyRent * 10000 * 12; // 初年度収入
        var repayment = this.loanRepaymentAmount(8); // 返済額(月)
        for (var year = 1; year <= 45; year++) {
          // 収入＝月額賃料×台数×12
          var tmp1 = this.calcChange({
            value: income, // 変動対象
            year: year, // 年数
            term: this.climingRate.deferralPeriod, // 据置期間
            period: this.climingRate.rent.changePeriod, // 変動周期
            factor: this.climingRate.rent.coefficientOfVariation, // 変動係数
          });
          total += tmp1;
          // 空車損失＝収入×空車率
          var tmp2 = (tmp1 * vacancyRate) / 100;
          total -= tmp2;
          // 年間管理費＝収入×管理費率
          var tmp3 = (tmp1 * managementCostRate) / 100;
          total -= tmp3;
          // 土地固都税
          var tmp4 = this.calcTaxLand({
            surfaceArea: this.surfaceArea, // 敷地面積
            propertyTaxAssessment: this.propertyTaxAssessment, // 固定資産税評価額
            year: year, // 年数
            id: 8, // 課税標準額の倍率に必要
          });
          total -= tmp4;
          // 年間ローン返済額
          if (year <= this.inputValue.overallPlan.monthlyParkingLot.borrowingPeriod) {
            total -= repayment * 12;
          }
          // 累計
          data.push(total / 10000);
        }
        return data;
      },
      // グラフデータ生成(将来の相続財産額の推移)
      createInheritedPropertyDatasets: function() {
        var ds = [];
        // 賃貸
        this.columns.forEach(column => {
          if (column.checked) {
            // ラベル
            var label = this.nvl(this.name(column.id), column.text);
            ds.push({
              label: label,
              data: this.createInheritedPropertyRent(column.id),
              borderColor: colors[column.id],
              pointBackgroundColor: colors[column.id],
              borderWidth: 10,
              fill: false,
              lineTension: 0,
              pointRadius: 4,
            });
          }
        });
        // 月極駐車場
        ds.push({
          label: '月極駐車場',
          data: this.createInheritedPropertyParking(),
          borderColor: colors[6],
          pointBackgroundColor: colors[6],
          borderWidth: 10,
          fill: false,
          lineTension: 0,
          pointRadius: 4,
        });
        // 店舗・事務所
        ds.push({
          label: '店舗・事務所',
          data: this.createInheritedPropertyStoreOffice(),
          borderColor: colors[8],
          pointBackgroundColor: colors[8],
          borderWidth: 10,
          fill: false,
          lineTension: 0,
          pointRadius: 4,
        });
        // 貸地
        ds.push({
          label: '貸地',
          data: this.createInheritedPropertyLeasedLand(),
          borderColor: colors[9],
          pointBackgroundColor: colors[9],
          borderWidth: 10,
          fill: false,
          lineTension: 0,
          pointRadius: 4,
        });
        // 更地
        ds.push({
          label: '更地',
          data: this.createInheritedPropertyVacantLand(),
          borderColor: colors[10],
          pointBackgroundColor: colors[10],
          borderWidth: 10,
          fill: false,
          lineTension: 0,
          pointRadius: 4,
        });
        // 売却
        ds.push({
          label: '売却',
          data: this.createInheritedPropertySale(),
          borderColor: colors[11],
          pointBackgroundColor: colors[11],
          borderWidth: 10,
          fill: false,
          lineTension: 0,
          pointRadius: 4,
        });

        return ds;
      },
      // データ生成／相続財産額／賃貸
      createInheritedPropertyRent: function(id) {
        // 戸数
        var num = this.counts(id);
        // 固定資産税評価率(対工事費)
        var propertyTaxAssessmentRate = this.assessmentRate(id);
        // 借入金利
        var borrowingRate = this.borrowingRate(id);
        // 借入期間
        var borrowingPeriod = this.borrowingPeriod(id);
        // 借地権割合
        var r1 = this.inputValue.siteConditions.siteInformation.percentageOfLeaseholdInterest;
        // 借家権割合
        var r2 = this.tenancyRate(id);
        // 空室率
        var r3 = this.vacancyRate(id);
        var data = [null];
        // 土地＝路線価×敷地面積
        //     ×(1－借地権割合×借家権割合)
        var land =
          this.roadsideLandPrice *
          this.surfaceArea *
          (1 - (r1 / 100) * (r2 / 100)) *
          (1 - r3 / 100);
        // 建物＝(1戸当り工事費×戸数×固定資産税評価率)
        //     ×(1－借家権割合)
        var building = this.cost(id) * 10000 * num * (propertyTaxAssessmentRate / 100);
        var fund = this.funds(id) * 10000; // 自己資金
        var pay = this.loanRepaymentAmount(id); // 返済金額
        var loan = this.calcLoanBalance(
          pay,
          borrowingRate / 100,
          this.loanAmount(id) * 10000,
          borrowingPeriod
        );
        // ローン残高;
        for (var year = 1; year <= 45; year++) {
          // ※「固都税 上昇率」を考慮
          var tmp =
            this.calcChange({
              value: building, // 変動対象
              year: year, // 年数
              term: this.climingRate.deferralPeriod, // 据置期間
              period: this.climingRate.tax.building[this.structure[id]].changePeriod, // 変動周期
              factor: this.climingRate.tax.building[this.structure[id]].coefficientOfVariation, // 変動係数
            }) *
            (1 - r2 / 100) *
            (1 - r3 / 100);
          // ローン残高
          var balance = year <= borrowingPeriod ? loan[year] * 10000 : 0;
          // (土地＋建物)－(自己資金＋ローン残高)
          data.push((land + tmp - (fund + balance)) / 10000);
        }
        return data;
      },
      // データ生成／相続財産額／月極駐車場
      createInheritedPropertyParking: function() {
        var data = [null];
        var price = this.roadsideLandPrice * this.surfaceArea; // 路線価×敷地面積
        var fund = this.funds(7) * 10000; // 自己資金
        var pay = this.loanRepaymentAmount(7); // 返済金額
        var loan = this.calcLoanBalance(
          pay,
          this.borrowingRate(7) / 100,
          this.loanAmount(7) * 10000,
          this.borrowingPeriod(7)
        ); // ローン残高;
        for (var year = 1; year <= 45; year++) {
          // 路線価×敷地面積－(自己資金＋ローン残高)
          var balance = year <= this.borrowingPeriod(7) ? loan[year] * 10000 : 0;
          data.push((price - fund - balance) / 10000);
        }
        return data;
      },
      // データ生成／相続財産額／店舗・事務所
      createInheritedPropertyStoreOffice: function() {
        var data = [null];
        var price = this.roadsideLandPrice * this.surfaceArea; // 路線価×敷地面積
        var fund = this.funds(8) * 10000; // 自己資金
        var pay = this.loanRepaymentAmount(8); // 返済金額
        var loan = this.calcLoanBalance(
          pay,
          this.borrowingRate(8) / 100,
          this.loanAmount(8) * 10000,
          this.borrowingPeriod(8)
        ); // ローン残高;
        for (var year = 1; year <= 45; year++) {
          // 路線価×敷地面積－(自己資金＋ローン残高)
          var balance = year <= this.borrowingPeriod(8) ? loan[year] * 10000 : 0;
          data.push((price - fund - balance) / 10000);
        }
        return data;
      },
      // データ生成／相続財産額／貸地
      createInheritedPropertyLeasedLand: function() {
        // 更地と同様
        return this.createInheritedPropertyVacantLand();
      },
      // データ生成／相続財産額／更地
      createInheritedPropertyVacantLand: function() {
        var data = [null];
        var price = this.roadsideLandPrice * this.surfaceArea; // 路線価×敷地面積
        for (var year = 1; year <= 45; year++) {
          // 路線価×敷地面積
          data.push(price / 10000);
        }
        return data;
      },
      // データ生成／相続財産額／売却
      createInheritedPropertySale: function() {
        var data = [null];
        var price = this.netProceeds(); // 手取額
        for (var year = 1; year <= 45; year++) {
          // 手取額
          data.push(price / 10000);
        }
        return data;
      },
      // 変動計算
      calcChange: function(param) {
        // value    変動対象
        // year     年数
        // term     据置期間
        // period   変動周期
        // factor   変動係数
        let tmp = Math.max(0, Math.ceil((param.year - param.term) / param.period));
        let result = param.value;
        // Excel風の計算
        for (let i = 0; i < tmp; i++) {
          result = Math.floor(result * (1 + param.factor / 100));
        }
        return result;
      },
      // 建物固都税計算 ※「固都税 上昇率」を考慮
      calcTaxBuilding: function(param) {
        // マスタチェック
        if (this.master.taxRate == null) {
          return null;
        }
        //  constructionCostPerUnit     1戸当り工事費
        //  numberOfHouses              戸数
        //  propertyTaxAssessmentRate   固定資産税評価率
        //  structure                   構造
        //  year                        年数
        // 課税標準額＝1戸当り工事費×戸数×固定資産税評価率
        var tmp =
          param.constructionCostPerUnit *
          10000 *
          param.numberOfHouses *
          (param.propertyTaxAssessmentRate / 100);
        // 上昇率考慮
        tmp = this.calcChange({
          value: tmp, // 変動対象
          year: param.year, // 年数
          term: this.climingRate.deferralPeriod, // 据置期間
          period: this.climingRate.tax.building[param.structure].changePeriod, // 変動周期
          factor: this.climingRate.tax.building[param.structure].coefficientOfVariation, // 変動係数
        });
        // 固定資産税＝課税標準税額×固定資産税率
        var t1 = Math.floor((tmp * this.master.defaultValue.taxLand.propertyTax) / 100);
        // 都市計画税＝課税標準税額×都市計画税率
        var t2 = Math.floor((tmp * this.master.defaultValue.taxLand.cityPlanningTax) / 100);
        return t1 + t2;
      },
      // 土地固都税計算 ※「固都税 上昇率」を考慮
      calcTaxLand: function(param) {
        // マスタチェック
        if (this.master.taxRate == null) {
          return null;
        }
        // surfaceArea              敷地面積(㎡)
        // propertyTaxAssessment    固定資産税評価額(円/坪)
        // isResidence              住宅の場合、true
        // year                     年数
        // 課税標準額＝敷地面積×固定資産税評価額
        var tmp = Math.floor(
          this.tsubo(param.surfaceArea) * param.propertyTaxAssessment * (param.id === 8 ? 0.7 : 1)
        );
        // 住宅以外の場合、通常の計算
        if (!param.isResidence) {
          // 上昇率考慮
          tmp = this.calcChange({
            value: tmp, // 変動対象
            year: param.year, // 年数
            term: this.climingRate.deferralPeriod, // 据置期間
            period: this.climingRate.tax.land.changePeriod, // 変動周期
            factor: this.climingRate.tax.land.coefficientOfVariation, // 変動係数
          });
          // 固定資産税＝課税標準税額×固定資産税率
          var t1 = Math.floor((tmp * this.master.defaultValue.taxLand.propertyTax) / 100);
          // 都市計画税＝課税標準税額×都市計画税率
          var t2 = Math.floor((tmp * this.master.defaultValue.taxLand.cityPlanningTax) / 100);
        }
        // 住宅の場合、小規模宅地特例を適用
        else {
          var tmp1 = Math.floor(
            param.propertyTaxAssessment * this.tsubo(Math.min(param.surfaceArea, 200))
          );
          var tmp2 = tmp - tmp1; // 200㎡超
          // 上昇率考慮
          tmp1 = this.calcChange({
            value: tmp1, // 変動対象
            year: param.year, // 年数
            term: this.climingRate.deferralPeriod, // 据置期間
            period: this.climingRate.tax.land.changePeriod, // 変動周期
            factor: this.climingRate.tax.land.coefficientOfVariation, // 変動係数
          });
          tmp2 = this.calcChange({
            value: tmp2, // 変動対象
            year: param.year, // 年数
            term: this.climingRate.deferralPeriod, // 据置期間
            period: this.climingRate.tax.land.changePeriod, // 変動周期
            factor: this.climingRate.tax.land.coefficientOfVariation, // 変動係数
          });
          // [固定資産税]
          // 小規模住宅用地（200㎡以下の部分）・・・課税標準 × 1／6
          // 一般住宅用地（200㎡超の部分）    ・・・課税標準 × 1／3
          t1 =
            Math.floor(((tmp1 / 6) * Number(this.master.defaultValue.taxLand.propertyTax)) / 100) +
            Math.floor(((tmp2 / 3) * Number(this.master.defaultValue.taxLand.propertyTax)) / 100);
          // [都市計画税]
          // 小規模住宅用地（200㎡以下の部分）・・・課税標準 × 1／3
          // 一般住宅用地（200㎡超の部分）    ・・・課税標準 × 2／3
          t2 =
            Math.floor(
              ((tmp1 / 3) * Number(this.master.defaultValue.taxLand.cityPlanningTax)) / 100
            ) +
            Math.floor(
              (((tmp2 * 2) / 3) * Number(this.master.defaultValue.taxLand.cityPlanningTax)) / 100
            );
        }
        // 固定資産税+都市計画税
        return t1 + t2;
      },
      // 相続税計算（メイン）
      calcInheritanceTax: function(year, bs) {
        var ret = [];
        if (year == null || bs == null) {
          return null;
        }
        for (var i = 0; i < bs.length; i++) {
          if (bs[i] == null) {
            ret.push(null);
          } else {
            var total = 0; // 遺産総額
            total += bs[i][year] * 10000; // 指定年度の収支を加算
            // 貸地・⻘空駐⾞場・賃貸住宅で、
            // 現金収支累計がプラスになる場合は
            // その80%を手取り金額とみなして表⽰します
            if ((i < 8 || i == 9) && total > 0) {
              total *= 0.8;
            }
            // 売却の場合は、路線価と売却時手取り現金の差額を加算
            if (i == 11) {
              total += this.netProceeds() - this.roadsideLandPrice * this.surfaceArea;
            }
            // 推定相続財産額を加算
            total += this.inputValue.siteConditions.ownerInformation.estimatedEstateValue * 10000;
            // ローン残高をマイナス
            if (i < 8) {
              total -= this.loanBalance(
                this.loanRepaymentAmount(i),
                this.borrowingRate(i) / 100,
                this.loanAmount(i) * 10000,
                year
              );
            }
            // 相続税計算
            var tax = this.calcInheritanceTaxSub(total);
            ret.push(tax);
          }
        }
        return ret;
      },
      // 相続税計算（サブ）
      calcInheritanceTaxSub: function(total) {
        // マスタチェック
        if (this.master.inheritanceTax == null) {
          return null;
        }
        // 相続人数
        var count = this.numberOfHeirs;
        if (count == 0) {
          return null;
        }
        // 基礎控除額
        var base = 30000000 + 6000000 * count;
        if (total < base) {
          return null;
        }
        // 法定相続税額の計算
        var legalTax = [];
        for (let i = 0; i < count; i++) {
          // 法定相続金額＝(遺産総額－基礎控除額)×相続割合
          var amount = (total - base) * this.actualInheritancePercentage[i].percentage;
          // 速算表参照
          for (let x of this.master.inheritanceTax) {
            if (amount < Number(x[0])) {
              // 法定相続税額＝法定相続金額×税率－控除額
              legalTax[i] = amount * (Number(x[1]) / 100) - Number(x[2]);
              break;
            }
          }
        }
        // 法定相続税額を集計
        if (legalTax.length === 0) {
          return null;
        }
        var legalTaxSum = legalTax.reduce(function(a, c) {
          return a + c;
        });
        // 実際の相続税額の計算
        var tax = [];
        for (let i = 0; i < count; i++) {
          // 相続税額＝SUM(法定相続税額)×相続割合
          tax[i] = legalTaxSum * (this.actualInheritancePercentage[i].realPercentage / 100);
          // ※配偶者は「配偶者の税額軽減」によりゼロ
          if (this.actualInheritancePercentage[i].heiress == '配偶者') {
            tax[i] = 0;
          }
        }
        // 実際の相続税額の合計を返す
        return tax.reduce(function(a, c) {
          return a + c;
        });
      },
      // 損益分岐点空室率
      calcBreakevenVacancyRate: function(id) {
        var income = this.monthlyRent(id) * 10000 * this.counts(id) * 12; // 家賃収入
        var repair = this.repairCosts(id); // 年間修繕費
        var tax = this.taxBuiding[id]; // 建物固都税
        var man = this.managementCosts(id); // 年間管理費
        var loan = this.loanRepaymentAmount(id) * 12; // ローン返済額
        // (家賃収入－年間修繕費－建物固都税－年間管理費－ローン返済額)／家賃収入
        // ※⼟地の固都税は除外
        return (income - repair - tax - man - loan) / income;
      },
      // ローン残高
      loanBalance: function(repayment, rate, amount, year) {
        // N回後の借受残元金=
        //  (返済金額-POWER(1+利率,N)*(返済額－借入金額×利率))/利率
        var value =
          (repayment - Math.pow(1 + rate / 12, year * 12) * (repayment - (amount * rate) / 12)) /
          (rate / 12);
        return Math.max(year == 0 ? amount : value, 0);
      },
      // NVL
      nvl: function(v1, v2) {
        return v1 == null || v1 == '' || v1 == undefined ? v2 : v1;
      },
      //相続税路線価またはその単位、固定資産税評価額の推定or実績が変化した時の計算
      calcPriceSub: function(sellPrice = true) {
        if (this.inputValue.siteConditions.siteInformation.roadsideLandPrice != null) {
          if (this.inputValue.siteConditions.siteInformation.presumption == 2) {
            // 推定の場合に自動計算
            //固定資産税評価額（推定）=（路線価）/ 0.8 * 0.7
            this.inputValue.siteConditions.siteInformation.propertyTaxAssessment = Math.floor(
              (this.roadsideLandPrice / 0.3025 / 0.8) * 0.7
            ); //路線価は円/㎡で扱う仕様のため円/坪に変換
          }

          //固定資産税評価額の推定or実績が変化した時は計算しない(自由入力できるため)
          if (sellPrice) {
            //売却額（目安）=（路線価）/ 0.8
            this.inputValue.siteConditions.siteInformation.availableForSale = Math.floor(
              this.roadsideLandPrice / 0.3025 / 0.8
            ); //路線価は円/㎡で扱う仕様のため円/坪に変換
          }
        }
      },
      //全体計画＞その他＞売却価格
      autoSalesPrice: function() {
        let availableForSale = this.inputValue.siteConditions.siteInformation.availableForSale;

        //どちらかがnullの時は処理をしないで終了する
        if (this.surfaceArea == null) return;
        if (availableForSale == null) return;

        // 売却価格＝敷地面積(㎡⇒坪) x 売却額（目安）(円/坪)
        this.inputValue.overallPlan.sale.salePrice = parseFloat(
          ((this.tsubo(this.surfaceArea) * availableForSale) / 10000).toFixed(1)
        );
      },
    },
    mounted: async function() {
      //全体計画チェックボックス
      this.inputValue.overallPlan.rentalProperty.forEach((property, index) => {
        this.columns[index].checked = property.checked;
      });
      //月極駐車場：台数
      if (this.inputValue.overallPlan.monthlyParkingLot.numberOfUnits == null) {
        this.inputValue.overallPlan.monthlyParkingLot.numberOfUnits = parseInt(
          this.landArea / this.parkingAreaPerCar
        );
      }
      //店舗・事務所の自己資金計算
      if (this.inputValue.overallPlan.storeOffice.personalFunds == null) {
        this.inputValue.overallPlan.storeOffice.personalFunds =
          (this.constructionCosts(8) * this.master.defaultValue.storeOffice.equityRatio) / 100;
      }
      //規定値の存在チェック
      if (this.master.taxRate == null) {
        return;
      }
      //↓↓経緯は不明ながら使用されていなかった。何れにせよ単価設定の途中変更に対応できないので使用しない
      // 税率
      this.taxRate = {
        property: Number(this.master.defaultValue.taxLand.propertyTax), // 固定資産税率
        cityPlanning: Number(this.master.defaultValue.taxLand.cityPlanningTax), // 都市計画税率
        income: {
          shortTerm: Number(this.master.defaultValue.sale.capitalGainsTaxRateShortTerm), // 短期所得税税率
          longTerm: Number(this.master.defaultValue.sale.capitalGainsTaxRateLongTerm), // 長期所得税税率
          specialCase: Number(this.master.taxRate[4][1]), // 10年超所有軽減税率の特例
        },
      };
      //↑↑
      // 上昇率
      this.master.climingRate.forEach(x => {
        var arg = x[0].split('-');
        switch (arg[0]) {
          case '据え置き期間':
            this.climingRate.deferralPeriod = Number(x[1]);
            break;
          case '賃料':
            switch (arg[1]) {
              case '変動周期':
                this.climingRate.rent.changePeriod = Number(x[1]);
                break;
              case '変動係数':
                this.climingRate.rent.coefficientOfVariation = Number(x[1]);
                break;
            }
            break;
          case '固都税':
            switch (arg[1]) {
              case '土地':
                switch (arg[2]) {
                  case '変動周期':
                    this.climingRate.tax.land.changePeriod = Number(x[1]);
                    break;
                  case '変動係数':
                    this.climingRate.tax.land.coefficientOfVariation = Number(x[1]);
                    break;
                }
                break;
              case '建物':
                switch (arg[3]) {
                  case '変動周期':
                    this.climingRate.tax.building[arg[2]].changePeriod = Number(x[1]);
                    break;
                  case '変動係数':
                    this.climingRate.tax.building[arg[2]].coefficientOfVariation = Number(x[1]);
                    break;
                }
                break;
            }
            break;
          case '維持費':
            switch (arg[1]) {
              case '変動周期':
                this.climingRate.maintenanceCosts.changePeriod = Number(x[1]);
                break;
              case '変動係数':
                this.climingRate.maintenanceCosts.coefficientOfVariation = Number(x[1]);
                break;
            }
            break;
        }
      });
      this.loading = false;
    },
  };
</script>
<style>
  #realEstateSuitability .nav-link {
    font-size: medium;
  }
  #realEstateSuitability table tr {
    height: 1.5em;
  }
  #realEstateSuitability .component {
    padding: 0;
  }
  #realEstateSuitability table tbody td,
  #realEstateSuitability table thead th {
    padding-top: 0.1em;
    padding-bottom: 0.1em;
  }
  #realEstateSuitability .title {
    padding-right: 0.5em;
    display: grid;
    text-align: right;
    font-weight: bold;
    align-items: center;
  }
  #realEstateSuitability .value {
    padding: 0;
    margin: 0;
    display: table-cell;
    align-items: center;
  }
  #realEstateSuitability .unit {
    padding-left: 0.5em;
    padding-right: 0;
    display: flex;
    text-align: left;
    align-items: center;
  }
  #realEstateSuitability .currency {
    text-align: right;
  }
  #realEstateSuitability .ally-select select {
    height: 2.875rem;
  }
  #realEstateSuitability .form-group {
    margin-top: 0;
    margin-bottom: 0;
  }
  #realEstateSuitability .form-control {
    padding: 0.5em;
    margin-left: 0px;
  }
  #realEstateSuitability .form-group label,
  #realEstateSuitability .form-check-label {
    font-weight: bold;
  }
  #realEstateSuitability .checkbox,
  #realEstateSuitability .checkbox label {
    font-weight: bold;
  }
  #realEstateSuitability #actualInheritancePercentage tr {
    height: 1.5em;
  }
  #realEstateSuitability input[type='number'] {
    text-align: right;
    padding-right: 15px;
  }
  #realEstateSuitability legend {
    padding-bottom: 0.5em;
    font-weight: bold;
  }
  #realEstateSuitability input[type='radio'] {
    margin-left: unset;
  }
  #realEstateSuitability input[name='rentLandRadios'] + label,
  #realEstateSuitability #spouse label {
    font-size: initial;
  }
  #realEstateSuitability .card {
    margin-top: 0.5em;
    border-top-color: gainsboro !important;
  }
  #realEstateSuitability #ownerInformation .card,
  #realEstateSuitability #ownerInformation .card-body,
  #realEstateSuitability #siteInformation .card,
  #realEstateSuitability #siteInformation .card-body {
    border: 0 !important;
    padding-top: 0.5em;
    padding-bottom: 0;
    padding-left: 0.25em;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  #realEstateSuitability .card-header {
    font-size: medium;
    font-weight: bold;
  }
  #defaultValue #rentalProperty,
  #masterPlan #rentalProperty {
    max-width: unset;
  }
  #defaultValue #parking .row,
  #defaultValue #sale .row,
  #siteConditions .row,
  #locationConditions .row {
    margin-bottom: 1em;
  }
  #defaultValue #parking .unit,
  #defaultValue #sale .unit,
  #defaultValue #vacantLand .unit,
  #siteConditions .unit {
    padding-bottom: 1em;
    align-items: flex-end;
  }
  #realEstateSuitability input,
  #realEstateSuitability .title,
  #realEstateSuitability .ally-select select,
  #realEstateSuitability .unit {
    font-size: small;
  }
  #realEstateSuitability .checkbox,
  #realEstateSuitability .checkbox label {
    font-size: medium;
  }
  #realEstateSuitability #graph .nav-link {
    font-size: unset;
    padding: 4px;
    margin: 0;
  }
  #realEstateSuitability .carousel-control-prev,
  #realEstateSuitability .carousel-control-next {
    width: 0%;
  }
  #realEstateSuitability #siteConditions #ownerInformation .card-columns,
  #realEstateSuitability #siteConditions #siteInformation .card-columns {
    column-count: 2;
  }
  #realEstateSuitability #multiple-file-preview img {
    width: 32px;
    height: 32px;
  }
  @media screen and (min-width: 576px) {
    #realEstateSuitability #defaultValue .card-columns,
    #realEstateSuitability #masterPlan .card-columns {
      column-count: 1;
    }
    #realEstateSuitability #siteConditions .card-columns {
      column-count: 1;
    }
  }
  @media screen and (min-width: 768px) {
    /* iPad     - 縦 :  768 */
    #realEstateSuitability #defaultValue .card-columns,
    #realEstateSuitability #masterPlan .card-columns,
    #realEstateSuitability #siteConditions .card-columns {
      column-count: 1;
    }
  }
  @media screen and (min-width: 992px) {
    /* iPad Pro - 縦 : 1024 */
    /* iPad     - 横 : 1024 */
    #realEstateSuitability #defaultValue .card-columns,
    #realEstateSuitability #masterPlan .card-columns,
    #realEstateSuitability #siteConditions .card-columns {
      column-count: 2;
    }
  }
  @media screen and (min-width: 1200px) {
    /* iPad Pro - 横 : 1366 */
    #realEstateSuitability #defaultValue .card-columns,
    #realEstateSuitability #masterPlan .card-columns {
      column-count: 3;
    }
    #realEstateSuitability #siteConditions .card-columns {
      column-count: 2;
    }
  }
  .image-upload > input {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .previewImage {
    width: 200px;
    height: 300px;
    border-radius: 0%;
  }
  [id^='ally-text-disp-inputValue-overallPlan-personalFunds'] {
    text-align: right;
  }
  #ally-text-disp-inputValue-overallPlan-storeOffice-personalFunds,
  #ally-text-disp-inputValue-overallPlan-monthlyParkingLot-personalFunds {
    text-align: right;
  }
  #realEstateSuitability input[name='incomeTaxLabel'] + label {
    font-size: small;
  }
  .incomeTaxRate > .custom-radio > .custom-control-label {
    margin: 0%;
  }
  #realEstateSuitability .selectUnit {
    font-size: small;
    padding-top: 0.5em;
    padding-left: 0.5em;
    padding-right: 0;
    display: flex;
    text-align: left;
    align-items: center;
  }
  #realEstateSuitability .selectUnit .custom-select {
    vertical-align: bottom;
    font-size: small;
  }
  #realEstateSuitability .squareMeter .custom-select {
    color: red;
  }
  #realEstateSuitability .squareMeter .custom-select option {
    color: black;
  }
  #realEstateSuitability .landMeasurement .custom-select {
    color: royalblue;
  }
  #realEstateSuitability .landMeasurement .custom-select option {
    color: black;
  }
  #realEstateSuitability .unit.landMeasurement {
    color: royalblue;
  }
</style>
