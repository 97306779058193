var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CommonLayout',{attrs:{"title":"Ⅲ．実行可能な具体的活用法"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ReportSubTitle',{attrs:{"subTitle":"比較検討の前提条件各項目の解説"}}),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"width":"50%","padding":"1%","margin-left":"-45px","margin-top":"20px"}},[_c('ReportTable',{style:(_vm.tablseStyle),attrs:{"header":_vm.headerMonthlyParkingLot,"items":_vm.itemsMonthlyParkingLot},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var col = ref.col;
return [_c('div',{staticStyle:{"font-size":"70px","text-align":"center"},domProps:{"innerHTML":_vm._s(col.label)}})]}},{key:"table",fn:function(ref){
var col = ref.col;
var item = ref.item;
return [_c('div',{staticStyle:{"padding-right":"15px","font-size":"55px"},domProps:{"innerHTML":_vm._s(item[col.name].toLocaleString('ja-JP', { maximumSignificantDigits: 20 }))}})]}}])}),_c('ReportTable',{style:(_vm.tablseStyle),attrs:{"header":_vm.headerRentLand,"items":_vm.itemsRentLand},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var col = ref.col;
return [_c('div',{staticStyle:{"font-size":"70px","text-align":"center"},domProps:{"innerHTML":_vm._s(col.label)}})]}},{key:"table",fn:function(ref){
var col = ref.col;
var item = ref.item;
return [_c('div',{staticStyle:{"padding-right":"15px","font-size":"55px"},domProps:{"innerHTML":_vm._s(item[col.name].toLocaleString('ja-JP', { maximumSignificantDigits: 20 }))}})]}}])}),_c('ReportTable',{style:(_vm.tablseStyle),attrs:{"header":_vm.headerEmpty,"items":_vm.itemsVacantLotOrLand},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var col = ref.col;
return [_c('div',{staticStyle:{"font-size":"70px","text-align":"center"},domProps:{"innerHTML":_vm._s(col.label)}})]}},{key:"table",fn:function(ref){
var col = ref.col;
var item = ref.item;
return [_c('div',{staticStyle:{"padding-right":"15px","font-size":"55px"},domProps:{"innerHTML":_vm._s(item[col.name].toLocaleString('ja-JP', { maximumSignificantDigits: 20 }))}})]}}])})],1),_c('div',{staticStyle:{"width":"50%","padding":"1%","margin-top":"20px"}},[_c('ReportTable',{style:(_vm.tablseStyle),attrs:{"header":_vm.headerStoreOffice,"items":_vm.itemsStoreOffice},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var col = ref.col;
return [_c('div',{staticStyle:{"font-size":"70px","text-align":"center"},domProps:{"innerHTML":_vm._s(col.label)}})]}},{key:"table",fn:function(ref){
var col = ref.col;
var item = ref.item;
return [_c('div',{staticStyle:{"padding-right":"15px","font-size":"55px"},domProps:{"innerHTML":_vm._s(item[col.name].toLocaleString('ja-JP', { maximumSignificantDigits: 20 }))}})]}}])}),_c('ReportTable',{style:(_vm.tablseStyle),attrs:{"header":_vm.headerSale,"items":_vm.itemsSale},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var col = ref.col;
return [_c('div',{staticStyle:{"font-size":"70px","text-align":"center"},domProps:{"innerHTML":_vm._s(col.label)}})]}},{key:"table",fn:function(ref){
var col = ref.col;
var item = ref.item;
return [(typeof item[col.name] == 'number')?_c('div',{staticStyle:{"padding-right":"15px","font-size":"55px"},domProps:{"innerHTML":_vm._s(item[col.name].toLocaleString('ja-JP', { maximumSignificantDigits: 20 }))}}):_c('div',{staticStyle:{"padding-right":"15px","font-size":"55px"},domProps:{"innerHTML":_vm._s(item[col.name])}})]}}])})],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }