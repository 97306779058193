<template>
  <div style="height: 80%;">
    <!-- <div style="display: flex; height: 30px;">
      <TileBtn
        :selected="selectMenuMode === 'detail'"
        @click="$emit('changeSelectedMenuMode', 'detail')"
        ><i class="ti-pencil-alt"
      /></TileBtn>
      <TileBtn
        :selected="selectMenuMode === 'tree'"
        @click="$emit('changeSelectedMenuMode', 'tree')"
        ><i class="ti-list"
      /></TileBtn>
    </div> -->
    <TreeView v-if="selectMenuMode === 'tree'" :objectTree="objectTree" />
    <div v-if="selectMenuMode === 'detail' && !activeObject" class="not-selected-container">
      <div v-show="activeObject == null" style="text-align: center">
        <span class="not-selected-text">オブジェクトを</span><br />
        <span class="not-selected-text">選択してください</span>
      </div>
    </div>
    <div
      v-if="activeObject != null && selectMenuMode !== 'tree'"
      class="item-list-container floor-material-list"
      :style="{ height: listHeight + 'px', padding: '10px' }"
    >
      <CommonState :activeObject="activeObject" :isEditor="isEditor" />
      <FloorState
        v-if="activeObject.type === 'floor'"
        :activeObject="activeObject"
        :materials="materials"
        :height="height"
        :isEditor="isEditor"
      />
      <IconState
        v-if="activeObject.type === 'icon'"
        :activeObject="activeObject"
        :isEditor="isEditor"
      />
      <DoorState
        v-if="activeObject.type === 'door'"
        :activeObject="activeObject"
        :height="height"
        :isEditor="isEditor"
        :doorWindowList="doorWindowList"
      />
      <WallState
        v-if="activeObject.type === 'wall'"
        :activeObject="activeObject"
        :isEditor="isEditor"
      />
      <StairsState
        v-if="activeObject.type === 'stairs'"
        :activeObject="activeObject"
        :isEditor="isEditor"
        :isVRSimulatorLicense="isVRSimulatorLicense"
        :stairList="stairList"
      />
      <TextState
        v-if="activeObject.type === 'text'"
        :activeObject="activeObject"
        :isEditor="isEditor"
      />
    </div>
  </div>
</template>
<script>
  //@ts-check
  import { computed, defineComponent } from '@vue/composition-api';
  import TileBtn from '../../PvBtn.vue';
  import TreeView from '../TreeView.vue';
  import CommonState from './CommonState.vue';
  import FloorState from './FloorState.vue';
  import IconState from './IconState.vue';
  import DoorState from './DoorState.vue';
  import WallState from './WallState.vue';
  import StairsState from './StairsState.vue';
  import TextState from './TextState.vue';

  export default defineComponent({
    components: {
      TileBtn,
      TreeView,
      CommonState,
      FloorState,
      IconState,
      DoorState,
      WallState,
      StairsState,
      TextState,
    },
    props: {
      mode: String,
      activeObject: Object, // PvObject
      selectMenuMode: String,
      objectTree: Array, // PvObject[]
      height: Number,
      materials: {
        type: Array,
        default: () => [],
      },
      isEditor: {
        //  PROP: 編集権限の有無
        type: Boolean,
        default: true,
      },
      isVRSimulatorLicense: Boolean,
      stairList: Array, // PROP: 階段リスト
      doorWindowList: Array, // PROP: 建具リスト
    },
    setup(props) {
      const listHeight = computed(() => {
        return props.height - 30; // DATA: リストの縦幅
      });

      return {
        listHeight,
      };
    },
  });
</script>
<style scoped>
  .menu-header-title {
    display: flex;
    justify-content: center;
    height: 40px;
    line-height: 40px;
  }
  .not-selected-text {
    font-size: 15px;
    font-weight: bold;
    color: #666666;
  }
  .not-selected-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .item-list-container {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .item-list-container::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .item-list-container::-webkit-scrollbar-track {
    background: #eeeeee;
  }
  .item-list-container::-webkit-scrollbar-thumb {
    background: var(--colorTheme);
    border-radius: 10px;
  }
</style>
