// @ts-check

import { DEFAULT_DOOR_TYPE, DEFAULT_WALL_TYPE, FLOOR_MODE, MODE } from './const';

export class GlobalSettings {
  mode = MODE.FLOOR;
  floorType = FLOOR_MODE.RECT;
  doorType = DEFAULT_DOOR_TYPE;
  wallType = DEFAULT_WALL_TYPE;
  stairsWidth = 100;
  stairsLeftSize = false;
  stairsWallOffset = true;
}
