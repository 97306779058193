<!--
作成者：Lee HyeonSeok
作成日：2020.6.10
画面名：イメージ入力フォーム
機能：イメージをダウンロード、照会する。
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div class="form-group">
    <label v-if="title" style="display: flex;">
      {{ title }}
      <allyBadge :mode="hideBadge ? 'View' : mode" :rules="rules" />
    </label>
    <div>
      <label
        class="btn btn-ally form-control"
        :for="name + 'images'"
        :class="mode == 'View' ? 'labelDisable' : ''"
        >画像を選択</label
      >
      <input
        type="file"
        :id="name + 'images'"
        multiple="multiple"
        @change="fileLoad"
        :disabled="mode == 'View'"
        accept="image/png, image/jpeg"
      />
      <div class="row">
        <img :src="image.fileDataURL" width="100%" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    // mode: View/Edit/Add
    // value: 値
    // loading: ロード中確認
    // hideBadge: バッジ非表示
    props: ['mode', 'value', 'title', 'loading', 'name', 'private', 'rules', 'hideBadge'],
    data: function() {
      return {
        image: {},
      };
    },
    methods: {
      fileLoad: function(e) {
        var files = e.target.files;

        // イメージロード
        var file = files[0];
        var reader = new FileReader();
        if (file) reader.readAsDataURL(file);

        reader.onload = this.addImage;
      },
      addImage: function(imageOrigin) {
        // Image-DataURL
        var image = imageOrigin.target.result;

        //画像を選択
        this.image.fileDataURL = image;
        this.$emit('input', image);
        this.$forceUpdate();
      },
    },
  };
</script>

<style scoped>
  input[type='file'] {
    display: none;
  }
  .input[type='file']:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgb(40 167 69 / 25%);
  }
  .labelDisable {
    opacity: 0.65;
  }
</style>
