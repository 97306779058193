<template>
  <div>
    <div class="mt-5"></div>
    <h2 class="heading">シミュレーター結果</h2>

    <div class="row serch-data-table-top" style="margin-top:0px">
      <div class="col-12">
        <b-table
          thead-tr-class="serch-data-table-header"
          tbody-tr-class="serch-data-table-body"
          hover
          striped
          :fixed="true"
          :busy="loading"
          :items="logList"
          :fields="fields"
          show-empty
          responsive="sm"
        >
          <template v-slot:table-busy>
            <Load />
          </template>
          <template v-slot:empty>
            <div class="row">
              <div class="col-12 text-center">
                <svg
                  class="bi bi-exclamation-diamond-fill"
                  width="8em"
                  height="8em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                  />
                </svg>
                <div class="alert">データがありません</div>
              </div>
            </div>
          </template>
          <template v-slot:cell(imageUrl)="row">
            <div v-if="loading == false">
              <SlideImage
                :slideImages="row.item.imageUrl"
                :loadFlag="true"
                :max_width="150"
                :max_height="90"
              />
            </div>
          </template>
          <template v-slot:cell(update)="row">
            <b-button class="simbutton" variant="ally" @click="simLink(row.item)">{{simulatorStr}}</b-button>
          </template>
        </b-table>
      </div>
    </div>
    <h4>※最新10件まで表示されます。</h4>
  </div>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { getS3Url, listPhotoSimulators, getProperty } from '../../../graphql/queries';
  import awsconfig from '../../../aws-exports';
  import Load from '../../../components/Common/Load';
  import SlideImage from './slideImage.vue';

  API.configure(awsconfig);

  export default {
    data: function() {
      return {
        logList: [],
        fields: [
          { key: 'updateDate', label: '日時' },
          { key: 'imageUrl', label: 'イメージ', thStyle: 'width: 30%' },
          { key: 'simName', label: '画像名' },
          { key: 'propertyName', label: '物件名' },
          { key: 'staffName', label: '担当者' },
          { key: 'roomName', label: '部屋番号' },
          { key: 'update', label: 'シミュレーター' },
        ],
        loading: true,
        simulatorStr: `シミュ
                        レーター`,
      };
    },
    watch: {},
    mounted: async function() {
      this.loading = true;
      this.logLoed();
    },
    methods: {
      //ログをLoadingして表示する。
      async logLoed() {
        let logList = [];
        let photoData = await this.getPhotoData();
        photoData = photoData.filter(val => val.quickSimulationFlag == 'true');

        for (let photo of photoData) {
          try {
            let getResult = await API.graphql({
              query: getProperty,
              variables: { id: photo.propertyId },
            });
            if (getResult.data.getProperty != null) {
              let log = {
                simName: photo.simInfo.baseImageDisplayName,
                simInfoName: photo.simInfo.baseImageFileName,
                propertyId: photo.propertyId,
                simInfoPattern: photo.simInfo.savePeten,
                quickSimulationFlag: photo.quickSimulationFlag,
                updateDate: this.getViewDate(photo.updatedAt),
                propertyName: getResult.data.getProperty.commonValue.propertyName,
                staffName: getResult.data.getProperty.commonValue.staffName,
                roomId: photo.roomId,
              };

              //部屋情報がない時は、そのままシミュレーター一覧に表示する
              if (photo.roomId != null) {
                let roomData = null;
                if (getResult.data.getProperty.roomInfo != null) {
                  roomData = getResult.data.getProperty.roomInfo.find(
                    val => val.id == photo.roomId
                  );
                }
                if (roomData != null) {
                  log.roomName = roomData.roomName;
                  logList.push(log);
                }
              } else {
                logList.push(log);
              }
            }
          } catch (e) {
            console.error(e);
          }
        }
        logList.sort(function(a, b) {
          return new Date(b.updateDate) - new Date(a.updateDate);
        });
        this.logList = logList.slice(0, 10);

        for (let log of this.logList) {
          if (log.simInfoPattern == null) {
            log.imageUrl = await this.getImage(log);
          } else if (log.simInfoPattern.length == 0) {
            log.imageUrl = await this.getImage(log);
          } else {
            if (log.simInfoPattern[0].id == null) {
              log.imageUrl = await this.getImage(log);
            } else {
              //patternが存在するときの処理
              log.imageUrl = await this.getImagePatten(log);
            }
          }
          log.viewFlag = true;
        }
        this.loading = false;
      },
      /**
       * イメージ画像を取得する。（ベースイメージ）
       */
      async getImage(log) {
        var links = await API.graphql(
          graphqlOperation(getS3Url, {
            level: 'public',
            file: {
              fileName: log.simInfoName,
              fileAction: 'View',
              propertyDirectory: {
                propertyId: log.propertyId,
                fileDirectory: 'simulation/baseImage',
              },
            },
          })
        );
        let list = [];
        list.push(links.data.getS3Url[0].fileUrl);
        return list;
      },
      /**
       * パターン数分の画像を取得する
       */
      async getImagePatten(log) {
        let list = [];
        if (log.simInfoPattern == null) return null;
        for (let pattern of log.simInfoPattern) {
          let str = log.simInfoName.replace(/\.[^/.]+$/, '') + '_' + pattern.id + '.png';
          var links = await API.graphql(
            graphqlOperation(getS3Url, {
              level: 'public',
              file: {
                fileName: str,
                fileAction: 'View',
                propertyDirectory: {
                  propertyId: log.propertyId,
                  fileDirectory: 'simulation/thumbnailImage',
                },
              },
            })
          );
          list.push(links.data.getS3Url[0].fileUrl);
        }
        return list;
      },

      //日時の表示フォーマットを調整
      async getDateTime(date) {
        let dateTime = await new Date(date);
        let str =
          dateTime.getFullYear() +
          '/' +
          ('0' + (dateTime.getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + dateTime.getDate()).slice(-2) +
          ' ' +
          ('0' + dateTime.getHours()).slice(-2) +
          ':' +
          ('0' + dateTime.getMinutes()).slice(-2) +
          '';
        return str;
      },
      simLink(log) {
        if (log.roomId == null) {
          this.$router.push(
            '/Property/' +
              log.propertyId +
              '/WallPaperSimulator/Edit/' +
              log.simInfoName +
              '?side=true'
          );
        } else if (log.roomId != null) {
          this.$router.push(
            '/Property/' +
              log.propertyId +
              '/WallPaperSimulator/Edit/' +
              log.simInfoName +
              '?side=true&room=' +
              log.roomId
          );
        }
      },
      //会社情報を取得する。
      getCompanyId: function() {
        //アカウントから会社ID取得
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },
      /**
       * listPhotoSimulatorsを取得する
       */
      getPhotoData: async function() {
        let query = {
          company: await this.getCompanyId(),
          limit: 60,
          nextToken: null,
        };
        var result = [];
        try {
          do {
            var getPhoto = await API.graphql(graphqlOperation(listPhotoSimulators, query));
            result = [...result, ...getPhoto.data.listPhotoSimulators.items];
            query.nextToken = getPhoto.data.listPhotoSimulators.nextToken;
          } while (query.nextToken != null);
          return result;
        } catch (e) {
          console.error(e);
          return [];
        }
      },
      /**日時の表示を設定する */
      getViewDate: function(getDate) {
        let date = new Date(getDate);

        return (
          date.getFullYear() +
          '/' +
          (date.getMonth() + 1) +
          '/' +
          date.getDate() +
          '' +
          ' ' +
          ('0' + date.getHours()).slice(-2) +
          ':' +
          ('0' + date.getMinutes()).slice(-2)
        );
      },
    },
    components: {
      Load,
      SlideImage,
    },
  };
</script>

<style scoped>
  .heading {
    margin-bottom: 0;
    padding: 10px 12px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 1px;
    color: white;
    background: var(--colorTheme);
    transition: 0.15s;
    /*background: url('../my-images/my-bg-slash.png');*/
  }
  .news {
    padding: 0px;
    overflow-y: auto;
    margin-bottom: 50px;
    list-style: none;
  }
  .news_item div {
    display: block;
    padding: 12px 15px;
    color: #000;
    font-size: 0; /* 追加 */
    transition: background-color 0.1s;
  }
  .news_item div:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .news_item:nth-of-type(even) {
    background: rgba(var(--colorThemeRGB), 0.2);
    transition: 0.15s;
  }
  .news_date {
    display: inline-block;
    width: 19%;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
  }
  .news_category {
    display: inline-block;
    width: 10%;
    font-size: 1rem;
    background-color: rgba(var(--colorThemeRGB));
    border-radius: 5px;
    color: #fff;
    text-align: center;
    letter-spacing: 1px;
    line-height: 3;
  }

  .news_category:hover {
    cursor: hand;
    cursor: pointer;
    background-color: rgba(var(--colorThemeRGB), 0.5);
    color: #fff;
    transition: 0.15s;
  }

  .news_text {
    display: inline-block;
    width: 50%;
    font-size: 1.2rem;
    padding-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table td img {
    width: auto;
    height: auto;
    max-width: 200px;
    max-height: 100px;
    border-radius: 10px;
  }

  .imagesClass {
    max-width: 200px;
  }

  .simbutton {
    white-space: nowrap;
    font-size: 0.8vw;
  }

  @media screen and (max-width: 1870px) {
    .simbutton {
      white-space: pre-line;
    }
  }

  @media screen and (min-width: 1870px) {
    .simbutton {
      white-space: nowrap;
    }
  }

</style>
