<template>
  <div>
    <allySelect :items="selectItems" v-model="selectNum" />
    <ReportPreview
      :fileTitle="commonInput.reportTitle"
      :customerId="commonInput.customerId"
      :customerName="commonInput.customerName"
      :saveS3="false"
      class="report-preview"
      v-if="showReport"
    >
      <template #default="{companyInfo}">
        <slide
          ><ReportCover
            :title="commonInput.reportTitle"
            :commonInput="commonInput"
            :companyInfo="companyInfo"
        /></slide>
        <slide>
          <ReportIntro :commonInput="commonInput" :companyInfo="companyInfo" />
        </slide>
        <slide>
          <LandUsage />
        </slide>
        <slide>
          <PropertyOverview1
            :commonInput="commonInput"
            :realEstateInput="propertyDataList[selectNum]"
          />
        </slide>
        <slide>
          <PropertyOverview2 :realEstateInput="propertyDataList[selectNum]" />
        </slide>
        <slide>
          <ExecutableUsage1 :realEstateInput="propertyDataList[selectNum]" />
        </slide>
        <slide>
          <ExecutableUsage2 :property="propertyDataList[selectNum]" />
        </slide>
        <slide>
          <ExecutableUsage3 :otherProperty="otherProperty[selectNum]" />
        </slide>
        <slide v-for="(s, i) in slideSet || []" :key="i" class="report-slide">
          <ChartLayout
            :chartData="s.chartData"
            :title="s.title"
            :subTitle="s.subTitle"
            :chartCaption="s.caption"
            :chartType="s.chartType"
            :commentList="s.comments"
            :chartOption="s.chartOptions"
          >
          </ChartLayout>
        </slide>
        <slide>
          <ReportEndMessage :commonInput="commonInput" :companyInfo="companyInfo" />
        </slide>
        <slide>
          <endSlide />
        </slide>
      </template>
    </ReportPreview>
  </div>
</template>
<script>
  import ReportPreview from '@/components/ExportReport/ReportPreview.vue';
  import ReportCover from '@/components/ExportReport/ReportCover';
  import ReportIntro from './Pages/Intro';
  import ReportEndMessage from '@/components/ExportReport/EndMessage';
  import ChartLayout from './Layout/ChartLayout';
  import chartOptions from './chartOptions';
  import RealEstateInput from './inputClass';
  import inputClass from './inputClass';
  import LandUsage from './Pages/LandUsage';
  import PropertyOverview1 from './Pages/PropertyOverview1';
  import PropertyOverview2 from './Pages/PropertyOverview2';
  import ExecutableUsage1 from './Pages/ExecutableUsage1';
  import ExecutableUsage2 from './Pages/ExecutableUsage2';
  import ExecutableUsage3 from './Pages/ExecutableUsage3';
  import endSlide from './Pages/endSlide';

  export default {
    props: {
      propertyList: Array,
      commonInput: Object,
    },
    components: {
      ReportPreview,
      ChartLayout,
      ReportCover,
      ReportIntro,
      LandUsage,
      PropertyOverview1,
      PropertyOverview2,
      ReportEndMessage,
      ExecutableUsage1,
      ExecutableUsage2,
      ExecutableUsage3,
      endSlide,
    },
    data() {
      return {
        chartOptions: chartOptions(true),
        propertyListData: [],
        input: new RealEstateInput(),
        selectNum: 0,
        showReport: false,
      };
    },
    computed: {
      chartDataList() {
        if (this.propertyDataList == null) {
          return;
        }
        return this.propertyDataList.map(val => {
          return JSON.parse(val.chartData);
        });
      },
      slideSet() {
        return this.slideData[this.selectNum];
      },
      selectItems() {
        return this.propertyList.map((_, i) => ({ text: `不動産${i + 1}`, value: i }));
      },
      slideData() {
        if (this.chartDataList == null) {
          return null;
        }

        let result = [];
        for (const chartData of this.chartDataList) {
          result = [
            ...result,
            [
              {
                title: '不動産適正化診断',
                subTitle: '月間収支（毎日の手取り収入）の比較',
                caption: '毎月の手取り収入',
                chartType: 'allyBarChartV',
                comments: [
                  '土地の固定資産税・都市計画税: 除外',
                  '○更地: 収入ゼロとし草刈り等の維持費用分マイナス',
                  '○貸地: 地代収入',
                  '○売却: 売却後の手取り額に対しての運⽤益とする',
                  '○不動産所得に対する所得税・住民税: 各人ごとの総所得や家族構成等<br/>　 により異なるため考慮しない',
                ],
                chartData: chartData?.[1],
                chartOptions: this.chartOptions?.[1],
              },
              {
                title: '不動産適正化診断',
                subTitle: '固定資産税の減額効果の検討',
                caption: '土地の固定資産税比較（年間）',
                chartType: 'allyBarChartV',
                comments: [
                  '○ここでいう固定資産税とは、毎年課税される「固定資産税＋都市計画税」<br/>　 を表す。',
                  '○固定資産税は市区町村が定める固定資産税評価順に一定の税率を<br/>　 かけて算出。',
                  '○[標準税率] 固定資産税： 1.4%（制限税率なし）<br/>　 都市計画税率： 0.2%（制限税率）',
                  '　 ※税率は市区町村によって異なる',
                  '○賃貸住宅の敷地は１戸につき２００㎡までの固定資産税が６分の１に、<br/>　 都市計画税が３分の１になる（小規模住宅用地）',
                  '　 ※200㎡を超える部分については<br/>　 固定資産税は3分の1<br/>　 都市計画税は3分の2に軽減される。<br/>　 尚、軽減される土地面積は建物面積の10倍までとされる。',
                ],
                chartData: chartData?.[2],
                chartOptions: this.chartOptions?.[2],
              },
              {
                title: '不動産適正化診断',
                subTitle: '長期事業収支比較（土地固定資産税も考慮）',
                caption: '現金収支累計',
                chartType: 'allyLineChart',
                comments: [
                  '○ここでの計算値は、土地の固定資産税まで考慮した「毎月の手取り収入」<br/>　 を累計していったもので、グラフが右肩上がりになるほど収益性が<br/>　 高いことを表す。',
                  '○更地の場合は、固定資産税が毎年課税されていき、収入はゼロでグラフ<br/>　 は右肩下がりになる。',
                  '○売却の場合は、譲渡所得税や仲介手数料等を支払った後の手取り現金額<br/>　 を運用した場合に得られるであろう運用益を累計していったもの。',
                  '○賃貸住宅の場合は、ローン返済が終了するとグラフの角度はより<br/>　 右肩上がりになる(青空駐車場も同様)。',
                ],
                chartData: chartData?.[3],
                chartOptions: this.chartOptions?.[3],
              },
              {
                title: '不動産適正化診断',
                subTitle: '経営安定度『間取りタイプ別の借入金額と損益分岐点空室率』の比較',
                caption: '賃貸経営安定度',
                chartType: 'allyBarChartV',
                comments: [
                  '○借入金を有効に使うことで、少ない手元資金で大きな利益を得ることが<br/>　 可能。',
                  '○借入金の残債は相続時に相続財産から控除できるため、相続税対策<br/>　 としても有効。',
                  '○損益分岐点空室率は、空室が何%でマイナスになってしまうかを表す<br/>　 （数字が大きい＝経営は安定）。',
                  '○相続税対策としての効果など、総合的に判断することが大切<br/>　 （借入金が少ない＝有利ということではなく）。',
                ],
                chartData: chartData?.[4],
                chartOptions: this.chartOptions?.[4],
              },
              {
                title: '不動産適正化診断',
                subTitle: '相続税評価額の減額効果比較（活用直後）',
                caption: '不動産の活用法別財産評価額（活用直後）',
                chartType: 'allyBarChartV',
                comments: [
                  '○不動産は活用法ごとに相続時の評価方法が異なりますが、ここでは<br/>　 活用直後の相続税評価額を比較したグラフです。<br/>　 （※評価額は少ないほど有利です）',
                  '　 ・更地、貸地の場合は土地の路線価が評価額となる。',
                  '　 ・駐車場の場合、「路線価 －（自己資金 ＋ 借入金額）」が評価額となる。',
                  '　 ・売却の場合、売却後の手取り金額が評価額となる。',
                  '　 ・賃貸住宅が一般的には減税効果が最も大きくなる。',
                  '　 ・更地に比べ土地の評価は10～20%下がるうえ、建物も実際の建築費<br/>　　の30～50%の評価にとどまる。更に借入金は額面100%を<br/>　　相続財産から控除できるため減税効果が大きい。',
                  '',
                  "<span style='font-weight: bold;'>[ 計算方法 ]</span>",
                  '　 相続税評価額 ＝（① ＋ ②）－（自己資金 ＋ 借入金）',
                  '　 ※土地 ＝ 路線価 ×（1 － 借地権割合 × 借家権割合）・・・①',
                  '　 ※建物 ＝ 固定資産税評価額 ×（1 － 借家権割合）・・・②',
                  '　　　借家権割合は全国一律30%、借地権割合は地域ごとに異なる。',
                  '　 ※借入金の借主が死亡すると残債の支払いが免除される団体信用生命<br/>　　　保険付きローンでは前述の効果はありません。<br/>　　　ここでは「団信なし」ローンを前提としています。',
                ],
                chartData: chartData?.[5],
                chartOptions: this.chartOptions?.[5],
              },
              {
                title: '不動産適正化診断',
                subTitle: '相続税評価額の減額効果比較（経年推移）',
                caption: '将来の相続財産額の推移',
                chartType: 'allyLineChart',
                comments: [
                  '○活用法ごとの相続税評価額が経過年数と共にどのように変化していくか<br/>　 を表したグラフです。',
                  '　 ・路線価等の物価は将来に渡り変動しないものとする。また活用で新たに<br/>　　 生まれる収入は贈与等により分散させるものとし計算から除外。',
                  '　　 更地・貸地は土地の路線価が評価額となる。',
                  '　　 月極駐車場の場合は「路線価 － （自己資金 ＋ 借入金残債）」が<br/>　　 評価額となる。',
                  '　 ・売却の場合は売却後手取金額が評価額となる。',
                  '　　 賃貸住宅の場合の計算式は次のとおり',
                  '　　 ※土地 ＝ 路線価 ×（1 － 借地権割合 × 借家権割合）・・・①',
                  '　　 ※建物 ＝ 固定資産税評価額 ×（1 － 借家権割合）・・・②',
                  '　　 相続税評価額 ＝（① － ②）－（自己資金 ＋ 借入金残債）',
                  '　　 建物の固定資産税評価額は経過年数とともに減少し、減少率は建物<br/>　　 の構造により異なる（※構造ごとの耐用年数参照）。',
                  '　 ・借入金残債の減少は評価額アップの１つの要因となり、例えば賃貸住宅<br/>　　 で借入金残債が減ってくるとトータルでの評価額は上昇していく<br/>　　 （借入期間・建物構造等により上昇率は異なる）。',
                  '　 ・借入金は「団信なし」ローンを前提で計算。',
                ],
                chartData: chartData?.[6],
                chartOptions: this.chartOptions?.[6],
              },
              {
                title: '総合判定',
                subTitle: '総合比較',
                caption: '5年後の相続税額と収支累計',
                chartType: 'allyBarChartH',
                comments: [
                  '○活用法ごとに、5年後に相続が発生した場合を比較したもの<br/>　 （土地活用で増やす事ができた「現金収支の累計」と「相続税」）。',
                  '○相続税額は平成27年1月1日以後の新税率にて計算。',
                  '○相続財産には現金収支累計も含めて計算。',
                  '○貸地・駐車場・賃貸住宅で現金収支累計がプラスの場合、手取り金額は<br/>　 80％で表示（税金・贈与等で20％は手元に残らないものと仮定）。',
                  '　 ※マイナスの場合は100％の額面で表示',
                  '○とくに相続発生までの期間が短い場合、相続税額に活用法によって<br/>　 どれくらい差がつくかが比較のポイントになる。',
                ],
                chartData: chartData?.[7],
                chartOptions: this.chartOptions?.[7],
              },
              {
                title: '総合判定',
                subTitle: '総合比較',
                caption: '10年後の相続税額と収支累計',
                chartType: 'allyBarChartH',
                comments: [
                  '○土地活用開始から相続発生までの年数が長くなるとその差も<br/>　 大きくなってくる。',
                  '○活用法ごとに、10年後に相続が発生した場合を比較したもの。',
                  '　 （土地活用で増やすことができた「現地収支の累計」と「相続税」）',
                ],
                chartData: chartData?.[8],
                chartOptions: this.chartOptions?.[8],
              },
              {
                title: '総合判定',
                subTitle: '総合比較',
                caption: '10年後に相続が発生した場合に残る資産',
                chartType: 'allyBarChartV',
                comments: [
                  '○10年後に相続が発生した場合に、相続税支払い後に残る資産を<br/>　 比較したもの。',
                  '○土地は路線価で計算。',
                  '　 借入金の残債は相続財産から控除できるため、相続時にあえて借入金を<br/>　 残すことで相続税の課税対象額を減らすことができ減税効果を<br/>　 得ることができる。',
                ],
                chartData: chartData?.[9],
                chartOptions: this.chartOptions?.[9],
              },
            ],
          ];
        }

        return result;
      },
      otherProperty() {
        if (this.propertyDataList == null) {
          return [];
        }
        const result = this.propertyDataList.map(val => {
          const defaultValue = this.$store.state.master.defaultValue;
          const overallPlan = val.overallPlan;

          const sale = { ...defaultValue.sale, ...overallPlan.sale };
          const leasedLand = {
            ...defaultValue.leasedLand,
            ...overallPlan.leasedLand,
          };
          const monthlyParkingLot = {
            ...defaultValue.monthlyParkingLot,
            ...overallPlan.monthlyParkingLot,
          };
          const vacantLotOrLand = {
            ...defaultValue.vacantLotOrLand,
            ...overallPlan.vacantLotOrLand,
          };
          const storeOffice = {
            ...defaultValue.storeOffice,
            ...overallPlan.storeOffice,
          };

          return { sale, leasedLand, monthlyParkingLot, vacantLotOrLand, storeOffice };
        });

        return result;
      },
    },
    methods: {
      async getPropertyDataList() {
        const promise = this.propertyList.map(async property => {
          const dbResult = await this.$db.propertyListInputs.get(property.id);
          const rt = new inputClass(dbResult.data);
          return rt;
        });

        const result = await Promise.all(promise);
        return result;
      },
    },
    async mounted() {
      await this.$nextTick();
      this.propertyDataList = await this.getPropertyDataList();
      this.showReport = true;
    },
    watch: {
      async selectNum() {
        this.showReport = false;
        await this.$nextTick();
        this.showReport = true;
      },
    },
  };
</script>
<style scoped>
  .loop {
    display: inline;
  }
  .download-all {
    text-align: right;
  }
</style>
