// @ts-check

export const CALL_COMMAND = {
  SEND_INTERIOR_MASTER: 'SEND_INTERIOR_MASTER', // 建具マスタ送信
  START_FURNITURE_SIMULATOR: 'START_FURNITURE_SIMULATOR', // 家具シミュレーター起動
  START_AUTO_LOGIN: 'START_AUTO_LOGIN', // 自動ログイン
  RESET_USER_ID: 'RESET_USER_ID', // IDリセット
  SEND_USER_ID_AND_PASSWORD: 'SEND_USER_ID_AND_PASSWORD', // IDとパスワード送信
};

/**
 * Unity処理呼び出し
 * @param {string} command // CALL_COMMAND指定
 * @param {object} body // Unityに渡すパラメータ
 * @returns {boolean}
 */
export const callUnity = (command, body = {}) => {
  const data = {
    command,
    body,
  };
  try {
    eval(`Unity.call('${JSON.stringify(data)}');`);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Unity起動確認
 */
export const checkUnity = () => {
  try {
    eval(`Unity.call('1');`);
    return true;
  } catch (error) {
    return false;
  }
};
