<!--
作成者：Nakatsuka Daisuke
作成日：2020.12.7
画面名：物件情報登録・修正・照会
機能：物件情報登録・修正・照会
親子関係：なし
更新者：Nakatsuka Daisuke
①
更新内容：
①
-->
<template>
  <div class="property-view">
    <div v-show="upLoading">
      <Load />
    </div>

    <ValidationObserver ref="simInfoData" v-if="!loading" v-show="!upLoading">
      <!-- 物件情報 -->
      <div class="grid-margin">
        <div class="ally-input-background card col-12">
          <div class="row header-add" v-if="mode == 'New'">
            <div class="col-12">
              新たにシミュレーターを開始する
            </div>
          </div>
          <div class="card-body">
            <div v-if="upLoading">
              <Load />
            </div>
            <div v-if="mode != 'New'">
              <div class="row">
                <div class="col-md-4" v-show="!upLoading">
                  <allyImages
                    class="col-12"
                    v-model="simInfoData.baseImageFileName"
                    :loading="loading"
                    :mode="'Add'"
                    :imageUrl="imageUrl"
                    title=""
                    ref="room"
                    max="1"
                    butonLabel="画像ファイルの選択"
                    :propertyId="propertyId"
                    propertyDirectory="simulation/baseImage"
                    rules="required"
                  />
                </div>
                <div>
                  <allyText
                    title="画像名"
                    :nonAppear="true"
                    name="baseImageDisplayName"
                    v-model="simInfoData.baseImageDisplayName"
                    :mode="mode"
                    :loading="loading"
                    :placeholder="'寝室'"
                    rules="required|max:15"
                  />
                </div>
                <div v-if="mode != 'New'" v-show="!upLoading">
                  <div style="margin-left:30px;">
                    <button
                      class="btn btn-ally float-right"
                      v-on:click="create"
                      v-if="mode == 'List'"
                      type="submit"
                      :disabled="imageMaxFlag"
                    >
                      一覧へ登録
                    </button>
                  </div>
                  <div class="col-12">
                    <span class="float-right text-red" v-if="mode == 'Add' && imageMaxFlag">
                      登録数が上限に達しました。
                    </span>
                  </div>
                </div>
              </div>
              <div class="ally-input-background card col-12">
                <div class="card-body">
                  <div>対応形式 ：PNG JPEG</div>
                  <div>推奨画像比：1280×1024以上</div>
                  <div>上限サイズ：5MB未満</div>
                </div>
              </div>
            </div>
            <div v-if="mode == 'New'">
              <div class="row">
                <div class="col-5" v-show="!upLoading">
                  <div>
                    <PropertySetting id="PropertySetting" ref="PropertySetting" />
                  </div>
                </div>
                <div class="col-md-1">
                  <div
                    class="col-6"
                    style="border-right: 1px solid rgb(154, 154, 154); height: 100%;"
                  ></div>
                </div>
                <div class="col-5">
                  <div>
                    <allyText
                      title="画像名"
                      name="baseImageDisplayName"
                      v-model="simInfoData.baseImageDisplayName"
                      :mode="mode"
                      :loading="loading"
                      :placeholder="'寝室'"
                      rules="required|max:15"
                    />
                  </div>
                  <div>
                    <allyImages
                      v-model="simInfoData.baseImageFileName"
                      name="allyPhoto"
                      :loading="loading"
                      :mode="'Add'"
                      :imageUrl="imageUrl"
                      title="画像ファイル"
                      ref="room"
                      max="1"
                      butonLabel="画像ファイルの選択"
                      :propertyId="propertyId"
                      propertyDirectory="simulation/baseImage"
                      rules="required"
                    />
                  </div>
                  <div class="ally-input-background card col-12 card-border">
                    <div class="card-body">
                      <div>対応形式 ：PNG JPEG</div>
                      <div>推奨画像比：1280×1024以上</div>
                      <div>上限サイズ：5MB未満</div>
                    </div>
                  </div>
                  <div class="mt-3" v-if="mode == 'New'" v-show="!upLoading">
                    <button id="save" class="btn btn-ally col-12" v-on:click="newButton">
                      開始
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <div id="fadeLayer" ref="fadeLayer"></div>
  </div>
</template>
<script>
  import awsconfig from '../../../aws-exports';
  import { API, graphqlOperation } from 'aws-amplify';

  //graphQL
  import { createPhotoSimulator, updateProperty } from '../../../graphql/mutations';
  import { getS3Url, getProperty } from '../../../graphql/queries';

  import Load from '../../../components/Common/Load';
  import PropertySetting from '../../../components/Property/PropatySetting';

  API.configure(awsconfig);

  export default {
    props: ['propertyInfo', 'propSimInfoData', 'loading', 'mode'],
    data: function() {
      return {
        propertyId: null,
        roomId: null,
        imageName: null,
        images: [],
        imageUrl: null,
        maxImage: 20, //保存MAX件数
        imageMaxFlag: false,
        simInfoData: {
          baseImageFileName: null,
          baseImageDisplayName: null,
          layersInfo: [],
        },
        upLoading: false,
      };
    },
    watch: {},
    created: function() {
      if (this.propSimInfoData) {
        this.simInfoData = this.propSimInfoData;
      }
    },
    mounted: async function() {
      this.propertyId = this.$route.params.id;
      this.imageName = this.$route.params.image;
      this.roomId = this.$route.params.roomId;

      if (this.mode != 'New') {
        this.getImages();
        //最大件数設定
        this.imageMaxFlag = false;
        if (this.propertyInfo.simInfo != null) {
          if (this.propertyInfo.simInfo.length >= this.maxImage) {
            this.imageMaxFlag = true;
          }
        } else {
          this.propertyInfo.simInfo = [];
        }
      }
      this.loading = false;
    },
    methods: {
      //データ登録
      create: async function() {
        this.$refs.simInfoData.validate().then(async propertyValid => {
          if (propertyValid) {
            if (this.simInfoData.baseImageFileName[0] != null) {
              //uploadフラグ
              this.upLoading = true;
              //画像アップロード
              await this.fileUpload();
              let companyId = await this.getCompanyId();
              let createData = {
                company: companyId,
                propertyId: this.propertyId,
                roomId: this.roomId,
                simInfo: null,
              };
              //入力内容を追加
              let simInfoData = {
                baseImageFileName: this.simInfoData.baseImageFileName[0],
                baseImageDisplayName: this.simInfoData.baseImageDisplayName,
                simCreateDate: new Date(),
                simUpdateDate: new Date(),
              };
              createData.simInfo = simInfoData;

              //ログをセットする
              try {
                let result = await API.graphql({
                  query: getProperty,
                  variables: {
                    id: this.propertyId,
                  },
                });

                let update = {
                  company: companyId,
                  id: this.propertyId,
                };

                //ログ情報を作成
                let simulationLog = {
                  updateDate: new Date(),
                  simId: createData.simInfo.simId,
                  fileUrlName: createData.simInfo.baseImageFileName,
                  fileName: createData.simInfo.baseImageDisplayName,
                  action: 'add',
                  userName:
                    this.$store.state.user.attributes.family_name +
                    ' ' +
                    this.$store.state.user.attributes.given_name,
                  userMail: this.$store.state.user.attributes.email,
                };

                //部屋ではない時通常通りログをセットする
                if (this.roomId == null) {
                  update.simulationLog = result.data.getProperty.simulationLog;
                  if (update.simulationLog == null) update.simulationLog = [];

                  update.simulationLog.push(simulationLog);
                } else {
                  //部屋別の履歴を設定する
                  update.roomInfo = result.data.getProperty.roomInfo;
                  let roomData = update.roomInfo.find(val => val.id == this.roomId);
                  if (roomData.simulationLog == null) roomData.simulationLog = [];
                  roomData.simulationLog.push(simulationLog);
                }

                await API.graphql({
                  query: updateProperty,
                  variables: { input: update },
                });
              } catch (e) {
                console.error(e);
              }

              //DBに追加
              try {
                await API.graphql({
                  query: createPhotoSimulator,
                  variables: { input: createData },
                });

                await this.$emit('upLoadCheck', this.upLoading);

                this.simInfoData = {
                  baseImageFileName: null,
                  baseImageDisplayName: null,
                  layersInfo: [],
                };
                this.$refs.room.clearFiles();
                this.$refs.simInfoData.reset();
                this.upLoading = false;
              } catch (e) {
                console.error(e);
                //エラー時モーダルを表示する。
                this.$bvModal.show('E0001');
              }
            } else if (this.simInfoData.baseImageFileName[0] == null) {
              this.$refs.fadeLayer.style.visibility = 'hidden';
            }
          }
        });
      },
      update: async function() {},

      //モーダルから閉じたときページ遷移
      pageLink: async function() {
        //保存フラグをtrueに設定（ページ移動の警告メッセージを防ぐ為）
        this.$store.commit('setSaveFlg', true);
        setTimeout('location.reload()', 2000);
        //this.$router.push("/Property/"+this.propertyId+"/WallPaperSimulator/List");
      },
      //S3へ部屋画像をupload
      fileUpload: async function() {
        await this.$refs.room.fileUpload();
      },
      //ログイン情報から会社情報を取得する。
      getCompanyId: function() {
        //アカウントから会社ID取得
        var groups = this.$store.state.user.signInUserSession.accessToken.payload['cognito:groups'];
        var groupIndex = groups.findIndex(group => {
          return group.startsWith('Company-');
        }, 'Company-');
        return groups[groupIndex];
      },

      //componentから部屋画像を取得

      //S3から画像情報を取得する。
      getImages: async function() {
        try {
          this.imageDownloading = true;

          if (this.images == null) this.images = [];
          if (!this.simInfoData.layersInfo) return;
          if (this.mode == 'New') return;
          //ファイル名だけの時
          // S3からイメージURLを受けてくる
          var links = await API.graphql(
            graphqlOperation(getS3Url, {
              level: 'public',
              file: this.simInfoData.layersInfo.map(x => {
                return {
                  fileName: x.layerDisplayName,
                  fileAction: 'View',
                };
              }),
            })
          );

          // Download
          for (var i = 0; i < links.data.getS3Url.length; i++) {
            var link = links.data.getS3Url[i];
            this.simInfoData.layersInfo[i].url = link.fileUrl;
          }
        } finally {
          this.imageDownloading = false;
        }
      },
      setPropertyId: async function(propertyId) {
        this.propertyId = propertyId;
      },
      newButton: function(e) {
        this.$refs.simInfoData.validate().then(async propertyValid => {
          if (propertyValid) {
            this.upLoading = true;
            this.$emit('newButton', e);
          }
        });
      },
    },
    components: {
      Load,
      PropertySetting,
    },
  };
</script>
<style scoped>
  #fadeLayer {
    position: absolute;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;

    background-color: #000000;
    opacity: 0.5;
    visibility: hidden;
    z-index: 1;
  }
  .header-add {
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    background-color: var(--colorTheme);
    font-weight: bold;
  }

  .ally-input-background .card {
    border-top-color: gainsboro !important;
  }
</style>
