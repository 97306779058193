<template>
  <div style="height: 80%">
    <div class="not-selected-container">
      <div style="text-align: center">
        <span class="not-selected-text">ドラッグで移動</span>
      </div>
    </div>
  </div>
</template>
<script>
  //@ts-check
  import { defineComponent } from '@vue/composition-api';

  export default defineComponent({});
</script>
<style scoped>
  .not-selected-text {
    font-size: 15px;
    font-weight: bold;
    color: #666;
  }
  .not-selected-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
</style>
