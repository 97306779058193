<!--
作成者：本間
作成日：2021.02.24
画面名：投資分析/出力
機能：収支計画書
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <b-tab id="InvestmentAnalyticsResults" title="投資分析">
    <b-card class="card-result" title="投資分析(満室想定)">
      <b-card style="width:49%;margin-left:0.5em;">
        <b-container class="report">
          <b-row />
          <b-row>
            <b-col class="title text-left">収益価格(DCF法)</b-col>
            <b-col class="value">{{ profitPriceDcf | currency }}</b-col>
          </b-row>
          <b-row>
            <b-col class="title text-left">正味現在価値(NPV)</b-col>
            <b-col class="value">{{ netPresentValue | currency }}</b-col>
          </b-row>
          <b-row>
            <b-col class="title text-left">内部収益率(IRR)</b-col>
            <b-col class="value">{{
              isNaN(internalRateOfReturn) ? '0' : internalRateOfReturn | ratio
            }}</b-col>
          </b-row>
          <b-row>
            <b-col class="title text-left">最終自己資金配当率</b-col>
            <b-col class="value">{{
              isNaN(finalCashOnCashReturn) ? '0' : finalCashOnCashReturn | ratio
            }}</b-col>
          </b-row>
        </b-container>
      </b-card>
      <b-card-group>
        <b-card v-for="id in [0, 1]" v-bind:key="`chart-${id}`" style="margin:0.5em;">
          <template #header> {{ chartData[id].datasets[0].label }} </template>
          <allyLineChart :data="chartData[id]" :options="chartOptions[id]" :styles="chartStyles" />
        </b-card>
      </b-card-group>
      <b-card-group>
        <b-card v-for="id in [2, 3]" v-bind:key="`chart-${id}`" style="margin:0.5em;">
          <template #header> {{ chartData[id].datasets[0].label }} </template>
          <allyLineChart :data="chartData[id]" :options="chartOptions[id]" :styles="chartStyles" />
        </b-card>
      </b-card-group>
    </b-card>
  </b-tab>
</template>
<script>
  import Calculation1 from '../Input/Calculation';
  import Calculation2 from '../Result/Calculation';
  import DichotomyIRR from './DichotomyIRR';
  // ツールチップ設定
  const tooltips = {
    // 年(タイトル)
    year: function(tooltipItem) {
      return tooltipItem[0].xLabel + '年目';
    },
    // 比率
    ratio: function(tooltipItem, data) {
      if (isNaN(tooltipItem.value)) {
        return null;
      }
      var label = data.datasets[tooltipItem.datasetIndex].label || '';
      if (label) {
        label += ': ';
      }
      label +=
        Number(Number(tooltipItem.value).toFixed(2)).toLocaleString('ja-JP', {
          style: 'decimal',
        }) + '％';
      return label;
    },
  };
  export default {
    props: ['model', 'resultMode'],
    mixins: [Calculation1, Calculation2, DichotomyIRR],
    data: function() {
      return {
        graphBorderWidth: this.resultMode ? 2 : 4,
        graphpointRadius: this.resultMode ? 2 : 4,
        // グラフスタイル
        chartStyles: {
          height: '30vh',
          position: 'relative',
        },
        // グラフオプション
        chartOptions: [
          {
            id: 0,
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltips: {
              mode: 'index',
              titleFontSize: 16,
              bodyFontSize: 16,
              bodySpacing: 4,
              callbacks: {
                title: tooltips.year,
                label: tooltips.ratio,
              },
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: '年',
                    padding: 0,
                    fontSize: 16,
                    fontStyle: 'bold',
                  },
                  ticks: {
                    fontSize: 14,
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: '％',
                    padding: 0,
                    fontSize: 16,
                    fontStyle: 'bold',
                  },
                  ticks: {
                    beginAtZero: true,
                    callback: function(value) {
                      return Number(value).toLocaleString();
                    },
                    fontSize: 14,
                  },
                },
              ],
            },
            animation: {
              duration: 0,
            },
            hover: {
              animationDuration: 0,
            },
            responsiveAnimationDuration: 0,
          },
          {
            id: 0,
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltips: {
              mode: 'index',
              titleFontSize: 16,
              bodyFontSize: 16,
              bodySpacing: 4,
              callbacks: {
                title: tooltips.year,
                label: tooltips.ratio,
              },
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: '年',
                    padding: 0,
                    fontSize: 16,
                    fontStyle: 'bold',
                  },
                  ticks: {
                    fontSize: 14,
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: '％',
                    padding: 0,
                    fontSize: 16,
                    fontStyle: 'bold',
                  },
                  ticks: {
                    beginAtZero: true,
                    callback: function(value) {
                      return Number(value).toLocaleString();
                    },
                    fontSize: 14,
                  },
                },
              ],
            },
            animation: {
              duration: 0,
            },
            hover: {
              animationDuration: 0,
            },
            responsiveAnimationDuration: 0,
          },
          {
            id: 0,
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltips: {
              mode: 'index',
              titleFontSize: 16,
              bodyFontSize: 16,
              bodySpacing: 4,
              callbacks: {
                title: tooltips.year,
                label: tooltips.ratio,
              },
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: '年',
                    padding: 0,
                    fontSize: 16,
                    fontStyle: 'bold',
                  },
                  ticks: {
                    fontSize: 14,
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: '％',
                    padding: 0,
                    fontSize: 16,
                    fontStyle: 'bold',
                  },
                  ticks: {
                    beginAtZero: true,
                    callback: function(value) {
                      return Number(value).toLocaleString();
                    },
                    fontSize: 14,
                  },
                },
              ],
            },
            animation: {
              duration: 0,
            },
            hover: {
              animationDuration: 0,
            },
            responsiveAnimationDuration: 0,
          },
          {
            id: 0,
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltips: {
              mode: 'index',
              titleFontSize: 16,
              bodyFontSize: 16,
              bodySpacing: 4,
              callbacks: {
                title: tooltips.year,
                label: tooltips.ratio,
              },
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: '年',
                    padding: 0,
                    fontSize: 16,
                    fontStyle: 'bold',
                  },
                  ticks: {
                    fontSize: 14,
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: '倍',
                    padding: 0,
                    fontSize: 16,
                    fontStyle: 'bold',
                  },
                  ticks: {
                    beginAtZero: true,
                    callback: function(value) {
                      return Number(value).toLocaleString();
                    },
                    fontSize: 14,
                  },
                },
              ],
            },
            animation: {
              duration: 0,
            },
            hover: {
              animationDuration: 0,
            },
            responsiveAnimationDuration: 0,
          },
        ],
        // グラフデータ
        chartData: [
          {
            labels: [],
            datasets: [{ label: '' }],
          },
          {
            labels: [],
            datasets: [{ label: '' }],
          },
          {
            labels: [],
            datasets: [{ label: '' }],
          },
          {
            labels: [],
            datasets: [{ label: '' }],
          },
        ],
      };
    },
    mounted: function() {
      this.chartData = [
        {
          labels: [],
          datasets: [
            {
              label: '純利回り(FCR)',
              data: [],
              borderColor: 'rgb(192,56,43)',
              pointBackgroundColor: 'rgb(192,56,43)',
              borderWidth: this.graphBorderWidth,
              fill: false,
              lineTension: 0,
              pointRadius: this.graphpointRadius,
            },
          ],
        },
        {
          labels: [],
          datasets: [
            {
              label: '自己資金配当率(CCR)',
              data: [],
              borderColor: 'rgb(41,128,185)',
              pointBackgroundColor: 'rgb(41,128,185)',
              borderWidth: this.graphBorderWidth,
              fill: false,
              lineTension: 0,
              pointRadius: this.graphpointRadius,
            },
          ],
        },
        {
          labels: [],
          datasets: [
            {
              label: '損益分岐入居率(BER)',
              data: [],
              borderColor: 'rgb(242,197,17)',
              pointBackgroundColor: 'rgb(242,197,17)',
              borderWidth: this.graphBorderWidth,
              fill: false,
              lineTension: 0,
              pointRadius: this.graphpointRadius,
            },
          ],
        },
        {
          labels: [],
          datasets: [
            {
              label: '債務回収率(DSCR)',
              data: [],
              borderColor: 'rgb(28,160,133)',
              pointBackgroundColor: 'rgb(28,160,133)',
              borderWidth: this.graphBorderWidth,
              fill: false,
              lineTension: 0,
              pointRadius: this.graphpointRadius,
            },
          ],
        },
      ];
      // 売却年
      const timeOfSale = Math.floor(Number(this.investmentAnalytics.salePlan.timeOfSale));
      // 返済期間
      const repaymentPeriod = Math.floor(
        Number(this.investmentAnalytics.initialInvestment.capitalPlan.repaymentPeriod)
      );
      // グラフデータ設定
      {
        const years = [
          // 売却年まで
          [...Array(timeOfSale)].map((_, i) => i + 1),
          // 返済期間(または売却年の小さい方)
          [...Array(Math.min(repaymentPeriod, timeOfSale))].map((_, i) => i + 1),
        ];
        // 純利回り(FCR)
        this.chartData[0].labels = years[0];
        this.chartData[0].datasets[0].data = this.investmentAnalyticsTemp.indicators.fcr;
        // 自己資金配当率(CCR)
        this.chartData[1].labels = years[0];
        this.chartData[1].datasets[0].data = this.investmentAnalyticsTemp.indicators.ccr;
        // 損益分岐入居率(BER)
        this.chartData[2].labels = years[0];
        this.chartData[2].datasets[0].data = this.investmentAnalyticsTemp.indicators.ber;
        // 債務回収率(DSCR)
        this.chartData[3].labels = years[1];
        this.chartData[3].datasets[0].data = this.investmentAnalyticsTemp.indicators.dscr;
      }
    },
    watch: {
      // 純利回り(FCR)
      'investmentAnalyticsTemp.indicators.fcr': function() {
        this.chartData[0].datasets[0].data = this.investmentAnalyticsTemp.indicators.fcr;
      },
      // 自己資金配当率(CCR)
      'investmentAnalyticsTemp.indicators.ccr': function() {
        this.chartData[1].datasets[0].data = this.investmentAnalyticsTemp.indicators.ccr;
      },
      // 損益分岐入居率(BER)
      'investmentAnalyticsTemp.indicators.ber': function() {
        this.chartData[2].datasets[0].data = this.investmentAnalyticsTemp.indicators.ber;
      },
      // 債務回収率(DSCR)
      'investmentAnalyticsTemp.indicators.dscr': function() {
        this.chartData[3].datasets[0].data = this.investmentAnalyticsTemp.indicators.dscr;
      },
    },
    computed: {
      // 収益価格(DCF法)
      profitPriceDcf: function() {
        let pvTotal = 0;
        const noi = this.investmentAnalyticsTemp.cashNoi; // 各年の純収益(NOI)
        if (noi === undefined || noi == null) {
          return null;
        }
        const ratio = this.getRateOfIncrease('割引率');
        if (ratio === undefined) {
          return null;
        }
        // 現在価値の合計：各年の純収益(NOI) ÷ POWER(1+割引率,年度) の累計
        noi.forEach((n, i) => {
          pvTotal += n / Math.pow(1 + ratio / 100, i + 1);
        });

        return pvTotal;
      },
      // 正味現在価値(NPV)
      netPresentValue: function() {
        // 収益価格(DCF法) － 投資計画/総事業費
        return this.profitPriceDcf - this.totalProjectCost;
      },
      // 内部収益率(IRR)
      internalRateOfReturn: function() {
        let values = [];
        const cat = this.investmentAnalyticsTemp.cashFlowsAfterTax; // 税引後現金収支
        if (cat === undefined || cat == null) {
          return null;
        }

        // 初期投資(C0) ※０年目分扱い
        values.push(-this.totalProjectCost);

        // 税引後現金収支
        cat.forEach(v => {
          values.push(v);
        });
        // IRR(税引後現金収支、...)
        return this.calcIRR(values);
      },
      // 最終自己資金配当率
      finalCashOnCashReturn: function() {
        // 売却年の税引後現金収支累計
        let yearlyBalanceTotal = 0;
        const cat = this.investmentAnalyticsTemp.cashFlowsAfterTax; // 税引後現金収支
        if (cat === undefined || cat == null) {
          return null;
        }
        // 売却年の税引後現金収支累計額＝単年度現金収支を売却年まで加算
        cat.forEach(v => {
          yearlyBalanceTotal += v;
        });
        // 自己資金
        let ownResources = Number(
          this.investmentAnalytics.initialInvestment.capitalPlan.ownResources
        );
        // 最終自己資金配当率＝売却年の税引後現金収支累計額÷自己資金×100
        return (
          ( yearlyBalanceTotal / ownResources ) * 100
        );
      },
    },
  };
</script>
<style scoped>
  #InvestmentAnalyticsResults .card-result .card-header,
  #InvestmentAnalyticsResults .report .title {
    background: var(--colorTheme);
    font-size: larger;
    color: #fff;
    text-shadow: 1px 1px 1px #000000, -1px 1px 1px #000000, 1px -1px 1px #000000,
      -1px -1px 1px #000000, 1px 0px 1px #000000, 0px 1px 1px #000000, -1px 0px 1px #000000,
      0px -1px 1px #000000;
  }
  #InvestmentAnalyticsResults .card-result .card {
    border: 1px solid black;
    border-top-color: black;
    box-shadow: 4px 8px 9px -2px #c7c5d8;
  }
  .value {
    font-size: larger;
  }
</style>
