<!--
作成者：Nagata Yoshitaka
作成日：2021.01.07
画面名：画像情報編集 レイヤーコンポーネント
機能：
親子関係：
更新者：
①
更新内容：
①
-->
<template>
  <div :class="{ layerWrapper: true, active: layer.isActive }" v-show="loading == false">
    <canvas
      class="dummyBaseImage"
      ref="dummyBaseImage"
      :layerName="layer.name"
      @mousedown.prevent="imageMouseDown($event, false)"
      @mouseup="imageMouseUp($event)"
      @mousemove="imageMouseMove($event)"
      @touchstart="imageMouseDown($event, false)"
      @touchend="imageMouseUp($event)"
      @touchmove="imageMouseMove($event)"
      @mousedown.right="pointFix()"
    ></canvas>
    <!--  @mouseout="imageMouseUp($event)" -->
    <canvas
      class="free-draw-canvas"
      ref="freeDrawCanvas"
      :layerName="layer.name"
      :class="{ active: editProperty.isEdit }"
    ></canvas>
    <canvas class="layer" ref="main" :layerName="layer.name"></canvas>
    <div v-if="editProperty.editMode === 'curve'">
      <div
        v-for="point of curvePoint"
        :key="point.index"
        class="corner"
        @mousedown.left="pointMouseDown(point)"
        @touchstart="pointMouseDown(point)"
        @mouseup.left="pointMouseUp(point)"
        @touchend="pointMouseUp(point)"
        @mousedown.right="pointFix()"
        @touchmove="pointMoveEvent($event)"
        :style="{ top: point.y - 3 + 'px', left: point.x - 3 + 'px' }"
      ></div>
    </div>
  </div>
</template>
<script>
  import MagicWand from 'magic-wand-tool';

  //マスクの色
  const COLOR_BYTE = 4;
  const DOT_OFFSET = 5;

  export default {
    /*
    layer: 個別のレイヤー情報
    baseImageInfo: シミュレート画像が適用されているベースCanvas情報
    editProperty: 編集情報が格納されている。（選択中の操作など）
    layerReplacement:
    allEnabledLayer:他のレイヤー情報を表示用canvasに適用する。
    */
    props: ['layer', 'baseImageInfo', 'editProperty', 'layerReplacement', 'allEnabledLayer'],
    data: function() {
      return {
        //mask: magicwand用マスクデータ, oldMask:magicwand用直前マスクデータ, undoData:直前のcontextデータ
        mask: null,
        oldMask: null,
        undoData: [],
        undoDummyData: [],
        undoCancelData: [],
        undoCancelDummyData: [],
        imageInfo: null,
        freeDrawCanvas: null,
        freeDrawContext: null,
        rectPoint: [],
        lineStartMove: {
          offsetX: 0,
          offsetY: 0,
        },
        materialImages: null,
        setColor: this.layer.layerColor,
        curvePoint: [],
        downSw: false,
        dragPoint: null,
        eventRect: null,
        scrollY: 0,
        loading: false,
        shiftKey: false,
      };
    },
    watch: {
      'editProperty.editMode': function() {
        this.imageMouseMove(this.touchMovePoint);
      },
      'layer.brightness': function() {
        this.layerFunctionImage();
      },
      'layer.saturation': function() {
        this.layerFunctionImage();
      },
      'layer.transparent': function() {
        this.allEnabledLayer();
      },
      'layer.layerColor': function() {
        this.allEnabledLayer();
      },
      'layer.enabled': function() {
        this.allEnabledLayer();
      },
    },
    mounted: async function() {
      this.loading = true;
      //編集用ダミーイメージ作成
      await this.initDummyBaseImage();
      //サイズを部屋画像にあわせる
      this.$refs.main.width = this.baseImageInfo.canvas.width;
      this.$refs.main.height = this.baseImageInfo.canvas.height;
      //編集時、既存のレイヤー画像があるばあいは最初に描画
      if (this.layer.fileUrl) {
        var ctx = this.$refs.main.getContext('2d');

        this.setColor = this.layer.layerColor.replace('#', '');
        let getImage = await new Promise(resolve => {
          var image = new Image();
          image.crossOrigin = 'anonymous';
          image.src = this.layer.fileUrl;
          image.onload = () => {
            resolve(image);
          };
        });
        await ctx.drawImage(getImage, 0, 0, this.$refs.main.width, this.$refs.main.height);
      }
      await this.allEnabledLayer();
      this.loading = false;

      //マウント処理が完了をmainに通知
      this.$emit('completeComponent', 'layer');
    },
    methods: {
      modeChange: function() {
        if (this.freeDrawContext != null) {
          this.freeDrawEnd();
        }
        this.freeDrawCanvas = null;
        this.freeDrawContext = null;
        this.rectPoint = [];
        this.curvePoint = [];
        this.dragPoint = null;
        this.downSw = false;
        this.editProperty.isEdit = false;
      },
      changeLayer: function() {
        /* if(this.layer.color != null){
          this.setColor = this.layer.color.replace('#','');
        } */
      },
      initDummyBaseImage: async function() {
        this.$refs.dummyBaseImage.width = this.baseImageInfo.canvas.width;
        this.$refs.dummyBaseImage.height = this.baseImageInfo.canvas.height;
        let ctx = await this.$refs.dummyBaseImage.getContext('2d');
        let image = await this.baseImageInfo.context.getImageData(
          0,
          0,
          this.baseImageInfo.canvas.width,
          this.baseImageInfo.canvas.height
        );
        await ctx.putImageData(image, 0, 0);
        await this.setBaseImageInfo();
      },
      mobileTouch: function(e) {
        let pos = {
          x: 0,
          y: 0,
        };
        if (e.changedTouches != null) {
          const rect = e.target.getBoundingClientRect();
          pos.x = Math.floor(e.touches[0].clientX - rect.left);
          pos.y = Math.floor(e.touches[0].clientY - rect.top);
        }
        return pos;
      },
      imageMouseDown: function(e, addMode) {
        if (this.editProperty.editMode === 'move') return;
        this.setColor = this.layer.layerColor.replace('#', '');
        this.touchMovePoint = e;
        //canvas.dummyBaseImage上での右クリック時のメニュー表示を禁止する
        this.$refs.dummyBaseImage.oncontextmenu = function() {
          return false;
        };

        //スクロール禁止
        document.addEventListener('touchmove', e.preventDefault(), { passive: false });

        //クリック場所の部屋のカラーを取得
        let pos = {
          x: e.offsetX,
          y: e.offsetY,
        };
        if (e.changedTouches != null) {
          pos = this.mobileTouch(e);
        }
        //編集モードで操作変更
        if (this.editProperty.editMode === 'floodFill') {
          this.createMaskData(pos.x, pos.y, addMode);
          this.drawMask();
        } else if (this.editProperty.editMode === 'freeDraw') {
          //手動塗りモード
          this.editProperty.isEdit = true;
          this.undoFunction();
          this.freeDrawStart(e);
        } else if (this.editProperty.editMode === 'line') {
          //直線引き機能
          this.editProperty.isEdit = true;
          this.undoFunction(); //undo設定
          this.lineDrawStart(e);
          if (e.changedTouches == null) this.lineStartMove = e;
          if (e.changedTouches != null) {
            let repos = this.mobileTouch(e);
            this.lineStartMove = {
              offsetX: repos.x,
              offsetY: repos.y,
            };
          }
          0;
        } else if (
          this.editProperty.editMode === 'rect' ||
          this.editProperty.editMode === 'eraserRect'
        ) {
          if (e.button == 0 || e.changedTouches != null) {
            //矩形モード
            this.editProperty.isEdit = true;
            this.rectPoint.push(pos);
            //左クリックイベント
            //クリック毎に点線を仮描画
            if (this.rectPoint.length === 1) {
              this.rectStart(pos);
            } else {
              //最初の点に戻ってきたら内部を塗りつぶし
              //2座標間の距離 = √((x2 - x1)2 + (y2- y1)2)
              let distance = Math.sqrt(
                Math.pow(this.rectPoint[0].x - pos.x, 2) + Math.pow(this.rectPoint[0].y - pos.y, 2)
              );
              //3px以内なら同一点と見做す
              if (distance <= DOT_OFFSET) {
                this.rectEnd(pos);
                this.rectPoint = [];
                this.undoFunction(); //undo設定
                if (this.editProperty.editMode === 'eraserRect') {
                  this.$refs.main.getContext('2d').globalCompositeOperation = 'destination-out';
                }
                this.$refs.main
                  .getContext('2d')
                  .drawImage(
                    this.freeDrawCanvas,
                    0,
                    0,
                    this.$refs.main.width,
                    this.$refs.main.height,
                  );
                this.$refs.main.getContext('2d').globalCompositeOperation = 'source-over';
                this.editProperty.isEdit = false;
              }
            }
            //右クリックイベント
          } else if (e.button == 2) {
            if (this.rectPoint.length > 0) {
              //右クリックごとに選択をひとつ前に戻す。
              if (this.rectPoint.length >= 1) {
                this.rectPoint.splice(this.rectPoint.length - 1, 1);
              }

              //目安の点線を再表示
              if (this.rectPoint.length == 0) {
                this.freeDrawContext.clearRect(
                  0,
                  0,
                  this.freeDrawCanvas.width,
                  this.freeDrawCanvas.height
                );
                return;
              }
              //目安の点線を表示
              let repos = {
                x: e.offsetX,
                y: e.offsetY,
              };
              if (e.changedTouches != null) {
                repos = this.mobileTouch(e);
              }

              this.reDrawRect();
              this.rectLine(repos);
            }
          }
        } else if (this.editProperty.editMode === 'eraser') {
          //消しゴムモード
          this.editProperty.isEdit = true;
          this.undoFunction(); //undo設定
          this.eraserStart(e);
        } else if (this.editProperty.editMode === 'eraserLine') {
          //直線引き機能
          this.editProperty.isEdit = true;
          this.undoFunction(); //undo設定
          if (e.changedTouches == null) this.lineStartMove = e;
          if (e.changedTouches != null) {
            let repos = this.mobileTouch(e);

            this.lineStartMove = {
              offsetX: repos.x,
              offsetY: repos.y,
            };
          }
          this.lineDrawStart(this.lineStartMove);
        } else if (
          this.editProperty.editMode === 'shapeRect' ||
          this.editProperty.editMode === 'shapeEllipse'
        ) {
          //図形塗りつぶし(四角形)モード
          this.editProperty.isEdit = true;
          this.rectPoint.push(pos);
          if (this.rectPoint.length === 1) {
            this.drawRectStart(pos);
          }
          document.addEventListener('keydown', event => {
            if (event.key === 'Shift' && this.shiftKey === false) {
              this.shiftKey = true;
            }
          });
          document.addEventListener('keyup', event => {
            if (event.key === 'Shift' && this.shiftKey === true) {
              this.shiftKey = false;
            }
          });
        } else if (
          this.editProperty.editMode === 'eraserShapeRect' ||
          this.editProperty.editMode === 'eraserShapeEllipse'
        ) {
          //図形塗りつぶし(四角形)モード
          this.editProperty.isEdit = true;
          this.rectPoint.push(pos);
          if (this.rectPoint.length === 1) {
            this.drawRectStart(pos);
          }
        } else if (this.editProperty.editMode === 'curve') {
          //スクロール禁止
          document.addEventListener('touchmove', e.preventDefault(), { passive: false });
          if (e.which == 1 || e.touches != null) {
            this.editProperty.isEdit = true;
            //曲線スタート
            let points = {
              index: null,
              x: e.offsetX,
              y: e.offsetY,
            };
            if (e.changedTouches != null) {
              const rect = e.target.getBoundingClientRect();
              let repos = this.mobileTouch(e);
              this.eventRect = rect;
              points.x = Math.floor(repos.x);
              points.y = Math.floor(repos.y);
            }

            let check = null;
            if (this.curvePoint.length > 0) {
              check = this.curvePoint.find(
                val =>
                  val.x >= points.x - 3 &&
                  val.x < points.x + 3 &&
                  val.y >= points.y - 3 &&
                  val.y < points.y + 3
              );
            }
            //曲線のポインタが１個もない時の処理
            if (check == null) {
              if (this.curvePoint.length == 0) {
                this.freeDrawCanvas = this.$refs.freeDrawCanvas;
                this.freeDrawCanvas.width = this.$refs.main.width;
                this.freeDrawCanvas.height = this.$refs.main.height;
                this.freeDrawContext = this.freeDrawCanvas.getContext('2d');
                this.freeDrawContext.lineCap = 'round';
                this.freeDrawContext.lineJoin = 'round';
                this.freeDrawContext.lineWidth = this.editProperty.lineWidth;
                this.freeDrawContext.strokeStyle = '#' + this.setColor;
              }

              points.index = this.curvePoint.length;
              //if(this.curvePoint.length < 4)this.curvePoint.push(points);
              if (this.curvePoint.length < 3) this.curvePoint.push(points);
              this.freeDrawContext.clearRect(
                0,
                0,
                this.freeDrawCanvas.width,
                this.freeDrawCanvas.height
              );
              this.freeDrawContext.beginPath();
              this.freeDrawContext.moveTo(this.curvePoint[0].x, this.curvePoint[0].y);

              if (this.curvePoint.length == 3) {
                this.freeDrawContext.quadraticCurveTo(
                  this.curvePoint[1].x,
                  this.curvePoint[1].y,
                  this.curvePoint[2].x,
                  this.curvePoint[2].y
                );
              } else if (this.curvePoint.length >= 4) {
                this.freeDrawContext.bezierCurveTo(
                  this.curvePoint[1].x,
                  this.curvePoint[1].y,
                  this.curvePoint[2].x,
                  this.curvePoint[2].y,
                  this.curvePoint[3].x,
                  this.curvePoint[3].y
                );
              } else {
                for (let i = 1; i < this.curvePoint.length; i++) {
                  this.freeDrawContext.lineTo(this.curvePoint[i].x, this.curvePoint[i].y);
                }
              }
              this.freeDrawContext.stroke();
            }
          }
        }
        //ダミー画像に合成
        this.allEnabledLayer();
      },
      //マウスを離したときの処理
      imageMouseUp: function(e) {
        if (this.editProperty.editMode === 'move') return;
        if (this.editProperty.editMode === 'freeDraw' && this.editProperty.isEdit) {
          this.freeDrawEnd(e);
          this.$refs.main
            .getContext('2d')
            .drawImage(this.freeDrawCanvas, 0, 0, this.$refs.main.width, this.$refs.main.height);
          this.allEnabledLayer();
          this.editProperty.isEdit = false;
        } else if (this.editProperty.editMode === 'line' && this.editProperty.isEdit) {
          //直線の処理
          this.reDrawRect();
          this.freeDrawContext.moveTo(this.lineStartMove.offsetX, this.lineStartMove.offsetY);
          if (e.changedTouches == null) this.freeDrawContext.lineTo(e.offsetX, e.offsetY);
          if (e.changedTouches != null) {
            let repos = this.mobileTouch(this.touchMovePoint);
            this.freeDrawContext.lineTo(repos.x, repos.y);
          }
          this.freeDrawContext.stroke();

          this.freeDrawEnd(e);
          this.$refs.main
            .getContext('2d')
            .drawImage(this.freeDrawCanvas, 0, 0, this.$refs.main.width, this.$refs.main.height);
          this.allEnabledLayer();
          this.editProperty.isEdit = false;
        } else if (
          this.editProperty.editMode === 'shapeRect' ||
          this.editProperty.editMode === 'shapeEllipse'
        ) {
          this.freeDrawContext.strokeStyle = '#' + this.setColor;
          this.reDrawRect();
          if (this.editProperty.editMode === 'shapeRect') {
            if (e.changedTouches == null) {
              this.drawRect(
                {
                  x: e.offsetX,
                  y: e.offsetY,
                },
                this.shiftKey ? true : false
              );
            } else if (e.changedTouches != null) {
              let repos = this.mobileTouch(this.touchMovePoint);
              this.drawRect(
                {
                  x: repos.x,
                  y: repos.y,
                },
                this.shiftKey ? true : false
              );
            }
          } else {
            if (e.changedTouches == null) {
              this.drawEllipse(
                {
                  x: e.offsetX,
                  y: e.offsetY,
                },
                this.shiftKey ? true : false
              );
            } else if (e.changedTouches != null) {
              let repos = this.mobileTouch(this.touchMovePoint);
              this.drawEllipse(
                {
                  x: repos.x,
                  y: repos.y,
                },
                this.shiftKey ? true : false
              );
            }
          }
          this.rectPoint = [];
          this.undoFunction(); //undo設定
          this.$refs.main
            .getContext('2d')
            .drawImage(this.freeDrawCanvas, 0, 0, this.$refs.main.width, this.$refs.main.height);
          this.editProperty.isEdit = false;
          this.shiftKey = false;
        } else if (this.editProperty.editMode === 'eraser') {
          //消しゴムモード
          this.freeDrawEnd(e);

          //描画モード切替 切り抜きモード
          this.$refs.main.getContext('2d').globalCompositeOperation = 'destination-out';

          //消しゴム選択箇所を切り抜きする。
          this.$refs.main
            .getContext('2d')
            .drawImage(this.freeDrawCanvas, 0, 0, this.$refs.main.width, this.$refs.main.height);

          //描画モードを元に戻す。
          this.$refs.main.getContext('2d').globalCompositeOperation = 'source-over';

          this.eraserFusionImage();
          this.editProperty.isEdit = false;
        } else if (this.editProperty.editMode === 'eraserLine' && this.editProperty.isEdit) {
          //直線の処理
          this.reDrawRect();
          this.freeDrawContext.moveTo(this.lineStartMove.offsetX, this.lineStartMove.offsetY);
          if (e.changedTouches == null) this.freeDrawContext.lineTo(e.offsetX, e.offsetY);
          if (e.changedTouches != null) {
            let repos = this.mobileTouch(this.touchMovePoint);
            this.freeDrawContext.lineTo(Math.floor(repos.x), Math.floor(repos.y));
          }
          this.freeDrawContext.stroke();
          this.freeDrawEnd(e);

          //描画モード切替 切り抜きモード
          this.$refs.main.getContext('2d').globalCompositeOperation = 'destination-out';
          this.$refs.main
            .getContext('2d')
            .drawImage(this.freeDrawCanvas, 0, 0, this.$refs.main.width, this.$refs.main.height);

          //描画モードを元に戻す。
          this.$refs.main.getContext('2d').globalCompositeOperation = 'source-over';
          this.eraserFusionImage();
          this.editProperty.isEdit = false;
        } else if (
          this.editProperty.editMode === 'eraserShapeRect' ||
          this.editProperty.editMode === 'eraserShapeEllipse'
        ) {
          this.freeDrawContext.strokeStyle = '#AAAAFF';
          this.reDrawRect();
          this.$refs.main.getContext('2d').globalCompositeOperation = 'destination-out';
          if (this.editProperty.editMode === 'eraserShapeRect') {
            if (e.changedTouches == null) {
              this.drawRect({
                x: e.offsetX,
                y: e.offsetY,
              });
            } else if (e.changedTouches != null) {
              let repos = this.mobileTouch(this.touchMovePoint);
              this.drawRect({
                x: repos.x,
                y: repos.y,
              });
            }
          } else {
            if (e.changedTouches == null) {
              this.drawEllipse({
                x: e.offsetX,
                y: e.offsetY,
              });
            } else if (e.changedTouches != null) {
              let repos = this.mobileTouch(this.touchMovePoint);
              this.drawEllipse({
                x: repos.x,
                y: repos.y,
              });
            }
          }

          this.rectPoint = [];
          this.undoFunction(); //undo設定

          this.$refs.main
            .getContext('2d')
            .drawImage(this.freeDrawCanvas, 0, 0, this.$refs.main.width, this.$refs.main.height);
          this.$refs.main.getContext('2d').globalCompositeOperation = 'source-over';
          this.eraserFusionImage();
          this.editProperty.isEdit = false;
        }
        if (this.editProperty.editMode === 'curve') {
          //曲線終了
        }
        this.allEnabledLayer();
      },
      //マウスを動かしているときの処理
      imageMouseMove: function(e) {
        if (e == null) return;
        this.touchMovePoint = e;
        if (
          (this.editProperty.editMode === 'freeDraw' || this.editProperty.editMode === 'eraser') &&
          this.editProperty.isEdit
        ) {
          this.freeDraw(e);
        } else if (
          (this.editProperty.editMode === 'line' || this.editProperty.editMode === 'eraserLine') &&
          this.editProperty.isEdit
        ) {
          this.reDrawRect();
          this.freeDrawContext.moveTo(this.lineStartMove.offsetX, this.lineStartMove.offsetY);
          let x = e.offsetX;
          let y = e.offsetY;
          if (e.changedTouches != null) {
            let repos = this.mobileTouch(e);
            x = Math.floor(repos.x);
            y = Math.floor(repos.y);
          }
          this.freeDrawContext.lineTo(x, y);
          this.freeDrawContext.stroke();
        } else if (
          (this.editProperty.editMode === 'rect' || this.editProperty.editMode === 'eraserRect') &&
          this.editProperty.isEdit
        ) {
          //選択範囲
          if (this.rectPoint.length == 0) return;

          //目安の点線を表示
          let pos = {
            x: e.offsetX,
            y: e.offsetY,
          };
          if (e.changedTouches != null) {
            let repos = this.mobileTouch(e);
            pos.x = repos.x;
            pos.y = repos.y;
          }

          this.reDrawRect();
          this.rectLine(pos);
        } else if (this.editProperty.editMode === 'eraserRect' && this.editProperty.isEdit) {
          if (this.rectPoint.length == 0) return;

          //目安の点線を表示
          let pos = {
            x: e.offsetX,
            y: e.offsetY,
          };
          if (e.changedTouches != null) {
            let repos = this.mobileTouch(e);
            pos.x = repos.x;
            pos.y = repos.y;
          }

          this.reDrawRect();
          this.rectLine(pos);
        } else if (
          (this.editProperty.editMode === 'shapeRect' ||
            this.editProperty.editMode === 'shapeEllipse') &&
          this.editProperty.isEdit
        ) {
          if (this.rectPoint.length == 0) return;

          let pos = {
            x: e.offsetX,
            y: e.offsetY,
          };
          if (e.changedTouches != null) {
            let repos = this.mobileTouch(e);
            pos.x = repos.x;
            pos.y = repos.y;
          }

          document.addEventListener('keydown', event => {
            if (event.key === 'Shift' && this.shiftKey === false) {
              this.shiftKey = true;
            }
          });
          document.addEventListener('keyup', event => {
            if (event.key === 'Shift' && this.shiftKey === true) {
              this.shiftKey = false;
            }
          });

          this.reDrawRect();
          if (this.editProperty.editMode === 'shapeRect' && this.shiftKey) {
            this.drawRect(pos, true);
          } else if (this.editProperty.editMode === 'shapeRect') {
            this.drawRect(pos);
          } else if (this.editProperty.editMode === 'shapeEllipse' && this.shiftKey) {
            this.drawEllipse(pos, true);
          } else {
            this.drawEllipse(pos);
          }
        } else if (
          (this.editProperty.editMode === 'eraserShapeRect' ||
            this.editProperty.editMode === 'eraserShapeEllipse') &&
          this.editProperty.isEdit
        ) {
          if (this.rectPoint.length == 0) return;

          let pos = {
            x: e.offsetX,
            y: e.offsetY,
          };
          if (e.changedTouches != null) {
            let repos = this.mobileTouch(e);
            pos.x = repos.x;
            pos.y = repos.y;
          }

          this.reDrawRect();
          if (this.editProperty.editMode === 'eraserShapeRect') {
            this.drawRect(pos);
          } else {
            this.drawEllipse(pos);
          }
        } else if (this.editProperty.editMode === 'curve') {
          //スクロール禁止
          if (e.preventDefault != null) {
            document.addEventListener('touchmove', e.preventDefault(), { passive: false });
          }

          //曲線動き
          if (this.downSw == true) {
            let modeX = e.offsetX;
            let modeY = e.offsetY;
            if (e.changedTouches != null) {
              let repos = this.mobileTouch(e);
              modeX = Math.floor(repos.x);
              modeY = Math.floor(repos.y);
            }
            this.dragPoint.x = modeX;
            this.dragPoint.y = modeY;

            this.freeDrawContext.clearRect(
              0,
              0,
              this.freeDrawCanvas.width,
              this.freeDrawCanvas.height
            );
            this.freeDrawContext.beginPath();
            this.freeDrawContext.moveTo(this.curvePoint[0].x, this.curvePoint[0].y);

            if (this.curvePoint.length == 3) {
              this.freeDrawContext.quadraticCurveTo(
                this.curvePoint[1].x,
                this.curvePoint[1].y,
                this.curvePoint[2].x,
                this.curvePoint[2].y
              );
            } else if (this.curvePoint.length >= 4) {
              this.freeDrawContext.bezierCurveTo(
                this.curvePoint[1].x,
                this.curvePoint[1].y,
                this.curvePoint[2].x,
                this.curvePoint[2].y,
                this.curvePoint[3].x,
                this.curvePoint[3].y
              );
            } else {
              for (let i = 1; i < this.curvePoint.length; i++) {
                this.freeDrawContext.lineTo(this.curvePoint[i].x, this.curvePoint[i].y);
              }
            }
            this.freeDrawContext.stroke();
          }
        }
        //this.allEnabledLayer();
      },
      /**
       * マスクデータ作成
       * 引数に渡されたレイヤーのマスクデータを作成します。
       * マスクデータの変更はレイヤーコンポーネントが監視し、
       * 即時マスク描画が行われます。
       * @param object layer
       * @param number x
       * @param number y
       */
      createMaskData: function(x, y) {
        let old = this.oldMask ? this.oldMask.data : null;
        let mainCtx = this.$refs.main
          .getContext('2d')
          .getImageData(0, 0, this.$refs.main.width, this.$refs.main.height);
        let dummyCtx = this.$refs.dummyBaseImage
          .getContext('2d')
          .getImageData(0, 0, this.$refs.dummyBaseImage.width, this.$refs.dummyBaseImage.height);
        this.allEnabledLayer();
        this.undoData.unshift(mainCtx);
        this.undoDummyData.unshift(dummyCtx);

        this.setBaseImageInfo();
        this.mask = MagicWand.floodFill(
          this.imageInfo,
          x,
          y,
          this.editProperty.threshold,
          old,
          true
        );
      },
      //layerのmaskデータが変わったら描画
      drawMask: function() {
        let rgba = this.hexToRgb(this.setColor, 1.0);
        let canvas = document.createElement('canvas');
        canvas.width = this.$refs.main.width;
        canvas.height = this.$refs.main.height;
        let data = this.mask.data,
          bounds = this.mask.bounds,
          maskW = this.mask.width,
          w = this.$refs.main.width,
          h = this.$refs.main.height,
          ctx = canvas.getContext('2d'),
          imgData = ctx.createImageData(w, h),
          res = imgData.data;

        for (let y = bounds.minY; y <= bounds.maxY; y++) {
          for (let x = bounds.minX; x <= bounds.maxX; x++) {
            if (data[y * maskW + x] == 0) continue;
            let k = (y * w + x) * 4;
            res[k] = rgba[0];
            res[k + 1] = rgba[1];
            res[k + 2] = rgba[2];
            res[k + 3] = rgba[3];
          }
        }
        ctx.putImageData(imgData, 0, 0);
        this.$refs.main
          .getContext('2d')
          .drawImage(canvas, 0, 0, this.$refs.main.width, this.$refs.main.height);
      },
      //手動塗り
      freeDrawStart: function(e) {
        let x = e.offsetX;
        let y = e.offsetY;
        if (e.changedTouches != null) {
          let repos = this.mobileTouch(e);
          x = repos.x;
          y = repos.y;
        }
        this.freeDrawCanvas = this.$refs.freeDrawCanvas;
        this.freeDrawCanvas.width = this.$refs.main.width;
        this.freeDrawCanvas.height = this.$refs.main.height;
        this.freeDrawContext = this.freeDrawCanvas.getContext('2d');
        this.freeDrawContext.lineCap = 'round';
        this.freeDrawContext.lineJoin = 'round';
        this.freeDrawContext.lineWidth = this.editProperty.lineWidth;
        this.freeDrawContext.strokeStyle = '#' + this.setColor;
        this.freeDrawContext.beginPath();
        this.freeDrawContext.lineTo(x, y);
        this.freeDrawContext.stroke();
      },
      //直線
      lineDrawStart: function(e) {
        let x = e.offsetX;
        let y = e.offsetY;
        if (e.changedTouches != null) {
          let repos = this.mobileTouch(e);
          x = repos.x;
          y = repos.y;
        }
        this.freeDrawCanvas = this.$refs.freeDrawCanvas;
        this.freeDrawCanvas.width = this.$refs.main.width;
        this.freeDrawCanvas.height = this.$refs.main.height;
        this.freeDrawContext = this.freeDrawCanvas.getContext('2d');
        this.freeDrawContext.lineCap = 'round';
        this.freeDrawContext.lineJoin = 'round';
        this.freeDrawContext.lineWidth = this.editProperty.lineWidth;
        this.freeDrawContext.strokeStyle = '#' + this.setColor;
        if (this.editProperty.editMode === 'eraserLine')
          this.freeDrawContext.strokeStyle = '#' + 'AAAAFF';
        this.freeDrawContext.beginPath();
        this.freeDrawContext.moveTo(x, y);

        this.freeDrawContext.stroke();
      },
      //消しゴム
      eraserStart: function(e) {
        let x = e.offsetX;
        let y = e.offsetY;
        if (e.changedTouches != null) {
          let repos = this.mobileTouch(e);
          x = repos.x;
          y = repos.y;
        }
        this.freeDrawCanvas = this.$refs.freeDrawCanvas;
        this.freeDrawCanvas.width = this.$refs.main.width;
        this.freeDrawCanvas.height = this.$refs.main.height;
        this.freeDrawContext = this.freeDrawCanvas.getContext('2d');
        this.freeDrawContext.lineCap = 'round';
        this.freeDrawContext.lineJoin = 'round';
        this.freeDrawContext.lineWidth = this.editProperty.lineWidth;
        this.freeDrawContext.strokeStyle = '#' + 'AAAAFF';
        this.freeDrawContext.beginPath();
        this.freeDrawContext.lineTo(x, y);
        this.freeDrawContext.stroke();
      },
      freeDraw: function(e) {
        //ここに
        let x = e.offsetX;
        let y = e.offsetY;
        if (e.changedTouches != null) {
          let repos = this.mobileTouch(e);
          x = repos.x;
          y = repos.y;
        }
        this.freeDrawContext.lineTo(x, y);
        this.freeDrawContext.stroke();
      },
      freeDrawEnd: function() {
        this.freeDrawContext.closePath();
      },
      curveStart: function(e) {
        this.freeDrawCanvas = this.$refs.freeDrawCanvas;
        this.freeDrawCanvas.width = this.$refs.main.width;
        this.freeDrawCanvas.height = this.$refs.main.height;
        this.freeDrawContext = this.freeDrawCanvas.getContext('2d');
        this.freeDrawContext.lineCap = 'round';
        this.freeDrawContext.lineJoin = 'round';
        this.freeDrawContext.lineWidth = this.editProperty.lineWidth;
        this.freeDrawContext.strokeStyle = '#' + this.setColor;
        this.freeDrawContext.beginPath();
        this.freeDrawContext.moveTo(e.x, e.y);
        this.freeDrawContext.stroke();
      },

      //ダミーデータにマスクを合成する。
      fusionImage: async function(enabledLayer = null) {
        try {
          if (this.$refs.dummyBaseImage == null) return;
          let ctx = this.$refs.dummyBaseImage.getContext('2d');
          let image = this.baseImageInfo.context.getImageData(
            0,
            0,
            this.baseImageInfo.canvas.width,
            this.baseImageInfo.canvas.height
          );
          ctx.putImageData(image, 0, 0);

          //表示状態の各レイヤーをレイヤーをダミーキャンパスに設定する
          if (enabledLayer != null) {
            for (let layer of enabledLayer) {
              ctx.filter = 'opacity(' + layer.transparent + '%)'; //透明度の設定
              let mems_canvas = document.createElement('canvas');
              mems_canvas.width = this.baseImageInfo.canvas.width;
              mems_canvas.height = this.baseImageInfo.canvas.height;
              let mems_context = mems_canvas.getContext('2d');
              await mems_context.drawImage(
                layer.canvas,
                0,
                0,
                this.$refs.main.width,
                this.$refs.main.height
              );
              mems_context.globalCompositeOperation = 'source-in';
              mems_context.fillStyle = layer.layerColor;
              mems_context.fillRect(
                0,
                0,
                this.baseImageInfo.canvas.width,
                this.baseImageInfo.canvas.height
              );
              mems_context.globalCompositeOperation = 'source-over';
              mems_context.fillStyle = 'none';
              await ctx.drawImage(mems_canvas, 0, 0, this.$refs.main.width, this.$refs.main.height);
              ctx.filter = 'none';
            }
          }
        } catch (e) {
          console.error(e);
        }
      },
      //削除時の画像描画処理
      eraserFusionImage: async function() {
        let ctx = this.$refs.dummyBaseImage.getContext('2d');

        //設定していないときそのままで描画する。
        if (this.materialImages == null) {
          //baseイメージを出力
          ctx.drawImage(
            this.baseImageInfo.canvas,
            0,
            0,
            this.$refs.main.width,
            this.$refs.main.height
          );
          //フィルターON
          ctx.filter =
            'brightness(' + this.layer.brightness + '% ) saturate(' + this.layer.saturation + '%)';
          ctx.drawImage(this.$refs.main, 0, 0, this.$refs.main.width, this.$refs.main.height);
        } else {
          //レイヤーに削除範囲を指定
          this.$refs.main.getContext('2d').globalCompositeOperation = 'destination-out';
          this.$refs.main
            .getContext('2d')
            .drawImage(this.freeDrawCanvas, 0, 0, this.$refs.main.width, this.$refs.main.height);
          this.$refs.main.getContext('2d').globalCompositeOperation = 'source-over';

          //変換元の画像
          let getDataUrl = await this.layerReplacement(this.$refs.main.toDataURL('image/png'));
          let image = await new Promise(resolve => {
            let image = new Image();
            image.src = getDataUrl;
            image.crossOrigin = 'anonymous';
            image.onload = function() {
              resolve(image);
            };
          });
          this.materialImages = image;
          //baseイメージを出力
          ctx.drawImage(
            this.baseImageInfo.canvas,
            0,
            0,
            this.$refs.main.width,
            this.$refs.main.height
          );
          //フィルターON
          ctx.filter =
            'brightness(' + this.layer.brightness + '% ) saturate(' + this.layer.saturation + '%)';
          await ctx.drawImage(image, 0, 0, this.$refs.main.width, this.$refs.main.height);
        }
        ctx.filter = 'none';
        this.allEnabledLayer();
      },
      layerFunctionImage: function() {
        let ctx = this.$refs.dummyBaseImage.getContext('2d');

        //baseイメージを出力
        ctx.drawImage(
          this.baseImageInfo.canvas,
          0,
          0,
          this.$refs.main.width,
          this.$refs.main.height
        );
        ctx.filter =
          'brightness(' + this.layer.brightness + '% ) saturate(' + this.layer.saturation + '%)';
        //設定していないときそのまま緑色で描画する。
        if (this.materialImages == null) {
          ctx.drawImage(this.$refs.main, 0, 0, this.$refs.main.width, this.$refs.main.height);
        } else {
          ctx.drawImage(this.materialImages, 0, 0, this.$refs.main.width, this.$refs.main.height);
        }
        ctx.filter = 'none';
      },
      rectStart: function(pos) {
        this.freeDrawCanvas = this.$refs.freeDrawCanvas;
        this.freeDrawCanvas.width = this.$refs.main.width;
        this.freeDrawCanvas.height = this.$refs.main.height;
        this.freeDrawContext = this.freeDrawCanvas.getContext('2d');
        this.freeDrawContext.lineCap = 'round';
        this.freeDrawContext.lineJoin = 'round';
        this.freeDrawContext.lineWidth = this.editProperty.lineWidth * 0.5;
        this.freeDrawContext.setLineDash([
          this.editProperty.lineWidth * 0.5,
          this.editProperty.lineWidth,
        ]);
        this.freeDrawContext.shadowColor = '#000';
        this.freeDrawContext.shadowOffsetX = 1;
        this.freeDrawContext.shadowOffsetY = 1;
        this.freeDrawContext.strokeStyle = '#' + this.setColor;
        this.freeDrawContext.fillStyle = '#' + this.setColor;
        if (this.editProperty.editMode === 'eraserRect') {
          this.freeDrawContext.strokeStyle = '#AAAAFF';
        }
        this.freeDrawContext.beginPath();
        this.freeDrawContext.moveTo(pos.x, pos.y);
        this.freeDrawContext.stroke();
        this.freeDrawContext.closePath();
      },
      rectLine: function(pos) {
        this.freeDrawContext.beginPath();
        let fromPos = this.rectPoint[this.rectPoint.length - 1];
        this.freeDrawContext.fillStyle = '#' + this.setColor;
        this.freeDrawContext.moveTo(fromPos.x, fromPos.y);
        this.freeDrawContext.lineTo(pos.x, pos.y);
        this.freeDrawContext.stroke();
      },

      rectEnd: function(pos) {
        this.freeDrawContext.clearRect(0, 0, this.freeDrawCanvas.width, this.freeDrawCanvas.height);
        this.freeDrawContext.setLineDash([]);
        this.freeDrawContext.strokeStyle = '#' + this.setColor;
        this.freeDrawContext.beginPath();
        this.freeDrawContext.moveTo(this.rectPoint[0].x, this.rectPoint[0].y);
        for (let i = 1; i < this.rectPoint.length; i++) {
          this.freeDrawContext.lineTo(this.rectPoint[i].x, this.rectPoint[i].y);
        }
        this.freeDrawContext.lineTo(pos.x, pos.y);
        this.freeDrawContext.fill();
        this.freeDrawContext.stroke();
        this.freeDrawContext.closePath();
      },
      //矩形の途中まで再描画
      reDrawRect: function() {
        this.freeDrawContext.clearRect(0, 0, this.freeDrawCanvas.width, this.freeDrawCanvas.height);
        this.freeDrawContext.beginPath();
        for (let i = 1; i < this.rectPoint.length; i++) {
          this.freeDrawContext.moveTo(this.rectPoint[i - 1].x, this.rectPoint[i - 1].y);
          this.freeDrawContext.lineTo(this.rectPoint[i].x, this.rectPoint[i].y);
          this.freeDrawContext.stroke();
        }
      },
      drawRectStart: function() {
        this.freeDrawCanvas = this.$refs.freeDrawCanvas;
        this.freeDrawCanvas.width = this.$refs.main.width;
        this.freeDrawCanvas.height = this.$refs.main.height;
        this.freeDrawContext = this.freeDrawCanvas.getContext('2d');
        this.freeDrawContext.fillStyle = '#' + this.setColor;
        if (
          this.editProperty.editMode === 'eraserShapeRect' ||
          this.editProperty.editMode === 'eraserShapeEllipse'
        ) {
          this.freeDrawContext.fillStyle = '#AAAAFF';
        }
      },
      drawRect: function(pos, square = false) {
        if (this.rectPoint.length <= 0) return;
        let width = pos.x - this.rectPoint[0].x;
        let height = pos.y - this.rectPoint[0].y;
        this.freeDrawContext.clearRect(0, 0, this.freeDrawCanvas.width, this.freeDrawCanvas.height);
        //  shiftキー押しながらの場合
        if (square) {
          //  比率を調節するための値（短い値ベース）
          let adjustNum = 0;
          if (Math.abs(width) > Math.abs(height)) {
            adjustNum = Math.abs(height);
          } else {
            adjustNum = Math.abs(width);
          }
          if (width >= 0 && height >= 0) {
            this.freeDrawContext.fillRect(
              this.rectPoint[0].x,
              this.rectPoint[0].y,
              adjustNum,
              adjustNum
            );
          } else if (width < 0 && height >= 0) {
            this.freeDrawContext.fillRect(
              this.rectPoint[0].x,
              this.rectPoint[0].y,
              adjustNum * -1,
              adjustNum
            );
          } else if (width >= 0 && height < 0) {
            this.freeDrawContext.fillRect(
              this.rectPoint[0].x,
              this.rectPoint[0].y,
              adjustNum,
              adjustNum * -1
            );
          } else if (width < 0 && height < 0) {
            this.freeDrawContext.fillRect(
              this.rectPoint[0].x,
              this.rectPoint[0].y,
              adjustNum * -1,
              adjustNum * -1
            );
          }
        } else {
          if (width >= 0 && height >= 0) {
            this.freeDrawContext.fillRect(this.rectPoint[0].x, this.rectPoint[0].y, width, height);
          } else if (width < 0 && height >= 0) {
            this.freeDrawContext.fillRect(pos.x, this.rectPoint[0].y, width * -1, height);
          } else if (width >= 0 && height < 0) {
            this.freeDrawContext.fillRect(this.rectPoint[0].x, pos.y, width, height * -1);
          } else if (width < 0 && height < 0) {
            this.freeDrawContext.fillRect(pos.x, pos.y, width * -1, height * -1);
          }
        }
      },
      drawEllipse: function(pos, perfectCircle = false) {
        if (this.rectPoint.length <= 0) return;
        //長径、短径に使うため、長さを出しておく。
        let width = pos.x - this.rectPoint[0].x;
        let height = pos.y - this.rectPoint[0].y;
        //楕円形の中心座標を求める
        let centerOfGravity = {
          x: null,
          y: null,
        };
        //  比率を調節するための値（短い値ベース）
        let adjustNum = 0;
        //  shiftキー押しながらの場合
        if (perfectCircle) {
          if (Math.abs(width) > Math.abs(height)) {
            adjustNum = Math.abs(height / 2);
          } else {
            adjustNum = Math.abs(width / 2);
          }
        }
        //x座標
        if (width >= 0) {
          centerOfGravity.x = this.rectPoint[0].x + (pos.x - this.rectPoint[0].x) / 2;
        } else {
          centerOfGravity.x = pos.x + (this.rectPoint[0].x - pos.x) / 2;
        }
        //y座標
        if (height >= 0) {
          centerOfGravity.y = this.rectPoint[0].y + (pos.y - this.rectPoint[0].y) / 2;
        } else {
          centerOfGravity.y = pos.y + (this.rectPoint[0].y - pos.y) / 2;
        }
        this.freeDrawContext.clearRect(0, 0, this.freeDrawCanvas.width, this.freeDrawCanvas.height);
        this.freeDrawContext.strokeStyle = '#' + this.setColor;
        this.freeDrawContext.beginPath();
        this.freeDrawContext.ellipse(
          centerOfGravity.x,
          centerOfGravity.y,
          perfectCircle ? adjustNum : Math.abs(width / 2),
          perfectCircle ? adjustNum : Math.abs(height / 2),
          0,
          0,
          2 * Math.PI
        );
        this.freeDrawContext.fill();
        this.freeDrawContext.stroke();
      },
      /**
       * マスク状態を一つ前に戻します。
       */
      undoMask: function() {
        //ポイント設置、処理中はundoを行わない
        if (this.rectPoint.length > 0) return;
        if (this.curvePoint.length > 0) return;

        if (this.undoData.length > 0) {
          //現在の状態を保存
          let mainCtx = this.$refs.main
            .getContext('2d')
            .getImageData(0, 0, this.$refs.main.width, this.$refs.main.height);
          let dummyCtx = this.$refs.dummyBaseImage
            .getContext('2d')
            .getImageData(0, 0, this.$refs.dummyBaseImage.width, this.$refs.dummyBaseImage.height);
          this.undoCancelData.unshift(mainCtx);
          this.undoCancelDummyData.unshift(dummyCtx);

          //一つ前の状態にする。
          if (this.undoData.length < 1) return;
          let imageData = this.undoData.shift();
          this.$refs.main.getContext('2d').putImageData(imageData, 0, 0);
          let dummyImageData = this.undoDummyData.shift();
          this.$refs.dummyBaseImage.getContext('2d').putImageData(dummyImageData, 0, 0);
          //ダミー画像に合成
          this.allEnabledLayer();
        }
      },
      /**
       * マスクを一つ先の状態にする。
       */
      undoCancelMask: function() {
        //ポイント設置、処理中はundoを行わない
        if (this.rectPoint.length > 0) return;
        if (this.curvePoint.length > 0) return;

        if (this.undoCancelData.length > 0) {
          //現在の状態を保存
          let mainCtx = this.$refs.main
            .getContext('2d')
            .getImageData(0, 0, this.$refs.main.width, this.$refs.main.height);
          let dummyCtx = this.$refs.dummyBaseImage
            .getContext('2d')
            .getImageData(0, 0, this.$refs.dummyBaseImage.width, this.$refs.dummyBaseImage.height);
          this.undoData.unshift(mainCtx);
          this.undoDummyData.unshift(dummyCtx);

          //一つ前の状態にする。
          if (this.undoData.length < 1) return;
          let imageData = this.undoCancelData.shift();
          this.$refs.main.getContext('2d').putImageData(imageData, 0, 0);
          let dummyImageData = this.undoCancelDummyData.shift();
          this.$refs.dummyBaseImage.getContext('2d').putImageData(dummyImageData, 0, 0);
          //ダミー画像に合成
          this.allEnabledLayer();
        }
      },

      //16進数表記からRGBに変換する
      hexToRgb: function(hex, alpha) {
        let int = parseInt(hex, 16);
        let r = (int >> 16) & 255;
        let g = (int >> 8) & 255;
        let b = int & 255;
        return [r, g, b, Math.round(alpha * 255)];
      },
      convertCanvasToImage: function() {
        //todo: 画像サイズを一律にする
        return this.$refs.main.toDataURL('image/png');
      },
      //部屋情報設定
      setBaseImageInfo: function() {
        this.imageInfo = {
          width: this.$refs.dummyBaseImage.width,
          height: this.$refs.dummyBaseImage.height,
          data: this.$refs.dummyBaseImage
            .getContext('2d')
            .getImageData(0, 0, this.$refs.dummyBaseImage.width, this.$refs.dummyBaseImage.height)
            .data,
          bytes: COLOR_BYTE,
        };
      },
      getMainCanvas: function() {
        return this.$refs.main;
      },
      pointMouseDown: function(e) {
        this.downSw = true;
        if (e.changedTouches == null) {
          this.dragPoint = e;
        } else if (e.changedTouches != null) {
          let repos = this.mobileTouch(e);
          this.dragPoint.x = repos.x;
          this.dragPoint.y = repos.y;
        }
      },
      pointMouseUp: function() {
        this.downSw = false;
        this.dragPoint = null;
      },
      /**
       * 右クリック処理
       */
      pointFix: function() {
        //曲線を確定する。
        if (this.editProperty.editMode === 'curve') {
          //ポインタを１つも付けてないものは処理しない
          if (this.curvePoint.length == 0) return;
          //曲線の処理
          this.curvePoint = [];
          this.dragPoint = null;
          this.downSw = false;

          this.undoFunction(); //undo処理

          this.freeDrawEnd();
          this.$refs.main
            .getContext('2d')
            .drawImage(this.freeDrawCanvas, 0, 0, this.$refs.main.width, this.$refs.main.height);
          this.allEnabledLayer();
          this.editProperty.isEdit = false;
        }
      },
      pointMoveEvent: function(e) {
        //スクロール禁止
        document.addEventListener('touchmove', e.preventDefault(), { passive: false });

        this.imageMouseMove(e);
      },
      /**
       * undoの保存
       */
      undoFunction() {
        //undoの設定
        let mainCtx = this.$refs.main
          .getContext('2d')
          .getImageData(0, 0, this.$refs.main.width, this.$refs.main.height);
        let dummyCtx = this.$refs.dummyBaseImage
          .getContext('2d')
          .getImageData(0, 0, this.$refs.dummyBaseImage.width, this.$refs.dummyBaseImage.height);
        this.undoData.unshift(mainCtx);
        this.undoDummyData.unshift(dummyCtx);
        this.undoCancelData = [];
      },
    },
  };
</script>
<style scoped>
  .layerWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
  }
  .layerWrapper.active {
    display: block;
  }
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
  .layer {
    display: none;
    pointer-events: none;
  }
  .free-draw-canvas {
    display: none;
    pointer-events: none;
  }

  .free-draw-canvas.active {
    display: block;
  }

  .corner {
    position: absolute;
    width: 16px;
    height: 16px;
    margin: -5px;
    background: #ff0000;
    border: 2px solid white;
    border-radius: 50%;
    cursor: move;
    z-index: 9999;
  }
</style>
