<template>
  <CommonLayout title="Ⅲ．実行可能な具体的活用法">
    <template v-slot>
      <ReportSubTitle subTitle="活用法の比較・検討" />
      <div
        style="
            text-align: center;
            font-size: 90px;
            margin-top: 150px;
            font-weight: bold;
        "
      >
        調査検討の結果、 実行可能な活用法として次のものが考えられます。
      </div>
      <div
        style="
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 8% 8%;
            padding: 1%;
            padding-right: 5%;
            font-size: 90px;
            width:80%;
            height: 65%;
            margin-left: 7%;
            margin-top: 1%;
        "
      >
        <div
          v-for="item in dispItems"
          :key="item.title"
          style="
              box-shadow: 2px 2px 6px rgba(0,0,0,.4);
              background-color: white;
              width: 1260px;
              height: 840px;
          "
        >
          <div>{{ item.title }}</div>
          <img
            :src="item.imageUrl"
            alt=""
            width="1050"
            height="700"
            style="margin:auto; display:block;"
          />
        </div>
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';
  import ReportSubTitle from '@/components/ExportReport/ReportSubTitle';
  import { API, graphqlOperation } from 'aws-amplify';
  import { getS3Url } from '../../../graphql/queries';
  import awsconfig from '../../../aws-exports';
  import noImage from '@/assets/report/reportImages/noimage.png';
  import detachedHouses from '../ReportImages/detatchedHouse.png';
  import housingComplexFamily from '../ReportImages/housingComplex_family.png';
  import housingComplexAlone from '../ReportImages/housingComplex_alone.png';
  import landForRent from '../ReportImages/landForRent.png';
  import landForSale from '../ReportImages/landForSale.png';
  import monthlyParkingLot from '../ReportImages/monthlyParkingLot.png';

  API.configure(awsconfig);
  export default {
    props: {
      realEstateInput: Object,
    },
    components: {
      CommonLayout,
      ReportSubTitle,
    },
    data() {
      return {
        imageUrl: {
          vacantLandAndLeasedLand: null,
          monthlyParkingLot: null,
          groupRental: null,
          groupRentalFamily: null,
          detachedHousesForRent: null,
          sellAndCashOut: null,
        },
        items: [
          {
            title: '①更地・貸地（資材置場）',
            name: 'vacantLandAndLeasedLand',
            imgPath: null,
          },
          { title: '②月極駐車場', name: 'monthlyParkingLot', imageUrl: null },
          { title: '③集合型賃貸（単身用）', name: 'groupRental', imageUrl: null },
          {
            title: '④集合型賃貸・家族用',
            name: 'groupRentalFamily',
            imageUrl: null,
          },
          { title: '⑤賃貸戸建', name: 'detachedHousesForRent', imageUrl: null },
          { title: '⑥売却して現金化', name: 'sellAndCashOut', imageUrl: null },
        ],
        dispItems: [],
      };
    },
    async created() {
      const img = this.realEstateInput.locationRequirement.concreteApplicationImages;
      // 画像のプロパティ名取得
      const keys = Object.keys(img);

      try {
        const result = await API.graphql(
          graphqlOperation(getS3Url, {
            level: 'public',
            file: keys.map(x => {
              const file = {
                fileName: this.realEstateInput.locationRequirement.concreteApplicationImages[x],
                fileAction: 'View',
              };
              return file;
            }),
          })
        );

        // if (result.data == null) {
        //   throw Error('画像URLの取得に失敗しました');
        // }

        const links = result.data.getS3Url;
        const imageResult = this.items.map(async item => {
          const link = links.find(val => {
            if (img[item.name] == null) {
              return;
            }
            return val.fileName === img[item.name];
          });

          const defaultImage =
            item.name == 'vacantLandAndLeasedLand'
              ? landForRent
              : item.name == 'monthlyParkingLot'
              ? monthlyParkingLot
              : item.name == 'groupRental'
              ? housingComplexAlone
              : item.name == 'groupRentalFamily'
              ? housingComplexFamily
              : item.name == 'detachedHousesForRent'
              ? detachedHouses
              : item.name == 'sellAndCashOut'
              ? landForSale
              : noImage;

          let defaultUrl = location.origin + defaultImage;

          return {
            title: item.title,
            name: item.name,
            imageUrl: link ? link.fileUrl : defaultUrl,
          };
        });

        this.dispItems = await Promise.all(imageResult);
      } catch {
        const imageResult = this.items.map(async item => {
          // const link = links.find(val => {
          //   if (img[item.name] == null) {
          //     return;
          //   }
          //   return val.fileName === img[item.name];
          // });

          const defaultImage =
            item.name == 'vacantLandAndLeasedLand'
              ? landForRent
              : item.name == 'monthlyParkingLot'
              ? monthlyParkingLot
              : item.name == 'groupRental'
              ? housingComplexAlone
              : item.name == 'groupRentalFamily'
              ? housingComplexFamily
              : item.name == 'detachedHousesForRent'
              ? detachedHouses
              : item.name == 'sellAndCashOut'
              ? landForSale
              : noImage;

          return {
            title: item.title,
            name: item.name,
            imageUrl: await this.toDataURL(defaultImage),
          };
        });

        this.dispItems = await Promise.all(imageResult);

        // console.error(error);
        // this.showError('E0001');
      }
    },
  };
</script>
