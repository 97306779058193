import { API, graphqlOperation } from 'aws-amplify';
import { getSystemMaster, getToday } from '../../graphql/queries';
import { getAozoraPayment } from '../../graphql/queries';
var moment = require('moment-timezone');
moment.tz.setDefault('Asia/Tokyo');

export const SWITCH_MESSAGES = { ON: 'ON', OFF: 'OFF', OFF_NG: 'OFF_NG', NG: 'NG' }; // スイッチメッセージ

export default {
  filters: {
    // 金額書式編集
    yen(value) {
      return value == 0
        ? null
        : value == null || value == '' || value == undefined || isNaN(value)
        ? null
        : Math.round(Number(value)).toLocaleString('ja-JP', {
            style: 'currency',
            currency: 'JPY',
          });
    },
  },
  methods: {
    // システム日付(YYYY-MM-DD)
    getToday: function() {
      return new Date().toISOString().slice(0, 10);
    },

    // 指定日の翌日(YYYY-MM-DD)
    getTomorrow: function(today) {
      let dt = new Date(Date.parse(today));
      dt.setDate(dt.getDate() + 1);
      return dt.toISOString().slice(0, 10);
    },

    // 指定日の翌年(YYYY-MM-DD)
    getNewYear: function(today) {
      let dt = new Date(Date.parse(today));
      dt.setFullYear(dt.getFullYear() + 1);
      return dt.toISOString().slice(0, 10);
    },

    // ライセンスマスタ取得
    loadMaster: async function() {
      const result = await API.graphql(
        graphqlOperation(getSystemMaster, {
          classification: 'license',
          classificationDetail: '1',
        })
      );

      // 文字列⇒JSON変換
      let items = [];
      result.data.getSystemMaster.value.forEach(element => {
        items.push(JSON.parse(element));
      });

      return items;
    },

    /**
     *  METHOD: 日付を任意の値やフォーマットで変更
     */
    changeDateString: function(dateStr, addNum, format, addTarget) {
      // 決済IDかをチェック
      if (
        moment('20' + dateStr, 'YYYYMM', true).isValid() &&
        moment('20' + dateStr).isBetween('2000-01', '3000-01', 'month') // 4桁決済IDの場合
      ) {
        // 日付にフォーマット
        dateStr = '20' + dateStr;
      } else if (
        moment('200' + dateStr, 'YYYYMM', true).isValid() &&
        moment('200' + dateStr).isBetween('2000-01', '3000-01', 'month') // 3桁決済IDの場合
      ) {
        // 日付にフォーマット
        dateStr = '200' + dateStr;
      }

      // 日付データへ変換
      const date = moment(dateStr);
      // データに問題がないかチェック
      if (!date.isValid()) {
        throw `changeDateString: 渡された日付に問題があります, dateStr: ${dateStr}, num: ${addNum} `;
      }
      // 日付を指定された数だけ移動
      if (addNum != 0) {
        date.add(addNum, addTarget ? addTarget : 'month');
      }
      // フォーマット
      const newPaymentId = date.format(format ? format : 'YYYY-MM-DD');
      return newPaymentId;
    },

    /**
     * 支払方法設定
     * @param {*} key
     * @returns
     */
    getPaymentType: function(key) {
      let items = [];
      items.push({ no: -1, name: '-', key: 'NON_TYPE' });
      items.push({ no: 0, name: 'クレジットカード', key: 'CREDIT_CARD' });
      items.push({ no: 36, name: 'あおぞら銀行', key: 'BANK_TRANSFER' });
      items.push({ no: 998, name: '口座振替', key: 'ACCOUNT_TRANSFER_PAPER' });
      //items.push({ no: 999, name: '口座振替(オンライン)', key: 'ACCOUNT_TRANSFER_ONLINE' });

      let item = items.find(val => val.key == key);
      if (item != null) {
        return item;
      } else if (item == null) {
        return items;
      }

      return items.find(val => val.no == -1);
    },

    /**
     *  METHOD:  決済ID生成
     */
    createPaymentId: function(dateStr, addNum) {
      const addNumResult = addNum ? addNum : 0;
      // 日付または決済IDを任意月の決済ID用フォーマットへ変更し、数値に変換
      return parseInt(this.changeDateString(dateStr, addNumResult, 'YYMM'));
    },

    /**
     *  METHOD: あおぞらの支払方法を取得
     * @param {*} companys
     * @returns
     */
    getAozoraPaymentData: async function(company) {
      let aozoraPayment = [];
      if (company?.bankTransfer != null) {
        try {
          let result = await API.graphql(
            graphqlOperation(getAozoraPayment, {
              accessID: company.bankTransfer.accessID,
              accessPass: company.bankTransfer.accessPass,
              orderID: company.bankTransfer.orderId,
            })
          );
          if (result.data.getAozoraPayment.statusCode == 200) {
            
            let oldCompany = company?.payment?.filter(val => parseInt(val.id) < this.getTodayId());
            let payData = JSON.parse(result.data.getAozoraPayment.result);

            let createOldPay = [];
            if(oldCompany != null){
              for (let pay of oldCompany) {
                if (pay.paymentResult == true) {
                  let formattedDateStr = pay.billingDate.replace(/-/g, '');
                  let amount = pay.amount + pay.tax;
                  createOldPay.push([
                    this.changeDay(formattedDateStr,26),
                    'ｶｺｹｯｻｲ',
                    'ｶｺｹｯｻｲ',
                    'ｶｺｹｯｻｲ',
                    amount.toString(),
                  ]);
                }
              }
            }
            for (let i = createOldPay.length - 1; i >= 0; i--) {
              payData.push(createOldPay[i]);
            }
            
            aozoraPayment.push({
              id: company.id,
              pay: payData,
            });
          }
        } catch (e) {
          console.error(e);
        }
      }
      return aozoraPayment;
    },
    getTodayId: function () {
      // 日付文字列
      let dateStr = this.today;

      if (dateStr == null) return null;

      // 日付文字列をDateオブジェクトに変換
      let date = new Date(dateStr);

      // 10ヶ月前の日付を計算
      date.setMonth(date.getMonth() - 13);

      // 結果の月と日を取得（0埋めで2桁にする）
      let YY = String(date.getFullYear()).slice(-2); // 年の後ろ2桁を取得
      let MM = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() は0から11までの値を返すので +1 が必要

      // MMDD形式の文字列を作成
      let id = YY + MM;
      return parseInt(id);
    },
    changeDay(dateStr, newDay) {
      // Check if dateStr is in the correct format
      if (!/^\d{8}$/.test(dateStr)) {
          throw new Error('Invalid date format. Expected YYYYMMDD.');
      }

      // Extract the year, month, and day parts
      let year = dateStr.substring(0, 4);
      let month = dateStr.substring(4, 6);
      let day = dateStr.substring(6, 8);

      // Ensure newDay is a valid two-digit number
      newDay = newDay.toString().padStart(2, '0');

      // Construct the new date string
      let newDateStr = year + month + newDay;

      return newDateStr;
    } 
  },
};
