<template>
  <div class="margin-bottom">
    <div class="info-box">
      <b-container class="main">
        <b-row class="info-title">
          ソフトウェア利用規約
        </b-row>
        <b-row class="info-content form-control overflow-auto " readonly>
          <b-col style="padding:0;">
            本ソフトウェア利用規約（以下「本規約」といいます。）は、ダイナステージ株式会社（以下「弊社」といいます。）が提供する本ソフトウェアおよび本サービス（無料トライアル期間を含みます。）をご利用いただく全てのお客様（個人・法人を問いません。）に適用されます。本ソフトウェアをご利用いただく前に、本規約をお読みください。本ソフトウェア又は本サービスの利用を開始することによって、お客様が本規約に同意したものとします。<br />
            <br />
            第１条（定義）<br />
            本規約で使用する用語の定義は、次の各号に定めるとおりとします。
            <table>
              <tr>
                <td>（１）</td>
                <td>
                  「本ソフトウェア」とは、弊社がお客様に対し使用を許諾するソフトウェアプログラムをいいます。
                </td>
              </tr>
              <tr>
                <td>（２）</td>
                <td>
                  「本サービス」とは、弊社がALLY-PROの名称で又はこれらに関連してお客様に提供するウェブサイト、ソフトウェア、アプリケーション、ドキュメントその他一切の製品およびサービスをいいます。
                </td>
              </tr>
              <tr>
                <td>（３）</td>
                <td>「ライセンス」とは、本ソフトウェアを使用する権利をいいます。</td>
              </tr>
            </table>

            <br />
            第２条（権利帰属）<br />
            本ソフトウェアおよび関連するドキュメントの著作権を含む一切の知的財産権および所有権は弊社に帰属します。本ソフトウェアの使用許諾により、著作権等がお客様に移転するものではありません。<br />
            <br />
            第３条（目的）<br />
            お客様は、お客様自身の生産性の向上を目的として本ソフトウェアを利用し、弊社はこれを提供します。<br />
            <br />
            第４条（利用契約）
            <table>
              <tr>
                <td>1.</td>
                <td>
                  本ソフトウェアの利用契約は、お客様が本規約に同意の上、本ソフトウェア上にて又は弊社の指定する方法で利用申込みを行い、弊社が申込みを承諾した時点で成立するものとします。
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>
                  弊社は、次のいずれかに該当する場合は、利用申込みを承諾しない又は承諾を取り消すことがあります。
                  <table>
                    <tr>
                      <td>（１）</td>
                      <td>お客様が虚偽の申告をしたとき</td>
                    </tr>
                    <tr>
                      <td>（２）</td>
                      <td>
                        お客様が本ソフトウェアを含む弊社の提供するサービスの使用料の支払いを怠り又は怠るおそれのあるとき
                      </td>
                    </tr>
                    <tr>
                      <td>（３）</td>
                      <td>弊社の業務遂行上著しい支障があるとき</td>
                    </tr>
                    <tr>
                      <td>（４）</td>
                      <td>その他弊社が不適当と判断したとき</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <br />
            第５条（IDおよびパスワードの管理）
            <table>
              <tr>
                <td>1.</td>
                <td>
                  お客様が本ソフトウェアの利用申込み時に設定した任意のIDとパスワードについては、お客様自身がその管理責任を負うものとします。
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>
                  お客様は、IDおよびパスワードを第三者に利用させる行為、貸与、譲渡、名義変更、売買、質入れ等を行ってはならないものとします。
                </td>
              </tr>
              <tr>
                <td>3.</td>
                <td>
                  IDおよびパスワードの管理不十分、使用上の過誤、第三者の使用等による損害の責任はお客様自身が負うものとし、弊社は一切責任を負わないものとします。
                </td>
              </tr>
              <tr>
                <td>4.</td>
                <td>
                  お客様は、IDおよびパスワードの盗難又は第三者に使用されていることが判明した場合、直ちにその旨を弊社に連絡し、弊社の指示に従うものとします。
                </td>
              </tr>
            </table>

            <br />
            第６条（使用料）
            <table>
              <tr>
                <td>1.</td>
                <td>
                  お客様は、別紙ALLY-PRO料金表記載のとおりの使用料を支払うものとします。ただし、本ソフトウェアの無料トライアル期間については、この限りではありません。
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>
                  お客様は、申込みの日（無料トライアル期間付きの製品については、無料トライアル終了日の翌日）より1ヶ月分の使用料を、弊社が指定する日までに、弊社が指定する方法にて、弊社に支払うものとし、以降本契約が終了するまで同様とします。なお、振込手数料その他支払いに際して要する費用は、原則としてお客様が負担するものとします。
                </td>
              </tr>
              <tr>
                <td>3.</td>
                <td>
                  お客様は、本ソフトウェアの使用料その他利用規約上の債務について、指定期日を過ぎてもなお履行しない場合には、指定期日の翌日から支払の日までの日数について、年１４．６％の割合で計算して得た額を遅延損害金として弊社に支払うものとします。なお、遅延損害金の振込手数料その他支払いに際して要する費用は、お客様が負担するものとします。
                </td>
              </tr>
              <tr>
                <td>4.</td>
                <td>
                  弊社は、弊社が必要と認める場合、予告なく使用料を変更することができるものとします。
                </td>
              </tr>
            </table>

            <br />
            第７条（使用許諾）
            <table>
              <tr>
                <td>1.</td>
                <td>
                  弊社は、お客様が本規約の約定を遵守し、弊社が別に定める使用料を支払うことを条件に、本ソフトウェアを非独占的に使用することを許諾します。
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>
                  本ソフトウェアは、お客様自身およびその管理下にある第三者が、第３条に定める目的においてのみ利用することができます。
                </td>
              </tr>
            </table>

            <br />
            第８条（禁止事項）<br />
            お客様は、本ソフトウェアを利用するにあたり、次の各号に定める行為を行ってはなりません。<br />
            <table>
              <tr>
                <td>（１）</td>
                <td>本規約その他弊社が定める事項に反する行為</td>
              </tr>
              <tr>
                <td>（２）</td>
                <td>
                  ウイルス等の有害なコンピュータープログラムを弊社又は第三者に送信する行為
                </td>
              </tr>
              <tr>
                <td>（３）</td>
                <td>
                  本ソフトウェアでリバースエンジニアリング、逆コンパイル、逆アセンブルを行うことその他これに準ずる行為
                </td>
              </tr>
              <tr>
                <td>（４）</td>
                <td>
                  本ソフトウェアと同種又は類似する内容のソフトウェアを製作、開発および販売する行為
                </td>
              </tr>
              <tr>
                <td>（５）</td>
                <td>
                  弊社に許諾を得ずに行う本ソフトウェアの改変、翻案、複製、第三者への譲渡又は貸与、再使用権および担保権の設定その他弊社の権利を侵害する行為
                </td>
              </tr>
              <tr>
                <td>（６）</td>
                <td>
                  本ソフトウェアの利用により得られた情報の正確性、有用性、適切性および違法性等について第三者に誤認させる行為
                </td>
              </tr>
              <tr>
                <td>（７）</td>
                <td>
                  虚偽情報、事実誤認を生じさせる情報等を提供、告知、掲載および配信する行為
                </td>
              </tr>
              <tr>
                <td>（８）</td>
                <td>法律、命令、処分その他規制に反する行為</td>
              </tr>
              <tr>
                <td>（９）</td>
                <td>犯罪行為を惹起又は助長する行為その他犯罪行為に結び付く行為</td>
              </tr>
            </table>

            <table>
              <tr>
                <td>（１０）</td>
                <td>
                  本ソフトウェアのライセンスを第三者に継承し、あるいは利用規約から生じる権利義務の全部又は一部を第三者に譲渡又は貸与若しくは引き受けさせる行為
                </td>
              </tr>
              <tr>
                <td>（１１）</td>
                <td>
                  第三者が有する著作権、商標権、肖像権、プライバシーその他の権利利益を侵害する行為
                </td>
              </tr>
              <tr>
                <td>（１２）</td>
                <td>弊社および第三者を誹謗中傷し又はその名誉信用を害する行為</td>
              </tr>
              <tr>
                <td>（１３）</td>
                <td>その他公序良俗に反する行為</td>
              </tr>
              <tr>
                <td>（１４）</td>
                <td>前各号の他、方法のいかんを問わず弊社の運営を妨害する行為</td>
              </tr>
            </table>

            <br />
            第９条（責任範囲）
            <table>
              <tr>
                <td>1.</td>
                <td>
                  弊社は、本ソフトウェア製品がお客様の保有する動作環境において、正常に動作することを保証するものではありません。
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>
                  弊社は、本ソフトウェア製品の仕様を予告なしに変更することがあり、本ソフトウェア製品の機能、性能および品質がお客様の特定目的に適合することを何らの保証もなさないものとします。
                </td>
              </tr>
              <tr>
                <td>3.</td>
                <td>本ソフトウェアの提供は、弊社の判断により中止又は中断する場合があります。</td>
              </tr>
              <tr>
                <td>4.</td>
                <td>
                  お客様が本ソフトウェアの利用により、ハードウェア機器若しくはデータ等に支障が生じた場合でも、弊社は一切その責任を負いません。
                </td>
              </tr>
              <tr>
                <td>5.</td>
                <td>
                  上記の他、本ソフトウェアの利用に関して、お客様又は第三者に何らかの損害が生じた場合でも、弊社は一切その責任を負いません。
                </td>
              </tr>
              <tr>
                <td>6.</td>
                <td>前２項において、弊社に帰責事由がある場合は、この限りではありません。</td>
              </tr>
            </table>

            <br />
            第１０条（使用期間）
            <table>
              <tr>
                <td>1.</td>
                <td>
                  本ソフトウェアの使用期間は、別紙のとおりとします。なお、期間満了日までにお客様又は弊社から別段の意思表示がない場合、更に満1ヶ月本契約を自動更新するものとし、以降同様とします。ただし、利用契約の際に、弊社が別に指定するものについては、この限りではありません。
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>
                  本契約が解約、解除その他の事由により終了した場合、お客様は直ちに本ソフトウェアを弊社に返還し又は破棄若しくは削除しなければなりません。
                </td>
              </tr>
            </table>

            <br />
            第１１条（解約）
            <table>
              <tr>
                <td>1.</td>
                <td>
                  お客様は、本ソフトウェア上又は弊社が指定する方法で解約手続きを行うことにより、本契約を終了することができます。
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>
                  本ソフトウェアのライセンスは、解約日より失効します。なお、解約手続きの時点で発生している利用料金の日割り計算による返金はありません。
                </td>
              </tr>
              <tr>
                <td>3.</td>
                <td>
                  解約の取り消しはできません。解約後に再度ご利用いただく場合は、ライセンスの新規購入手続きが必要です。
                </td>
              </tr>
            </table>

            <br />
            第１２条（契約解除）
            <table>
              <tr>
                <td>1.</td>
                <td>
                  弊社又は弊社が指定した者は、以下の各号に該当すると判断した場合、お客様に対し何らの通知および催告を行うことなく直ちにサービスの利用停止、アカウントの削除又は契約を解除することができます。
                  <table>
                    <tr>
                      <td>（１）</td>
                      <td>お客様が、第８条各号の禁止行為を行った場合</td>
                    </tr>
                    <tr>
                      <td>（２）</td>
                      <td>弊社が、本サービスの保守管理上必要であると判断した場合</td>
                    </tr>
                    <tr>
                      <td>（３）</td>
                      <td>その他、弊社が必要であると判断した場合</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>
                  前項において、弊社は、お客様によって被った損害を、お客様に請求することができます。
                </td>
              </tr>
              <tr>
                <td>3.</td>
                <td>
                  第１項において、アカウントの削除又は契約の解除がなされた場合、それまでに本ソフトウェア上で保存したデータ等は全て削除されます。なお、削除されたデータを復元することはできません。
                </td>
              </tr>
            </table>

            <br />
            第１３条（利用制限）<br />
            弊社は、天災、事変その他の非常事態が発生し又は発生するおそれのあるときは、お客様に予告なく、本サービスの提供の全部又は一部を中止する措置を取ることができます。<br />
            <br />
            第１４条（規約変更）<br />
            弊社は、弊社が必要と判断した場合、本サービスの目的の範囲内で、本規約を変更することができます。なお、弊社は、変更後の本規約の内容および効力の発生日を本サービス若しくは弊社ウェブサイトに表示又は弊社が定める方法により、お客様に通知することで、お客様に周知するものとします。<br />
            <br />
            第１５条（協議）<br />
            本規約に関して、規定のない事項又は疑義を生じた場合は、お客様と弊社の間で誠意をもって協議し、これを解決するものとします。<br />
            <br />
            第１６条（裁判管轄）<br />
            本規約の準拠法は日本法とし、本サービスに起因又は関連して、お客様と弊社の間に生じた紛争については、盛岡地方裁判所を第一審の専属的管轄裁判所とします。<br />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'info',
    data: function() {
      return {
        space: '　',
      };
    },
  };
</script>
<style scoped>
  .margin-bottom {
    margin-bottom: 15px;
  }
  .info-box {
    background-color: rgba(43, 160, 132, 0.2);
  }
  .info-title {
    font-weight: bold;
    padding-top: 5px;
    margin-left: -5px;
  }
  .info-content {
    width: calc(30px + 100%);
    height: 35rem;
    font-size: 14px;
  }
  .info-content table td:first-child {
    display: flex;
    white-space: nowrap;
  }
</style>
