<template>
  <CommonLayout title="最後に">
    <div style="font-size: 100px; padding: 0px 120px;">
      <br />
      <br />
      <div>
        不動産に関わる税制・活用法は、とても複雑なものとなります。情報・知識の有無によって、ご所有の資産を大きく<br />増やしたり、逆に減らしたりしてしまうことになりかねません。
      </div>
      <div style="margin-top: 20px;">
        <br />この診断レポートでわかるように、同じ土地であってもどのような活用をするかで「生み出される収入」、<br />「毎年かかる税金」、「相続時の減税効果」などが変わってきます。
      </div>
      <div style="margin-top: 20px;">
        その土地が持つ「収益性」と「減税効果」を主軸に検証を行いましたが、他にも「相続時分割対策」、「二次相続対策」、<br />「生命保険による納税対策」、「最適な売却時期」など総合的な検証が大切です。
      </div>
      <div style="margin-top: 20px;">
        <br />また、売却の場合は「納税の資金確保」の視点にとどまらず、相続時に減税効果がどれ程得られるかなど、<br />別の視点からも検証し売却せずに所有したままの場合と比較が必要です。
      </div>
      <div style="margin-top: 20px;">
        {{
          (commonInput.customerName || '*********') +
            '様の不動産最適化のお力になれるよう全力でサポートさせていただきますので、ご質問等がございましたらお気軽にご連絡いただければと存じます。'
        }}
      </div>
      <div style="margin-top: 20px;">
        <br />この度は貴重な機会をいただき誠にありがとうございました。
      </div>
    </div>
    
  </CommonLayout>
</template>

<script>
  import Logo from '@/assets/report/reportImages/logo.png';
  import CommonLayout from '@/components/ExportReport/CommonLayout';

  export default {
    props: {
      commonInput: Object,
      companyInfo: Array,
    },
    data() {
      return {
        logo: Logo,
      };
    },
    computed: {
      companyName() {
        if (0 in this.companyInfo) {
          return this.companyInfo[0][1];
        }
        return '';
      },
    },
    components: {
      CommonLayout,
    },
  };
</script>
