// @ts-check

import { Rect } from './math/rect';
import { Vec } from './math/vector';
import { meterToPx, getClosestPoint, getMostSimilarLine, round } from './util';
import { AR_BASE_OFFSET } from './const';
import { InteriorMasterDataForPlaneView } from '../state/interiorMaster';
import { createNode } from '../node/node/pvNode';
import { PvFloor } from '../node/node/floorNode';
import { PvDoor } from '../node/node/doorNode';
import { PvStairs } from '../node/node/stairsNode';
import { PvObject } from '../node/node/pvObject';
import { PvAdditionalInfo } from '../node/tools/additionalInfo';
import { Transform } from './math/transform';
import { cloneNode } from '../node/tools/clone';
import { IconDataFetcher } from '../fetch/icon';

/**
 * @typedef {{x: number, y: number, z: number}} IPosition
 * @typedef {{linePosition: IPosition, curvePosition: IPosition}} PositionSet
 *
 * @typedef {{
 *   type: number,
 *   height: number,
 *   measurements: PositionSet[]
 * }} IMeasureType
 *
 * @typedef {{
 *   code: number,
 *   setNumber: number,
 *   additionalInfo: PvAdditionalInfo,
 *   measureTypes: IMeasureType[]
 * }} IInterior
 *
 * @typedef {{
 *   interiors: IInterior[],
 *   azimuth: number
 * }} ArJson
 */
/**
 *  CLASS: 計測データクラス
 */
export class ArMeasure {
  /** @type {Number} */
  _azimuth;
  /** @type {Interior[]} */
  _interiors;
  /** @type {PvObject[]} */
  _svgObjects = [];
  /** @type {Array} */
  fixtureMaster = [];
  /** @type {Array} */
  icons = [];
  presets;
  constructor({ presets }) {
    this.presets = presets;
  }

  /**
   *  FUNC: オブジェクトを取り出し配列を空にする
   * @returns {PvObject[]}
   */
  takeSvgObject() {
    const res = this._svgObjects;
    this._svgObjects = [];
    this._interiors = [];
    return res;
  }

  getAzimuth() {
    return this._azimuth;
  }

  /**
   *  FUNC: load
   * @param {ArJson} json
   * @param {InteriorMasterDataForPlaneView[]} interiorMasterDataForPlaneView
   * @param {number?} adjAzimuth
   */
  async load(
    json,
    std = new Vec(AR_BASE_OFFSET.X, AR_BASE_OFFSET.Y),
    interiorMasterDataForPlaneView,
    adjAzimuth
  ) {
    try {
      this._azimuth = adjAzimuth ?? json?.azimuth ?? 0;

      // 部屋の傾き取得、すでに方位角が設定されていればその角度に合わせる
      let angle =
        adjAzimuth == null
          ? this._getAngle(json)
          : adjAzimuth - (((json?.azimuth ?? 0) + 180) % 360);

      this._interiors = json.interiors.map(inte => {
        return new Interior(inte);
      });

      // オフセット
      const offset = this._interiors.find(v => v.code === 0)?.getRect().position;

      if (offset == null) {
        throw new Error('');
      }
      // 角度補正
      this._interiors.forEach(inte => {
        return inte.rotatePosition(angle + 180, offset);
      });
      // すでに方位角が設定されていればその値を使う、そうでなければ角度補正分を加算する
      this._azimuth = adjAzimuth ?? this._GetNormalizedAngle180(this._azimuth + angle + 180);

      const offsetter = this._interiors.find(v => v.code === 0)?.getRect().position;
      if (offsetter == null) {
        throw new Error('');
      }

      // offsetに合わせて平行移動
      this._interiors.forEach(inte => {
        return inte.shiftPosition(offsetter.reverse().add(std));
      });

      await this._createObjects(interiorMasterDataForPlaneView);
    } catch (error) {
      console.error(error);
      alert('createObject' + JSON.stringify(error));
    }
  }

  /**
   * FUNC: _±180度に正規化
   * @param {number} angle
   * @returns {number}
   */
  _GetNormalizedAngle180(angle) {
    angle = ((angle + 180) % 360) - 180;
    if (angle < -180) angle += 360;
    return angle;
  }

  /**
   *  FUNC: clear
   */
  clear() {
    this._svgObjects = [];
  }

  /**
   *  FUNC: _部屋の傾き取得
   * @param {ArJson} json
   * @returns {number}
   */
  _getAngle(json) {
    const floor = json.interiors[0];
    const measurements = floor.measureTypes[0].measurements;
    const vec1 = new Vec(measurements[0].linePosition.x, measurements[0].linePosition.z);
    const vec2 = new Vec(measurements[1].linePosition.x, measurements[1].linePosition.z);
    const vec3 = vec1.sub(vec2);
    const unit = new Vec(1, 0);
    const a = unit.angle(vec3) ?? 0;
    const angle = ((a / Math.PI) * 180 + 360) % 360;
    return angle;
  }

  /**
   * FUNC: _左回りかどうか判定する（VRからの流用）
   * @param {Vec[]} points
   * @returns {boolean}
   */
  _isLeftTurn(points) {
    if (points.length < 3) return false;
    let angle = 0;
    let lastVec = new Vec(0, 0);
    points.forEach((point, index, arry) => {
      let next = 0;
      if (index == arry.length - 1) {
        next = 0;
      } else {
        next = index + 1;
      }
      // 現在点から次の点へのベクトルを求める
      const vector = arry[next].sub(point);
      // 角度を計算する
      if (index != 0) angle += lastVec.angle(vector) ?? 0;
      lastVec = vector;
    });
    // console.log(`isLeftTurn angle=${angle}`);
    if (angle > 0) return false;
    if (angle < 0) return true;
    return false;
  }

  /**
   *  FUNC: _オブジェクト作成
   * @param {InteriorMasterDataForPlaneView[]} interiorMasterDataForPlaneView
   */
  async _createObjects(interiorMasterDataForPlaneView) {
    let floor;
    let floorObj;
    let floorOffset;
    let allOffset;
    for (const interior of this._interiors) {
      if (interior.code === 0) {
        // 床
        floor = interior;
        const rect = floor.getRect();
        allOffset = rect.position;
        floorObj = cloneNode(this.presets.floor);
        floorObj.code = interior.code;
        const measureType = floor.measureTypes[0];
        let linePositions = measureType.measurements.map(m => m.linePosition);
        let curvePositions = measureType.measurements.map(m => m.curvePosition);
        // 床のデータが左回りの時は右回りに変換する
        if (this._isLeftTurn(linePositions)) {
          linePositions = linePositions.reverse();
          curvePositions = curvePositions.reverse();
          // 反転させると、curvePositionの位置が一つずつずれるため補正する
          let top = curvePositions.shift() ?? new Vec(0, 0);
          curvePositions.push(top);
        }
        floorObj.vertexes = linePositions.map(lp => lp.sub(allOffset).round(3));

        floorObj.arcPoints = curvePositions.map((cp, i) => {
          const v1 = linePositions[i];
          const v2 = linePositions[(i + 1) % linePositions.length];
          const edgeVec = v2.sub(v1);
          const center = edgeVec.scale(1 / 2).add(v1);
          const distFromCenter = cp.sub(center).round(3);
          // ラインの中点とカーブポイントの角度を比較する（ラインの開始点を中心とした角度）
          const angle = cp.sub(v1).angle(center.sub(v1)) ?? 0;
          // 角度がマイナスだったら引っ込める
          return distFromCenter.absVal() * (angle < 0 ? -1 : 1);
        });

        floorObj.appendChild(floorObj.getTextNode()); // TEXTノード
        floorObj.updateAreaSize();

        floorObj.style.fill = 'white';
        floorObj.moveBy(rect.position);

        this._svgObjects.push(floorObj);

        floorOffset = floorObj.transform.translate.clone();
        // floorObj.setTextTranslate(floorObj.offset());
        // floorObj.initWalls();
      } else if (1001 <= interior.code && interior.code < 2001) {
        // 床
        const rect = interior.getRect();
        // const svgObject = new PvFloor();
        const svgObject = createNode(PvFloor);
        svgObject.code = interior.code;
        svgObject.additionalInfo = interior.additionalInfo;
        // @ts-ignore
        svgObject.vertexes = floor.measureTypes[0].measurements.map(m =>
          m.linePosition.sub(allOffset)
        );
        svgObject.appendChild(svgObject.getTextNode()); // TEXTノード
        svgObject.arcPoints = svgObject.vertexes.map(() => 0);
        svgObject.style.fill = 'white';
        svgObject.moveBy(rect.position);
        this._svgObjects.push(svgObject);
      } else if (2001 <= interior.code && interior.code < 4001) {
        // ドア、窓
        const vertexes = interior.measureTypes[0].measurements.map(m => m.linePosition);
        const start = vertexes[2];
        const end = vertexes[1];
        const baseVec = end.sub(start).round(3);
        // const door = new PvDoor();
        const door = createNode(PvDoor);
        door.code = interior.code;
        door.additionalInfo = interior.additionalInfo;
        door.vertexes = [new Vec(), baseVec];
        let rad = Math.atan2(
          door.vertexes[1].y - door.vertexes[0].y,
          door.vertexes[1].x - door.vertexes[0].x
        );

        // @ts-ignore
        const record = interiorMasterDataForPlaneView.find(d => d.code === interior.code);
        // @ts-ignore
        door.subType = record.subType;
        door.transform = new Transform({
          translate: start,
        });
        door.height3d = interior.measureTypes[0].height;
        door.mountingHeight = interior.measureTypes[0].mountingHeight;
        door.moveBy(new Vec(-floorOffset.x, -floorOffset.y));

        // 二点を結ぶ直線と最も近い直線を作る二点の座標を取得する
        const mostSimilarLine = getMostSimilarLine(floorObj.vertexes, {
          startPoint: door.transform.translate.add(door.vertexes[0]),
          endPoint: door.transform.translate.add(door.vertexes[1]),
        });

        // ドア・窓と壁の向きが逆の場合を計算する
        let angle =
          mostSimilarLine.startPoint
            .sub(mostSimilarLine.endPoint)
            .angle(door.vertexes[0].sub(door.vertexes[1])) ?? 0;

        // 座標の補正
        // 床ライン上の、建具の開始座標に一番近い座標に移動
        door.moveVertexBy(0, new Vec(0, 0));

        // 座標[1]補正
        // ドア・窓と壁の向きが逆の場合は入れ替えフラグを立てる
        const changeFlag = Math.abs(angle) > Math.PI / 2;
        // 床ラインの開始座標
        const lineStartVec = changeFlag ? mostSimilarLine.endPoint : mostSimilarLine.startPoint;
        // 床ラインの終了座標
        const lineEndVec = changeFlag ? mostSimilarLine.startPoint : mostSimilarLine.endPoint;
        // // 床ライン開始座標から建具の開始座標までの距離
        // const moveLength = lineStartVec.getSideLength(door.vertexes[0]);
        // // 床ライン上の、建具の終了座標に一番近い座標を取得
        // door.moveVertexBy(
        //   1,
        //   getMovePoint({ startPoint: lineStartVec, endPoint: lineEndVec }, moveLength) ??
        //     door.vertexes[1]
        // );

        // 角度補正
        // 床ラインの角度に合わせる
        door.transform.rotate = Math.atan2(
          lineEndVec.y - lineStartVec.y,
          lineEndVec.x - lineStartVec.x
        );
        // 基準補正
        // 床ライン上の、基準座標に一番近い座標を取得
        door.transform.translate = getClosestPoint(door.transform.translate, {
          startPoint: mostSimilarLine.startPoint,
          endPoint: mostSimilarLine.endPoint,
        }).round(3);

        // 床オブジェクトに建具を追加
        floorObj.appendChild(door);
        floorObj.children.filter(node => node.id == door.id)[0].reflectAdditionalInfoToStyle();
      } else if (4001 <= interior.code && interior.code < 5001) {
        // 階段
        const vecStack = [];
        let prevPos = new Vec();
        for (let i = 0; i < interior.measureTypes.length; i++) {
          if (interior.measureTypes[i].type !== 1) {
            continue;
          }
          // 開始点を追加
          const start = interior.measureTypes[i].measurements[0].linePosition;

          vecStack.push(start.sub(interior.measureTypes[0].measurements[0].linePosition).round(3));
          // 最後の階段場合、終点を追加
          if (i == interior.measureTypes.length - 1) {
            const end = interior.measureTypes[i].measurements[3].linePosition;
            vecStack.push(end.sub(interior.measureTypes[0].measurements[0].linePosition).round(3));
          }
        }
        // 階段ノード生成
        if (interior.code == 4301 || interior.code == 4302) {
          const repo = new IconDataFetcher();
          repo.listIcons().then(response => {
            // 螺旋階段はアイコンを読み込んでから生成する
            const stairs = createNode(PvStairs);
            const a = response.find(value => value.code == 4300);
            // @ts-ignore
            stairs.style.imageUrl = a.url; // 螺旋階段に画像のurlを持たせる
            // 階段のコードを取得
            stairs.code = interior.code;

            // 回転の中心位置
            const p0 = interior.measureTypes[0].measurements[0].linePosition;
            // 階段の端
            const p1 = interior.measureTypes[0].measurements[1].linePosition;
            // 基準ベクトル（右回りの場合は左方向、左回りの場合は右方向）
            const ref = interior.code == 4301 ? new Vec(-1, 0) : new Vec(1, 0);
            // 基準ベクトルと、中心から端までのベクトルとの回転を求める（全体の回転）
            const angle = ref.angle(p1.sub(p0)) ?? 0;
            // s1:上端、s2:左上を計算する（螺旋階段の原点は左上なので）
            const s1 = p1.rotate(interior.code == 4301 ? 90 : -90, p0);
            const s2 = p0.rotate(90, s1);

            // 階段の幅を取得
            const firstStair = interior.measureTypes[0].measurements;
            const width = firstStair[0].linePosition.getSideLength(firstStair[1].linePosition);
            stairs.style.patternWidth = width;
            stairs.width = width;
            // 階段一段分の高さ
            stairs.height = interior.measureTypes[0].mountingHeight;
            // 階段全体の高さ
            stairs.height3d = 0;
            interior.measureTypes.forEach(measureType => {
              stairs.height3d += measureType.height;
            });
            // 階段の合計段数
            stairs.additionalInfo = interior.additionalInfo;
            stairs.additionalInfo.steps = Math.round(stairs.height3d / stairs.height);
            stairs.additionalInfo.stepAngle = 15;
            stairs.additionalInfo.stepHeight = stairs.height;

            // 左上の位置に移動する
            stairs.moveBy(new Vec(-floorOffset.x, -floorOffset.y).add(s2));
            // 全体の回転を掛ける
            stairs.transform.rotate = angle;
            floorObj.appendChild(stairs);
          });
          continue;
        }
        const stairs = createNode(PvStairs);
        // 階段のコードを取得
        stairs.code = interior.code;
        // 階段の座標を取得
        stairs.vertexes = vecStack;
        // 座標が３点以上かチェック
        if (stairs.vertexes.length >= 3) {
          // 階段の回り方向を取得
          const line = stairs.vertexes[1].sub(stairs.vertexes[0]);
          const isLeft = line.isLeft(stairs.vertexes[2]);
          stairs.style.flipHorizontal = isLeft;
        }

        // 階段の幅を取得
        const firstStair = interior.measureTypes[0].measurements;
        const width = round(
          firstStair[0].linePosition.getSideLength(firstStair[1].linePosition),
          2
        );
        stairs.style.patternWidth = width;
        stairs.width = width;
        // 階段一段分の高さ
        stairs.height = round(interior.measureTypes[0].mountingHeight, 2);
        // 階段全体の高さ
        stairs.height3d = 0;
        interior.measureTypes.forEach(measureType => {
          stairs.height3d += measureType.height;
        });
        // 階段の合計段数
        stairs.additionalInfo = interior.additionalInfo;
        stairs.additionalInfo.steps = Math.round(stairs.height3d / stairs.height);
        stairs.additionalInfo.stepAngle = 15;
        stairs.additionalInfo.stepHeight = stairs.height;

        stairs.moveBy(
          new Vec(-floorOffset.x, -floorOffset.y).add(firstStair[0].linePosition).round(2)
        );
        floorObj.appendChild(stairs);
      }
    }
  }

  /**
   * FUNC: アイコンUrl取得
   * @param {number} code
   * @returns {string}
   */
  getIconUrl(code) {
    const record = this.fixtureMaster.find(v => v.code === code);
    const url = this.icons.find(v => v.name === record.name).url;
    return url;
  }

  /**
   * FUNC: _矩形取得
   */
  _getRect() {
    /** @type {Vec[]} */
    // @ts-ignore
    const vecList = this._interiors.reduce((prev, current) => {
      return [...prev, ...current.measureTypes];
    }, []);
    const rect = Rect.fromVecs(vecList);
    return rect;
  }

  /**
   * FUNC: _位置移動
   * @param {Vec} dv
   */
  _shiftPosition(dv) {
    for (const inte of this._interiors) {
      inte.shiftPosition(dv);
    }
  }

  /**
   * FUNC: _位置回転
   * @param {number} deg
   * @param {Vec} origin
   */
  _rotatePosition(deg, origin) {
    for (const inte of this._interiors) {
      inte.rotatePosition(deg, origin);
    }
  }
}

export class Interior {
  /** @type {number}  PROPERTY: 建具種別 */
  code;
  /** @type {number} */
  setNumber;
  /** @type {PvAdditionalInfo}  PROPERTY: 建具追加情報 */
  additionalInfo;
  /** @type {MeasureType[]} */
  measureTypes = [];
  constructor(jsonInterior) {
    this.code = jsonInterior.code;
    this.setNumber = jsonInterior.setNumber;
    this.additionalInfo = new PvAdditionalInfo(jsonInterior.additionalInfo);
    this.measureTypes = jsonInterior.measureTypes.map(mt => {
      return new MeasureType(this.code, mt);
    });
  }

  /**
   * FUNC: 矩形取得
   * @returns {Rect}
   */
  getRect() {
    /** @type {Vec[]} */
    // @ts-ignore
    const vecList = this.measureTypes.reduce((prev, current) => {
      return [...prev, ...current.getRect().toVecs()];
    }, []);
    const rect = Rect.fromVecs(vecList);
    return rect;
  }

  /**
   * FUNC: 位置移動
   * @param {Vec} dv
   */
  shiftPosition(dv) {
    for (const mt of this.measureTypes) {
      mt.shift(dv);
    }
  }

  /**
   * FUNC: 位置回転
   * @param {number} deg
   * @param {Vec} origin
   */
  rotatePosition(deg, origin) {
    for (const mt of this.measureTypes) {
      mt.rotate(deg, origin);
    }
  }
}

/**
 * @typedef {{ linePosition: Vec, curvePosition: Vec }} VecSet
 */

export class MeasureType {
  /** @type {number} */
  type;
  /** @type {number} 高さ */
  height;
  /** @type {number} 取付高さ */
  mountingHeight;
  /** @type {VecSet[]} */
  measurements = [];
  /**
   *
   * @param {number} code
   * @param {object} measureType
   */
  constructor(code, measureType) {
    this.type = measureType.type;
    // 階段のデータかチェック
    const isStairs = 4001 <= code && code < 5001;
    // 階段のデータかどうかで、高さの取得処理を変更
    this.height = isStairs
      ? measureType.measurements[2].linePosition.y - measureType.measurements[1].linePosition.y
      : Math.abs(
          measureType.measurements[1].linePosition.y - measureType.measurements[0].linePosition.y
        );
    // 取付高さ（階段の場合、measureType[0]の値は一段あたりの高さ）
    this.mountingHeight = measureType.height;

    this.measurements = measureType.measurements.map(m => {
      return {
        linePosition: new Vec(meterToPx(m.linePosition.x), -meterToPx(m.linePosition.z)),
        curvePosition: new Vec(meterToPx(m.curvePosition.x), -meterToPx(m.curvePosition.z)),
      };
    });
  }

  /**
   * FUNC: 矩形取得
   * @returns {Rect}
   */
  getRect() {
    const rect = Rect.fromVecs(this.measurements.map(m => m.linePosition));
    return rect;
  }

  /**
   * FUNC: 移動
   * @param {Vec} dv
   */
  shift(dv) {
    for (const mm of this.measurements) {
      const { linePosition, curvePosition } = mm;
      linePosition.x = linePosition.x + dv.x;
      linePosition.y = linePosition.y + dv.y;
      curvePosition.x = curvePosition.x + dv.x;
      curvePosition.y = curvePosition.y + dv.y;
    }
  }

  /**
   * FUNC: 回転
   * @param {number} deg
   * @param {Vec} origin
   */
  rotate(deg, origin) {
    /** @type {VecSet[]} */
    const newMeasurements = [];
    for (const mm of this.measurements) {
      const { linePosition, curvePosition } = mm;
      const rotateVec1 = linePosition.rotate(deg, origin);
      const rotateVec2 = curvePosition.rotate(deg, origin);
      linePosition.x = rotateVec1.x;
      linePosition.y = rotateVec1.y;

      curvePosition.x = rotateVec2.x;
      curvePosition.y = rotateVec2.y;
      newMeasurements.push({
        linePosition,
        curvePosition,
      });
    }
    this.measurements = newMeasurements;
  }
}
