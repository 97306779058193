<template>
  <CommonLayout title="ご挨拶">
    <template v-slot>
      <span style="font-weight: bold; font-size: 125px;">{{
        (commonInput.customerName || '********') + ' 様'
      }}</span>
      <div
        style="
                    font-size: 120px;
                    padding: 0 120px;
                "
      >
        <br /><br />
        この度は、不動産の活用方法についてご相談をいただき、誠にありがとうございます。
        <br /><br />
        このレポートは、不動産の潜在的な価値を見出し、<br />お客様の不動産運用をサポートするためのものです。
        <br /><br />
        {{ `お客様が所有する不動産のより良いご活用の手助けとなれば幸いでございます。` }}<br /><br />
        {{
          `${commonInput.customerName || '********'}様の今後益々のご発展を祈念いたしております。`
        }}
      </div>
    </template>
  </CommonLayout>
</template>
<script>
  import CommonLayout from '@/components/ExportReport/CommonLayout';

  export default {
    props: {
      commonInput: {
        type: Object,
        required: true,
      },
      companyInfo: {
        type: Array,
        required: true,
      },
    },
    components: {
      CommonLayout,
    },
    data() {
      return {};
    },
    computed: {
      companyName() {
        if (0 in this.companyInfo) {
          return this.companyInfo[0][1];
        }

        return '';
      },
    },
  };
</script>
