<template>
  <div
    :class="{
      'scrollbar-base': true,
      transparent: transparent || dragging,
    }"
    style="background-color: white;"
    :style="{
      width: horizontal ? length + 'px' : THUMB_WIDTH + 'px',
      height: horizontal ? THUMB_WIDTH + 'px' : length + 'px',
    }"
  >
    <!-- divだとドラッグできないことがある ブラウザによる？ -->
    <button
      :style="{
        position: 'absolute',
        right: 0,
        width: horizontal ? THUMB_HEIGHT + 'px' : THUMB_WIDTH + 'px',
        height: horizontal ? THUMB_WIDTH + 'px' : THUMB_HEIGHT + 'px',
        'border-radius': '10px',
        'background-color': 'var(--colorTheme)',
        top: horizontal ? '0' : position + 'px',
        left: horizontal ? position + 'px' : '0',
        cursor: 'move',
        border: '0',
        padding: THUMB_PADDING / 2 + 'px',
      }"
      @touchstart="dragstart"
      @touchmove.prevent="move"
      @mousedown="dragstart"
      @mousemove.prevent="move"
      @mouseover="transparent = true"
      @mouseleave="transparent = false"
    >
      <!-- <div
        :style="{
          right: 0,
          width: horizontal
            ? THUMB_HEIGHT - THUMB_PADDING + 'px'
            : THUMB_WIDTH - THUMB_PADDING + 'px',
          height: horizontal
            ? THUMB_WIDTH - THUMB_PADDING + 'px'
            : THUMB_HEIGHT - THUMB_PADDING + 'px',
          'border-radius': '10px',
          'background-color': 'white',
          cursor: 'move',
          border: '0',
        }"
      ></div> -->
    </button>
  </div>
</template>
<script>
  // @ts-check
  import { computed, defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api';
  const THUMB_WIDTH = 15;
  const THUMB_HEIGHT = 30;
  const THUMB_PADDING = 10;

  export default defineComponent({
    props: {
      value: {
        type: Number,
        required: true,
      },
      length: {
        type: Number,
        required: true,
      },
      horizontal: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, context) {
      const dragging = ref(false);
      let startX = 0;
      let startY = 0;
      let startVal = 0;

      const length2 = computed(() => {
        return props.length - (THUMB_WIDTH + THUMB_HEIGHT);
      });

      const transparent = ref(true);
      const opacity = computed(() => {
        return transparent.value || dragging.value ? '1' : '0.4';
      });

      const dragstart = (
        /** @type {{ clientX: number; clientY: number; touches:Array<{clientX:number; clientY:number;}>; }} */ e
      ) => {
        dragging.value = true;
        startX = e.clientX ?? e.touches[0].clientX;
        startY = e.clientY ?? e.touches[0].clientY;
        startVal = props.value;
      };
      const move = (/** @type {any} */ e) => {
        if ((dragging.value === false || e.buttons !== 1) && e.type != 'touchmove') {
          return;
        }
        const movedX = e.clientX ?? e.touches[0].clientX;
        const movedY = e.clientY ?? e.touches[0].clientY;
        const dr = props.horizontal ? movedX - startX : movedY - startY;
        const d = startVal + dr / length2.value;
        if (0 <= d && d <= 1) {
          context.emit('change', d);
        }
      };
      const dragend = () => {
        dragging.value = false;
        startX = 0;
        startY = 0;
        startVal = 0;
      };

      const position = computed(() => {
        return length2.value * props.value;
      });

      onMounted(() => {
        addEventListener('mousemove', move);
        addEventListener('mouseup', dragend);
      });

      onUnmounted(() => {
        removeEventListener('mousemove', move);
        removeEventListener('mouseup', dragend);
      });
      return {
        move,
        dragging,
        dragstart,
        dragend,
        position,
        length2,
        transparent,
        opacity,
        THUMB_WIDTH,
        THUMB_HEIGHT,
        THUMB_PADDING,
      };
    },
  });
</script>
<style scoped>
  @keyframes fe {
    0% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }
  @keyframes be {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }
  .scrollbar-base {
    opacity: 0.4;
  }
  .scrollbar-base.transparent {
    animation: fe 1s forwards;
    opacity: 1;
  }
</style>
